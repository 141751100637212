import moment from "moment/moment";
import Tooltip from "../../tooltip";
import {BellIcon as BellSolidIcon} from "@heroicons/react/20/solid";
import React, {useEffect, useState} from "react";
import CellButton from "./cell-button";

function ActiveTableReminderMessage({

                                        LastUpdateDateTime,
                                        UpdateReminderInterval,
                                        toggleReminderDialog,
                                        item,
                                        translate
                                    }) {
    const [state, setState] = useState({content: "", className: "", status: "", message: ""});

    useEffect(() => {
        let className;
        let content;
        let status = '';
        let intervalDiff = moment.utc().diff(moment.utc(LastUpdateDateTime), 'minutes');
        let message = translate("text.reminder_interval_in_minutes", [UpdateReminderInterval]);

        const roundedIntervalDiff = Math.abs(UpdateReminderInterval - Math.round(intervalDiff));
        let days = 0;
        let hours = 0;
        let minutes = 0;
        let timeData = {days, hours, minutes};


            timeData.days = Math.floor(roundedIntervalDiff / 1440);
            timeData.minutes = roundedIntervalDiff % 1440;
            timeData.hours = Math.floor(timeData.minutes / 60);
            timeData.minutes = timeData.minutes % 60;

        if (intervalDiff <= UpdateReminderInterval * 0.8) {
            if (timeData.days) {
                content = translate('text.reminderInDays', [timeData.days, timeData.hours, timeData.minutes]);
            } else if (timeData.hours) {
                content = translate('text.reminderInHours', [timeData.hours, timeData.minutes]);
            } else {
                content = translate('text.reminderInMinutes', [timeData.minutes]);
            }
            className = 'mr-1 w-5 h-5 flex-shrink-0 text-green-600'
            status = 'green'
        } else if (roundedIntervalDiff === 0) {
            content = translate('text.lastStopReminderNowNote');
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-yellow-600'
            status = 'yellow'
        } else if (intervalDiff <= UpdateReminderInterval) {
            content = translate('text.reminderInMinutes', [roundedIntervalDiff]);
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-yellow-600'
            status = 'yellow'
        } else {
            if (timeData.days) {
                content = translate('text.reminderOverdueInDays', [timeData.days, timeData.hours, timeData.minutes]);
            } else if (timeData.hours) {
                content = translate('text.reminderOverdueInHours', [timeData.hours, timeData.minutes]);
            } else {
                content = translate('text.reminderOverdueInMinutes', [timeData.minutes]);
            }
            className = 'mr-1 w-5 h-5 flex-shrink-0 animate-blink-soft text-red-600'
            status = 'red'
        }

        setState({content, className, status, message})
    }, []);

    return <CellButton
        disabled={!toggleReminderDialog}
        onClick={() => toggleReminderDialog(item)}
    >
        <Tooltip content={state.message}>
            <BellSolidIcon className={state.className}/>
        </Tooltip>

        <div className="truncate">{state.content}</div>
    </CellButton>
}

export default function TableReminderMessage({
                                                 IsUpdateReminderOn,
                                                 message = "No reminder",
                                                 LastUpdateDateTime,
                                                 UpdateReminderInterval, // in minutes
                                                 toggleReminderDialog,
                                                 createdDate,
                                                 item,
                                                 translate
                                             }) {
    if (!IsUpdateReminderOn) {
        return <div className='flex relative z-0'>
            {message}
        </div>
    }

    return <ActiveTableReminderMessage
        LastUpdateDateTime={LastUpdateDateTime}
        UpdateReminderInterval={UpdateReminderInterval}
        toggleReminderDialog={toggleReminderDialog}
        createdDate={createdDate}
        item={item}
        translate={translate}
    />
}