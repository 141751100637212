import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_CRUD_STATE, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getProp,
    mergeDeep,
    openInNewTab,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {showModal} from "../../../data/actions/ui";
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import Tippy from "@tippyjs/react";
import {download} from '../../../data/actions/download'
import {currentDate} from "../../../common/util/util-dates";
import moment from "moment-timezone";
import {cloneDeep} from "../../../common/util/util-vanilla";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import ResourceTable from "../../../common/components/resource-table";
import StatusProgressTable from "../../../common/components/resource-table/table-components/status-progress-table";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

export default class UnpaidCommissions extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: true
            },
            columns: {}
        }

        this.pagePath = "unpaid-commissions"

        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate)
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_unpaid_commissions_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData();
            this.saveFilters();
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleQuickContactView = (item) => {
        item.ContactID = item.ComissionContactID
        this.props.dispatch(showModal('ViewContactCard', item))
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.UnpaidCommissions
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getFields = () => {
        return {
            Commision: new Field('Commision', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Contacts, READ_PERM) ? this.handleQuickContactView(item) : null}>
                        <div>
                            {item.Commision}
                        </div>
                    </CellButton>
            }),
            Customer: new Field('Customer', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Organizations, READ_PERM) ? this.handleToggleOrganizationInfoDialog(item) : null}>
                        <div>
                            {item.Customer}
                        </div>
                    </CellButton>
            }),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.LoadInfo, UPDATE_PERM) && openInNewTab(`/loads/info/${item.LoadID}`)}>
                        <div>
                            {item.LoadNumber}
                        </div>
                    </CellButton>
            }),
            LoadStatus: new Field('LoadStatus', '', [''], false, 'select', {
                render: (item) => {
                    return (
                        <StatusProgressTable
                            addClass=""
                            LoadStatusID={item.LoadStatusID}
                            translate={this.props.translate}
                        />
                    )
                }
            }),
            PickupDate: new Field('PickupDate', '', [''], false, 'date', {}),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'date', {}),
            Amount: new Field('Amount', '', [''], false, 'float', {
                omitSort: true
            }),
        }
    }

    getQueryFilterFields = () => {
        return {
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'}),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {
                addContainerClass: "z-50"
            }, {isClearable: true}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {
                addContainerClass: "z-50"
            }, {isClearable: true}),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search', {
                addContainerClass: "col-span-2"
            }, {isClearable: true}),
            LoadID: new Field('LoadID', '', [], false, 'select-search', {
                    addContainerClass: "col-span-2"
                },
                {isClearable: true}
            ),
            ContactID: new Field('ContactID', '', [], false, 'select-search', {
                addContainerClass: "col-span-2"
            }),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)

        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <React.Fragment>
                <div className="flex mb-4">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleFilterClear}
                        translate={translate}
                    />

                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        forceDialog
                        onRefreshTable={this.fetchData}
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                                gridCols={"grid-cols-1"}
                            />
                        }
                    />

                    <ResourceTable
                        data={data}
                        commonTable={true}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                    />

                    {/*Table footer*/}
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}
            </React.Fragment>
        )
    }
}
