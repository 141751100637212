import React from 'react';
import {useSelector} from 'react-redux';
import Button from "../../../button";
import {classNames} from "../../../../util/util-helpers";

export default function PageFooter({
                                       hasPerm = true,
                                       htmlBefore,
                                       actionSubmit,
                                       canSubmit,
                                       buttonLabel,
                                       actionCancel,
                                       cancelButtonLabel,
                                       translate
                                   }) {
    const ui = useSelector((state) => state.ui);


    return (
        <div
            className={
                classNames(
                    "w-full pl-52 z-30 h-16 flex justify-end items-center gap-3 fixed right-0 px-8 bg-inverse border-tm-gray-300 border-t",
                    ui?.AIChatPosition === 'bottom' && ui?.isAIChatVisible
                        ? "footer--with-bottom-chat"
                        : "bottom-0"
                )
            }
        >
            {htmlBefore}

            {actionSubmit && (
                <Button
                    data={{"data-name": "submit-button-shortcut"}}
                    hasPerm={hasPerm}
                    appearance="primary"
                    disabled={!canSubmit}
                    onClick={canSubmit ? actionSubmit : null}>{
                    buttonLabel ?? translate('btn.update')}
                </Button>
            )}

            {actionCancel && (
                <Button
                    data-name="cancel-button-shortcut"
                    className="btn btn-outline"
                    disabled={!canSubmit}
                    onClick={canSubmit ? actionCancel : null}
                >
                    {cancelButtonLabel ?? translate('btn.cancel')}
                </Button>
            )}
        </div>
    )
}
