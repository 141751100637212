import React, { useEffect, useMemo, useRef, useState } from "react";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import Tippy from "@tippyjs/react";
import StarIcon from "@heroicons/react/24/outline/StarIcon";
import NoSymbolIcon from "@heroicons/react/24/outline/NoSymbolIcon";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import LightBulbIcon from "@heroicons/react/24/outline/LightBulbIcon";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import {fieldsToHtml} from "../../../../util/util-fields";
import LocalStorage from "../../../../util/localStorage";
import {cloneDeep} from "../../../../util/util-vanilla";
import {classNames} from "../../../../util/util-helpers";

export default function TableColumnFilters({fields, selects, columnOptions, tableColumnFields, onFilterChange, onClearFiltersClick, translate}) {
    const storage = LocalStorage.get("loads_column_filter_options", {});

    const filterContainer = useRef(null);

    const [filter, setFilter] = useState(storage?.filter ?? "");
    const [activeField, setActiveField] = useState(storage?.activeField ?? "");
    const [toggleFavourite, setToggleFavourite] = useState(storage?.toggleFavourite ?? false);
    const [toggleVisible, setToggleVisible] = useState(storage?.toggleVisible ?? false);
    const [toggleActiveTop, setToggleActiveTop] = useState(storage?.toggleActiveTop ?? false);

    const [favourite, setFavourite] = useState(storage?.favourite ?? {});
    const [favouriteView, setFavouriteView] = useState( storage?.favouriteView ?? !favourite.length);

    const handleCheckFavourite = (e) => {
        let favouriteClone = cloneDeep(favourite)
        if (favouriteClone[e.target.name]) {
            delete favouriteClone[e.target.name];
        } else {
            favouriteClone[e.target.name] = true;
        }
        setFavourite(favouriteClone);
    }

    const areFilterClear = useMemo(() => {
        return !!Object.values(fields).find(it => it?.value?.value || it?.value)
    }, [fields])

    const colFields = useMemo(() => {
        const fieldsClone = cloneDeep(fields);
        
        const sortedFields = Object.keys(columnOptions).map(it => fieldsClone[it]);

        return sortedFields
            .filter(field =>
                !!field && (field?.type !== 'hidden') && (
                    (filter === "" || field.name.toLowerCase().includes(filter.toLowerCase())) &&
                    (!toggleVisible || columnOptions[field.name].show) &&
                    (!toggleActiveTop || !!field.value) &&
                    (!toggleFavourite || !!favourite[field.name])
                )
            )
            .map(field => {
                return modifyField(field, columnOptions, translate);
            })
    }, [fields, filter, tableColumnFields, toggleVisible, toggleActiveTop, toggleFavourite, favourite])

    const colInactiveFields = useMemo(() => {
        const fieldsClone = cloneDeep(fields);

        const sortedFields = Object.keys(columnOptions).map(it => fieldsClone[it]);

        return sortedFields
            .filter(field =>
                (!toggleVisible || columnOptions[field.name].show) &&
                (!(field?.value || field?.value?.value))
            )
            .map(field => {
                return modifyField(field, columnOptions, translate);
            })

    }, [fields, setToggleActiveTop, toggleVisible])

    const favFields = useMemo(() => {
        const fieldsClone = cloneDeep(Object.values(fields));

        return fieldsClone
            .filter(field => filter === "" || field.name.toLowerCase().includes(filter.toLowerCase()))
            .filter(field => !toggleVisible && !!columnOptions?.[field.name]?.show)
            .map(field => {
                return  <label
                    key={field.name}
                    className="mb-0 select-none py-1 px-2 flex items-center cursor-pointer hover:bg-tm-gray-100 rounded-md"
                >

                    <input
                        type="checkbox"
                        className="z-10 rounded bg-tm-gray-50 checked:bg-primary ring-offset-inverse mr-3"
                        checked={favourite[field.name]}
                        name={field.name}
                        onChange={handleCheckFavourite}
                    />

                    {columnOptions[field.name].label}
                </label>
            })
    }, [fields, filter, tableColumnFields, toggleVisible, toggleActiveTop, favourite])

    const ALL_FIELDS_NUMBER = Object.keys(fields).length;
    const UNFILTERED_FIELDS_UMBER = colFields.length;
    const FAV_FIELDS_NUMBER = Object.keys(favFields).length;
    const handleFilterChange = (name, value) => {
        setActiveField(name);
        onFilterChange(name, value);
    }

    const toggleFavouriteView = () => {
        setFavouriteView(!favouriteView);
    }

    useEffect(() => {
        if (!!toggleActiveTop && filterContainer?.current) {
            const input = filterContainer.current.querySelector("input[name='" + activeField + "']")

            if (input) {
                input.focus();
            }
        }
    }, [fields])

    useEffect(() => {
        let storage = LocalStorage.get("loads_column_filter_options", {});

        storage = Object.assign(storage, {
            filter, activeField, toggleFavourite, toggleVisible, toggleActiveTop, favourite, favouriteView
        })

        LocalStorage.set("loads_column_filter_options", storage);
    }, [filter, activeField, toggleFavourite, toggleVisible, toggleActiveTop, favourite, favouriteView])

    return (
        <div
            ref={filterContainer}
            className="grid gap-4 px-2 pt-3 pb-72 text-tm-gray-900"
        >
            <div className="inline relative">
                <input placeholder={translate("text.filter_columns")} className="form-control" type="text" value={filter}
                    onChange={e => setFilter(e.target.value)}/>

                {!!filter && (
                    <div className="absolute top-0 right-0 h-full flex items-center pr-1">
                        <button 
                            onClick={() => setFilter('')}
                            className="btn btn-close bg-transparent"
                        >
                            <XMarkIcon/>
                        </button>
                    </div>
                )}
            </div>

            <div className="flex items-center gap-2 border-b border-tm-gray-300 -mx-2 px-2 pb-4">
                <Tippy
                    content={translate("text.show_active_on_top")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        className={classNames(toggleActiveTop ? "bg-primary text-white border-primary" : undefined, "btn btn-header flex-shrink-0 w-8 h-8")}
                        onClick={() => {
                            setToggleActiveTop(!toggleActiveTop);
                            setToggleFavourite(false);
                        }}
                    >
                        <LightBulbIcon className="w-5 h-5"/>
                    </button>
                </Tippy>

                <Tippy
                    content={translate("text.show_visible_columns")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        className={classNames(toggleVisible ? "bg-primary text-white border-primary" : undefined, "btn btn-header flex-shrink-0 w-8 h-8")}
                        onClick={() => setToggleVisible(!toggleVisible)}
                    >
                        <EyeIcon className="w-5 h-5"/>
                    </button>
                </Tippy>

                <Tippy
                    content={translate("text.show_favourite")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        className={classNames(toggleFavourite ? "bg-primary text-white border-primary" : undefined, "btn btn-header flex-shrink-0 w-8 h-8")}
                        onClick={() => {
                            setToggleFavourite(!toggleFavourite);
                            setToggleActiveTop(false);
                        }}
                    >
                        <StarIcon className="w-5 h-5"/>
                    </button>
                </Tippy>

                <Tippy
                    content={translate("text.clear_all_col_filters")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        disabled={!areFilterClear}
                        className={classNames("btn btn-header flex-shrink-0 w-8 h-8 ml-auto")}
                        onClick={onClearFiltersClick}
                    >
                        <NoSymbolIcon className="w-5 h-5"/>
                    </button>
                </Tippy>
            </div>

            {!toggleFavourite && !toggleActiveTop && ALL_FIELDS_NUMBER !== UNFILTERED_FIELDS_UMBER && (
                <div
                    className="text-center items-center justify-between bg-tm-gray-50 py-2 px-2 rounded-lg border border-tm-gray-300 text-tm-gray-700">
                    <p><span className="font-bold">{UNFILTERED_FIELDS_UMBER}</span> of <span
                        className="font-bold">{ALL_FIELDS_NUMBER}</span> columns visible.</p>
                </div>
            )}

            {!!toggleFavourite && !!favouriteView && FAV_FIELDS_NUMBER !== ALL_FIELDS_NUMBER && (
                <div
                    className="text-center items-center justify-between bg-tm-gray-50 py-2 px-2 rounded-lg border border-tm-gray-300 text-tm-gray-700">
                    <p><span className="font-bold">{FAV_FIELDS_NUMBER}</span> of <span
                        className="font-bold">{ALL_FIELDS_NUMBER}</span> columns visible.</p>
                </div>
            )}

            {!!toggleActiveTop && !!colFields.length && (
                <p className="font-semibold text-uppercase">{translate("text.active_filters")}</p>
            )}

            {!!toggleFavourite && (
                <div className="flex items-center">
                    <p className="font-semibold text-uppercase">{translate("text.favourite_fields")}</p>

                    <Tippy
                        disabled={!favourite.length}
                        content={translate("text.save_favourites")}
                        delay={[400, 0]}
                        trigger={"mouseenter"}
                    >
                        <button
                            className={classNames("btn btn-header flex-shrink-0 w-8 h-8 ml-auto", favouriteView ? "hover:border-green-600" : undefined)}
                            onClick={toggleFavouriteView}
                        >
                            {!!favouriteView && (
                                <CheckIcon className="w-5 h-5 text-green-600"/>
                            )}

                            {!favouriteView && (
                                <Cog6ToothIcon className="w-5 h-5 text-tm-gray-400"/>
                            )}
                        </button>
                    </Tippy>
                </div>
            )}

            {!(!!toggleFavourite && !!favouriteView) && (
                fieldsToHtml(colFields, translate, handleFilterChange, selects)
            )}

            {!!toggleFavourite && favouriteView && (
                favFields
            )}

            {!!toggleActiveTop && !!colInactiveFields.length && (
                <React.Fragment>
                    <p className={classNames("font-semibold text-uppercase", colFields.length ? "mt-8" : undefined)}>{translate("text.inactive_filters")}</p>
                    {fieldsToHtml(colInactiveFields, translate, handleFilterChange, selects)}
                </React.Fragment>
            )}
        </div>
    )
}

const modifyField = (field, columnOptions, translate) => {
    if (!field) {
        return {}
    }
    if (!field.metadata) {
        field.metadata = {};
    }

    if (!field.props) {
        field.props = {icon: false}
    } else {
        field.props = Object.assign(field.props, {icon: false})
    }

    if (field.metadata.colFilter === true) {
        field.metadata.colFilter = {};
    }

    if (!columnOptions[field.name].show && !columnOptions[field.name].inSubColumn) {
        field.metadata.note = translate("text.column_is_hidden")
    }

    if (field.metadata?.colFilter?.type) {
        field.type = field.metadata.colFilter.type
    }


    // This doesn't do anything anymore
    // if (field.type === "checkbox") {
    //     field.type = "select";
    //     field.metadata.colFilter.select = {
    //         [field.name]: {
    //             "": translate("text.all"),
    //             "1": translate("text.yes"),
    //             "0": translate("text.no")
    //         }
    //     }
    // }
    //
    if (!(field.type === "select-search" || field.type === "select" || field.type === 'multi-select-search')) {
        field.type = "search"
    }

    if (field.metadata?.colFilter?.label) {
        field.metadata.label = field.metadata.colFilter.label
    }

    if (field.metadata?.colFilter?.hideLabel) {
        field.metadata.hideLabel = field.metadata.colFilter.hideLabel
    }

    field.metadata.addContainerClass = "w-56"

    return field;
}
