import React, {Component} from "react";
import {connect} from "react-redux";
import LocalStorage from "../../../util/localStorage";
import FieldSelectSearch from "../../fields/field-select-search";
import FieldDate from "../../fields/field-date";
import FieldText from "../../fields/field-text";
import FieldTextarea from "../../fields/field-textarea";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {createResource, updateResource} from "../../../../data/actions/resource";
import {getLookup} from "../../../util/util-helpers";
import ModalFooter from "../../modal/modal-footer";
import {fillFieldsFromData} from "../../../util/util-fields";

class AddEmploymentDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Data Events
     ================================================================= */
    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.props.selectedItem) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            [this.getPrimaryKey()]: this.props.selectedItem[this.getPrimaryKey()],
                            id: this.getID()
                        }),
                        query: {
                            id: this.getID(),
                        },
                        resource: this.getResource(),
                        piggyResource: this.getResource(),
                        errorMessage: true, successMessage: this.props.translate('text.employment_updated'),
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            id: this.getID()
                        }),
                        query: {
                            id: this.getID()
                        },
                        resource: this.getResource(),
                        piggyResource: this.getResource(),
                        errorMessage: true, successMessage: this.props.translate('text.employment_created'),
                    }));
                }
                this.handleCloseModal();
            }
        });
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        if (name === "HireDate" && value > this.state.fields.ReleaseDate.value) {
            let fields = this.state.fields
            fields.ReleaseDate.value = ''
            fields.HireDate.value = value
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true});
        } else {
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true});
        }
    };


    handleCloseModal = () => {
        this.props.onClose()
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        const disabled = this.props.driverStatusID == 8;
        const fieldTemplates = {
            HireDate: new Field('HireDate', '', ['empty'], disabled),
            ReleaseDate: new Field('ReleaseDate', '', [], disabled),
            ContractNum: new Field("ContractNum", '', [], disabled),
            EmploymentTypeID: new Field("EmploymentTypeID", '', [this.props.hideEmploymentType ? '' : 'empty'], disabled),
            Notes: new Field("Notes", '', [])
        }

        return this.props.selectedItem ? fillFieldsFromData(fieldTemplates, this.props.selectedItem) : fieldTemplates
    }

    getResource = () => {
        return this.props.resourceName ?? ""
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? ""
    }

    getID = () => {
        return this.props.id ?? ""
    }

    render() {
        const {translate, title} = this.props;

        const EmploymentType = getLookup('EmploymentType', 'EmploymentTypeID', 'EmploymentType');

        return (
            <React.Fragment>
                <header
                    className="p-4 flex justify-between items-center border-tm-gray-200 border-b text-tm-gray-900">
                    <h2 className="text-lg text-current">{title ?? translate(this.props.selectedItem ? "header.UpdateEmployment" : "header.CreateEmployment")}</h2>

                    <button aria-label="Close"
                            onClick={() => {
                                this.handleCloseModal();
                            }}>
                        <XMarkIcon className="w-5 h-5"/>
                    </button>
                </header>
                <div className={'p-5'}>
                    <div className="row grid gap-2">
                        <div className="col-md-12">
                            <span className="form-group has-float-label mb-4">
                                <span>{translate('field.ContractStartDate')} *</span>

                                <FieldDate
                                    {...this.state.fields.HireDate}
                                    className={"form-control w-full select-css-search"}
                                    addClass={"form-control"}
                                    onChange={this.handleInputChange}
                                    showTimeSelect={false}
                                    isClearable
                                />
                            </span>
                        </div>
                        <div className="col-md-12">
                            <span className="form-group has-float-label mb-4">
                                <span>{translate('field.ContractEndDate')}</span>

                                <FieldDate
                                    {...this.state.fields.ReleaseDate}
                                    className={"form-control  select-css-search"}
                                    addClass={"form-control"}
                                    onChange={this.handleInputChange}
                                    showTimeSelect={false}
                                    isClearable
                                />
                            </span>
                        </div>
                        <div className="col-md-12">
                            <label className="form-group has-float-label mb-4">
                                <span>{translate('field.ContractNum')}</span>

                                <FieldText
                                    className={"form-control  select-css-search"}
                                    addClass={"form-control"}
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.ContractNum}
                                />
                            </label>
                        </div>
                        {!this.props.hideEmploymentType && (
                            <div className="col-md-12">
                                <label className={"form-group has-float-label mb-4"}>
                                    <span>{translate("field.EmploymentTypeID")} *</span>

                                    <FieldSelectSearch
                                        addClass="form-control"
                                        className={"form-control"}
                                        values={EmploymentType}
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.EmploymentTypeID}
                                    />
                                </label>
                            </div>
                        )}
                        <div className="col-md-12">
                            <label className="form-group has-float-label mb-4">
                                <span>{translate('field.Notes')}</span>

                                <FieldTextarea
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.Notes}
                                    addClass={"form-control whitespace-pre-wrap"}/>
                            </label>
                        </div>
                    </div>
                </div>

                <ModalFooter
                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.handleCloseModal}
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate(`btn.${this.props.selectedItem ? "save" : "create"}`)}
                    onButtonClick={this.state.canSubmit && this.submit}
                />
            </React.Fragment>
        );
    }
}

export default connect(state => state)(AddEmploymentDialog);
