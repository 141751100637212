import React, {useEffect, useState} from "react";
import ResourceTable from "../../../../common/components/resource-table";
import {Field} from "../../../../data/services/fields";
import CellButton from "../../../../common/components/resource-table/table-components/cell-button";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import ModalDefault from "../../../../common/components/modal/modal-default";
import FieldCellMoney from "../../../../common/components/fields/field-cell-money";
import {formatMoney} from "../../../../common/util/util-formaters";
import {numberWithCommasToBack} from "../../../../util/util-formaters";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {LoaderLarge} from "../../../../common/components/loader";

export default function StripeBulkCreateTerminalPayments({translate, show, data, onSubmit, isLoading, onClose}) {
    const [innerData, setInnerData] = useState({});

    const hasNegativeBalance = Object.values(innerData).some(it => {
        const currentBalance = it.Balance - numberWithCommasToBack(it.PayAmount)
        return !!currentBalance && currentBalance < 0;
    })

    function onPayAmountChange(name, innerValue, item) {
        const innerDataUpdate = Object.assign({}, innerData);
        innerDataUpdate[item.InvoiceID][name] = innerValue;
        setInnerData(innerDataUpdate);
    }

    function getFields() {
        return {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text'),
            RefNumber: new Field('RefNumber', '', [''], false, 'text'),
            ChargeTo: new Field('ChargeTo', '', [], false, 'custom', {
                omitSort: true,
                render: (item) =>
                    <CellButton
                        onClick={() => item.OrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                        <div>
                            {item.ContactID ? item.Contact : item.Organization}
                        </div>
                    </CellButton>,
                label: 'ChargeTo'
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return (
                        <div className="flex justify-end w-full">
                            {genericMoneyFormatter(item.Amount - item.AmountTransferred)}
                        </div>
                    )
                }
            }),
            Balance: new Field('Balance', '', [''], false, 'money', {
                label: "CurrentBalance"
            }),
            RemainingBalance: new Field('RemainingBalance', '', [''], false, 'money', {
                render: (it) => {
                    const remainingBalance = it.Balance - numberWithCommasToBack(it.PayAmount);
                    const isRemainingBalanceNegative = !!remainingBalance && remainingBalance < 0;
                    return <span className={isRemainingBalanceNegative ? "text-red-600" : undefined}>
                        {formatMoney(it.Balance - numberWithCommasToBack(it.PayAmount))}
                        </span>
                }
            }),
            PayAmount: new Field('PayAmount', "", ['required'], false, 'money', {
                render: (item) => {
                    return <div className="pr-6">
                        <FieldCellMoney
                            value={item?.PayAmount}
                            name="PayAmount"
                            onChange={(name, innerValue) => onPayAmountChange(name, innerValue, item)}
                        />
                    </div>
                }
            })
        }
    }

    useEffect(() => {
        const initialInnerData = Object.values(data ?? []).reduce((memo, it) => {
            memo[it.InvoiceID] = it;
            memo[it.InvoiceID]["Balance"] = parseFloat((it?.Amount - it?.AmountTransferred).toFixed(2))
            memo[it.InvoiceID]["PayAmount"] = memo[it.InvoiceID]["Balance"];

            return memo;
        }, {})
        setInnerData(initialInnerData);
    }, [data]);

    return <ModalDefault
        show={show}
        widthClass="max-w-5xl"
        title={'Pay confirmation'}
        onClose={onClose}
        closeButtonLabel={translate('btn.close')}
        buttonDisabled={hasNegativeBalance || isLoading}
        buttonLabel={translate("btn.pay_with_card")}
        onButtonClick={() => onSubmit(innerData)}
        translate={translate}
    >
        {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
                <LoaderLarge/>
            </div>
        )}

        <ResourceTable
            data={Object.values(data)}
            fields={getFields()}
            translate={translate}
        />

        {hasNegativeBalance && (
            <div className="p-1">
                <InfoBar type="danger">The remaining balance cannot be negative.</InfoBar>
            </div>
        )}
    </ModalDefault>
}