import React from 'react'

export default function paymentsTab() {

    return (
        <React.Fragment>
            <div className="card h-full">
                <div className="card-body">
                    <p className="mb-3">

                    </p>

                    <p className="text-tm-gray-500 text-small mb-2">Test</p>
                    <p className="mb-3">Test</p>

                    <p className="text-tm-gray-500 text-small mb-2">Test</p>
                    <p className="mb-3">

                        <a href="#">
                    <span
                        className="badge badge-pill badge-outline-theme-2 mb-1">Test</span>
                        </a>
                        <a href="#"></a>

                    </p>
                    <p className="text-tm-gray-500 text-small mb-2">Test</p>
                    <div className="social-icons">
                        <ul className="list-unstyled list-inline">
                            <li className="list-inline-item">
                                <a href="#"><i className="simple-icon-social-facebook"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#"><i className="simple-icon-social-twitter"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#"><i className="simple-icon-social-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
