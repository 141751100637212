import React, {Component} from 'react'
import {connect} from 'react-redux'
import EventsTab from './load-sidebar/events-tab'
import TracksTab from './tracks-tab'
import ClaimsTab from './claimsTab'
import {updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import WatchersTab from './watchersTab'
import {closeNotification, pushNotification, showGlobalModal, showModal} from '../../../data/actions/ui'
import LoadInfoTab from './load-info-tab'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import LoadPricingTab from './load-pricing-tab'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getThirdResource} from '../../../data/actions/thirdResource'
import Tippy from '@tippyjs/react'
import Bars3BottomRightIcon from '@heroicons/react/24/outline/Bars3BottomRightIcon'
import LoadInternalChatTab from './load-sidebar/load-internal-chat-tab'
import LoadExternalChatTab from './load-sidebar/load-customer-chat-tab'
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon'

import moment from "moment";
import {
    COLOR_PICKER_COLOR,
    CREATE_PERM,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DELETE_PERM,
    LOAD_STATUS_CANCELED,
    READ_PERM,
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDocumentTypesFor,
    getProp,
    openInNewTab,
    resourceIsCreated,
    resourceIsLoaded,
    resourceIsUpdated
} from '../../../common/util/util-helpers'
import {DocumentTextIcon, ExclamationTriangleIcon, PauseCircleIcon} from '@heroicons/react/24/outline'
import {getInfoResource} from "../../../data/actions/infoResource";
import {clearLoadUpdated, editLoad, joinLoad, leaveLoad} from '../../../package/realtime/actions/realtime'
import {cloneDeep} from "../../../common/util/util-vanilla";
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import ContactSelectButton from "./load-sections/load-components/contact-select-button";
import ArrowTopRightOnSquareIcon from "@heroicons/react/24/outline/ArrowTopRightOnSquareIcon";
import {getContactDialogFields} from "./load-utils";
import SendLoadDocuments from "./load-sections/send-load-documents";
import {TruckIcon} from "@heroicons/react/20/solid";
import {setTableData} from "../../../data/actions/tablesDataResource";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import Modal from "../../../common/components/modal";
import TableTagManagerModal
    from "../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-modal";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import NavResponsive from "../../../common/components/nav-responsive";
import ImageAvatarLoader from "../../../common/components/image/image-avatar-loader";
import PopOver from "../../../common/components/popover";
import InfoParagraph from "../../../common/components/info-paragraph";
import {LoaderSmall} from "../../../common/components/loader";
import StickyElement from "../../../common/components/sticky";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import PageResourceTag from "../../../common/components/layout/layout-components/page/page-resource-tag";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import ContactForm from "../../../common/components/forms/contact-form";
import Layout from "../../../common/components/layout";
import {getUser} from "../../../common/util/util-auth";
import {excludeFields} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import LoadInvoicedButton from "./load-invoiced-button";

class LoadView extends Component {
    constructor(props) {
        super(props)
        this.prevPageIsSplitLoad = sessionStorage.getItem("go_back_path") === '/loads-split';

        const query = new URLSearchParams(this.props.location.search)
        const queryTab = query.get('tab');

        const sidebarTab = query.get('sidebar');

        let tabs = [
            {
                name: 'LoadInfo',
                resource: Resources.LoadInfo,
                hasPerm: checkPerm(Resources.LoadInfo, READ_PERM),
                current: true
            },
            {
                name: 'LoadPricing',
                resource: Resources.LoadPricing,
                hasPerm: checkPerm(Resources.LoadPricing, READ_PERM),
                current: false
            },
            {
                name: 'LoadMap',
                resource: Resources.LoadTracks,
                hasPerm: checkPerm(Resources.LoadTracks, READ_PERM),
                current: false
            },
            {
                name: 'LoadWatchers',
                resource: Resources.LoadWatchers,
                hasPerm: checkPerm(Resources.LoadWatchers, READ_PERM),
                current: false
            },
            {
                name: 'LoadClaims',
                resource: Resources.LoadClaims,
                hasPerm: checkPerm(Resources.LoadClaims, READ_PERM),
                current: false
            },
            {
                name: 'LoadNotificationSettings',
                resource: Resources.LoadNotificationSettings,
                visible: false, //checkPerm(Resources.LoadNotificationSettings, READ_PERM),
                current: false
            },
            {
                name: 'LoadDocuments',
                resource: Resources.LoadDocuments,
                hasPerm: checkPerm(Resources.LoadDocuments, READ_PERM) || checkPerm(Resources.LoadDocuments, CREATE_PERM),
                current: false
            },
        ]

        if (queryTab) {
            tabs = tabs.map(tab => {
                tab.current = tab.name === queryTab;
                return tab;
            })
        }

        this.sidebarTabs = [
            {name: 'Events', resource: Resources.LoadEvents, visible: checkPerm(Resources.LoadEvents, READ_PERM)},
            {name: 'Internal chat', resource: Resources.LoadChatInternal, visible: true},
            {name: 'Customer chat', resource: Resources.LoadChatExternal, visible: true},
        ]

        this.state = {
            isDirty: false,
            remountComponentKey: 1,
            fields: this.getFields(),
            lineOfBusiness: undefined,
            fields_notifications: this.getNotifications(),
            sections: {
                'info-basic': {
                    isOpen: true
                },
                'locations': {
                    isOpen: true
                }
            },
            IsTonu: undefined,
            IsDropped: undefined,
            isConfirmModalOpen: false,
            selectedTabResource: undefined,
            isResourceTaskModalVisible: false,
            viewHistoryDialog: false,
            isSidebarVisible: !!sidebarTab,
            tabs: tabs,
            activeSidebarTab: sidebarTab ?? this.sidebarTabs[0].resource,
            selectedTab: tabs.find(it => it.current)?.resource,
            isManifestSent: false,
            loadStatusID: undefined,
            updateEventsTab: 0,

            usersOnPage: {
                totalUsers: 0,
                users: []
            },

            colorTags: [],
            loadColorTags: "",
            tagColor: COLOR_PICKER_COLOR[0],
            selectedLabels: {},

            isInvoiced: undefined,
            isPODReady: undefined,
            documentCounts: {},
            isLoadUpdatedByOtherUserModalOpen: false,
            isBookedByDialogOpen: false,
            isCoveredByDialogOpen: false,
            isCreateContactDialogOpen: false,
            isLoadUpdatedByOtherUser: false,
            loadUpdatedByOtherUserTime: undefined,
            isTagManagerModalOpen: false,
            isLayoutScrolled: false,
            doesEmailExistsError: false,
            printLoadCommodityID: 0,
            LoadNumber: undefined,
            createdBy: {
                id: undefined,
                name: ""
            }
        }

        this.PopoverButtonRef = React.createRef();
        this.tagPopoverButtonRef = React.createRef();
        this.layoutRef = React.createRef();
        this.initialLoadStatusID = 0;
        this.ContactID = LocalStorage.get('user')?.Contact?.ContactID;

        this.contactTableOptions = {
            style: {
                stripedRows: true,
                horizontalLines: false,
                floatingActions: false,
            },
        }

        this.emailTemplateQuery = {
            LoadID: this.getID(),
            CarrierID: undefined,
            DriverID: undefined,
            CustomerID: undefined
        }

        this.goBackPath = this.getBackPath();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchTaskResourceCount();
        this.fetchLoadTags();

        if (this.state.selectedTab !== Resources.LoadInfo) {
            this.fetchShortInfoData()
        }

        if (this.props.match.params.id) {
            this.props.dispatch(
                setTableData(
                    {
                        key: "loads",
                        data: {lastVisited: {LoadID: this.props.match.params.id}}
                    }
                )
            );
        }
    }

    componentWillUnmount() {
        // Signal that user joined load room
        this.props.dispatch(leaveLoad({
            LoadID: this.getID()
        }));

        this.props.dispatch(clearLoadUpdated());
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === Resources.LoadNotificationSettings) {
            this.fetchNotifications()
        }

        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            const initialLoadStatusID = this.isCopyMode() ? 0 : getProp(this.props.resource.data, 'load/info.LoadStatusID', 0);

            if (initialLoadStatusID) {
                this.initialLoadStatusID = initialLoadStatusID
            }

            let colorTags = this.state.loadColorTags;

            if (getProp(this.props.resource.data, 'load/info.ColorTag', null)) {
                colorTags = getProp(this.props.resource.data, 'load/info.ColorTag', "");
            }

            let LoadNumber = this.state.LoadNumber
                ?? getProp(this.props.resource.data, 'load/info.LoadNumber', undefined)
                ?? getProp(this.props.resource.data, 'info.LoadNumber', undefined)
                ?? getProp(this.props.info, 'data.LoadNumber', "");

            const isInvoiced = this.state.isInvoiced
                || getProp(this.props.resource.data, 'load/info.IsInvoiced', false)
                || getProp(this.props.resource.data, 'info.IsInvoiced');

            const isPODReady = this.state.isPODReady
                || getProp(this.props.resource.data, 'load/info.IsPODReady', false)
                || getProp(this.props.resource.data, 'info.IsPODReady');

            const billType = this.state.billType
                ?? getProp(this.props.resource.data, 'load/info.BillType', undefined)
                ?? getProp(this.props.resource.data, 'info.BillType');

            const invoiceStatus = this.state.invoiceStatus
                ?? getProp(this.props.resource.data, 'load/info.InvoiceStatus', undefined)
                ?? getProp(this.props.resource.data, 'info.InvoiceStatus', 0);

            let createdBy = {};
            if (this.state.createdBy.id) {
                createdBy = this.state.createdBy
            } else if (getProp(this.props.resource.data, 'load/info.CreatedByID', false)) {
                createdBy = {
                    id: getProp(this.props.resource.data, 'load/info.CreatedByID', 0),
                    name: getProp(this.props.resource.data, 'load/info.CreatedBy', "")
                }
            } else if (getProp(this.props.resource.data, 'info.CreatedByID', false)) {
                createdBy = {
                    id: getProp(this.props.resource.data, 'info.CreatedByID', 0),
                    name: getProp(this.props.resource.data, 'info.CreatedBy', "")
                }
            }


            const documentCounts = {
                documentsReadyCount: this.state.documentCounts?.documentsReadyCount
                    ?? getProp(this.props.resource.data, 'load/info.DocumentsReadyCount', undefined)
                    ?? getProp(this.props.resource.data, 'info.DocumentsReadyCount'),
                documentsRequiredCount: this.state.documentCounts.documentsRequiredCount
                    ?? getProp(this.props.resource.data, 'load/info.DocumentsRequiredCount', undefined)
                    ?? getProp(this.props.resource.data, 'info.DocumentsRequiredCount', 0),
            }

            this.emailTemplateQuery = {
                LoadID: this.getID(),
                CarrierID: this.emailTemplateQuery.CarrierID
                    ?? getProp(this.props.resource.data, 'load/info.CarrierID', undefined)
                    ?? getProp(this.props.resource.data, 'info.CarrierID', 0),
                DriverID: this.emailTemplateQuery.DriverID
                    ?? getProp(this.props.resource.data, 'load/info.DriverID', undefined)
                    ?? getProp(this.props.resource.data, 'info.DriverID', 0),
                CustomerID: this.emailTemplateQuery.CustomerID
                    ?? getProp(this.props.resource.data, 'load/info.CustomerID', undefined)
                    ?? getProp(this.props.resource.data, 'info.CustomerID', 0),
            }

            if (!!LoadNumber && LoadNumber !== this.state.LoadNumber) {
                this.props.dispatch(joinLoad({
                    LoadID: this.getID()
                }));
            }

            this.setState({
                loadStatusID: initialLoadStatusID,
                updateEventsTab: this.state.updateEventsTab + 1,
                loadColorTags: colorTags,
                LoadNumber: LoadNumber,
                isInvoiced: isInvoiced,
                isPODReady,
                IsTonu: this.state.IsTonu
                    ?? getProp(this.props.resource.data, 'load/info.IsTonu', undefined)
                    ?? getProp(this.props.resource.data, 'info.IsTonu', false),
                IsDropped: getProp(this.props.resource.data, 'load/info.IsDropped', undefined)
                    ?? getProp(this.props.resource.data, 'info.IsDropped', undefined)
                    ?? !!this.state.IsDropped,
                billType,
                documentCounts,
                invoiceStatus,
                createdBy
            });
        }

        if (!this.state.LoadNumber && prevProps.info.isLoading && !this.props.info.isLoading) {
            const LoadNumber = getProp(this.props.resource.data, 'load/info.LoadNumber', undefined)
                ?? getProp(this.props.resource.data, 'info.LoadNumber', undefined)
                ?? getProp(this.props.info, 'data.LoadNumber', "");

            if (LoadNumber) {
                this.setState({LoadNumber});
            }
        }

        if (!prevProps.download?.data?.id && !!this.props.download?.data?.id) {
            this.fetchInfoData();
        }

        // Delete a required document
        if (!prevProps.resource.deleteResource && this.props.resource?.deleteResource) {
            this.fetchInfoData();
        }

        if (
            !!this.props?.loadUpdate?.data?.LoadID
            &&
            (this.getID() === this.props.loadUpdate.data.LoadID)
            &&
            this.props.loadUpdate?.data?.DateTime
            &&
            (!this.state.loadUpdatedByOtherUserTime || moment(this.props.loadUpdate.data.DateTime, DEFAULT_DATABASE_DATETIME_FORMAT).unix() > this.state.loadUpdatedByOtherUserTime)
        ) {
            this.handleUpdateLoadByOtherUser();
        }

        if (!!this.props.loadPresence?.UserMessagesCount && this.props.loadPresence.UserMessagesCount !== prevProps.loadPresence?.UserMessagesCount) {
            if (this.state.activeSidebarTab !== Resources.LoadChatInternal || this.state.isSidebarVisible === false) {
                const latestMessage = this.props.loadPresence?.messages?.UserMessages[this.props.loadPresence?.messages?.UserMessages.length - 1];

                this.dispatchInternalChatNotification(latestMessage)
            }
        }

        if (!!this.props.loadPresence?.CustomerMessagesCount && this.props.loadPresence.CustomerMessagesCount !== prevProps.loadPresence?.CustomerMessagesCount) {
            if (this.state.activeSidebarTab !== Resources.LoadChatExternal || this.state.isSidebarVisible === false) {
                const latestMessage = this.props.loadPresence?.messages?.CustomerMessages[this.props.loadPresence?.messages?.CustomerMessages.length - 1];

                this.dispatchCustomerChatNotification(latestMessage)
            }
        }

        if (prevProps.dialogResource.isLoading && !this.props.dialogResource.isLoading) {
            if (this.props.dialogResource?.data?.[0]?.ColorTagID) {
                this.setState({
                    colorTags: this.props.dialogResource.data
                })
            }
        }

        if (
            !!this.props.dialogResource?.create?.id
            && this.props.dialogResource.create.id !== prevProps.dialogResource?.create?.id
            && this.props.dialogResource.resource === Resources.CustomersCommissions
        ) {
            this.props.dispatch(updateDialogResource({
                user: LocalStorage.get('user'),
                params: {
                    BookedByID: this.props.dialogResource.create.id,
                    CoveredByID: this.state.fields.CoveredByID?.value?.value,
                    id: this.getID()
                },
                resource: Resources.LoadShort,
                query: {id: this.getID()},
                piggyResource: Resources.LoadShort,
                piggyQuery: {id: this.getID()},
                errorMessage: true,
                successMessage: this.props.translate('text.booked_by_update_success')
            }));

            this.setState({
                isBookedByDialogOpen: false,
                isCoveredByDialogOpen: false,
                isCreateContactDialogOpen: false
            });
        }

        if (
            resourceIsLoaded(this.props.resource, prevProps.resource)
            && this.props.resource.resource === Resources.LoadInfo
        ) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource, 'data.load/info', {})),
                lineOfBusiness: getProp(this.props.resource, 'data.load/info.IsBroker', 0) === 0 ? 'carrier' : 'brokerage'
            });
        }

        if (
            resourceIsCreated(this.props.resource, prevProps.resource)
            || resourceIsUpdated(this.props.resource, prevProps.resource)
        ) {
            this.setState({
                isDirty: false
            })
        }

        if (
            resourceIsUpdated(this.props.dialogResource, prevProps.dialogResource)
            && this.props.dialogResource.resource === Resources.LoadShort) {
            this.setState({
                fields: this.getFields(getProp(this.props, 'dialogResource.data', {}))
            });
        }

        if (
            resourceIsLoaded(this.props.info, prevProps.info)
            && this.props.info.resource === Resources.LoadShort
        ) {
            this.setState({
                fields: this.getFields(getProp(this.props.info, 'data', {})),
                lineOfBusiness: getProp(this.props.info, 'data.IsBroker', 0) === 0 ? 'carrier' : 'brokerage',
                documentCounts: {
                    documentsReadyCount: getProp(this.props, 'info.data.DocumentsReadyCount', null),
                    documentsRequiredCount: getProp(this.props, 'info.data.DocumentsRequiredCount', null),
                }
            });
        }

        // On socket event update
        if (
            !!this.props?.loadPresence?.loadEvent?.DateTime
            && this.props?.loadPresence?.loadEvent?.DateTime !== prevProps.loadPresence?.loadEvent?.DateTime
            && this.props?.loadPresence?.loadEvent?.Metadata?.ContactID !== getUser("Contact.ContactID")
        ) {
            if (this.state.activeSidebarTab === Resources.LoadEvents) {
                this.setState({
                    updateEventsTab: this.state.updateEventsTab + 1,
                });
            }
            if (!this.state.isSidebarVisible) {
                this.props.dispatch(pushNotification({
                        title: "New event",
                        content: this.props?.loadPresence?.loadEvent?.Metadata.EventKey,
                        side: {
                            buttons: (it) => [
                                {
                                    label: "Check events",
                                    className: "notification-button",
                                    onClick: () => {
                                        this.setState({
                                            isSidebarVisible: true,
                                            activeSidebarTab: Resources.LoadEvents
                                        })
                                        this.props.dispatch(closeNotification(it))
                                    }
                                }
                            ]
                        },
                        timeout: 10000
                    }
                ));
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    fetchLoadTags = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    fetchShortInfoData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadShort,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchInfoData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadInfo,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchNotifications = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadNotificationSettings,
            query: {LoadID: this.getID()}
        }))
    }

    createTag = (label, color) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {ColorTag: label + ',' + color},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true,
            successMessage: this.props.translate('text.tag_created'),
        }))
    }

    handleClearBookedByClick = () => {
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {
                BookedByID: "",
                CoveredByID: this.state.fields.CoveredByID?.value?.value,
                id: this.getID()
            },
            resource: Resources.LoadShort,
            query: {id: this.getID()},
            piggyResource: Resources.LoadShort,
            piggyQuery: {id: this.getID()},
            errorMessage: true,
            successMessage: this.props.translate('text.booked_by_update_success')
        }));
    }

    handleClearCoveredByClick = () => {
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {
                CoveredByID: "",
                BookedByID: this.state.fields.BookedByID?.value?.value,
                id: this.getID()
            },
            resource: Resources.LoadShort,
            query: {id: this.getID()},
            piggyResource: Resources.LoadShort,
            piggyQuery: {id: this.getID()},
            errorMessage: true,
            successMessage: this.props.translate('text.booked_by_update_success')
        }));
    }

    submitContactForm = (params, file) => {
        params.id = this.state.fields.CustomerID?.value?.value;
        params.ExcludeOrganizationID = this.state.fields.CustomerID?.value?.value;

        this.props.dispatch(createDialogResource({
            user: getUser(),
            params: params,
            errorMessage: true,
            successMessage: this.props.translate('message.ContactCreatedSuccessfully'),
            query: LocalStorage.get("contactFormQuery") ?? {},
            resource: Resources.CustomersCommissions,
            file: !!file && [file],
            fileResource: Resources.ContactImage,
        }));
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = this.state.fields

        fields = FieldsManager.updateField(fields, name, value);

        this.setState({
            fields
        });
    }

    handleChoseCoveredByClick = () => {
        this.setState({
            isCoveredByDialogOpen: !this.state.isCoveredByDialogOpen
        })
    }

    handleChoseBookedByClick = () => {
        this.setState({
            isBookedByDialogOpen: !this.state.isBookedByDialogOpen
        })
    }

    handleCreateContactClick = () => {
        this.setState({
            isCreateContactDialogOpen: !this.state.isCreateContactDialogOpen
        })
    }

    changeRouteStatus = (route) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                id: this.getID()
            },
            query: {
                id: this.getID()
            },
            resource: route,
            piggyResource: Resources.LoadInfo,
            errorMessage: true, successMessage: `Load status updated`
        }))
    }

    dirtyStateToggle = () => {
        this.setState({isDirty: !this.state.isDirty})
    }

    setIsDirty = () => {
        this.setState({isDirty: true}, () => {
            // TODO: Should this fire only once?
            this.props.dispatch(editLoad({
                LoadID: this.getID()
            }));
        })
    }

    newResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    toggleResourceTasks = () => {
        this.setState({
            isResourceTaskModalVisible: !this.state.isResourceTaskModalVisible
        })
    }

    remountInfoTab = () => {
        this.setState({
            remountComponentKey: (this.state.remountComponentKey + 1),
            isDirty: false
        })
    }

    isCopyMode = () => {
        return this.props.match.path.includes('copy')
    }

    getNotifications = () => {
        return {
            ContactID: new Field('ContactID', '', []),
            ContactIDs: new Field('ContactIDs', '', []),
            CustomerLoadBooked: new Field('CustomerLoadBooked', '', [], false, 'checkbox'),
            CustomerPickupComplete: new Field('CustomerPickupComplete', '', [], false, 'checkbox'),
            CustomerDeliveryComplete: new Field('CustomerDeliveryComplete', '', [], false, 'checkbox'),
            EDI: new Field('EDI', '', [], false, 'checkbox'),
            ShipperDeliveryComplete: new Field('ShipperDeliveryComplete', '', [], false, 'checkbox'),
            ConsigneePickupComplete: new Field('ConsigneePickupComplete', '', [], false, 'checkbox'),
            ShipperTruckRadiusCheckbox: new Field('ShipperTruckRadiusCheckbox', '', [], false, 'checkbox'),
            ShipperTruckRadius: new Field('ShipperTruckRadius', '', ['integer_not_require']),
            ConsigneeTruckRadius: new Field('ConsigneeTruckRadius', '', ['integer_not_require']),
            ConsigneeTruckRadiusCheckbox: new Field('ConsigneeTruckRadiusCheckbox', '', [], false, 'checkbox'),
            SendCustomerNotifications: new Field('SendCustomerNotifications', '', [], false, 'checkbox'),
        }
    }

    toggleSection = (sectionName) => {
        let sections = this.state.sections
        let section = Object.assign({}, sections[sectionName])
        section.isOpen = !section.isOpen
        sections[sectionName] = section

        this.setState({
            sections: sections
        })
    }

    handleTabChange = (resource) => {
        if (this.state.isDirty) {
            this.setState({
                selectedTabResource: resource,
                isConfirmModalOpen: true
            })
        } else {
            this.updateSelectedTab(resource);
        }
    }

    updateSelectedTab = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleTagLoad = (selectedLabels) => {
        const IDs = [this.getID()]
        const ColorTag = selectedLabels.filter(it => it.checked).reduce((memo, it, index) => {
            memo = memo + (index ? '|' : '') + it.label + ',' + it.color
            return memo
        }, '')

        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {IDs, ColorTag},
            resource: Resources.LoadColor,
            query: {},
            errorMessage: true,
            successMessage: this.props.translate('text.tags_applied'),
        }));

        this.setState({
            loadColorTags: ColorTag
        })

        this.tagPopoverButtonRef.current.click();
    }

    toggleTagManagerModal = () => {
        this.setState({isTagManagerModalOpen: !this.state.isTagManagerModalOpen})
    }

    colorPicker = () => {
        return COLOR_PICKER_COLOR.map((it, i) => (
                <button
                    key={i}
                    onClick={() => this.setState({tagColor: it})}
                    className={classNames(
                        'w-7 h-7 rounded-full ring-offset-inverse ring-link focus:ring-2 focus:ring-offset-2 flex items-center justify-center',
                    )}
                    style={{background: it}}
                >
                    {it === this.state.tagColor && (
                        <CheckIcon className="w-5 h-5 text-white"/>
                    )}
                </button>
            )
        )
    }

    deleteLabels = () => {
        const ColorTag = Object.keys(this.state.selectedLabels).reduce((memo, it) => {
            memo.push(it)
            return memo
        }, [])

        this.setState({
            selectedLabels: {}
        }, () => {
            this.props.dispatch(deleteDialogResource({
                user: LocalStorage.get('user'),
                query: {ColorTagIDs: ColorTag},
                resource: Resources.ColorTag,
                piggyResource: Resources.ColorTag,
                errorMessage: true, successMessage: this.props.translate('text.selected_tags_deleted'),
            }));
        })
    }

    handleTagCheckboxChange = (e) => {
        const id = e.target.getAttribute("name");
        let selectedLabels = this.state.selectedLabels;
        if (selectedLabels[id]) {
            delete selectedLabels[id];
        } else {
            selectedLabels = Object.assign({}, selectedLabels, {[id]: id})
        }

        this.setState({
            selectedLabels
        })
    }

    handleUpdateLoadByOtherUser = () => {
        if (this.props.loadUpdate?.data?.Contact.ContactID === this.ContactID) {
            return null;
        }

        this.setState({
            isLoadUpdatedByOtherUserModalOpen: true,
            isLoadUpdatedByOtherUser: true,
            loadUpdatedByOtherUserTime: moment(this.props.loadUpdate.data.DateTime, DEFAULT_DATABASE_DATETIME_FORMAT).unix()
        });
    }

    toggleUpdatedByOtherUserModal = () => {
        this.setState({
            isLoadUpdatedByOtherUserModalOpen: !this.state.isLoadUpdatedByOtherUserModalOpen,
        })
    }

    dispatchInternalChatNotification = (message) => {
        this.props.dispatch(pushNotification({
                title: message.LoadID + ": " + message.Contact,
                content: message.Message,
                onNotificationClick: () => this.toggleInternalChat(),
                side: {
                    buttons: (it) => [
                        {
                            label: "Reply",
                            className: "notification-button",
                            onClick: () => {
                                this.toggleInternalChat();
                                this.props.dispatch(closeNotification(it))
                            }
                        }
                    ]
                },
                messageType: "avatar",
                contactID: message.ContactID,
                timeout: 10000
            }
        ));
    }

    dispatchCustomerChatNotification = (message) => {
        this.props.dispatch(pushNotification({
                title: message.Name,
                content: message.Message,
                onNotificationClick: () => this.toggleInternalChat(),
                side: {
                    buttons: (it) => [
                        {
                            label: "Reply",
                            className: "notification-button",
                            onClick: () => {
                                this.toggleCustomerChat();
                                this.props.dispatch(closeNotification(it))
                            }
                        }
                    ]
                },
                messageType: "avatar",
                contactID: message.ContactID,
                timeout: 10000
            }
        ));
    }

    /** Helpers
     ================================================================= */
    toggleInternalChat = () => {
        this.setState({
            isSidebarVisible: true,
            activeSidebarTab: Resources.LoadChatInternal
        })
    }

    toggleCustomerChat = () => {
        this.setState({
            isSidebarVisible: true,
            activeSidebarTab: Resources.LoadChatExternal
        })
    }

    getID = () => {
        return this.props.match.params.id
    }

    refreshPage = () => {
        this.props.history.go(0);
    }

    handleLayoutScroll = () => {
        if (this.layoutRef?.current.scrollTop > 100 && !this.state.isLayoutScrolled) {
            this.setState({
                isLayoutScrolled: true
            })
        }

        if (this.layoutRef?.current.scrollTop <= 100 && this.state.isLayoutScrolled) {
            this.setState({
                isLayoutScrolled: false
            })
        }
    }

    handleScrollLayoutOnTop = () => {
        if (this.layoutRef?.current) {
            this.layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    getCoveredByMessage = () => {
        let messages = [];
        if (this.state.lineOfBusiness !== 'brokerage') {
            if (this.state.fields.DriverID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.covered_by_carrier_driver_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/drivers/info/"
                        + this.state.fields.DriverID?.value?.value
                        + "?tab=drivers/contacts"
                    )
                });
            }
        }

        return messages
    }

    getBookedByMessage = () => {
        let messages = [];
        if (this.state.lineOfBusiness !== 'brokerage') {
            if (this.state.fields.DriverID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.booked_by_carrier_driver_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/drivers/info/"
                        + this.state.fields.DriverID?.value?.value
                        + "?tab=drivers/contacts"
                    )
                });
            }

            if (this.state.fields.CustomerID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.booked_by_carrier_customer_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/customers/info/"
                        + this.state.fields.CustomerID?.value?.metadata?.OrganizationID
                        + "/"
                        + this.state.fields.CustomerID?.value?.value
                        + "?tab=customers/comissions&customer=" + this.state.fields.CustomerID?.value?.label
                    )
                });
            }
        }

        if (this.state.lineOfBusiness === 'brokerage') {
            if (this.state.fields.CustomerID?.value?.value) {
                messages.push({
                    content:
                        <div className="flex w-full">
                            {this.props.translate("text.booked_by_carrier_customer_message")}

                            <div className="ml-auto text-sky-600">
                                <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                            </div>
                        </div>
                    ,
                    handler: () => openInNewTab("/customers/info/"
                        + this.state.fields.CustomerID?.value?.metadata?.OrganizationID
                        + "/"
                        + this.state.fields.CustomerID?.value?.value
                        + "?tab=customers/comissions&customer=" + this.state.fields.CustomerID?.value?.label
                    )
                });
            }
        }

        return messages;
    }

    getBookedByQuery = () => {
        if (this.state.fields.CustomerID?.value?.value && this.state.lineOfBusiness === 'brokerage') {
            return {SelectCommisionID: this.state.fields.CustomerID.value.value}
        }

        if (this.state.lineOfBusiness !== 'brokerage') {
            return {
                SelectDriverOrID: this.state.fields.DriverID?.value?.value,
                SelectCommisionOrID: this.state.fields.CustomerID?.value?.value
            }
        }

        return null
    }

    getCoveredByQuery = () => {
        if (this.state.fields.DriverID?.value?.value && this.state.lineOfBusiness !== 'brokerage') {
            return {SelectDriverID: this.state.fields.DriverID.value.value}
        }

        if (this.state.lineOfBusiness === 'brokerage') {
            return {}
        }

        return null
    }

    getFields = (item = {}) => {
        return {
            BookedByID: new Field('BookedByID', {value: item.BookedByID, label: item.BookedBy}, [""], false),
            CoveredByID: new Field('CoveredByID', {value: item.CoveredByID, label: item.CoveredBy}, [""], false),
            DriverID: new Field('DriverID', {
                value: item.DriverID,
                label: item.Driver,
                metadata: {ContactID: getProp(item, 'DriverContactID', '')},
            }, [], false, 'select-search', {}, {isClearable: true}),
            CustomerID: new Field('CustomerID', {
                value: item.CustomerID,
                label: item.Customer,
                metadata: item?.CustomerInfo ?? {}
            }, [], false, 'select-search', {}, {isClearable: true}),
            OfficeID: new Field('OfficeID', {value: item.OfficeID, label: item.OfficeName}, [""], false),
        }
    };

    setDoesEmailExistsError = (bool) => {
        this.setState({
            doesEmailExistsError: bool
        })
    }

    handlePrintBOLClick = (LoadCommodityID) => {
        this.setState({
            printLoadCommodityID: LoadCommodityID
        })
    }

    clearPrintLoadCommodityID = () => {
        this.setState({
            printLoadCommodityID: 0
        })
    }

    getBackPath = () => {
        return this.prevPageIsSplitLoad
            ? '/loads-split'
            : '/loads'
    }

    render() {
        const {translate, sidebarResource} = this.props;
        const {IsTonu, isInvoiced, isPODReady, isLayoutScrolled, documentCounts} = this.state;
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0);
        const openTaskCountTwoDigits = TaskCount > 9;
        const editLoadMode = !!this.getID() && !this.isCopyMode();
        const info = getProp(sidebarResource.data, 'load/info', {});
        const IsLoadCanceled = getProp(this.props.resource.data, 'load/info.LoadStatusID', 0) === LOAD_STATUS_CANCELED;
        const isPageLoading = !!this.props.resource?.isLoading || !!this.props.secondResource?.isLoading || this.props.dialogResource?.isLoading || !!this.props.info?.isLoading;
        const contacts = this.props.loadPresence?.data?.Contacts ?? {};
        const documentsReadyCount = documentCounts?.documentsReadyCount;
        const documentsRequiredCount = documentCounts?.documentsRequiredCount;

        const notificationsList = getProp(this.props.notifications, 'data.notifications.list', [])

        const tailgateParentID = getProp(this.props.resource.data, 'load/info.ParentLoadID', 0);

        const isSendWithInvoice = getProp(this.props.resource, 'data.customer.IsSendBackupDocumentsWithInvoice', 0)

        // Important note: Commodities can't be checked if load is opened in pricing tab (no commodity data in loadShort)
        const hasCommodities = !!getProp(this.props, 'resource.data.load/commodities', []).length;

        let userEditingLoad;
        const usersOnPage = {
            totalUsers: Object.keys(contacts)?.length - 1 ?? 0,
            users: contacts ? Object.values(contacts).filter(it => Number(this.ContactID) !== Number(it.ContactID)).map((it) => {
                if (it.IsEditing) {
                    userEditingLoad = it;
                }
                return {
                    name: it.Name,
                    isEditing: it.IsEditing,
                    handle: it.ContactID,
                    hasImage: true
                }
            }) : []
        }

        return (
            <Layout
                {...this.props}
                hasFooter={this.state.selectedTab === Resources.LoadInfo || this.state.selectedTab === Resources.LoadPricing}
                onScroll={this.handleLayoutScroll}
                layoutRef={this.layoutRef}
                isDirty={this.state.isDirty}
                isAccessible={!editLoadMode || this.state.LoadNumber}
                notFoundBackLink={"/loads"}
                notFoundBackBtnLabel={translate("text.back_to_list")}
                customPageTitle={this.state.LoadNumber ? `#${this.state.LoadNumber}` : 'Load'}
            >
                <Page
                    replaceClass="flex flex-col space-y-2 min-h-full"
                    className={this.state.isSidebarVisible ? 'transition-all pr-[25.5rem] -mr-6' : null}
                >
                    <div className="relative grid xl:grid-cols-3 gap-x-4">
                        <div className="flex">
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center">
                                    <GoBackButton
                                        translate={this.props.translate}
                                        history={this.props.history}
                                        path={this.goBackPath}
                                    />

                                    <h1 className={'mr-5 text-3xl ml-2 text-tm-gray-900'}>
                                        {this.isCopyMode() ? translate('text.copy_load') + ": " : ""}
                                        {editLoadMode ? translate('text.Load') + " - " : ""}
                                        {!!this.getID() && (
                                            <span className="text-tm-gray-700">
                                                {this.state.LoadNumber}

                                                {isPageLoading && !this.state.LoadNumber && (
                                                    <LoaderSmall disableContainer addClass={'inline'}/>
                                                )}

                                                {info.ArchivedDate && (
                                                    <Tippy content={<span>Archived</span>}>
                                                        <i className="iconsminds-delete-file"/>
                                                    </Tippy>
                                                )}
                                            </span>
                                        )}

                                        {!editLoadMode && !this.isCopyMode() && translate("text.create_new_load")}
                                    </h1>
                                </div>
                            </div>
                        </div>

                        {/* Send documents */}
                        {!!editLoadMode && (
                            <div
                                className={classNames("flex justify-end items-center col-span-2", !!isPageLoading && "invisible")}
                            >
                                <SendLoadDocuments
                                    initialLoadStatusID={this.state.initialLoadStatusID}
                                    loadID={this.getID()}
                                    loadInfo={
                                        getProp(this.props, 'resource.data.load/info', undefined) ??
                                        getProp(this.props, 'info.data', {})
                                    }
                                    hasCommodities={hasCommodities}
                                    isLoading={isPageLoading}
                                    translate={translate}
                                    isStateDirty={this.state.isDirty}
                                    lineOfBusiness={this.state.lineOfBusiness}
                                    thirdResource={this.props.thirdResource}
                                    clearPrintLoadCommodityID={this.clearPrintLoadCommodityID}
                                    printLoadCommodityID={this.state.printLoadCommodityID}
                                    hasDocumentSetting={true}
                                />

                                {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                    <div className="btn btn-outline-secondary bg-inverse mr-3 ml-3"
                                         onClick={() => this.newResourceTask()}>{translate('btn.new_load_task')}
                                    </div>
                                )}

                                {checkPerm(Resources.Tasks, READ_PERM) && (
                                    <div className="relative">
                                        <button
                                            className="btn btn-outline-secondary bg-inverse mr-3"
                                            disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                            onClick={() => this.toggleResourceTasks()}>

                                            {TaskCount > 0 && (
                                                <span
                                                    className={
                                                        classNames(
                                                            "absolute z-10 flex -top-2.5 -left-2",
                                                            openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                        )
                                                    }
                                                >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                            )}
                                            {translate('btn.show_tasks')}
                                            {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                            {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                        </button>
                                    </div>
                                )}

                                {!this.state.isSidebarVisible && (
                                    <button
                                        className="btn btn-outline ml-3"
                                        onClick={() => this.setState({isSidebarVisible: !this.state.isSidebarVisible})}
                                    >
                                        <Bars3BottomRightIcon className="h-5 w-5"/>
                                    </button>
                                )}
                            </div>
                        )}

                        {!!editLoadMode && isPageLoading && (
                            <div className="h-8 mt-4 pl-2 flex items-center">
                                <LoaderSmall disableContainer/>
                            </div>
                        )}

                        {!!editLoadMode && !isPageLoading && (
                            <div
                                className="xl:col-span-full col-span-2 sm:flex sm:space-x-8 space-y-2 sm:space-y-0 pl-2 mt-4"
                            >
                                {!!IsTonu && (
                                    <div className="flex flex-shrink-0 items-center space-x-2">
                                        <ExclamationTriangleIcon
                                            className='w-5 h-5 text-red-600'
                                        />

                                        <span className='text-sm font-semibol text-red-600'>
                                            {translate('text.load_is_tonu')}  
                                        </span>
                                    </div>
                                )}

                                <LoadInvoicedButton
                                    isInvoiced={isInvoiced}
                                    LoadID={this.getID()}
                                    invoiceStatus={this.state.invoiceStatus}
                                    billType={this.state.billType}
                                    translate={translate}
                                />

                                {!!documentsRequiredCount && (
                                    <div className="flex flex-shrink-0 items-center space-x-2">
                                        <DocumentTextIcon
                                            className={classNames('w-5 h-5', isPODReady ? 'text-green-600' : 'text-tm-gray-400')}/>

                                        <span
                                            className={classNames('text-sm font-semibold', isPODReady ? 'text-green-600' : 'text-tm-gray-500')}
                                        >
                                            {isPODReady ? translate('text.backup_docs_ready') : translate('text.backup_docs_not_ready')}
                                            {' '}
                                            ({translate("text.documents_ready", [documentsReadyCount, documentsRequiredCount])})
                                        </span>
                                    </div>
                                )}

                                <div className="flex items-center space-x-0.5">
                                    {!!tailgateParentID && (
                                        <PageResourceTag
                                            onClick={() => {
                                                openInNewTab("/loads/info/" + tailgateParentID)
                                            }}
                                        >
                                            <TruckIcon className="w-5 h-5"/>
                                            <p>Tailgate</p>
                                        </PageResourceTag>
                                    )}

                                    {!!this.state.IsDropped && (
                                        <PageResourceTag
                                            bgClass="bg-yellow-600"
                                        >
                                            <PauseCircleIcon className="w-5 h-5"/>
                                            <p>Dropped</p>
                                        </PageResourceTag>
                                    )}

                                    <ResourceTableTags data={this.state.loadColorTags} addClass="py-1"/>
                                </div>

                                <div className="col-span-8 flex items-center gap-4 w-full justify-end">
                                    <ContactSelectButton
                                        readOnly={false}
                                        isDisabled={this.state.isDirty}
                                        id={this.state.fields?.BookedByID?.value?.value}
                                        name={this.state.fields?.BookedByID?.value?.label}
                                        hasImagePath={!!this.state.fields?.BookedByID?.value?.value}
                                        text={translate("text.booked_by")}
                                        onChoseContactClick={this.handleChoseBookedByClick}
                                        onClearContactClick={this.handleClearBookedByClick}
                                        onAvatarClick={() => this.props.dispatch(
                                            showModal(
                                                'ViewContactCard',
                                                {ContactID: this.state.fields?.BookedByID?.value?.value})
                                        )}
                                        isLoading={isPageLoading}
                                        translate={this.props.translate}
                                    />

                                    {!this.isCopyMode() && !!editLoadMode && (
                                        <ContactSelectButton
                                            readOnly={false}
                                            isDisabled={this.state.isDirty}
                                            id={this.state.fields?.CoveredByID?.value?.value}
                                            name={this.state.fields?.CoveredByID?.value?.label}
                                            hasImagePath={!!this.state.fields?.CoveredByID?.value?.value}
                                            text={translate("text.covered_by")}
                                            onChoseContactClick={this.handleChoseCoveredByClick}
                                            onClearContactClick={this.handleClearCoveredByClick}
                                            onAvatarClick={() => this.props.dispatch(
                                                showModal(
                                                    'ViewContactCard',
                                                    {ContactID: this.state.fields?.CoveredByID?.value?.value})
                                            )}
                                            isLoading={isPageLoading}
                                            translate={this.props.translate}
                                        />
                                    )}

                                    {!!this.state.createdBy?.id && (
                                        <ContactSelectButton
                                            readOnly={true}
                                            isDisabled={true}
                                            id={this.state.createdBy.id}
                                            name={this.state.createdBy?.name}
                                            hasImagePath={this.state.createdBy.id}
                                            text={translate("text.created_by")}
                                            onAvatarClick={() => this.props.dispatch(
                                                showModal(
                                                    'ViewContactCard',
                                                    {ContactID: this.state.createdBy.id})
                                            )}
                                            isLoading={isPageLoading}
                                            translate={this.props.translate}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {!!isPageLoading && (
                            <LoaderSmall disableContainer addClass={"absolute right-0 mt-4 mr-10"}/>
                        )}
                    </div>

                    {(this.state.isLoadUpdatedByOtherUser || !!usersOnPage.users.length && editLoadMode) && (
                        <StickyElement
                            className="-top-5 z-30"
                            stickyClass="pt-3 pb-2 bg-tm-gray-100 -mx-4 px-4 md:-mx-4 md:px-4 border-b border-dashed border-tm-gray-300"
                        >
                            <div className="flex items-center relative gap-x-4">
                                {!!userEditingLoad && !this.state.isLoadUpdatedByOtherUser && (
                                    <InfoParagraph>
                                        <p className="font-bold leading-4">{translate("text.is_currently_editing", [userEditingLoad.Name])}</p>
                                        <p className="leading-4">Last person who saves the load will overwrite other
                                            user
                                            changes.</p>
                                    </InfoParagraph>
                                )}

                                {this.state.isLoadUpdatedByOtherUser && (
                                    <div className="mr-auto">
                                        <InfoParagraph className="rounded-xl overflow-hidden h-12" type="warning">
                                            <div className="flex items-center gap-x-3">
                                                <p>Load is updated by other user.</p>
                                                <button
                                                    className="btn btn-outline bg-inverse py-0.5"
                                                    onClick={() => {
                                                        this.setState({isLoadUpdatedByOtherUserModalOpen: true})
                                                    }}
                                                >
                                                    Take action
                                                </button>
                                            </div>
                                        </InfoParagraph>
                                    </div>
                                )}

                                {!!usersOnPage.users.length && (
                                    <div className="flex items-center space-x-2 relative pl-2 ml-auto">
                                    <span
                                        className="font-semibold text-tm-gray-700 "
                                    >
                                        {translate("text.also_on_page")}:
                                    </span>

                                        <div className="flex flex-shrink-0 -space-x-1 h-8">
                                            {usersOnPage.users.filter(user => Number(user.handle) !== Number(this.ContactID)).map((user, index) => {
                                                return (
                                                    <div
                                                        key={this.ContactID + index}
                                                        className={classNames(
                                                            "relative",
                                                            user?.isEditing ? "ring-2 ring-link ring-offset-2 ring-offset-inverse rounded-full z-10 w-8 h-8" : undefined
                                                        )}>
                                                        <ImageAvatarLoader
                                                            addClass="bg-tm-gray-100 rounded-full"
                                                            ContactID={user.handle}
                                                            size="sm"
                                                            hasImagePath={true}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {usersOnPage.totalUsers > usersOnPage.users.length ? (
                                            <span
                                                className="flex-shrink-0 text-xs leading-5 font-medium text-tm-gray-700 ">
                                        +{usersOnPage.totalUsers - usersOnPage.users.length}
                                      </span>
                                        ) : null}

                                        <PopOver
                                            PopoverButtonRef={this.PopoverButtonRef}
                                            className="absolute -inset-2"
                                            btnClass="absolute inset-0 w-full focus:outline-none focus-visible:ring-2 rounded-full"
                                            widthClass="lg:max-w-sm"
                                            positionClass="absolute translate-x-0 right-0 top-5"
                                            tippy={translate('text.click_to_view_details')}
                                        >
                                            <ul role="list"
                                                className="-my-5 divide-y divide-tm-gray-300 rounded-lg bg-popup">
                                                {usersOnPage.users.filter(user => Number(user.handle) !== Number(this.ContactID)).map((user) => (
                                                    <li key={user.handle} className="py-4 px-6 mb-0">
                                                        <div className="flex items-center space-x-4">
                                                            <div className="flex-shrink-0">
                                                                <ImageAvatarLoader
                                                                    addClass="bg-tm-gray-100 rounded-full"
                                                                    ContactID={user.handle}
                                                                    size="sm"
                                                                    hasImagePath={user.hasImage}
                                                                />
                                                            </div>
                                                            <div className="flex-1 min-w-0">
                                                                <button
                                                                    onClick={() => {
                                                                        !!this.PopoverButtonRef.current && this.PopoverButtonRef.current.click();
                                                                        setTimeout(() => {
                                                                            this.props.dispatch(
                                                                                showModal('ViewContactCard', {ContactID: user.handle})
                                                                            )
                                                                        }, 200);
                                                                    }}
                                                                    className="btn btn-text text-sm font-medium text-primary truncate"
                                                                >
                                                                    {user.name}
                                                                </button>

                                                                {user.isEditing && (
                                                                    <p className="text-primary font-bold">Editing this
                                                                        load!</p>
                                                                )}
                                                            </div>


                                                            <div>
                                                                <button
                                                                    onClick={() => {
                                                                        !!this.PopoverButtonRef.current && this.PopoverButtonRef.current.click();
                                                                        setTimeout(this.toggleInternalChat, 200);
                                                                    }}
                                                                    className="btn btn-outline-secondary"
                                                                >
                                                                    Message
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </PopOver>
                                    </div>
                                )}
                            </div>
                        </StickyElement>
                    )}

                    {editLoadMode && (
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                            disabled={!(!editLoadMode || this.state.LoadNumber)}
                        />
                    )}

                    <div className="tab-content">
                        <div className="tab-pane show active">
                            <div className="">
                                <div
                                    className="">
                                    {this.state.selectedTab === Resources.LoadInfo && (
                                        <LoadInfoTab
                                            key={this.state.remountComponentKey}
                                            LoadNumber={this.state.LoadNumber}
                                            IsDropped={this.state.IsDropped}
                                            setDirty={this.setIsDirty}
                                            onToggleSection={this.toggleSection}
                                            sections={this.state.sections}
                                            dirtyStateToggle={this.dirtyStateToggle}
                                            isStateDirty={this.state.isDirty}
                                            isCopyMode={this.isCopyMode()}
                                            isEditMode={editLoadMode}
                                            remountInfoTab={this.remountInfoTab}
                                            onTabChange={this.handleTabChange}
                                            colorTags={this.state.colorTags}
                                            loadColorTags={this.state.loadColorTags}
                                            onTagLoad={this.handleTagLoad}
                                            toggleTagManagerModal={this.toggleTagManagerModal}
                                            tagPopoverButtonRef={this.tagPopoverButtonRef}
                                            isLayoutScrolled={isLayoutScrolled}
                                            scrollLayoutOnTop={this.handleScrollLayoutOnTop}
                                            onPrintBOLClick={this.handlePrintBOLClick}
                                            lastNotification={notificationsList[notificationsList.length - 1]}
                                            {...this.props}
                                        />
                                    )}

                                    {this.state.selectedTab === Resources.LoadPricing && (
                                        <LoadPricingTab
                                            loadID={this.getID()}
                                            fetchInfoData={this.fetchShortInfoData}
                                            CustomerID={this.state.CustomerID}
                                            setDirty={this.setIsDirty}
                                            dirtyStateToggle={this.dirtyStateToggle}
                                            isSidebarVisible={this.state.isSidebarVisible}
                                            isStateDirty={this.state.isDirty}
                                            isInvoiced={this.state.isInvoiced}
                                            {...this.props}
                                        />
                                    )}


                                    {this.state.selectedTab === Resources.LoadTracks && (
                                        <TracksTab
                                            loadID={this.getID()}
                                            fetchInfoData={this.fetchShortInfoData}
                                            {...this.props}
                                        />
                                    )}

                                    {this.state.selectedTab === Resources.LoadDocuments && (
                                        <DocumentsUpdateTab
                                            id={this.getID()}
                                            fetchInfoData={this.fetchShortInfoData}
                                            info={this.props.info}
                                            dispatch={this.props.dispatch}
                                            translate={this.props.translate}
                                            resource={this.props.resource}
                                            thirdResource={this.props.thirdResource}
                                            dialogTitle={'Load Documents'}

                                            sortBy={'Description'}
                                            primaryKey={'DocumentID'}

                                            download={this.props.download}
                                            resourceName={Resources.LoadDocuments}
                                            documentTypes={getDocumentTypesFor("IsDispatchDocument")}
                                            additionalTableFields={{
                                                IsSendWithInvoice: new Field("IsSendWithInvoice", '', [], false, 'checkbox'),
                                                IsVisibleForCustomer: new Field("IsVisibleForCustomer", '', [], false, 'checkbox'),
                                            }}

                                            documentUpdateFields={checkPerm(Resources.LoadDocuments, CREATE_PERM) && {
                                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                                    values: getDocumentTypesFor("IsDispatchDocument")
                                                }),
                                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {
                                                    isClearable: true
                                                }),
                                                IsSendWithInvoice: new Field("IsSendWithInvoice", '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
                                                IsVisibleForCustomer: new Field("IsVisibleForCustomer", '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
                                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                            }}
                                            documentUploadFields={(index = 0) => {
                                                return Object.assign({}, {
                                                    DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {}, {
                                                        values: getDocumentTypesFor("IsDispatchDocument")
                                                    }),
                                                    ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {}),
                                                    IsSendWithInvoice: new Field("IsSendWithInvoice", isSendWithInvoice, [], false, 'checkbox', {}, {
                                                        htmlFor: `IsSendWithInvoice${index}`,
                                                        id: `IsSendWithInvoice${index}`,
                                                    }),
                                                    IsVisibleForCustomer: new Field("IsVisibleForCustomer", isSendWithInvoice, [], false, 'checkbox', {}, {
                                                        htmlFor: `IsVisibleForCustomer${index}`,
                                                        id: `IsVisibleForCustomer${index}`,
                                                    }),
                                                    Description: new Field("Description", '', [], false, 'textarea', {}),
                                                })
                                            }}
                                            readOnly={!checkPerm(Resources.LoadDocuments, CREATE_PERM)}
                                            createOnly={!checkPerm(Resources.LoadDocuments, READ_PERM) && checkPerm(Resources.LoadDocuments, CREATE_PERM)}
                                            disableDelete={!checkPerm(Resources.LoadDocuments, DELETE_PERM)}

                                            searchFields={{
                                                query: new Field('query', '', [], false, 'text', {
                                                    containerClass: 'col-md-4'
                                                }),
                                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                                    containerClass: 'col-md-4'
                                                }, {
                                                    all: true,
                                                    values: getDocumentTypesFor("IsDispatchDocument")
                                                }),
                                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                                    containerClass: 'col-md-4'
                                                }, {
                                                    all: true
                                                }),
                                                IsSendWithInvoice: new Field("IsSendWithInvoice", '', [], false, 'checkbox'),
                                                IsVisibleForCustomer: new Field("IsVisibleForCustomer", '', [], false, 'checkbox'),
                                            }}

                                            fetchEmailTemplateQuery={this.emailTemplateQuery}
                                        />
                                    )}

                                    {this.state.selectedTab === Resources.LoadWatchers && (
                                        <WatchersTab
                                            loadID={this.getID()}
                                            fetchInfoData={this.fetchShortInfoData}
                                            limit={this.state.limit}
                                            offset={this.state.offset}
                                            paginationPage={this.state.paginationPage}
                                            {...this.props}
                                        />
                                    )}

                                    {this.state.selectedTab === Resources.LoadClaims && (
                                        <ClaimsTab
                                            loadID={this.getID()}
                                            LoadNumber={this.state.LoadNumber}
                                            fetchInfoData={this.fetchShortInfoData}
                                            loadInfo={info}
                                            {...this.props}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>

                <aside
                    className={classNames(
                        'custom-sidebar z-30',
                        this.state.isSidebarVisible
                            ? 'translate-x-0'
                            : 'translate-x-full',
                        'transition-all transform bg-tm-gray-50 border-tm-gray-300 border-l fixed top-16 right-0 w-96 app-body-height-footer'
                    )}
                >
                    <div className="p-6">
                        <div className="flex items-start justify-between">
                            <h2 className="text-lg font-medium text-tm-gray-900">
                                {translate("text.load_sidebar")}
                            </h2>

                            <div className="ml-3 h-7 flex items-center">
                                <button
                                    type="button"
                                    className="btn btn-close"
                                    onClick={() => this.setState({isSidebarVisible: !this.state.isSidebarVisible})}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-tm-gray-200">
                        <div className="px-6">
                            <nav className="-mb-px flex space-x-6">
                                {this.sidebarTabs.map((tab) => (
                                    <span
                                        key={tab.name}
                                        onClick={() => this.setState({activeSidebarTab: tab.resource})}
                                        className={classNames(
                                            this.state.activeSidebarTab === tab.resource
                                                ? 'border-primary text-primary'
                                                : 'border-transparent text-tm-gray-600 hover:text-tm-gray-500 hover:border-tm-gray-300',
                                            'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                        )}
                                    >
                                        {tab.name}
                                    </span>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {this.state.isSidebarVisible && editLoadMode && this.state.activeSidebarTab === Resources.LoadEvents && (
                        <div className="h-[calc(100vh-243px)]">
                            <EventsTab
                                key={this.state.updateEventsTab}
                                loadID={this.props?.match?.params?.id}
                                translate={translate}
                            />
                        </div>
                    )}

                    {this.state.activeSidebarTab === Resources.LoadChatInternal && (
                        <LoadInternalChatTab
                            dispatch={this.props.dispatch}
                            resource={this.props.resource}
                            loadID={this.getID()}
                            translate={translate}
                            loadPresence={cloneDeep(this.props.loadPresence)}
                        />
                    )}

                    {this.state.activeSidebarTab === Resources.LoadChatExternal && (
                        <LoadExternalChatTab
                            loadID={this.getID()}
                            {...this.props}
                        />
                    )}
                </aside>

                {this.props.ui.modalName === 'CheckLoadTasks' && (
                    <ResourceTasksDialog
                        close={this.hideConfirmCheckLoadTasksHandler}
                        {...this.props}
                        large={true}
                    />
                )}

                <ModalDefault
                    show={!!this.state.isResourceTaskModalVisible}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.load_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.toggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.toggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>

                <ModalConfirm
                    show={!!this.state.isConfirmModalOpen}
                    width="md"
                    title={translate("dialog_heading.unsaved_changes_on_tab")}
                    onClose={() => this.setState({isConfirmModalOpen: false})}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    onConfirm={() => {
                        this.updateSelectedTab(this.state.selectedTabResource);
                        this.setState({
                            isConfirmModalOpen: false,
                            isDirty: false
                        });
                    }}
                    translate={translate}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>

                <ModalDefault
                    show={!!this.state.isLoadUpdatedByOtherUserModalOpen}
                    title={translate('modal_heading.load_updated_by_other_user')}
                    widthClass="max-w-xl"

                    onClose={this.toggleUpdatedByOtherUserModal}
                    closeButtonLabel="Ignore for now"

                    buttonLabel={translate('btn.apply_other_user_changes', [this.props.loadUpdate?.data?.Contact?.FirstName + " " + this.props.loadUpdate?.data?.Contact?.LastName])}
                    onButtonClick={this.refreshPage}
                >
                    <div className="p-6 space-y-3">
                        <p>This load is updated by <span className="font-bold">
                                {this.props.loadUpdate?.data?.Contact?.FirstName + " " + this.props.loadUpdate?.data?.Contact?.LastName}.
                            </span>
                        </p>

                        <p>
                            Saving your changes will overwrite the latest changes made by <span className="font-bold">
                                {this.props.loadUpdate?.data?.Contact?.FirstName + " " + this.props.loadUpdate?.data?.Contact?.LastName}.
                            </span>.
                        </p>

                        <p>Alternatively, you can apply all his/her changes and start updating again.</p>
                        <p>Applying <span
                            className="font-bold">{this.props.loadUpdate?.data?.Contact?.FirstName + " " + this.props.loadUpdate?.data?.Contact?.LastName}&apos;s</span> changes
                            will undo your changes!</p>
                    </div>
                </ModalDefault>

                <TableTagManagerModal
                    show={this.state.isTagManagerModalOpen}
                    onClose={this.toggleTagManagerModal}
                    translate={translate}
                    onCreateTag={this.createTag}
                    tagsData={this.state.colorTags}
                    isLoading={this.props.dialogResource.isLoading}
                />

                <ResourceTableDialog
                    show={this.state.isBookedByDialogOpen}
                    hideDialogFooter={true}
                    tableHeaderRight={
                        <div className="col-start-9 col-span-4 flex justify-end items-center space-x-4">
                            {this.state.lineOfBusiness === 'brokerage' && (
                                <button className="btn btn-primary"
                                        onClick={() => {
                                            const user = LocalStorage.get('user')
                                            this.props.dispatch(updateDialogResource({
                                                user: user,
                                                params: {
                                                    BookedByID: user.Contact.ContactID,
                                                    CoveredByID: this.state.fields.CoveredByID?.value?.value,
                                                    id: this.getID()
                                                },
                                                resource: Resources.LoadShort,
                                                query: {id: this.getID()},
                                                piggyResource: Resources.LoadShort,
                                                piggyQuery: {id: this.getID()},
                                                errorMessage: true,
                                                successMessage: translate('text.booked_by_update_success')
                                            }));

                                            this.handleChoseBookedByClick();
                                        }
                                        }>
                                    {this.props.translate("text.assign_to_me")}
                                </button>
                            )}
                            {checkPerm(Resources.Contacts, CREATE_PERM) && (
                                <button
                                    className="btn btn-primary"
                                    onClick={this.handleCreateContactClick}
                                >
                                    {translate("btn.create_new_contact")}
                                </button>
                            )}
                        </div>
                    }
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Contacts}
                    title={translate("text.select_booked_by")}
                    messages={this.getBookedByMessage()}
                    dispatch={this.props.dispatch}
                    fields={excludeFields(getContactDialogFields(translate), ['DepartmentID'])}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    defaultQuery={this.getBookedByQuery()}
                    sortBy={"FirstName"}
                    defaultAction={
                        (item) => {
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get('user'),
                                params: {
                                    BookedByID: item.ContactID,
                                    CoveredByID: this.state.fields.CoveredByID?.value?.value,
                                    id: this.getID()
                                },
                                resource: Resources.LoadShort,
                                query: {id: this.getID()},
                                piggyResource: Resources.LoadShort,
                                piggyQuery: {id: this.getID()},
                                errorMessage: true,
                                successMessage: translate('text.booked_by_update_success')
                            }));

                            this.handleChoseBookedByClick();
                        }
                    }
                    onView={(item) => this.props.dispatch(
                        showModal('ViewContactCard', {ContactID: item.ContactID})
                    )}
                    onClose={this.handleChoseBookedByClick}
                    translate={translate}
                    // buttonLabel={"Create new contact"}
                    // onButtonClick={() => this.handleCreateContactClick()}
                    storeKey={'contactFormQuery'}
                    htmlAfter={
                        <Modal
                            show={this.state.isCreateContactDialogOpen}
                            widthClass={'max-w-3xl'}
                            onClose={() => this.setState({isCreateContactDialogOpen: false})}
                        >
                            <ContactForm
                                hasSalesSection={true}
                                onClose={() => this.setState({isCreateContactDialogOpen: false})}
                                errorMessages={this.state.doesEmailExistsError
                                    ? [{field: 'Email', message: 'text.email_exists'}]
                                    : []
                                }
                                clearErrorData={() => this.setDoesEmailExistsError(false)}
                                onSubmit={this.submitContactForm}
                                translate={translate}
                            />
                        </Modal>
                    }
                />

                <ResourceTableDialog
                    show={this.state.isCoveredByDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Contacts}
                    title={translate("text.select_covered_by")}

                    dispatch={this.props.dispatch}
                    fields={excludeFields(getContactDialogFields(translate), ['DepartmentID'])}

                    widthClass={"max-w-7xl"}

                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}

                    tableHeaderRight=
                        {this.state.lineOfBusiness === 'brokerage' && (
                            <button className="btn btn-primary col-start-11 col-span-2 place-content-center"
                                    onClick={() => {
                                        const user = LocalStorage.get('user');
                                        this.props.dispatch(updateDialogResource({
                                            user: user,
                                            params: {
                                                BookedByID: this.state.fields.BookedByID?.value?.value,
                                                CoveredByID: user.Contact.ContactID,
                                                id: this.getID()
                                            },
                                            resource: Resources.LoadShort,
                                            query: {id: this.getID()},
                                            piggyResource: Resources.LoadShort,
                                            piggyQuery: {id: this.getID()},
                                            errorMessage: true,
                                            successMessage: translate('text.booked_by_update_success')
                                        }));

                                        this.handleChoseCoveredByClick();
                                    }}>
                                {this.props.translate("text.assign_to_me")}
                            </button>
                        )}

                    messages={this.getCoveredByMessage()}

                    defaultQuery={this.getCoveredByQuery()}
                    sortBy={"FirstName"}
                    defaultAction={(item) => {
                        this.props.dispatch(updateDialogResource({
                            user: LocalStorage.get('user'),
                            params: {
                                BookedByID: this.state.fields.BookedByID?.value?.value,
                                CoveredByID: item.ContactID,
                                id: this.getID()
                            },
                            resource: Resources.LoadShort,
                            query: {id: this.getID()},
                            piggyResource: Resources.LoadShort,
                            piggyQuery: {id: this.getID()},
                            errorMessage: true,
                            successMessage: translate('text.booked_by_update_success')
                        }));

                        this.handleChoseCoveredByClick();
                    }}
                    onView={(item) => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                    onClose={this.handleChoseCoveredByClick}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(LoadView)
