import {Menu, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import {classNames} from "../../../util/util-helpers";

export default function MenuDropdown({
                                         buttonClass = "btn btn-outline",
                                         buttonLabel,
                                         options = [],
                                         sections = [],
                                         addMenuClass,
                                         addMenuItemClass
                                     }) {
    return (

        <Menu as="div" className={classNames(addMenuClass, "relative inline-block text-left")}>
            <div>
                <Menu.Button
                    className={buttonClass}
                >
                    {buttonLabel}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={classNames(addMenuItemClass, "absolute z-[100] mt-2 w-56 border border-tm-gray-200 origin-top-right divide-y divide-tm-gray-100 rounded-md bg-popup shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none")}
                >
                    {!!sections.length && sections.map((section) => {
                        return <div className="px-1 py-1">
                            <Options
                                options={section.options}
                            />
                        </div>
                    })}
                    {!sections.length && (
                        <div className="px-1 py-1">
                            <Options
                                options={options}
                            />
                        </div>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const Options = ({options}) => {
    return options.map((option, i) => {
        return (
            <Menu.Item key={i}>
                {({active}) => (
                    option.content
                        ? option.content(active)
                        : (<button
                                onClick={() => option?.onClick && option.onClick()}
                                className={`${
                                    active ? 'bg-primary text-primary-contrast' : 'text-tm-gray-700'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                                {option.icon && (
                                    <option.icon className="w-5 h-5 mr-2.5 opacity-60 group-hover:opacity-100"/>
                                )}

                                {option.label}
                            </button>
                        )
                )}
            </Menu.Item>
        )
    })
}