import React, {Component} from 'react'
import axios from 'axios'
import Env from '../../../util/env'
import Resources from '../../../data/services/resources'
import {processResponse} from '../../../data/services/api-util'
import Tippy from '@tippyjs/react'
import {showModal} from '../../../data/actions/ui'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid'
import {
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    READ_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import {Field} from "../../../data/services/fields";
import {cloneDeep} from "../../../common/util/util-vanilla";
import {getHistoryResource} from "../../../data/actions/historyResource";
import LocalStorage from "../../../util/localStorage";
import {HourglassIcon} from "../../../data/themes/icons";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import {Loader, LoaderLarge, LoaderSmall} from "../../../common/components/loader";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";
import InfoParagraph from "../../../common/components/info-paragraph";
import DriverStatusBadge from "../../../common/components/badge/driver-status-badge";
import Card from "../../../common/components/card";
import ContactInfoFields from "../../../common/components/contact/contact-fields/contact-info-fields";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import ContactPhoto from "../../../common/components/forms/contact-form/contact-photo";
import EmergencyContactFields from "../../../common/components/contact/contact-fields/emergency-contact-fields";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import Modal from "../../../common/components/modal";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import PhoneFields from "../../../common/components/misc/phone-fields";
import FieldDropdownSelect from "../../../common/components/fields/field-dropdown-select";
import {getJWT} from "../../../common/util/util-auth";

export default class InfoTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            historyModalOpen: false,
            suspendModalOpen: false,
        }
    }

    getFieldTypes = () => {
        return Object.values(this.props.fields_info).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    getFieldsToHtmlForIncludedFields = (arr = []) => {
        const selects = {
            MailingStateID: getLookup('State'),
            CDLState: getLookup('State'),
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            }
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(cloneDeep(this.props.fields_info), arr)))), this.props.translate, this.props.handleInputChange, selects)
    }

    getLinkedContactFields = () => {
        const linkedInfo = this.props.linkedContactInfo;
        const fieldTemplates = {
            FirstName: new Field('FirstName', '', [''], true, 'text', {addContainerClass: "md:col-span-4 md:col-start-1 my-2"}),
            LastName: new Field('LastName', '', [''], true, 'text', {addContainerClass: "md:col-span-4 my-2"}),
            Email: new Field('Email', '', [''], true, 'text', {
                addContainerClass: "md:col-span-4 my-2",
                label: "BusinessEmail"
            }),
            PersonalTitleID: new Field('PersonalTitleID', '', [''], true, 'select', {addContainerClass: "md:col-span-4"}, {placeholder: ' '}),
            Title: new Field('Title', '', [''], true, "text", {addContainerClass: "md:col-span-4"}),
            DateOfBirth: new Field('DateOfBirth', '', [''], true, "date", {addContainerClass: "md:col-span-4 my-2"})
        }

        let fields = fillFieldsFromData(fieldTemplates, linkedInfo)

        return fields
    }

    render() {
        const {
            translate,
            resource,
            historyResource,
            HireNote,
            StatusID,
            OnLoadID,
        } = this.props

        const isLoading = resource.isLoading;

        const fieldsHtmlAddressInfo = this.getFieldsToHtmlForIncludedFields(['CountryID', 'GooglePlaces', 'AddressName', 'AddressName2', 'StateID', 'CityName', 'PostalCode'])
        const fieldsHtmlMailingAddressInfo = this.getFieldsToHtmlForIncludedFields(this.props.fields_info.MailingSameAsFirstAddress.value ? ['MailingSameAsFirstAddress'] : ['MailingSameAsFirstAddress', 'MailingListAddressAutocomplete', 'MailingAddressName', 'MailingAddressName2', 'MailingCityName', 'MailingStateID', 'MailingPostalCode'])
        const fieldsHtmlDriverInfo = this.getFieldsToHtmlForIncludedFields(['OfficeID', 'SocialSecurityNo', 'UserDefinedField1', 'UserDefinedField2', 'DriverIDNumber', 'DriverPositionTypeID', 'GovernmentAndStrictLoads', 'LegalStatusID'])
        const fieldsHtmlCDLInfo = this.getFieldsToHtmlForIncludedFields(['CDLNumber', 'CDLState', 'CDLIssued', 'CDLExpDate', 'HasTWICCard', 'TWICCardExpDate', 'HasTSAApproved', 'TSAApprovedExpDate', 'TWICCardNumber', 'TSANumber', 'PharmaApproved', 'HazmatApproved'])
        const fieldsHtmlNotes = this.getFieldsToHtmlForIncludedFields(['Notes', 'DriverAssignmentStatusID', 'AssignmentNotes'])

        const fieldsHtmlLinkedContact = fieldsToHtml(Object.values(Object.assign({}, this.getLinkedContactFields())), translate)

        return (
            <div className="relative">
                {isLoading && (
                    <LoaderLarge stripesBg/>
                )}

                <React.Fragment>
                    {
                        this.props.mainID && checkPerm(Resources.DriversHistory, READ_PERM) &&
                        <div className={'flex justify-between ml-4'}>
                            <TabTitle
                                text={translate('text.Driver')}
                            />
                            <div className={'flex justify-end items-center mb-4 space-x-4'}>
                                <div className={'mx-1'}>
                                    {this.props.returnDriverStatus()}
                                    {HireNote && <InfoParagraph className="mt-2" message={HireNote}/>}

                                </div>

                                {isLoading && (
                                    <div className="relative w-8 h-8">
                                        <LoaderSmall/>
                                    </div>
                                )}

                                {/*{!isLoading && (*/}
                                {/*    <DriverStatusBadge*/}
                                {/*        item={{*/}
                                {/*            StatusID: StatusID,*/}
                                {/*            OnLoadID: OnLoadID*/}
                                {/*        }}*/}
                                {/*        translate={translate}*/}
                                {/*        addClass={"h-8"}*/}
                                {/*    />*/}
                                {/*)}*/}

                                <Tippy content={<span>{translate('text.view_history')}</span>}>
                                    <button
                                        className={'btn-header flex items-center justify-center'}
                                        onClick={() => this.setState({historyModalOpen: true})}
                                    >
                                        {<HourglassIcon className="w-5 h-5"/>}
                                    </button>
                                </Tippy>
                            </div>
                        </div>
                    }

                    <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-4 pb-20">
                        <div className="relative">
                            {resource.isLoading && (
                                <LoaderLarge/>
                            )}

                            <Card addClass={'h-full'}>
                                {!this.props.mainID && (
                                    <div className="row mb-3">
                                        <div className="col">
                                            <button onClick={this.props.toggleCreateNewContactBtnHandler}
                                                    className="btn btn-outline cursor-pointer ">
                                                {!this.props.createNewContactBtn ? translate('text.link_existing_contact') : translate('text.create_contact')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {!!this.props.createNewContactBtn && (
                                    <div className="row">
                                        <div className="col">
                                            <label className="flex justify-between items-center">
                                                        <span
                                                            className={'font-bold'}>{translate('field.ContactID')} *</span>
                                                {this.props.fields_info.ContactID.value?.value && (
                                                    <span className="flex justify-end items-center">
                                                            {checkPerm(Resources.Contacts, UPDATE_PERM) && (
                                                                <Tippy
                                                                    content={<span>{translate('btn.edit')}</span>}>
                                                                        <span
                                                                            className={'cursor-pointer'}
                                                                            onClick={() => window.open(`${window.location.origin}/contacts?ContactID=${this.props.fields_info.ContactID.value?.value}`, '_blank')}>
                                                                            <ArrowTopRightOnSquareIcon
                                                                                className={'w-5 h-5 text-primary'}/>
                                                                        </span>
                                                                </Tippy>
                                                            )}
                                                                </span>
                                                )}
                                            </label>
                                            <div>
                                                <FieldDropdownSelect
                                                    onChange={this.props.handleInputChange}

                                                    {...this.props.fields_info.ContactID}
                                                    addClass="form-control"
                                                    defaultOptions={true}
                                                    placeholder={' '}
                                                    loadOptions={
                                                        (inputValue, callback) => {
                                                            axios.get(
                                                                Env.getApiUrl('api/' + Resources.ContactsQuick, {
                                                                    query: inputValue,
                                                                    NotEmployees: 1,
                                                                    NotAgents: 1,
                                                                    NotDrivers: 1
                                                                }),
                                                                {
                                                                    headers: {
                                                                        'Authorization': 'Bearer ' + getJWT().access_token
                                                                    }
                                                                }
                                                            )
                                                                .then((response) => {
                                                                    const result = processResponse(response)
                                                                    if (result && result.status === 0) {
                                                                        const list = result.data.list.map((it) => {
                                                                            return {
                                                                                label: it.FirstName + ' ' + it.LastName,
                                                                                value: it.ContactID,
                                                                                metadata: it
                                                                            }
                                                                        })
                                                                        callback(list)
                                                                    }
                                                                })
                                                        }
                                                    }
                                                />
                                            </div>

                                            <InfoParagraph className={"mt-5"}
                                                           message={translate("text.use_existing_contact")}/>
                                            <div>{this.props.fields_info.ContactID.value != -1 && fieldsHtmlLinkedContact}</div>
                                        </div>
                                    </div>
                                )}

                                {!this.props.createNewContactBtn && (
                                    <ContactInfoFields
                                        fields={this.props.fields_info}
                                        handleInputChange={this.props.handleInputChange}
                                        translate={translate}
                                        ValidationType={{
                                            1: 'Email',
                                            2: 'SMS',
                                        }}
                                        hideSystemUser={this.props.match.params.id}
                                        selectedItem={this.props.match.params.id} // for disabling email
                                    />
                                )}
                            </Card>
                        </div>

                        <div className="">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.Notes')}
                                />
                                <div className="space-y-5">
                                    {fieldsHtmlNotes}
                                </div>
                            </Card>
                        </div>

                        <div className="">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.AddressInfo')}
                                />
                                <div className="grid grid-cols-12 gap-4">
                                    {fieldsHtmlAddressInfo}
                                </div>
                            </Card>
                        </div>

                        <div className="">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.MailingAddressInfo')}
                                />
                                <div className="grid grid-cols-3 gap-4">
                                    {fieldsHtmlMailingAddressInfo}
                                </div>
                            </Card>
                        </div>

                        {!this.props.createNewContactBtn && (
                            <div className="">
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.Phones')}
                                    />
                                    <div className="">
                                        <PhoneFields
                                            phonesFields={this.props.phones_info}
                                            handleItemInputChange={this.props.handleItemInputChange}
                                            onInputFocus={this.props.onInputFocus}
                                            removePhoneField={this.props.removePhoneField}
                                            {...this.props}
                                        />
                                        <div className="row mb-5">
                                            <div className="col">
                                                <button className="btn btn-outline"
                                                        onClick={() => this.props.addPhoneField()}>{translate('btn.add_new_phone')}</button>
                                            </div>
                                        </div>

                                        {!isLoading && (
                                            <ContactPhoto
                                                addClass="col-span-full"
                                                imageID={getProp(this.props.resource, 'data.ContactID', null)}
                                                hasImage={getProp(this.props.resource, 'data.ImagePath', null)}
                                                lastUpdated={getProp(this.props.resource, 'data.CreateUpdateDate', null)}
                                                onGetImage={(file) => this.props.setImage(file)}
                                                translate={translate}
                                                removeDisabled={true}
                                            />
                                        )}
                                        {isLoading && (
                                            <div className={"relative h-12"}>
                                                <Loader disableContainer={true}/>
                                            </div>
                                        )}
                                    </div>
                                </Card>
                            </div>
                        )}
                        <div className="">
                            <Card addClass={'h-full'}>
                                <div className="grid grid-cols-1 gap-4">
                                    <EmergencyContactFields
                                        translate={this.props.translate}
                                        handleInputChange={this.props.handleInputChange}
                                        fields={this.props.fields_info}
                                    />
                                </div>
                            </Card>
                        </div>

                        <div className="">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.DriverInfo')}
                                />
                                <div className="grid grid-cols-1 gap-4">
                                    {fieldsHtmlDriverInfo}
                                </div>
                            </Card>
                        </div>

                        <div className="">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.CDLInfo')}
                                />
                                <div className="grid grid-cols-12 gap-4">
                                    {fieldsHtmlCDLInfo}
                                </div>
                            </Card>
                        </div>
                    </div>

                    {this.props.match.params.id && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.props.canSubmit}
                            actionCancel={this.props.cancel}
                            actionSubmit={checkPerm(Resources.DriverInfo, UPDATE_PERM) && this.props.submit}
                        />
                    )}

                    <Modal
                        show={this.state.historyModalOpen}
                        widthClass={"max-w-5xl"}
                        onClose={() => this.setState({historyModalOpen: false})}
                        translate={translate}
                    >
                        {this.state.historyModalOpen && (
                            <HistoryModal
                                onClose={() => this.setState({historyModalOpen: false})}
                                translate={translate}
                                title={translate("text." + Resources.DriversHistory)}
                                tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                                filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                                onFetchData={(query) => {
                                    this.props.dispatch(getHistoryResource({
                                        user: LocalStorage.get('user'),
                                        query: Object.assign({}, query, {
                                            id: this.props.match.params.id
                                        }),
                                        resource: Resources.DriversHistory
                                    }))
                                }}
                                data={getProp(historyResource, "data.list", [])}
                                count={getProp(historyResource, "data.count", 0)}
                                isLoading={getProp(historyResource, "isLoading", false)}
                                showUserAvatar

                                onRowClick={(item) => {
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                                }}
                                fieldTypes={this.getFieldTypes()}
                            />
                        )}
                    </Modal>
                </React.Fragment>

            </div>
        )
    }
}
