import React, {useEffect, useMemo, useState} from "react";
import {fieldToCell, getLabel, TableActions} from "./table-utils-old";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import {classNames} from "../../../util/util-helpers";
import {LoaderLarge} from "../../loader";


export default function TableVertical({
                                          fields,
                                          resourceData,
                                          options,
                                          tableKey,
                                          isLoading,
                                          actions,
                                          onView,
                                          onEdit,
                                          onDelete,
                                          onRestore,
                                          selectedRows = [],
                                          onSelectAllClick,
                                          onSelectRow,
                                          verticalTableContainerClass,
                                          translate
                                      }) {
    const [focus, setFocus] = useState("");
    const [isSelectMode, setIsSelectMode] = useState(false);
    const hasDefaultActions = !!onView || !!onEdit || !!onDelete || !!onRestore;

    const hasSelected = !!resourceData.find(it => selectedRows[it[tableKey]]);

    let columnsData = options?.columns ? options.columns : {};
    const columnsList = !!Object.values(columnsData).length && Object.values(columnsData);

    const columns = useMemo(() => columnsList.length
            ? columnsList.filter(column => column.show && column.showMobile).reduce((memo, column) => {
                memo.push(column.name);
                return memo
            }, [])
            : Object.keys(fields)
        , [resourceData, columnsList]
    );

    const onTableBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocus(null);
        }
    }

    useEffect(() => {
        setFocus(null);
    }, [isLoading]);

    return (
        <React.Fragment>
            {!!onSelectRow && (
                <div className="pb-5 space-x-2.5">
                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => {
                            onSelectAllClick(!hasSelected)
                        }}
                    >
                        {hasSelected ? "Unselect all" : "Select all"}
                    </button>

                    <button
                        className="btn btn-outline-secondary"
                        onClick={() => setIsSelectMode(!isSelectMode)}
                    >
                        {isSelectMode ? "Stop selecting" : "Select rows ..."}
                    </button>
                </div>
            )}

            <div className={verticalTableContainerClass ?? "space-y-5"}>
                {
                    resourceData.map((row, rowIndex) => {
                        const isTableFocused = row[tableKey] === focus;
                        const isSelected = selectedRows[row[tableKey]];
                        return (

                            <div
                                tabIndex={0}
                                onClick={() => null}
                                className={classNames(
                                    isTableFocused ? "ring ring-primary" : undefined,
                                    "relative border border-tm-gray-300 rounded-lg overflow-hidden bg-inverse block w-full",
                                    isSelected ? "ring-2 ring-primary" : undefined
                                )}
                                onFocus={() => !isSelectMode && setFocus(row[tableKey])}
                                onBlur={onTableBlur}
                            >
                                {columns.map((colName, i) => {
                                    const cellData = fieldToCell(fields, colName, row);

                                    return (
                                        <div
                                            key={i}
                                            className={
                                                classNames(i ? "border-t" : undefined, "w-full flex h-10 items-center border-tm-gray-300")
                                            }>
                                            <div
                                                className={classNames("w-32 bg-tm-gray-50 mr-2 h-full flex shrink-0 items-center px-3 border-tm-gray-300")}>
                                                <span
                                                    className="truncate font-bold">{getLabel(colName, columnsData, translate)}</span>
                                            </div>

                                            {isLoading && (
                                                <LoaderLarge stripesBg={true}/>
                                            )}

                                            {!isLoading && <p className="truncate max-w-full">{cellData}</p>}
                                        </div>
                                    )
                                })}

                                {isTableFocused && (
                                    <div
                                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10">
                                        <div className="p-2 bg-inverse rounded-full border border-tm-gray-300">
                                            <TableActions
                                                hasDefaultActions={hasDefaultActions}
                                                actions={actions}

                                                item={row}
                                                rowIndex={rowIndex}
                                                onView={onView}
                                                onEdit={onEdit}
                                                onDelete={onDelete}
                                                onRestore={onRestore}
                                                translate={translate}
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </div>
                                )}

                                {isSelectMode && (
                                    <div
                                        onClick={() => onSelectRow(row)}
                                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10 z-50 cursor-pointer"
                                    >
                                        <div
                                            className={
                                                classNames(
                                                    "bg-inverse rounded-full w-8 h-8 absolute top-1 right-1 flex items-center justify-center",
                                                    isSelected ? "ring-4 ring-primary" : undefined
                                                )
                                            }
                                        >
                                            {isSelected && (
                                                <CheckIcon className="w-7 h-7 text-primary"/>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}
