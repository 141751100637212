import React, { Component } from 'react'
import {fillFieldsFromData} from '../../../common/util/util-fields';
import { createResource, deleteResource, getResource, updateResource } from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import { Field, FieldsManager } from '../../../data/services/fields'
import {
    ArrowPathIcon,
    CalendarDaysIcon,
    ClipboardDocumentListIcon,
    PencilIcon,
    TrashIcon
} from '@heroicons/react/24/outline'
import { checkPerm, getLookup, getProp } from '../../../common/util/util-helpers'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import WidgetStats from "../../../common/components/widgets/widget-stats";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Card from "../../../common/components/card";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import InfoParagraph from "../../../common/components/info-paragraph";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";

export default class EmployeeVacationsTab extends Component {

    constructor (props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "EndVacation",
            sort: "DESC",
            limit: DEFAULT_QUERY_LIMIT,

            createDialog: false,
            updateDialog: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount () {
        !!this.getID() && this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    addNewVacation = (fields) => {
        if (this.getID()) {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                    EmployeeID: this.getID()
                }),
                query: this.getQuery(),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true, successMessage: this.props.translate('text.vacation_created'),
            }))
        } else {
            let list = getProp(this.props.resource.data, Resources.EmployeesVacation, [])
            list.push(FieldsManager.getFieldKeyValues(fields))
            this.props.onCreateNewData(Resources.EmployeesVacation, FieldsManager.getFieldKeyValues(fields))
        }
    }

    handleToggleUpdateDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            updateDialog: !this.state.updateDialog
        })
    }

    handleToggleUpdateApprovalDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            updateApprovalDialog: !this.state.updateApprovalDialog
        })
    }

    handleToggleCreateDialog = () => {
        this.setState({
            createDialog: !this.state.createDialog
        })
    }

    handleToggleConfirmDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmDialog: !this.state.confirmDialog
        })
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.id
    }

    getQuery = () => {
        return {
            id: this.getID(),
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit
        }
    }

    getUpdateFields = (item = null) => {
        let fieldTemplates = {
            VacationStatusID: new Field('VacationStatusID', '', ['empty'], false, 'select', { addContainerClass: 'col-span-full' }),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getCreateFields = (item = null) => {
        const fieldTemplates = {
            VacationID: new Field('VacationID', '', ['empty'], false, 'select', { addContainerClass: 'col-span-full' }),
            StartVacation: new Field('StartVacation', '', ['empty'], false, 'date', { addContainerClass: 'col-span-full' }, {
            }),
            EndVacation: new Field('EndVacation', '', ['empty'], false, 'date', { addContainerClass: 'col-span-full' }, {
            }),
            VacationTimezone: new Field('VacationTimezone', 'UTC', ['empty'], false, 'hidden', {
                hideDialog: true,
                hideTable: true
            }),
            VacationStatus: new Field('VacationStatus', '', [], false, 'text', {
                hideDialog: true,
                hideTable: !this.getID()
            }),
            RequestCreatedDateTime: new Field('RequestCreatedDateTime', '', [], false, 'datetime', {
                hideDialog: true,
                hideTable: !this.getID()
            }),
            StatusUpdateByID: new Field('StatusUpdateByID', '', [], false, 'select-search', {
                hideDialog: true,
                hideTable: !this.getID()
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea', { addContainerClass: 'col-span-full' }),
            InternalNotes: new Field('InternalNotes', '', [''], false, 'textarea', { addContainerClass: 'col-span-full' }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFormatedPropValues = () => {
        const Vacation = getLookup('Vacation')
        return getProp(this.props.resource, 'data.list', []).map((it, index) => {
            return {
                Vacation: Vacation[it.VacationID],
                VacationID: it.VacationID,
                StartVacation: it.StartVacation,
                EndVacation: it.EndVacation,
                Notes: it.Notes,
                InternalNotes: it.InternalNotes,
                id: index
            }
        })
    }

    getResource = () => {
        return Resources.EmployeesVacation
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    render () {
        const { translate, resource, isCreate } = this.props

        const data = this.getID() ? getProp(resource, 'data.list', []) : this.getFormatedPropValues();
        const count = getProp(this.props, 'resource.data.count', 0)
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        const stats = [
            {
                id: 1,
                name: translate('text.PreviousYearUsedDays'),
                icon: CalendarDaysIcon,
                iconClass: 'bg-green-500',
                valueRaw: getProp(resource, 'data.previousYearUsedDays', 0) === 0 ? '0' :
                    getProp(resource, 'data.previousYearUsedDays', 0) > 0 ?
                        (<div className={'text-red-600'}>{getProp(resource, 'data.previousYearUsedDays', 0)}</div>) :
                        (<div className={'text-green-600'}>{getProp(resource, 'data.previousYearUsedDays', 0)}</div>)
            },
            {
                id: 3,
                name: translate('text.CurrentYearUsedDays'),
                icon: CalendarDaysIcon,
                iconClass: 'bg-green-500',
                valueRaw: getProp(resource, 'data.currentYearUsedDays', 0) === 0 ? '0' :
                    getProp(resource, 'data.currentYearUsedDays', 0) > 0 ?
                        (<div className={'text-red-600'}>{getProp(resource, 'data.currentYearUsedDays', 0)}</div>) :
                        (<div className={'text-green-600'}>{getProp(resource, 'data.currentYearUsedDays', 0)}</div>)
            },
        ]

        return (
            <React.Fragment>
                {!isCreate && (
                    <div className="relative z-40 mb-5">
                        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
                            <WidgetStats
                                isLoading={resource.isLoading}
                                stats={stats}
                            />
                        </div>
                    </div>
                )}

                <Card>
                    <PageHeader
                        title={translate('text.Vacations')}
                        titleClass="mr-5 text-2xl"
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateDialog()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                    >
                        {!isCreate && (
                            <button className="btn btn-header ml-auto mr-2" onClick={this.fetchData}>
                                <ArrowPathIcon className="w-5 h-5" />
                            </button>
                        )}
                    </PageHeader>

                    <TableCard>
                        <div className="p-1">
                            <InfoParagraph className="" message={translate("message.VacationTabMessage")}/>
                        </div>

                        <ResourceTable
                            data={data}
                            count={count}
                            fields={this.getCreateFields()}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}
                            options={{style: {floatingActions: true, horizontalLines: true}}}
                            actions={[
                                {
                                    tooltipText: () => translate('btn.edit'),
                                    action: this.handleToggleUpdateDialog,
                                    visible: (item) => this.getID() ? (item.VacationStatusID === 1 && checkPerm(this.getResource(), UPDATE_PERM)) : true,
                                    icon: () => PencilIcon
                                },
                                {
                                    tooltipText: () => translate('btn.edit_approval'),
                                    action: this.handleToggleUpdateApprovalDialog,
                                    visible: () => isCreate ? false : checkPerm(this.getResource(), UPDATE_PERM),
                                    icon: () => ClipboardDocumentListIcon
                                },
                                {
                                    tooltipText: () => translate('btn.delete'),
                                    action: this.handleToggleConfirmDialog,
                                    visible: (item) => this.getID() ? (item.VacationStatusID === 1 && checkPerm(this.getResource(), DELETE_PERM)) : true,
                                    icon: () => TrashIcon
                                },
                            ]}
                        />

                        <TableCardFooter
                            show={!(!data.length && !isLoading)  && count > this.state.limit}
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleUpdateOffset(name, value)
                                }
                                pageOffset={this.state.offset}
                                pageLimit={this.state.limit}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate("text.no_records")}

                            text={translate("text.create_time_off")}
                            btnLabel={translate("btn_create_time_off")}
                            onBtnClick={() => {
                                this.handleToggleCreateDialog()
                            }}

                            clearFilterBtnLabel={translate("text.clear_all_filters")}
                            clearFilterText={translate("text.try_without_filters")}
                            filters={{}}
                        />
                    </TableCard>
                </Card>

                <ModalSaveResource
                    show={this.state.updateApprovalDialog}
                    title={this.props.translate('text.EditApproval')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={() => this.handleToggleUpdateApprovalDialog()}
                    fields={this.getUpdateFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params['EmployeeVacationID'] = this.state.selectedItem['EmployeeVacationID']
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: Resources.EmployeesVacationStatus,
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: this.props.translate('text.EmployeeVacationStatusUpdated'),
                            }))

                            this.handleToggleUpdateApprovalDialog()
                        }
                    }}
                    translate={translate}
                />

                <ModalSaveResource
                    show={this.state.updateDialog}
                    title={this.props.translate('text.edit_vacation')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleUpdateDialog}
                    fields={this.getCreateFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (!isCreate) {
                                params['EmployeeVacationID'] = this.state.selectedItem['EmployeeVacationID']
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: this.props.translate('text.EmployeeVacationUpdated'),
                                }))
                            } else {
                                this.props.onEditVacation(params, this.state.selectedItem.id)
                            }

                            this.handleToggleUpdateDialog()
                        }
                    }}
                    translate={translate}
                />

                <ModalSaveResource
                    show={this.state.createDialog}
                    title={this.props.translate('text.add_new_vacation')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateDialog}
                    fields={this.getCreateFields()}
                    getRawFields
                    onSubmit={(params) => {
                        if (params) {
                            this.addNewVacation(params)
                            this.handleToggleCreateDialog()
                        }
                    }}
                    translate={translate}
                    handleInputChange={(fields, name, value) => {
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={translate('message.confirm_delete_generic', [this.state.selectedItem?.Vacation])}
                    onClose={this.handleToggleConfirmDialog}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (this.getID()) {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                query: Object.assign({}, this.getQuery(), {
                                    EmployeeVacationID: this.state.selectedItem?.EmployeeVacationID,
                                }),
                                errorMessage: true, successMessage: this.props.translate('text.EmployeeVacationDeleted'),
                                resource: this.getResource(),
                                piggyResource: this.getResource()
                            }))
                        } else {
                            this.props.onRemoveVacation(this.state.selectedItem)
                        }
                        this.handleToggleConfirmDialog(false)
                    }}
                />
            </React.Fragment>
        )
    }
}
