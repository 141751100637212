import { genericMoneyFormatter } from "../../../../common/util/util-vanilla";
import { numberWithCommasToBack } from "../../../../util/util-formaters";
import React from "react";
import { classNames } from "../../../../common/util/util-helpers";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {Loader, LoaderLarge} from "../../../../common/components/loader";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../../common/components/resource-table";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import {excludeFields} from "../../../../common/util/util-fields";

export default function LoadInfoAccessorials({innerRef, getFields, toggleExpandSection, toggleAccessorialFormModal, handleDeleteAccessorialClick, data, isLoading, isSectionExpanded, translate}) {
    return (
        <div ref={innerRef} className={classNames(
            "border-t border-tm-gray-300 relative pt-3 pb-6"
        )}>
            <div className="flex items-center px-6">
                <button
                    className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                    onClick={() => toggleExpandSection("LoadInfoAccessorials")}
                >
                    <ChevronRightIcon
                        className={
                            classNames(
                                "w-5 h-5",
                                isSectionExpanded ? "rotate-90" : undefined
                            )
                        }
                    />
                </button>

                <Subtitle subtitle={translate("text.LoadInfoAccessorials")}/>

                {isSectionExpanded && !!data.length && (
                    <div className="ml-auto">
                        <button
                            className="btn btn-text gap-2 hover:bg-tm-gray-50 text-primary pr-4"
                            onClick={toggleAccessorialFormModal}
                        >
                            <PlusCircleIcon className="w-5 h-5"/>
                            {translate("btn.add_accessorial")}
                        </button>
                    </div>
                )}
            </div>

            {!!data.length && (
                <div className="flex gap-2 px-6">
                    <InformationCircleIcon className="w-5 h-5 text-primary"/>
                    <span>Total:</span> {' '} {genericMoneyFormatter(data.reduce((memo, it) => {
                    memo = memo + numberWithCommasToBack(it.TotalAmount)
                    return memo
                }, 0))}
                </div>
            )}

            {isSectionExpanded && (
                <div className="mt-6">
                    <ResourceTable
                        data={data}

                        fields={excludeFields(getFields(), ['AccessorialID', 'IsPayDriver', 'LoadAccessorialNotes', 'IsPayDriver'])}
                        translate={translate}
                        isLoading={isLoading}

                        onEdit={(it, i) => toggleAccessorialFormModal(it, i)}
                        onDelete={(it, i) => handleDeleteAccessorialClick(it, i)}
                    />

                    <NoRecords
                        show={!data.length}
                        onBtnClick={toggleAccessorialFormModal}
                        addClass="px-4 pb-6"
                        title="No Accessorials"
                        text={'You haven’t added any accessorial yet.'}
                        btnLabel="Add an accessorial"
                    />
                </div>
            )}

            {isLoading && (
                <React.Fragment>
                    {isSectionExpanded && (
                        <LoaderLarge stripesBg={true}/>
                    )}

                    {!isSectionExpanded && (
                        <Loader stripesBg={true}/>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}