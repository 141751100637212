import React, {useEffect, useState} from 'react'
import {LoaderSmall} from "../../../../common/components/loader";
import {
    createIntegrationResource,
    getIntegrationResource,
    resetIntegrationResource,
} from '../../../../data/actions/integrationResource'
import LocalStorage from '../../../../util/localStorage'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {getProp} from '../../../../common/util/util-helpers'
import ModalDefault from "../../../../common/components/modal/modal-default";
import InfoParagraph from "../../../../common/components/info-paragraph";
import FieldText from "../../../../common/components/fields/field-text";

export default (props) => {
    const {translate, integrationResource, dispatch, resourceName} = props

    /** Helpers
     ================================================================= */
    const integrationData = (getProp(integrationResource, 'data', []))

    const returnInfoRow = (item, heading, values) => {
        const rows = values.map(it => {
            const cols = it.map(elem => {
                return (
                    <div className="col">
                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.${elem}`)}</p>
                        <p className="mb-3 font-weight-bold">{item[elem]}</p>
                    </div>
                )
            })

            return (
                <div className="row">
                    {cols}
                </div>
            )
        })

        return (
            <>
                {rows}
            </>
        )
    }

    const returnInfoRows = (item, heading, values) => {
        if (!item) return null
        return (
            <>
                <div className="row">
                    <div className="col">
                        <h3>{translate(`text.${heading}`)}</h3>
                    </div>
                </div>
                {returnInfoRow(item, heading, values)}
            </>
        )
    }

    const AssureAdvantage = (getProp(integrationResource, 'data.AssureAdvantage', []))
        .map((item) => {
            return (
                <div>
                    {returnInfoRows(item?.CarrierDetails?.dotNumber, 'dotNumber', [['Value', 'status']])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Review, 'Review', [['accidentRate', 'mcs150MileYear', 'mcs150Miles', 'reviewType']])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Identity, 'Identity', [ // two dimensional array => each array means row
                        ['businessCity', 'businessColonia', 'businessCountry', 'businessState'],
                        ['businessStreet', 'dbaName', 'legalName', 'mailingCity'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Crash, 'Crash', [
                        ['crashFatalCAN', 'crashFatalUS', 'crashInjuryCAN', 'crashInjuryUS'],
                        ['crashTotalCAN', 'crashTotalUS', 'crashTowCAN', 'crashTowUS'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Authority, 'Authority', [
                        ['authGrantDate', 'brokerAuthority', 'brokerAuthorityPending', 'brokerAuthorityRevocation'],
                        ['commonAuthority', 'commonAuthorityPending', 'commonAuthorityRevocation'],
                        ['contractAuthority', 'contractAuthorityPending', 'contractAuthorityRevocation'],
                        ['enterprise', 'freight', 'householdGoods', 'passenger', 'private'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Equipment, 'Equipment', [
                        ['fleetsize', 'totalPower', 'tractorsOwned', 'tractorsTerm'],
                        ['tractorsTrip', 'trailersOwned', 'trailersTerm', 'trailersTrip', 'trucksOwned'],
                        ['trucksTerm', 'trucksTotal', 'trucksTrip'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.FMCSAInsurance, 'FMCSAInsurance', [
                        ['bipdOnFile', 'bipdRequired', 'bondSuretyOnFile', 'bondSuretyRequired', 'cargoOnFile', 'cargoRequired'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Cargo, 'Cargo', [
                        ['cargoBeverages', 'cargoBldgMaterial', 'cargoChemicals', 'cargoCoalcoke', 'cargoConstruction', 'cargoDriveTow'],
                        ['cargoDryBulk', 'cargoFarmSupplies', 'cargoGarbage', 'cargoGenFreight', 'cargoGrainfeed', 'cargoHousehold'],
                        ['cargoIntermodal', 'cargoLiqGas', 'cargoLivestock', 'cargoLogPole', 'cargoMachLarge', 'cargoMeat'],
                        ['cargoMetal', 'cargoMobileHome', 'cargoMotorVeh', 'cargoOilfield', 'cargoOther', 'cargoOtherDesc'],
                        ['cargoPaperProd', 'cargoPassengers', 'cargoProduce', 'cargoRefrigerated', 'cargoUSMail'],
                        ['cargoWaterwell', 'cargoUtilities', 'hazmatIndicator'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Inspection, 'Inspection', [
                        ['inspectDrvCAN', 'inspectDrvOOSCAN', 'inspectDrvOOSPctCAN', 'inspectDrvOOSPctUS', 'inspectDrvOOSUS', 'inspectDrvUS'],
                        ['inspectHazOOSPctUS', 'inspectHazOOSUS', 'inspectHazUS', 'inspectIEPOOSPctUS', 'inspectIEPOOSUS', 'inspectIEPUS'],
                        ['inspectTotalCAN', 'inspectTotalIEPUS', 'inspectTotalUS', 'inspectVehCAN', 'inspectVehOOSCAN', 'inspectVehOOSPctCAN'],
                        ['inspectVehOOSPctUS', 'inspectVehOOSUS', 'inspectVehUS'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Operation, 'Operation', [
                        ['carrierOperation', 'classAuthForHire', 'classExemptForHire', 'classFederalGovernment', 'classIndianNation', 'classLocalGovernment'],
                        ['classMigrant', 'classOther', 'classPrivPassBusiness', 'classPrivPassNonBusiness', 'classPrivateProperty', 'classStateGovernment'],
                        ['classUSMail', 'dotAddDate', 'entityBroker', 'entityCargoTank', 'entityFreightFowarder', 'entityShipper'],
                        ['mxOperationType', 'mxRFCNumber', 'operatingStatus', 'outOfService', 'outOfServiceDate', 'outOfServiceReason', 'shipperOperation'],
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.RiskAssessment, 'RiskAssessment', [
                        ['Authority', 'Insurance', 'Operation', 'Other', 'Overall', 'Safety']
                    ])}
                    <div className="separator"/>

                    {returnInfoRows(item?.CarrierDetails?.Safety, 'Safety', [
                        ['controlSubAlert', 'controlSubOT', 'controlSubPCT', 'controlSubSV', 'controlSubTrend', 'drvFitAlert'],
                        ['drvFitOT', 'drvFitPCT', 'drvFitSV', 'drvFitTrend', 'hosAlert', 'hosOT'],
                        ['hosPCT', 'hosSV', 'hosTrend', 'rating', 'ratingDate', 'unsafeDrvAlert'],
                        ['unsafeDrvOT', 'unsafeDrvPCT', 'unsafeDrvSV', 'unsafeDrvTrend', 'vehMaintAlert', 'vehMaintOT'],
                        ['vehMaintPCT', 'vehMaintSV', 'vehMaintTrend'],
                    ])}
                </div>
            )
        })

    const getFields = () => {
        return {
            USDOTNumber: new Field('USDOTNumber', '', ['empty'], false, 'text', {
                containerClass: 'col-9'
            })
        }
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [canSubmit, setCanSubmit] = useState(false)
    const [selectedUSDOT, setSelectedUSDOT] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        !integrationResource.isLoading && setCanSubmit(!!integrationResource.data)
    }, [integrationResource])

    /** Data Events
     ================================================================= */
    const fetchData = () => {
        setSelectedUSDOT(FieldsManager.getFieldKeyValues(fields))
        dispatch(getIntegrationResource({
            user: LocalStorage.get('user'),
            resource: resourceName,
            query: FieldsManager.getFieldKeyValues(fields)
        }))
    }

    const handleImport = () => {
        dispatch(createIntegrationResource({
            user: LocalStorage.get('user'),
            errorMessage: true, successMessage: `Resource created`,
            params: {
                USDOTNumber: selectedUSDOT.USDOTNumber
            },
            resource: Resources.MyCarrierIntegrationImport,
        }))
        hideResourceDialog()
    }

    /** UI Events
     ================================================================= */
    const handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(fields, name, value)
        setFields(newFields)
    }

    const hideResourceDialog = () => {
        dispatch(resetIntegrationResource())
        props.hideResourceDialog()
    }

    return (
        <ModalDefault
            title={translate('modal_heading.mycarrier_integration_details')}
            close={hideResourceDialog}

            onClose={hideResourceDialog}
            closeButtonLabel="Close"

            primaryButtonLabel={'Import'}
            primaryButtonDisabled={!canSubmit}
            onPrimaryButtonClick={() => canSubmit && handleImport()}

            translate={translate}
            show={props.show}
            large
        >
            <div className={'relative mt-2'}>
                {integrationResource.isLoading && (
                    <div className="text-center absolute position-absolute-center">
                        <LoaderSmall/>
                    </div>
                )}
            </div>
            {!integrationResource.isLoading && (
                <div className="row">
                    <div className="col-9">
                        <label className="form-group has-float-label mb-4">
                            <FieldText
                                autoFocus
                                addClass="form-control"
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') fetchData()
                                }}
                                {...fields.USDOTNumber}
                                placeholder={''}/>
                            <span>{translate('field.USDOTNumber')}</span>
                        </label>
                    </div>
                    <div className="col-3 text-right">
                        <button
                            onClick={fetchData}
                            className="btn btn-primary ">Find
                        </button>
                    </div>
                </div>
            )}

            {integrationData.Message && getProp(integrationResource, 'data.AssureAdvantage', []).length === 0 && (
                <div className="row mb-2">
                    <div className="col">
                        <InfoParagraph
                            type={'danger'}
                            message={integrationData.Message ?? 'No response.'}
                        />
                    </div>
                </div>
            )}

            {AssureAdvantage}
        </ModalDefault>
    )
}
