import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
    chartWidthChanged,
    destroyChatSidebar,
    destroyConfirmDialog,
    destroyConfirmRedirectionDialog,
    destroyCreateOfficeDialog,
    destroyGlobalInfoSearchPopup,
    destroyGlobalSearchPopup,
    destroyHelpSidebar,
    destroyHistoryModal,
    destroyInfoOfficeDialog,
    destroyMapDialog,
    destroyModal,
    destroyResourceDialog,
    destroySecondModal,
    destroySetupNewLoadDialog,
    destroySetupNewLoadDialogSeccond,
    destroyTaskDialog,
    destroyUpdateOfficeDialog,
    doneGetCommunicationList,
    doneGetConversation,
    doneGetNotificationList,
    errorGetCommunicationList,
    errorGetConversation,
    errorGetNotificationList, grabUpdateSignal,
} from '../actions/ui'
import { checkUserHelper } from "../services/api-util";
import Api from "../services/api";
import { doneGetInfoResource, errorGetInfoResource } from "../actions/infoResource";

// export const getMessagesCount = (state) => state.ui.messagesCount;
// export const getMessageDuration = (state) => state.ui.messageDuration;

export function* destroyModalCall(action) {
    let time = 500; // default time

    if (action.delay === 0 || action.delay) {
        time = action.delay;
    }
    if (time) yield delay(time);

    yield put(destroyModal());
}

export function* watchHideModal() {
    yield takeLatest('HIDE_MODAL', destroyModalCall);
}

export function* destroyHistoryModalCall(action) {
    let time = 500; // default time

    if (action.delay === 0 || action.delay) {
        time = action.delay;
    }

    yield delay(time);
    yield put(destroyHistoryModal());
}

export function* watchHideHistoryModal() {
    yield takeLatest('HIDE_HISTORY_MODAL', destroyHistoryModalCall);
}

export function* destroyHelpSidebarCall(action) {
    let time = 500; // default time

    if (action.delay === 0 || action.delay) {
        time = action.delay;
    }

    yield delay(time);
    yield put(destroyHelpSidebar());
}

export function* watchHideHelpSidebar() {
    yield takeLatest('HIDE_HELP_SIDEBAR', destroyHelpSidebarCall);
}

export function* destroyChatSidebarCall(action) {
    let time = 500; // default time

    if (action.delay === 0 || action.delay) {
        time = action.delay;
    }

    yield delay(time);
    yield put(destroyChatSidebar());
}

export function* watchHideChatSidebar() {
    yield takeLatest('HIDE_CHAT_SIDEBAR', destroyChatSidebarCall);
}

export function* destroySecondModalCall(action) {
    let time = 500; // default time

    if (action.delay === 0 || action.delay) {
        time = action.delay;
    }

    yield delay(time);
    yield put(destroySecondModal());
}

export function* watchHideSecondModal() {
    yield takeLatest('HIDE_SECOND_MODAL', destroySecondModalCall);
}

export function* destroyCreateOfficeDialogCall() {
    yield delay(500);
    yield put(destroyCreateOfficeDialog());
}

export function* watchHideCreateOfficeDialog() {
    yield takeLatest('HIDE_CREATE_OFFICE_DIALOG', destroyCreateOfficeDialogCall);
}

export function* destroyDeleteOfficeDialogCall() {
    yield delay(500);
    yield put(destroyCreateOfficeDialog());
}

export function* watchHideDeleteOfficeDialog() {
    yield takeLatest('HIDE_DELETE_OFFICE_DIALOG', destroyDeleteOfficeDialogCall);
}

export function* hideConfirmDialogCall() {
    yield delay(500);
    yield put(destroyConfirmDialog());
}

export function* watchHideConfirmDialog() {
    yield takeLatest('HIDE_CONFIRM_DIALOG', hideConfirmDialogCall);
}

export function* hideConfirmRedirectionDialogCall() {
    yield delay(500);
    yield put(destroyConfirmRedirectionDialog());
}

export function* watchHideConfirmRedirectionDialog() {
    yield takeLatest('HIDE_CONFIRM_REDIRECTION_DIALOG', hideConfirmRedirectionDialogCall);
}

export function* hideUpdateDialogCall() {
    yield delay(500);
    yield put(destroyUpdateOfficeDialog());
}

export function* watchHideUpdateDialog() {
    yield takeLatest('HIDE_UPDATE_DIALOG', hideUpdateDialogCall);
}

export function* hideInfoDialogCall() {
    yield delay(500);
    yield put(destroyInfoOfficeDialog());
}

export function* watchHideInfoDialog() {
    yield takeLatest('HIDE_INFO_DIALOG', hideInfoDialogCall);
}

export function* hideMapDialogCall() {
    yield put(destroyMapDialog());
}

export function* watchHideMapDialog() {
    yield takeLatest('HIDE_MAP_DIALOG', hideMapDialogCall);
}

export function* hideResourceDialogCall() {
    yield delay(500);
    yield put(destroyResourceDialog());
}

export function* watchHideResourceDialog() {
    yield takeLatest('HIDE_RESOURCE_DIALOG', hideResourceDialogCall);
}

export function* hideGlobalSearchPopupCall() {
    yield delay(500);
    yield put(destroyGlobalSearchPopup());
}

export function* watchGlobalSearchPopupDialog() {
    yield takeLatest('HIDE_GLOBAL_SEARCH_POPUP', hideGlobalSearchPopupCall);
}

export function* hideGlobalInfoSearchPopupCall() {
    yield delay(500);
    yield put(destroyGlobalInfoSearchPopup());
}

export function* watchGlobalInfoSearchPopupDialog() {
    yield takeLatest('HIDE_GLOBAL_INFO_SEARCH_POPUP', hideGlobalInfoSearchPopupCall);
}

export function* hideSetupNewLoadDialogCall() {
    yield delay(500);
    yield put(destroySetupNewLoadDialog());
}

export function* watchSetupNewLoadDialog() {
    yield takeLatest('HIDE_SETUP_NEW_LOAD_DIALOG', hideSetupNewLoadDialogCall);
}

export function* hideSetupNewLoadDialogSeccondCall() {
    yield delay(500);
    yield put(destroySetupNewLoadDialogSeccond());
}

export function* watchSetupNewLoadDialogSeccond() {
    yield takeLatest('HIDE_SETUP_NEW_LOAD_DIALOG_SECCOND', hideSetupNewLoadDialogSeccondCall);
}

export function* hideCloseTaskDialog() {
    yield delay(500);
    yield put(destroyTaskDialog());
}

export function* watchCloseTaskDialog() {
    yield takeLatest('CLOSE_TASK_DIALOG', hideCloseTaskDialog);
}

export function* globalSearchShow(action) {
    // Call API
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetInfoResource(result.data));
    } else {
        yield put(errorGetInfoResource(result.data));
    }
    // Put result in new infoReducer doneGetInfoData, errorGetInfoData
}

export function* watchGlobalSearchShow() {
    yield takeLatest('GET_INFO_RESOURCE', globalSearchShow);
}

export function* watchPushNotification() {
     yield takeLatest('PUSH_NOTIFICATION', pushNotificationCall);
}
export function* pushNotificationCall(action) {
    if (!!action.data
        &&
        !!action.data.notificationMetadata
        &&
        (
            action.data.notificationMetadata?.type === "GRAB_UPDATE"
            ||
            action.data.notificationMetadata?.type === "NEW_TASK_ON_BOARD"
            ||
            action.data.notificationMetadata?.type === "GRABBED_TASK_ON_BOARD"
        )) {
        yield put(grabUpdateSignal());
    }
}

export function* updateChartWidthCall() {
    yield delay(310);

    yield put(chartWidthChanged());
}

export function* watchUpdateChartWidth() {
    yield takeLatest('UPDATE_CHART_WIDTH', updateChartWidthCall);
}

export function* getNotificationListCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.getNotificationList, user);

    if (result && result.status === 0) {
        yield put(doneGetNotificationList(result.data));
    } else {
        yield put(errorGetNotificationList(result.data));
    }
}

export function* watchGetNotificationList() {
    yield takeLatest('GET_NOTIFICATION_LIST', getNotificationListCall);
}

export function* getCommunicationListCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result;
    // Todo: refactor, this condition is separated in getConversationCall()
    if (action.data.query.ContactID) {
        result = yield call(Api.getConversation, user, action.data.query.ContactID);
    } else {
        result = yield call(Api.getCommunicationList, user, action.data.query);
    }

    if (result && result.status === 0) {
        yield put(doneGetCommunicationList(result.data));
    } else {
        yield put(errorGetCommunicationList(result.data));
    }
}

export function* watchGetCommunicationList() {
    yield takeLatest('GET_COMMUNICATION_LIST', getCommunicationListCall);
}

export function* getConversationCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.getConversation, user, action.data.ContactID);


    if (result && result.status === 0) {
        yield put(doneGetConversation(result.data));
    } else {
        yield put(errorGetConversation(result.data));
    }
}

export function* watchGetConversation() {
    yield takeLatest('GET_CONVERSATION', getConversationCall);
}
