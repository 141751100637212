import React, {Component} from 'react';
import {Field, FieldsManager} from "../../../../data/services/fields";
import PhoneFields, {getPhoneFieldsKeyValues} from "../../misc/phone-fields";
import ContactInfoFields from "./contact-info-fields";
import InfoParagraph from "../../info-paragraph";
import {LoaderSmall} from "../../loader";
import {getLookupsFromArray, getProp, splitName} from "../../../util/util-helpers";
import {fillFieldsFromData} from "../../../util/util-fields";
import {DEFAULT_CONTACT_INFO_FIELDS, DEFAULT_PHONES_FIELDS} from "../../../../util/util-constants";
import {getContactResource} from "../../../../data/actions/contactResource";
import FieldCheckbox from "../../fields/field-checkbox";
import FieldSelectSearch from "../../fields/field-select-search";
import Resources from "../../../../data/services/resources";
import ContactImageUpload from "../contactImageUpload";
import axios from "axios";
import Env from "../../../../util/env";
import {getJWT} from "../../../util/util-auth";
import {processResponse} from "../../../../data/services/api-util";
import LocalStorage from "../../../util/localStorage";
import {scrollErrorIntoViewFields} from "../../../util/util-vanilla";


class ContactFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            phonesFields: this.getPhoneFields(),
            files: [],

            errorMessage: null,
            emailExist: false,

            //IMAGE CROP
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            isCropVisible: false
        }

        this.imageRef = null;
        this.fileImageObject = null;
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.props.selectedItem && this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.contactResource && prevProps.contactResource.isLoading && !this.props.contactResource.isLoading && !prevProps.contactResource.data && this.props.contactResource.data && !!this.props?.selectedItem) {
            const contact = getProp(this.props.contactResource, "data", {});
            const phones = this.getPhoneFields(contact)
            this.setState({
                fields: this.getFields(contact),
                phonesFields: phones,
            })
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.Contacts,
            query: {id: this.props?.selectedItem.ContactID}
        }));
    }

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                const phones = getPhoneFieldsKeyValues(this.state.phonesFields)

                this.props.submit({fields: this.state.fields, phones: phones}, this.fileImageObject)
            } else {
                scrollErrorIntoViewFields([this.state.phonesFields, this.state.fields]);
            }
        })
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        // focusNextAfterSelect(name, this.state.fields)
        let fields = this.state.fields
        if (name === "IsSystemUser") {
            fields.Roles.value = (!value) ? '' : fields.Roles.value
            fields.ValidationType.value = (!value) ? '' : fields.ValidationType.value
            fields.Roles.validate = (!value) ? [''] : ['empty']
            fields.Roles.type = (!value) ? 'hidden' : 'multi-select'
            fields.Groups.type = (!value) ? 'hidden' : 'multi-select-search'
            if (!this.props.selectedItem) {
                fields.ValidationType.validate = (!value) ? [''] : ['empty']
                fields.ValidationType.type = (!value) ? 'hidden' : 'select'
            }
        } else if (name === "Email" && this.state.emailExist) {
            this.setState({emailExist: false})
        } else if (name === "Organization") {
            this.getOrganizationOptions('', value)
            fields[name].value = value
        }
        if (name === "MemberOfOrganization") {
            fields.OrganizationID.validate = !!value ? ['empty'] : []
            fields.Organization.validate = !!value ? ['empty'] : []
            fields.DepartmentID.validate = !!value ? ['empty'] : []
        }
        fields[name].value = value

        this.props.setCanSubmit()
        this.setState({fields: fields})
    };

    handlePhonesInputChange = (i, name, value) => {
        let fields = this.state.phonesFields[i];
        // focusNextAfterSelect(name, fields)

        const newItems = this.state.phonesFields;
        newItems[i] = FieldsManager.updateField(fields, name, value);
        this.props.setCanSubmit()
        this.setState({canSubmit: true, phonesFields: newItems})
    };

    cancelImageCropping = () => {
        this.setState({
            src: null,
            croppedImageUrl: null,
            files: [],
        }, () => this.fileImageObject = null)
    }

    setCropOk = () => {
        this.setState({
            isCropVisible: false
        })
    }

    onSelectFile = acceptedFiles => {
        if (acceptedFiles) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    canSubmit: true,
                    isCropVisible: true
                }, () => this.props.setCanSubmit())
            );
            reader.readAsDataURL(acceptedFiles[0]);
        }
    };

    removeImage = () => {
        this.setState({
            files: [],
            src: null,
            croppedImageUrl: null
        })
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop) => {
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.fileImageObject = new File([blob], fileName);
                resolve(this.fileUrl);
            }, 'image/png');
        });
    }

    handleAddPhoneField = () => {
        const newPhones = this.state.phonesFields;
        newPhones.push(this.getDefaultPhoneFields());
        this.setState({
            phonesFields: newPhones,
            canSubmit: true
        });
        this.props.setCanSubmit()
    };

    handleRemovePhoneField = (i) => {
        let newPhones = this.state.phonesFields;
        newPhones.splice(i, 1);
        this.setState({phonesFields: Array.from(newPhones), canSubmit: true});
        this.props.setCanSubmit()
    };

    handleCopyToClipboard = (link) => {
        this.setState({urlCopied: true})
        let textField = document.createElement('textarea')
        textField.innerText = link
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }
    /** Helpers
     ================================================================= */
    getOrganizationOptions = (inputValue, route) => {
        axios.get(
            Env.getApiUrl("api/" + route, {query: inputValue ? inputValue : ''}),
            {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }
        )
            .then((response) => {
                const result = processResponse(response);
                if (result && result.status === 0) {
                    const listObject = {}
                    result.data.list.forEach((it) => {
                        listObject[it.OrganizationID] = it.LegalName;
                    });
                    this.setState({OrganizationValues: listObject});
                }
            })
    }

    getFields = (item = {}) => {
        let fieldTemplates = Object.assign({}, DEFAULT_CONTACT_INFO_FIELDS(), !this.props.disableMemberOfOrganization && {
            DepartmentID: new Field('DepartmentID', '', [''], false, "select"),
            Organization: new Field('Organization', '', [''], false, "text"),
            MemberOfOrganization: new Field('MemberOfOrganization', 0, [], false, "checkbox"),
            OrganizationID: new Field('OrganizationID', '', [''], false, "select"),
        })

        fieldTemplates['FirstName'].value = splitName(this.props.prefilled)[0] ?? ''
        fieldTemplates['LastName'].value = splitName(this.props.prefilled)[1] ?? ''
        fieldTemplates['IsSystemUser'].value = this.props.IsSystemUser ?? item.IsSystemUser
        fieldTemplates['IsSystemUser'].disabled = this.props.IsSystemUser
        fieldTemplates['Roles'].validate = (this.props.IsSystemUser || item.IsSystemUser) ? ['empty'] : ['']
        fieldTemplates['Roles'].type = (this.props.IsSystemUser || item.IsSystemUser) ? 'multi-select' : 'hidden'
        fieldTemplates['Groups'].type = (this.props.IsSystemUser || item.IsSystemUser) ? 'multi-select-search' : 'hidden'
        fieldTemplates['ValidationType'].validate = this.props.selectedItem ? [] : (this.props.IsSystemUser || item.IsSystemUser) ? ['empty'] : ['']
        fieldTemplates['ValidationType'].type = this.props.selectedItem ? 'hidden' : (this.props.IsSystemUser || item.IsSystemUser) ? 'multi-select' : 'hidden'

        if (Object.keys(item).length > 0) {
            fieldTemplates = fillFieldsFromData(fieldTemplates, item);
            fieldTemplates.Roles.value = (item.Roles && item.Roles.length > 0) ? item.Roles.map(role => {
                return {
                    value: role.role_id,
                    label: role.role_name
                }
            }) : ''
            fieldTemplates.Groups.value = item.Groups && item.Groups.map(group => {
                return {
                    value: group.ContactGroupID,
                    label: group.ContactGroupName
                }
            })
        }

        return fieldTemplates
    }

    getPhoneFields = (item = null) => {
        if (item) {
            return item.Phones && item.Phones.map(phone => {
                return this.getDefaultPhoneFields(phone)
            })
        } else {
            return [this.getDefaultPhoneFields()]
        }
    }

    getDefaultPhoneFields = (item = {}) => {
        return fillFieldsFromData(DEFAULT_PHONES_FIELDS(), item)
    }

    render() {
        const {translate} = this.props;
        const {src, crop, croppedImageUrl, isCropVisible, isLoading} = this.state;

        const {Department} = getLookupsFromArray(["Department", "PermRoles"])

        return (
            <React.Fragment>
                {isLoading && (
                    <div className="text-center absolute position-absolute-center">
                        <LoaderSmall/>
                    </div>
                )}

                <div className={isLoading ? "invisible" : "p-4"}>
                    <div className='grid grid-cols-12 gap-x-8'>
                        {!!this.props.emailExist && (
                            <div className={"col-span-12"}>
                                <div className="mb-3">
                                    <InfoParagraph
                                        type="danger"
                                    >
                                        {translate("EMAIL_PARAMETER_ALREADY_EXISTS")}
                                    </InfoParagraph>
                                </div>
                            </div>
                        )}

                        <div className="xl:col-span-4 col-span-full mb-4">
                            <ContactInfoFields
                                fields={this.state.fields}
                                handleInputChange={this.handleInputChange}
                                translate={translate}
                                IsSystemUser={this.props.IsSystemUser}
                                hideSystemUser={this.props.hideSystemUser}
                                ValidationType={this.props.ValidationType}
                                selectedItem={!!this.props.selectedItem}
                            />
                        </div>

                        <div className="xl:col-span-4 col-span-full mb-4">
                            <h5 className="mb-4 text-tm-gray-700 text-base">{translate("field.phones")}</h5>
                            <PhoneFields
                                phonesFields={this.state.phonesFields}
                                handleItemInputChange={this.handlePhonesInputChange}
                                removePhoneField={this.handleRemovePhoneField}
                                {...this.props}/>


                                <div>
                                    <button className="btn btn-outline"
                                            onClick={() => this.handleAddPhoneField()}>{translate('btn.add_new_phone')}</button>
                                </div>

                            {
                                !this.props.selectedItem && !this.props.disableMemberOfOrganization && (
                                            <div className="flex items-center mt-3 mb-5">
                                                <FieldCheckbox
                                                    onChange={this.handleInputChange}
                                                    id="isInDepartmentContactCreate"
                                                    {...this.state.fields.MemberOfOrganization}
                                                />
                                                <label className="ml-1 mb-0 hover"
                                                       htmlFor="isInDepartmentContactCreate">{translate("field.memberOfOrganization")}</label>
                                            </div>
                                )
                            }

                            {
                                !this.props.disableMemberOfOrganization && this.state.fields?.MemberOfOrganization?.value &&  (
                                    <React.Fragment>
                                            <div>
                                                <label className="form-group has-float-label mb-4">
                                                    <FieldSelectSearch
                                                        className="form-control"
                                                        values={{
                                                            [Resources.Customers]: 'Customer',
                                                            [Resources.Carriers]: 'Carrier',
                                                            [Resources.Vendors]: 'Vendor'
                                                        }}
                                                        onChange={this.handleInputChange}
                                                        {...this.state.fields.Organization}
                                                        placeholder={translate("text.select")}
                                                        addClass={"form-control"}
                                                    />
                                                    <span>{translate("field.organization")} *</span>
                                                </label>
                                            </div>

                                            <div>
                                                <label className="form-group has-float-label mb-4">
                                                    <FieldSelectSearch
                                                        className="form-control"
                                                        values={Department}
                                                        onChange={this.handleInputChange}
                                                        {...this.state.fields.DepartmentID}
                                                        placeholder={translate("text.select")}
                                                        addClass={"form-control"}
                                                    />
                                                    <span>{translate("field.department")} *</span>
                                                </label>
                                            </div>


                                            <div>
                                                <label className="form-group has-float-label mb-4">
                                                    <FieldSelectSearch
                                                        addClass="form-control"
                                                        className={"form-control"}
                                                        values={this.state.OrganizationValues}
                                                        onChange={this.handleInputChange}
                                                        isDisabled={false}
                                                        {...this.state.fields.OrganizationID}
                                                    />
                                                    <span>{this.state.fields.Organization.value ? translate(`page.heading.${this.state.fields.Organization.value}`) : translate("field.selectFirstOrganization")} *</span>
                                                </label>
                                            </div>
                                    </React.Fragment>
                                )}
                        </div>

                        <div className="xl:col-span-4 col-span-full">

                            <ContactImageUpload
                                title={translate("modal_heading.contact_image")}
                                imageID={this.props?.selectedItem?.ContactID}
                                imagePath={this.props?.selectedItem?.ImagePath}
                                files={this.state.files}
                                onSelectFile={this.onSelectFile}
                                onImageLoaded={this.onImageLoaded}
                                onCropComplete={this.onCropComplete}
                                onCropChange={this.onCropChange}
                                croppedImageUrl={croppedImageUrl}
                                isCropVisible={isCropVisible}
                                src={src}
                                crop={crop}
                                setCropOk={this.setCropOk}
                                cancelImageCropping={this.cancelImageCropping}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ContactFields;
