import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    createResource,
    deleteResource,
    getResource,
    resetResource,
    updateResource
} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import {ArrowRightIcon, PlusCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import PencilIcon from '@heroicons/react/24/outline/PencilIcon'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import {fieldsToHtml, includeFields} from '../../util/util-fields';
import {CREATE_PERM, DELETE_PERM, SETTINGS_LOOKUP_GROUPING, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from "../../util/util-helpers";
import Card from "../card";
import InfoParagraph from "../info-paragraph";
import PageHeader from "../layout/layout-components/page/page-header";
import {LoaderSmall} from "../loader";
import ModalDefault from "../modal/modal-default";
import ModalSaveResource from "../modal/modal-save-resource";
import ModalConfirm from "../modal/modal-confirm";
import {scrollErrorIntoViewFields} from "../../util/util-vanilla";

export default class Lookups extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: this.getFields(),
            createFields: this.getCreateFields(),
            searchFields: this.getSearchFields(),

            selectedLookup: null,
            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            createSubTypeModalOpen: false,
            editSubTypeModalOpen: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentWillUnmount() {
        this.props.dispatch(resetResource())
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName()
        }))
    }

    createNewLookup = () => {
        this.setState({createFields: this.state.createFields.map(item => FieldsManager.validateFields(item))}, () => {
            if (this.state.createFields.every(item => FieldsManager.checkFieldsForErrors(item))) {
                // find duplicates
                let canCreate = true
                const selectedLookups = LocalStorage.get('lookup')[this.state.selectedLookup]
                const createFields = this.state.createFields.map(item => {
                    const result = selectedLookups.some(elem => item.Value.value.trim() === elem[this.state.selectedLookup])
                    if (result) canCreate = false
                    item.Value.metadata.label = result ? 'ValueAlreadyExisting' : 'Value'
                    return item
                })
                this.setState({createFields})

                if (canCreate) {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, {
                            Lookup: this.state.selectedLookup,
                            Values: this.state.createFields.map(item => item.Value.value)
                        }),
                        errorMessage: true, successMessage: `Resource ${this.props.translate('text.created')}`,
                        resource: Resources.Lookup,
                        piggyResource: Resources.Lookup,
                        onPiggyCallback: this.handleEnterNewDataInLookup
                    }))
                    this.handleToggleCreateModal()
                }
            } else {
                scrollErrorIntoViewFields(this.state.createFields)
            }
        })
    }

    createSubTypeLookup = (params) => {
        if (params) {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, {
                    [`${this.state.selectedLookup}SubItem`]: params.Value,
                    [`${this.state.selectedLookup}ID`]: this.state.selectedSubItem[`${this.state.selectedLookup}ID`]
                }),
                errorMessage: true, successMessage: `Resource ${this.props.translate('text.created')}`,
                resource: Resources.Lookup,
                piggyResource: Resources.Lookup,
                onPiggyCallback: this.handleEnterNewDataInLookup
            }))
            this.handleToggleCreateSubTypeModal()
        }
    }

    editSubTypeLookup = (params) => {
        if (params) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, {
                    [`${this.state.selectedLookup}SubItem`]: params.Value,
                    [`${this.state.selectedLookup}SubItemID`]: this.state.selectedSubItem[`${this.state.selectedLookup}SubItemID`]
                }),
                errorMessage: true, successMessage: `Resource ${this.props.translate('text.created')}`,
                resource: Resources.Lookup,
                piggyResource: Resources.Lookup,
                onPiggyCallback: this.handleEnterNewDataInLookup
            }))
            this.handleToggleEditSubTypeModal()
        }
    }

    editLookup = (params) => {
        if (params) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, params, {
                    Lookup: this.state.selectedLookup,
                    Id: this.state.selectedItem[`${this.state.selectedLookup}ID`]
                }),
                errorMessage: true, successMessage: `Resource ${this.props.translate('text.updated')}`,
                resource: Resources.Lookup,
                piggyResource: Resources.Lookup,
                onPiggyCallback: this.handleEnterNewDataInLookup
            }))
            this.handleToggleEditModel()
        }
    }

    deleteSubTypeLookup = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                Lookup: `${this.state.selectedLookup}SubItem`,
                Id: this.state.selectedSubItem[`${this.state.selectedLookup}SubItemID`]
            },
            errorMessage: true, successMessage: `Resource ${this.props.translate('text.deleted')}`,
            resource: Resources.Lookup,
            piggyResource: Resources.Lookup,
            onPiggyCallback: this.handleEnterNewDataInLookup
        }))
        this.setState({confirmSubItemModalOpen: false})
    }

    deleteLookup = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                Lookup: this.state.selectedLookup,
                Id: this.state.selectedItem[`${this.state.selectedLookup}ID`]
            },
            errorMessage: true, successMessage: `Resource ${this.props.translate('text.deleted')}`,
            resource: Resources.Lookup,
            piggyResource: Resources.Lookup,
            onPiggyCallback: this.handleEnterNewDataInLookup
        }))
        this.setState({confirmModalOpen: false})
    }

    /** UI Events
     ================================================================= */
    handleSearchInputChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)})
    }

    handleEnterNewDataInLookup = (result) => {
        if (result && result.status === 0) {
            LocalStorage.set('lookup', result.data)
        }
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            errorMessage: false,
            createFields: this.getCreateFields()
        })
    }

    handleToggleCreateSubTypeModal = (item = null) => {
        this.setState({
            selectedSubItem: item,
            createSubTypeModalOpen: !this.state.createSubTypeModalOpen,
            errorMessage: false
        })
    }

    handleToggleEditSubTypeModal = (item = null) => {
        this.setState({
            selectedSubItem: item,
            editSubTypeModalOpen: !this.state.editSubTypeModalOpen,
            errorMessage: false
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            errorMessage: false
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleSubTypeConfirmModal = (item = null) => {
        this.setState({
            selectedSubItem: item,
            confirmSubItemModalOpen: !this.state.confirmSubItemModalOpen
        })
    }

    handleInputChange = (name, value, index) => {
        let items = this.state.createFields
        items[index] = FieldsManager.updateField(items[index], name, value)
        this.setState({createFields: items, canSubmit: true})
    }

    handleRemoveItem = (index) => {
        this.setState({createFields: this.state.createFields.filter((_, i) => i !== index)}, () => {
            if (this.state.createFields.length === 0) this.handleToggleCreateModal()
        })
    }

    handleAddItem = () => {
        this.setState({createFields: [...this.state.createFields, this.getFields()]})
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.Lookup
    }

    getFields = (item = null) => {
        return {
            Value: new Field('Value', item ? item[this.state.selectedLookup] : '', ['empty'], false, 'text', {
                addContainerClass: 'relative col-span-3 md:col-span-6'
            }),
        }
    }

    getSubTypeFields = (item = null) => {
        return {
            Value: new Field('Value', item ? item[`${this.state.selectedLookup}SubItem`] : '', ['empty'], false, 'text', {
                addContainerClass: 'relative col-span-3 md:col-span-6'
            }),
        }
    }

    getCreateFields = () => {
        return [this.getFields()]
    }

    getSearchFields = () => {
        return {
            LookupName: new Field('LookupName', '', [], false, 'search', {labelType: 'float', label: 'search'}),
            LookupItemName: new Field('LookupItemName', '', [], false, 'search', {labelType: 'float', label: 'search'}),

        }
    }

    getPrefilteredLookup = (item) => {
        if (this.props.customLookupFilter) {
            return this.props.customLookupFilter(item, this.state.selectedLookup)
        }
        return true
    }
    /** Render
     ================================================================= */

    renderLookups = (lookups) => {
        return lookups
            .sort()
            .filter(item => !((FieldsManager.getFieldKeyValues(this.state.searchFields)['LookupName'] && !this.props.translate(`text.${item}`).toLowerCase().includes(FieldsManager.getFieldKeyValues(this.state.searchFields)['LookupName'].toLowerCase()))))
            .map(item => {
                return (
                    <button
                        onClick={() => {
                            this.setState({selectedLookup: item})
                        }}
                        type="button"
                        className={`${this.state.selectedLookup === item ? 'text-white bg-primary border-transparent' : 'text-primary border-tm-gray-300 hover:bg-primary-transparent'} border font-bold w-full flex items-center justify-between mb-2 cursor-pointer px-4 py-2 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-popup`}>
                        {this.props.translate(`text.${item}`)}
                        <ArrowRightIcon className={'h-4 w-4'}/>
                    </button>
                )
            })
    }

    render() {
        const {translate, resource} = this.props
        const data = getProp(resource, 'data', {})
        const groupLookups = getProp(this.props, 'groupLookups', null)
        const excludeLookups = getProp(this.props, 'excludeLookups', [])
        const includeLookups = getProp(this.props, 'includeLookups', [])

        let lookups = null;
        if (groupLookups) {
            lookups = Object.keys(groupLookups).map((group) => {
                const items = Object.keys(data).filter(
                    (it) => SETTINGS_LOOKUP_GROUPING[group].includes(it)
                );
                return (
                    <div className={"mb-2"}>
                        <h3 className="text-lg font-medium text-tm-gray-700 mb-1">
                            {group}
                        </h3>
                        {this.renderLookups(items)}
                        {(items.length === 0) && (<div>{translate("text.no_items")}</div>)}
                    </div>
                )
            })
        } else if (!!includeLookups.length || !!excludeLookups.length) {
            lookups = this.renderLookups(
                Object.keys(data).filter(
                    (it) => includeLookups.includes(it) && !excludeLookups.includes(it)
                )
            )
        }

        const selectedLookup = this.state.selectedLookup && data[this.state.selectedLookup]
            .filter(item => !(FieldsManager.getFieldKeyValues(this.state.searchFields)['LookupItemName'] && !item[this.state.selectedLookup].toLowerCase().includes(FieldsManager.getFieldKeyValues(this.state.searchFields)['LookupItemName'].toLowerCase())) && this.getPrefilteredLookup(item))
            .map(item => {
                return (
                    <Card addClass={'mb-3'}>
                        <div
                            className="flex justify-between items-center">
                            <div className="w-50">
                                <h6>
                                    {item[this.state.selectedLookup]}
                                </h6>
                            </div>
                            <div className="w-50 text-right">
                                {item[`${this.state.selectedLookup}SubItems`] && (
                                    <Tippy content={<span>Add SubType</span>}>
                                        <button
                                            className="btn-table-action"
                                            onClick={() => this.handleToggleCreateSubTypeModal(item)}
                                        >
                                            <PlusCircleIcon className="h-5 w-5"/>
                                        </button>
                                    </Tippy>
                                )}
                                {checkPerm(Resources.Lookup, UPDATE_PERM) &&
                                    <Tippy content={<span>Edit</span>}>
                                        <button
                                            className="btn-table-action"
                                            onClick={() => this.handleToggleEditModel(item)}
                                        >
                                            <PencilIcon className="h-4 w-4"/>
                                        </button>
                                    </Tippy>
                                }
                                {checkPerm(Resources.Lookup, DELETE_PERM) &&
                                    <Tippy content={<span>Delete</span>}>
                                        <button
                                            className="btn-table-action"
                                            onClick={() => this.handleToggleConfirmModal(item)}
                                        >
                                            <TrashIcon className="h-4 w-4"/>
                                        </button>
                                    </Tippy>
                                }
                            </div>
                        </div>
                        {item[`${this.state.selectedLookup}SubItems`] && item[`${this.state.selectedLookup}SubItems`].length === 0 && (
                            <InfoParagraph
                                message={translate(`text.${this.state.selectedLookup}`) + ' must have at least one sub spec in order to work correctly.'}
                            />
                        )}
                        {item[`${this.state.selectedLookup}SubItems`] && item[`${this.state.selectedLookup}SubItems`].length > 0 && (
                            <div>
                                <h6>SubSpecs for {item[this.state.selectedLookup]} </h6>

                                {item[`${this.state.selectedLookup}SubItems`].map((elem, i) => {
                                    return (
                                        <div
                                            className="flex justify-between items-center ml-4 border-b mt-2 mb-2">
                                            <div className="w-50">
                                                <p>
                                            <span
                                                className="font-bold">{i + 1}.</span> {elem[`${this.state.selectedLookup}SubItem`]}
                                                </p>
                                            </div>
                                            <div className="w-50 text-right">
                                                <Tippy content={<span>Edit</span>}>
                                                    <button
                                                        className="table-action mx-1 bg-inverse p-2 rounded-full text-inverse hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2  bg-tm-gray-200-50"
                                                        onClick={() => this.handleToggleEditSubTypeModal(elem)}
                                                    >
                                                        <PencilIcon className="h-4 w-4"/>
                                                    </button>
                                                </Tippy>
                                                <Tippy content={<span>Delete</span>}>
                                                    <button
                                                        className="table-action mx-1 bg-inverse p-2 rounded-full text-inverse hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2  bg-tm-gray-200-50"
                                                        onClick={() => this.handleToggleSubTypeConfirmModal(elem)}
                                                    >
                                                        <TrashIcon className="h-4 w-4"/>
                                                    </button>
                                                </Tippy>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </Card>
                )
            })

        const fieldsHtml = this.state.createFields.map((item, index) => {
            const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, item)), translate, (name, value) => this.handleInputChange(name, value, index), {})

            return (
                <div key={index} className={'mb-5'}>
                    <div className={'flex justify-end'}>
                        <XMarkIcon onClick={() => this.handleRemoveItem(index)}
                                   className={'w-5 h-5 cursor-pointer'}/>
                    </div>
                    <div className={'grid gap-4 grid-cols-3'}>
                        {itemToHtml}
                    </div>
                </div>
            )
        })

        const fieldLookupNameHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.searchFields, ['LookupName']))), translate, this.handleSearchInputChange, {})
        const fieldLookupItemNameHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.searchFields, ['LookupItemName']))), translate, this.handleSearchInputChange, {})

        return (
            <React.Fragment>
                <div className="pb-20">
                        <PageHeader
                            title={translate('text.CustomFields')}
                            titleClass="mr-5 text-2xl m-4"
                        />

                    <Card>
                        {resource.isLoading && (
                            <div className="m-5 text-center">
                                <LoaderSmall/>
                            </div>
                        )}

                        {!resource.isLoading && (
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-full xl:col-span-4">
                                    <Card addClass={'relative'}>
                                        <div className="sticky top-0 bg-inverse pb-3">
                                            {fieldLookupNameHtml}
                                        </div>
                                        {lookups}
                                    </Card>
                                </div>

                                <div className="col-span-full 2xl:col-span-8">
                                    <Card>
                                        {this.state.selectedLookup ? (
                                                <div>
                                                    <div className="flex justify-between items-center mb-3">
                                                        <h5 className="text-xl m-0 p-0">
                                                            {translate(`text.${this.state.selectedLookup}`)}
                                                        </h5>

                                                        {checkPerm(Resources.Lookup, CREATE_PERM) && (
                                                            <button
                                                                className="btn btn-primary btn-lg btn-shadow ml-2"
                                                                onClick={this.handleToggleCreateModal}
                                                            >
                                                                {translate('btn.create')}
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        {fieldLookupItemNameHtml}
                                                    </div>
                                                    {selectedLookup}
                                                </div>
                                            )
                                            :
                                            <p className="text-center">Select Item</p>
                                        }
                                    </Card>
                                </div>
                            </div>
                        )}
                    </Card>
                </div>

                <ModalDefault
                    show={this.state.createModalOpen}
                    widthClass={'max-w-2xl'}
                    title={'Create new ' + translate(`text.${this.state.selectedLookup}`)}

                    onButtonClick={this.createNewLookup}
                    buttonLabel={translate('btn.save')}
                    buttonDisabled={!this.state.canSubmit}

                    closeButtonLabel={translate('btn.Cancel')}
                    onClose={this.handleToggleCreateModal}
                >
                    <div className="p-5">
                        {fieldsHtml}

                        <button
                            className="table-action flex items-center mx-1 bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                            onClick={this.handleAddItem}
                        >
                            {translate('text.AddNewItem')}
                            <PlusCircleIcon className="h-4 w-4 ml-3"/>
                        </button>
                    </div>
                </ModalDefault>

                <ModalSaveResource
                    show={this.state.editSubTypeModalOpen}
                    title={'Edit SubType for ' + translate(`text.${this.state.selectedLookup}`)}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={() => this.handleToggleEditSubTypeModal()}
                    errorMessage={this.state.errorMessage}
                    fields={this.getSubTypeFields(this.state.selectedSubItem)}
                    onSubmit={(params) => {
                        this.editSubTypeLookup(params)
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalSaveResource
                    show={this.state.createSubTypeModalOpen}
                    title={'Create new SubType for ' + translate(`text.${this.state.selectedLookup}`)}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateSubTypeModal}
                    errorMessage={this.state.errorMessage}
                    fields={this.getSubTypeFields()}
                    onSubmit={(params) => {
                        this.createSubTypeLookup(params)
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalSaveResource
                    show={this.state.editModalOpen}
                    title={'Edit ' + translate(`text.${this.state.selectedLookup}`)}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    visible={this.state.editModalOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        this.editLookup(params)
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmModalOpen}
                    text={'Are you sure you want to delete this settings?'}
                    onClose={() => this.setState({confirmModalOpen: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.deleteLookup}
                />

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmSubItemModalOpen}
                    text={'Are you sure you want to delete this sub settings?'}
                    onClose={() => this.setState({confirmSubItemModalOpen: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.deleteSubTypeLookup}
                />

            </React.Fragment>
        )
    }
}
