import React, {useRef, useState} from 'react';
import DraggableWidgetListItem from "./draggable-widget-list-item";
import ModalDefault from "../../common/components/modal/modal-default";

export default function DashboardSettings({show, translate, onClose, dashboardItems, onReset, setDashboardItems}) {
    const selectedItemRef = useRef();
    const [canDrag, setCanDrag] = useState(false);
    const [draggedItem, setDraggedItem] = useState(undefined);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [draggedIntoArea, setDraggedIntoArea] = useState("");
    const [isDragOverEmptyArea, setIsDragOverEmptyArea] = useState(false);

    const dashboardItemsValues = Object.values(dashboardItems);

    function toggleVisible(item) {
        setDashboardItems(
            Object.assign({}, dashboardItemsValues.reduce((memo, listItem) => {
                if (listItem.name === item.name) {
                    listItem.visible = !listItem.visible;
                }
                memo[listItem.name] = listItem;
                return memo;
            }, {}))
        )
    }

    function handleDragEnter(item, area) {
        selectedItemRef.current = item;
        setDraggedIntoArea(area);
        setSelectedItem(item);
    }

    function handleDragStart(item) {
        setDraggedItem(item)
    }

    function handleDragEnd() {
        const selectedItemOrder = selectedItemRef?.current?.order;

        const draggedItemName = draggedItem.name;

        const draggedItemNewOrder = selectedItemRef?.current?.isLast ? selectedItemOrder + 1 : selectedItemOrder;

        setDashboardItems(Object.assign({}, dashboardItemsValues.reduce((memo, listItem) => {
            if (draggedItemName === listItem.name) {
                listItem.order = draggedItemNewOrder - 1;
                listItem.position = draggedIntoArea;
            } else if (draggedItemNewOrder > listItem.order) {
                listItem.order = listItem.order - 1;
            }

            memo[listItem.name] = listItem;
            return memo;
        }, {})))

        setDraggedItem(undefined);
        setSelectedItem(undefined);
    }

    const mainAreaFilters = dashboardItemsValues.filter(it => it.position === "main");
    const sidebarAreaFilters = dashboardItemsValues.filter(it => it.position === "sidebar");

    return (
        <ModalDefault
            show={show}
            widthClass="max-w-3xl"
            title={translate('modal_heading.dashboard_widgets')}
            hideDialogFooter={true}
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
            translate={translate}
        >
            <div className="bg-tm-gray-100">
                <div className="p-6 space-y-8 mx-auto max-w-lg">
                    <div>
                        <h5 className="text-tm-gray-900 text-lg">Main area</h5>

                        <div className="space-y-3 mt-6">
                            {mainAreaFilters
                                .sort((a, b) => a.order - b.order)
                                .map((item, i) => {
                                    return <DraggableWidgetListItem
                                        key={item.name}
                                        isLast={mainAreaFilters.length - 1 === i}
                                        item={item}
                                        area={'main'}
                                        selectedItem={selectedItem}
                                        draggedItem={draggedItem}
                                        canDrag={canDrag}
                                        setCanDrag={setCanDrag}
                                        handleDragStart={handleDragStart}
                                        handleDragEnd={handleDragEnd}
                                        handleDragEnter={handleDragEnter}
                                        toggleVisible={toggleVisible}
                                        setSelectedItem={setSelectedItem}
                                        isDragOverEmptyArea={isDragOverEmptyArea}
                                        translate={translate}
                                    />
                                })
                            }
                        </div>

                        {!mainAreaFilters.length && (
                            <div className="relative">
                                <div className="h-24 border-2 border-tm-gray-200 text-base font-bold text-tm-gray-600 flex items-center justify-center rounded-card"
                                     onDragOver={() => setIsDragOverEmptyArea(true)}
                                     onDragEnter={() => handleDragEnter(Object.values(sidebarAreaFilters)[sidebarAreaFilters.length - 1], 'main')}
                                     onDragLeave={() => {
                                         setSelectedItem(undefined);
                                         setIsDragOverEmptyArea(false);
                                     }}
                                >
                                    Main area is empty
                                </div>

                                {isDragOverEmptyArea && (
                                    <div
                                        className='w-full h-1 bg-primary absolute z-0 top-0 left-0 right-0 rounded-card'></div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="pt-8 pb-24">
                        <h5 className="text-tm-gray-900 text-lg">Sidebar area</h5>

                        <div className="space-y-3 mt-6">
                            {sidebarAreaFilters
                                .sort((a, b) => a.order - b.order)
                                .map((item, i) => {
                                    return <DraggableWidgetListItem
                                        key={item.name}
                                        area={'sidebar'}
                                        item={item}
                                        isLast={sidebarAreaFilters.length - 1 === i}
                                        selectedItem={selectedItem}
                                        draggedItem={draggedItem}
                                        canDrag={canDrag}
                                        setCanDrag={setCanDrag}
                                        handleDragStart={handleDragStart}
                                        handleDragEnd={handleDragEnd}
                                        handleDragEnter={handleDragEnter}
                                        toggleVisible={toggleVisible}
                                        setSelectedItem={setSelectedItem}
                                        isDragOverEmptyArea={isDragOverEmptyArea}
                                        translate={translate}
                                    />
                                })
                            }

                            {!sidebarAreaFilters.length && (
                                <div className="relative">
                                    <div className="h-24 border-2 border-tm-gray-200 text-base font-bold text-tm-gray-600 flex items-center justify-center rounded-card"
                                         onDragOver={() => setIsDragOverEmptyArea(true)}
                                         onDragEnter={() => handleDragEnter(Object.values(mainAreaFilters)[mainAreaFilters.length - 1], 'sidebar')}
                                         onDragLeave={() => {
                                             setSelectedItem(undefined);
                                             setIsDragOverEmptyArea(false);
                                         }}
                                    >
                                        Sidebar area is empty
                                    </div>

                                    {isDragOverEmptyArea && (
                                        <div
                                            className='w-full h-1 bg-primary absolute z-0 top-0 left-0 right-0 rounded-card'></div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="pb-8 text-center">
                    <button
                        onClick={onReset}
                        className="btn btn-outline"
                    >
                        Reset to default
                    </button>
                </div>
            </div>
        </ModalDefault>
    )
}

