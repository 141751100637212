import React from "react";
import {classNames} from "../../../util/util-helpers";

export default function TableCard({id, className, addClass, children, isRounded = true}) {
    return (
        <div className={
            classNames(
                "h-full md:overflow-hidden relative",
                isRounded ? "rounded-card" : undefined
            )
        }>
            <div
                id={id}
                className={
                    className
                        ? className
                        : classNames(
                            "flex flex-col max-h-full md:bg-inverse md:border border-tm-gray-300 md:shadow-card",
                            isRounded ? "rounded-card" : undefined,
                            addClass
                        )
                }
            >
                {children}
            </div>
        </div>
    )
}
