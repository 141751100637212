import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS, MAXIMUM_DOCUMENT_UPLOAD_SIZE} from "../../../util/util-constants"

export default function DropZoneAlt({
                                        content,
                                        maxFilesAccepted = 1,
                                        onAcceptFiles,
                                        accept = DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
                                        className = "",
                                        maxSize = MAXIMUM_DOCUMENT_UPLOAD_SIZE
                                    }) {
    const onDrop = useCallback(acceptedFiles => {
        onAcceptFiles(acceptedFiles);
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({multiple: maxFilesAccepted > 1, maxFiles: maxFilesAccepted, onDrop, accept: accept,maxSize: maxSize});

    return (
        <div
            className={className}
            {...getRootProps()}
        >
            <input {...getInputProps()} />

            {content(isDragAccept, isDragReject)}
        </div>
    )
}