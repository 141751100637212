import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import {ClipboardDocumentListIcon, ArrowPathIcon, TrashIcon} from '@heroicons/react/24/outline'
import NoRecordsTable from "../../no-records-found/no-records-table";
import {getProp, returnOffsetAndPagination} from "../../../util/util-helpers";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import {enumerateDaysBetweenDates} from "../../../util/util-dates";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {checkPerm, resourceIsDeleted} from "../../../util/util-helpers";
import TableCard from "../../resource-table/table-components/table-card";
import PageHeader from "../../layout/layout-components/page/page-header";
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS
} from "../../../../util/util-constants";
import ResourceTable from "../../resource-table";
import TableFooter from "../../resource-table/table/table-footer";
import Pagination from "../../resource-table/table-components/pagination";
import ModalSaveResource from "../../modal/modal-save-resource";
import ModalConfirm from "../../modal/modal-confirm";
import {fillFieldsFromData} from "../../../util/util-fields";


export default class VacationTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getCreateFields(),
            editFields: this.getEditFields(),

            // Create/Edit modal
            selectedItem: null,
            createModalOpen: false,
            editModalShow: false,

            // Delete modal
            confirmModal: false,
            confirmModalAnimation: false
        }
    }

    componentDidMount = () => {
        !this.props.isCreate && this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource !== this.props.resource && this.getID()) {
            this.setState({fields: this.getCreateFields()})
        }

        if (resourceIsDeleted(this.props.resource) && !!this.state?.confirmModal) {
            this.setState({
                confirmModal: false
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: this.getQuery(),
        }));
    };

    archiveResource = (item, i) => {
        this.setState({confirmText: this.props.translate("message.confirm_delete_vacation")}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, "list", []), this.getQuery(), this.state.paginationPage),
                }, () => {
                    if (this.props.isCreate) {
                        let list = getProp(this.props.resource.data, [this.getResourceName()], [])
                        list.splice(i, 1)
                        this.props.onCreateNewData([this.getResourceName()], list)
                        this.setState({ confirmModal: false })
                    } else {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                [this.getPrimaryKey()]: item[this.getPrimaryKey()],
                                id: this.getID()
                            },
                            errorMessage: true, successMessage: `Time off has been deleted`,
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }))
                    }
                })
            }, item)
        })
    }

    handleShowConfirmDialog = (submit = null) => {
        this.setState({
            confirmModal: submit
        })
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmModalAnimation: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    confirmModalAnimation: false,
                    confirmModal: false
                })
            }, 500)
        })
    };

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData();
        })
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchData();
        })
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    onCreateNew = (params) => {
        let list = getProp(this.props.resource.data, this.getResourceName(), []);
        list.push(params);
        this.props.onCreateNewData(this.getResourceName(), list)
    }

    getCreateFields = () => {
        const excludeDates = []
        getProp(this.props.resource, "data.list", []).forEach(item => {
            enumerateDaysBetweenDates(item.StartVacation, item.EndVacation).forEach(date => excludeDates.push(date))
        })
        return {
            VacationID: new Field('VacationID', '', ['empty'], false, "select", {addContainerClass: "col-span-full"}),
            VacationTimezone: new Field("VacationTimezone", 'UTC', [], false, 'hidden', {
                hideTable: true
            }),
            VacationStatus: new Field("VacationStatus", '', [], false, '', {
                hideDialog: true
            }),
            RequestCreatedDateTime: new Field("RequestCreatedDateTime", '', [], false, 'datetimezone', {
                hideDialog: true
            }),
            StatusUpdateByID: new Field("StatusUpdateByID", '', [], false, 'select-search', {
                hideDialog: true
            }),
            StartVacation: new Field('StartVacation', '', ['empty'], false, 'date', {
                addContainerClass: "col-span-full"
            }, {
                excludeDates: excludeDates
            }),
            EndVacation: new Field('EndVacation', '', ['empty'], false, 'date', {
                addContainerClass: "col-span-full"
            }, {
                excludeDates: excludeDates
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}),
        }
    }

    getEditFields = (item = null) => {
        let fieldTemplates = {
            VacationStatusID: new Field("VacationStatusID", '', ['empty'], false, 'select', {addContainerClass: "col-span-full"}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            id: this.getID()
        }
    }

    getPrimaryKey = () => {
        return this.props.primaryKey
    }

    getID = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName
    }

    render() {
        const {translate, resource} = this.props;

        const dataItems = getProp(resource, "data.list", [])
        const isLoading = resource?.isLoading;
        const count = getProp(resource, "data.count", 0);

        return (
            <React.Fragment>
                    {/* Create button */}
                    <div className="px-6 py-3">
                        <PageHeader
                            title={translate("text.Vacations")}
                            titleClass="mr-5 text-2xl"
                            buttonLabel={translate("btn.create_new")}
                            onButtonClick={() => this.handleToggleCreateModal()}
                            buttonHidden={!checkPerm(this.getResourceName(), CREATE_PERM)}
                        >
                            <button className="btn btn-header ml-auto" onClick={this.fetchData}>
                                <ArrowPathIcon className="w-5 h-5" />
                            </button>

                        </PageHeader>
                    </div>

                <TableCard>
                    <ResourceTable
                        tableID="agent-vacation"
                        options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}
                        data={dataItems}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                tooltipText: () => translate("btn.edit_approval"),
                                action: (it) => this.handleToggleEditModel(it),
                                icon: () => ClipboardDocumentListIcon,
                                visible: () => !this.props.isCreate
                            },
                            {
                                tooltipText: () => translate("btn.delete"),
                                action: (it) => this.archiveResource(it),
                                icon: () => TrashIcon,
                                visible: () => checkPerm(this.getResourceName(), DELETE_PERM)
                            },
                        ]}
                    />

                    <TableFooter
                        show={!(!dataItems.length && !isLoading)  && count > this.state.limit}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleUpdateOffset(name, value)
                            }
                            pageOffset={this.state.offset}
                            pageLimit={this.state.limit}
                            translate={translate}
                        />
                    </TableFooter>

                    <NoRecordsTable
                        show={(dataItems.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResourceName(), CREATE_PERM)}
                        title={translate("text.no_records")}

                        text={translate("text.create_time_off")}
                        btnLabel={translate("btn_create_time_off")}
                        onBtnClick={() => {
                            this.handleToggleCreateModal()
                        }}
                        clearFilterBtnLabel={translate("text.clear_all_filters")}
                        clearFilterText={translate("text.try_without_filters")}
                        filters={{}}
                    />
                </TableCard>

                <ModalSaveResource
                    title={"Create " + (this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-md"
                    show={this.state.createModalOpen}
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getCreateFields()}
                    onSubmit={(params) => {
                        if (params) {
                            if (!this.props.isCreate) {
                                params.id = this.getID();
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true,
                                    successMessage: this.props.dialogTitle + " created."
                                }));
                            } else {
                                this.onCreateNew(params)
                            }

                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "StartVacation" && value > fields.EndVacation.value) {
                            fields.EndVacation.value = ''
                            fields.StartVacation.value = value

                        } else if (name === "EndVacation" && value < fields.StartVacation.value) {
                            fields.StartVacation.value = ''
                            fields.EndVacation.value = value
                        }
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalSaveResource
                    title={translate("text.EditApproval")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getID();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: this.props.dialogTitle + " updated."
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModal}
                    text={this.state.confirmText}
                    onClose={() => this.setState({ confirmModal: false })}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

            </React.Fragment>
        )
    }
}
