import React from 'react';
import {
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_IN_TRANSIT,
    LOAD_STATUS_POSSIBLE,
    LOAD_STATUS_READY,
    LOAD_STATUS_RESERVED
} from "../../../../../util/util-constants";
import {classNames, getLookup} from "../../../../util/util-helpers";

const StatusProgressTable = ({LoadStatusID, translate, htmlAfterStatus}) => {
    const loadStatusLookup = getLookup("LoadStatus", "LoadStatusID", "LoadStatus");

    if (!loadStatusLookup) {
        return null;
    }

    const loadStatusesArray = Object.values(loadStatusLookup);
    let loadStatus = loadStatusesArray[LoadStatusID - 1];
    if (!loadStatus) {
        loadStatus = translate("text.canceled")
    }

    const getBadgeClass = () => {
        switch (+LoadStatusID) {
            case LOAD_STATUS_DELIVERED:
                return "bg-green-600/5 text-green-600"
            case LOAD_STATUS_POSSIBLE:
            case LOAD_STATUS_READY:
            case LOAD_STATUS_RESERVED:
                return "bg-gray-600/10 text-tm-gray-600"
            case LOAD_STATUS_DISPATCHED:
            case LOAD_STATUS_IN_TRANSIT:
                return "bg-amber-600/5 text-amber-600"
            case undefined:
                return "bg-gray-400/5 text-tm-gray-600"
            default:
                return "bg-red-600/5 text-red-600"
        }
    }

    return (
        <div
            className={classNames("leading-5 inline-block px-3 rounded-md text-sm font-semibold", getBadgeClass())}
        >
            {loadStatus} {htmlAfterStatus}
        </div>
    )
}

export default StatusProgressTable;
