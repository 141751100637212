import React, {Component} from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';
import Days from "../day-select";

export default class MonthlyCron extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hour: 0,
            minute: 0
        };

        this.onAtDayChange = this.onAtDayChange.bind(this);
        this.onAtHourChange = this.onAtHourChange.bind(this);
        this.onAtMinuteChange = this.onAtMinuteChange.bind(this);
    }

    componentWillMount() {
        this.state.value = this.props.value;
    }

    onAtDayChange(e) {
        let val = [this.state.value[0], this.state.value[1], '*', this.state.value[3], '*']
        val[2] = e.target.value ? `${e.target.value}` : '1';
        this.props.onChange(val);
    }

    onAtHourChange(e) {
        let val = this.state.value;
        val[1] = `${e.target.value}`;
        this.props.onChange(val)
    }

    onAtMinuteChange(e) {
        let val = this.state.value;
        val[0] = `${e.target.value}`;
        this.props.onChange(val)
    }

    render() {
        const translateFn = this.props.translate;

        this.state.value = this.props.value;

        return (
            <div className="my-2 py-6 flex flex-col justify-start space-y-3">
                <div className="flex items-center justify-between space-x-3">
                    <span className="text-sm font-semibold text-tm-gray-900">{translateFn('Day')}</span>

                    <div className="flex items-center space-x-3">
                        <Days onChange={this.onAtDayChange} value={this.state.value[2]}/>

                        <div className="w-28">{translateFn('of every month(s)')}</div>
                    </div>
                </div>

                <div className="pr-[7.75rem] flex justify-between items-center">
                    <span className="text-sm font-semibold text-tm-gray-900 ">{translateFn('At')}</span>
                    <div className="space-x-3">
                        <Hour disabled={this.state.every} onChange={this.onAtHourChange}
                              value={this.state.value[1]}/>
                        <Minutes disabled={this.state.every} onChange={this.onAtMinuteChange}
                                 value={this.state.value[0]}/>
                    </div>
                </div>
            </div>
        )
    }
}

