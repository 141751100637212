import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import LoadStepsPopup from "../load-steps-popup";

export default function LoadStepsCoveredStatus({
                                                   fields,
                                                   fieldsRefs,
                                                   loadStatus,
                                                   loadSettings,
                                                   submitButtonRef,
                                                   setAreStepsActive,
                                                   dispatchedStatusRef,
                                                   currentlyFocusedElement,

                                                   stops,
                                                   element,
                                                   setElement,
                                                   suggestedStopField,
                                                   suggestNextStopField,
                                                   setSuggestedStopField,
                                                   findNextEmptyStopField,
                                                   areAllRequiredStopsFieldFilled
                                               }) {
    const isCustomerRefRequired = !!loadSettings?.RequireCustomerRefDispatch;
    const totalSteps = isCustomerRefRequired ? 3 : 2;
    const [currentField, setCurrentField] = useState(!fields.CustomerReferenceNumber.value ? "CustomerReferenceNumber" : "");


    function handleStepCloseClick() {
        setElement(undefined); // Erase popup element
        setAreStepsActive(false);
    }

    function suggestNextLoadStatus() {
        if (dispatchedStatusRef.current) {
            setElement(createPortal(
                <LoadStepsPopup
                    title="Update load status"
                    currentElement={dispatchedStatusRef.current}
                    currentlyFocusedElement={currentlyFocusedElement}
                    addClass="w-[500px]"
                    totalSteps={totalSteps}
                    currentStep={1}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                You can now advance to Dispatched status.
                            </p>

                            <p>
                                Press the Dispatched status or Next button.
                            </p>
                        </>
                    }
                    onNextClick={() => {
                        dispatchedStatusRef.current.click();
                    }}
                />,
                dispatchedStatusRef.current
            ));
        }
    }

    function saveCoveredStep() {
        if (submitButtonRef.current.parentNode) {
            setElement(createPortal(
                <LoadStepsPopup
                    position="top-left"
                    addClass="mt-8"
                    title="Save changes"
                    stepText={
                        <>
                            <p>
                                Now you can save dispatch status change.
                            </p>
                        </>
                    }
                    totalSteps={totalSteps}
                    currentStep={isCustomerRefRequired ? 1 : 2}
                    onCloseClick={handleStepCloseClick}
                    onNextClick={() => {
                        submitButtonRef.current.click();
                    }}
                />,
                submitButtonRef.current.parentNode
            ));
        }
    }

    function suggestCarrierRefField() {
        const inputRef = fieldsRefs.CustomerReferenceNumber?.current;
        if (inputRef) {
            setElement(createPortal(
                <LoadStepsPopup
                    title="Enter customer reference number"
                    currentElement={inputRef}
                    currentlyFocusedElement={currentlyFocusedElement}
                    position="top-left"
                    addClass="w-[500px]"
                    totalSteps={totalSteps}
                    currentStep={1}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                This field is set as required in Dispatch settings for Dispatched load status.
                            </p>
                        </>
                    }
                    isNextDisabled={!fields.CustomerReferenceNumber?.value}
                    onNextClick={() => {
                        setCurrentField("");
                    }}
                />,
                inputRef.parentNode
            ));
        }
    }

    function getSuggestedStep() {
        const areStopFieldsFilled = !findNextEmptyStopField();

        if (areStopFieldsFilled) {
            setSuggestedStopField(null)
        }

        if (!areStopFieldsFilled) {
            setSuggestedStopField(findNextEmptyStopField())
        } else if (isCustomerRefRequired && loadStatus !== 5 && currentField === 'CustomerReferenceNumber') {
            suggestCarrierRefField();
        } else if (loadStatus !== 5) {
            suggestNextLoadStatus();
        } else {
            saveCoveredStep();
        }
    }

    // Logic for adding new stops
    useEffect(() => {
        if (suggestedStopField && !areAllRequiredStopsFieldFilled) {
            suggestNextStopField(getSuggestedStep)
        }
    }, [suggestedStopField, stops]);

    useEffect(() => {
        if (loadStatus === 5) {
            getSuggestedStep();
        }
    }, [loadStatus]);

    useEffect(() => {
        getSuggestedStep();
    }, [fields]);

    useEffect(() => {
        if (currentField === '') {
            getSuggestedStep();
        }
    }, [currentField]);

    return element ? element : null;
}
