import React, {useEffect, useState} from 'react'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import {FieldsManager} from '../../../data/services/fields'
import {fieldsToHtml} from "../../../common/util/util-fields";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalFooter from "../../../common/components/modal/modal-footer";

export default function TaxReportDialog({
                                            translate,
                                            onClose,
                                            title,
                                            queryFilterFields,
                                            tableFields,
                                            isLoading,
                                            data,
                                            onFetchData
                                        }) {
    const [query, setQuery] = useState(queryFilterFields)
    const [fields, setFields] = useState(tableFields)

    const handleInputChange = (name, value) => {
        setQuery(FieldsManager.updateField(query, name, value))
    }

    const queryToHtml = fieldsToHtml(Object.values(Object.assign({}, query)), translate, handleInputChange)

    const fetchData = () => {
        onFetchData(query)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        fetchData()
    }, [query])

    return (
        <div>
            <header
                className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900">
                <h2 className="text-lg text-current truncate">{title}</h2>

                <button
                    className="absolute right-5 top-5 focus:ring-2 focus:ring-offset-2 focus:ring-primary rounded-full"
                    aria-label="Close"
                    onClick={() => {
                        onClose()
                    }}>
                    <XMarkIcon className="w-5 h-5"/>
                </button>
            </header>

            <div className="p-5 grid grid-cols-4 gap-5">{queryToHtml}</div>

            <div className="p-5">
                <ResourceTable
                    data={data}
                    fields={fields}

                    translate={translate}
                    isLoading={isLoading}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    title={translate('text.no_matching_records')}
                />
            </div>

            <ModalFooter
                // TODO: Pay button
                closeButtonLabel={translate('btn.Cancel')}
                onClose={onClose}
            />
        </div>
    )
}
