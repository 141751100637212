import {Field, FieldsManager} from "../../../data/services/fields";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import React, {useEffect, useState} from "react";
import {checkPerm, getProp} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../data/actions/secondResource";
import LocalStorage from "../../../util/localStorage";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {TrashIcon} from "@heroicons/react/24/outline";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../common/components/resource-table";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import {formatMoney} from "../../../common/util/util-formaters";


const PayAdjustmentsSection = ({translate, dispatch, ContactID, secondResource, resourceName}) => {

    const payAdjustmentsData = getProp(secondResource, 'data.list', {})
    const payAdjustmentsIsLoading = getProp(secondResource, 'isLoading', false)

    /** Helpers
     ================================================================= */
    const getPayAdjustmentsResource = () => {
        return resourceName
    }

    const getPayAdjustmentsQuery = () => {
        return {
            searchFields: JSON.stringify({ContactID})
        }
    }

    const getPayAdjustmentsFields = (item = null) => {
        let fieldTemplates = {
            Notes: new Field('Notes', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Deduction: new Field('Deduction', Number(item?.Deduction) === 1 ? 1 : 0, [''], false, 'button-group', {
                data: {1: 'Deduction', 0: 'Addition'},
                addContainerClass: 'col-span-full',
                render: (it) => !it.Deduction
                    ? <div className="flex gap-x-2">
                        <PlusCircleIcon className="text-green-600 w-5 h-5"/>
                        {translate("text.addition")}
                    </div>
                    : <div className="flex gap-x-2">
                        <MinusCircleIcon className="text-red-600 w-5 h-5"/>
                        {translate("text.deduction")}
                    </div>
            }),
            IncomeAccountID: new Field('IncomeAccountID', '', Number(item?.Deduction) !== 1 ? [''] : ['empty'], false, Number(item?.Deduction) !== 1 ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                label: "account"
            }),
            ExpenseAccountID: new Field('ExpenseAccountID', '', Number(item?.Deduction) === 1 ? [''] : ['empty'], false, Number(item?.Deduction) === 1 ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                label: "account",
                render: (it) => !!it.Deduction ? it.IncomeAccount : it.ExpenseAccount
            }),
            /* ScheduledDeductionsType: new Field('ScheduledDeductionsType', Number(item?.ScheduledDeductionsType) === 2 ? 2 : 1, [], false, 'button-group', {
                 data: {1: 'Fixed amount', 2: 'Per mile'},
                 addContainerClass: 'col-span-full',
                 render: (item) => (Number(item?.ScheduledDeductionsType) === 1)
                     ? <div className="flex gap-x-2">
                         {this.props.translate("text.fixed_amount")}
                     </div>
                     : <div className="flex gap-x-2">
                         {this.props.translate("text.per_mile_amount")}
                     </div>
             }),*/
            Amount: new Field('Amount', '', ['float'], false, Number(item?.ScheduledDeductionsType) !== 2 ? 'float' : 'hidden', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    return formatMoney(!!item?.Amount && !!item?.Amount > 0 ? item?.Amount : item?.AmountPer)
                }
            }),
            /* AmountPer: new Field('AmountPer', '', [], false, Number(item?.ScheduledDeductionsType) === 2 ? 'float' : 'hidden', {
                 addContainerClass: 'col-span-full'
             }),*/
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            }),
            IsForever: new Field('IsForever', '1', [], false, 'radio', {
                options: [{name: 'Always', value: '1'}, {name: 'Max Amount', value: '2'}],
                addContainerClass: 'col-span-full',
                render: (it) => !it.IsForever ? <CheckIcon className="w-5 h-5 text-green-600"/> :
                    <XMarkIcon className="w-5 h-5 text-red-600"/>
            }),
            MaxAmount: new Field('MaxAmount', '', ['float_or_empty'], true, 'float', {
                addContainerClass: 'col-span-full'
            }),
            Active: new Field('Active', 1, [], false, 'checkbox', {
                addContainerClass: 'col-span-full'
            })
        }

        if (item) {
            fieldTemplates = fillFieldsFromData(fieldTemplates, item)
            if (!item.IsForever) {
                if (item.MaxAmount) {
                    fieldTemplates.MaxAmount.disabled = false
                    fieldTemplates.IsForever.value = '2'
                }
            } else {
                fieldTemplates.IsForever.value = '1'
            }
        }
        return fieldTemplates
    }


    /** State
     ================================================================= */
    const [payAdjustmentsFields, setPayAdjustmentsFields] = useState(getPayAdjustmentsFields())
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
            dispatch(getSecondResource({
                user: LocalStorage.get("user"),
                resource: getPayAdjustmentsResource(),
                query: getPayAdjustmentsQuery()
            }));
    }, [])

    /** UI Events
     ================================================================= */
    const handleToggleCreateModal = () => {
        setIsCreateModalOpen(!isCreateModalOpen)
    }

    const handleToggleUpdateModal = (item = null) => {
        setSelectedItem(item)
        setIsUpdateModalOpen(!isUpdateModalOpen)
    }

    const handleToggleConfirmModal = (item = null) => {
        setSelectedItem(item)
        setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen)
    }

    const handlePayAdjustmentsInputChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);

        if (name === 'IsForever') {
            if (value === '1') {
                fieldsUpdate.MaxAmount.value = ''
                fieldsUpdate.MaxAmount.disabled = true
            }
            if (value === '2') {
                fieldsUpdate.MaxAmount.value = ''
                fieldsUpdate.MaxAmount.disabled = false
            }
        }

        if (name === 'Deduction') {
            fieldsUpdate.IncomeAccountID.type = value === 1 ? 'select-search' : 'hidden';
            fieldsUpdate.IncomeAccountID.validate = value === 1 ? ['empty'] : ['']
            fieldsUpdate.ExpenseAccountID.value = value === 1 ? '' : fieldsUpdate.ExpenseAccountID.value

            fieldsUpdate.ExpenseAccountID.type = value === 0 ? 'select-search' : 'hidden';
            fieldsUpdate.ExpenseAccountID.validate = value === 0 ? ['empty'] : [''];
            fieldsUpdate.IncomeAccountID.value = value === 0 ? '' : fieldsUpdate.IncomeAccountID.value;
        }

        /*if (name === 'ScheduledDeductionsType') {
            fieldsUpdate.Amount.type = value === 1 ? 'float' : 'hidden';
            fieldsUpdate.AmountPer.type = value === 2 ? 'float' : 'hidden';
        }*/

        return fieldsUpdate
    }

    const metadata = {
        IncomeAccountID: {
            api: 'api/' + Resources.AccountsQuick,
            query: {
                limit: 30,
                offset: 0
            },
            searchMap: (item) => ({
                label: item.AccountNumber + ' ' + item.AccountName,
                value: item.AccountID
            })
        },
        ExpenseAccountID: {
            api: 'api/' + Resources.AccountsQuick,
            query: {
                limit: 30,
                offset: 0
            },
            searchMap: (item) => ({
                label: item.AccountNumber + ' ' + item.AccountName,
                value: item.AccountID
            })
        }
    }

    return (
        <div>
            <PageHeader
                className={"flex pb-4"}
                title={translate('page.heading.driver_pay_adjustments')}
                titleClass="mr-5 text-2xl"
                buttonLabel={translate('btn.create_new')}
                onButtonClick={handleToggleCreateModal}>
            </PageHeader>
            <Card addClass={"flex-shrink-1"} bodyClass={"overflow-hidden rounded-lg"}>
                <ResourceTable
                    data={payAdjustmentsData}
                    fields={getPayAdjustmentsFields()}

                    options={{
                        style: {
                            verticalLines: true,
                            stripedRows: true
                        },
                        classes: {
                            headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                            primaryTextClass: ''
                        }
                    }}

                    translate={translate}
                    isLoading={payAdjustmentsIsLoading}

                    actions={[
                        {
                            action: handleToggleUpdateModal,
                            icon: PencilIcon,
                            visible: () => checkPerm(getPayAdjustmentsResource(), UPDATE_PERM),
                            title: translate('text.edit'),
                        },
                        {
                            action: handleToggleConfirmModal,
                            icon: TrashIcon,
                            visible: () => checkPerm(getPayAdjustmentsResource(), DELETE_PERM),
                            title: translate('text.delete_item'),
                        }
                    ]}
                />

                <NoRecordsTable
                    show={(!payAdjustmentsData.length) && !payAdjustmentsIsLoading}
                    title={translate('text.no_matching_records')}
                    canCreate={checkPerm(getPayAdjustmentsResource(), CREATE_PERM)}
                    btnLabel={translate("btn.create_new")}
                    onBtnClick={handleToggleCreateModal}
                />
            </Card>

            <ModalSaveResource
                title={translate('dialog_heading.create_driver_deduction')}
                widthClass="max-w-md"
                show={isCreateModalOpen}
                onClose={handleToggleCreateModal}
                fields={getPayAdjustmentsFields()}
                onSubmit={(params) => {
                    if (params) {
                        dispatch(createSecondResource({
                            user: LocalStorage.get('user'),
                            query: getPayAdjustmentsQuery(),
                            params: Object.assign(params, {ContactID: ContactID}),
                            resource: getPayAdjustmentsResource(),
                            piggyResource: getPayAdjustmentsResource(),
                            errorMessage: true, successMessage: translate('message.pay_adjustment_created')
                        }))
                        handleToggleCreateModal();
                    }
                }}
                translate={translate}
                metadata={metadata}
                handleInputChange={handlePayAdjustmentsInputChange}
            />

            <ModalSaveResource
                title={translate('dialog_heading.edit_driver_deduction')}
                widthClass="max-w-md"
                show={isUpdateModalOpen}
                onClose={handleToggleUpdateModal}
                fields={getPayAdjustmentsFields(selectedItem)}
                onSubmit={(params) => {
                    if (params) {
                        dispatch(updateSecondResource({
                            user: LocalStorage.get('user'),
                            query: getPayAdjustmentsQuery(),
                            params: Object.assign(
                                params, {ContactScheduledDeductionsID: selectedItem.ContactScheduledDeductionsID}),
                            resource: getPayAdjustmentsResource(),
                            piggyResource: getPayAdjustmentsResource(),
                            errorMessage: true, successMessage: translate('message.pay_adjustment_updated')
                        }))
                        handleToggleUpdateModal();
                    }
                }}
                translate={translate}
                metadata={metadata}
                handleInputChange={handlePayAdjustmentsInputChange}
            />

            <ModalConfirm
                title={'Confirm'}
                show={isConfirmDeleteModalOpen}
                text={translate('message.are_you_sure_remove_pay_adjustment')}
                onClose={handleToggleConfirmModal}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    dispatch(deleteSecondResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign(
                            {...getPayAdjustmentsQuery()},
                            {ContactScheduledDeductionsID: selectedItem.ContactScheduledDeductionsID}),
                        errorMessage: true, successMessage: translate('message.pay_adjustment_deleted'),
                        resource: getPayAdjustmentsResource(),
                        secondPiggyResource: getPayAdjustmentsResource(),
                    }))
                    handleToggleConfirmModal()
                }}
            />
        </div>
    )
}

export default PayAdjustmentsSection