import React from "react";
import LocalStorage from "../../../util/localStorage";
import {getJWT} from "../../../util/util-auth";
import Env from "../../../../util/env";
import Resources from "../../../../data/services/resources";

const About = ({translate}) => {
    const user = LocalStorage.get('user');
    const token = getJWT().access_token;
    const ContactID = user?.Contact.ContactID;
    const CompanyName = user?.Contact.CompanyName;
    const commitHash = process.env.REACT_APP_COMMIT_HASH;

    const currentVersion = Env.getNpmPackageVersion();

    return (
        <div className={'text-center'}>
            <div className={'bg-tm-gray-200 p-5'}>
                <img className={"h-16 m-auto mb-3 rounded-2xl"} src={Env.getApiUrl("api/" + Resources.CompanyImage, {
                    token: token,
                    ContactID: ContactID
                })}
                     alt={CompanyName}
                />
                <h1 className="text-2xl">ACCUR8 TMS</h1>
            </div>

            <div className={'mt-5 text-tm-gray-500 p-5'}>
                {translate("text.CurrentVersion")} - {currentVersion}
                {!!commitHash && (
                    <p>{translate("text.GitVersion")} - {commitHash}</p>
                )}
            </div>
        </div>
    )
}

export default About
