import React from "react";
import {classNames} from "../../../util/util-helpers";

export default function WorkOrderStatusBadge({workOrderStatuses, statusID}) {
    const ScheduledStatus = [1];
    const OrangeStatuses = [2, 3, 4, 5, 6, 7, 8, 9];
    const BlueStatuses = [10,11];
    const GreenStatuses = [12];
    const LightGreenStatuses = [13];
    const DarkGreenStatuses = [14];

    function getBadgeClass(statusID) {
        if (ScheduledStatus.includes( statusID)) {
            return "bg-gray-600/10 text-tm-gray-600";
        } else if (OrangeStatuses.includes(statusID)) {
            return "bg-amber-600/10 text-amber-600"
        } else if (BlueStatuses.includes(statusID)) {
            return "bg-blue-600/5 text-blue-600";
        } if (GreenStatuses.includes(statusID)) {
            return "bg-green-600/10 text-green-700";
        } else if (LightGreenStatuses.includes(statusID)) {
            return "bg-green-400/10 text-green-500";
        }  else if (DarkGreenStatuses.includes(statusID)) {
            return "bg-green-800/10 text-green-800";
        } {
            return "bg-tm-gray-900 text-inverse";
        }
    }

   function getDescriptions(statusID) {
       const descriptions = [
           {id: 1, description: "The appointment has been scheduled, but the customer has yet to arrive."},
           {id: 2, description: "The customer has confirmed their appointment."},
           {id: 3, description: "The customer has arrived at the shop"},
           {id: 4, description: " The vehicle has been checked in and is awaiting inspection."},
           {id: 5, description: "The vehicle is being inspected to identify issues."},
           {id: 6,  description: "An estimate for the repairs has been prepared."},
           {id: 7,  description: "The customer has approved the estimate."},
           {id: 8,  description: "Necessary parts for the repair have been ordered."},
           {id: 9, description: "Ordered parts have been received and are ready for use."},
           {id: 10,  description: "Repairs are being carried out on the vehicle."},
           {id: 11, description: "The repair work is complete, and the vehicle is undergoing a final quality check."},
           {id: 12, description: "The vehicle is ready for the customer to pick up."},
           {id: 13, description: "The invoice for the repair work is being prepared."},
           {id: 14, description: "The customer has picked up the vehicle, and the invoice has been settled."}]

       return descriptions.filter((it) => it.id === statusID)?.[0]?.description

   }

    return <div className={classNames(
        "inline-flex text-center px-3 py-0.5 rounded-btn text-sm font-medium whitespace-nowrap",
        getBadgeClass(statusID)
    )} title={getDescriptions(statusID)}>
        {workOrderStatuses[statusID]}
    </div>
}