import React from 'react'
import Resources from '../../../../data/services/resources'
import {fieldsToHtml, includeFields} from "../../../../common/util/util-fields";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";
import CardSubTitle from "../../../../common/components/card/card-sub-title";
import Card from "../../../../common/components/card";
import InfoParagraph from "../../../../common/components/info-paragraph";

const BillingSection = ({fields, translate, handleInputChange}) => {
    /** Constants
     ================================================================= */
    const selects = {
        ApprovedByID: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName} - ${it.Email}`,
                value: it.ContactID
            })
        }
    }

    /** Render
     ================================================================= */
    const bondsFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['Bond', 'Expires', 'Number', 'Bank']))), translate, handleInputChange, selects)
    const documentsFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['IsSendBackupDocumentsWithInvoice', 'IsDocumentsOverridden', 'DocsSettings']))), translate, handleInputChange, selects)

    return (
        <>
            <div className="grid grid-cols-2 gap-3">
                {/* Documents
                        =================================================== */}
                <Card>
                    <CardSubTitle
                        subtitle={translate('text.Documents')}
                    />
                    <div className="grid grid-cols-1 gap-3">
                        {documentsFieldsHtml}
                    </div>
                </Card>
                {/* Bonds
                        =================================================== */}
                <Card>
                    <CardSubTitle
                        className={'mb-5'}
                        subtitle={translate('text.Bonds')}
                    />
                    <div className="grid grid-cols-1 gap-3">
                        {bondsFieldsHtml}
                    </div>
                </Card>
            </div>
        </>
    )
}

export default BillingSection
