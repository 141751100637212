import React, {Fragment, useEffect, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {classNames} from "../../util/util-helpers";

export default function Modal({
                                  initialFocusRef,
                                  children,
                                  hideOverlay,
                                  modalClass,
                                  baseClass,
                                  show = false,
                                  widthClass = "sm:max-w-lg sm:w-full",
                                  onBackdropClick = () => null,
                                  onClose
                              }) {
    const refDialog = useRef(null);

    // Close on ESC key
    useEffect(() => {
        // When mixing with old dialogs
        const isLastModalInStack = () => {
            const lastModalInStack = document.getElementById('headlessui-portal-root')?.lastChild;
            if (lastModalInStack === refDialog.current.parentElement?.parentElement) {
                return true;
            } else if (lastModalInStack === refDialog.current?.parentElement?.parentElement?.parentElement) { // fix for WO system
                return true;
            }
        }

        const escFunction = (event) => {
            if (show && event.keyCode === 27) {
                if (isLastModalInStack() && onClose) {
                    onClose();
                }
            }
        };

        if (show) {
            document.addEventListener("keydown", escFunction, false);
        }

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [show]);

    return (
        <Transition.Root
            show={show}
            as={Fragment}
        >
            <Dialog
                as="div"
                open={show} // if this is false close animation won't play out
                className="fixed z-50 inset-0 overflow-y-auto"
                initialFocus={initialFocusRef}
                onClose={onBackdropClick}
            >
                <div
                    ref={refDialog}
                    className={baseClass ?? "flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:py-0 sm:px-8"}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay
                            className={classNames(hideOverlay ? "hidden" : "", "fixed inset-0 bg-black bg-opacity-50 transition-opacity")}
                        />

                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={
                                classNames(
                                    widthClass,
                                    modalClass ?? "text-tm-gray-900 w-full inline-block align-bottom bg-popup rounded-card text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
                                )
                            }
                        >
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
