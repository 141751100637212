import React, {Component} from 'react';
import cronstrue from 'cronstrue';
import {loadHeaders, metadata} from './meta';
import ArrowPathIcon from "@heroicons/react/24/outline/ArrowPathIcon";
import {classNames} from "../../util/util-helpers";

export default class Cron extends Component {

    constructor(props) {
        super(props);
        const headers = loadHeaders(this.props.options);
        this.state = {
            headers: headers,
            locale: this.props.locale ? this.props.locale : 'en',
            selectedTab: headers[0]
        };
    }

    componentWillMount() {
        this.setValueInit(this.props.value)
        if (this.props.translateFn && !this.props.locale) {
            console.log('Warning !!! locale not set while using translateFn');
        }
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            //this.setValue(nextProps.value)
        }
    }

    setValueInit(value) {
        let prevState = this.state;
        if (!value || value.split(' ').length !== 5) {
            prevState.value = ['0', '*/1', '*', '*', '*']
            prevState.selectedTab = prevState.headers[0];
            this.parentChange(prevState.value);
        } else {
            prevState.value = value.replace(/,/g, '!').split(' ');
        }
        this.setState(prevState)
    }

    tabChanged = (tab) => {
        const value = this.defaultValue(tab);
        this.setState({selectedTab: tab}, () => {
            this.onValueChange(value);
        });
    }

    getHeaders = () => {
        return this.state.headers.map((d, index) => {
            return <button key={index}
                           className={classNames("btn", (this.state.selectedTab === d ? 'btn-primary' : 'btn-outline'))}
                           onClick={() => this.tabChanged(d)}>
                {this.translate(d)}
            </button>
        })
    }

    onValueChange(val) {
        if (!(val && val.length)) {
            val = this.defaultValue(this.state.selectedTab);
        }

        this.setState({value: val});
        this.parentChange(val)
    }

    parentChange(val) {
        let newVal = '';
        newVal = val.toString().replace(/,/g, ' ');
        newVal = newVal.replace(/!/g, ',');
        this.props.onChange(newVal)
    }

    getVal() {
        let val = cronstrue.toString(this.state.value.toString().replace(/,/g, ' ').replace(/!/g, ','))
        if (val.search('undefined') === -1) {
            return val;
        }
        return '-';
    }

    defaultValue(tab) {
        const index = this.state.headers.indexOf(tab);
        if (metadata[index] === -1) {
            return;
        }
        return metadata[index].initialCron;
    }

    getComponent = () => {
        const tab = this.state.selectedTab;
        const index = this.state.headers.indexOf(tab);

        if (index === -1) {
            return;
        }
        let selectedMetaData = metadata.find(data => data.component.name === (tab + 'Cron'))
        if (!selectedMetaData) {
            selectedMetaData = metadata[index];
        }
        if (!selectedMetaData) {
            throw new Error('Value does not match any available headers.');
        }
        const CronComponent = selectedMetaData.component;
        return <CronComponent translate={this.translate.bind(this)} value={this.state.value}
                              onChange={this.onValueChange.bind(this)}/>;
    }

    translate(key) {
        let translatedText = key;
        if (this.props.translateFn) {
            translatedText = this.props.translateFn(key);
            if (typeof translatedText !== 'string') {
                throw new Error('translateFn expects a string translation');
            }
        }
        return translatedText;
    }

    render() {
        return (
            <div className='cron-builder '>
                <div className="space-x-3">
                    {this.getHeaders()}
                </div>

                <div className="">{this.getComponent()}</div>

                {this.props.showResultText && <div
                    className="flex justify-center items-center bg-tm-gray-100 font-bold p-3 rounded-lg mb-4 border-2 border-tm-gray-200 border-dashed">
                    <ArrowPathIcon className="w-5 h-5 mr-3 text-tm-gray-700"/>
                    {this.getVal()}
                </div>}

                {this.props.showResultCron && <div
                    className="cron-builder-bg">{this.state.value.toString().replace(/,/g, ' ').replace(/!/g, ',')}</div>}
            </div>
        )
    }
}
