import React, {useEffect, useRef, useState} from 'react'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {
    createSidebarResource,
    getInfiniteSidebarResource,
    resetSidebarResource,
    updateSidebarResource
} from '../../../../data/actions/sidebarResource'
import {CREATE_PERM, DEFAULT_METADATA_SELECT_SEARCH_QUERY, EVENT_TYPES} from '../../../../util/util-constants'
import {checkPerm, ConstantTranslate, getProp} from '../../../../common/util/util-helpers'
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import LoadsEventsList from './load-events-list'
import {useDispatch, useSelector} from "react-redux";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {fieldsToHtml} from "../../../../common/util/util-fields";

const EventsTab = ({translate, loadID, disableCreateEvent}) => {
    const dispatch = useDispatch();
    const sidebarResource = useSelector((state) => state.sidebarResource);

    /** Helpers
     ================================================================= */
    const events = getProp(sidebarResource, 'data.list', [])
    const count = getProp(sidebarResource.data, 'count', 0)

    const isLoading = sidebarResource.isLoading;

    const limit = 100;
    const sort = 'DESC';
    const sortBy = 'EventDate';

    const isLoadingRef = useRef(false);
    const getResourceName = () => {
        return Resources.LoadEvents
    }

    const getQueryFilterFields = () => {
        return {
            EventType: new Field('EventType', '', [''], false, 'select', {
                addContainerClass: 'col-span-full',
                labelType: "float",
                label: "selectEventType"
            }, {
                all: true
            }),
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: disableCreateEvent ? 'col-span-full' : 'col-span-6',
                labelType: "float"
            }, {}),
        }
    }

    const getFields = () => {
        return {
            DispatchTemplates: new Field('DispatchTemplates', '', [''], false, 'select-search'),
            EventKey: new Field('EventKey', '', ['empty'], false, 'text', {
                label: 'Title',
                addContainerClass: 'col-span-3'
            }),
            EventValue: new Field('EventValue', '', ['empty'], false, 'textarea', {
                label: 'Description',
                addContainerClass: 'col-span-3'
            }, {
                rows: 10
            }),
            IsCustomerVisible: new Field('IsCustomerVisible', '', [''], false, 'checkbox', {
                label: 'visible_on_customer_shared_load',
                addContainerClass: 'col-span-3'
            }),
        }
    }

    const getQuery = () => {
        return {
            limit: limit,
            offset: offset,
            sort: sort,
            sortBy: sortBy,
            id: loadID,
            query: queryFilterFields.query.value,
            searchFields: JSON.stringify({EventType: queryFilterFields.EventType.value})
        }
    }

    const getSelect = () => {
        return {
            DispatchTemplates: {
                api: 'api/' + Resources.LoadEventTemplates,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.LoadEventTemplateID,
                    label: item.EventKey,
                    metadata: item
                })
            }
        }
    }
    /** State
     ================================================================= */

    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [offset, setOffset] = useState(0)
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFilterFields())
    const [isShareUpdate, setIsShareUpdate] = useState(false);

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        dispatch(resetSidebarResource())
        fetchData(true)
    }, [])

    useEffect(() => {
        fetchData(true)
    }, [queryFilterFields])

    useEffect(() => {
        fetchData(!offset)
    }, [offset])

    useEffect(() => {
        setOffset(+0)
    }, [queryFilterFields])

    useEffect(() => {
        if (sidebarResource.data) {
            isLoadingRef.current = false;
        }
    }, [sidebarResource])

    /** Data Events
     ================================================================= */
    const fetchData = (reset = false) => {
        dispatch(getInfiniteSidebarResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: getQuery(),
            reset: reset
        }))
    }

    const handleShareEventClick = (event) => {
        dispatch(updateSidebarResource({
            user: LocalStorage.get('user'),
            params: {
                LoadEventID: event.LoadEventID,
                IsCustomerVisible: !event.IsCustomerVisible ? 1 : 0
            },
            resource: getResourceName(),
            piggyResource: getResourceName(),
            query: getQuery(),
            errorMessage: true,
            successMessage: event.IsCustomerVisible
                ? translate("text.event_made_hidden_customer")
                : translate("text.event_made_visible_customer")
        }));

        setIsShareUpdate(true);
    }

    /** UI Events
     ================================================================= */
    const handleScroll = (e) => {
        if (
            !isLoadingRef.current
            && count > limit + offset
            && e.target.scrollHeight - e.target.offsetHeight < e.target.scrollTop + limit
        ) {
            isLoadingRef.current = true;
            setOffset(limit + offset);
        }
    }

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        setIsShareUpdate(false);
    }

    const clearFilters = () => {
        let queryFilterFieldsUpdate = queryFilterFields;

        queryFilterFieldsUpdate = Object.values(queryFilterFieldsUpdate).reduce((memo, it) => {
            it.value = "";
            memo[it.name] = it;

            return memo;
        }, {});

        setQueryFilterFields(queryFilterFieldsUpdate);
    }

    /** Render
     ================================================================= */
    return (
        <div className="h-full overflow-y-auto" id={'events-scroll-list'} onScroll={handleScroll}>

            <div className="p-4 grid gap-4 grid-cols-12">
                {fieldsToHtml(Object.values(Object.assign({}, queryFilterFields)), translate, (name, value) => handleFilterInputChange(name, value), {EventType: ConstantTranslate(EVENT_TYPES, translate)})}

                {!disableCreateEvent && (
                    <div className="text-right col-span-6">
                        {
                            checkPerm(Resources.LoadEvents, CREATE_PERM) && (
                                <div className="">
                                    <div className="btn btn-primary"
                                         onClick={() => setCreateModalOpen(true)}
                                    >
                                        <PlusIcon className="w-5 h-5 mr-1"/>

                                        {translate('btn.event')}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )}
            </div>

            <div className="border-t border-tm-gray-300"/>

            {isLoading && !isShareUpdate && (
                <div className="spinner">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div>
            )}

            {(!isLoading || isShareUpdate) && (
                <LoadsEventsList
                    events={events}
                    isLoading={isLoading}
                    handleShareEventClick={handleShareEventClick}
                    translate={translate}
                />
            )}

            {!!isLoadingRef?.current && (
                <div className="row">
                    <div className="col text-center">
                        <div className="spinner">
                            <div className="bounce1"/>
                            <div className="bounce2"/>
                            <div className="bounce3"/>
                        </div>
                    </div>
                </div>
            )}

            {!isLoading && !events.length && (
                <div className="text-center mt-12">
                    <p className="font-bold text-lg text-tm-gray-600 mb-3">{translate("text.no_results")}</p>
                    <button
                        className="btn btn-outline-secondary border border-tm-gray-300"
                        onClick={clearFilters}
                    >
                        {translate("btn.clear_filters")}
                    </button>
                </div>
            )}

            <ModalSaveResource
                title={'Create event'}
                show={createModalOpen}
                widthClass="max-w-3xl"
                gridColsClass="grid-cols-3"
                translate={translate}
                fields={getFields()}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={(params) => {
                    if (params) {
                        dispatch(createSidebarResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign(params, {
                                id: loadID,
                            }),
                            query: {
                                limit: limit,
                                offset: 0,
                                sort: sort,
                                sortBy: sortBy,
                                id: loadID
                            },
                            errorMessage: true, successMessage: `Event has been created.`,
                            resource: getResourceName(),
                            piggyResource: getResourceName()
                        }))
                        setOffset(0)
                        setCreateModalOpen(false)
                    }
                }}
                metadata={getSelect()}
                handleInputChange={(fields, name, value) => {
                    fields = FieldsManager.updateField(fields, name, value)

                    if (name === "DispatchTemplates") {
                        fields.EventKey.value = value.metadata.EventKey
                        fields.EventValue.value = value.metadata.EventValue
                        fields.IsCustomerVisible.value = value.metadata.IsCustomerVisible
                    }
                    return fields
                }}
            />
        </div>
    )
}

export default EventsTab
