import {call, put} from 'redux-saga/effects';
import Api from './api';
import {logout} from '../actions/user';
import LocalStorage from '../../util/localStorage';
import jwt_decode from 'jwt-decode';
import {getCurrentTimeSeconds} from "../../common/util/util-dates";
import {getJWT, saveJWTToken} from "../../common/util/util-auth";

export function* checkUserHelper(user) {
    if (!user || !user.access_token) {
        return {};
    }
    const current = getCurrentTimeSeconds();
    const decoded = jwt_decode(getJWT().access_token);

    const expires = decoded.exp ?? 0;

    const tenPercentOfExpiryTime = ((Number(expires) * 1000) - new Date().getTime()) / 10000;

    if (user?.refresh_token && expires < current + tenPercentOfExpiryTime) {
        let result = yield call(Api.refresh, user.refresh_token);

        if (result && result.status === 0) {
            if (LocalStorage.get('user') && LocalStorage.get('user')?.Contact) {
                const newUser = Object.assign(
                    {},
                    LocalStorage.get('user'),
                    result.data,
                );
                LocalStorage.set('user', newUser);

                user = newUser;
            }

            saveJWTToken(result.data); // Check if data is right
        } else {
            yield put(logout());
        }
    }

    return user;
}

export function processResponse(response) {
    return {
        'status': 0,
        'data': response.data.data
    };
}

export function processRegisterResponse(response) {
    return {
        'status': 0,
        'data': response.data
    };
}

export function processError(error) {
    if (error == 'Error: Network Error') {
        return {
            'status': 3,
            'data': 'NO_RESPONSE'
        }
    }

    if (error?.response?.status === 403) {
        logout()
    }

    if (error.response) {
        return {
            'status': 2,
            'responseStatus': error.response.status,
            'data': error.response.data.error ? error.response.data.error : (error.response.data.message ? error.response.data.message : "API_ERROR"),
            'metadata': error?.response?.data?.data ?? null
        }
    } else if (error.request) {
        return {
            'status': 3,
            'data': 'NO_RESPONSE'
        }
    } else {
        return {
            'status': 3,
            'data': error.message
        }
    }
}

export function isTokenExpired(token) {
    const decoded = jwt_decode(token);
    const current = getCurrentTimeSeconds();
    const expires = decoded.exp ?? 0;
    return expires < current;
}
