import React, {Component} from "react";
import {scrollIntoView} from "../../../util/util-vanilla";

export default class FieldPassword extends Component {

    render() {
        return (
            <React.Fragment>
                <input type={"password"}
                       onChange={(event) => {
                           this.props.onChange(this.props.name, event.target.value);
                       }}
                       name={this.props.name}
                       value={this.props.value}
                       placeholder={this.props.placeholder}
                       disabled={this.props.disabled}
                       className={this.props.addClass + (this.props.errorMessage ? " is-invalid" : "")}
                       onFocus={(event) => {
                           if (this.props.onFocus) {
                               this.props.onFocus(event);
                           }

                           scrollIntoView(event);
                       }}
                       autoComplete="off"
                />
                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold text-left"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Field required*"}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
