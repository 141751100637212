import React, {Component} from "react";
import Tippy from "@tippyjs/react";
import {Field} from "../../../../../data/services/fields";
import {getDialogResource} from "../../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../../data/services/resources";
import {hideModal} from "../../../../../data/actions/ui";
import {getProp} from "../../../../util/util-helpers";
import FieldSwitch from "../../../fields/field-switch";
import InfoParagraph from "../../../info-paragraph";
import ResourceTable from "../../../resource-table";
import NoRecordsTable from "../../../no-records-found/no-records-table";
import Pagination from "../../../resource-table/table-components/pagination";
import Modal from "../../index";
import TasksInfoDialog from "../../allTasksDialog/tasksInfoDialog";
import TableCardFooter from "../../../resource-table/table-components/table-card-footer";
import FieldSearch from "../../../fields/field-text/search";

class ResourceTasksDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            offset: 0,
            fields: this.getFields(),
            sort: "ASC",
            sortBy: "TaskName",
            query: "",
            paginationPage: 1,
            input: '',
            ShowAll: false,
            AssignedToMe: 0,
            tasksInfoDialog: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.taskResource.update && !!this.props.taskResource.update) {
            this.fetchData();
        }
    };

    getFields = () => {
        return {
            TaskName: new Field("TaskName", '', []),
            Description: new Field("Description", '', []),
            AssigneeID: new Field("AssigneeID", '', [], false, "select-search"),
            TaskPriorityID: new Field("TaskPriorityID", '', [], false, 'select'),
            TaskStatusID: new Field("TaskStatusID", '', [], false, 'select')
        };
    };

    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: Resources.TaskResource,
            query: this.getQuery()
        }));
    };

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            query: this.state.input,
            ShowAll: this.state.ShowAll ? 1 : 0,
            MyTasks: this.state.AssignedToMe ? 1 : 0,
            param: window.location.pathname
        }
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    setParentState = (data, callback = () => {
    }) => {
        this.setState(data, callback);
    };

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    hideModal = () => {
        this.props.dispatch(hideModal());
    }

    toggleItem = (it = null) => {
        this.setState({
            tasksInfoDialog: !this.state.tasksInfoDialog,
            items: it
        })
    }

    render() {
        const {translate, dialogResource} = this.props;
        const dataItems = getProp(dialogResource.data, "list", []);
        const isLoading = dialogResource.isLoading;
        const count = dialogResource?.data?.count ?? 0;

        return (
            <React.Fragment>
                <div className="">
                    <div className="flex space-x-3 p-4">
                        <div className="">
                            <FieldSearch
                                addClass={"form-control px-6"}
                                onChange={(name, value) => this.handleFilterChange('input', value)}
                                value={this.state.input}
                            />
                        </div>

                        <div className="flex pl-4 space-x-8">
                            <div className="flex items-center h-10">
                                <FieldSwitch
                                    id="AssignedToMe"
                                    value={this.state.AssignedToMe}
                                    onChange={() => this.setState({
                                        AssignedToMe: !this.state.AssignedToMe,
                                        offset: 0,
                                        paginationPage: 1,
                                    }, () => this.fetchData())}
                                />

                                <label htmlFor="AssignedToMe"
                                       className="cursor-pointer text-tm-gray-500 text-small my-0 mt-sm-0 ml-2">{translate(`field.AssignedToMe`)}</label>
                            </div>

                            <Tippy content={<span>{translate("tippy.showAllTask")}</span>}>
                                <div className="flex items-center h-10">
                                    <FieldSwitch
                                        id="ShowAllTasks"
                                        value={this.state.ShowAll}
                                        onChange={() => this.setState({
                                            ShowAll: !this.state.ShowAll,
                                            offset: 0,
                                            paginationPage: 1,
                                        }, () => this.fetchData())}
                                    />

                                    <label htmlFor="ShowAllTasks"
                                           className="cursor-pointer text-tm-gray-500 text-small my-0 mt-sm-0 ml-2">{translate(`field.ShowAllTasks`)}</label>
                                </div>
                            </Tippy>
                        </div>
                    </div>

                    <div className="px-4 mb-4">
                        <InfoParagraph
                            className=""
                            message={"List of all tasks that belong to this entity, and that are not completed, or closed"}
                        />
                    </div>

                    <ResourceTable
                        commonTable={true}
                        data={dataItems}
                        count={dialogResource?.data?.count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={(it) => this.toggleItem(it)}
                    />

                    <NoRecordsTable
                        show={(dataItems.length === 0) && !isLoading}
                        canCreate={false}
                        title={translate("text.no_matching_records")}
                    />

                    <TableCardFooter
                        show={(count > this.state.limit) && !(!!count && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageLimit={this.state.limit}
                            pageOffset={this.state.offset}
                            translate={translate}
                        />
                    </TableCardFooter>
                </div>

                <Modal
                    show={!!this.state.tasksInfoDialog}
                    widthClass={"max-w-5xl"}
                    title={translate("text.task_info")}

                    translate={translate}
                    onClose={this.toggleItem}
                >
                    <TasksInfoDialog
                        onClose={this.toggleItem}
                        role={"assignee"}
                        id={this.state?.items?.TaskID}
                        translate={translate}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

export default ResourceTasksDialog;
