import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showMapDialog, showModal} from '../../../data/actions/ui'
import {download} from '../../../data/actions/download'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import RectangleGroupIcon from '@heroicons/react/20/solid/RectangleGroupIcon'
import Square2StackIcon from '@heroicons/react/20/solid/Square2StackIcon'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {HourglassIcon} from "../../../data/themes/icons";
import {getHistoryResource} from "../../../data/actions/historyResource";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Modal from "../../../common/components/modal";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import CreateLocationDialog from "../../../common/components/modal/createLocationDialog";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import {MapPinIcon} from "@heroicons/react/24/outline";

class LocationsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LocationName',

            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    rowSelect: true,
                    canAdjustWidth: true,
                    hasMenu: true
                },
                style: {
                    condensed: true,
                    isGPUAccelerated: true
                },
                columns: {
                    LocationNumber: {
                        minWidth: 220
                    }
                }
            }, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            selectedItem: undefined,
            createUpdateDialog: false,
            createUpdateDialogHideAnimation: false,
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {},
            isTableFullScreen: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour);
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.handleCheckIfRedirected()
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If location is created via Global location dialog
        if (!!this.props.resource?.create && prevProps.resource?.create === null) {
            this.fetchData()
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    showDuplicates = () => {
        this.props.history.push(`/locations/duplicates`)
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'locations_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'locations_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate("message.confirm_archive_generic")} ${item.LocationName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {LocationID: item.LocationID}),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.LocationName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate("message.are_you_sure_restore_location")} ${item.LocationName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            LocationID: item.LocationID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `${item.LocationName} restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    submit = (params) => {
        if (this.state.selectedItem?.LocationID) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                query: this.getQuery(),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true, successMessage: `Location ${this.state.selectedItem.LocationName} updated`,
            }))
        } else {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: params,
                query: this.getQuery(),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true, successMessage: 'Location Created Successfully!'
            }))
        }
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'LocationName'
            sort = 'ASC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleLocationMapView = (item) => {
        this.props.dispatch(showMapDialog(item))
    }

    handleToggleCreateUpdateDialog = (selectedItem = null) => {
        this.setState({
            selectedItem: selectedItem, CreateLocationDialog: !this.state.CreateLocationDialog
        }, () => {
            if (LocalStorage.has('selectedItem')) LocalStorage.remove('selectedItem')
        })
    }

    handleOpenInfoDialog = (item) => {
        this.props.dispatch(showModal('ViewLocationCard', Object.assign(
            item,
            {
                query: this.getQuery(),
                resource: this.getResource()
            }
        )))
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCheckIfRedirected = () => {
        if (LocalStorage.has('selectedItem')) {
            this.handleToggleCreateUpdateDialog(LocalStorage.get('selectedItem'))
        }
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleToggleHistoryModal = (item = null) => {
        this.setState({
            historyModalOpen: !this.state.historyModalOpen,
            selectedItem: item
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }
    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            LocationName: new Field('LocationName', '', ['empty'], false, 'custom', {
                    render: (item) => this.renderLocationNameCell(item)
                }
            ),
            LocationNumber: new Field('LocationNumber', '', ['empty']),
            LocationZoneID: new Field('LocationZoneID', '', ['empty'], false, 'select-search'),
            AddressName: new Field('AddressName', '', ['empty']),
            CityName: new Field('CityName', '', ['empty']),
            State: new Field('State', '', ['empty']),
            PostalCode: new Field('PostalCode', '', ['']),
            PhoneNumber: new Field('PhoneNumber', '', ['empty'], false, 'phone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date'),
        }
    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, this.getFields())

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['CountryID'] = 'select'
        fieldsCombined['StateID'] = 'select'
        fieldsCombined['RequiresAppointment'] = 'checkbox'
        fieldsCombined['LocationZoneID'] = 'select-search'
        fieldsCombined['StopTypeID'] = 'select'
        fieldsCombined['LocationTypeID'] = 'select'
        fieldsCombined['AreaCode'] = 'select'
        fieldsCombined['PhoneNumber'] = 'phone'

        return Object.assign({}, fieldsCombined);
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'query', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            ShowInvalidLocations: new Field('ShowInvalidLocations', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.Locations
    }

    /** Render
     ================================================================= */
    renderLocationNameCell = (item) => {

        let isLocationInfoValid = true;
        if (!item.Longitude || !item.Latitude || (item.Longitude == "0") || (item.Latitude == "0")) {
            isLocationInfoValid = false
        }

        return (
            <div className="flex">
                {item.LocationName}
                {!isLocationInfoValid && (
                    <Tippy
                        content={this.props.translate('text.location_invalid')}
                    >
                        <div>
                            <ExclamationTriangleIcon className={'ml-2 w-5 h-5 text-red-600'}/>
                        </div>
                    </Tippy>
                )}
            </div>
        )
    }

    render() {
        const {translate, resource, historyResource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = resource.isLoading

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.locations')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateUpdateDialog()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            {/*<Tippy content={translate('text.download_template')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcelTemplate}*/}
                            {/*    >*/}
                            {/*        <RectangleGroupIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            {checkPerm(Resources.LocationsDuplicates, READ_PERM) && (
                                <Tippy content={translate('text.show_duplicates')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.showDuplicates}
                                    >
                                        <Square2StackIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                            )}

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleCreateUpdateDialog : null}

                            onView={this.handleOpenInfoDialog}
                            onEdit={this.handleToggleCreateUpdateDialog}
                            onDelete={this.archiveResource}
                            onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}

                            hasViewPerm={checkPerm(this.getResource(), READ_PERM)}
                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            tableKey={"LocationID"}

                            actions={[
                                {
                                    action: this.handleLocationMapView,
                                    icon: MapPinIcon,
                                    visible: (it) => (it.Latitude && it.Longitude),
                                    title: translate('text.map'),
                                },
                                {
                                    action: this.handleToggleHistoryModal,
                                    customContent: () => <HourglassIcon className="w-5 h-5"/>,
                                    hasPerm: checkPerm(Resources.LocationsHistory, READ_PERM),
                                    title: translate('text.history'),
                                }
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new location'}
                            btnLabel="Create location"
                            onBtnClick={() => {
                                this.setState({
                                    prefilled: this.state.queryFilterFields.query.value,
                                    CreateLocationDialog: true
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>
                </Page>

                <Modal
                    show={!!this.state.CreateLocationDialog}
                    widthClass={'max-w-7xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleCreateUpdateDialog}

                    translate={translate}
                >
                    <CreateLocationDialog
                        onClose={this.handleToggleCreateUpdateDialog}
                        prefilled={this.state.prefilled}
                        onSubmit={this.submit}
                        updateItem={this.state.selectedItem}
                        handleActionHistoryView={this.handleActionHistoryView}
                        {...this.props}
                    />
                </Modal>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-full"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.LocationsHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.state.selectedItem.LocationID
                                    }),
                                    resource: Resources.LocationsHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>
            </Layout>
        )
    }
}

export default connect(state => state)(LocationsView)
