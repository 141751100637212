import Button from "../../../../../common/components/button";
import React, {useEffect} from "react";
import {classNames} from "../../../../../common/util/util-helpers";

export default function LoadStepsPopup({
                                           title,
                                           totalSteps,
                                           currentStep,
                                           onCloseClick,
                                           onNextClick,
                                           isNextDisabled,
                                           addClass,
                                           stepText,
                                           summary,
                                           translateYClass,
                                           position = "bottom",
                                           containerClass,
                                           currentElement,

                                           currentlyFocusedElement
                                       }) {
    function scrollToElement(element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    }


    useEffect(() => {
        if (currentElement) {
            scrollToElement(currentElement);
            if (currentlyFocusedElement !== undefined) {
                currentlyFocusedElement.current = currentElement;
            }
        }

        return () => {
            if (currentlyFocusedElement?.current) {
                currentlyFocusedElement.current = undefined;
            }
        }
    }, []);

    return (
        <div className={containerClass ?? "w-full"}>
            <div
                className={
                    classNames(
                        "absolute text-left px-6 pt-6 pb-4 z-10 w-full max-w-lg rounded-md bg-tm-gray-900 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                        position === "top"
                            ? translateYClass ?? "-translate-y-full -top-10"
                            : undefined,
                        position === "bottom" ? "mt-5" : undefined,
                        position === "top-left" ? "-translate-y-full -translate-x-[calc(100%-4rem)] -top-10" : undefined,
                        position === "bottom-left" ? "mt-5 -translate-x-[calc(100%-4rem)]" : undefined,
                        addClass
                    )
                }
            >

                <div
                    className={
                        classNames(
                            "w-5 h-5 absolute bg-tm-gray-900 rotate-45",
                            position === 'bottom' ? "-top-2.5" : undefined,
                            position === 'top' ? "-bottom-2.5" : undefined,
                            position === "top-left" ? "-bottom-2.5 right-6" : undefined,
                            position === "bottom-left" ? "-top-2.5 right-6" : undefined
                        )}
                />


                <div className="mb-4 flex justify-between text-sm">
                    <span className="font-bold text-tm-gray-200">{title}</span>
                    {!!totalSteps && (
                        <span className="text-tm-gray-500">{currentStep} of {totalSteps}</span>
                    )}
                </div>


                {summary}


                <div className="space-y-2 text-tm-gray-50">
                    {stepText}
                </div>

                <div className="mt-6 flex justify-end gap-4">
                    <Button
                        onClick={onCloseClick}
                        addClass="hover:text-primary-tint hover:border-primary-tint"
                    >
                        Close
                    </Button>

                    <Button
                        onClick={onNextClick}
                        disabled={isNextDisabled}
                        appearance="primary"
                        addClass="focus:ring-offset-tm-gray-900"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}