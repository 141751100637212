import React, {useState} from "react";
import FileViewer from "../../file-viewer/components"
import {Loader} from "../../loader";
import FileList from "../../dropzone/file-list";
import {FieldsManager} from "../../../../data/services/fields";
import ModalDefault from "../../modal/modal-default";
import FieldsToHtml from "../../fields/fields-to-html";
import {getLookup} from "../../../util/util-helpers";


export default function DocumentListUploadDialog({
                                                     files = [],
                                                     dialogWidth,
                                                     setFiles,
                                                     isLoading,
                                                     documentTypes,
                                                     onUploadFiles,
                                                     translate
                                                 }) {
    const [canSubmit, setCanSubmit] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);

    function handleInputChange(name, value, i) {
        let filesUpdate = [...files];
        filesUpdate[i].fields = FieldsManager.updateField(filesUpdate[i].fields, name, value)
        filesUpdate[i].fields[name].errorMessage = "";
        setFiles(filesUpdate);
        setCanSubmit(true);
    }

    function downloadPreview() {
        if (previewFile) {
            const link = document.createElement("a");
            link.download = previewFile.name;
            link.href = URL.createObjectURL(previewFile);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        }
    }

    function handleDeleteFileClick(i) {
        let filesUpdate = [...files];
        filesUpdate.splice(i, 1)
        setFiles(filesUpdate);
    }

    function handleUploadFilesClick() {
        let filesUpdate = [...files];
        filesUpdate = filesUpdate.map((file) => Object.assign(file, {
            fields: FieldsManager.validateFields(file.fields)
        }));

        if (filesUpdate.reduce((memo, file) => (memo && FieldsManager.checkFieldsForErrors(file.fields)), true)) {
            onUploadFiles(filesUpdate);
        } else {
            setFiles(filesUpdate);
        }
    }

    return (
        /** File upload dialog
         ================================================================= */
        <ModalDefault
            show={!!files.length}
            title={translate("modal_heading.upload_confirm")}
            onClose={() => setFiles([])}
            closeButtonLabel={translate("btn.cancel")}
            onButtonClick={() => {
                handleUploadFilesClick()
            }}
            buttonDisabled={!canSubmit && !files?.length}
            buttonLabel={translate('Upload')}
            widthClass={dialogWidth ?? "max-w-xl"}
            translate={translate}
        >
            <div className="p-6 space-y-4 relative">
                {isLoading && (
                    <Loader stripesBg={true} />
                )}

                {files.map((file, i) => {
                    return (
                        <>
                            <FileList
                                files={[file]}
                                onFileView={() => setPreviewFile(file)}
                                onFileDelete={() => handleDeleteFileClick(i)}
                            />

                            <div className="grid grid-cols-12 gap-4">
                                <FieldsToHtml
                                    fieldsState={file?.fields}
                                    onInputChange={(name, value) => handleInputChange(name, value, i)}
                                    translate={translate}
                                    selects={{
                                        DocumentTypeID:
                                            documentTypes ? documentTypes :
                                                getLookup('DocumentType')
                                    }}
                                />
                            </div>
                        </>
                    )
                })}
            </div>

            {/*/** File preview dialog*/}
            {/*================================================================= */}
            <ModalDefault
                show={!!previewFile}
                widthClass={'max-w-7xl'}
                title={translate("modal_heading.Preview")}
                limitHeight={true}

                closeButtonLabel={translate('btn.close')}
                onClose={() => setPreviewFile(null)}
                buttonLabel={translate('Download')}
                onButtonClick={() => {
                    downloadPreview()
                }}
            >
                <div className="max-h-[calc(100vh-12rem)] overflow-auto">
                    {!!previewFile && (
                        <FileViewer
                            filePath={URL.createObjectURL(previewFile)}
                            fileType={previewFile.name.split('.').pop()}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </div>
            </ModalDefault>
        </ModalDefault>
    )
}