import React, {useEffect, useState} from 'react';
import ModalDefault from "../modal-default";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultTableOptions} from "../../../util/util-helpers";
import ResourceTable from "../../resource-table";
import {getAccountingResource} from "../../../../data/actions/accountingResource";
import getInvoicesFields from "../../../../views/accounting/income/invoice-fields";
import CellButton from "../../resource-table/table-components/cell-button";
import OrganizationInfoDialog from "../modal-global/organization-info-dialog";
import ContactInfoDialog from "../contact-info-dialog";
import {getAccountingInfoResource} from "../../../../data/actions/accountingInfoResource";
import InvoiceModal from "../invoice-modal";

export default function InvoicesListModal({translate, show, onClose, selectedInvoices}) {
    const dispatch = useDispatch();

    const [selectedOrganization, setSelectedOrganization] = useState();
    const [isOrganizationInfoDialogOpen, setIsOrganizationInfoDialogOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState();
    const [isContactInfoDialogOpen, setIsContactInfoDialogOpen] = useState(false);
    const [isInvoiceDialogVisible, setIsInvoiceDialogVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [tableOptions] = useState(getDefaultTableOptions(getFields(), {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true,
            hasMenu: true
        },
        columns: {
            AutoCounter: {
                minWidth: 120,
            },
            Labels: {minWidth: 100},
            RefNumber: {minWidth: 105},
            Amount: {minWidth: 120},
            AmountTransferred: {minWidth: 120},
            IsLoadInvoice: {minWidth: 110},
            InvoiceType: {minWidth: 80},
            Date: {minWidth: 130},
            DueDate: {minWidth: 130},
            BookDate: {minWidth: 130},
            InvoiceStatus: {minWidth: 100},
            Batch: {minWidth: 80},
            IsDigitalSent: {minWidth: 80},
            IsDigitalConfirmed: {minWidth: 80},
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        }
    }, "", translate))

    const resource = useSelector(state => state.accountingResource);
    const data = resource?.data?.list ?? [];

    function fetchData() {
        dispatch(getAccountingResource({
            user: LocalStorage.get('user'),
            query: {
                InvoiceIDs: selectedInvoices.split(",")
            },
            resource: Resources.Invoices
        }))
    }

    function handleRowClick(row) {
        setSelectedItem(row);
        setIsInvoiceDialogVisible(true);
    }

    function handleToggleOrganizationInfoDialog(item = {}) {
        setSelectedOrganization(item);
        setIsOrganizationInfoDialogOpen(true);
    }

    function handleToggleContactInfoDialog(item = {}) {
        setSelectedContact(item);
        setIsContactInfoDialogOpen(true);
    }

    function getFields() {
        const invoiceFields = getInvoicesFields();

        invoiceFields.ChargeTo.metadata.render = (item) => <CellButton
            onClick={() => item.OrganizationID ? handleToggleOrganizationInfoDialog(item) : handleToggleContactInfoDialog(item)}>
            <div>
                {item.ContactID ? item.Contact : item.Organization}
            </div>
        </CellButton>

        return invoiceFields;
    }

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show]);


    return <ModalDefault
        show={show}
        widthClass={'max-w-full'}
        title={translate('text.invoices_list')}

        closeButtonLabel={translate('btn.Cancel')}
        onClose={onClose}
    >
        <ResourceTable
            data={data}
            fields={getFields()}

            translate={translate}
            isLoading={resource.isLoading}

            options={tableOptions}

            onRowClick={handleRowClick}
        />

        {(isOrganizationInfoDialogOpen &&
            <OrganizationInfoDialog
                show={isOrganizationInfoDialogOpen}
                translate={translate}
                organizationID={selectedOrganization?.OrganizationID}
                handleClose={() => setIsOrganizationInfoDialogOpen(false)}
            />
        )}

        {(isContactInfoDialogOpen &&
            <ContactInfoDialog
                show={isContactInfoDialogOpen}
                translate={translate}
                contactID={selectedContact?.ContactID}
                handleClose={() => setIsContactInfoDialogOpen(false)}
            />
        )}

        <InvoiceModal
            show={isInvoiceDialogVisible}
            selectedItem={selectedItem}
            onClose={() => setIsInvoiceDialogVisible(false)}
            onFetchData={() => {
                dispatch(getAccountingInfoResource({
                    user: LocalStorage.get('user'),
                    query: {
                        InvoiceID: selectedItem.InvoiceID
                    },
                    resource: Resources.InvoicesInvoice
                }))
            }}
            translate={translate}
        />
    </ModalDefault>
}