import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import { pushNotification } from "../actions/ui";
import {
    doneCountTaskResource,
    doneCreateTaskResource,
    doneGetTaskResource,
    doneUpdateTaskResource,
    errorCreateTaskResource,
    errorGetTaskResource,
    errorUpdateTaskResource
} from "../actions/taskResource";
import { doneCreateResource } from "../actions/resource";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getTaskResourceCall(action) {
    const user = action.data.user ? yield* checkUserHelper(action.data.user) : null;
    const result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetTaskResource(result.data));
    } else {
        yield put(errorGetTaskResource(result.data));
    }
}

export function* watchGetTaskResource() {
    yield takeLatest('GET_TASK_RESOURCE', getTaskResourceCall);
}

export function* updateTaskResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
                yield put(doneUpdateTaskResource(result.data));
            }
        } else {
            yield put(doneUpdateTaskResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateTaskResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateTaskResource() {
    yield takeLatest('UPDATE_TASK_RESOURCE', updateTaskResourceCall);
}

export function* createTaskResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.taskCountResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.taskCountQuery ?? action.data.query, action.data.taskCountResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneCountTaskResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        }

        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);

            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
                yield put(doneCreateTaskResource(result.data));
            }
        } else {

            yield put(doneCreateTaskResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true,
                result: result
            }));
        }
    } else {
        yield put(errorCreateTaskResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateTaskResource() {
    yield takeLatest('CREATE_TASK_RESOURCE', createTaskResourceCall);
}