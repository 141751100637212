import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {classNames, getDocumentTypesFor, getLookupWithFilter} from "../../../../common/util/util-helpers";
import React, {useEffect, useRef, useState} from "react";
import {PaperClipIcon, TrashIcon} from "@heroicons/react/24/outline";
import {Field, FieldsManager} from "../../../../data/services/fields";
import RectangleGroupIcon from "@heroicons/react/24/outline/RectangleGroupIcon";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import DropZoneAlt from "../../../../common/components/dropzone/drop-zone-alt";
import Tooltip from "../../../../common/components/tooltip";
import ModalDefault from "../../../../common/components/modal/modal-default";
import TabTitle from "../../../../common/components/layout/layout-components/tab-title";
import FieldCheckbox from "../../../../common/components/fields/field-checkbox";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import {fillFieldsFromData} from "../../../../common/util/util-fields";

export default function LoadInfoDocuments({
                                              innerRef,
                                              isSectionExpanded,
                                              toggleExpandSection,
                                              createLoadDocuments,
                                              onSetCreateLoadDocuments,
                                              handleRemoveDocument,
                                              onOCRDocumentClick,
                                              updateDocumentState,
                                              translate,
                                              OCRDropzoneDocument
                                          }) {
    const dropZoneClickRef = useRef();
    const areCheckboxesTouched = useRef();

    const [isDirty, setIsDirty] = useState(false);
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const [isDocumentUploadModalOpen, setIsDocumentUploadModalOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [willCopyValues, setWillCopyValues] = useState(true);
    const [bufferedDocExists, setBufferedDocExists] = useState(true)

    const onAcceptFiles = (files) => {
        setFiles(files);
        setIsDocumentUploadModalOpen(true);
    }

    const handleRemoveDocumentFromUpload = (i) => {
        setFiles(files.splice(i, 1));
    }

    const handleCancelUpload = () => {
        setIsDocumentUploadModalOpen(false);
        setFiles([]);
    }

    function getFields(item = {}) {
        const fieldTemplates = {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                values: getDocumentTypesFor("IsDispatchDocument")
            }),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {
                isClearable: true
            }),
            IsSendWithInvoice: new Field("IsSendWithInvoice", '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            IsVisibleForCustomer: new Field("IsVisibleForCustomer", '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            OriginalName: new Field("OriginalName", '', [], false, 'text', {
                hideDialog: true,
                addContainerClass: 'col-span-full'
            }),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    function handleInputChange(name, value, i) {
        let filesClone = [...files];
        filesClone[i].fields = FieldsManager.updateField(filesClone[i].fields, name, value)
        let updateIsVisibleForCustomer = false;

        if (!i && name === "IsSendWithInvoice") {
            // In value is initially false, checking IsSendWithInvoice will also check IsVisibleForCustomer
            if (!areCheckboxesTouched.current && value) {
                files[i].fields.IsVisibleForCustomer.value = value;
                updateIsVisibleForCustomer = true;
            }

            areCheckboxesTouched.current = true;
        }

        if (!i && willCopyValues) {
            filesClone = filesClone.map((file, fileIndex) => {
                if (fileIndex) {
                    file.fields[name].value = value;

                    if (updateIsVisibleForCustomer) {
                        file.fields.IsVisibleForCustomer.value = value;
                    }
                }

                return file
            });
        }

        setFiles(filesClone);
        setIsDirty(true);
    }

    function handleSubmitDocumentsClick() {
        const filesValidated = files.map((file) => Object.assign(file, {
            fields: FieldsManager.validateFields(file.fields)
        }));

        if (filesValidated.reduce((memo, file) => (memo && FieldsManager.checkFieldsForErrors(file.fields)), true)) {
            onSetCreateLoadDocuments(filesValidated);
            setIsDocumentUploadModalOpen(false);
        } else {
            setFiles(filesValidated);
        }
    }

    async function fetchDocumentForOcr() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const bufferedDocument = urlParams.get('ocrDocumentUrl');

        if (bufferedDocument) {
            const updatedUrl = `${window.location.origin}${window.location.pathname}`;
            window.history.replaceState({}, '', updatedUrl)

            const decodedData = JSON.parse(atob(bufferedDocument));
            const blobUrl = decodedData.url;
            const documentName = decodedData.documentName || 'Rate_Confirmation.pdf'

            try {
                const response = await fetch(blobUrl);
                const blobData = await response.blob();
                const fetchedFile = new File([blobData], 'Rate_Confirmation.pdf');
                if (fetchedFile) {
                    updateDocumentState(
                        [addOcrDocument(fetchedFile, documentName)],
                        [fetchedFile],
                        true
                    )
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        } else {
            setBufferedDocExists(false)
        }
    }

    function addOcrDocument(file, originalName) {

        const docsTypes = getLookupWithFilter('DocumentType', 'DocumentTypeID', 'DocumentType', (it) => {
            return it.IsDispatchDocument === 1
        })

        const keywords = [/\bRC\b/i, "rate confirmation"];
        let documentType = Object.keys(docsTypes)[0];

        for (const key in docsTypes) {
            if (Object.hasOwnProperty.call(docsTypes, key)) {
                const value = docsTypes[key].toLowerCase();
                for (const keyword of keywords) {
                    if (typeof keyword === 'string') {
                        if (value.includes(keyword)) {
                            documentType = key;
                            break;
                        }
                    } else if (keyword instanceof RegExp) {
                        if (keyword.test(value)) {
                            documentType = key;
                            break;
                        }
                    }
                }
            }
        }

        file.fields = getFields({
            IsSendWithInvoice: 1,
            IsVisibleForCustomer: 1,
            Description: "Rate confirmation",
            ExpiryDate: "",
            OriginalName: originalName,
            DocumentTypeID: documentType,
        });

        return file;
    }

    useEffect(() => {
        void fetchDocumentForOcr();
    }, []);

    useEffect(() => {
        if (!bufferedDocExists && OCRDropzoneDocument?.length) {
            const file = OCRDropzoneDocument[0]
            const originalFilename = file.path || file.fields['OriginalName'].value
            const renamedFile = new File([file], 'Rate_Confirmation.pdf', {type: file.type});
            updateDocumentState(
                [addOcrDocument(renamedFile, originalFilename)],
                [renamedFile],
                true
            )
        }
    }, [OCRDropzoneDocument?.length, bufferedDocExists])

    return (
        <div
            ref={innerRef}
            onDragOver={() => setIsDropZoneActive(true)}
            className="grid grid-cols-12 px-6 relative py-3 border-b border-tm-gray-300"
        >
            <div className="col-span-5 py-3 pr-3">
                <div className="flex items-center">
                    <button
                        className="btn btn-icon -ml-2 md mr-2 w-8 h-8"
                        onClick={() => toggleExpandSection("LoadInfoDocuments")}
                    >
                        <ChevronRightIcon
                            className={
                                classNames(
                                    "w-5 h-5",
                                    isSectionExpanded ? "rotate-90" : undefined
                                )
                            }
                        />
                    </button>

                    <Subtitle className="flex items-center gap-2" subtitle={translate("text.load_info_documents")}>
                        {!isSectionExpanded && (
                            <span className="text-sm font-bold text-tm-gray-500">({createLoadDocuments.length})</span>
                        )}
                    </Subtitle>
                </div>
            </div>

            {isSectionExpanded && (
                <div className="pl-5 col-span-7 py-3 min-h-[10rem]">
                    <DropZoneAlt
                        className={
                            classNames(
                                "absolute inset-0 ",
                                isDropZoneActive ? "z-50" : "-z-10"
                            )
                        }
                        maxFilesAccepted={10}
                        accept="application/pdf"
                        translate={translate}
                        onAcceptFiles={(files) => {
                            files = files.map(file => {
                                file.fields = getFields();
                                return file
                            });
                            onAcceptFiles(files);
                            setIsDropZoneActive(false);
                        }}
                        content={(isDragAccept, isDragReject) => {
                            return (
                                <React.Fragment>
                                    <div
                                        ref={dropZoneClickRef}
                                        onDragLeave={() => {
                                            setIsDropZoneActive(false);
                                        }}
                                        className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "rounded-card pb-4 absolute inset-1 bottom-0 z-50 flex items-center justify-center"
                                        )}>
                                    </div>

                                    {isDragAccept && (
                                        <div
                                            className="flex mx-auto my-10 items-center rounded-btn bg-primary text-base font-bold text-center text-primary-contrast max-w-xs p-4">
                                            Drop files to upload them when load is created
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                        }}
                    />

                    {!!createLoadDocuments.length && (
                        <ul role="list"
                            className="divide-y divide-tm-gray-200 rounded-md border border-tm-gray-300 mb-6">
                            {createLoadDocuments.map((it, i) => {
                                    return <li key={i}
                                               className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex flex-col">
                                            <div className="flex w-0 items-center">
                                                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400"/>
                                                <div className="ml-4 flex grow flex-1 gap-2">
                                                    <span className="truncate font-medium">{it.name}</span>
                                                    <span
                                                        className="flex-shrink-0 text-gray-400">{(it.size / (1024)).toFixed(2)}kb</span>
                                                </div>
                                            </div>

                                            <div className="flex gap-4 relative top-1.5 pl-8 text-tm-gray-500">
                                                {it.fields?.IsSendWithInvoice?.value && (
                                                    <span className="flex items-center gap-1"><CheckCircleIcon
                                                        className="w-5 h-5 text-green-700"/> Send with invoice</span>
                                                )}
                                                {it.fields?.IsVisibleForCustomer?.value && (
                                                    <span className="flex items-center gap-1"><CheckCircleIcon
                                                        className="w-5 h-5 text-green-700"/> Customer portal</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="ml-4 flex-shrink-0 space-x-2">
                                            <Tooltip content={translate("text.load_pdf_scan")}>
                                                <button
                                                    onClick={() => onOCRDocumentClick(i)}
                                                    className="btn btn-header"
                                                >
                                                    <RectangleGroupIcon className="w-5 h-5"/>
                                                </button>
                                            </Tooltip>

                                            <Tooltip content={translate("text.remove")}>
                                                <button
                                                    onClick={() => handleRemoveDocument(i)}
                                                    className="btn btn-header"
                                                >
                                                    <TrashIcon className="w-5 h-5"/>
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </li>
                                }
                            )}
                        </ul>
                    )}

                    {!createLoadDocuments.length && (
                        <div
                            className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 rounded-md border border-dashed border-tm-gray-200 hover:bg-tm-gray-100 cursor-pointer"
                            onClick={() => {
                                dropZoneClickRef?.current?.click();
                            }}
                        >
                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400"/>
                            <div className="flex w-0 flex-1 items-center">
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium text-tm-gray-400">No files attached yet</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        className="absolute bottom-0 left-0 right-0 px-6 cursor-pointer py-3 border-t border-primary border-dotted bg-primary-transparent text-center text-base z-40"
                        onClick={() => {
                            dropZoneClickRef?.current?.click();
                        }}
                    >
                        Attach files by dragging and dropping them or
                        <span
                            className="text-primary-tint font-semibold"
                        >{" "} click here</span> to
                        select them.
                    </div>
                </div>
            )}

            <ModalDefault
                show={isDocumentUploadModalOpen}
                widthClass={'max-w-5xl'}
                title={translate("modal_heading.upload_confirm")}
                limitHeight={true}

                close={handleCancelUpload}
                closeButtonLabel={translate('btn.cancel')}
                onClose={handleCancelUpload}

                buttonDisabled={!isDirty}
                buttonLabel={translate('Upload')}
                onButtonClick={handleSubmitDocumentsClick}
            >
                <div className="px-5 pt-5 pb-40">
                    {files.map((it, i) => {
                        return (
                            <div key={i}>
                                {!!i && (
                                    <div className={'border-b border-tm-gray-300 mb-4 p-4 -ml-4 -mr-4'}/>
                                )}

                                <div className="flex items-center mb-4 justify-between">
                                    <div
                                        className="flex items-center text-sm font-semibold text-tm-gray-900 break-all"
                                    >
                                        {files.length > 1 && (
                                            <div
                                                className='w-10 h-10 mr-2 rounded-full flex items-center justify-center bg-tm-gray-50'>
                                                <TabTitle
                                                    text={i + 1}
                                                />
                                            </div>
                                        )}

                                        <label>{translate("text.desc_for_file")}: {files[i].name}</label>
                                    </div>
                                    <div className="text-right">
                                        {/*<button*/}
                                        {/*    className="btn btn-icon"*/}
                                        {/*    title={translate('text.preview')}*/}
                                        {/*    onClick={() => this.showPreviewModal(it)}*/}
                                        {/*>*/}
                                        {/*    <EyeIcon className="h-4 w-4"/>*/}
                                        {/*</button>*/}

                                        {files.length > 1 && (
                                            <button
                                                className="btn btn-icon"
                                                title={translate('text.delete')}
                                                onClick={() => handleRemoveDocumentFromUpload(i)}
                                            >
                                                <TrashIcon className="h-4 w-4"/>
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="grid gap-4">
                                    {files.length > 1 && !i && (
                                        <label
                                            className="h-9 flex flex-center justify-start px-2 -ml-2 w-full cursor-pointer hover:bg-tm-gray-50"
                                        >
                                            <FieldCheckbox
                                                className={
                                                    classNames(
                                                        "checkbox",
                                                        willCopyValues
                                                            ? "border-primary"
                                                            : "border-tm-gray-400"
                                                    )
                                                }
                                                onChange={() => setWillCopyValues(!willCopyValues)}
                                                value={willCopyValues}
                                            />

                                            <div
                                                className="ml-2 flex flex-col text-sm font-semibold select-none text-tm-gray-900"
                                            >
                                                {translate("text.copy_to_other_fields")}
                                            </div>
                                        </label>
                                    )}

                                    <FieldsToHtml
                                        fieldsState={files[i].fields}
                                        onInputChange={(name, value) => handleInputChange(name, value, i)}
                                        translate={translate}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ModalDefault>
        </div>
    )
}