import React, {Component} from 'react'
import icons from '../../../../data/themes/icons'
import {toFrontDateTimeFromUTC} from '../../../../common/util/util-dates'
import ModalDefault from "../../../../common/components/modal/modal-default";

export default class LoginsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    returnOSIcon = (os) => {
        switch (os) {
            case 'linux':
                return icons.linux
            case 'mac':
                return icons.mac
            case 'windows':
                return icons.windows
            case 'iPad':
                return icons.iPad
            case 'iPhone':
                return icons.iPhone
            case 'Android':
                return icons.Android
            default:
                return icons.QuestionMark
        }
    }

    returnBrowserIcon = (browser) => {
        let icon
        if (browser) {
            ['Chrome', 'Safari', 'Firefox', 'Opera', 'Explorer', 'Edge'].forEach(it => {
                if (browser.includes(it)) {
                    icon = icons[it]
                }
            })
        }
        if (icon) {
            return icon
        } else {
            return icons.QuestionMark
        }
    }

    render() {
        const {translate, onClose} = this.props
        const settingsList = this.props?.item?.Logins?.map((item) => {
            return (
                <tr key={item}>
                    <td>{toFrontDateTimeFromUTC(item.login_time)}</td>
                    <td>
                        <span className="submenu-icon mr-2">{this.returnOSIcon(item.os)}</span>
                        <span className="text-capitalize">{item.os}</span>
                    </td>
                    <td>
                        <span className="submenu-icon mr-2">{this.returnBrowserIcon(item.Browser)}</span>
                        <span className="text-capitalize">{item.Browser}</span>
                    </td>
                    <td>{item.location}</td>
                    <td>{item.ip}</td>
                </tr>
            )
        })

        return (
            <ModalDefault
                show={!!this.props.show}
                widthClass="max-w-7xl"
                title={translate('modal_heading.users-report')}
                closeButtonLabel={translate('btn.close')}
                onClose={onClose}
            >
                <div className="relative">
                    <div className="">
                        <table className="data-table w-full">
                            <thead>
                            <tr>
                                <th>{translate('table.LoginDate')}</th>
                                <th>{translate('table.OS')}</th>
                                <th>{translate('table.Browser')}</th>
                                <th>{translate('table.Location')}</th>
                                <th>{translate('table.IPAddress')}</th>
                            </tr>
                            </thead>
                            <tbody className="text-left">
                            {settingsList}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalDefault>
        )
    }
}
