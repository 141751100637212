import React, {useState} from "react";
import {classNames} from "../../../common/util/util-helpers";
import {cloneDeep} from "../../../common/util/util-vanilla";
import {Loader} from "../../../common/components/loader";

export default function PermissionsMenu({permissions = [], isLoading, onSelectCategory, filter = "", translate}) {
    const [expandedItem, setExpandedItem] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");

    const menu = permissions.reduce((memo, it) => {
        memo.push(renderMenuItem(it, expandedItem, setExpandedItem, selectedCategory, setSelectedCategory, onSelectCategory, filter))
        return memo;
    }, []);


    return <div className="w-60 bg-popup rounded-card border border-tm-gray-200">
        {isLoading && (
            <div className="p-4 flex justify-center">
                <Loader disableContainer={true}/>
            </div>
        )}

        {menu}

        {!isLoading && !menu.find(it => !!it) && (
            <div className="p-6 text-center">{translate("text.no_results")}</div>
        )}
    </div>
}

const renderMenuItem = (it, expandedItem, setExpandedItem, selectedCategory, setSelectedCategory, onSelectItem, filter) => {

    const isExpanded = expandedItem === it.Name;
    const hasChildren = !!it?.ChildrenGroups?.length;


    const filteredChildrenGroups = cloneDeep(it?.ChildrenGroups).reduce((memo, group) => {
        if (group.Name.toLowerCase().search(filter.toLowerCase()) > -1) {
            memo.push(group);
        }
        return memo;
    }, []);

    const hasFilterResults = !filter || !!filteredChildrenGroups.length;

    if (!hasFilterResults) {
        return null;
    }

    return (
        <React.Fragment>
            <button
                onClick={() => setExpandedItem(isExpanded ? "" : it.Name)}
                disabled={!hasChildren}
                className="hover:bg-tm-gray-50 text-tm-gray-700 group w-full flex items-center px-4 py-2 text-sm font-medium rounded-btn"
            >
                <div>
                    {it.Name}
                </div>

                {hasChildren && (
                    <svg
                        className={
                            classNames(
                                "text-tm-gray-400 ml-auto flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150",
                                isExpanded ? "rotate-90" : undefined
                            )
                        }
                    >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
                    </svg>
                )}
            </button>

            {isExpanded && hasChildren && (
                <ChildrenGroup
                    parentName={it.Name}
                    childrenGroups={filteredChildrenGroups}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    onSelectItem={onSelectItem}
                />
            )}
        </React.Fragment>

    );
}

const ChildrenGroup = ({childrenGroups, selectedCategory, setSelectedCategory, parentName, onSelectItem}) => {
    return <div>
        <button className="space-y-2 w-full px-2 mb-4">
            {childrenGroups.map(it => {
                return (
                    <button
                        key={it.name}
                        onClick={() => {
                            onSelectItem(parentName, it);
                            setSelectedCategory(it.Name)
                        }}
                        className={
                            classNames(
                                it.Name === selectedCategory ? "bg-primary-transparent text-tm-gray-900" : "text-tm-gray-700",
                                "font-semibold text-left hover:bg-tm-gray-50 group w-full flex grow items-center pl-11 pr-2 py-1.5 text-sm rounded-btn hover:text-tm-gray-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-link"
                            )
                        }
                    >
                        {it.Name}
                    </button>
                )
            })}
        </button>
    </div>
}
