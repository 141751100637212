import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import { checkUserHelper } from "../services/api-util";
import {
    doneCreateDialogResource,
    doneDeleteDialogResource,
    doneGetDialogResource,
    doneGetDialogResourceDetails,
    doneUpdateDialogResource,
    doneUploadDialogDocument,
    errorCreateDialogResource,
    errorDeleteDialogResource,
    errorGetDialogResource,
    errorGetDialogResourceDetails,
    errorUpdateDialogResource,
    errorUploadDialogDocument
} from "../actions/dialogResource";
import { pushNotification } from "../actions/ui";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getDialogResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetDialogResource(result.data));
    } else {
        yield put(errorGetDialogResource(result.data));
    }
}

export function* watchGetDialogResource() {
    yield takeLatest('GET_DIALOG_RESOURCE', getDialogResourceCall);
}

export function* getDialogResourceDetailsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetDialogResourceDetails(result.data));
    } else {
        yield put(errorGetDialogResourceDetails(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchGetDialogResourceDetails() {
    yield takeLatest('GET_DIALOG_RESOURCE_DETAILS', getDialogResourceDetailsCall);
}

export function* createDialogResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (!!action?.data?.file) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDialogResource(resPiggy.data));
                yield put(doneCreateDialogResource(result.data));
            }
        } else {
            yield put(doneCreateDialogResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateDialogResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage,
                result: result,
                messageType: "error"
            }));
        }
    }
}

export function* watchCreateDialogResource() {
    yield takeLatest('CREATE_DIALOG_RESOURCE', createDialogResourceCall);
}

export function* updateDialogResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (!!action?.data?.file) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDialogResource(resPiggy.data));
                yield put(doneUpdateDialogResource(result.data));
            }
        } else {
            yield put(doneUpdateDialogResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateDialogResource(result.data));

        if (!!action.data.errorMessage) {
            const errorNotification = getErrorNotificationWithExceptions(result, action)

            yield put(pushNotification(errorNotification));
        }
    }
}

export function* watchUpdateDialogResource() {
    yield takeLatest('UPDATE_DIALOG_RESOURCE', updateDialogResourceCall);
}

export function* deleteDialogResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDialogResource(resPiggy.data));
                yield put(doneDeleteDialogResource(result.data));
            }
        } else {
            yield put(doneDeleteDialogResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteDialogResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDeleteDialogResource() {
    yield takeLatest('DELETE_DIALOG_RESOURCE', deleteDialogResourceContactCall);
}

export function* uploadDialogDocumentsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.uploadFiles, user, action.data.resource, action.data.files, action.data.params, action.data.descriptions, action.data.types);

    if (result && result.status === 0) {
        const resultPiggy = yield call(Api.getResource, user, Object.assign({
            id: action.data.id ? action.data.id : action.data.params.id
        }, action.data.piggyQuery ? action.data.piggyQuery : (action.data.query ? action.data.query : {})), action.data.piggyResource ? action.data.piggyResource : action.data.resource);

        if (resultPiggy && resultPiggy.status === 0) {
            yield put(doneGetDialogResource(resultPiggy.data));
            yield put(doneUploadDialogDocument(true));
        } else {
            yield put(errorUploadDialogDocument(result.data));
        }
    } else {
        yield put(errorUploadDialogDocument(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchUploadDialogDocument() {
    yield takeLatest('UPLOAD_DIALOG_DOCUMENT', uploadDialogDocumentsCall);
}
