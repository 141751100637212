import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import ModalDefault from "../../../common/components/modal/modal-default";
import SchedulerShareDialog from "./scheduler-share-dialog";
import {getResourceDiff} from "../../../data/actions/resourceDiff";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";
import CommandCenterTable from "../../../common/components/command-center-table";
import Button from "../../../common/components/button";
import ShareIcon from "@heroicons/react/20/solid/ShareIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import {checkPerm, classNames, getLookup} from "../../../common/util/util-helpers";
import {READ_PERM} from "../../../common/util/util-consts";
import ClosestMatchDialog from "./closest-match-dialog";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import {toFrontDate} from "../../../common/util/util-dates";

export default function CommandCenterView(props) {
    const autoRefreshIntervalRef = useRef();

    const translate = props.translate;
    const dispatch = useDispatch()
    const history = useHistory()
    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
    const [isClosestMatchDialogVisible, setIsClosestMatchDialogVisible] = useState(false);
    const [isClosestMatchListingActive, setIsClosestMatchListingActive] = useState(() => false);
    const [autocompleteFields, setAutocompleteFields] = useState({});
    const [closesMatchData, setClosesMatchData] = useState({});
    const resource = useSelector((state) => state.resourceDiff);
    const dialogInfoResource = useSelector((state) => state.dialogInfoResource);
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);
    const info = useSelector((state) => state.info);
    const contactResource = useSelector((state) => state.contactResource);

    const selectedState = useMemo(() => {
        return getLookup('State', 'StateID', 'StateAbbreviation')?.[autocompleteFields?.StateID];
    }, [autocompleteFields]);

    function fetchData(query = {}) {

        dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            resource: Resources.UnitsSchedule,
            data: {isInitial: true},
            query: query.TargetDate
                ? Object.assign({}, query, {TargetDate: query.TargetDate + " 00:00:00"})
                : {}
        }));

        if (!query.TargetDate) {
            setAutocompleteFields({})
            setIsClosestMatchListingActive(false);
            setClosesMatchData({});
        } else {
            setIsClosestMatchListingActive(true);
            setClosesMatchData(query);
        }
    }

    function fetchUpdateData() {
        dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            resource: Resources.UnitsSchedule,
            data: {isInitial: false},
            query: {}
        }))
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isAutoRefreshEnabled) {
            autoRefreshIntervalRef.current = setInterval(fetchUpdateData, 60000);
        } else {
            clearTimeout(autoRefreshIntervalRef.current);
        }

        return () => {
            clearTimeout(autoRefreshIntervalRef.current);
        }
    }, [isAutoRefreshEnabled]);

    return <Layout
        {...props} ui={ui} dispatch={dispatch} contactResource={contactResource} history={history} user={user}
        isAccessible={!(resource.errorStatus === 2)}
        accessibleMessageResource={resource}
        dialogInfoResource={dialogInfoResource}
    >
        <Page>
            <PageHeader
                title={translate('page.heading.command_center')}
                customHtml={
                    <div className="flex gap-2">
                        <div>
                            <Button
                                // TODO ADD PERM
                                onClick={() => setIsClosestMatchDialogVisible(true)}
                                className={
                                    classNames(
                                        "btn btn-outline px-2",
                                        isClosestMatchListingActive ? "rounded-r-none border-r-0" : undefined
                                    )
                                }
                            >
                                <MapPinIcon className="w-5 h-5 mr-1 -ml-1"/>
                                {!isClosestMatchListingActive && (
                                    translate("btn.order_by_closest_location_match")
                                )}

                                {isClosestMatchListingActive && (
                                    translate("btn.update_order_by_closest_location_match")
                                )}
                            </Button>

                            {isClosestMatchListingActive && (
                                <Button
                                    onClick={() => fetchData()}
                                    className="btn btn-outline px-2 rounded-l-none"
                                >
                                    <XMarkIcon className="w-5 h-5"/>
                                </Button>
                            )}
                        </div>

                        <Button
                            onClick={() => setIsShareDialogOpen(true)}
                            hasPerm={checkPerm(Resources.SchedulerShare, READ_PERM)}
                            appearance="primary"
                        >
                            <ShareIcon className="w-5 h-5 mr-1 -ml-1"/>
                            Create fleet share link
                        </Button>
                    </div>
                }
            />

            {isClosestMatchListingActive && (
                <InfoBar
                    message={translate("text.closest_match_info", [resource?.data?.list?.length ?? "", autocompleteFields?.CityName, selectedState, autocompleteFields?.PostalCode, closesMatchData?.TargetDate ? toFrontDate(closesMatchData?.TargetDate) : ""])}/>
            )}

            <CommandCenterTable
                list={resource?.data?.list ?? []}
                driverMileageDiff={resource?.data?.latestNonFutureLocationForDriver ?? {}}
                isClosestMatchListingActive={isClosestMatchListingActive}
                closesMatchData={Object.assign(autocompleteFields, {
                    state: selectedState
                }, closesMatchData)}
                setIsClosestMatchDialogVisible={setIsClosestMatchDialogVisible}
                comments={resource?.data?.comments ?? []}
                isLoading={resource.isLoading}
                isLoadingSilent={resource.isLoadingSilent}
                isAutoRefreshEnabled={isAutoRefreshEnabled}
                setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                onFetchData={fetchData}
                autoRefreshIntervalRef={autoRefreshIntervalRef}
                translate={translate}
                info={info}
            />
        </Page>

        <ModalDefault
            show={isShareDialogOpen}
            widthClass={"max-w-5xl"}
            title={translate("text.fleet_share_links")}
            closeButtonLabel={translate("btn.close")}
            onClose={() => setIsShareDialogOpen(false)}
        >
            <SchedulerShareDialog
                show={isShareDialogOpen}
                translate={translate}
            />
        </ModalDefault>

        <ClosestMatchDialog
            show={isClosestMatchDialogVisible}
            onClose={() => setIsClosestMatchDialogVisible(false)}
            autocompleteFields={autocompleteFields}
            setAutocompleteFields={setAutocompleteFields}
            onFetchData={fetchData}
            translate={translate}
        />
    </Layout>
}