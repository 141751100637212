import {FieldsManager} from '../../../../data/services/fields'
import React from 'react'
import {genericMoneyFormatter} from '../../../../common/util/util-vanilla'
import {getProp} from '../../../../common/util/util-helpers'
import {calculateLoanPayment} from "../../../../common/util/util-accounting";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";

export default function AccountingLoadBreakdown({
                                                    show,
                                                    cachedChangedFields,
                                                    amount = 0,
                                                    onShowMonthlyBreakdownClick,
                                                    translate
                                                }) {
    const values = FieldsManager.getFieldKeyValues(cachedChangedFields)
    const Amount = parseFloat(values['Amount'] ?? amount)
    const ResidualValue = parseFloat(values['ResidualValue'] ?? 0)
    const InterestRate = parseFloat(values['InterestRate'] ?? 0)
    const LoanTerm = parseFloat(values['LoanTerm'] ?? 0)
    const downPayment = getProp(values, 'DownPayment', 0)

    if (show && (Amount > 0) && (InterestRate >= 0) && (LoanTerm > 0)) {
        const paymentBreakdown = calculateLoanPayment(Amount - ResidualValue, InterestRate, LoanTerm, downPayment)
        return (
            <React.Fragment>
                {!isNaN(InterestRate) && !isNaN(LoanTerm) && !isNaN(parseFloat(paymentBreakdown.monthlyPayment)) && (
                    <div className="overflow-hidden mx-auto text-center">
                        {!!onShowMonthlyBreakdownClick && (
                            <button
                                className="btn btn-outline mb-6"
                                onClick={onShowMonthlyBreakdownClick}
                            >
                                {translate('btn.show_monthly_breakdown')}
                            </button>
                        )}

                        <dl className="grid grid-cols-1 divide-y divide-tm-gray-200 border-t border-tm-gray-200 bg-tm-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                            <div className="flex flex-col pl-6 pr-4 py-4">
                                <dt className="order-2 text-xs font-medium text-tm-gray-500">Monthly payment</dt>
                                <dd className="order-1 text-lg font-bold text-primary sm:text-xl sm:tracking-tight">
                                    {genericMoneyFormatter(paymentBreakdown.monthlyPayment)}
                                </dd>
                            </div>
                            <div className="flex flex-col px-4 pt-4">
                                <dt className="order-2 text-xs font-medium text-tm-gray-500">Total payment</dt>
                                <dd className="order-1 text-lg font-bold text-primary sm:text-xl sm:tracking-tight">
                                    {genericMoneyFormatter(paymentBreakdown.totalAmountPaid)} {downPayment > 0 ? ' + ' + genericMoneyFormatter(downPayment) : ''}
                                </dd>
                            </div>
                            <div className="flex flex-col pl-4 pr-6 pt-4">
                                <dt className="order-2 text-xs font-medium text-tm-gray-500">Interest</dt>
                                <dd className="order-1 text-lg font-bold text-primary sm:text-xl sm:tracking-tight">
                                    {genericMoneyFormatter(paymentBreakdown.interestAmount)}
                                </dd>
                            </div>
                        </dl>
                    </div>
                )}
            </React.Fragment>
        )
    }

    if (show && !Amount && (InterestRate >= 0) && (LoanTerm > 0)) {

        return <div className={'p-6'}>
            <InfoBar message="Amount value is needed for calculation"/>
        </div>
    }

    return null
}