import React, { useState } from "react";
import { MapPinIcon, BuildingOfficeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import {Field} from "../../../../data/services/fields";
import DataCard from "../../display-data/data-card";
import {classNames, checkPerm} from "../../../util/util-helpers";
import ModalFooter from "../modal-footer";
import Resources from "../../../../data/services/resources";
import {UPDATE_PERM} from "../../../../util/util-constants";
import Modal from "../index";
import CreateOfficeDialog from "../../../../views/my-company/offices-view/create-update-office-dialog";

export default function OfficeCard({translate, office, onClose}) {
    const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
    const [isOfficeFormDialogVisible, setIsOfficeFormDialogVisible] = useState(false)
    const [officeData, setOfficeData] = useState(office)

    const handleUpdateOfficeClick = () => {
        setIsOfficeFormDialogVisible(true)
    }

    const geInfoFields = () => {
        return {
            OfficeNumber: new Field('OfficeNumber', '', [''], false, 'text', {icon: () => BuildingOfficeIcon}),
            AddressName: new Field('AddressName', '', [''], false, 'address', {icon: () => MapPinIcon}),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'phone', {icon: () => PhoneIcon}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "pt-6"}),
        }
    }

    const getDispatchFields = () => {
        return {
            Division: new Field('Division', '', [''], false, 'text'),
            DispatchDocumentName: new Field('DispatchDocumentName', '', [''], false, 'select'),
            DispatchDocumentAddress: new Field('DispatchDocumentAddress', '', [''], false, 'select'),
            DispatchDocumentLogo: new Field('DispatchDocumentLogo', '', [''], false, 'select'),
            AccountingDocumentName: new Field('AccountingDocumentName', '', [''], false, 'select'),
            AccountingDocumentAddress: new Field('AccountingDocumentAddress', '', [''], false, 'select'),
            AccountingDocumentLogo: new Field('AccountingDocumentLogo', '', [''], false, 'select'),
        }
    }

    const getAccountingFields = () => {
        return {
            IsPaymentOverrideActive: new Field('IsPaymentOverrideActive', '', [], false, 'checkbox', {label: "paymentOverridden"}),
            AccountName: new Field('AccountName', '', [], false, 'text'),
            AccountNumber: new Field('AccountNumber', '', [], false, 'text'),
            RoutingNumber: new Field('RoutingNumber', '', [], false, 'text'),
            BankName: new Field('BankName', '', [], false, 'text'),
        }
    }

    const DispatchMetadata = {
        DispatchDocumentName: {
            1: 'Print Company Name',
            2: 'Print Division Name'
        },
        DispatchDocumentAddress: {
            1: 'Print Company Address',
            2: 'Print Division Address',
            3: 'Print Office Address',
        },
        DispatchDocumentLogo: {
            1: 'Print Company Logo',
            2: 'Print Division Logo'
        },
        AccountingDocumentName: {
            1: 'Print Company Name',
            2: 'Print Division Name'
        },
        AccountingDocumentAddress: {
            1: 'Print Company Address',
            2: 'Print Division Address',
        },
        AccountingDocumentLogo: {
            1: 'Print Company Logo',
            2: 'Print Division Logo'
        }
    }

    const dispatchFields = Object.values(getDispatchFields());

    return (
        <React.Fragment>
            <div className="relative text-tm-gray-700 h-dialog-body bg-tm-gray-50">
                <div className={'max-w-md mx-auto my-10'}>
                    <p className="text-2xl font-bold truncate text-tm-gray-900 mb-6">
                        {officeData.OfficeName}
                    </p>
                    <div className="space-y-2">
                        {Object.values(geInfoFields()).filter(it => officeData[it.name] !== undefined).map(field => {
                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={officeData}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>

                    {!isShowMoreVisible && (
                        <div className="text-center mt-10">
                            <button
                                onClick={() => setIsShowMoreVisible(true)}
                                className="btn btn-outline"
                            >
                                {translate("btn.show_more")}
                            </button>
                        </div>
                    )}

                    {!!isShowMoreVisible && (
                        <React.Fragment>
                            <p className="text-lg font-bold truncate text-tm-gray-900 mt-6 mb-2">
                                {translate("text.divisions")}
                            </p>

                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                {dispatchFields.filter(it => officeData[it.name] !== undefined).map((field, i) => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={officeData}
                                            selects={DispatchMetadata}
                                            className={classNames("px-3 py-1.5 flex items-center px-6", i < dispatchFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>

                            <p className="text-lg font-bold truncate text-tm-gray-900 mt-6 mb-2">
                                {translate("text.accounting")}
                            </p>

                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                {Object.values(getAccountingFields()).filter(it => officeData[it.name] !== undefined).map((field, i) => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={officeData}
                                            className={classNames("py-1.5 flex items-center px-6", i < dispatchFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>

            <ModalFooter
                buttonLabel={checkPerm(Resources.Offices, UPDATE_PERM) && translate("btn.edit")}
                onButtonClick={handleUpdateOfficeClick}
                closeButtonLabel={translate("btn.cancel")}
                onClose={onClose}
            />

            <Modal
                show={isOfficeFormDialogVisible}
                widthClass={'max-w-5xl'}
                onClose={() => setIsOfficeFormDialogVisible(false)}
            >
                <CreateOfficeDialog
                    selectedItem={officeData}
                    translate={translate}
                    close={() => setIsOfficeFormDialogVisible(false)}
                    setDialogState={setOfficeData}
                />
            </Modal>
        </React.Fragment>
    )
}
