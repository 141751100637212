import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import {checkPerm, getPreFillFromQuery, getProp} from '../../../common/util/util-helpers'
import {UPDATE_PERM} from '../../../util/util-constants'
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../common/components/card";
import {LoaderLarge} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";


class CreateSettlementTemplateTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,

            // Modals
            importFromTemplateModalOpen: false,
            submit: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource !== this.props.resource && !this.props.resource.isLoading && this.getID()) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource?.data?.list, '0', {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    submit = () => {
        this.setState({canSubmit: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && this.getID()) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        DriverSettlementTemplateID: getProp(this.props.resource, 'data.list.0.DriverSettlementTemplateID', 0)
                    }),
                    resource: this.getResourceName(),
                    piggyResource: this.getResourceName(),
                    piggyQuery: this.getQuery(),
                    errorMessage: true, successMessage: 'Driver settlement template successfully updated!',
                }))
            } else if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        Items: this.props.DeductionItems
                    }),
                    resource: this.getResourceName(),
                    piggyResource: this.getResourceName(),
                    piggyQuery: this.getQuery(),
                    errorMessage: true,
                    successMessage: 'Driver settlement template successfully created!',
                }))
                this.handleFormCancel();
            }
        })
        this.setState({canSubmit: false});
        this.props.handleIsDirty(false)
    }

    /** UI Events
     ================================================================= */

    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value)

        if (name === 'IsFactoringActive') {

            fields.Send1099.value = value ? "" : fields.Send1099.value;
            fields.Send1099.disabled = !!value;
        }
        if (name === 'LoadPayMethodID') {
            fields.PayMethodValueExtra.type = Number(value) === 7 ? 'money' : 'hidden'
            fields.PayMethodValueExtra.value = Number(value) === 7 ? fields.PayMethodValueExtra.value : ''
            fields.PayMethodValueExtra.metadata.addContainerClass = Number(value) === 7 ? "col-span-4" : "col-span-6"
            fields.PayMethodValue.metadata.addContainerClass = Number(value) === 7 ? "col-span-4" : "col-span-6"
            fields.LoadPayMethodID.metadata.addContainerClass = Number(value) === 7 ? "col-span-4" : "col-span-6"
            if (Number(value) >= 2 && Number(value) <= 5) {
                fields.PayMethodValue.type = 'percentage'
                fields.PayMethodValue.validate = ['float']
            } else {
                fields.PayMethodValue.type = 'money'
                fields.PayMethodValue.validate = ['empty']
            }
            fields.PayMethodValue.value = ''
        }
        this.setState({fields: fields, canSubmit: true})
        this.props.handleIsDirty(true)
    }

    handleFormCancel = () => {
        if (this.getID()) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource.data.list, '0', {})),
                canSubmit: false
            })
            this.props.handleIsDirty(false)
        } else {
            this.setState({
                fields: this.getFields(),
                canSubmit: false
            })
            this.props.handleIsDirty(false)
        }
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.match.params.id
    }

    getResourceName = () => {
        return Resources.DriverSettlementsTemplate
    }

    getQuery = () => {
        return {
            searchFields: JSON.stringify({DriverSettlementTemplateID: this.props.match.params.id})
        }
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            DriverSettlementTemplate: new Field('DriverSettlementTemplate', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select', {addContainerClass: item?.LoadPayMethodID == 7 ? 'col-span-4' : 'col-span-6'}),
            PayMethodValue: new Field('PayMethodValue', '', (item?.LoadPayMethodID >= 2 && item?.LoadPayMethodID <= 5) ? ['float'] : ['empty'], false, (item?.LoadPayMethodID >= 2 && item?.LoadPayMethodID <= 5) ? 'percentage' : 'money', {addContainerClass: item?.LoadPayMethodID == 7 ? 'col-span-4' : 'col-span-6'}),
            PayMethodValueExtra: new Field('PayMethodValueExtra', '', [], false, (item?.LoadPayMethodID == 7) ? 'money' : 'hidden', {addContainerClass: 'col-span-4'}),

            // PAY TO
            PayOnlyWhenPODReady: new Field('PayOnlyWhenPODReady', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                labelType: "float"
            }),
            IsPaysIFTA: new Field('IsPaysIFTA', '', [], false, 'hidden', {
                addContainerClass: 'col-span-4',
            }),
            IsPaysFuel: new Field('IsPaysFuel', '', [], false, 'hidden', {
                addContainerClass: 'col-span-4',
            }),
            Send1099: new Field('Send1099', '', [], false, 'hidden', {}),

            IsFactoringActive: new Field('IsFactoringActive', '', [], false, 'hidden', {}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
        if (!item) {
            item = {}
            const DriverSettlementTemplate = getPreFillFromQuery()
            if (DriverSettlementTemplate) {
                item.DriverSettlementTemplate = DriverSettlementTemplate
            }
        }

        return fillFieldsFromData(fieldTemplates, item, ['FuelCards', 'Transponders', 'Send1099'])
    }


    render() {
        const {translate, resource} = this.props

        const fieldsHtmlColumnOne = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange)


        return (
            <div className="space-y-4">
                <PageHeader
                    title={translate('text.PayPlan')}
                    titleClass="mr-5 text-2xl"
                    className={'flex justify-between'}
                >
                </PageHeader>

                <Card addClass="relative min-h-[33.5rem]" bodyClass="py-3 px-6">
                    <div className="grid grid-cols-12 gap-4">
                        {fieldsHtmlColumnOne}
                    </div>

                    {resource.isLoading && (
                        <LoaderLarge className="m-0" stripesBg/>
                    )}
                </Card>


                <PageFooter
                    translate={translate}
                    canSubmit={this.state.canSubmit || this.props.isDirty}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(Resources.DriverSettlementsTemplate, UPDATE_PERM) && this.submit}
                />
            </div>
        )
    }
}

export default connect(state => state)(CreateSettlementTemplateTab)
