import React, {useState} from 'react'
import ResourceTable from "../../../resource-table";
import {classNames, getProp} from "../../../../util/util-helpers";
import TableCard from "../../../resource-table/table-components/table-card";
import Dropzone from "react-dropzone";
import FileViewer from "../../../file-viewer/components";
import DocumentPreviewModal from "../document-preview-modal";
import {ArrowPathIcon, TrashIcon} from "@heroicons/react/24/outline";
import NoRecordsTable from "../../../no-records-found/no-records-table";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import PageHeader from "../../../layout/layout-components/page/page-header";
import ModalDefault from "../../../modal/modal-default";
import {currentDateTimeToUtc} from "../../../../util/util-dates";
import {fieldsToHtml, fillFieldsFromData} from "../../../../util/util-fields";
import ModalConfirm from "../../../modal/modal-confirm";
import ModalSaveResource from "../../../modal/modal-save-resource";
import {
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE
} from "../../../../../util/util-constants";

const DocumentVersionsModal = ({
                                   selectedItem,
                                   isLoading,
                                   translate,
                                   handleDownloadDocument,
                                   onDocumentDelete,
                                   onReturnExpiryDate,
                                   id,
                                   resourceName,
                                   onUploadVersion,
                                   documentTypes,
                                   onEditDocument
                              }) => {

    /** Constants
     ================================================================= */
    const versions = [selectedItem].concat(getProp(selectedItem, 'Versions', []).sort((a, b) => b.Version - a.Version))

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => {
        return {
            Version: new Field("Version", '', [], false, 'custom', {
                render: (item) => item.Version || "Current"
            }),
            CreateUpdateDate: new Field("CreateUpdateDate", '', [], false, 'datetimezone'),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'custom', {
                render: (item) => onReturnExpiryDate(item)
            })
        }
    }
    const getEditFields = (item = null) => {

        let fieldTemplates = {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                values: documentTypes
            }),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'},{isClearable: true}),
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }


        return fillFieldsFromData(fieldTemplates, item);
    }


    const getUploadFields = () => {
        return {
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date')
        }
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getUploadFields())
    const [selectedVersion, setSelectedVersion] = useState(null)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [file, setFile] = useState(null)
    const [previewDocumentModalOpen, setPreviewDocumentModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)

    /** UI events
     ================================================================= */
    const handleSubmit = () => {
        let validatedFields = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if  (selectedVersion) {
                onUploadVersion(file, { Version: selectedVersion.Version , ...FieldsManager.getFieldKeyValues(validatedFields) })
            } else {
                onUploadVersion(file, { Version: -1, ...FieldsManager.getFieldKeyValues(validatedFields) })
            }
            handleToggleCreateModal()
        } else {
            setFields(validatedFields)
        }
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
    }

    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
        setFields(getUploadFields())
        setSelectedVersion(null)
        setFile(null)
    }
    
    const handleToggleViewModal = (item = null) => {
        let documentIndex = null
        if (item) {
            documentIndex = versions.findIndex(elem => elem.DocumentID === item.DocumentID)
        }
        setSelectedDocumentIndex(documentIndex)
        setPreviewDocumentModalOpen(!previewDocumentModalOpen)
    }

    const handleOnPreviousDocumentPreviewClick = () => {
        selectedDocumentIndex > 0 && setSelectedDocumentIndex(selectedDocumentIndex - 1)
    }

    const handleOnNextDocumentPreviewClick = () => {
        selectedDocumentIndex < (versions.length - 1) && setSelectedDocumentIndex(selectedDocumentIndex + 1)
    }
    
    const handleToggleConfirmModal = (item = null) => {
        setConfirmModalOpen(!confirmModalOpen)
        setSelectedVersion(item)
    }

    const handleToggleEditModal = (item = null) => {
        setEditModalOpen(!editModalOpen)
        setSelectedVersion(item)
    }

    const handleToggleReplaceModal = (item = null) => {
        setCreateModalOpen(!createModalOpen)
        setFields(getUploadFields())
        setFile(null)
        setSelectedVersion(item)
    }
    /** Render
     ================================================================= */

    return (
        <div className={'p-5'}>
            <PageHeader
                onButtonClick={handleToggleCreateModal}
                buttonLabel={translate("btn.UploadNewVersion")}
            />

            <TableCard addClass={'relative mt-5 z-0'}>
                <ResourceTable
                    data={versions}
                    fields={getFields()}

                    limit={10} // Must keep limit as a prop even we dont have it in query, because 'Skeleton Loader' wont show up (can't calculate skeleton rows)

                    translate={translate}
                    isLoading={isLoading}

                    onRowClick={handleToggleViewModal}
                    onView={handleToggleViewModal}
                    onEdit={handleToggleEditModal}

                    actions={[
                        {
                            action: handleToggleReplaceModal,
                            icon: ArrowPathIcon, // make this a function
                            visible: () => true,
                            label: false, // make this a function
                            title: translate('text.DeleteAndReplace'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },{
                            action: handleToggleConfirmModal,
                            icon: TrashIcon, // make this a function
                            visible: (it) => !!it.Version,
                            label: false, // make this a function
                            title: translate('text.Delete'),
                            disabled: false,
                            class: false,
                            iconClass: false,
                            order: 100
                        },
                    ]}
                />

                <NoRecordsTable
                    show={versions.length === 0}
                    title={'No matching records found'}
                />
            </TableCard>


            <ModalDefault
                show={createModalOpen}
                widthClass={'max-w-5xl'}
                title={translate("modal_heading.upload_version_confirm")}

                close={handleToggleCreateModal}
                closeButtonLabel={translate('btn.cancel')}
                onClose={handleToggleCreateModal}

                buttonLabel={translate('Upload')}
                buttonDisabled={!file}
                onButtonClick={handleSubmit}
            >
                <div className="p-5">
                    <div className='relative'>
                        <span className="legend bg-inverse">Document</span>
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                setFile(Object.assign(acceptedFiles[0], {
                                    preview: URL.createObjectURL(acceptedFiles[0]),
                                    key: currentDateTimeToUtc()
                                }))
                            }}
                            accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                            maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                            multiple={false}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}
                                         className={
                                             classNames(
                                                 "mb-3 p-2 flex items-center justify-center rounded-card border-2 border-dashed border-tm-gray-300"
                                             )
                                         }
                                         style={{height: "40px"}}
                                    >
                                        <input {...getInputProps()} />

                                        <p className="m-0"
                                           style={{userSelect: "none"}}><i
                                            className="simple-icon-cloud-upload mr-1"/> Drop file to
                                            upload,
                                            or <span className='has-link'>browse</span>.</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>

                    {!!file && (
                        <div key={file.key}>
                            <FileViewer
                                filePath={URL.createObjectURL(file)}
                                fileType={file.name.split('.').pop()}
                                onError={(e) => {
                                    console.log(e)
                                }}
                            />

                            {fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange)}
                        </div>
                    )}
                </div>
            </ModalDefault>

            <DocumentPreviewModal
                show={previewDocumentModalOpen}
                translate={translate}
                item={versions[selectedDocumentIndex]}

                onButtonClick={() => {
                    handleDownloadDocument(Object.assign({}, versions[selectedDocumentIndex], {
                        PhysicalLocation: versions[selectedDocumentIndex].OriginalFilename
                    }))
                }}
                buttonLabel={translate("btn.Download")}
                closeButtonLabel={translate("btn.close")}
                onClose={handleToggleViewModal}

                showPreviousButton={selectedDocumentIndex > 0}
                showNextButton={selectedDocumentIndex < (versions.length - 1)}
                onNextDocumentPreviewClick={handleOnNextDocumentPreviewClick}
                onPreviousDocumentPreviewClick={handleOnPreviousDocumentPreviewClick}

                primaryKey={"DocumentID"}
                onReturnExpiryDate={onReturnExpiryDate}
                id={id}
                resourceName={resourceName}
            />
            
            <ModalConfirm
                title={"Confirm"}
                show={!!confirmModalOpen}
                text={translate("text.confirm_delete_document")}
                onClose={handleToggleConfirmModal}
                buttonLabel={translate("btn.confirm")}
                closeButtonLabel={translate('btn.cancel')}
                translate={translate}
                onConfirm={() => {
                    onDocumentDelete(selectedVersion)
                    handleToggleConfirmModal()
                }}
            />

            <ModalSaveResource
                title={"Edit document"}
                gridColsClass={"grid-cols-12"}
                show={editModalOpen}
                translate={translate}
                fields={getEditFields(selectedVersion)}
                onClose={handleToggleEditModal}
                onSubmit={(params) => {
                    params.id = selectedVersion.DocumentID;
                    params.Version = selectedVersion.Version;
                    onEditDocument(params);
                    handleToggleEditModal();
                }}
                metadata={{}}
            />
        </div>
    )
}

export default DocumentVersionsModal
