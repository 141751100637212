export function currentTheme(themeName) {
    return {
        type: 'CURRENT_THEME',
        data: themeName
    };
}

export function showModal(modalName, data) {
    return {
        type: 'SHOW_MODAL',
        data: {
            modalName,
            data
        }
    };
}

export function hideModal(data, delay) {
    return {
        type: 'HIDE_MODAL',
        data: data,
        delay: delay
    };
}

export function destroyModal(delay) {
    return {
        type: 'DESTROY_MODAL',
        delay: delay
    };
}

// Simplified version of showModal function
export function showGlobalModal(modalName, data) {
    return {
        type: 'SHOW_GLOBAL_MODAL',
        data: {
            modalName,
            data
        }
    };
}

export function hideGlobalModal(modalName, data) {
    return {
        type: 'HIDE_GLOBAL_MODAL',
        data: {
            modalName
        }
    };
}

export function showHelpSidebar() {
    return {
        type: 'SHOW_HELP_SIDEBAR',
        data: {}
    };
}

export function hideHelpSidebar() {
    return {
        type: 'HIDE_HELP_SIDEBAR',
        data: {}
    };
}

export function destroyHelpSidebar(delay) {
    return {
        type: 'DESTROY_HELP_SIDEBAR',
        delay: delay
    };
}

export function showChatSidebar() {
    return {
        type: 'SHOW_CHAT_SIDEBAR',
        data: {}
    };
}

export function hideChatSidebar() {
    return {
        type: 'HIDE_CHAT_SIDEBAR',
        data: {}
    };
}

export function destroyChatSidebar(delay) {
    return {
        type: 'DESTROY_CHAT_SIDEBAR',
        delay: delay
    };
}

export function showSecondModal(secondModalName, data) {
    return {
        type: 'SHOW_SECOND_MODAL',
        data: {
            secondModalName,
            data
        }
    };
}

export function hideSecondModal(data) {
    return {
        type: 'HIDE_SECOND_MODAL',
        data: data
    };
}

export function destroySecondModal(delay) {
    return {
        type: 'DESTROY_SECOND_MODAL',
        delay: delay
    };
}

export function showHistoryModal() {
    return {
        type: 'SHOW_HISTORY_MODAL',
        data: {}
    };
}

export function hideHistoryModal() {
    return {
        type: 'HIDE_HISTORY_MODAL',
        data: {}
    };
}

export function destroyHistoryModal() {
    return {
        type: 'DESTROY_HISTORY_MODAL',
        data: {}
    };
}

/*
 * @TODO: Refactor and remove 95% of unnecessary actions
 */
export function hideModals() {
    return {
        type: 'HIDE_MODALS',
        data: {}
    };
}

//
// export function showSupportModal() {
//     return {
//         type: 'SHOW_SUPPORT_MODAL',
//         data: {}
//     };
// }
//
// export function hideSupportModal() {
//     return {
//         type: 'HIDE_SUPPORT_MODAL',
//         data: {}
//     };
// }
//
// export function destroySupportModal() {
//     return {
//         type: 'DESTROY_SUPPORT_MODAL',
//         data: {}
//     };
// }

// export function showOnBoardModal() {
//     return {
//         type: 'SHOW_ONBOARD_MODAL',
//         data: {}
//     };
// }
//
// export function hideOnBoardModal() {
//     return {
//         type: 'HIDE_ONBOARD_MODAL',
//         data: {}
//     };
// }
//
// export function destroyOnBoardModal() {
//     return {
//         type: 'DESTROY_ONBOARD_MODAL',
//         data: {}
//     };
// }

export function hideCreateOfficeDialog() {
    return {
        type: 'HIDE_CREATE_OFFICE_DIALOG',
        data: {}
    };
}

export function showCreateOfficeDialog() {
    return {
        type: 'SHOW_CREATE_OFFICE_DIALOG',
        data: {}
    };
}

export function destroyCreateOfficeDialog() {
    return {
        type: 'DESTROY_CREATE_OFFICE_DIALOG',
        data: {}
    };
}

export function hideDeleteeOfficeDialog() {
    return {
        type: 'HIDE_DELETE_OFFICE_DIALOG',
        data: {}
    };
}

export function showDeleteOfficeDialog() {
    return {
        type: 'SHOW_DELETE_OFFICE_DIALOG',
        data: {}
    };
}

export function destroyDeleteOfficeDialog() {
    return {
        type: 'DESTROY_DELETE_OFFICE_DIALOG',
        data: {}
    };
}

export function showConfirmDialog() {
    return {
        type: 'SHOW_CONFIRM_DIALOG',
        data: {}
    };
}


export function hideConfirmDialog() {
    return {
        type: 'HIDE_CONFIRM_DIALOG',
        data: {}
    };
}

export function destroyConfirmDialog() {
    return {
        type: 'DESTROY_CONFIRM_DIALOG',
        data: {}
    };
}


export function showConfirmRedirectionDialog() {
    return {
        type: 'SHOW_CONFIRM_REDIRECTION_DIALOG',
        data: {}
    };
}


export function hideConfirmRedirectionDialog() {
    return {
        type: 'HIDE_CONFIRM_REDIRECTION_DIALOG',
        data: {}
    };
}

export function destroyConfirmRedirectionDialog() {
    return {
        type: 'DESTROY_CONFIRM_REDIRECTION_DIALOG',
        data: {}
    };
}

export function showUpdateOfficeDialog() {
    return {
        type: 'SHOW_UPDATE_DIALOG',
        data: {}
    };
}


export function hideUpdateOfficeDialog() {
    return {
        type: 'HIDE_UPDATE_DIALOG',
        data: {}
    };
}

export function destroyUpdateOfficeDialog() {
    return {
        type: 'DESTROY_UPDATE_DIALOG',
        data: {}
    };
}

export function showInfoOfficeDialog() {
    return {
        type: 'SHOW_INFO_DIALOG',
        data: {}
    };
}


export function hideInfoOfficeDialog() {
    return {
        type: 'HIDE_INFO_DIALOG',
        data: {}
    };
}

export function destroyInfoOfficeDialog() {
    return {
        type: 'DESTROY_INFO_DIALOG',
        data: {}
    };
}

export function showMapDialog(data) {
    return {
        type: 'SHOW_MAP_DIALOG',
        data: data
    };
}


export function hideMapDialog() {
    return {
        type: 'HIDE_MAP_DIALOG',
        data: {}
    };
}

export function destroyMapDialog() {
    return {
        type: 'DESTROY_MAP_DIALOG',
        data: {}
    }
}


export function showResourceDialog() {
    return {
        type: 'SHOW_RESOURCE_DIALOG',
        data: {}
    }
}

export function hideResourceDialog() {
    return {
        type: 'HIDE_RESOURCE_DIALOG',
        data: {}
    }
}

export function destroyResourceDialog() {
    return {
        type: 'DESTROY_RESOURCE_DIALOG',
        data: {}
    }
}

export function showGlobalSearchPopup() {
    return {
        type: 'SHOW_GLOBAL_SEARCH_POPUP',
        data: {}
    }
}

export function hideGlobalSearchPopup() {
    return {
        type: 'HIDE_GLOBAL_SEARCH_POPUP',
        data: {}
    }
}

export function destroyGlobalSearchPopup() {
    return {
        type: 'DESTROY_GLOBAL_SEARCH_POPUP',
        data: {}
    }
}

export function showGlobalInfoSearchPopup() {
    return {
        type: 'SHOW_GLOBAL_INFO_SEARCH_POPUP',
        data: {}
    }
}

export function hideGlobalInfoSearchPopup() {
    return {
        type: 'HIDE_GLOBAL_INFO_SEARCH_POPUP',
        data: {}
    }
}

export function destroyGlobalInfoSearchPopup() {
    return {
        type: 'DESTROY_GLOBAL_INFO_SEARCH_POPUP',
        data: {}
    }
}

export function showSetupNewLoadDialog() {
    return {
        type: 'SHOW_SETUP_NEW_LOAD_DIALOG',
        data: {}
    }
}

export function hideSetupNewLoadDialog() {
    return {
        type: 'HIDE_SETUP_NEW_LOAD_DIALOG',
        data: {}
    }
}

export function destroySetupNewLoadDialog() {
    return {
        type: 'DESTROY_SETUP_NEW_LOAD_DIALOG',
        data: {}
    }
}

export function showSetupNewLoadDialogSeccond() {
    return {
        type: 'SHOW_SETUP_NEW_LOAD_DIALOG_SECCOND',
        data: {}
    }
}

export function hideSetupNewLoadDialogSeccond() {
    return {
        type: 'HIDE_SETUP_NEW_LOAD_DIALOG_SECCOND',
        data: {}
    }
}

export function destroySetupNewLoadDialogSeccond() {
    return {
        type: 'DESTROY_SETUP_NEW_LOAD_DIALOG_SECCOND',
        data: {}
    }
}

export function pushNotification(data) {
    return {
        type: 'PUSH_NOTIFICATION',
        data: data
    }
}

export function closeNotification(data) {
    return {
        type: 'CLOSE_NOTIFICATION',
        data: data
    }
}

export function displayUnreadNotificationCount(data) {
    return {
        type: 'DISPLAY_UNREAD_NOTIFICATIONS_COUNT',
        data
    }
}

export function getNotificationList(data) {
    return {
        type: 'GET_NOTIFICATION_LIST',
        data
    }
}

export function doneGetNotificationList(data) {
    return {
        type: 'DONE_GET_NOTIFICATION_LIST',
        data
    }
}

export function errorGetNotificationList(data) {
    return {
        type: 'ERROR_GET_NOTIFICATION_LIST',
        data
    }
}

export function getCommunicationList(data) {
    return {
        type: 'GET_COMMUNICATION_LIST',
        data: data
    }
}

export function doneGetCommunicationList(data) {
    return {
        type: 'DONE_GET_COMMUNICATION_LIST',
        data: data
    }
}

export function errorGetCommunicationList(data) {
    return {
        type: 'ERROR_GET_COMMUNICATION_LIST',
        data: data
    }
}

export function getConversation(data) {
    return {
        type: 'GET_CONVERSATION',
        data: data
    }
}

export function doneGetConversation(data) {
    return {
        type: 'DONE_GET_CONVERSATION',
        data: data
    }
}

export function errorGetConversation(data) {
    return {
        type: 'ERROR_GET_CONVERSATION',
        data: data
    }
}

export function pushLoadFields(data) {
    return {
        type: 'PUSH_LOAD_FIELDS',
        data: data
    }
}

export function closeLoadFields(data) {
    return {
        type: 'CLOSE_LOAD_FIELDS',
        data
    }
}

export function closeNotificationDelayed(data) {
    return {
        type: 'CLOSE_NOTIFICATION_DELAY',
        data
    }
}

export function changeTheme(data) {
    return {
        type: 'CHANGE_THEME',
        data
    }
}

export function updateChartWidth() {
    return {
        type: "UPDATE_CHART_WIDTH"
    }
}

export function chartWidthChanged() {
    return {
        type: "CHART_WIDTH_CHANGED"
    }
}

export function createTask(data) {
    return {
        type: 'CREATE_TASK_DIALOG',
        data: data
    }
}

export function closeCreateTaskDialog() {
    return {
        type: 'CLOSE_TASK_DIALOG'
    }
}

export function destroyTaskDialog() {
    return {
        type: 'DESTROY_TASK_DIALOG'
    }
}

export function grabUpdateSignal() {
    return {
        type: 'GRAB_UPDATE'
    }
}

export function layoutUpdate(data) {
    return {
        type: 'LAYOUT_UPDATE',
        data: data
    }
}

export function resetIdleTimer() {
    return {
        type: 'RESET_IDLE_TIMER'
    }
}
