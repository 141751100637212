import {Field, FieldsManager} from "../../../../data/services/fields";
import React, {useEffect, useState} from "react";
import LocalStorage from "../../../../util/localStorage";
import {createDialogResource, deleteDialogResource, getDialogResource} from "../../../../data/actions/dialogResource";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import {checkPerm, getDefaultTableOptions, getProp, openInNewTab} from "../../../../common/util/util-helpers";
import {CREATE_PERM, DEFAULT_DATABASE_DATE_FORMAT} from "../../../../util/util-constants";
import {EnvelopeIcon, LinkIcon} from "@heroicons/react/24/outline";
import {copyToClipboard, emailValid} from "../../../../common/util/util-vanilla";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import moment from "moment";
import ModalDefault from "../../../../common/components/modal/modal-default";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import Button from "../../../../common/components/button";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import ResourceTable from '../../../../common/components/resource-table'

export default function SchedulerShareDialog({show, translate}) {
    const dispatch = useDispatch();
    const resource = useSelector(state => state.dialogResource);
    const data = getProp(resource.data, 'list', []);
    const isLoading = resource?.isLoading;
    const hasData = (data.length === 0) && !isLoading;
    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
    const [tableOptions, setTableOptions] = useState(() => getDefaultTableOptions(getFields(), {}, 'command-center', translate));
    const [queryFields, setQueryFields] = useState(() => getQueryFields());
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedEmailItem, setSelectedEmailItem] = useState(() => {
    });
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [emailFields, setEmailFields] = useState(() => getEmailFields());

    function fetchData() {
        dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: Resources.SchedulerShare,
            query: getQuery()
        }))
    }

    function handleSendEmailClick() {
        const validatedEmailFields = Object.assign({}, FieldsManager.validateFields(emailFields));

        validatedEmailFields.Emails.value = validatedEmailFields.Emails.value.filter(it => !it.invalid).map(it => {
            if (it.Contact) {
                return it.Contact.Email;
            }

            return it.value;
        });

        if (!validatedEmailFields.Emails.value.length) {
            validatedEmailFields.Emails.errorMessage = ['no_valid_emails'];
        }

        if (FieldsManager.checkFieldsForErrors(validatedEmailFields)) {
            const params = Object.assign({
                id: selectedEmailItem.FleetShareLinkID,
            }, FieldsManager.getFieldKeyValues(validatedEmailFields));

            params.Emails = params.Emails.join(",");

            dispatch(createDialogResource({
                user: LocalStorage.get('user'),
                query: getQuery(),
                params: params,
                resource: Resources.SchedulerShareSend,
                errorMessage: true,
                successMessage: 'Email public link sent',
            }));

        } else {
            setEmailFields(validatedEmailFields);
        }
    }

    function handleEmailInputChange(name, value) {
        let emailFieldsUpdate = Object.assign({}, emailFields);

        emailFieldsUpdate = FieldsManager.updateField(emailFieldsUpdate, name, value);

        setEmailFields(emailFieldsUpdate)
    }

    function copyShareLinkClick(it) {
        copyToClipboard(window.location.origin + "/fleet/public/" + it.ExternalAccessToken);
    }

    function visitShareLinkClick(it) {
        openInNewTab(window.location.origin + "/fleet/public/" + it.ExternalAccessToken);
    }

    function handleSendLinkClick(it) {
        setIsEmailModalOpen(true);
        setSelectedEmailItem(it);
    }

    function handleCreateEmail(value) {
        if (!emailFields.Emails.value) {
            emailFields.Emails.value = []
        }

        emailFields.Emails.value.push({
            value: value,
            label: value,
            invalid: !emailValid(value),
            manual: true
        });

        handleEmailInputChange('Emails', emailFields.Emails.value);
    }

    function handleDeleteShareClick(item) {
        setSelectedItem(item);
    }

    function deleteShare() {
        dispatch(deleteDialogResource({
            user: LocalStorage.get('user'),
            query: Object.assign({
                FleetShareLinkID: selectedItem.FleetShareLinkID
            }, {}),
            piggyQuery: getQuery(),
            errorMessage: true,
            successMessage: translate(`message.scheduler_share_deleted`),
            resource: Resources.SchedulerShare,
            piggyResource: Resources.SchedulerShare,
        }));

        setSelectedItem({});
    }

    function getQuery() {
        return FieldsManager.getFieldKeyValues(queryFields);
    }

    function handleQueryInputChange(name, value) {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        if ("sortBy" === name && queryFieldsUpdate.sortBy.value === value) {
            queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'DESC' ? 'ASC' : 'DESC';
        }

        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        setQueryFields(queryFieldsUpdate)
    }

    function getFields() {
        return {
            Description: new Field('Description', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            ExternalNotesCustomer: new Field('ExternalNotesCustomer', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-1'
            }, {
                minDate: moment().format(DEFAULT_DATABASE_DATE_FORMAT),
                maxDate: moment().add(2, "week").format(DEFAULT_DATABASE_DATE_FORMAT)
            }),
        }
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search'),
            sort: new Field('sort', 'DESC', ['']),
            sortBy: new Field('sortBy', 'CreateUpdateDate', ['']),
            offset: new Field('offset', '', ['']),
            limit: new Field('limit', 10, [''], false, 'select')
        }
    }

    function getEmailFields() {
        return {
            Emails: new Field('Emails', '', ['empty'], false, 'creatable-select-search', {}, {
                formatCreateLabel: userInput => translate('text.add_email') + ' "' + userInput + '"',
                onCreateOption: (_, value) => handleCreateEmail(value),
                isMulti: true,
                placeholder: "Select contact or type email address manually"
            }),
            Subject: new Field('Subject', '', ['empty'], false, 'text', {}),
            Content: new Field('Content', '', [], false, 'rich-text', {
                addContainerClass: 'col-span-full'
            }),
        }
    }

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show]);

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    return <div>
        <div className="flex w-full pb-2 pt-3 px-4 border-b border-tm-gray-300">
            <TableFilters
                className=" "
                hideLimit
                filterFields={queryFields}
                handleInputChange={handleQueryInputChange}
                translate={translate}
                isLoading={isLoading}
            />

            <div className="flex flex-1 items-center justify-end">
                {!hasData && !isLoading && (
                    <Button
                        hasPerm={checkPerm(Resources.SchedulerShare, CREATE_PERM)}
                        onClick={() => setIsShareDialogOpen(true)}
                        className="btn btn-outline"
                    >
                        Create new fleet share link
                    </Button>
                )}
            </div>
        </div>

        <ResourceTable
            data={data}
            fields={getFields()}
            tableKey="FleetShareLinkID"

            translate={translate}
            isLoading={isLoading}

            options={tableOptions}

            queryFields={queryFields}
            onSortChange={(name) => handleQueryInputChange("sortBy", name)}
            onDelete={handleDeleteShareClick}
            actions={[
                {
                    tooltipText: () => translate('text.send_link_via_email'),
                    action: (it) => handleSendLinkClick(it),
                    icon: () => EnvelopeIcon,
                },
                {
                    tooltipText: () => translate('text.visit_link'),
                    action: (it) => visitShareLinkClick(it),
                    icon: () => ArrowTopRightOnSquareIcon,
                },
                {
                    tooltipText: () => translate('text.copy_share_link'),
                    action: (it) => copyShareLinkClick(it),
                    icon: () => LinkIcon,
                }
            ]}
            saveTableOptions={setTableOptions}
        />

        <NoRecordsTable
            show={hasData}
            title={translate('text.no_fleet_share_on_the_record')}
            // text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
            hasPerm={checkPerm(Resources.SchedulerShare, CREATE_PERM)}
            btnLabel="Create new fleet share link"
            onBtnClick={() => setIsShareDialogOpen(true)}
        />

        <ModalSaveResource
            show={isShareDialogOpen}
            title={'Customer Fleet Share'}
            widthClass="max-w-md"
            gridColsClass="grid-cols-3"
            onClose={() => setIsShareDialogOpen(false)}
            fields={getFields()}
            handleInputChange={(fields, name, value) => {
                let fieldsUpdate = Object.assign({}, fields);

                fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);

                return fieldsUpdate;
            }}
            onSubmit={(params) => {
                if (params) {
                    dispatch(createDialogResource({
                        user: LocalStorage.get('user'),
                        query: getQuery(),
                        params: params,
                        resource: Resources.SchedulerShare,
                        piggyResource: Resources.SchedulerShare,
                        errorMessage: true, successMessage: 'Credit account updated.',
                    }));

                    setIsShareDialogOpen(false);
                }
            }}
            translate={translate}
            htmlBefore={
                <>
                    <div className={'px-5 mt-3'}>

                    </div>
                </>
            }
        />

        <ModalConfirm
            title={translate("text.confirm_delete_title", [selectedItem.Description])}
            show={!!selectedItem?.Description}
            text={translate("text.confirm_delete_share")}
            onClose={() => setSelectedItem({})}
            buttonLabel={translate("btn.confirm")}
            closeButtonLabel={translate('btn.cancel')}
            translate={translate}
            onConfirm={() => {
                deleteShare();
            }}
        />

        <ModalDefault
            show={isEmailModalOpen}
            title={"Share link via email"}
            widthClass={"max-w-2xl w-screen"}
            onClose={() => setIsEmailModalOpen(false)}
            closeButtonLabel={translate("btn.close")}
            buttonLabel="Send"
            onButtonClick={handleSendEmailClick}
            buttonDisabled={false}
            // buttonPerm={checkPerm(Resources.SchedulerShareSend, UPDATE_PERM)}
            translate={translate}
        >
            <div className="p-4 gap-4 grid grid-cols-1">
                <FieldsToHtml
                    fieldsState={emailFields}
                    translate={translate}
                    onInputChange={handleEmailInputChange}
                    selects={{
                        Emails: {
                            api: "api/" + Resources.Contacts,
                            query: {limit: 8, offset: 0},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName,
                                Contact: item
                            })
                        }
                    }}
                />
            </div>
        </ModalDefault>
    </div>
}