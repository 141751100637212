import React, {useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import ModalHeader from '../modal/modal-header'
import ModalFooter from '../modal/modal-footer'
import moment from 'moment-timezone'
import ArrowPathIcon from '@heroicons/react/20/solid/ArrowPathIcon'
import {getUserTimeFormat, getTimeFromServerDate} from "../../util/util-dates";
import {classNames} from '../../util/util-helpers'
import {cloneDeep, scrollErrorIntoView} from '../../util/util-vanilla'
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../util/util-constants";
import InfoBar from "../info-paragraph/info-bar";
import {fieldsToHtml} from "../../util/util-fields";

export default function TaskPostponeForm({StartDate, StartDateTime, DueDate, DueDateTime, translate, onClose, onSubmit}) {

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [undoButtonDisabled, setUndoButtonDisabled] = useState(true)

    const onUndo = () => {
        const Notes = fields.Notes.value
        setFields(getFields(Notes))
        setButtonDisabled(true)
        setUndoButtonDisabled(true)
    }

    const getFields = (Notes = '') => {

        const minStartDate = DueDate ? DueDate.split(" ")[0] : undefined;
        const minDueDate = StartDate ? StartDate.split(" ")[0] : undefined;

        return ({
            PostponeStartDate: new Field('PostponeStartDate', StartDate, [''], false, 'datetimezone', {addContainerClass: 'col-span-3'}, {maxDate: minStartDate}),
            PostponeStartDateTime: new Field('PostponeStartDateTime', StartDateTime, [''], false, 'timezone-custom', {addContainerClass: 'col-span-3'}),
            PostponeDueDate: new Field('PostponeDueDate', DueDate, [''], false, 'datetimezone', {addContainerClass: 'col-span-3'}, {minDate: minDueDate}),
            PostponeDueDateTime: new Field('PostponeDueDateTime', DueDateTime, [''], false, 'timezone-custom', {addContainerClass: 'col-span-3'}),
            Notes: new Field('Notes', Notes, [''], false, 'textarea', {addContainerClass: 'col-span-6'}),
        })
    }

    const [fields, setFields] = useState(getFields())

    const handleInputChange = (name, value) => {
        let fieldsUpdate = fields;

        if (name === 'PostponeStartDateTime') {
            const postponeStartDate = fieldsUpdate.PostponeStartDate.value ?? moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);
            fieldsUpdate.PostponeStartDate.value = postponeStartDate.split(" ")[0] + " " + moment(value, getUserTimeFormat()).format(DEFAULT_DATABASE_DATETIME_FORMAT).split(" ")[1];
        }

        if (name === 'PostponeDueDateTime') {
            const postponeDueDate = fieldsUpdate.PostponeDueDate.value ?? moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);
            fieldsUpdate.PostponeDueDate.value = postponeDueDate.split(" ")[0] + " " + moment(value, getUserTimeFormat()).format(DEFAULT_DATABASE_DATETIME_FORMAT).split(" ")[1];
        }

        if (name === 'PostponeStartDate') {
            if (!fieldsUpdate.PostponeDueDate.props) {
                fieldsUpdate.PostponeDueDate.props = {};
            }
            fieldsUpdate.PostponeDueDate.props.minDate = value.split(" ")[0];
        }

        if (name === 'PostponeDueDate') {
            if (!fieldsUpdate.PostponeStartDate.props) {
                fieldsUpdate.PostponeStartDate.props = {};
            }
            fieldsUpdate.PostponeStartDate.props.maxDate = value.split(" ")[0];
        }

        setFields(FieldsManager.updateField(fieldsUpdate, name, value));
        if ('Notes' !== name) {
            setButtonDisabled(false)
            setUndoButtonDisabled(false)
        }
    }

    const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange)

    const postponeTask = (it) => {
        let fieldsUpdate = cloneDeep(fields)
        const value = it.split('_')[0]
        const valueType = it.split('_')[1]
        let date
        if (!!fieldsUpdate.PostponeDueDate.value) {
            date = fieldsUpdate.PostponeDueDate.value
        } else {
            date = fieldsUpdate.PostponeStartDate.value
        }

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, 'PostponeDueDate', moment(date, DEFAULT_DATABASE_DATETIME_FORMAT).add(value, valueType).format(DEFAULT_DATABASE_DATETIME_FORMAT))
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, 'PostponeDueDateTime', getTimeFromServerDate(moment(date, DEFAULT_DATABASE_DATETIME_FORMAT).add(value, valueType).format(getUserTimeFormat())))

        setFields(fieldsUpdate)
        setButtonDisabled(false)
        setUndoButtonDisabled(false)
    }

    const getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(fields)

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields)
        } else {
            setFields(validatedFields)

            scrollErrorIntoView(validatedFields)
        }
    }

    const submit = () => {
        onSubmit(getValidFields())
    }

    const diff = (startDate, endDate) => {
        if (!startDate || !endDate) return false;

        let isNegative = false;

        let start = moment(startDate, DEFAULT_DATABASE_DATETIME_FORMAT);
        let end = moment(endDate, DEFAULT_DATABASE_DATETIME_FORMAT); // another date

        if (start.isAfter(end)) {
            isNegative = true;
            const startOld = start.clone();
            start = end.clone();
            end = startOld.clone();
        }

        const days = Math.floor(end.diff(start, 'days', true));
        const hours = Math.floor(end.diff(start, 'hours', true)) - (days * 24);
        const minutes = end.diff(start, 'minutes', true) - (days * 1440 + hours * 60);

        let result = {
            days: !!days && translate("text.day_abbr", [days]),
            hours: !!hours && translate("text.hour_abbr", [hours]),
            minutes: !!minutes && translate("text.minute_abbr", [minutes])
        }

        return (!!isNegative ? "- " : "") + (Object.values(result).filter(it => !!it).reduce((memo, it) => {
            memo.push(it);
            return memo;
        }, []).join(", "));
    }

    const StartDateDiff = diff(StartDate, fields.PostponeStartDate.value);
    const DueDateDateDiff = diff(DueDate, fields.PostponeDueDate.value);

    return (
        <React.Fragment>
            <ModalHeader
                className="border-tm-gray-200 border-b text-tm-gray-900"
                title={translate('text.postpone_task')}
                onClose={onClose}
            />

            {!!StartDate && (
                <div className="grid gap-4 grid-cols-12 px-6 pt-6 text-tm-gray-900">
                    <div className="col-span-full">
                        Postpone by:
                    </div>

                    {['15_minutes', '30_minutes', '1_hours', '2_hours', '1_days', '1_weeks', '1_months'].map((it, i) => (
                        <button
                            key={it}
                            className={classNames(i > 3 ? 'col-span-4' : 'col-span-3', 'hover:bg-primary hover:text-white border border-tm-gray-200 text-tm-gray-900 p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-2 flex-grow bg-tm-gray-100 rounded-md justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-popup focus:ring-primary-shade')}
                            onClick={() => postponeTask(it)}
                        >
                            {translate('text.' + it)}
                        </button>
                    ))}
                </div>
            )}

            <div className={classNames("p-6", !StartDateDiff && !DueDateDateDiff ? "invisible" : undefined)}>
                <InfoBar>
                    <div className="space-y-2 pl-2">
                        {StartDateDiff && (
                            <p><span className="mr-2">Start time postponed by:</span> {StartDateDiff}</p>
                        )}

                        {DueDateDateDiff && (
                            <p><span className="mr-2">Due time postponed by:</span> {DueDateDateDiff}</p>
                        )}
                    </div>
                </InfoBar>
            </div>

            <div className="grid gap-4 px-6 pb-6 grid-cols-6">
                {fieldsHtml}
            </div>

            <p className="px-6 pb-3 text-tm-gray-700">
                {translate('text.change_task_date')}
                <br/>
                {translate('text.change_task_date_history')}
                <br/>
                {translate('text.change_task_date_requirements')}
            </p>

            <ModalFooter
                buttonLabel={translate('btn.save')}
                buttonDisabled={buttonDisabled}
                onButtonClick={submit}
                closeButtonLabel={translate('btn.close')}
                secondaryButtonLabel={<div className="flex items-center"><ArrowPathIcon
                    className="w-5 h-5 mr-1"/> {translate('btn.undo')}</div>}
                onSecondaryButtonClick={onUndo}
                secondaryButtonDisabled={undoButtonDisabled}
                onClose={onClose}
            />
        </React.Fragment>
    )
}
