import React from 'react';
import {
    TASK_STATUS_CLOSED,
    TASK_STATUS_COMPLETED,
    TASK_STATUS_IN_PROGRESS,
    TASK_STATUS_OPEN
} from "../../../../../util/util-constants";
import { classNames, getLookup } from "../../../../util/util-helpers";
import EllipsisHorizontalCircleIcon from "@heroicons/react/24/outline/EllipsisHorizontalCircleIcon";
import ChartPieIcon from "@heroicons/react/24/outline/ChartPieIcon";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import ShieldCheckIcon from "@heroicons/react/24/outline/ShieldCheckIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";

export default function TaskStatusProgressTableV2(props) {
    const {TaskStatusID, translate} = props;
    const taskStatusLookup = getLookup("TaskStatus");

    const taskStatusesArray = Object.values(taskStatusLookup ?? {});
    let taskStatus = taskStatusesArray[TaskStatusID - 1];
    if (!taskStatus) {
        taskStatus = translate("text.canceled")
    }

    const getBadgeClass = () => {
        switch (TaskStatusID) {
            case TASK_STATUS_IN_PROGRESS:
                return "bg-sky-500/10"
            case TASK_STATUS_COMPLETED:
            case TASK_STATUS_CLOSED:
                return "bg-green-500/10"
            default:
                return "bg-gray-500/10"
        }
    }

    const getBadgeIcon = () => {
        switch (TaskStatusID) {
            case TASK_STATUS_OPEN:
                return <EllipsisHorizontalCircleIcon className="w-5 h-5 mr-2 text-gray-500" />
            case TASK_STATUS_IN_PROGRESS:
                return <ChartPieIcon className="w-5 h-5 mr-2 text-sky-500" />
            case TASK_STATUS_COMPLETED:
                return <CheckCircleIcon className="w-5 h-5 mr-2 text-green-500" />
            case TASK_STATUS_CLOSED:
                return <ShieldCheckIcon className="w-5 h-5 mr-2 text-green-500" />
            default:
                return <InformationCircleIcon className="w-5 h-5 mr-2 text-gray-500" />
        }
    }

    return (

        <div
            className={classNames("inline-flex items-center rounded-btn pl-2.5 pr-4 py-0.5 text-xs font-base font-semibold text-tm-gray-900", getBadgeClass())}>
            {getBadgeIcon()} {taskStatus}
        </div>
    )
}
