import {ArrowSmallDownIcon, ArrowSmallUpIcon, ChevronDownIcon} from "@heroicons/react/24/outline";
import PopOver from "../popover";
import React from "react";
import {numberWithCommas} from "../../util/util-formaters";
import LoaderSmall from "../loader/loader-small";
import {numberWithCommasToBack} from "../../../util/util-formaters";
import {classNames, getProp} from "../../util/util-helpers";

const WidgetStats = ({stats,isLoading}) => {

    const percentDiff = (item) => {
        const currentValue = numberWithCommasToBack(item?.value);
        const prevValue = numberWithCommasToBack(item?.valueOld);

        if (!currentValue || !prevValue) return {};

        let percent = (currentValue * 100) / prevValue;

        if (percent >= 100) {
            return {value: (percent - 100).toFixed(2), changeType: "increase"}
        } else {
            return {value: (100 - percent).toFixed(2), changeType: "decrease"};
        }
    }

    return (
        stats.filter(it => !it.hidden).map((item) => {
            const moreDetailsFirstCol = getProp(item, "more", []).filter(it => !it.hidden);
            const moreDetailsSecondCol = getProp(item, "more-col-2", []).filter(it => !it.hidden);
            let diff = percentDiff(item);

            return (
                <div
                    key={item.id}
                    className={"relative border border-tm-gray-300 bg-inverse py-4 pl-4 pr-2 shadow rounded-lg"}
                >
                    <div className="flex">
                        <div className="flex-1 relative">
                            <div
                                className={classNames("absolute bg-transparent rounded-md px-1 py-3")}>
                                {!!item.icon && (
                                    <item.icon
                                        className={classNames(
                                            item.iconColorClass ? item.iconColorClass : "text-tm-gray-700",
                                            "h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                    />
                                )}
                            </div>
                            <p className="ml-12 2xl:ml-14 text-sm font-semibold text-tm-gray-900 truncate">{item.name}</p>

                            <dd className="ml-12 2xl:ml-14 flex flex-wrap items-baseline">
                                <div className="text-lg font-medium text-primary mr-2 leading-7">
                                    {
                                        isLoading
                                            ? <div className="h-7"><LoaderSmall disableContainer="true"/></div>
                                            : item.valueRaw ? item.valueRaw : (item.valuePrefix ?? "") + numberWithCommas(item.value, item.decimalCount) + (item.valueSuffix ?? "")
                                    }

                                   <span
                                        className="text-sm whitespace-nowrap"
                                    >
                                        {item?.valueExtra}
                                    </span>
                                </div>

                                {!!diff?.changeType && (
                                    <div
                                        className={classNames(
                                            diff.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                            'flex items-baseline text-sm font-semibold'
                                        )}
                                    >
                                        {diff.changeType === 'increase' ? (
                                            <ArrowSmallUpIcon
                                                className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                                aria-hidden="true"/>
                                        ) : (
                                            <ArrowSmallDownIcon
                                                className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                                aria-hidden="true"/>
                                        )}
                                        <span
                                            className="sr-only">{diff.changeType === 'increase' ? 'Increased' : 'Decreased'} by
                                </span>

                                        {!!diff.value && diff.value + "%"}
                                    </div>
                                )}
                            </dd>
                        </div>

                        {(moreDetailsFirstCol.length > 0 || moreDetailsSecondCol > 0) && (
                            <div
                                className="absolute top-3 right-3 bg-tm-gray-200-50 p-1 rounded-bl-md rounded-br-md"
                            >
                                <div className="text-sm">
                                    <PopOver
                                        btnClass="btn btn-icon m-0"
                                        BtnIcon={ChevronDownIcon}
                                        btnIconClass={"w-5 h-5"}
                                        widthClass={item.widthClass ?? "max-w-xs"}
                                        positionClass= {item?.positionClass ?? ((item.id === 1 || item.id === 2) ? "absolute lg:translate-x-full xl:translate-x-full right-10" : "absolute translate-x-0 right-0")}

                                    >
                                        <div className="flex overflow-hidden shadow bg-popup border border-tm-gray-300 rounded-card divide-x divide-tm-gray-200">
                                            {moreDetailsFirstCol.length > 0 && (
                                                <ul
                                                    className={item.className ?? "divide-y divide-tm-gray-200 flex-1"}
                                                >
                                                    {getProp(item, "more", []).filter(it => !it.hidden).map((subItem) => (
                                                        <li 
                                                            key={subItem.id} 
                                                            className={"m-0"}
                                                        >
                                                            <span
                                                                className={subItem.className ?? "block pl-4 pr-8 py-3 bg-popup hover:bg-tm-gray-200"}
                                                            >
                                                                <span
                                                                    className="flex items-center space-x-4"
                                                                >
                                                                    <span
                                                                        className="flex-1 flex space-x-2 truncate"
                                                                    >
                                                                        {!!subItem.icon && (
                                                                            <subItem.icon
                                                                                className="absolute flex-shrink-0 h-5 w-5 text-tm-gray-700"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}

                                                                        <span
                                                                            className={subItem.labelClass ?? "pl-5 flex flex-col text-tm-gray-900 text-sm truncate"}
                                                                        >
                                                                            <span
                                                                                className="truncate">{subItem.name}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    className={subItem.valueClass ?? "pl-7 text-primary font-medium"}
                                                                >
                                                                    {subItem.amount}
                                                                </span>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}

                                            {moreDetailsSecondCol.length > 0 && (
                                                <ul
                                                    className={item.className ?? "divide-y divide-tm-gray-200 flex-1"}
                                                >
                                                    {getProp(item, "more-col-2", []).filter(it => !it.hidden).map((subItem) => (
                                                        <li 
                                                            key={subItem.id} 
                                                            className={"m-0"}
                                                        >
                                                            <span
                                                                className={subItem.className ?? "block pl-4 pr-8 py-3 bg-popup hover:bg-tm-gray-200"}
                                                            >
                                                                <span
                                                                    className="flex items-center space-x-4"
                                                                >
                                                                    <span
                                                                        className="flex-1 flex space-x-2 truncate"
                                                                    >
                                                                        {!!subItem.icon && (
                                                                            <subItem.icon
                                                                                className="absolute flex-shrink-0 h-5 w-5 text-tm-gray-700"
                                                                                aria-hidden="true"
                                                                            />
                                                                        )}

                                                                        <span
                                                                            className={subItem.labelClass ?? "pl-5 flex flex-col text-tm-gray-900 text-sm truncate"}
                                                                        >
                                                                            <span
                                                                                className="truncate">{subItem.name}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    className={subItem.valueClass ?? "pl-7 text-primary font-medium"}
                                                                >
                                                                    {subItem.amount}
                                                                </span>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </PopOver>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        })
    )
}

export default WidgetStats;
