import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {classNames} from "../../util/util-helpers";

export default function SlideOver({title, open, setOpen, widthClass, children, left}) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className={classNames("z-50 fixed inset-0 overflow-hidden")} onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0"/>

                    <div className={classNames(left ? "left-0 pr-10 sm:pr-16" : "right-0 pl-10 sm:pl-16" ,"fixed inset-y-0 max-w-full flex")}>
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom={classNames(left ? "-translate-x-full" : "translate-x-full")}
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-200 sm:duration-400"
                            leaveFrom="translate-x-0"
                            leaveTo={classNames(left ? "-translate-x-full" : "translate-x-full")}
                        >
                            <div className={classNames(widthClass ?? "max-w-md", "w-screen")}>
                                <div
                                    className="h-full flex flex-col bg-inverse shadow-xl overflow-y-scroll"
                                >
                                    <div className="px-6 py-4 border-b border-tm-gray-300 sticky top-0 z-50 bg-inverse">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title
                                                className="text-lg font-medium leading-8 text-tm-gray-900">{title}</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-close"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
