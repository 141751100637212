import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, getResource, resetResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import InfoTab from './infoTab'
import {deleteDocument, uploadDocument} from '../../../data/actions/download'
import Tippy from '@tippyjs/react'
import {CREATE_PERM, DELETE_PERM, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, classNames, getDocumentTypesFor, getLookup, getProp} from '../../../common/util/util-helpers'
import {scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import {fillFieldsFromData} from '../../../common/util/util-fields';
import {showGlobalModal} from "../../../data/actions/ui";
import {getCountTaskResource} from "../../../data/actions/taskResource";
import AccidentNotesTab from "./accidents-notes-tab";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import NavResponsive from "../../../common/components/nav-responsive";
import ModalDefault from "../../../common/components/modal/modal-default";
import Card from "../../../common/components/card";

class AccidentsCreateView extends Component {

    constructor(props) {
        super(props)
        const tabs = [
            {
                name: 'AccidentsInfo',
                resource: Resources.AccidentsInfo,
                current: true,
                visible: true
            },
            {
                name: 'AccidentsNotes',
                resource: Resources.AccidentsNotes,
                current: false,
                visible: this.getID()
            },
            {
                name: 'AccidentsDocuments',
                resource: Resources.AccidentsDocuments,
                current: false,
                visible: true
            },
        ].filter((it) => checkPerm(it.resource, READ_PERM))

        this.state = {
            tabs: tabs,
            selectedTab: Resources.AccidentsInfo,
            fields_documents: [],
            fields_info: this.getFieldsInfo(),
            another_vehicle_fields: this.getAnotherVehicleFields(),
            isRedirectDialogVisible: false,
            canSubmit: false,
        }
    }

    componentDidMount() {
        if (this.getID()) {
            this.fetchData()
            this.fetchTaskResourceCount()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.toggleRedirectDialog()
        }
        if (this.props.resource !== prevProps.resource) {
            this.setState({fields_info: this.getFieldsInfo(), another_vehicle_fields: this.getAnotherVehicleFields()})
        }
        if (prevProps.resource !== this.props.resource && this.state.selectedTab === Resources.AccidentsInfo) {
            this.setState({
                FileNumber: getProp(this.props.resource, 'data.FileNumber', ''),
                ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
            })
        }
    }

    toggleRedirectDialog = () => {
        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.id,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,
            fields_info: this.getFieldsInfo(),
            another_vehicle_fields: this.getAnotherVehicleFields(),
            canSubmit: false
        })
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccidentsInfo,
            query: {id: this.getID()}
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }


    getAnotherVehicleFields = () => {
        let fields = []
        if (!this.getID()) {
            fields = []
        }
        if (this.getID()) {
            let data = getProp(this.props.resource, 'data', {})
            if (data.AccidentOtherVehicle && data.AccidentOtherVehicle.length > 0) {
                return data.AccidentOtherVehicle.map((item, index) => this.anotherVehicleBasicFields(item, index))
            } else {
                fields = []
            }
        }
        return fields
    }

    anotherVehicleBasicFields = (item = null, index = 0) => {
        const fieldTemplates = {
            OtherDriverName: new Field('OtherDriverName', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            OtherDriverCountryID: new Field('OtherDriverCountryID', 1, [], false, 'select', {addContainerClass: 'col-span-6'}),
            OtherDriverAddress: new Field('OtherDriverAddress', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            OtherDriverCity: new Field('OtherDriverCity', '', [], false, 'text', {addContainerClass: 'col-span-4'}),
            OtherDriverStateID: new Field('OtherDriverStateID', '', [], false, 'select', {addContainerClass: 'col-span-4'}),
            OtherDriverZip: new Field('OtherDriverZip', '', [], false, 'text', {addContainerClass: 'col-span-4'}),

            OtherDriverPhone: new Field('OtherDriverPhone', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            OtherDriverLicense: new Field('OtherDriverLicense', '', [], false, 'text', {addContainerClass: 'col-span-full'}),

            OtherDriverCommercialVehicle: new Field('OtherDriverCommercialVehicle', '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            OtherDriverCompanyAddress: new Field('OtherDriverCompanyAddress', '', [], true, 'text', {addContainerClass: 'col-span-full'}),
            OtherDriverCompanyName: new Field('OtherDriverCompanyName', '', [], true, 'text', {addContainerClass: 'col-span-6'}),
            OtherDriverCompanyPhone: new Field('OtherDriverCompanyPhone', '', [], true, 'text', {addContainerClass: 'col-span-6'}),

            InsuranceName: new Field('InsuranceName', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            InsuranceContactID: new Field('InsuranceContactID', '', [], false, 'select-search', {addContainerClass: 'col-span-full'}),
            InsurancePhone: new Field('InsurancePhone', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            InsuranceClaimNumber: new Field('InsuranceClaimNumber', '', [], false, 'text', {addContainerClass: 'col-span-full'}),

            OtherPropertyDamage: new Field('OtherPropertyDamage', '', ['float'], false, 'text', {
                addContainerClass: 'col-span-full',
                label: 'EstOtherPropertyDamage'
            }),
            OtherInjuries: new Field('OtherInjuries', '', [], false, 'select', {addContainerClass: 'col-span-6'}),
            OtherFatalities: new Field('OtherFatalities', '', [], false, 'select', {addContainerClass: 'col-span-6'}),
            OtherAmbulanceUsed: new Field('OtherAmbulanceUsed', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            OtherDriverHospitalized: new Field('OtherDriverHospitalized', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            OtherVehicleDisabled: new Field('OtherVehicleDisabled', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),

            OtherAttorneyName: new Field('OtherAttorneyName', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            OtherAttorneyContactID: new Field('OtherAttorneyContactID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}),
            OtherAttorneyAddress: new Field('OtherAttorneyAddress', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            OtherAttorneyCountryID: new Field('OtherAttorneyCountryID', 1, [], false, 'select', {addContainerClass: 'col-span-4'}),
            OtherAttorneyCity: new Field('OtherAttorneyCity', '', [], false, 'text', {addContainerClass: 'col-start-1 col-span-4'}),
            OtherAttorneyStateID: new Field('OtherAttorneyStateID', '', [], false, 'select', {addContainerClass: 'col-span-4'}),
            OtherAttorneyZip: new Field('OtherAttorneyZip', '', [], false, 'text', {addContainerClass: 'col-span-4'}),
            OtherAttorneyPhone: new Field('OtherAttorneyPhone', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            OtherAttorneyFax: new Field('OtherAttorneyFax', '', [], false, 'text', {addContainerClass: 'col-span-6'}),

            IsCitations: new Field('IsCitations', '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}, {id: 'vehicleCitations_' + index}),
            CitationsDescription: new Field('CitationsDescription', '', [], false, 'hidden', {addContainerClass: 'col-span-full'})
        }

        if (item?.OtherDriverCommercialVehicle) {
            fieldTemplates.OtherDriverCompanyAddress.disabled = false
            fieldTemplates.OtherDriverCompanyName.disabled = false
            fieldTemplates.OtherDriverCompanyPhone.disabled = false
        }

        if (item?.CitationsDescription) {
            fieldTemplates.CitationsDescription.type = 'textarea'
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFieldsInfo = () => {
        const q = new URLSearchParams(this.props.location.search).get('q')
        let fields = {
            FileNumber: new Field('FileNumber', q ?? '', ['empty'], false, 'text', {addContainerClass: 'col-span-4'}),
            FileOpened: new Field('FileOpened', '', [], false, 'date', {addContainerClass: 'col-span-4'}),
            FileClosed: new Field('FileClosed', '', [], false, 'date', {addContainerClass: 'col-span-4'}),
            AccidentCodeID: new Field('AccidentCodeID', '', [], false, 'select', {addContainerClass: 'col-span-full'}),
            AccidentDate: new Field('AccidentDate', '', [], false, 'datetimezone', {addContainerClass: 'col-span-6'}),
            AccidentDateTime: new Field('AccidentDateTime', '', [], false, 'timezone-custom', {addContainerClass: 'col-span-6'}),

            DriverID: new Field('DriverID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}),
            CoDriverID: new Field('CoDriverID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}),
            TruckID: new Field('TruckID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}),

            PoliceReport: new Field('PoliceReport', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            Recordable: new Field('Recordable', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            Reportable: new Field('Reportable', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            Preventable: new Field('Preventable', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            HazMat: new Field('HazMat', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            Photos: new Field('Photos', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),

            ChargeDriver: new Field('ChargeDriver', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            Amount: new Field('Amount', '', [], false, 'hidden', {addContainerClass: 'col-span-6'}),

            AccidentCountryID: new Field('AccidentCountryID', 1, [], false, 'select', {addContainerClass: 'col-span-4'}),
            AccidentCity: new Field('AccidentCity', '', [], false, 'text', {addContainerClass: 'col-span-4 col-start-1'}),
            AccidentStateID: new Field('AccidentStateID', '', [], false, 'select', {addContainerClass: 'col-span-4'}),
            AccidentCounty: new Field('AccidentCounty', '', [], false, 'text', {addContainerClass: 'col-span-4'}),
            AccidentDescribeLocation: new Field('AccidentDescribeLocation', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),

            AccidentTruckDamage: new Field('AccidentTruckDamage', '', ['float_or_empty'], false, 'float_or_empty', {
                addContainerClass: 'col-span-4',
                label: 'EstAccidentTruckDamage'
            }),
            AccidentTrailerDamage: new Field('AccidentTrailerDamage', '', ['float_or_empty'], false, 'float_or_empty', {
                addContainerClass: 'col-span-4',
                label: 'EstAccidentTrailerDamage'
            }),
            AccidentCargoDamage: new Field('AccidentCargoDamage', '', ['float_or_empty'], false, 'float_or_empty', {
                addContainerClass: 'col-span-4',
                label: 'EstAccidentCargoDamage'
            }),
            AccidentInjuries: new Field('AccidentInjuries', '0', [], false, 'select', {addContainerClass: 'col-span-6'}),
            AccidentFatalities: new Field('AccidentFatalities', '0', [], false, 'select', {addContainerClass: 'col-span-6'}),
            AccidentAmbulanceUsed: new Field('AccidentAmbulanceUsed', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            AccidentDriverHospitalized: new Field('AccidentDriverHospitalized', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),
            AccidentVehicleDisabled: new Field('AccidentVehicleDisabled', '', [], false, 'checkbox', {addContainerClass: 'col-span-4'}),

            CompanyAttorneyName: new Field('CompanyAttorneyName', '', [], false, 'text', {addContainerClass: 'col-span-full'}),
            CompanyAttorneyContactID: new Field('CompanyAttorneyContactID', '', [], false, 'select-search', {addContainerClass: 'col-span-full'}),
            CompanyAttorneyCountryID: new Field('CompanyAttorneyCountryID', 1, [], false, 'select', {addContainerClass: 'col-span-6'}),
            CompanyAttorneyAddress: new Field('CompanyAttorneyAddress', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            CompanyAttorneyStateID: new Field('CompanyAttorneyStateID', '', [], false, 'select', {addContainerClass: 'col-span-full'}),
            CompanyAttorneyCity: new Field('CompanyAttorneyCity', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            CompanyAttorneyZip: new Field('CompanyAttorneyZip', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            CompanyAttorneyPhone: new Field('CompanyAttorneyPhone', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            CompanyAttorneyFax: new Field('CompanyAttorneyFax', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            AccidentDescription: new Field('AccidentDescription', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),

            IsCitations: new Field('IsCitations', '', [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            CitationsDescription: new Field('CitationsDescription', '', [], false, 'hidden', {addContainerClass: 'col-span-full'})
        }
        if (this.getID()) {
            let data = getProp(this.props.resource, 'data', {})
            if (data.IsCitations) {
                fields.CitationsDescription.type = 'textarea'
            }
            if (data.Amount) {
                fields.Amount.type = 'money'
            }
            return fillFieldsFromData(fields, data)
        }
        return fields
    }

    cancel = () => {
        this.setState({
            fields_info: this.getFieldsInfo(),
            another_vehicle_fields: this.getAnotherVehicleFields(),
            canSubmit: false
        })
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.getID()) {
            if (this.state.canSubmit) {
                this.setState({
                    confirmModal: true,
                    reserveTab: resource
                })
            } else {
                this.tabChange(resource)
            }
            return false
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        if (resource === Resources.AccidentsInfo && this.getID()) this.fetchData()
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    submit = () => {
        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info),
            another_vehicle_fields: this.state.another_vehicle_fields.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info)) {
                if (this.getID()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.fields_info), {
                                AccidentOtherVehicles: this.state.another_vehicle_fields.map((fields) => {
                                    return Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                                        OtherDriverStateID: fields.OtherDriverStateID.value || '',
                                        OtherAttorneyStateID: fields.OtherAttorneyStateID.value || ''
                                    })
                                }),
                                id: this.getID(),
                            }),
                        query: {
                            id: this.getID()
                        },
                        resource: Resources.AccidentsInfo,
                        piggyResource: Resources.AccidentsInfo,
                        document: this.state.fields_documents,
                        documentResource: Resources.AccidentsDocuments,
                        errorMessage: true, successMessage: this.props.translate('text.accident_edited'),
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.fields_info), {
                                AccidentOtherVehicles: this.state.another_vehicle_fields.map((fields) => FieldsManager.getFieldKeyValues(fields)),
                            }),
                        resource: Resources.Accidents,
                        showRedirectDialog: true,
                        document: this.state.fields_documents,
                        documentResource: Resources.AccidentsDocuments,
                    }))
                }
                this.setState({canSubmit: false})
            } else {
                if (this.state.selectedTab !== Resources.AccidentsInfo) this.handleTabChange(Resources.AccidentsInfo)
                scrollErrorIntoViewFields([this.state.fields_info])
            }
        })
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTasksModalOpen: !this.state.resourceTasksModalOpen
        })
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    toggleResourceTasks = () => {
        this.setState({
            isResourceTaskModalVisible: !this.state.isResourceTaskModalVisible
        })
    }

    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields_info, name, value)
        if (name === 'ClaimClosed' && !value) {
            fields = FieldsManager.updateField(this.state.fields_info, 'DateClosed', '')
        }
        if (name === 'IsCitations') {
            fields.CitationsDescription.type = value ? 'textarea' : 'hidden'
        }
        if (name === 'ChargeDriver') {
            fields.Amount.type = value ? 'money' : 'hidden'
        }
        if (name === 'CountryID') {
            fields.StateID.type = (value == 1) ? 'select' : 'hidden'
            fields.StateID.value = ''
        }
        if (name === 'CompanyAttorneyCountryID') {
            fields.CompanyAttorneyStateID.type = (value == 1) ? 'select' : 'hidden'
            fields.CompanyAttorneyStateID.value = ''
        }
        if (name === 'AccidentCountryID') {
            fields.AccidentStateID.type = (value == 1) ? 'select' : 'hidden'
            fields.AccidentStateID.value = ''
        }
        this.setState({canSubmit: true, fields_info: fields})
    }

    addAnotherVehicleHandler = () => {
        let fields = this.state.another_vehicle_fields
        fields.push(this.anotherVehicleBasicFields(undefined, this.state.another_vehicle_fields.length))
        this.setState({another_vehicle_fields: fields})
    }

    removeAnotherVehicleHandler = (index) => {
        let fields = this.state.another_vehicle_fields
        fields.splice(index, 1)
        this.setState({another_vehicle_fields: fields})
    }

    handleAnotherVehicleInputChange = (name, value, index) => {
        let fields = this.state.another_vehicle_fields
        fields[index][name].value = value
        if (name === 'OtherDriverCommercialVehicle') {
            fields[index].OtherDriverCompanyAddress.disabled = !value
            fields[index].OtherDriverCompanyName.disabled = !value
            fields[index].OtherDriverCompanyPhone.disabled = !value
        }
        if (name === 'OtherAttorneyCountryID') {
            fields[index].OtherAttorneyStateID.type = (value == 1) ? 'select' : 'hidden'
            fields[index].OtherAttorneyStateID.value = ''
        }
        if (name === 'OtherDriverCountryID') {
            fields[index].OtherDriverStateID.type = (value == 1) ? 'select' : 'hidden'
            fields[index].OtherDriverStateID.value = ''
        }

        if (name === 'IsCitations') {
            fields[index].CitationsDescription.type = value ? 'textarea' : 'hidden'
        }
        this.setState({another_vehicle_fields: fields, canSubmit: true})
    }

    handleItemInputChange = (i, name, value) => {
        let fields = this.state.phones_info[i]
        const newItems = this.state.phones_info
        newItems[i] = FieldsManager.updateField(fields, name, value)
        this.setState({canSubmit: true, phones_info: newItems})
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    uploadDocument = (files, descriptions) => {
        this.props.dispatch(uploadDocument({
            user: LocalStorage.get('user'),
            files: files,
            id: this.getID(),
            query: {
                id: this.getID(),
            },
            Descriptions: descriptions,
            errorMessage: true,
            successMessage: `Attachment${files.length > 1 ? 's' : ''} ${files.length > 1 ? 'have' : 'has'} been uploaded successfully.`,
            resource: Resources.AccidentsDocuments,
            piggySecondResource: Resources.AccidentsDocuments
        }))
    }

    deleteDocument = (document) => {
        this.props.dispatch(deleteDocument({
            user: LocalStorage.get('user'),
            query: {
                id: this.getID(),
            },
            params: {
                AccidentDocumentID: document.AccidentDocumentID,
                id: this.getID(),
            },
            errorMessage: true, successMessage: `Attachment has been deleted successfully.`,
            resource: Resources.AccidentsDocuments,
            piggySecondResource: Resources.AccidentsDocuments
        }))
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate, history} = this.props
        const AccidentCode = getLookup('AccidentCode', 'AccidentCodeID', 'AccidentCode')
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const openTaskCountTwoDigits = TaskCount > 9;
        const pageTitle = "Accident" + (this.state.FileNumber ? ` #${this.state.FileNumber}` : "")

        return (
            <Layout
                {...this.props}
                hasFooter={this.state.selectedTab === Resources.AccidentsInfo}
                isDirty={(this.getID() && this.state.canSubmit)}
                customPageTitle={pageTitle}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="mb-2 flex justify-start items-center">
                            <GoBackButton
                                translate={translate}
                                history={history}
                                path={'/accidents'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('page.heading.Accidents')}
                                <span
                                    className="text-tm-gray-500"> - {this.getID() ? this.state.FileNumber : 'Create new'}
                                    {this.state.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                        </span>
                            </h1>
                        </div>
                        <div className={'flex justify-end'}>

                            {checkPerm(Resources.Tasks, READ_PERM) && !!this.getID() && (
                                <>
                                    <button className="btn btn-outline mr-3"
                                            disabled={!checkPerm(Resources.Tasks, CREATE_PERM)}
                                            onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_accidents_task')}
                                    </button>

                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -left-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </>
                            )}
                        </div>

                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>


                    {this.state.selectedTab === Resources.AccidentsInfo && (
                        <InfoTab
                            removeAnotherVehicleHandler={this.removeAnotherVehicleHandler}
                            addAnotherVehicleHandler={this.addAnotherVehicleHandler}
                            handleItemInputChange={this.handleItemInputChange}
                            handleAnotherVehicleInputChange={this.handleAnotherVehicleInputChange}
                            handleInputChange={this.handleInputChange}
                            another_vehicle_fields={this.state.another_vehicle_fields}
                            fields_info={this.state.fields_info}
                            canSubmit={this.state.canSubmit}
                            AccidentCode={AccidentCode}
                            submit={this.submit}
                            cancel={this.cancel}
                            {...this.props}/>
                    )}

                    {this.state.selectedTab === Resources.AccidentsNotes && (
                        <AccidentNotesTab
                            translate={translate}
                            id={this.getID()}
                            tabResource={Resources.AccidentsNotes}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccidentsDocuments && !!this.getID() && (
                        <DocumentsUpdateTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            thirdResource={this.props.thirdResource}

                            dialogTitle={'Accident Documents'}

                            sortBy={'Description'}
                            primaryKey={'AccidentDocumentID'}

                            download={this.props.download}
                            resourceName={Resources.AccidentsDocuments}

                            documentUpdateFields={checkPerm(Resources.AccidentsDocuments, UPDATE_PERM) && {
                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                    values: getDocumentTypesFor("IsSafetyDocument")
                                }),
                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}

                            documentTypes={getDocumentTypesFor("IsSafetyDocument")}
                            searchFields={{
                                query: new Field('query', '', [], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true,
                                    values: getDocumentTypesFor("IsSafetyDocument")
                                }),
                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true
                                }),
                            }}
                            readOnly={!checkPerm(Resources.AccidentsDocuments, CREATE_PERM)}
                            disableDelete={!checkPerm(Resources.AccidentsDocuments, DELETE_PERM)}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccidentsDocuments && !this.getID() && (
                        <Card>
                            <DocumentsCreateTab
                                fields={this.state.fields_documents}
                                translate={this.props.translate}

                                dialogTitle={'Accident Documents'}
                                documentTypes={getDocumentTypesFor("IsSafetyDocument")}
                                documentUploadFields={() => {
                                    return Object.assign({}, {
                                        DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {}, {
                                            values: getDocumentTypesFor("IsSafetyDocument")
                                        }),
                                        ExpiryDate: new Field("ExpiryDate", '', [], false, 'date'),
                                        Description: new Field("Description", '', [], false, 'textarea'),
                                    })
                                }}

                                handleUploadDocument={this.handleUploadDocument}
                                handleDeleteDocument={this.handleDeleteDocument}
                            />
                        </Card>
                    )}

                    {!this.getID() && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.cancel}
                            actionSubmit={checkPerm(Resources.Claims, UPDATE_PERM) && this.submit}
                        />
                    )}
                </Page>

                <ModalConfirm
                    type="info"
                    title={'Confirm redirection'}
                    show={!!this.state.confirmModal && this.state.selectedTab === Resources.AccidentsInfo}
                    text={this.props.translate('text.all_fields_will_be_lost')}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        canSubmit: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                />

                <RedirectDialog
                    show={this.state.isRedirectDialogVisible}
                    title={translate('card_header.accident_created')}
                    onClose={() => {
                        this.props.dispatch(resetResource())
                        this.setState({
                            createdResourceID: null,
                            isRedirectDialogVisible: false
                        })
                    }}

                    createdLabel={translate('btn.accident')}
                    createdLink={`/accidents/info/${this.state.createdResourceID}`}

                    newLabel={translate('btn.create_new')}

                    listLabel={translate('btn.list')}
                    listLink="/accidents"

                    dashboardLabel={translate('btn.dashboard')}

                    {...this.props}
                >
                    <p>{translate('message.accident_created')}</p>
                </RedirectDialog>

                <ModalDefault
                    show={!!this.state.isResourceTaskModalVisible}
                    title={translate('modal_heading.accidents_tasks')}
                    widthClass={'max-w-3xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={this.toggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.toggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>

                <ModalDefault
                    show={!!this.state.resourceTasksModalOpen}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.accidents_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(AccidentsCreateView)

