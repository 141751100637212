import React from "react";
import {classNames} from "../../../util/util-helpers";
import useTheme from "../../../hooks/use-theme";
import useThemeColor from "../../../hooks/use-theme-color";
import ModalDefault from "../../modal/modal-default";
import { CheckIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import LocalStorage from "../../../../util/localStorage";
import ButtonsGroup from "../../buttons/buttons-group";

export default function ThemePicker({translate, isThemesDialogOpen, setIsThemeDialogOpen}) {
    const [theme, setTheme] = useTheme();
    const [themeColor, setThemeColor] = useThemeColor();

    function handleThemeColorClick(appThemeColor) {
        if (themeColor) {
            document.documentElement.classList.remove(themeColor + "-theme");
        }
        document.documentElement.classList.add(appThemeColor + "-theme");
        LocalStorage.set("APP_THEME_COLOR", appThemeColor);

        setThemeColor(appThemeColor)
    }

    return (
        <>
            <button
                onClick={() => {
                    setTheme(theme === "dark" ? "light" : "dark");
                }}
                type="button"
                className="icon-btn"
            >
                <span className="sr-only">Change theme</span>

                {theme !== 'dark' && (
                    <MoonIcon className="w-6 h-6" aria-hidden="true"/>
                )}

                {theme === 'dark' && (
                    <SunIcon className="w-6 h-6" aria-hidden="true"/>
                )}
            </button>

            <ModalDefault
                show={isThemesDialogOpen}
                widthClass={"max-w-xl"}
                title={translate("text.chose_a_theme")}

                closeButtonLabel={translate("btn.close")}
                onClose={() => setIsThemeDialogOpen(false)}
            >
                <div className="bg-tm-gray-100 space-y-8 py-12">
                    <div className="flex justify-center px-6">
                        <ButtonsGroup
                            data={{
                                light: <div className="flex"><SunIcon className="w-5 h-5 mr-1"/> {translate('btn.light')}</div>,
                                dark: <div className="flex"><MoonIcon className="w-5 h-5 mr-1"/> {translate('btn.dark')}</div>
                            }}
                            value={theme !== 'dark' ? 'light' : 'dark'}
                            onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                        />
                    </div>


                    <div className="bg-inverse rounded-card pt-8 pb-3 mx-12 border border-tm-gray-300">
                        <div className="w-48 p-4 mx-auto relative">
                            <div className="absolute inset-0 bg-primary rounded-card opacity-25"/>
                            <div
                                className="relative z-10 w-40 h-32 rounded-card bg-tm-gray-100 flex flex-column overflow-hidden">
                                <div
                                    className="w-40 h-4 bg-inverse border-b border-tm-gray-200 flex items-center justify-center">
                                    <div className="w-1 h-1 rounded-full bg-tm-gray-900 opacity-50 mr-0.5"/>
                                    <div className="w-4 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                </div>

                                <div className="flex items-center">
                                    <div className="h-28 w-6 bg-popup border-r border-tm-gray-200 py-2 space-y-1">
                                        <div className="flex">
                                            <div className="w-1 h-1 rounded-full bg-tm-gray-600 opacity-50 mr-0.5"/>
                                            <div className="w-3 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                        </div>
                                        <div className="flex bg-tm-gray-200">
                                            <div className="w-1 h-1 rounded-full bg-tm-gray-600 opacity-50 mr-0.5"/>
                                            <div className="w-3 h-1 rounded-btn bg-tm-gray-200"/>
                                        </div>

                                        <div className="flex">
                                            <div className="w-1 h-1 rounded-full bg-tm-gray-600 opacity-50 mr-0.5"/>
                                            <div className="w-3 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                        </div>

                                        <div className="flex">
                                            <div className="w-1 h-1 rounded-full bg-tm-gray-600 opacity-50 mr-0.5"/>
                                            <div className="w-3 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                        </div>
                                    </div>

                                    <div className="w-24 mx-auto">
                                        <div className="flex items-center justify-content-between mb-1">
                                            <div className="w-8 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                        </div>

                                        <div
                                            className="w-24 h-18 bg-popup border shadow border-tm-gray-300 rounded-card pt-4 px-2 py-1 space-y-2">
                                            <div className="space-y-1">
                                                <div className="w-8 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                                <div className="w-16 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                                <div className="w-14 h-1 rounded-btn bg-tm-gray-900 opacity-50"/>
                                            </div>

                                            <div className="grid grid-cols-2 gap-2">
                                                <div className="w-full border rounded-input border-tm-gray-300 h-2.5 bg-field"/>
                                                <div className="w-full border rounded-input border-tm-gray-300 h-2.5 bg-field"/>
                                                <div
                                                    className="col-span-full border rounded-input border-tm-gray-300 h-2.5 bg-field"/>
                                            </div>

                                            <div className="flex justify-end space-x-1">
                                                <div className="w-4 h-1.5 bg-primary rounded-btn"/>
                                                <div className="w-4 h-1.5 border border-primary rounded-btn"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-lg text-center mt-4">{themeList[themeColor].name}</div>


                        <div className={
                            classNames(
                                "border-t border-tm-gray-300 pt-3 mt-4 px-12 flex"
                            )}
                        >
                            <span className="text-base mr-8 text-tm-gray-900 pt-2.5">Color</span>
                            <div>
                                <div className="flex flex-wrap justify-start">
                                    {Object.values(themeList).map(it => {
                                        return (
                                            <button
                                                key={it.key}
                                                className={
                                                    classNames("w-8 h-8 mx-3 my-2 flex items-center justify-center rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-blue-600")
                                                }
                                                onClick={() => handleThemeColorClick(it.key)}
                                                style={{background: it.color}}
                                            >
                                                {themeColor === it.key && (
                                                    <CheckIcon className="w-5 h-5 text-white"/>
                                                )}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ModalDefault>
        </>
    )
}

const themeList = {
    "blue": {
        name: "Blue",
        key: 'blue',
        color: "#0085FF"
    },
    "adwaita": {
        name: "Blue alt",
        key: 'adwaita',
        color: '#3584e4'
    },
    'green': {
        name: "Green",
        key: 'green',
        color: '#15803D'
    },
    'green-600': {
        name: "Green 600",
        key: 'green-600',
        color: '#16a34a'
    },
    'pink': {
        name: "Pink",
        key: 'pink',
        color: '#db2777'
    },
    'purple': {
        name: "Purple",
        key: 'purple',
        color: '#9333ea'
    },
    'violet': {
        name: "Violet",
        key: 'violet',
        color: "#7c3aed"
    },
    'indigo': {
        name: "Indigo",
        key: 'indigo',
        color: "#4338ca"
    },
    'amber': {
        name: "Amber",
        key: 'amber',
        color: "#b45309"
    },
    'orange': {
        name: "Orange",
        key: 'orange',
        color: "#ea580c"
    }
};
