import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Resources from '../../../data/services/resources'
import {checkPerm} from '../../../common/util/util-helpers'
import {READ_PERM} from '../../../util/util-constants'
import JournalTab from './journal-tab'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";

const JournalView = ({translate, match, history, location}) => {
    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const resource = useSelector((state) => state.resource)
    const user = useSelector((state) => state.user)

    /** Fields Data Definitions
     ================================================================= */
    const getTabs = () => {
        return [
            {
                name: 'Journal',
                resource: Resources.Journal,
                current: true,
                visible: checkPerm(Resources.Journal, READ_PERM)
            }
        ]
    }

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs())
    const [selectedTab, setSelectedTab] = useState(tabs[0].resource)
    const [breakpoint, setBreakpoint] = useState({})

    /** UI events
     ================================================================= */
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource
                return it
            })
        )
        setSelectedTab(resource)
    }

    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }

    /** Render
     ================================================================= */
    return (
        <Layout
            onBreakpoinChange={handleBreakpointChange}
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={!(resource.errorStatus === 2)}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.AccountingJournal')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {selectedTab === Resources.Journal && (
                    <JournalTab
                        translate={translate}
                        breakpoint={breakpoint}
                    />
                )}
            </Page>
        </Layout>
    )
}

export default JournalView
