import React, {Component} from 'react'
import LoadBilling from './load-sections/load-billing'
import {Field, FieldsManager as Fields, FieldsManager} from '../../../data/services/fields'
import {
    checkPerm,
    classNames,
    ConstantTranslate,
    getProp,
    resourceIsCreated,
    returnMileage,
    tooltipTextareaContent,
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import LocalStorage from '../../../util/localStorage'
import {getResource, updateMultipleResource, updateResource} from '../../../data/actions/resource'
import ScaleIcon from '@heroicons/react/24/outline/ScaleIcon'
import {
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
    CubeIcon,
    CurrencyDollarIcon,
    MapPinIcon,
    TruckIcon
} from '@heroicons/react/24/outline'
import CubeTransparentIcon from '@heroicons/react/24/outline/CubeTransparentIcon'
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    feeTypes,
    LOAD_PAY_METHOD_ACC_PERCENT,
    LOAD_PAY_METHOD_FLAT,
    LOAD_PAY_METHOD_NET_PROFIT_PERCENT,
    LOAD_PAY_METHOD_PRICE_ACC_PERCENT,
    LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES,
    LOAD_PAY_METHOD_PRICE_LOADED_MILES,
    LOAD_PAY_METHOD_PRICE_PERCENT,
    LOAD_PRICING_FLAT,
    LOAD_PRICING_PER_MILE,
    LOAD_PRICING_PER_PIECE,
    LOAD_PRICING_PER_VOLUME,
    LOAD_PRICING_PER_WEIGHT,
    LOAD_STATUS_DELIVERED,
    READ_PERM,
    UPDATE_PERM,
    WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY
} from '../../../util/util-constants'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {numberWithCommas, numberWithCommasToBack} from '../../../util/util-formaters'
import LoadPricingAccessorials from './load-sections/load-pricing-accessorials'
import LoadPricingCommission from './load-sections/load-pricing-commission'
import LoadPricingDriverAccessorials from './load-sections/load-pricing-driver-accessorials'
import LoadPricingDriversFee from './load-sections/load-pricing-drivers-fee'
import LoadPricingCarriersFee from './load-sections/load-pricing-carriers-fee'
import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon'
import {pushNotification, showModal} from '../../../data/actions/ui'
import ApplyCustomerSettingsForm from './load-sections/apply-customer-settings-form'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import Tippy from '@tippyjs/react'
import {createSecondResource, getSecondResource} from '../../../data/actions/secondResource'
import ReceiptPercentIcon from '@heroicons/react/24/outline/ReceiptPercentIcon'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import {ExclamationCircleIcon, InformationCircleIcon, MinusCircleIcon, PlusCircleIcon} from '@heroicons/react/20/solid'
import LoadPricingAdvanceReimbursement from './load-sections/load-pricing-advance-reimbursement'
import moment from 'moment-timezone'
import Env from '../../../util/env'
import FieldText from '../../../common/components/fields/field-text'
import LoadPricingCustomerInfo from './load-sections/load-pricing-customer.js load-pricing-customer-info'
import ModalHeader from "../../../common/components/modal/modal-header";
import Modal from "../../../common/components/modal";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import Card from "../../../common/components/card";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResourceWithDropZone from "../../../common/components/modal/modal-save-resource-with-drop-zone";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import InfoParagraph from "../../../common/components/info-paragraph";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import LoadPricingMoneyCodes from "./load-sections/load-pricing-money-codes";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import {LoaderSmall} from "../../../common/components/loader";
import FileList from "../../../common/components/dropzone/file-list";
import ResourceTable from "../../../common/components/resource-table";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";

function UserBadge({Contact, ContactID, IsRateConfirmed, showContactDetails, translate}) {
    if (!ContactID || !IsRateConfirmed) return null

    return (
        <span className="relative ml-1">
              <Tippy content={translate('text.rate_confirmed_by', [Contact])}>
                    <button
                        className="field-btn absolute -top-1.5 whitespace-nowrap"
                        onClick={() => showContactDetails(ContactID)}
                    >
                        <CheckCircleIcon className="w-4 h-4 text-green-600 inline-flex mr-2"/>

                        {Contact}
                    </button>
              </Tippy>
        </span>
    )
}

class LoadPricingTab extends Component {

    constructor(props) {
        super(props)
        this.accessorialsDialogFocusRef = React.createRef();

        this.state = {

            offset: 0,
            limit: 10,
            sort: "ASC",
            sortBy: "",
            paginationPage: 1,
            archived: false,

            billingFields: this.getBillingFields(),
            accessoryFields: this.getAccessoriesList(),
            commissionFields: this.getCommissionList(),
            driversFeeFields: this.getDriversFeeList(),
            carriersFeeFields: this.getCarriersFeeList(),
            carriersFeeAdditionalFields: this.getCarriersFeeAdditionalFields(),
            extraChargesFields: this.getExtraChargeList(),
            driverAccessorialFields: this.getDriversAccessorialsList(),
            advancesReimbursementFields: this.getAdvancesReimbursementsFields(),
            moneyCodeFields: this.getMoneyCodeFields(),
            advancesReimbursementQueryFields: this.getAdvancesReimbursementsQueryFilterFields(),

            totalMiles: 0,
            TotalMilesNoStopBy: 0,
            emptyMiles: 0,
            editMode: false,
            isBroker: undefined,

            AccessorialDriverPayAmount: 0,
            CustomerID: getProp(this.props.resource, 'data.load.CustomerID', 0),
            LoadStatusID: false,
            selectedItem: null,
            selectedItemIndex: undefined,
            showDialog: false,
            isAccessorialDialogOpen: false,
            isCommissionDialogOpen: false,
            isCarriersFeeModalOpen: false,
            isDriversFeeModalOpen: false,
            isDriverAccessorialDialogOpen: false,
            isSetPriceMatrixModalOpen: false,
            isDefaultAccesorialModalOpen: false,
            isCustomerSettingsModalOpen: false,
            isAdvancesReimbursementsCreateModalOpen: false,
            isAdvancesReimbursementsViewModalOpen: false,
            isMoneyCodesCreateModalOpen: false,
            isMoneyCodesViewModalOpen: false,
            isReceivedCodeModalOpen: false,
            displayValue: 0,
            PriceBase: undefined,
            PriceTypeID: undefined,
            PriceType: undefined,
            matrixApplied: undefined,
            LoadPricingMatrixID: null,
            CommodityPricingMatrixID: null,
            milesForCalculation: 0,
            areCustomerDetailsExpanded: false,

            // Files
            files: [],
            existingFiles: [],
            previewShow: false,
            filePreview: {
                show: false,
                fileType: "",
                filePath: ""
            },
        }

        this.fieldsRef = {}
        this.feeTypes = ConstantTranslate(feeTypes, this.props.translate);

        this.oldPriceBase = 0;
        this.oldPriceTypeID = LOAD_PRICING_FLAT;
    }

    componentDidMount() {
        this.fetchData();
        this.fetchAdvancesReimbursementsData();
        if (!!this.props?.fetchInfoData && !this.props?.info?.isLoading && this.props?.info?.data?.LoadID !== Number(this.props?.id)) {
            this.props.fetchInfoData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource !== this.props.resource && !this.props.resource.isLoading) {
            const data = cloneDeep(getProp(this.props.resource, 'data', {}));

            const matrixApplied = getProp(this.props.resource, 'data.load.LoadPricingMatrix', null) || getProp(this.props.resource, 'data.load.CommodityPricingMatrix', null)

            this.setState({
                isBroker: getProp(data.load, 'IsBroker', 0),
                billingFields: this.getBillingFields(data.load, !!matrixApplied),
                accessoryFields: this.getAccessoriesList(),
                commissionFields: this.getCommissionList(),
                driversFeeFields: this.getDriversFeeList(),
                carriersFeeFields: this.getCarriersFeeList(),
                carriersFeeAdditionalFields: this.getCarriersFeeAdditionalFields(data?.load),
                extraChargesFields: this.getExtraChargeList(),
                driverAccessorialFields: this.getDriversAccessorialsList(),
                AccessorialDriverPayAmount: getProp(data.load, 'AccessorialDriverPayAmount', 0),
                CustomerID: getProp(this.props.resource, 'data.load.CustomerID', 0),
                LoadStatusID: data?.load?.LoadStatusID,
                totalMiles: getProp(data.load, 'TotalMiles', 0),
                TotalMilesNoStopBy: getProp(data.info, 'TotalMilesNoStopBy', 0),
                emptyMiles: getProp(data.load, 'EmptyMiles', 0),
                accessorialData: getProp(this.props.resource, 'data.customer.Accessorial.list', []),
                commissionData: getProp(this.props.resource, 'data.customer.Commision.list', []),
                matrixApplied: matrixApplied,
                LoadPricingMatrixID: getProp(this.props.resource, 'data.load.LoadPricingMatrixID', null),
                CommodityPricingMatrixID: getProp(this.props.resource, 'data.load.CommodityPricingMatrixID', null)
            }, this.updateCalculations)
        }

        if (prevProps.secondResource !== this.props.secondResource && this.props.secondResource !== null) {
            const PriceTypeLookup = {
                [LOAD_PRICING_FLAT]: 'Flat',
                [LOAD_PRICING_PER_MILE]: 'Per mile',
                [LOAD_PRICING_PER_WEIGHT]: 'Per weight',
                [LOAD_PRICING_PER_VOLUME]: 'Per volume',
                [LOAD_PRICING_PER_PIECE]: 'Per piece'
            }
            const PriceTypeID = getProp(this.props.secondResource.data, 'PriceTypeID', null)

            if (!!PriceTypeID && !!PriceTypeLookup) {
                const PriceType = PriceTypeLookup[PriceTypeID]

                this.setState({
                    PriceBase: this.props.secondResource.data.PriceBase,
                    PriceTypeID: this.props.secondResource.data.PriceTypeID,
                    PriceType: PriceType
                })
            }

            const Accessorial = getProp(this.props.secondResource.data, 'Accessorial', null)

            if (Accessorial) {
                this.setState({
                    FuelSurcharge: getProp(this.props.secondResource, 'data', null)
                })
            }
        }

        if (
            resourceIsCreated(this.props.secondResource, prevProps.secondResource)
            && this.props.secondResource.resource === Resources.LoadReimbursement
        ) {
            this.toggleAdvancesReimbursementsCreateModal()
        }
    }

    /** Data events
     =========================================== */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadPricing,
            query: {
                LoadID: this.getID()
            }
        }))
    }

    fetchAdvancesReimbursementsData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadReimbursement,
            query: this.getQuery()
        }))
    }

    fetchMoneyCodesData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadMoneyCodes,
            query: this.getQuery()
        }))
    }

    fetchMatrixCalculation = (LoadID, LoadPricingMatrixID, CommodityPricingMatrixID) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadMatrixCalculate,
            query: {
                LoadID: this.getID(),
                LoadPricingMatrixID: LoadPricingMatrixID,
                CommodityPricingMatrixID: CommodityPricingMatrixID
            }
        }))
    }

    fetchFuelMatrixCalculation = (LoadID, FuelSurchargeMatrixID) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.FuelMatrixCalculate,
            query: {
                LoadID: LoadID,
                FuelSurchargeMatrixID: FuelSurchargeMatrixID
            }
        }))
    }

    /** Field Definitions
     ============================================================ */
    getAdvancesReimbursementsQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true})
        }
    }

    getAccessoriesList = () => {
        return getProp(this.props.resource, 'data.LoadAccessorials', []).map(it => Object.assign(it, FieldsManager.getFieldKeyValues(this.getAccessorialFields(it))));
    }

    getAccessorialFields = (item = {}, isViewMode = false) => {
        const Deduction = !!item?.Amount && item.Amount < 0
        const IsPayDriver = item?.IsPayDriver && !this.state.isBroker
        const isEditMode = !!item?.AccessorialID;
        const isInvoiced = this.props.isInvoiced;
        const areDriverFieldsVisible = !isEditMode && !isInvoiced && IsPayDriver;

        if (!!item && !!Object.keys(item)?.length) {
            item.AmountCalculated = item?.LoadAccessorialUnits * (item?.Amount ?? 0);
        }

        const fieldTemplates = {
            Date: new Field('Date', isInvoiced ? moment().format(DEFAULT_DATABASE_DATETIME_FORMAT) : '', [''], isViewMode, isInvoiced ? 'date' : 'hidden', {
                addContainerClass: 'col-span-4',
                hideTable: true
            }),
            AccessorialID: new Field('AccessorialID', '', ['empty_select_search'], isViewMode, 'select-search', {
                addContainerClass: 'col-span-full', render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <RowNotes notes={it.LoadAccessorialNotes}/>

                        {!!it.IsOnHold && !it.IsAdjust && (
                            <Tippy content={this.props.translate("text.not_yet_confirmed")}>
                                <ExclamationCircleIcon
                                    className="w-5 h-5 text-yellow-600"
                                />
                            </Tippy>
                        )}
                        {(it?.IsPayed) ?
                            <Tippy
                                content={"Item is processed"}
                            >
                                <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                            </Tippy> : null
                        }

                        {it.Accessorial}
                    </div>
                }
            }, {innerRef: (el) => this.accessorialsDialogFocusRef.current = el}),
            InvoiceSumType: new Field('InvoiceSumType', '', [''], true, isInvoiced ? 'hidden' : 'text', {
                hideTable: true,
                addContainerClass: 'col-span-4'
            }, {placeholder: ' '}),
            IncomeAccount: new Field('IncomeAccount', '', [''], true, 'text', {
                hideTable: true,
                addContainerClass: 'col-span-4'
            }, {placeholder: ' '}),
            ExpenseAccount: new Field('ExpenseAccount', '', [''], true, 'text', {
                hideTable: true,
                addContainerClass: 'col-span-4'
            }, {placeholder: ' '}),
            LoadAccessorialUnits: new Field('LoadAccessorialUnits', 1, ['float'], isViewMode, '', {addContainerClass: 'col-span-full'}),
            Deduction: new Field('Deduction', '', [''], isViewMode, 'button-group', {
                hideTable: true,
                data: {0: this.props.translate('btn.charge'), 1: this.props.translate('btn.deduction')},
                addContainerClass: 'col-span-6',
                hideLabel: true
            }),
            Amount: new Field('Amount', 0, this.props.isInvoiced ? ['gt:0'] : [''], isViewMode, 'money', {
                addContainerClass: 'col-span-6',
                label: "amount_per_unit"
            }, {
                placeholder: '0',
                addClass: 'form-control ' + (Deduction ? 'text-red-600' : 'text-green-600'),
            }),
            AmountCalculated: new Field('AmountCalculated', 0, [''], isViewMode, 'float', {
                render: (item) => <div
                    className={"text-right"}>{genericMoneyFormatter(numberWithCommasToBack(item.AmountCalculated))}</div>
            }),
            IsOnHold: new Field('IsOnHold', 1, [''], isViewMode, isInvoiced ? 'hidden' : 'checkbox', {
                hideTable: true,
                addContainerClass: 'col-span-full',
                label: "notYetConfirmed"
            }),
            LoadAccessorialNotes: new Field('LoadAccessorialNotes', '', [''], isViewMode, 'textarea', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            IsPayDriver: new Field('IsPayDriver', 0, [], !areDriverFieldsVisible && isViewMode, areDriverFieldsVisible ? 'checkbox' : 'hidden', {
                hideTable: true,
                label: 'IsPayDriver',
                note: 'Add driver pay entry',
                addContainerClass: 'col-span-full'
            }),

            DriverID: new Field('DriverID', '', areDriverFieldsVisible ? ['empty'] : [''], isViewMode, areDriverFieldsVisible ? 'select-search' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),

            FeeTypeID: new Field('FeeTypeID', '', areDriverFieldsVisible ? ['empty'] : [''], isViewMode, areDriverFieldsVisible ? 'select' : 'hidden', {
                hideTable: true,
                values: Object.values(feeTypes).reduce((memo, it, i) => {
                    memo[i + 1] = this.props.translate(it)
                    return memo
                }, {}),
                addContainerClass: 'col-span-full'
            }),

            PayDriverAmount: new Field('PayDriverAmount', '', areDriverFieldsVisible ? ['float'] : [''], isViewMode, areDriverFieldsVisible ? 'text' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full',
                render: (item) => genericMoneyFormatter(numberWithCommasToBack(item.PayDriverAmount))
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getAdvancesReimbursementsFields = (item = null) => {
        const IsPayed = !!item?.IsPayed

        const fieldTemplates = {
            Notes: new Field('Notes', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                label: "Name"
            }, {
                disabled: IsPayed
            }),
            Deduction: new Field('Deduction', item?.Deduction === 1 ? 1 : 0, [], false, 'button-group', {
                data: {0: 'Advances', 1: 'Reimbursement'},
                addContainerClass: 'col-span-full',
                render: (it) => !it.Deduction
                    ? <div className="flex gap-x-2">
                        <PlusCircleIcon className="text-green-600 w-5 h-5"/>
                        {this.props.translate("text.Advancements")}
                    </div>
                    : <div className="flex gap-x-2">
                        <MinusCircleIcon className="text-red-600 w-5 h-5"/>
                        {this.props.translate("text.Reimbursement")}
                    </div>
            }),
            DriverID: new Field('DriverID', '', ['empty'], false, 'select', {
                addContainerClass: 'col-span-full',
                render: (item) => item.Contact
            }, {
                disabled: IsPayed
            }),
            LoadNumber: new Field('LoadNumber', '', [''], true, 'text', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [''], false, 'date', {addContainerClass: 'col-span-full'}, {
                disabled: IsPayed
            }),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {addContainerClass: 'col-span-full'}, {
                disabled: IsPayed
            }),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            IsDelayedPayment: new Field('IsDelayedPayment', '', [''], false, !item?.Deduction ? 'checkbox' : 'hidden', {
                addContainerClass: 'col-span-full'
            }),
            InterestRate: new Field('InterestRate', '', !item?.IsDelayedPayment ? [] : ['integer'], false, !item?.IsDelayedPayment ? 'hidden' : 'float', {addContainerClass: 'col-span-full'}, {}),
            LoanTerm: new Field('LoanTerm', '', !item?.IsDelayedPayment ? [] : ['integer'], false, !item?.IsDelayedPayment ? 'hidden' : 'integer', {addContainerClass: 'col-span-full'}, {}),
            IsApproved: new Field('IsApproved', '', [''], false, 'checkbox', {
                render: this.renderApprovedCell,
                hideDialog: true
            }, {})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getMoneyCodeFields = (item = null) => {
        return {
            Code: new Field('Code', '', [], false, 'text', {hideDialog: true}),
            Amount: new Field('Amount', '', ['empty'], false, 'float', {
                hideTable: false,
                addContainerClass: 'col-span-full'
            }),
            IssuedToID: new Field('IssuedToID', item ? item.DriverContactID : null, item ? [!item.IsBroker ? 'empty' : ''] : [], false, !!item && !item.IsBroker ? 'select' : 'hidden', {
                render: (it) => {
                    return it.Contact ?? it.Organization
                },
                hideTable: false,
                addContainerClass: 'col-span-full'
            }),
            IssuedToOrganizationID: new Field('IssuedToOrganizationID', item ? item.CarrierOrganizationID : null, item ? [item.IsBroker ? 'empty' : ''] : [], false, !!item && item.IsBroker ? 'select' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search', {
                hideTable: false,
                addContainerClass: 'col-span-full'
            }),
            LoadNumber: new Field('LoadNumber', item ? {
                value: item.LoadID,
                label: item.LoadNumber
            } : '', ['empty'], true, 'select-search', {addContainerClass: 'col-span-full'}),
            Notes: new Field('Notes', '', ['empty'], false, 'textarea', {
                hideTable: false,
                addContainerClass: 'col-span-full'
            }),
            IsVoided: new Field('IsVoided', '', [''], false, 'checkbox', {
                hideDialog: true,
                addContainerClass: 'col-span-full'
            }),
        };
    }


    getCommissionList = () => {
        return getProp(this.props.resource, 'data.LoadCommissions', [])
            .map(it => Object.assign(it, {FeeType: this.props.translate(feeTypes[it.FeeTypeID])}, FieldsManager.getFieldKeyValues(this.getCommissionFields(it))))
    }

    getCommissionFields = (item = {}, isViewMode = false) => {
        if (!!item && !!Object.keys(item)?.length) {
            item.AmountCalculated = this.calculateValueBasedOnFee(item, "commission");
        }

        const isContactVisible = (!item?.OrganizationID && !item?.ContactID) || !!item?.ContactID
        const fieldTemplates = {
            PayTo: new Field('PayTo', isContactVisible ? 1 : 2, [''], false, 'button-group', {
                data: {
                    1: this.props.translate('field.PayToContactID'),
                    2: this.props.translate('field.PayToOrganizationID')
                },
                hideTable: true,
                addContainerClass: 'col-span-full',
            }),
            OrganizationID: new Field('OrganizationID', '', isContactVisible ? [''] : ['empty'], isViewMode, isContactVisible ? 'hidden' : 'select-search', {
                hideTable: true,
                addContainerClass: 'col-span-3',
                headerLabel: 'PayTo',
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <RowNotes notes={it.LoadCommissionNotes}/>

                        <span>
                            {it.Organization}
                            {it?.IsPayed ?
                                <Tippy
                                    content={"Item is processed "
                                        + (it?.PayrollBatchEntryID ? " in batch #" + it.AutoCounter : "")}
                                >
                                    <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                                </Tippy> : null}
                        </span>
                    </div>
                }
            }),
            ContactID: new Field('ContactID', '', isContactVisible ? ['empty'] : [''], isViewMode, isContactVisible ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-3',
                headerLabel: 'PayTo',
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <RowNotes notes={it.LoadCommissionNotes}/>

                        <span>
                            {!!it.Contact && it.Contact !== " " ? it.Contact : it.Organization}
                            {it?.IsPayed ?
                                <Tippy
                                    content={"Item is processed "
                                        + (it?.PayrollBatchEntryID ? " in batch #" + it.AutoCounter : "")}
                                >
                                    <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                                </Tippy> : null}
                        </span>
                    </div>
                }
            }),
            FeeTypeID: new Field('FeeTypeID', '1', ['empty'], isViewMode, 'select', {addContainerClass: 'col-span-3'}, {values: this.feeTypes}),
            Amount: new Field('Amount', 0, [''], isViewMode, this.getAmountFieldType(item?.FeeTypeID), {
                addContainerClass: 'col-span-3',
                render: (item) => <div className={"text-right"}>{this.getAmountCellSuffix(item)}</div>
            }, {placeholder: '0', isNegativeAllowed: true}),
            AmountCalculated: new Field('AmountCalculated', 0, [''], isViewMode, 'float', {
                render: (item) => {
                    if (!!item?.IsPayed && !!item?.PayrollBatchEntryID) {
                        return (
                            <div className={"text-right"}>{genericMoneyFormatter(item.PayedAmount)}</div>
                        )
                    }
                    return (<div
                        className={"text-right"}>{genericMoneyFormatter(numberWithCommasToBack(item.AmountCalculated))}</div>)
                }
            }),
            LoadCommissionNotes: new Field('LoadCommissionNotes', '', [''], isViewMode, 'textarea', {
                hideTable: true, addContainerClass: 'col-span-3'
            }),
            LoadCommissionID: new Field('LoadCommissionID', '', [''], true, 'hidden', {
                hideTable: true,
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getDriversFeeList = () => {
        return getProp(this.props.resource, 'data.Drivers', [])
            .map(it => Object.assign(it, {FeeType: this.props.translate(feeTypes[it.FeeTypeID])}, FieldsManager.getFieldKeyValues(this.getDriversFeeFields(it))))
    }

    getDriversFeeFields = (item = {}, tableView = false, isViewMode = false) => {
        const showAmountExtra = Number(item?.FeeTypeID) === 7 || tableView

        if (!!item && !!Object.keys(item)?.length) {
            item.AmountCalculated = this.calculateValueBasedOnFee(item, "driver");
        }

        const fieldTemplates = {
            DriverID: new Field('DriverID', '', ['empty_select_search'], isViewMode, 'select-search', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <RowNotes notes={it.LoadDriverNotes}/>
                        {it?.IsPayed ?
                            <Tippy
                                content={"Item is processed " + (it?.PayrollBatchEntryID ? " in batch #" + it.AutoCounter : "")}
                            >
                                <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                            </Tippy> : null}

                        <span>
                            {it.Driver}
                        </span>

                    </div>
                }
            }),
            FeeTypeID: new Field('FeeTypeID', '1', ['empty'], isViewMode, 'select', {addContainerClass: 'col-span-full'}, {values: this.feeTypes}),
            Amount: new Field('Amount', 0, ['float'], isViewMode, 'float', {
                addContainerClass: showAmountExtra ? 'col-span-6' : 'col-span-full',
                render: (item) => <div
                    className={"text-right"}>{this.getAmountCellSuffix(item)} {item.AmountExtra ? (" + " + this.getAmountCellSuffix(item, 'AmountExtra')) : ""}</div>
            }, {placeholder: '0'}),
            AmountExtra: new Field('AmountExtra', 0, [''], isViewMode, showAmountExtra ? 'money' : 'hidden', {
                addContainerClass: 'col-span-6',
                hideTable: true,
                label: "amount_per_empty_mile"
            }, {placeholder: '0', isNegativeAllowed: true}),
            AmountCalculated: new Field('AmountCalculated', 0, [''], isViewMode, 'float', {
                render: (item) => {
                    if (!!item?.IsPayed && !!item?.PayrollBatchEntryID) {
                        return (
                            <div className={"text-right"}>{genericMoneyFormatter(item.PayedAmount)}</div>
                        )
                    }
                    return (<div
                        className={"text-right"}>{genericMoneyFormatter(numberWithCommasToBack(item.AmountCalculated))}</div>)
                }
            }),
            LoadDriverNotes: new Field('LoadDriverNotes', '', [''], isViewMode, 'textarea', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            LoadDriverPayID: new Field('LoadDriverPayID', '', [''], true, 'hidden', {
                hideTable: true,
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getDriversAccessorialsList = () => {
        return getProp(this.props.resource, 'data.DriversAccessorials', [])
            .map(it => Object.assign(it, {FeeType: this.props.translate(feeTypes[it.FeeTypeID])}, FieldsManager.getFieldKeyValues(this.getDriversAccessorialFields(it))))
    }

    getDriversAccessorialFields = (item = {}, tableView = false, isViewMode = false) => {
        const deduction = !!item?.Amount && item.Amount < 0
        const showAmountExtra = Number(item?.FeeTypeID) === 7 || tableView

        if (!!item && !!Object.keys(item)?.length) {
            item.AmountCalculated = this.calculateValueBasedOnFee(item, "driver");
        }

        const fieldTemplates = {
            AccessorialID: new Field('AccessorialID', '', ['empty_select_search'], isViewMode, 'select-search', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <RowNotes notes={it.Notes}/>
                        {it?.IsPayed ?
                            <Tippy
                                content={"Item is processed " + (it?.PayrollBatchEntryID ? " in batch #" + it.AutoCounter : "")}
                            >
                                <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                            </Tippy> : null}

                        <span>
                            {it.Accessorial}
                        </span>
                    </div>
                }
            }),
            DriverID: new Field('DriverID', '', [''], isViewMode, 'select-search', {addContainerClass: 'col-span-full'}),
            FeeTypeID: new Field('FeeTypeID', '', ['empty'], isViewMode, 'select', {addContainerClass: 'col-span-full'}, {values: this.feeTypes}),
            Deduction: new Field('Deduction', '', [''], isViewMode, 'button-group', {
                data: {0: this.props.translate('btn.pay'), 1: this.props.translate('btn.deduct')},
                addContainerClass: 'col-span-6',
                hideLabel: true,
                hideTable: true,
            }),
            Amount: new Field('Amount', 0, ['float'], isViewMode, 'float', {
                addContainerClass: showAmountExtra ? 'col-span-6' : 'col-span-full',
                addClass: 'form-control ' + (deduction ? 'text-red-600' : 'text-green-600'),
                render: (item) => <div
                    className={"text-right"}>{this.getAmountCellSuffix(item)} {(item.AmountExtra && Number(item?.FeeTypeID) === 7) ? (" + " + this.getAmountCellSuffix(item, 'AmountExtra')) : ""}</div>
            }, {placeholder: '0'}),
            AmountExtra: new Field('AmountExtra', 0, ['float_or_empty'], isViewMode, showAmountExtra ? 'float' : 'hidden', {
                addContainerClass: 'col-span-6',
                addClass: 'form-control ' + (deduction ? 'text-red-600' : 'text-green-600'),
                hideTable: true,
                label: "amount_per_empty_mile"
            }, {placeholder: '0'}),
            AmountCalculated: new Field('AmountCalculated', 0, [''], isViewMode, 'float', {
                render: (item) => {
                    if (!!item?.IsPayed && !!item?.PayrollBatchEntryID) {
                        return (
                            <div className={"text-right"}>{genericMoneyFormatter(item.PayedAmount)}</div>
                        )
                    }
                    return (<div
                        className={"text-right"}>{genericMoneyFormatter(numberWithCommasToBack(item.AmountCalculated))}</div>)
                }
            }),
            Notes: new Field('Notes', '', [''], isViewMode, 'textarea', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            LoadAccesorialDriverPayID: new Field('LoadAccesorialDriverPayID', '', [''], true, 'hidden', {
                hideTable: true,
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getCarriersFeeList = () => {
        return getProp(this.props.resource, 'data.Carriers', [])
            .map(it => Object.assign(it, {FeeType: this.props.translate(feeTypes[it.FeeTypeID])}, FieldsManager.getFieldKeyValues(this.getCarriersFeeFields(it))))
    }

    getCarriersFeeFields = (item = {}, isViewMode = false) => {
        const showAmountExtra = Number(item?.FeeTypeID) === 7

        const showAcc = !!item?.showAcc

        if (!!item && !!Object.keys(item)?.length) {
            item.AmountCalculated = this.calculateValueBasedOnFee(item, "carrier");
        }

        const carrierFeeTypes = Object.assign({}, this.feeTypes);
        delete carrierFeeTypes[LOAD_PAY_METHOD_NET_PROFIT_PERCENT];

        const fieldTemplates = {
            CarrierID: new Field('CarrierID', '', ['empty_select_search'], isViewMode, 'select-search', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <RowNotes notes={it.CarrierPayNotes}/>
                        {it?.IsPayed ?
                            <Tippy
                                content={"Item is processed"}
                            >
                                <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                            </Tippy> : null}

                        <span>
                            {it.Carrier}
                        </span>
                    </div>
                }
            }),
            CarrierPayType: new Field('CarrierPayType', '', [], isViewMode, 'text', {
                addContainerClass: 'col-span-full',
                hideDialog: true,
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <span>
                            {it.Accessorial ?? "Line haul"}
                        </span>
                    </div>
                }
            }),
            IsAcc: new Field('IsAcc', 0, [], isViewMode, 'checkbox', {
                addContainerClass: 'col-span-full',
                label: "IsAcc",
                hideTable: true
            }),
            AccessorialID: new Field('AccessorialID', '', [], isViewMode, showAcc ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                label: "IsAcc",
                hideTable: true,
                render: (it) => {
                    return <div className="flex gap-x-1 p-0.5">
                        <span>
                            {it.Accessorial ?? "Line haul"}
                        </span>
                    </div>
                }
            }),
            FeeTypeID: new Field('FeeTypeID', '1', ['empty'], isViewMode, 'select', {addContainerClass: 'col-span-full'}, {values: carrierFeeTypes}),
            Amount: new Field('Amount', 0, [''], isViewMode, this.getAmountFieldType(item?.FeeTypeID), {
                addContainerClass: showAmountExtra ? 'col-span-6' : 'col-span-full',
                render: (item) => <div
                    className={"text-right"}>{this.getAmountCellSuffix(item)} {(item?.AmountExtra && item?.FeeTypeID === 7) ? (" + " + this.getAmountCellSuffix(item, 'AmountExtra')) : ""}</div>
            }, {placeholder: '0', isNegativeAllowed: true}),
            AmountExtra: new Field('AmountExtra', 0, ['float_or_empty'], isViewMode, showAmountExtra ? 'float' : 'hidden', {
                addContainerClass: 'col-span-6',
                hideTable: true,
                label: "amount_per_empty_mile"
            }, {placeholder: '0'}),
            AmountCalculated: new Field('AmountCalculated', 0, [''], isViewMode, 'float', {
                render: (item) => <div className={"text-right"}>{genericMoneyFormatter(item.AmountCalculated)}</div>
            }),
            CarrierPayNotes: new Field('CarrierPayNotes', '', [''], isViewMode, 'textarea', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            CarrierPayID: new Field('CarrierPayID', '', [''], true, 'hidden', {hideTable: true,}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getCarriersFeeAdditionalFields = (item = {}) => {
        const fieldTemplates = {
            IsHoldCarrierPay: new Field('IsHoldCarrierPay', item.IsHoldCarrierPay, [''], false, 'button-group', {
                data: {0: 'No', 1: 'Yes'},
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-3',
                labelType: 'left',
                note: 'Do not pay carrier yet, since not all paperwork is complete, or there is an outstanding claim'
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getExtraChargeList = () => {
        return getProp(this.props.resource, 'data.ExtraCharges', []).map(carrier => {
            return this.getExtraChargeFields(carrier)
        })
    }

    getExtraChargeFields = (item = {}) => {
        let initialData = !Object.keys(item).length
        let paysToContact = !item.OrganizationPayID && !!item.ContactPayID

        let fieldTemplates = {
            PayTo: new Field('PayTo', '', [''], false, 'button-group', {
                data: {
                    0: this.props.translate('field.OrganizationPayID'),
                    1: this.props.translate('field.ContactPayID')
                },
                labelType: 'left',
            }),

            OrganizationPayID: new Field('OrganizationPayID', '', [''], false, paysToContact ? 'hidden' : 'select-search', {addContainerClass: 'col-span-3'}),
            ContactPayID: new Field('ContactPayID', '', [''], false, paysToContact ? 'select-search' : 'hidden', {addContainerClass: 'col-span-3'}),

            Type: new Field('Type', '', [''], false, 'button-group', {
                data: {0: this.props.translate('btn.charge'), 1: this.props.translate('btn.expense')},
                labelType: 'left',
            }),
            IncludeInNetProfit: new Field('IncludeInNetProfit', '', [''], false, 'button-group', {
                data: {0: this.props.translate('btn.no'), 1: this.props.translate('btn.yes')},
                labelType: 'left',
                note: 'Include net profit calculation into the commission'
            }),
            Amount: new Field('Amount', '', ['float_or_empty'], false, 'float', {addContainerClass: 'col-span-3'}, {placeholder: '0'}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-3'})
        }
        fieldTemplates = fillFieldsFromData(fieldTemplates, item)

        if (initialData) {
            fieldTemplates.ContactPayID.type = 'hidden'
        }

        if (paysToContact) {
            fieldTemplates.PayTo.value = '1'
        }

        return fieldTemplates
    }

    getBillingFields = (item = {}, matrixApplied = false) => {
        const isLoadDelivered = getProp(this.props.resource, 'data.load.LoadStatusID', 0) == LOAD_STATUS_DELIVERED
        const RateConfirmedContact = getProp(this.props.resource, 'data.load.RateConfirmedContact', '')
        const RateConfirmedContactID = getProp(this.props.resource, 'data.load.RateConfirmedContactID', '')

        let fieldTemplates = {
            PriceTypeID: new Field('PriceTypeID', getProp(item, 'PriceTypeID', LOAD_PRICING_FLAT), ['empty'], (item.IsInvoiced || matrixApplied), 'select', {
                label: 'RateCalculationBasis',
                addContainerClass: 'col-span-full sm:col-span-3'
            }),

            PriceMilesCalculationTypeID: new Field('PriceMilesCalculationTypeID', 0, [''], false, item.PriceTypeID !== LOAD_PRICING_PER_MILE ? 'hidden' : 'button-group', {
                data: {
                    0: this.props.translate('btn.TotalMilesNoStopBy'),
                    1: this.props.translate('field.TotalMiles'),
                    2: this.props.translate('field.CustomTotalMiles')
                },
                hideTable: true,
                hideLabel: false,
                addContainerClass: "col-span-full lg:col-span-6",
                addClass: "grow"
            }),

            CustomTotalMiles: new Field('CustomTotalMiles', "", [], Number(item?.PriceMilesCalculationTypeID) !== 2, item.PriceTypeID !== LOAD_PRICING_PER_MILE ? 'hidden' : 'float', {addContainerClass: 'col-span-full lg:col-span-3'}),

            PriceBase: new Field('PriceBase', 0, ['float_or_empty'], (item.IsInvoiced || matrixApplied), 'float', {
                label: 'BaseRate',
                addContainerClass: 'col-span-full sm:col-span-3 sm:col-start-1'
            }),

            Price: new Field('Price', 0, ['float_or_empty'], true, 'float', {
                label: 'CalculatedRate',
                addContainerClass: 'col-span-full sm:col-span-3'
            }),
            BillTypeID: new Field('BillTypeID', getProp(item, 'BillTypeID', ''), [], (item.IsInvoiced), 'select', {addContainerClass: 'col-span-full sm:col-span-3'}),

            CalculationPrice: new Field('CalculationPrice', 0, ['float_or_empty'], (item.IsInvoiced), 'float', {
                label: 'CalculationsBaseRate',
                addContainerClass: 'col-span-full sm:col-span-3'
            }),

            IsCalculationPriceOverride: new Field('IsCalculationPriceOverride', '', [''], false, 'button-group', {
                data: {0: 'No', 1: 'Yes'},
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-3',
                labelType: 'left',
                note: 'This value will be used as base for driver and carrier payment calculations.',
                tooltip: (
                    <div>
                        <p className="mb-4">If overridden this value will be used as base for driver and carrier
                            payment calculations, this value will also be displayed on Dispatch Confirmation. </p>
                        <p>In case there is no override checked, `Base Rate` will be used.</p>
                    </div>
                )
            }),

            IsRateConfirmed: new Field('IsRateConfirmed', item.IsRateConfirmed, [''], item.IsInvoiced, 'button-group', {
                data: {0: 'No', 1: 'Yes'},
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-3',
                labelType: 'left',
                note: 'Indicates if rate is communicated and confirmed with a customer.',
                afterLabelHtml: <UserBadge
                    Contact={RateConfirmedContact}
                    ContactID={RateConfirmedContactID}
                    IsRateConfirmed={item.IsRateConfirmed}
                    showContactDetails={this.showContactDetails}
                    translate={this.props.translate}
                />
            }),

            IsAccessorialHold: new Field('IsAccessorialHold', item.IsAccessorialHold, [''], true, 'hidden', {
                data: {0: 'No', 1: 'Yes'},
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-3',
                labelType: 'left',
                note: 'Full list of accessorials is not yet confirmed.'
            }),

            IsInvoiced: new Field('IsInvoiced', item.IsInvoiced, [''], true, 'hidden'),
            IsPODReady: new Field('IsPODReady', item.IsPODReady, [''], !isLoadDelivered, 'hidden'),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** UI Events
     ================================================================ */
    handleDriverAccessorialChange = (fields, name, value) => {
        let displayValue = this.state.displayValue
        fields = FieldsManager.updateField(fields, name, value)

        if ('Deduction' === name) {
            fields.Amount.props.addClass = 'form-control ' + (value ? 'text-red-600' : 'text-green-600')
            fields.AmountExtra.props.addClass = 'form-control ' + (value ? 'text-red-600' : 'text-green-600')
        }

        if (['Amount', 'AmountExtra', 'Deduction', 'FeeTypeID'].includes(name)) {
            let amountValue = this.calculateValueBasedOnFee(FieldsManager.getFieldKeyValues(fields), "driver")
            amountValue = amountValue ? amountValue : 0
            amountValue = fields.Deduction.value ? -Math.abs(amountValue) : Math.abs(amountValue)

            let amountExtraValue = fields.AmountExtra.value ? fields.AmountExtra.value : 0
            amountExtraValue = fields.Deduction.value ? -Math.abs(numberWithCommasToBack(amountExtraValue)) : Math.abs(numberWithCommasToBack(amountExtraValue))

            const calculatedValue = amountValue + amountExtraValue;
            displayValue = genericMoneyFormatter(calculatedValue)
            fields = FieldsManager.updateField(fields, 'AmountCalculated', calculatedValue)
        }

        if ('FeeTypeID' === name) {
            const isTypeTotalMiles = Number(value) === 7
            fields.AmountExtra.validate = isTypeTotalMiles ? ['float_or_empty'] : [''];
            fields.AmountExtra.type = isTypeTotalMiles ? 'float' : 'hidden';
            fields.AmountExtra.value = "";
            fields.Amount.metadata = isTypeTotalMiles ? Object.assign(fields.Amount.metadata, {addContainerClass: 'col-start-1 col-span-6'}) : Object.assign(fields.Amount.metadata, {addContainerClass: 'col-span-full'});
        }

        fields[name].errorMessage = "";

        this.setState({
            displayValue: displayValue
        })

        return fields
    }

    handleDriversFeeChange = (fields, name, value) => {
        let displayValue = this.state.displayValue;

        fields = FieldsManager.updateField(fields, name, value);
        fields[name].errorMessage = "";

        if ('Amount' === name || 'AmountExtra' === name || 'FeeTypeID' === name) {
            let calculatedValue = this.calculateValueBasedOnFee(FieldsManager.getFieldKeyValues(fields), "driver");
            displayValue = genericMoneyFormatter(calculatedValue);
            fields = FieldsManager.updateField(fields, 'AmountCalculated', calculatedValue);
        }

        fields = this.limitPercentAmount(fields);

        if ('FeeTypeID' === name) {
            const isTypeTotalMiles = Number(value) === LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES; // Total miles
            fields.AmountExtra.type = isTypeTotalMiles ? 'money' : 'hidden';

            if (!isTypeTotalMiles) {
                fields['AmountExtra'].metadata.htmlAfterField = "";
                fields['AmountExtra'].metadata.htmlBeforeField = "";
            }

            fields.Amount.metadata = isTypeTotalMiles ? Object.assign(fields.Amount.metadata, {addContainerClass: 'col-span-6'}) : Object.assign(fields.Amount.metadata, {addContainerClass: 'col-span-full'})
            fields.AmountExtra.value = "";

            let amountType = this.getAmountFieldType(fields.FeeTypeID.value);
            fields['Amount'].type = amountType;
            if (amountType === 'float') {
                fields['Amount'].metadata.htmlAfterField = "";
                fields['Amount'].metadata.htmlBeforeField = "";
            }
        }

        if ("CarrierID" === name && !!fields?.ContactID) {
            fields.ContactID.disabled = !value?.value;
            fields.ContactID.value = "";
            fields.ContactID.props.key += 1;
            fields.ContactID.props.query = {
                SelectCarrierID: value?.value
            }
        }

        if ("IsAcc" === name) {
            fields.AccessorialID.type = value ? 'select-search' : 'hidden';
            if (!value) {
                fields.AccessorialID.value = '';
            }
        }

        this.setState({
            displayValue: displayValue
        })

        return fields
    }

    handleMoneyCodesChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);

        this.setState({moneyCodeFields: fieldsUpdate})
        return fieldsUpdate;
    }

    handleAdvancesReimbursementsChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);

        if (name === 'Deduction') {
            fieldsUpdate.IsDelayedPayment.type = value === 0 ? 'checkbox' : 'hidden';
        }

        if (name === 'IsDelayedPayment') {
            fieldsUpdate.InterestRate.type = Number(value) === 1 ? 'float' : 'hidden';
            fieldsUpdate.LoanTerm.type = Number(value) === 1 ? 'float' : 'hidden';

            fieldsUpdate.InterestRate.validate = Number(value) === 1 ? ['integer'] : [''];
            fieldsUpdate.LoanTerm.validate = Number(value) === 1 ? ['integer'] : [''];
        }

        this.setState({advancesReimbursementFields: fieldsUpdate})
        return fieldsUpdate;
    }

    handleAccessorialChange = (fields, name, value) => {
        const isEditMode = !!this.state.selectedItem;
        let accessorialFieldsUpdate = cloneDeep(fields)

        if ('AccessorialID' === name) {
            // TODO Take customer defaults as well
            if (value?.metaData && !this.props.isInvoiced) {
                const isBroker = !!getProp(this.props.resource, 'data.load.IsBroker', false);
                value.metaData.IsPayDriver = isBroker ? false : value.metaData.IsPayDriver;
                value.metaData.DriverID = isBroker ? false : value.metaData.DriverID;
                value.metaData.FeeTypeID = isBroker ? false : value.metaData.FeeTypeID;
                value.metaData.PayDriverAmount = isBroker ? false : value.metaData.PayDriverAmount;
            }

            if (value?.metaData) {
                accessorialFieldsUpdate = this.getAccessorialFields(value.metaData)
                accessorialFieldsUpdate.AmountCalculated.type = 'hidden'
            }

            const isPayDriver = accessorialFieldsUpdate.IsPayDriver.value

            if (!!accessorialFieldsUpdate.IsPayDriver.value && !isEditMode && !this.props.isInvoiced) {
                accessorialFieldsUpdate.IsPayDriver.type = isPayDriver ? 'checkbox' : 'hidden'

                if (!accessorialFieldsUpdate.DriverID.value && !!this.state.selectedItem?.DriverID) {
                    accessorialFieldsUpdate.DriverID.value = {
                        value: this.state.selectedItem.DriverID,
                        label: this.state.selectedItem.Driver
                    }
                }

                accessorialFieldsUpdate.DriverID.type = value ? 'select-search' : 'hidden';
                accessorialFieldsUpdate.DriverID.validate = value ? ['empty'] : [''];

                accessorialFieldsUpdate.FeeTypeID.type = value ? 'select' : 'hidden';
                accessorialFieldsUpdate.FeeTypeID.validate = value ? ['empty'] : [''];

                accessorialFieldsUpdate.PayDriverAmount.type = value ? 'text' : 'hidden';
                accessorialFieldsUpdate.PayDriverAmount.validate = value ? ['float'] : [''];
            }
        }

        if ('IsPayDriver' === name) {
            accessorialFieldsUpdate.DriverID.type = value ? 'select-search' : 'hidden';
            accessorialFieldsUpdate.DriverID.validate = value ? ['empty'] : [''];

            accessorialFieldsUpdate.FeeTypeID.type = value ? 'select' : 'hidden';
            accessorialFieldsUpdate.FeeTypeID.validate = value ? ['empty'] : [''];

            accessorialFieldsUpdate.PayDriverAmount.type = value ? 'text' : 'hidden';
            accessorialFieldsUpdate.PayDriverAmount.validate = value ? ['float'] : [''];
        }

        if ('Amount' === name) {
            const calculatedAmount = value * accessorialFieldsUpdate.LoadAccessorialUnits.value

            accessorialFieldsUpdate = FieldsManager.updateField(accessorialFieldsUpdate, 'AmountCalculated', calculatedAmount ? calculatedAmount : 0)
        }

        if ('LoadAccessorialUnits' === name) {
            const calculatedAmount = value * accessorialFieldsUpdate.Amount.value

            accessorialFieldsUpdate = FieldsManager.updateField(accessorialFieldsUpdate, 'AmountCalculated', calculatedAmount ? calculatedAmount : 0)
        }

        if ('Deduction' === name) {
            accessorialFieldsUpdate.Amount.props.addClass = 'form-control ' + (value ? 'text-red-600' : 'text-green-600')
        }

        return FieldsManager.updateField(accessorialFieldsUpdate, name, value)
    }

    showContactDetails = (ContactID) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: ContactID}))
    }

    toggleDefaultAccesorialModal = () => {
        this.setState({
            isDefaultAccesorialModalOpen: !this.state.isDefaultAccesorialModalOpen,
            FuelSurcharge: undefined
        })
    }

    toggleSetPriceMatrixModal = () => {
        this.setState({
            isSetPriceMatrixModalOpen: !this.state.isSetPriceMatrixModalOpen,
            PriceBase: this.state.isSetPriceMatrixModalOpen ? this.state.PriceBase : undefined,
            PriceTypeID: this.state.isSetPriceMatrixModalOpen ? this.state.PriceTypeID : undefined,
            PriceType: this.state.isSetPriceMatrixModalOpen ? this.state.PriceType : undefined,
        })
    }

    toggleCustomerSettingsDialog = () => {
        this.setState({
            isCustomerSettingsModalOpen: !this.state.isCustomerSettingsModalOpen
        })
    }

    handleFuelMatrixInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)

        if ('FuelSurchargeMatrixID' === name) {
            this.fetchFuelMatrixCalculation(this.getID(), value.metaData.FuelSurchargeMatrixID)
        }

        return fields
    }

    handlePricingMatrixInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)

        if ('LoadPricingMatrixID' === name || 'CommodityPricingMatrixID' === name) {
            this.fetchMatrixCalculation(this.getID(), value.metaData.LoadPricingMatrixID, value.metaData.CommodityPricingMatrixID)
        }

        if (name === 'MatrixTypeID') {
            fields.LoadPricingMatrixID.type = value === 2 ? 'hidden' : 'select-search'
            fields.CommodityPricingMatrixID.validate = value === 2 ? [''] : ['empty']
            fields.CommodityPricingMatrixID.type = value === 2 ? 'select-search' : 'hidden'
            fields.CommodityPricingMatrixID.validate = value === 2 ? ['empty'] : ['']

            fields.LoadPricingMatrixID.type = value === 1 ? 'select-search' : 'hidden'
            fields.LoadPricingMatrixID.validate = value === 1 ? ['empty'] : ['']
            fields.CommodityPricingMatrixID.type = value === 1 ? 'hidden' : 'select-search'
            fields.CommodityPricingMatrixID.validate = value === 1 ? [''] : ['']

            if (value === 2) {
                fields.CommodityPricingMatrixID.value = null
            } else {
                fields.LoadPricingMatrixID.value = null
            }

            this.setState({
                PriceBase: undefined,
                PriceTypeID: undefined,
                PriceType: undefined
            })
        }

        return fields
    }

    handleRemoveSelectedMatrix = () => {
        let billingFieldsUpdate = cloneDeep(this.state.billingFields)

        billingFieldsUpdate.PriceBase.value = this.oldPriceBase ?? 0
        billingFieldsUpdate.PriceBase.disabled = false
        billingFieldsUpdate.PriceTypeID.value = this.oldPriceTypeID ?? LOAD_PRICING_FLAT
        billingFieldsUpdate.PriceTypeID.disabled = false
        billingFieldsUpdate.Price.value = this.calculatePriceValue(billingFieldsUpdate.PriceBase.value, billingFieldsUpdate.PriceTypeID.value)

        if (!billingFieldsUpdate.IsCalculationPriceOverride.value) {
            billingFieldsUpdate.CalculationPrice.value = billingFieldsUpdate.Price.value
        }

        this.setState(({
            billingFields: billingFieldsUpdate,
            matrixApplied: undefined,
            LoadPricingMatrixID: null,
            CommodityPricingMatrixID: null
        }), () => {
            this.props.dispatch(pushNotification({
                data: {
                    notificationMessage: this.props.translate('message.matrix_removed'),
                    messageDuration: 4,
                }
            }));

            this.updateCalculations();
        })
    }

    calculatePriceValue = (baseValue, type, milesType) => {
        let calculatedPriceValue = numberWithCommasToBack(baseValue)

        switch (Number(type)) {
            case LOAD_PRICING_PER_MILE:
                calculatedPriceValue = calculatedPriceValue * this.getMiles(milesType);
                break
            case LOAD_PRICING_PER_WEIGHT:
                calculatedPriceValue = calculatedPriceValue * getProp(this.props.resource, 'data.load.TotalWeight', 0)
                break
            case LOAD_PRICING_PER_VOLUME:
                calculatedPriceValue = calculatedPriceValue * getProp(this.props.resource, 'data.load.TotalVolume', 0)
                break
            case LOAD_PRICING_PER_PIECE:
                calculatedPriceValue = calculatedPriceValue * getProp(this.props.resource, 'data.load.TotalPieces', 0)
                break
        }
        return numberWithCommas(calculatedPriceValue)
    }

    handleBillingInputChange = (name, value) => {
        let milesForCalculation = this.state.milesForCalculation;

        let billingFields = this.state.billingFields
        billingFields = FieldsManager.updateField(billingFields, name, value)

        if ('Price' === name && value.startsWith('0')) {
            value = value.substring(1)
        }

        if (('IsCalculationPriceOverride' === name) && !!value) {
            billingFields = FieldsManager.updateField(billingFields, 'Price', billingFields.CalculationPrice.value)
        }
        if (('IsCalculationPriceOverride' === name) && !value) {
            billingFields.CalculationPrice.value = billingFields.Price.value
        }

        if (name === "PriceMilesCalculationTypeID") {
            billingFields.CustomTotalMiles.disabled = value !== 2;
            milesForCalculation = value;
        }

        if ('PriceBase' === name || 'PriceTypeID' === name || name === "PriceMilesCalculationTypeID" || name === "CustomTotalMiles") {
            let calculatedPriceValue = this.calculatePriceValue(numberWithCommasToBack(billingFields.PriceBase.value) ?? 0, numberWithCommasToBack(billingFields.PriceTypeID?.value), billingFields.PriceMilesCalculationTypeID.value);
            billingFields.Price.value = calculatedPriceValue
            if (!billingFields.IsCalculationPriceOverride.value) {
                billingFields.CalculationPrice.value = calculatedPriceValue
            }
        }

        if ('PriceTypeID' === name) {
            billingFields.PriceMilesCalculationTypeID.type = numberWithCommasToBack(billingFields.PriceTypeID.value) !== LOAD_PRICING_PER_MILE ? 'hidden' : 'button-group'
            billingFields.CustomTotalMiles.type = numberWithCommasToBack(billingFields.PriceTypeID.value) !== LOAD_PRICING_PER_MILE ? 'hidden' : 'float'
        }

        this.setState({
            billingFields: billingFields,
            milesForCalculation: milesForCalculation
        }, () => {
            this.props.setDirty()
            this.updateCalculations()
        })
    }

    handleCommissionInputChange = (fields, name, value) => {
        let displayValue = this.state.displayValue
        fields = FieldsManager.updateField(fields, name, value);
        fields[name].errorMessage = "";

        if ('Amount' === name || 'FeeTypeID' === name) {
            const calculatedValue = this.calculateValueBasedOnFee(FieldsManager.getFieldKeyValues(fields), "commission")

            displayValue = genericMoneyFormatter(calculatedValue)

            if (fields.CalculationPrice) {
                fields = FieldsManager.updateField(fields, 'CalculationPrice', calculatedValue)
            }

            if (fields.AmountCalculated) {
                fields = FieldsManager.updateField(fields, 'AmountCalculated', calculatedValue)
            }

            let amountType = this.getAmountFieldType(fields.FeeTypeID.value);

            fields['Amount'].type = amountType;

            if (amountType === 'float') {
                fields['Amount'].metadata.htmlAfterField = "";
                fields['Amount'].metadata.htmlBeforeField = "";
            }
        }

        fields = this.limitPercentAmount(fields);

        if ('FeeTypeID' === name && !!fields.AmountExtra) {
            const isTypeTotalMiles = Number(value) === 7
            fields.AmountExtra.validate = isTypeTotalMiles ? ['float_or_empty'] : ['']
            fields.AmountExtra.type = isTypeTotalMiles ? 'float' : 'hidden'
            fields.Amount.metadata = isTypeTotalMiles ? Object.assign(fields.Amount.metadata, {addContainerClass: 'col-start-1 col-span-6'}) : Object.assign(fields.Amount.metadata, {addContainerClass: 'col-span-full'})
        }

        if ("PayTo" === name) {
            fields.OrganizationID.type = Number(value) === 2 ? 'select-search' : 'hidden';
            fields.OrganizationID.validate = Number(value) === 2 ? ['empty'] : [''];
            fields.OrganizationID.value = '';
            fields.OrganizationID.metadata.hideTable = Number(value) === 1;

            fields.ContactID.type = Number(value) === 1 ? 'select-search' : 'hidden';
            fields.ContactID.validate = Number(value) === 1 ? ['empty'] : [''];
            fields.ContactID.value = '';
            fields.ContactID.metadata.hideTable = Number(value) === 2;
        }

        this.setState({
            displayValue: displayValue
        })

        return fields
    }

    handleCarriersFeeAdditionalFieldsChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.carriersFeeAdditionalFields, name, value);
        fields[name].errorMessage = "";

        this.setState({
            carriersFeeAdditionalFields: fields
        }, () => this.props.setDirty());
    }

    applyCustomerSettings = (accessorials, commission) => {
        let accessorialsUpdate = cloneDeep(this.state.accessoryFields)
        let commissionUpdate = cloneDeep(this.state.commissionFields)

        accessorials = accessorials.map(it => {
            it.Amount = Number(it.CustomerAccessorialAmount)
            it.LoadAccessorialUnits = Number(it.CustomerAccessorialUnits)
            it.AmountCalculated = it.CustomerAccessorialAmount * it.CustomerAccessorialUnits
            return it
        })

        commission = commission.map(it => {
            it.FeeType = this.props.translate(feeTypes[it.FeeTypeID])
            it.AmountCalculated = this.calculateValueBasedOnFee(it)
            return it
        })

        this.setState({
            accessoryFields: [...accessorials, ...accessorialsUpdate],
            commissionFields: [...commission, ...commissionUpdate],
            isCustomerSettingsModalOpen: false,
        }, () => this.props.setDirty())
    }

    toggleCreateDriverAccessorialDialog = () => {
        this.setState({
            isDriverAccessorialDialogOpen: !this.state.isDriverAccessorialDialogOpen,
            selectedItem: this.getDefaultDriver(),
            selectedItemIndex: undefined
        })
    }

    toggleEditDriverAccessorialDialog = (it, index) => {
        let selectedItem = it
        let displayValue = this.state.displayValue

        if (this.state.isDriverAccessorialDialogOpen) {
            selectedItem = this.state.selectedItem
            index = undefined
        } else {
            displayValue = genericMoneyFormatter(this.calculateValueBasedOnFee(this.state.commissionFields[index], "driver"))
        }

        this.setState({
            isDriverAccessorialDialogOpen: !this.state.isDriverAccessorialDialogOpen,
            selectedItem: selectedItem,
            selectedItemIndex: index,
            displayValue: displayValue
        })
    }

    handleDeleteDriverAccessorialClick = (selectedItem, index) => {
        let driverAccessorialFieldsUpdate = cloneDeep(this.state.driverAccessorialFields)
        driverAccessorialFieldsUpdate.splice(index, 1)

        this.setState({
            driverAccessorialFields: driverAccessorialFieldsUpdate
        }, () => this.props.setDirty());
    }

    toggleCreateAccessorialDialog = () => {
        this.setState({
            isAccessorialDialogOpen: !this.state.isAccessorialDialogOpen,
            selectedItem: this.getDefaultDriver(),
            selectedItemIndex: undefined
        })
    }

    toggleEditAccessorialDialog = (it, index, isViewMode = false) => {
        let selectedItem = Object.assign({}, it);

        if (this.state.isAccessorialDialogOpen) {
            selectedItem = this.state.selectedItem
            index = undefined
        }

        if (selectedItem && selectedItem?.Amount < 0) {
            selectedItem.Amount = Math.abs(selectedItem.Amount); // number should always display positive
            selectedItem.Deduction = 1; // Deduction is not saving on the server
        }

        this.setState({
            isAccessorialDialogOpen: !this.state.isAccessorialDialogOpen,
            selectedItem: selectedItem,
            selectedItemIndex: index,
            isViewMode: isViewMode
        })
    }

    handleDeleteAccessorialClick = (selectedItem, index) => {
        let accessoryFieldsUpdate = cloneDeep(this.state.accessoryFields)
        accessoryFieldsUpdate.splice(index, 1)

        this.setState({accessoryFields: accessoryFieldsUpdate}, () => this.props.setDirty())
    }

    toggleCreateCommissionDialog = () => {
        this.setState({
            isCommissionDialogOpen: !this.state.isCommissionDialogOpen,
            selectedItem: undefined,
            selectedItemIndex: undefined,
            displayValue: 0
        })
    }

    toggleEditCommissionDialog = (it, index, isViewMode = false) => {
        let selectedItem = it
        let displayValue = this.state.displayValue

        if (this.state.isCommissionDialogOpen) {
            selectedItem = this.state.selectedItem
            index = undefined
        } else {
            displayValue = genericMoneyFormatter(this.calculateValueBasedOnFee(this.state.commissionFields[index], "commission"))
        }

        this.setState({
            isCommissionDialogOpen: !this.state.isCommissionDialogOpen,
            selectedItem: selectedItem,
            selectedItemIndex: index,
            displayValue: displayValue,
            isViewMode: isViewMode
        })
    }

    handleDeleteCommissionClick = (selectedItem, index) => {
        let commissionFieldsUpdate = cloneDeep(this.state.commissionFields)
        commissionFieldsUpdate.splice(index, 1)

        this.setState({
            commissionFields: commissionFieldsUpdate,
        }, () => this.props.setDirty())
    }

    toggleCreateDriversFeeModal = () => {
        this.setState({
            isDriversFeeModalOpen: !this.state.isDriversFeeModalOpen,
            selectedItem: undefined,
            selectedItemIndex: undefined,
            displayValue: 0
        })
    }

    toggleEditDriversFeeDialog = (it, index, isViewMode = false) => {
        let selectedItem = it
        let displayValue = this.state.displayValue

        if (this.state.isDriversFeeModalOpen) {
            selectedItem = this.state.selectedItem
            index = undefined
        } else {
            displayValue = genericMoneyFormatter(this.calculateValueBasedOnFee(this.state.driversFeeFields[index], "driver"))
        }

        this.setState({
            isDriversFeeModalOpen: !this.state.isDriversFeeModalOpen,
            selectedItem: selectedItem,
            selectedItemIndex: index,
            displayValue: displayValue,
            isViewMode: isViewMode
        })
    }

    handleDeleteDriversFeeClick = (selectedItem, index) => {
        let driversFeeFieldsUpdate = cloneDeep(this.state.driversFeeFields)
        driversFeeFieldsUpdate.splice(index, 1)

        this.setState({driversFeeFields: driversFeeFieldsUpdate}, () => this.props.setDirty())
    }

    toggleCreateCarriersFeeModal = () => {
        let selectedItem = undefined

        let Carrier = getProp(this.props.resource, 'data.info.Carrier', null)
        let CarrierID = getProp(this.props.resource, 'data.info.CarrierID', null)

        if (!!Carrier && !!CarrierID) {
            selectedItem = {Carrier: Carrier, CarrierID: CarrierID}
        }

        this.setState({
            isCarriersFeeModalOpen: !this.state.isCarriersFeeModalOpen,
            selectedItem,
            selectedItemIndex: undefined,
            displayValue: 0
        })
    }

    toggleEditCarriersFeeDialog = (it, index, isViewMode = false) => {
        let selectedItem = it
        let displayValue = this.state.displayValue

        if (this.state.isCarriersFeeModalOpen) {
            selectedItem = this.state.selectedItem
            index = undefined
        } else {
            displayValue = genericMoneyFormatter(this.calculateValueBasedOnFee(this.state.carriersFeeFields[index], "carrier"))
        }

        this.setState({
            isCarriersFeeModalOpen: !this.state.isCarriersFeeModalOpen,
            selectedItem: selectedItem,
            selectedItemIndex: index,
            displayValue: displayValue,
            isViewMode: isViewMode
        })
    }

    toggleAdvancesReimbursementsCreateModal = () => {
        let advancesReimbursementFields = this.state.advancesReimbursementFields;
        if (!this.state.createModalOpen) {
            advancesReimbursementFields = this.getAdvancesReimbursementsFields();
        }

        this.setState({
            advancesReimbursementFields,
            selectedItem: this.getDefaultDriver(),
            isAdvancesReimbursementsCreateModalOpen: !this.state.isAdvancesReimbursementsCreateModalOpen
        })
    }

    toggleAdvancesReimbursementsViewModal = () => {
        this.fetchAdvancesReimbursementsData()
        this.setState({
            isAdvancesReimbursementsViewModalOpen: !this.state.isAdvancesReimbursementsViewModalOpen
        })
    }

    toggleMoneyCodesCreateModal = () => {
        let moneyCodeFields = this.state.moneyCodeFields;
        if (!this.state.createModalOpen) {
            moneyCodeFields = this.getMoneyCodeFields();
        }

        this.setState({
            moneyCodeFields,
            selectedItem: this.getDefaultDriver(),
            isMoneyCodesCreateModalOpen: !this.state.isMoneyCodesCreateModalOpen
        })
    }

    toggleMoneyCodesViewModal = () => {
        this.fetchMoneyCodesData()
        this.setState({
            isMoneyCodesViewModalOpen: !this.state.isMoneyCodesViewModalOpen
        })
    }

    handleDeleteCarriersFeeClick = (selectedItem, index) => {
        let carriersFeeFieldsUpdate = cloneDeep(this.state.carriersFeeFields)
        carriersFeeFieldsUpdate.splice(index, 1)

        this.setState(
            {
                carriersFeeFields: carriersFeeFieldsUpdate
            }, () => this.props.setDirty());
    }

    getDefaultDriver = () => {
        let Driver = getProp(this.props.resource, 'data.info.Driver', null)
        let DriverID = getProp(this.props.resource, 'data.info.DriverID', null)

        if (!!Driver && !!DriverID) {
            return {DriverID: DriverID, Driver: Driver}
        }

        return undefined
    }

    getDriversList = () => {
        return getProp(this.props.resource, 'data.Drivers', []).reduce((memo, it) => {
            memo[it.DriverID] = it.Driver;
            return memo
        }, {})
    }
    getDriversContactsList = () => {
        return getProp(this.props.resource, 'data.Drivers', []).reduce((memo, it) => {
            memo[it.ContactID] = it.Driver;
            return memo
        }, {})
    }

    getCarrierOrgList = () => {
        return getProp(this.props.resource, 'data.Carriers', []).reduce((memo, it) => {
            memo[it.OrganizationID] = it.Carrier;
            return memo
        }, {})
    }

    handleCancelClick = () => {
        const data = cloneDeep(getProp(this.props.resource, 'data', {}));

        const matrixApplied = getProp(this.props.resource, 'data.load.LoadPricingMatrix', null) || getProp(this.props.resource, 'data.load.CommodityPricingMatrix', null)

        this.setState({
            isBroker: data.load.IsBroker,
            billingFields: this.getBillingFields(data.load, !!matrixApplied),
            accessoryFields: this.getAccessoriesList(),
            commissionFields: this.getCommissionList(),
            driversFeeFields: this.getDriversFeeList(),
            carriersFeeFields: this.getCarriersFeeList(),
            extraChargesFields: this.getExtraChargeList(),
            driverAccessorialFields: this.getDriversAccessorialsList(),
        }, () => this.props.dirtyStateToggle())
    }

    handleSubmitClick = () => {
        let LoadAccessorialsAdjust = [];
        const LoadAccessorials = cloneDeep(getProp(this.state, 'accessoryFields', [])).reduce((memo, it) => {
            if (it.IsCreated) {
                delete it.IsCreated;
                delete it.IsAdjust;
                LoadAccessorialsAdjust.push(it);
            } else {
                memo.push(it);
            }

            return memo;
        }, []);

        const params = {
            'LoadID': this.getID(),
            'PriceBase': numberWithCommasToBack(this.state.billingFields.PriceBase.value),
            'IsCalculationPriceOverride': this.state.billingFields.IsCalculationPriceOverride.value,
            'PriceTypeID': this.state.billingFields.PriceTypeID.value,
            'Price': numberWithCommasToBack(this.state.billingFields.Price.value),
            'CalculationPrice': numberWithCommasToBack(this.state.billingFields.CalculationPrice.value),
            'IsInvoiced': this.state.billingFields.IsInvoiced.value,
            'IsPODReady': this.state.billingFields.IsPODReady.value ? 1 : 0,
            'IsAccessorialHold': this.state.billingFields.IsAccessorialHold.value ? 1 : 0,
            'IsRateConfirmed': this.state.billingFields.IsRateConfirmed.value ? 1 : 0,
            'IsHoldCarrierPay': !!this.state.carriersFeeFields.length && this.state.carriersFeeAdditionalFields.IsHoldCarrierPay.value ? 1 : 0,
            LoadPricingMatrixID: this.state.LoadPricingMatrixID,
            CommodityPricingMatrixID: this.state.CommodityPricingMatrixID,
            BillTypeID: this.state.billingFields.BillTypeID.value,
            LoadAccessorials: LoadAccessorials,
            LoadCommissions: getProp(this.state, 'commissionFields', []),
            Carriers: getProp(this.state, 'carriersFeeFields', []),
            Drivers: getProp(this.state, 'driversFeeFields', []),
            DriversAccessorials: getProp(this.state, 'driverAccessorialFields', []),
            ExtraCharges: getProp(this.state, 'extraChargesFields', []).map(extra => Object.assign({}, FieldsManager.getFieldKeyValues(extra), {
                OrganizationPayID: !extra.PayTo.value ? extra.OrganizationPayID?.value?.value : null,
                ContactPayID: extra.PayTo?.value ? extra.ContactPayID?.value?.value : null
            })),
            "PriceMilesCalculationTypeID": this.state.billingFields.PriceMilesCalculationTypeID.value,
            "CustomTotalMiles": this.state.billingFields.PriceMilesCalculationTypeID.value === 2 ? this.state.billingFields.CustomTotalMiles.value : undefined,
        }

        if (this.props.isInvoiced && LoadAccessorialsAdjust.length) {
            const accParams = {
                'LoadID': this.getID(),
                Items: LoadAccessorialsAdjust
            }

            this.props.dispatch(updateMultipleResource({
                user: LocalStorage.get('user'),
                params: [params, accParams],
                resource: [Resources.LoadPricing, Resources.LoadPricingAcc],
                piggyResource: Resources.LoadPricing,
                query: {
                    LoadID: this.getID(),
                },
                errorMessage: true,
                successMessage: 'Load Updated'
            }))
        } else {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: Resources.LoadPricing,
                piggyResource: Resources.LoadPricing,
                query: {
                    LoadID: this.getID(),
                },
                errorMessage: true,
                successMessage: 'Load Updated'
            }))
        }

        this.props.dirtyStateToggle()
    }

    handleAcceptFiles = (files) => {
        this.setState({
            files: files,
            canModalSubmit: true
        });
    }

    closePreview = () => {
        this.setState({
            filePreview: {
                show: false,
                fileType: "",
                filePath: ""
            }
        })
    }

    handleFileView = (file, hasFilePath) => {
        this.setState({
            filePreview: {
                show: true,
                fileType: file.path.split(".").pop(),
                filePath: hasFilePath
                    ? Env.getApiUrl("api/" + Resources.AccountingReimbursementDocument, Object.assign({}, {
                        ReimbursementID: this.state.selectedItem?.ReimbursementID,
                        token: getJWT().access_token,
                        date: moment(this.state.selectedItem.CreateUpdateDate, DEFAULT_DATABASE_DATETIME_FORMAT).unix(),
                    }))
                    : URL.createObjectURL(file)
            }
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchAdvancesReimbursementsData();
        })
    };

    handleFilterInputChange = (name, value) => {
        this.setState({
            advancesReimbursementQueryFields: FieldsManager.updateField(this.state.advancesReimbursementQueryFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            if (this.state.isAdvancesReimbursementsViewModalOpen) {
                this.fetchAdvancesReimbursementsData()
            } else if (this.state.isMoneyCodesViewModalOpen) {
                this.fetchMoneyCodesData();
            }
        })
    }

    /** Helpers
     =============================================================== */
    calculateValueBasedOnFee = (it, type) => {
        if (!this.state || (!it?.Amount && !it?.AmountExtra)) {
            return 0;
        }

        let Amount = it.Amount

        Amount = it.LoadAccessorialUnits ? it.LoadAccessorialUnits * Amount : Amount

        let value = Amount ? Amount : 0

        let Price = this.state.billingFields.Price.value !== undefined ? this.state.billingFields.Price.value : getProp(this.props.resource, 'data.load.Price', 0)

        if (this.state.billingFields.CalculationPrice.value) {
            Price = this.state.billingFields.CalculationPrice.value ? this.state.billingFields.CalculationPrice.value : getProp(this.props.resource, 'data.load.CalculationPrice', 0)
        }

        if (it.FeeTypeID) {
            const accessorialsValue = this.getAccessorialTotalValue();

            const totalMiles = this.getMiles();

            const emptyMiles = numberWithCommasToBack(getProp(this.state, 'emptyMiles', 0))

            const FeeTypeID = Number(it.FeeTypeID);

            switch (FeeTypeID) {
                case LOAD_PAY_METHOD_PRICE_PERCENT:
                    value = numberWithCommasToBack(Price) * (Amount / 100)
                    break;
                case LOAD_PAY_METHOD_ACC_PERCENT:
                    value = accessorialsValue * (Amount / 100)
                    break;
                case LOAD_PAY_METHOD_PRICE_ACC_PERCENT:
                    value = ((numberWithCommasToBack(Price)
                        + accessorialsValue) * (Amount / 100))
                    break;
                case LOAD_PAY_METHOD_NET_PROFIT_PERCENT: {
                    const totalIncome = numberWithCommasToBack(Price) + numberWithCommasToBack(accessorialsValue)

                    const commissionValue = type === "driver" ? this.getCommissionValue() : 0;
                    const driversFeeValue = type === "commission" ? this.getDriversFeeValue() : 0;
                    const driverAccessorialValue = type === "commission" ? this.getDriverAccessorialValue() : 0;

                    const carriersFeeValue = type === "commission" ? this.getCarriersFeeValue() : 0

                    const totalExpenses = commissionValue + driversFeeValue + driverAccessorialValue + carriersFeeValue
                    const netProfit = (totalIncome - totalExpenses) * (Amount / 100.0)

                    value = netProfit

                    break;
                }
                case LOAD_PAY_METHOD_PRICE_LOADED_MILES:
                    value = totalMiles * Amount
                    break;
                case LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES:
                    value = (totalMiles * Amount) + (emptyMiles * Number(it.AmountExtra ? it.AmountExtra : 0))
                    break;

                case LOAD_PAY_METHOD_FLAT:
                default:
                    value = Amount
            }
        }

        if (isNaN(numberWithCommasToBack(value)) || !numberWithCommasToBack(value)) {
            return 0;
        } else {
            return numberWithCommasToBack(value).toFixed(2);
        }
    }

    getAmountCalculationHtml = () => (
        <div className="p-5 md border-t border-tm-gray-300">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                </div>
                <div className="ml-5 w-0 flex-1">
                    <dl>
                        <dt className="text-sm font-medium text-tm-gray-700 truncate">
                            {this.props.translate('table.CalculatedAmount')}
                        </dt>
                        <dd>
                            <div className="text-lg font-medium text-tm-gray-900">
                                {this.state.displayValue}
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )

    getFuelMatrixCalculationHtml = () => {
        if ((!this.state.FuelSurcharge) && !this.props.secondResource.isLoading) return null

        if (this.props.secondResource.isLoading) return (<div className="p-5"><LoaderSmall/></div>)

        return (
            <div className="p-5 md border-t border-tm-gray-300">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                        </div>

                        <div className="ml-5 w-0 flex-1">
                            <dl>
                                <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                    {this.props.translate('table.CalculatedAmount')}
                                </dt>
                                <dd>
                                    <div className="text-lg font-medium text-tm-gray-900">
                                        {numberWithCommas(this.state.FuelSurcharge.Amount * this.state.FuelSurcharge.LoadAccessorialUnits)}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    getPriceMatrixCalculationHtml = () => {
        if ((!this.state.PriceType) && !this.props.secondResource.isLoading) return null

        if (this.props.secondResource.isLoading) return (<div className="p-5"><LoaderSmall/></div>)

        return (
            <div className="p-5 md border-t border-tm-gray-300">
                <div className="grid grid-cols-3">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                        </div>

                        <div className="ml-5 w-0 flex-1">
                            <dl>
                                <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                    {this.props.translate('field.BaseRate')}
                                </dt>
                                <dd>
                                    <div className="text-lg font-medium text-tm-gray-900">
                                        {numberWithCommas(this.state.PriceBase)}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <ReceiptPercentIcon className="h-6 w-6 text-tm-gray-500"/>
                        </div>

                        <div className="ml-5 w-0 flex-1">
                            <dl>
                                <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                    {this.props.translate('field.PriceType')}
                                </dt>
                                <dd>
                                    <div className="text-lg font-medium text-tm-gray-900">
                                        {this.state.PriceType}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                        </div>

                        <div className="ml-5 w-0 flex-1">
                            <dl>
                                <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                    {this.props.translate('field.CalculatedRate')}
                                </dt>
                                <dd>
                                    <div className="text-lg font-medium text-tm-gray-900">
                                        {numberWithCommas(this.calculatePriceValue(this.state.PriceBase, this.state.PriceTypeID))}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getRateValue = () => {
        let rate = this.state.billingFields.Price?.value
        if (!rate) {
            rate = 0
        }

        return numberWithCommasToBack(rate)
    }

    getCommissionValue = (skip = false) => {
        let commissionAmount = Number(Object.values(this.state.commissionFields).reduce((memo, it) => {
            if (!skip) {
                memo = memo + numberWithCommasToBack(it.AmountCalculated)
            }
            return memo
        }, 0))

        if (!commissionAmount) {
            commissionAmount = 0
        }

        return numberWithCommasToBack(commissionAmount)
    }

    getDriversFeeValue = (skip = false) => {
        let driversFeeAmount = Number(Object.values(this.state.driversFeeFields).reduce((memo, it) => {
            if (!skip) {
                memo = memo + numberWithCommasToBack(it.AmountCalculated)
            }
            return memo
        }, 0))

        if (!driversFeeAmount) {
            driversFeeAmount = 0
        }

        return numberWithCommasToBack(driversFeeAmount)
    }

    getCarriersFeeValue = (skip = false) => {
        let carriersFeeAmount = Number(Object.values(this.state.carriersFeeFields).reduce((memo, it) => {
            if (!skip) {
                memo = memo + numberWithCommasToBack(it.AmountCalculated)
            }
            return memo
        }, 0))

        if (!carriersFeeAmount) {
            carriersFeeAmount = 0
        }

        return numberWithCommasToBack(carriersFeeAmount)
    }

    getDriverAccessorialValue = (skip = false) => {
        const driverAccessorialAmount = Number(Object.values(this.state.driverAccessorialFields).reduce((memo, it) => {
            if (!skip) {
                memo = memo + numberWithCommasToBack(it.AmountCalculated)
            }
            return memo
        }, 0))
        let driverAccessorialTotal = driverAccessorialAmount

        if (!driverAccessorialTotal) {
            driverAccessorialTotal = 0
        }

        return numberWithCommasToBack(driverAccessorialTotal)
    }

    getAccessorialTotalValue = () => {
        let accessorialTotal = Number(Object.values(this.state.accessoryFields).reduce((memo, it) => {
            memo += ((it.Amount ?? 0) * (it.LoadAccessorialUnits ?? 0))
            return memo
        }, 0))

        if (!accessorialTotal) {
            accessorialTotal = 0
        }

        return numberWithCommasToBack(accessorialTotal)
    }

    updateCalculations = () => {
        let commissionFields = cloneDeep(this.state.commissionFields)
        commissionFields = commissionFields.map(commission => {
            commission.AmountCalculated = (!!commission.IsPayed && !!commission.PayrollBatchEntryID) ? commission.PayedAmount : this.calculateValueBasedOnFee(commission, "commission")
            return commission
        })
        let driversFeeFields = cloneDeep(this.state.driversFeeFields)
        driversFeeFields = driversFeeFields.map(driverFee => {
            driverFee.AmountCalculated = (!!driverFee.IsPayed && !!driverFee.PayrollBatchEntryID) ? driverFee.PayedAmount : this.calculateValueBasedOnFee(driverFee, "driver")
            return driverFee
        })
        let driverAccessorialFields = cloneDeep(this.state.driverAccessorialFields)
        driverAccessorialFields = driverAccessorialFields.map(driverAccessorial => {
            driverAccessorial.AmountCalculated = (!!driverAccessorial.IsPayed && !!driverAccessorial.PayrollBatchEntryID) ? driverAccessorial.PayedAmount : this.calculateValueBasedOnFee(driverAccessorial, "driver")
            return driverAccessorial
        })
        let carriersFeeFields = cloneDeep(this.state.carriersFeeFields)
        carriersFeeFields = carriersFeeFields.map(carriersFee => {
            carriersFee.AmountCalculated = this.calculateValueBasedOnFee(carriersFee, "carrier")
            return carriersFee
        });

        this.setState({
            commissionFields: commissionFields,
            driversFeeFields: driversFeeFields,
            driverAccessorialFields: driverAccessorialFields,
            carriersFeeFields: carriersFeeFields
        }, () => {
            let commissionFields = cloneDeep(this.state.commissionFields)
            commissionFields = commissionFields.map(commission => {
                commission.AmountCalculated = (!!commission.IsPayed && !!commission.PayrollBatchEntryID) ? commission.PayedAmount : this.calculateValueBasedOnFee(commission, "commission")
                return commission
            })
            let driversFeeFields = cloneDeep(this.state.driversFeeFields)
            driversFeeFields = driversFeeFields.map(driverFee => {
                driverFee.AmountCalculated = (!!driverFee.IsPayed && !!driverFee.PayrollBatchEntryID) ? driverFee.PayedAmount : this.calculateValueBasedOnFee(driverFee, "driver")
                return driverFee
            })
            let driverAccessorialFields = cloneDeep(this.state.driverAccessorialFields)
            driverAccessorialFields = driverAccessorialFields.map(driverAccessorial => {
                driverAccessorial.AmountCalculated = (!!driverAccessorial.IsPayed && !!driverAccessorial.PayrollBatchEntryID) ? driverAccessorial.PayedAmount : this.calculateValueBasedOnFee(driverAccessorial, "driver")
                return driverAccessorial
            })
            let carriersFeeFields = cloneDeep(this.state.carriersFeeFields)
            carriersFeeFields = carriersFeeFields.map(carriersFee => {
                carriersFee.AmountCalculated = this.calculateValueBasedOnFee(carriersFee, "carrier")
                return carriersFee
            });
            this.setState({
                commissionFields: commissionFields,
                driversFeeFields: driversFeeFields,
                driverAccessorialFields: driverAccessorialFields,
                carriersFeeFields: carriersFeeFields
            });
        })
    }

    getAmountCellSuffix = (item, field = 'Amount') => {
        const LOAD_PAY_METHOD_PRICE_PERCENT = 2
        const LOAD_PAY_METHOD_ACC_PERCENT = 3
        const LOAD_PAY_METHOD_PRICE_ACC_PERCENT = 4
        const LOAD_PAY_METHOD_NET_PROFIT_PERCENT = 5
        const LOAD_PAY_METHOD_PRICE_LOADED_MILES = 6
        const LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES = 7

        if ((field !== 'Amount') && !(item[field])) {
            return '';
        }
        if ([LOAD_PAY_METHOD_PRICE_PERCENT, LOAD_PAY_METHOD_ACC_PERCENT, LOAD_PAY_METHOD_PRICE_ACC_PERCENT, LOAD_PAY_METHOD_NET_PROFIT_PERCENT].includes(Number(item.FeeTypeID))) {
            return isNaN(item[field]) ? '' : (numberWithCommasToBack(item[field]) + ' %')
        }

        if ([LOAD_PAY_METHOD_PRICE_LOADED_MILES, LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES].includes(Number(item.FeeTypeID))) {
            return isNaN(item[field]) ? '' : (genericMoneyFormatter(numberWithCommasToBack(item[field] ?? 0)));
        }


        return genericMoneyFormatter(numberWithCommasToBack(item[field]));
    }

    getAmountFieldType = (FeeTypeID) => {
        if (!FeeTypeID) {
            return "money";
        }

        if ([
            LOAD_PAY_METHOD_PRICE_PERCENT,
            LOAD_PAY_METHOD_ACC_PERCENT,
            LOAD_PAY_METHOD_PRICE_ACC_PERCENT,
            LOAD_PAY_METHOD_NET_PROFIT_PERCENT
        ].includes(Number(FeeTypeID))) {
            return "float"
        }

        return "money";
    }

    getMiles = (typeID) => {
        switch (typeID ?? this.state.milesForCalculation) {
            case 0: //"TotalMilesNoStopBy
                return getProp(this.props.resource, 'data.info.TotalMilesNoStopBy', 0)
            case 1: //"TotalMiles":
                return getProp(this.props.resource, 'data.load.TotalMiles', 0)
            case 2: //"CustomTotalMiles":
                return this.state.billingFields.CustomTotalMiles.value;
            default:
                return getProp(this.props.resource, 'data.load.TotalMiles', 0)
        }
    }

    getProfitLossPercent = (totalIncome, totalExpense, MinimumMargin) => {
        let margin = (totalIncome - totalExpense) / totalIncome;
        let percent = (margin) * 100;
        let isBelowMinMargin = false;

        if ((!percent && percent !== 0) || percent === Infinity || margin === Infinity) {
            return {};
        }

        isBelowMinMargin = MinimumMargin >= (MinimumMargin + margin);

        if (Math.abs(percent) === Infinity) {
            percent = 0;
        }

        return {
            percent: percent.toFixed(2),
            margin: totalIncome / totalExpense,
            isBelowMinMargin: isBelowMinMargin,
        };
    }

    getOverLimitMessage = (isOverMarginLimit, isOverCreditLimit, AllowCreditLimitOverride, translate) => {
        if (isOverMarginLimit && isOverCreditLimit) {
            return translate("text.customer_credit_and_margin_over_limit", [getProp(this.props.resource, 'data.MinimumMargin', 0) * 100]);
        } else if (isOverMarginLimit) {
            return translate("text.loss_margin_over_limit", [getProp(this.props.resource, 'data.MinimumMargin', 0) * 100]);
        } else if (AllowCreditLimitOverride) {
            return translate("text.customer_is_over_credit_limit_but_allowed");
        } else {
            return translate("text.customer_is_over_credit_limit");
        }
    }

    limitPercentAmount = (fields) => {
        const isFeePercentType = Number(fields.FeeTypeID?.value) === LOAD_PAY_METHOD_ACC_PERCENT
            || Number(fields.FeeTypeID?.value) === LOAD_PAY_METHOD_PRICE_ACC_PERCENT
            || Number(fields.FeeTypeID?.value) === LOAD_PAY_METHOD_NET_PROFIT_PERCENT;

        if (isFeePercentType && numberWithCommasToBack(fields.Amount.value) > 100) {
            fields.Amount.errorMessage = ["error.amount_must_be_100_or_less"]
        } else {
            fields.Amount.errorMessage = "";
        }

        return fields
    }

    getID = () => {
        return this.props.match.params.id
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.advancesReimbursementQueryFields),
            searchFields: JSON.stringify({
                LoadID: this.getID()
            }),
        }
    }

    getDriverContactID = () => {
        let driverContactID = getProp(this.props.resource, 'data.Drivers', []).reduce((memo, it) => {
            memo['ContactID'] = it.ContactID;
            return memo
        }, {});

        return driverContactID.ContactID
    }

    render() {
        const {translate} = this.props
        const isLoadInternational = getProp(this.props.resource, 'data.info.LoadType', "") === "International";

        const rate = this.getRateValue()
        const accessorialTotal = this.getAccessorialTotalValue()
        const totalIncome = (rate + accessorialTotal)

        const commissionValue = this.getCommissionValue()
        const driversFeeValue = this.getDriversFeeValue()
        const driverAccessorialValue = this.getDriverAccessorialValue()

        const carriersFeeValue = this.getCarriersFeeValue()

        // TODO: Remove percentage part
        const totalExpense = (commissionValue + driversFeeValue + driverAccessorialValue + carriersFeeValue)

        const fullMiles = this.state.totalMiles
        const emptyMiles = this.state.emptyMiles
        const TotalMilesNoStopBy = this.state.TotalMilesNoStopBy
        const totalMiles = (fullMiles + emptyMiles)

        const totalValue = (totalIncome - totalExpense)

        const altPopupWidthClass = 'max-w-xl w-96'

        const CustomerStats = getProp(this.props.resource, 'data.CustomerStats', {});

        const DriversList = this.getDriversList()

        const DriversContactList = this.getDriversContactsList();

        const CarriersOrgList = this.getCarrierOrgList();

        const CreditLimit = Number(CustomerStats?.CreditLimit ?? 0);
        const CreditLimitOtherActiveLoadsCost = Number(CustomerStats?.OtherActiveLoadsCost ?? 0);

        const TotalOpenInvoices = CustomerStats?.Income?.TotalOpenInvoices ?? 0;
        const CreditLimitAvailable = CreditLimit - CreditLimitOtherActiveLoadsCost - TotalOpenInvoices;

        const MinimumMargin = getProp(this.props.resource, 'data.MinimumMargin', {});
        const AllowCreditLimitOverride = !!getProp(this.props.resource, 'data.CustomerStats.AllowCreditLimitOverride', 0);
        const AllowMarginOverride = getProp(this.props.resource, 'data.CustomerStats.AllowMarginOverride', 0);
        const profitLossExtra = this.getProfitLossPercent(totalIncome, totalExpense, MinimumMargin);

        const isCreditOnHold = !!getProp(this.props.resource, 'data.customer.Customer.CreditHold', 0);

        const isOverMarginLimit = AllowMarginOverride ? false : profitLossExtra.isBelowMinMargin;
        const isOverCreditLimit = CreditLimitAvailable < (totalIncome ?? 0);

        const advancesReimbursementData = getProp(this.props.secondResource, 'data.list', [])
        const advancesReimbursementCount = getProp(this.props.secondResource, 'data.count', 0)
        const advancesReimbursementIsLoading = getProp(this.props.secondResource, 'isLoading', false)

        const stats = [
            {
                id: 2,
                name: translate('text.income'),
                icon: ArrowTrendingUpIcon,
                iconColorClass: 'text-green-700',
                value: genericMoneyFormatter(totalIncome.toFixed(2)),
                more: [
                    {
                        id: 1,
                        name: translate('text.Rate'),
                        amount: genericMoneyFormatter(rate.toFixed(2)),
                        icon: ArrowTrendingUpIcon
                    },
                    {
                        id: 2,
                        name: translate('text.accessoryValue'),
                        amount: genericMoneyFormatter(accessorialTotal.toFixed(2)),
                        icon: ArrowTrendingUpIcon
                    },
                ]
            },
            {
                id: 3,
                name: translate('text.expenses'),
                icon: ArrowTrendingDownIcon,
                iconColorClass: 'text-red-700',
                value: genericMoneyFormatter(totalExpense.toFixed(2)),
                more: [
                    {
                        id: 7,
                        name: translate('text.commissionValue'),
                        amount: genericMoneyFormatter(commissionValue.toFixed(2)),
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 6,
                        name: translate('text.driversFeeValue'),
                        amount: genericMoneyFormatter(driversFeeValue.toFixed(2)),
                        hidden: this.state.isBroker,
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 6,
                        name: translate('text.carriersFeeValue'),
                        amount: genericMoneyFormatter(carriersFeeValue.toFixed(2)),
                        hidden: !this.state.isBroker,
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 8,
                        name: translate('text.driver_accessorials'),
                        hidden: this.state.isBroker,
                        amount: genericMoneyFormatter(driverAccessorialValue.toFixed(2)),
                        icon: ArrowTrendingDownIcon
                    },
                ]
            },
            {
                id: 1,
                name: translate('text.profitAndLoss'),
                icon: ScaleIcon,
                iconColorClass: 'text-yellow-700',
                value: genericMoneyFormatter(totalValue.toFixed(2)),
                widthClass: altPopupWidthClass,
                valueExtra: !!Object.keys(profitLossExtra).length
                    && Number(profitLossExtra.percent) !== 0
                    && <span className={
                        classNames(
                            profitLossExtra.percent > 0 && !profitLossExtra.isBelowMinMargin ? "text-green-600" : undefined,
                            profitLossExtra.percent > 0 && profitLossExtra.isBelowMinMargin ? "text-yellow-600" : undefined,
                            profitLossExtra.percent < 0 ? "text-red-600" : undefined,
                            "inline-flex space-x-2 ml-2"
                        )}
                    >
                    <div>{"(" + profitLossExtra.percent + "% )"}</div>

                        {
                            profitLossExtra.percent < 0 && (
                                <ExclamationTriangleIcon className="w-5 h-5"/>
                            )
                        }

                        {
                            profitLossExtra.percent > 0 && profitLossExtra.isBelowMinMargin && (
                                <Tippy
                                    content={translate("text.pricing_minimum_margin_warning", [MinimumMargin * 100])}>
                                    <ExclamationTriangleIcon className="w-5 h-5"/>
                                </Tippy>
                            )
                        }
                </span>,
                positionClass: "z-50 px-4 mt-3 transform sm:px-0 w-screen max-w-xs absolute xl:right-0 opacity-100 left-10 lg:left-0 xl:left-auto xl:right-0 -translate-x-full lg:-translate-x-1/2 xl:translate-x-0 xl:translate-y-0",
                more: [
                    {
                        id: 1,
                        name: translate('text.Rate'),
                        amount: genericMoneyFormatter(rate.toFixed(2)),
                        icon: ArrowTrendingUpIcon
                    },


                    {
                        id: 2,
                        name: translate('text.accessoryValue'),
                        amount: genericMoneyFormatter(accessorialTotal.toFixed(2)),
                        icon: ArrowTrendingUpIcon
                    },
                    {
                        id: 6,
                        name: translate('text.carriersFeeValue'),
                        amount: genericMoneyFormatter(carriersFeeValue.toFixed(2)),
                        hidden: !this.state.isBroker,
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 4,
                        name: translate('text.total_income'),
                        amount: genericMoneyFormatter(totalIncome),
                        labelClass: 'pl-5 flex flex-col text-green-500 text-sm font-bold truncate',
                        valueClass: 'pl-7 text-green-600 font-bold',
                        icon: ArrowTrendingUpIcon
                    }
                ],
                "more-col-2": [

                    {
                        id: 7,
                        name: translate('text.commissionValue'),
                        amount: genericMoneyFormatter(commissionValue.toFixed(2)),
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 6,
                        name: translate('text.driversFeeValue'),
                        amount: genericMoneyFormatter(driversFeeValue.toFixed(2)),
                        hidden: this.state.isBroker,
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 8,
                        name: translate('text.driver_accessorials'),
                        amount: genericMoneyFormatter(driverAccessorialValue.toFixed(2)),
                        hidden: this.state.isBroker,
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 8,
                        name: translate('text.total_expense'),
                        amount: genericMoneyFormatter(totalExpense),
                        labelClass: 'pl-5 flex flex-col text-red-500 text-sm font-bold truncate',
                        valueClass: 'pl-7 text-red-600 font-bold',
                        icon: ArrowTrendingDownIcon
                    }
                ]
            },

            {
                id: 4,
                name: translate('text.mileage'),
                icon: TruckIcon,
                value: "Loaded: " + (returnMileage(TotalMilesNoStopBy)),
                decimalCount: 0,
                valueExtra: " (Total: " + returnMileage(totalMiles) + ")",
                hidden: isLoadInternational,
                more: [
                    {
                        id: 1,
                        name: translate('text.LoadedMiles'),
                        amount: returnMileage(fullMiles),
                        icon: CubeIcon
                    },
                    {
                        id: 2,
                        name: translate('text.EmptyMiles'),
                        amount: returnMileage(emptyMiles),
                        icon: CubeTransparentIcon
                    },
                    {
                        id: 3,
                        name: translate('text.TotalMilesNoStopBy'),
                        amount: returnMileage(TotalMilesNoStopBy),
                        icon: MapPinIcon
                    },
                    {
                        id: 4,
                        name: translate('text.TotalWeight'),
                        amount: numberWithCommas(getProp(this.props.resource, 'data.load.TotalWeight', 0)) + ' lbs',
                        icon: ScaleIcon
                    },
                    {
                        id: 5,
                        name: translate('text.TotalVolume'),
                        amount: numberWithCommas(getProp(this.props.resource, 'data.load.TotalVolume', 0)) + ' feet(3)',
                        icon: Squares2X2Icon
                    },
                    {
                        id: 6,
                        name: translate('text.TotalPieces'),
                        amount: numberWithCommas(getProp(this.props.resource, 'data.load.TotalPieces', 0)),
                        icon: CubeTransparentIcon
                    },

                ]
            },
        ]

        const metadata = {
            AccessorialID: {
                api: 'api/' + Resources.AccessorialQuick,
                query: {
                    CustomerID: this.state.CustomerID,
                    offset: 0,
                    limit: 50
                },
                searchMap: (item) => ({
                    value: item.AccessorialID,
                    label: item.Accessorial,
                    metaData: item
                })
            },
            DriverAccessorialID: {
                api: 'api/' + Resources.AccessorialQuick,
                query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                    limit: 50,
                    IsPayDriver: 1,
                    CustomerID: this.state.CustomerID
                }),
                searchMap: (item) => ({
                    value: item.AccessorialID,
                    label: item.Accessorial,
                    metaData: item
                })
            },
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + ' ' + item.LastName,
                    metaData: {
                        FeeTypeID: item?.Commissions?.FeeTypeID,
                        Amount: item?.Commissions?.Amount,
                        LoadCommissionNotes: item?.Commissions?.LoadCommissionNotes,
                    }
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.Organizations,
                query: {offset: 0, limit: 50},
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName
                })
            },
            CarrierID: {
                api: 'api/' + Resources.CarriersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY,
                searchMap: (item) => ({
                    value: item.CarrierID,
                    label: item.LegalName,
                    metaData: item
                })
            },
            LoadPricingMatrixID: {
                api: 'api/' + Resources.LoadPricingMatrix,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY,
                searchMap: (item) => ({
                    value: item.LoadPricingMatrixID,
                    label: item.MatrixName,
                    metaData: item
                })
            },
            CommodityPricingMatrixID: {
                api: 'api/' + Resources.CommodityPricingMatrix,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY,
                searchMap: (item) => ({
                    value: item.CommodityPricingMatrix,
                    label: item.MatrixName,
                    metaData: item
                })
            },
            FuelSurchargeMatrixID: {
                api: 'api/' + Resources.FuelSurchargeMatrix,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY,
                searchMap: (item) => ({
                    value: item.FuelSurchargeMatrixID,
                    label: item.FuelSurchargeMatrix,
                    metaData: item
                })
            },
            FeeTypeID: this.feeTypes
        }

        const hasEFSIntegration = getProp(this.props.resource.data, 'integrations.EFS.hasIntegration', 0)

        return (
            <React.Fragment>
                {!!this.props.resource.isLoading && (
                    <div className="mb-3">
                        <Card>
                            <div className="py-5 text-center">
                                <LoaderSmall/>
                            </div>
                        </Card>
                    </div>
                )}

                {!this.props.resource.isLoading && (
                    <React.Fragment>
                        <div
                            className={
                                classNames(
                                    "grid grid-cols-1 gap-4 mb-4",
                                    this.props.isSidebarVisible
                                        ? "3xl:grid-cols-4 xl:grid-cols-2"
                                        : "xl:grid-cols-4 lg:grid-cols-2"
                                )
                            }
                        >
                            <WidgetStats
                                stats={stats}
                                popoverWidth=""
                            />
                        </div>

                        <div
                            className={
                                classNames(
                                    "grid grid-cols-1 gap-4 mb-4",
                                    this.props.isSidebarVisible
                                        ? "3xl:grid-cols-4"
                                        : "xl:grid-cols-4"
                                )
                            }
                        >
                            <div className="col-span-2 space-y-4">
                                {checkPerm(Resources.AccountingSummary, READ_PERM)
                                    && !!Object.keys(CustomerStats).length
                                    && (
                                        <LoadPricingCustomerInfo
                                            AllowCreditLimitOverride={AllowCreditLimitOverride}
                                            CustomerStats={CustomerStats}
                                            CreditLimitAvailable={CreditLimitAvailable}
                                            CustomerID={getProp(this.props.resource, 'data.info.CustomerID', {})}
                                            OrganizationID={getProp(this.props.resource, 'data.info.OrganizationID', {})}
                                            isOverCreditLimit={isOverCreditLimit}
                                            CreditLimit={CreditLimit}
                                            CreditLimitOtherActiveLoadsCost={CreditLimitOtherActiveLoadsCost}
                                            isCreditOnHold={isCreditOnHold}
                                            translate={translate}
                                        />
                                    )}

                                <LoadBilling
                                    editMode={this.getID()}
                                    LoadStatusID={this.state.LoadStatusID}
                                    fields={this.state.billingFields}
                                    onHandleInputChange={this.handleBillingInputChange}
                                    onSetPriceMatrixClick={this.toggleSetPriceMatrixModal}
                                    onCustomerSettingsDialogClick={this.toggleCustomerSettingsDialog}
                                    translate={translate}
                                    IsInvoiced={this.props.isInvoiced}
                                    matrixApplied={this.state.matrixApplied}
                                    onRemoveSelectedMatrix={this.handleRemoveSelectedMatrix}
                                />

                                <LoadPricingAccessorials
                                    getFields={this.getAccessorialFields}
                                    fields={this.state.accessoryFields}

                                    toggleCreateDialog={this.toggleCreateAccessorialDialog}
                                    toggleViewDialog={(it, i) => this.toggleEditAccessorialDialog(it, i, true)}
                                    toggleEditDialog={this.toggleEditAccessorialDialog}
                                    handleDeleteClick={this.handleDeleteAccessorialClick}

                                    onSetDefaultAccesorialClick={this.toggleDefaultAccesorialModal}

                                    translate={translate}
                                    isInvoiced={this.props.isInvoiced}
                                />

                                {!!this.props.isInvoiced && (
                                    <LoadPricingAccessorials
                                        title={translate('text.accessorials_adjustments')}
                                        getFields={this.getAccessorialFields}
                                        fields={this.state.accessoryFields}

                                        toggleCreateDialog={this.toggleCreateAccessorialDialog}
                                        toggleViewDialog={(it, i) => this.toggleEditAccessorialDialog(it, i, true)}
                                        toggleEditDialog={this.toggleEditAccessorialDialog}
                                        handleDeleteClick={this.handleDeleteAccessorialClick}

                                        onSetDefaultAccesorialClick={this.toggleDefaultAccesorialModal}

                                        translate={translate}
                                        isInvoiced={this.props.isInvoiced}
                                        isAdjustmentTable={true}
                                    />
                                )}
                            </div>

                            <div className="col-span-2 space-y-4">


                                <div className="">
                                    <LoadPricingCommission
                                        getFields={this.getCommissionFields}
                                        fields={this.state.commissionFields}

                                        toggleCreateDialog={this.toggleCreateCommissionDialog}
                                        toggleEditDialog={this.toggleEditCommissionDialog}
                                        toggleViewDialog={(it, i) => this.toggleEditCommissionDialog(it, i, true)}
                                        handleDeleteClick={this.handleDeleteCommissionClick}

                                        translate={translate}
                                    />
                                </div>

                                <div className="">
                                    {!this.state.isBroker && (
                                        <LoadPricingDriversFee
                                            getFields={this.getDriversFeeFields}
                                            fields={this.state.driversFeeFields}

                                            toggleCreateDialog={this.toggleCreateDriversFeeModal}
                                            toggleEditDialog={this.toggleEditDriversFeeDialog}
                                            toggleViewDialog={(it, i) => this.toggleEditDriversFeeDialog(it, i, true)}
                                            handleDeleteClick={this.handleDeleteDriversFeeClick}

                                            translate={translate}
                                        />
                                    )}

                                    {!!this.state.isBroker && (
                                        <LoadPricingCarriersFee
                                            getFields={this.getCarriersFeeFields}
                                            fields={this.state.carriersFeeFields}

                                            showAdditionalFields={!!this.state.carriersFeeFields.length}
                                            additionalFields={this.state.carriersFeeAdditionalFields}
                                            onAdditionalFieldsChange={this.handleCarriersFeeAdditionalFieldsChange}

                                            toggleCreateDialog={this.toggleCreateCarriersFeeModal}
                                            toggleViewDialog={(it, i) => this.toggleEditCarriersFeeDialog(it, i, true)}
                                            toggleEditDialog={this.toggleEditCarriersFeeDialog}
                                            handleDeleteClick={this.handleDeleteCarriersFeeClick}

                                            translate={translate}
                                        />
                                    )}

                                    {!this.state.isBroker && (
                                        <LoadPricingDriverAccessorials
                                            getFields={this.getDriversAccessorialFields}
                                            fields={this.state.driverAccessorialFields}

                                            toggleCreateDialog={this.toggleCreateDriverAccessorialDialog}
                                            toggleViewDialog={(it, i) => this.toggleEditDriversFeeDialog(it, i, true)}
                                            toggleEditDialog={this.toggleEditDriverAccessorialDialog}
                                            handleDeleteClick={this.handleDeleteDriverAccessorialClick}

                                            translate={translate}
                                        />
                                    )}

                                    {!this.state.isBroker && (
                                        <LoadPricingAdvanceReimbursement
                                            toggleCreateDialog={this.toggleAdvancesReimbursementsCreateModal}
                                            toggleViewDialog={this.toggleAdvancesReimbursementsViewModal}
                                            translate={translate}
                                        />
                                    )}

                                    {!!hasEFSIntegration && (
                                        <LoadPricingMoneyCodes
                                            toggleCreateDialog={this.toggleMoneyCodesCreateModal}
                                            toggleViewDialog={this.toggleMoneyCodesViewModal}
                                            translate={translate}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>


                        <PageFooter
                            translate={translate}
                            hasPerm={checkPerm(Resources.LoadPricing, UPDATE_PERM)}
                            canSubmit={this.props.isStateDirty && !isOverMarginLimit && !(!AllowCreditLimitOverride && isOverCreditLimit)}
                            actionCancel={this.handleCancelClick}
                            actionSubmit={this.handleSubmitClick}
                            htmlBefore={
                                <React.Fragment>
                                    {!isCreditOnHold && (isOverMarginLimit || (isOverCreditLimit && CreditLimit)) &&
                                        <InfoParagraph type={AllowCreditLimitOverride ? "warning" : "danger"}>
                                            <p>
                                                {this.getOverLimitMessage(isOverMarginLimit, isOverCreditLimit, AllowCreditLimitOverride, translate)}
                                            </p>
                                        </InfoParagraph>
                                    }
                                </React.Fragment>
                            }
                        />
                    </React.Fragment>
                )}

                <ModalSaveResource
                    title={this.state.selectedItemIndex !== undefined ? translate('text.edit_driver_accessory') : translate('text.create_driver_accessory')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.isDriverAccessorialDialogOpen}
                    errorMessage={this.state.errorMessage} // rename to errMessage because it's reserved keyword
                    onClose={this.toggleEditDriverAccessorialDialog}
                    fields={excludeFields(this.getDriversAccessorialFields(this.state.selectedItem, false, this.state.isViewMode), ['AmountCalculated'])}
                    handleInputChange={this.handleDriverAccessorialChange}
                    onSubmit={(params, fields) => {
                        if (params) {
                            Object.assign(params, Fields.getFieldSelectValues(fields, {FeeTypeID: metadata['FeeTypeID']}))

                            params.Amount = params.Deduction ? -Math.abs(params.Amount) : Math.abs(params.Amount)

                            let driverAccessorialFieldsUpdate = cloneDeep(this.state.driverAccessorialFields)

                            if (this.state.selectedItemIndex !== undefined) {
                                driverAccessorialFieldsUpdate.splice(this.state.selectedItemIndex, 1, params)
                            } else {
                                driverAccessorialFieldsUpdate.push(params)
                            }

                            this.setState({
                                driverAccessorialFields: driverAccessorialFieldsUpdate
                            })

                            this.toggleEditDriverAccessorialDialog()
                            this.props.setDirty()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        AccessorialID: metadata['DriverAccessorialID'],
                        DriverID: metadata['DriverID'],
                        FeeTypeID: metadata['FeeTypeID']
                    }}
                    htmlBefore={(
                        <>
                            {this.state.isViewMode && (
                                <InfoBar addClass="rounded-none px-6">
                                    {translate("text.transaction_is_processed")}
                                </InfoBar>
                            )}
                        </>
                    )}
                    htmlAfter={this.getAmountCalculationHtml()}
                />

                <ModalSaveResource
                    title={this.state.selectedItem ? translate('dialog_heading.edit_driversFee') : translate('dialog_heading.add_driversFee')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.isDriversFeeModalOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={this.toggleEditDriversFeeDialog}
                    fields={excludeFields(this.getDriversFeeFields(this.state.selectedItem, false, this.state.isViewMode), ['AmountCalculated'])}
                    handleInputChange={this.handleDriversFeeChange}
                    onSubmit={(params, fields) => {
                        if (params) {
                            Object.assign(params, Fields.getFieldSelectValues(fields, {FeeTypeID: metadata['FeeTypeID']}))

                            let driversFeeFieldsUpdate = cloneDeep(this.state.driversFeeFields)

                            if (this.state.selectedItemIndex !== undefined) {
                                driversFeeFieldsUpdate.splice(this.state.selectedItemIndex, 1, params)
                            } else {
                                driversFeeFieldsUpdate.push(params)
                            }

                            this.setState({
                                driversFeeFields: driversFeeFieldsUpdate
                            })

                            this.toggleEditDriversFeeDialog()
                            this.props.setDirty()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        DriverID: metadata['DriverID'],
                        FeeTypeID: metadata['FeeTypeID']
                    }}
                    htmlBefore={(
                        <>
                            {this.state.isViewMode && (
                                <InfoBar addClass="rounded-none px-6">
                                    {translate("text.transaction_is_processed")}
                                </InfoBar>
                            )}
                        </>
                    )}
                    htmlAfter={(
                        <div className="p-5 md border-t border-tm-gray-300">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                            {translate('table.CalculatedAmount')}
                                        </dt>
                                        <dd>
                                            <div className="text-lg font-medium text-tm-gray-900">
                                                {this.state.displayValue}
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    )}
                />

                <ModalSaveResource
                    title={this.state.selectedItemIndex !== undefined ? translate('dialog_heading.edit_carrierFee') : translate('dialog_heading.add_carrierFee')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.isCarriersFeeModalOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={this.toggleEditCarriersFeeDialog}
                    fields={excludeFields(this.getCarriersFeeFields(this.state.selectedItem, this.state.isViewMode), ['AmountCalculated'])}
                    handleInputChange={this.handleDriversFeeChange}
                    onSubmit={(params, fields) => {
                        if (params && !fields.Amount.errorMessage.length) {
                            Object.assign(params, Fields.getFieldSelectValues(fields, {
                                FeeTypeID: metadata['FeeTypeID'],
                                ContactID: metadata['DriverID'],
                                CarrierID: metadata['CarrierID']
                            }))

                            let carriersFeeFieldsUpdate = cloneDeep(this.state.carriersFeeFields)

                            if (this.state.selectedItemIndex !== undefined) {
                                carriersFeeFieldsUpdate.splice(this.state.selectedItemIndex, 1, params)
                            } else {
                                carriersFeeFieldsUpdate.push(params)
                            }

                            this.setState({
                                carriersFeeFields: carriersFeeFieldsUpdate
                            })

                            this.toggleEditCarriersFeeDialog();
                            this.props.setDirty()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        AccessorialID: metadata['AccessorialID'],
                        FeeTypeID: metadata['FeeTypeID'],
                        CarrierID: metadata['CarrierID']
                    }}
                    htmlAfter={this.getAmountCalculationHtml()}
                />

                <ModalSaveResource
                    title={this.state.selectedItemIndex !== undefined ? translate('text.edit_accessorial') : translate('text.create_accessorial')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.isAccessorialDialogOpen}
                    initialFocusRef={this.accessorialsDialogFocusRef}
                    errorMessage={this.state.errorMessage}
                    onClose={this.toggleEditAccessorialDialog}
                    fields={excludeFields(this.getAccessorialFields(this.state.selectedItem, this.state.isViewMode), ['AmountCalculated'])}
                    handleInputChange={this.handleAccessorialChange}

                    onSubmit={(params, fields) => {
                        if (params) {
                            // Submit when it's not invoiced
                            params.Amount = params.Deduction ? -Math.abs(params.Amount) : Math.abs(params.Amount)

                            Object.assign(params, Fields.getFieldSelectValues(fields, {
                                AccessorialID: metadata['AccessorialID'],
                                DriverID: metadata['DriverID'],
                                FeeTypeID: metadata['FeeTypeID']
                            }))

                            params.AmountCalculated = params.Amount * (params.LoadAccessorialUnits ?? 1)

                            let accessoryFieldsUpdate = cloneDeep(this.state.accessoryFields)

                            if (this.state.selectedItemIndex !== undefined) {
                                accessoryFieldsUpdate.splice(this.state.selectedItemIndex, 1, params)
                            } else {
                                accessoryFieldsUpdate.push(params)
                            }

                            let driverAccessorialFields = cloneDeep(this.state.driverAccessorialFields)

                            if (!!params.IsPayDriver && !this.state.selectedItem) {
                                let driverParams = {
                                    AccessorialID: params.AccessorialID,
                                    Accessorial: params.Accessorial,
                                    DriverID: params.DriverID,
                                    Driver: params.Driver,
                                    FeeTypeID: params.FeeTypeID,
                                    Amount: numberWithCommasToBack(params.PayDriverAmount),
                                }

                                driverAccessorialFields.push(driverParams)
                            }

                            if (this.props.isInvoiced) {
                                params.IsAdjust = true;
                                params.IsCreated = true;
                            }

                            this.setState({
                                accessoryFields: accessoryFieldsUpdate,
                                driverAccessorialFields: driverAccessorialFields,
                            }, this.updateCalculations)

                            this.toggleEditAccessorialDialog()
                            this.props.setDirty()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        AccessorialID: metadata['AccessorialID'],
                        DriverID: metadata['DriverID'],
                        FeeTypeID: metadata['FeeTypeID']
                    }}
                />

                <ModalSaveResource
                    title={this.state.selectedItem ? translate('text.edit_commission') : translate('text.create_commission')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-1"
                    show={this.state.isCommissionDialogOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={this.toggleEditCommissionDialog}
                    fields={excludeFields(this.getCommissionFields(this.state.selectedItem, this.state.isViewMode), ['AmountCalculated'])}
                    handleInputChange={this.handleCommissionInputChange}
                    onSubmit={(params, fields) => {
                        if (params && !fields.Amount.errorMessage.length) {
                            if (params.PayTo) {
                                delete params.PayTo;
                            }

                            Object.assign(params, Fields.getFieldSelectValues(fields, {FeeTypeID: metadata['FeeTypeID']}))

                            let commissionFieldsUpdate = cloneDeep(this.state.commissionFields)

                            if (this.state.selectedItemIndex !== undefined) {
                                commissionFieldsUpdate.splice(this.state.selectedItemIndex, 1, params)
                            } else {
                                commissionFieldsUpdate.push(params)
                            }

                            this.setState({
                                commissionFields: commissionFieldsUpdate
                            })

                            this.toggleEditCommissionDialog()
                            this.props.setDirty()
                        }
                    }}
                    metadata={{
                        ContactID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {
                                limit: 10,
                                offset: 0,
                                IncludeStatuses: 1
                            },
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + ' ' + item.LastName,
                                metaData: {
                                    FeeTypeID: item?.Commissions?.FeeTypeID,
                                    Amount: item?.Commissions?.Amount,
                                    LoadCommissionNotes: item?.Commissions?.LoadCommissionNotes,
                                }
                            })
                        },
                        OrganizationID: metadata['OrganizationID'],
                        FeeTypeID: metadata['FeeTypeID']
                    }}
                    htmlAfter={this.getAmountCalculationHtml()}

                    translate={this.props.translate}
                />

                <ModalSaveResource
                    title={translate('dialog_heading.default_accesorial_modal')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.isDefaultAccesorialModalOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={this.toggleDefaultAccesorialModal}
                    fields={{
                        FuelSurchargeMatrixID: new Field('FuelSurchargeMatrixID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                    }}
                    handleInputChange={this.handleFuelMatrixInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            let accessoryFieldsUpdate = cloneDeep(this.state.accessoryFields);

                            let FuelSurcharge = this.state.FuelSurcharge;
                            FuelSurcharge.AmountCalculated = numberWithCommasToBack(FuelSurcharge.LoadAccessorialUnits) * numberWithCommasToBack(FuelSurcharge.Amount);

                            accessoryFieldsUpdate.push(FuelSurcharge)

                            this.setState({
                                isDefaultAccesorialModalOpen: false,
                                accessoryFields: accessoryFieldsUpdate,
                                FuelSurcharge: FuelSurcharge
                            }, () => {
                                this.props.dispatch(pushNotification({
                                    title: this.props.translate('text.fuel_surcharge_added'),
                                    timeout: 4000,
                                }))
                            })

                            this.props.setDirty()
                        }
                    }}
                    metadata={{
                        FuelSurchargeMatrixID: metadata['FuelSurchargeMatrixID'],
                    }}
                    htmlAfter={this.getFuelMatrixCalculationHtml()}

                    translate={this.props.translate}
                />

                <ModalSaveResource
                    title={translate('dialog_heading.set_pricing_matrix')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.isSetPriceMatrixModalOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={this.toggleSetPriceMatrixModal}
                    fields={{
                        MatrixTypeID: new Field('MatrixTypeID', 1, ['empty'], false, 'button-group', {
                            data: {
                                1: translate('text.load_pricing_matrix'),
                                2: translate('text.commodity_pricing_matrix')
                            }, addContainerClass: 'col-span-full'
                        }),
                        LoadPricingMatrixID: new Field('LoadPricingMatrixID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                        CommodityPricingMatrixID: new Field('CommodityPricingMatrixID', '', [''], false, 'hidden', {addContainerClass: 'col-span-full'}),
                    }}
                    handleInputChange={this.handlePricingMatrixInputChange}
                    onSubmit={(params, fields) => {
                        if (params) {
                            let billingFieldsUpdate = cloneDeep(this.state.billingFields)

                            let matrixName = ''
                            if (params.MatrixTypeID === 1) {
                                matrixName = fields.LoadPricingMatrixID.value.metaData.MatrixName
                            } else {
                                matrixName = fields.CommodityPricingMatrixID.value.metaData.MatrixName
                            }

                            this.oldPriceBase = billingFieldsUpdate.PriceBase.value
                            this.oldPriceTypeID = billingFieldsUpdate.PriceTypeID.value

                            billingFieldsUpdate.PriceBase.value = this.state.PriceBase
                            billingFieldsUpdate.PriceBase.disabled = true
                            billingFieldsUpdate.PriceTypeID.value = this.state.PriceTypeID
                            billingFieldsUpdate.PriceTypeID.disabled = true
                            billingFieldsUpdate.Price.value = this.calculatePriceValue(billingFieldsUpdate.PriceBase.value, billingFieldsUpdate.PriceTypeID.value)

                            if (!billingFieldsUpdate.IsCalculationPriceOverride.value) {
                                billingFieldsUpdate.CalculationPrice.value = billingFieldsUpdate.Price.value
                            }

                            this.setState({
                                isSetPriceMatrixModalOpen: false,
                                billingFields: billingFieldsUpdate,
                                matrixApplied: matrixName,
                                CommodityPricingMatrixID: fields.CommodityPricingMatrixID?.value?.value,
                                LoadPricingMatrixID: fields.LoadPricingMatrixID?.value?.value
                            }, () => {
                                this.props.dispatch(pushNotification({
                                    title: this.props.translate('text.matrix_applied', [matrixName]),
                                    messageDuration: 4
                                }));

                                this.updateCalculations();
                            })

                            this.props.setDirty()
                        }
                    }}
                    metadata={{
                        LoadPricingMatrixID: metadata['LoadPricingMatrixID'],
                        CommodityPricingMatrixID: metadata['CommodityPricingMatrixID'],
                    }}
                    htmlAfter={this.getPriceMatrixCalculationHtml()}

                    translate={this.props.translate}
                />

                <ModalSaveResource
                    isLoading={this.props.secondResource.isLoading}
                    show={this.state.isMoneyCodesCreateModalOpen}
                    title={translate('text.create_new_money_code')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.toggleMoneyCodesCreateModal}
                    fields={this.getMoneyCodeFields(getProp(this.props.resource, 'data.info', {}))}
                    metadata={{
                        IssuedToID: DriversContactList,
                        IssuedToOrganizationID: CarriersOrgList,
                        MoneyCodeTypeID: {
                            api: 'api/' + Resources.MoneyCodeType,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.MoneyCodeTypeID,
                                label: item.Reason
                            })
                        },
                        LoadNumber: {
                            api: 'api/' + Resources.LoadsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (it) => ({
                                label: it.LoadNumber,
                                value: it.LoadID
                            })
                        },
                    }}
                    handleInputChange={this.handleMoneyCodesChange}

                    onSubmit={(params) => {
                        if (params) {
                            params.LoadID = params.LoadNumber;
                            this.props.dispatch(createSecondResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: {
                                    LoadID: this.getID()
                                },
                                resource: Resources.LoadMoneyCodes,
                                piggyResource: Resources.LoadMoneyCodes,
                                errorMessage: true,
                                successMessage: this.props.translate('message.entry_added'),
                                onResultCallback: (result) => {
                                    if (result && result.data && result.data.code) {
                                        this.setState({
                                            isReceivedCodeModalOpen: result.data.code
                                        })
                                    }
                                    this.toggleMoneyCodesCreateModal();
                                }
                            }))
                        }
                    }}

                    htmlBefore={
                        <React.Fragment>
                            <InfoParagraph type="info" className={"p-2"}>
                                {"Issuing money code will contact EFS directly and will show code number after action is complete. Codes issued for this load can be viewed after, and voided if necessary."}
                            </InfoParagraph>
                        </React.Fragment>
                    }
                    translate={translate}
                />

                <ModalDefault
                    show={!!this.state.isMoneyCodesViewModalOpen}
                    widthClass={'max-w-7xl w-screen'}
                    title={translate('page.heading.MoneyCodes')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={this.toggleMoneyCodesViewModal}
                >
                    <Card>
                        <ActiveFilters
                            filterFields={this.state.advancesReimbursementQueryFields}
                            onLabelClick={this.handleFilterInputChange}
                            translate={translate}
                        />
                        <TableCard>
                            <TableFilters
                                filterFields={this.state.advancesReimbursementQueryFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                                hideLimit
                            />
                            <ResourceTable
                                addClass="rounded-card"
                                data={advancesReimbursementData}
                                count={advancesReimbursementCount}

                                fields={this.state.moneyCodeFields}
                                translate={translate}
                                isLoading={advancesReimbursementIsLoading}
                            />

                            {/*Table footer*/}
                            {!(!advancesReimbursementData.length && !advancesReimbursementIsLoading) && (
                                <div
                                    className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                >
                                    <Pagination
                                        count={advancesReimbursementCount}
                                        isLoading={advancesReimbursementIsLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === "offset"
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.advancesReimbursementQueryFields}
                                        translate={translate}
                                    />
                                </div>
                            )}

                            <NoRecordsTable
                                show={(advancesReimbursementData.length === 0) && !advancesReimbursementIsLoading}
                                canCreate={false}
                                title={translate("text.no_matching_records")}
                            />
                        </TableCard>
                    </Card>
                </ModalDefault>

                <ModalDefault
                    baseClass={"fixed inset-0 flex items-center justify-center"}
                    show={!!this.state.isReceivedCodeModalOpen}
                    widthClass={"max-w-xl"}
                    translate={translate}
                    title={translate('Money code')}
                    onClose={() => this.setState({
                        isReceivedCodeModalOpen: false
                    })}
                    closeButtonLabel={translate("btn.close")}
                >
                    <div className="flex items-center m-4">
                        <CopyToClipboardButton
                            clipboardText={this.state.isReceivedCodeModalOpen}
                            translate={translate}
                        />
                        <FieldText
                            addClass={"font-bold text-2xl rounded-md"}
                            value={this.state.isReceivedCodeModalOpen ? this.state.isReceivedCodeModalOpen : ""}
                            disabled={true}
                        />
                    </div>
                </ModalDefault>

                <ModalSaveResourceWithDropZone
                    isLoading={this.props.secondResource.isLoading}
                    show={this.state.isAdvancesReimbursementsCreateModalOpen}
                    title={translate('text.create_reimbursement')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.toggleAdvancesReimbursementsCreateModal}
                    fields={this.getAdvancesReimbursementsFields(getProp(this.props.resource, 'data.info', {}))}
                    metadata={{
                        DriverID: DriversList
                    }}
                    handleInputChange={this.handleAdvancesReimbursementsChange}
                    onAcceptFiles={(files) => this.handleAcceptFiles(files)}
                    maxFilesAccepted={1}
                    fileUploadInfoText={"Max file size 25 MB."}
                    files={this.state.files}
                    accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                    filePreview={this.state.filePreview}
                    onClosePreview={this.closePreview}

                    onSubmit={(params) => {
                        if (params) {
                            params.ContactID = this.getDriverContactID();

                            this.props.dispatch(createSecondResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {LoadID: this.getID()}),
                                query: {
                                    LoadID: this.getID()
                                },
                                resource: Resources.LoadReimbursement,
                                piggyResource: Resources.LoadReimbursement,
                                file: this.state.files,
                                fileResource: Resources.LoadReimbursementDocument,
                                errorMessage: true,
                                successMessage: this.props.translate('message.entry_added')
                            }))
                            this.setState({
                                files: []
                            })
                        }
                    }}

                    htmlBefore={
                        <React.Fragment>
                            {!!this.state.files.length && (
                                <div className="px-6 pt-6">
                                    <h2
                                        className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                    >
                                        Files
                                    </h2>

                                    <FileList
                                        files={this.state.files}
                                        onFileDelete={() => this.setState({
                                            files: []
                                        })}
                                        onFileView={(file) => this.handleFileView(file)}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    }
                    translate={translate}
                />

                {/*View*/}
                <ModalDefault
                    show={this.state.isAdvancesReimbursementsViewModalOpen}
                    widthClass={'max-w-7xl w-screen'}
                    title={translate('text.Advances/Reimbursements')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={this.toggleAdvancesReimbursementsViewModal}
                >
                    <Card>
                        <ActiveFilters
                            filterFields={this.state.advancesReimbursementQueryFields}
                            onLabelClick={this.handleFilterInputChange}
                            translate={translate}
                        />
                        <TableCard>
                            <TableFilters
                                filterFields={this.state.advancesReimbursementQueryFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                                hideLimit
                            />
                            <ResourceTable
                                addClass="rounded-card"
                                data={advancesReimbursementData}
                                count={advancesReimbursementCount}

                                commonTable={true}

                                fields={this.state.advancesReimbursementFields}
                                translate={translate}
                                isLoading={advancesReimbursementIsLoading}
                            />

                            {/*Table footer*/}
                            {!(!advancesReimbursementData.length && !advancesReimbursementIsLoading) && (
                                <div
                                    className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                >
                                    <Pagination
                                        count={advancesReimbursementCount}
                                        isLoading={advancesReimbursementIsLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === "offset"
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.advancesReimbursementQueryFields}
                                        translate={translate}
                                    />
                                </div>
                            )}

                            <NoRecordsTable
                                show={(advancesReimbursementData.length === 0) && !advancesReimbursementIsLoading}
                                canCreate={false}
                                title={translate("text.no_matching_records")}
                            />
                        </TableCard>
                    </Card>
                </ModalDefault>


                <Modal
                    show={this.state.isCustomerSettingsModalOpen}
                    widthClass={'max-w-6xl'}
                    onClose={this.toggleCustomerSettingsDialog}
                    isLoading={false}
                >
                    <ModalHeader
                        title={translate('text.customer_settings')}
                        onClose={this.toggleCustomerSettingsDialog}
                    />

                    <ApplyCustomerSettingsForm
                        accessorialsData={this.state.accessorialData}
                        commissionData={this.state.commissionData}
                        calculateValueBasedOnFee={this.calculateValueBasedOnFee}
                        onApplySelected={this.applyCustomerSettings}
                        onClose={this.toggleCustomerSettingsDialog}
                        translate={translate}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

export default LoadPricingTab

const RowNotes = ({notes = ""}) => {
    return <>
        {!!notes && (
            <Tippy content={tooltipTextareaContent(notes)}>
                <button
                    className="rounded-full focus:outline-none focus:ring-1 focus:ring-primary"
                >
                    <InformationCircleIcon className="w-5 h-5 text-primary"/>
                </button>
            </Tippy>
        )}
    </>
}
