import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../data/services/resources";
import { PERSONNEL_SETTINGS_INCLUDED_LOOKUPS, READ_PERM, REQUIRED_DOCUMENTS_TYPES } from '../../../util/util-constants'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import DocumentTypesTab from "../../../common/components/tabs/document-types-tab";
import Layout from "../../../common/components/layout";
import Lookups from "../../../common/components/tabs/lookupTab";
import DocumentEntitiesTab from "../../../common/components/tabs/document-entities-tab";
import { checkPerm } from '../../../common/util/util-helpers'
export default function PersonnelSettingsView({translate, match, history, location}) {
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);
    const resource = useSelector((state) => state.resource);

    const [tabs, setTabs] = useState(getTabs());
    const [breakpoint, setBreakpoint] = useState({})
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }

    const currentTab = tabs.find(tab => tab.current);

    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            onBreakpoinChange={handleBreakpointChange}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.PersonnelSettings')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'customFields' && (
                    <Lookups
                        customLookupFilter={(item, name) => {
                            if (name === "DocumentType") {
                                return !!item.IsPersonnelDocument
                            }
                            return true
                        }}
                        includeLookups={PERSONNEL_SETTINGS_INCLUDED_LOOKUPS}
                        translate={translate}
                        resource={resource}
                        dispatch={dispatch}
                    />
                )}

                {currentTab.name === "DocumentTypes" && (
                    <DocumentTypesTab
                        translate={translate}
                        primaryType={'IsPersonnelDocument'}
                        breakpoint={breakpoint}
                    />
                )}

                {currentTab.name === "RequiredDocuments" && (
                    <DocumentEntitiesTab
                        requiredTypes = {REQUIRED_DOCUMENTS_TYPES.Personnel}
                        translate={translate}
                        resource={resource}
                        dispatch={dispatch}
                        sortOrder={['Driver', 'Employee','Agent']}
                    />
                )}
            </Page>
        </Layout>
    )
}

const getTabs = () => {
    return [
        {
            name: 'customFields',
            resource: Resources.Lookup,
            current: true,
            visible: checkPerm(Resources.Lookup, READ_PERM)
        },
        {
            name: 'DocumentTypes',
            resource: Resources.DocumentTypes,
            current: false,
            visible: checkPerm(Resources.DocumentTypes, READ_PERM)
        },
        {
            name: 'RequiredDocuments',
            resource: Resources.DocumentEntities,
            current: false,
            visible: checkPerm(Resources.DocumentEntities, READ_PERM)
        }
    ]
}
