import {OCR_MAIN_FRAGMENT_TYPES, STOP_TYPE_DELIVERY, STOP_TYPE_PICKUP} from "../../../../../util/util-constants";
import moment from "moment/moment";
import {toFrontDateNoTime} from "../../../../../common/util/util-dates";
import {getLookup} from "../../../../../common/util/util-helpers";
import React from "react";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import OCRWarnings from "./ocr-warnings";
import InfoParagraph from "../../../../../common/components/info-paragraph";

/** Constants
 ================================================================= */
const stopTypes = {
    [STOP_TYPE_PICKUP]: 'Pickup',
    [STOP_TYPE_DELIVERY]: 'Delivery'
}
const states = getLookup('State', 'StateID', "StateAbbreviation") ?? {};
const stateFullNames = getLookup('State', 'StateID', "State") ?? {};

/** Content & fragment extraction / processing
 ================================================================= */
export const getContentValue = (element, field) => {
    const formatName = element?.specific?.['format-name'];
    const type = element?.type;

    // Pre-process
    switch (field.name) {
        case "RequiresAppointment":
        case "Stackable":
        case "Hazmat":
            return getCheckboxValue(field.name, element?.specific["content-0"])
        case "LoadTypeID":
        case "UnitTypeID":
        case "StopType":
            return getSelectValue(field.name, element?.specific["content-0"]);
        case "LoadSubTypeID": {
            let fieldValue = element?.specific["content-0"];
            if (formatName === 'TRUCKOCR_CHROBINSON_EQUIPMENT_ITEM') {
                fieldValue = element?.specific["content-0"]
            }
            if (formatName === "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_EQUIPMENT") {
                fieldValue = element?.specific["content-2"] + element?.specific["content-3"];
            }
            return getSelectValue(field.name, fieldValue);
        }
        case "StateID": {
            let fieldValue = element?.specific["content-0"]
            if (formatName === "ADDRESS_CITY_STATE_ZIP" || formatName === "ADDRESS_USA_CITY_STATE_ZIP") {
                fieldValue = element?.specific["content-3"];
            }
            if (formatName === "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_ORIGIN" || formatName === "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_DESTINATION") {
                fieldValue = element?.specific["content-4"];
            }
            return getSelectValue(field.name, fieldValue)
        }
    }

    if (type.startsWith('group::PARAGRAPH')) {
        switch (field.type) {
            case 'textarea': {
                return getMergedGroupContent(element)
            }
        }
    }

    if (formatName === "ADDRESS_CITY_STATE_ZIP" || formatName === "ADDRESS_USA_CITY_STATE_ZIP") {
        switch (field.type) {
            case "location-city":
                return element?.specific["content-1"];
            case "location-zip":
                return element?.specific["content-6"] ?
                    (element.specific["content-5"] + element.specific["content-6"] + element.specific["content-7"]) : // Zip+4 format
                    element?.specific["content-5"];
        }
    }

    if (formatName === "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_ORIGIN" || formatName === "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_DESTINATION") {
        switch (field.type) {
            case "location-city":
                return element?.specific["content-2"];
            case "location-zip":
                return element?.specific["content-6"]
        }
    }

    if (formatName === 'PAIR_3WORD_COMMA_3WORD') {
        switch (field.type) {
            case "location-city":
                return element?.specific["content-1"]
            case "location-zip":
                return ""
        }
    }

    if (formatName === 'TRUCKOCR_CHROBINSON_REFERENCE_NUMBER') {
        switch (field.name) {
            case "CustomerID":
                return element?.specific["content-1"];
            case "CustomerReferenceNumber":
                return element?.specific["content-4"];
        }
    }

    if (formatName === 'TRUCKOCR_JBHUNT_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_ECHO_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_BNSF_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_ATS_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_NOLAN_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_REDWOOD_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_RETRANS_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_SHIPEX_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_TQL_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_YUSEN_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_ALLEN_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_GLT_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_SCHNEIDER_REFERENCE_NUMBER' ||
        formatName === 'TRUCKOCR_TQL_RC_REFERENCE_NUMBER'
    ) {
        switch (field.name) {
            case "CustomerReferenceNumber":
                return element?.specific["content-3"];
        }
    }

    if (formatName === 'TRUCKOCR_ARMSTRONG_REFERENCE_NUMBER') {
        switch (field.name) {
            case "CustomerReferenceNumber":
                return element?.specific["content-2"];
        }
    }

    if (formatName === 'TRUCKOCR_JBS_REFERENCE_NUMBER') {
        switch (field.name) {
            case "CustomerReferenceNumber":
                return element?.specific["content-0"];
        }
    }

    if (formatName === 'TRUCKOCR_CHROBINSON_CONFIRMATION_SMALL') {
        switch (field.name) {
            case "CustomerReferenceNumber":
                return element?.specific["content-2"];
        }
    }

    // Detect fragments and override content values when appropriate
    if (element.specific["fragment-0"]) {
        const fragments = processFragments(element)
        const fragmentsTypesCount = countFragmentsTypesInstances(fragments)

        if (elementContainsMoneyFragments(element)) {
            switch (field.name) {
                case "Price":
                    return fragments.find((fragment) => fragment.type === 'FLOAT')?.value || getMoneyFieldValue(element)
            }
        }

        if (elementContainsDateTimeFragments(element)) {
            switch (getDateTimeFormat(fragmentsTypesCount)) {
                case 'dateInterval': {
                    switch (field.name) {
                        case "StopDate":
                            return getFirstDateTimeFragmentValue(fragments, 'date')
                        case "StopEndDate":
                            return getLastDateTimeFragmentValue(fragments, 'date')
                    }
                    break;
                }
                case 'timeInterval': {
                    switch (field.name) {
                        case "StopTime":
                            return getFirstDateTimeFragmentValue(fragments, 'time')
                        case "StopEndTime":
                            return getLastDateTimeFragmentValue(fragments, 'time')
                    }
                    break;
                }
                case 'dateTimeStamp': {
                    switch (field.metadata.loadFieldType) {
                        case "date":
                            return getFirstDateTimeFragmentValue(fragments, 'date')
                        case "time-custom":
                            return getFirstDateTimeFragmentValue(fragments, 'time')
                    }
                    break;
                }
                case 'dateWithTimeInterval': {
                    switch (field.name) {
                        case "StopDate":
                        case "StopEndDate":
                            return getFirstDateTimeFragmentValue(fragments, 'date')
                        case "StopTime":
                            return getFirstDateTimeFragmentValue(fragments, 'time')
                        case "StopEndTime":
                            return getLastDateTimeFragmentValue(fragments, 'time')
                    }
                    break;
                }
                case 'dateTimeIntervalFull': {
                    switch (field.name) {
                        case "StopDate":
                            return getFirstDateTimeFragmentValue(fragments, 'date')
                        case "StopEndDate":
                            return getLastDateTimeFragmentValue(fragments, 'date')
                        case "StopTime":
                            return getFirstDateTimeFragmentValue(fragments, 'time')
                        case "StopEndTime":
                            return getLastDateTimeFragmentValue(fragments, 'time')
                    }
                    break;
                }
                case 'timeSingle': {
                    switch (field.name) {
                        case "StopTime":
                        case "StopEndTime":
                            return getFirstDateTimeFragmentValue(fragments, 'time')
                    }
                    break;
                }
                case 'dateSingle': {
                    switch (field.name) {
                        case "StopDate":
                        case "StopEndDate":
                            return getFirstDateTimeFragmentValue(fragments, 'date')
                    }
                    break;
                }
                default:
                    return element?.specific?.content
            }
        }

        if (elementContainsWeightFragments(element)) {
            switch (field.name) {
                case "Weight": {
                    const weightValue = fragments.find((fragment) => fragment.type === 'WEIGHT_VALUE')?.value
                    if (weightValue) {
                        return weightValue.replace(/[^\d.-]/g, '')
                    } else {
                        return element.specific.content;
                    }
                }
            }
        }
    }

    return element?.specific?.content;
}

const getFragmentType = (fragment) => {
    const regex = /::(.*?);/;
    const match = regex.exec(fragment);
    return match[1]
}

const getFragmentValue = (fragment) => {
    return fragment.split(';')[1]
}

export const processFragments = (element) => {
    let fragments = Object.entries(element.specific).reduce((memo, item) => {
        item[0].startsWith('fragment') ? memo[item[0]] = item[1] : null;
        return memo
    }, {})


    let processedFragments = []
    for (const fragment in fragments) {
        const fragmentType = getFragmentType(fragments[fragment])
        const fragmentValue = getFragmentValue(fragments[fragment])

        const processedFragment = {
            "type": fragmentType,
            "value": fragmentValue
        }

        if (fragmentType === 'TIME_RELAX') {
            processedFragment.type = 'TIME'
            processedFragment.value = !fragmentValue.includes(':') ? `${fragmentValue.slice(0, 2)}:${fragmentValue.slice(2)}` : fragmentValue
        }

        if (OCR_MAIN_FRAGMENT_TYPES.includes(processedFragment.type)) {
            processedFragments.push(processedFragment)
        }
    }

    return processedFragments
}

export const countFragmentsTypesInstances = (fragments) => {
    return {
        'Date': fragments.filter((fragment) => fragment.type === 'DATE').length,
        'Time': fragments.filter((fragment) => fragment.type === 'TIME' || fragment.type === 'TIME_RELAX').length,
        'Email': fragments.filter((fragment) => fragment.type === 'EMAIL').length,
        'EmailDomain': fragments.filter((fragment) => fragment.type === 'EMAIL_DOMAIN').length,
        'EmailAccount': fragments.filter((fragment) => fragment.type === 'EMAIL_ACCOUNT').length,
        'Duration': fragments.filter((fragment) => fragment.type === 'DURATION').length,
        'Float': fragments.filter((fragment) => fragment.type === 'FLOAT').length,
        'Money': fragments.filter((fragment) => fragment.type === 'MONEY').length,
    }
}

export const elementContainsDateTimeFragments = (selectedElement) => {
    if (Object.keys(selectedElement).length) {
        const fragments = processFragments(selectedElement)
        return fragments.some((fragment) => fragment.type === 'DATE' || fragment.type === 'TIME' || fragment.type === 'TIME_RELAX')
    }

    return false
}

export const elementContainsMoneyFragments = (selectedElement) => {
    if (Object.keys(selectedElement).length) {
        const fragments = processFragments(selectedElement)
        return fragments.some((fragment) => fragment.type === 'MONEY') || fragments.some((fragment) => fragment.type === 'MONEY_UNIT')
    }

    return false
}

const elementContainsWeightFragments = (selectedElement) => {
    if (Object.keys(selectedElement).length) {
        const fragments = processFragments(selectedElement)
        return fragments.some((fragment) => fragment.type === 'WEIGHT_VALUE')
    }

    return false
}

const getFirstDateTimeFragmentValue = (fragments, type) => {
    switch (type) {
        case 'date': {
            let dateValue = fragments.find((fragment) => fragment.type === 'DATE').value
            if (moment(dateValue, 'YYYY-MM-DD').format('YYYY-MM-DD') === dateValue) {
                return toFrontDateNoTime(dateValue)
            }
            return dateValue
        }
        case 'time': {
            return fragments.find((fragment) => fragment.type === 'TIME').value
        }
    }
}

const getLastDateTimeFragmentValue = (fragments, type) => {
    switch (type) {
        case 'date': {
            let dateValue = fragments.findLast((fragment) => fragment.type === 'DATE').value
            if (moment(dateValue, 'YYYY-MM-DD').format('YYYY-MM-DD') === dateValue) {
                return toFrontDateNoTime(dateValue)
            }
            return dateValue
        }
        case 'time': {
            return fragments.findLast((fragment) => fragment.type === 'TIME').value
        }
    }
}

export const pageHasElements = (page) => {
    return !!page["element"]
}

export const getFieldType = (fieldName) => {
    switch (fieldName) {
        case 'UnitTypeID':
            return 'select'
        case 'Hazmat':
        case 'Stackable':
            return 'checkbox'
        case 'CountPallets':
        case 'CountPieces':
        case 'Length':
        case 'Width':
        case 'Height':
        case 'Weight':
        case 'Temp':
            return 'float'
        case 'MeasureUnitID':
            return 'hidden'
        default:
            return 'text'
    }
}

export const isFieldTypeDateTime = (field) => {
    return field.metadata.loadFieldType === 'time-custom' || field.metadata.loadFieldType === 'date'
}

/** Date time manipulation and parsing
 ================================================================= */
const parseDate = (date, format, separator) => {
    const parts = date.split(separator);
    const formatParts = format.split(separator);

    if (!parts.length || !formatParts.length) return null

    if (parts[2].length !== formatParts[2].length) {
        return null;
    }

    if (parts[2].length === 2) {
        parts[2] = "20" + parts[2];
    }

    if (parts.length !== formatParts.length) {
        return null;
    }

    const dateObject = {};
    for (let i = 0; i < formatParts.length; i++) {
        dateObject[formatParts[i]] = parseInt(parts[i]);
    }

    const month = dateObject['MM'] - 1; // JS months are zero-based
    const day = dateObject['DD'];
    const year = dateObject[formatParts[2]]

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return null;
    }

    return new Date(year, month, day);
}

export const guessDateFormat = (date) => {
    let separator = "";
    const dateFormats = [
        {format: 'MM/DD/YYYY', separator: '/', dayIndex: 1, monthIndex: 0},
        {format: 'DD/MM/YYYY', separator: '/', dayIndex: 0, monthIndex: 1},
        {format: 'MM/DD/YY', separator: '/', dayIndex: 1, monthIndex: 0},
        {format: 'DD/MM/YY', separator: '/', dayIndex: 0, monthIndex: 1},
        {format: 'MM-DD-YYYY', separator: '-', dayIndex: 1, monthIndex: 0},
        {format: 'DD-MM-YYYY', separator: '-', dayIndex: 0, monthIndex: 1},
        {format: 'MM-DD-YY', separator: '-', dayIndex: 1, monthIndex: 0},
        {format: 'DD-MM-YY', separator: '-', dayIndex: 0, monthIndex: 1},
        {format: 'MM.DD.YYYY', separator: '.', dayIndex: 1, monthIndex: 0},
        {format: 'DD.MM.YYYY', separator: '.', dayIndex: 0, monthIndex: 1},
        {format: 'MM.DD.YY', separator: '.', dayIndex: 1, monthIndex: 0},
        {format: 'DD.MM.YY', separator: '.', dayIndex: 0, monthIndex: 1}
    ];

    if (date.search("/") > 0) {
        separator = "/"
    }

    if (date.search("-") > 0) {
        separator = "-"
    }

    if (date.search(".") > 0) {
        separator = "."
    }

    if (separator) {
        const formatList = dateFormats.reduce((memo, it) => {
            if (it.separator === separator) {
                memo.push(it);
            }
            return memo;
        }, []);

        for (const format of formatList) {
            const parsedDate = parseDate(date, format.format, separator);
            if (parsedDate !== null) {
                const guessedDay = parsedDate.getDate();
                const guessedMonth = parsedDate.getMonth() + 1;
                if (guessedDay === parseInt(date.split(separator)[format.dayIndex]) &&
                    guessedMonth === parseInt(date.split(separator)[format.monthIndex])) {
                    return format.format;
                }
            }
        }
    }
}

export const splitDateString = (dateTimeString) => {
    if (!dateTimeString) {
        return {
            stopDate: "",
            stopTime: "",
            stopEndDate: "",
            stopEndTime: ""
        }
    } else {
        let splitIndex = dateTimeString?.indexOf("-", dateTimeString.indexOf("-", dateTimeString.indexOf("-") + 1) + 1);

        const firstDateTimeStamp = splitIndex !== -1 ? dateTimeString.substring(0, splitIndex) : dateTimeString;
        const secondDateTimeStamp = splitIndex !== -1 ? dateTimeString.substring(splitIndex + 1) : null;

        return {
            stopDate: toFrontDateNoTime(firstDateTimeStamp.split(" ")[0]),
            stopTime: firstDateTimeStamp.split(" ")[1],
            stopEndDate: toFrontDateNoTime(secondDateTimeStamp?.split(" ")[0]) || "",
            stopEndTime: secondDateTimeStamp?.split(" ")[1] || ""
        }
    }
}

export const getDateTimeFormat = (fragmentsCount) => {
    if (fragmentsCount["Time"] === 1 && fragmentsCount["Date"] === 0) return 'timeSingle' // 09:00
    if (fragmentsCount["Time"] === 0 && fragmentsCount["Date"] === 1) return 'dateSingle' // 1/11/2023
    if (fragmentsCount["Date"] === 2 && fragmentsCount["Time"] === 0) return 'dateInterval' // 1/11/2023 - 1/12/2023
    if (fragmentsCount["Date"] === 0 && fragmentsCount["Time"] === 2) return 'timeInterval' // 08:00 - 16:00
    if (fragmentsCount["Date"] === 1 && fragmentsCount["Time"] === 2) return 'dateWithTimeInterval' // 1/11/2023 08:00 - 16:00
    if (fragmentsCount["Date"] === 1 && fragmentsCount["Time"] === 1) return 'dateTimeStamp' // 01/11/2023 07:30 AM
    if (fragmentsCount["Date"] === 2 && fragmentsCount["Time"] === 2) return 'dateTimeIntervalFull' // 2022-11-07 23:59 - 2022-11-07 23:59
}

/** Selects and checkboxes
 ================================================================= */
const getCheckboxValue = (name, value) => {
    value = typeof value === "string" ? value.toLowerCase() : "";

    if (name === 'RequiresAppointment') {
        if (value.includes('appt') || value.includes('yes')) {
            return 1
        } else {
            return 0
        }
    }

    if (value.includes("no")) {
        return 0;
    }
    return 1;
}

const getSelectClosestMatch = (value, options, isStopTypeField = false) => {
    let words = isStopTypeField ?
        value.toString().replace(/\W/g, ',').split(',') :
        value.replace(/\b\w{1}\b|\W/g, ',').split(',');

    const optionValues = Object.values(options);
    if (words.length) {
        let maxHits = 0;
        const maxHitsOption = optionValues.reduce((memo, option) => {
            let hits = 0;
            words.forEach(word => {

                const hit = option.toLowerCase().search(word.toLowerCase())
                hits = hit > -1 ? hits + 1 : hits;
            });

            if (hits > maxHits) {
                maxHits = hits;
                memo = option;
            }
            return memo;
        }, "")

        if (maxHitsOption) {
            return Object.keys(options).find(key => options[key] === maxHitsOption) ?? "";
        }
    }

    return value;
}

export const getSelectValue = (name, value) => {
    value = typeof value === "string" ? value.toLowerCase() : value;
    if (name === 'StopType') {
        return getSelectClosestMatch(value, stopTypes, true)
    }
    if (name === 'LoadSubTypeID') {
        return getSelectClosestMatch(value, getLookup("LoadSubType"))
    }
    if (name === 'LoadTypeID') {
        return getSelectClosestMatch(value, getLookup("LoadType"))
    }
    if (name === 'UnitTypeID') {
        return getSelectClosestMatch(value, getLookup("UnitType"))
    }
    if (name === 'StateID') {
        if (getSelectClosestMatch(value, states) !== value) {
            return getSelectClosestMatch(value, states)
        } else {
            return getSelectClosestMatch(value, stateFullNames)
        }
    }

    return value ?? "";
}

/** CustomerID field helpers
 ================================================================= */
const getCustomerNameFromReferenceNumber = (referenceNumberType) => {
    const startIdx = referenceNumberType.indexOf("TRUCKOCR_") + "TRUCKOCR_".length;
    const endIdx = referenceNumberType.indexOf("_REFERENCE");
    if (startIdx !== -1 && endIdx !== -1 && startIdx < endIdx) {
        const customerName = referenceNumberType.slice(startIdx, endIdx)
        return customerName === 'CHROBINSON' ? 'c.h. robinson' : customerName.toLowerCase();
    } else {
        return null;
    }
}

const getCustomerNameFromEmailDomain = (unprocessedEmailDomainString) => {
    const domainName = unprocessedEmailDomainString.substring(0, unprocessedEmailDomainString.indexOf(".com"))
    const domainNameToSearchStringMap = {
        'chrobinson': 'robinson',
        'skywaybrokerage': 'skyway',
        'bedrocklogistics': 'bedrock',
        'bluegracegroup': 'bluegrace',
        'cornerstone-systems': 'cornerstone',
        'spotinc': 'spot',
    }

    return domainNameToSearchStringMap[domainName] ?? domainName
}

export const getCustomerQuery = (element) => {
    const format = element.specific['format-name']
    if (format === 'TRUCKOCR_CUSTMERS_NAME_LEGAL_64') {
        return {query: element.specific['content-0']}
    } else if (format.endsWith('REFERENCE_NUMBER')) {
        return {query: getCustomerNameFromReferenceNumber(element.type)}
    } else if (element.specific['fragment-0']) {
        const processedFragments = processFragments(element)
        const fragmentsTypesCount = countFragmentsTypesInstances(processedFragments)
        if (fragmentsTypesCount['EmailDomain']) {
            return {query: getCustomerNameFromEmailDomain(processedFragments.find((fragment) => fragment.type === 'EMAIL_DOMAIN')?.value)}
        }
    }

    return {query: element.specific['content-0']}
}

/** Misc
 ================================================================= */
const extractNumber = (inputString) => {
    const pattern = /::(.*?);(\d+)/;
    const match = inputString.match(pattern);

    if (match && match[2]) {
        return match[2];
    } else {
        return inputString
    }
}

export const getMergedGroupContent = (element) => {
    let mergedNotes = ""
    Object.entries(element.specific).forEach((keyValueArr) => {
        const key = keyValueArr[0]
        const val = keyValueArr[1]
        if (key.startsWith('child-content')) {
            mergedNotes += val
        }
    })
    return mergedNotes
}

export const getMoneyFieldValue = (element) => {
    const fragmentValues = Object.values(element.specific).filter((prop) => (typeof (prop) === 'string') && prop.startsWith('text::'))
    const correctFragment = fragmentValues.find((val) => val.includes('FLOAT') || val.includes('UNSIGNED'))

    return extractNumber(correctFragment)
}

export const extractFieldName = (fieldDbGuid = "") => {
    if (fieldDbGuid.startsWith("locations")) {
        return fieldDbGuid.slice(12)
    } else if (fieldDbGuid.startsWith("pricing")) {
        return fieldDbGuid.slice(10)
    } else if (fieldDbGuid.startsWith("info")) {
        return fieldDbGuid.slice(7)
    } else if (fieldDbGuid.startsWith("commodities")) {
        return fieldDbGuid.slice(14)
    } else {
        return fieldDbGuid
    }
}

export const getValuesFromDbGuid = (dbGuid) => {
    if (!dbGuid) {
        return ["", "", ""];
    }

    const stateName = dbGuid.split(".")[0];
    const stateIndex = dbGuid.split(".")[1];
    const fieldName = dbGuid.split(".")[2];

    return [fieldName, stateName, stateIndex];
}

export const isValidDbGuid = (dbGuid) => {
    const stateName = dbGuid.split(".")[0];
    const stateIndex = dbGuid.split(".")[1];
    const fieldName = dbGuid.split(".")[2];

    return !!stateName && !!fieldName && stateIndex > -1;
}

export const getTextareaNumberOfRows = (textareaContent) => {
    const minimumRows = 5
    if (textareaContent) {
        const calculatedRows = Math.round(textareaContent.length / 60)

        return calculatedRows > minimumRows ? calculatedRows : minimumRows
    }

    return minimumRows
}

export const getJobStatus = (status) => {
    switch (status) {
        case "Done":
            return 'Done'
        case "Fail":
            return 'Fail'
        default:
            return 'In Progress'
    }
}

/** Components
 ================================================================= */
export const Message = ({report, onCreateLocations}) => {
    const hasErrors = !!report?.errors?.length;
    const hasWarnings = !!report?.warnings?.length
    const noMessages = !hasErrors && !hasWarnings;

    return (
        <React.Fragment>
            {hasErrors && (
                <div className="rounded-md border-2 border-red-500 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">There
                                were {report?.errors?.length} error(s) with your submission</h3>
                            <div className="mt-2 text-sm text-red-700">
                                <ul role="list" className="list-disc space-y-1 pl-5">
                                    {report?.errors?.map((err, i) => {
                                        return <li key={i}><span
                                            className="font-bold">{err.field.split(".").pop()}</span> {err.message}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {hasWarnings && (
                <div className="rounded-md border-2 border-yellow-500 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-yellow-800" aria-hidden="true"/>
                        </div>
                        <div className="ml-3">
                            <OCRWarnings
                                warnings={report?.warnings}
                                onCreateLocations={onCreateLocations}
                            />
                        </div>
                    </div>
                </div>
            )}

            {noMessages && (
                <InfoParagraph type="success">
                    Everything went OK!
                </InfoParagraph>
            )}
        </React.Fragment>
    )
}