import {BuildingOfficeIcon, ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import React, {useRef, useState} from 'react';
import {classNames, openInNewTab} from '../../../../common/util/util-helpers';
import {genericMoneyFormatter} from '../../../../common/util/util-vanilla';
import {numberWithCommasToBack} from '../../../../util/util-formaters';
import LocalStorage from '../../../../util/localStorage';
import {Field} from '../../../../data/services/fields';
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import PopOver from "../../../../common/components/popover";
import ResourceTable from "../../../../common/components/resource-table";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {showGlobalModal} from "../../../../data/actions/ui";
import {useDispatch} from "react-redux";

export default function LoadPricingCustomerInfo({
                                                    CustomerID,
                                                    OrganizationID,
                                                    CustomerStats,
                                                    CreditLimit,
                                                    CreditLimitAvailable,
                                                    isOverCreditLimit,
                                                    CreditLimitOtherActiveLoadsCost,
                                                    translate,
                                                    AllowCreditLimitOverride,
                                                    isCreditOnHold
                                                }) {
    const dispatch = useDispatch();

    const localStorageKey = "load_pricing_customer_info";
    const customerSettingsBtnRef = useRef();
    const [areCustomerDetailsExpanded, setAreCustomerDetailsExpanded] = useState(
        !!LocalStorage.get(localStorageKey, {})?.areDetailsExpanded
    );
    const [isSectionExpanded, setIsSectionExpanded] = useState(
        !!LocalStorage.get(localStorageKey, {})?.isSectionExpanded
    )

    function handleExpandSectionClick() {
        const isExpanded = !isSectionExpanded;
        let localStorageValue = LocalStorage.get(localStorageKey, {});
        localStorageValue.isSectionExpanded = isExpanded;

        setIsSectionExpanded(isExpanded);
        LocalStorage.set(localStorageKey, localStorageValue);
    }


    function handleCustomerDetailsClick() {
        const isExpanded = !areCustomerDetailsExpanded;
        let localStorageValue = LocalStorage.get(localStorageKey, {});
        localStorageValue.areDetailsExpanded = isExpanded;

        setAreCustomerDetailsExpanded(!areCustomerDetailsExpanded);
        LocalStorage.set(localStorageKey, localStorageValue);
    }

    return (
        <div className="relative bg-inverse shadow rounded-lg border-tm-gray-300 border">
            <div className="py-4 px-4 sm:px-5">
                <div className="relative spece-y-5">
                    <div className="flex items-center flex-wrap gap-x-2">
                        <button
                            className="btn btn-icon -ml-2 md mr-2 w-8 h-8"
                            onClick={handleExpandSectionClick}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        "w-5 h-5",
                                        isSectionExpanded ? "rotate-90" : undefined
                                    )
                                }
                            />
                        </button>

                        <BuildingOfficeIcon className="w-5 h-5 text-tm-gray-600"/>

                        <div className="text-lg text-tm-gray-700 flex items-start gap-0 flex-col leading-5">
                            <span>Customer info</span> {!isSectionExpanded &&
                            <span className="text-sm text-tm-gray-700 leading-4">{CustomerStats.LegalName}</span>}
                        </div>
                    </div>

                    <PopOver
                        className={"absolute -top-1 -right-1"}
                        btnClass="btn-icon"
                        btnIconClass={"w-5 h-5"}
                        BtnIcon={Cog6ToothIcon}
                        widthClass={'max-w-[16rem]'}
                        positionClass="fixed md:absolute right-0 mx-5 md:mx-0 translate-x-0"
                        PopoverButtonRef={customerSettingsBtnRef}
                    >
                        <div
                            className="bg-popup border border-tm-gray-300 rounded-card overflow-hidden">
                            <button
                                className="dropdown-item"
                                onClick={() => {
                                    openInNewTab('/customers/info/'
                                        + OrganizationID
                                        + '/'
                                        + CustomerID
                                        + '?tab=customers/credit'
                                    );
                                    customerSettingsBtnRef.current.click();
                                }}
                            >
                                {translate("text.check_customer_settings")}
                            </button>

                            <button
                                className="dropdown-item"
                                onClick={() => {
                                    openInNewTab('/dispatch-settings');
                                    customerSettingsBtnRef.current.click();
                                }}
                            >
                                {translate("text.check_global_settings")}
                            </button>
                        </div>
                    </PopOver>
                </div>

                {isSectionExpanded && (
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <p className="text-tm-gray-500 text-xs leading-4">Legal name</p>

                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">
                                {CustomerStats.LegalName}
                            </p>
                        </div>

                        {!!CreditLimit && (
                            <div>
                                <div className="text-right">
                                    <p className="text-tm-gray-500 text-xs leading-4">
                                        Credit limit
                                    </p>

                                    <div
                                        className={
                                            classNames(
                                                "font-bold leading-5 whitespace-pre text-base",
                                                isOverCreditLimit
                                                    ? AllowCreditLimitOverride
                                                        ? "text-yellow-600"
                                                        : "text-red-600"
                                                    : "text-green-600"
                                            )
                                        }
                                    >
                                        {genericMoneyFormatter(CreditLimitAvailable)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!CustomerStats?.Income && (
                            <React.Fragment>
                                <div className="relative col-span-full">
                                    <div
                                        className="absolute leading-8 top-0 -translate-x-1/2 left-1/2 bg-inverse px-2 font-bold text-tm-gray-600 z-10">
                                        {translate("text.last_12_months")}
                                    </div>

                                    <div
                                        className="grid grid-cols-3 border border-tm-gray-200 rounded-card relative mt-4 divide-x divide-tm-gray-200">
                                        <div className="col-start-1 p-4">
                                            <p className="text-tm-gray-500 text-xs leading-4">{translate("field.TotalCountInvoices")}</p>
                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">{CustomerStats?.Income?.TotalCountInvoices ?? 0}</p>
                                        </div>

                                        <div className="p-4">
                                            <p className="text-tm-gray-500 text-xs leading-4">
                                                {translate("field.TotalPaidInvoices")}
                                            </p>

                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">
                                                {genericMoneyFormatter(CustomerStats.Income.TotalPaidInvoices)}
                                            </p>
                                        </div>

                                        <div className="p-4">
                                            <p className="text-tm-gray-500 text-xs leading-4">{translate("field.AverageDaysToPay")}</p>
                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">{CustomerStats.Income.AverageDaysToPay ?? 0}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="relative col-span-full">
                                    <div
                                        className="absolute leading-8 top-0 -translate-x-1/2 left-1/2 bg-inverse px-2 font-bold text-tm-gray-600 z-10">
                                        {translate("text.current")}
                                    </div>

                                    <div
                                        className="grid grid-cols-3 border border-tm-gray-200 rounded-card relative mt-4 divide-x divide-tm-gray-200">
                                        <div className="col-start-1 p-4">
                                            <p className="text-tm-gray-500 text-xs leading-4">{translate("field.TotalOpenInvoices")}</p>
                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">{genericMoneyFormatter(CustomerStats.Income.TotalOpenInvoices)}</p>
                                        </div>

                                        <div className="p-4">
                                            <p className="text-tm-gray-500 text-xs leading-4">{translate("field.TotalCurrentInvoices")}</p>
                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">{genericMoneyFormatter(CustomerStats?.Income?.TotalCurrentInvoices)}</p>
                                        </div>

                                        <div className="p-4">
                                            <p className="text-tm-gray-500 text-xs leading-4">{translate("field.TotalOverDueInvoices")}</p>
                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">{genericMoneyFormatter(CustomerStats.Income.TotalOverDueInvoices)}</p>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        <div className="col-span-full gap-2 flex flex-col">
                            {isCreditOnHold && (
                                <InfoBar type="warning" addClass="text-base">
                                    {translate("text.customer_is_blacklisted_edit")}
                                </InfoBar>
                            )}

                            {!!CreditLimit && (
                                <InfoBar
                                    type={
                                        CreditLimitAvailable > 0
                                            ? "default"
                                            : AllowCreditLimitOverride
                                                ? "warning"
                                                : "danger"
                                    }
                                >
                                    <div className="flex w-full justify-between gap-2">
                                        <sapn className="text-base">Available credit {genericMoneyFormatter(CreditLimitAvailable)}</sapn>
                                        <button
                                            onClick={() => {
                                                dispatch(
                                                    showGlobalModal(
                                                        'createTaskDialog',
                                                        {
                                                            taskResource: `/customers/info/${OrganizationID}/${CustomerID}`,
                                                            TaskName: `Request credit for customer ${CustomerStats.LegalName}`
                                                        }
                                                    )
                                                )
                                            }}
                                            className="btn btn-outline py-0 h-auto min-h-6"
                                        >
                                            Request Credit
                                        </button>
                                    </div>
                                </InfoBar>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {isSectionExpanded && CreditLimit !== CreditLimitAvailable && (
                <div className="bg-inverse rounded-b-lg overflow-hidden">
                    <div className="text-sm px-3 py-1 bg-tm-gray-50">
                        <button
                            onClick={handleCustomerDetailsClick}
                            className="btn-text px-2 font-medium text-cyan-700 hover:text-primary font-bold flex items-center"
                        >
                            {!areCustomerDetailsExpanded ? "View details" : "Hide details"}
                            <ChevronDownIcon className={
                                classNames(
                                    "w-5 h-5 ml-2",
                                    areCustomerDetailsExpanded ? "rotate-180" : undefined
                                )
                            }/>
                        </button>
                    </div>

                    {areCustomerDetailsExpanded && (
                        <React.Fragment>

                            <NoRecords
                                show={!CustomerStats?.OtherActiveLoads?.length}
                                addClass="p-4 mt-6"
                                title={translate("text.no_records")}
                            />

                            <ResourceTable
                                data={CustomerStats.OtherActiveLoads}
                                fields={{
                                    LoadNumber: new Field('LoadNumber', '', [''], false, ''),
                                    LoadStatus: new Field('LoadStatus', '', [''], false, ''),
                                    Price: new Field('Price', '', [''], false, 'float', {
                                        label: "rate"
                                    }),
                                }}
                                limit={CustomerStats.OtherActiveLoads.length}
                                translate={translate}
                                isLoading={false}

                                options={{
                                    style: {
                                        verticalLines: true,
                                        stripedRows: true
                                    },
                                    classes: {
                                        headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                                        primaryTextClass: ''
                                    }
                                }}

                                onRowClick={(it) => {
                                    window.open(`${window.location.origin}/loads/info/${it.LoadID}`, '_blank');
                                }}
                                tfoot={{
                                    LoadNumber: (<div>
                                        <p className="text-tm-gray-500 text-xs leading-4">
                                            Total credit limit
                                        </p>

                                        <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">
                                            {genericMoneyFormatter(CreditLimit)}
                                        </p>
                                    </div>),
                                    LoadStatus: (<div>
                                        <p className="text-tm-gray-500 text-xs leading-4">
                                            Credit available for this load
                                        </p>

                                        <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">
                                            {genericMoneyFormatter(CreditLimitAvailable)}
                                        </p>
                                    </div>),
                                    Price: (
                                        <div>
                                            <p className="text-tm-gray-500 text-xs leading-4">
                                                Other active loads and invoices cost
                                            </p>

                                            <p className="font-bold leading-5 whitespace-pre  text-tm-gray-800">
                                                {genericMoneyFormatter(numberWithCommasToBack(CreditLimitOtherActiveLoadsCost))}
                                            </p>
                                        </div>
                                    )
                                }}
                            />
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    )
}

