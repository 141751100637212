import {checkPerm} from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_IN_TRANSIT,
    UPDATE_PERM
} from '../../../../util/util-constants'
import React, {useEffect, useState} from "react";
import {PauseCircleIcon, PlayCircleIcon} from "@heroicons/react/24/outline";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import {getLoadSelects} from "../load-utils";
import {updateResource} from "../../../../data/actions/resource";
import { timePickerValueToServerTime, toFrontDate } from '../../../../common/util/util-dates'
import moment from 'moment/moment'
import Tooltip from "../../../../common/components/tooltip";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import {Loader} from "../../../../common/components/loader";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";

export default function DropLoadButton({
                                           isLoading,
                                           LoadID,
                                           IsDropped,
                                           DriverID,
                                           CoDriverID,
                                           TruckID,
                                           TrailerID,
                                           initialLoadStatusID,
                                           innerRef,
                                           getQuery,
                                           isLoadDirty,
                                           buttonClass,
                                           stop,
                                           translate
                                       }) {
    const resourceName = Resources.LoadDrop;

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isConfirmContinueModalVisible, setIsConfirmContinueModalVisible] = useState(false);
    const [dropTrailerFields, setDropTrailerFields] = useState(getDropLoadFields());
    const [continueLoadFields, setContinueLoadFields] = useState(getContinueLoadFields());

    function handleDropLoadClick() {
        const validFields = FieldsManager.validateFields(dropTrailerFields);
        if (FieldsManager.checkFieldsForErrors(validFields)) {
            let params = {
                LoadID: LoadID,
                Dropped: "1",
                KeepTrailer: 1,//dropTrailerFields.KeepTrailer.value ? 1 : 0,
                DriverID: DriverID.value,
                CoDriverID: CoDriverID.value,
                TruckID: TruckID.value,
                TrailerID: TrailerID.value,
                LoadStopByID: stop?.metadata?.LoadStopByID
            }
            params.ActualArrivalDateTime = validFields.ActualArrivalDate.value.split(" ")[0] + " " + timePickerValueToServerTime(validFields.ActualArrivalDateTime.value);

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: resourceName,
                piggyResource: Resources.LoadInfo,
                query: getQuery(),
                errorMessage: true,
                successMessage: 'Load dropped successfully',
            }))
        } else {
            setDropTrailerFields(Object.assign({}, validFields));
        }
    }

    function handleContinueDroppedLoadClick() {
        const validFields = FieldsManager.validateFields(continueLoadFields);

        if (FieldsManager.checkFieldsForErrors(validFields)) {
            let params = {
                LoadID: LoadID,
                Dropped: "0",
                DriverID: continueLoadFields.DriverID.value?.value,
                CoDriverID: continueLoadFields.CoDriverID.value?.value,
                TruckID: continueLoadFields.TruckID.value?.value,
                TrailerID: continueLoadFields.TrailerID.value?.value,
                LoadStopByID: stop?.metadata?.LoadStopByID
            }
            params.ActualDepartureDateTime = validFields.ActualDepartureDate.value.split(" ")[0] + " " + timePickerValueToServerTime(validFields.ActualDepartureDateTime.value);

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: resourceName,
                piggyResource: Resources.LoadInfo,
                query: getQuery(),
                errorMessage: true,
                successMessage: 'Load continued successfully',
            }))
        } else {
            setContinueLoadFields(Object.assign({}, validFields));
        }
    }

    function handleInputChange(name, value) {
        setDropTrailerFields(Object.assign({}, FieldsManager.updateField(dropTrailerFields, name, value)));
    }

    function handleContinueDroppedInputChange(name, value) {
        const continueLoadFieldsClone = continueLoadFields;
        continueLoadFieldsClone[name].errorMessage = "";
        setContinueLoadFields(Object.assign({}, FieldsManager.updateField(continueLoadFieldsClone, name, value)));
    }

    function getDropLoadFields() {
        return {
            ActualArrivalDate: new Field('ActualArrivalDate', '', ['empty'], false, 'datetime', {
                addContainerClass: 'col-start-1 w-[calc(100%+1rem+1px)] col-span-4',
                label: "time_in"
            }, {isClearable: true, addClass: "rounded-r-none"}),
            ActualArrivalDateTime: new Field('ActualArrivalDateTime', '', [''], false, 'time-custom', {
                addContainerClass: "col-span-4",
                hideLabel: true
            }, {addClass: "rounded-l-none"}),
            /*
            KeepTrailer: new Field('KeepTrailer', "", [""], false, 'checkbox', {
                addContainerClass: "col-span-full col-start-1"
            })*/
        }
    }

    function getContinueLoadFields() {
        return {
            ActualDepartureDate: new Field('ActualDepartureDate', toFrontDate(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)), ['empty'], false, "date", {
                addContainerClass: "relative text-tm-gray-900 col-start-1 w-[calc(100%+1rem+1px)] col-span-4",
                label: "time_out"
            }, {addClass: "rounded-r-none"}),
            ActualDepartureDateTime: new Field('ActualDepartureDateTime', '', ['empty'], false, "time-custom", {
                addContainerClass: "col-span-4",
                hideLabel: true
            }, {addClass: "rounded-l-none"}),

            DriverID: new Field('DriverID', "", ["empty"], false, 'select-search', {
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            CoDriverID: new Field('CoDriverID', "", [""], false, 'select-search', {
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            TruckID: new Field('TruckID', "", ["empty"], false, 'select-search', {
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', "", [""], false, 'hidden', {
                addContainerClass: "col-span-6"
            }, {isClearable: true})
        }
    }

    useEffect(() => {
        if (resource.resource === resourceName && resource.update) {
            setIsConfirmModalVisible(false);
            setIsConfirmContinueModalVisible(false);
        }
    }, [resource]);

    return <>
        {
            !isLoading
            && !IsDropped
            && checkPerm(resourceName, UPDATE_PERM)
            && (initialLoadStatusID === LOAD_STATUS_DISPATCHED || initialLoadStatusID === LOAD_STATUS_IN_TRANSIT)
            && (
                <Tooltip disabled={!isLoadDirty} content={translate("text.save_changes_first")}>
                    <span
                        className="w-full">
                        <button
                            ref={innerRef}
                            className={buttonClass ?? 'btn btn-text w-full hover:bg-tm-gray-100 p-3 justify-start text-base'}
                            onClick={() => setIsConfirmModalVisible(true)}
                            disabled={isLoading || isLoadDirty}
                        >
                            <PauseCircleIcon className="w-5 h-5 mr-1 -ml-1"/>
                            {translate('btn.drop_load')}
                        </button>
                    </span>
                </Tooltip>
            )
        }

        {
            !isLoading
            && !!IsDropped
            && checkPerm(resourceName, UPDATE_PERM)
            && (initialLoadStatusID === LOAD_STATUS_DISPATCHED || initialLoadStatusID === LOAD_STATUS_IN_TRANSIT)
            && (
                <Tooltip disabled={!isLoadDirty} content={translate("text.save_changes_first")}>
                  <span
                      className="w-full">
                        <button
                            className={buttonClass ?? 'btn btn-text w-full hover:bg-tm-gray-100 p-3 justify-start text-base'}
                            disabled={isLoading || isLoadDirty}
                            onClick={() => {
                                setIsConfirmContinueModalVisible(true);
                            }}
                        >
                            <PlayCircleIcon className="w-5 h-5 mr-1 -ml-1"/>
                            {translate('btn.continue_dropped_load')}
                        </button>
                    </span>
                </Tooltip>
            )
        }

        <ModalConfirm
            show={isConfirmModalVisible}
            type={"warning"}
            onClose={() => setIsConfirmModalVisible(false)}
            onConfirm={handleDropLoadClick}
            buttonLabel={translate("text.confirm")}
            closeButtonLabel={translate("text.cancel")}
            title={translate('modal_heading.confirm_drop_load')}
            text={translate('text.drop_load_confirm_msg')}
            buttonDisabled={isLoading}
            translate={translate}
        >
            <div className="pt-5 grid grid-cols-12 gap-4 relative">
                {isLoading && (
                    <Loader stripesBg={true}/>
                )}

                <FieldsToHtml
                    onInputChange={handleInputChange}
                    fieldsState={dropTrailerFields}
                    translate={translate}
                />
            </div>
        </ModalConfirm>

        <ModalConfirm
            show={isConfirmContinueModalVisible}
            type={"warning"}
            onClose={() => setIsConfirmContinueModalVisible(false)}
            onConfirm={handleContinueDroppedLoadClick}
            buttonLabel={translate("text.confirm")}
            buttonDisabled={isLoading}
            closeButtonLabel={translate("text.cancel")}
            title={translate('modal_heading.confirm_continue_dropped_load')}
            text={translate('text.confirm_continue_dropped_load')}
            translate={translate}
        >
            <div className="pt-5 grid grid-cols-12 gap-4 relative">
                <FieldsToHtml
                    onInputChange={handleContinueDroppedInputChange}
                    fieldsState={continueLoadFields}
                    translate={translate}
                    selects={getLoadSelects()}
                />

                {isLoading && (
                    <Loader stripesBg={true}/>
                )}
            </div>
        </ModalConfirm>
    </>
}