const secondTaskReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_TASK_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_TASK_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_TASK_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPDATE_TASK_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'DONE_UPDATE_TASK_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null,
                updated: action.data?.id
            });
        case 'ERROR_UPDATE_TASK_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CLEAR_TASK_RESOURCE':
            return Object.assign({}, state, {
                data: null,
            });
        case 'COUNT_TASK_RESOURCE':
            return Object.assign({}, state, {
                count: null,
                isCountLoading: true,
                countError: false,
                countErrorMessage: null
            });
        case 'DONE_COUNT_TASK_RESOURCE':
            return Object.assign({}, state, {
                count: action.data,
                isCountLoading: false,
                countError: false,
                countErrorMessage: null
            });
        case 'ERROR_COUNT_TASK_RESOURCE':
            return Object.assign({}, state, {
                count: null,
                isCountLoading: false,
                countError: true,
                countErrorMessage: action.data
            });
        case 'CLEAR_COUNT_TASK_RESOURCE':
            return Object.assign({}, state, {
                count: null,
            });
        case 'CREATE_TASK_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'DONE_CREATE_TASK_RESOURCE':
            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null,
                showRedirectDialog: action.data.showRedirectDialog
            });
        case 'ERROR_CREATE_TASK_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        default:
            return state;
    }
};

export default secondTaskReducer;
