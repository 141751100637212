import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    ACCOUNT_CREDIT_CARD,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {cloneDeep, scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import {ArchiveBoxIcon, EyeIcon, PencilIcon} from '@heroicons/react/24/outline'
import Dropzone from "react-dropzone";
import {uploadDocument} from "../../../data/actions/download";
import {createDialogResource} from "../../../data/actions/dialogResource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../common/components/resource-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import {LoaderLarge} from "../../../common/components/loader";
import DocumentCard from "../../../common/components/card/document-card";
import Button from '../../../common/components/button'
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

class CreditAccountsPageView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'CreditName',
            sort: 'ASC',
            limit: 50,

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            importFields: this.getImportFields(),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            isColumnsDialogVisible: false,

            // Dialogs
            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            isImportDataModalOpen: false,
            isPreviewImportDataModalOpen: false,

            rawData: [],
            data: [],
            files: [],
            numOfCols: 0,

            canSubmit: false,
            breakpoint: {}
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.isPreviewImportDataModalOpen !== this.state.isPreviewImportDataModalOpen) {
            this.setState({
                importFields: this.getImportFields()
            })
        }

        if (!prevProps.download.data && this.props.download.data) {

            let formattedData = this.props.download.data.map(row => {
                    return row.reduce((memo) => {
                        memo["TransactionDate"] = row[0]
                        memo["PostedDate"] = row[1]
                        memo["Description"] = row[2]
                        memo["Debit"] = row[3]
                        memo["Credit"] = row[4]
                        return memo
                    }, {})
                }
            )

            formattedData = formattedData.filter(row => !this.isRowEmpty(Object.values(row)))

            this.setState({
                rawData: this.props.download.data,
                data: formattedData,
                numOfCols: this.props.download.data[0].length,
                isPreviewImportDataModalOpen: true
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    handleSubmitDocument = () => {
        this.props.dispatch(uploadDocument({
            user: LocalStorage.get('user'),
            resource: Resources.ExcelPreview,
            files: this.state.files
        }))
        this.setState({
            isImportDataModalOpen: false,
            files: []
        })
    }

    handleConfirmImport = () => {
        this.setState({
            importFields: FieldsManager.validateFields(this.state.importFields)
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.importFields)) {
                this.props.dispatch(createDialogResource({
                    user: LocalStorage.get('user'),
                    resource: Resources.AccountingCreditAccountsImport,
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.importFields), {
                        Items: this.state.rawData
                    }),
                    query: this.getQuery(),
                    errorMessage: true,
                    successMessage: this.props.translate("text.import_success")
                }))
                this.setState({
                    isPreviewImportDataModalOpen: false
                })
            } else {
                scrollErrorIntoViewFields(this.state.importFields)
            }
        })
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_archive_generic') + ` ${item.CreditName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {CreditAccountID: item['CreditAccountID']}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: this.props.translate(`message.credit_account_archived`),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            })
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.CreditName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            CreditAccountID: item['CreditAccountID'],
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: this.props.translate(`message.restored_generic`),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            })
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleImportFieldsInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.importFields, name, value)
        this.setState({importFields: fields})
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleImportListModal = () => {
        this.setState({
            isImportPreviewListModalOpen: !this.state.isImportPreviewListModalOpen
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleOpenImportDataModal = () => {
        this.setState({
            isImportDataModalOpen: true
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
        });
    }

    handleViewInfoPage = (item = null) => {
        this.props.history.push(`/accounting/credit-cards/${item.CreditAccountID}`)
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            CreditName: new Field('CreditName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}, {}),
            CreditNumber: new Field('CreditNumber', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}, {}),
            CreditLimit: new Field('CreditLimit', '', [''], false, 'money', {addContainerClass: 'col-span-full'}, {}),
            LastRechargeDate: new Field('LastRechargeDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            ExpiryDate: new Field('ExpiryDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}, {})
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getCreateFields = (item = null) => {
        const fieldTemplates = {
            CreditName: new Field('CreditName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}, {}),
            CreditNumber: new Field('CreditNumber', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}, {}),
            CreditLimit: new Field('CreditLimit', '', [''], false, 'money', {addContainerClass: 'col-span-full'}, {}),
            ExpiryDate: new Field('ExpiryDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}, {})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getEditFields = (item = null) => {
        const fieldTemplates = {
            CreditAccountID: new Field('CreditAccountID', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }, {}),
            CreditName: new Field('CreditName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}, {}),
            CreditNumber: new Field('CreditNumber', '', ['empty'], true, 'text', {
                addContainerClass: 'col-span-full'
            }, {}),
            CreditLimit: new Field('CreditLimit', '', [''], false, 'money', {addContainerClass: 'col-span-full'}, {}),
            ExpiryDate: new Field('ExpiryDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}, {})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getPreviewFields = () => {
        return {
            TransactionDate: new Field('TransactionDate', '', [], false, 'text', {label: "CreditAccountsTransactionDate"}),
            PostedDate: new Field('PostedDate', '', [], false, 'text', {label: "PostedDate"}),
            Description: new Field('Description', '', [], false, 'text'),
            Debit: new Field('Debit', '', [], false, 'text'),
            Credit: new Field('Credit', '', [], false, 'text')
        }
    }

    getImportFields = () => {
        return {
            OfficeID: new Field('OfficeID', "", ['empty'], false, 'select-search', {addContainerClass: 'col-span-6'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-6'}),
            CreditAccountID: new Field('CreditAccountID', "", ['empty'], false, 'select-search', {addContainerClass: 'col-span-6'}),
            OrganizationID: new Field('OrganizationID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-6'}),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getResource = () => {
        return Resources.AccountingCreditAccounts
    }

    isRowEmpty = (row) => {
        function isEmptyString(item) {
            return item === ''
        }

        return row.every(isEmptyString)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const document = this.state.files[0]

        const importFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.importFields)), translate, this.handleImportFieldsInputChange, {
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName + " - " + (item.AddressName ?? "")
                        + ", " + (item.CityName ?? "")
                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                        + (item.PostalCode ?? ""),
                    value: item.OrganizationID
                })
            },
            CreditAccountID: {
                api: 'api/' + Resources.AccountingCreditAccounts,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.AccountName,
                    value: item.CreditAccountID
                })
            }
        })


        const metadata = {
            AccountID: {
                api: 'api/accounting/accounts',
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_LIABILITY,
                    AccountTypeID: ACCOUNT_CREDIT_CARD
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <div className="space-y-4 lg:col-span-12">
                    <PageHeader
                        title={translate('page.heading.accounting_credit_accounts')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <div className={'flex mr-4'}>
                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>

                            <Button
                                hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                                className={`btn btn-outline-secondary mr-4`}
                                onClick={() => {
                                    this.setState({
                                        isPreviewImportDataModalOpen: false
                                    })
                                }}
                            >
                                {translate('text.import_data')}
                            </Button>

                            <Button
                                hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                                className={`btn btn-primary`}
                                onClick={this.handleToggleCreateModal}
                            >
                                {translate('btn.create_new')}
                            </Button>
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRestore={checkPerm(this.getResource(), DELETE_PERM) ? this.restoreFromArchive : null}
                            onRowClick={this.handleViewInfoPage}

                            actions={[
                                {
                                    action: (item) => this.handleViewInfoPage(item),
                                    icon: EyeIcon, // make this a function
                                    visible: () => checkPerm(this.getResource(), READ_PERM),
                                    label: false, // make this a function
                                    title: translate('btn.view'),
                                },
                                {
                                    action: (item) => this.handleToggleEditModal(item),
                                    icon: PencilIcon, // make this a function
                                    visible: () => checkPerm(this.getResource(), UPDATE_PERM),
                                    label: false, // make this a function
                                    title: translate('btn.edit')
                                },
                                {
                                    action: this.archiveResource,
                                    icon: ArchiveBoxIcon, // make this a function
                                    visible: (item) => (!item.IsPayed && !item.ArchivedDate),
                                    label: false, // make this a function
                                    title: translate('text.archive'),

                                },
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('btn.create_new')}
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleToggleCreateModal()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <TableOptionsDialog
                        show={this.state.columnsModalOpen}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <ModalSaveResource
                        show={!!this.state.createModalOpen}
                        title={'Create credit account'}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        errorMessage={this.state.errorMessage}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getCreateFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Credit account created.',
                                }))
                                this.handleToggleCreateModal()
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        show={!!this.state.editModalOpen}
                        title={'Edit credit account'}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        errorMessage={this.state.errorMessage}
                        onClose={this.handleToggleEditModal}
                        fields={this.getEditFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Credit account updated.',
                                }))
                                this.handleToggleEditModal()
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalDefault
                        show={this.state.isImportDataModalOpen}
                        title={'Import data'}
                        widthClass="max-w-xl"
                        buttonLabel={'Submit document'}
                        onButtonClick={this.handleSubmitDocument}
                        buttonDisabled={!this.state.files.length}
                        onClose={() => this.setState({
                            isImportDataModalOpen: false,
                            files: []
                        })}
                    >
                        <div className="m-4 p-4">
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    this.setState({
                                        files: acceptedFiles,
                                        canSubmit: true
                                    })
                                }}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDropAccepted={this.onDrop}
                                multiple={false}
                                maxFiles={1}
                                accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}
                                             className={'border-2 flex items-center justify-center h-20' + (this.state.dropzoneActive ? ' border-primary border-solid text-primary bg-sky-100' : ' border-tm-gray-200 border-dashed text-tm-gray-700')}>
                                            <input {...getInputProps()} />

                                            <p className="select-none text-tm-gray-500">{translate("field.drag_n_drop")}</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        {!!this.state.files.length && (
                            <div>
                                <DocumentCard
                                    layoutSmall={true}
                                    fileName={document?.name}
                                    onDeleteDocument={() => this.setState({
                                        files: [],
                                        canSubmit: false
                                    })}
                                    translate={this.props.translate}
                                />
                            </div>
                        )}
                    </ModalDefault>

                    {this.props.download.isLoading ? (
                            <LoaderLarge/>
                        ) :
                        <ModalDefault
                            show={this.state.isPreviewImportDataModalOpen}
                            title={'Preview data to import'}
                            widthClass="max-w-full"
                            buttonLabel={'Confirm import'}
                            onButtonClick={this.handleConfirmImport}
                            onClose={() => this.setState({
                                isPreviewImportDataModalOpen: false
                            })}
                            limitHeight={true}
                        >
                            <div className={"grid gap-4 grid-cols-12 py-6 mx-6"}>
                                {importFieldsHtml}
                            </div>

                            <ResourceTable
                                data={this.state.data ?? []}
                                count={this.state.data?.length ?? 10}

                                /*commonTable={true}*/

                                maxHeightClass={'h-dialog-body'}
                                fields={this.getPreviewFields()}

                                translate={this.props.translate}

                                limit={this.state.queryFilterFields.limit}
                                offset={this.state.offset}
                                page={this.state.paginationPage}

                            />
                        </ModalDefault>
                    }

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />
                </div>
            </Layout>
        )
    }
}

export default connect(state => state)(CreditAccountsPageView)
