import {Component} from 'react'

export default class TripStopsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return 'Stops Tab';
    }
}