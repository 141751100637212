import React from "react";
import { getStopPalletData } from "../dialog/load-complete-stop-dialog";
import ViewColumnsIcon from "@heroicons/react/20/solid/ViewColumnsIcon";

export default function PalletsNumberWarning({selectedLocation, commodityData, defaultWeightUnit, stops, translate}) {
    const palletsData = !!selectedLocation && getStopPalletData(selectedLocation, commodityData, defaultWeightUnit, stops);
    const doLoadedAndPlannedNumbersMatch = palletsData.loadedTotal === palletsData.totalPalletsPlanned;
    const doUnLoadedAndPlannedNumbersMatch = palletsData.unLoadedTotal === palletsData.totalPalletsPlanned;
    const areAllStopsCompleted = !stops.find(stop => stop?.StopID?.value?.metadata?.IsStopCompleted === undefined ||
        stop?.StopID?.value?.metadata?.IsStopCompleted === 0)
    const doLoadedAndUnLoadedMatch = palletsData.loadedTotal === palletsData.unLoadedTotal;

    if (!areAllStopsCompleted || (doUnLoadedAndPlannedNumbersMatch && doLoadedAndPlannedNumbersMatch && doLoadedAndUnLoadedMatch)) {
        return null;
    }

    let commodityUnitType = undefined;

    commodityData.forEach(commodity => {
        if (commodityUnitType === undefined) {
            commodityUnitType = commodity.UnitType;
        } else {
            commodityUnitType = commodityUnitType === commodity.UnitType ? commodityUnitType : translate("text.unit_s");
        }
    })

    if (!commodityUnitType) {
        commodityUnitType = translate("text.unit_s");
    }

    commodityUnitType = commodityUnitType.toLowerCase();

    return (
        <div
            className="flex gap-2 py-2 bg-red-600/10 hover:bg-red-600/20 px-2 rounded-md relative"
        >
            <ViewColumnsIcon className="w-5 h-5 text-red-600"/>

            <div>
                <p>{translate("text.pallets_number_do_not_match", [commodityUnitType])}</p>
                {!doLoadedAndPlannedNumbersMatch && (
                    <p>{translate("text.pallets_number_do_not_match_line_2", [palletsData.loadedTotal, palletsData.totalPalletsPlanned])}</p>
                )}

                {!doUnLoadedAndPlannedNumbersMatch && (
                    <p>{translate("text.pallets_number_do_not_match_line_4", [palletsData.unLoadedTotal, palletsData.totalPalletsPlanned])}</p>
                )}

                {!doLoadedAndUnLoadedMatch && (
                    <p>{translate("text.pallets_number_do_not_match_line_3", [palletsData.loadedTotal, palletsData.unLoadedTotal])}</p>
                )}
            </div>
        </div>
    )
}