import React from "react";
import {classNames} from "../../../../util/util-helpers";

export default function PageResourceTag({children, onClick, bgClass = "bg-primary"}) {
    const ElementTag = (onClick) ? 'button' : 'div';

    return (
        <ElementTag
            onClick={onClick}
            className={
                classNames(
                    "px-2 text-primary-contrast rounded-card text-base font-semibold",
                    bgClass,
                    onClick ? "hover:bg-primary-shade" : undefined
                )
            }
        >
            <div className="flex items-center gap-2 py-1">
                {children}
            </div>
        </ElementTag>
    )
}