import React from "react";
import UpcomingLoadCard from "./upcoming-load-card";

export default function UpcomingLoads({upcomingLoads}) {
    return (
        upcomingLoads
            .map(
                upcomingLoad => <UpcomingLoadCard upcomingLoad={upcomingLoad}/>
            )
    )
}