import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    ACCOUNT_TYPE_CASH,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_ASSET,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    PAYMENT_METHOD_CACHE,
    READ_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    longTableColumn,
    mergeDeep, processFloat,
    resourceIsCreated,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {CreditCardIcon, EyeIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {deleteSecondResource, getSecondResource} from '../../../data/actions/secondResource'
import moment from 'moment-timezone'
import {numberWithCommasToBack} from '../../../util/util-formaters'
import ReviewLoanModal from "./review-loan-modal";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {calculateNextLoanPaymentPerDay} from "../../../common/util/util-accounting";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from '../../../common/components/button'
import {UPDATE_PERM} from "../../../common/util/util-consts";

class LiabilitiesTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_liabilities'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Date',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            selectedItem: {},
            isTableFullScreen: false,

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialogs
            isMonthlyBreakdownOpen: false,
            isSendPaymentDialogOpen: false,
            isSendDownPaymentDialogOpen: false,
            createModalOpen: false,
            editModalOpen: false,
            confirmDialog: false,
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))

        this.selects = {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {AccountTypeID: ACCOUNT_TYPE_CASH},
                searchMap: (it) => ({
                    label: it.AccountName,
                    value: it.AccountID
                })
            },
            PaymentTypeID: getLookup('PaymentType', 'PaymentTypeID', 'PaymentType'),
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            LoanOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName,
                    value: item.OrganizationID
                })
            },
            InterestExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
            LiabilityAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_LIABILITY, NotePayable: 1},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
            BankAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_ASSET},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            }
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsCreated(this.props.resource, prevProps.resource)) {
            this.handleCloseSendPaymentDialog()
            this.handleCloseSendDownPaymentDialog()
        }

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchInvoiceDialogData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                ExpenseID: this.state.selectedItem.ExpenseID
            },
            resource: Resources.ExpenseExpense
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'expenses_liabilities_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_delete_generic', [item.LoadName])}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {
                            LongTermPaymentsID: item.LongTermPaymentsID
                        }),
                        errorMessage: true,
                        successMessage: this.props.translate(`text.recurring_deleted_succesfully`),
                        resource: this.getLiabilityResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'Date'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleToggleShowDetails = (item) => {
        this.setState({
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
            selectedItem: item
        })
    }

    handleOpenSendPaymentDialog = (item = {}) => {
        this.setState({
            isSendPaymentDialogOpen: true,
            selectedItem: item
        })
    }

    handleCloseSendPaymentDialog = () => {
        this.setState({
            isSendPaymentDialogOpen: false
        })
    }

    handleOpenSendDownPaymentDialog = (item = {}) => {
        this.setState({
            isSendDownPaymentDialogOpen: true,
            selectedItem: item
        })
    }

    handleCloseSendDownPaymentDialog = () => {
        this.setState({
            isSendDownPaymentDialogOpen: false
        })
    }

    handleShowMonthlyBreakdownClick = (item = {}) => {
        this.setState({
            isMonthlyBreakdownOpen: !this.state.isMonthlyBreakdownOpen,
            selectedItem: item
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleToggleEditModal = (item = {}) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getQuery = () => {
        const IsPayed = this.state.queryFilterFields.IsPayed.value;
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                LoanOrganizationID: this.state.queryFilterFields.LoanOrganizationID.value?.value,
                LiabilityAccountID: this.state.queryFilterFields.LiabilityAccountID.value?.value,
                IsPayed: IsPayed == "-1" ? null : [
                    'IsPayed', '=', IsPayed
                ],
            })
        }
    }

    getEditFields = (item) => {
        let fieldTemplates = {
            LoadName: new Field('LoadName', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Description: new Field('Description', '', [], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    return (
                        <div>{longTableColumn(item.Description)}</div>
                    )
                }
            }),
            InterestRate: new Field('InterestRate', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{parseFloat(item.InterestRate).toFixed(4)} % yearly</div>
                )
            }),
            MonthlyPayment: new Field('MonthlyPayment', '', [''], false, 'money', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            UsedAmount: new Field('UsedAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                label: 'principal_left',
                hideDialog: true
            }),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),

            // Payments made

            UsedExpenseAmount: new Field('UsedExpenseAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                label: 'interest_left',
                hideDialog: true
            }),
            LastPaidDate: new Field('LastPaidDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            LastPaidAmount: new Field('LastPaidAmount', '', [''], false, 'money', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            PaymentCounter: new Field('PaymentCounter', '', [], false, 'custom', {
                addContainerClass: 'col-span-full',
                hideDialog: true,
                render: (item) => (
                    <div>Paid {item.PaymentCounter} rates {item.DownPaymentPaid ? '(Paid down payment)' : item.DownPayment > 0 ? '(Down payment not paid)' : ''}</div>
                )
            }),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'},),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {})
        }
        fieldTemplates = fillFieldsFromData(fieldTemplates, item)
        return fieldTemplates
    }

    getFields = (item) => {
        let fieldTemplates = {
            LoanOrganizationID: new Field('LoanOrganizationID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full'
            }),
            LoadName: new Field('LoadName', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Description: new Field('Description', '', [], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    return (
                        <div>{longTableColumn(item.Description)}</div>
                    )
                }
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            }),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                addContainerClass: 'col-span-full'
            }),
            ResidualValue: new Field('ResidualValue', 0, ['empty'], false, 'money', {
                addContainerClass: 'col-span-full'
            }),
            DownPayment: new Field('DownPayment', '', ['empty'], false, 'money', {
                addContainerClass: 'col-span-full'
            }),
            LoanTerm: new Field('LoanTerm', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{item.LoanTerm} months</div>
                )
            }),
            InterestRate: new Field('InterestRate', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{parseFloat(item.InterestRate).toFixed(4)} % yearly</div>
                )
            }),
            MonthlyPayment: new Field('MonthlyPayment', '', [''], false, 'money', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            // Payments made
            UsedAmount: new Field('UsedAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                label: 'principal_left',
                hideDialog: true
            }),
            UsedExpenseAmount: new Field('UsedExpenseAmount', '', [], false, 'money', {
                addContainerClass: 'col-span-full',
                label: 'interest_left',
                hideDialog: true
            }),
            LastPaidDate: new Field('LastPaidDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            LastPaidAmount: new Field('LastPaidAmount', '', [''], false, 'money', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            PaymentCounter: new Field('PaymentCounter', '', [], false, 'custom', {
                addContainerClass: 'col-span-full',
                hideDialog: true,
                render: (item) => (
                    <div>Paid {item.PaymentCounter} rates {item.DownPaymentPaid ? '(Paid down payment)' : item.DownPayment > 0 ? '(Down payment not paid)' : ''}</div>
                )
            }),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            WriteInitialEntry: new Field('WriteInitialEntry', 1, [], false, 'checkbox', {
                addContainerClass: 'col-span-full',
            }),
            BankAccountID: new Field('BankAccountID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: 'BankAssetAccountID'
            }),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'},),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {}),
            LiabilityAccountID: new Field('LiabilityAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            InterestExpenseAccountID: new Field('InterestExpenseAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
        }
        fieldTemplates = fillFieldsFromData(fieldTemplates, item)
        return fieldTemplates
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            LoanOrganizationID: new Field('LoanOrganizationID', '', [''], false, 'select-search', {}, {isClearable: true}),

            LiabilityAccountID: new Field('LiabilityAccountID', '', [''], false, 'select-search', {}, {isClearable: true}),

            IsPayed: new Field('IsPayed', '-1', [''], false, 'button-group', {
                data: {
                    "-1": "All",
                    "0": "No",
                    "1": "Yes"
                },
                hideTable: true,
                addContainerClass: 'col-span-full',
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getSendPaymentFields = (item = {}, downpayment = false) => {
        let fields = {
            LongTermPaymentsID: new Field('LongTermPaymentsID', item.LongTermPaymentsID, [], true, 'hidden'),
            NotFixed: new Field('NotFixed', '', [], downpayment, downpayment ? 'hidden' : 'checkbox', {
                label: 'customPayment',
                addContainerClass: 'col-span-full'
            }),
            CustomPrincipalInterest: new Field('CustomPrincipalInterest', '0', [], true, downpayment ? 'hidden' : 'checkbox', {
                addContainerClass: 'col-span-full'
            }),
            PaymentTypeID: new Field('PaymentTypeID', PAYMENT_METHOD_CACHE, ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            Amount: new Field('Amount', downpayment ? item.DownPayment : item.MonthlyPayment, ['empty'], true, 'money', {addContainerClass: 'col-span-full'}),
            AmountPrincipal: new Field('AmountPrincipal', 0, [], true, downpayment ? 'hidden' : 'money', {addContainerClass: 'col-span-full'}),
            AmountExpense: new Field('AmountExpense', 0, [], true, downpayment ? 'hidden' : 'money', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', downpayment ? moment(item.LastPaidDate).format(DEFAULT_DATABASE_DATETIME_FORMAT) : moment(item.LastPaidDate).add(1, 'month').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], true, 'date', {addContainerClass: 'col-span-full sm:col-span-2'}),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full sm:col-span-4'}),
            Description: new Field('Description', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        const result = this.getInterestForFields(fields, item)
        fields.AmountPrincipal.value = result.principalPayment
        fields.AmountExpense.value = result.interestPayment
        return fields
    }

    getResource = () => {
        return Resources.ExpenseLiabilities
    }

    getLiabilityResource = () => {
        return Resources.ExpenseLiabilitiesLoan
    }

    getInterestForFields = (fields, selectedItem) => {
        const principalLeft = selectedItem.UsedAmount // Principal amount left
        const yearlyInterestRate = selectedItem.InterestRate
        const numberOfDays =
            !fields.NotFixed.value ? 30 : (
                moment(selectedItem.LastPaidDate).diff(fields.Date.value, 'days')
            ) // Payment made after a certain number of days

        return calculateNextLoanPaymentPerDay(
            principalLeft,
            yearlyInterestRate,
            numberWithCommasToBack(fields?.Amount?.value ?? 0),
            Math.abs(numberOfDays),
            moment().year());
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = getProp(resource, 'isLoading', false)

        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                    <div className={'flex ml-auto'}>
                        <div className={'flex mr-4'}>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(Resources.ExpenseLiabilities, CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>

                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}

                        selects={this.selects}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={this.handleShowMonthlyBreakdownClick} // handleToggleShowDetails
                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.handleShowMonthlyBreakdownClick,
                                icon: EyeIcon,
                                visible: (item) => item.Amount > 0 && item.InterestRate >= 0 && item.LoanTerm > 0,
                                hasPerm: checkPerm(Resources.ExpenseExpense, READ_PERM),
                                title: translate('text.show_details'),
                            },
                            {
                                action: this.handleOpenSendDownPaymentDialog,
                                icon: CreditCardIcon,
                                visible: (item) => !item.IsPayed && (!item.DownPaymentPaid && item.DownPayment > 0),
                                hasPerm: checkPerm(Resources.ExpenseLiabilitiesDownpayment, CREATE_PERM),
                                title: translate('text.send_downpayment'),
                            },
                            {
                                action: this.handleOpenSendPaymentDialog,
                                icon: CreditCardIcon,
                                visible: (item) => !item.IsPayed && (item.DownPaymentPaid || !item.DownPayment),
                                hasPerm: checkPerm(Resources.ExpenseLiabilities, CREATE_PERM),
                                title: translate('text.send_payment'),
                            },
                            {
                                action: this.handleToggleEditModal,
                                icon: PencilIcon, // make this a function
                                visible: (it) => !it.ArchivedDate,
                                hasPerm: checkPerm(this.getLiabilityResource(), UPDATE_PERM),
                                label: false, // make this a function
                                title: translate('btn.edit'),
                                order: 30
                            },
                            {
                                action: this.archiveResource,
                                icon: TrashIcon, // make this a function
                                visible: (it) => !it.ArchivedDate && it.PaymentCounter === 0,
                                hasPerm: checkPerm(this.getLiabilityResource(), DELETE_PERM),
                                label: false, // make this a function
                                title: translate('btn.delete'),
                                order: 40
                            }
                        ]}

                        tableKey={'ExpenseID'}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-7xl w-screen'}
                    title={translate('text.ExpenseID')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        type="expense"

                        disableComments={true}
                        canDeletePayment={true}
                        disableAddReference={false}
                        disableTransactionImport={true}


                        showLoadReferenceLinks

                        onDocumentDownload={(item) => {
                            const fileExt = item?.AttachPath.split('.').pop()

                            this.props.dispatch(download({
                                user: LocalStorage.get('user'),
                                resource: Resources.ExpenseDocuments,
                                query: Object.assign({
                                    id: item.ExpenseID,
                                    format: fileExt,
                                    name: 'document_' + currentDate() + '.' + fileExt
                                })
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchInvoiceDialogData()
                            }
                        }}

                        onDeletePayment={(item) => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    SendPaymentID: item.SendPaymentID
                                },
                                resource: Resources.ExpensePayment,
                                secondPiggyResource: Resources.ExpenseExpense,
                                secondPiggyQuery: {
                                    ExpenseID: this.state.selectedItem.ExpenseID
                                },
                                errorMessage: true,
                                successMessage: translate('text.PaymentDeletedSuccessfully')
                            }))
                        }}
                    />
                </ModalDefault>

                <ReviewLoanModal
                    show={this.state.isMonthlyBreakdownOpen}
                    selectedItem={this.state.selectedItem}
                    cachedChangedFields={this.getFields(this.state.selectedItem)}
                    onClose={this.handleShowMonthlyBreakdownClick}
                    translate={translate}
                />

                {/* Liabilities pay downpayment dialog */}
                <ModalSaveResource
                    show={this.state.isSendDownPaymentDialogOpen}
                    title={translate('text.send_downpayment')}
                    widthClass="max-w-3xl"
                    onClose={this.handleCloseSendDownPaymentDialog}
                    fields={this.getSendPaymentFields(this.state.selectedItem, true)}
                    isLoading={isLoading}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.ExpenseLiabilitiesDownpayment,
                                piggyResource: Resources.ExpenseLiabilities,
                                errorMessage: true,
                                successMessage: this.props.translate('text.payment_sent_successfully'),
                            }))
                        }
                    }}
                    metadata={this.selects}
                    translate={translate}
                />

                {/* Liabilities pay dialog */}
                <ModalSaveResource
                    show={this.state.isSendPaymentDialogOpen}
                    title={translate('text.send_payment')}
                    widthClass="max-w-3xl"
                    onClose={this.handleCloseSendPaymentDialog}
                    fields={this.getSendPaymentFields(this.state.selectedItem, false)}
                    isLoading={isLoading}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: Resources.ExpenseLiabilities,
                                piggyResource: Resources.ExpenseLiabilities,
                                errorMessage: true,
                                successMessage: this.props.translate('text.payment_sent_successfully'),
                            }))
                        }
                    }}
                    metadata={this.selects}
                    translate={translate}

                    handleInputChange={(fields, name, value,) => {
                        let newFields = FieldsManager.updateField(fields, name, value)
                        if (name === 'NotFixed') {
                            newFields.Amount.disabled = !value
                            newFields.Date.disabled = !value
                            newFields.CustomPrincipalInterest.disabled = !value
                            if (!value) {
                                newFields.CustomPrincipalInterest.value = '0'
                                newFields.AmountPrincipal.disabled = !value
                                newFields.AmountExpense.disabled = !value
                            }
                        }
                        if (name === 'CustomPrincipalInterest') {
                            newFields.Amount.disabled = value
                            newFields.AmountPrincipal.disabled = !value
                            newFields.AmountExpense.disabled = !value
                        }
                        if (!this.state.selectedItem) {
                            return null
                        }
                        if (
                            fields.CustomPrincipalInterest.value != "1"
                        ) {
                            const result = this.getInterestForFields(newFields, this.state.selectedItem)

                            newFields.AmountPrincipal.value = result.principalPayment
                            newFields.AmountExpense.value = result.interestPayment
                        } else {
                            newFields.Amount.value = parseFloat(processFloat(
                                    newFields.AmountPrincipal.value
                                )) +
                                parseFloat(processFloat(
                                    newFields.AmountExpense.value
                                  )
                                )
                        }

                        return newFields
                    }}
                />

                <ModalSaveResource
                    title={translate('text.CreateLoan')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.createModalOpen}
                    onClose={() => this.handleToggleCreateModal()}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getLiabilityResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Loan payment created.',
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.selects}
                />

                <ModalSaveResource
                    title={translate('text.EditLoan')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-6"
                    show={this.state.editModalOpen}
                    onClose={() => this.handleToggleEditModal()}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getLiabilityResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: 'Loan updated.',
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.selects}
                />

                <ModalConfirm
                    title={translate('text.confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(LiabilitiesTab)
