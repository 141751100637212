import { checkUserHelper } from "../services/api-util";
import { call, takeLatest, put } from "redux-saga/effects";
import Api from "../services/api";
import { doneUploadAutofillDocument, errorUploadAutofillDocument } from "../actions/ocrFillResource";
import { pushNotification } from "../actions/ui";

export function* uploadOcrFillDocumentCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.uploadOcrFillDocuments, user, action?.data?.file, action?.data?.templateID);

    if (result && result.status === 0) {
        yield put(doneUploadAutofillDocument(result.data));

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUploadAutofillDocument(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchUploadOcrFillDocument() {
    yield takeLatest('UPLOAD_OCR_FILL_DOCUMENT', uploadOcrFillDocumentCall);
}