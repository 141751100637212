import React from "react";
import Button from "../button";

const ModalFooter = (props) => {
    const {
        buttonLabel,
        onButtonClick,
        buttonDisabled,
        buttonID,
        middleButtonLabel,
        middleButtonID,
        middleButtonDisabled,
        onMiddleButtonClick,
        closeButtonLabel,
        onClose,
        closeButtonRef,
        secondaryButtonLabel,
        onSecondaryButtonClick,
        secondaryButtonDisabled,
        secondaryButtonHasPerm = true,
        customHtmlLeft,
        customHtmlRight,
        submitButtonRef,
        closeButtonID,
        className
    } = props;
    return (
        <footer
            className={className ?? "min-h-[4rem] flex flex-wrap flex-row-reverse items-center border-tm-gray-200 border-t px-6 py-2 gap-3 bg-popup rounded-b-lg"}>
            {(!!buttonLabel || !!closeButtonLabel) && (
                <div>
                    {!!customHtmlRight && (
                        <div className={"inline-flex mr-4"}>{customHtmlRight}</div>
                    )}

                    {!!buttonLabel && (
                        <button
                            ref={submitButtonRef}
                            data-name="submit-button-shortcut"
                            id={buttonID}
                            className={`btn btn-primary mr-2`}
                            onClick={() => {
                                if (!buttonDisabled) {
                                    onButtonClick();
                                }
                            }}
                            disabled={!!buttonDisabled}
                        >
                            {buttonLabel}
                        </button>
                    )}

                    {!!middleButtonLabel && (
                        <button
                            data-name="submit-button-shortcut"
                            id={middleButtonID}
                            className={`btn btn-primary mr-2`}
                            onClick={() => {
                                if (!middleButtonDisabled) {
                                    onMiddleButtonClick();
                                }
                            }}
                            disabled={!!middleButtonDisabled}
                        >
                            {middleButtonLabel}
                        </button>
                    )}

                    {!!closeButtonLabel && (
                        <button
                            data-name="cancel-button-shortcut"
                            id={closeButtonID}
                            className="btn btn-outline"
                            onClick={() => {
                                onClose();
                            }}
                            ref={closeButtonRef}
                        >
                            {props.closeButtonLabel}
                        </button>
                    )}
                </div>
            )}

            <span className="flex mr-auto">
                {!!secondaryButtonLabel && (
                    <Button
                        className={'btn btn-outline mr-auto'}
                        onClick={() => {
                            if (!secondaryButtonDisabled) {
                                onSecondaryButtonClick();
                            }
                        }}
                        hasPerm={secondaryButtonHasPerm}
                        disabled={!!secondaryButtonDisabled}
                    >
                        {secondaryButtonLabel}
                    </Button>
                )}

                {!!customHtmlLeft && (
                    <div>
                        {customHtmlLeft}
                    </div>
                )}

                {props.children}
            </span>
        </footer>
    )
}

export default ModalFooter;
