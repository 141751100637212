import React from 'react';
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import {classNames} from "../../../../common/util/util-helpers";
import Card from "../../../../common/components/card";

const LoadPricingMoneyCodes = (props) => {

    const { toggleCreateDialog, toggleViewDialog, translate} = props

    return (
        <div className="mt-4">
            <Card addClass={classNames(
                'col-span-2 xl:col-span-1 min-h-[3rem] flex overflow-hidden z-0 relative'
            )}
                  addBodyClass='border-0 border-l-4 border-red-700'
                  bodyClass="flex flex-col w-full grow justify-center">
                <div className="text-center px-4 py-10 pt-0">
                    <h2 className="text-lg text-tm-gray-700 py-4">
                        {translate("page.heading.MoneyCodes")}
                    </h2>
                    <div className="pt-3 flex justify-evenly items-center">
                        <button
                            type="button"
                            className="btn btn-outline"
                            onClick={toggleCreateDialog}
                        >
                            <PlusIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true"/>
                            {translate("btn.quick_create_new")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline"
                            onClick={toggleViewDialog}
                        >
                            {translate("btn.quick_view")}
                        </button>
                    </div>
                </div>
            </Card>
        </div>

    )
}

export default LoadPricingMoneyCodes