import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {
    checkPerm,
    getDefaultTableOptions,
    getProp,
    getReferenceLinks,
    openInNewTab,
    resourceIsCreated
} from "../../../../common/util/util-helpers";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import {CREATE_PERM, DEFAULT_METADATA_SELECT_SEARCH_QUERY, DELETE_PERM} from "../../../../util/util-constants";
import {createDialogResource, deleteDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import Button from "../../../../common/components/button";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../../common/components/modal/modal-default";
import ResourceTable from "../../../../common/components/resource-table";

export default function ReviewLoanModalLinksTab({selectedExpenseItem, translate}) {
    /** Constants and store
     ================================================================= */
    const dispatch = useDispatch();
    const dialogResource = useSelector(state => state.dialogResource);
    const data = getProp(dialogResource, 'data.References', [])
    const isLoading = dialogResource.isLoading;
    const tableOptions = getTableOptions("loan_modal_links", translate);

    /** State
     ================================================================= */
    const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
    const [formFields, setFormFields] = useState(() => getDialogFields());
    const [selectedItem, setSelectedItem] = useState({});
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    /** UI events
     ================================================================= */
    function onDialogInputChange(name, value) {
        let fieldsUpdate = Object.assign({}, formFields);
        if (name === 'ReferenceType') {
            fieldsUpdate.ReferenceID.type = value ? 'select-search' : 'hidden'
        }
        setFormFields(FieldsManager.updateField(fieldsUpdate, name, value));
    }

    function handleCreateReferenceClick() {
        let fieldsUpdate = Object.assign({}, formFields);
        fieldsUpdate.ReferenceID.type = 'hidden';
        fieldsUpdate.ReferenceType.value = '';

        setFormFields(fieldsUpdate);
        setIsFormDialogOpen(true);
    }

    function handleToggleConfirmReferenceDialog(item) {
        setSelectedItem(item);
        setIsConfirmModalOpen(!isConfirmModalOpen);
    }

    function handleViewClick(item) {
        if (item) {
            openInNewTab(getReferenceLinks(item));
        }
    }

    /** Helpers
     ================================================================= */
    function getTableOptions(pagePath, translate) {
        return getDefaultTableOptions(getFields(translate), {
            style: {
                floatingActions: true
            },
            behaviour: {
                rowSelect: true
            }
        }, pagePath, translate)
    }

    function getFields() {
        return {
            ReferenceID: new Field('ReferenceID', '', [], false, 'custom', {
                render: (item) => {
                    return <span>{translate('reference_type.' + item.ReferenceType)} : {item.Reference}</span>
                }
            }),
        }
    }

    function getDialogFields() {
        return {
            ReferenceType: new Field('ReferenceType', '', ['empty'], false, 'select', {addContainerClass: 'col-span-6'}, {valueSort: true}),
            ReferenceID: new Field('ReferenceID', '', ['empty'], false, 'hidden', {addContainerClass: 'col-span-6'}),
        }
    }

    function getReferenceOptions() {
        switch (formFields.ReferenceType.value) {
            case 'tbl_Load':
                return {
                    api: 'api/' + Resources.LoadsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        label: item.LoadID,
                        value: item.LoadID
                    })
                }
            case 'tbl_Truck':
                return {
                    api: 'api/' + Resources.TrucksQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.TruckID,
                        label: item.Truck
                    })
                }
            case 'tbl_Trailer':
                return {
                    api: 'api/' + Resources.TrailersQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.TrailerID,
                        label: item.Trailer
                    })
                }
            case 'tbl_Driver':
                return {
                    api: 'api/' + Resources.DriversQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.DriverID,
                        label: item.FirstName + ' ' + item.LastName
                    })
                }
            case 'tbl_Employee':
                return {
                    api: 'api/' + Resources.EmployeesQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.EmployeeID,
                        label: item.FirstName + ' ' + item.LastName
                    })
                }
            case 'tbl_Agent':
                return {
                    api: 'api/' + Resources.AgentsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.AgentID,
                        label: item.FirstName + ' ' + item.LastName
                    })
                }
            default:
                return null
        }
    }

    useEffect(() => {
        if (resourceIsCreated(dialogResource)) {
            setIsFormDialogOpen(false);
        }
    }, [dialogResource]);

    /** Render
     ================================================================= */
    return <div className="h-full">
        <div className="py-1 px-6 text-right border-b border-tm-gray-300">
            <Button
                onClick={handleCreateReferenceClick}
                appearance="primary"
                hasPerm={checkPerm(Resources.ExpenseReference, CREATE_PERM)}
            >
                Create reference
            </Button>
        </div>

        {(data.length === 0) && !isLoading && (
            <div className="h-full flex justify-center items-center">
                <NoRecordsTable
                    className="pt-12 px-6 pb-6"
                    show={true}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                />
            </div>
        )}

        <ResourceTable
            data={data}
            isLoading={isLoading}
            options={tableOptions}
            fields={getFields()}
            onRowClick={(item) => handleViewClick(item)}
            onView={handleViewClick}
            actions={[
                {
                    action: handleToggleConfirmReferenceDialog,
                    hasPerm: checkPerm(Resources.ExpenseReference, DELETE_PERM),
                    icon: TrashIcon,
                    order: 100,
                    visible: (item) => (item.ReferenceType !== 'tbl_Load') && (item.ReferenceType !== 'tbl_CarrierPay'),
                    title: translate('btn.delete'),
                }
            ]}
            translate={translate}
        />

        <ModalDefault
            show={isFormDialogOpen}
            title={translate('preview')}
            widthClass={'max-w-xl'}
            translate={translate}
            onClose={() => setIsFormDialogOpen(false)}
            closeButtonLabel={translate('btn.cancel')}
            buttonLabel={translate('btn.save')}
            onButtonClick={() => {
                dispatch(createDialogResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(formFields), {
                        Reference: formFields.ReferenceID.value.label,
                        ExpenseID: selectedExpenseItem.ExpenseID
                    })
                    ,
                    query: {ExpenseID: selectedExpenseItem.ExpenseID},
                    resource: Resources.ExpenseReference,
                    piggyResource: Resources.ExpenseExpense,
                    errorMessage: true,
                    successMessage: translate('Reference Added'),
                }))
            }}
        >
            <div
                className={'grid gap-4 grid-cols-6 m-5'}
            >
                <FieldsToHtml
                    key={formFields.ReferenceType.value}
                    fieldsState={formFields}
                    onInputChange={onDialogInputChange}
                    selects={{
                        ReferenceType: {
                            tbl_Load: 'Load',
                            tbl_Truck: 'Truck',
                            tbl_Trailer: 'Trailer',
                            tbl_Driver: 'Driver',
                            tbl_Employee: 'Employee',
                            tbl_Agent: 'Agent',
                        },
                        ReferenceID: getReferenceOptions()
                    }}
                    translate={translate}
                />
            </div>
        </ModalDefault>

        <ModalConfirm
            title={translate('text.Confirm')}
            show={isConfirmModalOpen}
            text={translate('message.confirm_delete_reference')}
            onClose={() => {
                setIsConfirmModalOpen(false);
                setSelectedItem({});
            }}
            buttonLabel={translate('btn.confirm')}
            closeButtonLabel={translate('btn.Cancel')}
            translate={translate}
            onConfirm={() => {
                dispatch(deleteDialogResource({
                    user: LocalStorage.get('user'),
                    resource: Resources.ExpenseReference,
                    query: {
                        ExpenseReferenceID: selectedItem?.ExpenseReferenceID
                    },
                    piggyQuery: {ExpenseID: getProp(dialogResource, 'data', {}).ExpenseID},
                    piggyResource: Resources.ExpenseExpense,
                    errorMessage: true,
                    successMessage: translate('Reference deleted')
                }));

                setIsConfirmModalOpen(false);
                setSelectedItem({});
            }}
        />
    </div>
}