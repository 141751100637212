import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import LoadStepsPopup from "../load-steps-popup";

export default function LoadStepsPossibleStatus({
                                                    stops,
                                                    loadStatus,
                                                    onStopClick,
                                                    stopsCombined,
                                                    readyStatusRef,
                                                    stopFieldsRefs,
                                                    submitButtonRef,
                                                    stepsAddStopsRef,
                                                    setAreStepsActive,
                                                    possibleStatusRef,
                                                    currentlyFocusedElement
                                                }) {
    const [element, setElement] = useState();
    const [suggestedStopField, setSuggestedStopField] = useState({stopIndex: -1, fieldName: ""});

    function handleStepCloseClick() {
        setElement(undefined); // Erase popup element
        setAreStepsActive(false);
    }

    function findNextEmptyStopField() {
        let suggestedStopIndex = 0;
        let suggestedFieldName = "StopID";

        //const nextEmptyStopID = stops.findIndex(stop => !stop.StopID.value?.value);
        const nextEmptyStopID = stopsCombined.findIndex(stop => {
            if (stop.StopType) {
                if (!stops[stop.index].StopID.value?.value) {
                    return stop;
                }
            }
        });

        suggestedStopIndex = nextEmptyStopID;
        // const nextEmptyStopDate = stops.findIndex(stop => !stop.StopDate.value);
        const nextEmptyStopDate = stopsCombined.findIndex(stop => {
            if (stop.StopType) {
                if (!stops[stop.index].StopDate.value) {
                    return stop;
                }
            }
        });

        if (nextEmptyStopID > nextEmptyStopDate || nextEmptyStopID === -1) {
            suggestedStopIndex = nextEmptyStopDate;
            suggestedFieldName = "StopDate";
        }

        if (suggestedStopIndex !== -1) {
            setSuggestedStopField({stopIndex: suggestedStopIndex, fieldName: suggestedFieldName});
        } else {
            // All required stop fields are filled
            setSuggestedStopField({stopIndex: -1, fieldName: ""});
            getSuggestedStep();
        }
    }

    function addStopStep() {
        if (stepsAddStopsRef.current.parentNode) {
            setElement(createPortal(
                <LoadStepsPopup
                    title="Adding Stops and Selecting Location Names"
                    currentElement={stepsAddStopsRef.current}
                    currentlyFocusedElement={currentlyFocusedElement}
                    totalSteps={4}
                    currentStep={1}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                <span className="font-bold">1. Add Stops</span> by clicking on the Add stops button.</p>
                            <p>
                                Two default stops for pickup and delivery will be added.
                            </p>
                        </>
                    }
                    summary={
                        <p className="text-tm-gray-300 mb-4">In this phase, you will add stops and select location names
                            for
                            your route. Follow
                            these instructions to complete this process:</p>
                    }
                    onNextClick={() => {
                        stepsAddStopsRef.current.click();
                    }}
                />,
                stepsAddStopsRef.current.parentNode
            ));
        }
    }

    function saveStopsStep() {
        if (submitButtonRef.current.parentNode) {
            readyStatusRef.current.click();

            setElement(createPortal(
                <LoadStepsPopup
                    position="top-left"
                    addClass="mt-8"
                    title="Save stops"
                    stepText={
                        <>
                            <p>
                                Now you can save selected stops.
                            </p>

                            <p>
                                After that we can set Load to READY status.
                            </p>
                        </>
                    }
                    totalSteps={4}
                    currentStep={4}
                    onCloseClick={handleStepCloseClick}
                    onNextClick={() => {
                        submitButtonRef.current.click();
                    }}
                />,
                submitButtonRef.current.parentNode
            ));
        }
    }

    function suggestNextLoadStatus() {
        if (readyStatusRef.current) {

            setElement(createPortal(
                <LoadStepsPopup
                    title="Update load status"
                    currentElement={readyStatusRef.current}
                    currentlyFocusedElement={currentlyFocusedElement}
                    addClass="w-[500px]"
                    totalSteps={4}
                    currentStep={3}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                You can now advance to Ready status.
                            </p>

                            <p>
                                Press the Ready status or Next button.
                            </p>
                        </>
                    }
                    onNextClick={() => {
                        readyStatusRef.current.click();
                    }}
                />,
                readyStatusRef.current
            ));
        }
    }

    function suggestNextStopField(suggestedStopField) {
        let refInput = stopFieldsRefs.StopID.current?.select?.select?.select?.inputRef;

        if (suggestedStopField.fieldName === 'StopDate') {
            refInput = stopFieldsRefs.StopDate?.current?.input?.inputElement
        }

        if (suggestedStopField.stopIndex > -1) {
            onStopClick(suggestedStopField.stopIndex);
        }

        setTimeout(() => {
            if (suggestedStopField.fieldName === 'StopDate') {
                setElement(createPortal(
                    <LoadStepsPopup
                        position="top"
                        currentElement={refInput}
                        currentlyFocusedElement={currentlyFocusedElement}
                        addClass="min-w-[500px] z-50"
                        title={"Update pickup date for stop " + (suggestedStopField.stopIndex + 1) + "."}
                        stepText={
                            <p>
                                Select pickup date.
                            </p>
                        }
                        totalSteps={4}
                        currentStep={2}
                        onCloseClick={handleStepCloseClick}
                        isNextDisabled={!stops[suggestedStopField.stopIndex]?.StopDate?.value}
                        onNextClick={() => {
                            getSuggestedStep();
                        }}
                    />,
                    refInput.parentNode
                ));
            } else if (suggestedStopField.fieldName === 'StopID') {
                const el = refInput.parentNode.parentNode.parentNode.parentNode.parentNode
                setElement(createPortal(
                    <LoadStepsPopup
                        position="top"
                        currentElement={el}
                        currentlyFocusedElement={currentlyFocusedElement}
                        addClass="min-w-[500px] z-50"
                        title={"Update stop " + (suggestedStopField.stopIndex + 1) + "."}
                        stepText={<>
                            <p>
                                You can select a stop from a dropdown menu or from a list that appears when you press
                                the 'Plus'
                                button.
                            </p>

                            <p>
                                If the stop you need isn't available, you can create a new one from the list view or by
                                typing a new
                                location name in the dropdown menu.
                            </p>

                            <p>
                                All stops must be selected before you can continue.
                            </p>
                        </>}
                        totalSteps={4}
                        currentStep={2}
                        onCloseClick={handleStepCloseClick}
                        isNextDisabled={!stops?.[suggestedStopField?.stopIndex]?.StopID?.value?.value}
                        onNextClick={() => {
                            getSuggestedStep();
                        }}
                    />,
                    el
                ));
            }
        }, 300);
    }

    function getSuggestedStep() {
        if (!!stops.length && !!stops.length && stops.findIndex(stop => !stop.StopID?.value?.value) === -1 && stops.findIndex(stop => !stop.StopDate.value) === -1 && loadStatus === 3) {
            saveStopsStep();
        } else if (!!stops.length && stops.findIndex(stop => !stop.StopID?.value?.value) === -1 && stops.findIndex(stop => !stop.StopDate.value) === -1) {
            suggestNextLoadStatus()
        } else if (stops.length) {
            findNextEmptyStopField();
        } else if (!stops.length && stepsAddStopsRef.current) {
            addStopStep();
        }
    }

    useEffect(() => {
        if (loadStatus === 2 || (!!stops.length && !!stops.length && stops.findIndex(stop => !stop.StopID?.value?.value) === -1 && stops.findIndex(stop => !stop.StopDate.value) === -1 && loadStatus === 3)) {
            getSuggestedStep();
        } else {
            possibleStatusRef.current.click();
        }
    }, []);

    useEffect(() => {
        if (loadStatus === 3) {
            getSuggestedStep();
        }
    }, [loadStatus]);

    useEffect(() => {
        if (loadStatus !== 3) {
            if (suggestedStopField?.stopIndex !== -1) {
                suggestNextStopField(suggestedStopField);
            } else {
                getSuggestedStep();
            }
        }

    }, [suggestedStopField, stops])

    useEffect(() => {
        const selectedStopIndex = stopsCombined.findIndex(stop => stop.IsSelected)
        if (selectedStopIndex !== suggestedStopField.stopIndex && suggestedStopField.stopIndex !== -1) {
            handleStepCloseClick();
        }
    }, [stopsCombined]);

    return element ? element : null;
}
