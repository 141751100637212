import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {classNames, openInNewTab} from '../../../common/util/util-helpers'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {READ_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Resources from "../../../data/services/resources";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";

export default class ClaimsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 10,
            offset: 0,
            sort: 'ASC',
            sortBy: 'ClaimNumber',
            query: '',
            paginationPage: 1,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.props.resourcePath,
            query: this.getQuery()
        }))
    }
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            ClaimNumber: new Field('ClaimNumber', '', [''], false, 'text'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true, labelType: 'float'})
        }
    }
    getQuery = () => {
        return {
            id: this.props.CustomerID,
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
        }
    }
    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData)
    }
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, this.fetchData)
    }
    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, this.fetchData);
    }
    getFields = () => {
        return {
            ClaimNumber: new Field('ClaimNumber', '', [''], false, 'text'),
            Customer: new Field('Customer', '', [''], false, 'text'),
            Driver: new Field('Driver', '', [''], false, 'text', {omitSort: true}),
            Truck: new Field('Truck', '', [''], false, 'text', {omitSort: true}),
            Trailer: new Field('Trailer', '', [''], false, 'text', {omitSort: true}),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'text'),
            ClaimCode: new Field('ClaimCode', '', ['empty']),
            ClaimOpened: new Field('ClaimOpened', '', ['empty'], false, 'date'),
            ClaimDate: new Field('ClaimDate', '', ['empty'], false, 'date'),
            ClaimClosed: new Field('ClaimClosed', '', ['empty'], false, 'checkbox')
        }

    }

    render() {
        const {translate, resource} = this.props
        const data = getProp(resource, 'data.list', [])
        const count = getProp(resource, 'data.count', 0)

        return (
            <React.Fragment>
                <TableCard>
                    <div className={"flex justify-end p-5 border-tm-gray-300 border-b"}>
                        <button className="btn btn-header" onClick={this.fetchData}>
                            <ArrowPathIcon className={
                                classNames(
                                    "w-5 h-5",
                                    resource.isLoading ? "animate-spin" : undefined,
                                )
                            }/>
                        </button>
                    </div>

                    <ResourceTable
                        data={data}
                        count={count}

                        fields={this.getFields()}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.limit}

                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        paginationButtonLimit={5}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                        onRowClick={(item) => {
                            return checkPerm(Resources.ClaimsInfo, READ_PERM) ? openInNewTab(`/claims/info/${item.ClaimID}`) : null
                        }}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !resource.isLoading) && (
                        <div
                            className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </div>

                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={false}
                        title={translate('text.no_matching_records')}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>
            </React.Fragment>
        )
    }
}
