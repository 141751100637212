import React, { useEffect } from "react";
import {getDialogInfoResource} from "../../../../data/actions/dialogInfoResource";
import LocalStorage from "../../../../common/util/localStorage";
import Resources from "../../../../data/services/resources";
import {classNames, getProp} from "../../../../common/util/util-helpers";
import {LoaderLarge} from "../../../../common/components/loader";
import DataCard from "../../../../common/components/display-data/data-card";
import {Field} from "../../../../data/services/fields";

export default function AccidentCard(props) {
    const {translate, dialogInfoResource, AccidentID} = props;
    useEffect(() => {
        props.dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.AccidentsInfo,
            query: {id: AccidentID}
        }));
    }, [])

    const getFields = () => {
        return {
            FileNumber: new Field('FileNumber', '', [''], false, "text"),
            AccidentCode: new Field('AccidentCode', '', [''], false, "text"),
            AccidentDate: new Field('AccidentDate', '', [''], false, "date"),
            FileOpened: new Field('FileOpened', '', [''], false, "date"),
            FileClosed: new Field('FileClosed', '', [''], false, "date"),
            Driver: new Field('Driver', '', [''], false, "text"),
            Truck: new Field('Truck', '', [''], false, "text"),
            Trailer: new Field('Trailer', '', [''], false, "text")
        }
    };

    const data = getProp(dialogInfoResource, "data", {});
    const isLoading = getProp(dialogInfoResource, "isLoading", true);
    const infoFields = Object.values(getFields()).filter(it => !!data[it.name]);

    const getDetailsFields = () => {
        return {
            AccidentTruckDamage: new Field('AccidentTruckDamage', '', [''], false, "text",),
            AccidentTrailerDamage: new Field('AccidentTrailerDamage', '', [''], false, "text",),
            AccidentCargoDamage: new Field('AccidentCargoDamage', '', [''], false, "text"),
            AccidentInjuries: new Field('AccidentInjuries', '', [''], false, "text"),
            AccidentFatalities: new Field('AccidentFatalities', '', [''], false, "text"),
            AccidentAmbulanceUsed: new Field('AccidentAmbulanceUsed', '', [''], false, "checkbox"),
            AccidentDriverHospitalized: new Field('AccidentDriverHospitalized', '', [''], false, "checkbox"),
            AccidentVehicleDisabled: new Field('AccidentVehicleDisabled', '', [''], false, "checkbox"),
        }
    };

    const infoDetailFields = Object.values(getDetailsFields()).filter(it => !!data[it.name] || it.type === "checkbox");


    return (
        <React.Fragment>
            {isLoading && (
                <div className="">
                    <LoaderLarge/>
                </div>
            )}

            {!isLoading && (
                <React.Fragment>
                    <div className={'max-w-md mx-auto my-10'}>
                        <p className="text-2xl font-bold truncate mr-6 mb-2 text-tm-gray-900">
                            Accident Info
                        </p>

                        <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                            {infoFields.map((field, i) => {
                                return (
                                    <DataCard
                                        key={field.name}
                                        displayField={field}
                                        fieldsData={data}
                                        className={classNames("px-6 py-1.5 flex items-center", i < infoFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                        translate={translate}
                                    />
                                )
                            })}
                        </div>

                        <p className="text-2xl font-bold truncate mr-6 mt-6 mb-2 text-tm-gray-900">
                            Accident Details
                        </p>

                        <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                            {infoDetailFields.map((field, i) => {
                                return (
                                    <DataCard
                                        key={field.name}
                                        displayField={field}
                                        fieldsData={data}
                                        className={classNames("px-3 py-1.5 flex items-center px-6", i < infoDetailFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                        translate={translate}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

