import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const defaultOptions = {
    plugins: {
        legend: {},
        title: {},
    },
};

export function ChartLine({
                              data,
                              rawData,
                              options,
                              title,
                              maintainAspectRatio = false,
                              responsive = true,
                              legendPosition = "left",
                              hideLegend = false
}) {
    let chartData = rawData;
    if (!rawData) {
        chartData = {
            labels: Object.values(data[0]?.data).map(it => {
                return it.label;
            }),
            datasets: data.reduce((memo, set) => {
                memo.push({
                    label: set.label,
                    data: Object.values(set.data).map(it => {
                        return it.value;
                    }),
                    borderColor: set.borderColor,
                    backgroundColor: set.backgroundColor,
                });
                return memo;
            }, [])
        }
    }

    let lineOptions = options;
    if (!options) {
        lineOptions = defaultOptions;
    }

    if (!options?.plugins) {
        lineOptions.plugins = {};
    }

    lineOptions.plugins.title = {
        text: title,
        display: !!title
    };

    lineOptions.plugins.legend = {
        position: legendPosition,
        display: !hideLegend
    };

    lineOptions.responsive = responsive;
    lineOptions.maintainAspectRatio = maintainAspectRatio;

    return <Line options={lineOptions} data={chartData} />;
}

