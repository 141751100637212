import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateSecondResourceDiff,
    doneDeleteSecondResourceDiff,
    doneGetSecondResourceDiff,
    doneUpdateSecondResourceDiff,
    errorCreateSecondResourceDiff,
    errorDeleteSecondResourceDiff,
    errorGetSecondResourceDiff,
    errorUpdateSecondResourceDiff
} from "../actions/secondResourceDiff";
import {pushNotification} from "../actions/ui";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* createSecondResourceDiffCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (result && result.status === 0) {
        if (action.data.showRedirectDialog) {
            result.data.showRedirectDialog = action.data.showRedirectDialog
        } else {
            result.data.showRedirectDialog = false;
        }

        if (action.data.file && action.data.file.length > 1) {
            const id = getIdForImageResource(action, result);
            yield call(
                Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams
                        ? action.data.fileParams
                        : action.data.params,
                    action.data.customID
                        ? {[action.data.customID]: id}
                        : {id: id}),
                {},
                action.data.customID);
        } else if (action.data.file && action.data.file[0]) {
            const id = getIdForImageResource(action, result);
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, action.data.customID ? {[action.data.customID]: id} : {id: id}))
        }

        if (action.data.document && action.data.document.length > 1) {
            const id = getIdForDocumentResource(action, result);
            yield call(Api.uploadDocuments,
                user,
                id,
                action.data.document,
                null,
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource,
                null,
                action.data.customID
            );
        } else if (action.data.document && action.data.document[0]) {
            const id = getIdForDocumentResource(action, result);
            yield call(Api.uploadDocument,
                user,
                null,
                action.data.document[0],
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource,
                id,
                action.data.customID
            );
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResourceDiff(resPiggy.data));
                yield put(doneCreateSecondResourceDiff(result.data));
            }
        } else {
            yield put(doneCreateSecondResourceDiff(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateSecondResourceDiff(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateSecondResourceDiff() {
    yield takeLatest('CREATE_SECOND_RESOURCE_DIFF', createSecondResourceDiffCall);
}

export function* getSecondResourceDiffCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSecondResourceDiff(result.data));
    } else {
        yield put(errorGetSecondResourceDiff(result));
        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchGetSecondResourceDiff() {
    yield takeLatest('GET_SECOND_RESOURCE_DIFF', getSecondResourceDiffCall);
}

export function* updateSecondResourceDiffCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResourceDiff(resPiggy.data));
                yield put(doneUpdateSecondResourceDiff(result.data));
            }
        } else {
            yield put(doneUpdateSecondResourceDiff(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateSecondResourceDiff(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateSecondResourceDiff() {
    yield takeLatest('UPDATE_SECOND_RESOURCE_DIFF', updateSecondResourceDiffCall);
}

export function* deleteSecondResourceDiffCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResourceDiff(resPiggy.data));
                yield put(doneDeleteSecondResourceDiff(result.data));
            }
        } else {
            yield put(doneGetSecondResourceDiff(result.data));
        }


        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteSecondResourceDiff(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchDeleteSecondResourceDiff() {
    yield takeLatest('DELETE_SECOND_RESOURCE_DIFF', deleteSecondResourceDiffCall);
}

function getIdForImageResource(action, result) {
    let id
    if (action.data.resource === "drivers" || action.data.resource === "employees" || action.data.resource === "agents") {
        id = result.data.ContactID
    } else {
        id = result.data.id
    }
    return id;
}

function getIdForDocumentResource(action, result) {
    let id
    if (action.data.resource === "drivers") {
        id = result.data.DriverID
    } else if (action.data.resource === "carriers" || action.data.resource === "customers" || action.data.resource === "vendors") {
        id = result.data.OrganizationID
    } else if (action.data.resource === "employees") {
        id = result.data.EmployeeID
    } else {
        id = result.data.id
    }
    return id;
}