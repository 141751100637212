import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    resourceIsCreated,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'

import {ArrowUturnLeftIcon, PencilIcon, XCircleIcon} from '@heroicons/react/24/outline'

import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import Tippy from '@tippyjs/react'
import MoneyCodesSettings from './moneyCodesSettings'
import FieldText from "../../../common/components/fields/field-text";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import {getSecondResource} from "../../../data/actions/secondResource";
import {Link} from "react-router-dom";
import moment from "moment";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import Card from "../../../common/components/card";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import InfoParagraph from "../../../common/components/info-paragraph";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ButtonIntegration from "../../../common/components/button/button-integration";
import ResourceTable from "../../../common/components/resource-table"
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

class MoneyCodesView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props?.location?.pathname?.substring(1) + 'money-codes' ?? ''

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sort: "DESC",
            sortBy: "Date",

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            createModalOpen: false,
            confirmDialog: false,
            isReceivedCodeModalOpen: false,
            selectedItem: null,
            moneyCodesSettingsModalOpen: false,
            hasExpressCodeWarning: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchIntegrationData()
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (resourceIsCreated(this.props.resource, prevProps.resource)) {
            const receivedCode = getProp(this.props.resource, 'create.code', 0)
            this.setState({
                fields: this.getFields(receivedCode),
                isReceivedCodeModalOpen: true
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchIntegrationData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.MoneyCodesIntegrated,
            query: this.getQueryIntegrated()
        }))
    }
    archiveResource = (item = null) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_delete_money_code')}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {
                            Id: item?.Code,
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: `Money code voided`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleToggleMoneyCodesSettingsModal = () => {
        this.setState({
            moneyCodesSettingsModalOpen: !this.state.moneyCodesSettingsModalOpen
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleRevertModal = (item = null) => {
        this.setState({
            selectedItem: item,
            revertModalOpen: !this.state.revertModalOpen
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleMoneyCodeFormInputChange = (fields, name, value) => {
        let fieldsClone = Object.assign({}, fields);

        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);

        this.setState({
            hasExpressCodeWarning: value?.metadata?.DoNotIssueExpressCode === 1
        });

        return fieldsClone;
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        let queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = Object.assign({}, this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        let queryFilterFields = Object.assign({}, this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = (receivedCode = '') => {
        return {
            // Table
            Code: new Field('Code', receivedCode, [], false, 'text'),
            ContactID: new Field('ContactID', '', [], false, 'custom', {
                render: (it) => {
                    return it.Contact ?? it.Organization
                },
            }),
            Date: new Field('Date', '', [], false, 'datetimezone'),
            Amount: new Field('Amount', '', [], false, 'money'),
            Description: new Field('Description', '', [], false, 'textarea', {label: 'notes'}),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', [], false, 'select-search'),
            LoadID: new Field('LoadID', '', [], false, 'select-search'),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {label: 'Processed'}),
            IsVoided: new Field('IsVoided', '', [], false, 'checkbox'),
            IssuedByContact: new Field('IssuedByContact', '', [], false, 'text'),
            IntegrationType: new Field('IntegrationType', '', [], false, 'text')
        }
    }

    getFieldsModal = () => {
        return {
            Amount: new Field('Amount', '', ['empty'], false, 'float'),
            IssuedToID: new Field('IssuedToID', '', ['empty'], false, 'select-search'),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search'),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),

            Notes: new Field('Notes', '', [''], false, 'textarea')
        }
    }

    getRestoreFields = (item = null) => {
        let fieldTemplates = {
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getEditFields = (item = null) => {
        let fieldTemplates = {
            ContactID: new Field('ContactID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: "IssuedToID"
            }),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            LoadNumber: new Field('LoadNumber', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full'
            }, {isClearable: true,}),
            Description: new Field('Description', '', [], false, 'textarea', {
                label: 'notes',
                addContainerClass: 'col-span-full'
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}, {
                isClearable: true
            }),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            LoadID: new Field('LoadID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox'),
            IsVoided: new Field('IsVoided', '', [''], false, 'checkbox'),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', [''], false, 'select-search', {}, {
                isClearable: true,

                api: 'api/' + Resources.MoneyCodeType,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.MoneyCodeTypeID,
                    label: item.Reason
                })
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
        }
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'query', 'offset'])
    }

    getResource = () => {
        return Resources.MoneyCodes
    }

    getQueryIntegrated = () => {
        const initialStartDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
        const initialEndDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')

        return {
            sort: "ASC",
            sortBy: "",
            offset: 0,
            query: "",
            StartDate: initialStartDate,
            EndDate: initialEndDate
        }
    }

    getQuery = () => {
        const {
            ContactID,
            LoadID,
            StartDate,
            EndDate,
            IsPayed,
            IsVoided,
            MoneyCodeTypeID
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                ContactID: ContactID,
                LoadID: LoadID,
                IsPayed: IsPayed,
                IsVoided: IsVoided,
                MoneyCodeTypeID: MoneyCodeTypeID,
                ...(!!StartDate && {StartDate: ['Date', '>=', StartDate]}),
                ...(!!EndDate && {EndDate: ['Date', '<=', EndDate]}),
            })
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, location} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource, 'data.count', 0)

        const hasEFSIntegration = !!getProp(this.props.secondResource.data, 'integrations.EFS.hasIntegration', 1)

        const noEFSIntegrationMessage = (
            <div>
                No money code provider integrated, to check your current integrations visit
                <Link to={{pathname: '/settings', from: {prevPath: location.pathname}}}
                      className="text-sky-600 hover:underline"> settings page</Link>.
            </div>
        )

        return (
            <>
                <PageHeader
                    title={translate('page.heading.MoneyCodes')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={this.props.dispatch}
                        />
                    )}
                >
                    <ButtonIntegration
                        id="integration-btn"
                        hasIntegration={hasEFSIntegration}
                        history={this.props.history}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        onClick={() => this.handleToggleCreateModal()}
                        integrationName={"EFS"}
                    >
                        {translate('btn.create_new')}
                    </ButtonIntegration>
                </PageHeader>
                <div className="max-w-fit">
                    <InfoParagraph>
                        {translate('text.listOfMoneyCodes')}
                    </InfoParagraph>
                </div>

                {hasEFSIntegration ? (
                        <>
                            <div className="flex">
                                <ActiveFilters
                                    filterFields={this.state.queryFilterFields}
                                    onLabelClick={this.handleFilterInputChange}
                                    onClearFiltersClick={this.handleClearFiltersClick}
                                    translate={translate}
                                />
                                <div className="ml-auto flex">
                                    <Tippy content={translate('text.ReasonCodes')}>
                                        <button
                                            className="btn-icon"
                                            onClick={this.handleToggleMoneyCodesSettingsModal}
                                        >
                                            <Cog6ToothIcon className="w-5 h-5"/>
                                        </button>
                                    </Tippy>

                                    <div className="ml-auto flex">
                                        <TableSettingsPopOver
                                            options={this.state.tableOptions}
                                            setOptions={this.setOptions}
                                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                            translate={translate}
                                        />
                                    </div>
                                </div>
                            </div>

                            <TableCard addClass={'relative'}>
                                <TableFilters
                                    addClass="relative z-30"
                                    hideLimit
                                    filterFields={this.state.queryFilterFields}
                                    handleInputChange={this.handleFilterInputChange}
                                    translate={translate}
                                    onRefreshTable={this.fetchData}
                                    isLoading={resource.isLoading}
                                />

                                <ResourceTable
                                    data={data}
                                    fields={this.state.fields}
                                    verticalTableIsVisible={this.props.breakpoint.index <= 1}

                                    translate={translate}
                                    isLoading={resource.isLoading}

                                    options={this.state.tableOptions}

                                    limit={count ? count : 10}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}

                                    actions={[
                                        {
                                            action: this.handleToggleEditModal,
                                            icon: PencilIcon,
                                            visible: (it) => !it.IsVoided && !it.IsPayed && checkPerm(this.getResource(), UPDATE_PERM),
                                            label: false,
                                            title: translate('btn.edit')
                                        },
                                        {
                                            action: this.handleToggleRevertModal,
                                            icon: ArrowUturnLeftIcon,
                                            visible: (it) => !it.IsVoided && !!it.IsPayed && checkPerm(this.getResource(), UPDATE_PERM),
                                            label: false,
                                            title: translate('btn.restore')
                                        },
                                        {
                                            action: this.archiveResource,
                                            icon: XCircleIcon,
                                            visible: (it) => (!it.IsVoided && checkPerm(this.getResource(), DELETE_PERM)),
                                            label: false,
                                            title: translate('btn.void'),
                                            disabled: false,
                                            class: false,
                                            iconClass: false
                                        },
                                    ]}
                                />

                                <TableCardFooter
                                    show={!(!data.length && !resource.isLoading)}
                                >
                                    <Pagination
                                        count={count}
                                        isLoading={resource.isLoading}
                                        hideRowsPerPage={this.props.breakpoint.index <= 1}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === 'offset'
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.queryFilterFields}
                                        translate={translate}
                                    />
                                </TableCardFooter>

                                <NoRecordsTable
                                    show={(data.length === 0) && !resource.isLoading}
                                    canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                                    title={translate('text.no_records')}
                                    text={translate('text.create_new_money_code')}
                                    btnLabel="Create money codes"
                                    onBtnClick={() => this.handleToggleCreateModal()}
                                    onClearFilterClick={this.handleClearFiltersClick}
                                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                                    clearFilterText={translate('text.try_without_filters')}
                                    filters={this.state.queryFilterFields}
                                />
                            </TableCard>
                        </>)
                    : !resource.isLoading && (
                    <Card>
                        <InfoBar addClass={'text-base'}>
                            {noEFSIntegrationMessage}
                        </InfoBar>
                    </Card>
                )}

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('text.create_new_money_code')}
                    widthClass="max-w-[32rem]"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFieldsModal()}
                    handleInputChange={this.handleMoneyCodeFormInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            params.LoadID = params.LoadNumber
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: this.props.translate('text.money_code_created')
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={translate}
                    htmlBefore={() => {
                        if (this.state.hasExpressCodeWarning) {
                            return <div className="p-1">
                                <InfoBar type="warning">
                                    {translate("text.express_code_warning_desc")}
                                </InfoBar>
                            </div>
                        }
                    }}
                    metadata={{
                        IssuedToID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {
                                ...DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                IsDriver: 1,
                                IncludeStatuses: 1
                            },
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + ' ' + item.LastName,
                                metadata: item
                            })
                        },
                        MoneyCodeTypeID: {
                            api: 'api/' + Resources.MoneyCodeType,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.MoneyCodeTypeID,
                                label: item.Reason
                            })
                        },
                        LoadNumber: {
                            api: 'api/' + Resources.LoadsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (it) => ({
                                label: it.LoadNumber,
                                value: it.LoadID
                            })
                        },
                    }}
                />

                <ModalSaveResource
                    show={!!this.state.revertModalOpen}
                    title={'Edit money code processed status'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleRevertModal}
                    fields={this.getRestoreFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        params.MoneyCodeID = this.state.selectedItem.MoneyCodeID
                        params.LoadID = params.LoadNumber
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: ' Money code updated.',
                            }))
                            this.handleToggleRevertModal()
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalSaveResource
                    show={!!this.state.editModalOpen}
                    title={'Edit money code'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleEditModal}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        params.MoneyCodeID = this.state.selectedItem.MoneyCodeID
                        params.LoadID = params.LoadNumber
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: ' Money code updated.',
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        MoneyCodeTypeID: {
                            api: 'api/' + Resources.MoneyCodeType,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.MoneyCodeTypeID,
                                label: item.Reason
                            })
                        },
                        ContactID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.FirstName + ' ' + item.LastName,
                                value: item.ContactID
                            })
                        },
                        LoadNumber: {
                            api: 'api/' + Resources.LoadsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (it) => ({
                                label: it.LoadNumber,
                                value: it.LoadID
                            })
                        },
                    }
                    }
                />

                <ModalDefault
                    show={!!this.state.moneyCodesSettingsModalOpen}
                    title={translate('modal_heading.ReasonCodes')}
                    widthClass={'max-w-7xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.handleToggleMoneyCodesSettingsModal()}
                    translate={translate}
                >
                    <MoneyCodesSettings
                        location={this.props.location}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        secondResource={this.props.secondResource}
                    />

                </ModalDefault>

                <ModalDefault
                    baseClass={'fixed inset-0 flex items-center justify-center'}
                    show={this.state.isReceivedCodeModalOpen}
                    widthClass={'max-w-xl'}
                    translate={translate}
                    title={translate('Money code')}
                    onClose={() => this.setState({
                        isReceivedCodeModalOpen: false
                    })}
                    closeButtonLabel={translate('btn.close')}
                >
                    <div className="flex items-center m-4">
                        <CopyToClipboardButton
                            clipboardText={this.state.fields.Code.value}
                            translate={translate}
                        />
                        <FieldText
                            addClass={'font-bold text-2xl rounded-md'}
                            {...this.state.fields.Code}
                        />
                    </div>
                </ModalDefault>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </>
        )
    }
}

export default connect(state => state)(MoneyCodesView)
