import React from "react";
import XCircleIcon from "@heroicons/react/20/solid/XCircleIcon";
import ImageAvatarLoader from "../../../../../common/components/image/image-avatar-loader";
import {LoaderSmall} from "../../../../../common/components/loader";

export default function ContactSelectButton({id, name, hasImagePath, text, email, readOnly, isDisabled, disabledContent, onChoseContactClick, onClearContactClick, onAvatarClick, readOnlyContent, isLoading, translate}) {
    return (
        <div className="flex items-center space-x-2 relative pl-2 h-8 text-sm font-semibold group">
            {!!text && (
                <span
                    className="font-semibold text-tm-gray-700"
                >
                    {text}
                </span>
            )}

            {!id && !isLoading && !readOnly && (
                <button
                    className="btn btn-outline-secondary bg-inverse py-1"
                    disabled={isDisabled}
                    title={isDisabled ? translate("text.save_changes_first") : undefined}
                    onClick={onChoseContactClick}
                >
                    {translate("btn.choose")}
                </button>
            )}

            {!id && !isLoading && !!readOnly && (
                <React.Fragment>
                    {!readOnlyContent && (
                        <span className="ml-1">
                            {translate("text.none")}
                        </span>
                    )}

                    {readOnlyContent}
                </React.Fragment>
            )}

            {!!id && !isLoading && (
                <div
                    className="flex items-center space-x-2 rounded-full hover:bg-tm-gray-200 pr-4"
                >
                    <div
                        className="flex items-center flex-shrink-0 cursor-pointer bg-tm-gray-100 rounded-full hover:ring-4 hover:ring-link"
                        onClick={id && onAvatarClick}
                    >
                        <ImageAvatarLoader
                            ContactID={id}
                            size="sm"
                            hasImagePath={hasImagePath}
                        />
                    </div>

                    <button
                        className="font-semibold text-primary text-left"
                        disabled={isDisabled}
                        title={isDisabled ? translate("text.save_changes_first") : undefined}
                        onClick={onChoseContactClick}
                    >
                        <p className="text-sm">{name}</p>
                        {!!email && (
                            <p className="text-tm-gray-600">{email}</p>
                        )}
                    </button>
                </div>
            )}

            {!!id && !isLoading && !isDisabled && !readOnly &&  (
                <button
                    className="w-5 h-5 text-red-600 w-5 h-5 p-0 invisible group-hover:visible hover:text-red-700"
                    onClick={onClearContactClick}
                >
                    <XCircleIcon/>
                </button>
            )}

            {(isLoading) && (
                <LoaderSmall/>
            )}

            {!!disabledContent && isDisabled && (
                disabledContent
            )}
        </div>
    )
}