
import React from 'react'
import {classNames} from "../../../../common/util/util-helpers";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import axios from "axios";
import Env from "../../../../util/env";
import Resources from "../../../../data/services/resources";
import {processResponse} from "../../../../data/services/api-util";
import FieldTextarea from "../../../../common/components/fields/field-textarea";
import FieldDropdownSelect from "../../../../common/components/fields/field-dropdown-select";
import CardSubtitle from "../../../../common/components/card/card-sub-title";
import Card from "../../../../common/components/card";
import FieldCheckbox from "../../../../common/components/fields/field-checkbox";
import FieldContainer from "../../../../common/components/fields/field-container";
import {getJWT} from "../../../../common/util/util-auth";

const LoadProgressButtons = (props) => {
    const {
        loadStatusList,
        activeStatus,
        onSetActive,
        loadData,
        LoadInstructions,
        handleLoadInputChange,
        activeTab,
        translate
    } = props

    let statusFields = LoadInstructions
        .filter((status, index) => index === activeTab)
        .map(status => {
            return (
                <div className="">
                    <div className="form-group relative">
                        <label className="text-capitalize">{status.Name} status instruction</label>

                        <FieldTextarea
                            addClass="form-control whitespace-pre-wrap"
                            onChange={(name, value) => handleLoadInputChange(name, value, status.Type)}
                            {...LoadInstructions[status.Type].Instruction}
                            placeholder=""
                        />
                    </div>


                    <div className="form-group relative">
                        <label
                            className="h-10 flex items-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                            <FieldCheckbox
                                className="checkbox"
                                onChange={(name, value) => handleLoadInputChange(name, value, status.Type)}
                                {...LoadInstructions[status.Type].Active}
                            />

                            <span className="ml-2 text-sm font-semibold select-none">{status.Name} status is <span
                                className="font-weight-bold">Active</span></span>
                        </label>

                        <label
                            className="h-10 flex items-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                            <FieldCheckbox
                                className="checkbox"
                                onChange={(name, value) => handleLoadInputChange(name, value, status.Type)}
                                {...LoadInstructions[status.Type].CreateTask}
                            />

                            <span className="ml-2 text-sm font-semibold select-none"><span
                                className="font-weight-bold">Create Task</span> on {status.Name} processing</span>
                        </label>

                        <FieldContainer
                            item={LoadInstructions[status.Type].TaskGroupID}
                            translate={translate}
                        >
                            <FieldDropdownSelect
                                {...LoadInstructions[status.Type].TaskGroupID}
                                onChange={(name, value) => handleLoadInputChange(name, value, status.Type)}
                                addClass="form-control"
                                defaultOptions={true}
                                loadOptions={
                                    (inputValue, callback) => {
                                        axios.get(
                                            Env.getApiUrl('api/' + Resources.BoardTasks, {
                                                query: inputValue,
                                                IsSystemUser: 1
                                            }),
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                }
                                            }
                                        )
                                            .then((response) => {
                                                const result = processResponse(response);
                                                if (result && result.status === 0) {
                                                    const list = result.data.list.map((it) => {
                                                        return {
                                                            label: it.TaskGroupName,
                                                            value: it.TaskGroupID
                                                        };
                                                    });

                                                    callback(list);
                                                }
                                            })
                                    }
                                }
                            />

                        </FieldContainer>
                    </div>
                </div>
            )
        })
    const stepKeys = Object.keys(loadStatusList);
    const steps = stepKeys.map((item, index) => {
        const isNotLast = index + 1 !== stepKeys.length;
        const hasInstruction = loadData[index + 1] && loadData[index + 1].Active.value;
        const isActive = index === activeTab - 1
        return (
            <li className="relative md:flex md:flex-1">

                <button
                    onClick={() => onSetActive(index + 1)}
                    className={
                        classNames(
                            "flex items-center pl-2 pr-3 py-2 text-sm font-medium w-full relative"
                        )
                    }
                    aria-current="step"
                >
                    <span
                        className={classNames(
                            "text-sm flex items-center font-semibold",
                            isActive ? "relative" : "text-tm-gray-700"
                        )}
                    >
                       {hasInstruction && (
                           <CheckCircleIcon className="text-green-600 w-5 h-5 mr-1"/>
                       )}

                        <span className={
                            classNames(
                                "px-1",
                                isActive ? "bg-primary text-white rounded-md" : undefined
                            )}>
                            {loadStatusList[item]}
                        </span>
                   </span>
                </button>

                {isNotLast && (
                    <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                        <svg className="h-full w-full text-tm-gray-400" viewBox="0 0 22 80" fill="none"
                             preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor"
                                  strokeLinejoin="round"/>
                        </svg>
                    </div>
                )}
            </li>
        )
    })

    return (
        <Card>
            <CardSubtitle
                subtitle={translate('text.SpecificLoadStatusInstructions')}
            />

            <label>Click on a load status below to add an instruction for load processing:</label>

            <nav aria-label="Progress" className="overflow-hidden  rounded-md">
                <ol role="list"
                    className="divide-y divide-tm-gray-400 rounded-md border border-tm-gray-400 md:flex md:divide-y-0">
                    {steps}
                </ol>
            </nav>

            {statusFields}
        </Card>
    )
}

export default LoadProgressButtons
