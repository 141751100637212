import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    mergeDeep,
    openInNewTab,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    LOAD_PRICING_TYPES,
    UPDATE_PERM
} from '../../../util/util-constants'
import {cloneDeep} from '../../../common/util/util-vanilla'
import CalculatorIcon from "@heroicons/react/20/solid/CalculatorIcon";
import XCircleIcon from "@heroicons/react/20/solid/XCircleIcon";
import XCircleOutlineIcon from "@heroicons/react/24/outline/XCircleIcon";
import ArrowPathIcon from "@heroicons/react/24/outline/ArrowPathIcon";
import LoadPlaningModalBody from "../load-view/load-sections/dialog/load-planing-modal";
import {createDialogResource} from "../../../data/actions/dialogResource";
import {Square2StackIcon} from "@heroicons/react/24/outline";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Modal from "../../../common/components/modal";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import Layout from "../../../common/components/layout";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";

class QuotesView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)
        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            columns: {
                LoadQuoteID: {
                    minWidth: 220,
                    subColumns: [{'Labels': {show: true, name: 'Labels'}}]
                },
                Customer: {minWidth: 300},
                Truck: {minWidth: 200},
                Trailer: {minWidth: 200},
                Labels: {minWidth: 200, show: false},
                LoadStatus: {minWidth: 200}
            }
        }

        this.state = {
            offset: 0,
            limit: 50,
            paginationPage: 1,

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            sortBy: 'LoadQuoteID',
            sort: 'DESC',

            selectedItem: {},

            confirmSubmit: undefined,
            confirmTitle: "",
            confirmText: "",
            breakpoint: {},

            isConfirmDeleteDialogOpen: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.dialogResource.create && !prevProps.dialogResource.create) {
            this.setState({
                loadPreviewData: this.props.dialogResource.create
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    convertToLoad = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {LoadQuoteID: this.state.selectedItem.LoadQuoteID},
            resource: Resources.QuotesLoadConvert,
            piggyResource: Resources.QuotesLoads,
            errorMessage: true, successMessage: this.props.translate("message.quote_converted_success"),
            query: this.getQuery()
        }));

        this.setState({
            isConfirmDeleteDialogOpen: false,
            selectedItem: {}
        });
    }

    rejectQuote = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {LoadQuoteID: this.state.selectedItem.LoadQuoteID},
            resource: Resources.QuotesLoadReject,
            piggyResource: Resources.QuotesLoads,
            errorMessage: true, successMessage: this.props.translate("message.quote_rejected_success"),
            query: this.getQuery()
        }));

        this.setState({
            isConfirmDeleteDialogOpen: false,
            selectedItem: {}
        });
    }

    deleteResource = () => {
        this.setState({
            ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
        }, () => {
            this.props.dispatch(deleteResource({
                user: LocalStorage.get('user'),
                query: Object.assign({}, this.getQuery(),
                    {
                        LoadQuoteID: this.state.selectedItem.LoadQuoteID,
                    }
                ),
                errorMessage: true, successMessage: this.props.translate("message.quote_deleted_successfully"),
                resource: Resources.QuotesLoad,
                piggyResource: Resources.QuotesLoads
            }));

            this.setState({
                isConfirmDeleteDialogOpen: false,
                selectedItem: {}
            });
        })

    }

    handlePreviewLoadClick = (params) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.LoadPreview,
        }));
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    /** UI Events
     ================================================================= */
    handleCreateResourceClick = () => {
        this.props.history.push('/quotes/create');
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleFilterInputChange = (name, value) => {
        let fields = this.state.fields;

        if ("ColumnFilters" === name) {
            fields = Object.values(fields).map(it => {
                it.value = "";
                return it;
            });

            fields = fields.reduce((memo, it) => {
                memo[it.name] = it;
                return memo
            }, [])
        }

        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1,
            fields: fields
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)
        let fields = this.state.fields;

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            if ("ColumnFilters" === it.name && !!it.value) {
                fields = Object.values(fields).map(it => {
                    it.value = "";
                    return it;
                });

                fields = fields.reduce((memo, it) => {
                    memo[it.name] = it;
                    return memo
                }, []);

            }

            if ('DateType' !== it.name) {
                FieldsManager.updateField(queryFilterFields, it.name, '')
            }
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            tableColumnFields: {},
            fields: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters();
            this.fetchData(true)
        })
    }

    handleTableRowClick = (it) => {
        this.props.history.push("/quotes/info/" + it.LoadQuoteID);
    }

    handleEditResourceClick = (it) => {
        this.props.history.push("/quotes/info/" + it.LoadQuoteID);
    }

    handleDeleteResourceClick = (it) => {
        this.setState({
            selectedItem: it,
            confirmSubmit: this.deleteResource,
            confirmTitle: this.props.translate("text.delete_quote"),
            confirmText: this.props.translate("text.delete_quote_confirm", [it.LoadQuoteID]),
            isConfirmDeleteDialogOpen: true
        })
    }

    handleCloseConfirmDialogClick = () => {
        this.setState({
            isConfirmDeleteDialogOpen: false,
        })
    }

    handleConvertToLoadClick = (it) => {
        this.setState({
            selectedItem: it,
            confirmSubmit: this.convertToLoad,
            confirmTitle: this.props.translate("text.convert_to_load"),
            confirmText: this.props.translate("text.convert_quote_to_load_confirm", [it.LoadQuoteID]),
            isConfirmDeleteDialogOpen: true
        })
    }

    handleRejectQuoteClick = (it) => {
        this.setState({
            selectedItem: it,
            confirmSubmit: this.rejectQuote,
            confirmTitle: this.props.translate("text.reject_quote"),
            confirmText: this.props.translate("text.reject_quote_number_confirm", [it.LoadQuoteID]),
            isConfirmDeleteDialogOpen: true
        })
    }

    handleCopyQuote = (item) => {
        this.props.history.push('/quotes/copy/' + item.LoadQuoteID)
    }

    /** UI Events dialogs
     ================================================================= */
    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    toggleLoadPreviewDialog = () => {
        this.setState({
            isLoadPreviewDialogOpen: !this.state.isLoadPreviewDialogOpen,
            loadPreviewData: {}
        })
    }

    /** UI Events table
     ================================================================= */
    handleFilterChange = (name, value) => {
        const fieldsUpdate = FieldsManager.updateField(this.state.fields, name, value);
        let queryFields = this.state.queryFilterFields

        queryFields = FieldsManager.updateField(queryFields, 'ColumnFilters', true)

        this.setState({
            fields: fieldsUpdate,
            queryFilterFields: queryFields
        }, () => {
            this.fetchData();
            this.persistFilters(fieldsUpdate);
            this.saveFilters();
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        const queryFilterFields = FieldsManager.getFieldKeyValues(this.state.queryFilterFields);
        const queryFilterFieldsWithValues = Object.keys(queryFilterFields).filter(it => !!queryFilterFields[it]).reduce((memo, it) => {
            memo[it] = queryFilterFields[it];
            return memo;
        }, {});

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...queryFilterFieldsWithValues,
            limit: this.state.queryFilterFields.limit.value
        }
    }

    getQueryFilterFields = (item = {}) => {
        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 10, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: "top"})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            LoadQuoteID: new Field('LoadQuoteID', '', [''], false, 'custom', {icon: false}),

            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),

            PriceTypeID: new Field('PriceTypeID', '', [''], false, "custom", {
                render: (it) => {
                    return this.props.translate("option." + LOAD_PRICING_TYPES[it.PriceTypeID]);
                }
            }),

            PriceBase: new Field('PriceBase', '', [''], false, 'money'),

            IsConvertedToLoad: new Field('IsConvertedToLoad', 0, [''], false, "custom", {
                render: (it) => {
                    return !!it.IsConvertedToLoad && !!it.LoadID ? (<button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openInNewTab(`/loads/info/${it.LoadID}`);
                        }}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {it.LoadID}
                    </button>) : undefined
                }
            }),
            IsRejected: new Field('IsRejected', 0, [''], false, ['custom'], {
                render: (it) => {
                    return it.IsRejected ? <XCircleIcon className="w-5 h-5 text-red-600"/> : undefined
                }
            }),

            Customer: new Field('Customer', "", [''], false, 'select-search', {}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['offset', 'sort', 'sortBy', 'paginationPage', 'limit'])
        LocalStorage.persistFilters(this.pagePath, this.state.queryFilterFields);
    }

    toggleCreateNewTagModal = () => {
        this.setState({isCreateNewTagModalOpen: !this.state.isCreateNewTagModalOpen})
    }

    getPrimaryKey = () => {
        return 'LoadQuoteID'
    }

    getResource = () => {
        return Resources.QuotesLoads
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])

        const isLoading = resource.isLoading
        const count = resource?.data?.count

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.quotes')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateResourceClick()}
                        hasPerm={checkPerm(Resources.QuotesLoad, CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <Tippy
                            content={translate('btn.plan_load')}
                            delay={[400, 0]}
                            trigger="mouseenter"
                        >
                            <button
                                className={
                                    classNames(
                                        'btn btn-header'
                                    )
                                }
                                onClick={this.toggleLoadPreviewDialog}
                            >
                                <CalculatorIcon
                                    className="w-5 h-5"
                                />
                            </button>
                        </Tippy>
                    </PageHeader>

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            excludedFields={['limit', 'DateType']}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.toggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass="relative">
                        <TableFilters
                            hideLimit
                            filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType', 'ColumnFilters'])}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={isLoading}
                            selects={{
                                limit: {
                                    10: '10',
                                    20: '20',
                                    30: '30',
                                    40: '40',
                                    50: '50',
                                    100: '100',
                                },
                                IsBroker: {0: 'Carrier', 1: 'Brokerage'},
                                customers: {
                                    api: 'api/' + Resources.CustomersQuick,
                                    query: this.state.queryFilterFields.customers?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName || item.Organization,
                                        value: item.CustomerID
                                    })
                                },
                                Tags: getLookup('TaskTagType')
                            }}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(Resources.QuotesLoad, UPDATE_PERM) ? this.handleTableRowClick : null}
                            onEdit={this.handleEditResourceClick}
                            onDelete={this.handleDeleteResourceClick}

                            hasEditPerm={checkPerm(Resources.QuotesLoad, UPDATE_PERM)}
                            hasDeletePerm={checkPerm(Resources.QuotesLoad, DELETE_PERM)}

                            actions={[
                                {
                                    action: this.handleConvertToLoadClick,
                                    icon: ArrowPathIcon,
                                    visible: (it) => !it.IsConvertedToLoad && !it.IsRejected,
                                    hasPerm: checkPerm(Resources.QuotesLoadConvert, CREATE_PERM),
                                    label: false,
                                    title: translate('btn.convert_to_load'),
                                    disabled: false,
                                    class: "btn-table-action hover:text-green-600 hover:bg-green-600/10",
                                    iconClass: "w-5 h-5"
                                },
                                {
                                    action: this.handleRejectQuoteClick,
                                    icon: XCircleOutlineIcon,
                                    hasPerm: checkPerm(Resources.QuotesLoadReject, CREATE_PERM),
                                    visible: (it) => !it.IsConvertedToLoad && !it.IsRejected,
                                    label: false,
                                    title: translate('btn.reject_quote'),
                                    disabled: false,
                                    class: "btn-table-action hover:text-red-600 hover:bg-red-600/10",
                                    iconClass: "w-5 h-5"
                                },
                                {
                                    action: this.handleCopyQuote,
                                    icon: Square2StackIcon,
                                    hasPerm: checkPerm(Resources.QuotesLoad, CREATE_PERM),
                                    label: false,
                                    title: translate('btn.duplicate_quote'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(Resources.QuotesLoad, CREATE_PERM)}
                            title={translate("text.no_matching_records")}
                            text={translate("text.create_new_quote")}
                            btnLabel={translate("btn.create_quote")}
                            onBtnClick={() => {
                                this.props.history.push(`/quotes/create?q=${this.state.queryFilterFields.query.value}`)
                            }}
                            excludeFields={['query', 'limit']}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        {/*Table footer*/}
                        {!(!data.length && !isLoading) && (
                            <TableCardFooter>
                                <Pagination
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    count={count}
                                    isLoading={resource.isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        )}
                    </TableCard>
                </Page>

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.toggleColumnSettingsDialog}
                    translate={translate}
                />

                <Modal
                    show={this.state.isLoadPreviewDialogOpen}
                    widthClass={"max-w-7xl"}
                    onClose={this.toggleLoadPreviewDialog}
                >
                    <LoadPlaningModalBody
                        title={translate("dialog_heading.load_planing")}
                        dispatch={this.props.dispatch}
                        history={this.props.history}
                        previewData={this.state.loadPreviewData}
                        dialogResource={this.props.dialogResource}
                        isLoading={this.props.dialogResource.isLoading}
                        onSubmit={this.handlePreviewLoadClick}
                        onResetLoadReviewState={() => this.setState({loadPreviewData: {}})}

                        onClose={this.toggleLoadPreviewDialog}
                        translate={translate}
                    />
                </Modal>

                <ModalConfirm
                    show={this.state.isConfirmDeleteDialogOpen}
                    title={this.state.confirmTitle}
                    text={this.state.confirmText}
                    onClose={this.handleCloseConfirmDialogClick}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmSubmit}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(QuotesView)
