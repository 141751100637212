import React from "react";
import {BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/20/solid";
import {useDispatch} from "react-redux";
import {classNames} from "../../util/util-helpers";
import CellButton from "../resource-table/table-components/cell-button";
import {showModal} from "../../../data/actions/ui";

export default function InnerGroupTable({
                                            data,
                                            fields,
                                            hideFooter,
                                            onSort,
                                            tableIndex,
                                            sort,
                                            translate
                                        }) {

    const dispatch = useDispatch();
    const columns = Object.values(fields);

    function getCellTitle(tableIndex) {
        if (fields.PrimaryDispatch) {
            if (data[tableIndex]["Dispatcher"]) {
                return data[tableIndex]["Dispatcher"];
            }

            return "No dispatcher"
        }

        if (fields.ContactGroup) {
            if (data[tableIndex]["ContactGroup"]) {
                return data[tableIndex]["ContactGroup"]
            }

            return "Not grouped";
        }
    }

    return <div
        className="flex flex-col shrink-0 sticky left-0 z-10"
    >
        {!hideFooter && (
            <header className="sticky top-0">
                <div className="flex">
                    {
                        columns.filter(col => col.type !== 'hidden').map(col => {
                                const canSort = !!col?.metadata?.canSort;
                                const DynamicElement = canSort ? "button" : "div"

                                return <DynamicElement
                                    key={col.name}
                                    onClick={canSort ? (() => onSort(col.name)) : undefined}
                                    className={classNames(
                                        "cc-header col-group",
                                        canSort ? "hover:bg-tm-gray-200" : undefined
                                    )}
                                >
                                    {col?.metadata?.label ?? translate("field." + col.name)}

                                    {canSort && (
                                        <div className={
                                            classNames(
                                                sort ? "text-tm-gray-800" : "text-tm-gray-300",
                                                "inline-flex flex-shrink-0 ml-2 w-5 h-5"
                                            )
                                        }>
                                            {!(sort === "DESC") && (
                                                <BarsArrowUpIcon/>
                                            )}

                                            {sort === "DESC" && (
                                                <BarsArrowDownIcon/>
                                            )}
                                        </div>
                                    )}
                                </DynamicElement>
                            }
                        )
                    }
                </div>
            </header>
        )}

        <div className={
            classNames(
                "col-group border-r border-tm-gray-200 relative truncate",
                tableIndex % 2 === 0 ? "bg-inverse" : "bg-tm-gray-100"
            )}
             title={getCellTitle(tableIndex)}
        >
            {fields.PrimaryDispatch && (data[tableIndex]["Dispatcher"]
                    ? <CellButton
                        onClick={() => {
                            dispatch(showModal('ViewContactCard', {ContactID: data[tableIndex]["key"]}));
                        }}
                    >
                        {data[tableIndex]["Dispatcher"]}
                    </CellButton>
                    : "No dispatcher"
            )}
            {fields.ContactGroup && (data[tableIndex]["ContactGroup"]
                    ? data[tableIndex]["ContactGroup"]
                    : "Not grouped"
            )}
        </div>
    </div>
}