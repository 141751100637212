import {StripeIcon} from "../../../../data/themes/icons";
import ModalConfirm from "../modal-confirm";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideGlobalModal, showGlobalModal} from "../../../../data/actions/ui";
import CopyToClipboardButton from "../../buttons/copy-to-clipboard-button";
import {openInNewTab} from "../../../util/util-helpers";

export default function StripePaymentLinkModal({translate}) {
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);

    return (
        <ModalConfirm
            title={'Payment link created'}
            show={!!ui?.globalDialogs?.StripePaymentLinkModal?.visible}
            type="custom"
            widthClass="max-w-2xl"
            customIcon={<StripeIcon fill="#fff" className="w-5 h-5"/>}
            customIconBG="bg-[#6772e5]"
            onClose={() => dispatch(hideGlobalModal("StripePaymentLinkModal"))}
            closeButtonLabel={translate('btn.close')}
            secondaryButtonLabel={"Show all payment links"}
            onSecondaryButtonClick={() => {
                dispatch(hideGlobalModal("StripePaymentLinkModal"));
                dispatch(showGlobalModal("StripePaymentLinksListModal")); // not global
            }}
            translate={translate}
        >
            <div className="flex justify-between items-center form-control w-auto text-base pl-8 font-semibold">
                <span
                    className="cursor-pointer text-primary hover:underline"
                    onClick={() => openInNewTab(ui?.globalDialogs?.StripePaymentLinkModal?.data)}
                >
                    {ui?.globalDialogs?.StripePaymentLinkModal?.data}
                </span>

                <CopyToClipboardButton
                    clipboardText={ui?.globalDialogs?.StripePaymentLinkModal?.data}
                    addClass="relative"
                    translate={translate}
                />
            </div>
        </ModalConfirm>
    )
}