import {classNames} from '../../../../common/util/util-helpers'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import {numberWithCommasToBack} from '../../../../util/util-formaters'
import React from 'react'
import {ArrowTrendingDownIcon} from '@heroicons/react/20/solid'
import {EyeIcon, PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import Card from "../../../../common/components/card";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import {excludeFields} from "../../../../common/util/util-fields";

export default function LoadPricingCarriersFee({
                                                   fields,
                                                   toggleCreateDialog,
                                                   toggleEditDialog,
                                                   toggleViewDialog,
                                                   handleDeleteClick,
                                                   additionalFields,
                                                   onAdditionalFieldsChange,
                                                   showAdditionalFields,
                                                   getFields,
                                                   translate
                                               }) {

    const hasItems = !!Object.values(fields).length
    const calculatedAmount = Number(Object.values(fields).reduce((memo, it) => {
        memo = memo + numberWithCommasToBack(it.AmountCalculated)
        return memo
    }, 0))

    return (
        <React.Fragment>
            <div className="">
                <Card
                    addClass={classNames(
                        'col-span-2 xl:col-span-1 overflow-hidden min-h-[13rem] flex z-0 relative'
                    )}
                    addBodyClass={'border-0 border-l-4 border-red-700'}
                    bodyClass="flex flex-col w-full grow justify-center"
                >
                    {!!hasItems && (
                        <div className="px-4 py-3 flex justify-between items-center">
                            <h2 className="text-lg text-tm-gray-700 flex gap-2">
                                <ArrowTrendingDownIcon className="w-5 h-5 text-red-700"/>

                                {translate('text.carrier_fee')}
                            </h2>

                            <button
                                className="btn btn-outline-secondary py-1"
                                onClick={toggleCreateDialog}
                            >
                                <PlusIcon className="w-5 h-5 mr-1"/>
                                {translate('btn.carrier_fee')}
                            </button>
                        </div>
                    )}

                    {showAdditionalFields && (
                        <div className='px-5 border-t border-tm-gray-200'>
                            <FieldsToHtml
                                fieldsState={additionalFields}
                                translate={translate}
                                onInputChange={onAdditionalFieldsChange}
                            />
                        </div>
                    )}

                    <ResourceTable
                        data={fields}
                        fields={excludeFields(getFields({}), ['CarrierPayNotes'])}
                        translate={translate}
                        isLoading={false}

                        options={{
                            style: {
                                verticalLines: true,
                                floatingActions: false,
                            },
                            classes: {
                                headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                                primaryTextClass: ''
                            }
                        }}

                        onRowClick={(it) => {
                            if (it.IsPayed) {
                                toggleViewDialog(it, it.dataIndex);
                            } else {
                                toggleEditDialog(it, it.dataIndex);
                            }
                        }}

                        actions={[
                            {
                                action: (it) => toggleViewDialog(it, it.dataIndex),
                                icon: EyeIcon,
                                visible: (it) => it.IsPayed,
                            },
                            {
                                action: (it) => toggleEditDialog(it, it.dataIndex),
                                icon: PencilIcon,
                                visible: (it) => !it.IsPayed,
                            },
                            {
                                action: (it) => handleDeleteClick(it, it.dataIndex),
                                icon: TrashIcon,
                                visible: (it) => !it.IsPayed,
                            }
                        ]}

                        tfoot={{
                            CarrierID: () => <div className={"px-5"}>{translate('text.total')}</div>,
                            AmountCalculated: calculatedAmount
                        }}
                    />

                    <NoRecords
                        show={!hasItems}
                        onBtnClick={toggleCreateDialog}
                        addClass="px-4 py-10"
                        title={translate('title.no_carriersFee')}
                        text={translate('text.no_carriersFee')}
                        btnLabel={translate('btn.add_carriersFee')}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}
