import InfoBar from "../../../info-paragraph/info-bar";
import {checkPerm, openInNewTab} from "../../../../util/util-helpers";
import {getTimeDiff, getUserTimezone, toFrontDateFromUTC} from "../../../../util/util-dates";
import React from "react";
import moment from "moment-timezone";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../util/util-consts";
import {CREATE_PERM, DEFAULT_DATABASE_TIMEZONE} from "../../../../../util/util-constants";
import Resources from "../../../../../data/services/resources";
import {LoaderSmall} from "../../../loader";

export default function LastBookedLoad({lastLoad, lastBookedLoadMessage, isLoading, noLastLoadMessage, translate}) {
    const dateNow = moment.utc()
        .tz(getUserTimezone())
        .format(DEFAULT_DATABASE_DATETIME_FORMAT);


    const createDate = !!lastLoad?.CreateDate && moment.utc(lastLoad?.CreateDate, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone()).format()

    const hasLastLoad = !!lastLoad && lastLoad !== -1;
    const noLastLoad = !!lastLoad && lastLoad === -1

    return (
        <>
            {
                hasLastLoad && !isLoading && (
                    <InfoBar addClass="flex-wrap">
                        {lastBookedLoadMessage ?? `Last booked load `}
                        <button
                            disabled={checkPerm(Resources.LoadInfo, CREATE_PERM)}
                            onClick={() => openInNewTab(`/loads/info/${lastLoad.LoadID}`)}
                            className="text-primary font-bold disabled:text-tm-gray-900"
                        >
                            {lastLoad.LoadNumber}
                        </button>
                        {`was ${getTimeDiff(createDate, dateNow)?.value} ${getTimeDiff(createDate, dateNow)?.unit} ago ( ${toFrontDateFromUTC(lastLoad.CreateDate)} ).`}
                    </InfoBar>
                )
            }

            {noLastLoad && !isLoading && (
                <InfoBar>{noLastLoadMessage ?? translate("text.customer_no_booked_loads")}</InfoBar>
            )}

            {isLoading && (
                <div>
                    <LoaderSmall disableContainer={true}/>
                </div>
            )}
        </>
    )
}