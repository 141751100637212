import InvoiceExpenseDetailsDialog from "../../../../views/accounting/dialogs/invoice-expense-details-dialog";
import {
    createAccountingInfoResource,
    deleteAccountingInfoResource,
    updateAccountingInfoResource
} from "../../../../data/actions/accountingInfoResource";
import {download} from "../../../../data/actions/download";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {currentDate} from "../../../util/util-dates";
import ModalDefault from "../modal-default";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

export default function InvoiceModal({show, onFetchData, onClose, selectedItem, translate}) {
    const dispatch = useDispatch();
    const thirdResource = useSelector(state => state.thirdResource);
    const accountingInfoResource = useSelector(state => state.accountingInfoResource);
    const isInvoiceLoading = !!accountingInfoResource.isLoading;

    return (
        <ModalDefault
            show={show}
            widthClass={'max-w-full'}
            title={translate('text.InvoiceID')}
            closeButtonLabel={translate('btn.Close')}
            onClose={onClose}
        >
            <InvoiceExpenseDetailsDialog
                resource={accountingInfoResource}
                dialogResource={thirdResource}
                isLoading={isInvoiceLoading}
                translate={translate}
                dispatch={dispatch}

                selectedItem={selectedItem}

                type="invoice"
                canDeletePayment={false}
                showLoadReferenceLinks

                createCommentDispatchAction={createAccountingInfoResource}
                deleteCommentDispatchAction={deleteAccountingInfoResource}

                onDocumentDownload={(item) => {
                    const fileExt = item?.AttachPath.split('.').pop()

                    dispatch(download({
                        user: LocalStorage.get('user'),
                        resource: Resources.InvoicesDocuments,
                        query: Object.assign({
                            id: item.InvoiceID,
                            format: fileExt,
                            name: 'document_' + currentDate() + '.' + fileExt
                        })
                    }))
                }}

                onFetchData={() => {
                    onFetchData()
                }}

                onFactoringToggle={(item) => {
                    dispatch(updateAccountingInfoResource({
                        user: LocalStorage.get('user'),
                        resource: Resources.InvoicesFactoring,
                        params: {
                            InvoiceID: item.InvoiceID
                        },
                        errorMessage: true,
                        successMessage:
                            item.IsFactoringActive ?
                                translate('text.UnmarkedForFactoring') :
                                translate('text.MarkedForFactoring')
                    }))
                }}
            />
        </ModalDefault>
    )
}