import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {showGlobalModal} from '../../../data/actions/ui'
import {
    getDefaultFieldOrder,
    getDefaultTableOptions,
    longTableColumn,
    orderFields,
    returnOffsetAndPagination,
} from'../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import Tippy from '@tippyjs/react'
import {Bars4Icon, ViewColumnsIcon} from '@heroicons/react/24/outline'
import {Squares2X2Icon} from '@heroicons/react/20/solid'
import SimpleBoard from './board-view'
import {
    BugLevel,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    ticketingStatus,
    TicketTypes,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {checkPerm, classNames, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import Modal from "../../../common/components/modal";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TicketingInfoDialog from "../../../common/components/modal/ticketingInfoDialog";
import Layout from "../../../common/components/layout";

class TicketsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'TicketsID',
            sort: 'DESC',

            fields: this.getFields(),
            fieldsOrder: getDefaultFieldOrder(this.getFields(), this.pagePath),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            prefilled: '',

            dataView: LocalStorage.has('dataView') ? LocalStorage.get('dataView') : 'table',

            confirmDialog: false,
            TicketStatusID: false,
            createTaskDialog: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Tickets,
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Tickets,
            query: Object.assign({format: 'EXCEL', name: 'ticket' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Tickets,
            query: Object.assign({
                format: 'EXCEL',
                name: 'tickettemplate' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_archive_generic') + ` ${item.Title}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {id: item.TicketsID}),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.Title]),
                        resource: Resources.Tickets,
                        piggyResource: Resources.Tickets
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.Title}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.TicketsID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Ticket ${item.Title} restored`,
                        resource: Resources.Tickets,
                        piggyResource: Resources.Tickets
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: true
        })
    }

    handleCreateUpdateResource = (item = null) => {
        this.setState({selectedItem: item, showUpdateDialog: !this.state.showUpdateDialog})
    }

    handleSetDataView = (view) => {
        LocalStorage.set('dataView', view)
        this.setState({dataView: view}, this.fetchData)
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        let query = {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                TicketTypeID: this.state.queryFilterFields.TicketTypeID.value,
                TicketLevelID: this.state.queryFilterFields.TicketLevelID.value,
                TicketStatusID: this.state.queryFilterFields.TicketStatusID.value,
            })
        }
        if (this.state.dataView === 'board') delete query.limit

        return query
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            archived: new Field('archived', '', [''], false, 'checkbox', {}, {}),
            mine: new Field('mine', '', [''], false, 'checkbox', {}, {}),
            TicketStatusID: new Field('TicketStatusID', '', [''], false, 'select', {}, {}),
            TicketTypeID: new Field('TicketTypeID', '', [''], false, 'select', {}, {}),
            TicketLevelID: new Field('TicketLevelID', '', [''], false, 'select', {}, {}),
            limit: new Field('limit', 10, [''], false, 'select')
        }
    }

    getFields = () => {
        return {
            TicketsID: new Field('TicketsID', '', ['empty'], false, 'text', {onCellClick: (item) => checkPerm(Resources.Tickets, UPDATE_PERM) ? this.handleCreateUpdateResource(item) : item.disabled}),
            Contact: new Field('Contact', '', ['empty'], false, 'text', {}),
            Title: new Field('Title', '', ['empty'], false, 'text', {}),
            Description: new Field('Description', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <>{longTableColumn(item.Description)}</>
                )
            }),
            TicketTypeID: new Field('TicketTypeID', '', ['empty'], false, 'select'),
            TicketStatusID: new Field('TicketStatusID', '', ['empty'], false, 'select'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone')
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.Tickets
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])

        let queryFilterFields = Object.assign({}, this.state.queryFilterFields)
        if (this.state.dataView === 'board') queryFilterFields.limit.type = 'hidden'
        if (this.state.dataView === 'table') queryFilterFields.limit.type = 'select'

        const metadata = {
            TicketTypeID: TicketTypes,
            TicketLevelID: BugLevel,
            TicketStatusID: ticketingStatus,
        }

        return (
            <Layout {...this.props}>
                <Page>
                    <PageHeader
                        title={translate('page.heading.TicketsView')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.props.dispatch(
                            //showModal('Ticketing', { piggyQuery: this.getQuery() }))
                            showGlobalModal('ticketingModal', {piggyQuery: this.getQuery()})
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />
                    </div>

                    <TableCard>
                        <TableFilters
                            selects={metadata}
                            filterFields={queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            hideLimit={this.state.dataView === 'board'}
                            customHeaderHtml={
                                <div className="">
                                    <Tippy content={translate('text.board_view')}>
                                        <button
                                            onClick={() => this.handleSetDataView('board')}
                                            className={classNames(this.state.dataView === 'board' ? 'bg-tm-gray-200' : undefined, "h-full relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-inverse text-sm font-medium text-tm-gray-400 focus:z-10 focus:outline-none focus:ring-1")}
                                        >
                                            <ViewColumnsIcon className="h-5 w-5" aria-hidden="true"
                                                             style={this.state.dataView === 'board' ? {stroke: '#fff'} : {}}/>
                                        </button>
                                    </Tippy>
                                    <Tippy content={translate('text.table_view')}>
                                        <button
                                            onClick={() => this.handleSetDataView('table')}
                                            className={classNames(this.state.dataView === 'table' ? 'bg-tm-gray-200' : undefined, "h-full relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-inverse text-sm font-medium text-tm-gray-400 focus:z-10 focus:outline-none focus:ring-1")}
                                        >
                                            <Bars4Icon className="h-5 w-5" aria-hidden="true"
                                                       style={this.state.dataView === 'table' ? {stroke: '#fff'} : {}}/>
                                        </button>
                                    </Tippy>
                                    <Tippy content={translate('text.grid_view')}>
                                        <button
                                            onClick={() => this.handleSetDataView('grid')}
                                            className={classNames(this.state.dataView === 'grid' ? 'bg-tm-gray-200' : undefined, "h-full relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-inverse text-sm font-medium text-tm-gray-400 focus:z-10 focus:outline-none focus:ring-1")}
                                        >
                                            <Squares2X2Icon className="h-5 w-5" aria-hidden="true"
                                                            style={this.state.dataView === 'grid' ? {fill: '#fff'} : {}}/>
                                        </button>
                                    </Tippy>
                                </div>
                            }
                        />

                        {this.state.dataView === 'table' && (
                            <div>
                                <ResourceTable
                                    data={data}
                                    count={resource?.data?.count}

                                    fields={orderFields(this.state.fields, this.state.fieldsOrder)}
                                    translate={this.props.translate}
                                    isLoading={resource.isLoading}

                                    limit={this.state.queryFilterFields.limit.value}

                                    offset={this.state.offset}
                                    page={this.state.paginationPage}
                                    paginationButtonLimit={5}
                                    onOffsetChange={this.handleUpdateOffset}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}

                                    onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                                    defaultAction={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}

                                    onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                                    onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                                    onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                                />

                                {!!resource?.data?.count && (
                                    <div className="px-6 py-2">
                                        <Pagination
                                            count={resource?.data?.count}
                                            pageLimit={this.state.queryFilterFields.limit.value}
                                            hideRowsPerPage={true}
                                            pageOffset={this.state.offset}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffset(value, currentPage)
                                                    : this.handleFilterInputChange(name, value)
                                            }

                                            translate={translate}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {this.state.dataView === 'grid' && (
                            <div className="p-4 overflow-auto">
                                <ResourceTable
                                    verticalTableIsVisible={true}
                                    verticalTableContainerClass="grid gap-4 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4"
                                    tableKey="TicketsID"
                                    data={data}
                                    count={resource?.data?.count}

                                    fields={orderFields(this.state.fields, this.state.fieldsOrder)}
                                    translate={this.props.translate}
                                    isLoading={resource.isLoading}

                                    limit={this.state.queryFilterFields.limit.value}
                                    options={this.state.tableOptions}
                                    offset={this.state.offset}
                                    page={this.state.paginationPage}
                                    paginationButtonLimit={5}
                                    onOffsetChange={this.handleUpdateOffset}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}


                                    defaultAction={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}

                                    onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                                    onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                                    onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                                />
                            </div>
                        )}

                        {this.state.dataView === 'board' && (
                            <SimpleBoard
                                isDraggable
                                data={data}
                                isLoading={resource.isLoading}
                                translate={translate}
                                handleUnlinkIssue={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                                handleRestoreIssue={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                                handleDroppedItem={(draggedItem, Status) => {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: {
                                            OldValue: draggedItem?.TicketStatusID,
                                            TicketStatusID: +Status,
                                            TicketsID: draggedItem.TicketsID
                                        },
                                        piggyQuery: this.getQuery(),
                                        errorMessage: true,
                                        successMessage: translate('text.TicketStatusUpdatedTo', [ticketingStatus[Status]]),
                                        resource: Resources.TicketsInfo,
                                        piggyResource: Resources.Tickets
                                    }))
                                }}
                                handleCreateUpdateResource={this.handleCreateUpdateResource}
                                columns={ticketingStatus}
                            />
                        )}

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            title={'No matching records found'}
                            text={'Create a new ticket'}
                            btnLabel="Create ticket"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.props.dispatch(showGlobalModal('ticketingModal', {
                                        title: this.state.prefilled,
                                        piggyQuery: this.getQuery()
                                    }))
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>
                </Page>

                <ModalConfirm
                    title={translate('text.Confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <Modal
                    show={this.state.showUpdateDialog}
                    widthClass={'max-w-5xl'}
                    translate={translate}
                    onClose={() => this.setState({selectedItem: null, showUpdateDialog: false}, this.fetchData)}
                >
                    <TicketingInfoDialog
                        show={this.state.showUpdateDialog}
                        id={this.state.selectedItem?.TicketsID}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        onClose={() => this.setState({selectedItem: null, showUpdateDialog: false}, this.fetchData)}
                    />
                </Modal>
            </Layout>
        )
    }
}

export default connect(state => state)(TicketsView)
