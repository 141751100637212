import React from 'react'

export default function CellButton({onClick, className, disabled, children}) {
    return <button
        className={className ?? "leading-5 inline-flex py-0.5 -mx-2 px-2 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary truncate text-primary-shade font-btn font-medium hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"}
        disabled={disabled}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }}>

        {children}

    </button>
}
