import React from "react";
import TimeDropdownOption from "./time-dropdown-option";

const FieldTimeNewDropdown = ({
                                  innerRef,
                                  wholeFieldRef,
                                  hoursInputRef,
                                  hoursOptionsRef,
                                  minutesInputRef,
                                  periodInputRef,
                                  hoursValue,
                                  onSetHoursValue,
                                  minutesValue,
                                  onSetMinutesValue,
                                  hasTimePeriod,
                                  periodOptions,
                                  periodValue,
                                  onSetPeriodValue,
                                  hoursOptions,
                                  minutesOptions,
                                  hideDropdown,
                                  setIsWholeFieldFocused
                              }) => {

    function onHourOptionKeyDown(e) {
        const currentValueIndex = hoursOptions.indexOf(hoursValue);

        if (e.key === 'ArrowUp') {
            e.preventDefault();
            let selectedHour = hoursOptions[currentValueIndex - 1]
                ? currentValueIndex - 1 // Go up
                : hoursOptions.length - 1; // Go to the end
            onSetHoursValue(hoursOptions[selectedHour]);
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const selectedHour = hoursOptions[currentValueIndex + 1]
                ? currentValueIndex + 1 // Go down
                : 0; // Go to the start

            onSetHoursValue(hoursOptions[selectedHour]);
        }

        if (e.key === 'ArrowRight') {
            e.preventDefault();
            minutesInputRef.current.focus();
        }

        if (e.key === 'Tab') {
            hoursInputRef.current.focus();
        }

        if (e.key === 'Enter' || e.key === 'Escape' || (e.key === 'Tab' && e.shiftKey)) {
            hideDropdown();
            wholeFieldRef.current.focus();
            setIsWholeFieldFocused(true);
        }
    }

    function onMinuteOptionKeyDown(e) {
        const currentValueIndex = minutesOptions.indexOf(minutesValue);

        if (e.key === 'ArrowUp') {
            e.preventDefault();
            const selectedMinute = currentValueIndex - 1 !== -1 && currentValueIndex - 1 !== -2
                ? currentValueIndex - 1  // Go up
                : minutesOptions.length - 1; // Go to the end
            onSetMinutesValue(minutesOptions[selectedMinute]);
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const selectedMinute = currentValueIndex + 1 !== -1 && minutesOptions[currentValueIndex + 1]
                ? currentValueIndex + 1// Go down
                : 0; // Go to the start

            onSetMinutesValue(minutesOptions[selectedMinute]);
        }

        if (e.key === 'ArrowLeft') {
            e.preventDefault();
            hoursInputRef.current.focus();
        }

        if (e.key === 'ArrowRight') {
            e.preventDefault();
            periodInputRef.current.focus();
        }

        if (e.key === 'Tab') {
            minutesInputRef.current.focus();
        }

        if (e.key === 'Enter' || e.key === 'Escape') {
            hideDropdown();
            wholeFieldRef.current.focus();
            setIsWholeFieldFocused(true);
        }
    }

    function onPeriodOptionKeyDown(e) {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            onSetPeriodValue(periodValue === "AM" ? "PM" : "AM");
        }

        if (e.key === 'p' || e.key === 'P') {
            onSetPeriodValue("PM");
        }

        if (e.key === 'a' || e.key === 'A') {
            onSetPeriodValue("AM");
        }

        if (e.key === 'ArrowLeft') {
            e.preventDefault();
            minutesInputRef.current.focus();
        }

        if (e.key === 'Tab' && !e.shiftKey) {
            minutesInputRef.current.focus();
        }

        if (e.key === 'Tab' && e.shiftKey) {
            periodInputRef.current.focus();
        }

        if (e.key === 'Enter' || e.key === 'Escape') {
            hideDropdown();
            wholeFieldRef.current.focus();
            setIsWholeFieldFocused(true);
        }
    }

    return (
        <div
            ref={innerRef}
            className="left-0 right-0 my-2 absolute z-[11] text-center"
        >
            <div className="inline-flex justify-center gap-2 rounded-card p-1 shadow-card box-border bg-popup text-primary border border-tm-gray-200 w-auto mx-auto overflow-hidden">

                <div
                    className="scrollbar-hidden snap-y nap-start overflow-y-auto w-10 h-64"
                    tabIndex="-1"
                >
                    {!!hoursOptions && hoursOptions.map(option => {
                        return <TimeDropdownOption
                            innerRef={hoursOptionsRef}
                            key={option}
                            option={option}
                            isSelected={option === hoursValue}
                            onClick={onSetHoursValue}
                            onKeyDown={onHourOptionKeyDown}
                        />
                    })}
                </div>

                <div
                    className="scrollbar-hidden snap-y nap-start overflow-y-auto h-64 w-10"
                    tabIndex="-1"
                >
                    {!!minutesOptions && minutesOptions.map(option => {
                        return <TimeDropdownOption
                            key={option}
                            option={option}
                            isSelected={option === minutesValue}
                            onClick={onSetMinutesValue}
                            onKeyDown={onMinuteOptionKeyDown}
                        />
                    })}
                </div>

                {hasTimePeriod && (
                    <div
                        className="scrollbar-hidden snap-y nap-start overflow-y-auto h-64 w-10"
                        tabIndex="-1"
                    >
                        {!!periodOptions && periodOptions.map(option => {
                            return <TimeDropdownOption
                                key={option}
                                option={option}
                                isSelected={option === periodValue}
                                onClick={onSetPeriodValue}
                                onKeyDown={onPeriodOptionKeyDown}
                            />
                        })}
                    </div>
                )}
            </div>


        </div>
    )
}

export default FieldTimeNewDropdown;
