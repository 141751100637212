import React, {useEffect, useRef, useState} from "react";
import FieldTimeDropdown from "./field-time-dropdown";
import moment from "moment";
import {classNames} from "../../../util/util-helpers";
import FieldTimeFocusElement from "./field-time-focus-element";

// TODO: Make component respect hh and h hour format, now it defaults to hh.
// Don't trigger on change if value is the same

export default function FieldTimeCustomNew({onChange, name, value, timeFormat, withDateField, disabled, errorMessage}) {
    const inputClass = "w-6 h-6 text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-0.5 rounded-sm bg-field text-tm-gray-700";

    const defaultTimeFormats = [
        "h:mm A", "HH:mm"
    ];

    if (!defaultTimeFormats.includes(timeFormat)) {
        timeFormat = defaultTimeFormats[0];
    }

    const periodOptions = ["AM", "PM"];
    const hoursOptions = timeFormat === "h:mm A"
        ? ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
        : ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

    const minutesOptions = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

    const MAX_HOURS = timeFormat === "h:mm A" ? 12 : 23;
    const hasTimePeriod = timeFormat === "h:mm A";

    const inputsContainerRef = useRef();
    const hoursInputRef = useRef();
    const minutesInputRef = useRef();
    const periodInputRef = useRef();
    const dropdownRef = useRef();
    const wholeFieldRef = useRef();

    const hoursOptionsRef = useRef({});

    const [isWholeFieldFocused, setIsWholeFieldFocused] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [hoursValue, setHoursValue] = useState("");
    const [minutesValue, setMinutesValue] = useState("");
    const [periodValue, setPeriodValue] = useState(periodOptions[0]);

    const isEmpty = !hoursValue && minutesValue;

    function handleContainerClick() {
        hoursInputRef.current.focus();
    }

    function handleOnFocus() {
        setIsDropdownVisible(true);
        setIsWholeFieldFocused(true);
    }

    function setValue(e) {
        let value = e.target.value;
        value = value.replace(/[^0-9]/g, '');

        if (value.length > 2) {
            value = value.slice(2);
        }

        return value;
    }

    function handleHourChange(e) {
        let value = setValue(e);

        setHoursValue(value);
    }

    function handleMinutesChange(e) {
        let value = setValue(e);

        setMinutesValue(value);
    }

    function handleMouseUp(e) {
        if (e.button === 0) {
            e.target.select()
        }
    }

    function handleHoursBlur() {
        if (!hoursValue) {
            return null;
        }

        if (Number(hoursValue) > MAX_HOURS) {
            handleSetHoursValue("12");
        }

        if (Number(hoursValue) < 0) {
            handleSetHoursValue("00");
        }

        if (hoursValue.length === 1) {
            handleSetHoursValue("0" + hoursValue);
        }
    }

    function handleMinutesBlur() {
        if (!minutesValue) {
            return null;
        }

        if (Number(minutesValue) > 59) {
            handleSetMinutesValue("00");
        }

        if (Number(minutesValue) < 0) {
            handleSetMinutesValue("00");
        }

        if (minutesValue.length === 1) {
            handleSetMinutesValue((minutesValue) => "0" + minutesValue);
        }
    }

    function handleUnFocusComponent() {
        handleChangeValue();
        setIsDropdownVisible(false);
        setIsWholeFieldFocused(false);
    }

    function handleChangeValue(h, m, p) {
        let hours = h ?? hoursValue;
        let minutes = m ?? minutesValue;
        let period = p ?? periodValue;

        if (hoursValue && !minutesValue) {
            minutes = minutesOptions[0];
        }

        if (minutesValue && !hoursValue) {
            hours = hoursOptions[0];
        }

        let formattedValue = formatValue(hours, minutes, period);

        if (withDateField?.value && formattedValue) {
            const changeFieldName = withDateField?.name;
            onChange(changeFieldName, isEmpty ? "" : withDateField.value.split(" ")[0] + " " + formattedValue);
        }

        onChange(name, isEmpty ? "" : formattedValue);
    }


    function onHourInputKeyDown(e) {
        const currentValueIndex = hoursOptions.indexOf(hoursValue);

        if (e.key === 'ArrowUp') {
            e.preventDefault();
            let selectedHour = hoursOptions[currentValueIndex - 1]
                ? currentValueIndex - 1 // Go up
                : hoursOptions.length - 1; // Go to the end

            handleSetHoursValue(hoursOptions[selectedHour]);
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();

            const selectedHour = hoursOptions[currentValueIndex + 1]
                ? currentValueIndex + 1 // Go down
                : 0; // Go to the start
            handleSetHoursValue(hoursOptions[selectedHour]);
        }

        if (e.key === 'ArrowLeft') {
            e.preventDefault();
        }

        if (e.key === 'ArrowRight') {
            e.preventDefault();
            minutesInputRef.current.focus();
            minutesInputRef.current.select();
        }

        if (e.key === 'p' || e.key === 'P') {
            e.preventDefault();
            handleSetPeriodValue("PM");
        }

        if (e.key === 'a' || e.key === 'A') {
            e.preventDefault();
            handleSetPeriodValue("AM");
        }

        if (e.key === 'Enter' || e.key === 'Escape') {
            e.preventDefault()
            handleUnFocusComponent();
            wholeFieldRef.current.focus();
            setIsWholeFieldFocused(true);
        }

        if (e.key === 'Tab' && e.shiftKey) {
            handleUnFocusComponent();
        }
    }

    function onMinuteInputKeyDown(e) {
        const currentValueIndex = minutesOptions.indexOf(minutesValue);

        if (e.key === 'ArrowUp') {
            e.preventDefault();
            const selectedMinute = currentValueIndex - 1 !== -1 && currentValueIndex - 1 !== -2
                ? currentValueIndex - 1  // Go up
                : minutesOptions.length - 1; // Go to the end
            handleSetMinutesValue(minutesOptions[selectedMinute]);
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const selectedMinute = currentValueIndex + 1 !== -1 && minutesOptions[currentValueIndex + 1]
                ? currentValueIndex + 1// Go down
                : 0; // Go to the start

            handleSetMinutesValue(minutesOptions[selectedMinute]);
        }

        if (e.key === 'ArrowLeft') {
            e.preventDefault();
            hoursInputRef.current.focus();
            hoursInputRef.current.select();
        }

        if (e.key === 'ArrowRight' && timeFormat === defaultTimeFormats[0]) {
            e.preventDefault();
            periodInputRef.current.focus();
            periodInputRef.current.select();
        }

        if (timeFormat !== defaultTimeFormats[0] && e.key === 'Tab' && !e.shiftKey) {
            handleUnFocusComponent();
        }

        if (e.key === 'p' || e.key === 'P') {
            e.preventDefault();
            handleSetPeriodValue("PM");
        }

        if (e.key === 'a' || e.key === 'A') {
            e.preventDefault();
            handleSetPeriodValue("AM");
        }

        if (e.key === 'Enter' || e.key === 'Escape') {
            e.preventDefault()
            handleUnFocusComponent();
            wholeFieldRef.current.focus();
            setIsWholeFieldFocused(true);
        }
    }

    function onPeriodInputKeyDown(e) {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            handleSetPeriodValue(periodValue === "AM" ? "PM" : "AM");
        }

        if (e.key === 'p' || e.key === 'P') {
            handleSetPeriodValue("PM");
        }

        if (e.key === 'a' || e.key === 'A') {
            handleSetPeriodValue("AM");
        }

        if (e.key === 'ArrowLeft') {
            e.preventDefault();
            minutesInputRef.current.focus();
            minutesInputRef.current.select();
        }

        if (e.key === 'Tab' && !e.shiftKey) {
            handleUnFocusComponent();
            wholeFieldRef.current.focus();
        }

        if (e.key === 'ArrowRight') {
            e.preventDefault();
        }

        if (e.key === 'Enter' || e.key === 'Escape') {
            e.preventDefault()
            handleUnFocusComponent();
            wholeFieldRef.current.focus();
            setIsWholeFieldFocused(true);
        }
    }

    function formatValue(hours, minutes, period) {
        if (!hours || !minutes) {
            return "";
        }

        const time = timeFormat === defaultTimeFormats[0]
            ? hours + ":" + minutes + " " + period
            : hours + ":" + minutes

        return moment(time, timeFormat).format("HH:mm:ss")
    }

    function handleSetHoursValue(value) {
        setHoursValue(value);
        handleChangeValue(value, undefined, undefined);
    }

    function handleSetMinutesValue(value) {
        setMinutesValue(value);
        handleChangeValue(undefined, value, undefined);
    }

    function handleSetPeriodValue(value) {
        setPeriodValue(value);
        handleChangeValue(undefined, undefined, value);
    }

    useEffect(() => {
        if (value && moment(value, "HH:mm:ss").isValid()) {
            const timeValue = moment(value, "HH:mm:ss").format(timeFormat).split(" ");
            const period = timeValue[1];
            let hours = timeValue[0].split(":")[0];
            const minutes = timeValue[0].split(":")[1];

            if (hours.length === 1) {
                hours = "0" + hours; // TODO: Make this 6 instead of 06??
            }

            setHoursValue(hours);
            setMinutesValue(minutes);
            setPeriodValue(period);
        }
    }, [value, timeFormat]);

    /** Render
     ================================================================= */
    return (
        <div
            className="relative w-full"
        >
            <div
                className={
                    classNames(
                        errorMessage?.length ? "border-red-600" : undefined,
                        isWholeFieldFocused ? "border-primary" : "",
                        "form-control flex justify-center items-center gap-0.5"
                    )}
                onClick={handleContainerClick}
                ref={inputsContainerRef}
            >
                <input
                    ref={hoursInputRef}
                    className={inputClass}
                    placeholder="--"
                    value={hoursValue}
                    onFocus={handleOnFocus}
                    onBlur={handleHoursBlur}
                    onClick={e => e.stopPropagation()}
                    onMouseUp={handleMouseUp}
                    onKeyDown={onHourInputKeyDown}
                    onChange={handleHourChange}
                    disabled={disabled}
                />

                <span>:</span>

                <input
                    ref={minutesInputRef}
                    className={inputClass}
                    placeholder="--"
                    value={minutesValue}
                    onFocus={handleOnFocus}
                    onBlur={handleMinutesBlur}
                    onClick={e => e.stopPropagation()}
                    onMouseUp={handleMouseUp}
                    onKeyDown={onMinuteInputKeyDown}
                    onChange={handleMinutesChange}
                    disabled={disabled}
                />

                {defaultTimeFormats[0] === timeFormat && (
                    <input
                        ref={periodInputRef}
                        className={inputClass}
                        value={periodValue}
                        onFocus={handleOnFocus}
                        onClick={e => e.stopPropagation()}
                        onKeyDown={onPeriodInputKeyDown}
                        disabled={disabled}
                    />
                )}
            </div>

            {isDropdownVisible && (
                <FieldTimeDropdown
                    inputsContainerRef={inputsContainerRef}
                    wholeFieldRef={wholeFieldRef}
                    hoursOptionsRef={hoursOptionsRef}
                    hoursInputRef={hoursInputRef}
                    minutesInputRef={minutesInputRef}
                    periodInputRef={periodInputRef}
                    innerRef={dropdownRef}
                    hoursValue={hoursValue}
                    onSetHoursValue={handleSetHoursValue}
                    minutesValue={minutesValue}
                    onSetMinutesValue={handleSetMinutesValue}
                    hasTimePeriod={hasTimePeriod}
                    periodOptions={periodOptions}
                    periodValue={periodValue}
                    onSetPeriodValue={handleSetPeriodValue}
                    hoursOptions={hoursOptions}
                    minutesOptions={minutesOptions}
                    hideDropdown={handleUnFocusComponent}
                    setIsWholeFieldFocused={setIsWholeFieldFocused}
                />
            )}

            {/*{(isWholeFieldFocused || isDropdownVisible) && (*/}
                <FieldTimeFocusElement
                    isWholeFieldFocused={isWholeFieldFocused}
                    innerRef={wholeFieldRef}
                    dropdownRef={dropdownRef}
                    inputsContainerRef={inputsContainerRef}
                    hoursOptionsRef={hoursOptionsRef}
                    hoursInputRef={hoursInputRef}
                    minutesInputRef={minutesInputRef}
                    periodInputRef={periodInputRef}
                    hideDropdown={handleUnFocusComponent}
                    minutesValue={minutesValue}
                    hoursValue={hoursValue}
                    periodValue={periodValue}
                    onArrowUpDown={() => {
                        hoursInputRef.current.focus()
                    }}
                    isFocused={isWholeFieldFocused}
                />
            {/*)}*/}
        </div>
    )
}

