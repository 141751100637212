import React, {Component} from 'react'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getFullAddressName,
    getProp,
} from '../../../common/util/util-helpers'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {showMapDialog, showModal} from '../../../data/actions/ui'
import {Field, FieldsManager} from '../../../data/services/fields'
import L from 'leaflet'
import Tippy from '@tippyjs/react'
import 'react-leaflet-fullscreen/dist/styles.css'
import {MapPinIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import LoadInfoTracks from "./load-sections/load-info-tracks";
import {toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {
    CREATE_PERM, LOAD_STATUS_DISPATCHED, LOAD_STATUS_DELIVERED,
    UPDATE_PERM
} from '../../../util/util-constants'
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import CreateNewLocationDialog from "../../../common/components/modal/locations-tab/create-location-dialog";
import Modal from "../../../common/components/modal";
import {LoaderSmall} from "../../../common/components/loader";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import ButtonIntegration from "../../../common/components/button/button-integration";
import Button from "../../../common/components/button";
import ButtonsGroup from "../../../common/components/buttons/buttons-group";
import {checkFeatureFlag} from "../../../common/components/feature-flags";
import Accur8Tracking from "./load-sections/accur8-tracking";

class TracksTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            // ...DEFAULT_CRUD_STATE,
            queryFields: this.getQueryFields(),
            sortBy: 'LoadTrackID',
            sort: 'DESC',

            dataView: 'board',

            viewport: {
                center: [0, 0],
                zoom: 13
            },

            classIndicator: false,
            trackingOptionsDialog: false,
            tableOptions: getDefaultTableOptions(this.getFields(), {
                style: {
                    floatingActions: true,
                    stripedRows: true
                },
                columns: {
                    Number: {
                        minWidth: 70
                    },
                    Source: {
                        minWidth: 100
                    },
                    IsSynced: {
                        minWidth: 80
                    }
                }
            }, "load-info-tracks-full", this.props.translate),

            isSendSMSModalOpen: false,
            SMSModalPhoneField: {
                PhoneNumber: new Field('PhoneNumber', '', ['integer'], false, 'text')
            },
            isResend: false,
            isTrackingDetailsDialogOpen: false,
            editModalOpen: false,
            selectedItem: null
        }

        this.mapRef = React.createRef()
        this.groupRef = React.createRef()
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();

        const legend = L.control({position: 'bottomright'})
        legend.onAdd = () => {
            const div = L.DomUtil.create('div', 'info legend')
            const labels = [
                `<i style='background: green'>&nbsp;&nbsp;&nbsp;</i>&ndash;${this.props.translate('text.start')}`,
                `<i style='background: red'>&nbsp;&nbsp;&nbsp;</i>&ndash;${this.props.translate('text.locations')}`,
                `<i style='background: blue'>&nbsp;&nbsp;&nbsp;</i>&ndash;${this.props.translate('text.end')}`,
                `<i style='background: yellow'>&nbsp;&nbsp;&nbsp;</i>&ndash;${this.props.translate('text.pickup/destination')}`,
            ]
            div.innerHTML = labels.join('<br>')
            return div
        }
        if (this.mapRef.current) {
            const map = this.mapRef.current.leafletElement
            legend.addTo(map)
        }

        if (!!this.props?.fetchInfoData && !this.props?.info?.isLoading && this.props?.info?.data?.LoadID !== Number(this.props?.id)) {
            this.props.fetchInfoData();
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    handleQueryChange = (name, value) => {
        const queryFieldsUpdate = FieldsManager.updateField(this.state.queryFields, name, value)

        if (name === 'limit') {
            queryFieldsUpdate.offset.value = 0
        }
        this.setState({
            queryFields: queryFieldsUpdate
        });
    }

    archiveResource = (item) => {
        this.handleShowConfirmDialog(() => {
            this.setState({
                queryFields: this.getQueryFields(),
                confirmDialog: false
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, {LoadTrackID: item.LoadTrackID}),
                    piggyQuery: this.getQuery(),
                    errorMessage: true,
                    successMessage: `Location ${item.Source === 'Manual' ? item.AddressName : item.DetectedAddress} removed`,
                    resource: this.getResource(),
                    piggyResource: this.getResource()
                }))
            })
        }, item)
    }

    /** UI Events
     ================================================================= */
    handleShowConfirmDialog = (submit, item) => {
        this.setState({
            confirmText: this.props.translate('message.confirm_delete_generic', [item.DetectedAddress ?? (item.AddressName ? item.AddressName : getFullAddressName(item))]),
            confirmModal: submit,
            confirmDialog: true
        })
    }

    handleToggleEditModal = (item = {}) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleHideConfirmDialog = () => {
        this.setState({
            confirmDialog: false
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        })
    }

    handleToggleLocationsModal = (item = {}) => {
        this.setState({
            selectedItem: item,
            locationsModalOpen: !this.state.locationsModalOpen
        })
    }

    handleToggleTrackingOptionsDialog = () => {
        this.setState({
            trackingOptionsDialog: !this.state.trackingOptionsDialog
        })
    }

    handleLocationMapView = (item) => {
        this.props.dispatch(showMapDialog(item))
    }

    updateViewType = () => {
        const isBoardView = this.state.dataView === 'board';

        this.setState({
            dataView: isBoardView ? "table" : "board"
        });
    }

    getQueryFields = () => {
        return {
            offset: new Field('offset', 0, ['']),
            limit: new Field('limit', 10, [''], false, 'select', {
                labelType: "float",
                hideLabel: true
            }, {menuPlacement: "top"})
        }
    }

    /** Helpers
     ================================================================= */
    getEditFields = (item) => {
        return {
            LoadTrackID: new Field('LoadTrackID', item?.LoadTrackID, [''], false, 'hidden'),
            IsShared: new Field('IsShared', item?.IsShared, [''], false, 'checkbox'),
        }
    }

    getFields = () => {
        return {
            LocationDate: new Field('LocationDate', '', [], false, 'datetimezone', {
                omitSort: true
            }),
            AddressName: new Field('AddressName', '', [], false, 'text', {
                omitSort: true
            }),
            CityName: new Field('CityName', '', [], false, 'text', {
                omitSort: true
            }),
            StateID: new Field('StateID', '', [], false, 'select', {
                omitSort: true
            }),
            PostalCode: new Field('PostalCode', '', [], false, 'select', {
                omitSort: true
            }),
            Source: new Field('Source', '', [], false, 'text', {
                omitSort: true
            }),
            IsSynced: new Field('IsSynced', '', [''], false, 'checkbox'),
            IsShared: new Field('IsShared', '', [''], false, 'checkbox'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <div
                            className={item.Source === 'Manual' && item.UpdatedByContactID && 'text-primary cursor-pointer font-semibold'}
                            onClick={() => item.Source === 'Manual' && item.UpdatedByContactID && this.props.dispatch(showModal('ViewContactCard', {ContactID: item.UpdatedByContactID}))}
                        >
                            <Tippy disabled={item.Source !== 'Manual' || !item.UpdatedByContactID}
                                   content={<span>Quick View</span>}>
                                <span>{item.Source === 'Manual' ? `${item.FirstName} ${item.LastName}` : 'System'}</span>
                            </Tippy>
                        </div>
                    )
                },
                omitSort: true
            }),
            Notes: new Field('Notes', '', [], false, 'text', {
                omitSort: true
            }),
        }
    }

    getQuery = () => {
        return Object.assign({
            id: this.getID(),
            sortBy: 'LoadTrackID',
            sort: 'DESC',
        }, FieldsManager.getFieldKeyValues(this.state.queryFields));
    }

    getResource = () => {
        return Resources.LoadTracks
    }

    getID = () => {
        return this.props.match.params.id
    }

    getPaginatedData = (array, offset, limit) => {
        if (limit < 0) return array.slice(offset);
        return array.slice(offset, offset + Number(limit));
    }

    render() {
        const {translate, resource} = this.props
        const tracks = getProp(resource.data, 'list', [])
        const loadDispatchData = getProp(resource, "data.load/info", [])

        const LastDetectedAddress = loadDispatchData.LastDetectedAddress;
        const LastDetectedAddressEtaTime = toFrontDateTimeFromUTC(loadDispatchData.LastDetectedAddressEtaTime);
        const LastDetectedEta = toFrontDateTimeFromUTC(loadDispatchData.LastDetectedEta);
        const hasETAData = !!LastDetectedAddress && !!LastDetectedAddressEtaTime && LastDetectedEta;

        const isLoading = getProp(resource, 'isLoading', false)
        const count = getProp(resource.data, `count`, 0)
        const hasMacroPointIntegration = getProp(resource.data, "integrations.MacroPoint.hasIntegration", 0)
        const hasKeepTruckinIntegration = getProp(resource.data, "integrations.KeepTruckin.hasIntegration", 0)
        const hasSamsaraIntegration = getProp(resource.data, "integrations.Samsara.hasIntegration", 0)
        const hasSpireonIntegration = getProp(resource.data, "integrations.Spireon.hasIntegration", 0)

        const trackingData = getProp(resource.data, 'load/tracking', {})
        const {DriverCellTxt, DriverNameTxt} = loadDispatchData

        return (
            <React.Fragment>
                <div className="grid grid-cols-12 mb-6">
                    <div className="col-span-4">
                        <h2 className="text-2xl">{translate('text.map')}</h2>
                    </div>
                    <div className="col-span-8 flex justify-end items-center">
                        <div className="inline-flex">
                            <ButtonsGroup
                                disabled={false}
                                roundedClass="rounded-full"
                                data={{
                                    board: translate('btn.map'),
                                    table: translate('btn.table')
                                }}
                                value={this.state.dataView}
                                onChange={this.updateViewType}
                            />
                        </div>

                        <Button
                            isLoading={isLoading}
                            hasPerm={checkPerm(Resources.LoadTracks, CREATE_PERM)}
                            className="btn btn-outline ml-2 h-9"
                            onClick={() => this.handleToggleLocationsModal()}
                        >
                            {translate('btn.create_manually')}
                        </Button>

                        <ButtonIntegration
                            addClass={"mx-2"}
                            id="integration-btn"
                            hasIntegration={hasMacroPointIntegration || hasKeepTruckinIntegration || hasSamsaraIntegration || hasSpireonIntegration}
                            hasPerm={checkPerm(Resources.LoadTracking, UPDATE_PERM)}
                            history={this.props.history}
                            onClick={() => this.handleToggleTrackingOptionsDialog()}
                            integrationName={"GoMotive/Samsara/MacroPoint/Spireon"}
                        >
                            {translate('btn.setup_load_tracking')}
                        </ButtonIntegration>
                    </div>

                    {!isLoading && (
                        <div className="col-span-full mt-3">
                            {hasETAData && (
                                <InfoBar>
                                    Last detected address {LastDetectedAddress} at {LastDetectedAddressEtaTime}.
                                    Estimated arrival at {LastDetectedEta}.
                                </InfoBar>
                            )}
                            {!!loadDispatchData?.IsTrackingEnabled && (
                                <InfoBar addClass={"mt-2"}>
                                    Tracking is enabled for this load. The system will attempt to retrieve the latest truck/trailer location from the GPS/ELD provider approximately every 15 minutes and update the table below.
                                    {loadDispatchData.LoadStatusID < LOAD_STATUS_DISPATCHED && (<span>Tracking will start once the load is dispatched</span>)}
                                    {loadDispatchData.LoadStatusID == LOAD_STATUS_DELIVERED &&  (<span>Load is marked as delivered, hence tracking is completed.</span>)}
                                </InfoBar>
                            )}
                            {!loadDispatchData?.IsAutomaticCustomerShareEnabled && (
                                <InfoBar addClass={"mt-2"}>
                                    Automatic customer sharing for each location is<strong>not enabled.</strong>Locations will not be visible to the customer on the share link unless "Shared with customer" is manually selected for each location.
                                </InfoBar>
                            )}
                            {!!loadDispatchData?.IsAutomaticCustomerShareEnabled && (
                                <InfoBar type="warning" addClass={"mt-2"}>
                                    Automatic customer sharing for each location is<strong>enabled.</strong>All location pings created will have "Shared with customer" turned on by default and<strong>will be visible on the customer share link.</strong>
                                </InfoBar>
                            )}
                            {!!loadDispatchData?.IsCustomerSyncEnabled && (
                                <InfoBar type="warning" addClass={"mt-2"}>
                                    Customer location synchronization is enabled for this load. The system will capture the latest location approximately every 15 minutes and<strong>send it to the customer</strong> through a third-party integration.
                                </InfoBar>
                            )}
                        </div>
                    )}
                </div>

                {this.state.dataView === 'table' && (
                    <React.Fragment>
                        {checkFeatureFlag('IsAccurTrackingOn') && (
                            <div className={"mb-4 flex flex-gap-4"}>
                                <Accur8Tracking
                                    trackingData={trackingData}
                                    isLoading={isLoading}
                                    translate={translate}
                                    loadID={this.getID()}
                                    piggyResource={this.getResource()}
                                    driverCell={DriverCellTxt}
                                    driverName={DriverNameTxt}
                                />
                                <div></div>
                            </div>
                        )}

                        <TableCard>
                            <ResourceTable
                                addClass={"rounded-t-card border-b-none"}
                                maxHeightClass={"max-h-[calc(100vh-29rem)]"}
                                commonTable={true}
                                tableKey={'LoadTrackID'}
                                data={this.getPaginatedData(tracks, this.state.queryFields.offset.value, this.state.queryFields.limit.value)}
                                count={count}

                                options={this.state.tableOptions}

                                fields={this.state.fields}
                                translate={this.props.translate}
                                isLoading={resource.isLoading}

                                actions={[
                                    {
                                        action: this.handleLocationMapView,
                                        icon: MapPinIcon,
                                        visible: (item) => !!item.Longitude && !!item.Latitude,
                                        title: translate('btn.quick_view')
                                    },
                                    {
                                        action: this.handleToggleEditModal,
                                        icon: PencilIcon,
                                        visible: (item) => checkPerm(Resources.LoadTracksPush, UPDATE_PERM),
                                        title: translate('btn.edit')
                                    },
                                    {
                                        action: this.archiveResource,
                                        icon: TrashIcon,
                                        visible: (item) => item.Source === 'Manual',
                                        title: translate('btn.delete')
                                    }
                                ]}
                            />

                            {tracks.length ?
                                <TableCardFooter
                                    show={true}
                                >
                                    <Pagination
                                        count={getProp(resource.data, 'count', [])}
                                        isLoading={false}
                                        handleQueryChange={(name, value) => this.handleQueryChange(name, value)}
                                        pageOffset={this.state.queryFields?.offset?.value ?? 0}
                                        queryFields={this.state.queryFields}
                                        translate={translate}
                                    />
                                </TableCardFooter>
                                :
                                <NoRecords
                                    show
                                    addClass={"py-8"}
                                    title={translate("text.no_matching_records")}
                                />
                            }
                        </TableCard>
                    </React.Fragment>
                )}

                {this.state.dataView === 'board' && isLoading && (
                    <div className="p-5 text-center">
                        <LoaderSmall/>
                    </div>
                )}

                {this.state.dataView === 'board' && !isLoading && (
                    <div className="-mt-8">
                        <LoadInfoTracks
                            data={getProp(resource, 'data', [])}
                            maxHeightClass={"max-h-[calc(100vh-24rem)]"}
                            mapRef={this.mapRef}
                            groupRef={this.groupRef}
                            onQueryChange={this.handleQueryChange}
                            queryFields={this.state.queryFields}
                            translate={translate}

                            actions={[
                                {
                                    action: this.archiveResource,
                                    icon: TrashIcon,
                                    visible: (item) => item.Source === 'Manual',
                                    title: translate('btn.delete')
                                }
                            ]}

                            // Tracking card props
                            trackingData={trackingData}
                            isLoading={isLoading}
                            loadID={this.getID()}
                            piggyResource={this.getResource()}
                            driverCell={DriverCellTxt}
                            driverName={DriverNameTxt}
                        />
                    </div>
                )}

                <Modal
                    show={this.state.locationsModalOpen}
                    onClose={this.handleToggleLocationsModal}
                    widthClass={" "}
                >
                    <CreateNewLocationDialog
                        {...this.props}
                        onClose={this.handleToggleLocationsModal}
                        translate={translate}
                        resourcePath={this.getResource()}
                        resourceID={this.getID()}
                        resourceLocationID={'LoadTrackID'}
                        getQuery={this.getQuery}
                        item={this.state.selectedItem}
                    />
                </Modal>

                <ModalConfirm
                    show={this.state.confirmDialog}
                    text={this.state.confirmText}
                    title={this.state.confirmTitle}
                    onConfirm={this.state.confirmModal}
                    onClose={this.handleHideConfirmDialog}
                    translate={translate}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                />

                <ModalSaveResource
                    title={translate("text.setup_load_tracking")}
                    gridColsClass={"grid-cols-12"}
                    widthClass="max-w-xl"
                    show={this.state.trackingOptionsDialog}
                    translate={translate}
                    fields={{
                        IsTrackingEnabled: new Field('IsTrackingEnabled', loadDispatchData?.IsTrackingEnabled, [''], !hasKeepTruckinIntegration && !hasSamsaraIntegration, 'checkbox', {
                            addContainerClass: 'col-span-full',
                            note: translate("note.IsTrackingEnabled")
                        }),
                        IsAutomaticCustomerShareEnabled: new Field('IsAutomaticCustomerShareEnabled', loadDispatchData?.IsAutomaticCustomerShareEnabled, [''], false, 'checkbox', {
                            addContainerClass: 'col-span-full',
                            note: translate("note.IsAutomaticCustomerShareEnabled")
                        }),
                        IsCustomerSyncEnabled: new Field('IsCustomerSyncEnabled', loadDispatchData?.IsCustomerSyncEnabled, [''], !hasMacroPointIntegration, 'checkbox', {
                            addContainerClass: 'col-span-full',
                            note: translate("note.IsCustomerSyncEnabled")
                        }),
                    }}
                    onClose={this.handleToggleTrackingOptionsDialog}
                    onSubmit={(params) => {
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: {
                                LoadID: loadDispatchData.LoadID,
                                ...params
                            },
                            query: this.getQuery(),
                            resource: Resources.LoadTracking,
                            piggyResource: this.getResource(),
                            errorMessage: true,
                            successMessage: `Load tracking configured.`,
                        }))
                        this.handleToggleTrackingOptionsDialog()
                    }}
                    resourceName={this.getResource()}
                    {...this.props}
                />


                <ModalSaveResource
                    title={'Edit location ping'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.LoadTracksPush,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: 'Location ping updated.'
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                />
            </React.Fragment>
        )
    }
}

export default TracksTab
