import React, { useEffect, useState } from "react";
import ContactGroupCard from "./contact-group-card";
import { useDispatch, useSelector } from "react-redux";
import {checkPerm, getProp} from "../../../util/util-helpers";
import ModalDefault from "../modal-default";
import Resources from "../../../../data/services/resources";
import {UPDATE_PERM} from "../../../../util/util-constants";
import CreateUpdateContactGroupsDialog from '../createContactsDialog'

export default function ContactGroupDialog({translate, onClose, data, show}) {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);

    const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
    const [item, setItem] = useState({});

    useEffect(() => {
        if (!!resource && !!show) {
            const selectedItem = getProp(resource, 'data.list', []).find(it => data?.ContactGroupID === it.ContactGroupID);

            if (selectedItem) {
                setItem(selectedItem);
            }
        }
    }, [resource])

    useEffect(() => {
        setItem(data);
    }, [data])

    return (
        <React.Fragment>
            <ModalDefault
                show={show}
                title={"Details for " + data?.ContactGroupName}
                widthClass={"max-w-3xl w-screen"}
                buttonLabel={checkPerm(Resources.ContactGroups, UPDATE_PERM) && translate("btn.edit")}
                onButtonClick={() => setIsUpdateDialogOpen(true)}
                onClose={onClose}
                closeButtonLabel={translate("btn.close")}
                translate={translate}
            >
                <div className="relative h-dialog-body">
                    <ContactGroupCard
                        data={item}
                        dispatch={dispatch}
                        translate={translate}
                    />
                </div>
            </ModalDefault>

            <CreateUpdateContactGroupsDialog
                isOpen={isUpdateDialogOpen}
                selectedItem={item}
                translate={translate}
                query={{}}
                close={() => setIsUpdateDialogOpen(false)}
            />
        </React.Fragment>
    )
}
