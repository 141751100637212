import React from 'react'
import { Link } from 'react-router-dom'

export default function CellLink({to, children, className, openInNewTab = false, disabled}) {
    if (disabled) {
        return <div className="inline-flex font-btn font-medium">{children}</div>;
    }

    return <span 
        onClick={(e) => {
            e.stopPropagation()
    }}>
        <Link
            to={to}
            target={openInNewTab ? "_blank" : ''}
            className={className ?? "leading-5 inline-flex py-0.5 -mx-2 px-2 rounded-btn whitespace-nowrap hover:text-primary text-primary-shade font-btn font-medium hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"}
        >
            {children}
        </Link>
    </span>
}
