import Button from "../../../../common/components/button";
import {CloudArrowDownIcon} from "@heroicons/react/20/solid";
import {TrashIcon} from "@heroicons/react/24/outline";
import React from "react";
import {classNames} from "../../../../common/util/util-helpers";

export default function ProfileRow({profileKey, profiles, onApplyClick, profileComment, onDeleteClick}) {
    const comment = profileComment ?? (profiles?.[profileKey] ?? {})?.metadata?.Comment;

    return (
        <div
            className="pr-4 col-span-full flex justify-between items-center rounded-xl bg-inverse shadow border border-tm-gray-300"
        >
            <div className="font-medium text-tm-gray-900 select-none py-3 px-4">
                {profileKey}

                {comment && (
                    <div className="text-tm-gray-700">
                        {comment}
                    </div>
                )}
            </div>

            <div>
                <Button
                    onClick={() => onApplyClick(profileKey)}
                    className={
                    classNames(
                        "p-2 rounded-btn text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus:ring-2 ring-primary-tint",
                        !onDeleteClick ? "mr-9" : undefined
                    )}
                >
                    <CloudArrowDownIcon className="w-5 h-5 text-primary"/>
                </Button>

                {!!onDeleteClick && (
                    <Button
                        onClick={() => onDeleteClick(profileKey)}
                        className="p-2 rounded-btn text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus:ring-2 ring-primary-tint"
                    >
                        <TrashIcon className="w-5 h-5 text-tm-gray-700"/>
                    </Button>
                )}
            </div>
        </div>
    )
}