
import React from "react";
import {classNames} from "../../../util/util-helpers";

export default function ButtonsGroup({name, data, value, disabled, addClass, addButtonClass, textClass, onChange, roundedClass = "rounded-md", isLoading}) {
    const buttonData = data;
    const buttonKeys = Object.keys(buttonData);

    return (
        <div
            className={
                classNames(
                    addClass,
                    !disabled ? "hover:bg-tm-gray-200" : "",
                    disabled ? "opacity-60" : "",
                    roundedClass,
                    "group p-0.5 flex bg-tm-gray-300",
                    isLoading ? "relative" : ""
                )
            }
        >
            {
                buttonKeys.map((buttonValue, i) => {
                    const isActive = value === buttonValue;

                    return (
                        <button
                            key={buttonData[buttonValue] + "-" + i}
                            disabled={disabled}
                            className={classNames(
                                "grow justify-center",
                                addButtonClass,
                                isActive
                                    ? "p-1.5 lg:px-3 flex items-center shadow-sm ring-1 ring-black ring-opacity-5 focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100 focus-visible:ring-opacity-50"
                                    : "p-1.5 lg:px-3 flex items-center text-tm-gray-700 focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100",
                                roundedClass,
                                isActive && !disabled ? "bg-inverse text-primary" : "",
                                isActive && disabled ? "bg-tm-gray-100 text-tm-gray-700" : "",
                                textClass ? textClass : "text-sm font-medium capitalize",
                                disabled ? "cursor-default" : "",
                                isLoading ? "invisible" : ""
                            )}
                            onClick={() => disabled ? null : onChange(name, buttonValue)}
                        >
                            {buttonData[buttonValue]}
                        </button>
                    )
                })
            }
        </div>
    )
}