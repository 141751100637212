import React, { useEffect, useState } from "react";
import {
    createIntegrationResource,
    deleteIntegrationResource,
    getIntegrationResource,
    resetIntegrationResource,
    updateIntegrationResource
} from "../../../../data/actions/integrationResource";
import LocalStorage from "../../../util/localStorage";
import { Field, FieldsManager } from "../../../../data/services/fields";
import InfoParagraph from "../../info-paragraph";
import {getProp} from "../../../util/util-helpers";
import {fieldsToHtml} from "../../../util/util-fields";
import ModalDefault from "../modal-default";
import {toFrontDateTimeFromUNIX, toFrontDateTimeFromUTC} from "../../../util/util-dates";
import ModalConfirm from "../modal-confirm";
import {LoaderSmall} from "../../loader";

export default function EfsIntegrationDialog(props) {
    const {translate, integrationResource, dispatch, resourceName, id} = props;

    /** Helpers
     ================================================================= */
    const integrationData = (getProp(integrationResource, "data", {}));
    const header = (getProp(integrationResource, "data.header", {}));

    const metadata = {
        Status: {
            ACTIVE: "ACTIVE",
            INACTIVE: "INACTIVE",
            HOLD: "HOLD",
            DELETE: "DELETE",
        }
    }

    const getFields = (item = {}) => {
        return Object.assign({}, {
                CardNumber: new Field("CardNumber", item.cardNumber, ['empty'], false, 'text'),
            },
            (integrationData.CardStatus) ? {
                Status: new Field("Status", item?.header?.status, ['empty'], false, 'select', {
                    omitSort: true
                }, {
                    omitSort: true
                })
            } : {}
        )
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [canSubmit, setCanSubmit] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setFields(getFields(integrationData))
    }, [integrationResource])

    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getIntegrationResource({
            user: LocalStorage.get("user"),
            resource: resourceName,
            query: {id: id}
        }))
    }

    const submit = () => {
        setFields(FieldsManager.validateFields(fields))

        if (handleCheckFieldsForError()) {
            const params = Object.assign({},
                FieldsManager.getFieldKeyValues(fields),
                {DriverID: id}
            )

            if (integrationData.CardStatus) {
                dispatch(updateIntegrationResource({
                    user: LocalStorage.get("user"),
                    params: params,
                    query: {id: id},
                    errorMessage: true, successMessage: `Resource updated`,
                    resource: resourceName,
                    piggyResource: resourceName,
                }))
            } else {
                dispatch(createIntegrationResource({
                    user: LocalStorage.get("user"),
                    params: params,
                    query: {id: id},
                    errorMessage: true, successMessage: `Resource created`,
                    resource: resourceName,
                    piggyResource: resourceName,
                }))
            }
        }
    }

    const removeCard = () => {
        dispatch(deleteIntegrationResource({
            user: LocalStorage.get('user'),
            query: {DriverID: id},
            piggyQuery: {id: id},
            errorMessage: true, successMessage: `Card deleted`,
            resource: resourceName,
            piggyResource: resourceName,
        }))
    }

    const handleRemoveCard = () => {
        setConfirmModalOpen(true)
    }

    const handleHideConfirmDialog = () => {
        setConfirmModalOpen(false)
    }

    /** UI Events
     ================================================================= */
    const handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(fields, name, value)
        setFields(newFields)
        setCanSubmit(true)
    }

    const handleCheckFieldsForError = () => {
        let result = true
        Object.keys(fields).forEach(it => {
            if (!fields[it].value) result = false
        })
        return result
    }

    const hideResourceDialog = () => {
        dispatch(resetIntegrationResource())
        props.hideResourceDialog()
    }

    const fieldsToHTML = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, metadata)

    return (
        <ModalDefault
            title={translate("modal_heading.efs_integration_details")}
            close={hideResourceDialog}

            onClose={hideResourceDialog}
            closeButtonLabel="Close"

            primaryButtonLabel={"Save"}
            primaryButtonDisabled={!canSubmit}
            onPrimaryButtonClick={submit}

            tertiaryButtonLabel={!!integrationData.CardStatus && "RemoveCard"}
            tertiaryButtonDisabled={!integrationData.CardStatus}
            onTertiaryButtonClick={handleRemoveCard}

            translate={translate}
            show={props.show}
            large
        >
            <div className={"relative"}>
                {integrationResource.isLoading && (
                    <div className="text-center absolute position-absolute-center">
                        <LoaderSmall/>
                    </div>
                )}

                {integrationResource.errorMessage && (
                    <div className="row mb-4">
                        <div className="col">
                            <InfoParagraph
                                type={"danger"}
                                message={translate(integrationResource.errorMessage)}
                            />
                        </div>
                    </div>
                )}

                {!integrationResource.isLoading && integrationData && !integrationResource.errorMessage && (
                    <React.Fragment>
                        <div className="row">
                            {fieldsToHTML}
                        </div>

                        {!!integrationData.CardStatus && Object.keys(header).length > 0 && (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <h3>{translate(`text.heading`)}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.lastTransaction`)}</p>
                                        <p className="mb-3 font-weight-bold">{toFrontDateTimeFromUNIX(header.lastTransaction)}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.lastUsedDate`)}</p>
                                        <p className="mb-3 font-weight-bold">{toFrontDateTimeFromUTC(header.lastUsedDate)}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.limitSource`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.limitSource}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollAch`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollAch}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollAtm`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollAtm}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollChk`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollChk}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollDebit`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollDebit}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollStatus`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollStatus}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollUse`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollUse}</p>
                                    </div>
                                    <div className='col'>
                                        <p className="text-tm-gray-500 text-small mb-2">{translate(`text.payrollWire`)}</p>
                                        <p className="mb-3 font-weight-bold">{header.payrollWire}</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </React.Fragment>
                )}

                {confirmModalOpen && (
                    <ModalConfirm
                        text={"Are you sure you want to delete this card"}
                        cancel={handleHideConfirmDialog}
                        submit={removeCard}
                        ui={{confirmDialogHideAnimation: !confirmModalOpen}}
                        translate={translate}
                    />
                )}
            </div>
        </ModalDefault>
    )
}
