import {createSecondResource, deleteSecondResource, getSecondResource} from "../actions/secondResource";

export const getSecondDataSelect = ({dispatch, user}) => ({query, resource}) => {
    dispatch(getSecondResource({
        user: user,
        query: query,
        resource: resource
    }))
};

export const createDataSelect = ({dispatch, user}) => ({
                                                           params,
                                                           query,
                                                           resource,
                                                           piggyResource,
                                                           successMessage,
                                                           errorMessage = true
                                                       }) => {
    dispatch(createSecondResource({
        user: user,
        params: params,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        successMessage: successMessage,
        errorMessage: errorMessage
    }))
};

export const deleteDataSelect = ({dispatch, user}) => ({
                                                           query,
                                                           resource,
                                                           piggyResource,
                                                           successMessage,
                                                           errorMessage
                                                       }) => {
    dispatch(deleteSecondResource({
        user: user,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        successMessage: successMessage,
        errorMessage: errorMessage
    }))
}
