import {ReceiptPercentIcon} from "@heroicons/react/24/outline";
import {checkPerm, classNames} from "../../../../common/util/util-helpers";
import React, {useEffect, useState} from "react";
import InvoiceExpenseDetailsDialog from "../../../accounting/dialogs/invoice-expense-details-dialog";
import LocalStorage from "../../../../common/util/localStorage";
import Resources from "../../../../data/services/resources";
import {currentDate} from "../../../../common/util/util-dates";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {useDispatch, useSelector} from "react-redux";
import {
    createThirdResource,
    deleteThirdResource,
    getThirdResource,
    updateThirdResource
} from "../../../../data/actions/thirdResource";
import {READ_PERM} from "../../../../common/util/util-consts";

export default function LoadInvoicedButton({translate, isInvoiced, invoiceStatus, billType, LoadID}) {
    const dispatch = useDispatch();

    const canViewInvoice = isInvoiced && checkPerm(Resources.InvoicesInvoice, READ_PERM);
    const DynamicTag = canViewInvoice ? 'button' : 'div';

    const download = useSelector(state => state.download);
    const resource = useSelector(state => state.thirdResource);
    const secondResource = useSelector(state => state.dialogInfoResource);

    const [isInvoiceDialogVisible, setIsInvoiceDialogVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    function fetchData() {
        dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            query: {
                LoadID: LoadID
            },
            resource: Resources.InvoicesInvoice
        }))
    }

    useEffect(() => {
        if (resource && resource?.data?.InvoiceID) {
            setSelectedItem(resource.data)
        }
    }, [resource]);

    return (
        <>
            <DynamicTag
                onClick={() => canViewInvoice ? setIsInvoiceDialogVisible(true) : null}
                className={
                    classNames(
                        "flex flex-shrink-0 items-center space-x-2",
                        canViewInvoice ? "btn btn-outline border-green-600" : undefined,
                        isInvoiced ? 'text-green-600' : 'text-tm-gray-500'
                    )
                }
            >
                <ReceiptPercentIcon
                    className={classNames('w-5 h-5')}
                />

                <span
                    className={classNames('text-sm font-semibold')}
                >
                    {
                        isInvoiced
                            ? translate('text.invoiced')
                            : translate('text.not_invoiced')
                    }

                    {!!isInvoiced && (
                        (invoiceStatus ? ' (' + translate("text.invoice_status_" + invoiceStatus) + ') ' : '')
                    )}

                    {(billType === 'Invoice' ? '' : ' - ' + translate("text." + billType))}
                </span>
            </DynamicTag>

            <ModalDefault
                show={isInvoiceDialogVisible}
                widthClass={'max-w-full'}
                title={translate('text.InvoiceID')}
                closeButtonLabel={translate('btn.Close')}
                onClose={() => setIsInvoiceDialogVisible(false)}
            >
                <InvoiceExpenseDetailsDialog
                    resource={resource}
                    dialogResource={secondResource}
                    isLoading={resource.isLoading || download.isLoading}
                    translate={translate}
                    dispatch={dispatch}

                    selectedItem={selectedItem}

                    type="invoice"
                    canDeletePayment={false}
                    showLoadReferenceLinks

                    createCommentDispatchAction={createThirdResource}
                    deleteCommentDispatchAction={deleteThirdResource}

                    onDocumentDownload={(item) => {
                        const fileExt = item?.AttachPath.split('.').pop()

                        this.props.dispatch(download({
                            user: LocalStorage.get('user'),
                            resource: Resources.InvoicesDocuments,
                            query: Object.assign({
                                id: item.InvoiceID,
                                format: fileExt,
                                name: 'document_' + currentDate() + '.' + fileExt
                            })
                        }))
                    }}

                    onFetchData={() => {
                        fetchData()
                    }}

                    onFactoringToggle={(item) => {
                        dispatch(updateThirdResource({
                            user: LocalStorage.get('user'),
                            resource: Resources.InvoicesFactoring,
                            params: {
                                InvoiceID: item.InvoiceID
                            },
                            errorMessage: true,
                            successMessage:
                                item.IsFactoringActive ?
                                    translate('text.UnmarkedForFactoring') :
                                    translate('text.MarkedForFactoring')
                        }))
                    }}
                />
            </ModalDefault>
        </>
    )
}