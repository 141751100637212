export function getStackedResource(data) {
    return {
        type: 'GET_STACKED_RESOURCE',
        data: data
    };
}

export function doneGetStackedResource(data) {
    return {
        type: 'DONE_GET_STACKED_RESOURCE',
        data: data
    };
}

export function errorGetStackedResource(data) {
    return {
        type: 'ERROR_GET_STACKED_RESOURCE',
        data: data
    };
}