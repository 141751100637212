import React, {Component} from "react";
import moment from "moment-timezone";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import {FieldsManager} from "../../../../data/services/fields";
import {classNames, getLookup, returnSameKeyValueObject} from "../../../util/util-helpers";
import Resources from "../../../../data/services/resources";
import {toggleBodyScroll} from "../../../util/util-vanilla";
import {fieldsToHtml} from "../../../util/util-fields";
import Card from "../../card";
import ModalDefault from "../modal-default";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";


export default class BulkBatchEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            canSubmit: false
        };
    }

    componentDidMount() {
        toggleBodyScroll()
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        toggleBodyScroll()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.hideModal()
        }
    }

    submit = () => {
        if (Object.keys(this.state.fields).length > 0) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.props.onSubmit(this.state.fields)
                    this.setState({fields: {}})
                }
            })
        }
    }

    hideModal = () => {
        this.props.onClose()
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true})
    }

    toggleElementToFields = (field) => {
        let fields = this.state.fields
        if (fields[field.name]) {
            delete fields[field.name]
        } else {
            fields[field.name] = field
        }
        this.setState({fields: fields})
    }

    render() {
        const {translate} = this.props;
        const selectedItems = Object.keys(this.props.selectedItems).map((elem, i) => {
            return (
                <div>
                    <span className='font-weight-bolder'>{translate(`text.${this.props.itemKeyLabel}`)}: </span>
                    {this.props.selectedItems[elem][this.props.itemKeyLabel]}
                </div>
            )
        });

        const fieldsHeading = Object.keys(this.props.fields).map((elem, i) => {
            const lightBtn = 'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mb-1 mr-1 focus:ring focus:ring-gray-600'
            const greenBtn = 'bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-4 rounded mb-1 mr-1 focus:ring focus:ring-green-600'
            return (
                <button
                    onClick={() => this.toggleElementToFields(this.props.fields[elem])}
                    className={this.state.fields[elem] ? greenBtn : lightBtn}
                >
                    <span className='flex justify-between items-center'>
                        {translate(`field.${this.props.fields[elem]?.metadata?.label ?? elem}`)}
                        {this.state.fields[elem] ?
                            <MinusCircleIcon className="w-5 h-5 ml-2"/>
                            :
                            <PlusCircleIcon className="w-5 h-5 ml-2"/>
                        }
                    </span>
                </button>
            )
        });

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            OfficeID: {
                api: 'api/' + Resources.Offices,
                query: {},
                searchMap: (item) => ({
                    value: item.OfficeID,
                    label: item.OfficeName,
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            Year: returnSameKeyValueObject(1900, moment().year() + 1),
            CDLState: getLookup('State'),
            MailingStateID: getLookup('State'),
            DriverPosition: getLookup('DriverPositionType'),
        });

        return (
            <React.Fragment>
                <ModalDefault
                    show={this.props.show}
                    title={translate('modal_heading.BulkAction')}
                    widthClass={'max-w-4xl'}
                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.hideModal}
                    buttonDisabled={(!this.state.canSubmit || Object.keys(this.state.fields).length === 0)}
                    buttonLabel={translate("btn.save")}
                    onButtonClick={this.state.canSubmit && this.submit}
                >
                    <div>
                        <Card addClass={'m-5 overflow-auto max-h-[250px]'}>
                            <div className="grid-auto-fit-30">
                                {selectedItems}
                            </div>
                        </Card>
                        <Card addClass={'m-5'}>
                            <h5 className="mb-4">Fields</h5>
                            {fieldsHeading}
                        </Card>
                        <div
                            className={classNames("grid gap-4 p-6", "grid-cols-4", "grid gap-4")}>
                            {fieldsHtml}
                        </div>
                    </div>
                </ModalDefault>
            </React.Fragment>
        )
    }
}

