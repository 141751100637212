import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, resetResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    FIXED_ASSET_TYPES,
    PO_LINE_TYPE_ASSET,
    PURCHASE_ORDER_LINE_TYPES,
    READ_PERM
} from '../../../util/util-constants'
import {checkPerm, getDefaultUserOfficeValues, getDocumentTypesFor, getProp} from '../../../common/util/util-helpers'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import PlusCircleIcon from '@heroicons/react/24/outline/PlusCircleIcon'
import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon'
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import {LoaderSmall} from "../../../common/components/loader";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Page from "../../../common/components/layout/layout-components/page";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";
import Layout from "../../../common/components/layout";
import Card from "../../../common/components/card";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";

class PurchaseOrderSingleView extends Component {

    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'AccountingPurchaseOrder',
                resource: Resources.AccountingPurchaseOrderInfo,
                current: true,
                visible: true
            },
            {
                name: 'AccountingPurchaseOrderDocuments',
                resource: Resources.AccountingPurchaseOrderDocuments,
                current: false,
                visible: true
            },
        ].filter((it) => checkPerm(it.resource, READ_PERM))

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: tabs[0].resource,
            canSwapTabs: true,

            // Fields
            fields: this.getFields(),
            PurchaseOrderLines: this.getOrderLineFields(),
            fields_documents: [],
            purchaseNotesVisible: [],
            deliverDialogVisible: false,
            selectedItem: null
        }

    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        !this.isCreate() && this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }

        if ((prevProps.resource != this.props.resource) && this.getID()) {
            this.handleFormCancel()
        }

        if (prevState.selectedTab !== this.state.selectedTab && this.getID() && this.state.selectedTab === Resources.AccountingPurchaseOrderInfo) {
            this.fetchData()
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingPurchaseOrderInfo,
            query: {
                id: this.getID()
            }
        }))
    }

    onSubmit = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            PurchaseOrderLines: this.state.PurchaseOrderLines.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && this.state.PurchaseOrderLines.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)), true)) {
                const params = Object.assign({},
                    FieldsManager.getFieldKeyValues(this.state.fields),
                    {
                        PurchaseOrderLines: this.state.PurchaseOrderLines.map(it => FieldsManager.getFieldKeyValues(it)),
                    }
                )

                if (this.getID()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, params, {
                            id: this.getID(),
                        }),
                        query: {
                            id: this.getID()
                        },
                        errorMessage: true, successMessage: `Purchase Order updated`,
                        resource: Resources.AccountingPurchaseOrderInfo,
                        piggyResource: Resources.AccountingPurchaseOrderInfo,
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.AccountingPurchaseOrder,
                        showRedirectDialog: true,
                        errorMessage: true,
                        document: this.state.fields_documents,
                        documentResource: Resources.AccountingPurchaseOrderDocuments,
                    }))
                }
                this.setState({canSubmit: false, purchaseNotesVisible: []})
            } else {
                if (this.state.selectedTab !== Resources.AccountingPurchaseOrderInfo) this.handleTabChange(Resources.AccountingPurchaseOrderInfo)
            }
        })
    }

    /** UI events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!!this.getID()) {
            if (!!this.state.canSubmit) {
                this.setState({
                    confirmModal: true,
                    reserveTab: resource
                })
            } else {
                this.tabChange(resource)
            }
            return false
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleToggleRedirectDialog = () => {
        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.id,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,

            // Reset state
            fields: this.getFields(),
            PurchaseOrderLines: this.getOrderLineFields(),
            fields_documents: [],
            canSubmit: false
        })
    }

    handleAddNewPurchaseOrderLine = () => {
        this.setState({
            PurchaseOrderLines: this.state.PurchaseOrderLines.concat(this.getSingleOrderLineFields())
        })
    }

    handleRemovePurchaseOrderLine = (index) => {
        this.setState({
            PurchaseOrderLines: this.state.PurchaseOrderLines.filter((_, i) => i !== index),
            canSubmit: true
        })
    }

    handleToggleDeliver = (it = null) => {
        this.setState({deliverDialogVisible: !this.state.deliverDialogVisible, selectedItem: it})
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    canSwapTabsHandler = (canSwapTabs = false) => {
        this.setState({canSwapTabs: canSwapTabs, canSubmit: !canSwapTabs})
    }

    handleItemInputChange = (name, value, i) => {
        let PurchaseOrderLines = this.state.PurchaseOrderLines
        PurchaseOrderLines[i][name].value = value

        if (name === 'PurchaseOrderLineTypeID') {
            PurchaseOrderLines[i].FixedAssetTypeID.type = Number(value) === PO_LINE_TYPE_ASSET ? 'select' : 'hidden'
        }
        this.setState({PurchaseOrderLines, canSubmit: true})
    }

    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields, name, value)

        this.setState({
            canSubmit: true,
            fields: fields,
        })
    }

    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(getProp(this.props.resource, 'data.accounting/purchaseorder/info', {})),
            PurchaseOrderLines: this.getOrderLineFields(),
            canSubmit: false
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = (item = {}) => {

        let fieldTemplates = {
            VendorID: new Field('VendorID', '', [], false, 'select-search', {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), [], false, 'select-search', {
                isClearable: true
            }),
            PONumber: new Field('PONumber', '', ['empty']),
            Date: new Field('Date', '', [], false, 'date'),
            Reference: new Field('Reference', '', ['']),
            Notes: new Field('Notes', '', [''], false, 'textarea', {
                addContainerClass: 'col-span-full'
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getOrderLineFields = () => {
        if (!!this.getID()) {
            const data = getProp(this.props.resource, 'data.accounting/purchaseorder/info.PurchaseOrderline', [])
            return data.map(it => this.getSingleOrderLineFields(it))
        } else {
            return []
        }
    }

    getSingleOrderLineFields = (item = {}) => {
        const fieldTemplates = {
            PurchaseOrderLineTypeID: new Field('PurchaseOrderLineTypeID', '', [], false, 'select'),
            Name: new Field('Name', '', ['empty'], false, 'text', {addContainerClass: 'ml-3'}),
            Amount: new Field('Amount', '', ['float_not_require']),
            FixedAssetTypeID: new Field('FixedAssetTypeID', '', [''], false, (item?.PurchaseOrderLineTypeID == 3) ? 'select' : 'hidden', {label: 'type'}, {
                isClearable: true,
                values: FIXED_ASSET_TYPES,
                valueSort: true
            }),
            ETADate: new Field('ETADate', '', [''], false, 'date', {label: 'ETADate'}),
            DeliveryDate: new Field('DeliveryDate', '', [''], true, 'date', {label: 'DeliveryDate'}),
            IsCompleted: new Field('IsCompleted', '', [''], true, 'checkbox', {
                label: 'Delivered',
                addContainerClass: 'col-span-2'
            }),
            Notes: new Field('Notes', '', [], false, 'hidden', {
                addContainerClass: 'col-span-full mb-3'
            }),
            PurchaseOrderLineID: new Field('PurchaseOrderLineID', '', [], true, 'hidden'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getDeliverFields = (item = {}) => {
        return {
            DeliveryDate: new Field('DeliveryDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            Amount: new Field('Amount', '', ['empty'], false, 'float', {addContainerClass: 'col-span-6'}),
            Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            BookDate: new Field('BookDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            DueDate: new Field('DueDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
        }
    }
    /** Helpers
     ================================================================= */
    isCreate = () => {
        return !this.getID()
    }

    getID = () => {
        return this.props.match.params.id
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const isLoading = getProp(resource, 'isLoading', false)

        const metadata = {
            PurchaseOrderLineTypeID: PURCHASE_ORDER_LINE_TYPES,
            VendorID: {
                api: 'api/' + Resources.VendorsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.LegalName,
                    value: it.VendorID
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OfficeID,
                    label: item.OfficeName,
                })
            }
        }

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)
        const fieldsHtmlPurchaseOrder = this.state.PurchaseOrderLines.map((it, i) => {
            const fieldsItemHtml = fieldsToHtml(Object.values(Object.assign({}, it)), translate, (name, value) => this.handleItemInputChange(name, value, i), metadata)

            return (
                <div className={'border-tm-gray-200 border-t mb-5'}>
                    <div className="flex justify-between items-center pt-3">
                        <TabTitle
                            text={i + 1 + '.'}
                        />

                        {!this.isCreate() && !!it.PurchaseOrderLineID.value && !it.IsCompleted.value && (
                            <button
                                className="btn-primary ml-auto"
                                onClick={() => {
                                    this.handleToggleDeliver(it)
                                }}
                                title={translate('btn.deliver')}
                            >
                                <span>{translate('btn.deliver')}</span>
                            </button>
                        )
                        }

                        {!it.IsCompleted.value && (
                            <button
                                className="btn-table-action"
                                onClick={() => {
                                    this.handleRemovePurchaseOrderLine(i)
                                }}
                                title={translate('btn.delete')}
                            >
                                <TrashIcon className="h-4 w-4"/>
                            </button>
                        )}
                    </div>

                    <div className="grid grid-cols-6 gap-5 mt-4">
                        {fieldsItemHtml}

                        {!this.state.purchaseNotesVisible[i] && (
                            <button className="btn flex gap-2 text-xs  py-1 text-tm-gray-700 hover:text-primary mt-4"
                                    onClick={() => {
                                        let fields = this.state.PurchaseOrderLines
                                        fields[i].Notes.type = 'textarea'
                                        let visible = this.state.purchaseNotesVisible
                                        visible[i] = true
                                        this.setState({PurchaseOrderLines: fields, purchaseNotesVisible: visible})
                                    }}><PlusCircleIcon className="w-5 h-5 text-tm-gray-600"/>
                                {translate('btn.purchase_notes')}
                            </button>
                        )}
                    </div>
                    <div className={'flex justify-end'}>
                        {this.state.purchaseNotesVisible[i] && (
                            <button className="btn flex gap-2 text-xs  py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={() => {
                                        let fields = this.state.PurchaseOrderLines
                                        fields[i].Notes.type = 'hidden'
                                        let visible = this.state.purchaseNotesVisible
                                        visible[i] = false
                                        this.setState({PurchaseOrderLines: fields, purchaseNotesVisible: visible})
                                    }}>
                                <XCircleIcon className={'w-5 h-5'}/>
                                {translate('btn.hide_purchase_notes')}
                            </button>
                        )}
                    </div>
                </div>
            )
        })

        return (
            <Layout
                {...this.props}
                hasFooter={this.state.selectedTab === Resources.AccountingPurchaseOrderInfo}
                isDirty={(this.getID() && this.state.canSubmit)}
            >
                <Page>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-2 flex justify-between items-center">
                                    <div className="flex items-center justify-center">
                                        <div className="flex items-center justify-center">
                                            <GoBackButton
                                                canSubmit={!this.state.canSwapTabs}
                                                translate={this.props.translate}
                                                history={this.props.history}
                                                path={'/accounting-purchase-order'}
                                            />
                                            <h1 className={'mr-5 text-3xl ml-2'}>
                                                {translate('text.AccountingPurchaseOrder')}
                                                <span
                                                    className="text-tm-gray-500"> {this.getID() ? '' : ' - Create new'}
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="mb-5 hidden sm:block">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="tab-content">
                                    <div className="tab-pane show active">
                                        <div>
                                            {this.state.selectedTab === Resources.AccountingPurchaseOrderInfo && (
                                                <Card>
                                                    {!isLoading && (
                                                        <div>
                                                            <TabTitle
                                                                text={translate('text.PurchaseOrderInfo')}
                                                            />

                                                            <div className="grid grid-cols-3 gap-5 mt-5">
                                                                {fieldsHtml}
                                                            </div>
                                                            <div className={'mt-5'}>
                                                                {fieldsHtmlPurchaseOrder}

                                                                <button className="btn btn-outline mt-5"
                                                                        onClick={this.handleAddNewPurchaseOrderLine}>
                                                                    {translate('btn.add_new_purchase_order_line')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {!!isLoading && (
                                                        <div className={'flex justify-center'}>
                                                            <LoaderSmall/>
                                                        </div>
                                                    )}
                                                </Card>
                                            )}

                                            {this.state.selectedTab === Resources.AccountingPurchaseOrderDocuments && this.props.match.params.id && (
                                                <DocumentsUpdateTab
                                                    id={this.props.match.params.id}
                                                    dispatch={this.props.dispatch}
                                                    translate={this.props.translate}
                                                    resource={this.props.resource}
                                                    thirdResource={this.props.thirdResource}

                                                    dialogTitle={'Purchase Order Documents'}

                                                    sortBy={'Description'}
                                                    primaryKey={'PurchaseOrderDocumentID'}
                                                    documentID={'PurchaseOrderDocumentID'}

                                                    download={this.props.download}
                                                    resourceName={Resources.AccountingPurchaseOrderDocuments}
                                                    documentTypes={getDocumentTypesFor('IsAccountingDocument')}

                                                    searchFields={{
                                                        query: new Field('query', '', [], false, 'text', {
                                                            containerClass: 'col-md-4'
                                                        }),
                                                        DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                                            containerClass: 'col-md-4'
                                                        }, {
                                                            all: true,
                                                            values: getDocumentTypesFor('IsAccountingDocument')
                                                        }),
                                                        DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                                            containerClass: 'col-md-4'
                                                        }, {
                                                            all: true
                                                        }),
                                                    }}
                                                />
                                            )}

                                            {this.state.selectedTab === Resources.AccountingPurchaseOrderDocuments && !this.props.match.params.id && (
                                                <Card>
                                                    <DocumentsCreateTab
                                                        fields={this.state.fields_documents}
                                                        translate={this.props.translate}

                                                        dialogTitle={translate('text.purchase_order_documents')}

                                                        documentTypes={getDocumentTypesFor('IsAccountingDocument')}
                                                        handleUploadDocument={this.handleUploadDocument}
                                                        handleDeleteDocument={this.handleDeleteDocument}
                                                    />
                                                </Card>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <RedirectDialog
                        show={this.state.isRedirectDialogVisible}
                        title={translate('card_header.purchase_order_created')}
                        onClose={() => {
                            this.props.dispatch(resetResource())
                            this.setState({
                                createdResourceID: null,
                                isRedirectDialogVisible: false
                            })
                        }}

                        createdLabel={translate('btn.purchase-order')}
                        createdLink={`/purchase-order/${this.state.createdResourceID}`}

                        newLabel={translate('btn.create_new')}

                        listLabel={translate('btn.list')}
                        listLink="/accounting-purchase-order"

                        dashboardLabel={translate('btn.dashboard')}

                        {...this.props}
                    >
                        <p>{translate('message.purchase_order_created')}</p>
                    </RedirectDialog>

                    {(!this.getID() || (!!this.getID() && this.state.selectedTab === Resources.AccountingPurchaseOrderInfo)) && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleFormCancel}
                            actionSubmit={this.onSubmit}
                        />
                    )}
                </Page>

                <ModalConfirm
                    type="default"
                    title={translate('dialog_heading.unsaved_changes_on_tab')}
                    show={!!this.state.confirmModal && this.state.selectedTab === Resources.AccountingPurchaseOrderInfo}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate('btn.proceed')}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        canSubmit: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate('text.unsaved_changes_on_tab')}</p>
                    <p>{translate('text.unsaved_changes_on_tab_line_2')}</p>
                </ModalConfirm>

                <ModalSaveResource
                    title={translate('text.deliver_and_create_bill')}
                    gridColsClass={'grid-cols-12'}
                    widthClass="max-w-xl"
                    show={!!this.state.deliverDialogVisible}
                    translate={translate}
                    fields={this.getDeliverFields()}
                    onClose={this.handleToggleDeliver}
                    onSubmit={(params) => {
                        params.PurchaseOrderLineID = this.state.selectedItem.PurchaseOrderLineID.value
                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: Resources.AccountingExpenseOrder,
                            piggyResource: Resources.AccountingPurchaseOrderInfo,
                            piggyQuery: {
                                id: this.getID()
                            },
                            errorMessage: true,
                            successMessage: `Purchase order has been updated`,
                        }))
                        this.handleToggleDeliver()
                    }}

                />
            </Layout>
        )
    }
}

export default connect(state => state)(PurchaseOrderSingleView)
