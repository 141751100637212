export function getContactResource(data) {
    return {
        type: 'GET_CONTACT_RESOURCE',
        data: data
    };
}

export function doneGetContactResource(data) {
    return {
        type: 'DONE_GET_CONTACT_RESOURCE',
        data: data
    };
}

export function errorGetContactResource(data) {
    return {
        type: 'ERROR_GET_CONTACT_RESOURCE',
        data: data
    };
}

export function createContactResource(data) {
    return {
        type: 'CREATE_CONTACT_RESOURCE',
        data: data
    };
}

export function doneCreateContactResource(data) {
    return {
        type: 'DONE_CREATE_CONTACT_RESOURCE',
        data: data
    };
}

export function errorCreateContactResource(data) {
    return {
        type: 'ERROR_CREATE_CONTACT_RESOURCE',
        data: data
    };
}

export function updateContactResource(data) {
    return {
        type: 'UPDATE_CONTACT_RESOURCE',
        data: data
    };
}

export function doneUpdateContactResource(data) {
    return {
        type: 'DONE_UPDATE_CONTACT_RESOURCE',
        data: data
    };
}

export function errorUpdateContactResource(data) {
    return {
        type: 'ERROR_UPDATE_CONTACT_RESOURCE',
        data: data
    };
}

export function deleteContactResource(data) {
    return {
        type: 'DELETE_CONTACT_RESOURCE',
        data: data
    };
}

export function doneDeleteContactResource(data) {
    return {
        type: 'DONE_DELETE_CONTACT_RESOURCE',
        data: data
    };
}

export function errorDeleteContactResource(data) {
    return {
        type: 'ERROR_DELETE_CONTACT_RESOURCE',
        data: data
    };
}

export function resetContactResource() {
    return {
        type: 'RESET_CONTACT_RESOURCE',
        data: []
    }
}

export function resetContactCreateUpdateResource() {
    return {
        type: 'RESET_CREATE_UPDATE_CONTACT_RESOURCE',
        data: []
    }
}

export function clearContactResource() {
    return {
        type: 'CLEAR_CONTACT_RESOURCE',
    };
}