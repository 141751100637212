import {classNames} from "../../../../../common/util/util-helpers";
import React, {useRef} from "react";

export default function CarrierReportSection({data, title, Icon, sectionContentEnd}) {
    const colSpanRef = useRef(1);
    return (
        <div className={classNames("bg-inverse rounded-card px-5 pb-5 shadow-card")}>
            <div
                className="flex whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-bold sm:pl-0 border-b border-tm-gray-200 mb-5"
            >
                {!!Icon && (
                    <Icon className="w-5 h-5 mr-2 text-tm-gray-600"/>
                )}

                {title}
            </div>

            <div className="grid grid-cols-12 gap-5">
                {data.map(table => {
                    return <div className={table.tableWrapperClass}>
                        <div className="border border-tm-gray-300 rounded-card overflow-hidden">
                            <table
                                className="min-w-full divide-y divide-tm-gray-300 text-tm-gray-900">
                                <thead className="divide-y divide-tm-gray-300">
                                <tr className="divide-x divide-tm-gray-200">
                                    {table.columns.reduce((memo, header, i) => {
                                        const colSpan = colSpanRef.current;
                                        if (header === "") {
                                            colSpanRef.current++;
                                        } else {
                                            memo.push(
                                                <th
                                                    colSpan={colSpan}
                                                    className={
                                                        classNames(
                                                            "whitespace-nowrap py-1 px-2 text-sm font-semibold text-tm-gray-600",
                                                            !i ? "text-right" : "text-left"
                                                        )
                                                    }
                                                >
                                                    {header}
                                                </th>
                                            );
                                            colSpanRef.current = 1;
                                        }

                                        return memo;
                                    }, [])}
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-tm-gray-300 bg-inverse">
                                {table.data.map(row => {
                                    return (
                                        <tr className="divide-x divide-tm-gray-200">
                                            {row.map((cell, i) => (
                                                <td
                                                    className={
                                                        classNames(
                                                            !i ? "bg-tm-gray-100 text-right text-tm-gray-600 px-2 py-1" : "px-2 py-1 font-bold text-left"
                                                        )
                                                    }
                                                >
                                                    {cell}
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                })}

                {sectionContentEnd}
            </div>
        </div>
    )
}