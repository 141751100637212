import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResource, updateResource } from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import { fieldsToHtml, fillFieldsFromData } from "../../../../common/util/util-fields";
import { Field, FieldsManager } from "../../../../data/services/fields";
import { cloneDeep } from "../../../../common/util/util-vanilla";
import CardSubTitle from "../../../../common/components/card/card-sub-title";
import {LoaderLarge} from "../../../../common/components/loader";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";

export default function TasksSettingsGeneralTab({resourcePath, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    const onSubmit = () => {
        const validatedFields = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: {
                    TaskTimeClose: validatedFields.TaskTimeClose.value
                },
                resource: getResourceName(),
                piggyResource: getResourceName(),
                errorMessage: true,
                successMessage: translate("message.settings_updated")
            }))
        } else {
            setFields(validatedFields)
        }
    }

    const getFields = (item) => {
        item.AutoCloseTasksEnabled = !!item.TaskTimeClose

        const fieldTemplates = {
            AutoCloseTasksEnabled: new Field('AutoCloseTasksEnabled', !!item.TaskTimeClose, [''], false, 'checkbox'),
            TaskTimeClose: new Field('TaskTimeClose', '', item.TaskTimeClose ? ['bigger_than_1'] : [''], !item.TaskTimeClose, 'text', )
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(cloneDeep(fields), name, value)

        if (name === 'AutoCloseTasksEnabled') {
            if (value) {
                newFields.TaskTimeClose.disabled = false
                newFields.TaskTimeClose.validate = ['bigger_than_1']
                newFields.TaskTimeClose.value = 7 // Default placeholder value, change as needed
            } else {
                newFields.TaskTimeClose.disabled = true
                newFields.TaskTimeClose.validate = ['']
                newFields.TaskTimeClose.value = 0
            }
        }

        setFields(newFields);
        setIsStateDirty(true)
    }

    /** State
     ================================================================= */
    const [editorKey, setEditorKey] = useState(0);
    const [fields, setFields] = useState({});
    const [isStateDirty, setIsStateDirty] = useState(false);

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return resourcePath
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        setFields(getFields({}, translate, handleInputChange, editorKey));
        fetchData();
    }, [])

    useEffect(() => {
        if (resource?.data && !resource?.isLoading) {
            setEditorKey(editorKey + 1);
            setFields(getFields(cloneDeep(resource.data), editorKey));
        }
    }, [resource])

    return (
        <React.Fragment>
            <div className="grid gap-4 py-8 max-w-3xl mx-auto relative">
                <CardSubTitle
                    text={translate('page.heading.general')}
                />

                {fieldsToHtml(Object.values(fields), translate, handleInputChange)}

                {!!isLoading && (
                    <LoaderLarge />
                )}
            </div>

            <PageFooter
                translate={translate}
                canSubmit={isStateDirty}
                actionCancel={() => setFields(getFields(cloneDeep(resource.data)))}
                actionSubmit={onSubmit}
            />
        </React.Fragment>
    )
}
