import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../util/util-constants'
import {getDefaultTableOptions, getLookup, getProp, saveTableColumns} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {getThirdResource} from '../../../data/actions/thirdResource'
import {EyeIcon} from '@heroicons/react/24/outline'
import {numberWithCommas} from '../../../util/util-formaters'
import moment from 'moment-timezone'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import {cloneDeep} from "../../../common/util/util-vanilla";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecords from "../../../common/components/no-records-found/no-records";
import InfoParagraph from "../../../common/components/info-paragraph";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import ResourceTable from "../../../common/components/resource-table";
import {fieldsToHtml} from "../../../common/util/util-fields";

export default class CustomerStatement extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: true
            },
            columns: {}
        }

        this.pagePath = "customer-statement";

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            id: this.getIdField(),
            selectedItem: null,
            createModalOpen: false,
            confirmModalOpen: false
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_customer_statement_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.id
        fields = FieldsManager.updateField(fields, name, value)

        this.setState({
            id: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleReceiptDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            showReceiptModal: !this.state.showReceiptModal,
            receiptModalFetchKey: 'InvoiceID',
            receiptModalFetchResource: Resources.InvoicesInvoice
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.AccountingReportsCustomerStatement
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            OrganizationID: this.state.id?.id?.value?.value,
        }
    }

    getIdField = () => {
        return {
            id: new Field('id', '', [''], false, 'select-search', {
                addContainerClass: 'w-96 ml-2 z-50',
                label: 'Organization'
            }, {
                placeholder: 'Select organization'
            })
        }
    }

    getFields = () => {
        return {
            Date: new Field('Date', '', [''], false, 'date', {
                omitSort: true
            }),
            Type: new Field('Type', '', [''], false, 'select-search', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            AmountBilled: new Field('AmountBilled', '', [''], false, 'money', {
                omitSort: true
            }),
            AmountPaid: new Field('AmountPaid', '', [''], false, 'money', {
                omitSort: true
            }),
            Balance: new Field('Balance', '', [''], false, 'money', {
                omitSort: true
            }),
        }
    }

    getQueryFilterFields = () => {
        return {
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40',
            }, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40'
            }, {
                isClearable: true
            })
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const data = !!this.state.id?.id?.value?.value ? getProp(this.props, 'resource.data.list', []) : []
        const count = !!this.state.id?.id?.value?.value ? getProp(this.props, 'resource.data.count', 0) : 0
        const EndAmount = getProp(this.props, 'resource.data.EndAmount', 0)
        const StartAmount = getProp(this.props, 'resource.data.StartAmount', 0)

        const isLoading = getProp(this.props, 'resource.isLoading', false)


        const idField = fieldsToHtml(Object.values(Object.assign({}, this.state.id)), translate, this.handleInputChange, {
            id: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName + " - " + (item.AddressName ?? "")
                        + ", " + (item.CityName ?? "")
                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                        + (item.PostalCode ?? ""),
                    value: item.OrganizationID
                })
            }
        })

        return (
            <React.Fragment>
                <div className="flex mb-4">

                    <div className={'flex justify-between items-center'}>
                        {idField}
                    </div>
                    {/*<div className={'flex justify-between items-center mt-5 ml-2'}>*/}
                    {/*    <PopOver*/}
                    {/*        className="relative"*/}
                    {/*        btnClass="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-3"*/}
                    {/*        BtnIcon={CalendarIcon}*/}
                    {/*        chevronIcon={true}*/}
                    {/*        btnIconClass={classNames("h-4 w-4 mr-1")}*/}
                    {/*        chevronIconClass={*/}
                    {/*            classNames(*/}
                    {/*                "ml-2 h-4 w-4 text-tm-gray-700 group-hover:text-opacity-80 transition ease-in-out duration-150"*/}
                    {/*            )}*/}
                    {/*        widthClass="max-w-2xl"*/}
                    {/*        positionClass="absolute translate-x-0 left-0"*/}
                    {/*    >*/}
                    {/*        <div className="bg-popup border border-tm-gray-300 rounded-lg">*/}
                    {/*            <PayDateFilter*/}
                    {/*                queryFields={this.state.queryFilterFields}*/}
                    {/*                translate={translate}*/}
                    {/*                updateQueryFields={(queryFieldsUpdate) => {*/}
                    {/*                    this.setState({*/}
                    {/*                        queryFilterFields: queryFieldsUpdate,*/}
                    {/*                        offset: 0*/}
                    {/*                    }, () => this.fetchData())*/}
                    {/*                    this.saveFilters(queryFieldsUpdate)*/}
                    {/*                }}*/}
                    {/*                onQueryChange={this.handleFilterInputChange}*/}
                    {/*                hasDateType={false}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </PopOver>*/}
                    {/*</div>*/}


                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <div className={'m-2'}>
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleFilterClear}
                        translate={translate}
                    />
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        largeGrid
                        forceAll
                        forceDialog
                        onRefreshTable={this.fetchData}
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                gridCols={'grid-cols-1'}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                            />
                        }
                    />

                    {this.state.id.id.value && (
                        <div className="p-5">
                            <InfoParagraph className={'flex'} type="note" message={(
                                <div className={'flex justify-between'}>
                                    <div>
                                        Starting balance: <span>{numberWithCommas(StartAmount ?? 0)}</span>
                                    </div>
                                    <div className={'mr-4'}>
                                        End balance: <span>{numberWithCommas(EndAmount ?? 0)}</span>
                                    </div>
                                </div>
                            )}/>
                        </div>
                    )}

                    {(this.state.queryFilterFields.StartDate.value || this.state.queryFilterFields.EndDate.value) && (
                        <ResourceTable
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            data={data}
                            commonTable={true}
                            fields={this.getFields()}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={this.handleToggleReceiptDialog}
                            actions={[
                                {
                                    title: () => translate('btn.view'),
                                    visible: (it) => !!it.InvoiceID,
                                    action: this.handleToggleReceiptDialog,
                                    icon: () => EyeIcon
                                }
                            ]}
                        />
                    )}

                    {/*Table footer*/}
                    {(this.state.queryFilterFields.StartDate.value || this.state.queryFilterFields.EndDate.value) && this.state.id?.id?.value?.value && (
                        <NoRecords
                            show={(data.length === 0) && !isLoading}
                            title={translate('text.no_records')}
                            addClass={'pt-16 pb-10'}
                        />
                    )}

                    {!(this.state.queryFilterFields.StartDate.value || this.state.queryFilterFields.EndDate.value) && (
                        <NoRecords
                            show={!isLoading}
                            title={translate('text.atLeastOneDate')}
                            addClass={'pt-16 pb-10'}
                        />
                    )}

                    {!(this.state.id?.id?.value?.value) && (
                        <NoRecords
                            show={!isLoading}
                            title={translate("text.pleaseSelectOrganization")}
                            addClass={'pt-16 pb-10'}
                        />
                    )}
                </TableCard>

                <ModalDefault
                    show={this.state.showReceiptModal}
                    widthClass={'max-w-5xl w-screen'}
                    title={this.state.receiptModalFetchKey ? translate('text.' + this.state.receiptModalFetchKey) : ''}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleReceiptDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.receiptModalFetchKey]: this.state.selectedItem.ReferenceID ?? this.state.selectedItem[this.state.receiptModalFetchKey]
                                    },
                                    resource: this.state.receiptModalFetchResource
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            </React.Fragment>
        )
    }
}
