import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import { pushNotification } from "../actions/ui";
import {
    doneDeleteDialogInfoResource,
    doneGetDialogInfoResource, errorDeleteDialogInfoResource,
    errorGetDialogInfoResource
} from "../actions/dialogInfoResource";

export function* getDialogInfoResourceCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetDialogInfoResource(result.data));

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorGetDialogInfoResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchGetDialogInfoResource() {
    yield takeLatest('GET_DIALOG_INFO_RESOURCE', getDialogInfoResourceCall);
}

export function* deleteDialogInfoResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDialogInfoResource(resPiggy.data));
                yield put(doneDeleteDialogInfoResource(result.data));
            }
        } else {
            yield put(doneDeleteDialogInfoResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteDialogInfoResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDeleteDialogInfoResource() {
    yield takeLatest('DELETE_DIALOG_INFO_RESOURCE', deleteDialogInfoResourceContactCall);
}