import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateSidebarResource,
    doneGetInfiniteSidebarResource,
    doneGetSidebarResource,
    doneUpdateSidebarResource,
    errorCreateSidebarResource,
    errorGetInfiniteSidebarResource,
    errorGetSidebarResource,
    errorUpdateSidebarResource
} from "../actions/sidebarResource";
import { pushNotification } from "../actions/ui";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getSidebarResource(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSidebarResource(result.data));
    } else {
        yield put(errorGetSidebarResource(result.data));
    }
}

export function* watchGetSidebarResource() {
    yield takeLatest('GET_SIDEBAR_RESOURCE', getSidebarResource);
}


export function* getInfiniteSidebarResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetInfiniteSidebarResource(Object.assign({}, result.data, {reset: action.data.reset})));
    } else {
        yield put(errorGetInfiniteSidebarResource(result.data));
    }
}

export function* watchGetInfiniteSidebarResource() {
    yield takeLatest('GET_INFINITE_SIDEBAR_RESOURCE', getInfiniteSidebarResourceCall);
}

export function* createSidebarResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSidebarResource(resPiggy.data));
                yield put(doneCreateSidebarResource(result.data));
            }
        } else {
            yield put(doneCreateSidebarResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateSidebarResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateSidebarResource() {
    yield takeLatest('CREATE_SIDEBAR_RESOURCE', createSidebarResourceCall);
}

export function* updateSidebarResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action?.data?.file) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSidebarResource(resPiggy.data));
                yield put(doneUpdateSidebarResource(result.data));
            }
        } else {
            yield put(doneUpdateSidebarResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateSidebarResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateSidebarResource() {
    yield takeLatest('UPDATE_SIDEBAR_RESOURCE', updateSidebarResourceCall);
}