
import TableCellsIcon from "@heroicons/react/20/solid/TableCellsIcon";
import React from "react";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import PopOver from "../../../popover";
import {classNames} from "../../../../util/util-helpers";
import FieldCheckbox from "../../../fields/field-checkbox";

export default function TableSettingsPopOver({options, setOptions, translate, toggleColumnSettingsDialog, toggleTableProfiles}) {
    const handleTableOptionChange = (optionName, value) => {
        let optionsUpdate = Object.assign({}, options);

        optionsUpdate.style[optionName] = !value;

        setOptions(optionsUpdate)
    }

    return (
        <PopOver
            btnClass={classNames("btn-icon")}
            BtnIcon={TableCellsIcon}
            btnIconClass={"h-5 w-5 relative"}
            widthClass={"max-w-xs"}
            positionClass="absolute translate-x-0 right-0"
        >
            <div className="bg-popup border border-tm-gray-200 overflow-hidden rounded-md">
                <div className="border-b border-tm-gray-200">
                    <div className="">
                        <button
                            onClick={toggleColumnSettingsDialog}
                            className="w-full flex py-3 px-3.5 hover:bg-tm-gray-100 text-sm font-semibold"
                        >
                            <Cog6ToothIcon className="w-5 h-5 text-tm-gray-500 mr-2" />
                            {translate("btn.open_column_settings")}
                        </button>
                    </div>
                </div>

                {!!toggleTableProfiles && (
                    <div className="border-b border-tm-gray-200">
                        <div className="">
                            <button
                                onClick={toggleTableProfiles}
                                className="w-full flex py-3 px-3.5 hover:bg-tm-gray-100 text-sm font-semibold"
                            >
                                <AdjustmentsHorizontalIcon className="w-5 h-5 text-tm-gray-500 mr-2" />
                                {translate("btn.open_table_profiles")}
                            </button>
                        </div>
                    </div>
                )}

                <div className="bg-popup">
                    {Object.keys(options.style).filter(optionName => filterOptions(options.style, optionName)).map((optionName, i) => {
                        const value = options.style[optionName];

                        return (
                            <label
                                key={optionName}
                                className={
                                    classNames(
                                        i ? "border-t" : undefined,
                                        "mb-0 select-none py-2 px-4 border-tm-gray-200 flex items-center cursor-pointer hover:bg-tm-gray-100"
                                    )
                                }
                                htmlFor={optionName}
                            >
                                <FieldCheckbox
                                    id={optionName}
                                    className={classNames("checkbox", value ? "border-primary" : "border-tm-gray-200")}
                                    onChange={() => handleTableOptionChange(optionName, value)}
                                    value={value}
                                />

                                <span
                                    className="ml-2 flex flex-col text-sm font-semibold text-tm-gray-900"
                                >
                                    {translate("text." + optionName)}
                                </span>
                            </label>
                        )}
                    )}
                </div>
            </div>
        </PopOver>
    )
}

const filterOptions = (options, name) => {
    return !(name === "frozenActionColumn" && options["floatingActions"])
        && name !== 'isTableLayoutFixed'; // user can't define this option
}
