import React, {Component} from 'react'
import {fieldsToHtml, fillFieldsFromData} from '../../../common/util/util-fields'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import LocalStorage from '../../../util/localStorage'
import {getResource, updateResource} from '../../../data/actions/resource'
import {LoaderSmall} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp, openInNewTab} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../common/components/card";
import InfoParagraph from "../../../common/components/info-paragraph";

export default class MacroPointIntegrationTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            isDirty: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.resource?.isLoading && (!this.props?.resource?.isLoading)) {
            this.setState({
                fields: this.getFields()
            })
        }
    }
    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: {
                id: this.getID()
            }
        }))
        console.log("fetch")
    }

    onSubmit = () => {
        if (this.state.isDirty) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            id: this.getID(),
                        }),
                        query: {
                            id: this.getID(),
                        },
                        errorMessage: true, successMessage: this.props.translate('text.CustomerMPIDUpdated'),
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                    this.setState({isDirty: false})
                }
            })
        }
    }

    /** UI events
     ================================================================= */
    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(),
            isDirty: false
        })
    }

    handleInputChange = (name, value) => {
        this.setState({
            fields: FieldsManager.updateField(this.state.fields, name, value),
            isDirty: true
        })
    }

    /** Fields/data definitions
     ================================================================= */
    getFields = () => {
        let info = getProp(this.props.resource, 'data', {})

        let fieldTemplates = {
            MPID: new Field('MPID', '', [''], false, 'text', {addContainerClass: "col-span-full"}),
        }

        return fillFieldsFromData(fieldTemplates, info)
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.CustomersInfo
    }

    getID = () => {
        return this.props.match.params.CustomerID
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const fieldsHTML = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {})

        const hasMacroPointIntegration = getProp(resource.data, "integrations.MacroPoint.hasIntegration", 0)

        return (
            <div className="max-w-4xl mx-auto">
                <PageHeader
                    title={translate('page.heading.MacroPointIntegration')}
                    titleClass="mb-5 text-2xl ml-4"
                />

                <Card className="bg-inverse rounded-card border-tm-gray-300 border shadow-card mb-4 max-w-2xl">
                    {!!resource.isLoading && (
                        <div className="w-full text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!resource.isLoading && (
                        <div className={'grid grid-cols-12 gap-4'}>
                            {!hasMacroPointIntegration && (
                                <button className={"col-span-full"} disabled={!checkPerm(Resources.CustomersInfo, UPDATE_PERM)} onClick={() => openInNewTab('/integrations-center')}>
                                    <InfoParagraph
                                        type="info"

                                    >
                                        {translate("text.customerPleaseEnableMacroPoint")}
                                    </InfoParagraph>
                                </button>
                            )}

                            {!!hasMacroPointIntegration && (<div className={"col-span-full"}>
                                {fieldsHTML}

                                <InfoParagraph
                                type="info" className={"my-2"}>

                            {translate("text.customerToPushMacroPoint")}
                                </InfoParagraph>
                            </div>
                            )}
                        </div>
                    )}
                </Card>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.isDirty}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.onSubmit}
                />
            </div>
        )
    }
}
