import React, {useEffect, useState} from "react";
import Tippy from "@tippyjs/react";
import {
    Bars2Icon,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from "@heroicons/react/24/outline";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import ArrowUturnLeftIcon from "@heroicons/react/24/outline/ArrowUturnLeftIcon";
import {classNames} from "../../util/util-helpers";
import FireSolidIcon from "@heroicons/react/20/solid/FireIcon";
import FireIcon from "@heroicons/react/24/outline/FireIcon";
import {LoaderSmall} from "../loader";
import {getUser} from "../../util/util-auth";

export default function SimpleBoard({
                                        columns,
                                        translate,
                                        data,
                                        isLoading,
                                        isDraggable,
                                        handleUnlinkIssue,
                                        handleRestoreIssue,
                                        handleMarkIssue,
                                        handleCreateUpdateResource,
                                        handleDroppedItem,
                                        getUpdateStatusIcon,
                                        handleChangeTaskStatus,
                                        getStatusTooltipText
                                    }) {

    const [draggedItem, setDraggedItem] = useState(null);
    const [currentColumnIndex, setCurrentColumnIndex] = useState(null);
    const [draggedOverColumn, setDraggedOverColumn] = useState(null);

    const getTypeColor = (type) => {
        switch (+type) {
            case 1:
                return "#7c68ed"
            case 2:
                return "#fc991f"
            case 3:
                return "#4bade8"
            case 4:
                return "#188038"
            default:
                return "#4bade8"
        }
    }

    const getTaskPriorityIcon = (item) => {

        let Icon;
        let colorClass;
        switch (+item.TaskPriorityID) {
            case 1:
                Icon = ChevronDoubleDownIcon
                colorClass = 'text-primary'
                break
            case 2:
                Icon = ChevronDownIcon
                colorClass = 'text-primary'
                break
            case 3:
                Icon = Bars2Icon
                colorClass = 'text-orange-600'
                break
            case 4:
                Icon = ChevronUpIcon
                colorClass = 'text-red-600'
                break
            case 5:
                Icon = ChevronDoubleUpIcon
                colorClass = 'text-red-600'
                break
            default:
                Icon = false
                break
        }

        return Icon && (
            <Tippy
                content={<span>Priority: {item.TaskPriority}</span>}
            >
                <button
                    className="table-action bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse"
                >
                    <div className={
                        classNames("cursor-pointer w-fit", colorClass)
                    }>
                        <Icon className="h-5 w-5"/>
                    </div>
                </button>
            </Tippy>
        )
    }

    const handleDragOver = (e, columnIndex) => {
        e.preventDefault();
        setDraggedOverColumn(columnIndex)
    }

    const handleDragStart = (e, item, columnIndex) => {
        setDraggedItem(item);
        setCurrentColumnIndex(columnIndex)
    }

    const handleDragDrop = (e, TaskStatusID) => {
        if (draggedItem.TaskStatusID != TaskStatusID) {
            handleDroppedItem(draggedItem, TaskStatusID)
        }
    }

    const handleDragEnd = () => {
        setCurrentColumnIndex(undefined)
        setDraggedOverColumn(undefined)
        setDraggedItem(undefined);
    }

    useEffect(() => {
        handleDragEnd();
    }, [data])

    const grid = Object.keys(columns).map((item, columnIndex) => {
        const itemData = data
            .filter(it => (+it.TaskStatusID) === (+item))
            .map((it) => {
                return (
                    <div
                        key={it.TaskID}
                        draggable={isDraggable}
                        onDragStart={(e) => handleDragStart(e, it, columnIndex)}
                        onDrop={(e) => handleDragDrop(e, it.TaskStatusID)}
                        onDragEnd={handleDragEnd}
                        className={classNames(
                            "p-3 shadow rounded mb-3",
                            isDraggable ? "cursor-move" : undefined,
                            it.ArchivedDate ? "bg-stripes" : "bg-inverse"
                        )}
                        style={{borderLeft: `3px solid ${getTypeColor(it.TaskStatusID)}`, padding: "10px"}}
                    >
                        <div
                            onClick={() => handleCreateUpdateResource(it)}
                            className={`font-bold text-primary cursor-pointer mb-1 ${isDraggable && "cursor-move"}`}>
                            {it.TaskName} (#{it.TaskID})
                        </div>
                        <div className={'mb-2 text-tm-gray-500'}>
                            {it.AssigneeID ?
                                <Tippy content={<span>Assignee: {it.Assignee}</span>}>
                                    <span>{it.Assignee}</span>
                                </Tippy>
                                :
                                <span>Task not assigned</span>
                            }
                        </div>

                        <h1 className="text-blue-600 font-weight-bolder ">
                            Board: {it.TaskGroupName}
                        </h1>

                        <div className='flex justify-between items-center'>
                            <div className='flex'>
                                {getTaskPriorityIcon(it)}

                                <Tippy
                                    content={handleMarkIssue ? translate(!it.Marked ? "btn.set_as_marked" : "btn.remove_from_marked") : translate("text.NeedsAttention")}
                                >
                                    <button
                                        className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700  hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() => {
                                            if (handleMarkIssue) {
                                                handleMarkIssue(it)
                                            }
                                        }}
                                    >
                                        {it.Marked
                                            ? <FireSolidIcon
                                                className='relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse'/>
                                            : <FireIcon className="relative z-12 h-5 w-5 cursor-pointer opacity-50"/>}
                                    </button>
                                </Tippy>

                                {handleChangeTaskStatus && (it.TaskStatusID < 3 && it.AssigneeID == getUser('Contact.ContactID')) && (
                                    <Tippy
                                        content={getStatusTooltipText(it)}
                                    >
                                        <button
                                            className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700  hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                            onClick={() => {
                                                handleChangeTaskStatus(it)
                                            }}
                                        >
                                            {getUpdateStatusIcon(it)}
                                        </button>
                                    </Tippy>
                                )}

                            </div>

                            {handleUnlinkIssue && !it.ArchivedDate && (
                                <Tippy
                                    content={translate("btn.archive")}
                                >
                                    <button
                                        className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700  hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() => {
                                            handleUnlinkIssue(it)
                                        }}
                                    >
                                        <TrashIcon className="h-4 w-4"/>
                                    </button>
                                </Tippy>
                            )}

                            {handleRestoreIssue && !!it.ArchivedDate && (
                                <Tippy
                                    content={translate("btn.restore")}
                                >
                                    <button
                                        className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700  hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() => {
                                            handleRestoreIssue(it)
                                        }}
                                    >
                                        <ArrowUturnLeftIcon className="h-4 w-4"/>
                                    </button>
                                </Tippy>
                            )}
                        </div>
                    </div>
                )
            })

        return (
            <div
                key={item}
                className="relative"
                onDragOver={(e) => handleDragOver(e, columnIndex)}
                onDrop={(e) => handleDragDrop(e, item)}
            >
                {!!draggedItem && currentColumnIndex !== columnIndex && (
                    <div className={
                        classNames(
                            "absolute inset-2  z-10 border-2 border-primary border-dashed rounded-lg",
                            columnIndex === draggedOverColumn ? "bg-sky-800" : "bg-tm-gray-200"
                        )}/>
                )}

                <div className="text-tm-gray-500 p-3 no-select sticky top-0 bg-tm-gray-100 z-10">
                    <div
                        className="bg-inverse p-3 shadow rounded border-t font-weight-bolder uppercase"
                        style={{borderTop: `3px solid ${getTypeColor(item)}`, padding: "10px"}}>
                        {columns[item]} ({itemData.length})
                    </div>
                </div>

                <div
                    className={classNames(
                        'p-3',
                    )}
                >
                    {itemData}
                </div>
            </div>
        )
    })

    const styles = {
        display: "grid",
        gridTemplateColumns: `repeat(${Object.keys(columns).length}, ${100 / Object.keys(columns).length}%)`,
    }

    return (
        isLoading ?
            <div className="p-5 text-center">
                <LoaderSmall/>
            </div>
            :
            data.length > 0 && (
                <div style={styles} className={'bg-tm-gray-100'}>
                    {grid}
                </div>
            )
    )
}
