import React from "react";
import DataCard from "../../display-data/data-card";
import { UserCircleIcon, UserGroupIcon, TicketIcon } from "@heroicons/react/20/solid";
import {Field} from "../../../../data/services/fields";


export default function ContactGroupCard({data = {}, translate, dispatch}) {
    let basicFields = {
        ContactGroupName: new Field('ContactGroupName', '', [''], false, "text", {icon: () => UserGroupIcon}),
        ContactGroupManager: new Field('ContactGroupManager', '', [''], false, "contact", {icon: () => UserCircleIcon}),
        ContactGroupEmail: new Field('ContactGroupEmail', '', [''], false, "text", {icon: () => TicketIcon}),
        ContactGroupDesc: new Field('ContactGroupDesc', '', [''], false, "textarea", {addContainerClass: "pt-6"}),
    };

    return (
        <React.Fragment>
            <div className="relative px-3 py-8 text-tm-gray-700 bg-tm-gray-50">
                <div className="max-w-md mx-auto space-y-6">
                    <div className="space-y-2">
                        {Object.values(basicFields).filter(it => data[it.name] !== undefined).map(field => {
                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={data}
                                    dispatch={dispatch}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}