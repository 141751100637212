import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, getDefaultTableOptions, getProp, JSONParseFix} from '../../../common/util/util-helpers'
import {DEFAULT_CRUD_STATE} from '../../../util/util-constants'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {getResource} from "../../../data/actions/resource";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {EyeIcon} from "@heroicons/react/24/outline";
import {READ_PERM} from "../../../common/util/util-consts";
import FieldSearch from "../../../common/components/fields/field-text/search";

const MileageAndFuelTab = ({translate, breakpoint, dispatch, handleToggleDetailsDialog, handleQuickTruckView, handleQuickDriverView, resource}) => {
    /** Constants
     ================================================================= */


    /** Store
     ================================================================= */
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(getProp(resource, 'isLoading', true));

    /** Constants
     ================================================================= */
    const pagePath = 'MileageAndFuelTab'

    /** Fields Data Definitions
     ================================================================= */
    const getFields = () => {
        const cellButtonClass = 'btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0';
        return {
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Drivers, READ_PERM) ? handleQuickDriverView(item) : null}
                        className={cellButtonClass}
                    >
                        <div>{item.Driver}</div>
                    </CellButton>
            }),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Trucks, READ_PERM) ? handleQuickTruckView(item) : null}
                        className={cellButtonClass}
                    >
                        <div>{item.Truck}</div>
                    </CellButton>
            }),
            Mileage: new Field('Mileage', '', [''], false, 'float'),
            Fuel: new Field('Fuel', '', [''], false, 'float'),
        }
    }

    const getQueryFilterFields = () => {
        return {
            // query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}, {isClearable: true}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}, {isClearable: true}),
            IftaTruck: new Field('IftaTruck','', [''], false, 'checkbox'),
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {isClearable: true}),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {isClearable: true}),
            StateID: new Field('StateID', '', [''], false, 'select', {}, {isClearable: true}),
            limit: new Field('limit', 300, [''], false, 'select', {
                containerClass: 'col-md-4 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: "top"}),
        }
    }

    const getQueryField = () => {
        return {
            query: new Field('query', '', [''], false, 'search')
        }
    }

    const getTableOptions = (pagePath, translate) => {
        const tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: false,
                verticalLines: true,
                isGPUAccelerated: true,
                floatingActions: true
            }
        }

        return getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate)
    }

    const [tableOptions, setTableOptions] = useState(getTableOptions(pagePath, translate))
    /** Helpers
     ================================================================= */

    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    const getResourceName = () => {
        return Resources.MileageReports
    }

    /** State
     ================================================================= */
        // Query definitions
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state')
            ? JSONParseFix(LocalStorage.get(pagePath + '_state'))?.queryFilterFields
            : getQueryFilterFields())

    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE
    })

    const [queryField, setQueryField] = useState(getQueryField())

    /** UI events - QUERY HANDLERS - main table
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
    }

    const handleQueryFieldInputChange = (name, value) => {
        setQueryField(FieldsManager.updateField(queryField, 'query', value))
    }

    const handleUpdateSort = (sortBy) => {

        let sort = (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: sort
        }))

        sort = (sort === 'ASC') ? 'DESC' : 'ASC'

        setData(data.sort((A, B) => {
            let a = A[sortBy];
            let b = B[sortBy];

            if (a === null && b !== null) {
                return sort === "ASC" ? 1 : -1;
            } else if (a !== null && b === null) {
                return sort === "ASC" ? -1 : 1;
            } else {
                return sort === "ASC" ? (a === b ? 0 : (a < b ? -1 : 1)) : (a === b ? 0 : (a > b ? -1 : 1));
            }
        }))

        setIsLoading(true)
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter((it) => !excludedFields.includes(it.name)).forEach((it) => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        setQueryField(getQueryField())
    }

    /** UI events
     ================================================================= */

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get("user"),
            query: getQuery(),
            resource: getResourceName()
        }))
    }
    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
        setQuery({
            ...DEFAULT_CRUD_STATE
        })
    }, [queryFilterFields])

    useEffect(() => {
        if (resource.data) {
            setData(resource.data.list)
            setIsLoading(getProp(resource, 'isLoading', true))
        }
    }, [resource]);

    useEffect(() => {
        setIsLoading(true)
    }, []);

    useEffect(() => {
          setIsLoading((prevState) => !prevState)
    }, [query]);

    /** Render
     ================================================================= */
    const filteredData = data.filter(it => {
        const Mileage = it?.Mileage + ''
        const Fuel = it?.Fuel + ''
        let canPass = true
        if (queryField.query.value) {
            canPass &=
                it.Driver?.toLowerCase().includes(queryField.query?.value?.toLowerCase())
                ||
                it.Truck?.toLowerCase().includes(queryField.query?.value?.toLowerCase())
                ||
                (parseFloat(Mileage)?.toFixed(2).toString().toLowerCase().includes(queryField.query?.value?.toLowerCase()))
                ||
                (parseFloat(Fuel)?.toFixed(2).toString().toLowerCase().includes(queryField.query?.value?.toLowerCase()))
        }

        return canPass
    })

    return (
        <div>
            <div className="flex my-2">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />
            </div>

            <TableCard addClass={"min-h-[20rem]"}>
                <div className="pl-4 flex items-center gap-2 w-full relative z-30">
                        <FieldSearch
                            placeholder={translate("text.search")}
                            addClass="form-control form-group"
                            value={queryField.query.value}
                            onChange={handleQueryFieldInputChange}
                        />

                    <TableFilters
                        className="md:px-5 py-5 flex  w-full"
                        popoverClass="relative"
                        hideLimit
                        filterFields={queryFilterFields}
                        handleInputChange={handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={() => {setIsLoading(true);fetchData()}}
                        isLoading={isLoading}
                    />
                </div>

                <ResourceTable
                    data={Array.isArray(filteredData) ? filteredData : []}
                    fields={getFields()}
                    verticalTableIsVisible={breakpoint.index <= 1}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}

                    // limit={queryFilterFields.limit.value}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    disableEdit
                    disableDelete
                    disableCreate

                    // tableKey={getPrimaryKey()}
                    maxHeightClass="max-h-[calc(100vh-27rem)]"
                    actions={[
                        {
                            title: () => translate('btn.view'),
                            visible: () => true,
                            action: (item) => handleToggleDetailsDialog(item),
                            icon: () => EyeIcon
                        }
                    ]}
                    onRowClick={(item) => handleToggleDetailsDialog(item)}
                />

                {/*Table footer*/}
                <NoRecordsTable
                    show={(data?.length === 0 || filteredData?.length === 0) && !isLoading}
                    canCreate={false}
                    title={'No matching records found'}

                    className={'pb-12 pt-16 px-6'}
                    onClearFilterClick={handleClearFiltersClick}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                    filters={queryFilterFields}
                />
            </TableCard>
        </div>
    )

}

export default MileageAndFuelTab
