import OCRElement from "./ocr-element";
import React, {useRef, useState} from "react";

// pixel = dpi * mm / 25.4 mm (1 in)
const documentDisplayWidth = 1280;

export default function OpticalCharacterRecognition(
    {
        onSelectElement,
        focusedElement,
        parentRef,
        documentUri,
        documentZoomModifier = 1,
        documentDPI,
        elements,
        pairs,
        isLoading,
        processFragments,
        countFragmentTypes
    }) {

    const [selectedElement, setSelectedElement] = useState("");
    const [documentResolution, setDocumentResolution] = useState({width: 0, height: 0});

    const documentRef = useRef(null);
    const documentScale = ((documentDisplayWidth * documentZoomModifier) / (documentResolution?.width 
        ?? documentDisplayWidth)).toFixed(2);

    let mergedElements = [];

    if (focusedElement?.specific?.['merged-with']) {
        mergedElements = Object.values(JSON.parse(focusedElement?.specific?.['merged-with']))?.map(it => it.index);
    }

    return (
        <>
            <div
                className="overflow-hidden"
                style={{
                    width: (documentResolution?.width * documentScale ?? 0) + "px",
                    height: (documentResolution?.height * documentScale ?? 0) + "px",
                }}
            >
                <div
                    ref={documentRef}
                    className="relative origin-top-left"
                    style={{
                        width: (documentResolution?.width ?? 0) + "px",
                        height: (documentResolution?.height ?? 0) + "px",
                        scale: documentScale
                    }}
                >
                    {!isLoading && !!elements?.length && elements
                        //.filter(element => element.type === "text-text")
                        .map((element) => {
                            const elementID = element.specific?.index;
                            const isPaired = !!pairs[element?.specific?.DbGuid];

                            return (
                                <OCRElement
                                    key={elementID}
                                    isElementFocused={focusedElement?.specific?.index === elementID || mergedElements.includes(elementID)}
                                    elementID={elementID}
                                    element={element}
                                    location={element.location}
                                    documentDPI={documentDPI}
                                    selectedElement={selectedElement}
                                    parentRef={parentRef}
                                    isPaired={isPaired}
                                    pairedFieldName={""} // pairedIDs?.[element.specific?.index]?.fieldName
                                    //isDisabled={true}
                                    onSelectElement={onSelectElement}
                                    processFragments={processFragments}
                                    countFragmentTypes={countFragmentTypes}
                                />
                            )
                        }
                    )}

                    <div
                        className="z-1 absolute inset-0"
                        ref={documentRef}
                        onClick={() => {
                            setSelectedElement("");
                            onSelectElement("");
                        }}
                    />

                    {documentUri && (
                        <>
                            <img
                                src={documentUri}
                                alt=""
                                onLoad={(e) => {
                                    setDocumentResolution({
                                        width: e?.target?.naturalWidth,
                                        height: e?.target?.naturalHeight
                                    });
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

