import React from "react";
import {LoaderSmall} from "../../../../common/components/loader";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {checkPerm, classNames} from "../../../util/util-helpers";
import Tooltip from "../../../../common/components/tooltip";
import Resources from "../../../../data/services/resources";
import {CREATE_PERM} from "../../../../util/util-constants";

const ButtonIntegration = ({
                    children,
                    className = '',
                    addClass,
                    onClick,
                    hasPerm = true,
                    hasIntegration = true,
                    tooltip,
                    isLoading,
                    integrationName,
                               history
                }) => {

    if (!className) {
        if (!hasIntegration || !hasPerm) {
            className = "btn btn-outline-secondary";
        } else {
            className = "btn btn-outline";
        }
    }

    return (
    <button
        onClick={() => {
            if (!hasIntegration) {
                if (checkPerm(Resources.IntegrationsMy, CREATE_PERM)) {
                    history.push('/integrations-center');
                }
            } else if (hasPerm) {
                onClick()
            }
        }}
        className={classNames(className, addClass)}
        disabled={
            !hasPerm || (!checkPerm(Resources.IntegrationsMy, CREATE_PERM) && !hasIntegration)
        }
        type='button'
    >
        {isLoading && (
            <LoaderSmall addClass='mr-1 -ml-1'/>
        )}

        <Tooltip content={
            <div>
                {!hasIntegration && (
                    <p>Enter integration settings for {integrationName} in order to unlock functionality.</p>
                )}
                {!!hasIntegration && !!hasPerm && (
                    <p>{tooltip ?? integrationName}</p>
                )}
                {!!hasIntegration && !hasPerm && (
                    <p>Your account does not have sufficient permissions to perform this action.</p>
                )}
            </div>
        }>
            <div className={"flex"}>
                {children}

                {!hasIntegration && (
                        <LockClosedIcon className="w-4 h-4 text-blue-600 -mr-1 ml-1"/>
                )}
                {!hasPerm && (
                    <LockClosedIcon className="w-4 h-4 text-red-600 -mr-1 ml-1"/>
                )}
            </div>
        </Tooltip>
    </button>)
}

export default ButtonIntegration;