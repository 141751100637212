import React from 'react'

export default function TermsOfUse () {

    return (
        <div className="bg-inverse text-tm-gray-900 min-h-screen">
            <div style={{ width: '60%', margin: 'auto', textAlign: 'center' }}>
            <h1>Terms of Use</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pellentesque, metus non commodo
                fermentum, velit enim ultrices lorem, vitae gravida lectus diam ut magna. Quisque consequat facilisis
                dictum. Maecenas rhoncus massa vel arcu tristique tempor. Donec placerat pellentesque est nec congue.
                Phasellus metus dui, scelerisque ut metus vel, molestie pulvinar ligula. Aliquam est nisi, mollis quis
                erat ac, placerat faucibus augue. Quisque vestibulum magna id elit viverra consequat. Etiam sed purus at
                ligula lobortis porta. Mauris sed bibendum ex, quis volutpat nisi.

                Nunc vehicula metus quis nisi commodo, vitae bibendum nulla accumsan. Donec interdum sapien sit amet
                nisl egestas, quis iaculis orci finibus. Sed est augue, commodo eu malesuada eu, ultricies eget quam.
                Sed nec magna ligula. Nullam egestas rhoncus mi vitae mollis. Pellentesque lacinia risus at urna gravida
                efficitur et sed ante. Mauris lobortis ipsum leo, at mattis ex finibus vel. Donec condimentum ante in
                ligula ultricies lobortis. Sed imperdiet elit id nisl aliquet fermentum. Integer pharetra diam ex, nec
                eleifend nisl tempus id. Suspendisse potenti. Phasellus nec neque scelerisque, varius sapien nec,
                aliquam ipsum.

                Duis ut metus eu mi venenatis gravida. Nunc sagittis consequat ipsum ac euismod. Vestibulum eget lacus
                dignissim nulla egestas sodales. Praesent tristique augue eget maximus lacinia. Duis placerat aliquet
                dictum. Vestibulum bibendum tortor et purus consectetur, at semper felis pellentesque. Integer vehicula
                tellus tellus, ac laoreet lacus hendrerit vitae. Sed ultrices vestibulum tempor. Quisque faucibus
                tincidunt nunc et gravida. Curabitur pellentesque at elit nec pulvinar. Nulla non rhoncus lorem. Sed ac
                aliquet leo, vitae mattis sapien. Suspendisse potenti. Morbi et libero justo. Sed pulvinar malesuada
                sem, in porta erat scelerisque in. Vivamus elementum pellentesque maximus.

                Aliquam sodales felis fringilla aliquet dapibus. Ut nec felis in lacus scelerisque fermentum et non
                velit. Vivamus condimentum sem ut vehicula ultricies. Fusce mattis varius ex at maximus. Morbi
                scelerisque luctus dolor non vehicula. In dapibus consequat iaculis. Mauris ante ex, tincidunt ut cursus
                et, egestas nec diam. Vestibulum in ipsum magna.

                Sed et metus quis enim egestas imperdiet. Maecenas nec nisl libero. Integer consectetur purus vehicula
                lectus euismod auctor. Quisque dui dui, sodales ut neque at, molestie posuere mauris. Integer fringilla
                non dolor sit amet faucibus. Ut gravida eros at tincidunt consequat. Morbi pretium hendrerit felis vel
                consectetur. Aliquam nec dapibus orci. Nunc sed posuere erat.</p>
            </div>
        </div>
    )
}
