export function getAccountingResource(data) {
    return {
        type: 'GET_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function doneGetAccountingResource(data) {
    return {
        type: 'DONE_GET_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function errorGetAccountingResource(data) {
    return {
        type: 'ERROR_GET_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function createAccountingResource(data) {
    return {
        type: 'CREATE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function doneCreateAccountingResource(data) {
    return {
        type: 'DONE_CREATE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function errorCreateAccountingResource(data) {
    return {
        type: 'ERROR_CREATE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function deleteAccountingResource(data) {
    return {
        type: 'DELETE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function doneDeleteAccountingResource(data) {
    return {
        type: 'DONE_DELETE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function errorDeleteAccountingResource(data) {
    return {
        type: 'ERROR_DELETE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function resetAccountingResource() {
    return {
        type: 'RESET_ACCOUNTING_RESOURCE',
        data: []
    }
}

export function updateAccountingResource(data) {
    return {
        type: 'UPDATE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function doneUpdateAccountingResource(data) {
    return {
        type: 'DONE_UPDATE_ACCOUNTING_RESOURCE',
        data: data
    };
}

export function errorUpdateAccountingResource(data) {
    return {
        type: 'ERROR_UPDATE_ACCOUNTING_RESOURCE',
        data: data
    };
}