import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import ExpenseTab from './expenseTab'
import CheckTab from './checkTab'
import LiabilitiesTab from './liabilitiesTab'
import {READ_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import RecurringTab from './recurringTab'
import TransactionsTab from './transactionsTab'
import BillsTab from './billsTab'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import Layout from "../../../common/components/layout";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import VendorCreditTab from "./vendorCreditTab";

class IncomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: this.getTabs(),
            breakpoint: {}
        }
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            })
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    getTabs = () => {
        return [
            {
                name: 'Expense',
                resource: Resources.Expense,
                current: true,
                visible: checkPerm(Resources.Expense, READ_PERM)
            },
            {
                name: 'ExpenseTransactions',
                resource: Resources.ExpenseTransactions,
                current: false,
                visible: checkPerm(Resources.ExpenseTransactions, READ_PERM)
            },
            {
                name: 'VendorCredit',
                resource: Resources.ExpenseVendorCredit,
                current: false,
                visible: checkPerm(Resources.ExpenseVendorCredit, READ_PERM)
            },
            {
                name: 'ExpenseBills',
                resource: Resources.ExpenseBills,
                current: false,
                visible: checkPerm(Resources.Expense, READ_PERM)
            },
            {
                name: 'ExpenseChecks',
                resource: Resources.ExpenseChecks,
                current: false,
                visible: checkPerm(Resources.ExpenseChecks, READ_PERM)
            },
            {
                name: 'ExpenseLiabilities',
                resource: Resources.ExpenseLiabilities,
                current: false,
                visible: checkPerm(Resources.ExpenseLiabilities, READ_PERM)
            },
            {
                name: 'ExpenseRecurring',
                resource: Resources.ExpenseRecurring,
                current: false,
                visible: checkPerm(Resources.ExpenseRecurring, READ_PERM)
            }
        ]
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const activeTab = this.state.tabs.find(it => it.current);

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.ap_expenses')}
                        afterTitle={(
                            <>
                                <PageHeaderInfo
                                    dispatch={this.props.dispatch}
                                />

                                {(activeTab.resource === Resources.ExpenseChecks && !getProp(this.props.resource, 'isLoading', true)) && (
                                    <div>Total
                                        amount: {genericMoneyFormatter(getProp(this.props.resource.data, 'Total', 0))}</div>
                                )}
                            </>
                        )}
                    />

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="block sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {activeTab.resource === Resources.Expense && (
                        <ExpenseTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.ExpenseTransactions && (
                        <TransactionsTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.ExpenseVendorCredit && (
                        <VendorCreditTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.ExpenseBills && (
                        <BillsTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}
                    {activeTab.resource === Resources.ExpenseChecks && (
                        <CheckTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}
                    {activeTab.resource === Resources.ExpenseLiabilities && (
                        <LiabilitiesTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}
                    {activeTab.resource === Resources.ExpenseRecurring && (
                        <RecurringTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(IncomePage)
