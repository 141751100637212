import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {checkPasswordStrength, toggleBodyScroll} from "../../../util/util-vanilla";
import {updatePassword} from "../../../../data/actions/user";
import Cookie from "../../../../util/cookie";
import ModalDefault from "../modal-default";
import FieldContainer from "../../fields/field-container";
import icons from "../../../../data/themes/icons";
import PasswordStrengthMeter from "../../fields/field-password/passwordStrengthMeter";
import {LoaderSmall} from "../../loader";
import {focusOnElement} from "../../../util/util-helpers";
import {hideGlobalModal} from "../../../../data/actions/ui";
import FieldText from "../../fields/field-text";
import FieldPassword from "../../fields/field-password";
import {getUser} from "../../../util/util-auth";

class ChangePasswordDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                CurrentPassword: new Field('CurrentPassword', '', ['empty']),
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty'])
            },
            isPasswordVisible: false,
            isPasswordCurrentVisible: false,
            isPasswordConfirmVisible: false,
            match: false,
            canSubmit: false,
            passWeak: false,
            userError: false,
            passwordStrength: {
                passed: 0,
                strength: "",
                color: "white"
            }
        };
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        focusOnElement();
        toggleBodyScroll()
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        toggleBodyScroll()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user.error && this.props.user.error) {
            this.setState({
                userError: true
            })
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.cancel()
        }
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true});

        if (name === 'password') {
            this.setState({
                passwordStrength: checkPasswordStrength(value),
                passWeak: false,
                match: false,
                userError: false
            })
        }
    };

    cancel = () => {
        this.props.dispatch(hideGlobalModal('ChangePassword'));
    }

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (this.state.passwordStrength.passed < 4) {
                this.setState({
                    passWeak: true
                });
                return;
            }

            if (this.state.fields.password.value !== this.state.fields.password_confirm.value) {
                this.setState({
                    match: true,
                    passWeak: false
                });
            } else if (!this.state.fields.password.errorMessage && !this.state.fields.password_confirm.errorMessage) {
                this.props.dispatch(updatePassword({
                    user: getUser(),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    errorMessage: true, successMessage: this.props.translate('text.password_updated')
                }))
            }
        })
    };

    render() {
        const {translate} = this.props;
        const {isPasswordVisible, isPasswordConfirmVisible, isPasswordCurrentVisible} = this.state;

        return (
            <React.Fragment>
                <ModalDefault
                    show={true}
                    widthClass={'max-w-xl'}
                    title={translate('modal_heading.change_password')}
                    onButtonClick={this.submit}
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate('btn.save')}
                    closeButtonLabel={translate('btn.Cancel')}
                    onClose={this.cancel}
                >
                    <div>
                        <div className={"m-4 relative"}>
                            <FieldContainer
                                item={this.state.fields.CurrentPassword}
                                label={translate("field.CurrentPassword")}
                                translate={translate}
                            >
                                {!isPasswordCurrentVisible && (
                                    <FieldPassword
                                        className="form-control"
                                        onChange={this.handleInputChange} {...this.state.fields.CurrentPassword}
                                        placeholder={""} addClass={"form-control"}
                                    />
                                )}

                                {!!isPasswordCurrentVisible && (
                                    <FieldText
                                        className="form-control"
                                        onFocus={false}
                                        onChange={this.handleInputChange} {...this.state.fields.CurrentPassword}
                                        placeholder={""} addClass={"form-control"}
                                    />
                                )}

                                <div
                                    onClick={() => this.setState({
                                        isPasswordCurrentVisible: !this.state.isPasswordCurrentVisible
                                    })}
                                    className="absolute opacity-75 cursor-pointer text-tm-gray-600 bottom-1.5 right-3"
                                >
                                    {!isPasswordCurrentVisible ? icons.Eye : icons.EyeOff}
                                </div>
                            </FieldContainer>
                        </div>

                        <div className="relative m-4">
                            <FieldContainer
                                item={this.state.fields.password}
                                label={translate("field.password")}
                                translate={translate}
                            >

                                {!isPasswordVisible && (
                                    <FieldPassword
                                        className="form-control"
                                        onChange={this.handleInputChange} {...this.state.fields.password}
                                        placeholder={""} addClass={"form-control"}
                                    />
                                )}

                                {!!isPasswordVisible && (
                                    <FieldText
                                        className="form-control"
                                        onFocus={false}
                                        onChange={this.handleInputChange} {...this.state.fields.password}
                                        placeholder={""} addClass={"form-control"}
                                    />
                                )}
                            </FieldContainer>

                            <div
                                onClick={() => this.setState({
                                    isPasswordVisible: !this.state.isPasswordVisible
                                })}
                                className="absolute opacity-75 cursor-pointer text-tm-gray-600 bottom-1.5 right-3"
                            >
                                {!isPasswordVisible ? icons.Eye : icons.EyeOff}
                            </div>
                        </div>

                        <div className="relative m-4">
                            <FieldContainer
                                item={this.state.fields.password_confirm}
                                label={translate("field.password_confirm")}
                                translate={translate}
                            >
                                {!isPasswordConfirmVisible && (
                                    <FieldPassword
                                        className="form-control"
                                        onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                        placeholder={""} addClass={"form-control"}
                                    />
                                )}

                                {!!isPasswordConfirmVisible && (
                                    <FieldText
                                        className="form-control"
                                        onFocus={false}
                                        onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                        placeholder={""} addClass={"form-control"}
                                    />
                                )}
                            </FieldContainer>

                            <div
                                onClick={() => this.setState({
                                    isPasswordConfirmVisible: !this.state.isPasswordConfirmVisible
                                })}
                                className="absolute opacity-75 cursor-pointer text-tm-gray-600 bottom-1.5 right-3"
                            >
                                {!isPasswordConfirmVisible ? icons.Eye : icons.EyeOff}
                            </div>
                        </div>

                        {this.state.match && (
                            <h4>
                                <span className="flex text-white bg-red-600 rounded-md justify-center m-2">
                                    {translate("field.match_password")}
                                </span>
                            </h4>
                        )}

                        {this.state.passWeak && (
                            <h4>
                                <span className="flex text-white bg-red-600 rounded-md justify-center m-2">
                                    {translate("field.week_password")}
                                </span>
                            </h4>
                        )}

                        {this.props.user.error && this.state.userError && (
                            <h4>
                                <span className="flex text-white bg-red-600 rounded-md justify-center m-2">
                                    {translate(this.props.user.errorMessage)}
                                </span>
                            </h4>
                        )}

                        <p className="text-tm-gray-500 word-break no-select m-4">Your password must be at least 8
                            characters
                            long including number, lower case and uppercase characters.</p>

                        <div className="m-4">
                            <PasswordStrengthMeter password={this.state.fields.password.value}/>
                        </div>

                        {this.props.user.isLoading && (
                            <div>
                                <LoaderSmall/>
                            </div>
                        )}

                    </div>
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(ChangePasswordDialog);
