import React, {useEffect} from "react";

const FieldTimeDropdown = ({randomID, dropdownRef, renderOptions, formatTime, hideDropdown}) => {
    const outsideClick = (event) => {
        if (event.target.dataset.id !== randomID && !event.target.parentElement.dataset.id !== randomID) {
            formatTime();
            hideDropdown();
        }
    }

    useEffect(() => {
        document.addEventListener("click", outsideClick, false);

        return () => document.removeEventListener("click", outsideClick, false);
    }, []);

    return (
        <div
            data-id={randomID}
            ref={dropdownRef}
            className="top-full rounded-card shadow-card my-2 absolute w-full z-[11] box-border bg-popup text-primary overflow-y-auto max-h-[180px] min-w-[100px] border border-tm-gray-200" tabIndex="-1"
        >
            {renderOptions()}
        </div>
    )
}

export default FieldTimeDropdown;
