import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import PopOver from "../popover";
import {Popover} from '@headlessui/react'
import React from "react";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";


export default function InfoTooltipNew({children}) {

    return (
        <PopOver
            btnClass="field-btn"
            BtnIcon={InformationCircleIcon}
            btnIconClass="h-4 w-4 text-primary"
            widthClass="w-[calc(100vw-2.5rem)] md:max-w-[50vw] lg:max-w-lg"
            positionClass="z-20 fixed md:absolute left-0 mx-5 md:mx-0 translate-x-0 top-16 md:top-2.5"
        >
            <div
                className="py-2 pl-4 text-base border border-tm-gray-200 rounded-md bg-tm-gray-100 text-tm-gray-900"
            >
                <div className="relative pr-8">
                    {children}

                    <Popover.Button className="absolute top-0 right-2">
                        <XMarkIcon className="w-4 w-4 text-tm-gray-900" />
                    </Popover.Button>
                </div>
            </div>
        </PopOver>
    );
}

//btnClass
//type
// postiton: top, top-left, left (right bottom is default)