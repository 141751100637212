import React, {useEffect} from "react";
import Resources from "../../../data/services/resources";
import {getDialogInfoResource} from "../../../data/actions/dialogInfoResource";
import LocalStorage from "../../../util/localStorage";
import DataCard from "../../../common/components/display-data/data-card";
import {Field} from "../../../data/services/fields";
import {checkPerm, classNames, getProp, openInNewTab} from "../../../common/util/util-helpers";
import ModalDefault from "../../../common/components/modal/modal-default";
import {UPDATE_PERM} from "../../../util/util-constants";
import {useDispatch, useSelector} from "react-redux";
import {LoaderLarge} from "../../../common/components/loader";

export default function ClaimCard({show, translate, ClaimID, onClose}) {

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogInfoResource);
    const claimData = getProp(resource, 'data', {});
    const isLoading = getProp(resource, 'isLoading', false);

    const fetchData = () => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.ClaimsInfo,
            query: {id: ClaimID}
        }));
    }

    useEffect(() => {
        if (ClaimID) {
            fetchData()
        }
    }, [show])

    const infoFields = Object.values(getFields()).filter(it => !!claimData[it.name]);
    const infoDetailFields = Object.values(getClaimDetailsFields()).filter(it => !!claimData[it.name]);

    return (
        <ModalDefault
            show={show}
            title={translate('text.ClaimInfo')}
            limitHeight={true}
            widthClass={'max-w-3xl'}
            translate={translate}
            buttonDisabled={!checkPerm(Resources.ClaimsInfo, UPDATE_PERM)}
            buttonLabel={translate("btn.edit")}
            onButtonClick={() => {
                openInNewTab(`/claims/info/${ClaimID}`);
            }}
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
        >
            <div className={'bg-tm-gray-50 px-3 py-8 text-tm-gray-700 space-y-8 relative min-h-[27rem]'}>
                {isLoading && (
                    <div>
                        <LoaderLarge/>
                    </div>
                )}

                {!isLoading && (
                    <React.Fragment>
                        <div className={'max-w-md mx-auto my-10'}>
                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                {infoFields.map((field, i) => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={claimData}
                                            className={classNames("px-3 py-1.5 flex items-center px-6", i < infoFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>

                            {!!claimData.ClaimDescription && (
                                <div className="mt-6">
                                    <div className="mt-6">
                                        <p className="font-bold leading-5 mb-2">{translate("field.ClaimDescription")}</p>
                                        <div
                                            className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center">
                                            {claimData.ClaimDescription}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!!infoDetailFields.length && (
                                <div className="mt-6">
                                    <p className="font-bold leading-5 mb-2">
                                        {translate("text.claim_details")}
                                    </p>

                                    <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                        {infoDetailFields.map((field, i) => {
                                            return (
                                                <DataCard
                                                    key={field.name}
                                                    displayField={field}
                                                    fieldsData={claimData}
                                                    className={classNames("px-3 py-1.5 flex items-center px-6", i < infoDetailFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                                    translate={translate}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </ModalDefault>
    )
}

const getFields = () => {
    return {
        ClaimNumber: new Field('ClaimNumber', '', [''], false, "text"),
        ClaimCode: new Field('ClaimCode', '', [''], false, "text"),
        ClaimOpened: new Field('ClaimOpened', '', [''], false, "date"),
        ClaimDate: new Field('ClaimDate', '', [''], false, "date"),
        Driver: new Field('Driver', '', [''], false, "text"),
        Truck: new Field('Truck', '', [''], false, "text"),
        Trailers: new Field('Trailers', '', [''], false, "text"),
    }
};

const getClaimDetailsFields = () => {
    return {
        LoadNumber: new Field('LoadNumber', '', [''], false, "text",),
        Lost: new Field('Lost', '', [''], false, "text",),
        DateClosed: new Field('DateClosed', '', [''], false, "text"),
        Customer: new Field('Customer', '', [''], false, "text",),
        ContactName: new Field('ContactName', '', [''], false, "text"),
    }
};