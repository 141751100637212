import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";

export function WorldClock({timezone, onDelete, getCityName}) {
    // State
    const [currentTime, setCurrentTime] = useState("")

    // Lifecycle
    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(moment().tz(timezone).format('LTS')), 100);
        return () => {
            clearInterval(interval)
        };
    }, [])

    // Render
    return (
        <div className="p-3 relative rounded-card group max-w-sm hover:bg-tm-gray-100">
            {!!onDelete && (
                <button
                    className="hidden group-hover:flex btn-close items-center justify-center absolute top-1 right-1 z-10 hover:bg-red-500 hover:text-tm-gray-100"
                    onClick={() => {
                        onDelete(timezone)
                    }}
                >

                    <XMarkIcon className="w-5 h-5"/>
                </button>
            )}
            <div className={'text-3xl text-center'}>{currentTime}</div>
            <div className="text-sm text-tm-gray-500 text-center">
                {moment().tz(timezone).format('dddd, MMMM D, YYYY')}
            </div>
            <div className={'text-xl text-center'}>{getCityName(timezone)}</div>
        </div>
    )
}