const integrationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CREATE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_CREATE_INTEGRATION_RESOURCE':

            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null,
                showRedirectDialog: action.data.showRedirectDialog
            });
        case 'ERROR_CREATE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPDATE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_UPDATE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null,
                updated: action.data?.id
            });
        case 'ERROR_UPDATE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'DELETE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'RESET_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                create: null,
                isLoading: false,
                error: false,
                errorMessage: null,
                showRedirectDialog: false
            });
        case 'RESET_CREATE_UPDATE_INTEGRATION_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                updated: false
            })
        default:
            return state;
    }
};

export default integrationReducer;
