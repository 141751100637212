import DisplayDataField from "./display-data-field";
import React from "react";
import {classNames} from "../../util/util-helpers";

export default function DisplayDataFieldGroupContainer({fields, title, data, translate}) {
    const fieldGroup = Object.values(fields);

    return (
        <div className={'max-w-md mx-auto'}>
            {!!title && (
                <p className="font-bold leading-5 mb-2">
                    {title}
                </p>
            )}

            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                {fieldGroup.map((field, i) => {
                    return (
                        <DisplayDataField
                            key={field.name}
                            displayField={field}
                            fieldsData={data}
                            className={classNames("px-3 py-1.5 flex items-center px-6", i < fieldGroup.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                            translate={translate}
                        />
                    )
                })}
            </div>
        </div>
    )
}