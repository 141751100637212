import React from "react";

export default function FieldSwitchLabel({onClick, label, note, className}) {
    const DynamicElement = onClick ? "button" : "div"

    return (
        <DynamicElement
            onClick={onClick}
            className={className ?? "py-3 px-4 min-w-0 flex-1 text-sm text-left"}>
            <label htmlFor="person-3"
                   className="font-medium text-tm-gray-900 select-none">
                {label}
            </label>

            <p className="text-tm-gray-700">{note}</p>
        </DynamicElement>
    )
}