import React, { useEffect } from 'react'
import { getProp } from '../../../common/util/util-helpers'
import StarIcon from "@heroicons/react/24/outline/StarIcon";
import StarSolidIcon from "@heroicons/react/20/solid/StarIcon";

const DriverScore = (props) => {
    const { translate } = props

    useEffect(() => {
    }, [])

    const availableScores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    let RatingSumTotal = 0
    let ReviewSumSumTotal = 0
    let totalScore = {}

    const renderScore = (item, i, separator = true) => {
        RatingSumTotal += item.RatingSum
        ReviewSumSumTotal += item.ReviewSum
        const sum = item.RatingSum / item.ReviewSum
        const sumRound = Math.round(item.RatingSum / item.ReviewSum)
        const stars = availableScores.map((star, i) => {

            return (
                (i + 1) > sumRound ? (
                    <span className="text-tm-gray-400 inline-block">
                        <StarIcon className="w-5 h-5" />
                    </span>
                ) : (
                    <span className="text-yellow-600 inline-block">
                        <StarSolidIcon className="w-5 h-5" />
                    </span>
                )
            )
        })

        return (
            <React.Fragment>
                <div className="mb-3" key={i}>
                    <h5 className="text-base"><span className="text-tm-gray-700">{item.ReviewType}</span>
                        <span className="ml-1">({Math.round(sum * 100) / 100})</span></h5>

                    {stars}

                    <p>Out of {item.ReviewSum} {item.ReviewSum === 1 ? 'review' : 'reviews'}</p>

                    {separator && (
                        <div className="border-b border-tm-gray-200 mb-5"/>
                    )}
                </div>
            </React.Fragment>
        )
    }

    const score = getProp(props.resource.data, 'ratings', []).map((item, i) => {
        return renderScore(item, i)
    })

    totalScore.RatingSum = RatingSumTotal
    totalScore.ReviewSum = ReviewSumSumTotal
    totalScore.ReviewType = translate('text.AverageScore')

    return (
        <React.Fragment>
            <div className="border-2 border-tm-gray-300 -m-3 p-3 rounded-lg mb-5 bg-tm-gray-50">
                {renderScore(totalScore, 'key', false)}
            </div>

            <div>{!!score.length && score}</div>
        </React.Fragment>
    )
}

export default DriverScore
