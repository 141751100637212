import React, {Component} from 'react'
import {FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../../util/util-constants'
import {numberWithCommas, numberWithCommasToBack} from '../../../../util/util-formaters'
import {pushNotification} from '../../../../data/actions/ui'
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import {TrashIcon} from "@heroicons/react/24/outline";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {getDefaultTableOptions} from "../../../../common/util/util-helpers";

export default class InvoiceIncomeItemTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            createModalOpen: false,
            editModalOpen: false,

            selectedItem: {},
            itemModalKey: 0,
            editItemModalKey: 10000,
            query: ''
        }

        this.itemsTableOptions = getDefaultTableOptions(this.getFields(), {
            style: {
                horizontalLines: true,
                verticalLines: true,
                condensed: true,
                isGPUAccelerated: true,
                condensedHeight: 38
            },
        }, 'invoice-income-item-table', this.props.translate);
    }

    /** UI Events
     ================================================================= */
    deleteResource = (item) => {
        this.props.onRemoveItem(item.index)
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModal = (item = {}) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)

        let updateTotal = false

        if (name === 'ProductServiceID') {
            fields = FieldsManager.updateField(fields, 'AccountID',
                value
                    ? {
                        label: value.metadata?.IncomeAccount,
                        value: value.metadata?.IncomeAccountID
                    }
                    : ""
            )
            fields = FieldsManager.updateField(fields, 'Description',
                value?.metadata?.IncomeDescription ?? ""
            )
            fields = FieldsManager.updateField(fields, 'Amount',
                value?.metadata?.IncomePrice ?? 0
            )
            updateTotal = true;

            if (fields?.ProductServiceTypeID?.value) {
                fields.ProductServiceTypeID.value = value?.metadata?.ProductServiceTypeID ?? "";
            }
        }

        if (name === 'Qty' || name === 'Amount' || name === 'TaxID' || updateTotal) {
            let total = (
                numberWithCommasToBack(fields.Qty.value)
                * numberWithCommasToBack(fields.Amount.value)
                * numberWithCommasToBack(
                    fields.TaxID.value?.amount // From select dropdown value
                    ?? 1) // Multiply with 1 if there is no TAX
            );

            let totalCalculation = numberWithCommas(Math.round(total * 100) / 100) ? numberWithCommas(Math.round(total * 100) / 100) : 0

            fields = FieldsManager.updateField(fields, 'TotalAmount', totalCalculation)
        }

        if (name === 'Total') {
            fields = FieldsManager.updateField(fields, 'Amount', value)
        }

        if (name === 'ChargeTo') {
            fields['ChargeToContactID'].value = ''
            fields['ChargeToOrganizationID'].value = ''

            fields['ChargeToContactID'].type = value === 1 ? 'select-search' : 'hidden'
            fields['ChargeToOrganizationID'].type = value === 2 ? 'select-search' : 'hidden'

            fields['ChargeToContactID'].disabled = value === 2
            fields['ChargeToOrganizationID'].disabled = value === 1
        }

        if ('Advanced' === name) {
            fields.ChargeTo.type = value ? 'button-group' : 'hidden'
            fields.ChargeToContactID.type = value && fields.ChargeTo.value === 1 ? 'select-search' : 'hidden'
            fields.ChargeToOrganizationID.type = value && fields.ChargeTo.value === 2 ? 'select-search' : 'hidden'
        }

        if ('Assets' === name) {
            fields.AssetID.type = value ? 'select-search' : 'hidden'
            fields.AssetID.validate = value ? ['empty'] : ['']

            fields.Qty.disabled = !!value
            fields.Qty.value = value ? 1 : fields.Qty.value

            fields.ProductServiceTypeID.value = value ? 4 : value.metadata?.ProductServiceTypeID

            fields.ProductServiceID.disabled = !!value
            fields.ProductServiceID.validate = value ? [] : ['empty']
        }

        return fields
    }

    /** Fields/Data definitions
     ================================================================= */
    getFields = (item = {}) => {
        return this.props.getFields(item)
    }

    getFormattedFields = () => {
        return this.props.items.map((it, index) => {
            return Object.assign({}, FieldsManager.getFieldKeyValues(it),
                {
                    index: index,
                    ProductService: it.ProductServiceID?.value?.label ?? '',
                    Account: it.AccountID?.value?.label ?? '',
                    ChargeToContact: it.ChargeToContactID?.value?.label ?? '',
                    ChargeToOrganization: it.ChargeToOrganizationID?.value?.label ?? '',
                    Tax: it.TaxID?.value?.label ?? '',
                    Asset: it.AssetID?.value?.label,
                    TaxAmount: it.TaxID?.value?.amount
                })
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, hideAddInlineItemButton, renderCells} = this.props
        const metadata = {
            ProductServiceID: {
                api: 'api/' + Resources.ProductsServices,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ProductService,
                    value: item.ProductServiceID,
                    metadata: item
                })
            },
            TaxID: {
                api: 'api/' + Resources.AccountingTaxes,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.Tax,
                    value: item.TaxID,
                    amount: item.Amount
                })
            },
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },
            ChargeToOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName,
                    Organization: item
                })
            },
            ChargeToContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + ' ' + item.LastName,
                    Contact: item
                })
            },
            AssetID: {
                api: 'api/' + Resources.AccountingAssets,
                query: {},
                searchMap: (item) => ({
                    value: item.FixedAssetID,
                    label: item.FixedAsset
                })
            },
        }

        const data = this.getFormattedFields()


        const filteredData = data.filter(it => {
            const Amount = it?.Amount + ''
            const TotalAmount = it?.TotalAmount + ''
            let canPass = true
            if (this.state.query) {
                canPass &=
                    it.Description?.toLowerCase().includes(this.state.query?.toLowerCase())
                    ||
                    it.Account?.toLowerCase().includes(this.state.query?.toLowerCase())
                    ||
                    (parseFloat(Amount)?.toFixed(2).toString().toLowerCase().includes(this.state.query?.toLowerCase()))
                    ||
                    (parseFloat(TotalAmount)?.toFixed(2).toString().toLowerCase().includes(this.state.query?.toLowerCase()))
            }

            return canPass
        })


        return (
            <div className={this.props.className}>
                {!!data.length && (
                    <React.Fragment>
                        <div className="flex items-center justify-between px-4">
                            <p className="text-lg leading-6 font-medium text-tm-gray-900">Items</p>
                            <div className="relative mr-auto mx-4">
                                <div className="flex items-center">
                                    <MagnifyingGlassIcon className="absolute left-4 w-5 h-5 text-tm-gray-600"/>

                                    <input
                                        type="text"
                                        placeholder={translate("text.search")}
                                        className="form-control pl-10 rounded-t-card"
                                        value={this.state.query}
                                        autoFocus
                                        onChange={e => {
                                            this.setState({query: e.target.value})
                                        }}
                                    />
                                </div>

                            </div>

                            <button
                                onKeyDown={(event) => this.props.handleSelectFirstElement(event)}
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary btn-xs">
                                {translate('btn.add_item')}
                            </button>
                        </div>

                        <div className="mt-6 bg-inverse">
                            <ResourceTable
                                tableKey={"index"}
                                maxHeightClass="max-h-[calc(100vh-30rem)]"
                                data={filteredData}
                                fields={this.getFields()}
                                options={this.itemsTableOptions}
                                renderCells={renderCells}
                                translate={translate}
                                actions={[
                                    {
                                        action: this.deleteResource,
                                        icon: TrashIcon,
                                        visible: (item) => this.props.items.length > 1 && !item.IsPayed,
                                        title: translate('btn.delete'),
                                        order: 20
                                    },
                                    {
                                        action: this.handleToggleEditModal,
                                        icon: PencilIcon,
                                        visible: (item) => !item.IsPayed,
                                        title: translate('btn.edit'),
                                        order: 10
                                    }
                                ]}
                            />
                        </div>

                        {!hideAddInlineItemButton && (
                            <div className="flex items-end justify-end">
                                <button ref={this.props.addBtnRef} className={"btn btn-outline mx-2 my-5"}
                                        onClick={() => {
                                            this.props.onAddNewItem()
                                        }}>
                                    <span>Add and edit inline</span>
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                )}

                {!data.length && (
                    <div className="py-4">
                        <NoRecords
                            canCreate={true}
                            show={true}
                            title={translate('text.no_items')}
                            text={translate('text.disabled_until_item_add')}
                            btnLabel={'Add new item'}
                            onBtnClick={this.handleToggleCreateModal}
                        />
                    </div>
                )}
{/*dasd*/}
                <ModalSaveResource
                    key={this.state.itemModalKey}
                    title={'Create new item'}
                    widthClass="max-w-md"
                    limitHeight={true}
                    gridColsClass="grid-cols-2"
                    show={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.onAddNewItem(params)
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={this.handleInputChange}
                    getRawFields
                    secondaryButtonLabel={translate('btn.save_and_add_more')}
                    onSecondaryButtonClick={(params) => {
                        if (params) {
                            this.props.onAddNewItem(params)

                            this.setState({
                                itemModalKey: this.state.itemModalKey + 1
                            }, () => {
                                this.props.dispatch(pushNotification({
                                    title: this.props.translate('text.item_added'),
                                    timeout: true
                                }))
                            })
                        }
                    }}
                    disableSecondaryIfNotDirty={true}
                />

                <ModalSaveResource
                    key={this.state.editItemModalKey}
                    title={'Edit item'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-1"
                    limitHeight={true}
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.state.selectedItem?.index > -1) {
                                this.props.onEditItem(params, this.state.selectedItem?.index)
                            } else {
                                this.props.onAddNewItem(params)
                            }
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={this.handleInputChange}
                    getRawFields
                    secondaryButtonLabel={translate('btn.save_and_add_more')}
                    onSecondaryButtonClick={(params) => {
                        const selectedItemIndex = this.state.selectedItem?.index
                        if (params) {
                            if (selectedItemIndex > -1) {
                                this.props.onEditItem(params, selectedItemIndex)
                            } else {
                                this.props.onAddNewItem(params)
                            }

                            this.setState({
                                editItemModalKey: this.state.editItemModalKey + 1,
                                selectedItem: undefined
                            }, () => {
                                this.props.dispatch(pushNotification({
                                    title: this.props.translate('text.item_edited'),
                                    timeout: true
                                }))
                            })
                        }
                    }}
                    disableSecondaryIfNotDirty={true}
                />
            </div>
        )
    }
}