import Daily from '../cron-tab/daily';
import Hourly from '../cron-tab/hourly';
import Weekly from '../cron-tab/weekly';
import Monthly from '../cron-tab/monthly';
import Yearly from '../cron-tab/yearly';

export const HEADER = {
    MINUTES: 'MINUTES',
    HOURLY: 'HOURLY',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY'
};

const HEADER_VALUES = {
    MINUTES: 'Minutes',
    HOURLY: 'Hourly',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly'
};

// HEADER_VALUES.MINUTES,
const defaultTabs = [HEADER_VALUES.HOURLY, HEADER_VALUES.DAILY, HEADER_VALUES.WEEKLY, HEADER_VALUES.MONTHLY, HEADER_VALUES.YEARLY];

/*
{
    component: Minutes,
    initialCron: ['*', '*', '*', '*', '*']
},
 */
export const metadata = [{
    component: Hourly,
    initialCron: ['0', '*/1', '*', '*', '*']
}, {
    component: Daily,
    initialCron: ['0', '0', '*/1', '*', '*']
}, {
    component: Weekly,
    initialCron: ['0', '0', '*', '*', '*']
}, {
    component: Monthly,
    initialCron: ['0', '0', '1', '*', '*'],
}, {
    component: Yearly,
    initialCron: ['0', '0', '1', '1', '*']
}];

const validateHeaders = (headers) => {
    const validatedHeaders = [];
    headers.forEach(header => {
        if (!HEADER_VALUES[header]) {
            throw new Error('Invalid header ' + header);
            // Avoid duplicates
        } else if (validatedHeaders.indexOf(HEADER_VALUES[header]) === -1) {
            validatedHeaders.push(HEADER_VALUES[header]);
        }
    });
    return validatedHeaders;
}

/**
 * Validate and load-view headers
 * @param {*} options
 */
export const loadHeaders = (options) => {
    if (options) {
        if (options.headers) {
            if (!options.headers.length) {
                throw new Error('At least one header is required.');
            }
            return validateHeaders(options.headers);
        }
    }
    return defaultTabs;
};
