export function getIntegrationResource(data) {
    return {
        type: 'GET_INTEGRATION_RESOURCE',
        data: data
    };
}

export function doneGetIntegrationResource(data) {
    return {
        type: 'DONE_GET_INTEGRATION_RESOURCE',
        data: data
    };
}

export function errorGetIntegrationResource(data) {
    return {
        type: 'ERROR_GET_INTEGRATION_RESOURCE',
        data: data
    };
}

export function createIntegrationResource(data) {
    return {
        type: 'CREATE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function doneCreateIntegrationResource(data) {
    return {
        type: 'DONE_CREATE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function errorCreateIntegrationResource(data) {
    return {
        type: 'ERROR_CREATE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function updateIntegrationResource(data) {
    return {
        type: 'UPDATE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function doneUpdateIntegrationResource(data) {
    return {
        type: 'DONE_UPDATE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function errorUpdateIntegrationResource(data) {
    return {
        type: 'ERROR_UPDATE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function deleteIntegrationResource(data) {
    return {
        type: 'DELETE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function doneDeleteIntegrationResource(data) {
    return {
        type: 'DONE_DELETE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function errorDeleteIntegrationResource(data) {
    return {
        type: 'ERROR_DELETE_INTEGRATION_RESOURCE',
        data: data
    };
}

export function resetIntegrationResource() {
    return {
        type: 'RESET_INTEGRATION_RESOURCE',
        data: []
    }
}

export function resetCreateUpdateIntegrationResource() {
    return {
        type: 'RESET_CREATE_UPDATE_INTEGRATION_RESOURCE',
        data: []
    }
}
