import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import {CheckIcon} from '@heroicons/react/24/outline'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {ApprovalStatusID, CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import Button from '../../../common/components/button'
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

class PurchaseOrder extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LegalName',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'purchase_orders_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    archiveResource = (item) => {

        this.setState({text: this.props.translate('message.confirm_archive_generic') + ` ${item.LegalName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {id: item[this.getPrimaryKey()]}),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.LegalName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.LegalName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item[this.getPrimaryKey()],
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Purchase Order ${item.LegalName} restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleApprovePurchaseOrder = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_approve')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item[this.getPrimaryKey()],
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Purchase Order approve`,
                        query: this.getQuery(),
                        resource: Resources.AccountingPurchaseOrderApprove,
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleRejectPurchaseOrder = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_reject')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item[this.getPrimaryKey()],
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Purchase Order rejected`,
                        query: this.getQuery(),
                        resource: Resources.AccountingPurchaseOrderReject,
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }
    handleToggleConfirmDialog = (submit, item) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: true
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleActionUpdate = (item = null) => {
        this.props.history.push(`/purchase-order/${item[this.getPrimaryKey()]}`)
    }

    handleCreateResource = () => {
        this.handleLinkClick('/purchase-order/create')
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    /** Fields
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            ApprovalStatusID: new Field('ApprovalStatusID', '', [''], false, 'select', {
                containerClass: 'col-md-3',
            }, {
                all: true
            }),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-3 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    getFields = () => {
        return {
            LegalName: new Field('LegalName', '', ['empty'], false, 'text'),
            PONumber: new Field('PONumber', '', ['empty']),
            Date: new Field('Date', '', ['empty'], false, 'date'),
            OfficeName: new Field('OfficeName', '', ['']),
            ApprovalStatusID: new Field('ApprovalStatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <>{ApprovalStatusID[item.ApprovalStatusID]}</>
                )
            }),
            Reference: new Field('Reference', '', ['']),
            IsCompleted: new Field('IsCompleted', '', [''], false, 'checkbox')
        }
    }

    getResource = () => {
        return Resources.AccountingPurchaseOrder
    }

    getPrimaryKey = () => {
        return 'PurchaseOrderID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.PurchaseOrder')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex mb-2">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <div className={'flex mr-4'}>
                                <Tippy content={translate('text.download_excel')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.downloadExcel}
                                    >
                                        <DocumentArrowDownIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>

                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>
                            <Button
                                hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                                className={`btn btn-primary`}
                                onClick={() => {
                                    this.handleCreateResource()
                                }}
                            >
                                {translate('btn.create_new')}
                            </Button>
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleActionUpdate : null}
                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleActionUpdate : null}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                            onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}

                            actions={[
                                {
                                    action: this.handleApprovePurchaseOrder,
                                    icon: CheckIcon, // make this a function
                                    visible: (item) => (item.ApprovalStatusID === 1 || item.ApprovalStatusID === 3),
                                    label: false, // make this a function
                                    title: translate('text.approve'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.handleRejectPurchaseOrder,
                                    icon: XMarkIcon, // make this a function
                                    visible: (item) => (item.ApprovalStatusID === 1 || item.ApprovalStatusID === 2),
                                    label: false, // make this a function
                                    title: translate('text.reject'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecords
                            show={(data.length === 0) && !resource.isLoading}
                            title={translate('text.no_records')}
                            addClass={'pt-16 pb-10'}
                        />
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.columnsModalOpen}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(PurchaseOrder)
