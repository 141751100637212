import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect'
import NotFound from "./not-found";
import PublicLayout from "../../../common/components/layout-public";

class NotFoundView extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.updateBrowserUrl();
    }

    updateBrowserUrl = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const redirectUrl = urlParams.get('url');

        if (redirectUrl) {
            window.history.pushState({}, '', redirectUrl)
        }
    }

    render() {
        const {translate} = this.props

        return (
            <PublicLayout {...this.props}>
                <div className="fixed inset-0 bg-tm-gray-200"/>

                <main className="h-screen m-0">
                    {!this.props.user.isLoading && !this.props.user.register && (
                        <NotFound
                            user={this.props.user}
                            translate={translate}
                        />
                    )}
                </main>
            </PublicLayout>
        )
    }
}

export default connect(state => state)(NotFoundView)