import {Field, FieldsManager} from "../../../../data/services/fields";
import moment from "moment-timezone";
import {DEFAULT_DATABASE_DATE_FORMAT, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../common/util/util-consts";
import {
    getDefaultContactGroup,
    getDefaultTableOptions,
    getDefaultUserOfficeValues,
    checkPerm,
    updateQueryFields
} from "../../../../common/util/util-helpers";
import ResourceTable from "../../../../common/components/resource-table";
import React, {useEffect, useRef, useState} from "react";
import LocalStorage from "../../../../common/util/localStorage";
import {clearDialogResource, createDialogResource, getDialogResource} from "../../../../data/actions/dialogResource";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import useQuery from "../../../../hooks/use-query";
import FieldsForm from "../../../../common/components/fields/fields-form";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import {formatMoney} from "../../../../common/util/util-formaters";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {LoaderSmall} from "../../../../common/components/loader";
import {READ_PERM} from "../../../../util/util-constants";

export default function SalesTaxReportModal({show, selectedItem, onClose, translate}) {
    const dispatch = useDispatch();
    const prevCreateIDRef = useRef();
    const [isCreateTabBillDialogOpen, setIsCreateTabBillDialogOpen] = useState(false);

    const [queryFields, setQueryFields] = useQuery(getQueryFields(selectedItem), "");
    let query = FieldsManager.getFieldKeyValues(queryFields);

    const resource = useSelector(state => state.dialogResource);
    const isLoading = resource.isLoading;
    let data = [];
    if (resource?.data) {
        data.push(resource?.data);
    }

    const taxName = resource?.data?.Tax?.Tax;
    const taxValue = resource?.data?.CurrentAccountValue > 0 ? resource?.data?.CurrentAccountValue : 0;
    let period = resource?.data?.Tax?.CreateUpdateDate;

    if (period) {
        period = period.split(" ")[0];
    }

    function fetchData() {
        dispatch(getDialogResource(
            {
                user: LocalStorage.get("user"),
                resource: Resources.AccountingTaxesReport,
                query: query
            }
        ))
    }

    function handleQueryChange(name, value) {
        setQueryFields(updateQueryFields(queryFields, name, value))
    }

    function getFields() {
        return {
            GrossSales: new Field('GrossSales', '', [''], false, 'money', {omitSort: true}),
            NonTaxableSales: new Field('NonTaxableSales', '', [''], false, 'money', {omitSort: true}),
            TaxableSales: new Field('TaxableSales', '', [''], false, 'money', {omitSort: true}),
            TaxAmount: new Field('TaxAmount', '', [''], false, 'money', {omitSort: true})
        }
    }

    function getTabBillFields() {
        return {
            OrganizationID: new Field('OrganizationID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}),
            ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}),
            Date: new Field('Date', moment().format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date-new', {addContainerClass: "col-span-5"}),
            Amount: new Field('Amount', taxValue, ['empty'], false, 'money', {addContainerClass: "col-span-7"}),
        }
    }

    function getQueryFields(item) {
        const StartDateValue = moment().startOf('month').format(DEFAULT_DATABASE_DATE_FORMAT);
        const EndDateValue = moment().endOf('month').format(DEFAULT_DATABASE_DATE_FORMAT);

        return {
            StartDate: new Field('StartDate', StartDateValue, [''], false, 'date-new', {
                labelType: "float",
                addContainerClass: "col-span-2"
            }),
            EndDate: new Field('EndDate', EndDateValue, [''], false, 'date-new', {
                labelType: "float",
                addContainerClass: "col-span-2"
            }),
            TaxID: new Field('TaxID', item?.TaxID, [''], false, 'hidden'),
        }
    }

    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show, queryFields]);

    useEffect(() => {
        if (!!resource?.create?.id && resource.create.id !== prevCreateIDRef.current) {
            dispatch(clearDialogResource());
            onClose();
        }
    }, [resource]);

    return (
        <>
            <div className="">
                <div className="grid grid-cols-12 gap-4 px-6 pt-6">
                    <FieldsForm
                        fieldsState={queryFields}
                        onChange={handleQueryChange}
                        translate={translate}
                    />
                </div>

                <div
                    className="flex items-center gap-2 text-base text-tm-gray-600 border-b border-tm-gray-300 pb-4 mt-4 px-6">
                    Current tax account value: {isLoading ? <LoaderSmall disableContainer={true}/> : <span
                    className="text-tm-gray-700 font-bold">${formatMoney(resource?.data?.CurrentAccountValue)}</span>}
                </div>


                <ResourceTable
                    data={data}
                    fields={getFields(selectedItem)}

                    translate={translate}
                    isLoading={resource.isLoading}

                    options={getDefaultTableOptions(getFields(), {}, '', translate)}

                    queryFields={queryFields}
                    onSortChange={(colName) => handleQueryChange('sortBy', colName)}
                />
            </div>

            <ModalFooter
                secondaryButtonLabel={"Create tax bill"}
                onSecondaryButtonClick={() => setIsCreateTabBillDialogOpen(true)}
                secondaryButtonHasPerm={checkPerm(Resources.AccountingTaxesReport, READ_PERM)}
                closeButtonLabel={'Cancel'}
                onClose={onClose}
            />

            <ModalSaveResource
                title={"Create tax bill: " . taxName ?? ""}
                widthClass="max-w-sm"
                gridColsClass="grid-cols-12"
                show={isCreateTabBillDialogOpen}
                onClose={() => setIsCreateTabBillDialogOpen(false)}
                fields={getTabBillFields()}
                isLoading={isLoading}
                onSubmit={(params) => {
                    if (params) {
                        params = Object.assign(params, {
                            "BookDate": params.Date,
                            "DueDate": params.Date,
                            "InternalNotes": "",
                            "ExternalNotes": "",
                            "RefNumber": `Sales tax (${taxName}) (${period})`,
                            "Items": [
                                {
                                    "Description": `Sales tax (${taxName}) (${period})`,
                                    "AccountID": selectedItem.AccountID,
                                    "Amount": params.Amount,
                                    "Qty": "1",
                                    "TotalAmount": params.Amount
                                }
                            ],
                            "Amount": params.Amount
                        })

                        dispatch(createDialogResource({
                            user: LocalStorage.get('user'),
                            query: query,
                            params: params,
                            resource: Resources.ExpenseBill,
                            piggyResource: Resources.AccountingTaxesReport,
                            errorMessage: true,
                            successMessage: 'Tax bill created.'
                        }))
                    }
                }}
                translate={translate}
                metadata={{
                    OrganizationID: {
                        api: 'api/' + Resources.OrganizationsQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.OrganizationID,
                            label: item.LegalName,
                            Organization: item
                        })
                    },
                    OfficeID: {
                        api: 'api/' + Resources.OfficesQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            label: item.OfficeName,
                            value: item.OfficeID
                        })
                    },
                    ContactGroupID: {
                        api: 'api/' + Resources.ContactGroupsQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            label: item.ContactGroupName,
                            value: item.ContactGroupID
                        })
                    },
                }}
            />
        </>
    )
}

