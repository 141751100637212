import React, {useEffect, useRef, useState} from 'react'
import {CameraIcon, ArrowPathIcon, ArrowUpTrayIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ModalFooter from "../../modal/modal-footer";
import InfoParagraph from "../../info-paragraph";
import Modal from "../../modal";

const CameraImageDialog = ({translate, onSelectFile, onClose}) => {
    const videoRef = useRef(null)
    const photoRef = useRef(null)

    const [hasPhoto, setHasPhoto] = useState(false)
    const [videoErrorMessage, setVideoErrorMessage] = useState(false)

    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: {width: 1920, height: 1920}
            })
            .then(stream => {
                let video = videoRef.current
                video.srcObject = stream
                video.play()
            })
            .catch(err => {
                setVideoErrorMessage(true)
                console.error(err)
            })
    }

    const closeStream = () => {
        let video = document.getElementById('video');
        let stream = video.srcObject;
        let tracks = stream.getTracks();

        tracks.forEach(function (track) {
            track.stop();
        });

        video.srcObject = null;
    }

    const submit = () => {
        photoRef.current.toBlob(blob => onSelectFile([blob]), "image/jpeg", 1);
        close()
    }

    const close = () => {
        closeStream()
        onClose()
    }

    const onTakeAnother = () => {
        setHasPhoto(false)
    }

    const takePhoto = () => {
        const scaleFactor = 1;

        const video = document.getElementById("video");
        const w = video.videoWidth * scaleFactor;
        const h = video.videoHeight * scaleFactor;
        const canvas = document.getElementById('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, w, h);
        setHasPhoto(true)
    }

    useEffect(() => {
        getVideo()
    }, [videoRef])

    return (
        <Modal
            show={true}
            onClose={close}
            widthClass={"w-auto"}
        >
            {videoErrorMessage && (
                <div>
                    <header
                        className="p-4 flex justify-between items-center border-tm-gray-200 border-b text-tm-gray-900">
                        <h2 className="text-lg text-current truncate">{translate("text.Error")}</h2>
                        <button className="focus:ring-2 focus:ring-offset-2 focus:ring-primary rounded-full"
                                aria-label="Close"
                                onClick={onClose}>
                            <XMarkIcon className="w-5 h-5"/>
                        </button>
                    </header>

                    <div className="p-4">
                        <InfoParagraph
                            type={"danger"}
                        >
                            {translate("error.CAMERA_ERROR")}
                        </InfoParagraph>
                    </div>

                    <ModalFooter
                        closeButtonLabel={translate("btn.cancel")}
                        onClose={onClose}
                    />
                </div>
            )}
            {!videoErrorMessage && (
                <div>
                    <div className={`${!hasPhoto ? "hidden" : ""} `}>
                        <div className={'flex justify-center bg-black w-full'}>
                            <canvas id={"canvas"} style={{height: "70vh", width: "inherit", objectFit: "contain"}}
                                    ref={photoRef}/>
                        </div>
                        <div className={'flex justify-between p-3'}>
                            <button
                                className={`btn btn-primary`}
                                onClick={onTakeAnother}
                            >
                                <ArrowPathIcon className="h-4 w-4 mr-2"/>
                                {translate("text.Retake")}
                            </button>

                            <div className={'flex items-center'}>
                                <button
                                    className={`btn btn-primary`}
                                    onClick={submit}
                                >
                                    <ArrowUpTrayIcon className="h-4 w-4 mr-2"/>
                                    {translate("text.Continue")}
                                </button>

                                <button
                                    className="btn btn-outline ml-2"
                                    onClick={close}
                                >
                                    <XCircleIcon className="h-4 w-4 mr-2"/>
                                    {translate("text.Cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`${hasPhoto ? "hidden" : ""}`}>
                        <div className={'flex justify-center bg-black'}>
                            <video id={"video"} className={'w-full'} style={{height: "70vh"}} ref={videoRef}/>
                        </div>
                        <div className={'grid grid-cols-3 p-3'}>
                            <div/>
                            <div className="text-center">
                                <Tippy
                                    content={translate("btn.SnapPicture")}
                                >
                                    <button
                                        className="mx-1 bg-primary text-white p-2 rounded-full"
                                        onClick={takePhoto}
                                    >
                                        <CameraIcon className="h-5 w-5"/>
                                    </button>
                                </Tippy>
                            </div>
                            <div className={'text-right'}>
                                <button
                                    className="btn btn-outline"
                                    onClick={close}
                                >
                                    <XCircleIcon className="h-4 w-4 mr-2"/>
                                    {translate("text.Cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default CameraImageDialog
