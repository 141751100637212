import {useEffect, useRef} from "react";

export default function UpdateBreakpoint({onBreakpointChange}) {
    const screenBreakpointRef = useRef({});
function updateBreakpoint() {
    let breakPoints = {
        'sm': {
            index: 0,
            name: 'sm',
            width: 640,
        },
        'md': {
            index: 1,
            name: 'md',
            width: 768,
        },
        'lg': {
            index: 2,
            name: 'lg',
            width: 1024,
        },
        'xl': {
            index: 3,
            name: 'xl',
            width: 1280,
        },
        '2xl': {
            index: 4,
            name: '2xl',
            width: 1536,
        },
        '3xl': {
            index: 5,
            name: '3xl',
            width: 1856,
        }
    }
    // breakPoints.isMenuVisible = isMenuVisible;

    if (window.innerWidth < breakPoints.sm.width) {
        if (screenBreakpointRef.current?.name !== 'sm') {
            onBreakpointChange(breakPoints['sm'])
            screenBreakpointRef.current = breakPoints['sm'];
        }
    }

    if (window.innerWidth > breakPoints.sm.width && window.innerWidth <= breakPoints.md.width) {
        if (screenBreakpointRef.current?.name !== 'md') {
            onBreakpointChange(breakPoints['md'])
            screenBreakpointRef.current = breakPoints['md'];
        }
    }

    if (window.innerWidth > breakPoints.md.width && window.innerWidth <= breakPoints.lg.width) {
        if (screenBreakpointRef.current?.name !== 'lg') {
            onBreakpointChange(breakPoints['lg'])
            screenBreakpointRef.current = breakPoints['lg'];
        }
    }

    if (window.innerWidth > breakPoints.lg.width && window.innerWidth <= breakPoints.xl.width) {
        if (screenBreakpointRef.current?.name !== 'xl') {
            onBreakpointChange(breakPoints['xl'])
            screenBreakpointRef.current = breakPoints['xl'];
        }
    }

    if (window.innerWidth > breakPoints.xl.width && window.innerWidth <= breakPoints['2xl'].width) {
        if (screenBreakpointRef.current?.name !== '2xl') {
            onBreakpointChange(breakPoints['2xl'])
            screenBreakpointRef.current = breakPoints['2xl'];
        }
    }

    if (window.innerWidth > breakPoints['2xl'].width) {
        if (screenBreakpointRef.current?.name !== '3xl') {
            onBreakpointChange(breakPoints['3xl'])
            screenBreakpointRef.current = breakPoints['3xl'];
        }
    }
}

    useEffect(() => {
        updateBreakpoint();
        window.addEventListener('resize', updateBreakpoint);

        return () => {
            window.removeEventListener('resize', updateBreakpoint);
        }
    }, []);

return null;
}