import React from 'react'
import FieldSwitch from "../fields/field-switch";
import ClockIcon from "@heroicons/react/20/solid/ClockIcon";
import InfoParagraph from "../info-paragraph";
import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import moment from "moment-timezone";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getUserTimeFormat, isMilitaryTimeSet, getTimeFromServerDate} from "../../util/util-dates";
import {dirtyClone} from "../../util/util-vanilla";
import {WORKING_HOURS_CUSTOM_HOURS} from "../../../util/util-constants";
import {classNames} from "../../util/util-helpers";
import {getProp} from "../../util/util-helpers";
import FieldRadio from "../fields/field-radio";
import FieldTimeCustom from "../fields/field-time-custom";

export default class WorkingHours extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                CustomHours: new Field('CustomHours', getProp(this.props, "customHours", WORKING_HOURS_CUSTOM_HOURS), [''])
            },
            workingHours: !!this.props.workingHours.length ? this.props.workingHours.map((it) => {
                return {
                    day: it.Day,
                    Active: new Field('Active', it.Active, ['']),
                    FromTime: new Field('FromTime', getTimeFromServerDate(getProp(it, "FromTime", "")), [''], !!it.NonStop),
                    ToTime: new Field('ToTime', getTimeFromServerDate(getProp(it, "ToTime", "")), [''], !!it.NonStop),
                    NonStop: new Field('NonStop', it.NonStop, [''])
                }
            }) : this.getDays(),
            selectedDay: undefined,
            highlight: undefined
        };

        this.isMilitaryTimeSet = isMilitaryTimeSet();
    }

    componentDidMount() {
        this.saveToParent(true);
    }

    getDays = () => {
        let days = [
            this.props.translate('text.sunday'),
            this.props.translate('text.monday'),
            this.props.translate('text.tuesday'),
            this.props.translate('text.wednesday'),
            this.props.translate('text.thursday'),
            this.props.translate('text.friday'),
            this.props.translate('text.saturday')
        ];

        return days.map(day => ({
            day: day,
            Active: new Field('Active', 1, ['']),
            FromTime: new Field('FromTime', '', ['']),
            ToTime: new Field('ToTime', '', ['']),
            NonStop: new Field('NonStop', 0, [''])
        }))
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, this.saveToParent);
    };

    handleWorkingHoursChange = (name, value, i) => {
        let workingHours = dirtyClone(this.state.workingHours);
        workingHours[i] = FieldsManager.updateField(workingHours[i], name, value)

        if (name === 'NonStop') {
            if (value) {
                workingHours[i] = FieldsManager.disableFields(workingHours[i], ['FromTime', 'ToTime']);
                workingHours[i] = FieldsManager.updateField(workingHours[i], 'FromTime', getTimeFromServerDate("00:00:00"));
                workingHours[i] = FieldsManager.updateField(workingHours[i], 'ToTime', getTimeFromServerDate("00:00:00"));
            } else {
                workingHours[i] = FieldsManager.enableFields(workingHours[i], ['FromTime', 'ToTime'])
            }
        }

        let fields = this.state.fields;
        fields = FieldsManager.updateField(fields, "CustomHours", 2)

        this.setState({
            workingHours: workingHours,
            fields: fields,
        }, this.saveToParent);
    }

    handleCopyToAllClick = (i, excluding = []) => {
        let workingHours = dirtyClone(this.state.workingHours);

        let newWorkingHours = workingHours.map(day => {
            if (!excluding.includes(day.day)) {
                let dayField = dirtyClone(workingHours[i]);
                dayField.day = day.day;
                return dayField;
            }

            return day;
        })

        this.setState({
            workingHours: newWorkingHours,
            selectedDay: undefined,
            highlight: undefined
        }, this.saveToParent);
    }

    saveToParent = (initial = null) => {
        let dirty = this.props.dirty;

        if (!initial) {
            dirty = true;
        }

        const validatedForm = this.validateTimes();

        this.props.setParentState({
            [this.props.stateName]: validatedForm.map((it) => {
                return {
                    Day: it.day,
                    Active: it.Active.value,
                    NonStop: it.NonStop.value,
                    FromTime: it.FromTime.value,
                    ToTime: it.ToTime.value,
                    ToTimeErrorMessage: it.ToTime.errorMessage,
                    FromTimeErrorMessage: it.FromTime.errorMessage
                };
            }),
            ["Custom" + this.props.stateName]: this.state.fields.CustomHours.value,
            canSubmit: dirty
        });
    }

    handleDayClick = (i) => {
        if (i === this.state.selectedDay) {
            this.setState({selectedDay: undefined})
        }
    }

    setSelectedDay = (i) => {
        this.setState({
            selectedDay: i
        })
    }

    validateTimes = () => {
        this.state.workingHours.map(it => {
            let hasErrors = false;

            if (!!it.FromTime.value || !!it.ToTime.value) {
                if (!it.FromTime.value) {
                    hasErrors = true;
                    it.FromTime.errorMessage = "field_required";
                }

                if (!it.ToTime.value) {
                    hasErrors = true;
                    it.ToTime.errorMessage = "field_required";
                }
            }

            if (!!it.FromTime.value && !!it.ToTime.value && moment(it.FromTime.value, getUserTimeFormat()).isAfter(moment(it.ToTime.value, getUserTimeFormat()))) {
                hasErrors = true;
                it.FromTime.errorMessage = "from_is_after_to_time";
                it.ToTime.errorMessage = "from_is_after_to_time";
            }

            if (!hasErrors) {
                it.FromTime.errorMessage = "";
                it.ToTime.errorMessage = "";
            }

            return it;
        });

        return this.state.workingHours;
    }

    render() {
        const {translate} = this.props;
        const isCustomHours = Number(this.state.fields.CustomHours.value) === WORKING_HOURS_CUSTOM_HOURS;
        const buttonLabels = [this.props.translate('text.24/7'), this.props.translate('text.custom')]

        let isHoursFieldMissing = false;
        let isFromLaterThanToDate = false;

        this.state.workingHours.forEach(day => {
                if (day.FromTime.errorMessage === "field_required" || day.ToTime.errorMessage === "field_required") {
                    isHoursFieldMissing = true;
                }

                if (day.FromTime.errorMessage === "from_is_after_to_time") {
                    isFromLaterThanToDate = true;
                }
            }
        )

        return (
            <div className={this.props.className}>
                <div className="flex flex-col justify-center mb-6">

                    <div className="flex items-center justify-center">
                        <div
                            className={
                                classNames(
                                    "group p-0.5 rounded-md flex bg-tm-gray-300 hover:bg-tm-gray-200"
                                )
                            }
                        >
                            {
                                buttonLabels.map((button, i) => {
                                    const isActive = i + 1 === Number(this.state.fields.CustomHours.value);
                                    return (
                                        <button
                                            key={button}
                                            className={classNames(
                                                isActive
                                                    ? "bg-inverse text-primary p-1.5 lg:px-3 rounded-md flex items-center shadow-sm ring-1 ring-black ring-opacity-5 focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100 focus-visible:ring-opacity-50"
                                                    : "p-1.5 lg:px-3 rounded-md flex items-center text-tm-gray-700 focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100",
                                                "text-sm font-semibold"
                                            )}
                                            onClick={() => this.handleInputChange("CustomHours", i + 1)}
                                        >
                                            {button}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>

                <div className="max-w-4xl mx-auto space-y-4 text-tm-gray-900">
                    <React.Fragment>
                        <WorkingHoursHeader
                            translate={translate}
                            isCustomHours={isCustomHours}
                        />

                        {!isCustomHours && (
                            <WorkingHoursNonStop
                                workingHours={this.state.workingHours}
                                isMilitaryTimeSet={this.isMilitaryTimeSet}
                            />
                        )}

                        {
                            isCustomHours && !!this.state.fields.CustomHours.value && (
                                <WorkingHoursTable
                                    workingHours={this.state.workingHours}
                                    highlight={this.state.highlight}
                                    onWorkingHoursChange={this.handleWorkingHoursChange}
                                    selectedDay={this.state.selectedDay}
                                    onDayClick={this.handleDayClick}
                                    setSelectedDay={this.setSelectedDay}
                                    isMilitaryTimeSet={this.isMilitaryTimeSet}
                                />
                            )}

                    </React.Fragment>
                </div>

                {isCustomHours && (
                    <React.Fragment>
                        <div className="max-w-4xl mx-auto mt-4 space-y-4">
                            {isHoursFieldMissing && (
                                <InfoParagraph
                                    type="danger"
                                    message={translate("text.hour_field_is_required")}
                                />
                            )}

                            {isFromLaterThanToDate && (
                                <InfoParagraph
                                    type="danger"
                                    message={translate("text.to_date_should_be_later_than_from")}
                                />
                            )}

                            <InfoParagraph type="info">
                                <div className="flex">
                                    <div>
                                        <p className="text-sm font-bold">Copy selected day</p>
                                        {this.state.selectedDay === undefined && (
                                            <p>Select a day in the Copy column copy it to the whole week or working days
                                                only</p>
                                        )}

                                        {this.state.selectedDay !== undefined && (
                                            <p>Copy selected day to the whole week or working days only</p>
                                        )}
                                    </div>

                                    {this.state.selectedDay !== undefined && (
                                        <div className="space-x-4 ml-auto">
                                            <button
                                                onMouseEnter={() => this.setState({highlight: "all"})}
                                                onMouseLeave={() => this.setState({highlight: undefined})}
                                                onClick={() => this.handleCopyToAllClick(this.state.selectedDay)}
                                                className="btn-outline bg-inverse"
                                            >
                                                All days
                                            </button>

                                            <button
                                                className="btn-outline bg-inverse"
                                                onMouseEnter={() => this.setState({highlight: "workday"})}
                                                onMouseLeave={() => this.setState({highlight: undefined})}
                                                onClick={() => this.handleCopyToAllClick(this.state.selectedDay, [this.props.translate('text.saturday'), this.props.translate('text.sunday')])}
                                            >
                                                Working days
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </InfoParagraph>
                        </div>

                        <div className="max-w-4xl mx-auto mt-4">
                            <InfoParagraph type="note">
                                <div className="flex">
                                    {this.props.stateName === "ReceivingHours" && (
                                        <div>
                                            <p className="text-sm font-bold">Copy all data to shipping hours</p>
                                            <p>By clicking on this button you will copy the whole table to shipping
                                                hours</p>
                                        </div>
                                    )}

                                    {this.props.stateName !== "ReceivingHours" && (
                                        <div>
                                            <p className="text-sm font-bold">Copy all data to receiving hours</p>
                                            <p>By clicking on this button you will copy the whole table to receiving
                                                hours</p>
                                        </div>
                                    )}

                                    <button
                                        className="btn-outline bg-inverse ml-auto"
                                        onClick={() => this.props.onCopyTabSettings(this.props.stateName)}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </InfoParagraph>
                        </div>
                    </React.Fragment>
                )}

                {!isCustomHours && (
                    <div className="max-w-4xl mx-auto mt-4">
                        <InfoParagraph type="info">
                            <div className="flex">
                                <div>
                                    <p className="text-sm font-bold">Editing disabled</p>
                                    <p>Select custom hours menu at the top of the dialog to enable editing</p>
                                </div>
                            </div>
                        </InfoParagraph>
                    </div>
                )}
            </div>
        )
    }
}

const WorkingHoursHeader = ({translate, isCustomHours}) => {
    return (
        <div
            className={"grid grid-cols-10 gap-4 px-6 "}
        >
            <div className="font-bold text-base col-span-2">
                {translate("text.days")}
            </div>

            <div className="font-bold text-base">
                {translate("text.active")}
            </div>

            <div className="font-bold text-base col-span-2">
                {translate("text.from")}
            </div>

            <div className="font-bold text-base col-span-2 capitalize">
                {translate("text.to")}
            </div>

            <div className="font-bold text-base col-span-2">
                {translate("text.non-stop")}
            </div>

            <div className="font-bold text-base flex items-center">
                {isCustomHours && translate("text.copy")}
            </div>
        </div>
    )
}

const WorkingHoursTable = ({
                               workingHours,
                               highlight,
                               onWorkingHoursChange,
                               onDayClick,
                               selectedDay,
                               setSelectedDay,
                               isMilitaryTimeSet
                           }) => {
    return (
        workingHours.map((day, i) => {
            const isActiveDay = !!workingHours[i]?.Active.value;
            return (
                <div
                    key={day.day}
                    className={classNames(
                        "grid grid-cols-10 gap-4 rounded-lg border shadow border-tm-gray-300 px-6 py-2",
                        highlight === "workday" && !!i && i < 6 ? "bg-sky-600/10" : "",
                        highlight === "all" ? "bg-sky-600/10" : "",
                        highlight === undefined && isActiveDay ? "bg-inverse" : undefined,
                        highlight === undefined && !isActiveDay ? "bg-stripes" : undefined,
                    )}
                >
                    <div className="flex font-bold text-base text-tm-gray-700 items-center col-span-2">
                        {day.day}
                    </div>

                    <div className="flex items-center">
                        <FieldSwitch
                            key={"switch-" + day.day}
                            addClass={isActiveDay ? "" : "border border-tm-gray-300"}
                            value={isActiveDay}
                            name="Active"
                            onChange={(name, value) => {
                                onWorkingHoursChange(name, value, i)
                            }}
                        />
                    </div>

                    <div className="col-span-2">
                        <FieldTimeCustom
                            isMilitaryTimeFormat={isMilitaryTimeSet}
                            addClass={"form-control text-center form-control-time"}
                            onChange={(name, value) => onWorkingHoursChange(name, value, i)}
                            {...workingHours[i].FromTime}
                            disabled={!isActiveDay || workingHours[i]?.NonStop?.value}
                        />
                    </div>

                    <div className="col-span-2">
                        <FieldTimeCustom
                            isMilitaryTimeFormat={isMilitaryTimeSet}
                            addClass={"form-control text-center form-control-time"}
                            onChange={(name, value) => onWorkingHoursChange(name, value, i)}
                            {...workingHours[i].ToTime}
                            disabled={!isActiveDay || workingHours[i]?.NonStop?.value}
                        />
                    </div>

                    <div className="col-span-2">
                        <button
                            id={"non-stop-" + i}
                            onClick={() => onWorkingHoursChange("NonStop", !workingHours[i]?.NonStop?.value ? 1 : 0, i)}
                            className={classNames(
                                "btn-icon",
                                workingHours[i]?.NonStop?.value && isActiveDay ? "text-green-600 bg-green-600/10" : ""
                            )}
                        >
                            <ClockIcon className="w-5 h-5"/>
                        </button>
                    </div>

                    <div className="flex items-center">
                        <FieldRadio
                            className="text-primary w-6 h-6 cursor-pointer bg-inverse checked:bg-primary focus:ring-offset-inverse"
                            name="radio-day"
                            onChange={() => setSelectedDay(i)}
                            onClick={() => onDayClick(i)}
                            value={selectedDay === i}
                        />
                    </div>
                </div>
            )
        })
    )
}

const WorkingHoursNonStop = ({workingHours, isMilitaryTimeSet}) => {
    return (
        workingHours.map((day, i) => {
            return (
                <div
                    key={day.day}
                    className={classNames(
                        "grid grid-cols-10 gap-4 rounded-lg border shadow border-tm-gray-300 px-6 py-2 bg-inverse"
                    )}
                >
                    <div
                        className="flex font-bold text-base text-tm-gray-700 items-center col-span-2">
                        {day.day}
                    </div>

                    <div className="flex items-center">
                        <div className="btn-icon text-green-600 bg-green-600/10">
                            <CheckCircleIcon className="w-5 h-5"/>
                        </div>
                    </div>

                    <div className="col-span-2">
                        <FieldTimeCustom
                            isMilitaryTimeFormat={isMilitaryTimeSet}
                            addClass={"form-control text-center form-control-time"}
                            value="00:00"
                            disabled={true}
                        />
                    </div>

                    <div className="col-span-2">
                        <FieldTimeCustom
                            isMilitaryTimeFormat={isMilitaryTimeSet}
                            addClass={"form-control text-center form-control-time"}
                            value="00:00"
                            disabled={true}
                        />
                    </div>

                    <div className="col-span-2">
                        <button
                            id={"non-stop-" + i}
                            className={classNames(
                                "btn-icon text-green-600 bg-green-600/10",
                            )}
                        >
                            <ClockIcon className="w-5 h-5"/>
                        </button>
                    </div>

                    <div className="flex items-center">

                    </div>
                </div>
            )
        })
    )
}