import {all, call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateResource,
    doneDeleteResource,
    doneGetResource,
    doneGetResourceDetails,
    doneUpdateResource,
    errorCreateResource,
    errorDeleteResource,
    errorGetResource,
    errorGetResourceDetails,
    errorUpdateResource
} from "../actions/resource";
import {doneGetSecondResource} from "../actions/secondResource";
import {pushNotification} from "../actions/ui";
import {
    doneCountTaskResource,
    doneGetTaskResource,
    doneGrabTaskResource,
    errorCountTaskResource,
    errorGrabTaskResource
} from "../actions/taskResource";
import {doneGetHistoryResource, errorGetHistoryResource} from "../actions/historyResource";
import {doneGetInfoResource} from "../actions/infoResource";
import Resources from "../services/resources";
import LocalStorage from "../../util/localStorage";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                if (action.data.successMessage) {
                    yield put(pushNotification({
                        title: action.data.successMessage === true ? result.data : action.data.successMessage,
                        timeout: action.data.timeout ?? true
                    }));
                }
            }
        } else {
            yield put(doneGetResource(result.data));
        }
    } else {
        yield put(errorGetResource(result));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchGetResource() {
    yield takeLatest('GET_RESOURCE', getResourceCall);
}

export function* watchGetResourceSilent() {
    yield takeLatest('GET_RESOURCE_SILENT', getResourceCall);
}

export function* grabTaskResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result;
    if (action.data.request === 'POST') {
        result = yield call(Api.postResource, user, action.data.query, action.data.resource);
    } else {
        result = yield call(Api.getResource, user, action.data.query, action.data.resource);
    }

    if (result && result.status === 0) {
        yield put(doneGrabTaskResource(result.data));

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorGrabTaskResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchGrabTaskResource() {
    yield takeLatest('GRAB_TASK_RESOURCE', grabTaskResourceCall);
}

export function* countTaskResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result;
    if (action.data.request === 'POST') {
        result = yield call(Api.postResource, user, action.data.query, action.data.resource);
    } else {
        result = yield call(Api.getResource, user, action.data.query, action.data.resource);
    }

    if (result && result.status === 0) {
        yield put(doneCountTaskResource(result.data));
    } else {
        yield put(errorCountTaskResource(result.data));
    }
}

export function* watchCountTaskResource() {
    yield takeLatest('COUNT_TASK_RESOURCE', countTaskResourceCall);
}

export function* getResourceDetailsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResourceDetails(result.data));
    } else {
        yield put(errorGetResourceDetails(result.data));
    }
}

export function* watchGetResourceDetails() {
    yield takeLatest('GET_RESOURCE_DETAILS', getResourceDetailsCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.showRedirectDialog) {
            result.data.showRedirectDialog = action.data.showRedirectDialog
        } else {
            result.data.showRedirectDialog = false;
        }

        if (action.data.refreshLocalStorageLookups) {
            const lookups = yield call(Api.getResource, user, {}, Resources.Lookup);
            if (lookups && lookups.status === 0) {
                LocalStorage.set('lookup', lookups.data)
            }
        }

        if (action.data.file && action.data.file.length > 1) {
            const id = getIdForImageResource(action, result);
            yield call(
                Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams
                        ? action.data.fileParams
                        : action.data.params,
                    action.data.customID
                        ? {[action.data.customID]: id}
                        : {id: id}),
                {},
                action.data.customID);
        } else if (action.data.file && action.data.file[0]) {
            const id = getIdForImageResource(action, result);
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, action.data.customID ? {[action.data.customID]: id} : {id: id}))
        }

        if (action.data.document && action.data.document.length > 1) {
            const id = getIdForDocumentResource(action, result);
            yield call(Api.uploadDocuments,
                user,
                id,
                action.data.document,
                null,
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource,
                null,
                action.data.customID
            );
        } else if (action.data.document && action.data.document[0]) {
            const id = getIdForDocumentResource(action, result);
            yield call(Api.uploadDocument,
                user,
                null,
                action.data.document[0],
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource,
                id,
                action.data.customID
            );
        }

        if (action.data.onSuccessCallback && result) {
            action.data.onSuccessCallback(result)
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        }
        if (action.data.taskPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.taskPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
            }
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
         yield put(errorCreateResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateResource() {
    yield takeLatest('CREATE_RESOURCE', createResourceCall);
}

export function* updateMultipleResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    // TODO proper validate
    if (!Array.isArray(action.data.resource) || !Array.isArray(action.data.params)) {
        yield put(errorUpdateResource(result.data));

        yield put(pushNotification({
            title: "API_ERROR",
            result: 0,
            action: action,
        }));
    } else {
        result = yield all(action.data.resource.map((p, i) => {
            return call(
                Api.updateResource, user, action.data.params[i], p
            )
        }));

        if (!!result && !result.find(it => it.status !== 0)) {
            const resultData = result;
            if (action.data.piggyResource) {
                const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
                if (action.data.onPiggyCallback && resPiggy) {
                    action.data.onPiggyCallback(resPiggy)
                }
                if (resPiggy && resPiggy.status === 0) {
                    yield put(doneGetResource(resPiggy.data));
                    yield put(doneUpdateResource(resultData));
                }
            } else {
                yield put(doneUpdateResource(resultData));
            }
            if (!!action.data.successMessage) {
                yield put(pushNotification({
                    title: action.data.successMessage,
                    result: resultData,
                    action: action,
                    timeout: action.data.timeout ?? true
                }));
            }
        } else {

            yield put(errorUpdateResource(result));

            const errorResult = result.find(it => it.status !== 0);
            // TODO Push more notifications
            if (!!action.data.errorMessage) {
                yield put(pushNotification({
                    title: action.data.errorMessage === true ? errorResult.data : action.data.errorMessage,
                    result: errorResult,
                    action: action,
                }));
            }
        }
    }
}

export function* watchUpdateMultipleResource() {
    yield takeLatest('UPDATE_MULTIPLE_RESOURCE', updateMultipleResourceCall);
}

export function* updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.document && action.data.document.length > 1) {
            const id = getIdForDocumentResource(action, result);
            yield call(Api.uploadDocuments,
                user,
                id,
                action.data.document,
                null,
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource
            );
        } else if (action.data.document && action.data.document[0]) {
            const id = getIdForDocumentResource(action, result);
            yield call(Api.uploadDocument,
                user,
                null,
                action.data.document[0],
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource,
                id
            );
        }

        if (action.data.refreshLocalStorageLookups) {
            const lookups = yield call(Api.getResource, user, {}, Resources.Lookup);
            if (lookups && lookups.status === 0) {
                LocalStorage.set('lookup', lookups.data)
            }
        }

        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.onSuccessCallback && result) {
            action.data.onSuccessCallback(result)
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.taskPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.taskPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
            }
        }
        if (action.data.infoPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.infoQuery ? action.data.infoQuery : action.data.query, action.data.infoPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
            }
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                result: result,
                action: action,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateResource() {
    yield takeLatest('UPDATE_RESOURCE', updateResourceCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.refreshLocalStorageLookups) {
            const lookups = yield call(Api.getResource, user, {}, Resources.Lookup);
            if (lookups && lookups.status === 0) {
                LocalStorage.set('lookup', lookups.data)
            }
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDeleteResource(result.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondQuery, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.taskPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.taskQuery ? action.data.taskQuery : action.data.query, action.data.taskPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
            }
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchDeleteResource() {
    yield takeLatest('DELETE_RESOURCE', deleteResourceContactCall);
}

export function* getHistoryResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetHistoryResource(result.data));
    } else {
        yield put(errorGetHistoryResource(result.data));
    }
}

export function* watchGetHistoryResource() {
    yield takeLatest('GET_HISTORY_RESOURCE', getHistoryResourceCall);
}

function getIdForDocumentResource(action, result) {
    let id
    if (action.data.resource === "drivers") {
        id = result.data.DriverID
    } else if (action.data.resource === "carriers" || action.data.resource === "customers" || action.data.resource === "vendors") {
        id = result.data.OrganizationID
    } else if (action.data.resource === "employees") {
        id = result.data.EmployeeID
    } else {
        id = result.data.id
    }
    return id;
}

function getIdForImageResource(action, result) {
    let id
    if (action.data.resource === "drivers" || action.data.resource === "employees" || action.data.resource === "agents") {
        id = result.data.ContactID
    } else {
        id = result.data.id
    }
    return id;
}
