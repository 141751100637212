import {checkUserHelper} from "../services/api-util";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateIntegrationResource,
    doneDeleteIntegrationResource,
    doneGetIntegrationResource,
    doneUpdateIntegrationResource,
    errorCreateIntegrationResource,
    errorDeleteIntegrationResource,
    errorGetIntegrationResource,
    errorUpdateIntegrationResource
} from "../actions/integrationResource";
import {doneUpdateResource} from "../actions/resource";
import {pushNotification} from "../actions/ui";
import Resources from "../services/resources";

export function* getIntegrationResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetIntegrationResource(result.data));
    } else {
        yield put(errorGetIntegrationResource(result.data));
    }
}

export function* watchGetIntegrationResource() {
    yield takeLatest('GET_INTEGRATION_RESOURCE', getIntegrationResourceCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetIntegrationResource(resPiggy.data));
                yield put(doneCreateIntegrationResource(result.data));
            }
        } else {
            yield put(doneCreateIntegrationResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateIntegrationResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchCreateIntegrationResource() {
    yield takeLatest('CREATE_INTEGRATION_RESOURCE', createResourceCall);
}

export function* updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetIntegrationResource(resPiggy.data));
                yield put(doneUpdateIntegrationResource(result.data));
                if (action.data.piggyResource === Resources.Company || action.data.piggyResource === Resources.User) {
                    window.location.reload()
                }
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateIntegrationResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchUpdateIntegrationResource() {
    yield takeLatest('UPDATE_INTEGRATION_RESOURCE', updateResourceCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetIntegrationResource(resPiggy.data));
                yield put(doneDeleteIntegrationResource(result.data));
            }
        } else {
            yield put(doneDeleteIntegrationResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteIntegrationResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDeleteIntegrationResource() {
    yield takeLatest('DELETE_INTEGRATION_RESOURCE', deleteResourceContactCall);
}

