import React, { useEffect, useState } from "react";
import Loader from "../../loader/loader";
import {useSelector} from "react-redux";
import {
    clearContactResource,
    getContactResource,
    updateContactResource
} from "../../../../data/actions/contactResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {hideModal} from "../../../../data/actions/ui";
import {getProp} from "../../../util/util-helpers";
import ModalHeader from "../modal-header";
import ContactCard from "./contact-card";
import ModalFooter from "../modal-footer";
import Modal from "../index";
import ContactForm from "../../forms/contact-form";

// TODO: Add message button for opening chat

export default function ContactCardDialog(props) {
    const {translate, onClose} = props;
    const contactResource = useSelector(state => state.contactResource);


    const [isContactFromVisible, setIsContactFromVisible] = useState(false)

    useEffect(() => {
        props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.Contacts,
            query: {id: props.ContactID}
        }));

        const queryString = window.location.search;
        if (queryString === '?new-tab=true') {
            let url = window.location.href.split("?")[0];
            window.history.pushState({}, document.title, url);

            close();
        }

        return () => {
            props.dispatch(clearContactResource());
        };
    }, [])

    const userData = (getProp(contactResource, "data", {}));

    const close = () => {
        props.dispatch(hideModal());
    }

    const submitContactForm = (params, file) => {
        props.dispatch(updateContactResource({
            user: LocalStorage.get('user'),
            params: params,
            errorMessage: true, successMessage: translate('message.ContactUpdatedSuccessfully'),
            query: {id: props.ContactID},
            resource: Resources.Contacts,
            piggyResource: Resources.Contacts,
            file: !!file && [file],
            fileResource: Resources.ContactImage
        }))

        handleToggleContactForm()
    }

    const handleToggleContactForm = () => {
        setIsContactFromVisible(!isContactFromVisible);
    }

    return (
        <React.Fragment>
            <ModalHeader
                className="border-tm-gray-200 border-b text-tm-gray-900"
                title={translate("text.contact_info")}
                onClose={onClose}
            />

            {contactResource.isLoading && (
                <div className="text-center absolute position-absolute-center">
                    <Loader />
                </div>
            )}

            <ContactCard
                userData={userData}
                translate={translate}
                dispatch={props.dispatch}
                isLoading={contactResource.isLoading}
            />

            <ModalFooter
                buttonLabel={translate("btn.edit")}
                onButtonClick={handleToggleContactForm}

                closeButtonLabel={translate("btn.close")}
                onClose={onClose}
            />

            <Modal
                show={isContactFromVisible}
                widthClass={'max-w-3xl'}
                onClose={handleToggleContactForm}
            >
                <ContactForm
                    data={userData}
                    isLoading={false}
                    onClose={handleToggleContactForm}
                    errorMessages={[]}
                    onSubmit={submitContactForm}
                    translate={translate}
                />
            </Modal>
        </React.Fragment>
    )
}
