import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {ArrowPathIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import {enumerateDaysBetweenDates} from '../../../common/util/util-dates'
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from '../../../common/util/util-consts'
import CheckIcon from '@heroicons/react/24/outline/CheckIcon'
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import InfoParagraph from "../../../common/components/info-paragraph";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class DriverVacationsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            createDialog: false,
            updateDialog: false,
            editDialog: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        !!this.getID() && this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    addNewVacation = (fields) => {
        if (this.getID()) {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                    DriverID: this.getID()
                }),
                query: this.getQuery(),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true, successMessage: this.props.translate('text.vacation_created'),
            }))
        } else {
            this.props.addNewVacation(fields)
        }
    }

    handleToggleUpdateDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            updateDialog: !this.state.updateDialog
        })
    }

    handleToggleEditDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            editDialog: !this.state.editDialog
        })
    }

    handleToggleCreateDialog = () => {
        this.setState({
            createDialog: !this.state.createDialog
        })
    }

    handleToggleConfirmDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmDialog: !this.state.confirmDialog
        })
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.match.params.id
    }

    getQuery = () => {
        return {
            id: this.getID()
        }
    }

    getUpdateFields = (item = null) => {
        let fieldTemplates = {
            VacationStatusID: new Field('VacationStatusID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getEditFields = (item = null) => {
        const excludedDates = this.getExcludedDates(getProp(this.props, 'resource.data.list', []))

        let fieldTemplates = {
            VacationID: new Field('VacationID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            StartVacation: new Field('StartVacation', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}, {
                excludeDates: excludedDates
            }),
            EndVacation: new Field('EndVacation', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}, {
                excludeDates: excludedDates
            }),
            VacationTimezone: new Field('VacationTimezone', 'UTC', ['empty'], false, 'hidden', {
                hideTable: true
            }),
            VacationStatusID: new Field('VacationStatusID', '', [], false, 'select', {
                addContainerClass: 'col-span-full',
                hideTable: !this.getID()
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getCreateFields = () => {
        const excludedDates = this.getExcludedDates(getProp(this.props, 'resource.data.list', []))

        return {
            VacationID: new Field('VacationID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            StartVacation: new Field('StartVacation', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}, {
                excludeDates: excludedDates
            }),
            EndVacation: new Field('EndVacation', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}, {
                excludeDates: excludedDates
            }),
            VacationTimezone: new Field('VacationTimezone', 'UTC', ['empty'], false, 'hidden', {
                hideDialog: true,
                hideTable: true
            }),
            VacationStatus: new Field('VacationStatus', '', [], false, 'text', {
                hideDialog: true,
                hideTable: !this.getID()
            }),
            RequestCreatedDateTime: new Field('RequestCreatedDateTime', '', [], false, 'datetime', {
                hideDialog: true,
                hideTable: !this.getID()
            }),
            StatusUpdateByID: new Field('StatusUpdateByID', '', [], false, 'select-search', {
                hideDialog: true,
                hideTable: !this.getID()
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
    }

    getFormatedPropValues = () => {
        const Vacation = getLookup('Vacation')
        return this.props.fields_vacations.map((it, index) => {
            return {
                Vacation: Vacation[it.VacationID.value],
                VacationID: it.VacationID.value,
                StartVacation: it.StartVacation.value,
                EndVacation: it.EndVacation.value,
                Notes: it.Notes.value,
                id: index
            }
        })
    }

    getResource = () => {
        return Resources.DriverVacations
    }

    getExcludedDates = (data) => {
        return data.reduce((memo, it) => {
            if (it.VacationStatusID !== 3) {
                return memo.concat(enumerateDaysBetweenDates(it.StartVacation, it.EndVacation))
            }
            return memo
        }, [])
    }

    render() {
        const {translate, resource} = this.props

        const data = !!this.getID() ? getProp(resource, 'data.list', []) : this.getFormatedPropValues()
        const count = getProp(this.props, 'resource.data.count', 0)

        return (
            <React.Fragment>
                <PageHeader
                    title={translate('text.Vacations')}
                    titleClass="mr-5 text-2xl ml-4 mb-3"
                    buttonLabel={translate('btn.create_new')}
                    onButtonClick={() => this.handleToggleCreateDialog()}
                    hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                >
                    <button className="btn btn-header ml-auto mr-2" onClick={this.fetchData}>
                        <ArrowPathIcon className="w-5 h-5"/>
                    </button>

                </PageHeader>

                <Card>
                    <InfoParagraph className="" message={translate("message.VacationTabMessage")}/>

                    <TableCard>
                        <ResourceTable
                            addClass="rounded-card"
                            data={data}
                            count={count}

                            commonTable={true}
                            tableKey='DriverVacationID'

                            fields={this.getCreateFields()}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}
                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleUpdateDialog : null}
                            actions={[
                                {
                                    tooltipText: () => translate('btn.edit'),
                                    action: this.handleToggleEditDialog,
                                    hidden: () => !this.getID() || !checkPerm(this.getResource(), UPDATE_PERM),
                                    icon: () => PencilIcon
                                },
                                {
                                    tooltipText: () => translate('btn.edit_approval'),
                                    action: this.handleToggleUpdateDialog,
                                    hidden: () => !this.getID() || !checkPerm(this.getResource(), UPDATE_PERM),
                                    icon: () => CheckIcon
                                },
                                {
                                    tooltipText: () => translate("btn.delete"),
                                    action: (it) => this.handleToggleConfirmDialog(it),
                                    icon: () => TrashIcon,
                                    hidden: () => !checkPerm(this.getResource(), DELETE_PERM)
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate("text.no_records")}

                            text={translate("text.create_time_off")}
                            btnLabel={translate("btn_create_time_off")}
                            onBtnClick={() => {
                                this.handleToggleCreateDialog()
                            }}

                            clearFilterBtnLabel={translate("text.clear_all_filters")}
                            clearFilterText={translate("text.try_without_filters")}
                            filters={{}}
                        />
                    </TableCard>
                </Card>

                <ModalSaveResource
                    show={this.state.updateDialog}
                    title={this.props.translate('text.EditApproval')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={() => this.handleToggleUpdateDialog()}
                    fields={this.getUpdateFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params['DriverVacationID'] = this.state.selectedItem['DriverVacationID']
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: this.props.translate('text.DriverVacationStatusUpdated'),
                            }))

                            this.handleToggleUpdateDialog()
                        }
                    }}
                    translate={translate}
                />

                <ModalSaveResource
                    show={this.state.editDialog}
                    title={this.props.translate('text.Edit_time_off')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={() => this.handleToggleEditDialog()}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params['DriverVacationID'] = this.state.selectedItem['DriverVacationID']
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: this.props.translate('text.DriverVacationStatusUpdated'),
                            }))

                            this.handleToggleEditDialog()
                        }
                    }}
                    translate={translate}
                />

                <ModalSaveResource
                    show={this.state.createDialog}
                    title={this.props.translate('text.add_new_vacation')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateDialog}
                    fields={this.getCreateFields()}
                    getRawFields
                    onSubmit={(params) => {
                        if (params) {
                            this.addNewVacation(params)
                            this.handleToggleCreateDialog()
                        }
                    }}
                    translate={translate}
                    handleInputChange={(fields, name, value) => {
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={translate('message.confirm_delete_generic', [this.state.selectedItem?.Vacation])}
                    onClose={this.handleToggleConfirmDialog}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (!!this.getID()) {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                query: Object.assign({}, this.getQuery(), {
                                    DriverVacationID: this.state.selectedItem?.DriverVacationID,
                                }),
                                errorMessage: true, successMessage: this.props.translate('text.DriverVacationDeleted'),
                                resource: this.getResource(),
                                piggyResource: this.getResource()
                            }))
                            this.handleToggleConfirmDialog(false)
                        } else {
                            this.props.removeNewVacation(this.state.selectedItem['id'])
                            this.handleToggleConfirmDialog(false)
                        }
                    }}
                />
            </React.Fragment>
        )
    }
}
