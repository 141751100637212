import {ClipboardDocumentListIcon, TrashIcon} from "@heroicons/react/24/outline";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {showGlobalModal} from "../../../../data/actions/ui";
import {createResource, deleteResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../data/services/fields";
import FieldText from "../../fields/field-text";
import FieldTextarea from "../../fields/field-textarea";
import ModalFooter from "../modal-footer";
import ModalConfirm from "../modal-confirm";
import {fillFieldsFromData} from "../../../util/util-fields";
import {classNames} from "../../../util/util-helpers";


export default function PersonalNoteForm({translate, Id, data, onClose, isHome, query, isLoading}) {
    /** Constants
     ================================================================= */
    const isEditMode = !!Id;

    /** Store
     ================================================================= */
    const dispatch = useDispatch();

    /** State
     ================================================================= */
    const [areFieldsDirty, setAreFieldsDirty] = useState(false);
    const [fields, setFields] = useState(getFields(data));
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    /** Data Events
     ================================================================= */

    function handleCreateTask(note){
        dispatch(showGlobalModal('createTaskDialog', {TaskName: note.Title, Description: note.Description}));
    }

    function handleDeleteNote() {
        dispatch(deleteResource({
            user: LocalStorage.get("user"),
            query: {
                id: Id
            },
            errorMessage: true,
            successMessage: translate("text.note_deleted"),
            resource: Resources.NotesPersonal,
        }));

        setConfirmModalOpen(false);
    }

    function submit() {
        const validFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validFields)) {
            if (isEditMode) {
                dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                        "id": Id,
                    }),
                    errorMessage: true, successMessage: translate("text.note_updated"),
                    resource: Resources.NotesPersonal,
                    query: query,
                    piggyResource: Resources.NotesPersonal
                }));
            } else {
                dispatch(createResource({
                    user: LocalStorage.get("user"),
                    query: query,
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(fields)),
                    errorMessage: true, successMessage: translate("text.note_created"),
                    resource: Resources.NotesPersonal,
                    piggyResource: isHome ? Resources.NotesPersonal : false
                }));
            }

            setFields(getFields({}));

            if (onClose) {
                onClose();
            }
        } else {
            if (validFields.Title.errorMessage) {
                validFields.Title.errorMessage = [ "fields.errors.title_required" ]
            }

            setFields(Object.assign({}, validFields));
        }
    }

    /** UI Events
     ================================================================= */
    function handleInputChange(name, value) {
        fields[name].errorMessage = "";
        setFields(Object.assign({}, FieldsManager.updateField(fields, name, value)));
        setAreFieldsDirty(true);
    }

    /** Helper functions
     ================================================================= */
    function getFields(item = {}) {
        const fieldTemplates = {
            Title: new Field('Title', "", ['empty']),
            Description: new Field('Description', "", [''])
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    return (
        <>
            <div
                className={
                    classNames(
                        "relative px-3 py-0 m-3 rounded-card space-y-4 border-2 border-transparent focus-within:border-primary",
                        isLoading ? "bg-tm-gray-300" : "bg-input"
                    )
                }
            >
                <div className="relative">
                    <FieldText
                        value={fields.Title.value}
                        errorMessage={fields.Title.errorMessage}
                        name="Title"
                        addClass="block w-full border-none pt-2.5 text-lg font-medium placeholder:text-tm-gray-400 focus:outline-none bg-transparent bg-tm-gray-300"
                        onChange={handleInputChange}
                        disabled={isLoading}
                        translate={translate}
                        placeholder={"Title"}
                    />

                    <FieldTextarea
                        value={fields.Description.value}
                        errorMessage={fields.Description.errorMessage}
                        name="Description"
                        disabled={isLoading}
                        onChange={handleInputChange}
                        rows={6}
                        placeholder={"Write your personal note..."}
                        addClass={"block w-full resize-none border-0 p-0 text-tm-gray-700 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"}
                    />
                </div>
            </div>

            {isEditMode && (
                <div className="space-x-2 p-2">
                    <button
                        onClick={() => setConfirmModalOpen(true)}
                        className="group relative inline-flex items-center whitespace-nowrap rounded-full bg-tm-gray-100 px-2 py-2 text-sm font-medium text-tm-gray-500 hover:bg-red-600 sm:px-3"
                    >
                        <TrashIcon
                            className={classNames(
                                'text-tm-gray-400 group-hover:text-white',
                                'h-5 w-5 flex-shrink-0 sm:-ml-1'
                            )}
                            aria-hidden="true"
                        />
                        <span
                            className={classNames(
                                'group-hover:text-white text-tm-gray-900',
                                'hidden truncate sm:ml-2 sm:block'
                            )}
                        >
                      {translate("btn.delete")}
                    </span>
                    </button>

                    <button
                        onClick={() => handleCreateTask(FieldsManager.getFieldKeyValues(fields))}
                        className="relative inline-flex items-center whitespace-nowrap rounded-full bg-tm-gray-100 px-2 py-2 text-sm font-medium text-tm-gray-500 hover:bg-tm-gray-200 sm:px-3">
                        <ClipboardDocumentListIcon
                            className={classNames(
                                'text-tm-gray-400',
                                'h-5 w-5 flex-shrink-0 sm:-ml-1'
                            )}
                            aria-hidden="true"
                        />
                        <span
                            className={classNames(
                                'text-tm-gray-900',
                                'hidden truncate sm:ml-2 sm:block'
                            )}
                        >
                      {translate("text.create_task_from_note")}
                    </span>
                    </button>
                </div>
            )}

            <ModalFooter
                onClose={onClose}
                closeButtonLabel={onClose ? translate('btn.cancel') : null}
                buttonDisabled={!areFieldsDirty}
                onButtonClick={submit}
                buttonLabel={translate('btn.save')}
            />

            <ModalConfirm
                title={"Confirm delete"}
                show={confirmModalOpen}
                text={`${translate("text.confirm_delete_note")} '${fields?.Title?.value}'?`}
                onClose={() => setConfirmModalOpen(false)}
                buttonLabel={translate("btn.confirm")}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    handleDeleteNote();
                    onClose();
                }}
            />
        </>
    )
}