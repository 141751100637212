import {checkUserHelper} from "../services/api-util";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateDocumentResource,
    doneDeleteDocumentResource,
    doneGetDocumentResource,
    doneUpdateDocumentResource,
    errorDeleteDocumentResource,
    errorGetDocumentResource,
    errorUpdateDocumentResource
} from "../actions/documentResource";
import {pushNotification} from "../actions/ui";
import {errorUploadDocument} from "../actions/download";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getDocumentResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetDocumentResource(result.data));
    } else {
        yield put(errorGetDocumentResource(result.data));
    }
}

export function* watchGetDocumentResource() {
    yield takeLatest('GET_DOCUMENT_RESOURCE', getDocumentResourceCall);
}

export function* createDocumentResourceCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.files && action.data.files.length > 0) {
        result = yield call(Api.uploadDocuments, user, action.data.id, action.data.files, action.data.DocumentTypeID, action.data.Descriptions, action.data.resource, action.data.params);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDocumentResource(resPiggy.data));
                yield put(doneCreateDocumentResource(result.data));
            }
        } else {
            yield put(doneGetDocumentResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUploadDocument(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchCreateDocumentResource() {
    yield takeLatest('CREATE_DOCUMENT_RESOURCE', createDocumentResourceCall);
}

export function* deleteResourceDocumentCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDocumentResource(resPiggy.data));
                yield put(doneDeleteDocumentResource(result.data));
            }
        } else {
            yield put(doneDeleteDocumentResource(result.data));
        }


        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteDocumentResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDeleteDocumentResource() {
    yield takeLatest('DELETE_DOCUMENT_RESOURCE', deleteResourceDocumentCall);
}

export function* updateDocumentResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetDocumentResource(resPiggy.data));
                yield put(doneUpdateDocumentResource(result.data));
            }
        } else {
            yield put(doneUpdateDocumentResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateDocumentResource(result.data));

        if (action.data.errorMessage) {
            const errorNotification = getErrorNotificationWithExceptions(result, action)

            yield put(pushNotification(errorNotification));
        }
    }
}

export function* watchUpdateDocumentResource() {
    yield takeLatest('UPDATE_DOCUMENT_RESOURCE', updateDocumentResourceCall);
}