import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showGlobalModal, showModal} from '../../../data/actions/ui'
import {download} from '../../../data/actions/download'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import CreateUpdateContactGroupsDialog from '../../../common/components/modal/createContactsDialog'
import {Field, FieldsManager} from '../../../data/services/fields'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {clearThirdResource} from "../../../data/actions/thirdResource";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Layout from "../../../common/components/layout";

class GroupsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ContactGroupName',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            tableColumns: {},
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialog
            createUpdateDialog: false,
            createUpdateDialogHideAnimation: false,
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.ContactGroups,
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.ContactGroups,
            query: Object.assign({
                format: 'EXCEL',
                name: 'contact_groups_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.ContactGroups,
            query: Object.assign({
                format: 'EXCEL',
                name: 'contact_groups_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    deleteResource = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_delete_group')} ${item.ContactGroupName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            ContactGroupID: item.ContactGroupID
                        }, this.getQuery()),
                        errorMessage: true, successMessage: `${item.ContactGroupName} deleted`,
                        resource: Resources.ContactGroups,
                        piggyResource: Resources.ContactGroups
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleShowUpdateCreateUpdateDialog = (selectedItem) => {
        this.setState({selectedItem: selectedItem, createUpdateDialog: true})
    }

    handleCloseCreateUpdateDialog = () => {
        this.setState({
            createUpdateDialogHideAnimation: false,
            createUpdateDialog: false,
            prefilled: null,
            selectedItem: null,
        }, () => this.props.dispatch(clearThirdResource()))
    }

    handleOpenGroupInfoDialog = (item) => {
        this.props.dispatch(showGlobalModal('ViewContactGroupCard', item))
    }

    handleCreateResourceClick = () => {
        this.setState({
            selectedItem: null,
            createUpdateDialog: true
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    viewContactCard = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            ContactGroupName: new Field('ContactGroupName', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return (
                        <span>{item.ContactGroupName} ({item.MemberCount})</span>
                    )
                }
            }),
            ContactGroupDesc: new Field('ContactGroupDesc', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <>{longTableColumn(item.ContactGroupDesc)}</>
                )
            }),
            ContactGroupManager: new Field('ContactGroupManager', '', [], false, 'text', {}),
            ContactGroupEmail: new Field('ContactGroupEmail', '', [], false, 'text', {}),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date', {hideDialog: true})
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'query', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getPrimaryKey = () => {
        return 'ContactGroupID'
    }

    getResource = () => {
        return Resources.ContactGroups
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.group/team/department')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateResourceClick()}
                        buttonHidden={!checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                        <div className="ml-auto">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(Resources.ContactGroups, CREATE_PERM) ? this.handleShowUpdateCreateUpdateDialog : null}

                            onView={this.handleOpenGroupInfoDialog}
                            onEdit={this.handleShowUpdateCreateUpdateDialog}
                            onDelete={this.deleteResource}

                            hasViewPerm={checkPerm(Resources.ContactGroups, READ_PERM)}
                            hasEditPerm={checkPerm(Resources.ContactGroups, UPDATE_PERM)}
                            hasDeletePerm={checkPerm(Resources.ContactGroups, DELETE_PERM)}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new group'}
                            btnLabel="Create group"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleCreateResourceClick()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <CreateUpdateContactGroupsDialog
                        isOpen={this.state.createUpdateDialog}
                        HideModalAnimation={this.state.createUpdateDialogHideAnimation}
                        prefilled={this.state.prefilled}
                        selectedItem={this.state.selectedItem}
                        translate={translate}
                        query={this.getQuery()}
                        close={this.handleCloseCreateUpdateDialog}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(GroupsView)
