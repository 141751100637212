import React, {useEffect, useState} from 'react'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {createResource, deleteResource, getResource} from '../../../data/actions/resource'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DELETE_PERM} from '../../../common/util/util-consts'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import {useDispatch, useSelector} from "react-redux";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Page from "../../../common/components/layout/layout-components/page";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ModalDefault from "../../../common/components/modal/modal-default";
import InfoParagraph from "../../../common/components/info-paragraph";
import ResourceTable from "../../../common/components/resource-table";
import Layout from "../../../common/components/layout";
import {fieldsToHtml} from "../../../common/util/util-fields";

const IntegrationsTab = (props) => {
    const {translate, match, history, location} = props
    const resource = useSelector((state) => state.resource);
    /** Helpers
     ================================================================= */
    const getTableFields = () => {
        return {
            TrackingMethodID: new Field('TrackingMethodID', '', ['empty'], false, 'select', {
                omitSort: true
            }),
            Description: new Field('Description', '', ['empty'], false, 'text', {
                omitSort: true
            }),
        }
    }

    const getFields = () => {
        return {
            TrackingMethodID: new Field('TrackingMethodID', '', ['empty'], false, 'select'),
        }
    }

    const getResourceName = () => {
        return Resources.IntegrationsMy
    }

    const data = getProp(resource, 'data.list', [])
    const TrackingMethodTemplates = getProp(resource, 'data.templates', [])

    let TrackingMethodID = TrackingMethodTemplates
        .filter(item => !(data.some(elem => elem.TrackingMethodID === item.TrackingMethodID)))
        .reduce((memo, it) => {
            memo[it.TrackingMethodID] = it.TrackingMethod
            return memo
        }, {})

    /** State
     ================================================================= */
    const [additionalFields, setAdditionalFields] = useState({})
    const [description, setDescription] = useState('No description')
    const [fields, setFields] = useState(getFields())
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(false)

    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    const submit = () => {
        setAdditionalFields(FieldsManager.validateFields(additionalFields))
        setFields(FieldsManager.validateFields(fields))

        if (handleCheckFieldsForError()) {
            const params = Object.assign({},
                FieldsManager.getFieldKeyValues(fields),
                {Settings: FieldsManager.getFieldKeyValues(additionalFields)}
            )
            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, params),
                errorMessage: true, successMessage: `Resource updated`,
                resource: getResourceName(),
                piggyResource: getResourceName(),
            }))
            handleToggleCreateModal()
        }
    }

    const archiveResource = () => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {IntegrationID: selectedItem.IntegrationID},
            errorMessage: true, successMessage: `Integration deleted`,
            resource: getResourceName(),
            piggyResource: getResourceName()
        }))
    }

    /** UI Events
     ================================================================= */
    const handleCheckFieldsForError = () => {
        let result = true
        Object.keys(fields).forEach(it => {
            if (!fields[it].value) result = false
        })
        Object.keys(additionalFields).forEach(it => {
            if (!additionalFields[it].value) result = false
        })
        return result
    }

    const handleInputChange = (name, value) => {
        if (name === 'TrackingMethodID' && fields.TrackingMethodID.value !== value) {
            let template = TrackingMethodTemplates.find(item => (+item.TrackingMethodID) === (+value))
            if (template) {
                let tmp = {}
                setDescription(template.Description)
                Object.keys(template.SettingsTemplate).forEach(it => {
                    Object.assign(tmp, {
                        [it]: new Field(it, '', template.SettingsTemplate[it] === 'checkbox' ? [] : ['empty'], false, template.SettingsTemplate[it])
                    })
                })
                setAdditionalFields(tmp)
            }
        }
        let newFields = FieldsManager.updateField(fields, name, value)
        setFields(newFields)
    }

    const handleAdditionalFieldsInputChange = (name, value) => {
        let newFields = FieldsManager.updateField(additionalFields, name, value)
        setAdditionalFields(newFields)
    }

    const handleArchiveResource = (item) => {
        setConfirmModalOpen(true)
        setSelectedItem(item)
    }

    const hideConfirmDialog = () => {
        setConfirmModalOpen(false)
    }

    const handleToggleCreateModal = () => {
        setAdditionalFields({})
        setFields(getFields())
        setCreateModalOpen(!createModalOpen)
    }

    let additionalFieldsToHTML = fieldsToHtml(Object.values(Object.assign({}, additionalFields)), translate, handleAdditionalFieldsInputChange, {})
    let fieldsToHTML = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, {
        TrackingMethodID: TrackingMethodID
    })

    return (
        <React.Fragment>
            <Layout
                ui={ui}
                user={user}
                match={match}
                history={history}
                location={location}
                translate={translate}
                dispatch={dispatch}
            >
                <Page>
                    <TableCard>
                        <div className="p-4">
                            <PageHeader
                                title={translate('text.Integrations')}
                                titleClass="mr-5 text-2xl"
                                buttonLabel={translate('btn.create_new')}
                                onButtonClick={() => handleToggleCreateModal()}
                                buttonHidden={!checkPerm(getResourceName(), CREATE_PERM)}
                            />
                        </div>

                        <ResourceTable
                            hasVirtualizedRows={true}
                            key="integrationTable"
                            options={{style: {floatingActions: true, horizontalLines: true}}}
                            tableKey="IntegrationID"
                            data={data}

                            fields={getTableFields()}
                            translate={translate}
                            isLoading={resource.isLoading}

                            onDelete={checkPerm(getResourceName(), DELETE_PERM) ? handleArchiveResource : null}
                        />
                    </TableCard>

                    <ModalDefault
                        show={createModalOpen}
                        widthClass={'max-w-2xl'}
                        title={translate('text.create') + ' ' + translate('text.integration')}

                        onButtonClick={submit}
                        buttonLabel={'Save'}

                        onClose={handleToggleCreateModal}
                        closeButtonLabel={translate('btn.cancel')}

                        translate={translate}
                    >
                        <div className="grid grid-cols-3 gap-4 px-6 py-7">
                            {fieldsToHTML}
                            <div className="col-span-3">
                                <InfoParagraph
                                    message={description ?? 'No description'}
                                />
                            </div>
                            {additionalFieldsToHTML}
                        </div>
                    </ModalDefault>

                    <ModalConfirm
                        show={confirmModalOpen}
                        text={translate("text.confirm_delete_integration")}
                        onClose={hideConfirmDialog}
                        onConfirm={() => {
                            archiveResource();
                            hideConfirmDialog()
                        }}
                        buttonLabel={translate("text.Confirm")}
                        closeButtonLabel={translate("text.Cancel")}
                        translate={translate}
                    />

                </Page>
            </Layout>
        </React.Fragment>
    )
}

export default IntegrationsTab
