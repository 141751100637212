import React, {useEffect, useState} from 'react';
import Fields from "../../../../common/components/fields/fields-to-html";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../util/util-constants";
import ModalDefault from "../../../../common/components/modal/modal-default";
import moment from "moment";
import InfoParagraph from "../../../../common/components/info-paragraph";
import {DEFAULT_DATABASE_DATE_FORMAT} from "../../../../common/util/util-consts";

export default function ClosestMatchDialog({show, onClose, onFetchData, autocompleteFields, setAutocompleteFields, translate}) {
    const [fields, setFields] = useState(() => getFields());

    const [invalidLatLongMessage, setInvalidLatLongMessage] = useState(() => false);

    function getFields(data = {}) {
        const momentNow = moment();

        const fieldTemplates = {
            Date: new Field("Date", moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], '', 'date', {
                addContainerClass: 'col-span-2'
            }, {
                minDate: momentNow.clone().startOf('isoWeek').subtract('4', 'week').add('3', 'day').format(DEFAULT_DATABASE_DATE_FORMAT),
                maxDate: momentNow.clone().startOf('isoWeek').add('2', 'week').subtract('3', 'day').format(DEFAULT_DATABASE_DATE_FORMAT)
            }),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: handleSetAutocompleteFields,
                addContainerClass: 'col-span-10'
            }),
            AddressName: new Field('AddressName', '', [], true, "text", {addContainerClass: "col-span-full"}),
            CityName: new Field('CityName', '', [''], true, "text", {addContainerClass: "col-span-5"}),
            StateID: new Field('StateID', '', [''], true, 'select', {addContainerClass: "col-span-5"}),
            PostalCode: new Field('PostalCode', '', [''], true, "text", {addContainerClass: "col-span-2"}),
            Latitude: new Field('Latitude', '', [], false, "hidden"),
            Longitude: new Field('Longitude', '', [], false, "hidden")
        }

        return fillFieldsFromData(fieldTemplates, data)
    }

    function handleSetAutocompleteFields(fields) {
        setInvalidLatLongMessage(false);
        setAutocompleteFields(Object.assign({}, fields));
    }

    function handleInputChange(name, value) {
        let fieldsUpdate = Object.assign({}, fields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);
        setFields(fieldsUpdate);
    }

    useEffect(() => {
        const fieldsUpdate = Object.assign({}, getFields(Object.assign(autocompleteFields, {Date: fields.Date.value})));
        setFields(fieldsUpdate);
    }, [autocompleteFields]);

    useEffect(() => {
        if (show) {
            setFields(getFields());
        }
    }, [show]);

    return <ModalDefault
        show={show}
        widthClass={"max-w-5xl"}
        title={translate("btn.order_by_closest_location_match")}
        closeButtonLabel={translate("btn.close")}
        onButtonClick={() => {
            if (fields.Latitude.value && fields.Longitude.value) {
                const validatedFields = FieldsManager.validateFields(fields);
                if (FieldsManager.checkFieldsForErrors(validatedFields)) {
                    onFetchData({
                        SearchLatitude: fields.Latitude.value,
                        SearchLongitude: fields.Longitude.value,
                        TargetDate: fields.Date.value?.split(" ")[0]
                    });

                    onClose();
                } else {
                    setFields(Object.assign({}, validatedFields));
                }
            } else {
                setInvalidLatLongMessage(true);
            }
        }}
        buttonLabel={translate("btn.apply_order")}
        buttonDisabled={!fields.Latitude?.value || !fields.Longitude?.value}
        onClose={onClose}
    >
        {invalidLatLongMessage && (
            <div className="p-1">
                <InfoParagraph type="danger">
                    <span>Error: The selected location does not have valid latitude and longitude coordinates. Please try again.</span>
                </InfoParagraph>
            </div>
        )}

        <div
            className="p-6 grid grid-cols-12 gap-4"
        >
            <Fields
                fieldsState={fields}
                onInputChange={handleInputChange}
                translate={translate}
            />
        </div>
    </ModalDefault>

}