import React, { Component } from 'react'
import Resources from '../../../data/services/resources'
import { createResource, deleteResource, getResource } from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import { Field, FieldsManager } from '../../../data/services/fields'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import {CREATE_PERM, DELETE_PERM} from "../../../common/util/util-consts";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class DriverRestrictionsTab extends Component {
    constructor (props) {
        super(props)
        this.state = {
            canSubmit: false,
            id: null,
            items: [],

            createModalOpen: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount () {
        !!this.getID() && this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: {
                id: this.getID()
            }
        }))
    }

    /** UI Events
     ================================================================= */
    addNewRestriction = (fields) => {
        if (this.getID()) {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(fields),
                    {
                        DriverID: this.getID()
                    }),
                query: {
                    id: this.getID()
                },
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true, successMessage: `Driver Restriction has been created.`,
            }))
        } else {
            this.props.addNewRestriction(fields)
        }
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            DriverRestrictionTypeID: new Field('DriverRestrictionTypeID', '', ['empty'], false, 'select', { addContainerClass: 'col-span-full' }),
            Notes: new Field('Notes', '', [], false, 'textarea', { addContainerClass: 'col-span-full' }),
        }
    }

    getFormattedPropValues = () => {
        const DriverRestrictions = getLookup('DriverRestrictionType')
        return this.props.fields_restrictions.map((it, index) => {
            return {
                DriverRestrictionType: DriverRestrictions[it.DriverRestrictionTypeID.value],
                DriverRestrictionTypeID: it.DriverRestrictionTypeID.value,
                Notes: it.Notes.value,
                id: index
            }
        })
    }

    getID = () => {
        return this.props.match.params.id
    }

    getResource = () => {
        return Resources.DriverRestrictions
    }

    /** Render
     ================================================================= */
    render () {
        const { translate, resource } = this.props

        const data = !!this.getID() ? getProp(this.props, 'resource.data.list', []) : this.getFormattedPropValues()
        const count = !!this.getID() ? getProp(this.props, 'resource.data.count', []) : 0

        return (
            <React.Fragment>
                <div className={"ml-4"}>
                    <PageHeader
                        title={translate('text.driverRestrictions')}
                        titleClass="mr-5 text-2xl mb-4"
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.setState({ createModalOpen: true })}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                    >
                        <button className="btn btn-header ml-auto mr-2" onClick={this.fetchData}>
                            <ArrowPathIcon className="w-5 h-5" />
                        </button>

                    </PageHeader>
                </div>

                <Card>
                    <TableCard>
                        <ResourceTable
                            addClass="rounded-card"
                            data={data}
                            count={count}

                            commonTable={true}

                            fields={this.getFields()}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}

                            onDelete={checkPerm(this.getResource(), DELETE_PERM) && this.handleToggleConfirmModal}

                            tableKey='DriverRestrictionID'
                        />

                        <NoRecordsTable
                            show={data.length === 0 && !resource.isLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                            className={"pb-12 pt-16 px-6"}
                        />
                    </TableCard>
                </Card>

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('text.CreateNewDriverRestriction')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    getRawFields
                    onSubmit={(params) => {
                        if (params) {
                            this.addNewRestriction(params)
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('message.are_you_sure_delete_restriction')}
                    onClose={() => this.handleToggleConfirmModal()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (!!this.getID()) {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    DriverRestrictionID: this.state.selectedItem?.DriverRestrictionID,
                                    id: this.getID()
                                },
                                errorMessage: true, successMessage: `Driver Restriction  has been deleted`,
                                resource: this.getResource(),
                                piggyResource: this.getResource()
                            }))
                            this.handleToggleConfirmModal(false)
                        } else {
                            this.props.removeNewRestriction(this.state.selectedItem['id'])
                            this.handleToggleConfirmModal(false)
                        }
                    }}
                />
            </React.Fragment>
        )
    }
}
