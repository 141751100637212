const tablesDataReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_TABLE_DATA':
            return Object.assign({}, state, {
                [action.data.key]: action?.data?.data
            });
        case 'PATCH_TABLE_DATA':
            return Object.assign({}, state, {
                [action.data.key]: Object.assign(state[action.data.key], action?.data?.data)
            });
        default:
            return state;
    }
}

export default tablesDataReducer;