import React from "react";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {classNames} from "../../../util/util-helpers";

export default function ContentWithSidebar({
                                               addClass,
                                               onClose,
                                               mainTitle,
                                               mainBeforeTitle,
                                               mainAfterTitle,
                                               sidebarTitle,
                                               sidebarBeforeTitle,
                                               sidebarAfterTitle,
                                               sidebarWidth,
                                               isMainFocused,
                                               sidebarContent,
                                               mainContent,
                                               onSave,
                                               translate
                                           }) {
    const widthClasses = getWidthClasses(sidebarWidth);

    return (
        <React.Fragment>
            <div className={classNames(
                "flex text-tm-gray-700",
                addClass,
                widthClasses.container
            )}>
                <div
                    className={classNames(
                        "border-tm-gray-200 border-r z-0 relative",
                        "",
                        widthClasses.sidebar
                    )}>

                    {/* Sidebar title */}
                    <div className="h-12 border-b border-tm-gray-200 flex items-center justify-center px-4">
                        <div className="w-full">
                            {sidebarBeforeTitle}
                        </div>

                        <h2 className="text-base font-bold flex-shrink-0">{sidebarTitle}</h2>

                        <div className="w-full flex items-center justify-end gap-x-3">
                            {sidebarAfterTitle}

                            {!!onClose && (
                                <button
                                    className={
                                        classNames(
                                            "btn btn-close",
                                            widthClasses.hideButtons
                                        )
                                    }
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    <XMarkIcon/>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="bg-popup h-[calc(100%-6.5rem)] overflow-y-auto">
                        {sidebarContent}
                    </div>

                    <footer className="h-14 bg-inverse flex items-center px-6 border-t border-tm-gray-300">
                        <div className="flex w-full">
                            <div className="ml-auto">
                                <button
                                    className="btn btn-primary"
                                    onClick={onSave}
                                >
                                    {translate("btn.save")}
                                </button>
                            </div>
                        </div>
                    </footer>
                </div>

                <div className={classNames(
                    "w-full relative z-1 bg-tm-gray-50",
                    widthClasses.main,
                    isMainFocused ? widthClasses.mainTransition : undefined
                )}
                >
                    {/* Main content title */}
                    <div
                        className="h-12 border-b border-tm-gray-200 flex items-center justify-center relative px-4"
                    >
                        <div className="w-full">
                            {mainBeforeTitle}
                        </div>

                        <h2 className="text-base font-bold flex-shrink-0">{mainTitle}</h2>

                        <div className="w-full flex items-center gap-x-3 justify-end">
                            {mainAfterTitle}

                            {!!onClose && (
                                <button
                                    className="btn btn-close"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    <XMarkIcon/>
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="h-[calc(100%-3rem)]">
                        {mainContent}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const getWidthClasses = (width) => {
    let widthClasses = {};

    switch (width) {
        case 'xl':
            widthClasses = Object.assign(widthClasses, {
                sidebar: "lg:w-96 transition-all w-full shrink-0",
                main: "lg:w-[calc(100%-24rem)] shrink-0 transition-all rounded-r-lg",
                mainTransition: "-translate-x-full lg:translate-x-0 rounded-lg",
                hideButtons: "lg:hidden"
            });
            break;
        case 'lg':
            widthClasses = Object.assign(widthClasses, {
                sidebar: "sm:w-72 transition-all w-full shrink-0",
                main: "sm:w-[calc(100%-18rem)] shrink-0 transition-all rounded-r-lg",
                mainTransition: "-translate-x-full sm:translate-x-0 rounded-lg",
                hideButtons: "sm:hidden"
            });
            break;
        case 'sm':
            widthClasses = Object.assign(widthClasses, {
                sidebar: "sm:w-52 transition-all w-full shrink-0",
                main: "sm:w-[calc(100%-13rem)] shrink-0 transition-all rounded-r-lg",
                mainTransition: "-translate-x-full sm:translate-x-0 rounded-lg",
                hideButtons: "sm:hidden"
            });
            break;
        default:
            return {
                sidebar: "sm:w-64 transition-all w-full shrink-0",
                main: "sm:w-[calc(100%-16rem)] shrink-0 transition-all rounded-r-lg",
                mainTransition: "-translate-x-full sm:translate-x-0 rounded-lg",
                hideButtons: "sm:hidden"
            }
    }

    return Object.assign(widthClasses, {
        container: "flex-nowrap overflow-hidden",
    });
}
