import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource, resetResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    JournalEntryTypeID,
    READ_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getProp,
    longTableColumn,
    renderExpenseStatusBadge,
    renderInvoiceStatusBadge,
    saveTableColumns
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {cloneDeep} from '../../../common/util/util-vanilla'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {getThirdResource} from '../../../data/actions/thirdResource'
import {EyeIcon} from '@heroicons/react/24/outline'
import {getDialogResource} from '../../../data/actions/dialogResource'
import {numberWithCommas} from '../../../util/util-formaters'
import AccountingLineChartDialog from "./accounting-line-chart-dialog/accounting-line-chart-dialog";
import PresentationChartLineIcon from "@heroicons/react/24/outline/PresentationChartLineIcon";
import moment from "moment-timezone";
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import {ArrowPathIcon} from "@heroicons/react/20/solid";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import {showModal} from "../../../data/actions/ui";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {excludeFields, fieldsToHtml} from "../../../common/util/util-fields";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

export default class AccountAuditTab extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_account_audit'
        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: true
            },
            columns: {}
        }

        this.contactTableOptions = {
            style: {
                stripedRows: true,
                horizontalLines: false,
                floatingActions: false,
            },
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            selectedItem: null,
            createModalOpen: false,
            confirmModalOpen: false,
            id: this.getIdField(),
            showReceiptModal: false,
            columnsModalOpen: false,
            isTransactionsDialogVisible: false,
            isLineChartDialogVisible: false,
            isContactSelectDialogOpen: false,
            isOrganizationSelectDialogOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentWillUnmount() {
        this.props.dispatch(resetResource())
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        if (!this.state.isLineChartDialogVisible) {
            this.props.dispatch(getResource({
                user: LocalStorage.get('user'),
                query: this.getQuery(),
                resource: this.getResourceName()
            }))
        }
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_account_audit_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    fetchExpenseDetailsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                SendBatchPaymentID: this.state.selectedItem?.ReferenceType === 'tbl_SendBatchPayment' ? this.state.selectedItem?.ReferenceID : null,
                SendPaymentID: this.state.selectedItem?.ReferenceType === 'tbl_SendPayment' ? this.state.selectedItem?.ReferenceID : null
            },
            resource: Resources.ExpenseTransactionsDetails
        }))
    }

    fetchIncomeDetailsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: this.state.selectedItem?.ReferenceType === 'tbl_RecvBatchPayment' ? this.state.selectedItem?.ReferenceID : null,
                RecvPaymentID: this.state.selectedItem?.ReferenceType === 'tbl_RecvPayment' ? this.state.selectedItem?.ReferenceID : null
            },
            resource: Resources.IncomeTransactionsDetails
        }))
    }
    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.id
        fields = FieldsManager.updateField(fields, name, value)

        this.setState({
            id: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleReceiptDialog = (item = null) => {
        let receiptModalFetchKey = null
        let receiptModalFetchResource = null
        if (item) {
            receiptModalFetchKey = (item.ReferenceType === 'tbl_Invoice' || item.RecvBatchPaymentID || item.RecvPaymentID) ? 'InvoiceID' :
                (item.ReferenceType === 'tbl_Expense' || item.SendBatchPaymentID || item.SendPaymentID) ? 'ExpenseID' : ''

            receiptModalFetchResource = (item.ReferenceType === 'tbl_Invoice' || item.RecvBatchPaymentID || item.RecvPaymentID) ? Resources.InvoicesInvoice :
                (item.ReferenceType === 'tbl_Expense' || item.SendBatchPaymentID || item.SendPaymentID) ? Resources.ExpenseExpense : ''
        }

        this.setState({
            selectedItem: item,
            showReceiptModal: !this.state.showReceiptModal,
            receiptModalFetchKey: receiptModalFetchKey,
            receiptModalFetchResource: receiptModalFetchResource
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate)
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleToggleShowTransactions = (item = null) => {
        this.setState({
            selectedItem: item,
            selectedTransactionsItem: item,
            isTransactionsDialogVisible: !this.state.isTransactionsDialogVisible,
        }, () => {
            this.state.isTransactionsDialogVisible && (item.ReferenceType === 'tbl_SendBatchPayment' || item.ReferenceType === 'tbl_SendPayment') ? this.fetchExpenseDetailsData() : this.fetchIncomeDetailsData()
            !this.state.isTransactionsDialogVisible && this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleToggleLineGraphDialog = () => {
        this.setState({isLineChartDialogVisible: !this.state.isLineChartDialogVisible})
    }

    toggleContactSelectDialog = () => {
        this.setState({
            isContactSelectDialogOpen: !this.state.isContactSelectDialogOpen,
        })
    }

    toggleOrganizationSelectDialog = () => {
        this.setState({
            isOrganizationSelectDialogOpen: !this.state.isOrganizationSelectDialogOpen,
        })
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.AccountingAccountReport
    }

    getQuery = () => {
        let id = FieldsManager.getFieldKeyValues(this.state.id)

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            AccountIDs: id?.AccountIDs
        }
    }

    getIdField = () => {
        return {
            AccountIDs: new Field('AccountIDs', '', [''], false, 'multi-select-search', {
                addContainerClass: 'w-96 ml-2 z-50',
                label: 'Account'
            }, {
                placeholder: 'Select account',
                multi: true
            })
        }
    }

    getFields = () => {
        return {
            Description: new Field('Description', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <div
                        onClick={() => {
                            if (item.ReferenceType === 'tbl_Invoice' || item.ReferenceType === 'tbl_Expense') {
                                this.handleToggleReceiptDialog(item)
                            }
                        }}
                    >{longTableColumn(item.Description)}</div>
                )
                , omitSort: true
            }),
            Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
            Debit: new Field('Debit', '', [], false, 'float'),
            Credit: new Field('Credit', '', [], false, 'float'),
            Reference: new Field('Reference', '', [], false, 'custom', {
                render: (item) => {
                    if (item.Organization) {
                        return item.Organization
                    } else if (item.Contact) {
                        return item.Contact
                    }
                    return ''
                }
            }),
            JournalEntryTypeID: new Field('JournalEntryTypeID', '', [], false, 'custom', {
                render: (item) => (
                    <>{JournalEntryTypeID[item.JournalEntryTypeID]}</>
                )
            })
        }

    }

    getDetailsFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: 'PayTo',
                render: (it) => it.Organization ?? it.Contact
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                omitSort: true,
                label: 'PaymentAmount',
            }),
            ExpenseAmount: new Field('ExpenseAmount', '', [], false, 'money', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.ExpenseAmount,
                omitSort: true
            }),
            ExpenseStatus: new Field('ExpenseStatus', '', [''], false, 'custom', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.ExpenseStatus,
                omitSort: true,
                render: (it) => renderExpenseStatusBadge(it)
            }),
            InvoiceAmount: new Field('InvoiceAmount', '', [], false, 'money', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.InvoiceAmount,
                omitSort: true
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.InvoiceStatus,
                omitSort: true,
                render: (it) => renderInvoiceStatusBadge(it)
            })
        }
    }

    getContactFields = () => ({
        FirstName: new Field('FirstName', '', ['empty'], false, 'text', {addContainerClass: "md:col-span-4"}),
        LastName: new Field('LastName', '', ['empty'], false, 'text', {addContainerClass: "md:col-span-4"}),
        Email: new Field('Email', '', [], false, "email", {addContainerClass: "md:col-span-4"}),
    })

    getOrganizationFields = () => ({
        LegalName: new Field('LegalName', '', ['empty'], false, 'text', {addContainerClass: "md:col-span-4"}),
        AddressName: new Field('AddressName', '', ['empty'], false, 'text', {addContainerClass: "md:col-span-4"}),
        CityName: new Field('CityName', '', ['empty']),
        State: new Field('State', '', ['empty']),
        PostalCode: new Field('PostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),
        FederalID: new Field('FederalID', '', ['empty'])
    })

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-4', htmlAfterField: (it) =>
                    <button
                        disabled={it.disabled}
                        onClick={() => this.toggleContactSelectDialog()}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
            }, {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.FirstName + ' ' + item.LastName,
                    value: item.ContactID
                })
                ,
                isClearable: true
            }),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-4',
                htmlAfterField: (it) =>
                    <button
                        disabled={it.disabled}
                        onClick={() => this.toggleOrganizationSelectDialog()}
                        className="btn btn-input"
                    >
                        {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
            }, {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName,
                    value: item.OrganizationID
                })
                ,
                isClearable: true
            }),
            StartDate: new Field('StartDate', moment().startOf('year').format(DEFAULT_DATABASE_DATETIME_FORMAT), [''], false, 'date', {
                addContainerClass: 'md:col-span-4',
                labelType: "float",
            }, {isClearable: true}),
            EndDate: new Field('EndDate', moment().endOf('year').format(DEFAULT_DATABASE_DATETIME_FORMAT), [''], false, 'date', {
                addContainerClass: 'md:col-span-4',
                labelType: "float",
            }, {isClearable: true}),
            NotAssignedToContact: new Field('NotAssignedToContact', '', [''], false, 'checkbox', {}, {}),
            NotAssignedToOrganization: new Field('NotAssignedToOrganization', '', [''], false, 'checkbox', {}, {}),
            NotAssignedToDriver: new Field('NotAssignedToDriver', '', [''], false, 'checkbox', {}, {}),
            CreditLessThan: new Field('CreditLessThan', '', [], false, 'float', {addContainerClass: 'md:col-span-2'}),
            CreditMoreThan: new Field('CreditMoreThan', '', [], false, 'float', {addContainerClass: 'md:col-span-2'}),
            DebitLessThan: new Field('DebitLessThan', '', [], false, 'float', {addContainerClass: 'md:col-span-2'}),
            DebitMoreThan: new Field('DebitMoreThan', '', [], false, 'float', {addContainerClass: 'md:col-span-2'}),

            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true, labelType: 'float'
            }, {menuPlacement: 'top'})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const data = getProp(this.props, 'resource.data.Transactions', [])
        const count = getProp(this.props, 'resource.data.count', 0)

        const isLoading = getProp(this.props, 'resource.isLoading', false)

        const EndAmount = getProp(this.props, 'resource.data.EndAmount', 0)
        const StartAmount = getProp(this.props, 'resource.data.StartAmount', 0)

        const transactionsData = getProp(this.props.dialogResource, 'data.list', [])
        const transactionsDataIsLoading = getProp(this.props.dialogResource, 'isLoading', false)

        const idField = fieldsToHtml(Object.values(Object.assign({}, this.state.id)), translate, this.handleInputChange, {
            AccountIDs: {
                api: 'api/' + Resources.Accounts,
                query: {},
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        })

        let queryFilterFields = excludeFields(cloneDeep(this.state.queryFilterFields), [
            'StartDate', 'EndDate'
        ])

        const DateFilters = <PayDateFilter
            queryFields={this.state.queryFilterFields}
            translate={translate}
            updateQueryFields={(queryFieldsUpdate) => {
                this.setState({
                    queryFilterFields: queryFieldsUpdate,
                    offset: 0
                }, () => this.fetchData())
                this.saveFilters(queryFieldsUpdate)
            }}
            onQueryChange={this.handleFilterInputChange}
            hasDateType={false}
        />

        let queryFilterFieldsDialog = cloneDeep(this.state.queryFilterFields)

        queryFilterFieldsDialog.StartDate.props.isClearable = false;
        queryFilterFieldsDialog.EndDate.props.isClearable = false;

        const DateFiltersDialog = <PayDateFilter
            queryFields={queryFilterFieldsDialog}
            translate={translate}
            updateQueryFields={(queryFieldsUpdate) => {
                this.setState({
                    queryFilterFields: queryFieldsUpdate,
                    offset: 0
                }, () => this.fetchData())
                this.saveFilters(queryFieldsUpdate)
            }}
            onQueryChange={this.handleFilterInputChange}
            hasDateType={false}
            isClearDatesButtonVisible={false}
        />

        return (
            <React.Fragment>
                <div className="flex mb-4">

                    <div className={'flex justify-between items-center'}>
                        {idField}

                        <button className={"btn btn-primary flex mt-5 mx-4"}
                                onClick={() => this.handleToggleLineGraphDialog()}
                                disabled={!this.state.queryFilterFields.StartDate.value || !this.state.queryFilterFields.EndDate.value || !this.state.id.AccountIDs?.value}>
                            <div><PresentationChartLineIcon className={"w-5 h-5"}/></div>
                        </button>
                    </div>

                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <div className={'m-2'}>
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        customHeaderHtml={(<></>)}
                        customHtml={
                            DateFilters
                        }
                        forceDialog
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={isLoading}
                    />

                    {this.state.id.AccountIDs.value && (
                        <div className="p-5">
                            <div className={'flex justify-between font-bold text-base'}>
                                <div>
                                    Starting balance: <span>{numberWithCommas(StartAmount ?? 0)}</span>
                                </div>
                                <div className={'mr-4'}>
                                    End balance: <span>{numberWithCommas(EndAmount ?? 0)}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <ResourceTable
                        maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                        data={data}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        limit={this.state.queryFilterFields.limit.value}

                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                title: () => translate('btn.view'),
                                visible: (item) => checkPerm(Resources.InvoicesInvoice, READ_PERM) && item.ReferenceType === 'tbl_Invoice',
                                action: (item) => this.handleToggleReceiptDialog(item),
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.ExpenseExpense, READ_PERM) && item.ReferenceType === 'tbl_Expense',
                                action: (item) => this.handleToggleReceiptDialog(item),
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_SendPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_SendBatchPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.IncomeTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_RecvPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.IncomeTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_RecvBatchPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            }
                        ]}
                    />

                    {/*Table footer*/}
                    {!(this.state.queryFilterFields.StartDate.value || this.state.queryFilterFields.EndDate.value) && (
                        <NoRecords
                            show={!isLoading}
                            title={translate('text.atLeastOneDate')}
                            addClass={'pt-16 pb-10'}
                        />
                    )}

                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={this.state.id?.AccountIDs.value ? translate('text.no_records') : translate("text.pleaseSelectAccount")}
                        addClass={'pt-16 pb-10'}
                    />
                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />

                    </TableCardFooter>
                </TableCard>

                <ModalDefault
                    show={this.state.showReceiptModal}
                    widthClass={'max-w-5xl w-screen'}
                    title={this.state.receiptModalFetchKey ? translate('text.' + this.state.receiptModalFetchKey) : ''}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleReceiptDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.receiptModalFetchKey]: this.state.selectedItem.ReferenceID ?? this.state.selectedItem[this.state.receiptModalFetchKey]
                                    },
                                    resource: this.state.receiptModalFetchResource
                                }))
                            }
                        }}
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isTransactionsDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.Payments')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowTransactions()}
                >
                    <div>
                        <ResourceTable
                            data={transactionsData}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={transactionsDataIsLoading}

                            limit={(transactionsData?.length ?? 10)}

                            actions={[
                                {
                                    action: this.handleToggleReceiptDialog,
                                    icon: EyeIcon,
                                    visible: () => true,// TODO Check perm
                                    title: translate('text.show_details'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(transactionsData.length === 0) && !transactionsDataIsLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                        />
                    </div>
                </ModalDefault>


                <AccountingLineChartDialog
                    show={!!this.state.isLineChartDialogVisible}
                    onClose={this.handleToggleLineGraphDialog}
                    query={this.getQuery()}
                    translate={translate}
                    info={this.props.info}
                    dispatch={this.props.dispatch}
                    title={this.state.id.AccountIDs?.value?.label}
                    handleInputChange={this.handleFilterInputChange}
                    filters={this.state.queryFilterFields}
                >
                    {DateFiltersDialog}
                </AccountingLineChartDialog>


                <ResourceTableDialog
                    show={this.state.isContactSelectDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ContactsQuick}
                    title={translate("text.select_contact")}
                    dispatch={this.props.dispatch}
                    fields={this.getContactFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    sortBy={"FirstName"}
                    defaultAction={(item) => this.props.dispatch(showModal('ViewContactCard', item))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleFilterInputChange("ContactID", {
                                    value: item.ContactID,
                                    label: item.FirstName + " " + item.LastName,
                                    metadata: item
                                })
                                this.toggleContactSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    onClose={this.toggleContactSelectDialog}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.isOrganizationSelectDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.OrganizationsQuick}
                    title={translate("text.select_organization")}
                    dispatch={this.props.dispatch}
                    fields={this.getOrganizationFields()}
                    widthClass={"max-w-7xl"}
                    options={this.contactTableOptions}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    sortBy={"LegalName"}
                    defaultAction={(item) => this.handleToggleOrganizationInfoDialog(item)}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleFilterInputChange("OrganizationID", {
                                    label: item.LegalName,
                                    value: item.OrganizationID,
                                    metadata: item
                                })
                                this.toggleOrganizationSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    onClose={this.toggleOrganizationSelectDialog}
                    translate={translate}
                />

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}
            </React.Fragment>
        )
    }
}