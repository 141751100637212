const resourceDiffReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CREATE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'DONE_CREATE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null,
            });
        case 'ERROR_CREATE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'GET_RESOURCE_DIFF': {
            const prevData = state?.data ?? null;

            return Object.assign({}, state, {
                data: action?.data?.data?.isInitial ? null : state.data,
                deltaKey: action?.data?.data?.deltaKey,
                compareKeys: action?.data?.data?.compareKeys ?? [],
                diffData: null,
                prevData: prevData,
                isLoading: !!action?.data?.data?.isInitial,
                isLoadingSilent: !action?.data?.data?.isInitial,
                error: false,
                errorStatus: null,
                errorMessage: null,
                showRedirectDialog: false,
                resource: action.data.resource
            });
        }
        case 'DONE_GET_RESOURCE_DIFF': {
            let diffData = {};
            if (action?.data?.list && state?.prevData?.list && state?.deltaKey && state?.compareKeys) { // list location may vary
                const dataNow = Date.now();
                const prevList = state.prevData.list.reduce((memo, it) => {
                    memo[it[state?.deltaKey]] = state.compareKeys.reduce((acc, key) => {
                        acc[key] = it[key];
                        return acc;
                    }, {});

                    return memo;
                }, {});

                diffData = action.data.list.reduce((memo, currentItem) => {
                    if (!prevList[currentItem[state?.deltaKey]]) {
                        memo[currentItem[state?.deltaKey]] = dataNow;
                    } else {
                        if (state.compareKeys.find(key => currentItem[key] !== prevList[currentItem[state?.deltaKey]][key])) {
                            memo[currentItem[state?.deltaKey]] = dataNow;
                        }
                    }
                    return memo;
                }, {});
            }

            return Object.assign({}, state, {
                data: action.data,
                prevData: state?.prevData,
                diffData: diffData,
                isLoading: false,
                isLoadingSilent: false,
                error: false,
                errorMessage: null
            });
        }
        case 'ERROR_GET_RESOURCE_DIFF':
            return Object.assign({}, state, {
                data: null,
                prevData: null,
                isLoading: false,
                isLoadingSilent: false,
                diffData: null,
                error: true,
                errorMessage: action.data,
                errorStatus: action.data.status,
            });
        case 'UPDATE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_UPDATE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null,
                updated: action.data?.id
            });
        case 'ERROR_UPDATE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'DELETE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                deleteResource: null,
                diffData: null,
                prevData: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                deleteResource: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_RESOURCE_DIFF':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });

        default:
            return state;
    }
};

export default resourceDiffReducer;
