import {showGlobalModal} from "../../../../data/actions/ui";
import ModalFooter from "../../modal/modal-footer";
import {checkPerm} from "../../../util/util-helpers";
import Resources from "../../../../data/services/resources";
import {UPDATE_PERM} from "../../../../util/util-constants";

export default function TaskGrabDialog({translate, item, onClose, dispatch}) {

    const tasks = item.task;

    const handleUpdateButtonClick = () => {
        dispatch(showGlobalModal('createTaskDialog', tasks))
    }

    return (
        <div>
            <div className="gap-y-8 h-dialog-body">
                <div className="col-span-5 md:col-span-6 border-r bg-tm-gray-50 border-tm-gray-200 p-6">
                    <div className="p-5 bg-tm-gray-100">
                        {!!item.task && (
                        <div className="mb-8">
                            <div
                                className="text-base p-5 bg-inverse rounded-card whitespace-pre-line shadow"
                            >
                                You have claimed task: {tasks?.TaskID} {"\n"}
                                Requester: {tasks?.Requester} {"\n"}
                                Name: {tasks?.TaskName} {"\n"}
                                Description: {tasks?.Description}
                            </div>
                            <div
                                className="text-base p-5 mt-2 bg-inverse rounded-card whitespace-pre-line shadow"
                            >

                                {translate("text.tasks_assigned_to",[item.AssignedCount])}
                            </div>
                        </div> )}

                        {item.task === null && (
                            <div
                                className="text-base p-5 mt-2 bg-inverse rounded-card whitespace-pre-line shadow"
                            >
                                {item.AssignedCount > 10 && (
                                    <div>
                                        You have more than 10 tasks already, please complete one of them first.
                                    </div>
                                )}

                                {item.AssignedCount <= 10 && (
                                    <div>
                                {translate("text.all_tasks_claimed")}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                </div>
            </div>


            <ModalFooter
                buttonLabel={checkPerm(Resources.Tasks, UPDATE_PERM) && !!item.task && translate("btn.view_task")}
                onButtonClick={() => handleUpdateButtonClick(item)}
                buttonDisabled={!item.task}
                closeButtonLabel={translate("btn.close")}
                onClose={onClose}
            />
        </div>
    )
}


