import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {showModal} from '../../../data/actions/ui'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    AGENT_EMPLOYMENT_STATUS,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {PencilIcon} from "@heroicons/react/24/outline";
import EmployeeStatusBadge from "../../../common/components/badge/employee-status-badge";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import BulkBatchEdit from "../../../common/components/modal/bulk-batch-edit";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Layout from "../../../common/components/layout";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";

class AgentView extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)
        this.tablePageDefaults = {
            behaviour: {
                rowSelect: checkPerm(Resources.AgentInfo, UPDATE_PERM),
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'FirstName',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            tableColumns: {},
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialogs
            selectedItems: {},
            selectedRows: {},
            batchActionDialog: false,
            batchFields: this.getBatchFields(),

            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {},
            isTableFullScreen: false

        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.FirstName} ${item.LastName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {AgentID: item.AgentID}),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [`${item.FirstName} ${item.LastName}`]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: `${this.props.translate('message.are_you_sure_restore_agent')} ${item.FirstName} ${item.LastName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.AgentID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Agent ${item.FirstName} ${item.LastName} restored.`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'Agents_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'Agents_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    handleBulkEdit = (fields) => {
        let tmpFields = fields
        let offices = Array.isArray(tmpFields.OfficeID?.value) ? tmpFields.OfficeID?.value?.map(it => it.value) : []
        delete tmpFields.OfficeID
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: Object.keys(this.state.selectedRows),
                Fields: Object.assign({}, FieldsManager.getFieldKeyValues(tmpFields), {
                    OfficeIDs: offices.join(',')
                })
            },
            resource: Resources.AgentsBulkEdit,
            query: this.getQuery(),
            piggyResource: this.getResource(),
            errorMessage: true, successMessage: 'Agents updated successfully!'
        }))
        this.handleCloseBatchActionDialog()
        this.handleResetBatchFields()
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleFilterInputChange = (name, value) => {
        let fields = this.state.queryFilterFields
        fields = FieldsManager.updateField(fields, name, value)
        this.setState({queryFilterFields: fields}, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCreateNewResource = () => this.handleLinkClick('/agent-create')

    handleUpdateResource = (item) => this.handleLinkClick(`/agents/info/${item.AgentID}`)

    handleShowDetails = (item) => this.props.dispatch(showModal('ViewContactCard', item))

    handleLinkClick = (link) => this.props.history.push(link)

    handleCloseBatchActionDialog = () => {
        this.setState({batchActionDialog: false})
    }


    handleResetBatchFields = () => {
        this.setState({
            selectedItems: {},
            selectedRows: {},
            batchFields: this.getBatchFields()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'FirstName'
        }
        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item[this.getPrimaryKey()];

        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it[this.getPrimaryKey()]])
            const lastSelectedItem = data.findIndex(it => it[this.getPrimaryKey()] === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i][this.getPrimaryKey()]]: data[i][this.getPrimaryKey()]})
                        selectedItems[data[i][this.getPrimaryKey()]] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i][this.getPrimaryKey()]] = data[i]
                        Object.assign(selectedRows, {[data[i][this.getPrimaryKey()]]: data[i][this.getPrimaryKey()]})
                    }
                }
            }
        } else {
            if (!!selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it[this.getPrimaryKey()] === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it[this.getPrimaryKey()]] = it
                memo[it[this.getPrimaryKey()]] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it[this.getPrimaryKey()].toString())) {
                    delete selectedItems[it[this.getPrimaryKey()]]
                    delete selectedRows[it[this.getPrimaryKey()]]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }
    /** Helpers
     ================================================================= */
    getFields = () => ({
        FirstName: new Field('FirstName', '', ['empty'], false, 'text'),
        LastName: new Field('LastName', '', ['empty'], false, 'text'),
        Offices: new Field('Offices', '', ['empty'], false, 'text', {
            omitSort: true
        }),
        Email: new Field('Email', '', ['empty']),
        StatusID: new Field('StatusID', '', [], false, 'custom', {
            render: (item) => {
                return (
                    <EmployeeStatusBadge item={item} translate={this.props.translate}/>
                )
            }
        }),
        CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date')
    })

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'query', 'archived'])
    }

    getBatchFields = () => {
        return {
            OfficeID: new Field('OfficeID', '', [], false, 'multi-select-search', {}, {
                multi: true
            }),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Offices: new Field('Offices', '', [''], false, 'multi-select-search', {
                query: {}
            }, {
                multi: true,
                isClearable: true,
            }),
            StatusID: new Field('StatusID', '', [''], false, 'select', {}, {
                values: AGENT_EMPLOYMENT_STATUS,
                isClearable: true
            }),
            HasActiveEmployment: new Field('HasActiveEmployment', '1', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getPrimaryKey = () => {
        return 'AgentID'
    }

    getResource = () => {
        return Resources.Agents
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length
        const isLoading = !!resource.isLoading;

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.AgentsView')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateNewResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {/*<Tippy content={translate('text.download_template')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcelTemplate}*/}
                            {/*    >*/}
                            {/*        <RectangleGroupIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(Resources.AgentInfo, UPDATE_PERM) ? this.handleUpdateResource : null}

                            onView={this.handleShowDetails}
                            onEdit={this.handleUpdateResource}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(Resources.Contacts, READ_PERM)}
                            hasEditPerm={checkPerm(Resources.AgentInfo, UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={this.getPrimaryKey()}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey={this.getPrimaryKey()}
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                        >
                            <div className="flex divide-x-2 divide-tm-gray-300">
                                <div className="flex space-x-2 px-3">
                                    {checkPerm(Resources.AgentInfo, UPDATE_PERM) && (
                                        <Tippy content={translate('text.bulk_actions')}>
                                            <button
                                                onClick={() => this.setState({batchActionDialog: true})}
                                                disabled={!areSomeItemsSelected}
                                                className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                            >
                                                <PencilIcon
                                                    className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                            </button>
                                        </Tippy>
                                    )}
                                </div>
                            </div>
                        </TableBulkActions>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new agent'}
                            btnLabel="Create agent"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleLinkClick(`/agent-create?prefilled=` + this.state.prefilled)
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    {this.state.batchActionDialog && (
                        <BulkBatchEdit
                            show={this.state.batchActionDialog}
                            fields={this.state.batchFields}
                            translate={translate}
                            onClose={this.handleCloseBatchActionDialog}
                            selectedItems={Object.values(this.state.selectedItems)}
                            itemKeyLabel={'FirstName'}
                            onSubmit={this.handleBulkEdit}
                        />
                    )}

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(AgentView)
