export function getResource(data) {
    return {
        type: 'GET_RESOURCE',
        data: data
    };
}

export function getResourceSilent(data) {
    return {
        type: 'GET_RESOURCE_SILENT',
        data: data
    };
}

export function doneGetResource(data) {
    return {
        type: 'DONE_GET_RESOURCE',
        data: data
    };
}

export function errorGetResource(data) {
    return {
        type: 'ERROR_GET_RESOURCE',
        data: data
    };
}

export function getResourceDetails(data) {
    return {
        type: 'GET_RESOURCE_DETAILS',
        data: data
    };
}

export function doneGetResourceDetails(data) {
    return {
        type: 'DONE_GET_RESOURCE_DETAILS',
        data: data
    };
}

export function errorGetResourceDetails(data) {
    return {
        type: 'ERROR_GET_RESOURCE_DETAILS',
        data: data
    };
}

export function createResource(data) {
    return {
        type: 'CREATE_RESOURCE',
        data: data
    };
}

export function doneCreateResource(data) {
    return {
        type: 'DONE_CREATE_RESOURCE',
        data: data
    };
}

export function errorCreateResource(data) {
    return {
        type: 'ERROR_CREATE_RESOURCE',
        data: data
    };
}

export function updateResource(data) {
    return {
        type: 'UPDATE_RESOURCE',
        data: data
    };
}

export function updateMultipleResource(data) {
    return {
        type: 'UPDATE_MULTIPLE_RESOURCE',
        data: data
    };
}

export function doneUpdateResource(data) {
    return {
        type: 'DONE_UPDATE_RESOURCE',
        data: data
    };
}

export function errorUpdateResource(data) {
    return {
        type: 'ERROR_UPDATE_RESOURCE',
        data: data
    };
}

export function deleteResource(data) {
    return {
        type: 'DELETE_RESOURCE',
        data: data
    };
}

export function doneDeleteResource(data) {
    return {
        type: 'DONE_DELETE_RESOURCE',
        data: data
    };
}

export function errorDeleteResource(data) {
    return {
        type: 'ERROR_DELETE_RESOURCE',
        data: data
    };
}

export function resetResource() {
    return {
        type: 'RESET_RESOURCE',
        data: []
    }
}

export function resetCreateUpdateResource() {
    return {
        type: 'RESET_CREATE_UPDATE_RESOURCE',
        data: []
    }
}

export function storeResourceTempData(data) {
    return {
        type: 'STORE_RESOURCE_TEMP_DATA',
        data: data
    }
}

export function deleteResourceTempData() {
    return {
        type: 'DELETE_RESOURCE_TEMP_DATA',
        data: []
    }
}
