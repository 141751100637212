import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {checkPerm, getLookup, getProp, openInNewTab} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {DEFAULT_CRUD_STATE, READ_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {getSecondResource} from "../../../data/actions/secondResource";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../common/components/resource-table";
import Card from "../../../common/components/card";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import {getThirdResource} from "../../../data/actions/thirdResource";
import ResourceListTab from "../../../common/components/tabs/resource-list-tab";
import Env from "../../../util/env";
import {EyeIcon} from "@heroicons/react/24/outline";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge/index";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {showGlobalModal, showModal} from "../../../data/actions/ui";

export default class ServicesTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            offsetPastWO: 0,
            offsetCurrentWO: 0,
            queryFilterFieldsPastWO: this.getQueryFilterFieldsPastWO(),
            queryFilterFieldsCurrentWO: this.getQueryFilterFieldsCurrentWO(),
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchDataCurrentWO();
        this.fetchDataPastWO();
    }

    /** Data Events
     ================================================================= */
    fetchDataCurrentWO = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrders,
            query: this.getQueryCurrentWO()
        }))
    }

    fetchDataPastWO = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrders,
            query: this.getQueryPastWO()
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchDataCurrentWO()
        })
    }

    handleFilterInputChangePastWO = (name, value) => {
        this.setState({
            queryFilterFieldsPastWO: FieldsManager.updateField(this.state.queryFilterFieldsPastWO, name, value),
            offsetPastWO: 0,
            paginationPage: 1
        }, () => {
            this.fetchDataPastWO()
        })
    }

    handleUpdateOffsetPastWO = (offset, num) => {
        this.setState({
            offsetPastWO: offset,
            paginationPage: num
        }, () => {
            this.fetchDataPastWO()
        })
    }

    handleFilterInputChangeCurrentWO = (name, value) => {
        this.setState({
            queryFilterFieldsCurrentWO: FieldsManager.updateField(this.state.queryFilterFieldsCurrentWO, name, value),
            offsetCurrentWO: 0,
            paginationPage: 1
        }, () => {
            this.fetchDataCurrentWO()
        })
    }

    handleUpdateOffsetCurrentWO = (offset, num) => {
        this.setState({
            offsetCurrentWO: offset,
            paginationPage: num
        }, () => {
            this.fetchDataCurrentWO()
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            OrganizationID: this.props.id
        }
    }

    getQueryCurrentWO = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offsetCurrentWO,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFieldsCurrentWO),
            searchFields: JSON.stringify({
                ...({WorkOrderStatusID: ['WorkOrderStatusID', '<>', 14]}),
                TruckID: this.props.TruckID,
                TrailerID: this.props.TrailerID
            })
        }
    }

    getQueryPastWO = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offsetPastWO,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFieldsPastWO),
            searchFields: JSON.stringify({
                ...({WorkOrderStatusID: ['WorkOrderStatusID', '=', 14]}),
                TruckID: this.props.TruckID,
                TrailerID: this.props.TrailerID,
            })
        }
    }

    handleViewTruckClick = (truckID) => {
        this.props.dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    handleViewTrailerClick = (trailerID) => {
        this.props.dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    handleViewContactClick = (contactID) => {
        this.props.dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    handleVendorInfoClick = (item) => {
        this.props.dispatch(showModal('ViewVendorCard', {
            VendorID: item?.AssignedVendorID,
            OrganizationID: item?.CompanyID
        }))
    }
    /** Fields/Data definitions
     ================================================================= */
    getFieldsWO = (item = {}) => {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const workOrderStatuses = getLookup("WorkOrderStatus");

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text'),
            WorkOrder: new Field('WorkOrder', '', ['empty'], false, "text", {}, {autofocus: true}),
            RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', ['empty'], false, 'select'),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={workOrderStatuses}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            // VMRSCode: new Field('VMRSCode', '', [''], false, 'creatable-select-search', {addContainerClass: "col-span-6"}),
            // CustomCodeID: new Field('CustomCodeID', '', [''], false, 'select-search', {addContainerClass: "col-span-6"}),
            CreatedDate: new Field('CreatedDate', '', [''], false, 'datetime', {hideDialog: true}),

            DueDate: new Field('DueDate', '', [''], false, 'datetime', {addContainerClass: "col-span-6"}),

            AssignedVendorID: new Field('AssignedVendorID', '', [''], false, 'select-search', {
                render: (item) => {
                    if (item.AssignedVendorID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Vendors, UPDATE_PERM) && this.handleVendorInfoClick(item)}
                        >
                            {item.AssignedVendor}
                        </CellButton>
                    }
                }
            }, {menuPlacement: "top"}),
            AssignedContactID: new Field('AssignedContactID', '', [''], false, 'select-search', {
                render: (item) => {
                    if (item.AssignedContactID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, UPDATE_PERM) && this.handleViewContactClick(item.AssignedContactID)}
                        >
                            {item.AssignedContact}
                        </CellButton>
                    }
                }
            }, {menuPlacement: "top"}),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && this.handleViewTruckClick(item.TruckID)}
                            >
                                {item.Truck}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                },
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Trailer, READ_PERM) && this.handleViewTrailerClick(item.TrailerID)}
                            >
                                {item.Trailer}
                            </CellButton>
                        )
                    } else {
                        return item.Trailer;
                    }
                },
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            RepairCompletedDate: new Field('RepairCompletedDate', '', [''], false, 'datetime', {addContainerClass: "col-span-6"}),
            RequesterID: new Field('RequesterID', '', [''], false, 'select-search', {}, {
                menuPlacement: "top",
                isClearable: true
            }),

            Notes: new Field('Notes', '', [''], false, 'textarea', {label: "Description"}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }


    getQueryFilterFieldsPastWO = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getQueryFilterFieldsCurrentWO = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, secondResource, thirdResource} = this.props
        const secondData = getProp(this.props, "secondResource.data.list", []);
        const countSecond = getProp(this.props, "secondResource.data.count", 0);
        const thirdData = getProp(this.props, "thirdResource.data.list", []);
        const countThird = getProp(this.props, "thirdResource.data.count", 0);

        return (
            <div className="space-y-8 pb-8">
                <Card>
                    <ResourceListTab
                        tabKey={this.props.tabKey}
                        id={this.props.TruckID ?? this.props.TrailerID}
                        dispatch={this.props.dispatch}
                        translate={this.props.translate}
                        resource={this.props.resource}
                        disableEdit={this.props.disableEdit}
                        disableDelete={this.props.disableDelete}

                        dialogTitle={this.props.dialogTitle}

                        sortBy={this.props.sortBy}
                        primaryKey={this.props.primaryKey}
                        resourceName={this.props.resourceName}
                        fields={{
                            WorkOrder: new Field("WorkOrder", '', [], false, 'text', {
                                addContainerClass: "col-span-full",
                                hideDialog: true
                            }),
                            OutOfServiceDate: new Field("OutOfServiceDate", '', ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
                            ReturnsToServiceDate: new Field("ReturnsToServiceDate", '', [], false, 'date', {addContainerClass: "col-span-full"}),
                            VendorID: new Field("VendorID", '', [], false, 'select-search', {addContainerClass: "col-span-full"}),
                            ServiceNotes: new Field("ServiceNotes", '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
                        }}
                        searchFields={{
                            query: new Field('query', '', [''], false, 'search', {}),
                            limit: new Field('limit', 10, [''], false, 'select', {
                                hideLabel: true
                            })
                        }}
                        metadata={{
                            VendorID: {
                                api: "api/" + Resources.VendorsQuick,
                                query: {},
                                searchMap: (item) => ({
                                    label: item.LegalName + ", " + item.AddressName,
                                    value: item.VendorID
                                })
                            }
                        }}
                        actions={[{
                            title: () => translate("btn.view_work_order"),
                            action: (it) => checkPerm(Resources.WorkOrders, UPDATE_PERM) && openInNewTab(Env.getTruckWorkOrdersUrl("/work-orders/" + it?.WorkOrderID)),
                            icon: () => EyeIcon,
                            visible: (it) => !!it.WorkOrderID
                        }]}
                    />
                </Card>

                {checkPerm(Resources.WorkOrders, READ_PERM) && (<div>
                        <Card addClass={"mt-5"}>
                            <PageHeader
                                title={translate('page.heading.CurrentWO')}
                                titleClass="mb-5 mt-5 text-2xl"
                            />

                            <TableCard addClass="relative">
                                <TableFilters
                                    hideLimit
                                    filterFields={this.state.queryFilterFieldsCurrentWO}
                                    handleInputChange={this.handleFilterInputChangeCurrentWO}
                                    translate={translate}
                                    onRefreshTable={this.fetchDataCurrentWO}
                                    isLoading={secondResource.isLoading}
                                />

                                <ResourceTable
                                    data={secondData}
                                    fields={this.getFieldsWO()}

                                    translate={translate}
                                    isLoading={secondResource.isLoading}

                                    options={this.state.tableOptions}

                                    limit={this.state.queryFilterFieldsCurrentWO.limit.value}


                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}
                                />

                                {/*Table footer*/}
                                {!(!secondData.length && !secondResource.isLoading) && (
                                    <div
                                        className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                    >
                                        <Pagination
                                            count={countSecond}
                                            isLoading={secondResource.isLoading}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffsetCurrentWO(value, currentPage)
                                                    : this.handleFilterInputChangeCurrentWO(name, value)
                                            }
                                            pageOffset={this.state.offsetCurrentWO}
                                            queryFields={this.state.queryFilterFieldsCurrentWO}
                                            translate={translate}
                                        />
                                    </div>
                                )}

                                <NoRecords
                                    show={(secondData.length === 0) && !secondResource.isLoading}
                                    title={translate('text.no_records')}
                                    addClass={'pt-16 pb-10'}
                                />
                            </TableCard>
                        </Card>

                        <Card addClass="relative mt-5">
                            <PageHeader
                                title={translate('page.heading.PastWO')}
                                titleClass="mb-5 mt-5 text-2xl"
                            />

                            <TableCard addClass="relative z-10">
                                <TableFilters
                                    hideLimit
                                    filterFields={this.state.queryFilterFieldsPastWO}
                                    handleInputChange={this.handleFilterInputChangePastWO}
                                    translate={translate}
                                    onRefreshTable={this.fetchDataPastWO}
                                    isLoading={thirdResource.isLoading}
                                />

                                <ResourceTable
                                    data={thirdData.filter((it) => it.WorkOrderStatusID === 14)}
                                    fields={this.getFieldsWO()}

                                    translate={translate}
                                    isLoading={thirdResource.isLoading}

                                    options={this.state.tableOptions}

                                    limit={this.state.queryFilterFieldsPastWO.limit.value}
                                />

                                {/*Table footer*/}
                                {!(!thirdData.length && !thirdResource.isLoading) && (
                                    <div
                                        className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                    >
                                        <Pagination
                                            count={countThird}
                                            isLoading={thirdResource.isLoading}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffsetPastWO(value, currentPage)
                                                    : this.handleFilterInputChangePastWO(name, value)
                                            }
                                            pageOffset={this.state.offsetPastWO}
                                            queryFields={this.state.queryFilterFieldsPastWO}
                                            translate={translate}
                                        />
                                    </div>
                                )}

                                <NoRecords
                                    show={(thirdData.length === 0) && !thirdResource.isLoading}
                                    title={translate('text.no_records')}
                                    addClass={"pt-16 pb-10"}
                                />
                            </TableCard>
                        </Card>
                    </div>
                )}
            </div>
        )
    }
}

