import {call, put, takeEvery} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {doneGetStackedResource, errorGetStackedResource} from "../actions/stackedResource";

export function* getStackedResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetStackedResource({resource: action.data.resource, data: result.data}));
    } else {
        yield put(errorGetStackedResource({resource: action.data.resource, data: result.data}));
    }
}

export function* watchGetStackedResource() {
    yield takeEvery('GET_STACKED_RESOURCE', getStackedResourceCall);
}
