import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {fillFieldsFromData} from '../../../common/util/util-fields';
import LocalStorage from '../../../util/localStorage'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import Tippy from '@tippyjs/react'
import Square2StackIcon from '@heroicons/react/20/solid/Square2StackIcon'
import Resources from '../../../data/services/resources'
import {CREATE_PERM, DEFAULT_CRUD_STATE} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import InfoParagraph from "../../../common/components/info-paragraph";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Button from "../../../common/components/button";

export default class TruckTypeTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            searchFields: this.getSearchFields(),

            fields: this.getFields(),

            selectedItem: null,
            copyTypesModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => this.fetchData())
    }
    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData())
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleTypesCopy = () => {
        this.setState({
            copyTypesModalOpen: !this.state.copyTypesModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            searchFields: FieldsManager.updateField(this.state.searchFields, name, value),
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleInputChange = (fields, name, value) => {
        if (name === "TruckTypeID") {
            const TruckSubTypes = getProp(LocalStorage.get('lookup'), 'TruckType', []).find(it => it.TruckTypeID == value)
            fields['TruckTypeSubItemID'].props.values = getProp(TruckSubTypes, 'TruckTypeSubItems', []).reduce((memo, it) => {
                memo[it.TruckTypeSubItemID] = it.TruckTypeSubItem
                return memo
            }, {})
            fields['TruckTypeSubItemID'].value = ''
        }
        return FieldsManager.updateField(fields, name, value)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? ''
    }

    getResourceName = () => {
        return this.props.resourceName ?? ''
    }

    getQuery = () => {
        return Object.assign({}, {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,

            ...FieldsManager.getFieldKeyValues(this.state.searchFields)
        })
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            TruckTypeID: new Field('TruckTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-3'}, {}),
            TruckTypeSubItemID: new Field('TruckTypeSubItemID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-3'}, {}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-3'}),
        }

        if (item && item.TruckTypeSubItemID) {
            const TruckSubTypes = getProp(LocalStorage.get('lookup'), 'TruckType', []).find(it => it.TruckTypeID == item.TruckTypeID)
            fieldTemplates['TruckTypeSubItemID'].props.values = getProp(TruckSubTypes, 'TruckTypeSubItems', []).reduce((memo, it) => {
                memo[it.TruckTypeSubItemID] = it.TruckTypeSubItem
                return memo
            }, {})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getSearchFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
            }),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true})

        }
    }

    getCopySpecFromFields = () => {
        return {
            SourceTruckID: new Field('SourceTruckID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            OverwritePreviousValues: new Field('OverwritePreviousValues', 1, [''], false, 'checkbox', {addContainerClass: 'col-span-3'}),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, disableEdit, disableDelete,} = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)

        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <div>
                <div className={"flex"}>
                    <ActiveFilters
                        filterFields={this.state.searchFields}
                        onLabelClick={this.handleFilterInputChange}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <Tippy content={translate('text.CopySpecFrom')}>
                            <button
                                className="btn btn-header mr-5"
                                onClick={this.handleToggleTypesCopy}
                            >
                                <Square2StackIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <Button
                            hasPerm={checkPerm(this.getResourceName(), CREATE_PERM)}
                            onClick={this.handleToggleCreateModal}
                            appearance="primary"
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                {this.props.infoParagraph && (
                    <div className="mt-2">
                        <InfoParagraph className="" message={this.props.infoParagraph}/>
                    </div>
                )}

                <TableCard addClass={"mt-2"}>
                    <TableFilters
                        hideLimit
                        onRefreshTable={this.fetchData}
                        isLoading={isLoading}
                        filterFields={this.state.searchFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                    />
                    <ResourceTable
                        data={data}
                        count={count}

                        fields={this.props.tableFields ?? this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.searchFields?.limit?.value ?? this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}
                        paginationButtonLimit={5}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onEdit={!disableEdit ? this.handleToggleEditModel : null}
                        onDelete={!disableDelete ? this.handleToggleConfirmModal : null}

                        actions={this.props.onCustomActions ? this.props.onCustomActions(this.getQuery()) : []}

                    />
                    {!(!data.length && !this.props.resource.isLoading) && (
                        <div
                            className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                        >
                            <Pagination
                                count={count}
                                isLoading={this.props.resource.isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.searchFields}
                                translate={translate}
                            />
                        </div>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !isLoading}
                        title={'No matching records found'}

                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={{}}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>

                <ModalSaveResource
                    title={'Edit ' + (this.props.dialogTitle ? this.props.dialogTitle : '')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    handleAddPhoneField={() => {
                    }}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId()
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: this.props.dialogTitle + ' updated.'
                            }))
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    handleInputChange={this.handleInputChange}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={'Create ' + (this.props.dialogTitle ? this.props.dialogTitle : '')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId()
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: this.props.dialogTitle + ' created.'
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    handleInputChange={this.handleInputChange}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalSaveResource
                    show={this.state.copyTypesModalOpen}
                    title={'Copy Spec From Another Truck'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleTypesCopy}
                    fields={this.getCopySpecFromFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.TargetTruckID = this.getId()
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.TrucksTypeCopy,
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: 'Copied spec from another truck'
                            }))
                            this.handleToggleTypesCopy()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        SourceTruckID: {
                            api: 'api/' + Resources.TrucksQuick,
                            query: {
                                ExcludeIDs: this.getId(),
                                limit: 30,
                                offset: 0
                            },
                            searchMap: (item) => ({
                                value: item.TruckID,
                                label: item.Truck
                            })
                        }
                    }}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('text.confirm_delete')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName(),
                            errorMessage: true, successMessage: this.props.dialogTitle + ' delete.'
                        }))
                        this.handleToggleConfirmModal(false)
                    }}
                />
            </div>
        )
    }
}
