import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, getDefaultTableOptions, getProp} from '../../../common/util/util-helpers'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    UPDATE_PERM
} from "../../../util/util-constants";
import {PencilIcon} from "@heroicons/react/24/outline";
import {createSecondResource, getSecondResource, updateSecondResource} from "../../../data/actions/secondResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

export default class MoneyCodesSettings extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_settings"
        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            createModalOpen: false,
            editModalOpen: false,
            selectedItem: null

        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchMoneyCodesSettingsData();
    }


    /** Data Events
     ================================================================= */
    fetchMoneyCodesSettingsData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: this.getSettingsResource(),
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'query', 'offset'])
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchMoneyCodesSettingsData()
        })
    }

    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value)


        this.setState({
            fields: fields
        })
    }


    handleFilterInputChange = (name, value) => {
        let queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchMoneyCodesSettingsData()
        })
    }


    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchMoneyCodesSettingsData()
        })
    }
    /** Helpers
     ================================================================= */
    getSettingsResource = () => {
        return Resources.MoneyCodeType
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            Reason: new Field('Reason', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            IsCompanyExpense: new Field('IsCompanyExpense', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),

        }

        return fillFieldsFromData(fieldTemplates, item)
    }
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    render() {
        const {translate, secondResource} = this.props

        const data = getProp(this.props.secondResource, 'data.list', [])
        const count = getProp(this.props.secondResource, 'data.count', 0)

        return (
            <div>
                <PageHeader
                    buttonLabel={translate('btn.create_new')}
                    buttonsClassName={"ml-auto space-x-3 flex m-5"}
                    onButtonClick={() => this.handleToggleCreateModal()}
                    buttonHidden={!checkPerm(this.getSettingsResource(), CREATE_PERM)}
                />
                <div className="flex">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchMoneyCodesSettingsData}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        // verticalTableIsVisible={this.state.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={secondResource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                action: this.handleToggleEditModal,
                                icon: PencilIcon, // make this a function
                                visible: () => checkPerm(this.getSettingsResource(), UPDATE_PERM),
                                label: false, // make this a function
                                title: translate('btn.edit')
                            },
                        ]}
                    />

                    <TableCardFooter
                        show={!(!data.length && !secondResource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={secondResource.isLoading}
                            // hideRowsPerPage={this.state.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('text.create_money_code_type')}
                    widthClass="max-w-[32rem]"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createSecondResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getSettingsResource(),
                                piggyResource: this.getSettingsResource(),
                                errorMessage: true, successMessage: this.props.translate('text.money_code_type_created')
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={translate}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY,
                            searchMap: (it) => ({
                                label: it.AccountNumber + ' ' + it.AccountName,
                                value: it.AccountID
                            })
                        }
                    }
                    }
                />

                <ModalSaveResource
                    show={!!this.state.editModalOpen}
                    title={'Edit reason'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        params.MoneyCodeTypeID = this.state.selectedItem.MoneyCodeTypeID
                        if (params) {
                            this.props.dispatch(updateSecondResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getSettingsResource(),
                                piggyResource: this.getSettingsResource(),
                                errorMessage: true, successMessage: 'Reason updated.',
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY,
                            searchMap: (it) => ({
                                label: it.AccountNumber + ' ' + it.AccountName,
                                value: it.AccountID
                            })
                        }
                    }
                    }
                />
            </div>

        )
    }
}
