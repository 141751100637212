import {
    ArchiveBoxIcon,
    ArrowUturnLeftIcon,
    CheckCircleIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import {classNames, getLookup} from "../../util/util-helpers";
import React from "react";
import {toFrontDate, toFrontDateTime, toFrontDateTimeFromUTC} from "../../util/util-dates";
import {formatMoney} from "../../util/util-formaters";
import {genericMoneyFormatter} from "../../util/util-vanilla";
import {numberWithCommas} from "../../../util/util-formaters";
import Tooltip from "../tooltip";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {showGlobalModal} from "../../../data/actions/ui";
import FieldText from "../fields/field-text";
import axios from "axios";
import Env from "../../../util/env";
import {getJWT} from "../../util/util-auth";
import {processResponse} from "../../../data/services/api-util";
import {getColumnFilterStyles} from "../../../data/themes";
import FieldDropdownSelect from "../fields/field-dropdown-select";
import FieldSelectSearch from "../fields/field-select-search";

const rightAlignedFiledTypes = ['integer', 'money', 'float']; // add more
const centerAlignedFiledTypes = ['checkbox', 'date', 'datetime', 'datetimezone'];

function sortHeaders(a, b, options) {
    return options?.columns?.[a?.name]?.order - options?.columns?.[b?.name]?.order;
}

function getColumnAlignment(column) {
    const fieldType = column.type;

    if (column?.metadata?.tableAlign) {
        return column?.metadata?.tableAlign;
    }

    if (rightAlignedFiledTypes.includes(fieldType)) {
        return "right";
    }

    if (centerAlignedFiledTypes.includes(fieldType)) {
        return "center";
    }

    return "left"
}

export function mergeCRUDActions(actions, onView, onEdit, onDelete, onRestore, translate, hasViewPerm, hasUpdatePerm, hasDeletePerm, hasRestorePerm) {
    let actionsClone = [...actions];

    if (!actionsClone) {
        actionsClone = [];
    }

    if (onView) {
        actionsClone.push({
            action: onView,
            hasPerm: hasViewPerm,
            icon: EyeIcon,
            order: 10,
            key: "view",
            title: translate("btn.view")
        });
    }

    if (onEdit) {
        actionsClone.push({
            action: onEdit,
            hasPerm: hasUpdatePerm,
            icon: PencilIcon,
            order: 20,
            key: "edit",
            title: translate("btn.edit")
        });
    }

    if (onDelete) {
        actionsClone.push({
            action: onDelete,
            hasPerm: hasDeletePerm,
            icon: onRestore ? ArchiveBoxIcon : TrashIcon,
            visible: (item) => !item["ArchivedDate"],
            order: 30,
            key: "delete",
            title: onRestore ? translate("btn.archive") : translate("btn.delete")
        });
    }

    if (onRestore) {
        actionsClone.push({
            action: onRestore,
            hasPerm: hasRestorePerm,
            icon: ArrowUturnLeftIcon,
            visible: (item) => !!item["ArchivedDate"],
            order: 40,
            key: "restore",
            title: translate("btn.restore")
        });
    }

    actionsClone.map(action => {
        return action;
    });

    return actionsClone;
}

function ButtonIcon({button, rowData, rowKey, className}) {
    if (!button.icon) {
        return null;
    }

    let buttonIconClass = className ?? "w-5 h-5";

    if (button.iconClass) {
        buttonIconClass = typeof button.iconClass === 'function'
            ? button.iconClass(rowData, rowKey)
            : button.iconClass
    }

    if (typeof button.icon !== 'function') {
        return <button.icon
            className={buttonIconClass}
        />
    }

    if (typeof button.icon === 'function') {
        let ButtonIcon = button.icon(rowData, rowKey);

        return <ButtonIcon
            className={buttonIconClass}
        />
    }
}

export function ButtonsActions({actions, rowData, buttonClass, rowKey, dispatch}) {
    return (
        <div className="space-x-2 whitespace-nowrap">
            {actions
                .filter(button => button.visible === undefined || button.visible(rowData, rowKey))
                .sort((a, b) => a.order - b.order)
                .map((button, btnIndex) => {
                    const hasTooltip = typeof button.tooltipText === "function";

                    const hasPerm = typeof button.hasPerm === "function"
                        ? button.hasPerm(rowData)
                        : button?.hasPerm !== false;

                    const buttonTitle = (typeof button.title === "function" ? button.title(rowData, rowKey) : button.title);

                    return (
                        <Tooltip
                            key={button.key ?? btnIndex}
                            content={hasTooltip && button.tooltipText()}
                            disabled={!hasTooltip}
                        >
                            <button
                                disabled={button.disabled}

                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (hasPerm) {
                                        !!button.action && hasPerm && button.action(rowData, rowKey)
                                    } else {
                                        dispatch(showGlobalModal('NoPermissionModal'))
                                    }
                                }}
                                className={hasPerm
                                    ? button.class ? button.class : buttonClass
                                    : "focus:outline-none focus:ring-1 rounded-btn ring-red-600 p-2 text-tm-gray-500 cursor-default"
                                }
                                title={
                                    buttonTitle
                                        ? buttonTitle + (!hasPerm ? " - ( Permission required! )" : "")
                                        : undefined
                                }
                            >
                                <ButtonIcon
                                    className={
                                        classNames(
                                            "w-5 h-5",
                                        )
                                    }
                                    button={button}
                                    rowData={rowData}
                                    rowKey={rowKey}
                                />

                                {!!button.customContent && button.customContent(rowData, rowKey)}

                                {!hasPerm && (
                                    <LockClosedIcon
                                        className="w-3 h-3 text-red-500 outline-0 absolute -translate-y-2.5 translate-x-3"/>
                                )}

                                {
                                    typeof button.label === "function"
                                        ? <span
                                            className={button.iconPosition === 'right' && "order-1"}>
                                        {() => button.label(rowData, rowKey)}
                                    </span>

                                        : <span className={button.iconPosition === 'right' ? "order-1" : undefined}>
                                        {button.label}
                                    </span>
                                }
                            </button>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
}

export function getDefaultRowMinHeight(options) {
    if (options?.style?.superCondensed) {
        return 24;
    }

    if (options?.style?.condensed) {
        return options?.style?.condensedHeight ?? 36;
    }

    return 48;
}

export function getHeadersData(fields, options, headersRefs, onSelectRow, onSortChange, mergedActions, tableID, translate) {
    let headers = Object.values(fields)
        .filter(it => !it?.metadata?.hideTable && options?.columns?.[it.name]?.show !== false);

    headers = headers.sort((a, b) => sortHeaders(a, b, options))
        .reduce((memo, it, i) => {
            let isFrozen = options?.columns?.[it.name]?.frozen;
            //const isSortable = !!onSortChange && !it?.metadata?.omitSort; // hardCoded for now because of the menu testing

            let additionalClass = !onSelectRow && !i ? ' tc-first' : '';
            additionalClass = additionalClass + (headers.length - 1 === i && options?.style?.floatingActions ? ' tc-last' : '')

            const keyPlusID = tableID + "-" + it.name;
            let header = {
                order: i,
                key: it.name,
                keyPlusID: keyPlusID,
                content: options?.columns[it.name]?.label ?? translate(it.name),
                class: options?.behaviour?.hasVirtualRows
                    ? `vth-${tableID}-default vth-` + keyPlusID + additionalClass
                    : `th-${tableID}-default th-` + keyPlusID + additionalClass,
                cellClass: options?.behaviour?.hasVirtualRows
                    ? `vtc-${tableID}-default vtc-` + keyPlusID + additionalClass
                    : `tc-${tableID}-default tc-` + keyPlusID + additionalClass,
                style: {},
                align: getColumnAlignment(it),
                ref: (element) => headersRefs.current[it.name] = element,
                subColumns: options?.columns?.[it.name]?.subColumns?.reduce((memo, it) => {
                    memo.push(Object.values(it)?.[0]);
                    return memo;
                }, []),
                isFrozen: isFrozen,
                isTruncate: true,
                canAdjustWidth: options?.behaviour?.canAdjustWidth ?? false,
                isSortable: !!onSortChange && !it?.metadata?.omitSort,
                hasMenu: options?.behaviour?.hasMenu ?? false
            }

            memo.push(header);
            return memo;
        }, []);

    if (onSelectRow) {
        headers.unshift({
            key: "select-row-column",
            keyPlusID: tableID + "-select-row-column",
            class: "vth-select-row-column",
            isFrozen: headers?.[0]?.isFrozen,
            cellClass: "vtc-select-row-column",
            columnWidth: 48
        });
    }

    let actionsColWidth = 0;
    if (mergedActions.length) {
        let actionsCount = mergedActions.length;

        if (mergedActions.find(it => it.key === 'restore')) {
            actionsCount = actionsCount - 1;
        }

        actionsColWidth = (actionsCount * 36) + ((actionsCount + 1) * 8);

        headers.push({
            key: "actions",
            keyPlusID: tableID + "-actions",
            isFloating: !!options?.style?.floatingActions,
            class: "vth-" + tableID + "-actions",
            cellClass: "vtc-" + tableID + "-actions",
            content: "Actions",
            isFrozen: options?.style?.frozenActionColumn || options?.style?.floatingActions,
            style: {
                // width: options?.style?.floatingActions ? 0 : actionsColWidth + "px",
                // minWidth: options?.style?.floatingActions ? 0 : actionsColWidth + "px",
                // padding: options?.style?.floatingActions ? 0 : actionsColWidth + "px"
                width: 0,
                minWidth: 0,
                padding: 0
            },
            columnWidth: options?.style?.floatingActions ? 0 : actionsColWidth
        });
    }

    return headers;
}

export function fieldToCell(fields, name, item, modifiedItem) {
    let field = fields[name];
    let value = item[name];

    if (fields[name]?.metadata?.render) {
        return fields[name].metadata.render(item);
    }

    switch (field?.type) {
        case 'hidden':
            return null;
        case 'date':
            return toFrontDate(value);
        case 'datetime':
            return toFrontDateTime(value);
        case 'datetimezone':
            return toFrontDateTimeFromUTC(value);
        case 'checkbox':
        case 'switch':
            // yes, no, empty, customize labels and icons (make default imports)
            return value
                ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
                : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>

        case 'select':
            return modifiedItem[name + "_select"];
        case 'select-search':
            return item[name.replace("ID", "")];
        case 'float':
        case 'float_up_to_12_not_require':
        case 'float_not_require':
        case 'float_up_to_100':
            return numberWithCommas(value)
        case 'integer':
        case 'integer_or_empty':
        case 'integer_not_require':
        case 'integer_up_to_100':
        case 'integer_up_to_256':
            return formatMoney(value, 0, ".", ",")
        case 'money':
            return genericMoneyFormatter(value, 0, ".", ",")
        default:
            return value;
    }
}


export function getColumns(fields, translate) {
    return Object.values(fields).filter(field => !field?.metadata?.hideTable).reduce((memo, it, index) => {
        const defaultLabel = fields[it.name]?.metadata?.label ? translate("field." + fields[it.name].metadata.label) : translate("field." + it.name);

        memo[it.name] = {
            name: it.name,
            label: defaultLabel,
            frozen: !index,
            type: it.type,
            show: true,
            showMobile: index < 4,
            isMerged: false,
            merged: [],
            minWidth: fields[it.name].metadata?.minWidth
        };
        return memo
    }, {});
}

export function mergeColumns(options, tableCustomDefaults) {
    return Object.keys(options["columns"]).reduce((columnsMemo, column) => {
        if (tableCustomDefaults["columns"][column]) {
            columnsMemo[column] = Object.assign({}, options["columns"][column], tableCustomDefaults["columns"][column]);
        } else {
            columnsMemo[column] = options["columns"][column];
        }

        return columnsMemo;
    }, {});
}

export function getColumnFilterField(fields, columnData, handleInputChange, selects, translate) {

    const item = fields?.[columnData.key];

    if (!item) {
        return null;
    }

    switch (item.type) {
        case "select": {
            let values;
            if ((typeof selects[item.name] === "function")) {
                values = selects[item.name](item, fields, handleInputChange);
            } else if (selects[item.name]) {
                values = selects[item.name];
            } else {
                values = getLookup(item.name.replace("ID", ""));
            }

            return <FieldSelectSearch
                hasPortal={true}
                isClearable={true}
                disabled={!!fields?.[columnData.key]?.['metadata']?.['disableColumnFilter']}
                name={columnData.key}
                addClass="table-header-filter"
                values={values}
                value={fields?.[columnData.key]?.["value"]}
                onChange={handleInputChange}
                inputStyles={getColumnFilterStyles()}
                isMulti={fields?.[columnData.key]?.props?.isMulti}
                translate={translate}
            />
        }
        case "select-search":
        case "multi-select-search":
            return <FieldDropdownSelect
                isClearable={true}
                hasPortal={true}
                disabled={!!fields?.[columnData.key]?.['metadata']?.['disableColumnFilter']}
                name={columnData.key}
                value={fields?.[columnData.key]?.["value"]}
                onChange={handleInputChange}
                addClass="table-header-filter"
                multi={item.props?.isMulti || item.type === "multi-select-search"}
                inputStyles={getColumnFilterStyles()}
                defaultOptions={true}
                formatCreateLabel={item.props.formatCreateLabel}
                loadOptions={
                    (inputValue, callback) => {
                        axios.get(
                            Env.getApiUrl(selects[item.name].api, Object.assign(selects[item.name].query ? selects[item.name].query : {}, {query: inputValue})),
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getJWT().access_token
                                }
                            }
                        )
                            .then((response) => {
                                const result = processResponse(response);
                                if (result && result.status === 0) {
                                    const list = result.data.list.map((it) => {
                                        return selects[item.name].searchMap(it);
                                    });
                                    callback(list);
                                }
                            })
                            .catch((error) => {
                                console.log("Error", error)
                            });
                    }
                }
            />
        case "custom":
        default:
            return <FieldText
                clear={() => handleInputChange(columnData.key, "")}
                disabled={!!fields?.[columnData.key]?.['metadata']?.['disableColumnFilter']}
                placeholder={fields?.[columnData.key]?.props?.placeholder
                    ?? (fields?.[columnData.key]?.['metadata']?.['disableColumnFilter']
                            ? ""
                            : "Filter..."
                    )
                }
                name={columnData.key}
                value={fields?.[columnData.key]?.["value"]}
                addClass={"table-header-filter"}
                onChange={handleInputChange}
                translate={translate}
            />
    }
}