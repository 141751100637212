const sidebarResource = (state = {}, action) => {
    switch (action.type) {
        case 'RESET_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'GET_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'GET_INFINITE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                isLoading: action.data.reset,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_INFINITE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                data: {
                    count: action.data.count,
                    list: (!!state.data && state.data.list && !action.data.reset) ? state.data.list.concat(action.data.list) : action.data.list
                },
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_INFINITE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CREATE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_CREATE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null,
                showRedirectDialog: action.data.showRedirectDialog
            });
        case 'ERROR_CREATE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPDATE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_UPDATE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_UPDATE_SIDEBAR_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        default:
            return state;
    }
};

export default sidebarResource;
