import React from 'react';

export default function TableFooter({tfoot, headerData, rowStyle, footerCellRender, getFooterRowClass}) {
    if (!tfoot) {
        return null;
    }
    return (
        <footer className="table-footer-group">
            <div 
                className={getFooterRowClass()}
                style={rowStyle('table-footer')}
            >
                {headerData.map((header) => {
                    return footerCellRender
                        ? footerCellRender(tfoot, header)
                        : <td 
                            key={header.key} 
                            style={header.style}
                        >
                            {typeof header === 'string' ? tfoot[header] : tfoot[header.key]}
                        </td>

                })}
            </div>
        </footer>
    )
}
