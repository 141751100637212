import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_CRUD_STATE, DEFAULT_QUERY_LIMIT,} from '../../../util/util-constants'
import {getDefaultTableOptions, getProp, mergeDeep, verifyFilterFields} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import moment from 'moment-timezone'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";

export default class CustomerRevenue extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: true
            },
            columns: {}
        }

        this.pagePath = "customer-revenue";

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            selectedItem: null,
            createModalOpen: false,
            confirmModalOpen: false
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_revenue_by_customer_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData();
            this.saveFilters();
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.id
        fields = FieldsManager.updateField(fields, name, value)

        this.setState({
            id: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.fetchData()
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.AccountingReportsRevenueCustomer
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            OrganizationID: this.state.id?.id?.value?.value,
        }
    }

    getFields = () => {
        return {
            Organization: new Field('Organization', '', [''], false, 'text', {
                render: (it) => {
                    return it.Organization ?? it.Contact
                },
            }),
            Number: new Field('Number', '', [''], false, 'text', {}),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            Balance: new Field('Balance', '', [''], false, 'money', {}),
        }
    }

    getQueryFilterFields = () => {
        return {
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative'
            }, {
                isClearable: true,

            }),
            EndDate: new Field('EndDate', '', [], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative'
            }, {
                isClearable: true,
            }),
            IsLoadInvoice: new Field('IsLoadInvoice', '', [''], false, 'checkbox', {label: 'onlyLoadRevenue'}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {
                        1: this.props.translate("text.Date"),
                        2: this.props.translate("text.PostingDate"),
                    },
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-full',
                }
            ),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <React.Fragment>
                <div className="flex mb-4">
                    <div>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />
                    </div>

                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        forceDialog
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={true}
                                gridCols={"grid-cols-1"}
                            />
                        }
                        largeGrid
                    />

                    <ResourceTable
                        maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                        data={data}
                        commonTable={true}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        limit={this.state.queryFilterFields.limit.value}
                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                    />

                    {/*Table footer*/}
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>
            </React.Fragment>
        )
    }
}
