export function getHelpResource(data) {
    return {
        type: 'GET_HELP_RESOURCE',
        data: data
    };
}

export function doneGetHelpResource(data) {
    return {
        type: 'DONE_GET_HELP_RESOURCE',
        data: data
    };
}

export function errorGetHelpResource(data) {
    return {
        type: 'ERROR_GET_HELP_RESOURCE',
        data: data
    };
}

export function resetHelpResource() {
    return {
        type: 'RESET_HELP_RESOURCE',
        data: []
    }
}

export function updateHelpResource(data) {
    return {
        type: 'UPDATE_HELP_RESOURCE',
        data: data
    };
}

export function doneUpdateHelpResource(data) {
    return {
        type: 'DONE_UPDATE_HELP_RESOURCE',
        data: data
    };
}

export function errorUpdateHelpResource(data) {
    return {
        type: 'ERROR_UPDATE_HELP_RESOURCE',
        data: data
    };
}
