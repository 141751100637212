import React, { useEffect, useState } from 'react';
import ModalDefault from '../../modal/modal-default';
import { XMarkIcon } from '@heroicons/react/20/solid';
import ResourceTableNew from '..';
import Tooltip from "../../tooltip";

export default function TableBulkActions({selectedRows, children, tableKey, fields, setSelectedRows, onSelectAllClick, filterSelects, footerContent, options, translate}) {
    let updatedOptions = Object.assign({}, options);

    updatedOptions.behaviour ={
        canAdjustWidth: false,
        hasMenu: false,
        hasVirtualRows: true
    }

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [count, setCount] = useState(0);
    const [isViewSelectedOpen, setIsViewSelectedOpen] = useState(false);
    const [rows, setRows] = useState(Object.assign({}, selectedRows));

    function handleSelectRowClick(item) {
        const itemID = item[tableKey];

        let rowsClone = Object.assign({}, rows)

        if (rowsClone[itemID]) {
            delete rowsClone[itemID]
        } else {
            Object.assign(rowsClone, {[itemID]: item})
        }

        setRows(rowsClone);
    }

    function handleSelectAllClick(selectAll) {
        const data = Object.values(selectedRows);

        let selected = Object.assign({}, rows)

        if (!selectAll) {
            selected = Object.assign(selected, data.reduce((memo, it) => {
                memo[it[tableKey]] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selected)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it[tableKey].toString())) {
                    delete selected[it[tableKey]]
                }
            })
        }

        setRows(selected);
    }

    useEffect(() => {
        if (Object.values(selectedRows ?? {}).length) {
            setIsMenuVisible(true);
            setCount(Object.keys(selectedRows ?? {}).length);
            setRows(Object.assign({}, selectedRows));
        } else {
            setIsMenuVisible(false)
            setRows({});
        }
    }, [selectedRows]);

    return (
        <React.Fragment>
            {isMenuVisible && (
                <div className={'left-1/2 fixed z-50 bottom-2 flex flex-col bg-primary text-primary-contrast rounded-card animate-fade-in-bottom'}>
                    <div className='flex items-center py-1'>
                        <div className='px-2 flex'>
                                <Tooltip content={translate("text.clear_all_selected")}>
                                    <button 
                                        onClick={() => setSelectedRows({})}
                                        className='p-1 flex items-center justify-center curor-pointer hover:bg-primary-shade rounded-btn w-7 h-7'
                                    >
                                        <XMarkIcon className='w-4 h-4' />
                                    </button>
                                </Tooltip>

                            <button
                                onClick={() => setIsViewSelectedOpen(true)}
                                className='py-1 px-2 curor-pointer whitespace-nowrap hover:bg-primary-shade rounded-btn'
                            >
                                ({count}) View selected
                            </button>
                        </div>

                        <div className='w-0.5 h-5 bg-primary-shade' />

                        <div className='px-4'>
                            {children} 
                        </div>
                    </div>

                    {!!footerContent && (
                        <div className='border-t-2 border-primary-shade'>
                            {footerContent}
                        </div>
                    )}
                </div>
            )}

            {isMenuVisible && (
                <ModalDefault
                    show={isViewSelectedOpen}
                    widthClass={"max-w-7xl"}
                    translate={translate}
                    title={translate("modal_heading.review_selected_rows")}
                    buttonLabel={translate("btn.done")}
                    onButtonClick={() => {
                        setSelectedRows(rows);
                        setIsViewSelectedOpen(false);
                    }}
                    buttonDisabled={false}
                    onClose={() => setIsViewSelectedOpen(false)}
                    closeButtonLabel={translate("btn.cancel")}
                >
                    <ResourceTableNew
                        commonTable={true}
                        data={Object.values(selectedRows)}
                        count={count}
                        filterSelects={filterSelects}
                        tableKey={tableKey}
                        onSelectRow={handleSelectRowClick}
                        selectedRows={rows}
                        onSelectAllClick={handleSelectAllClick}
                        limit={Object.keys(selectedRows).length}
                        fields={fields}
                        translate={translate}

                        options={updatedOptions}                    
                    />
                </ModalDefault>
            )}
        </React.Fragment>
    )
}
