import React from 'react'

export default function BillingTab ({ translate }) {

    return (
        <table className="data-table data-table-feature dataTable no-footer">
            <tbody>
            <tr className="odd">
                <td colSpan="5" className="dataTables_empty" valign="top">
                    {translate('text.no_records')}
                </td>
            </tr>
            </tbody>
        </table>
    )
}
