import React from "react";
import {classNames} from "../../../util/util-helpers";

export default function ResourceTableTopBar({children, addClass}) {
    return (
        <div
            className={
                classNames(
                    "md:px-5 py-4 h-[4.5rem] flex items-center relative border border-tm-gray-300 bg-inverse z-30 space-x-4 rounded-t-card",
                    addClass
                )
            }
        >
            {children}
        </div>
    )
}
