import React, {useEffect, useRef, useState} from 'react';
import {
    ArrowSmallDownIcon,
    ArrowSmallUpIcon,
    ArrowsUpDownIcon,
    ExclamationTriangleIcon,
    EyeIcon,
    EyeSlashIcon,
    MapPinIcon
} from '@heroicons/react/20/solid';
import {getStopIcon} from '../../../load-utils';
import {
    CREATE_PERM,
    LOAD_STATUSES,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_PICKUP_AND_DELIVERY
} from '../../../../../../util/util-constants';
import {checkPerm, classNames, getFullAddressName, getProp} from '../../../../../../common/util/util-helpers';
import {Field} from '../../../../../../data/services/fields';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import TrucksTableBadge from '../../../../../fleet/trucks-view/trucks-table-badge';
import {CubeIcon, ScaleIcon, Squares2X2Icon, ViewColumnsIcon} from '@heroicons/react/24/outline';
import Resources from "../../../../../../data/services/resources";
import DataCard from "../../../../../../common/components/display-data/data-card";
import ResourceTable from "../../../../../../common/components/resource-table";
import Tooltip from "../../../../../../common/components/tooltip";
import Button from "../../../../../../common/components/button";

export default function LoadPlaningPreview({
                                               translate,
                                               previewData,
                                               onResetLoadReviewState,
                                               onCreateLoadClick,
                                               onCreateQuoteClick,
                                               setCommodityData,
                                               stops,
                                               setStops,
                                               isLoading,
                                               onClose
                                           }) {

    const mapRef = useRef();
    const loadPreviewMap = useRef();
    const previewRoute = useRef();

    const stopLabels = Object.values(stops).reduce((memo, stop) => {
        if (stop.StopID) {
            memo[stop.StopID?.value?.value] = stop?.StopID?.value?.label;
        }
        return memo;
    }, {})

    const loadCreationDisabled = !stops.find(stop => stop.StopID?.value?.value);

    TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_API_KEY;

    const [tab, setTab] = useState(undefined);
    const [isTableVisible, setIsTableVisible] = useState(false);

    const previewStops = sortPreviewStops();
    const NearestTrucks = getProp(previewData, 'NearestTrucks', []);

    function frameRoute() {
        previewRoute.current.frameRoute()
    }

    function handleFocusMapTruckClick(truck) {
        let sw = new TrimbleMaps.LngLat(Number(truck.Longitude) - 0.01, Number(truck.Latitude) - 0.01)
        let ne = new TrimbleMaps.LngLat(Number(truck.Longitude) + 0.01, Number(truck.Latitude) + 0.01)
        let llb = new TrimbleMaps.LngLatBounds(sw, ne);

        loadPreviewMap.current.fitBounds(llb, true)
    }

    function getSimilarLoadsFields() {
        return {
            LoadID: new Field('LoadID', '', [''], false, 'text'),
            LoadStatusID: new Field('LoadStatusID', '', [''], false, 'text'),
            Price: new Field('Price', '', [''], false, 'text'),
            PricePerMile: new Field('PricePerMile', '', [''], false, 'text', {label: "pricePerLoadedMile"}),
            TotalMilesNoStopBy: new Field('TotalMilesNoStopBy', '', [''], false, 'text'),
            IsBroker: new Field('TotalMilesNoStopBy', '', [''], false, 'text', {
                render: (it) =>
                    <React.Fragment>
                        {!!it.IsBroker && (
                            <div
                                className="px-4 text-center rounded-full font-bold py-1 bg-green-600/10 text-green-800">{translate("text.broker")}</div>
                        )}

                        {!it.IsBroker && (
                            <div
                                className="px-4 text-center rounded-full font-bold py-1 bg-blue-600/10 text-blue-800">{translate("text.carrier")}</div>
                        )}
                    </React.Fragment>

            }),
            TotalWeight: new Field('TotalWeight', '', [''], false, 'text'),
        }
    }

    function getPreviewFields() {
        return {
            TotalMiles: new Field('TotalMiles', '', [''], false, "integer", {icon: () => MapPinIcon}),
            TotalMilesNoStopBy: new Field('TotalMilesNoStopBy', '', [''], false, "integer", {icon: () => MapPinIcon}),
            TotalWeight: new Field('TotalWeight', '', [''], false, "float", {icon: () => ScaleIcon}),
            TotalVolume: new Field('TotalVolume', '', [''], false, "float", {icon: () => CubeIcon}),
            TotalPieces: new Field('TotalPieces', '', [''], false, "float", {icon: () => Squares2X2Icon}),
            TotalUnits: new Field('TotalUnits', '', [''], false, "float", {
                icon: () => ViewColumnsIcon,
                labelRaw: previewData?.TotalUnitsType
            }),
            IsHazmat: new Field('IsHazmat', '', [''], false, "checkbox", {
                customIcon: () => <ExclamationTriangleIcon className="w-5 h-5 text-red-600"/>
            }),
        }
    }

    function getNearestTruckFields() {
        return {
            TruckNumber: new Field('TruckNumber', '', [''], false, 'custom', {
                    render: (it) => <button
                        className="text-primary hover:text-tm-gray-900 hover:bg-tm-gray-200 h-12 w-full text-left px-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(`${window.location.origin}/trucks/info/${it.TruckID}`, '_blank');
                        }}
                    >
                        {it.TruckNumber}
                    </button>
                }
            ),
            StatusID: new Field('StatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <TrucksTableBadge
                        item={item}
                        translate={translate}
                    />
                )
            }),
            Distance: new Field('Distance', '', [''], false, 'text'),
            Longitude: new Field('Longitude', '', [''], false, 'hidden'),
            Latitude: new Field('Latitude', '', [''], false, 'hidden'),
            TruckID: new Field('TruckID', '', [''], false, 'hidden')
        }
    }

    function getTollsFields() {
        return {
            StCntry: new Field('StCntry', '', [''], false, 'text'),
            EstimateTollCost: new Field('EstimateTollCost', '', [''], false, 'text'),
            FreeMiles: new Field('FreeMiles', '', [''], false, 'text'),
            TollMiles: new Field('TollMiles', '', [''], false, 'text'),
            TotalMiles: new Field('TotalMiles', '', [''], false, 'text')
        }
    }

    function initMap() {
        loadPreviewMap.current = new TrimbleMaps.Map({
            container: "trimbleMap",
            center: new TrimbleMaps.LngLat(-96, 35),
            zoom: 3,
            style: TrimbleMaps.Common.Style.TRANSPORTATION,
            region: TrimbleMaps.Common.Region.NA,
            displayControlsDefault: true
        });

        loadPreviewMap.current.addControl(new TrimbleMaps.NavigationControl());

        loadPreviewMap.current.on('load', () => {
            const stops = previewStops
                .filter((item) => !!item.Longitude && !!item.Latitude)
                .map((item) => new TrimbleMaps.LngLat(item.Longitude, item.Latitude))

            previewRoute.current = new TrimbleMaps.Route({
                stops: stops,
                vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                highwayOnly: false,
                distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                tollDiscourage: true,
                bordersOpen: false
            })

            if (previewRoute?.current?._stopSourceData?.features.length) {
                previewRoute.current._stopSourceData.features.map((it, i) => {
                    it.properties.stopLabel = i + 1
                    return it;
                });
            }

            previewRoute.current.addTo(loadPreviewMap.current);

            //this.createOverlappingStops(this.props.previewStops);
        });
    }

    function sortPreviewStops() {
        if (!previewData?.Stops?.length) return [];

        const Stops = previewData.Stops.map(stop => {
            let stopPosition = {}
            stopPosition.Longitude = stop.Longitude ?? stop?.LocationData?.Longitude;
            stopPosition.Latitude = stop.Latitude ?? stop?.LocationData?.Latitude;
            stopPosition.StopOrder = stop.StopOrder;
            stopPosition.StopType = Number(stop.StopType);
            stopPosition.StopID = stop.StopID

            stopPosition.LocationName = getFullAddressName(stop);
            //stopPosition.Address = stop.GooglePlaces;

            return stopPosition;
        });


        return Stops.sort(function (a, b) {
            return a.StopOrder - b.StopOrder;
        });
    }


    useEffect(() => {
        initMap();
    }, [])

    return (
        <React.Fragment>
            <div className="flex items-stretch h-[calc(100vh-12rem)]">
                <div className="py-6 pl-6 pr-6 max-w-sm mx-r border-r border-tm-gray-300">
                    <p className="font-bold leading-5 mb-2">Calculations</p>
                    <div className="gap-y-2 gap-x-8 grid grid-cols-2">
                        {Object.values(getPreviewFields()).filter(field => {
                            if (field.name === 'TotalMilesNoStopBy') {
                                return false;
                            }

                            return !!previewData[field.name]
                        }).map(field => {
                            return (
                                <DataCard
                                    addClass="min-h-[50px]"
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={previewData}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>

                    <p className="font-bold leading-5 mb-2 mt-6">Stops</p>
                    <div className="space-y-2">
                        {previewStops.map((it, i) => {
                            let StopByIcon;
                            if (!it.StopType) {
                                StopByIcon = getStopIcon(Number(it.StopByTypeID))
                            }

                            return (
                                <div
                                    key={i}
                                    className="flex border border-tm-gray-300 rounded-xl overflow-hidden items-stretch cursor-pointer group"
                                    onClick={() => handleFocusMapTruckClick(it)}
                                >
                                    <div
                                        className="flex items-center justify-center text-base font-bold bg-tm-gray-100 pl-1 w-12 flex-shrink-0 group-hover:bg-tm-gray-200">{i + 1}
                                    </div>

                                    <div className="flex items-center pl-4 pr-8 rounded-r-full min-h-[3rem] py-1">
                                        {!StopByIcon && (
                                            <React.Fragment>
                                                {Number(it.StopType) === STOP_TYPE_PICKUP && (
                                                    <ArrowSmallUpIcon
                                                        className={classNames("mr-4 w-6 h-6 rounded-md flex-shrink-0 relative text-tm-gray-500 cursor-default border-2 border-tm-gray-400")}/>
                                                )}
                                                {Number(it.StopType) === STOP_TYPE_DELIVERY && (
                                                    <ArrowSmallDownIcon
                                                        className={classNames("mr-4 w-6 h-6 rounded-md flex-shrink-0 relative text-tm-gray-500 cursor-default border-2 border-tm-gray-400")}/>
                                                )}

                                                {Number(it.StopType) === STOP_TYPE_PICKUP_AND_DELIVERY && (
                                                    <ArrowsUpDownIcon
                                                        className={classNames("mr-4 w-6 h-6 rounded-md flex-shrink-0 relative text-tm-gray-500 cursor-default border-2 border-tm-gray-400")}/>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {!!StopByIcon && (
                                            <StopByIcon
                                                className={classNames("w-6 h-6 p-1 bg-red-600 rounded-md text-white mr-4")}/>
                                        )}

                                        <div className="flex justify-center flex-col">
                                            <p className="text-sm text-left font-bold">
                                                {stopLabels[it.StopID] ?? it.LocationName ?? it.Address}
                                            </p>

                                            {!!it.Address && !!it.LocationName && (
                                                <p className="leading-4 text-xs text-left w-full">
                                                    {it.Address}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="bg-tm-gray-100 flex flex-col grow relative">
                    <div
                        className="grow w-full relative"
                    >
                        <div
                            ref={mapRef}
                            id="trimbleMap"
                            className={"absolute inset-0 trimblemaps-map"}
                        />
                    </div>

                    <div className={
                        classNames(
                            "bg-inverse w-full flex flex-col justify-between max-h-full"
                        )}
                    >
                        {tab === 'Tolls' && isTableVisible && (
                            <div className="max-h-full scroll-overflow-y">
                                <ResourceTable
                                    data={getProp(previewData, 'Tolls', [])}
                                    fields={getTollsFields()}
                                    translate={translate}
                                    isLoading={isLoading}
                                />
                            </div>
                        )}

                        {tab === 'NearestTrucks' && isTableVisible && (
                            <div className="max-h-full scroll-overflow-y">
                                <ResourceTable
                                    data={NearestTrucks.map(it => {
                                        it.Distance = Math.round(it.Distance * 100) / 100;

                                        return it;
                                    })}
                                    fields={getNearestTruckFields()}
                                    translate={translate}
                                    isLoading={isLoading}

                                    selects

                                    onRowClick={handleFocusMapTruckClick}
                                />
                            </div>
                        )}

                        {tab === 'SimilarLoads' && isTableVisible && (
                            <div className="max-h-full scroll-overflow-y">
                                <ResourceTable
                                    data={getProp(previewData, 'SimilarLoads', []).map(it => {
                                        it.LoadStatusID = translate(LOAD_STATUSES[it.LoadStatusID]);
                                        if (it.PricePerMile) {
                                            it.PricePerMile = it.PricePerMile.toFixed(2);
                                        }
                                        return it;
                                    })}
                                    onRowClick={(it) => {
                                        window.open(`${window.location.origin}/loads/info/${it.LoadID}`, '_blank');
                                    }}
                                    fields={getSimilarLoadsFields()}
                                    translate={translate}
                                    isLoading={isLoading}
                                />
                            </div>
                        )}

                        <div
                            className={
                                classNames(
                                    "flex items-center px-4 gap-x-3 h-12 bg-tm-gray-50"
                                )
                            }
                        >
                            <button
                                onClick={() => {
                                    setTab(tab !== "Tolls" ? "Tolls" : undefined)
                                    setIsTableVisible(tab !== "Tolls")
                                }}
                                className={
                                    classNames(
                                        "btn btn-text font-semibold leading-4 px-4",
                                        tab === 'Tolls' ? "bg-primary text-inverse" : undefined
                                    )
                                }
                            >
                                Tolls
                            </button>

                            <button
                                onClick={() => {
                                    setTab(tab !== "NearestTrucks" ? "NearestTrucks" : undefined)
                                    setIsTableVisible(tab !== "NearestTrucks")
                                }}

                                className={
                                    classNames(
                                        "btn btn-text font-semibold leading-4 px-4",
                                        tab === 'NearestTrucks' ? "bg-primary text-inverse" : undefined
                                    )
                                }
                            >
                                Nearest trucks
                            </button>

                            <button
                                onClick={() => {
                                    setTab(tab !== "SimilarLoads" ? "SimilarLoads" : undefined);
                                    setIsTableVisible(tab !== "SimilarLoads")
                                }}
                                className={
                                    classNames(
                                        "btn btn-text font-semibold leading-4 px-4",
                                        tab === 'SimilarLoads' ? "bg-primary text-inverse" : undefined
                                    )
                                }
                            >
                                Similar loads
                            </button>

                            <div className="ml-auto">
                                {!!tab && (
                                    <button
                                        onClick={() => setIsTableVisible(!isTableVisible)}
                                        className="btn-icon"
                                    >
                                        {isTableVisible && (
                                            <EyeSlashIcon className="w-5 h-5"/>
                                        )}

                                        {!isTableVisible && (
                                            <EyeIcon className="w-5 h-5"/>
                                        )}
                                    </button>
                                )}

                                <button
                                    className="btn-icon"
                                    onClick={frameRoute}
                                >
                                    <MapPinIcon className="w-5 h-5"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer
                className="min-h-[4rem] flex items-center border-tm-gray-200 border-t gap-3 bg-inverse rounded-b-lg"
            >
                <div className="pl-3 pr-6 flex grow w-full relative">

                    <button
                        onClick={() => {
                            setStops([]);
                            setCommodityData([]);

                            onResetLoadReviewState();
                        }}
                        className="btn btn-outline"
                    >
                        {translate("btn.preview_from_scratch")}
                    </button>

                    <button
                        className="btn btn-outline ml-20"
                        onClick={onResetLoadReviewState}
                    >
                        {translate("btn.update_plan")}
                    </button>
                </div>

                <div className="pl-3 pr-6 flex grow w-full relative">
                    <div className="ml-auto space-x-3 flex">
                        <Tooltip disabled={!loadCreationDisabled} content={translate("text.StopID_needed")}>
                            <div>
                                <button
                                    className="btn btn-outline"
                                    disabled={loadCreationDisabled}
                                    onClick={onCreateQuoteClick}
                                >
                                    {translate("btn.create_quote")}
                                </button>
                            </div>
                        </Tooltip>

                        <Tooltip disabled={!loadCreationDisabled} content={translate("text.StopID_needed")}>
                            <div>
                                <Button
                                    hasPerm={checkPerm(Resources.LoadPreview, CREATE_PERM)}
                                    isLoading={isLoading}
                                    className="btn btn-primary"
                                    disabled={loadCreationDisabled}
                                    onClick={onCreateLoadClick}
                                >
                                    {translate("btn.create_load")}
                                </Button>
                            </div>
                        </Tooltip>

                        <button
                            className="btn btn-outline"
                            onClick={onClose}
                        >
                            {translate("btn.close")}
                        </button>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
