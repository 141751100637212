import React, {useEffect, useRef, useState} from "react";
import TrimbleMap
    from "../../map/TrimbleMap";

export default function FleetShareTableMap({selectedLocation}) {
    const mapRef = useRef();

    const [initializeMap, setInitializeMap] = useState(false)

    useEffect(() => {
        setTimeout(() => setInitializeMap(true), 300)
    }, []);

    return <div className="relative h-[calc(100vh-12rem)]">
        {initializeMap && (
        <TrimbleMap
            className={"absolute inset-0"}
            stops={[]}
            mapRef={mapRef}
            tracks={[selectedLocation]}
        />
        )}
    </div>
}