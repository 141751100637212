import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {classNames} from "../../util/util-helpers";
import {DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS} from "../../../util/util-constants";

export default function DropZone({maxFilesAccepted = 1, onAcceptFiles, translate}) {
    const onDrop = useCallback(acceptedFiles => {
        onAcceptFiles(acceptedFiles);
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({multiple: maxFilesAccepted > 1, maxFiles: maxFilesAccepted, onDrop, accept: DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS});

    return (
        <div
            className={classNames(
                isDragAccept ? "border-green-600 bg-green-600 bg-opacity-10" : "border-tm-gray-200",
                isDragReject ? "border-red-600 bg-red-600 bg-opacity-10" : "border-tm-gray-200",
                "text-center absolute inset-6 rounded-xl border-2 border-dashed flex items-center justify-center")
            }
            {...getRootProps()}
        >
            <input {...getInputProps()} />

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true"
                     className="w-10 h-10 text-tm-gray-700 inline-block mb-4"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>

                {
                    <p className="text-xl text-tm-gray-900">{translate("text.drop_zone_line_1")}<br />{translate("text.drop_zone_line_2")}</p>

                }

                <p className={isDragReject ? "text-red-500" : "invisible"}>{translate("text.drop_zone_accepted_images")}</p>
            </div>
        </div>
    )
}