import {Field, FieldsManager} from "../../../../data/services/fields";
import React, {useState} from "react";
import {getDefaultTableOptions, getProp} from "../../../../common/util/util-helpers";
import {calculateLoanPayment, planLoanPayments} from "../../../../common/util/util-accounting";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../../common/components/resource-table";

export default function ReviewLoanModalScheduledPaymentsTab({cachedChangedFields, isLoading, translate}) {
    const tableOptions = getTableOptions("loan_modal_scheduled_payments", translate);

    function getTableOptions(pagePath, translate) {
        return getDefaultTableOptions(getFields(translate), {
            behaviour: {
                rowSelect: true,
            }
        }, pagePath, translate)
    }


    const values = FieldsManager.getFieldKeyValues(cachedChangedFields);

    const Amount = (getProp(values, 'Amount', 0))
    const ResidualValue = getProp(values, 'ResidualValue', 0)
    const InterestRate = (getProp(values, 'InterestRate', 0))
    const LoanTerm = (getProp(values, 'LoanTerm', 0))
    const downPayment = (getProp(values, 'DownPayment', 0))

    let paymentBreakdown
    if ((Amount > 0) && (InterestRate >= 0) && (LoanTerm > 0)) {

        // paymentBreakdown = calculateLoanPayment(Amount - ResidualValue, InterestRate, LoanTerm, downPayment)

        paymentBreakdown = planLoanPayments(
            values.StartDate,
            Amount,
            ResidualValue,
            InterestRate,
            LoanTerm,
            downPayment
        );
    }

    const [offset, setOffset] = useState(0);
    const limit = 12;

    let monthlyBreakdown = paymentBreakdown?.monthlyBreakdown
    let monthlyBreakdownSlice = []

    if (monthlyBreakdown?.length) {
        monthlyBreakdownSlice = monthlyBreakdown.slice(Number(offset), Number(offset) + Number(limit))
    }

    const handleUpdateOffset = (name, value) => {
        if (name === 'offset') {
            setOffset(value)
        }
    }

    function getFields() {
        return {
            PaymentNumber: new Field('PaymentNumber', '', [''], false, 'text'),
            PaymentDate: new Field('PaymentDate', '', [''], false, 'date'),
            BeginningBalance: new Field('BeginningBalance', '', [''], false, 'money'),
            ScheduledPayment: new Field('ScheduledPayment', '', [''], false, 'money'),
            Principal: new Field('Principal', '', [''], false, 'money'),
            Interest: new Field('Interest', '', [''], false, 'money'),
            EndingBalance: new Field('EndingBalance', '', [''], false, 'money'),
            CumulativeInterest: new Field('CumulativeInterest', '', [''], false, 'money'),
        }
    }

    return <div>
        {(paymentBreakdown?.monthlyBreakdown?.length === 0) && !isLoading && (
            <div className="h-full flex justify-center items-center">
                <NoRecordsTable
                    className="pt-12 px-6 pb-6"
                    show={true}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                />
            </div>
        )}

        <ResourceTable
            data={monthlyBreakdownSlice}
            options={tableOptions}
            fields={getFields()}
            translate={translate}
        />

        {(paymentBreakdown?.monthlyBreakdown?.length > limit) && (
            <div className="px-6 py-2">
                <Pagination
                    hideRowsPerPage
                    count={paymentBreakdown?.monthlyBreakdown?.length ?? 0}
                    handleQueryChange={
                        (name, value) => handleUpdateOffset(name, value)}
                    pageLimit={limit}
                    pageOffset={offset}
                    translate={translate}
                />
            </div>
        )}
    </div>
}