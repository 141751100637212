import React, {Component} from 'react'
import Tippy from '@tippyjs/react'
import Resources from '../../../../data/services/resources'
import ShareIcon from '@heroicons/react/20/solid/ShareIcon'
import {READ_PERM} from '../../../../util/util-constants'
import {checkPerm, classNames, getProp} from '../../../../common/util/util-helpers'
import {copyToClipboard} from '../../../../common/util/util-vanilla'
import {HourglassIcon} from "../../../../data/themes/icons";
import {TagIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon as CheckCircleIconMini} from "@heroicons/react/20/solid";
import PopOver from "../../../../common/components/popover";
import TableTagManagerPopover
    from "../../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-popover";
import ButtonIcon from "../../../../common/components/button/button-icon";

export default class LoadInfoAccess extends Component {

    constructor(props) {
        super(props)
        this.state = {
            urlCopied: false
        }
    }

    copyToClipboard = (link) => {
        this.setState({
            urlCopied: true
        }, () => copyToClipboard(window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + link))

        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }

    render() {
        const {
            resource,
            className,
            toggleHistoryDialog,
            tagPopoverButtonRef,
            colorTags,
            MarkLoad,
            translate,
            onTagLoad,
            toggleShareCustomerDialog,
            selectedColorTabs,
            toggleTagManagerModal
        } = this.props
        const info = getProp(resource.data, 'load/info', {})

        const taggedRows = selectedColorTabs.split("|").reduce((memo, it) => {
            memo[it] = [1]
            return memo;
        }, {});

        return (
            <div className={className}>
                <div className="flex items-center relative space-x-3">
                    {MarkLoad}

                    <PopOver
                        btnClass={"text-opacity-90 btn btn-header"}
                        tippy={translate("text.label_load")}
                        BtnIcon={TagIcon}
                        btnIconClass="text-tm-gray-600 w-5 h-5"
                        widthClass={'max-w-[16rem]'}
                        positionClass="fixed md:absolute right-0 mx-5 md:mx-0 translate-x-0"
                        PopoverButtonRef={tagPopoverButtonRef}
                    >
                        <div
                            className="bg-popup border border-tm-gray-300 rounded-md"
                        >
                            <TableTagManagerPopover
                                translate={translate}
                                labels={colorTags}
                                taggedRows={{
                                    list: taggedRows
                                }}
                                selectedRows={{[1]: 1}}
                                onApplySelected={(taggedRows, labelList) => onTagLoad(labelList)}
                                onManageTagsClick={toggleTagManagerModal}
                                isLoading={false}
                            />
                        </div>
                    </PopOver>

                    {!!info.ExternalAccessToken && (
                        <Tippy
                            content={this.props.allowCustomerShare ? translate('text.shared_to_customer') : translate('text.share_to_customer')}
                            delay={[400, 0]}
                            trigger={"mouseenter"}
                        >
                            <button
                                className="text-opacity-90 btn btn-header relative"
                                onClick={toggleShareCustomerDialog}
                            >
                                {!!this.props.allowCustomerShare && (
                                    <CheckCircleIconMini
                                        className="w-4 h-4 absolute -top-2 -right-1.5 text-green-600 bg-inverse rounded-full"/>
                                )}
                                <ShareIcon
                                    className={classNames(
                                        "h-5 w-5",
                                        this.props.allowCustomerShare ? "text-green-600" : ""
                                    )}
                                />
                            </button>
                        </Tippy>
                    )}

                    <ButtonIcon
                        type="button"
                        tooltip={translate('text.view_history')}
                        hasPerm={checkPerm(Resources.LoadHistory, READ_PERM)}
                        onClick={toggleHistoryDialog}
                        className="btn btn-header"
                    >
                        <HourglassIcon className="w-5 h-5"/>
                    </ButtonIcon>
                </div>
            </div>
        )
    }
}
