import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ResourceListTab from "../resource-list-tab";
import {checkPerm} from "../../../util/util-helpers";
import {DELETE_PERM, UPDATE_PERM} from "../../../../util/util-constants";
import Resources from "../../../../data/services/resources";
import {Field} from "../../../../data/services/fields";

export default function DocumentTypesTab({translate, primaryType, breakpoint}) {
    /** Store
     ================================================================= */
    const resource = useSelector((state) => state.resource);

    /** Constants
     ================================================================= */
    const dispatch = useDispatch();

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => {
        const checkboxMetadata = getPrimaryType() ?
            {
                addContainerClass: 'col-span-full', hideTable: true, hideDialog: true
            } :
            {
                addContainerClass: 'col-span-full'
            }

        return {
            DocumentType: new Field('DocumentType', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            IsDispatchDocument: new Field('IsDispatchDocument', getPrimaryType() === 'IsDispatchDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
            IsAccountingDocument: new Field('IsAccountingDocument', getPrimaryType() === 'IsAccountingDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
            IsFleetDocument: new Field('IsFleetDocument', getPrimaryType() === 'IsFleetDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
            IsCompanyDocument: new Field('IsCompanyDocument', getPrimaryType() === 'IsCompanyDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
            IsPersonnelDocument: new Field('IsPersonnelDocument', getPrimaryType() === 'IsPersonnelDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
            IsSafetyDocument: new Field('IsSafetyDocument', getPrimaryType() === 'IsSafetyDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
            IsWorkOrderDocument: new Field('IsWorkOrderDocument', getPrimaryType() === 'IsWorkOrderDocument' ? 1 : '', [], false, 'checkbox', checkboxMetadata),
        }
    }

    /** Helpers
     ================================================================= */
    const getPrimaryType = () => {
        return primaryType ?? ''
    }

    const getPrimaryKey = () => {
        return 'DocumentTypeID'
    }

    const getResourceName = () => {
        return Resources.DocumentTypes
    }

    /** Render
     ================================================================= */
    return (
        <ResourceListTab
            dispatch={dispatch}
            translate={translate}
            resource={resource}
            breakpoint={breakpoint}
            dialogTitle={translate('modal_heading.DocumentTypes')}
            tabKey={(getPrimaryType() || 'DocumentTypes') + "ListTab"}
            sortBy={'CreateUpdateDate'}
            primaryKey={getPrimaryKey()}
            resourceName={getResourceName()}
            fields={getFields()}
            additinonalQuery={getPrimaryType() ? {
                searchFields: JSON.stringify({[getPrimaryType()]: 1})
            } : {}}
            refreshLocalStorageLookups
            searchFields={{
                query: new Field('query', '', [''], false, 'search', {
                }),
                archived: new Field('archived', '', [''], false, 'checkbox'),
                limit: new Field('limit', 10, [''], false, 'select', {
                    hideLabel: true,
                })
            }}
            disableEdit={!checkPerm(Resources.DocumentTypes, UPDATE_PERM)}
            disableDelete={!checkPerm(Resources.DocumentTypes, DELETE_PERM)}
            enableRestore={checkPerm(Resources.DocumentTypes, UPDATE_PERM)}
        />
    )
}
