import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import CommodityForm from './dialogs/commodity-form'
import CommodityInfo from './dialogs/commodity-info'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    MEASURE_UNITS,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Modal from "../../../common/components/modal";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Button from "../../../common/components/button";

// @TODO: Cleanup old functions

class CommoditiesTemplatesTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_commodities_tab"

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ProductName',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            prefilled: '',

            confirmDialog: false,
            confirmDialogHideAnimation: false,
            infoDialog: false,
            infoDialogHideAnimation: false,
            isCommodityDialogOpen: false,
            isCommodityInfoDialogOpen: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10);
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'commodities_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('text.ConfirmDeleteCommodity')}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            CommodityID: item.CommodityID
                        }, this.getQuery()),
                        errorMessage: true, successMessage: `${item.ProductName} commodities deleted`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: `${this.props.translate("message.are_you_sure_restore")} ${item.ProductName}?`}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            CommodityID: item.CommodityID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `${item.ProductName} commodities restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleShowConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    toggleCommodityFormModal = (selectedItem = null, selectedItemIndex = null, prefilled = '') => {
        if (!!this.state.isCommodityDialogOpen || !!selectedItem?.target) {
            selectedItem = null
        }

        this.setState({
            isCommodityDialogOpen: !this.state.isCommodityDialogOpen,
            selectedItem: selectedItem,
            prefilled: prefilled,
        })
    }

    toggleCommodityInfoModal = (selectedItem = null) => {
        this.setState({
            isCommodityInfoDialogOpen: !this.state.isCommodityInfoDialogOpen,
            selectedItem: selectedItem
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            ProductName: new Field('ProductName', '', ['']),
            ProductNMFC: new Field('ProductNMFC', '', ['']),
            FreightClass: new Field('FreightClass', '', ['']),
            ProductCode: new Field('ProductCode', '', ['']),
            UnitType: new Field('UnitType', '', ['']),
            CountPieces: new Field('CountPieces', '', [''], false, 'integer'),
            CountPallets: new Field('CountPallets', '', [''], false, 'integer'),
            Weight: new Field('Weight', '', [''], false, 'float'),
            LWH: new Field('LWH', '', [''], false, 'custom', {
                render: (it) => <div
                    className="text-tm-gray-900 text-right">{(it.Length ?? 0) + ' - ' + (it.Width ?? 0) + ' - ' + (it.Height ?? 0)}<span
                    className="text-tm-gray-700">{' '}({this.props.translate(MEASURE_UNITS[it.MeasureUnitID])})</span>
                </div>,
                omitSort: true
            }),
            Hazmat: new Field('Hazmat', '', [''], false, 'custom', {
                render: (it) => it.Hazmat ? <ExclamationTriangleIcon className="w-5 h-5 text-red-600"/> : ''
            }),
            IsLTL: new Field('IsLTL', '', [''], false, 'custom', {
                render: (it) => it.IsLTL ? this.props.translate('text.LTL') : this.props.translate('text.TL')
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date'),
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }
    getResource = () => {
        return Resources.Commodities
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <>
                <div className="flex items-center">
                    <div>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                    </div>

                    <div className="ml-auto flex">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />

                        <Button
                            onClick={this.toggleCommodityFormModal}
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            appearance={'primary'}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.toggleCommodityFormModal : null}

                        onView={checkPerm(this.getResource(), READ_PERM) ? this.toggleCommodityInfoModal : null}
                        onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.toggleCommodityFormModal : null}
                        onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                        onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !resource.isLoading) && (
                        <TableCardFooter>
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.props.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    )}


                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={translate('text.no_matching_records')}
                        text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                        btnLabel={translate('btn.create_commodity')}
                        onBtnClick={() => {
                            this.toggleCommodityFormModal(null, null, this.state.queryFilterFields.query.value)
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />

                </TableCard>

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <Modal
                    show={!!this.state.isCommodityInfoDialogOpen}
                    widthClass="max-w-full md:max-w-4xl"
                    onClose={() => this.toggleCommodityInfoModal(this.state.selectedItem)}
                >
                    <CommodityInfo
                        title={'View commodity template'}
                        onClose={() => this.toggleCommodityInfoModal(this.state.selectedItem)}
                        onEdit={() => {
                            this.toggleCommodityInfoModal(this.state.selectedItem)
                            this.toggleCommodityFormModal(this.state.selectedItem)
                        }}
                        selectedItem={this.state.selectedItem}
                        translate={translate}
                    />
                </Modal>

                <Modal
                    show={!!this.state.isCommodityDialogOpen}
                    widthClass="max-w-full md:max-w-2xl"
                    onClose={() => this.toggleCommodityFormModal()}
                >
                    <CommodityForm
                        title={(this.state.selectedItem ? 'Edit' : 'Add') + ' commodity template'}
                        onClose={() => this.toggleCommodityFormModal()}
                        translate={translate}
                        locationFields={this.state.locationFields}
                        selectedItem={this.state.selectedItem}
                        prefilled={this.state.prefilled}
                        showInfo
                        hideFields={["Exch", this.state.selectedItem ? "CommodityTemplates" : undefined]}
                        onSubmit={(params) => {
                            if (params) {
                                delete params.WeightConversion
                                delete params.LengthConversion
                                delete params.WidthConversion
                                delete params.HeightConversion

                                if (this.state.selectedItem) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: Object.assign({}, params, {
                                            CommodityID: this.state.selectedItem.CommodityID
                                        }),
                                        query: this.getQuery(),
                                        resource: this.getResource(),
                                        piggyResource: this.getResource(),
                                        errorMessage: true, successMessage: `Commodity ${params.ProductName} updated`,
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        query: this.getQuery(),
                                        errorMessage: true, successMessage: `Commodity ${params.ProductName} created`,
                                        resource: this.getResource(),
                                        piggyResource: this.getResource()
                                    }))
                                }
                                this.toggleCommodityFormModal()
                            }
                        }}
                    />
                </Modal>
            </>
        )
    }
}

export default connect(state => state)(CommoditiesTemplatesTab)
