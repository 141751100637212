import {classNames} from "../../../util/util-helpers";
import React from "react";

export default function TimeDropdownOption({option, isSelected, onKeyDown, innerRef, onClick}) {
    return <button
        ref={(ref) => innerRef && (innerRef.current[option] = ref)}
        onKeyDown={onKeyDown}
        key={option}
        className={
            classNames(
                "rounded-btn snap-start cursor-pointer h-8 w-10 flex justify-center items-center px-2 select-none",
                isSelected ? "bg-primary text-primary-contrast font-bold" : "text-tm-gray-600 hover:bg-primary-transparent"
            )
        }
        onClick={() => onClick(option)}
    >
        {option}
    </button>
}