import React, {useRef, useState} from 'react';
import PencilAltOutlineIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import axios from "axios";
import Env from "../../../../util/env";
import {getJWT} from "../../../util/util-auth";
import {processResponse} from "../../../../data/services/api-util";
import FieldDropdownSelect from "../field-dropdown-select";
import {classNames} from "../../../util/util-helpers";
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

export default function FieldCellSelectSearch({
                                                  value = "",
                                                  name,
                                                  select,
                                                  onChange,
                                                  placeholder,
                                                  isClearable,
                                                  errorMessage,
                                                  disabled,
                                                  translate
                                              }) {
    const hasErrors = !!errorMessage?.length;
    const buttonRef = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const api = select.api;
    const query = select.query;

    function handleChange(name, value) {
        onChange(name, value);
    }

    return <>
        <button
            ref={buttonRef}
            className={
                classNames(
                    "relative h-7 w-full text-left px-1.5 rounded-btn",
                    isFocused ? "hidden" : undefined,
                    disabled ? "cursor-default" : undefined,
                    hasErrors ? "bg-red-600/10" : undefined
                )}
            onFocus={() => !disabled
                ? setTimeout(() => setIsFocused(true), 0)
                : null
            }
        >
            {(value?.label ? value?.label : placeholder) ?? ""}

            {!hasErrors && !disabled && (
                <PencilAltOutlineIcon className="w-5 h-5 text-tm-gray-400 absolute right-1.5 top-1/2 -translate-y-1/2"/>
            )}

            {!!hasErrors && (
                <ExclamationCircleIcon className="w-5 h-5 text-red-600 absolute right-1.5 top-1/2 -translate-y-1/2"/>
            )}
        </button>

        {isFocused && (
            <FieldDropdownSelect
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={() => setIsFocused(false)}
                placeholder={placeholder}
                autoFocus={true}
                openMenuOnFocus={true}
                isClearable={isClearable}
                addClass="form-control"
                disabled={disabled}
                defaultOptions={true}
                translate={translate}
                hasPortal={true}

                loadOptions={
                    (inputValue, callback) => {
                        axios.get(
                            Env.getApiUrl(
                                api,
                                Object.assign(query, {query: inputValue})),
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getJWT().access_token
                                }
                            }
                        )
                            .then((response) => {
                                const result = processResponse(response);

                                if (result && result.status === 0) {
                                    let list = result.data.list.map((it) => select.searchMap(it));
                                    callback(list);
                                }
                            })
                            .catch((error) => {
                                console.log("Error", error)
                            });
                    }
                }
            />
        )}
    </>
}