import React from "react";
import {classNames} from "../../util/util-helpers";

const CardSubTitle = ({ text, subtitle, strapline, className, addClass }) => {
    return (
        <div className={className}>
            <div className={classNames(addClass, 'text-lg leading-6 font-medium text-tm-gray-900')}>{text || subtitle}</div>
            <div className="text-tm-gray-700">{strapline}</div>
        </div>
    )
}

export default CardSubTitle



