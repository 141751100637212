import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import {ArrowRightOnRectangleIcon, CheckCircleIcon, EyeIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_ASSET,
    PAYMENT_METHOD_FACTOR,
    READ_PERM,
    RECONCILED_FILTER,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    renderInvoiceStatusBadge,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {currentDate, toFrontDateTime} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {getSecondResource,} from '../../../data/actions/secondResource'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {getDialogResource, updateDialogResource} from '../../../data/actions/dialogResource'
import Tippy from '@tippyjs/react'
import {getThirdResource} from '../../../data/actions/thirdResource'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import moment from "moment/moment";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalDefault from "../../../common/components/modal/modal-default";
import ResourceTable from "../../../common/components/resource-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import Button from "../../../common/components/button";

class TransactionsTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_income_transactions'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Date',
            sort: 'DESC',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            confirmModalOpen: false,
            isDetailsDialogVisible: false,
            isTransactionsDialogVisible: false,
            isTableFullScreen: false,

            // Dialog(s)
            editModalOpen: false,
            selectedItems: {},
            selectedTransactions: {},
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))

        this.transactionIDsInitial = []
        this.transactionIDsCurrent = []
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        if (!prevProps.dialogResource.data?.list?.length && this.props.dialogResource.data?.list?.length) {
            this.transactionIDsInitial = this.props.dialogResource.data.list.map((transaction) => transaction['RecvPaymentID'])
        }

        if (this.props.dialogResource.data?.list?.length &&
            (prevProps.dialogResource.data?.list?.length !== this.props.dialogResource.data.list.length)) {
            this.transactionIDsCurrent = this.props.dialogResource.data?.list.map((transaction) => transaction['RecvPaymentID'])
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchDetailsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                RecvPaymentID: this.state?.selectedTransactionsItem?.RecvPaymentID
            },
            resource: Resources.IncomeTransactionsDetails
        }))
    }

    fetchInvoiceDialogData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                InvoiceID: this.state.selectedItem.InvoiceID
            },
            resource: Resources.InvoicesInvoice
        }))
    }

    fetchTransactionsData = (query = {}) => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                RecvPaymentID: this.state?.selectedTransactionsItem?.RecvPaymentID,
                ...query
            },
            resource: Resources.IncomeTransactions
        }))
    }

    onAttachTransactions = () => {
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {
                RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                RecvPaymentIDs: Object.keys(this.state.selectedTransactions)
            },
            resource: Resources.IncomeTransactionsAttach,
            piggyResource: Resources.IncomeTransactionsDetails,
            query: {
                RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                RecvPaymentID: this.state?.selectedTransactionsItem?.RecvPaymentID
            }
        }))
        this.handleToggleAddTransactionsModal()
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'transactions_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        queryFilterFields.StartDate.props.maxDate = ''
        queryFilterFields.EndDate.props.minDate = ''

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    toggleShowDetails = (item = null) => {
        this.setState({
            selectedItem: item,
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
        })
    }

    handleToggleShowTransactions = (item = null) => {
        this.setState({
            selectedTransactionsItem: item,
            isTransactionsDialogVisible: !this.state.isTransactionsDialogVisible,
        }, () => {
            this.state.isTransactionsDialogVisible && this.fetchDetailsData()

            if (!this.state.isTransactionsDialogVisible && !this.areTransactionsEqual(this.transactionIDsInitial, this.transactionIDsCurrent)) {
                this.fetchData()
            }
        })
    }

    handleToggleAddTransactionsModal = () => {
        this.setState({
            addTransactionsModalOpen: !this.state.addTransactionsModalOpen,
            selectedTransactions: {}
        })
    }

    handleSelectTransaction = (item) => {
        let listClone = cloneDeep(this.state.selectedTransactions)

        const existingItem = !!listClone[item.RecvPaymentID]

        if (existingItem) {
            delete listClone[item.RecvPaymentID]
        } else {
            listClone[item.RecvPaymentID] = item
        }

        this.setState({
            selectedTransactions: listClone
        })
    }

    handleSelectAllTransactions = (selectAll) => {
        const data = cloneDeep(getProp(this.props.thirdResource.data, 'list', []))

        let selectedTransactions = cloneDeep(this.state.selectedTransactions)

        if (!selectAll) {
            Object.assign(selectedTransactions, data.reduce((memo, it) => {
                memo[it.RecvPaymentID] = it.RecvPaymentID
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedTransactions)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.RecvPaymentID.toString())) {
                    delete selectedTransactions[it.RecvPaymentID]
                }
            })
        }

        this.setState({selectedTransactions})
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleConfirmTransactionModal = (item = null) => {
        this.setState({
            selectedTransactionItem: item,
            confirmTransactionModalOpen: !this.state.confirmTransactionModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    sortByRefNumber = (unsortedTransactions) => {
        const compareFunction = (a, b) => a.RefNumber?.localeCompare(b.RefNumber, 'en', {numeric: true})
        return unsortedTransactions.sort(compareFunction)
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getResource = () => {
        return Resources.IncomeTransactions
    }

    getFields = () => {
        return {
            Invoices: new Field('Invoices', '', [], false, 'custom', {
                render: (item) => {
                    if (item.Items) {
                        const isBatch = (item.Count >= 1 && item.RecvBatchPaymentID != null)
                        return (
                            <div className={'flex'}>
                                {isBatch && <div
                                    className={'bg-green-600 text-white inline-flex text-center px-1 rounded-btn text-sm font-medium whitespace-nowrap'}>{item.Count}</div>}
                                <div className="ml-1 max-w-[24rem] truncate">{item.Items}</div>
                            </div>
                        )
                    }
                    return ''
                },
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [], false, 'custom', {
                render: (item) => {
                    if (item.ChargeTo) {
                        return <div className="max-w-[24rem] truncate">{item.ChargeTo}</div>
                    }
                    return ''
                },
                omitSort: true
            }),
            Deposit: new Field('Deposit', '', [], false, 'money', {
                omitSort: true,
                label: 'TransactionAmount',
                render: (item) => {
                    if (item.PaymentTypeID == PAYMENT_METHOD_FACTOR) {
                        return (
                            <div>
                                {item.ReconciliationDate ? (
                                        <Tippy content={'Amount before factoring expenses: ' + item.PaymentAmount}>
                                            <div className="flex justify-end">
                                                <CheckCircleIcon className="mr-4 w-5 h-5 text-green-600"/>
                                                <div>
                                                    {genericMoneyFormatter(item.Deposit)}
                                                </div>
                                            </div>
                                        </Tippy>
                                    ) :
                                    <div className={'text-right'}>
                                        {genericMoneyFormatter(item.Deposit)}
                                    </div>}
                            </div>
                        )
                    }
                    return (
                        <div>
                            {item.ReconciliationDate ? (
                                    <Tippy content={'Reconciled: ' + toFrontDateTime(item.ReconciliationDate)}>
                                        <div className="flex justify-end">
                                            <CheckCircleIcon className="mr-4 w-5 h-5 text-green-600"/>
                                            <div>
                                                {genericMoneyFormatter(item.Deposit)}
                                            </div>
                                        </div>
                                    </Tippy>
                                ) :
                                <div className={'text-right'}>
                                    {genericMoneyFormatter(item.Deposit)}
                                </div>}
                        </div>
                    )
                },
            }),
            Date: new Field('Date', '', [], false, 'date', {}),
            Account: new Field('Account', '', [], false, 'text', {
                omitSort: true
            }),
            PaymentTypeID: new Field('PaymentTypeID', '', [], false, 'select', {
                omitSort: true
            }),
            Description: new Field('Description', '', [], false, 'text', {
                render: (it) => (<div>{it.RecvPaymentDescription ?? it.RecvBatchPaymentDescription}</div>),
                omitSort: true,
                label: 'Description'
            })
        }
    }

    getDetailsFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                render: (it) => it.Organization ?? it.Contact
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                omitSort: true,
                label: 'PaymentAmount',
            }),
            InvoiceAmount: new Field('InvoiceAmount', '', [], false, 'money', {
                omitSort: true
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                omitSort: true,
                render: (it) => renderInvoiceStatusBadge(it)
            }),
        }
    }

    getEditFields = (item = null) => {
        const fieldTemplates = {
            Date: new Field('Date', '', [''], false, 'date', {addContainerClass: 'col-span-full'}),
            Description: new Field('Description', item?.RecvPaymentDescription ?? item?.RecvBatchPaymentDescription, [], false, 'text', {
                addContainerClass: 'col-span-full',
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            AccountID: new Field('AccountID', '', [], false, 'select-search', {}, {isClearable: true}),
            PaymentTypeID: new Field('PaymentTypeID', '', [], false, 'select', {}, {isClearable: true}),
            ReconciliationDate: new Field('ReconciliationDate', '', [], false, 'select', {
                label: 'IsReconciled'
            }, {
                values: RECONCILED_FILTER,
                isClearable: true
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'})
        }
    }

    areTransactionsEqual = (initialTransactions, currentTransactions) => {
        if (initialTransactions.length !== currentTransactions.length) {
            return false;
        }

        let sortedInitial = initialTransactions.slice().sort();
        let sortedCurrent = currentTransactions.slice().sort();

        for (let i = 0; i < initialTransactions.length; i++) {
            if (sortedInitial[i] !== sortedCurrent[i]) {
                return false;
            }
        }
        return true;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const transactionsData = getProp(this.props, 'dialogResource.data.list', [])
        const transactionsDataIsLoading = getProp(this.props, 'dialogResource.isLoading', false)

        const selects = {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_ASSET,
                    AccountTypeID: ACCOUNT_TYPE_CASH
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName,
                    metadata: item
                })
            }
        }

        return (
            <React.Fragment>
                <div className="flex mb-4">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <TableSettingsPopOver
                            options={this.state.tableOptions}
                            setOptions={this.setOptions}
                            toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                            translate={translate}
                        />
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >

                    <TableFilters
                        hideLimit
                        handleInputChange={this.handleFilterInputChange}
                        filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                        translate={translate}
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                gridCols={'grid-cols-1'}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                            />
                        }
                        onRefreshTable={this.fetchData}
                        selects={selects}
                        forceDialog={true}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        tableKey={'RecvPaymentID'}
                        data={data}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}
                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), READ_PERM) && this.handleToggleShowTransactions}

                        actions={[
                            {
                                action: this.handleToggleShowTransactions,
                                icon: EyeIcon,
                                visible: (it) => (it.RecvPaymentID || it.RecvBatchPaymentID),
                                hasPerm: checkPerm(this.getResource(), READ_PERM),
                                title: translate('text.show_details'),
                            },
                            {
                                action: this.handleToggleEditModal,
                                icon: PencilIcon,
                                visible: (it) => (it.RecvPaymentID || it.RecvBatchPaymentID) && !it.ReconciliationDate,
                                hasPerm: checkPerm(this.getResource(), UPDATE_PERM),
                                title: translate('text.edit'),
                            },
                            {
                                action: this.handleToggleConfirmModal,
                                icon: TrashIcon,
                                visible: (it) => (it.RecvPaymentID || it.RecvBatchPaymentID) && !it.ReconciliationDate,
                                hasPerm: checkPerm(Resources.InvoicesPayment, DELETE_PERM),
                                title: translate('text.delete_item'),
                            }
                        ]}

                        saveTableOptions={this.setOptions}
                    />

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.isTransactionsDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.Payments')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowTransactions()}
                >
                    <div className="p-5">
                        {this.state.selectedTransactionsItem?.RecvBatchPaymentID && !this.state.selectedTransactionsItem?.ReconciliationDate && (
                            <div className="flex justify-end mb-3">
                                <Button
                                    appearance={'primary'}
                                    onClick={this.handleToggleAddTransactionsModal}
                                    hasPerm={checkPerm(Resources.IncomeTransactionsAttach, UPDATE_PERM)}
                                >
                                    {translate('btn.AddPaymentToTransaction')}
                                </Button>
                            </div>
                        )}

                        <ResourceTable
                            tableKey={'RecvPaymentID'}
                            maxHeightClass={'max-h-[calc(100vh-26rem)]'}
                            data={this.sortByRefNumber(transactionsData)}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={transactionsDataIsLoading}

                            limit={(transactionsData?.length ?? 10)}

                            onRowClick={this.toggleShowDetails}
                            actions={[
                                {
                                    action: this.toggleShowDetails,
                                    icon: EyeIcon,
                                    hasPerm: checkPerm(Resources.InvoicesInvoice, READ_PERM),
                                    title: translate('text.show_details'),
                                },
                                {
                                    action: this.handleToggleConfirmTransactionModal,
                                    icon: ArrowRightOnRectangleIcon,
                                    visible: () => transactionsData?.length !== 1 && !this.state.selectedTransactionsItem?.ReconciliationDate,
                                    hasPerm: checkPerm(Resources.IncomeTransactionsDetach, UPDATE_PERM),
                                    title: translate('text.detach'),
                                },
                            ]}
                        />
                    </div>

                    <ResourceTableDialog
                        title={"btn.AddPaymentToTransaction"}
                        dispatch={this.props.dispatch}
                        show={this.state.addTransactionsModalOpen}
                        resource={Resources.IncomeTransactions}
                        alternateDialogResource={this.props.thirdResource}
                        alternateDialogResourceCall={getThirdResource}
                        fields={this.getFields()}
                        fieldsFilter={{
                            StartDate: new Field('StartDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}, {isClearable: true}),
                            EndDate: new Field('EndDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}, {isClearable: true}),
                        }}
                        defaultQuery={{
                            RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                            RecvPaymentID: this.state?.selectedTransactionsItem?.RecvPaymentID,
                            singleOnly: 1,
                            AccountID: this.state?.selectedTransactionsItem?.AccountID
                        }}
                        tableKey={'RecvPaymentID'}
                        selectedRows={this.state.selectedTransactions}
                        onSelectRow={this.handleSelectTransaction}
                        onRowClick={this.handleSelectTransaction}
                        onSelectAllClick={this.handleSelectAllTransactions}
                        translate={translate}
                        buttonLabel={translate('btn.save')}
                        onButtonClick={this.onAttachTransactions}
                        buttonDisabled={!Object.keys(this.state.selectedTransactions).length}
                        closeButtonLabel={translate('btn.Cancel')}
                        onClose={this.handleToggleAddTransactionsModal}
                        hasRefreshButton
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.InvoiceID')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading || this.props.download.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        type="invoice"

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}
                        showLoadReferenceLinks

                        onDocumentDownload={(item) => {
                            const fileExt = item?.AttachPath.split('.').pop()

                            this.props.dispatch(download({
                                user: LocalStorage.get('user'),
                                resource: Resources.InvoicesDocuments,
                                query: Object.assign({
                                    id: item.InvoiceID,
                                    format: fileExt,
                                    name: 'document_' + currentDate() + '.' + fileExt
                                })
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchInvoiceDialogData()
                            }
                        }}
                    />
                </ModalDefault>

                <ModalSaveResource
                    title={translate('text.Edit_transaction')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.state.selectedItem.RecvPaymentID) {
                                params.RecvPaymentID = this.state.selectedItem.RecvPaymentID
                            } else {
                                params.RecvBatchPaymentID = this.state.selectedItem.RecvBatchPaymentID
                            }
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('text.transaction_updated')
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('text.confirm_delete')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: Object.assign({
                                RecvPaymentID: this.state.selectedItem?.RecvPaymentID,
                                RecvBatchPaymentID: this.state.selectedItem?.RecvBatchPaymentID
                            }, this.getQuery()),
                            resource: Resources.InvoicesPayment,
                            piggyResource: this.getResource(),
                            errorMessage: true,
                            successMessage: 'Payment deleted.'
                        }))
                        this.handleToggleConfirmModal(false)
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmTransactionModalOpen}
                    text={translate('text.confirm_detach')}
                    onClose={this.handleToggleConfirmTransactionModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(updateDialogResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({
                                RecvPaymentID: this.state.selectedTransactionItem?.RecvPaymentID,
                            }),
                            resource: Resources.IncomeTransactionsDetach,
                            errorMessage: true,
                            successMessage: 'Transaction Detached.',
                            piggyResource: Resources.IncomeTransactionsDetails,
                            query: {
                                RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                                RecvPaymentID: this.state?.selectedTransactionsItem?.RecvPaymentID
                            }
                        }))
                        this.handleToggleConfirmTransactionModal()
                    }}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(TransactionsTab)
