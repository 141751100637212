import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneDeleteDocument,
    doneDownload,
    doneUploadDocument,
    errorDeleteDocument,
    errorDownload,
    errorUploadDocument
} from "../actions/download";
import {doneGetResource} from "../actions/resource";
import {pushNotification} from "../actions/ui";
import {doneGetSecondResource} from "../actions/secondResource";
import {doneGetTaskResource} from "../actions/taskResource";
import {donePreviewDocumentResource, errorPreviewDocumentResource} from "../actions/previewDocumentResource";

export function* downloadCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.downloadFile, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDownload(result.data));
            }
        } else {
            yield put(doneDownload(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDownload(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDownload() {
    yield takeLatest('DOWNLOAD', downloadCall);
}

export function* uploadDocumentCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.files && action.data.files.length > 0) {
        result = yield call(Api.uploadDocuments, user, action.data.id, action.data.files, action.data.DocumentTypeID, action.data.Descriptions, action.data.resource, action.data.params);
    }

    if (result && result.status === 0) {
        if (action.data.taskPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.taskPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUploadDocument(result.data));
            }
        } else {
            yield put(doneUploadDocument(result.data));
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUploadDocument(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchUploadDocument() {
    yield takeLatest('UPLOAD_DOCUMENT', uploadDocumentCall);
}

export function* deleteDocumentCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.deleteResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.taskPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.taskPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTaskResource(resPiggy.data));
            }
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (action.data.piggyResource) {
            // Fix following: action.data.piggyQuery ?? action.data.query
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUploadDocument(result.data));
            }
        } else {
            yield put(doneDeleteDocument(result.data));
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteDocument(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDeleteDocument() {
    yield takeLatest('DELETE_DOCUMENT', deleteDocumentCall);
}

export function* previewDocumentCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.files && action.data.files.length > 0) {
        result = yield call(Api.uploadDocuments, user, action.data.id, action.data.files, action.data.DocumentTypeID, action.data.Descriptions, action.data.resource, action.data.params);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(donePreviewDocumentResource(result.data));
            }
        } else {
            yield put(donePreviewDocumentResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorPreviewDocumentResource(result?.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchPreviewDocument() {
    yield takeLatest('PREVIEW_DOCUMENT_RESOURCE', previewDocumentCall);
}
