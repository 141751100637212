import {classNames, getFullAddressName, getLookup} from "../../../../../common/util/util-helpers";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import React, {Component} from "react";
import Resources from "../../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import axios from "axios";
import MagnifyingGlassPlusIcon from "@heroicons/react/24/outline/MagnifyingGlassPlusIcon";
import {
    CurrencyDollarIcon,
    InformationCircleIcon,
    MagnifyingGlassIcon,
    MagnifyingGlassMinusIcon,
    MinusCircleIcon,
    Square2StackIcon
} from "@heroicons/react/24/outline";
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    OCR_DATE_TIME_FIELDS,
    OCR_DATE_TIME_FORMAT_TYPES,
    OCR_GENERIC_FORMAT_TYPES,
    OCR_LOCATION_FORMAT_TYPES,
    OCR_MONEY_FIELDS,
    OCR_MONEY_FORMAT_TYPES,
    OCR_MULTIFILL_FIELDS,
    OCR_SPECIFIC_FIELDS,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_PICKUP_AND_DELIVERY,
} from '../../../../../util/util-constants'
import Env from "../../../../../util/env";
import ArrowUpIcon from "@heroicons/react/20/solid/ArrowUpIcon";
import ArrowDownIcon from "@heroicons/react/20/solid/ArrowDownIcon";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import {
    ArrowsUpDownIcon,
    CalendarDaysIcon,
    HashtagIcon,
    HomeIcon,
    LockOpenIcon,
    MapPinIcon
} from "@heroicons/react/20/solid";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import EllipsisHorizontalIcon from "@heroicons/react/24/outline/EllipsisHorizontalIcon";
import {cloneDeep} from "../../../../../common/util/util-vanilla";
import moment from "moment";
import {getUserTimeFormat, toBackDate} from "../../../../../common/util/util-dates";
import ScaleIcon from "@heroicons/react/20/solid/ScaleIcon";
import {pushNotification, showModal} from "../../../../../data/actions/ui";
import OcrDocumentSummary from "./ocr-document-summary";
import {
    countFragmentsTypesInstances,
    elementContainsDateTimeFragments,
    elementContainsMoneyFragments,
    extractFieldName,
    getContentValue,
    getCustomerQuery,
    getDateTimeFormat,
    getFieldType,
    getMergedGroupContent,
    getSelectValue,
    getTextareaNumberOfRows,
    getValuesFromDbGuid,
    guessDateFormat,
    isFieldTypeDateTime,
    Message,
    processFragments,
    splitDateString
} from "./ocr-util";
import Buttons from "../../../../../common/components/buttons";
import ModalSaveResource from "../../../../../common/components/modal/modal-save-resource";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import ModalConfirm from "../../../../../common/components/modal/modal-confirm";
import Subtitle from "../../../../../common/components/layout/layout-components/page/subtitle";
import Tooltip from "../../../../../common/components/tooltip";
import ModalHeader from "../../../../../common/components/modal/modal-header";
import DropZoneAlt from "../../../../../common/components/dropzone/drop-zone-alt";
import {LoaderLarge, LoaderSmall} from "../../../../../common/components/loader";
import FieldsToHtml from "../../../../../common/components/fields/fields-to-html";
import LoaderFake from "../../../../../common/components/loader/loader-fake";
import OpticalCharacterRecognition from "../../../../../common/components/modal/optical-character-recognition";
import MenuDropdown from "../../../../../common/components/headless-ui/menu-dropdown";
import {getJWT} from "../../../../../common/util/util-auth";
import FieldOptions from "../../../../../common/components/fields/field-options";

export default class LoadOCRDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                info: {0: this.getInfoFields()},
                pricing: {0: this.getPricingFields()},
                locations: {
                    0: this.getLoadLocationFields(0),
                    1: this.getLoadLocationFields(1)
                },
                commodities: {
                    0: this.getLoadCommodityFields(0),
                    1: this.getLoadCommodityFields(1),
                }
            },
            createdLocations: {},
            snap: {},
            exchange: {},
            focusedField: "",
            pairs: {},
            confirmPair: {},
            selectedElement: {},

            documentCurrentPage: 1,
            documentPagesNumber: 0,
            paginationInputValue: 1,

            file: [],
            documentUri: "",
            documentZoomModifier: 1,
            disableZoomToFit: false,

            isLoading: false,
            isMouseButtonDown: false,
            isSidebarCollapsed: false,
            isSidebarTempVisible: false,
            isElementsConflictDialogOpen: false,
            isLocationFormVisible: true,
            selectedLocationIndex: 0, // none selected
            OCRReports: {
                errors: [],
                warnings: [],
                messages: []
            },
            processedOCRData: {
                "load/multistops": {},
                "load/commodities": {}
            },
            isReportDialogOpen: false,
            createLoadAfterSubmission: false,
            buffer: undefined,
            noCustomerFoundDialog: false,
            isInitialRenderNotesFields: true,

            isFeedbackModalOpen: false,

            LLMProcessedData: {},
            isLLMSummaryDialogOpen: false,
            isLLMNetworkError: false
        }

        this.dialogBodyRef = React.createRef();
        this.documentScrollableContainerRef = React.createRef();
        this.documentContainerRef = React.createRef();
        this.dialogRef = React.createRef();
        this.locationBackButtonRef = React.createRef();

        this.loadSelects = {
            LocationName: {
                api: 'api/' + Resources.Locations,
                query: {},
                searchMap: (it) => ({
                    label: it.LocationName,
                    value: it.LocationID,
                    metadata: it
                })
            },
            OfficeID: {
                api: 'api/' + Resources.Offices,
                query: {},
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            CustomerID: {
                api: 'api/' + Resources.CustomersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName || item.Organization,
                    value: item.CustomerID,
                    metadata: item
                })
            },
            StopID: {
                api: 'api/' + Resources.Locations,
                query: {},
                searchMap: (it) => ({
                    label: it.LocationName,
                    value: it.LocationID,
                    metadata: it
                })
            },
            StopType: {
                [STOP_TYPE_PICKUP]: 'Pickup',
                [STOP_TYPE_DELIVERY]: 'Delivery'
            }
        }

        this.states = getLookup('State', 'StateID', "StateAbbreviation") ?? {};
        this.stateFullNames = getLookup('State', 'StateID', "State") ?? {};

        this.statesAbbr = Object.keys(this.states).reduce((memo, key) => {
            memo[this.states[key]] = key;
            return memo;
        }, {});

        this.createLocationData = {};
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.props.OCRDropzoneShortcutDocument) {
            this.handleAcceptFile(this.props.OCRDropzoneShortcutDocument)
        }

        if (this.props.hashUUID) {
            this.setState({isLoading: true}, async () => {
                try {
                    if (this.props.isDialogMinimized) this.handleMinimizeDialogClick()

                    const [processedData, file, snapshot] = await Promise.all([
                        this.getInferenceData(this.props.hashUUID),
                        this.getPdf(this.props.hashUUID),
                        this.getSnapshot(this.props.hashUUID)
                    ])

                    if (!!processedData && !!file && !!snapshot['snapshot-document']) {
                        this.fetchImage(file)

                        this.setState({
                            file: file,
                            LLMProcessedData: processedData,
                            snap: snapshot,
                            isLLMSummaryDialogOpen: true,
                        })
                    } else {
                        this.setState({isLLMNetworkError: true})
                    }
                } catch (err) {
                    console.log('err', err)
                } finally {
                    this.setState({isLoading: false})
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps.hashUUID && !!this.props.hashUUID) {
            this.setState({isLoading: true}, async () => {
                try {
                    if (this.props.isDialogMinimized) this.handleMinimizeDialogClick()

                    const [processedData, file, snapshot] = await Promise.all([
                        this.getInferenceData(this.props.hashUUID),
                        this.getPdf(this.props.hashUUID),
                        this.getSnapshot(this.props.hashUUID)
                    ])

                    if (!!processedData && !!file && !!snapshot['snapshot-document']) {
                        this.fetchImage(file)
                        this.setState({
                            file: file,
                            LLMProcessedData: processedData,
                            snap: snapshot,
                            isLLMSummaryDialogOpen: true,
                            isLoading: false
                        })
                    } else {
                        this.setState({isLLMNetworkError: true})
                    }

                } catch (err) {
                    console.log('err', err)
                }
            })
        }

        if (prevState.documentCurrentPage !== this.state.documentCurrentPage) {
            this.fetchImage(this.state.file, this.state.documentCurrentPage);
        }

        if (prevState.isLoading && !this.state.isLoading) { // Loading via axios
            this.setState({
                documentPagesNumber: this.state.snap?.['snapshot-document']?.page?.length ?? 0
            });

            // Autofill reference number and customer fields on document load if detected
            if (this.getReferenceNumberElement()) {
                const refNumberElement = this.getReferenceNumberElement()
                if (!this.state.pairs['info.0.CustomerReferenceNumber'] && !this.state.fields['info']['0']['CustomerReferenceNumber'].value) this.pairSingleField('info.0.CustomerReferenceNumber', refNumberElement)
                if (!this.state.fields['info']['0']['CustomerID'].value) this.updateCustomerField(refNumberElement, this.state.fields['info']['0']['CustomerID'], false)
            }

            if (!this.getReferenceNumberElement() && this.getCustomerNameElement() && !this.state.fields['info']['0']['CustomerID'].value) {
                this.updateCustomerField(this.getCustomerNameElement(), this.state.fields['info']['0']['CustomerID'])
            }
        }

        if (this.props.isDialogMinimized !== prevProps.isDialogMinimized) {
            this.handleMinimizeDialogClick();
        }

        if (this.state.isInitialRenderNotesFields && this.checkTextareasForContent()) {
            const fieldsUpdate = Object.assign({}, this.state.fields);
            this.getTextareaFields().forEach((field, index) => {
                if (field.name === 'Notes') fieldsUpdate['locations'][index.toString()]['Notes'].props.rows = getTextareaNumberOfRows(fieldsUpdate['locations'][index.toString()]['Notes'].value)
                else fieldsUpdate['info']['0']['ExternalNotesCustomer'].props.rows = getTextareaNumberOfRows(fieldsUpdate['info']['0']['ExternalNotesCustomer'].value)
            })

            this.setState({
                fields: fieldsUpdate,
                isInitialRenderNotesFields: false
            })
        }

        if (this.state.selectedLocationIndex !== 0 && (prevState.selectedLocationIndex !== this.state.selectedLocationIndex) && !!this.state.fields.commodities[this.state.selectedLocationIndex]) {
            const fields = Object.assign({}, this.state.fields)
            const locationIndex = this.state.selectedLocationIndex
            const isUnloadFromChecked = this.state.fields.commodities[locationIndex]['UnloadFrom']?.value
            const commodityFields = Object.values(this.state.fields.commodities[locationIndex]).splice(3)
            commodityFields.forEach((field) => {
                fields['commodities'][locationIndex][field.name].type = isUnloadFromChecked ? 'hidden' : 'text'
            })

            this.setState({
                fields: fields
            })
        }

        if (prevState.selectedLocationIndex !== this.state.selectedLocationIndex && this.state.fields.commodities[this.state.selectedLocationIndex]) {
            if (Object.values(this.state.fields.commodities[this.state.selectedLocationIndex]).splice(3).some((commodityField) => !!commodityField.value)) {
                this.handleInputChange('commodities', this.state.selectedLocationIndex, 'UnloadFrom', false)
            }
        }

        if (!Object.keys(prevState.LLMProcessedData).length && !!Object.keys(this.state.LLMProcessedData).length) {
            this.fillFieldsFromLLMData(this.state.LLMProcessedData)
            if (!this.state.fields.info['0'].CustomerID.value && !!this.state.LLMProcessedData?.['document_issuer']) {
                this.updateCustomerFieldFromLLMData(this.state.LLMProcessedData['document_issuer'])
            }
        }
    }

    componentWillUnmount() {
        this.setState({file: [], LLMProcessedData: {}, isLLMSummaryDialogOpen: false})
    }

    /** Entry point - submit PDF for processing
     ================================================================= */
    /*fetchOCRJson = (file) => {
        const formData = new FormData();
        formData.append('file', file[0]);

        axios.post(
            Env.getOcrApiUrl('ocr/json', {}),
            formData,
            {
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                }
            }
        )
            .then((response) => {
                const pairs = this.updatePairs(response.data.exchange);
                const snap = this.updateSnap(response.data.snap, pairs);
                // Add dbGuid to elements
                this.setState({
                    snap: snap,
                    /!*exchange: response.data.exchange,
                    pairs: pairs*!/
                }, () => {
                    this.fetchImage(file);
                    /!*this.setPairValues(this.state.pairs);*!/
                });

            })
            .catch((error) => {
                console.log('error', error)
            })
    }*/

    fetchImage = (file, page = 1) => {
        this.setState({
            isLoading: true
        }, () => {
            const formData = new FormData();
            formData.append('file', file[0] || file);

            axios.post(
                Env.getOcrApiUrl('ocr/image', {
                    index: page
                }),
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${getJWT().access_token}`,
                    },
                    responseType: 'blob',
                }
            )
                .then((response) => {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    this.setState({
                        documentUri: fileURL,
                        isLoading: page === 1 && !Object.keys(this.state.LLMProcessedData).length && !this.state.isLLMNetworkError
                    });
                })
                .catch((error) => {
                    console.log('error fetching file', error)
                })
                .finally(() => {
                    this.setState({isLoading: page === 1 && !Object.keys(this.state.LLMProcessedData).length && !this.state.isLLMNetworkError})
                })
        });
    }

    submitFileForProcessing = (file) => {
        this.setState({isLoading: true}, async () => {
            const formData = new FormData();
            formData.append('file', file[0]);
            const name = file[0].fields?.OriginalName?.value || file[0].path

            try {
                const response = await axios.post(
                    Env.getOcrApiUrl('ocr/manager/ignite'),
                    formData,
                    {
                        params: {
                            name
                        },
                        headers: {
                            Authorization: `Bearer ${getJWT().access_token}`,
                        },
                    }
                )

                if (response.status === 200) {
                    const {state: status, uuid} = response.data
                    switch (status) {
                        case "Done": {
                            const inferenceData = await this.getInferenceData(uuid)
                            const snapshot = await this.getSnapshot(uuid)
                            if (inferenceData) {
                                this.setState({
                                    LLMProcessedData: inferenceData,
                                    snap: snapshot
                                })
                            } else {
                                this.setState({isLLMNetworkError: true})
                            }
                            break;
                        }
                        case "SnapShotProbeDone": {
                            this.props.dispatch(pushNotification({
                                title: this.props.translate('text.image_detected'),
                                content: this.props.translate('text.image_detected_text'),
                                position: "top",
                                timeout: 5000
                            }))

                            this.setState({
                                isProgressBarHidden: true,
                                isLoading: false,
                            })
                            break;
                        }
                        case "Fail": {
                            this.setState({
                                isLLMNetworkError: true,
                                isProgressBarHidden: true
                            })
                        }
                    }
                }
            } catch (err) {
                this.setState({isLLMNetworkError: true})
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    /** Input processed data from the response into the form
     ================================================================= */

    /* 1. Automatic input - LLM inference data */
    fillFieldsFromLLMData = (LLMData) => {
        const fieldsUpdate = Object.assign({}, this.state.fields);

        // info
        const {equipment_type, load_number} = LLMData
        const fieldsToDataMapInfo = {
            'LoadSubTypeID': getSelectValue('LoadSubTypeID', equipment_type),
            'CustomerReferenceNumber': load_number
        }

        Object.values(fieldsUpdate.info[0]).forEach((field) => {
            const fieldDataExists = !!fieldsToDataMapInfo[field.name]
            if (field.name !== 'CustomerID' && fieldDataExists) { // CustomerID is async field and is handled separately
                this.handleInputChange('info', 0, field.name, fieldsToDataMapInfo[field.name])
            }
        })

        // locations
        const locations = LLMData['shipping_addresses'].concat(LLMData['delivery_addresses'])
        const dates = LLMData['shipping_dates'].concat(LLMData['delivery_dates'])

        let fieldsStateIndex = 0
        locations.forEach((location) => {
            if (!fieldsUpdate.locations?.[fieldsStateIndex]) {
                this.addState(fieldsUpdate, 'locations', fieldsStateIndex);
            }

            const {name, address_line, city_name, state, postal_code, ref_number, notes} = location
            const {stopDate, stopTime, stopEndDate, stopEndTime} = splitDateString(dates[fieldsStateIndex])

            const fieldsToDataMapLocations = {
                'LocationName': name,
                'AddressName': address_line,
                'ReferenceNumber': ref_number,
                'CityName': city_name,
                'StateID': getSelectValue('StateID', state),
                'PostalCode': postal_code,
                'Notes': notes,
                'StopDate': stopDate,
                'StopTime': stopTime,
                'StopEndDate': stopEndDate,
                'StopEndTime': stopEndTime
            }

            Object.values(fieldsUpdate.locations[fieldsStateIndex]).forEach((field) => {
                const fieldDataExists = !!fieldsToDataMapLocations[field.name]
                if (field.name !== 'StopType' && fieldDataExists) {
                    this.handleInputChange('locations', fieldsStateIndex, field.name, fieldsToDataMapLocations[field.name])
                }
            })

            fieldsStateIndex++
        })

        // commodity
        const totalWeight = LLMData['total_weight']
        if (totalWeight) this.handleInputChange('commodities', 0, 'Weight', totalWeight)

        // price
        const price = LLMData['total_price']
        if (price) this.handleInputChange('pricing', 0, 'Price', price)

        this.setState({
            fields: fieldsUpdate
        })
    }

    /* 2. Manual input (Drag n Drop) - regular snapshot */
    pairElementWithField = (element, dbGuid, existingElementIndex, concatString) => {
        const fieldsUpdate = Object.assign({}, this.state.fields);
        let pairs = this.state.pairs;
        let snap = this.state.snap;

        element.specific.pageIndex = this.state.documentCurrentPage - 1;
        element.specific.DbGuid = dbGuid;

        const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid);

        if (concatString) {
            const originalElement = snap['snapshot-document']
                .page[this.state.documentCurrentPage - 1]
                .element[existingElementIndex];

            const item = {
                index: element.specific.index,
                concatString: concatString,
                pageIndex: element.specific.pageIndex,
                content: element.type.startsWith('group::') ? getMergedGroupContent(element) : element.specific.content
            }

            const mergedElements = originalElement?.specific?.['merged-with']
                ? JSON.parse(originalElement?.specific?.['merged-with'])
                : {};

            originalElement.specific["merged-with"] = Object.assign(
                mergedElements,
                {[element.specific.index]: item}
            );

            if (originalElement.type.startsWith('group::')) {
                const startingString = getMergedGroupContent(originalElement)
                fieldsUpdate[stateName][stateIndex][fieldName].value = Object.values(originalElement.specific["merged-with"]).reduce((memo, it) => {
                    memo = memo + (it.concatString + it.content);
                    return memo;
                }, startingString);
            } else {
                fieldsUpdate[stateName][stateIndex][fieldName].value = Object.values(originalElement.specific["merged-with"]).reduce((memo, it) => {
                    memo = memo + (it.concatString + it.content);
                    return memo;
                }, originalElement.specific.content);
            }

            originalElement.specific['merged-with'] = JSON.stringify(originalElement.specific['merged-with']);

            snap['snapshot-document']
                .page[this.state.documentCurrentPage - 1]
                .element[existingElementIndex] = originalElement;

            const elementIndex = snap['snapshot-document']
                .page[this.state.documentCurrentPage - 1]
                .element.findIndex(el => el.specific.index === element.specific.index);

            snap['snapshot-document']
                .page[this.state.documentCurrentPage - 1]
                .element[elementIndex] = element;
        } else {
            // 1. Update field with OCR element value
            fieldsUpdate[stateName][stateIndex][fieldName].metadata.oldValue = fieldsUpdate[stateName][stateIndex][fieldName].value;

            if (!this.isTargetFieldValid()) {
                return
            }

            // Regular case (one element - one field)
            fieldsUpdate[stateName][stateIndex][fieldName].value = getContentValue(
                element,
                fieldsUpdate[stateName][stateIndex][fieldName]
            );
            fieldsUpdate[stateName][stateIndex][fieldName].disabled = true;
            pairs = Object.assign({}, pairs, {[dbGuid]: element}) // setPairs

            if (element.specific['format-name'] === 'TRUCKOCR_APPOINTMENT_TIME') {
                fieldsUpdate[stateName][stateIndex]['RequiresAppointment'].value = 1
            }

            // Location multifill (city, state, zip)
            if (fieldsUpdate[stateName][stateIndex][fieldName].type.startsWith('location')) {
                if (OCR_LOCATION_FORMAT_TYPES.includes(element.specific['format-name'])) {
                    const locationFields = ['CityName', 'StateID', 'PostalCode']
                    pairs = this.pairMultipleFields(dbGuid, locationFields, element)
                }
            }

            // Date/Time interval multifill
            if (isFieldTypeDateTime(fieldsUpdate[stateName][stateIndex][fieldName])) {
                if (elementContainsDateTimeFragments(element)) {
                    const fragmentTypesCount = countFragmentsTypesInstances(processFragments(element))

                    let fields;
                    switch (getDateTimeFormat(fragmentTypesCount)) {
                        case "dateInterval": {
                            fields = ['StopDate', 'StopEndDate']
                            break;
                        }
                        case "timeInterval": {
                            fields = ['StopTime', 'StopEndTime']
                            break;
                        }
                        case "dateWithTimeInterval": {
                            fields = (fieldName === 'StopDate' || fieldName === 'StopTime') ? ['StopDate', 'StopTime', 'StopEndTime'] : ['StopEndDate', 'StopTime', 'StopEndTime']
                            break;
                        }
                        case "dateTimeStamp": {
                            fields = (fieldName === 'StopDate' || fieldName === 'StopTime') ? ['StopDate', 'StopTime'] : ['StopEndDate', 'StopEndTime']
                            break;
                        }
                        case "dateTimeIntervalFull": {
                            fields = ['StopDate', 'StopTime', 'StopEndDate', 'StopEndTime']
                            break;
                        }
                        default: {
                            fields = [fieldsUpdate[stateName][stateIndex][fieldName].name]
                        }
                    }
                    pairs = this.pairMultipleFields(dbGuid, fields, element)
                }
            }

            // 2. Remove previously paired element if exists
            if (existingElementIndex > -1
                && !!snap?.['snapshot-document']?.page[this.state.documentCurrentPage - 1]?.element[existingElementIndex]
                && !concatString
            ) {
                snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[existingElementIndex].specific.DbGuid = "";

                // Unpairing all concatenated elements in the current field if they exist
                if (snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[existingElementIndex]?.specific['merged-with']) {
                    const mergedElementsIndexes = Object.keys(JSON.parse(snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[existingElementIndex]?.specific['merged-with']))
                    mergedElementsIndexes.forEach((elIndex) => {
                        const mergedElementIndex = snap['snapshot-document']?.page[this.state.documentCurrentPage - 1].element.findIndex(el => el.specific?.index === elIndex);
                        if (mergedElementIndex > -1) {
                            snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[mergedElementIndex].specific.DbGuid = ""
                        }
                    })
                }
            }

            // 3. Add new dbGuId to element
            const elementIndex = snap['snapshot-document']
                .page[this.state.documentCurrentPage - 1]
                .element.findIndex(el => el.specific.index === element.specific.index);

            snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[elementIndex] = element;
        }

        if (fieldsUpdate[stateName][stateIndex][fieldName].type === 'textarea') {
            fieldsUpdate[stateName][stateIndex][fieldName].props.rows = getTextareaNumberOfRows(fieldsUpdate[stateName][stateIndex][fieldName].value)
        }

        this.setState({
            isInitialRenderLoadTypeID: false,
            isMouseButtonDown: false,
            isSidebarTempVisible: false,
            selectedElement: {},
            fields: fieldsUpdate,
            pairs: pairs, // these pairs convert to load data on submit
            snap: snap,
        });
    }

    pairSingleField = (dbGuid, element) => {
        const fieldsUpdate = Object.assign({}, this.state.fields);
        const pairs = Object.assign({}, this.state.pairs, {[dbGuid]: element})
        const snap = this.state.snap;

        const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid);

        fieldsUpdate[stateName][stateIndex][fieldName].value = getContentValue(
            element,
            fieldsUpdate[stateName][stateIndex][fieldName]
        )

        fieldsUpdate[stateName][stateIndex][fieldName].disabled = true
        element.specific.pageIndex = this.state.documentCurrentPage - 1;
        element.specific.DbGuid = dbGuid

        const elementIndex = snap['snapshot-document']
            .page[this.state.documentCurrentPage - 1]
            .element.findIndex(el => el.specific.index === element.specific.index);
        snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[elementIndex] = element;

        this.setState({
            fields: fieldsUpdate,
            pairs: pairs,
            snap: snap
        })
    }

    pairMultipleFields = (dbGuid, targetFields, element) => {
        const fieldsUpdate = Object.assign({}, this.state.fields)
        const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid);
        let pairs = this.state.pairs;

        let newPairs = {}
        targetFields.forEach((field) => {
            fieldsUpdate[stateName][stateIndex][field].value = getContentValue(
                element,
                fieldsUpdate[stateName][stateIndex][field]
            )
            fieldsUpdate[stateName][stateIndex][field].disabled = !!fieldsUpdate[stateName][stateIndex][field].value
            const dbGuid = stateName + '.' + stateIndex + '.' + field
            const newPair = fieldsUpdate[stateName][stateIndex][field].value ? {[dbGuid]: element} : null
            newPairs = Object.assign({}, newPairs, newPair)
        })

        if (targetFields.length > 1) {
            Object.values(newPairs).map((pair) => {
                pair.specific["multifill-linked-fields"] = targetFields.toString()
                return pair
            })
        }

        pairs = Object.assign({}, pairs, newPairs)
        return pairs
    }

    unpairSingleField = (dbGuid, element, keepContent) => {
        let pairsUpdate = this.state.pairs;
        let snap = this.state.snap;
        const fieldsUpdate = Object.assign({}, this.state.fields);

        delete pairsUpdate[dbGuid];

        const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid);
        fieldsUpdate[stateName][stateIndex][fieldName].disabled = false;
        if (!keepContent) fieldsUpdate[stateName][stateIndex][fieldName].value = '';

        // Remove dbGuid from the element
        const elementIndex = snap['snapshot-document']?.page[this.state.documentCurrentPage - 1].element.findIndex(el => el.specific?.DbGuid === dbGuid);
        if (elementIndex > -1) {
            snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[elementIndex].specific.DbGuid = "";
        }

        // Unlinking multi-element fields (one field containing multiple concatanated elements)
        if (element?.specific['merged-with']) {
            const mergedElementsIndexes = Object.keys(JSON.parse(element.specific['merged-with']))
            mergedElementsIndexes.forEach((elIndex) => {
                const mergedElementIndex = snap['snapshot-document']?.page[this.state.documentCurrentPage - 1].element.findIndex(el => el.specific?.index === elIndex);
                if (mergedElementIndex > -1) {
                    snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[mergedElementIndex].specific.DbGuid = "";
                }
            })
        }

        // Specific custom behaviour on unpairing certain fields
        if (fieldName === 'ExternalNotesCustomer' || fieldName === 'Notes') {
            fieldsUpdate[stateName][stateIndex][fieldName].props.rows = 5
        }

        if (element?.specific['format-name'] === 'TRUCKOCR_APPOINTMENT_TIME') {
            fieldsUpdate[stateName][stateIndex]['RequiresAppointment'].value = 0
        }

        this.setState({
            pairs: pairsUpdate,
            snap: snap,
            fields: fieldsUpdate
        });
    }

    unpairMultipleFields = (dbGuid, element, keepContent) => {
        let pairsUpdate = this.state.pairs;
        let snap = this.state.snap;
        const fieldsUpdate = Object.assign({}, this.state.fields)

        // Delete element - field pairings for all linked fields
        element.specific["multifill-linked-fields"].split(",").forEach((field) => {
            const [tempFieldName, tempStateName, tempStateIndex] = getValuesFromDbGuid(element.specific.DbGuid)
            const currentFieldDbGuid = `${tempStateName}.${tempStateIndex}.${field}`

            delete pairsUpdate[currentFieldDbGuid];

            const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(currentFieldDbGuid);
            fieldsUpdate[stateName][stateIndex][fieldName].disabled = false;
            if (!keepContent) fieldsUpdate[stateName][stateIndex][fieldName].value = '';
        })

        // Remove dbGuids from all linked fields that consume their values from the element
        element.specific["multifill-linked-fields"].split(",").forEach((field) => {
            const [tempFieldName, tempStateName, tempStateIndex] = getValuesFromDbGuid(element.specific.DbGuid)
            const currentFieldDbGuid = `${tempStateName}.${tempStateIndex}.${field}`

            let elementIndex = snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element.findIndex(el => el.specific?.DbGuid === currentFieldDbGuid);
            if (elementIndex > -1) {
                snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[elementIndex].specific.DbGuid = "";
            }
        })

        this.setState({
            pairs: pairsUpdate,
            snap: snap,
            fields: fieldsUpdate
        });
    }

    async pairElementWithAsyncField(element, dbGuid, existingElementIndex) { // Currently only CustomerID field
        const fieldsUpdate = Object.assign({}, this.state.fields);
        const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid);
        let pairs = this.state.pairs;
        let snap = this.state.snap;
        element.specific.pageIndex = this.state.documentCurrentPage - 1;
        element.specific.DbGuid = dbGuid;

        fieldsUpdate[stateName][stateIndex][fieldName].props.isLoading = true
        try {
            const response = await this.request(fieldName, getCustomerQuery(element))
            if (response?.data?.data?.list[0]) {
                const {LegalName, CustomerID} = response.data.data.list[0]
                fieldsUpdate[stateName][stateIndex][fieldName].value = {
                    label: LegalName,
                    value: CustomerID,
                    metadata: response.data.data.list[0]
                }
                fieldsUpdate[stateName][stateIndex][fieldName].disabled = true;
                pairs = Object.assign({}, pairs, {[dbGuid]: element})

                if (existingElementIndex > -1
                    && !!snap?.['snapshot-document']?.page[this.state.documentCurrentPage - 1]?.element[existingElementIndex]
                ) {
                    snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[existingElementIndex].specific.DbGuid = "";
                }

                const elementIndex = snap['snapshot-document']
                    .page[this.state.documentCurrentPage - 1]
                    .element.findIndex(el => el.specific.index === element.specific.index);

                snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[elementIndex] = element;
            } else {
                this.setState({
                    noCustomerFoundDialog: true
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            fieldsUpdate[stateName][stateIndex][fieldName].props.isLoading = false
        }

        this.setState({
            fields: fieldsUpdate,
            pairs: pairs,
            snap: snap,
            isMouseButtonDown: true,
            selectedElement: {}
        })
    }

    async updateCustomerFieldFromLLMData(value = '') {
        const fieldsUpdate = Object.assign({}, this.state.fields);
        fieldsUpdate['info']['0']['CustomerID'].props.isLoading = true
        try {
            const response = await this.request('CustomerID', {query: value})
            const {LegalName, CustomerID} = response.data.data.list[0]
            fieldsUpdate['info']['0']['CustomerID'].value = {
                label: LegalName,
                value: CustomerID,
                metadata: response.data.data.list[0]
            }
        } catch (error) {
            console.log(error)
        } finally {
            fieldsUpdate['info']['0']['CustomerID'].props.isLoading = false
            this.setState({
                fields: fieldsUpdate
            })
        }
    }

    async updateCustomerField(element, field, isDirectPairing = true) {
        const fieldsUpdate = Object.assign({}, this.state.fields);
        const snap = Object.assign({}, this.state.snap)

        fieldsUpdate['info']['0'][field.name].props.isLoading = true
        try {
            const response = await this.request(field.name, getCustomerQuery(element))
            const {LegalName, CustomerID} = response.data.data.list[0]
            fieldsUpdate['info']['0'][field.name].value = {
                label: LegalName,
                value: CustomerID,
                metadata: response.data.data.list[0]
            }

            if (isDirectPairing) {
                fieldsUpdate['info']['0'][field.name].disabled = isDirectPairing
                element.specific.pageIndex = this.state.documentCurrentPage - 1;
                element.specific.DbGuid = 'info.0.CustomerID'

                const elementIndex = snap['snapshot-document']
                    .page[this.state.documentCurrentPage - 1]
                    .element.findIndex(el => el.specific.index === element.specific.index);
                snap['snapshot-document'].page[this.state.documentCurrentPage - 1].element[elementIndex] = element;
            }

        } catch (error) {
            console.log(error)
        } finally {
            fieldsUpdate['info']['0'][field.name].props.isLoading = false
            this.setState({
                fields: fieldsUpdate,
                pairs: isDirectPairing ? Object.assign({}, this.state.pairs, {['info.0.CustomerID']: element}) : this.state.pairs,
                snap: isDirectPairing ? snap : this.state.snap
            })
        }
    }

    /** Data processing before submission
     ================================================================= */
    processPairsData = (pairs) => {
        let locations = {};

        // By default, if a location instance doesn't have at least one element paired with the form, this function will skip over it as it only looks at pairs
        // To prevent losing location instances where all fields are manually input, this code block checks for and adds such cases to final locations data
        Object.values(this.state.fields.locations).forEach((locationFields, index) => {
            const locationFieldsHaveValues = Object.values(locationFields).some(
                (field) => ['LocationName', 'AddressName', 'CityName', 'StateID', 'PostalCode'].includes(field.name) && !!field.value)

            if (locationFieldsHaveValues) {
                locations[index] = Object.values(this.state.fields.locations[index])
                    .filter((field) => !!field.value)
                    .reduce((memo, field) => {
                        memo[field.name] = field
                        memo['dbGuId'] = `locations.${index}.LocationName`
                        memo['stateIndex'] = index.toString()
                        memo['stateName'] = 'locations'
                        return memo
                    }, {})
            }
        })

        let data = Object.values(pairs).reduce((memo, pairedElement) => {
            const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(pairedElement.specific.DbGuid);

            if (stateName === 'locations'
                && ['StopID', 'LocationName', 'AddressName', 'CityName', 'StateID', 'PostalCode'].includes(fieldName)
            ) {
                if (!locations[stateIndex]) {
                    // define dbguid here
                    locations[stateIndex] = {
                        dbGuId: pairedElement.specific.DbGuid,
                        stateName,
                        stateIndex
                    };
                }

                locations[stateIndex][fieldName] = this.state.fields[stateName][stateIndex][fieldName];
                locations[stateIndex][fieldName].value = getContentValue(
                    pairedElement,
                    this.state.fields[stateName][stateIndex][fieldName]
                );

                if (pairedElement.specific['multifill-linked-fields']) {
                    pairedElement.specific['multifill-linked-fields'].split(',').forEach((linkedField) => {
                        locations[stateIndex][linkedField] = this.state.fields[stateName][stateIndex][linkedField];
                        locations[stateIndex][linkedField].value = getContentValue(
                            pairedElement,
                            this.state.fields[stateName][stateIndex][linkedField]
                        );
                    })
                }
            } else {
                if (pairedElement.specific['multifill-linked-fields']) {
                    pairedElement.specific['multifill-linked-fields'].split(',').forEach((linkedField) => {
                        if (this.state.fields[stateName][stateIndex][linkedField]) {
                            memo[`${stateName}.${stateIndex}.${linkedField}`] = this.state.fields[stateName][stateIndex][linkedField]
                        }
                    })
                }
                memo[pairedElement.specific.DbGuid] = this.state.fields[stateName][stateIndex][fieldName];
            }

            return memo;
        }, {});

        Object.keys(locations).forEach(locationIndex => {
            let location = locations[locationIndex];
            let dbGuId = locations[locationIndex].dbGuId.split(".");
            dbGuId.pop();
            dbGuId = location.stateName + "." + location.stateIndex + '.StopID';

            data[dbGuId] = this.state.fields['locations'][locationIndex]['StopID'];

            const locationData = {
                LocationName: location.LocationName?.value ?? "",
                AddressName: location.AddressName?.value ?? "",
                CityName: location.CityName?.value ?? "",
                PostalCode: location.PostalCode?.value ?? "",
                StateID: location.StateID?.value ?? ""
            };

            this.createLocationData[dbGuId] = locationData;
            data[dbGuId].value = JSON.stringify(locationData)
        });

        return data;
    }

    processCommodities = (data) => {
        if (Object.keys(this.state.fields.commodities).length > 1) {
            let commodityData = Object.values(this.state.fields.commodities).map((commodity) => {
                if (commodity.UnloadFromLocation.value) {
                    let origin = Object.values(this.state.fields.commodities).find((originCommodity) => originCommodity.ConnectedLocationIndex.value === commodity.UnloadFromLocation.value)
                    const commodityTemp = commodity
                    commodity = Object.assign({}, commodityTemp, origin)

                    // Carry over pickup/destination location ID's to the original commodity object
                    commodity.UnloadFromLocation = commodityTemp.UnloadFromLocation
                    commodity.ConnectedLocationIndex = commodityTemp.ConnectedLocationIndex
                }
                return commodity
            })

            const isCommodityMergedWithDestination = (commodityData, commodity) => {
                // If commodity is unloaded on any of the other existing locations, it should be filtered out from the list as it is a duplicate
                return (
                    !commodity['UnloadFromLocation'].value &&
                    commodityData.some((c) => c.UnloadFromLocation.value == commodity['ConnectedLocationIndex'].value)
                )
            }

            const isCommodityEmpty = (commodity) => {
                const dataFields = Object.values(commodity).filter((field) => field.name !== 'ConnectedLocationIndex' && field.name !== 'UnloadFrom' && field.name !== 'UnloadFromLocation')
                return !dataFields.some((field) => !!field.value)
            }

            commodityData = commodityData.filter((commodity) => !!commodity?.["ConnectedLocationIndex"]?.value && !isCommodityMergedWithDestination(commodityData, commodity))

            if (commodityData.every((commodity) => isCommodityEmpty(commodity))) {
                commodityData = []
            }

            commodityData?.forEach((commodity, index) => {
                const commodityFields = Object.values(commodity)
                commodityFields.forEach((field) => {
                    if (field.value) {
                        data[`commodities.${index}.${field.name}`] = field
                    }
                })
            })

            const commodityDbGuids = Object.keys(data).filter((dbGuid) => dbGuid.startsWith('commodities'))
            commodityDbGuids.forEach((dbGuid) => {
                const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid)
                if (stateIndex && !commodityDbGuids.some((dbguid) => dbguid == `commodities.${stateIndex}.ConnectedLocationIndex`)) {
                    delete data[`commodities.${stateIndex}.${fieldName}`]
                }
            })

            return data
        }
    }

    processNonOCRInputs = (data) => { // Add non-ocr (regular input) field values to OCR submit data

        // info
        Object.values(this.state.fields.info[0]).forEach((field) => {
            if (!field.disabled && !!field.value) {
                data[`info.0.${field.name}`] = this.state.fields['info']['0'][field.name]
            }
        })

        // locations
        Object.values(this.state.fields.locations).forEach((location, index) => {
            const fields = Object.values(location)
            fields.forEach((field) => {
                if (field.type !== 'hidden' && !field.disabled && !!field.value) {
                    data[`locations.${index}.${field.name}`] = this.state.fields['locations'][index][field.name]
                }
            })
        })

        // commodities
        Object.values(this.state.fields.commodities).forEach((commodity, index) => {
            const fields = Object.values(commodity).splice(3)
            fields.forEach((field) => {
                if (!field.disabled && !!field.value) {
                    data[`commodities.${index}.${field.name}`] = this.state.fields['commodities'][index][field.name]
                }
            })
        })

        // pricing
        Object.values(this.state.fields.pricing[0]).forEach((field) => {
            if (!field.disabled && !!field.value) {
                data[`pricing.0.${field.name}`] = this.state.fields['pricing']['0'][field.name]
            }
        })

        return data
    }

    processFieldData = (field, dbGuid) => {
        switch (field.metadata.loadFieldType) {
            case 'select': {
                return {[dbGuid]: getSelectValue(field.name, field.value)}
            }
            case 'select-search':
                if (field.name === "CustomerID") {
                    return {
                        [dbGuid]: field.value
                    }
                }
                if (field.name === "StopID") {
                    return {
                        [dbGuid]: {
                            request: this.request(field.name, {searchFields: field.value}),
                            name: field.name,
                            dbGuid: dbGuid
                        }
                    }
                }

                return {
                    [dbGuid]: {
                        request: this.request(field.name, {query: field.value}),
                        name: field.name,
                        dbGuid: dbGuid
                    }
                }
            case 'date': {
                let date = field.value;
                let dateFormat = guessDateFormat(date);
                if (dateFormat) {
                    date = toBackDate(moment(date, dateFormat))
                } else {
                    date = ""
                }

                return {
                    [dbGuid]: date
                }
            }
            case 'time-custom': {
                const time = field.value?.toLowerCase().replace(/\s/g, "");
                let timeFormat = "HH:mm";
                if (time?.toLowerCase().search("m") > -1) {
                    timeFormat = "hh:mma";
                }

                return {
                    [dbGuid]: time ? moment(time, timeFormat).format(getUserTimeFormat()) : ""
                }
            }
            default:
                return {[dbGuid]: field.value}
        }
    }

    processSelectResponses = (processedData, responses) => {
        const selects = Object.values(processedData).filter(it => !!it.request);

        return responses.reduce((memo, it, index) => {
            const locationName = processedData?.[`locations.${index}.LocationName`];

            memo[selects[index].dbGuid] = it.data?.data?.list?.[0]
                ? this.loadSelects[selects[index].name].searchMap(it.data.data.list[0])
                : {};

            // Placeholder / test messages
            if (it.data?.data?.list?.length > 1) {
                memo[selects[index].dbGuid].report = {
                    warnings: {
                        message: "Similar data found, best result may not be selected",
                        field: selects[index].dbGuid
                    }
                }
            }

            if (it.data?.data?.list?.length === 0) {
                const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(selects[index].dbGuid);

                memo[selects[index].dbGuid].report = {
                    warnings: {
                        message: `Location ${locationName} was not found in our system. It will be created automatically for you.`,
                        field: "", //this.props.translate("field." + fieldName), // Field name is redundant
                        actions: {
                            createLocation: this.createLocationData[selects[index].dbGuid]
                        }
                    }
                }

                const fieldsUpdate = Object.assign({}, this.state.fields);

                if (fieldName === 'StopID') {
                    memo[selects[index].dbGuid] = Object.assign(
                        memo[selects[index].dbGuid],
                        {value: "-1", label: fieldsUpdate[stateName]?.[stateIndex]?.['LocationName']?.value}
                    )
                }
            }

            return memo;
        }, {});
    }

    consumeReports = (data) => {
        let OCRReports = {
            errors: [],
            warnings: [],
            messages: []
        };

        const OCRdData = Object.keys(data).reduce((memo, it) => {
            if (data[it].report) {
                // Placeholder logic
                if (data[it].report.warnings) {
                    OCRReports.warnings.push(data[it].report.warnings);
                }

                if (data[it].report.errors) {
                    OCRReports.errors.push(data[it].report.errors);
                }

                delete data[it].report;
            }

            memo[it] = data[it];
            return memo;
        }, {});
        return [OCRdData, OCRReports];
    }

    organizeData = (data) => {
        let emptyData = {
            "load/multistops": {},
            "load/commodities": {}
        };

        return Object.keys(data).reduce((memo, dbGuid) => {
            const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(dbGuid);

            if ("locations" === stateName) {
                if (!memo["load/multistops"][stateIndex]) {
                    memo["load/multistops"][stateIndex] = {}
                }

                if (data[dbGuid].value) {
                    memo["load/multistops"][stateIndex][fieldName] = data[dbGuid].value;
                    memo["load/multistops"][stateIndex][fieldName.replace("ID", "")] = data[dbGuid].label;
                    const stopData = data[dbGuid].metadata ?? this.createLocationData[dbGuid]; // From created or existing stop

                    if (stopData) {
                        Object.keys(this.createLocationData[dbGuid]).forEach(key => {
                            memo["load/multistops"][stateIndex][key] = stopData[key];
                        })
                        memo["load/multistops"][stateIndex]['State'] = stopData.State ?? this.getStateFullName(stopData.StateID, stateIndex)
                    }

                } else {
                    memo["load/multistops"][stateIndex][fieldName] = data[dbGuid];
                }
            }

            if ("commodities" === stateName) {
                if (!memo["load/commodities"][stateIndex]) {
                    memo["load/commodities"][stateIndex] = {}
                }

                if (data[dbGuid].value) {
                    data[dbGuid][fieldName] = data[dbGuid].value;
                    data[dbGuid][fieldName.replace("ID", "")] = data[dbGuid].label;
                } else {
                    memo["load/commodities"][stateIndex][fieldName] = data[dbGuid];
                }
            }

            if ("info" === stateName) {
                if (data[dbGuid]?.value) {
                    memo[fieldName] = data[dbGuid].value;
                    memo[fieldName.replace("ID", "")] = data[dbGuid]?.label;
                    memo[`${fieldName}Metadata`] = data[dbGuid]?.metadata
                } else {
                    memo[fieldName] = data[dbGuid];
                }
            }

            if ("pricing" === stateName) {
                memo[fieldName] = data[dbGuid];
            }

            return memo;
        }, emptyData);
    }

    /** Form submission
     ================================================================= */
    handleSetClick = () => {
        let processedData = {}

        let data = this.processPairsData(this.state.pairs);
        data = this.processCommodities(data)
        data = this.processNonOCRInputs(data)

        Object.keys(data).forEach(dbGuId => {
            processedData = Object.assign(processedData, this.processFieldData(data[dbGuId], dbGuId));
        });

        // 2. Get a separate (helper) list of async/select-search fields
        const requests = Object.values(processedData).reduce((memo, it) => {
            if (it.request) {
                memo.push(it.request);
            }
            return memo;
        }, []);

        // 3. Process data from select list, and merge with synchronous data
        axios.all(requests).then((responses) => {
            // 3a. Merge async and non async data
            let mergedData = Object.assign(
                processedData,
                this.processSelectResponses(processedData, responses) // add async data from response
            )

            // 4. Consume report messages from the data
            const [OCRdData, OCRReports] = this.consumeReports(mergedData);

            // 5. Organize data structure to be like server response data structure
            let organizedData = this.organizeData(OCRdData);

            // setState and open report dialog
            this.setState({
                processedOCRData: organizedData,
                OCRReports: OCRReports,
                isReportDialogOpen: true
            })
        });
    }
    handleSubmitFeedback = (params) => {
        this.setState({isLoading: true}, () => {

                const formData = new FormData();
                formData.append('file', this.state.file[0]);
                formData.append('text', params.Description);

                axios.post(
                    process.env.REACT_APP_OCR_API_URL_TICKET,
                    formData,
                    {
                        headers: {
                            "Authorization": `Bearer ${getJWT().access_token}`,
                            "Content-Type": "multipart/form-data"
                        }
                    }
                )
                    .then((response) => {
                        this.setState({
                            isLoading: false,
                            isFeedbackModalOpen: false
                        })
                        this.props.dispatch(pushNotification({
                            title: 'Feedback submitted',
                            content: 'Your feedback has been recorded.',
                            timeout: 10000
                        }))
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
            }
        )
    }

    /** Helpers
     ================================================================= */
    getInferenceData = async (uuid) => {
        const response = await axios.get(
            Env.getOcrApiUrl('ocr/manager/get-ai'),
            {
                params: {
                    uuid
                },
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                }
            }
        )

        return response.data.data
    }

    getSnapshot = async (uuid) => {
        const response = await axios.get(
            Env.getOcrApiUrl('ocr/manager/get-snapshot'),
            {
                params: {
                    uuid
                },
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                }
            }
        )

        return response.data
    }

    getPdf = async (uuid) => {
        const response = await axios.get(Env.getOcrApiUrl('ocr/manager/get-pdf'),
            {
                params: {
                    uuid
                },
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                },
                responseType: 'blob',
            }
        )
        return response.data
    }

    /*updatePairs = (data) => {
        return data["exchange-document"].page.reduce((memo, page) => {
            if (page?.element?.length) {
                page.element.forEach(element => {
                    let dbGuid = element?.specific?.DbGuid;

                    if (!!dbGuid && isValidDbGuid(dbGuid)) {
                        memo[dbGuid] = Object.assign({}, element);

                        // e.g. For city/state/zip
                        if (element?.specific?.['multi-field-element']) {
                            const indexPairs = element.specific['multi-field-element'];

                            delete element.specific['multi-field-element'];

                            indexPairs.split(',').forEach(pairDbguid => {
                                let elementClone = cloneDeep(element);
                                elementClone.specific.DbGuid = pairDbguid;

                                memo[pairDbguid] = elementClone;
                            });
                        }
                    }
                });
            }

            return memo;
        }, {});
    }

    updateSnap = (snap, pairs) => {
        Object.keys(pairs).forEach((dbGuid) => {
            const element = pairs[dbGuid];
            if (element?.specific?.['merged-with']) {
                const mergedElements = JSON.parse(element?.specific?.['merged-with']);
                Object.keys(mergedElements).forEach(elKey => {
                    const elIndex = snap["snapshot-document"]["page"][mergedElements[elKey].pageIndex]['element']
                        .findIndex(it => {
                            if (
                                it.specific.type === element.specific.type
                                && it.location.lo[0].toFixed(2) === element.location.lo[0].toFixed(2)
                                && it.location.hi[1].toFixed(2) === element.location.hi[1].toFixed(2)
                            ) {
                                return true;
                            }
                        });
                    if (elIndex !== -1) {
                        snap["snapshot-document"]["page"][element.specific.pageIndex]['element'][elIndex].specific.DbGuid = dbGuid;
                    }
                });
            }

            const pairIndex = snap["snapshot-document"]["page"][element.specific.pageIndex]['element'].findIndex(it => {
                if (
                    it.specific.type === element.specific.type
                    && it.location.lo[0].toFixed(2) === element.location.lo[0].toFixed(2)
                    && it.location.hi[1].toFixed(2) === element.location.hi[1].toFixed(2)
                ) {
                    return true;
                }
            });
            if (pairIndex !== -1) {
                snap["snapshot-document"]["page"][element.specific.pageIndex]['element'][pairIndex].specific.DbGuid = dbGuid;
            }
        });

        return snap;
    }

    setPairValues = (pairs) => {
        let fieldsUpdate = Object.assign({}, this.state.fields);

        fieldsUpdate = Object.keys(pairs).reduce((memo, fieldDbGuid) => {
            const [fieldName, stateName, stateIndex] = getValuesFromDbGuid(fieldDbGuid);

            if (!memo?.[stateName]?.[stateIndex]) {
                memo = this.addState(memo, stateName, stateIndex);
            }

            if (fieldName === 'CustomerID') {
                this.updateCustomerField(
                    pairs[fieldDbGuid],
                    this.state.fields?.[stateName]?.[stateIndex]?.[fieldName])
            } else {
                let elementValue = getContentValue(
                    pairs[fieldDbGuid],
                    this.state.fields?.[stateName]?.[stateIndex]?.[fieldName]
                );

                // Set paired values
                if (pairs[fieldDbGuid].specific['merged-with']) {
                    const mergedElements = pairs[fieldDbGuid].specific['merged-with']
                        ? JSON.parse(pairs[fieldDbGuid].specific['merged-with'])
                        : {};

                    elementValue = Object.values(mergedElements).reduce((memo, it) => {
                        memo = memo + (it.concatString + it.content);
                        return memo;
                    }, elementValue);
                }

                memo[stateName][stateIndex] = FieldsManager.updateField(memo[stateName][stateIndex], fieldName, elementValue)
                memo[stateName][stateIndex][fieldName].disabled = true;

                if (fieldName === 'StopTime') {
                    fieldsUpdate[stateName][stateIndex]['RequiresAppointment'].value = pairs[fieldDbGuid].specific['format-name'] === 'TRUCKOCR_APPOINTMENT_TIME' ? 1 : 0
                }
            }
            return memo;
        }, fieldsUpdate);

        this.setState({
            fields: fieldsUpdate,
            pairs: Object.assign({}, this.state.pairs, pairs)
        });
    }*/

    mergeSameIndexPairs = (pairs) => {
        const uniqueElementIndexes = {};

        return Object.keys(pairs).reduce((memo, key) => {
            if (!uniqueElementIndexes[pairs[key].specific.index]) {
                memo[key] = pairs[key];
                uniqueElementIndexes[pairs[key].specific.index] = key;
            } else {
                const originalElKey = uniqueElementIndexes[pairs[key].specific.index];

                memo[originalElKey].specific['multi-field-element'] = memo[originalElKey].specific['multi-field-element']
                    ? memo[originalElKey].specific['multi-field-element'] + "," + key
                    : key;
            }

            return memo;
        }, {});
    }

    request = (name, query) => {
        return axios.get(
            Env.getApiUrl(
                this.loadSelects[name].api,
                Object.assign(
                    this.loadSelects[name].query,
                    query,
                    {
                        offset: 0,
                        limit: 10
                    })),
            {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }
        )
    }

    handleNewLocationData = (locationsData) => {
        this.setState({
            createdLocations: locationsData
        })
    }

    getDocumentDpi = () => {
        return this.state.snap?.['snapshot-document']?.specific?.DPI?.replace(/[^\d,]+/g, '')?.split(',')?.[0] || 300
    }

    getUnloadLocationSelectOptions = () => {
        let defaultOptions = {"0": "Location #1"}

        // go through all currently present commodities - if any of the fields in that commodity instance contains data, add the dropdown option to unload commodity from that location
        const locationsWithCommodityData = Object.values(this.state.fields.commodities).filter((commodity) => commodity.ConnectedLocationIndex !== 0).reduce((validCopyLocations, commodityObject) => {
            const locationCommodityConnectIndex = parseInt(commodityObject.ConnectedLocationIndex.value)
            const commodityData = FieldsManager.getFieldKeyValues(Object.values(commodityObject).toSpliced(0, 3))
            if (Object.values(commodityData).some((commodityFieldValue) => commodityFieldValue !== "")) {
                validCopyLocations[locationCommodityConnectIndex.toString()] = `Location #${locationCommodityConnectIndex + 1}`
            }
            return validCopyLocations
        }, {})

        return Object.assign(defaultOptions, locationsWithCommodityData)
    }

    getReferenceNumberElement = () => {
        return this.state.snap?.['snapshot-document']?.page[0]?.element?.find((el) => el.specific['format-name']?.includes('REFERENCE_NUMBER'))
    }

    getCustomerNameElement = () => {
        return this.state.snap?.['snapshot-document']?.page[0]?.element?.find((el) => el.specific['format-name'] === 'TRUCKOCR_CUSTMERS_NAME_LEGAL_64')
    }

    getStateFullName = (stateID, locationIndex) => {
        if (!stateID) {
            // Read State from StateID field when entered manually
            const stateAbbreviation = this.state.fields.locations[locationIndex]['StateID'].value
            stateID = this.statesAbbr[stateAbbreviation]
            return this.stateFullNames[stateID]?.slice(0, -5) ?? ""
        }

        return this.stateFullNames[stateID]?.slice(0, -5) ?? ""
    }

    getTextareaFields = () => {
        const textareaFields = Object.values(this.state.fields.locations).map((location) => location['Notes'])
        textareaFields.push(this.state.fields["info"]["0"]["ExternalNotesCustomer"])
        return textareaFields
    }

    checkTextareasForContent = () => {
        return this.getTextareaFields().some((field) => !!field.value)
    }

    isTargetFieldValid = () => {
        const currentElementFormat = this.state.selectedElement?.specific?.['format-name']
        let targetField = extractFieldName(this.state.hoveredFieldDbGuid)

        return (
            (OCR_GENERIC_FORMAT_TYPES.includes(currentElementFormat) && !OCR_SPECIFIC_FIELDS.includes(targetField))
            || (!OCR_GENERIC_FORMAT_TYPES.includes(currentElementFormat) && !OCR_SPECIFIC_FIELDS.includes(targetField))
            || ((OCR_DATE_TIME_FORMAT_TYPES.includes(currentElementFormat) || elementContainsDateTimeFragments(this.state.selectedElement)) && OCR_DATE_TIME_FIELDS.includes(targetField))
            || ((OCR_MONEY_FORMAT_TYPES.includes(currentElementFormat) || elementContainsMoneyFragments(this.state.selectedElement)) && OCR_MONEY_FIELDS.includes(targetField))
        )
    }

    isSaveDisabled = () => {
        const {isLoading, documentUri, isLLMNetworkError} = this.state
        return isLoading || !documentUri || isLLMNetworkError || !this.state.snap?.['snapshot-document']?.specific
    }

    /** UI Events
     ================================================================= */
    handleAcceptFile = (file) => {
        this.setState({
            file: file,
        }, () => {
            this.submitFileForProcessing(file)
            this.fetchImage(file)
            this.props.updateCurrentFile(file)
        })
    }

    handleSelectElement = (element = {}) => {
        this.setState({
            selectedElement: element,
            isSidebarTempVisible: !!element?.specific?.index
        });
    }

    handleInputChange = (fieldsName, index, name, value) => {
        let fieldsUpdate = Object.assign({}, this.state.fields);
        const sectionFieldsUpdate = fieldsUpdate[fieldsName][index];
        fieldsUpdate[fieldsName][index] = FieldsManager.updateField(sectionFieldsUpdate, name, value);

        if (name === 'UnloadFrom') {
            fieldsUpdate[fieldsName][index]['UnloadFromLocation'].disabled = !value
            if (!value) {
                fieldsUpdate[fieldsName][index]['UnloadFromLocation'].value = ""
            }
        }

        if (name === 'UnloadFrom') {
            const commodityFields = Object.values(this.state.fields.commodities[index]).splice(3)
            commodityFields.forEach((field) => {
                fieldsUpdate['commodities'][index][field.name].type = value ? 'hidden' : getFieldType(field.name)
            })
        }

        this.setState({
            fields: fieldsUpdate
        });
    }

    handlePrevLocationClick = () => {
        let prevIndex = this.state.selectedLocationIndex;
        prevIndex = prevIndex - 1;
        if (prevIndex < 0) {
            prevIndex = Object.values(this.state.fields.locations).length - 1;
        }

        this.setState({
            selectedLocationIndex: prevIndex
        });
    }

    handleNextLocationClick = () => {
        let prevIndex = this.state.selectedLocationIndex;
        prevIndex = prevIndex + 1;
        if (prevIndex >= Object.values(this.state.fields.locations).length) {
            prevIndex = 0;
        }

        this.setState({
            selectedLocationIndex: prevIndex
        });
    }

    handleSelectLocationClick = (index) => {
        this.setState({
            isLocationFormVisible: true,
            selectedLocationIndex: index,
        }, () => {
            this.locationBackButtonRef.current.focus();
        });
    }

    setFocusedElement = (name) => {
        this.setState({
            focusedField: name
        });
    }

    unSetFocusedElement = () => {
        this.setState({
            focusedField: ""
        });
    }

    handleUnlinkFieldClick = (dbGuid, element, keepContent = false) => {
        const fieldName = dbGuid.split(".")[2];
        if (OCR_MULTIFILL_FIELDS.includes(fieldName) && element?.specific?.["multifill-linked-fields"]) {
            this.unpairMultipleFields(dbGuid, element, keepContent)
        } else {
            this.unpairSingleField(dbGuid, element, keepContent)
        }
    }

    handleDocumentPageChange = (documentCurrentPage) => {
        this.setState({
            documentCurrentPage: documentCurrentPage,
            paginationInputValue: documentCurrentPage
        });
    }

    handlePaginationInputChange = (e) => {
        this.setState({
            paginationInputValue: e.target.value
        });
    }

    handlePaginationInputKeyDown = (e) => {
        if (isNaN(e.target.value)) {
            return false

        }

        if (e.key === 'Enter') {
            if (Number(this.state.paginationInputValue) > Number(this.state.documentPagesNumber) || Number(this.state.paginationInputValue) < 1) {
                this.setState({
                    paginationInputValue: this.state.documentCurrentPage,
                });
            } else {
                this.setState({
                    documentCurrentPage: this.state.paginationInputValue,
                });
            }
        }

        return false;
    }

    handleAddLocation = () => {
        const fieldsUpdate = Object.assign({}, this.state.fields);
        const nextLocationIndex = Object.values(fieldsUpdate['locations']).length;

        fieldsUpdate['locations'][nextLocationIndex] = this.getLoadLocationFields(nextLocationIndex);

        Object.values(fieldsUpdate.locations[nextLocationIndex]).forEach((field) => {
            let dbGuid = 'locations' + '.' + nextLocationIndex + '.' + field.name
            this.handleUnlinkFieldClick(dbGuid)
        })

        fieldsUpdate['commodities'][nextLocationIndex] = this.getLoadCommodityFields(nextLocationIndex)

        this.setState({
            fields: fieldsUpdate
        });
    }

    handleDeleteLocationClick = (index) => {
        let fieldsUpdate = this.state.fields;

        Object.values(fieldsUpdate.locations[index]).forEach((field) => {
            let dbGuid = 'locations' + '.' + index + '.' + field.name
            this.handleUnlinkFieldClick(dbGuid)
        })
        delete fieldsUpdate.locations[index];
        fieldsUpdate.locations = Object.values(fieldsUpdate.locations).reduce((memo, it, index) => {
            memo[index] = it;
            return memo;
        }, {});

        // Delete commodity corresponding to location index
        Object.values(fieldsUpdate.commodities[index]).forEach((field) => {
            let dbGuid = 'commodities' + '.' + index + '.' + field.name
            this.handleUnlinkFieldClick(dbGuid)
        })
        delete fieldsUpdate.commodities[index];
        fieldsUpdate.commodities = Object.values(fieldsUpdate.commodities).reduce((memo, it, index) => {
            memo[index] = it;
            return memo;
        }, {});

        // Remap indexes to retain proper order after deleting location-commodity pair
        const currentNumberOfCommodities = Object.keys(this.state.fields.commodities).length
        const lastCommodityIndex = currentNumberOfCommodities - 1
        const lastLocationConnectedIndex = parseInt(this.state.fields.commodities[lastCommodityIndex]['ConnectedLocationIndex'].value)

        if (lastLocationConnectedIndex >= currentNumberOfCommodities) {
            let newStartingIndex = 0
            Object.values(fieldsUpdate.commodities).forEach((commodityFieldsObject) => {
                commodityFieldsObject['ConnectedLocationIndex'].value = newStartingIndex.toString()
                newStartingIndex += 1
            })
        }

        // Remap pairs DbGuids to reflect updated indexes
        const currentNumberOfLocations = Object.keys(this.state.fields.locations).length
        const lastLocationIndex = currentNumberOfLocations - 1
        const locationPairsDbGuids = Object.keys(this.state.pairs).filter((pairKey) => pairKey.includes('locations'))
        const lastDbGuidPairsIndex = parseInt(getValuesFromDbGuid(locationPairsDbGuids[locationPairsDbGuids.length - 1])[2])

        let pairsUpdate = this.state.pairs

        if (lastDbGuidPairsIndex >= currentNumberOfLocations) {
            pairsUpdate = Object.entries(this.state.pairs).reduce((memo, element) => {
                let [fieldName, stateName, stateIndex] = getValuesFromDbGuid(element[1].specific.DbGuid);
                if (stateIndex > lastLocationIndex) {
                    const newDbGuid = `${stateName}.${(stateIndex - 1).toString()}.${fieldName}`
                    element[1].specific.DbGuid = newDbGuid
                    memo[newDbGuid] = element[1]
                } else {
                    memo[element[1].specific.DbGuid] = element[1]
                }
                return memo
            }, {})
        }

        this.setState({
            pairs: pairsUpdate,
            fields: fieldsUpdate,
            selectedLocationIndex: 0,
            isLocationFormVisible: false
        });
    }

    toggleSidebar = () => {
        this.setState({
            isSidebarCollapsed: !this.state.isSidebarCollapsed,
            disableZoomToFit: false
        })
    }

    handleMouseDown = () => {
        this.setState({
            isMouseButtonDown: true,
        });
    };

    handleMouseUp = (e) => {
        const element = cloneDeep(this.state.selectedElement);
        const dbGuid = e.target?.dataset?.dbguid;

        if (!element?.specific || !dbGuid) {
            return null;
        }

        let snap = this.state.snap;

        const existingElementIndex = snap['snapshot-document']
            .page[this.state.documentCurrentPage - 1]
            .element
            .findIndex(el => el.specific?.DbGuid === dbGuid);

        if (existingElementIndex > -1
            && !!snap?.['snapshot-document']?.page[this.state.documentCurrentPage - 1]?.element[existingElementIndex]
            && this.isTargetFieldValid()
        ) {
            this.setState({
                buffer: {
                    element: element,
                    dbGuid: dbGuid,
                    existingElementIndex: existingElementIndex
                },
                isElementsConflictDialogOpen: true
            });
        } else {
            if (getValuesFromDbGuid(dbGuid)[0] === 'CustomerID') {
                this.pairElementWithAsyncField(element, dbGuid, existingElementIndex)
            } else {
                this.pairElementWithField(element, dbGuid, existingElementIndex);
            }
        }
    }

    updateZoom = (mod) => {
        let currentZoom = Math.floor(this.state.documentZoomModifier / 0.2) * 0.2;

        if (!(currentZoom % 1)) {
            currentZoom = Number(currentZoom.toFixed(1));
        }

        this.setState({
            documentZoomModifier: mod === 'minus'
                ? Number(currentZoom) - 0.2
                : Number(currentZoom) + 0.201,
            disableZoomToFit: false
        })
    }

    zoomToFit = () => {
        const fitZoomModifier = this.state.documentZoomModifier / (this.documentContainerRef.current.offsetWidth / this.documentScrollableContainerRef.current.offsetWidth);

        if (!this.state.disableZoomToFit) {
            this.setState({
                documentZoomModifier: fitZoomModifier,
                disableZoomToFit: true
            });
        }
    }

    toggleHandleLocationForm = () => {
        this.setState({
            isLocationFormVisible: !this.state.isLocationFormVisible
        });
    }

    handleMinimizeDialogClick = () => {
        const dialogWrapperElement = this.dialogRef.current.parentElement.parentElement.parentElement.parentElement;
        if (dialogWrapperElement) {
            dialogWrapperElement.classList.toggle("translate-y-full")
        }
    }

    addState = (fields, name, index) => {
        if ('locations' === name) {
            fields[name][index] = this.getLoadLocationFields(index);
            fields['commodities'][index] = this.getLoadCommodityFields(index);
        }

        return fields;
    }

    /** Field definitions
     ================================================================= */
    getInfoFields = () => {
        let fields = {
            LoadTypeID: new Field('LoadTypeID', '1', [], false, 'select', {loadFieldType: 'select'}, {}),
            LoadSubTypeID: new Field('LoadSubTypeID', '', [], false, 'select', {loadFieldType: 'select'}, {}),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search',
                {
                    addContainerClass: "col-start-1 col-span-8",
                    loadFieldType: 'select-search',

                    fieldOptions: (it) => {
                        return <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    toolTip: this.props.translate("text.view_customer_info"),
                                    onClick: () => this.props.dispatch(showModal('ViewCustomerCard', it?.value?.metadata)),
                                    isVisible: !!it.value?.value
                                }
                            ]}
                        />
                    }
                },
                {isLoading: false}
            ),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [], false, 'text', {
                loadFieldType: 'text',
                addContainerClass: classNames("col-span-4 relative")
            }, {
                addClass: "form-control text-center relative focus:z-10",
                icon: <HashtagIcon className="w-4 5-4 text-rose-600 z-10"/>
            }),
            ExternalNotesCustomer: new Field('ExternalNotesCustomer', '', [], false, 'textarea', {
                loadFieldType: 'textarea',
                addContainerClass: classNames("col-span-full"),
            }, {
                addClass: 'w-full overflow-hidden form-control whitespace-pre-wrap resize-none'
            }),
        }

        return this.updateFieldObjects(fields, 'info', 0);
    }

    getLoadLocationFields = (locationIndex = 0) => {
        const fields = {
            StopID: new Field('StopID', '', [], false, 'hidden', {
                addContainerClass: "col-span-full",
                loadFieldType: "select-search"
            }, {}),
            LocationName: new Field('LocationName', this.props.prefilled ?? "", [''], false, 'text', {
                addContainerClass: "col-span-7"
            }, {}),
            StopType: new Field('StopType', locationIndex === 0 ? STOP_TYPE_PICKUP : STOP_TYPE_DELIVERY, [], false, 'select', {
                    loadFieldType: 'select',
                    addContainerClass: 'col-span-5'
                },
                {menuPlacement: 'top'}
            ),

            // Location fields that become consumed when used to get StopID value
            AddressName: new Field('AddressName', '', [''], false, 'text', {
                addContainerClass: "col-span-8",
            }, {
                icon: <HomeIcon className="w-4 h-4 text-indigo-600 z-10"/>
            }),
            ReferenceNumber: new Field(
                'ReferenceNumber', '', [], false, 'text', {
                    loadFieldType: 'text',
                    addContainerClass: "col-span-4"
                }
            ),
            CityName: new Field('CityName', '', [''], false, 'location-city', {
                addContainerClass: "col-span-4",
            }, {
                addClass: "form-control relative focus:z-10",
                icon: <MapPinIcon className="w-4 h-4 text-blue-500 z-10"/>
            }),
            StateID: new Field('StateID', '', [''], false, 'select', {
                addContainerClass: "col-span-4",
            }, {
                addClass: "form-control relative focus:z-10",
                icon: <MapPinIcon className="w-4 h-4 text-blue-500 z-10"/>
            }),
            PostalCode: new Field('PostalCode', '', [''], false, 'location-zip', {
                addContainerClass: "col-span-4"
            }, {
                addClass: "form-control relative focus:z-10",
                icon: <MapPinIcon className="w-4 h-4 text-blue-500 z-10"/>
            }),
            // Location fields end

            StopDate: new Field('StopDate', '', [], false, 'text', {
                loadFieldType: 'date',
                label: "available_from_date_time",
                addContainerClass: "3xl:col-span-3 3xl:col-start-1 col-span-3 w-[calc(100%+1rem+1px)]",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {
                addClass: "form-control text-center relative rounded-r-none",
                icon: <CalendarDaysIcon className="w-4 h-4 text-cyan-300 z-10"/>
            }),
            StopTime: new Field('StopTime', '', [], false, 'text', {
                loadFieldType: 'time-custom',
                hideLabel: true,
                addContainerClass: "3xl:col-span-3 col-span-3",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 h-5"
            }, {
                addClass: "form-control text-center relative rounded-l-none focus:z-10",
                icon: <ClockIcon className="w-4 h-4 text-orange-500 z-10"/>
            }),
            StopEndDate: new Field('StopEndDate', '', [], false, 'text', {
                loadFieldType: 'date',
                label: "available_through_date_time",
                addContainerClass: "3xl:col-span-3 col-span-3 w-[calc(100%+1rem+1px)]",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {
                isClearable: true,
                addClass: "form-control text-center relative rounded-r-none focus:z-10",
                icon: <CalendarDaysIcon className="w-4 h-4 text-cyan-300 z-10"/>
            }),
            StopEndTime: new Field('StopEndTime', '', [], false, 'text', {
                loadFieldType: 'time-custom',
                hideLabel: true,
                addContainerClass: "3xl:col-span-3 col-span-3",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 h-5"
            }, {
                addClass: "form-control text-center relative rounded-l-none",
                icon: <ClockIcon className="w-4 h-4 text-orange-400 z-10"/>
            }),
            RequiresAppointment: new Field('RequiresAppointment', '', [], false, 'checkbox', {
                loadFieldType: 'checkbox',
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {
                loadFieldType: 'textarea',
                addContainerClass: classNames("col-span-full")
            }, {addClass: 'w-full overflow-hidden form-control whitespace-pre-wrap resize-none'}),
        }

        return this.updateFieldObjects(fields, 'locations', locationIndex);
    }

    getLoadCommodityFields = (commodityIndex = 0) => {
        const fields = {
            ConnectedLocationIndex: new Field('ConnectedLocationIndex', commodityIndex.toString(), [''], false, 'hidden'),
            UnloadFrom: new Field('UnloadFrom', commodityIndex !== 0, [''], false, commodityIndex === 0 ? 'hidden' : 'checkbox', {
                addContainerClass: "col-span-4"
            }),
            UnloadFromLocation: new Field('UnloadFromLocation', commodityIndex === 0 ? '' : "0", [''], false, commodityIndex === 0 ? 'hidden' : 'select', {
                addContainerClass: "col-span-8"
            }),
            ProductName: new Field('ProductName', '', [''], '', this.state?.fields?.commodities?.["1"]?.UnloadFrom?.value ? 'hidden' : 'text', {
                loadFieldType: 'text',
                addContainerClass: "col-span-full"
            }),
            UnitTypeID: new Field('UnitTypeID', '', [], false, 'select', {
                    loadFieldType: 'select',
                    addContainerClass: 'col-span-6 col-start-1'
                },
            ),
            ProductCode: new Field('ProductCode', '', [], '', 'text', {
                loadFieldType: 'text',
            }),
            CommodityReferenceNumber: new Field('CommodityReferenceNumber', '', [''], false, 'text', {
                loadFieldType: 'text',
            }),
            CountPallets: new Field('CountPallets', '', ['integer_or_empty'], false, 'float', {
                loadFieldType: 'text',
            }),
            CountPieces: new Field('CountPieces', '', ['integer_or_empty'], false, 'float', {
                loadFieldType: 'text',
                addContainerClass: "col-start-1 col-end-5"
            }),
            Hazmat: new Field('Hazmat', '', [''], false, 'checkbox', {
                loadFieldType: 'checkbox',
                altIcon: <ExclamationTriangleIcon className={'w-4 5-4 text-red-600'}/>,
                addContainerClass: "col-start-6 col-end-9"
            }),
            Stackable: new Field('Stackable', '', [''], false, 'checkbox', {
                loadFieldType: 'checkbox',
                addContainerClass: "col-start-9 col-end-12"
            }),
            MeasureUnitID: new Field('MeasureUnitID', '', [''], '', 'hidden'),
            Length: new Field('Length', 0, ['float_or_empty'], false, 'float', {
                    addContainerClass: "col-span-full sm:col-span-4",
                    htmlBefore: () => <Subtitle
                        className="text-tm-gray-500 col-span-full"
                        subtitle={this.props.translate('text.measurements')}
                    />
                }
            ),
            Width: new Field('Width', 0, ['float_or_empty'], false, 'float', {
                loadFieldType: 'text',
                addContainerClass: "col-span-full sm:col-span-4"
            }),
            Height: new Field('Height', 0, ['float_or_empty'], false, 'float', {
                loadFieldType: 'text',
                addContainerClass: "col-span-full sm:col-span-4"
            }),

            Weight: new Field('Weight', 0, ['float_or_empty'], false, 'float', {
                loadFieldType: 'text',
                addContainerClass: "col-span-full sm:col-span-4"
            }, {
                icon: <ScaleIcon className="w-4 h-4 fill-[#704322] z-10"/>
            }),

            Temp: new Field('Temp', "", ['float_or_empty'], false, 'text', {
                loadFieldType: 'text',
                addContainerClass: "col-span-full sm:col-span-4"
            }),
        }

        return this.updateFieldObjects(fields, 'commodities', commodityIndex);
    }

    getPricingFields = () => {
        let fields = {
            Price: new Field('Price', '', [], false, 'text', {loadFieldType: 'text'}, {
                addClass: "form-control text-center relative focus:z-10",
                icon: <CurrencyDollarIcon className="w-4 5-4 text-green-700 z-10"/>
            }),
        }

        return this.updateFieldObjects(fields, 'pricing', 0);
    }

    updateFieldObjects = (fields, fieldSectionName, stateIndex) => {
        return Object.values(fields).reduce((memo, it) => {
            it.metadata = Object.assign({
                addContainerClass: "col-span-full sm:col-span-6",
                fieldOptions: (it) => {
                    const rightAlignedMenuFields = ['LoadTypeID', 'Price', 'Length', 'Width', 'StopDate', 'Weight', 'CityName', 'RequiresAppointment', 'UnitTypeID', 'CommodityReferenceNumber', 'CountPieces']
                    return (
                        <React.Fragment>
                            {!!this.state?.pairs?.[fieldSectionName + "." + stateIndex + "." + it.name] && (
                                <MenuDropdown
                                    buttonLabel={<EllipsisHorizontalIcon className="w-5 h-5"/>}
                                    buttonClass={"btn btn-outline h-auto p-0.5" + (it.metadata.loadFieldType === 'date' ? " absolute -bottom-1 right-0" : " relative bottom-1.5")}
                                    addMenuItemClass={rightAlignedMenuFields.includes(it.name) ? "left-0" : "right-0"}
                                    options={[
                                        {
                                            icon: XMarkIcon,
                                            label: "Unpair field",
                                            onClick: () => this.handleUnlinkFieldClick(fieldSectionName + "." + stateIndex + "." + it.name, this.state?.pairs?.[fieldSectionName + "." + stateIndex + "." + it.name])
                                        },
                                        {
                                            icon: LockOpenIcon,
                                            label: "Unlock field",
                                            onClick: () => this.handleUnlinkFieldClick(fieldSectionName + "." + stateIndex + "." + it.name, this.state?.pairs?.[fieldSectionName + "." + stateIndex + "." + it.name], true)
                                        },
                                        {
                                            icon: Square2StackIcon,
                                            label: "Copy text",
                                            onClick: () => navigator.clipboard.writeText(it.value)
                                        }]}
                                />
                            )}
                        </React.Fragment>
                    )
                },
                htmlAfterField: (item) => {
                    return <React.Fragment>
                        <div
                            onMouseEnter={(event) => this.setState({
                                hoveredFieldDbGuid: event.target.getAttribute("data-dbguid")
                            })}
                            data-dbguid={fieldSectionName + "." + stateIndex + "." + item.name}
                            className={"hidden group-hover:block z-10 hover:border-2 rounded-btn absolute -inset-2" + (this.isTargetFieldValid() ? " hover:bg-green-600/10 border-green-600" : " hover:bg-red-600/10 border-red-600")}
                        />

                        {item.disabled && item.type !== 'select' && item.type !== 'select-search' && (
                            <Tooltip content={item.value}>
                                <div className="absolute group-hover:hidden flex justify-end inset-0 top-5">
                                    <button
                                        onFocus={() => this.setFocusedElement(fieldSectionName + "." + stateIndex + "." + it.name)}
                                        onBlur={this.unSetFocusedElement}
                                        className={"absolute w-full rounded-btn inset-0 hover:ring-2 focus:ring-2 ring-offset-2 ring-primary-tint ring-offset-inverse" + (item.metadata.loadFieldType === 'date' ? " z-[11]" : "")}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </React.Fragment>
                }
            }, it.metadata)

            memo[it.name] = it;
            return memo;
        }, {});
    }

    /** View
     ================================================================= */
    render() {
        const {onCloseScanDialog, onSave, translate} = this.props;

        const {
            documentPagesNumber,
            paginationInputValue,
            isLoading,
            isMouseButtonDown,
            isSidebarCollapsed,
            isSidebarTempVisible,
            documentCurrentPage,
            documentUri,
            documentZoomModifier,
            focusedField,
            pairs,
            isLocationFormVisible
        } = this.state;

        const selectedLocationAddress = getFullAddressName(this.state["loadLocationsFields" + this.state.selectedLocationIndex]?.StopID?.value?.metadata ?? "");

        return (
            <div ref={this.dialogRef}>

                <ModalHeader
                    title={translate("dialog_heading.autofill_load")}
                    onClose={onCloseScanDialog}
                >
                    <button className={"btn btn-primary ml-10"}
                            onClick={() => this.setState({isFeedbackModalOpen: true})}
                            disabled={!this.state.file.length || isLoading}>
                        Leave feedback
                    </button>

                    <button
                        onClick={this.props.onMinimizeDialogClick}
                        className="btn btn-close justify-center ml-auto mr-10 mt-1.5"
                    >
                        <ChevronDownIcon className="w-5 h-5"/>
                    </button>
                </ModalHeader>

                <div
                    className="flex h-[calc(100vh-8rem)]"
                    ref={this.dialogBodyRef}
                    onMouseUp={this.handleMouseUp}
                >
                    <div
                        className={
                            classNames(
                                "relative overflow-auto w-full border-r border-tm-gray-200 select-none min-h-[50vh]",
                                isMouseButtonDown ? "cursor-garb" : undefined
                            )
                        }
                        ref={this.documentScrollableContainerRef}
                        onMouseDown={this.handleMouseDown}
                    >

                        {!!Object.keys(this.state.file).length && (
                            <LoaderFake
                                randomIntervalMin={500}
                                randomIntervalMax={1500}
                                estimatedTime={25000}
                                isFinished={!!Object.keys(this.state.LLMProcessedData).length}
                                onFinished={() => this.setState({
                                    isLLMSummaryDialogOpen: true,
                                    isProgressBarHidden: true
                                })}
                                isProgressBarHidden={this.state.isProgressBarHidden || this.state.isLLMNetworkError}
                            />
                        )}

                        {!documentUri && !isLoading && (
                            <div className="absolute inset-0 flex items-center justify-center">

                                <DropZoneAlt
                                    className="h-full w-full pt-6 pb-12 px-6"
                                    maxFilesAccepted={1}
                                    accept={"application/pdf"}
                                    translate={translate}
                                    onAcceptFiles={(file) => this.handleAcceptFile(file)}
                                    content={(isDragAccept, isDragReject) => {
                                        return (
                                            <React.Fragment>
                                                <div className={classNames(
                                                    isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                    isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                    !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                    "pb-4 w-full h-full relative rounded-card"
                                                )}>
                                                    <div
                                                        className="rounded-card absolute inset-0 flex items-center justify-center border-2 border-dashed border-tm-gray-300">
                                                        <div className="text-center">
                                                            <p className="text-lg">
                                                                Click here or
                                                                drop
                                                                file to
                                                                upload</p>
                                                            <p className="text-base text-tm-gray-500">(Max
                                                                1 file, 25
                                                                mb
                                                                per file, document and image files
                                                                only)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="absolute bottom-0 px-6 left-0 right-0 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                                    Attach pdf file by dragging and dropping or <span
                                                    className="text-primary-tint font-semibold">click here</span> to
                                                    select it.
                                                </div>
                                            </React.Fragment>
                                        )
                                    }}
                                />

                            </div>
                        )}

                        {!!documentUri && (
                            <>
                                <div
                                    className="sticky flex items-center top-0 left-0 right-auto h-14 z-50 px-5 bg-primary-transparent">
                                    <div className="flex items-center">
                                        <button
                                            className="btn btn-outline-secondary bg-inverse p-2 rounded-r-none focus:z-10 relative hover:z-10 text-tm-gray-600"
                                            disabled={documentZoomModifier >= 1.8}
                                            onClick={() => this.updateZoom('plus')}
                                        >
                                            <MagnifyingGlassPlusIcon className="w-5 h-5"/>
                                        </button>

                                        <button
                                            className="btn btn-outline-secondary bg-inverse p-2 rounded-none -ml-px focus:z-10 relative hover:z-10 text-tm-gray-600"
                                            disabled={this.state.disableZoomToFit}
                                            onClick={() => this.zoomToFit()}
                                        >
                                            <MagnifyingGlassIcon className="w-5 h-5"/>
                                        </button>

                                        <button
                                            className="btn btn-outline-secondary bg-inverse p-2 rounded-l-none -ml-px text-tm-gray-600"
                                            disabled={documentZoomModifier <= 0.4}
                                            onClick={() => this.updateZoom('minus')}
                                        >
                                            <MagnifyingGlassMinusIcon className="w-5 h-5"/>
                                        </button>

                                        <span className={"px-4 text-lg"}>Zoom: x{documentZoomModifier.toFixed(1)}</span>
                                    </div>

                                    <div className="ml-auto space-x-2">
                                        <button
                                            className="btn btn-outline-secondary bg-inverse p-2 -ml-px"
                                            onClick={this.toggleSidebar}
                                        >
                                            <ChevronRightIcon
                                                className={
                                                    classNames(
                                                        "w-5 h-5 text-tm-gray-600",
                                                        isSidebarCollapsed ? "rotate-180" : undefined
                                                    )
                                                }
                                            />
                                        </button>

                                        <button
                                            className="btn btn-outline-secondary bg-inverse p-2 -ml-px"
                                            onClick={() => this.setState({isLLMSummaryDialogOpen: !this.state.isLLMSummaryDialogOpen})}
                                            disabled={!Object.keys(this.state.LLMProcessedData).length}
                                        >
                                            <InformationCircleIcon
                                                className={
                                                    classNames(
                                                        "w-5 h-5 text-primary",
                                                        isSidebarCollapsed ? "rotate-180" : undefined
                                                    )
                                                }
                                            />
                                        </button>
                                    </div>
                                </div>
                                <span ref={this.documentContainerRef}>
                                    <OpticalCharacterRecognition
                                        elements={this.state.snap?.['snapshot-document']?.page?.[documentCurrentPage - 1]?.element}
                                        parentRef={this.dialogBodyRef}
                                        documentResource={Resources.OCRImage}
                                        documentUri={documentUri}
                                        onSelectElement={this.handleSelectElement}
                                        focusedElement={this.state.pairs?.[focusedField] ? this.state.pairs?.[focusedField] : {}}
                                        currentPage={documentCurrentPage}
                                        documentDPI={this.getDocumentDpi()}
                                        pairs={pairs}
                                        isLoading={isLoading}
                                        documentZoomModifier={documentZoomModifier}
                                        processFragments={processFragments}
                                        countFragmentTypes={countFragmentsTypesInstances}
                                    />
                                </span>
                            </>
                        )}
                    </div>

                    {/* RIGHT SIDEBAR */}
                    {!!Object.keys(this.state.LLMProcessedData).length && this.state.isLLMSummaryDialogOpen && (
                        <OcrDocumentSummary
                            translate={translate}
                            data={this.state.LLMProcessedData}
                            onAcceptClick={() => this.setState({isLLMSummaryDialogOpen: false})}
                            onCancelClick={() => this.setState({isLLMSummaryDialogOpen: false})}
                        />
                    )}

                    <div
                        className={
                            classNames(
                                "h-full max-w-[800px] w-[800px] overflow-y-auto",
                                isSidebarCollapsed && !isSidebarTempVisible ? "absolute left-full hidden" : 'relative'
                            )
                        }
                    >
                        {isLoading && (
                            <LoaderLarge stripesBg/>
                        )}

                        <div
                            className={
                                classNames(
                                    "p-6",
                                    Object.keys(this.state.selectedElement).length ? "group" : ""
                                )
                            }
                        >
                            <h2 className="text-lg leading-6 font-medium text-tm-gray-900 mb-3">
                                Basic load info
                            </h2>

                            <div className="relative">
                                <div className="grid grid-cols-12 gap-4">
                                    <FieldsToHtml
                                        fieldsState={this.state.fields.info[0]}
                                        translate={translate}
                                        onInputChange={(name, value) => this.handleInputChange('info', 0, name, value)}
                                        selects={Object.assign(this.loadSelects, this.state.LoadTypeSelectOptions)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-6 pt-2">
                            <h2 className="text-lg leading-6 font-medium text-tm-gray-900 mb-3">
                                Locations and commodities
                            </h2>

                            <div className="relative">
                                <div className="space-y-4">
                                    {Object.values(this.state.fields.locations).map((_, index) => {
                                        const locationAddress = getFullAddressName(this.state.fields.locations?.[index]?.StopID?.value?.metadata ?? "");
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    classNames(
                                                        "flex items-center space-x-4",
                                                        (index === 0 || index === 1) ? "pr-10" : ""
                                                    )
                                                }
                                            >
                                                <div
                                                    className="w-6 h-6 relative flex items-center justify-center group"
                                                >
                                                    <span // group-hover:hidden
                                                        className="w-6 h-6 text-base font-bold absolute text-center text-tm-gray-500 ">
                                                        {index + 1}.
                                                    </span>
                                                </div>

                                                <div
                                                    className="w-6 h-6 flex items-center justify-center flex-shrink-0 relative rounded-md focus:ring-2 text-tm-gray-500 cursor-default border-2 border-tm-gray-400"
                                                >
                                                    {Number(this.state.fields.locations[index].StopType.value) === STOP_TYPE_PICKUP && (
                                                        <ArrowUpIcon className="w-5 h-5"/>
                                                    )}

                                                    {Number(this.state.fields.locations[index].StopType.value) === STOP_TYPE_DELIVERY && (
                                                        <ArrowDownIcon className="w-5 h-5"/>
                                                    )}

                                                    {Number(this.state.fields.locations[index].StopType.value) === STOP_TYPE_PICKUP_AND_DELIVERY && (
                                                        <ArrowsUpDownIcon className="w-5 h-5"/>
                                                    )}
                                                </div>

                                                <button
                                                    onClick={() => this.handleSelectLocationClick(index)}
                                                    className={
                                                        classNames(
                                                            "btn btn-outline flex-col w-full relative justify-center items-start rounded-lg py-0.5 grow h-12 overflow-hidden",
                                                            index === this.state.selectedLocationIndex ? "bg-primary hover:bg-primary text-white" : "bg-primary-transparent"
                                                        )
                                                    }
                                                >
                                                    {this.state.fields.locations[index]?.LocationName?.value
                                                        ? this.state.fields.locations[index]?.LocationName?.value
                                                        : "No location selected"
                                                    }

                                                    {!!locationAddress && (
                                                        <div
                                                            className="w-full text-left truncate">{locationAddress}</div>
                                                    )}
                                                </button>

                                                {Object.values(this.state.fields.locations).length > 2 && index !== 0 && index !== 1 && (
                                                    <button
                                                        onClick={() => this.handleDeleteLocationClick(index)}
                                                        className="btn btn-close"
                                                    >
                                                        <XMarkIcon className="w-5 h-5"/>
                                                    </button>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>

                                <div
                                    className={
                                        classNames(
                                            "bg-tm-gray-50 p-3 border border-tm-gray-200 rounded-card mt-8",
                                            isLocationFormVisible ? "" : "hidden"
                                        )
                                    }
                                >
                                    <div className="flex gap-x-4 items-center mb-3 justify-center">
                                        <button
                                            className="btn btn-table-action"
                                            onClick={this.handlePrevLocationClick}
                                        >
                                            <ChevronLeftIcon className="w-5 h-5"/>
                                        </button>

                                        <h2 className="text-base leading-6 font-medium text-tm-gray-900">
                                            Location #{this.state.selectedLocationIndex + 1}
                                        </h2>

                                        <button
                                            onClick={this.handleNextLocationClick}
                                            className="btn btn-table-action"
                                        >
                                            <ChevronRightIcon className="w-5 h-5"/>
                                        </button>

                                        <button
                                            ref={this.locationBackButtonRef}
                                            onClick={this.toggleHandleLocationForm}
                                            className="btn absolute text-tm-gray-700 right-4"
                                        >

                                            <MinusCircleIcon className="w-6 h-6"/>
                                        </button>
                                    </div>

                                    <div className="text-center mb-2">
                                        {selectedLocationAddress}
                                    </div>

                                    <div
                                        className={
                                            classNames(
                                                "grid grid-cols-12 gap-4",
                                                Object.keys(this.state.selectedElement).length ? "group" : ""
                                            )
                                        }
                                    >
                                        <FieldsToHtml
                                            fieldsState={this.state.fields.locations[this.state.selectedLocationIndex]}
                                            translate={translate}
                                            onInputChange={(name, value) => this.handleInputChange('locations', this.state.selectedLocationIndex, name, value)}
                                            selects={this.loadSelects}
                                        />

                                        <div className={"col-span-full"}>
                                            <div className={"border-b-2"}></div>
                                            <h2 className={"text-lg leading-6 font-medium text-tm-gray-900 pt-2"}>Commodity</h2>
                                        </div>

                                        <FieldsToHtml
                                            fieldsState={this.state.fields.commodities[this.state.selectedLocationIndex]}
                                            translate={translate}
                                            onInputChange={(name, value) => this.handleInputChange('commodities', this.state.selectedLocationIndex, name, value)}
                                            selects={{
                                                UnloadFromLocation: this.getUnloadLocationSelectOptions()
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="mt-6 pl-6">
                                        <button
                                            onClick={this.handleAddLocation}
                                            className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                        >
                                            <PlusCircleIcon className="w-5 h-5"/>
                                            {translate("btn.add_location")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                classNames(
                                    "p-6 pt-0",
                                    Object.keys(this.state.selectedElement).length ? "group" : ""
                                )
                            }
                        >
                            <h2 className="text-lg leading-6 font-medium text-tm-gray-900 mb-3">
                                Pricing
                            </h2>

                            <div className="relative">
                                <div className="grid grid-cols-12 gap-4">
                                    <FieldsToHtml
                                        fieldsState={this.state.fields.pricing[0]}
                                        translate={translate}
                                        onInputChange={(name, value) => this.handleInputChange('pricing', 0, name, value)}
                                        selects={this.loadSelects}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer
                    className="min-h-[4rem] flex border-tm-gray-200 border-t bg-popup rounded-b-lg"
                >
                    <div className="w-full max-w-5xl py-2 flex items-center justify-center">
                        {!!documentPagesNumber && (
                            <div className="relative z-0 inline-flex -space-x-px">
                                <button
                                    className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-r-none z-10"
                                    disabled={documentPagesNumber === 1 || isLoading}
                                    onClick={() => (documentCurrentPage > 1) ? this.handleDocumentPageChange(documentCurrentPage - 1) : null}
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="w-5 h-5"/>
                                </button>

                                <input
                                    className="form-control rounded-none w-12 text-right px-1.5 relative focus:z-20"
                                    onChange={this.handlePaginationInputChange}
                                    onKeyDown={this.handlePaginationInputKeyDown}
                                    onFocus={(e) => e.target.select()}
                                    value={paginationInputValue}
                                />

                                <div className="relative">
                                    <input
                                        disabled={true}
                                        className="form-control rounded-none w-12 text-right px-1.5"
                                        value={isLoading ? "" : translate("text.pagination_of", [documentPagesNumber])}
                                    />

                                    {isLoading && (
                                        <LoaderSmall/>
                                    )}
                                </div>

                                <button
                                    disabled={documentCurrentPage >= documentPagesNumber || isLoading}
                                    onClick={() => documentCurrentPage < documentPagesNumber ? this.handleDocumentPageChange(documentCurrentPage + 1) : null}
                                    className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-l-none"
                                >
                                    <span className="sr-only">Next</span>

                                    <ChevronRightIcon className="w-5 h-5"/>
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="flex items-center ml-auto px-6 gap-x-4">
                        {/*<button*/}
                        {/*    className="btn btn-primary"*/}
                        {/*    disabled={this.isSaveDisabled()}*/}
                        {/*    onClick={() => {*/}
                        {/*        this.setState({createLoadAfterSubmission: true})*/}
                        {/*        this.handleSetClick()*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    Save & Create Load*/}
                        {/*</button>*/}

                        <button
                            className="btn btn-primary"
                            disabled={this.isSaveDisabled()}
                            onClick={this.handleSetClick}
                        >
                            Save
                        </button>

                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                onCloseScanDialog();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </footer>

                <ModalDefault
                    show={this.state.isReportDialogOpen}
                    widthClass={"max-w-3xl"}
                    title={translate("text.OCR_report")}
                    buttonLabel={"Apply data"}
                    onButtonClick={() =>
                        onSave(
                            this.state.processedOCRData,
                            this.mergeSameIndexPairs(pairs),
                            this.state.snap?.['snapshot-document']?.specific,
                            documentPagesNumber,
                            this.state.createdLocations,
                            this.state.createLoadAfterSubmission
                        )
                    }
                    closeButtonLabel={translate("btn.close")}
                    onClose={() => this.setState({
                        createLoadAfterSubmission: false,
                        isReportDialogOpen: false
                    })}
                >
                    <div className="min-h-12rem p-5">
                        <Message
                            report={this.state.OCRReports}
                            onCreateLocations={this.handleNewLocationData}
                        />
                    </div>
                </ModalDefault>

                <ModalConfirm
                    title={translate("text.Confirm")}
                    show={this.state.noCustomerFoundDialog}
                    text={translate("text.OCR_no_customer_found")}
                    textClassName={"text-sm text-tm-gray-900"}
                    buttonLabel={translate("btn.confirm")}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            noCustomerFoundDialog: false
                        })
                    }}
                />

                <ModalConfirm
                    title={translate("text.Confirm")}
                    show={this.state.isLLMNetworkError}
                    text={translate("text.OCR_processing_error")}
                    textClassName={"text-sm text-tm-gray-900"}
                    buttonLabel={translate("btn.confirm")}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            isLLMNetworkError: false,
                        })
                        onCloseScanDialog();
                    }}
                />

                <ModalDefault
                    show={this.state.isElementsConflictDialogOpen}
                    widthClass={"max-w-3xl"}
                    title={translate("text.ocr_elements_conflict")}
                    closeButtonLabel={translate("btn.cancel")}
                    onClose={() => this.setState({
                        isElementsConflictDialogOpen: false
                    })}
                >
                    <div className="min-h-12rem p-5">
                        <div className="mx-auto flex flex-col gap-4">
                            <Buttons
                                buttons={[
                                    {
                                        label: "Replace element",
                                        onClick: () => {
                                            this.setState({
                                                isElementsConflictDialogOpen: false
                                            }, () => {
                                                getValuesFromDbGuid(this.state.buffer.dbGuid)[0] === 'CustomerID' ?
                                                    this.pairElementWithAsyncField(
                                                        this.state.buffer.element,
                                                        this.state.buffer.dbGuid,
                                                        this.state.buffer.existingElementIndex
                                                    ) :
                                                    this.pairElementWithField(
                                                        this.state.buffer.element,
                                                        this.state.buffer.dbGuid,
                                                        this.state.buffer.existingElementIndex
                                                    )
                                            })
                                        }
                                    }, {
                                        label: "Concatenate element",
                                        onClick: () => {
                                            this.setState({
                                                isElementsConflictDialogOpen: false
                                            }, () => {
                                                this.pairElementWithField(
                                                    this.state.buffer.element,
                                                    this.state.buffer.dbGuid,
                                                    this.state.buffer.existingElementIndex,
                                                    " "
                                                )
                                            })
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </ModalDefault>

                <ModalSaveResource
                    show={this.state.isFeedbackModalOpen}
                    title={'Leave feedback'}
                    onClose={() => this.setState({isFeedbackModalOpen: false})}
                    fields={{
                        Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: 'col-span-full'})
                    }}
                    htmlAfter={<p className={"px-6 pb-6"}>Please describe the issue you encountered. Attached document
                        will be sent automatically.</p>}
                    buttonLabel={"Submit"}
                    onSubmit={(params) => this.handleSubmitFeedback(params)}
                    translate={this.props.translate}
                />

            </div>
        )
    }
}


