import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {cloneDeep} from "../../../../common/util/util-vanilla";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";
import {LoaderLarge} from "../../../../common/components/loader";
import FieldSwitchLabel from "../../../../common/components/fields/field-switch/field-switch-label";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../../common/util/util-fields";
import Card from "../../../../common/components/card";
import InfoParagraph from "../../../../common/components/info-paragraph";

export default function DispatchSettingsGeneralTab({resourcePath, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading;

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    const onSubmit = () => {
        const validatedFields = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: FieldsManager.getFieldKeyValues(validatedFields),
                resource: getResourceName(),
                piggyResource: getResourceName(),
                errorMessage: true,
                successMessage: translate("message.settings_updated")
            }))
            setIsStateDirty(false)
        } else {
            setFields(validatedFields)
        }
    }

    const getFields = (item) => {
        const fieldTemplates = {
            PreventTruckPermitDispatch: new Field('PreventTruckPermitDispatch', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventTruckPermitDispatch")}
                        note={translate("text.PreventTruckPermitDispatchNote")}
                    />
            }),
            RequireCustomerRefDispatch: new Field('RequireCustomerRefDispatch', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.RequireCustomerRefDispatch")}
                        note={translate("text.RequireCustomerRefDispatchNote")}
                    />
            }),
            PreventDuplicateRefNumber: new Field('PreventDuplicateRefNumber', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventDuplicateRefNumber")}
                        note={translate("text.PreventDuplicateRefNumberNote")}
                    />
            }),
            PreventAssigningBlacklistedCarrier: new Field('PreventAssigningBlacklistedCarrier', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventAssigningBlacklistedCarrier")}
                        note={translate("text.PreventAssigningBlacklistedCarrierNote")}
                    />
            }),
            PreventAssigningInactiveDriver: new Field('PreventAssigningInactiveDriver', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventAssigningInactiveDriver")}
                        note={translate("text.PreventAssigningInactiveDriverNote")}
                    />
            }),
            PreventAssigningInactiveTruckTrailer: new Field('PreventAssigningInactiveTruckTrailer', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventAssigningInactiveTruckTrailer")}
                        note={translate("text.PreventAssigningInactiveTruckTrailerNote")}
                    />
            }),
            EmptyMilesWarningPercentage: new Field('EmptyMilesWarningPercentage', null, [], false, 'integer', {
                note: translate("text.EmptyMilesWarningPercentage")
            }),

            ConfirmationDocDefaultEmail: new Field('ConfirmationDocDefaultEmail', null, [], false, 'text'),
            AdditionalAccesorialDefaultEmail: new Field('AdditionalAccesorialDefaultEmail', null, [], false, 'text'),

            TransportAgreementNotes: new Field('TransportAgreementNotes', null, [''], false, 'rich-text', {
                addContainerClass: 'col-span-full',
                labelClass: 'form-group has-float-label font-weight-bold',
            }, {className: "form-control max-w-full min-h-[6rem]"}),
            CarrierDriverAgreementNotes: new Field('CarrierDriverAgreementNotes', null, [''], false, 'rich-text', {
                addContainerClass: 'col-span-full',
                labelClass: 'form-group has-float-label font-weight-bold',
            }, {className: "form-control max-w-full min-h-[6rem]"}),
            BolAgreementNotes: new Field('BolAgreementNotes', null, [''], false, 'rich-text', {
                addContainerClass: 'col-span-full',
                labelClass: 'form-group has-float-label font-weight-bold',
            }, {className: "form-control max-w-full min-h-[6rem]"})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setIsStateDirty(true);
        if (value === undefined) return;

        setFields(FieldsManager.updateField(cloneDeep(fields), name, value));
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [isStateDirty, setIsStateDirty] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return resourcePath;
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (resource?.data && !resource?.isLoading) {
            setFields(getFields(cloneDeep(resource.data)));
        }
    }, [resource]);

    const dispatchRulesFields = fieldsToHtml(Object.values(includeFields(fields, [
        'PreventTruckPermitDispatch',
        'RequireCustomerRefDispatch',
        'PreventDuplicateRefNumber',
        'PreventAssigningBlacklistedCarrier',
        'PreventAssigningInactiveDriver',
        'PreventAssigningInactiveTruckTrailer',
        'EmptyMilesWarningPercentage']
    )), translate, handleInputChange);
    const dispatchWarningEmailFields = fieldsToHtml(Object.values(includeFields(fields, ['ConfirmationDocDefaultEmail', 'AdditionalAccesorialDefaultEmail'])), translate, handleInputChange)
    const dispatchDocsNotesFields = fieldsToHtml(Object.values(includeFields(fields, ['TransportAgreementNotes', 'CarrierDriverAgreementNotes', 'BolAgreementNotes'])), translate, handleInputChange)

    // Raw html content can be passed through these variables to <InfoParagraph /> to support navigating to another page eg:
    // const infoMessage = <p>Changing values will override defaults from <a href="/dispatch-settings" target="_blank" className="text-sky-600 hover:underline"> global settings</a>.</p>
    const dispatchRulesInfoMessage = 'These options will affect the load dispatching procedure. Here, you can specify whether certain fields are required for dispatch and manage the overall dispatch flow.'
    const dispatchWarningEmailMessage = 'The following fields should contain email addresses that will receive specific informational or warning emails, such as when customers sign documents or when additional accessorial charges are added to an already invoiced load, or load is edited after invoice is created. If emails are not required, simply leave the fields blank.'
    const dispatchDocsNotesMessage = 'The following notes will appear on specific load documents.'

    return (
        <React.Fragment>
            <div className="pb-10">

                {!isLoading && (
                    <div className={"max-w-3xl mx-auto space-y-8"}>
                        <Card bodyClass={"py-5 px-6 space-y-4"}>
                            <h1 className={"text-lg leading-6 font-medium text-tm-gray-900"}>{translate('text.dispatch_rules')}</h1>
                            <InfoParagraph
                                message={dispatchRulesInfoMessage}
                            />
                            {dispatchRulesFields}
                        </Card>
                        <Card bodyClass={"py-5 px-6 space-y-4"}>
                            <h1 className={"text-lg leading-6 font-medium text-tm-gray-900"}>{translate('text.dispatch_warning_emails')}</h1>
                            <InfoParagraph
                                message={dispatchWarningEmailMessage}
                            />
                            {dispatchWarningEmailFields}
                        </Card>
                        <Card bodyClass={"py-5 px-6 space-y-4"}>
                            <h1 className={"text-lg leading-6 font-medium text-tm-gray-900"}>{translate('text.dispatch_docs')}</h1>
                            <InfoParagraph
                                message={dispatchDocsNotesMessage}
                            />
                            {dispatchDocsNotesFields}
                        </Card>
                    </div>
                )}

                {!!isLoading && (
                    <LoaderLarge/>
                )}
            </div>

            <PageFooter
                translate={translate}
                canSubmit={isStateDirty}
                actionCancel={() => setFields(getFields(cloneDeep(resource.data)))}
                actionSubmit={onSubmit}
            />
        </React.Fragment>
    )
}
