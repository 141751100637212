import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import moment from "moment-timezone";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import NavResponsive from "../../nav-responsive";
import Card from "../../card";
import {COUNTRY_ID_USA, DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../util/util-constants";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {dirtyClone} from "../../../util/util-vanilla";
import {createResource, updateResource} from "../../../../data/actions/resource";
import { getUserTimeFormat, timePickerValueToServerTime, timeZoneToUTC } from '../../../util/util-dates'
import {classNames} from "../../../util/util-helpers";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import {LoaderSmall} from "../../loader";
import ModalFooter from "../modal-footer";
import DragAndDrop from "../truckDialogs/createTruckLocationDialog/DragAndDrop";
import Resources from "../../../../data/services/resources";
import {fillFieldsFromData, fieldsToHtml} from "../../../util/util-fields";

class CreateNewLocationDialog extends Component {

    constructor(props) {
        super(props);
        const tabs = [
            {name: "Select", resource: "Select", visible: true, current: true},
            {name: "Manually", resource: "Manually", visible: true, current: false},
            {name: "DragAndDrop", resource: "DragAndDrop", visible: true, current: false}
        ]

        this.state = {
            // Tabs
            selectedTab: this.checkIfUpdate() ? 'Manually' : 'Select',
            tabs: tabs,

            // Fields
            fields: this.getFields(),
            ManuallyFields: this.getManualFields(),
            SelectFields: this.getSelectFields(),
            DragAndDropFields: this.getDnDFields(),

            canSubmit: false
        };
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value, tab) => {
        if (tab) {
            let fields = this.state?.[tab + 'Fields'];

            if (name === "CountryID") {
                fields.StateID.value = "";
                fields.StateID.validate = Number(value) === COUNTRY_ID_USA ? ['empty'] : [''];
                fields.StateID.type = Number(value) === COUNTRY_ID_USA ? 'select' : 'hidden';
            }

            this.setState({
                [`${tab}Fields`]: FieldsManager.updateField(fields, name, value),
                canSubmit: true
            });
        } else {
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true});
        }
    };

    handleAddMarker = (cords) => {
        this.setState({
            DragAndDropFields: {
                Latitude: new Field('Latitude', cords.Latitude, []),
                Longitude: new Field('Longitude', cords.Longitude, []),
            },
            canSubmit: true
        });
    };

    handleSetLocations = (fields) => {
        let updatedFields = dirtyClone(this.state.ManuallyFields);

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.ManuallyFields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            ManuallyFields: updatedFields,
            canSubmit: true
        })
    }

    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    onClose = () => {
        this.props.onClose()
    }

    /** Data Events
     ================================================================= */
    onSubmit = (event) => {
        event && event.preventDefault();

        this.setState({
            [`${this.state.selectedTab}Fields`]: FieldsManager.validateFields(this.state[`${this.state.selectedTab}Fields`]),
            fields: FieldsManager.validateFields(this.state.fields)
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state[`${this.state.selectedTab}Fields`]) && FieldsManager.checkFieldsForErrors(this.state.fields)) {
                const selectedTabFields = FieldsManager.getFieldKeyValues(this.state[`${this.state.selectedTab}Fields`])
                const fields = FieldsManager.getFieldKeyValues(this.state.fields)
                const LocationDate = fields.LocationDate.split(" ")[0] + " " + timePickerValueToServerTime(fields.LocationTime)

                delete fields.LocationTime
                delete selectedTabFields.GooglePlaces

                if (!this.checkIfUpdate()) {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            selectedTabFields,
                            fields,
                            {
                                id: this.props.resourceID,
                                LocationDate: timeZoneToUTC(LocationDate)
                            }, this.props.additionalParams ?? {}),
                        query: this.props.getQuery(),
                        errorMessage: true, successMessage: `Location created`,
                        resource: this.props.resourcePath,
                        piggyResource: this.props.resourcePath
                    }));
                    this.onClose();
                } else {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            selectedTabFields,
                            fields,
                            {
                                id: this.getResourceLocationID(),
                                LocationDate: timeZoneToUTC(LocationDate)
                            }),
                        query: this.props.getQuery(),
                        resource: this.props.resourcePath,
                        piggyResource: this.props.resourcePath,
                        errorMessage: true, successMessage: `Location updated.`,
                    }));
                    this.onClose();
                }
            }
        });
    }

    /** Helpers
     ================================================================= */
    getResourceLocationID = () => {
        return this.props.resourceLocationID ? this.props.item[this.props.resourceLocationID] : this.props.resourcePath === Resources.TrucksLocations ? this.props.item.TruckLocationID : this.props.item.TrailerLocationID;
    }

    checkIfUpdate = () => Object.keys(this.props.item).length !== 0;

    /** Fields definitions
     ================================================================= */
    getFields = () => {
        let fieldTemplates = { // main fields above tabs
            LocationDate: new Field('LocationDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [], false, 'date', { addContainerClass: 'col-span-3'}),
            LocationTime: new Field('LocationTime', moment().format(getUserTimeFormat()), [], false, 'time-custom', { addContainerClass: 'col-span-3'}),
            Notes: new Field('Notes', '', [], false, 'textarea', { addContainerClass: 'col-span-6'})
        }

        if (this.checkIfUpdate()) {
            fieldTemplates = fillFieldsFromData(fieldTemplates, this.props.item)
            fieldTemplates.LocationTime.value = moment(this.props.item?.LocationDate).format(getUserTimeFormat())
        }

        return fieldTemplates
    }

    getManualFields = () => {
        let fieldTemplates = {
            CountryID: new Field('CountryID', COUNTRY_ID_USA, ['empty'], false, 'select', { addContainerClass: 'col-span-4'}),
            GooglePlaces: new Field('GooglePlaces', '', [''], false, "google-locations", {
                setLocations: this.handleSetLocations,
                addContainerClass: "col-span-8",
                label: "location_autocomplete"
            }, {}),

            AddressName: new Field('AddressName', '', ['empty'], false, 'text', { addContainerClass: 'col-span-full'}),
            CityName: new Field('CityName', '', ['empty'], false, 'text', { addContainerClass: 'col-span-4'}),
            StateID: new Field('StateID', '', ['empty'], false, 'select', { addContainerClass: 'col-span-4'}),
            PostalCode: new Field('PostalCode', '', ['empty'], false, 'text', { addContainerClass: 'col-span-4'}),
        }

        if (this.checkIfUpdate()) {
            fieldTemplates = fillFieldsFromData(fieldTemplates, this.props.item)
        }

        return fieldTemplates
    }

    getDnDFields = () => {
        return {
            Latitude: new Field('Latitude', NaN, ['empty']),
            Longitude: new Field('Longitude', NaN, ['empty']),
        }
    }

    getSelectFields = () => {
        return {
            LocationID: new Field('LocationID', '', ['empty'], false, 'select-search')
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {})
        const fieldsManuallyHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.ManuallyFields)), translate, (name, value) => this.handleInputChange(name, value, 'Manually'), {})
        const fieldsSelectHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.SelectFields)), translate, (name, value) => this.handleInputChange(name, value, 'Select'), {
            LocationID: {
                api: 'api/' + Resources.LocationsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.LocationName,
                    value: it.LocationID,
                    metadata: it
                })
            },
        })

        return (
            <React.Fragment>
                <div className="relative">
                    <header
                        className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900">
                        <h2 className="text-lg text-current truncate">
                            {this.checkIfUpdate() ? translate("text.update_location") : translate("text.add_ping")}
                        </h2>

                        <button
                            className="absolute right-5 top-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary rounded-full"
                            aria-label="Close"
                            onClick={() => this.onClose()}>
                            <XMarkIcon className="w-5 h-5"/>
                        </button>
                    </header>

                    {this.props.htmlAfterHeader}

                    <div
                        className={
                            classNames(
                                "p-5",
                                this.props.isLoading ? "invisible" : "",
                            )
                        }
                    >
                        <div className="grid grid-cols-12 gap-5">
                            {fieldsHtml}
                        </div>

                        {!this.checkIfUpdate() && (
                            <>
                                <div className="mb-5 hidden sm:block relative z-0">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                 <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>
                            </>
                        )}

                        {this.state.selectedTab === 'Manually' && (
                            <Card addClass={'relative mt-5'}>
                                <div className={'grid grid-cols-12 gap-5'}>
                                    {fieldsManuallyHtml}
                                </div>
                            </Card>
                        )}

                        {this.state.selectedTab === "DragAndDrop" && (
                            <DragAndDrop
                                {...this.props}
                                Latitude={this.state.DragAndDropFields.Latitude.value}
                                Longitude={this.state.DragAndDropFields.Longitude.value}
                                addMarker={e => this.handleAddMarker(e)}
                            />
                        )}

                        {this.state.selectedTab === "Select" && (
                            <Card>
                                <div className={'grid grid-cols-1'}>
                                    {fieldsSelectHtml}
                                </div>
                            </Card>
                        )}
                    </div>

                    {this.props.isLoading && (
                        <div className="inset-center">
                            <LoaderSmall/>
                        </div>
                    )}
                </div>
                <ModalFooter
                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.onClose}
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate('btn.save')}
                    onButtonClick={this.state.canSubmit && this.onSubmit}
                />
            </React.Fragment>
        );
    }
}

export default CreateNewLocationDialog
