import React, {useRef, useState} from 'react'
import FileViewer from "../../../file-viewer/components";
import {ArrowPathIcon, TrashIcon} from "@heroicons/react/24/outline";
import DocumentPreviewModal from "../documents-list-preview-slide";
import DropZoneAlt from "../../../dropzone/drop-zone-alt";
import NoRecords from "../../../no-records-found/no-records";
import ResourceTable from "../../../resource-table";
import DocumentExpiryDate from "../../../badge/expiery-date-badge";
import {classNames, getProp} from "../../../../util/util-helpers";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import TableCard from "../../../resource-table/table-components/table-card";
import ModalDefault from "../../../modal/modal-default";
import {DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS, MAXIMUM_DOCUMENT_UPLOAD_SIZE} from "../../../../util/util-consts";
import {currentDateTimeToUtc} from "../../../../util/util-dates";
import {fieldsToHtml, fillFieldsFromData} from "../../../../util/util-fields";
import ModalConfirm from "../../../modal/modal-confirm";
import ModalSaveResource from "../../../modal/modal-save-resource";

const DocumentVersionsModal = ({
                                   selectedItem,
                                   isLoading,
                                   translate,
                                   handleDownloadDocument,
                                   onDocumentDelete,
                                   id,
                                   resourceName,
                                   onUploadVersion,
                                   accept,
                                   onEditDocument
                               }) => {

    /** Constants
     ================================================================= */
    const dropZoneClickRef = useRef();

    const versions = [selectedItem].concat(getProp(selectedItem, 'Versions', []).sort((a, b) => b.Version - a.Version))

    const [isDocumentDragged, setIsDocumentDragged] = useState(false);

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => {
        return {
            Version: new Field("Version", '', [], false, 'custom', {
                render: (item) => item.Version || "Current"
            }),
            CreateUpdateDate: new Field("CreateUpdateDate", '', [], false, 'datetimezone'),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'custom', {
                render: (item) => DocumentExpiryDate(item?.ExpiryDate)
            })
        }
    }
    const getEditFields = (item = null) => {

        let fieldTemplates = {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }


        return fillFieldsFromData(fieldTemplates, item);
    }


    const getUploadFields = () => {
        return {
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: "col-span-3"})
        }
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getUploadFields())
    const [selectedVersion, setSelectedVersion] = useState(null)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [file, setFile] = useState(null)
    const [previewDocumentModalOpen, setPreviewDocumentModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)

    /** UI events
     ================================================================= */
    const handleSubmit = () => {
        let validatedFields = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if (selectedVersion) {
                onUploadVersion(file, {Version: selectedVersion.Version, ...FieldsManager.getFieldKeyValues(validatedFields)})
            } else {
                onUploadVersion(file, {Version: -1, ...FieldsManager.getFieldKeyValues(validatedFields)})
            }
            handleToggleCreateModal()
        } else {
            setFields(validatedFields)
        }
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
    }

    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
        setFields(getUploadFields())
        setSelectedVersion(null)
        setFile(null)
    }

    const handleToggleViewModal = (item = null) => {
        let documentIndex = null
        if (item) {
            documentIndex = versions.findIndex(elem => elem.DocumentID === item.DocumentID)
        }
        setSelectedDocumentIndex(documentIndex)
        setPreviewDocumentModalOpen(!previewDocumentModalOpen)
    }

    const handleOnPreviousDocumentPreviewClick = () => {
        selectedDocumentIndex > 0 && setSelectedDocumentIndex(selectedDocumentIndex - 1)
    }

    const handleOnNextDocumentPreviewClick = () => {
        selectedDocumentIndex < (versions.length - 1) && setSelectedDocumentIndex(selectedDocumentIndex + 1)
    }

    const handleToggleConfirmModal = (item = null) => {
        setConfirmModalOpen(!confirmModalOpen)
        setSelectedVersion(item)
    }

    const handleToggleEditModal = (item = null) => {
        setEditModalOpen(!editModalOpen)
        setSelectedVersion(item)
    }

    const handleToggleReplaceModal = (item = null) => {
        setCreateModalOpen(!createModalOpen)
        setFields(getUploadFields())
        setFile(null)
        setSelectedVersion(item)
    }
    /** Render
     ================================================================= */

    return (
        <div className={'p-5'}>
            <div className="text-right mb-5">
                <button className="btn btn-primary" onClick={handleToggleCreateModal}>
                    {translate("btn.UploadNewVersion")}
                </button>
            </div>

            <TableCard>
                <ResourceTable
                    addTableClass="border-x border-tm-gray-200"
                    data={versions}
                    fields={getFields()}
                    translate={translate}
                    isLoading={isLoading}

                    options={{
                        style: {
                            stripedRows: true,
                            horizontalLines: true,
                            verticalLines: true,
                            floatingActions: true
                        }
                    }}

                    onRowClick={handleToggleViewModal}
                    onView={handleToggleViewModal}
                    onEdit={handleToggleEditModal}

                    actions={[
                        {
                            action: handleToggleReplaceModal,
                            icon: ArrowPathIcon,
                            visible: () => true,
                            label: false,
                            title: translate('text.DeleteAndReplace'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        }, {
                            action: handleToggleConfirmModal,
                            icon: TrashIcon,
                            visible: (it) => !!it.Version,
                            label: false,
                            title: translate('text.Delete'),
                            disabled: false,
                            class: false,
                            iconClass: false,
                            order: 100
                        },
                    ]}
                />
            </TableCard>

            {versions.length === 0 && (
                <NoRecords
                    title={'No matching records found'}
                />
            )}

            <ModalDefault
                show={createModalOpen}
                widthClass={'max-w-5xl'}
                title={translate("modal_heading.upload_version_confirm")}

                closeButtonLabel={translate('btn.cancel')}
                onClose={handleToggleCreateModal}

                buttonLabel={translate('Upload')}
                buttonDisabled={!file}
                onButtonClick={handleSubmit}
            >
                <div className="">
                    <div className='relative h-50 top-0 bg-tm-gray-50 p-4 border-b border-tm-gray-200'>
                        {!file && (
                            <DropZoneAlt
                                className={
                                    classNames(
                                        "h-64",
                                        isDocumentDragged ? "bg-green-600 inset-0 z-50" : "left-0 right-0 bottom-0"
                                    )
                                }
                                maxFilesAccepted={10}
                                maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                accept={accept ?? DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                translate={translate}
                                onAcceptFiles={(acceptedFiles) => {
                                    setFile(Object.assign(acceptedFiles[0], {
                                        preview: URL.createObjectURL(acceptedFiles[0]),
                                        key: currentDateTimeToUtc()
                                    }))
                                    setIsDocumentDragged(false);
                                }}
                                content={(isDragAccept, isDragReject) => {
                                    return (
                                        <>
                                            <div
                                                onDragLeave={() => setIsDocumentDragged(false)}
                                                className={classNames(
                                                    isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                    isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                    !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                    "pb-4 absolute inset-0 rounded-card"
                                                )}>

                                            </div>

                                            <div
                                                // ref={uploadDocumentButtonRef}
                                                className="h-9 flex text-sm items-center gap-1 absolute bottom-0 left-0 right-0 px-6 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                                Attach files by dragging and dropping them or
                                                <span className="text-primary-tint font-semibold"
                                                      ref={dropZoneClickRef}>{" "} click here</span> to
                                                select them.
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        )}
                    </div>

                    {!!file && (
                        <div className="p-4" key={file.key}>
                            <FileViewer
                                filePath={URL.createObjectURL(file)}
                                fileType={file.name.split('.').pop()}
                                onError={(e) => {
                                    console.log(e)
                                }}
                            />
                        </div>
                    )}

                    {!!file && (
                        <div className="bg-tm-gray-50 border-t border-tm-gray-200 px-24 grid grid-cols-12 gap-4 py-5">
                            {fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange)}
                            <div
                                className="col-start-11 col-span-2 flex justify-end items-end"
                            >
                                <button
                                    onClick={() => setFile(null)}
                                    className="btn btn-outline-secondary"
                                >
                                    {translate("btn.remove_file")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </ModalDefault>

            <DocumentPreviewModal
                show={previewDocumentModalOpen}
                translate={translate}
                item={versions[selectedDocumentIndex]}

                onButtonClick={() => {
                    handleDownloadDocument(Object.assign({}, versions[selectedDocumentIndex], {
                        PhysicalLocation: versions[selectedDocumentIndex].OriginalFilename
                    }))
                }}
                buttonLabel={translate("btn.download")}
                closeButtonLabel={translate("btn.close")}
                onClose={handleToggleViewModal}

                showPreviousButton={selectedDocumentIndex > 0}
                showNextButton={selectedDocumentIndex < (versions.length - 1)}
                onNextDocumentPreviewClick={handleOnNextDocumentPreviewClick}
                onPreviousDocumentPreviewClick={handleOnPreviousDocumentPreviewClick}

                primaryKey={"DocumentID"}
                id={id}
                resourceName={resourceName}
            />

            <ModalConfirm
                title={"Confirm"}
                type="danger"
                show={!!confirmModalOpen}
                content={translate("text.confirm_delete_document")}
                onClose={handleToggleConfirmModal}
                buttonLabel={translate("btn.delete")}
                closeButtonLabel={translate('btn.cancel')}
                translate={translate}
                onConfirm={() => {
                    onDocumentDelete(selectedVersion)
                    handleToggleConfirmModal()
                }}
            />

            <ModalSaveResource
                title={"Edit document"}
                gridColsClass={"grid-cols-12"}
                show={editModalOpen}
                translate={translate}
                fields={getEditFields(selectedVersion)}
                onClose={handleToggleEditModal}
                onSubmit={(params) => {
                    params.id = selectedVersion.DocumentID;
                    params.Version = selectedVersion.Version;
                    onEditDocument(params);
                    handleToggleEditModal();
                }}
                metadata={{}}
            />
        </div>
    )
}

export default DocumentVersionsModal
