import {Component} from 'react';
import {getProp} from "../../util/util-helpers";
import LocalStorage from "../../../util/localStorage";

// https://github.com/romaindso/react-feature-flags
export class Flags extends Component {

    /*
        authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
        exactFlags: PropTypes.bool,
        renderOn: PropTypes.func,
        renderOff: PropTypes.func
    */

    static defaultProps = {
        exactFlags: false,
        renderOn: () => null,
        renderOff: () => null
    }


    matchingFlags(flags) {
        return flags.filter((flag) => {
            return flag.isActive && this.props.authorizedFlags.includes(flag.name)
        });
    }

    resolveRender(matchingFlags) {
        return this.props.children && this.props.children ? this.props.children : this.props.renderOn(matchingFlags);
    }

    render() {
        const {authorizedFlags, exactFlags} = this.props;

        const flags = getFeatureFlags();

        const matchingFlags = this.matchingFlags(flags);
        if (exactFlags) {
            return matchingFlags.length === authorizedFlags.length
                ? this.resolveRender(matchingFlags)
                : this.props.renderOff(matchingFlags);
        } else {
            return !!matchingFlags.length || authorizedFlags.length === 0
                ? this.resolveRender(matchingFlags)
                : this.props.renderOff(matchingFlags)
        }
    }
}

export function getFeatureFlags() {
    const FeatureFlags = getProp(LocalStorage.get('lookup'), 'FeatureFlags', {})

    return Object.keys(FeatureFlags).map((it) => ({
        name: it,
        isActive: !!FeatureFlags[it]
    }))
}

export function checkFeatureFlag(flag = '') {
    return !!getFeatureFlags().find((it) => {
        return it.name === flag && !!it.isActive
    })
}