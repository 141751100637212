import React, {useRef, useState} from "react";
import {classNames} from '../../../common/util/util-helpers';
import LocalStorage from "../../../common/util/localStorage";
import {useHistory} from "react-router";
import MainMenuData from "../../../../src/assets/menus/menu-main.json"
import {
    ArrowLeftOnRectangleIcon,
    ArrowPathIcon,
    ChartPieIcon,
    CircleStackIcon,
    UserCircleIcon
} from "@heroicons/react/20/solid";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {useDispatch, useSelector} from "react-redux";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Layout from "../../../common/components/layout";
import {clearCookies} from "../../../common/util/util-auth";
import Button from "../../../common/components/button";
import ModalDefault from "../../../common/components/modal/modal-default";
import UserSettingsProfiles from "./ui-tools-components/user-settings-profiles";

export default function UiToolsView(props) {
    const history = useHistory();
    const excludedPages = ["/dispatch-expiry-items", "/dispatch-templates", "/dispatch-settings", "/tasks-settings", "/fleet-settings", "/safety-settings"];

    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)

    const confirmAction = useRef(() => null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confirmModalTitle, setConfirmModalTitle] = useState("");
    const [confirmModalContent, setConfirmModalContent] = useState("");
    const [sections, setSections] = useState(getSections() ?? {});
    const [isProfilesDialogOpen, setIsProfilesDialogOpen] = useState(false);

    function handleClearLocalstorageClick() {
        setConfirmModalTitle("Clear localstorage data");
        setConfirmModalContent("Clearing localstorage data will reset all of your table filter and column settings");
        confirmAction.current = clearLocalstorage;
        setIsConfirmModalOpen(true);
    }

    function handleClearCookiesClick() {
        clearCookies();
        LocalStorage.set("redirect_path", '/tools');
        history.push('/login')
    }

    function clearLocalstorage() {
        const initialState = LocalStorage.get('initial_storage_state');

        localStorage.clear();

        if (initialState) {
            LocalStorage.set('initial_storage_state', initialState);
        }

        LocalStorage.set("redirect_path", '/tools');
        history.push('/logging-in')
    }

    function handleClearPageTableDataClick(sectionKey, pageName, storageKey) {
        localStorage.removeItem(storageKey + "_state");
        localStorage.removeItem(storageKey + "_table_filters");
        localStorage.removeItem(storageKey + "_filters");
        localStorage.removeItem(storageKey.replace("-", "_") + "_filters");
        localStorage.removeItem(storageKey.replace("-", "_") + "_table_filters");

        let sectionsClone = Object.assign({}, sections);
        sectionsClone[sectionKey].list.map(page => {
            if (page.name === pageName) {
                page.hasTableData = false;
            }
            return page;
        });
        setSections(sectionsClone);
    }

    function handleClearTabTableDataClick(sectionKey, pageName, tabKey) {
        localStorage.removeItem(tabKey + "_state");
        localStorage.removeItem(tabKey + "_table_filters");
        localStorage.removeItem(tabKey.replace("-", "_") + "_table_filters");

        let sectionsClone = Object.assign({}, sections);
        sectionsClone[sectionKey].list.map(page => {
            if (page.tabs) {
                page = page.tabs.map(tab => {
                    if (tab.key === tabKey) {
                        tab.hasTableData = false;
                    }
                    return tab;
                })
            }
            return page;
        });

        setSections(sectionsClone);
    }

    function handleReLoginClick() {
        LocalStorage.set("redirect_path", '/tools');
        LocalStorage.remove('user');
        history.push('/logging-in');
    }

    function handleExpandSectionClick(key) {
        const sectionsClone = Object.assign({}, sections);
        sectionsClone[key].isExpanded = !sectionsClone[key].isExpanded;
        setSections(sectionsClone);
    }

    function getSections() {
        return MainMenuData.reduce((memo, menu) => {
            if (menu.children) {
                menu.children.filter(it => it.isVisible && !excludedPages.includes(it.link)).forEach(page => {
                    if (!memo[menu.name]) {
                        memo[menu.name] = {};
                        memo[menu.name].list = [];
                    }

                    memo[menu.name].hasChildren = true;

                    page.hasTableData = !!localStorage.getItem(page.link.slice(1) + "_state") || !!localStorage.getItem(page.link.slice(1) + "_filters");

                    if (page.hasTableData) {
                        memo[menu.name].hasTableData = true;
                        memo[menu.name].isExpanded = true;
                    }

                    if (page.tabs) {
                        page.tabs.map(tab => {
                            tab.hasTableData = !!localStorage.getItem(tab.key + "_state");

                            if (tab.hasTableData) {
                                memo[menu.name].hasTableData = true;
                                memo[menu.name].isExpanded = true;
                            }

                            return tab;
                        })
                    }

                    memo[menu.name].list.push(page);
                })
            } else if (menu.name === 'Contacts') {
                menu.hasTableData = localStorage.getItem("contacts_state");
                if (!memo[menu.name]) {
                    memo[menu.name] = {};
                    memo[menu.name].list = [];
                }

                if (menu.hasTableData) {
                    memo[menu.name].hasTableData = true;
                    memo[menu.name].isExpanded = true;
                }

                memo[menu.name].list.push(menu);
            }
            return memo;
        }, {});
    }

    return <Layout {...props} ui={ui} dispatch={dispatch} history={history} user={user}>
        <Page>
            <PageHeader title={'UI tools'}/>
            <div className="space-y-2 pb-8">
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2 flex-shrink-0">
                        <button
                            onClick={() => window.location.reload(true)}
                            className="btn btn-outline"
                        >
                            <ArrowPathIcon className="w-4 h-4 mr-1 -ml-1"/> Hard refresh
                        </button>

                        <button
                            onClick={handleClearCookiesClick}
                            className="btn btn-outline"
                        >
                            <ChartPieIcon className="w-4 h-4 mr-1 -ml-1"/>Clear cookies
                        </button>

                        <button
                            onClick={handleClearLocalstorageClick}
                            className="btn btn-outline"
                        >
                            <CircleStackIcon className="w-4 h-4 mr-1 -ml-1"/> Clear localstorage
                        </button>

                        <button
                            onClick={handleReLoginClick}
                            className="btn btn-outline"
                        >
                            <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-1 -ml-1"/> Re-login
                        </button>
                    </div>

                    <div className="flex w-full items-center justify-end">
                        <Button
                            appearance="primary"
                            onClick={() => setIsProfilesDialogOpen(true)}
                        >
                            <UserCircleIcon className="w-4 h-4 mr-1 -ml-1"/>

                            Profiles
                        </Button>
                    </div>
                </div>


                <div className="overflow-hidden rounded-card border border-tm-gray-200 shadow">
                    {Object.keys(sections).map(sectionKey =>
                        <div key={sectionKey}>
                            <div className="border-t border-tm-gray-200">
                                <div
                                    className="bg-tm-gray-50 h-10 flex items-center pl-4 pr-3 text-left text-sm font-bold text-tm-gray-900 sm:pl-3"
                                >
                                    <button
                                        onClick={() => handleExpandSectionClick(sectionKey)}
                                        className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                                    >
                                        <ChevronRightIcon
                                            className={
                                                classNames("w-5 h-5", sections[sectionKey]?.isExpanded ? "rotate-90" : undefined)
                                            }
                                        />
                                    </button>

                                    <span className="uppercase">{sectionKey}</span>
                                    {!sections[sectionKey]?.isExpanded && !sections[sectionKey]?.hasTableData ?
                                        <span className="text-tm-gray-400 ml-2">(No data)</span> : undefined}
                                </div>
                            </div>

                            {sections[sectionKey]?.isExpanded && sections[sectionKey].list.map(page => {
                                return (
                                    <>
                                        <div
                                            key={page.name}
                                            className="pl-16 pr-8 flex items-center justify-between border-t h-10 border-tm-gray-300 font-bold bg-inverse hover:bg-tm-gray-100"
                                        >
                                            {page.name}

                                            {page.hasTableData && (
                                                <button
                                                    className="btn btn-outline py-0.5 h-8 w-44"
                                                    onClick={() => handleClearPageTableDataClick(sectionKey, page.name, page.link.slice(1))}
                                                >
                                                    Clear table settings
                                                </button>
                                            )}

                                            {!page.hasTableData && (
                                                <div className="w-44 text-center">No table data</div>
                                            )}
                                        </div>

                                        {!!page.tabs && page.tabs.map(tab => {
                                            return <div
                                                key={sectionKey + tab.key}
                                                className="pl-16 pr-8 h-10 flex items-center justify-between border-t border-tm-gray-300 bg-inverse hover:bg-tm-gray-100"
                                            >
                                                <span><span className="mr-6 text-tm-gray-400">•</span>{tab.name}</span>

                                                {tab.hasTableData && (
                                                    <button
                                                        className="btn btn-outline py-0.5 h-8 w-44"
                                                        onClick={() => handleClearTabTableDataClick(sectionKey, page.name, tab.key)}
                                                    >
                                                        Clear table settings
                                                    </button>
                                                )}

                                                {!tab.hasTableData && (
                                                    <div className="w-44 text-center">No table data</div>
                                                )}
                                            </div>
                                        })}
                                    </>
                                )
                            })}
                        </div>
                    )}
                </div>

                <ModalDefault
                    show={isProfilesDialogOpen}
                    widthClass="max-w-3xl"
                    title={props.translate('modal_heading.profile_settings')}
                    onClose={() => setIsProfilesDialogOpen(false)}
                    closeButtonLabel={props.translate('btn.close')}
                    translate={props.translate}
                >
                    <UserSettingsProfiles
                        onClose={() => setIsProfilesDialogOpen(false)}
                        onReLogin={handleReLoginClick}
                        translate={props.translate}
                    />
                </ModalDefault>

                <ModalConfirm
                    title={confirmModalTitle}
                    show={isConfirmModalOpen}
                    onClose={() => setIsConfirmModalOpen(false)}
                    buttonLabel={'Confirm'}
                    closeButtonLabel={'Cancel'}
                    translate={props.translate}
                    onConfirm={() => {
                        setIsConfirmModalOpen(false);
                        if (confirmAction.current) {
                            confirmAction.current();
                        }
                    }}
                >
                    {confirmModalContent}
                </ModalConfirm>
            </div>
        </Page>

    </Layout>
}
