import React, {useRef, useState} from "react";
import {fieldsToHtml, includeFields} from "../../../util/util-fields";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import Resources from "../../../../data/services/resources";
import LocalStorage from "../../../../util/localStorage";
import {
    ApprovalStatusID,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    JournalEntryTypeID,
    QUERY_LIMIT
} from "../../../util/util-consts";
import {classNames, getLookup, getProp} from "../../../util/util-helpers";
import {ArrowPathIcon, ArrowsPointingOutIcon, WindowIcon} from "@heroicons/react/24/outline";
import {ArrowsPointingInIcon} from "@heroicons/react/20/solid";
import ModalDefault from "../../modal/modal-default";
import PopOver from "../../popover";
import FieldCheckbox from "../../fields/field-checkbox";
import ReactPortal from "../../layout/layout-components/react-portal";
import FieldSelectSearch from "../../fields/field-select-search";

export default function TableFilters(props) {
    const {
        translate,
        selects,
        filterFields,
        handleInputChange,
        customHtml,
        customHeaderHtml,
        hideLimit,
        className,
        containerClass,
        onRefreshTable,
        onFullScreenToggle,
        toggleAutoRefresh,
        isAutoRefreshEnabled,
        isLoading,
        isTableFullScreen,
        largeGrid,
        addClass,
        forceDialog,
        popoverClass,
        forceAll
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const defaultLabelType = "float";
    const canAutoRefresh = typeof toggleAutoRefresh === 'function';

    const dropdownRef = useRef();

    const defaultSelects = getDefaultSelects(filterFields, translate);

    const filterFieldsValues = Object.values(filterFields).map(it => {
        if (!it.metadata) Object.assign(it, {metadata: {}})
        if (!it.metadata.labelType) {
            it.metadata.labelType = defaultLabelType;
        }
        return it;
    });

    const filterSelects = selects ? Object.assign({}, defaultSelects, selects) : defaultSelects;

    const headerFilterLimit = customHeaderHtml ? 1 : 3;

    const largeScreenFields = filterFieldsValues.filter((it, i) => i < headerFilterLimit && it.name !== 'limit' && it.name !== 'sort' && it.name !== 'sortBy' && it.name !== 'offset').map(it => it.name);

    const largeScreenFilters = fieldsToHtml(Object.values(Object.assign({}, includeFields(filterFields, largeScreenFields))), translate, handleInputChange, filterSelects);

    const miscFilters = fieldsToHtml(filterFieldsValues.filter((it) => it.type !== 'checkbox' && it.name !== 'query' && it.name !== 'limit' && it.name !== 'sort' && it.name !== 'sortBy' && it.name !== 'offset'), translate, handleInputChange, filterSelects);

    const largerGrid = miscFilters.length >= 4 || largeGrid;

    const checkboxFilters = fieldsToHtml(filterFieldsValues.filter((it) => it.type === 'checkbox').map(it => {
        it = Object.assign({}, it);
        it.props = Object.assign(it.props ?? {}, {id: "dropdown_" + it.name})
        return it;
    }), translate, handleInputChange);


    const numberOfFilters = miscFilters.length + checkboxFilters.length;

    return (
        <div
            className={
                classNames(
                    className ?? "md:px-5 py-5 flex md:ring-1 ring-black ring-opacity-5 ring-b-none rounded-t-card md:border-b border-tm-gray-300",
                    addClass
                )}
        >
            <div className={containerClass ?? "xl:grid grid-cols-3 gap-4 hidden"}>
                {largeScreenFilters}
                {customHeaderHtml}
            </div>

            <div className="xl:hidden mr-3 sm:mr-0">
                {largeScreenFilters[0]}
            </div>

            <div className="ml-auto flex space-x-3">
                {props.children}
                {typeof onFullScreenToggle === 'function' && (
                    <button className={
                        classNames(
                            "btn btn-header",
                            isTableFullScreen ? "bg-primary text-primary-contrast" : undefined
                        )
                    }
                            onClick={onFullScreenToggle}
                    >
                        {!isTableFullScreen && (
                            <ArrowsPointingOutIcon className="w-5 h-5"/>
                        )}

                        {!!isTableFullScreen && (
                            <ArrowsPointingInIcon className="w-5 h-5"/>
                        )}

                    </button>
                )}

                {typeof onRefreshTable === 'function' && (
                    <div className="flex relative">
                        <button
                            className={
                                classNames(
                                    "btn btn-header z-10",
                                    canAutoRefresh ? 'rounded-r-none relative -right-px' : undefined
                                )
                            }
                            onClick={onRefreshTable}
                        >
                            <ArrowPathIcon className={
                                classNames(
                                    "w-5 h-5",
                                    isLoading ? "animate-spin" : undefined,
                                    isAutoRefreshEnabled ? "text-primary" : "text-tm-gray-700"
                                )
                            }/>
                        </button>

                        {canAutoRefresh && (
                            <PopOver
                                btnClass="relative inline-flex items-center rounded-r-btn bg-inverse px-2 py-2 text-gray-400 ring-1 ring-inset ring-tm-gray-300 hover:bg-tm-gray-50 hover:z-10 hover:ring-tm-gray-600"
                                BtnIcon={ChevronDownIcon}
                                btnIconClass="w-5 h-5 text-tm-gray-400"
                                widthClass="max-w-xs"
                                positionClass="absolute translate-x-0 right-0"
                            >
                                <div className="bg-popup border border-tm-gray-300 rounded-card p-4">
                                    <label
                                        className="h-10 flex items-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                                        <FieldCheckbox
                                            className="checkbox"
                                            onChange={toggleAutoRefresh}
                                            value={isAutoRefreshEnabled}
                                        />

                                        <span className="ml-2 text-sm select-none">
                                            {translate('field.isAutoRefreshEnabled')}
                                        </span>
                                    </label>
                                </div>
                            </PopOver>
                        )}
                    </div>
                )}

                {(numberOfFilters > 2 || forceAll) && (
                    <div
                        className={
                            classNames("w-32 hidden",
                                !forceDialog && !forceAll ? "2xl:block" : undefined,
                                !forceDialog && forceAll ? "xl:block" : undefined
                            )
                        }
                        ref={dropdownRef}
                    >
                        <ReactPortal
                            isActive={!!dropdownRef.current}
                            parent={dropdownRef.current}
                        >
                            <PopOver
                                className={popoverClass ?? "fixed z-30"}
                                btnClass={classNames("w-32 form-control p-0 text-tm-gray-700 flex items-center flex", (numberOfFilters < 3 && !forceAll) ? "xl:hidden" : "")}
                                widthClass={largerGrid ? "max-w-2xl" : "max-w-lg"}
                                positionClass="absolute translate-x-0 right-0"
                                btnCustomHtml={(
                                    <React.Fragment>
                                        <div className="py-2 px-3">
                                            <span className="hidden xl:inline">All</span> Filters
                                        </div>

                                        <span className="h-5 w-px bg-tm-gray-200"/>

                                        <div className="py-1 px-2">
                                            <ChevronDownIcon className="w-5 h-5"/>
                                        </div>
                                    </React.Fragment>
                                )}
                            >
                                <div
                                    className="  w-full max-w-2xl right-10 z-50 bg-popup p-6 border border-tm-gray-300 rounded-lg">
                                    <div
                                        className={classNames("grid gap-4", largerGrid && !!checkboxFilters.length ? "grid-cols-3" : "grid-cols-2")}>
                                        {customHtml}

                                        <div
                                            className={
                                                classNames(
                                                    largerGrid && !!checkboxFilters.length && !!miscFilters.length ? "col-span-1" : "",
                                                    !!checkboxFilters.length && !miscFilters.length ? "col-span-2" : ""
                                                )
                                            }
                                        >
                                            {checkboxFilters}
                                        </div>

                                        <div
                                            className={classNames(largerGrid ? "grid gap-4 col-span-2" : "space-y-4", !checkboxFilters.length ? "col-span-2" : "")}>
                                            {miscFilters}
                                        </div>
                                    </div>
                                </div>
                            </PopOver>
                        </ReactPortal>
                    </div>
                )}

                <button
                    className={classNames(
                        "form-control p-0 w-auto text-tm-gray-700 flex items-center h-9",
                        !forceDialog ? "2xl:hidden" : undefined,
                        (numberOfFilters < 3 && numberOfFilters > 0 && !forceDialog) ? "xl:hidden" : undefined,
                        numberOfFilters === 0 ? "hidden" : undefined
                    )}
                    onClick={() => setIsModalOpen(true)}
                >
                    <div className="py-2 px-3">
                        <span className="hidden xl:inline">All</span> Filters
                    </div>

                    <span className="h-5 w-px bg-tm-gray-200"/>

                    <div className="py-1 px-2">
                        <WindowIcon className="w-5 h-5"/>
                    </div>
                </button>

                {!hideLimit && (
                    <div className="relative w-24">
                        <span
                            className="label text-tm-gray-900 font-semibold absolute -top-2.5 left-2.5 bg-inverse px-1 text-xs z-10">
                            {translate("field.limit")}
                        </span>

                        <FieldSelectSearch
                            addClass="form-control"
                            values={filterSelects["limit"]}
                            onChange={handleInputChange}

                            {...filterFields.limit}
                            placeholder={""}
                            omitSort
                        />
                    </div>
                )}
            </div>

            <ModalDefault
                show={isModalOpen}
                widthClass={'max-w-2xl'}
                title={translate("text.all_filters")}
                closeButtonLabel={translate('btn.close')}
                onClose={() => setIsModalOpen(false)}
            >
                <div className="bg-popup p-6">
                    <div
                        className={classNames("grid gap-4", largerGrid && !!checkboxFilters.length ? "grid-cols-3" : "grid-cols-2")}>
                        {customHtml}

                        <div className={largerGrid && !!checkboxFilters.length ? "col-span-1" : ""}>
                            {checkboxFilters}
                        </div>

                        <div
                            className={classNames(largerGrid ? "grid gap-4 col-span-2" : "space-y-4", !checkboxFilters.length ? "col-span-2" : "")}>
                            {miscFilters}
                        </div>
                    </div>
                </div>
            </ModalDefault>
        </div>
    )
}

const getDefaultSelects = (filterFields, translate) => ({
    DocumentExtension: {
        "csv": "CSV",
        "xlsx": "EXCEL",
        "jpg": "JPG",
        "jpeg": "JPEG",
        "gif": "GIF",
        "bmp": "BMP",
        "png": "PNG",
        "pdf": "PDF",
        "docx": "DOCX",
        "mp3": "MP3",
        "webm": "WEBM",
        "mp4": "MP4",
        "odt": "ODT",
        "ods": "ODS",
        "ott": "OTT",
        "ots": "OTS",
        "rtf": "RTF",
        "txt": "TEXT"
    },
    IsBroker: {0: "Carrier", 1: "Brokerage"},
    limit: QUERY_LIMIT,
    JournalEntryTypeID: JournalEntryTypeID,
    ApprovalStatusID: ApprovalStatusID,
    RequestStatusID: ApprovalStatusID,
    ProductServiceTypeID: {1: "Inventory", 2: "Non-Inventory", 3: "Service"},
    ContactType: {1: "All", 2: "System Users", 3: "Contacts Only"},
    days: {7: 7, 15: 15, 30: 30, 60: 60, 90: 90},
    TruckAndDriverVisibility: {1: "Show all", 3: "Show without drivers", 2: "Show without drivers and trucks"},
    UpdatedByContactID: {
        api: 'api/' + Resources.ContactsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.FirstName + " " + item.LastName,
            value: item.ContactID
        })
    },
    ContactID: {
        api: 'api/' + Resources.ContactsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.FirstName + " " + item.LastName,
            value: item.ContactID
        })
    },
    LoadID: {
        api: 'api/' + Resources.LoadsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.LoadNumber,
            value: item.LoadID
        })
    },
    LoadNumber: {
        api: 'api/' + Resources.LoadsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (it) => ({
            label: it.LoadNumber,
            value: it.LoadID
        })
    },
    ContactIDs: {
        api: 'api/' + Resources.ContactsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.FirstName + " " + item.LastName,
            value: item.ContactID
        })
    },
    TrackingMethodID: {
        api: 'api/' + Resources.IntegrationsMy,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.TrackingMethod,
            value: item.TrackingMethodID
        })
    },
    OrganizationID: {
        api: 'api/' + Resources.OrganizationsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.LegalName,
            value: item.OrganizationID
        })
    },
    ProductServiceCategoryID: {
        api: 'api/' + Resources.ProductsServicesCategory,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.ProductServiceCategory,
            value: item.ProductServiceCategoryID
        })
    },
    Groups: {
        api: 'api/' + Resources.ContactGroupsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.ContactGroupName,
            value: item.ContactGroupID
        })
    },
    ContactGroupID: {
        api: 'api/' + Resources.ContactGroupsQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.ContactGroupName,
            value: item.ContactGroupID
        })
    },
    OfficeID: {
        api: 'api/' + Resources.OfficesQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.OfficeName,
            value: item.OfficeID
        })
    },
    customers: {
        api: 'api/' + Resources.CustomersQuick,
        query: filterFields.customers?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.LegalName || item.Organization,
            value: item.CustomerID
        })
    },
    CustomerID: {
        api: 'api/' + Resources.CustomersQuick,
        query: filterFields.CustomerID?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.LegalName || item.Organization,
            value: item.CustomerID
        })
    },
    Offices: {
        api: 'api/' + Resources.OfficesQuick,
        query: filterFields.Offices?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            label: item.OfficeName,
            value: item.OfficeID
        })
    },
    EmployeeStatus: {
        1: 'Active',
        2: 'Paused',
        3: 'Inactive'
    },
    Tags: getLookup("TaskTagType"),
    TaskGroups: {
        api: 'api/' + Resources.BoardTasks,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            value: item.TaskGroupID,
            label: item.TaskGroupName
        })
    },
    Assignee: {
        api: 'api/' + Resources.ContactsQuick,
        query: {
            Assignee: 1
        },
        searchMap: (item) => ({
            label: item.FirstName + ' ' + item.LastName + ' - ' + item.Email,
            value: item.ContactID
        })
    },
    TruckID: {
        api: 'api/' + Resources.TrucksQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            value: item.TruckID,
            label: item.Truck
        })
    },
    DriverID: {
        api: 'api/' + Resources.DriversQuick,
        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
        searchMap: (item) => ({
            value: item.DriverID,
            label: item.FirstName + " " + item.LastName
        })
    },
    PreferenceTypeSubItem: (_, fields) => {
        const valToFind = fields.find(i => i.name === "PreferenceTypeID")?.value;
        const PreferenceTypeSubItems = getProp(LocalStorage.get("lookup"), "PreferenceType", []).find(it => (+it.PreferenceTypeID) === (+valToFind))

        return valToFind ? PreferenceTypeSubItems.PreferenceTypeSubItems.reduce(
            (memo, item) => {
                memo[item["PreferenceTypeSubItemID"]] = item["PreferenceTypeSubItem"];
                return memo;
            }, {}) : {}
    },
    PreferenceTypeID: getLookup("PreferenceType"),
    EventType: {
        1: translate("text.EVENT_STATUS_UPDATE"),
        2: translate("text.EVENT_SUB_STATUS_UPDATE"),
        3: translate("text.EVENT_WARNING_UPDATE"),
        4: translate("text.DISPATCHER_NOTE"),
        5: translate("text.CARRIER_NOTE"),
        6: translate("text.EVENT_INFO_UPDATE")
    }
})
