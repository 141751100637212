import Env from "../../../../../util/env";
import Resources from "../../../../../data/services/resources";
import {classNames, returnTaskStatus} from "../../../../../common/util/util-helpers";
import { toFrontDateTime, toFrontDateTimeFromUTC } from '../../../../../common/util/util-dates'
import React from "react";
import {
    TASK_CHANGE_ASSIGNEE,
    TASK_CHANGE_CREATE,
    TASK_CHANGE_DUE_DATE,
    TASK_CHANGE_START_DATE,
    TASK_CHANGE_STATUS
} from "../../../../../util/util-constants";
import InfoParagraph from "../../../../../common/components/info-paragraph";
import {getJWT} from "../../../../../common/util/util-auth";

export default function TaskHistory( {history, onContactClick, translate} ) {
    return history.map(( item, index ) => {
        const isLastItem = history.length !== index + 1;

        return (
            <div
                key={(item.UpdatedByContactID || 'SystemUser') + "-" + index}
                onClick={() => onContactClick(item.UpdatedByContactID)}
                className="relative flex items-start space-x-3"
            >
                <img
                    src={item.ImagePath ? Env.getApiUrl('api/' + Resources.ContactImage, {
                        token: getJWT().access_token,
                        ContactID: item.UpdatedByContactID
                    }) : '/images/avatar.png'} alt=""
                    className="h-10 w-10 rounded-full flex items-center justify-center ring-8 ring-inverse z-10 bg-tm-gray-50 relative"
                />

                <div className={classNames("min-w-0 flex-1", isLastItem ? "mb-8" : undefined)}>
                    <div>
                        <div className="text-sm">
                            {!!item.UpdatedByContactID && (
                                <span className="font-medium text-primary cursor-pointer">{item.UpdatedByContact}</span>
                            )}
                            
                            {!item.UpdatedByContactID && (
                                <span className="font-medium text-tm-gray-600">{translate("text.system")}</span>
                            )}
                        </div>
                        <p className="mt-0.5 text-sm text-tm-gray-500">
                            {toFrontDateTimeFromUTC(item.CreateUpdateDate)}
                        </p>
                    </div>

                    <div className="mt-2 text-sm text-tm-gray-700">
                        {getTaskStatus(item, translate)}
                    </div>
                </div>

                {isLastItem && (
                    <span className="absolute top-5 left-2 -ml-px h-full w-0.5 bg-tm-gray-200" aria-hidden="true"/>
                )}
            </div>
        )
    })
}

const getTaskStatus = ( item, translate ) => {
    switch (item.ChangeType) {
        case TASK_CHANGE_DUE_DATE:
            return (
                <div>
                    {translate('text.task_due_date_updated', [toFrontDateTime(item.OldValue), toFrontDateTime(item.NewValue)])}.

                    {!!item.Notes && (
                        <div className="mt-4">
                            <InfoParagraph type="note" message={item.Notes}/>
                        </div>
                    )}
                </div>
            )
        case TASK_CHANGE_START_DATE:
            return translate('text.task_start_date_updated', [toFrontDateTime(item.OldValue), toFrontDateTime(item.NewValue)]);
        case TASK_CHANGE_CREATE:
            return translate('text.user_created_task')
        case TASK_CHANGE_ASSIGNEE:
            return (translate("text.changed_assignee_to") + " " + item.NewValue)
        case TASK_CHANGE_STATUS:
            return (
                <div>
                    {translate("text.updated_status_from", [translate(returnTaskStatus(Number(item.OldValue))), translate(returnTaskStatus(Number(item.NewValue)))])}
                </div>
            )
        default:
            return "";
    }

}
