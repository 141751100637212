import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    PAYMENT_METHOD_CARD,
    READ_PERM,
} from '../../../util/util-constants'
import {createResource, deleteResource, getResource, updateResource,} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import moment from 'moment'
import {
    checkPerm,
    getDefaultContactGroup,
    getDefaultTableOptions,
    getDefaultUserOfficeValues,
    getLookup,
    getProp
} from '../../../common/util/util-helpers'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {deleteSecondResource, getSecondResource} from '../../../data/actions/secondResource'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {CheckCircleIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {createDialogResource} from '../../../data/actions/dialogResource'
import Tippy from '@tippyjs/react'
import {toFrontDateTime} from '../../../common/util/util-dates'
import axios from "axios";
import Env from "../../../util/env";
import IncomeExpenseDialog from "../dialogs/income-expense-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import FileViewer from "../../../common/components/file-viewer/components"
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import {LoaderLarge} from "../../../common/components/loader";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFooter from "../../../common/components/resource-table/table/table-footer";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import PopoverDatePicker from "../../../common/components/popover/popover-datepicker";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import ResourceTable from "../../../common/components/resource-table";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";

const TransactionDetailsView = (props) => {

    /** Constants
     ===================================================== */
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()

    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)
    const secondResource = useSelector((state) => state.secondResource)
    const dialogResource = useSelector((state) => state.dialogResource)

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    const tablePageDefaults = {
        behaviour: {
            canAdjustWidth: false,
            hasMenu: false
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        }
    }

    // Set will be used for modifying table options
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), tablePageDefaults, "/accounting/credit-cards/", props.translate));

    /** Helpers
     ===================================================== */
    const getCreditAccountID = () => {
        return params.id
    }

    const getAccountID = () => {
        return getProp(resource, 'data.AccountID', 0);
    }

    function renderDescriptionField(item) {
        return (
            <div>
                {!!item.ExpenseID && (
                    <button onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        openDetailsDialog(item)
                    }}
                            className="btn btn-text p-1 hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                    >
                        {item.AutoCounter} - {item.LegalName}
                    </button>)}
                {!item.ExpenseID && (<p className="p-1">Credit account recharge</p>)}
            </div>
        )
    }

    function getFields(item) {
        let fieldTemplates = {
            PayTo: new Field('PayTo', '', [''], false, 'custom', {render: renderDescriptionField, omitSort: true}, {}),
            Date: new Field('Date', '', [''], false, 'date'),
            Account: new Field('Account', '', [''], false, 'text', {omitSort: true}),
            Description: new Field("Description", '', ['empty'], false, 'text'),
            ChargeToContact: new Field('ChargeToContact', '', [''], false, 'text', {label: 'ChargeTo', omitSort: true}),
            Balance: new Field('Balance', '', ['empty'], false, 'custom', {
                label: 'Amount',
                render: (item) => {
                    if (item.ReconciliationDate) {
                        return (
                            <Tippy content={"Reconciled: " + toFrontDateTime(item.ReconciliationDate)}>
                                <div className={"flex justify-end"}>
                                    <CheckCircleIcon className="w-5 h-5 text-green-600 mr-4"/>
                                    <div>{genericMoneyFormatter(item.Balance)}</div>
                                </div>
                            </Tippy>
                        )
                    }
                    return <div className={"text-right"}>{genericMoneyFormatter(item.Balance)}</div>
                }
            }, {
                isNegativeAllowed: true
            }),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    const getRechargeCreditAccountFields = () => {
        return {
            CashAccountID: new Field('CashAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Amount: new Field('Amount', '', ['empty'], false, 'float', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
        }
    }

    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: 'stack'}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: 'stack'}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    const getStatementFields = () => {
        return {
            AccountID: new Field('AccountID', getAccountID(), [''], false, 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            StartingBalance: new Field('StartingBalance', '', ['empty'], false, 'money', {addContainerClass: 'col-span-6'}, {isNegativeAllowed: true}),
            EndingBalance: new Field('EndingBalance', '', ['empty'], false, 'money', {addContainerClass: 'col-span-6'}, {isNegativeAllowed: true}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            IsCompleted: new Field('IsCompleted', '', [], false, 'checkbox', {
                hideDialog: true
            }),
        }
    }

    const getQuery = () => {
        return {
            CreditAccountID: getCreditAccountID(),
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    /** State
     ===================================================== */
    const [fields, setFields] = useState(getFields())
    const [query, setQuery] = useState(DEFAULT_CRUD_STATE)
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFilterFields())
    const [rechargeCreditAccountModalOpen, setRechargeCreditAccountModalOpen] = useState(false)
    const [enterExpenseModalOpen, setEnterExpenseModalOpen] = useState(false)
    const [isDetailsDialogVisible, setIsDetailsDialogVisible] = useState(false)
    const [isEditDialogVisible, setIsEditDialogVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [itemModalKey, setItemModalKey] = useState(0)
    const [title, setTitle] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState(null)
    const [isStatementsModalOpen, setIsStatementsModalOpen] = useState(false)
    const [isStatementsFormModalOpen, setIsStatementsFormModalOpen] = useState(false)

    const [isPreviewDocumentModalOpen, setIsPreviewDocumentModalOpen] = useState(false)
    const [isPreviewDocumentError, setIsPreviewDocumentError] = useState(false)
    const [isPreviewDocumentLoading, setIsPreviewDocumentLoading] = useState(false)
    const [previewFileURL, setPreviewFileURL] = useState("")

    /** UI Events
     ===================================================== */
    const toggleRechargeCreditAccountModal = () => {
        setRechargeCreditAccountModalOpen(!rechargeCreditAccountModalOpen)
    }

    const toggleEnterExpenseModal = () => {
        setEnterExpenseModalOpen(!enterExpenseModalOpen)
    }

    const openDetailsDialog = (item = null) => {
        setSelectedItem(item)
        setIsDetailsDialogVisible(true)
    }

    const closeDetailsDialog = () => {
        setSelectedItem(null)
        setIsDetailsDialogVisible(false)
    }

    const handleClearFiltersClick = () => {
        setQueryFilterFields(getQueryFilterFields())
    }

    const handleFilterInputChange = (name, value) => {
        const queryFields = FieldsManager.updateField(queryFilterFields, name, value);
        if ("limit" === name) {
            setQuery((prevState) => ({
                ...prevState,
                offset: 0
            }))
        }

        setQueryFilterFields(queryFields)
    }

    const handleUpdateQueryFields = (fields) => {
        setQueryFilterFields(fields)
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleToggleConfirmDialog = (item) => {
        if (item) {
            setTitle(item.ExpenseID ? (item.AutoCounter + '-' + item.LegalName) : "Credit account recharge")
            setSelectedItem(item)
        }
        setConfirmDialog(!confirmDialog)
    }

    const handleToggleEditDialog = (item = null) => {
        setIsEditDialogVisible(!isEditDialogVisible)
        setSelectedItem(item)
    }

    const archiveResource = () => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                ExpenseID: selectedItem.ExpenseID
            },
            errorMessage: true, successMessage: `Expense ${title} deleted`,
            resource: Resources.Expense,
            piggyQuery: getQuery(),
            piggyResource: Resources.AccountingCreditAccountsDetails
        }))
        handleToggleConfirmDialog();
    }

    const handleInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)

        if (name === 'ExpenseAccountID') {
            fields.ChargeToContactID.validate = value?.metadata?.IsChargeToContact ? ['empty'] : [''];
        }

        return fields
    }

    const handlePreviewDocumentClick = (it) => {
        setIsPreviewDocumentModalOpen(true)
        setIsPreviewDocumentLoading(true)
        setIsPreviewDocumentError(false)
        setSelectedItem(it)
    }

    /** Data events
     ===================================================== */
    const fetchExpensesData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingCreditAccountsDetails,
            query: getQuery()
        }))
    }

    const fetchInvoiceDialogData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                ExpenseID: selectedItem.ExpenseID
            },
            resource: Resources.ExpenseExpense
        }))
    }

    const downloadDocument = () => {
        if (previewFileURL) {
            const pdfWindow = window.open(previewFileURL)
            pdfWindow.location.href = previewFileURL
        }
    }

    const fetchPDF = async () => {
        try {
            await axios
                .get(Env.getApiUrl('api/' + Resources.AccountingReconciliation, Object.assign({}, {
                        ReconciliationBatchID: selectedItem.ReconciliationBatchID,
                        ExportPDF: 1
                    })),
                    {
                        responseType: 'blob',
                        headers: {
                            Authorization: "Bearer " + getJWT().access_token,
                        }
                    })
                .then((response) => {
                    //Create a Blob from the PDF Streamwork
                    const file = new Blob([response.data], {type: 'application/pdf'})
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);

                    setPreviewFileURL(fileURL)
                    setIsPreviewDocumentLoading(false)
                })
                .catch((error) => {
                    setIsPreviewDocumentLoading(false)
                    setIsPreviewDocumentError(true)
                    console.log(error)
                })
        } catch (error) {
            return {error}
        }
    }

    /** Lifecycle
     ===================================================== */
    useEffect(() => {
        fetchExpensesData()
    }, [query, queryFilterFields])

    useEffect(() => {
        if (isPreviewDocumentModalOpen) {
            fetchPDF()
        }
    }, [isPreviewDocumentModalOpen])

    /** Render
     ===================================================== */
    const balance = genericMoneyFormatter(getProp(resource, 'data.Balance', 0))
    const creditLimit = genericMoneyFormatter(getProp(resource, 'data.CreditLimit', 0))
    const accountID = getProp(resource, 'data.AccountID', 0)

    return (
        <Layout {...props} ui={ui} dispatch={dispatch} history={history} user={user}>
            <Page>
                <div className="flex justify-between mb-8">
                    <div className="flex justify-start items-center">
                        <GoBackButton
                            translate={props.translate}
                            history={props.history}
                            path={'/accounting-credit-cards'}
                        />
                        <h1 className={'mr-5 text-3xl ml-2'}>{props.translate('page.heading.transaction_details')}</h1>
                    </div>

                    <div className={"flex ml-auto mr-4 space-x-4"}>
                        <button
                            className={"btn btn-outline"}
                            onClick={() => setIsStatementsModalOpen(true)}
                        >
                            {props.translate("btn.statements")}
                        </button>

                        <button className={"btn btn-primary"} onClick={() => toggleEnterExpenseModal()}>
                            {props.translate("text.enterExpense")}
                        </button>
                    </div>

                    <PageHeader
                        buttonLabel={props.translate('btn.recharge')}
                        onButtonClick={toggleRechargeCreditAccountModal}
                    />
                </div>

                <>
                    <div className={'mb-5'}>
                        <ActiveFilters
                            filterFields={queryFilterFields}
                            onLabelClick={handleFilterInputChange}
                            onClearFiltersClick={handleClearFiltersClick}
                            translate={props.translate}
                        />
                    </div>
                    <TableCard>
                        <TableFilters
                            hideLimit
                            filterFields={queryFilterFields}
                            handleInputChange={handleFilterInputChange}
                            customHeaderHtml={
                                <PopoverDatePicker
                                    queryFields={queryFilterFields}
                                    onQueryInputChange={handleFilterInputChange}
                                    updateFields={handleUpdateQueryFields}
                                    translate={props.translate}
                                />
                            }
                            translate={props.translate}
                        />
                        <ResourceTable
                            addClass="rounded-card"
                            data={data}
                            count={count}
                            options={tableOptions}
                            fields={fields}
                            translate={props.translate}
                            isLoading={isLoading}

                            limit={queryFilterFields?.limit?.value ?? query.limit}
                            offset={query.offset}
                            page={query.paginationPage}
                            onOffsetChange={handleUpdateOffset}

                            sort={query.sort}
                            sortBy={query.sortBy}
                            onSortChange={handleUpdateSort}
                            actions={[
                                {
                                    title: () => props.translate('btn.view'),
                                    visible: (item) => !!item.ExpenseID,
                                    action: (item) => openDetailsDialog(item),
                                    icon: () => EyeIcon
                                },
                                {
                                    title: () => props.translate('btn.edit'),
                                    visible: (item) => !!item.ExpenseID && !item.ReconciliationDate,
                                    action: (item) => handleToggleEditDialog(item),
                                    icon: () => PencilIcon
                                },
                                {
                                    title: () => props.translate('btn.delete'),
                                    visible: (item) => !!item.ExpenseID && !item.ReconciliationDate,
                                    action: handleToggleConfirmDialog,
                                    icon: () => TrashIcon
                                }
                            ]}
                            metadata={{
                                OrganizationID: {
                                    api: 'api/' + Resources.OrganizationsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName + " - " + (item.AddressName ?? "")
                                            + ", " + (item.CityName ?? "")
                                            + " " + (getLookup('State')[item.StateID] ?? "") + " "
                                            + (item.PostalCode ?? ""),
                                        value: item.OrganizationID
                                    })
                                }
                            }}
                        />
                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            title={props.translate('text.no_matching_records')}
                        />

                        <div className="px-0 py-1 border-y border-tm-gray-200  flex">
                            <div className="text-sm flex justify-end w-full h-9 mr-6 items-center">
                                <div className="mr-4">
                                    Current balance: <span className="font-bold text-tm-gray-700">{balance}</span>
                                </div>

                                <div className="">
                                    Credit limit: <span className="font-bold text-tm-gray-700">{creditLimit}</span>
                                </div>
                            </div>
                        </div>

                        <TableCardFooter show={!!count || isLoading}>
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? handleUpdateOffset(value, currentPage)
                                        : handleFilterInputChange(name, value)
                                }
                                pageOffset={query.offset}
                                queryFields={queryFilterFields}
                                translate={props.translate}
                            />
                        </TableCardFooter>
                    </TableCard></>

                {isDetailsDialogVisible && (
                    <ModalDefault
                        show={isDetailsDialogVisible}
                        widthClass={'max-w-7xl w-screen'}
                        title={props.translate('text.ExpenseID')}

                        closeButtonLabel={props.translate('btn.Close')}
                        onClose={closeDetailsDialog}
                    >
                        <InvoiceExpenseDetailsDialog
                            resource={secondResource}
                            isLoading={secondResource.isLoading || resource.isLoading}
                            translate={props.translate}
                            dispatch={dispatch}
                            type="expense"

                            disableComments={true}
                            canDeletePayment={false}
                            disableAddReference={true}
                            disableTransactionImport={true}
                            showLoadReferenceLinks

                            onFetchData={() => {
                                if (selectedItem) {
                                    fetchInvoiceDialogData()
                                }
                            }}

                            onDeletePayment={(item) => {
                                dispatch(deleteSecondResource({
                                    user: LocalStorage.get('user'),
                                    resource: Resources.ExpensePayment,
                                    query: {
                                        SendPaymentID: item.SendPaymentID
                                    },
                                    secondPiggyResource: Resources.ExpenseExpense,
                                    errorMessage: true,
                                    successMessage: props.translate('text.PaymentDeletedSuccessfully')
                                }))
                            }}
                        />
                    </ModalDefault>
                )}

                {/* Edit Dialog */}
                {isEditDialogVisible && (
                    <IncomeExpenseDialog
                        isExpenseDialogType={true}
                        updateItem={selectedItem}
                        dialogType={'expense'}
                        onClose={handleToggleEditDialog}
                        onSubmit={(params, resource, files, fileResource, notePayableFields) => {

                            if (!!notePayableFields) {
                                Object.assign(params, notePayableFields)
                            }

                            if (params) {
                                if (params.id) {
                                    dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: Resources.ExpenseDocuments,
                                        piggyResource: Resources.AccountingCreditAccountsDetails,
                                        errorMessage: true, successMessage: props.translate('text.entry_updated')
                                    }))
                                }
                                handleToggleEditDialog()
                            }
                        }}
                        {...props}
                    />
                )}


                <ModalSaveResource
                    show={rechargeCreditAccountModalOpen}
                    title={'Create recharge'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={toggleRechargeCreditAccountModal}
                    fields={getRechargeCreditAccountFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.CreditAccountID = getCreditAccountID()
                            dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: getQuery(),
                                params: params,
                                resource: Resources.AccountingCreditAccountsRecharge,
                                piggyResource: Resources.AccountingCreditAccountsDetails,
                                errorMessage: true, successMessage: 'Credit Account recharged.',
                            }))
                            setRechargeCreditAccountModalOpen(false)
                        }
                    }}
                    translate={props.translate}
                    metadata={{
                        CashAccountID: {
                            api: 'api/accounting/accounts',
                            query: {
                                AccountTypeID: ACCOUNT_TYPE_CASH
                            },
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName
                            })
                        }
                    }}
                />

                <ModalSaveResource
                    key={itemModalKey}
                    title={props.translate("text.enterExpense")}
                    widthClass="max-w-md"
                    show={enterExpenseModalOpen}
                    gridClass="grid gap-4 grid-cols-6 p-6"
                    handleInputChange={handleInputChange}
                    onClose={toggleEnterExpenseModal}
                    fields={{
                        OrganizationID: new Field("OrganizationID", '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                        OfficeID: new Field("OfficeID", getDefaultUserOfficeValues(), ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
                        ContactGroupID: new Field("ContactGroupID", getDefaultContactGroup(), ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
                        InternalNotes: new Field("InternalNotes", '', ['empty'], false, 'text', {
                            label: "Description",
                            addContainerClass: 'col-span-full'
                        }),
                        Amount: new Field("Amount", '', ['empty'], false, 'money', {addContainerClass: 'col-span-full'}, {
                            isNegativeAllowed: true
                        }),
                        Date: new Field("Date", '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
                        ExpenseAccountID: new Field("ExpenseAccountID", '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                        RefNumber: new Field("RefNumber", '', [''], false, 'text', {addContainerClass: 'col-span-full'}),
                        ChargeToContactID: new Field("ChargeToContactID", '', [], false, 'select-search', {addContainerClass: 'col-span-full'}),

                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.AccountID = accountID;
                            params.PaymentTypeID = PAYMENT_METHOD_CARD;
                            params.BookDate = params.Date
                            dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: getQuery(),
                                params: params,
                                resource: Resources.ExpenseExpenseShort,
                                piggyResource: Resources.AccountingCreditAccountsDetails,
                                errorMessage: true, successMessage: "Expense created."
                            }));
                            toggleEnterExpenseModal();
                        }
                    }}

                    secondaryButtonLabel={props.translate('btn.save_and_add_more')}
                    onSecondaryButtonClick={(params) => {
                        params.AccountID = accountID;
                        params.PaymentTypeID = PAYMENT_METHOD_CARD;
                        if (params) {
                            dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: getQuery(),
                                params: params,
                                resource: Resources.ExpenseExpenseShort,
                                piggyResource: Resources.AccountingCreditAccountsDetails,
                                errorMessage: true, successMessage: "Expense created."
                            }));
                            setItemModalKey(itemModalKey + 1)
                        }
                    }}
                    disableSecondaryIfNotDirty={true}
                    metadata={{
                        OrganizationID: {
                            api: 'api/' + Resources.OrganizationsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.LegalName,
                                value: item.OrganizationID
                            })
                        },
                        OfficeID: {
                            api: 'api/' + Resources.OfficesQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.OfficeName,
                                value: item.OfficeID
                            })
                        },
                        ContactGroupID: {
                            api: 'api/' + Resources.ContactGroupsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.ContactGroupName,
                                value: item.ContactGroupID
                            })
                        },
                        ChargeToContactID: {
                            api: "api/" + Resources.ContactsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                        ExpenseAccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.AccountNumber + ' ' + item.AccountName,
                                value: item.AccountID,
                                metadata: item
                            })
                        },
                    }}
                    translate={props.translate}
                />


                <ResourceTableDialog
                    show={isStatementsModalOpen}
                    dialogResource={dialogResource}
                    resource={Resources.AccountingReconciliationBatch}
                    title={props.translate('modal_heading.credit_card_statements')}
                    dispatch={dispatch}

                    fields={getStatementFields()}

                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            hideLabel: true,
                            labelType: 'float'
                        }),
                    }}

                    defaultQuery={{
                        searchFields: JSON.stringify({
                            AccountID: getAccountID()
                        })
                    }}
                    defaultAction={(it) => history.push('/accounting/reconciliation/' + it.ReconciliationBatchID + "?cc_statement=" + props.match.params.id)}
                    onView={(it) => history.push('/accounting/reconciliation/' + it.ReconciliationBatchID + "?cc_statement=" + props.match.params.id)}

                    onCustomActions={[
                        {
                            action: (it) => handlePreviewDocumentClick(it),
                            customContent: () => <MagnifyingGlassIcon className="w-5 h-5"/>,
                            visible: () => checkPerm(Resources.AccountingReconciliation, READ_PERM),
                            title: props.translate('text.preview_document'),
                            order: 20
                        }
                    ]}

                    tableKey={"ReconciliationBatchID"}

                    onClose={() => setIsStatementsModalOpen(false)}
                    closeButtonLabel={props.translate("btn.close")}
                    translate={props.translate}
                    commonTable={true}
                    tableHeaderRight={
                        <div className="col-span-9 flex justify-end">
                            <button
                                onClick={() => setIsStatementsFormModalOpen(true)}
                                className="btn btn-primary"
                            >
                                {props.translate("btn.new_statements")}
                            </button>
                        </div>
                    }
                    htmlAfter={(_, query) => {
                        return <ModalSaveResource
                            show={isStatementsFormModalOpen}
                            title={props.translate('page.heading.Reconciliation')}
                            widthClass="max-w-lg"
                            gridColsClass="grid-cols-12"
                            onClose={() => setIsStatementsFormModalOpen(false)}
                            fields={getStatementFields()}
                            onSubmit={(params) => {
                                if (params) {
                                    dispatch(createDialogResource({
                                        user: LocalStorage.get('user'),
                                        query: query,
                                        params: params,
                                        resource: Resources.AccountingReconciliationBatch,
                                        piggyResource: Resources.AccountingReconciliationBatch,
                                        errorMessage: true,
                                        successMessage: 'Reconciliation batch created successfully!'
                                    }))
                                    setIsStatementsFormModalOpen(false);
                                }
                            }}
                            translate={props.translate}
                            //metadata={metadata}
                            //handleInputChange={this.props.handleInputChange ?? null}
                        />
                    }}
                />

                {/* Preview PDF */}
                <ModalDefault
                    show={isPreviewDocumentModalOpen}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    title={props.translate('modal_heading.preview_statement')}

                    closeButtonLabel={props.translate('Close')}
                    onClose={() => setIsPreviewDocumentModalOpen(false)}
                    buttonLabel={
                        !isPreviewDocumentLoading
                        && !isPreviewDocumentError
                        && props.translate('Download')
                    }
                    onButtonClick={downloadDocument}
                >

                    {isPreviewDocumentLoading && (
                        <div className='relative flex items-center justify-center h-64'>
                            <LoaderLarge/>
                        </div>
                    )}

                    {!isPreviewDocumentLoading && isPreviewDocumentError && (
                        <InfoBar type="danger">
                            {props.translate("text.document_load_fail")}
                        </InfoBar>
                    )}

                    {!isPreviewDocumentLoading && !isPreviewDocumentError && (
                        <FileViewer
                            fileType={'pdf'}
                            filePath={previewFileURL}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>

                <ModalConfirm
                    title={title}
                    show={!!confirmDialog}
                    text={props.translate("message.are_you_sure_delete_item")}
                    onClose={() => handleToggleConfirmDialog()}
                    buttonLabel={props.translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={props.translate}
                    onConfirm={archiveResource}
                />
            </Page>
        </Layout>
    )
}

export default TransactionDetailsView

