const stackedResourceReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_STACKED_RESOURCE':
            return Object.assign({}, state, {
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_STACKED_RESOURCE':
            return Object.assign({}, state, {
                data: Object.assign(state.data ? state.data : {}, {[action.data.resource]: action.data.data}),
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_STACKED_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: true,
                errorMessage: action.data
            });
        default:
            return state;
    }
};

export default stackedResourceReducer;