import {cloneDeep} from "../../../util/util-vanilla";
import {fieldsToHtml} from "../../../util/util-fields";

export default function Fields({fieldsState, excludeFields = [], includeFields = [], translate, onInputChange, selects = {}, fieldRefs}) {
    const fields = cloneDeep(fieldsState);

    if (excludeFields.length) {
        excludeFields.forEach(field => {
            delete fields?.[field];
        })
    }

    if (includeFields.length) {
        Object.values(fields).forEach(field => {
            if (!includeFields.includes(field.name)) {
                delete fields?.[field.name];
            }
        })
    }

    if (fieldRefs) {
        Object.keys(fieldRefs).forEach(fieldName => {
            fields[fieldName].props.innerRef = fieldRefs[fieldName];
        })
    }

    const fieldValues = Object.values(Object.assign({}, fields))

    return fieldsToHtml(fieldValues, translate, onInputChange, selects);
}

