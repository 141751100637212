import React from "react";
import {fieldsToHtml, includeFields} from "../../../util/util-fields";

export default function ResourceTableSearch({handleQueryChange, queryFields, includedFields = ['query'], selects = {}, translate}) {
    return (
        <div className="grid grid-cols-12 grow gap-x-3">
            {fieldsToHtml(
                Object.values(Object.assign({}, includeFields(queryFields, includedFields))),
                translate,
                handleQueryChange, selects
            )}
        </div>
    )
}