import React, {useRef} from 'react'
import Modal from "./index";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import DialogDefaultFooter from './modal-footer'
import {classNames} from "../../util/util-helpers";

export default function ModalDefault({
                                         show,
                                         initialFocusRef,
                                         focusXButton,
                                         widthClass,
                                         hideOverlay,
                                         onClose,
                                         onBackdropClick,
                                         isLoading,
                                         onButtonClick,
                                         buttonDisabled,
                                         buttonLabel,
                                         onSecondaryButtonClick,
                                         secondaryButtonLabel,
                                         customHtmlLeft = null,
                                         customHtmlRight = null,
                                         title,
                                         customButtonsHTML,
                                         secondaryButtonDisabled,
                                         closeButtonLabel,
                                         middleButtonLabel,
                                         middleButtonID,
                                         middleButtonDisabled,
                                         onMiddleButtonClick,
                                         options,
                                         hideXBtn,
                                         children,
                                         limitHeight,
                                         hideDialogFooter,
                                         addClass,
                                         baseClass,
                                         afterTitle,
                                         modalClass,
                                         submitButtonRef
                                     }) {
    const closeButtonRef = useRef(null);

    return (
        <Modal
            baseClass={baseClass}
            show={show}
            modalClass={modalClass}
            hideOverlay={hideOverlay}
            onClose={onClose}
            widthClass={widthClass}
            onBackdropClick={onBackdropClick}
            initialFocusRef={initialFocusRef ?? closeButtonRef}
        >
            <div className="relative">
                <header
                    className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900 h-14 relative">
                    <h2 className="text-lg text-current truncate">{title}</h2>

                    {!!afterTitle && (
                        <div>{afterTitle}</div>
                    )}

                    {!!options && (
                        <div className="ml-auto pr-14">
                            {options}
                        </div>
                    )}

                    {!hideXBtn && focusXButton && (
                        <button
                            className="absolute top-[18px] right-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary rounded-full"
                            aria-label="Close"
                            onClick={() => {
                                onClose();
                            }}>
                            <XMarkIcon className="w-5 h-5"/>
                        </button>
                    )}
                </header>

                <div
                    className={
                        classNames(
                            limitHeight ? "h-dialog-body" : "",
                            isLoading ? "invisible" : "",
                            addClass
                        )
                    }
                >
                    {children}
                </div>

                {!hideDialogFooter && (
                    <DialogDefaultFooter
                        buttonLabel={buttonLabel}
                        buttonDisabled={buttonDisabled}
                        onButtonClick={onButtonClick}


                        closeButtonLabel={closeButtonLabel}
                        onClose={onClose}

                        middleButtonLabel={middleButtonLabel}
                        middleButtonID={middleButtonID}
                        middleButtonDisabled={middleButtonDisabled}
                        onMiddleButtonClick={onMiddleButtonClick}

                        secondaryButtonLabel={secondaryButtonLabel}
                        secondaryButtonDisabled={secondaryButtonDisabled}
                        onSecondaryButtonClick={onSecondaryButtonClick}

                        customHtmlLeft={customHtmlLeft}
                        customHtmlRight={customHtmlRight}
                        submitButtonRef={submitButtonRef}
                    >
                        {!!customButtonsHTML && (
                            customButtonsHTML
                        )}
                    </DialogDefaultFooter>
                )}

                {!hideXBtn && !focusXButton && (
                    <button
                        className="absolute top-[18px] right-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary rounded-full"
                        aria-label="Close"
                        onClick={() => {
                            onClose();
                        }}>
                        <XMarkIcon className="w-5 h-5"/>
                    </button>
                )}
            </div>
        </Modal>
    )
}
