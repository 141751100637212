import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {fieldsToHtml, fillFieldsFromData, includeFields} from '../../../common/util/util-fields';
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {getCountryCodes} from "../../../common/util/countryCodes";
import {
    BANK_ACCOUNT_TYPES,
    COUNTRY_ID_CANADA,
    COUNTRY_ID_USA,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    FIELD_MASK_PHONE,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {
    checkPerm,
    getLookup,
    getPreFillFromQuery,
    getProp,
    updateContractFields,
    updateStateFields
} from '../../../common/util/util-helpers'
import {scrollErrorIntoViewFields, setSelectionRange} from '../../../common/util/util-vanilla'
import CardSubTitle from "../../../common/components/card/card-sub-title";
import {getHistoryResource} from "../../../data/actions/historyResource";
import {showModal} from "../../../data/actions/ui";
import {InformationCircleIcon} from '@heroicons/react/24/outline'
import {HourglassIcon} from "../../../data/themes/icons";
import GroupsViewDialog from "../../../common/components/modal/groups-view-dialog";
import {LoaderSmall} from "../../../common/components/loader";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import Card from "../../../common/components/card";
import Modal from "../../../common/components/modal";
import FieldOptions from "../../../common/components/fields/field-options";
import Button from "../../../common/components/button";

export default class InfoTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // Fields
            fields: this.getFields(),

            // Footer variables
            canSubmit: false,
            footerIsSticky: true,

            // Modals
            historyModalOpen: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {}
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.VendorsInfo,
            query: {
                id: this.getID()
            },
        }))
    }

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        this.props.canSwapTabsHandler(false)
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                id: this.getID(),
                            }),
                            query: {
                                id: this.getID(),
                            },
                            errorMessage: true,
                            successMessage: `Vendor ${getProp(this.props, 'resource.data.' + Resources.VendorsInfo + '.LegalName', '')} updated`,
                            resource: Resources.VendorsInfo,
                            piggyResource: Resources.VendorsInfo,
                        }))
                    })
                } else {
                    scrollErrorIntoViewFields([this.state.fields])
                }
            })
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields, name, value)
        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        fields = updateContractFields(fields, name, value)
        fields = updateStateFields(fields, name, value)

        this.setState({canSubmit: true, fields: fields})
    }

    handleSetLocations = (fields, isLocationPhysical = false) => {
        let updatedFields = this.state.fields

        for (let [key, value] of Object.entries(fields)) {
            if (isLocationPhysical) {
                key = "Physical" + key;
            }

            if (key in this.state.fields) {
                if (!(key === 'LegalName' && !!this.state.fields?.['LegalName']?.value)) {
                    updatedFields = FieldsManager.updateField(updatedFields, key, value)
                }
            }
        }

        this.setState({
            fields: updatedFields,
            canSubmit: true
        })
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            }, () => this.props.canSwapTabsHandler(true))
        }
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    /** Helpers
     ================================================================= */
    getFields = (item = {}) => {
        const LegalName = getPreFillFromQuery()

        let fieldTemplates = {
            VendorID: new Field('VendorID', '', ['']),

            // Card 1
            CountryID: new Field('CountryID', 1, [], false, 'select', {addContainerClass: "col-span-full"}),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: this.handleSetLocations,
                addContainerClass: "col-span-full"
            }),
            LegalName: new Field('LegalName', LegalName ?? '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
            DBAName: new Field('DBAName', '', [''], false, "text", {addContainerClass: "col-span-6"}),
            AddressName: new Field('AddressName', '', [], false, "text", {addContainerClass: "col-span-full"}),
            AddressName2: new Field('AddressName2', '', [], false, "text", {addContainerClass: "col-span-full"}),
            CityName: new Field('CityName', '', [], false, "text", {addContainerClass: "col-span-4"}),
            StateID: new Field('StateID', '', [], false, (item?.CountryID == COUNTRY_ID_USA || item?.CountryID == COUNTRY_ID_CANADA) ? 'select' : 'hidden', {addContainerClass: "col-span-4"}),
            PostalCode: new Field('PostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),

            PhysicalCountryID: new Field('PhysicalCountryID', 1, [], false, 'select', {addContainerClass: "col-span-full"}),
            PhysicalGooglePlaces: new Field('PhysicalGooglePlaces', '', [], false, 'google-locations', {
                setLocations: (fields) => this.handleSetLocations(fields, true),
                countryKey: "PhysicalCountryID",
                addContainerClass: "col-span-full"
            }),
            PhysicalAddressName: new Field('PhysicalAddressName', '', [], false, "text", {addContainerClass: "col-span-full"}),
            PhysicalAddressName2: new Field('PhysicalAddressName2', '', [], false, "text", {addContainerClass: "col-span-full"}),
            PhysicalCityName: new Field('PhysicalCityName', '', [], false, "text", {addContainerClass: "col-span-4"}),
            PhysicalStateID: new Field('PhysicalStateID', '', [], false, !item?.PhysicalCountryID || (item?.PhysicalCountryID == COUNTRY_ID_USA || item?.PhysicalCountryID == COUNTRY_ID_CANADA) ? 'select' : 'hidden', {addContainerClass: "col-span-4"}, {menuPlacement: "top"}),
            PhysicalPostalCode: new Field('PhysicalPostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),

            ContactGroupID: new Field('ContactGroupID',
                this.getID() ? this.getVendorContactGroup() : null,
                ['empty'], false, 'select-search',
                {
                    addContainerClass: "col-span-full xl:col-span-6",
                    label: "CompaniesGroupID",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                        isVisible: !!it.value && checkPerm(Resources.ContactGroups, READ_PERM)
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    isClearable: true,
                    api: 'api/' + Resources.ContactGroupsQuick,
                    query: {},
                    searchMap: (it) => ({
                        label: it.ContactGroupName,
                        value: it.ContactGroupID,
                        metadata: it
                    })
                }
            ),

            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search',
                {
                    addContainerClass: "col-span-full xl:col-span-6",
                }, {
                    api: 'api/' + Resources.OfficesQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        label: item.OfficeName,
                        value: item.OfficeID
                    })
                }
            ),

            UserDefinedField1: new Field('UserDefinedField1', '', [], false, "test", {addContainerClass: "col-span-full"}),
            UserDefinedField2: new Field('UserDefinedField2', '', [], false, "test", {addContainerClass: "col-span-full"}),

            // Card 2
            AreaCode: new Field('AreaCode', '', [], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: FIELD_MASK_PHONE
            }),
            PhoneExtension: new Field('PhoneExtension', '', []),
            PrimaryEmail: new Field('PrimaryEmail', '', ['']),
            SCAC: new Field('SCAC', '', [''], false, 'text', {}, {
                max: SCAC_CODE_FIELD_LENGTH,
                upperCaseOnly: true
            }),
            OrganizationEntityTypeID: new Field('OrganizationEntityTypeID', '', [''], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            MCNumber: new Field('MCNumber', '', [''], false, 'text', {label: 'MCMXNumber'}, {
                max: 10,
            }),
            FFNumber: new Field('FFNumber', '', [''], false, 'text', {}, {
                max: 10,
            }),
            USDOTNumber: new Field('USDOTNumber', '', [''], false, 'text', {}, {
                max: 10,
            }),
            FederalID: new Field('FederalID', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                onFocus: (event) => {
                    setSelectionRange('FederalID', event.target.value)
                }
            }),
            NaicsID: new Field('NaicsID', '', [''], false, 'select'),
            SendPromotionalEmails: new Field('SendPromotionalEmails', '', [], false, 'checkbox'),

            // Card 3
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: "col-span-full"}),
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    getFieldTypes = () => {
        return Object.values(this.getFields()).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    getID = () => {
        return this.props.match.params.VendorID
    }

    handleCopyFromPhysicalAddressClick = () => {
        const fields = this.state.fields;

        fields.CountryID.value = fields.PhysicalCountryID.value;
        fields.AddressName.value = fields.PhysicalAddressName.value;
        fields.AddressName2.value = fields.PhysicalAddressName2.value;
        fields.CityName.value = fields.PhysicalCityName.value;
        fields.StateID.value = fields.PhysicalStateID.value;
        fields.PostalCode.value = fields.PhysicalPostalCode.value;

        this.setState({
            fields: fields,
            canSubmit: true
        })
    }

    handleCopyFromMailingAddressClick = () => {
        const fields = this.state.fields;

        fields.PhysicalCountryID.value = fields.CountryID.value;
        fields.PhysicalAddressName.value = fields.AddressName.value;
        fields.PhysicalAddressName2.value = fields.AddressName2.value;
        fields.PhysicalCityName.value = fields.CityName.value;
        fields.PhysicalStateID.value = fields.StateID.value;
        fields.PhysicalPostalCode.value = fields.PostalCode.value;

        this.setState({
            fields: fields,
            canSubmit: true
        })
    }

    getVendorContactGroup = () => {
        const info = getProp(this.props.resource, 'data', {})

        return {
            label: getProp(info, 'ContactGroup', ''),
            value: getProp(info, 'ContactGroupID', '')
        }
    }

    getFieldsToHtmlForIncludedFields = (arr = []) => {
        const selects = {
            AreaCode: getCountryCodes(),
            BankAccountTypeID: BANK_ACCOUNT_TYPES,
            NaicsID: getLookup('Naics'),
            PhysicalCountryID: getLookup('Country'),
            PhysicalStateID: getLookup('State')
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, arr)))), this.props.translate, this.handleInputChange, selects)
    }

    render() {
        const {translate, historyResource} = this.props;

        const fieldsHtmlBasicInfo = this.getFieldsToHtmlForIncludedFields(['LegalName', 'DBAName', 'ContactGroupID', 'OfficeID', 'UserDefinedField1', 'UserDefinedField2', 'Notes'])
        const fieldsHtmlAddresses = this.getFieldsToHtmlForIncludedFields(['CountryID', 'GooglePlaces', 'AddressName', 'AddressName2', 'CityName', 'StateID', 'PostalCode']);
        const fieldsHtmlPhysicalAddresses = this.getFieldsToHtmlForIncludedFields(['PhysicalCountryID', 'PhysicalGooglePlaces', 'PhysicalAddressName', 'PhysicalAddressName2', 'PhysicalCityName', 'PhysicalStateID', 'PhysicalPostalCode']);
        const fieldsHtmlOrganizationInfo = this.getFieldsToHtmlForIncludedFields(['AreaCode', 'PhoneNumber', 'PhoneExtension', 'PrimaryEmail', 'SCAC', 'OrganizationEntityTypeID', 'MCNumber', 'FFNumber', 'USDOTNumber', 'FederalID', 'NaicsID', 'SendPromotionalEmails'])

        return (
            <React.Fragment>
                {this.props.resource.isLoading && (
                    <Card>
                        <div className="text-center">
                            <LoaderSmall/>
                        </div>
                    </Card>
                )}

                {!this.props.resource.isLoading && (
                    <div className="pb-20">
                        <div className="flex justify-end items-center mb-2">
                            {
                                checkPerm(Resources.VendorHistory, READ_PERM) &&
                                <Tippy content={<span>{translate('text.view_history')}</span>}>
                                    <button
                                        type="button"
                                        onClick={() => this.setState({historyModalOpen: true})}
                                        className="btn btn-header"
                                    >
                                        {<HourglassIcon className="w-5 h-5"/>}
                                    </button>
                                </Tippy>
                            }
                        </div>

                        <div className="grid lg:grid-cols-2 3xl:grid-cols-3 gap-4">
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <div className="h-9 flex items-center">
                                        <CardSubTitle
                                            text={translate('text.BasicInfo')}
                                        />
                                    </div>

                                    <div className="grid grid-cols-12 gap-5">
                                        {fieldsHtmlBasicInfo}
                                    </div>
                                </Card>
                            </div>

                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <div className="h-9 flex items-center">
                                        <CardSubTitle
                                            text={translate('text.OrganizationInfo')}
                                        />
                                    </div>

                                    <div className="grid grid-cols-2 gap-5">
                                        {fieldsHtmlOrganizationInfo}
                                    </div>
                                </Card>
                            </div>

                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <div className="flex items-center justify-between">
                                        <CardSubTitle
                                            text={translate('text.MailingAddress')}
                                        />

                                        <Button onClick={this.handleCopyFromPhysicalAddressClick}>
                                            {translate('text.copyPhysicalAddress')}
                                        </Button>
                                    </div>

                                    <div className="grid grid-cols-12 gap-5">
                                        {fieldsHtmlAddresses}
                                    </div>
                                </Card>
                            </div>

                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <div className="flex items-center justify-between">
                                        <CardSubTitle
                                            text={translate('text.PhysicalAddress')}
                                        />

                                        <Button onClick={this.handleCopyFromMailingAddressClick}>
                                            {translate('text.copyMailingAddress')}
                                        </Button>
                                    </div>

                                    <div className="grid grid-cols-12 gap-5">
                                        {fieldsHtmlPhysicalAddresses}
                                    </div>
                                </Card>
                            </div>
                        </div>

                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleFormCancel}
                            actionSubmit={checkPerm(Resources.VendorsInfo, UPDATE_PERM) && this.onSubmit}
                        />
                    </div>
                )}
                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.VendorHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.props.match.params.OrganizationID
                                    }),
                                    resource: Resources.VendorHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                <GroupsViewDialog
                    groupID={this.state.fields?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}
