import Card from "../../card";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {LoaderLarge, LoaderSmall} from "../../loader";
import NoRecordsTable from "../../no-records-found/no-records-table";
import {Field} from "../../../../data/services/fields";
import ModalDefault from "../../modal/modal-default";
import ResourceTable from "../../resource-table"
import {toFrontDate} from "../../../util/util-dates";

const URL_WEEKLY_VIEW = 'https://api.eia.gov/v2/petroleum/pri/gnd/data/?api_key=qgNk2KY8tgCluY6mZv2yNaVwXMrmwa2zYUCffV50&frequency=weekly&data[0]=value&facets[series][]=EMD_EPD2D_PTE_NUS_DPG&sort[0][column]=period&sort[0][direction]=desc&offset=0&length=5'
const URL_MONTHLY_VIEW = 'https://api.eia.gov/v2/petroleum/pri/gnd/data/?api_key=qgNk2KY8tgCluY6mZv2yNaVwXMrmwa2zYUCffV50&frequency=monthly&data[0]=value&facets[series][]=EMD_EPD2D_PTE_NUS_DPG&sort[0][column]=period&sort[0][direction]=desc&offset=0&length=7'

const FuelPriceWidget = ({translate}) => {

    /** Helpers
     ================================================================= */
    const getFields = () => {
        return {
            period: new Field('period', '', [''], false, 'date'),
            value: new Field('value', '', [''], false, 'float'),
            difference: new Field('difference', '', [''], false, 'text', {
                render: (item) => calculatePercentageChange(item)
            }),
        }
    }

    const getMonthlyFields = () => {
        return {
            period: new Field('period', '', [''], false, 'date'),
            value: new Field('value', '', [''], false, 'float'),
            difference: new Field('difference', '', [''], false, 'text', {
                render: (item) => calculatePercentageChange(item)
            }),
        }
    }

    const calculatePercentageChange = (item) => {
        const currentState = monthlyViewModalOpen ? monthlyFuelData : weeklyFuelData
        const prevItem = currentState.find((it) => it.index === item.index + 1)

        const diff = item.value - prevItem.value
        const percentageDiff = (diff / prevItem.value) * 100

        const getTextColor = (value) => {
            if (value < 0) {
                return "text-green-500"
            } else if (value > 0) {
                return "text-red-500"
            }
            return ""
        }

        return (
            <div className={getTextColor(percentageDiff)}>
                {percentageDiff.toFixed(2)}
            </div>
        )
    }

    /** State
     ================================================================= */
    const [tableFields, setTableFields] = useState(getFields())
    const [monthlyViewTableFields, setMonthlyViewTableFields] = useState(getMonthlyFields())
    const [monthlyViewModalOpen, setMonthlyViewModalOpen] = useState(false)

    const [fuelData, setFuelData] = useState([]);
    const [weeklyFuelData, setWeeklyFuelData] = useState([])
    const [monthlyFuelData, setMonthlyFuelData] = useState([])

    const [isWeeklyDataLoading, setIsWeeklyDataLoading] = useState(true)
    const [errorWeeklyData, setErrorWeeklyData] = useState(false)
    const [errorMonthlyData, setErrorMonthlyData] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        async function fetchFuelData() {
            setIsWeeklyDataLoading(true)
            try {
                const response = await axios.get(URL_WEEKLY_VIEW)
                setFuelData(response.data.response.data)
            } catch (err) {
                console.log(err)
                setErrorWeeklyData(true)
            } finally {
                setIsWeeklyDataLoading(false)
            }
        }

        if (!monthlyViewModalOpen) {
            fetchFuelData()
        }
    }, [monthlyViewModalOpen]);

    useEffect(() => {
        async function fetchMonthlyFuelData() {
            try {
                const response = await axios.get(URL_MONTHLY_VIEW)
                setFuelData(response.data.response.data)
            } catch (err) {
                console.log(err)
                setErrorMonthlyData(true)
            }
        }

        if (monthlyViewModalOpen) {
            fetchMonthlyFuelData()
        }
    }, [monthlyViewModalOpen])

    useEffect(() => {
        if (!!fuelData.length) {
            if (!monthlyViewModalOpen) {
                let counter = 0
                setWeeklyFuelData(fuelData.map((item) => {
                    item['index'] = counter
                    counter++
                    return item
                }))
            } else {
                let counter = 0
                setMonthlyFuelData(fuelData.map((item) => {
                    item['index'] = counter
                    counter++
                    return item
                }))
            }
        }
    }, [fuelData])

    useEffect(() => {
        setTableFields(getFields())
    }, [weeklyFuelData])

    useEffect(() => {
        setMonthlyViewTableFields(getMonthlyFields())
    }, [monthlyFuelData])

    /** Body
     ================================================================= */
    return (
        <>
            <Card addClass={"min-w-[380px]"}>
                <div className={"flex justify-between"}>
                    <h1 className={"text-tm-white-700 text-base"}>Fuel price</h1>
                    <button className={`btn btn-primary`} disabled={!fuelData.length}
                            onClick={() => setMonthlyViewModalOpen(true)}>Detailed history
                    </button>
                </div>

                {isWeeklyDataLoading && (
                    <LoaderSmall disableContainer={true}/>
                )}

                {errorWeeklyData && (
                    <p>
                        An error occured when trying to get fuel prices data. Please try again later.
                    </p>
                )}

                {!errorWeeklyData && !isWeeklyDataLoading && (
                    <div>
                        <div className={"border-b-2"}>
                            <span className={"text-5xl font-bold"}>{fuelData[0].value}</span> <span
                            className={"text-2xl"}>$/GAL</span>
                            <p className={"text-gray-400 pb-3"}>Latest fuel price (last
                                updated: {toFrontDate(fuelData[0].period)})</p>
                        </div>
                        <div className={"pt-4"}>
                            <ResourceTable
                                data={weeklyFuelData.slice(0, 4)}
                                fields={tableFields}
                                translate={translate}
                            />
                        </div>
                    </div>
                )}
            </Card>

            <ModalDefault
                show={monthlyViewModalOpen}
                title={translate("page_heading.fuel_price_detailed")}
                widthClass={"max-w-[1440px]"}
                onClose={() => setMonthlyViewModalOpen(false)}
                closeButtonLabel={translate("btn.close")}
                translate={translate}
            >
                {!errorMonthlyData && !monthlyFuelData.length && (
                    <LoaderLarge/>
                )}

                {errorMonthlyData && (
                    <NoRecordsTable
                        show={true}
                        title={'An error occured when trying to get fuel prices data. Please try again later.'}
                    />
                )}

                {!errorMonthlyData && !!monthlyFuelData.length && (
                    <ResourceTable
                        data={monthlyFuelData.slice(0, 6)}
                        fields={monthlyViewTableFields}
                        translate={translate}
                    />
                )}
            </ModalDefault>
        </>
    )
}

export default FuelPriceWidget