import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';
import {getCustomStyles} from "../../../../data/themes";
import {getProp} from "../../../util/util-helpers";
import {scrollIntoView} from "../../../util/util-vanilla";

export default class CreatableMulti extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: ""
        }
    }

    createOption = (label) => {
        return ({
            label,
            value: label,
            manual: true,
        });
    }

    handleInputChange = (inputValue) => {
        this.setState({inputValue});
    };

    submitOption = (e) => {
        if (!e.target.value) return;

        this.props.onChange(this.props.name, [...getProp(this.props, "value", []), this.createOption(e.target.value)]);

        this.setState({
            inputValue: ""
        })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.submitOption(e)
        }
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs).sort((a, b) => {
            return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
        }).map((key, i) => ({value: key, label: pairs[key]}));
        return (
            <>
                <CreatableSelect
                    isDisabled={this.props.isDisabled}
                    name={this.props.name}
                    isClearable={!!this.props.isClearable}
                    onCreateOption={this.props.onCreateOption ? (e) => this.props.onCreateOption(this.props.name, e) : null}
                    styles={getCustomStyles()}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select"}
                    isMulti={this.props.isMulti}
                    options={values}
                    defaultValue={this.props.value}
                    onChange={(selectedOptions) => {
                        this.props.onChange(this.props.name, selectedOptions);
                    }}
                    inputValue={this.state.inputValue}
                    onInputChange={this.handleInputChange}
                    value={this.props.value}
                    closeMenuOnSelect={false}
                    className={"select-css-search p-0 " + (this.props.addClass ? " " + this.props.addClass : "")}
                    components={this.props.components}
                    menuIsOpen={this.props.menuIsOpen}
                    onKeyDown={this.onKeyDown}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    onBlur={this.submitOption}
                    menuPlacement={this.props.menuPlacement}
                    menuPortalTarget={this.props.hasPortal ? document.body : null}
                />
                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold"}>
                        {!!this.props.translate && (
                            this.props.errorMessage.reduce((memo, it) => {
                                return this.props.translate(it) + (!!memo ? "," + memo : "")
                            }, "")
                        )}
                        {!this.props.translate && (
                            <span>Field required*</span>
                        )}
                    </div>
                )}
            </>
        );
    }
}
