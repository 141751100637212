import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import AddEmploymentDialog from "./add-employment-dialog";
import InfoParagraph from "../../../components/info-paragraph";
import NoRecordsTable from "../../no-records-found/no-records-table";
import ResourceTable from "../../resource-table";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import PageHeader from "../../layout/layout-components/page/page-header";
import Card from "../../card";
import TableCard from "../../resource-table/table-components/table-card";
import Modal from "../../modal";
import ModalConfirm from "../../modal/modal-confirm";
import {
    checkEmploymentReleaseDate,
    checkPerm, getDefaultTableOptions, getDriverBadgeClass,
    getProp, longTableColumn,
    resourceIsCreated, resourceIsDeleted,
    resourceIsUpdated
} from '../../../util/util-helpers'
import {CREATE_PERM} from "../../../util/util-consts";
import {getResource, deleteResource, updateResource} from "../../../../data/actions/resource";
import {Field} from "../../../../data/services/fields";
import Resources from "../../../../data/services/resources";
import {getSecondResource, updateSecondResource} from "../../../../data/actions/secondResource";
import ModalSaveResource from "../../modal/modal-save-resource";
import Button from "../../button";
import Badge from "../../badge";
import Tippy from "@tippyjs/react";
import {UPDATE_PERM} from "../../../util/util-consts";
import DriverStatusBadge from "../../badge/driver-status-badge";
import {LoaderSmall} from "../../loader";
import { classNames } from "../../../util/util-helpers";
import {getInfoResource} from "../../../../data/actions/infoResource";


export default class EmploymentTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            id: null,
            item: null,
            previousItem: null,
            followingItem: null,
            lastHireDate: '',
            lastReleaseDate: '',

            fields: this.getFields(),
            confirmSetDriverToPendingModal: false,

            suspendModalOpen: false,
            suspendFields: this.getSuspendFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData();
        if (this.props.resourceName === Resources.DriverEmployments) {
            this.fetchDriverInfo()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsUpdated(this.props.secondResource, prevProps.secondResource) && (this.props.secondResource.resource === Resources.DriversSuspend)) {
            this.fetchInfo();
            this.fetchData();
        }
        if (
            this.props.resourceName === Resources.DriverEmployments && (
            resourceIsCreated(this.props.resource, prevProps.resource) || resourceIsDeleted(this.props.resource, prevProps.resource) || resourceIsUpdated(this.props.resource, prevProps.resource))) {
            this.fetchDriverInfo();
            this.fetchInfo();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResource(),
            query: {
                id: this.getID()
            },
        }));
    };

    fetchInfo = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: this.getID()}
        }));
    };

    fetchDriverInfo = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.DriverInfo,
            query: {id: this.getID()}
        }));
    }

    /** UI Events
     ================================================================= */
    handleToggleCreateUpdateModal = (item = null) => {
        const data = getProp(this.props.resource.data, 'list', [])

        if (item) {
            const index = data.findIndex(it => it[this.getPrimaryKey()] == item[this.getPrimaryKey()])

            this.setState({
                selectedItem: item,
                lastHireDate: data.length > 0 ? data[data.length - 1].HireDate : '',
                lastReleaseDate: data.length > 0 ? data[data.length - 1].ReleaseDate : '',
                previousItem: index !== 0 ? data[index - 1] : null,
                followingItem: (index - 1 && index - 1 > 0) < (data.length) ? data[index + 1] : null,
                createUpdateDialog: !this.state.createUpdateDialog,
            })
        } else {
            this.setState({
                lastReleaseDate: data.length > 0 ? data[data.length - 1].ReleaseDate : '',
                selectedItem: item,
                createUpdateDialog: !this.state.createUpdateDialog,
            })
        }
    }

    handleCloseCreateUpdateDialog = () => {
        this.setState({
            selectedItem: null,
            lastHireDate: '',
            previousItem: null,
            followingItem: null,
            createUpdateDialog: false
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleCloseDriverPendingConfirmModal = () => {
        this.setState({
            confirmSetDriverToPendingModal: false
        })
    }

    handleToggleSuspendModal = () => {
        this.setState({suspendModalOpen: !this.state.suspendModalOpen})
    }

    getSuspendFields = () => {
        return {
            SuspendedNotes: new Field('SuspendedNotes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'})
        }
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            EmploymentTypeID: new Field('EmploymentTypeID', '', ['empty'], false, 'select', {
                hideTable: this.props.hideEmploymentType
            }),
            HireDate: new Field('HireDate', '', ['empty'], false, 'date'),
            ReleaseDate: new Field('ReleaseDate', '', ['empty'], false, 'date'),
            ContractNum: new Field('ContractNum', '', ['empty'], false, 'text'),
            Notes: new Field('Notes', '', ['empty'], false, 'textarea',  {
                render: (item) => (
                    <div>{longTableColumn(item.Notes)}</div>
                )
            }),
        }
    }

    getResource = () => {
        return this.props.resourceName ?? ""
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? ""
    }

    getID = () => {
        return this.props.id ?? ""
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, pageTitle, secondResource = {}} = this.props;
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const IsSuspended = getProp(secondResource.data, 'IsSuspended', 0)
        const IsPending = getProp(secondResource.data, 'IsPending', 0)
        const driverStatusID = getProp(secondResource.data, 'StatusID', '')
        const onLoadID = getProp(secondResource.data, 'OnLoadID', '')
        const secondResourceIsLoading = getProp(secondResource, 'isLoading', false)

        const isDriverTerminated = driverStatusID === 8

        return (
            <React.Fragment>
                <PageHeader
                    title={pageTitle ?? translate("page.heading.Employments")}
                    titleClass="mr-5 text-2xl ml-4 mb-4"
                    buttonsClassName={"ml-auto space-x-4 flex items-center mb-4"}
                    buttonDisabled={driverStatusID === 8}
                    buttonLabel={translate("btn.create_new")}
                    onButtonClick={() => this.handleToggleCreateUpdateModal()}
                    buttonHidden={!!resource.isLoading || !checkPerm(this.getResource(), CREATE_PERM) || !checkEmploymentReleaseDate(getProp(resource.data, 'list', []))}
                >

                    {resource.isLoading || secondResourceIsLoading && (
                        <div className="relative w-8 h-8">
                            <LoaderSmall/>
                        </div>
                    )}

                    {this.props.resourceName === Resources.DriverEmployments && (!secondResourceIsLoading && !resource.isLoading) && (
                        <React.Fragment>
                            <Tippy
                                content={driverStatusID !== 5 ? translate('text.set_driver_pending_disabled') : translate('text.set_driver_pending')}>
                                <span>
                                    <Button appearance={'muted'}
                                            onClick={() => this.setState({confirmSetDriverToPendingModal: true})}
                                            disabled={driverStatusID !== 5}
                                            hasPerm={checkPerm(Resources.DriversPending, UPDATE_PERM)}
                                    >
                                        Set driver as pending
                                    </Button>
                                </span>
                            </Tippy>

                        </React.Fragment>
                    )}

                    {this.props.resourceName === Resources.DriverEmployments && !secondResourceIsLoading && (
                        <Button
                            hasPerm={checkPerm(Resources.DriversSuspend, UPDATE_PERM)}
                            disabled={isDriverTerminated || IsPending}
                            onClick={this.handleToggleSuspendModal}
                            title={"text"}
                            className={'flex items-center justify-center p-1 cursor-pointer border-none'}>
                            {IsSuspended ?
                                <Badge addClass="h-8 items-center"
                                       type={getDriverBadgeClass(1)}>
                                    <div className="flex">
                                        Unsuspend driver
                                    </div>
                                </Badge>
                                :<Tippy
                                    content={IsPending ? translate('text.cant_suspend_pending') : ''}
                                    disabled={!IsPending}>
                                    <div>
                                            <Badge addClass="h-8 items-center"
                                                   type={(isDriverTerminated || IsPending) ? "disabled" : getDriverBadgeClass(5)}>
                                                    <div className="flex">
                                                        Suspend driver
                                                    </div>
                                            </Badge>
                                    </div>
                                </Tippy>
                            }
                        </Button>
                    )}

                </PageHeader>


                <Card>
                    <div className={"flex justify-between"}>
                        <InfoParagraph message={translate("message.EmploymentTabMessage")}/>

                        <button className="btn btn-header ml-4" onClick={() => {
                            this.fetchData()
                            if (this.props.resourceName === Resources.DriverEmployments) this.fetchDriverInfo()
                        }}>
                            <ArrowPathIcon className={classNames(
                                "w-5 h-5",
                                resource.isLoading || secondResourceIsLoading ? "animate-spin" : undefined,
                            )}/>
                        </button>
                    </div>

                    {driverStatusID === 8 && (
                        <InfoParagraph type="warning" className=""
                                       message={translate("message.driver_is_terminated_please_undo")}/>
                    )}

                    {!checkEmploymentReleaseDate(getProp(resource.data, 'list', [])) && (
                        <InfoParagraph className="" message={translate("message.EmploymentTabCreateNewMessage")}/>
                    )}

                    <TableCard>
                        <ResourceTable
                            addClass="rounded-card"
                            data={data}
                            count={count}

                            options={this.state.tableOptions}
                            fields={this.state.fields}
                            translate={translate}
                            isLoading={resource.isLoading}

                            onEdit={this.handleToggleCreateUpdateModal}
                            onDelete={driverStatusID !== 8 && this.handleToggleConfirmModal}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={false}
                            title={translate("text.no_matching_records")}
                            className={"pb-12 pt-16 px-6"}
                        />
                    </TableCard>
                </Card>

                <Modal
                    show={!!this.state.createUpdateDialog}
                    widthClass={"max-w-7xl w-screen"}
                    closeButtonLabel={translate("btn.close")}
                    onClose={this.handleCloseCreateUpdateDialog}
                    translate={translate}
                >
                    <AddEmploymentDialog
                        driverStatusID={driverStatusID}
                        onClose={this.handleCloseCreateUpdateDialog}
                        lastReleaseDate={this.state.lastReleaseDate}
                        lastHireDate={this.state.lastHireDate}
                        followingItem={this.state.followingItem}
                        previousItem={this.state.previousItem}
                        selectedItem={this.state.selectedItem}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        primaryKey={this.getPrimaryKey()}
                        resourceName={this.getResource()}
                        id={this.getID()}
                        title={pageTitle ? translate(this.state.selectedItem ? "header.UpdateWorkToHire" : "header.CreateWorkToHire") : null}
                        hideEmploymentType={this.props.hideEmploymentType}
                    />
                </Modal>

                <ModalConfirm
                    title={translate("Confirm")}
                    show={!!this.state.confirmDialog}
                    text={translate("message.are_you_sure_delete_employment")}
                    onClose={() => this.handleToggleConfirmModal()}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={translate('Cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                                id: this.getID()
                            },
                            errorMessage: true, successMessage: translate("message.EmploymentDeleted"),
                            resource: this.getResource(),
                            piggyResource: this.getResource()
                        }))
                        this.handleToggleConfirmModal()
                    }}
                />

                <ModalConfirm
                    title={translate("Confirm")}
                    show={this.state.confirmSetDriverToPendingModal}
                    text={translate("message.confirm_set_driver_pending")}
                    onClose={this.handleCloseDriverPendingConfirmModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={translate('Cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: {
                                DriverID: this.getID()
                            },
                            errorMessage: true, successMessage: translate("message.DriverSetToPending"),
                            resource: Resources.DriversPending,
                            piggyResource: Resources.DriverInfo,
                            query: {
                                id: this.getID()
                            },
                        }))
                        this.handleCloseDriverPendingConfirmModal()
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!(this.state.suspendModalOpen && IsSuspended)}
                    text={"Are you sure you want to set this driver to active state?"}
                    onClose={this.handleToggleSuspendModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(updateSecondResource({
                            user: LocalStorage.get('user'),
                            params: {IsSuspended: 0, DriverID: this.getID()},
                            resource: Resources.DriversSuspend,
                            piggyResource: Resources.DriverInfo,
                            query: {id: this.getID()},
                            errorMessage: true,
                            successMessage: "Driver set back to active state successfully!"
                        }))
                        this.handleToggleSuspendModal()
                    }}
                />

                <ModalSaveResource
                    title={'Set driver as suspended'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.suspendModalOpen && !IsSuspended}
                    onClose={this.handleToggleSuspendModal}
                    fields={this.getSuspendFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.IsSuspended = IsSuspended ? 0 : 1
                            params.DriverID = this.getID()
                            this.props.dispatch(updateSecondResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.DriversSuspend,
                                piggyResource: Resources.DriverInfo,
                                query: {id: this.getID()},
                                errorMessage: true,
                                successMessage: "Driver suspended successfully!"
                            }))
                            this.handleToggleSuspendModal()
                        }
                    }}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}
