import React, {useEffect, useRef, useState} from "react";
import {DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS} from "../../../../common/util/util-consts";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import {classNames, getDocumentTypesFor} from "../../../../common/util/util-helpers";
import CardSubTitle from "../../../../common/components/card/card-sub-title";
import {EyeIcon, TrashIcon} from "@heroicons/react/24/outline";
import FieldCheckbox from "../../../../common/components/fields/field-checkbox";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {uploadDocument} from "../../../../data/actions/download";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {useDispatch} from "react-redux";

export default function LoadsFileUpload({translate, fileToUpload, query, inputFileRef}) {
    const areCheckboxesTouched = useRef(false);
    const dispatch = useDispatch();


    const [files, setFiles] = useState([]);
    const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] = useState(false);
    const [willCopyValues, setWillCopyValues] = useState(true);
    // const [canSubmit, setCanSubmit] = useState(false);

    function handleUploadDocument() {
        const validatedFiles = files.map((file) => Object.assign(file, {
            fields: FieldsManager.validateFields(file.fields)
        }))
        if (validatedFiles.reduce((memo, file) => (memo && FieldsManager.checkFieldsForErrors(file.fields)), true)) {
            const files = validatedFiles.map(file => Object.assign(file, {
                params: Object.assign({}, FieldsManager.getFieldKeyValues(file.fields), {
                    Version: 0
                })
            }))

            dispatch(uploadDocument({
                user: LocalStorage.get("user"),
                files: files,
                id: fileToUpload.LoadID,
                query: Object.assign({}, {
                    id: fileToUpload?.LoadID,
                }, query),
                resource: Resources.LoadDocuments,
                errorMessage: true,
                successMessage: `Attachment${files.length > 1 ? "s" : ""} ${files.length > 1 ? "have" : "has"} been uploaded successfully.`,
                piggyResource: Resources.Loads
            }))
            handleCancelUpload();
        } else {
            setFiles(validatedFiles);
        }
    }

    function onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();

        setFiles(Object.values(event.target.files).map((file, index) => Object.assign(file, {
            preview: URL.createObjectURL(file),
            errorMessage: '',
            fields: Object.assign({}, getDocumentUploadFields(index, file))
        })));

        setIsUploadFilesModalOpen(true);
    }

    function handleCancelUpload() {
        setIsUploadFilesModalOpen(false);
        setFiles([]);
    }

    function handleRemoveDocumentFromUpload(index) {
        files.splice(index, 1)
        if (files.length === 0) {
            handleCancelUpload();
        } else {
            setFiles(files)
        }
    }
    
    function handleUploadInputChange(name, value, i) {
        let filesUpdate = [...files];
        filesUpdate[i].fields = FieldsManager.updateField(files[i].fields, name, value)
        let updateIsVisibleForCustomer = false;

        if (!i && name === "IsSendWithInvoice") {
            // In value is initialy false, checking IsSendWithInvoice will also check IsVisibleForCustomer
            if (!areCheckboxesTouched.current && value) {
                filesUpdate[i].fields.IsVisibleForCustomer.value = value;
                updateIsVisibleForCustomer = true;
            }

            areCheckboxesTouched.current = true;
        }

        if (!i && willCopyValues) {
            filesUpdate = filesUpdate.map((file, fileIndex) => {
                if (fileIndex) {
                    file.fields[name].value = value;

                    if (updateIsVisibleForCustomer) {
                        file.fields.IsVisibleForCustomer.value = value;
                    }
                }

                return file
            });
        }

        setFiles(filesUpdate);
    }

    function getDocumentUploadFields(index = 0, file) {
        let fields;
        const DocumentTypeID = file.type

        fields = {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {}, {
                values: getDocumentTypesFor("IsDispatchDocument")
            }),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {}),
            IsSendWithInvoice: new Field("IsSendWithInvoice", '', [], false, 'checkbox', {}, {
                htmlFor: `IsSendWithInvoice${index}`,
                id: `IsSendWithInvoice${index}`,
            }),
            IsVisibleForCustomer: new Field("IsVisibleForCustomer", '', [], false, 'checkbox', {}, {
                htmlFor: `IsVisibleForCustomer${index}`,
                id: `IsVisibleForCustomer${index}`,
            }),
            Description: new Field("Description", '', [], false, 'textarea', {}),
        }

        if (!!DocumentTypeID && !!fields.DocumentTypeID) {
            fields.DocumentTypeID.value = DocumentTypeID;
        }

        return fields;
    }

    return (
        <>
            <input
                type='file'
                multiple
                accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                ref={inputFileRef}
                style={{display: 'none'}}
                onChange={onChangeFile}
                onClick={event => event.target.value = null}
            />

            <ModalDefault
                show={isUploadFilesModalOpen}
                widthClass={'max-w-5xl'}
                title={translate("modal_heading.upload_confirm")}
                limitHeight={true}

                closeButtonLabel={translate('btn.cancel')}
                onClose={handleCancelUpload}

                buttonDisabled={!files.length}
                buttonLabel={translate('Upload')}
                onButtonClick={handleUploadDocument}
            >
                <div className="px-5 pt-5 pb-40">
                    {files.map((it, i) => {
                        const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, it.fields)), translate, (name, value) => handleUploadInputChange(name, value, i), {
                            DocumentTypeID: getDocumentTypesFor("IsDispatchDocument")
                        })

                        return (
                            <div key={i}>
                                {!!i && (
                                    <div className={'border-b border-tm-gray-300 mb-4 p-4 -ml-4 -mr-4'}/>
                                )}

                                <div className="flex items-center mb-4 justify-between">
                                    <div
                                        className="flex items-center text-sm font-semibold text-tm-gray-900 break-all"
                                    >
                                        {files.length > 1 && (
                                            <div
                                                className='w-10 h-10 mr-2 rounded-full flex items-center justify-center bg-tm-gray-50'>
                                                <CardSubTitle
                                                    text={i + 1}
                                                />
                                            </div>
                                        )}

                                        <label>{translate("text.desc_for_file")}: {files?.[i]?.name}</label>
                                    </div>
                                    <div className="text-right">
                                        <button
                                            className="btn btn-icon"
                                            title={translate('text.preview')}
                                            onClick={() => this.showPreviewModal(it)}
                                        >
                                            <EyeIcon className="h-4 w-4"/>
                                        </button>

                                        <button
                                            className="btn btn-icon"
                                            title={translate('text.delete')}
                                            onClick={() => handleRemoveDocumentFromUpload(i)}
                                        >
                                            <TrashIcon className="h-4 w-4"/>
                                        </button>
                                    </div>
                                </div>

                                <div className="grid gap-4">
                                    {files.length > 1 && !i && (
                                        <label
                                            className="h-9 flex items-center justify-start px-2 -ml-2 w-full cursor-pointer hover:bg-tm-gray-50"
                                        >
                                            <FieldCheckbox
                                                className={
                                                    classNames(
                                                        "checkbox",
                                                        willCopyValues
                                                            ? "border-primary"
                                                            : "border-tm-gray-400"
                                                    )
                                                }
                                                onChange={() => setWillCopyValues(!willCopyValues)}
                                                value={willCopyValues}
                                            />

                                            <div
                                                className="ml-2 flex flex-col text-sm font-semibold select-none text-tm-gray-900"
                                            >
                                                {translate("text.copy_to_other_fields")}
                                            </div>
                                        </label>
                                    )}

                                    {itemToHtml}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ModalDefault>
        </>

    )
}
