import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateCommentResource,
    doneGetCommentResource,
    doneUpdateCommentResource,
    errorCreateCommentResource,
    errorDeleteCommentResource,
    errorUpdateCommentResource
} from "../actions/commentResource";
import { pushNotification } from "../actions/ui";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* createCommentResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);

            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetCommentResource(resPiggy.data));
                yield put(doneCreateCommentResource(result.data));
            }
        } else {
            yield put(doneCreateCommentResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateCommentResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateCommentResource() {
    yield takeLatest('CREATE_COMMENT_RESOURCE', createCommentResourceCall);
}


export function* deleteResourceCommentContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetCommentResource(resPiggy.data));
            }
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteCommentResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchDeleteCommentResource() {
    yield takeLatest('DELETE_COMMENT_RESOURCE', deleteResourceCommentContactCall);
}

export function* updateCommentResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);

            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetCommentResource(resPiggy.data));
                yield put(doneUpdateCommentResource(result.data));
            }
        } else {
            yield put(doneUpdateCommentResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }

    } else {
        yield put(errorUpdateCommentResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateCommentResource() {
    yield takeLatest('UPDATE_COMMENT_RESOURCE', updateCommentResourceCall);
}