import React, {Component} from 'react';
import Days from '../day-select';
import Month from '../month-select';
import Hour from "../hour-select";
import Minutes from "../minutes-select";

export default class YearlyCron extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onAtHourChange = this.onAtHourChange.bind(this);
        this.onAtMinuteChange = this.onAtMinuteChange.bind(this);
        this.onAtMonthChange = this.onAtMonthChange.bind(this);
        this.onAtDayChange = this.onAtDayChange.bind(this);
    }

    componentWillMount() {
        this.state.value = this.props.value;
    }

    onAtHourChange(e) {
        let val = [this.state.value[0], '*', this.state.value[2], this.state.value[3], '*']
        val[1] = e.target.value ? `${e.target.value}` : '0';
        this.props.onChange(val);
    }

    onAtMinuteChange(e) {
        let val = ['*', this.state.value[1], this.state.value[2], this.state.value[3], '*']
        val[0] = e.target.value ? `${e.target.value}` : '0';
        this.props.onChange(val);
    }

    onAtMonthChange(e) {
        let val = [this.state.value[0], this.state.value[1], this.state.value[2], '*', '*']
        val[3] = e.target.value ? `${e.target.value}` : '1';
        this.props.onChange(val);
    }

    onAtDayChange(e) {
        let val = [this.state.value[0], this.state.value[1], '*', this.state.value[3], '*']
        val[2] = e.target.value ? `${e.target.value}` : '1';
        this.props.onChange(val);
    }

    render() {
        const translateFn = this.props.translate;
        this.state.value = this.props.value
        return (
            <div className="my-2 py-6 flex flex-col justify-start space-y-3">

                <div className="flex justify-between items-center">
                    <span className="text-sm font-semibold text-tm-gray-900">{translateFn('Month')}</span>
                    <Month onChange={this.onAtMonthChange}
                           value={this.state.value[3]}/>
                </div>

                <div className="my-3 flex justify-between items-center">
                    <span className="text-sm font-semibold text-tm-gray-900">{translateFn('Day')}</span>
                    <Days onChange={this.onAtDayChange}
                          value={this.state.value[2]}/>
                </div>

                <div className="flex justify-between items-center">
                    <span className="text-sm font-semibold text-tm-gray-900">{translateFn('At')}</span>

                    <div className="space-x-3">
                        <Hour disabled={this.state.every} onChange={this.onAtHourChange}
                              value={this.state.value[1]}/>

                        <Minutes disabled={this.state.every} onChange={this.onAtMinuteChange}
                                 value={this.state.value[0]}/>
                    </div>
                </div>
            </div>
        )
    }
}

