import React, {useEffect} from 'react'
import FileViewer from "../../file-viewer/components"
import DocumentExpiryDate from "../../badge/expiery-date-badge";
import ModalDefault from "../../modal/modal-default";
import {getProp} from "../../../util/util-helpers";
import {getJWT} from "../../../util/util-auth";
import Env from "../../../../util/env";


const DocumentPreviewModal = ({
                                  show,
                                  translate,
                                  item,
                                  onButtonClick,
                                  buttonLabel,
                                  closeButtonLabel,
                                  onClose,
                                  id,
                                  primaryKey,
                                  resourceName,
                                  fileType,
                                  isLoading,
                                  onNextDocumentPreviewClick,
                                  onPreviousDocumentPreviewClick,
                                  leftButtons,
                              }) => {
    const handlePreviewDocumentSlide = event => {
        if (event.keyCode === 37) onPreviousDocumentPreviewClick()
        if (event.keyCode === 39) onNextDocumentPreviewClick()
    }

    useEffect(() => {
        if (show) document.addEventListener("keydown", handlePreviewDocumentSlide, false)
        if (!show) document.removeEventListener("keydown", handlePreviewDocumentSlide, false)
    }, [show])

    const type = fileType ?? item?.DocumentName.split('.').pop()
    const path = Env.getApiUrl("api/" + resourceName, Object.assign({}, {
        [primaryKey]: getProp(item, primaryKey, ''),
        token: getJWT().access_token,
        id: id
    }));

    return (
        <ModalDefault
            show={show}
            widthClass={"max-w-7xl"}
            limitHeight={true}
            title={translate("text.FilePreview")}
            isLoading={isLoading}

            onButtonClick={onButtonClick}
            buttonLabel={buttonLabel}

            leftButtons={leftButtons}

            closeButtonLabel={closeButtonLabel}
            onClose={onClose}
        >
            {item && (
                <div className={'p-4 max-h-[calc(100vh-11rem)] overflow-auto'}>
                    <div className={"block overflow-auto"}>
                        <FileViewer
                            fileType={type}
                            filePath={path}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    </div>
                    <div className="w-full border-t border-tm-gray-300 mt-2.5 pb-2.5"/>

                    {!!item.Version && (
                        <>
                            <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("field.Version")}</label>
                            <div className={'break-all'}>{item.Version}</div>
                            <div className="w-full border-t border-tm-gray-300 mt-2.5 pb-2.5"/>
                        </>
                    )}

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("text.OriginalFilename")}</label>
                    <div className={'break-all'}>{item.OriginalFilename}</div>
                    <div className="w-full border-t border-tm-gray-300 mt-2.5 pb-2.5"/>

                    <label
                        className="font-bold text-tm-gray-500 pl-0 pt-2 break-all">{translate("text.FileDescription")}</label>
                    <div className={'break-all'}>{item.Description}</div>
                    <div className="w-full border-t border-tm-gray-300 mt-2.5 pb-2.5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("text.DocumentType")}</label>
                    <div>{item.DocumentType}</div>
                    <div className="w-full border-t border-tm-gray-300 mt-2.5 pb-2.5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("text.UpdatedBy")}</label>
                    <span
                        className={'px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full ml-5'}>
                        {item.UpdatedByContact ?? translate("text.system")}
                    </span>
                    <div className="w-full border-t border-tm-gray-300 mt-2.5 pb-2.5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("text.ExpiryDate")}</label>
                    <span className="ml-5">{DocumentExpiryDate(item)}</span>
                </div>
            )}
        </ModalDefault>
    )
}

export default DocumentPreviewModal
