import React from "react";
import PencilAltOutlineIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import {classNames} from "../../util/util-helpers";
import ButtonIcon from "../button/button-icon";
import Tooltip from "../tooltip";

export default function InnerTable({
                                       data,
                                       prevRowCount = undefined,
                                       heightsRef,
                                       tableIndex,
                                       setHeights,
                                       tableOptions,
                                       comments,
                                       onDayCommentClick,
                                       closesMatchData,
                                       fields
                                   }) {
    const driverData = Object.values(data?.[tableIndex]?.driver ?? {})
    const columns = Object.values(fields);
    const filteredColumns = columns.filter(col => !tableOptions.columns?.[col.name]?.hidden);

    const startWithOddRowStripe = prevRowCount?.driver?.length % 2;

    function getRowRef(ref, rowID) {
        if (heightsRef?.current && ref?.offsetHeight) {
            heightsRef.current[rowID] = ref?.offsetHeight;
        }
    }

    function setRowStyle(rowId) {
        if (setHeights?.[rowId]) {
            return {height: setHeights?.[rowId] + "px"}
        }
    }

    return <div
        className="flex flex-col shrink-0"
    >
        {
            driverData.map((driver) => {
                return (
                    <div
                        ref={(ref) => getRowRef(ref, driver.DriverID)}
                        key={driver.DriverID}
                        className={
                            classNames(
                                "flex",
                                startWithOddRowStripe === 0 ? " even:bg-inverse odd:bg-tm-gray-50" : " odd:bg-inverse even:bg-tm-gray-50"
                            )
                        }
                        style={setRowStyle(driver.DriverID)}
                    >
                        {filteredColumns.map((col, i) => {
                            const commentCount = comments[col?.name]?.[driver?.DriverID]?.length;
                            const hasEvents = !!driver.Schedule[col?.name]?.length;
                            const hasMileageDiff = col.name === closesMatchData?.TargetDate;

                            return <div
                                className={
                                    classNames(
                                        "cc-cell group col-" + col.name,
                                        i ? "p-0.5" : "pl-4 py-1 pr-1"
                                    )
                                }
                                key={col.name}
                            >
                                <div className="flex justify-center flex-col gap-0.5 h-full w-full relative">
                                    {col?.metadata?.render ? col.metadata.render(driver) : driver[col.name]}

                                    {!!commentCount && (
                                        comments[col?.name]?.[driver?.DriverID].map(it => {
                                            return <Tooltip
                                                key={it.ScheduleCommentID}
                                                disabled={!it?.Comment}
                                                content={<div className="whitespace-pre"
                                                >
                                                    {it?.Comment}
                                                </div>}>
                                                <button
                                                    onClick={() => onDayCommentClick(col.name, driver, it)}

                                                    className={
                                                        classNames(
                                                            "relative flex h-full w-full flex-col overflow-y-auto justify-center rounded-sm py-0.5 px-2 text-xs leading-4",
                                                            it.ColorTag === "#292524" ? "hover:text-white" : undefined
                                                        )
                                                    }>

                                                    <div
                                                        style={{background: it.ColorTag}}
                                                        className={"hover:opacity-80 absolute z-0 inset-0 rounded-sm opacity-50"}
                                                    />

                                                    <div className={
                                                        classNames(
                                                            "relative z-1 pointer-events-none text-left",
                                                        )}
                                                    >
                                                        {!tableOptions.compressedView && (
                                                            <div>
                                                                {[it.CityName, it.State + " " + it.PostalCode].filter(it => !!it).join(", ")}
                                                                <div className="h-4 w-full truncate">{it.Comment}</div>
                                                            </div>
                                                        )}

                                                        {!!tableOptions.compressedView && (
                                                            [it.CityName, it.State].filter(it => !!it).join(", ")
                                                        )}
                                                    </div>
                                                </button>
                                            </Tooltip>
                                        })
                                    )}
                                </div>
                                {
                                    col.metadata.hasCommentAction && (hasEvents || commentCount || hasMileageDiff) && (
                                        <div className="hidden group-hover:block absolute bottom-0 right-0">
                                            <ButtonIcon
                                                onClick={() => onDayCommentClick(col.name, driver)}
                                                className="btn-icon p-1 bg-primary text-primary-contrast"
                                            >
                                                <PencilAltOutlineIcon className="w-5 h-5"/>
                                            </ButtonIcon>
                                        </div>
                                    )
                                }

                                {
                                    col.metadata.hasCommentAction && !(hasEvents || commentCount || hasMileageDiff) && (
                                        <button
                                            onClick={() => onDayCommentClick(col.name, driver)}
                                            className="hidden group-hover:flex absolute inset-0.5 bg-primary-transparent rounded-sm items-center justify-center">

                                            <div className="text-center">
                                                <PencilAltOutlineIcon
                                                    className="inline w-6 h-6 text-primary opacity-50"/>
                                                <div className="text-tm-gray-700 font-bold">Add day comment
                                                </div>
                                            </div>
                                        </button>
                                    )
                                }
                            </div>
                        })}
                    </div>
                )
            })
        }
    </div>
}