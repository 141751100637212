import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import Card from "../../../common/components/card";
import {LoaderLarge} from "../../../common/components/loader";

let timezone = require('../../../assets/json-helpers/timezone.json')
let dateTimeFormat = require('../../../views/general/profiles/datetimeformat.json')

export default function NewUserSettingsTab({translate}) {

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading

    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.CompanySettings
        }))
    }

    useEffect(() => {
        if (!isLoading) {
            setFields(getFields(resource.data))
        }
    }, [isLoading])

    useEffect(() => {
        fetchData()
    }, [])

    const getFields = (data = {}) => {
        let fieldTemplates = {
            Timezone: new Field('Timezone', 'UTC', ['empty'], false, 'select'),
            DateTimeFormat: new Field('DateTimeFormat', 'MM/DD/YYYY h:mm A', ['empty'], false, 'select')
        }

        return fillFieldsFromData(fieldTemplates, data)
    }

    const handleInputChange = (name, value) => {
        setIsDirty(true)
        const newFields = FieldsManager.updateField(fields, name, value)
        setFields(newFields)
    }

    const handleSubmit = () => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            resource: Resources.CompanySettings,
            params: FieldsManager.getFieldKeyValues(fields),
            piggyResource: Resources.CompanySettings
        }))
    }

    const handleCancel = () => {
        setFields(getFields(resource.data));setIsDirty(false)
    }

    const [fields, setFields] = useState(getFields())
    const [isDirty, setIsDirty] = useState(false)

    const selects = {
        Timezone: timezone,
        DateTimeFormat: dateTimeFormat
    }

    const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, selects)

    return (
        <>
            {!isLoading && (
                <div>
                    <div className="max-w-3xl mx-auto">
                        <Card bodyClass={"py-5 px-6 space-y-4"}>
                            {fieldsHtml}
                        </Card>
                    </div>
                </div>
            )}

            {isLoading && (
                <LoaderLarge />
            )}

            <PageFooter
                translate={translate}
                canSubmit={isDirty}
                actionCancel={handleCancel}
                actionSubmit={handleSubmit}
            />
        </>

    )
}
