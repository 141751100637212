import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {createEditor, Editor, Range, Transforms} from 'slate'
import {withHistory} from 'slate-history'
import {Editable, Slate, useFocused, useSelected, withReact,} from 'slate-react'
import LoaderSmall from "../../loader/loader-small";
import axios from "axios";
import Env from "../../../../util/env";
import {processResponse} from "../../../../data/services/api-util";
import Resources from "../../../../data/services/resources";
import {classNames} from "../../../util/util-helpers";

const FieldTextareaChat = ({onSetValue, token, addInputClass, placeholder, onEnterKeyDown}) => {

    const ref = useRef(null);
    const [target, setTarget] = useState()
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState('')
    const [mentionList, setMentionList] = useState([])
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(
        () => withMentions(withReact(withHistory(createEditor()))),
        []
    )

    const [isLoading, setIsLoading] = useState(false);

    const onKeyDown = useCallback(
        event => {
            if (target && mentionList.length > 0) {
                switch (event.key) {
                    case 'ArrowDown': {
                        event.preventDefault()
                        const prevIndex = index >= mentionList.length - 1 ? 0 : index + 1
                        setIndex(prevIndex)
                        break;
                    }
                    case 'ArrowUp': {
                        event.preventDefault()
                        const nextIndex = index <= 0 ? mentionList.length - 1 : index - 1
                        setIndex(nextIndex)
                        break;
                    }
                    case 'Tab':
                    case 'Enter':
                        event.preventDefault()
                        Transforms.select(editor, target)
                        insertMention(editor, mentionList[index].FirstName + " " + mentionList[index].LastName, mentionList[index].Email, mentionList[index].ContactID)
                        setTarget(null)
                        break
                    case 'Escape':
                        event.preventDefault()
                        setTarget(null)
                        break
                }
            }

            if (!target) {
                switch (event.key) {
                    case 'Enter':
                        if (!event.shiftKey) {
                            onEnterKeyDown();
                        }
                }
            }
        },
        [mentionList, editor, index, target]
    )

    useEffect(() => {
        if (target) {
            setIsLoading(true);

            axios.get(
                Env.getApiUrl("api/" + Resources.ContactsQuick, {
                    query: search,
                    IsSystemUser: 1,
                    limit: 10,
                    offset: 0
                }),
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            )
                .then((response) => {
                    const result = processResponse(response);
                    if (result && result.status === 0) {
                        let list = result.data.list;
                        list.push({
                            ContactID: 0,
                            Email: "@all",
                            FirstName: "All",
                            LastName: "System Users",
                            UserID: 0
                        });
                        setMentionList(list);
                    }

                    setIsLoading(false)
                })
        }
    }, [target])

    useEffect(() => {
        setTimeout(() => {
            const editor = document.querySelector('[data-slate-editor]');

            if (editor) {
                editor.focus()
            }
        }, 200)
    }, [])

    return (
        <div
            className="w-full text-tm-gray-900 placeholder-tm-gray-600 bg-inverse border border-tm-gray-300 rounded-card resize-none">
            <Slate
                editor={editor}
                initialValue={initialValue}
                value={initialValue} // in new version it's called initialValue
                onChange={(slateValue) => {
                    const {selection} = editor

                    if (selection && Range.isCollapsed(selection)) {
                        const [start] = Range.edges(selection)
                        const wordBefore = Editor.before(editor, start, {unit: 'word'})
                        const before = wordBefore && Editor.before(editor, wordBefore)
                        const beforeRange = before && Editor.range(editor, before, start)
                        const beforeText = beforeRange && Editor.string(editor, beforeRange)
                        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
                        const after = Editor.after(editor, start)
                        const afterRange = Editor.range(editor, start, after)
                        const afterText = Editor.string(editor, afterRange)
                        const afterMatch = afterText.match(/^(\s|$)/)

                        if (beforeMatch && afterMatch) {
                            setTarget(beforeRange)
                            setSearch(beforeMatch[1])
                            setIndex(0)
                            return
                        }
                    }

                    setTarget(null)
                    onSetValue(slateValue);
                }}
            >
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder ?? "Enter some text..."}
                    className={classNames(
                        "focus:ring-2 focus:ring-primary focus:ring-offset-inverse focus:ring-offset-0 rounded-input group",
                        addInputClass
                )}
                />
                {target && !isLoading && mentionList.length > 0 && (
                    <div
                        ref={ref}
                        className="fixed p-4 z-50 -translate-y-[calc(100%+1.5rem)] overflow-y-auto max-h-[20rem] bg-popup border border-tm-gray-200"
                        style={{
                            borderRadius: '4px',
                            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                        }}
                    >
                        {mentionList.map((contact, i) => {
                            const name = contact.FirstName + " " + contact.LastName;
                            const email = contact.Email;
                            const id = contact.ContactID
                            return (
                                <div
                                    key={name}
                                    onClick={() => {
                                        Transforms.select(editor, target)
                                        insertMention(editor, name, email, id)
                                        setTarget(null)
                                    }}
                                    className={
                                        classNames(
                                            "px-2 py-0.5 hover:bg-primary hover:text-primary-contrast hover:cursor-pointer group",
                                            i === index ? "bg-primary-tint text-primary-contrast" : 'transparent'
                                        )
                                    }
                                    style={{
                                        padding: '1px 3px',
                                        borderRadius: '3px',
                                    }}
                                >
                                    <span
                                        className={
                                            classNames(
                                                "group-hover:text-primary-contrast font-bold",
                                                i === index ? " text-primary-contrast" : 'text-tm-gray-900'
                                            )
                                        }
                                    >
                                        {name}
                                    </span> - <span
                                    className={
                                        classNames(
                                            "group-hover:text-primary-contrast",
                                            i === index ? "text-primary-contrast" : 'text-tm-gray-600'
                                        )
                                    }
                                >
                                    {email}
                                </span>
                                </div>
                            )
                        })}
                    </div>

                )}

                {target && isLoading && (
                    <div
                        className="fixed p-4 z-50 -translate-y-[calc(100%+1.5rem)] overflow-y-auto max-h-[20rem] border border-tm-gray-200 group-focus:border-none bg-popup"
                        style={{
                            borderRadius: '4px',
                            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                        }}
                    >
                        <LoaderSmall/>
                    </div>

                )}
            </Slate>
        </div>
    )
}

const withMentions = editor => {
    const {isInline, isVoid, markableVoid} = editor

    editor.isInline = element => {
        return element.type === 'mention' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'mention' ? true : isVoid(element)
    }

    editor.markableVoid = element => {
        return element.type === 'mention' || markableVoid(element)
    }

    return editor
}

const insertMention = (editor, contact, email, id) => {
    const mention = {
        type: 'mention',
        contact,
        email,
        id,
        children: [{text: ''}],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor, {
        distance: 1,
        unit: "offset"
    });

}

// Borrow Leaf renderer from the Rich Text example.
// Update this to shared function like `withRichText(editor)` or similar.
const Leaf = ({attributes, children, leaf}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const Element = props => {
    const {attributes, children, element} = props
    switch (element.type) {
        case 'mention':
            return <Mention {...props} />
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Mention = ({attributes, children, element}) => {
    const selected = useSelected()
    const focused = useFocused()
    const style = {
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    }
    // See if our empty text child has any styling marks applied and apply those
    if (element.children[0].bold) {
        style.fontWeight = 'bold'
    }
    if (element.children[0].italic) {
        style.fontStyle = 'italic'
    }

    return (
        <span
            {...attributes}
            contentEditable={false}
            style={style}
            className="bg-primary-transparent text-primary"
        >
            @{element.contact}
            {children}
        </span>
    )
}

const initialValue = [
    {
        type: 'paragraph',
        children: [
            {text: ''},
        ],
    },
]

export default FieldTextareaChat;