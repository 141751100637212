import React, {useEffect, useRef, useState} from 'react';
import Button from "../../../../common/components/button";
import LoadStepsPossibleStatus from "./load-steps-possible-status";
import LoadStepsReadyStatus from "./load-steps-ready-status";
import LoadStepsCoveredStatus from "./load-steps-covered-status";
import LoadStepsDispatchedStatus from "./load-steps-dispatched-status";
import LoadStepsInTransitStatus from "./load-steps-in-transit-status";
import LightBulbIcon from "@heroicons/react/24/outline/LightBulbIcon";
import {createPortal} from "react-dom";
import LoadStepsPopup from "./load-steps-popup";

export default function LoadSteps({
                                      areStepsActive,
                                      setAreStepsActive,
                                      stops,
                                      fields,
                                      isCarrier,
                                      loadStatus,
                                      fieldsRefs,
                                      onStopClick,
                                      loadSettings,
                                      stopsCombined,
                                      readyStatusRef,
                                      stopFieldsRefs,
                                      submitButtonRef,
                                      stepsAddStopsRef,
                                      coveredStatusRef,
                                      initialLoadStatus,
                                      possibleStatusRef,
                                      inTransitStatusRef,
                                      deliveredStatusRef,
                                      dispatchedStatusRef,
                                      completeStopFormRef,
                                      completeStopButtonRef,
                                      isCompleteStopModalOpen,
                                      isStopCompletedCheckboxRef,
                                      suggestNextActionButtonRef,
                                      loadStepsCompleteStopState,
                                      completeStopSubmitButtonRef
                                  }) {
    const currentlyFocusedElement = useRef();
    const stopsLengthRef = useRef(stops.length);
    const [element, setElement] = useState();



    const [suggestedStopField, setSuggestedStopField] = useState(null);
    const [areAllRequiredStopsFieldFilled, setAreAllRequiredStopsFieldFilled] = useState(() => !!findNextEmptyStopField())

    function scrollToElement(element) {
        // Scroll the element into view, centered
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }

    function handleNextSuggestedActionClick() {
        setAreStepsActive(true);

        if (currentlyFocusedElement?.current) {
            scrollToElement(currentlyFocusedElement.current);
        }
    }

    function findNextEmptyStopField() {
        let suggestedStopIndex;
        let suggestedFieldName = "StopID";

        //const nextEmptyStopID = stops.findIndex(stop => !stop.StopID.value?.value);
        const nextEmptyStopID = stopsCombined.findIndex(stop => {
            if (stop.StopType) {
                if (!stops[stop.index].StopID.value?.value) {
                    return stop;
                }
            }
        });

        suggestedStopIndex = nextEmptyStopID;
        // const nextEmptyStopDate = stops.findIndex(stop => !stop.StopDate.value);
        const nextEmptyStopDate = stopsCombined.findIndex(stop => {
            if (stop.StopType) {
                if (!stops[stop.index].StopDate.value) {
                    return stop;
                }
            }
        });

        if (nextEmptyStopID > nextEmptyStopDate || nextEmptyStopID === -1) {
            suggestedStopIndex = nextEmptyStopDate;
            suggestedFieldName = "StopDate";
        }

        if (suggestedStopIndex !== -1) {
            return {stopIndex: suggestedStopIndex, fieldName: suggestedFieldName};
        } else {
            return null;
        }
    }

    function suggestNextStopField(getSuggestedStep) {

        const areAllFieldsFilled = !findNextEmptyStopField();

        let refInput = stopFieldsRefs.StopID.current?.select?.select?.select?.inputRef;

        if (suggestedStopField?.fieldName === 'StopDate') {
            refInput = stopFieldsRefs.StopDate?.current?.input?.inputElement
        }

        if (suggestedStopField?.stopIndex > -1) {
            onStopClick(suggestedStopField.stopIndex);
        }

        setTimeout(() => {
            if (refInput.parentNode && suggestedStopField?.fieldName === 'StopDate') {
                setElement(createPortal(
                    <LoadStepsPopup
                        position="top"
                        currentElement={refInput}
                        currentlyFocusedElement={currentlyFocusedElement}
                        addClass="min-w-[500px] z-50"
                        title={"Update pickup date for stop " + (suggestedStopField.stopIndex + 1) + "."}
                        stepText={
                            <p>
                                Select pickup date.
                            </p>
                        }
                        onCloseClick={() => {
                            setElement(undefined);
                            setAreStepsActive(false);
                            setAreAllRequiredStopsFieldFilled(areAllFieldsFilled);
                        }}
                        isNextDisabled={!stops[suggestedStopField.stopIndex]?.StopDate?.value}
                        onNextClick={() => {
                            getSuggestedStep();
                            setAreAllRequiredStopsFieldFilled(areAllFieldsFilled);
                        }}
                    />,
                    refInput.parentNode
                ));
            } else if (refInput.parentNode.parentNode.parentNode.parentNode.parentNode && suggestedStopField?.fieldName === 'StopID') {
                const el = refInput.parentNode.parentNode.parentNode.parentNode.parentNode
                setElement(createPortal(
                    <LoadStepsPopup
                        position="top"
                        currentElement={el}
                        currentlyFocusedElement={currentlyFocusedElement}
                        addClass="min-w-[500px] z-50"
                        title={"Update stop " + (suggestedStopField.stopIndex + 1) + "."}
                        stepText={<>
                            <p>
                                You can select a stop from a dropdown menu or from a list that appears when you press
                                the 'Plus'
                                button.
                            </p>

                            <p>
                                If the stop you need isn't available, you can create a new one from the list view or by
                                typing a new
                                location name in the dropdown menu.
                            </p>

                            <p>
                                All stops must be selected before you can continue.
                            </p>
                        </>}
                        onCloseClick={() => {
                            setElement(undefined);
                            setAreStepsActive(false);
                            setAreAllRequiredStopsFieldFilled(areAllFieldsFilled);
                        }}
                        isNextDisabled={!stops?.[suggestedStopField?.stopIndex]?.StopID?.value?.value}
                        onNextClick={() => {
                            getSuggestedStep();
                            setAreAllRequiredStopsFieldFilled(areAllFieldsFilled);
                        }}
                    />,
                    el
                ));
            }
        }, 300);
    }

    // function findNextRequiredField() {
    //     Object.values(fields).filter(it => it.validate[0] === 'empty' || it.validate[0] === 'empty_select_search')
    // }

    useEffect(() => {
        if (!!stopsLengthRef.current && stops.length !== stopsLengthRef.current) {
            setElement(undefined);
            setAreStepsActive(false);
            setSuggestedStopField(null);
        }

        stopsLengthRef.current = stops.length;
    }, [stops]);

    return <div>
        <Button
            innerRef={suggestNextActionButtonRef}
            onClick={handleNextSuggestedActionClick}
            addClass="mt-4"
        >
            <LightBulbIcon className="w-5 h-5 mr-1 -ml-1"/>
            Next action
        </Button>

        {areStepsActive && initialLoadStatus === 2 && (
            <LoadStepsPossibleStatus
                stops={stops}
                loadStatus={loadStatus}
                onStopClick={onStopClick}
                stopsCombined={stopsCombined}
                readyStatusRef={readyStatusRef}
                stopFieldsRefs={stopFieldsRefs}
                submitButtonRef={submitButtonRef}
                stepsAddStopsRef={stepsAddStopsRef}
                possibleStatusRef={possibleStatusRef}
                setAreStepsActive={setAreStepsActive}
                currentlyFocusedElement={currentlyFocusedElement}
            />
        )}

        {areStepsActive && initialLoadStatus === 3 && (
            <LoadStepsReadyStatus
                fields={fields}
                isCarrier={isCarrier}
                fieldsRefs={fieldsRefs}
                loadStatus={loadStatus}
                submitButtonRef={submitButtonRef}
                coveredStatusRef={coveredStatusRef}
                setAreStepsActive={setAreStepsActive}
                currentlyFocusedElement={currentlyFocusedElement}

                stops={stops}
                element={element}
                setElement={setElement}
                suggestedStopField={suggestedStopField}
                suggestNextStopField={suggestNextStopField}
                setSuggestedStopField={setSuggestedStopField}
                findNextEmptyStopField={findNextEmptyStopField}
                areAllRequiredStopsFieldFilled={areAllRequiredStopsFieldFilled}
            />
        )}

        {areStepsActive && initialLoadStatus === 4 && (
            <LoadStepsCoveredStatus
                fields={fields}
                fieldsRefs={fieldsRefs}
                loadStatus={loadStatus}
                loadSettings={loadSettings}
                scrollToElement={scrollToElement}
                submitButtonRef={submitButtonRef}
                setAreStepsActive={setAreStepsActive}
                dispatchedStatusRef={dispatchedStatusRef}
                currentlyFocusedElement={currentlyFocusedElement}

                stops={stops}
                element={element}
                setElement={setElement}
                suggestedStopField={suggestedStopField}
                suggestNextStopField={suggestNextStopField}
                setSuggestedStopField={setSuggestedStopField}
                findNextEmptyStopField={findNextEmptyStopField}
                areAllRequiredStopsFieldFilled={areAllRequiredStopsFieldFilled}
            />
        )}

        {areStepsActive && initialLoadStatus === 5 && (
            <LoadStepsDispatchedStatus
                loadStatus={loadStatus}
                scrollToElement={scrollToElement}
                submitButtonRef={submitButtonRef}
                setAreStepsActive={setAreStepsActive}
                inTransitStatusRef={inTransitStatusRef}
                currentlyFocusedElement={currentlyFocusedElement}

                stops={stops}
                element={element}
                setElement={setElement}
                suggestedStopField={suggestedStopField}
                suggestNextStopField={suggestNextStopField}
                setSuggestedStopField={setSuggestedStopField}
                findNextEmptyStopField={findNextEmptyStopField}
                areAllRequiredStopsFieldFilled={areAllRequiredStopsFieldFilled}
            />
        )}

        {areStepsActive && initialLoadStatus === 6 && (
            <LoadStepsInTransitStatus
                loadStatus={loadStatus}
                onStopClick={onStopClick}
                stopsCombined={stopsCombined}
                scrollToElement={scrollToElement}
                submitButtonRef={submitButtonRef}
                setAreStepsActive={setAreStepsActive}
                deliveredStatusRef={deliveredStatusRef}
                completeStopFormRef={completeStopFormRef}
                completeStopButtonRef={completeStopButtonRef}
                currentlyFocusedElement={currentlyFocusedElement}
                isCompleteStopModalOpen={isCompleteStopModalOpen}
                isStopCompletedCheckboxRef={isStopCompletedCheckboxRef}
                loadStepsCompleteStopState={loadStepsCompleteStopState}
                completeStopSubmitButtonRef={completeStopSubmitButtonRef}

                stops={stops}
                element={element}
                setElement={setElement}
                suggestedStopField={suggestedStopField}
                suggestNextStopField={suggestNextStopField}
                setSuggestedStopField={setSuggestedStopField}
                findNextEmptyStopField={findNextEmptyStopField}
                areAllRequiredStopsFieldFilled={areAllRequiredStopsFieldFilled}
            />
        )}
    </div>
}

