import React, {Component} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import FieldContainer from "../../fields/field-container";
import FieldTextarea from "../../fields/field-textarea";


class CommentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                Comment: new Field("Comment", "", [this.props.required ? "empty" : ""]),
            },
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitComment = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.onAddComment(this.props.id, this.state.fields.Comment.value);
                this.setState({fields: FieldsManager.updateField(this.state.fields, "Comment", "")});
            }
        });
    }

    render() {
        const {translate} = this.props;

        return (
            <div
                className="text-tm-gray-700"
                style={this.props.style}
            >
                <div className={'mb-4'}>
                    <FieldContainer
                        item={this.state.fields.Comment}
                        translate={translate}
                    >
                        <FieldTextarea
                            maxLength={this.props.maxLength}
                            rows={this.props.rows}
                            addClass={"form-control whitespace-pre-wrap"}
                            {...this.state.fields.Comment}
                            onChange={this.handleInputChange}
                        />
                    </FieldContainer>
                </div>

                <div className="flex items-center">
                    {!!this.props.maxLength && (
                        <p>{translate('text.character_limit')} ({this.state.fields.Comment.value.length}/{this.props.maxLength})</p>
                    )}

                    <button onClick={() => this.submitComment()}
                            className="btn btn-outline  ml-auto">{translate('text.add_new_comment')}</button>
                </div>
            </div>
        )
    }
}

export default CommentForm;
