import React from "react";
import {classNames} from "../../../../util/util-helpers"
import Tooltip from "../../../tooltip";
import Button from "../../../button";
import Buttons from "../../../buttons";
export default function PageHeader({
                                       className,
                                       title,
                                       buttonLabel,
                                       onButtonClick,
                                       hasPerm = true,
                                       buttonDisabled,
                                       buttonHidden,
                                       secondaryButtonLabel,
                                       onSecondaryButtonClick,
                                       secondaryButtonHasPerm = true,
                                       secondaryButtonDisabled,
                                       secondaryButtonHidden,
                                       secondaryButtonTooltip,
                                       customHtml,
                                       titleClass,
                                       buttonsClassName,
                                       afterTitle,
                                       buttons,
                                       children,
                                       ButtonsLeading
                                   }) {
    return (
        <header className={classNames(className ?? "flex")}>
            {ButtonsLeading && (
                <Buttons
                    buttons={ButtonsLeading}
                />
            )}

            <h1 className={titleClass ?? "text-2xl flex items-center"}>
                {title}

                {afterTitle}
            </h1>

            <div className={classNames(buttonsClassName ?? "ml-auto space-x-3 flex")}>
                {customHtml}
                {children}

                {!!secondaryButtonLabel && !secondaryButtonHidden && (
                    <Tooltip
                        disabled={!secondaryButtonTooltip}
                        content={secondaryButtonTooltip}
                    >
                        <span>
                            <Button
                                hasPerm={secondaryButtonHasPerm}
                                className={`btn btn-outline-secondary`}
                                onClick={() => {
                                    !secondaryButtonDisabled && onSecondaryButtonClick();
                                }}
                                disabled={secondaryButtonDisabled}
                            >
                                {secondaryButtonLabel}
                            </Button>
                        </span>
                    </Tooltip>
                )}

                {!!buttonLabel && !buttonHidden && (
                    <Button
                        id="create-btn"
                        className={`btn btn-primary`}
                        hasPerm={hasPerm}
                        onClick={() => {
                            if (!buttonDisabled) {
                                onButtonClick();
                            }
                        }}
                        disabled={buttonDisabled}
                    >
                        {buttonLabel}
                    </Button>
                )}

                {buttons && (
                    <Buttons
                        buttons={buttons}
                    />
                )}
            </div>
        </header>
    )
}
