import React, {Component} from 'react';
import {DEFAULT_CONTACT_INFO_FIELDS, UPDATE_PERM} from '../../../../util/util-constants'
import {checkPerm, getLookup, getProp} from '../../../util/util-helpers'
import CardSubTitle from "../../../../common/components/card/card-sub-title";
import Resources from "../../../../data/services/resources";
import {fieldsToHtml} from "../../../util/util-fields";

class ContactInfoFields extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {translate, hideSystemUser} = this.props;

        const selects = this.props.selects ?? {
            PersonalTitleID: getLookup("PersonalTitle"),
            Groups: {
                api: "api/" + Resources.ContactGroups,
                query: {},
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            ValidationType: this.props.ValidationType ?? {
                1: "Email",
                2: "SMS",
            },
            Roles: getLookup("PermRoles")
        }

        const fields = Object.values(Object.assign({}, this.props.fields)).filter(it => DEFAULT_CONTACT_INFO_FIELDS()[it.name])

        const fieldsContactInfoHTML = fieldsToHtml(Object.values(Object.assign({}, fields)).slice(0, Object.keys(DEFAULT_CONTACT_INFO_FIELDS()).length - 4), translate, this.props.handleInputChange, selects)
        const fieldsSystemRoleHTML = fieldsToHtml(Object.values(Object.assign({}, fields)).slice(Object.keys(DEFAULT_CONTACT_INFO_FIELDS()).length - 4, Object.keys(DEFAULT_CONTACT_INFO_FIELDS()).length), translate, this.props.handleInputChange, selects);

        const hideSystemRoleFields = hideSystemUser || (getProp(this.props.fields, "IsSystemUser.type", '') === 'hidden');

        return (
            <div>
                <CardSubTitle
                    text={translate('modal_heading.contact_info')}
                    addClass={'mb-4'}
                />
                <div className={"grid gap-4 grid-cols-12 pb-5"}>
                    {fieldsContactInfoHTML}

                    {!!this.props.selectedItem && !!fields.find(it => it.name === 'Groups') && (
                        fieldsToHtml([fields.find(it => it.name === 'Groups')], translate, this.props.handleInputChange, selects)
                    )}
                </div>

                {!hideSystemRoleFields && !!checkPerm(Resources.UserPermissions, UPDATE_PERM) && (
                    <>
                        <CardSubTitle
                            text={translate('modal_heading.SystemRole')}
                        />
                        <div className={"grid gap-4 grid-cols-12 pb-5"}>
                            {fieldsSystemRoleHTML}
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default ContactInfoFields;