import {createPortal} from "react-dom";

export default function ReactPortal({isActive = true, children, parent = document.body}) {

    return isActive
        ? createPortal(
            children,
            parent
        )
        : children
}