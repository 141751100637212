import React from 'react';
import {PDFJS} from 'pdfjs-dist/build/pdf.combined';
import 'pdfjs-dist/web/compatibility';
import {ArrowPathIcon, MagnifyingGlassIcon, MagnifyingGlassMinusIcon} from "@heroicons/react/24/outline";
import MagnifyingGlassPlusIcon from "@heroicons/react/24/outline/MagnifyingGlassPlusIcon";
import {classNames} from "../../../../util/util-helpers";

PDFJS.disableWorker = true;
const INCREASE_PERCENTAGE = 0.2;
const DEFAULT_SCALE = 1.1;

export class PDFPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: 90,
            i: 0
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.fetchAndRenderPage();
    }

    componentDidUpdate(prevProps, prevState) {

        // we want to render/re-render in two scenarias
        // user scrolls to the pdf
        // user zooms in
        if (prevProps.rotate !== this.props.rotate) {
            this.onRotate();
        }
        if (prevState.isVisible === this.state.isVisible && prevProps.zoom === this.props.zoom) return;
        if (this.state.isVisible) this.fetchAndRenderPage();
        if (prevProps.zoom !== this.props.zoom) {
            this.fetchAndRenderPage();
        }
    }

    onRotate = () => {
        const page = document.getElementById('page-' + this.props.index);
        page.style.transform = 'rotate('+ this.state.rotate + 'deg)'
        this.setState({rotate:this.state.rotate + 90,i:this.state.i + 1})
    }

    onChange(isVisible) {
        if (isVisible) this.setState({isVisible});
    }

    fetchAndRenderPage() {
        const {pdf, index} = this.props;
        pdf.getPage(index).then(this.renderPage.bind(this));
    }

    renderPage(page) {
        const {containerWidth, zoom} = this.props;
        const calculatedScale = (containerWidth / page.getViewport(DEFAULT_SCALE).width);
        const scale = calculatedScale > DEFAULT_SCALE ? calculatedScale : DEFAULT_SCALE;
        const viewport = page.getViewport(scale + zoom);
        const {width, height} = viewport;

        const context = this.canvas.getContext('2d');
        this.canvas.width = width;
        this.canvas.height = height;

        page.render({
            canvasContext: context,
            viewport,
        });
    }

    render() {
        const {index} = this.props;
        return (
            <div>
                <div key={`page-${index}`} className="overflow-visible">
                    <canvas ref={node => this.canvas = node} id={`page-${index}`} className={classNames("m-auto",(this.state.i % 2) === 1 ? "relative left-64" : undefined)} width="768" height="1024"/>
                </div>
            </div>
        );
    }
}

export default class PDFDriver extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pdf: null,
            zoom: 0,
            percent: 0,
            rotate: 0
        };

        this.increaseZoom = this.increaseZoom.bind(this);
        this.reduceZoom = this.reduceZoom.bind(this);
        this.resetZoom = this.resetZoom.bind(this);
    }

    componentDidMount() {
        const {filePath} = this.props;
        const containerWidth = this.container.offsetWidth;
        PDFJS.getDocument(filePath, null, null, this.progressCallback.bind(this)).then((pdf) => {
            this.setState({pdf, containerWidth});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pdf === null && !!this.state.pdf && this.props.onPDFLoad) {
            this.props.onPDFLoad();
        }
    }

    setZoom(zoom) {
        this.setState({
            zoom,
        });
    }

    progressCallback(progress) {
        const percent = ((progress.loaded / progress.total) * 100).toFixed();
        this.setState({percent});
    }

    reduceZoom() {
        if (this.state.zoom === -5) return;
        this.setZoom(this.state.zoom - 1);
    }

    increaseZoom() {
        if (this.state.zoom === 5) return;
        this.setZoom(this.state.zoom + 1);
    }

    resetZoom() {
        this.setZoom(0);
    }

    increaseRotate() {
        this.setState((prevState) => ({
            rotate: prevState.rotate + 1}))
    }

    renderPages() {
        const {pdf, containerWidth, zoom, rotate} = this.state;
        if (!pdf) {
            return null;
        }
        const pages = Array.apply(null, {length: pdf.numPages});

        return pages.map((v, i) => {
            return (<PDFPage
                index={i + 1}
                pdf={pdf}
                containerWidth={containerWidth}
                zoom={zoom * INCREASE_PERCENTAGE}
                rotate={rotate}
            />)
        });
    }

    renderLoading() {
        if (this.state.pdf) {
            return null;
        }
        return (<div className="pdf-loading">LOADING ({this.state.percent}%)</div>);
    }

    render() {
        return (
            <div>
                <div ref={node => this.container = node}>
                    {this.state.pdf && (
                        <div className="fixed ml-5 my-2 z-20">
                            <button className={"block"} onClick={() => this.increaseRotate()}
                            ><ArrowPathIcon className={'w-7 h-7'}/></button>
                            <button onClick={this.increaseZoom}>
                                <MagnifyingGlassPlusIcon className={'w-7 h-7 mt-1'}/>
                            </button>
                            <button className="block" onClick={this.resetZoom}>
                                <MagnifyingGlassIcon className={'w-7 h-7 mt-1'}/>
                            </button>
                            <button className={"block"} onClick={this.reduceZoom}>
                                <MagnifyingGlassMinusIcon className={'w-7 h-7 mt-1'}/>
                            </button>
                        </div>
                    )}
                    {this.renderLoading()}
                    {this.renderPages()}
                </div>
            </div>
        );
    }
}

PDFDriver.defaultProps = {
    disableVisibilityCheck: false,
};
