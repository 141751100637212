import React, {useEffect, useRef, useState} from "react";
import {classNames} from "../../util/util-helpers";

export default function Accordion({expanded, children}) {
    const accordionRef = useRef(null);
    const [accordionHeight, setAccordionHeight] = useState(expanded ? "100%" : 0);
    const [overflow, setOverflow] = useState(!!expanded);

    useEffect(() => {
        if (!!expanded) {
            setAccordionHeight(accordionRef?.current.offsetHeight);
        } else {
            setOverflow(false);
            setAccordionHeight(0);
        }
    }, [expanded])

    return (
        <div
            className={classNames(overflow ? "" : "overflow-hidden", "transition-all ease-in-out delay-150")} style={{height: accordionHeight}}
            onClick={() => !!expanded && setOverflow(true)} // In case there are dropdowns that go outside of container
        >
            <div ref={accordionRef}>
                {children}
            </div>
        </div>
    )
}