import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LocalStorage from "../../../../util/localStorage";
import {getProp} from "../../../../util/util-helpers";

const DocumentHeader = ({translate, match, customPageTitle}) => {
    const location = useLocation();

    useEffect(() => {
        let compName = "ACCUR8 TMS";
        if (!!LocalStorage.has("user")) {
            compName = getProp(LocalStorage.get("user"), 'Contact.CompanyName', "ACCUR8 TMS");
        }

        let pageTitle;

        if (customPageTitle) {
            pageTitle = customPageTitle
        } else {
            const path = match.path.split("/");
            if (path[2] === 'info') {
                pageTitle = translate("page.title." + path[1] + ".info") + (path[3] ? " - " + path[3] : "");
            } else {
                pageTitle = translate("page.title." + path[1]);
            }
        }

        document.title = pageTitle + " | " + compName;
    }, [location, translate, customPageTitle]);

    return null
}

export default DocumentHeader;
