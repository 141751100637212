import { ArrowPathIcon, CpuChipIcon, PaperAirplaneIcon, TrashIcon, WindowIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import ChatbotBox from './chatbot-box';
import { PaperAirplaneIcon as  PaperAirplaneSolidIcon} from '@heroicons/react/20/solid';
import ImageAvatarLoader from "../../../image/image-avatar-loader";
import {LoaderSmall} from "../../../loader";
import InfoBar from "../../../info-paragraph/info-bar";
import {getUser} from "../../../../util/util-auth";
import Bot from '../../../../../assets/images/bot.png'
import {classNames} from "../../../../util/util-helpers";

export default function ChatSection({
    messages, 
    bodyRef, 
    bodyClass, 
    streamMessage, 
    fields, 
    onInputChange, 
    onClearConversation, 
    onRegenerateConversation, 
    isLoading, 
    onSubmit, 
    displayType, 
    onInputKeyPress,
    textboxRef,
    setDisplayType,
    setStreamMessage,
    onHelpChatToggleClick
}) {
    const canSubmit = !!fields.UserInput.value && !isLoading;

    return (
        <div className={classNames(
            displayType === 'bottom' 
                ? 'grid grid-cols-3'
                : ""
        )}>
            <div 
                className={
                    classNames(
                        displayType === 'bottom' 
                            ? "col-span-2 order-1"
                            : undefined
                    )
                }
            >
                <div 
                    ref={bodyRef}
                    className={bodyClass}
                >
                    <div 
                        className={
                            classNames(
                                'min-h-[24rem] bg-inverse relative',
                                displayType === 'bottom' 
                                    ? undefined
                                    : 'mb-16 rounded-card shadow-sm ring-1 ring-inset ring-tm-gray-300'
                            )
                        }
                    >
                        <div className={displayType === 'bottom' ? 'sticky top-0' : undefined}>
                            <div 
                                className={
                                    classNames(
                                        'absolute ring-1 ring-inset ring-tm-gray-300 left-0 p-1 flex bg-tm-gray-100 gap-x-1  px-2 text-tm-gray-700',
                                        displayType === 'bottom' 
                                            ? "rounded-r-card -top-px" 
                                            : "rounded-card top-0"
                                    )
                                }
                            >
                                <CpuChipIcon className='w-5 h-5' />
                                Chatbot
                            </div>
                        </div>

                        <div 
                            className={
                                classNames(
                                    displayType === 'bottom' 
                                        ? "py-5 px-24"
                                        : 'pt-10'
                                )
                            }
                        >
                            {messages.map((msg, i) => {
                                return <div 
                                    key={msg.id} 
                                    className={
                                        classNames(
                                            'flex py-2 px-4 gap-4 rounded-card',
                                            msg.user === 'bot' ? "bg-tm-gray-50 border border-tm-gray-300" : undefined
                                        )
                                    }>
                                    {msg.user === 'bot' && (
                                        <React.Fragment>
                                            <div>
                                                <div className='w-8 h-8 rounded-full text-tm-gray-700 flex-shrink-0'>
                                                    <img src={Bot} alt="bot" />
                                                </div>
                                            </div>

                                            <ChatbotBox
                                                onStream={() => {
                                                    if (bodyRef.current) {
                                                        bodyRef.current.scrollTop = bodyRef.current.scrollHeight
                                                    }
                                                }}
                                                isLoading={isLoading} streamContent={messages.length === i + 1 && streamMessage} content={msg.content} />
                                        </React.Fragment>
                                    )}

                                    {msg.user === 'me' && (
                                        <React.Fragment>
                                            <div>
                                                <ImageAvatarLoader
                                                    addClass="bg-tm-gray-100 rounded-full"
                                                    ContactID={getUser('Contact.ContactID')}
                                                    size="sm"
                                                    hasImagePath={true}
                                                />
                                            </div>

                                            <div className='flex items-center'>
                                                {msg.content}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            })}

                            {!!isLoading && (
                                <div key={"0"} className={
                                    classNames("flex py-2 px-4 gap-4 rounded-card bg-tm-gray-50 border border-tm-gray-300")
                                }>
                                    <div>
                                        <div className='w-8 h-8 rounded-full text-tm-gray-700 flex-shrink-0'>
                                            <img src={Bot} alt="bot" />
                                        </div>
                                    </div>

                                    <LoaderSmall addClass={""}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div 
                className={
                    classNames(
                        displayType === 'bottom' 
                            ? "order-2 border-l border-tm-gray-300 pb-5 px-5 flex flex-col justify-between"
                            : 'bg-inverse p-5 border-t border-tm-gray-300'
                    )
                }
            >
                {displayType === 'bottom' && (
                    <div>
                        <div className='h-16 flex items-center justify-between'>
                            <div className='text-lg font-medium'>Ask our AI assistant for help</div>

                            <div className='space-x-3'>
                                <button 
                                    className='btn btn-icon'
                                    onClick={() => {
                                        !!setDisplayType && setDisplayType("modal");
                                        !!setStreamMessage && setStreamMessage(false);
                                        setTimeout(() => {
                                            bodyRef.current.scrollTop = bodyRef.current.scrollHeight
                                        }, 200)
                                    }}
                                >
                                    <WindowIcon className='w-5 h-5 text-tm-gray-600' />
                                </button>

                                <button
                                    onClick={onHelpChatToggleClick}
                                    className='btn btn-close'
                                >
                                    <XMarkIcon className='w-6 h-6' />
                                </button>
                            </div>
                        </div>
      
                        <InfoBar addClass="mt-1">* ACCUR8 bot is in a development phase, and may produce inaccurate information about TMS or a general facts.</InfoBar>     
                    </div>
                )}

                <div>
                    {!!messages.length && (
                        <div className='h-12 flex gap-4 justify-end'>
                            <button 
                                className='btn btn-outline-secondary'
                                type="button"
                                onClick={onClearConversation}
                            >
                                <TrashIcon className='w-5 h-5 mr-1 -ml-1 text-tm-gray-500'/> Clear conversation
                            </button>

                            <button 
                                className='btn btn-outline-secondary'
                                type="button"
                                onClick={onRegenerateConversation}
                            >
                                <ArrowPathIcon className='w-5 h-5 mr-1 -ml-1 text-tm-gray-500'/> Regenerate
                            </button>
                        </div>
                    )}

                    <div className='bg-field relative rounded-card'>
                        {
                            // <Fields
                            //     fieldsState={fields}
                            //     onInputChange={onInputChange}
                            //     translate={translate}
                            // />
                            // 
                        }

                        <div 
                            ref={textboxRef}
                            className="form-control leading-6 bg-transparent relative z-10 max-h-[10rem] overflow-auto" 
                            onInput={(e) => onInputChange('UserInput', e.currentTarget.textContent)}
                            onKeyDown={onInputKeyPress}
                            role="textbox"
                            contentEditable
                        />

                        {!fields.UserInput.value && (
                            <span className='h-6 leading-6 absolute z-0 left-3 top-1.5 text-tm-gray-500'>Type something here ...</span>
                        )}

                        <button 
                            onClick={onSubmit}
                            disabled={!canSubmit}
                            className={
                                classNames(
                                    'p-1.5 mx-1.5 z-20 rounded-btn text-tm-gray-600 absolute right-1 bottom-0.5',
                                    canSubmit ? "hover:bg-primary-transparent cursor-pointer" : "cursor-default"
                                )
                            }
                        >
                            {canSubmit && (
                                <PaperAirplaneSolidIcon className='text-primary w-5 h-5' />
                            )}

                            {!canSubmit && (
                                <PaperAirplaneIcon className='w-5 h-5 text-tm-gray-600' />
                            )}
                        </button>
                    </div>

                    {displayType !== 'bottom' && (
                        <InfoBar addClass="mt-1">* ACCUR8 bot is in a development phase, and may produce inaccurate information about TMS or a general facts.</InfoBar>
                    )}
                </div>
            </div>
        </div>
    )
}
