import PhotoIcon from "@heroicons/react/24/outline/PhotoIcon";
import {ArrowDownTrayIcon, EyeIcon, PaperClipIcon, TrashIcon} from "@heroicons/react/24/outline";
import React from "react";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import {classNames} from "../../../util/util-helpers";

// NOTE: Use this component separately for existing files and for uploading files.
//       Organise them in separate lists

export default function FileList({files = [], onFileView, onFileDownload, onFileDelete, hideFileDelete, hideFileDownload, hideFileView, className, addClass, itemClass, filePath = "", filePaths = []}) {
    // Single or multiple files
    if (filePath) {
        files = [{
            type: filePath.split('.').pop(),
            path: filePath
        }]
    }

    if (filePaths.length) {
        files = filePaths.map(it => {
            return {
                type: it.split('.').pop(),
                path: it
            };
        })
    }

    if (!files.length) {
        return null;
    }

    const getFileType = (fileType) => {
        if (['.xlsx', '.doc','.docx','.xml','application/pdf', 'application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.pdf', '.ods', '.odt', '.ott', '.ots', '.rtf', '.txt', 'xlsx','doc','docx','xml', 'pdf', 'ods', 'odt', 'ott', 'ots', 'rtf', 'txt'].includes(fileType)) {
            return 'document'
        }

        if (['image/jpg', 'image/jpeg', 'image/png', '.jpg', '.jpeg', '.png', 'jpg', 'jpeg', 'png'].includes(fileType)) {
            return 'image'
        }

        return "attachment";
    }

    return (
        <ul
            role="list"
            className={
                classNames(
                    className ?? "divide-y divide-tm-gray-200 rounded-md border border-tm-gray-200",
                addClass
            )}
        >
            {files.map((file, i) => {
                const fileType = getFileType(file.type);

                return (
                    <li
                        key={i}
                        className={itemClass ?? "h-12 flex items-center justify-between px-3 text-sm"}
                    >
                        <div className="flex w-0 flex-1 items-center">
                            <svg className="h-5 w-5 flex-shrink-0 text-tm-gray-400" viewBox="0 0 20 20"
                                 fill="currentColor" aria-hidden="true">
                                {fileType === "image" && (
                                    <PhotoIcon className="w-5 h-5"/>
                                )}

                                {fileType === "document" && (
                                    <DocumentTextIcon className="w-5 h-5"/>
                                )}

                                {fileType === "attachment" && (
                                    <PaperClipIcon className="w-5 h-5"/>
                                )}
                            </svg>
                            <span
                                className="ml-2 w-0 flex-1 truncate">{file.path}</span>
                        </div>

                        <div className="ml-4 flex-shrink-0 mx-0 space-x-2">
                            {!!onFileView && !hideFileView && (
                                <button
                                    title={"Preview"}
                                    onClick={() => onFileView(file)}
                                    className="btn-table-action p-1"
                                >
                                    <EyeIcon className="w-5 h-5 text-tm-gray-600"/>
                                </button>
                            )}

                            {!!onFileDownload && !hideFileDownload && (
                                <button
                                    onClick={() => onFileDownload(file)}
                                    className="btn-table-action mx-0 p-1"
                                >
                                    <ArrowDownTrayIcon className="w-5 h-5 text-tm-gray-600"/>
                                </button>
                            )}

                            {!!onFileDelete && !hideFileDelete && (
                                <button
                                    title={"Delete"}
                                    onClick={() => onFileDelete(file, i)}
                                    className="btn-table-action mx-0 p-1"
                                >
                                    <TrashIcon className="w-5 h-5 text-tm-gray-600"/>
                                </button>
                            )}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}