import React, {Component} from "react";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import LocalStorage from "../../../util/localStorage";
import ResourceTable from "../../resource-table";
import NoRecordsTable from "../../no-records-found/no-records-table";
import {DEFAULT_CRUD_STATE} from "../../../../util/util-constants";
import Resources from "../../../../data/services/resources";
import {getContactResource} from "../../../../data/actions/contactResource";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {cloneDeep} from "../../../util/util-vanilla";
import {fieldsToHtml} from "../../../util/util-fields";
import {getProp} from "../../../util/util-helpers";
import {classNames} from "../../../util/util-helpers";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import TableCard from "../../resource-table/table-components/table-card";
import TableFilters from "../../resource-table/table-components/table-filters";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import Pagination from "../../resource-table/table-components/pagination";
import ModalDefault from "../modal-default";
import ContactFields from "../../contact/contact-fields";

export default class ContactSelectCreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            query: '',
            activeTab: 'select',
            additionalFields: this.props.additionalFields,
            selectExistingContactField: {
                ContactID: new Field("ContactID", "", ['empty'], false, 'creatable-select-search', {
                    labelType: "stack"
                }, {
                    onCreateOption: this.handleOnCreateOption,
                    formatCreateLabel: userInput => this.props.translate("btn.Create") + ' "' + userInput + '"'
                }),
            },
            prefilled: "",
            queryFilterFields: Object.assign(this.getQueryFilterFields(), this.props.queryFilterFields),
        }
        this.contactFormRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchContacts()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.secondResource.errorMessage && !prevProps.secondResource.errorMessage && (this.props.secondResource.errorMessage === "EMAIL_PARAMETER_ALREADY_EXISTS")) {
            this.setState({emailExist: true})
        }
        if (this.props.secondResource !== prevProps.secondResource && !this.props.secondResource.error && !!this.props.secondResource.create) {
            if (!this.props.secondResource.create.password) {
                this.props.onClose()
            }
            if (!!this.props.secondResource.create.password) {
                this.setState({createdPassword: this.props.secondResource.create.password})
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        if (this.state.activeTab === "select") {
            this.fetchContacts()
        } else if (this.state.activeTab === "select-group") {
            this.fetchGroups()
        }
    }

    fetchContacts = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.ContactsQuick,
            query: this.getQuery()
        }))
    }

    fetchGroups = () => {
        this.props.dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.ContactGroups,
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    handleAdditionalFieldsInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.additionalFields, name, value)});
    }

    handleOnCreateOption = (name, value) => {
        this.setState({
            prefilled: value,
            activeTab: "create",
            selectExistingContactField: FieldsManager.updateField(this.state.selectExistingContactField, "ContactID", '')
        })
    }

    handleExistingContactInputChange = (name, value) => {
        this.setState({
            selectExistingContactField: FieldsManager.updateField(this.state.selectExistingContactField, name, value),
            canSubmit: true
        });
    }

    handleSetActiveTab = (tab) => {
        this.setState({
            sort: "ASC",
            sortBy: '',
            offset: 0,
            limit: 10,
            activeTab: tab
        }, () => {
            this.fetchData()
        })
    }

    handleAddContact = (item) => {
        this.setState({additionalFields: FieldsManager.validateFields(this.state.additionalFields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.additionalFields)) {
                this.props.handleAddExistingContact(item, FieldsManager.getFieldKeyValues(this.state.additionalFields))
            }
        })
    }

    handleAddGroup = (item) => {
        this.setState({additionalFields: FieldsManager.validateFields(this.state.additionalFields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.additionalFields)) {
                this.props.handleAddExistingGroup(item, FieldsManager.getFieldKeyValues(this.state.additionalFields), this.getQuery())
            }
        })
    }

    handleCreateNewContact = (fields, files) => {
        this.setState({additionalFields: FieldsManager.validateFields(this.state.additionalFields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.additionalFields)) {
                this.props.handleCreateNewContact(FieldsManager.getFieldKeyValues(fields.fields), fields.phones, files, FieldsManager.getFieldKeyValues(this.state.additionalFields))
            }
        })
    }

    handleSubmit = () => {
        if (this.state.activeTab === "create") {
            this.contactFormRef.current.submit();
        } else if (this.state.activeTab === "select") {
            this.setState({selectExistingContactField: FieldsManager.validateFields(this.state.selectExistingContactField)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.selectExistingContactField)) {
                    this.handleAddContact(this.state.selectExistingContactField.ContactID.value.Contact)
                }
            })
        }
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        const fields = this.props.dialogTableSelectFields ?? {
            FirstName: new Field('FirstName', '', [], false, 'text'),
            LastName: new Field('LastName', '', [], false, 'text'),
            Email: new Field('Email', '', [], false, 'text'),
        }
        if (this.props.selectableCollumn) {
            Object.keys(fields).forEach(it => {
                fields[it].metadata = Object.assign({}, fields[it].metadata ?? {}, {
                    onCellClick: (item) => this.handleAddContact(item)
                })
            })
        }

        return fields
    }

    getGroupFields = () => ({
        ContactGroupName: new Field('ContactGroupName', '', [], false, 'text'),
        ContactGroupManager: new Field('ContactGroupManager', '', [], false, 'text'),
        ContactGroupEmail: new Field('ContactGroupEmail', '', []),
        ContactGroupDesc: new Field('ContactGroupDesc', '', [], false, 'text'),
    })

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ExcludeIDs: this.props.ExcludeIDs ? this.props.ExcludeIDs.join(",") : "",
            ...this.props.systemUserQuery ?? {},
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            ContactType: new Field('ContactType', '', [''], false, 'select'),

            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),

            limit: new Field('limit', 10, [''], false, 'select')
        }
    }

    render() {
        const {translate, onClose} = this.props;

        const additionalFields = fieldsToHtml(Object.values(Object.assign({}, this.state.additionalFields)), translate, this.handleAdditionalFieldsInputChange, this.props.selects ?? {});
        const selectExistingContactField = fieldsToHtml(Object.values(Object.assign({}, this.state.selectExistingContactField)), translate, this.handleExistingContactInputChange, {
            ContactID: {
                api: "api/" + Resources.Contacts,
                query: this.getQuery(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName,
                    Contact: item
                })
            }
        });

        const data = getProp(this.props.contactResource, 'data.list', [])
        const count = getProp(this.props.contactResource, 'data.count', 0)

        return (
            <React.Fragment>
                <ModalDefault
                    onClose={onClose}
                    closeButtonLabel={'Close'}
                    show={this.props.show}
                    title={this.props.title}

                    onButtonClick={this.handleSubmit}
                    buttonLabel={translate("btn.save")}
                    buttonDisabled={!this.state.canSubmit}

                    {...this.props}
                >
                    <div className="p-6">
                        {additionalFields}

                        {/* TABS */}
                        <ul className="flex items-center gap-x-8 border-y border-tm-gray-300 my-5">
                            <li className="">
                                <button
                                    className={
                                        classNames(
                                            "font-semibold",
                                            this.state.activeTab === "select" ? "text-primary pb-2 pt-2.5 border-b border-primary" : ""
                                        )
                                    }
                                    onClick={() => this.handleSetActiveTab("select")}>
                                    {translate("tab.SelectExisting")}
                                </button>
                            </li>
                            {!!this.props.resourceGroupName && (
                                <li className="">
                                    <button
                                        className={
                                            classNames(
                                                "font-semibold",
                                                this.state.activeTab === "select-group" ? "text-primary pb-2 pt-2.5 border-b border-primary" : ""
                                            )
                                        }
                                        onClick={() => this.handleSetActiveTab("select-group")}>{translate("tab.SelectFromGroup")}</button>
                                </li>
                            )}
                            {!this.props.disableContactCreate && (
                                <li className="">
                                    <button
                                        className={
                                            classNames(
                                                "font-semibold",
                                                this.state.activeTab === "create" ? "text-primary pb-2 pt-2.5 border-b border-primary" : ""
                                            )
                                        }
                                        onClick={() => this.handleSetActiveTab("create")}>{translate("tab.CreateNew")}</button>
                                </li>
                            )}
                        </ul>

                        {/* SELECT EXISTING CONTACT TABLE */}
                        {this.state.activeTab === "select" && (
                            <div>
                                {/* DROPDOWN */}
                                {/*{selectExistingContactField}*/}

                                <ActiveFilters
                                    filterFields={this.state.queryFilterFields}
                                    onLabelClick={this.handleFilterInputChange}
                                    onClearFiltersClick={this.handleClearFiltersClick}
                                    translate={translate}
                                />

                                <TableCard addClass={'relative z-0'}>
                                    <TableFilters
                                        filterFields={this.state.queryFilterFields}
                                        handleInputChange={this.handleFilterInputChange}
                                        translate={translate}
                                    />

                                    <ResourceTable
                                        verticalTable="md"

                                        data={data}
                                        fields={this.getFields()}

                                        tableKey="Email"

                                        translate={translate}
                                        isLoading={this.props.contactResource.isLoading}

                                        options={this.state.tableOptions}

                                        limit={this.state.queryFilterFields.limit.value}

                                        sort={this.state.sort}
                                        sortBy={this.state.sortBy}
                                        onSortChange={this.handleUpdateSort}

                                        onRowClick={this.handleAddContact}
                                        actions={[
                                            {
                                                action: this.handleAddContact,
                                                icon: PlusCircleIcon, // make this a function
                                                visible: () => true,
                                                label: false, // make this a function
                                                title: translate('btn.Add'),
                                                disabled: false,
                                                class: false,
                                                iconClass: false
                                            },
                                        ]}
                                    />

                                    <NoRecordsTable
                                        show={(data.length === 0) && !this.props.contactResource.isLoading}
                                        canCreate={true}
                                        title={translate('text.no_matching_records')}
                                        text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                                        btnLabel={translate('btn.create_contact')}
                                        onBtnClick={() => {
                                            this.setState({
                                                prefilled: this.state.queryFilterFields.query.value,
                                                activeTab: 'create'
                                            })
                                        }}
                                        onClearFilterClick={this.handleClearFiltersClick}
                                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                                        clearFilterText={translate('text.try_without_filters')}
                                        filters={this.state.queryFilterFields}
                                    />

                                    <TableCardFooter
                                        show={!(!data.length && !this.props.contactResource.isLoading)}
                                    >
                                        <Pagination
                                            count={count}
                                            pageLimit={this.state.queryFilterFields.limit.value}
                                            queryFields={this.state.queryFilterFields}
                                            pageOffset={this.state.offset}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffset(value, currentPage)
                                                    : this.handleFilterInputChange(name, value)
                                            }

                                            translate={translate}
                                        />
                                    </TableCardFooter>
                                </TableCard>
                            </div>
                        )}

                        {/* SELECT EXISTING CONTACT TABLE */}
                        {this.state.activeTab === "select-group" && (
                            <div>
                                <ActiveFilters
                                    filterFields={this.state.queryFilterFields}
                                    onLabelClick={this.handleFilterInputChange}
                                    onClearFiltersClick={this.handleClearFiltersClick}
                                    translate={translate}
                                />

                                <TableCard addClass={'relative z-0'}>
                                    <TableFilters
                                        filterFields={this.state.queryFilterFields}
                                        handleInputChange={this.handleFilterInputChange}
                                        translate={translate}
                                    />

                                    <ResourceTable
                                        verticalTable="md"

                                        data={data}
                                        fields={this.getGroupFields()}

                                        tableKey="Email"

                                        translate={translate}
                                        isLoading={this.props.contactResource.isLoading}

                                        options={this.state.tableOptions}

                                        limit={this.state.queryFilterFields.limit.value}

                                        sort={this.state.sort}
                                        sortBy={this.state.sortBy}
                                        onSortChange={this.handleUpdateSort}

                                        onRowClick={this.handleAddGroup}

                                        actions={[
                                            {
                                                action: this.handleAddGroup,
                                                icon: PlusCircleIcon, // make this a function
                                                visible: () => true,
                                                label: false, // make this a function
                                                title: translate('btn.Add'),
                                                disabled: false,
                                                class: false,
                                                iconClass: false
                                            },
                                        ]}
                                    />

                                    <NoRecordsTable
                                        show={(data.length === 0) && !this.props.contactResource.isLoading}
                                        canCreate={true}
                                        title={translate('text.no_matching_records')}
                                        text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                                        btnLabel={translate('btn.create_contact')}
                                        onBtnClick={() => {
                                            this.setState({
                                                prefilled: this.state.queryFilterFields.query.value,
                                                activeTab: 'create'
                                            })
                                        }}
                                        onClearFilterClick={this.handleClearFiltersClick}
                                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                                        clearFilterText={translate('text.try_without_filters')}
                                        filters={this.state.queryFilterFields}
                                    />

                                    <TableCardFooter
                                        show={!(!data.length && !this.props.contactResource.isLoading)}
                                    >
                                        <Pagination
                                            count={count}
                                            pageLimit={this.state.queryFilterFields.limit.value}
                                            queryFields={this.state.queryFilterFields}
                                            pageOffset={this.state.offset}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffset(value, currentPage)
                                                    : this.handleFilterInputChange(name, value)
                                            }

                                            translate={translate}
                                        />
                                    </TableCardFooter>
                                </TableCard>
                            </div>
                        )}

                        {/* CREATE NEW CONTACT FORM */}
                        {this.state.activeTab === "create" && (
                            <div>
                                <ContactFields
                                    submit={(fields, files) => {
                                        this.handleCreateNewContact(fields, files)
                                    }}
                                    prefilled={this.state.prefilled}
                                    ref={this.contactFormRef}
                                    translate={translate}
                                    emailExist={this.state.emailExist}
                                    createdPassword={this.state.createdPassword}
                                    hideSystemUser={this.props.hideSystemUser}
                                    setCanSubmit={() => this.setState({canSubmit: true})}
                                    ValidationType={{
                                        1: "Email",
                                        2: "SMS",
                                        3: "Temporary password"
                                    }}
                                    disableMemberOfOrganization={this.props.disableMemberOfOrganization}
                                />
                            </div>
                        )}

                    </div>
                </ModalDefault>
            </React.Fragment>
        );
    }
}
