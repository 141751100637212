import React, { Component } from 'react'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { Field, FieldsManager } from '../../../data/services/fields'
import { getProp } from '../../../common/util/util-helpers'
import Tippy from '@tippyjs/react'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import { PlusIcon } from '@heroicons/react/24/outline'
import { FUEL_SURCHARGE_MATRIX_TYPES } from '../../../util/util-constants'
import ModalFooter from "../../../common/components/modal/modal-footer";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
export default class FuelSurchargeCreateUpdateModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            Items: this.getItemsFields(),
            generateMatrixFields: this.getGenerateMatrixFields(),

            canSubmit: false
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({
            fields: FieldsManager.updateField(this.state.fields, name, value),
            canSubmit: true
        })
    }

    handleItemInputChange = (name, value, i) => {
        let Items = this.state.Items
        Items[i][name].value = value
        this.setState({ Items, canSubmit: true })
    }

    handleRemoveItemFromList = (index) => {
        let Items = this.state.Items
        Items.splice(index, 1)
        this.setState({ Items: Array.from(Items), canSubmit: true })
    }

    handleAddItemToList = () => {
        this.setState({
            Items: this.state.Items.concat(this.getDefaultItemField())
        })
    }

    handleSubmit = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            Items: this.state.Items.map(item => FieldsManager.validateFields(item))
        }, () => {
            if (
                FieldsManager.checkFieldsForErrors(this.state.fields) &&
                this.state.Items.every(item => FieldsManager.checkFieldsForErrors(item))
            ) {
                if (this.props.selectedItem) {
                    const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        Items: this.state.Items.map(item => FieldsManager.getFieldKeyValues(item)),
                        FuelSurchargeMatrixID: this.props.selectedItem.FuelSurchargeMatrixID
                    })
                    this.props.onUpdateData({
                        params: params,
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName,
                        query: this.props.query,
                        errorMessage: true, successMessage: 'Fuel Surcharge Updated.',
                    })
                    this.onClose()
                } else {
                    const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        Items: this.state.Items.map(item => FieldsManager.getFieldKeyValues(item))
                    })
                    this.props.onCreateData({
                        params: params,
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName,
                        query: this.props.query,
                        errorMessage: true, successMessage: 'Fuel Surcharge Created.',
                    })
                    this.onClose()
                }
            }
        })
    }

    onClose = () => {
        this.props.onClose()
    }

    handleToggleGenerateMatrixModal = () => {
        this.setState({ isGenerateMatrixModalOpen: !this.state.isGenerateMatrixModalOpen })
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        const item = this.props.selectedItem
        const fieldTemplates = {
            FuelSurchargeMatrix: new Field('FuelSurchargeMatrix', this.props.prefilled ?? '', ['empty'], false, 'text'),
            MatrixTypeID: new Field('MatrixTypeID', '', ['empty'], false, 'select', {}, {
                values: FUEL_SURCHARGE_MATRIX_TYPES
            })
        }
        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    getGenerateMatrixFields = () => {
        return {
            HowManyEntries: new Field('HowManyEntries', '', ['empty'], false, 'integer', { addContainerClass: 'col-span-full' }),
            StartingPrice: new Field('StartingPrice', '', ['empty'], false, 'float', { addContainerClass: 'col-span-full' }),
            PriceDifference: new Field('PriceDifference', '', ['empty'], false, 'float', { addContainerClass: 'col-span-full' }),
            SurchargePerMileIncrement: new Field('SurchargePerMileIncrement', '', ['empty'], false, 'float', { addContainerClass: 'col-span-full' }),
        }
    }

    getItemsFields = () => {
        if (this.props.selectedItem) {
            const items = getProp(this.props.selectedItem, 'Items', [])
            if (items.length > 0) {
                return items.map((item) => this.getDefaultItemField(item))
            } else {
                return [this.getDefaultItemField()]
            }
        } else {
            return [this.getDefaultItemField()]
        }
    }

    getDefaultItemField = (item = null) => {
        const fieldTemplates = {
            MinimumPrice: new Field('MinimumPrice', '', ['empty'], false, 'float'),
            MaximumPrice: new Field('MaximumPrice', '', ['empty'], false, 'float'),
            SurchargePerMileValue: new Field('SurchargePerMileValue', '', ['empty'], false, 'float'),
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    numberOfDecimals = (num) => {
        const arr = num.toString().split('.')
        if (arr && arr[1]) {
            return arr[1].length
        }
        return 0
    }

    render () {
        const { translate, selectedItem } = this.props

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange)

        const itemsToHtml = this.state.Items.map((item, i) => {
            const itemHtml = fieldsToHtml(Object.values(Object.assign({}, item)), translate, (name, value) => this.handleItemInputChange(name, value, i))
            return (
                <div className={'grid grid-cols-4 gap-5 my-5 border-t border-tm-gray-200'}>
                    {itemHtml}
                    {this.state.Items.length > 1 && (
                        <div className={'flex justify-center items-center'}>
                            <Tippy
                                content={translate('btn.delete')}
                            >
                                <button
                                    className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                    onClick={() => {
                                        this.handleRemoveItemFromList(i)
                                    }}
                                >
                                    <TrashIcon className="h-4 w-4"/>
                                </button>
                            </Tippy>
                        </div>
                    )}
                </div>
            )
        })

        return (
            <div>
                <header
                    className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900">
                    <h2
                        className="text-lg text-current truncate">{translate(`text.${selectedItem ? 'Update' : 'Create'}FuelSurcharge`)}</h2>

                    <button
                        className="absolute right-5 top-5 focus:ring-2 focus:ring-offset-2 focus:ring-primary rounded-full"
                        aria-label="Close"
                        onClick={this.onClose}>
                        <XMarkIcon className="w-5 h-5"/>
                    </button>
                </header>

                <div className="p-5">
                    <div className="mb-5 grid grid-cols-2 gap-5">
                        {fieldsHtml}
                    </div>

                    {itemsToHtml}

                    <div className={'mt-5 flex justify-center items-center'}>
                        <button
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={this.handleAddItemToList}
                        >
                            Add Manually
                            <PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true"/>
                        </button>
                        <div className={'px-4'}>
                            OR
                        </div>
                        <button
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={this.handleToggleGenerateMatrixModal}
                        >
                            Generate Matrix
                            <PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                </div>

                <ModalFooter
                    buttonLabel={translate('btn.save')}
                    buttonDisabled={!this.state.canSubmit}
                    onButtonClick={this.handleSubmit}

                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.onClose}
                />

                <ModalSaveResource
                    title={translate('text.GenerateItemsMatrix')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={!!this.state.isGenerateMatrixModalOpen}
                    onClose={this.handleToggleGenerateMatrixModal}
                    fields={this.getGenerateMatrixFields()}
                    onSubmit={(params) => {
                        if (params) {
                            // STARTING ITEM (USER ENTERED VALUES)
                            const numOfDecimalsSurchargePerMileIncrement = this.numberOfDecimals(params.SurchargePerMileIncrement)
                            let StartingMaximumPrice = params.StartingPrice + params.PriceDifference

                            if (this.numberOfDecimals(params.StartingPrice) && this.numberOfDecimals(params.PriceDifference)) {
                                const numberOfDecimals = Math.pow(10,
                                    this.numberOfDecimals(params.StartingPrice) > this.numberOfDecimals(params.PriceDifference) ?
                                        this.numberOfDecimals(params.StartingPrice) :
                                        this.numberOfDecimals(params.PriceDifference)
                                )

                                StartingMaximumPrice = (params.StartingPrice * numberOfDecimals + params.PriceDifference * numberOfDecimals) / numberOfDecimals
                            }
                            let items = [
                                this.getDefaultItemField({
                                    MinimumPrice: params.StartingPrice,
                                    MaximumPrice: StartingMaximumPrice,
                                    SurchargePerMileValue: params.SurchargePerMileIncrement
                                })
                            ]

                            // OTHER CALCULATED ITEMS
                            for (let i = 1; i < params.HowManyEntries; i++) {
                                // SURCHARGE PER MILE
                                const previousSurchargePerMileIncrement = items[i - 1].SurchargePerMileValue.value
                                let SurchargePerMileIncrement = params.SurchargePerMileIncrement
                                if (numOfDecimalsSurchargePerMileIncrement) {
                                    const numOfDecimals = Math.pow(10,numOfDecimalsSurchargePerMileIncrement)
                                    SurchargePerMileIncrement = (((previousSurchargePerMileIncrement * numOfDecimals) + (SurchargePerMileIncrement * numOfDecimals)) / numOfDecimals).toFixed(numOfDecimalsSurchargePerMileIncrement)
                                }

                                // MAXIMUM PRICE
                                const previousMaximumPrice = items[i - 1].MaximumPrice.value
                                let MaximumPrice = previousMaximumPrice + params.PriceDifference
                                if (this.numberOfDecimals(previousMaximumPrice) && this.numberOfDecimals(params.PriceDifference)) {
                                    const exponent = this.numberOfDecimals(previousMaximumPrice) > this.numberOfDecimals(params.PriceDifference) ?
                                        this.numberOfDecimals(previousMaximumPrice) :
                                        this.numberOfDecimals(params.PriceDifference)
                                    const numberOfDecimals = Math.pow(10,exponent)

                                    MaximumPrice = ((previousMaximumPrice * numberOfDecimals + params.PriceDifference * numberOfDecimals) / numberOfDecimals).toFixed(exponent)
                                }

                                // ITEM
                                const item = {
                                    MinimumPrice: previousMaximumPrice,
                                    MaximumPrice: MaximumPrice,
                                    SurchargePerMileValue: SurchargePerMileIncrement
                                }
                                items.push(this.getDefaultItemField(item))
                            }
                            // removing empty items
                            const currentItems = this.state.Items.filter(it => it.MinimumPrice.value || it.MaximumPrice.value || it.SurchargePerMileValue.value)
                            this.setState({ Items: currentItems.concat(items), isGenerateMatrixModalOpen: false })
                        }
                    }}
                    translate={translate}
                />
            </div>
        )
    }
}
