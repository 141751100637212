import {TagIcon} from "@heroicons/react/24/outline";
import {ChevronUpIcon} from "@heroicons/react/20/solid";
import React, {useEffect, useMemo, useRef, useState} from "react";
import TableTagManagerPopover from "./table-tag-manager-popover";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../../data/services/resources";
import {createDialogResource, getDialogResource} from "../../../../../data/actions/dialogResource";
import TableTagManagerModal from "./table-tag-manager-modal";
import {classNames, getProp} from "../../../../util/util-helpers";
import {createResource, updateResource} from "../../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import PopOver from "../../../popover";
import {createResourceDiff, updateResourceDiff} from "../../../../../data/actions/resourceDiff";

export default function TableTagManager({
                                            rowKey,
                                            selectedRows = [],
                                            onAfterTagRows = () => null,
                                            resourceName,
                                            id = 'IDs',
                                            requestMethod = 'PATCH',
                                            isDiffResource,
                                            InvoiceIDs,
                                            ExpenseIDs,
                                            piggyResourceName,
                                            query,
                                            translate,
                                            btnClass,
                                            btnLabel,
                                            btnIconClass,
                                            customIcon
                                        }) {
    const dispatch = useDispatch();
    const dialogResource = useSelector((state) => state.dialogResource);
    const tagsData = getProp(dialogResource, 'data', []);
    const tagsDataIsLoading = getProp(dialogResource, 'isLoading', false);

    const [isManageTagsModalOpen, setIsManageTagsModalOpen] = useState(false);
    const [isResourceLoaded, setIsResourceLoaded] = useState(false);

    const tags = useMemo(() => {
        return Object.values(selectedRows).reduce((memo, row) => {
            if (row.ColorTag) {
                const colorTags = row.ColorTag.split("|");
                if (colorTags.length) {
                    colorTags.forEach(colorTag => {
                        if (!memo?.list?.[colorTag]) {
                            memo.list = !memo.list ? {} : memo.list;
                            memo.list[colorTag] = [];
                        }

                        memo.list[colorTag].push(row[rowKey]);
                        memo.count = (!memo.count ? 0 : memo.count) + 1
                    })
                }
            } else {
                memo.hasRowsWithNoTags = true;
            }

            return memo;
        }, {})
    }, [selectedRows]);

    const areSomeRowsSelected = !!Object.keys(selectedRows).length
    const PopoverButtonRef = useRef();

    const tagRows = (taggedRows, labelList, selectedRows) => {
        const IDs = Object.keys(selectedRows);
        const ColorTag = labelList.reduce((memo, it) => {
            if (it.checked) {
                memo.push(it.tag);
            }

            return memo;
        }, []).join("|");


        if (isDiffResource && requestMethod === 'PATCH') {
            dispatch(updateResourceDiff({
                user: LocalStorage.get('user'),
                params: {
                    [id]: IDs,
                    ColorTag: ColorTag
                },
                resource: resourceName,
                piggyResource: piggyResourceName,
                query: query,
                errorMessage: true,
                successMessage: translate('text.tags_applied'),
            }))
        } else if (isDiffResource) {
            dispatch(createResourceDiff({
                user: LocalStorage.get('user'),
                params: Object.assign({
                    [id]: IDs,
                    ...!!ExpenseIDs && {ExpenseIDs: ExpenseIDs},
                    ...!!InvoiceIDs && {InvoiceIDs: InvoiceIDs},
                    ColorTag: ColorTag,
                }),
                resource: resourceName,
                piggyResource: piggyResourceName,
                query: query,
                errorMessage: true,
                successMessage: translate('text.tags_applied'),
            }))
        } else if (requestMethod === 'PATCH') {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: {
                    [id]: IDs,
                    ColorTag: ColorTag
                },
                resource: resourceName,
                piggyResource: piggyResourceName,
                query: query,
                errorMessage: true,
                successMessage: translate('text.tags_applied'),
            }))
        } else {
            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({
                    [id]: IDs,
                    ...!!ExpenseIDs && {ExpenseIDs: ExpenseIDs},
                    ...!!InvoiceIDs && {InvoiceIDs: InvoiceIDs},
                    ColorTag: ColorTag,
                }),
                resource: resourceName,
                piggyResource: piggyResourceName,
                query: query,
                errorMessage: true,
                successMessage: translate('text.tags_applied'),
            }))
        }

        if (PopoverButtonRef?.current?.click) {
            PopoverButtonRef.current.click();
        }

        onAfterTagRows();
    }

    const createTag = (label, color) => {
        dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {ColorTag: label + ',' + color},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true,
            successMessage: translate('text.tag_created'),
        }))
    }

    const fetchLabels = () => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }));

        setIsResourceLoaded(true);
    }

    useEffect(() => {
        if (!isResourceLoaded) {
            fetchLabels();
        }
    }, [areSomeRowsSelected]);


    return (
        <React.Fragment>
            <PopOver
                btnClass={
                    btnClass ?? "icon-btn p-1 flex items-center w-auto text-primary-contrast hover:text-primary-contrast hover:bg-primary-shade"
                }
                btnLabel={btnLabel}
                BtnIcon={TagIcon}
                btnIconClass={btnIconClass ?? classNames(areSomeRowsSelected ? 'text-primary' : 'text-tm-gray-400', 'w-5 h-5')}
                customIcon={
                    <React.Fragment>
                        {customIcon}

                        {!customIcon && (
                            <ChevronUpIcon
                                className={classNames(areSomeRowsSelected ? 'text-primary' : 'text-tm-gray-400', 'w-5 h-5')}
                            />
                        )}
                    </React.Fragment>
                }
                widthClass={'max-w-[16rem]'}
                positionClass="fixed md:absolute left-0 mx-5 md:mx-0 translate-x-0 bottom-12"
                PopoverButtonRef={PopoverButtonRef}
            >
                {areSomeRowsSelected && (
                    <div
                        className="text-tm-gray-700 bg-popup border border-tm-gray-300 rounded-md"
                    >
                        <TableTagManagerPopover
                            translate={translate}
                            labels={tagsData}
                            taggedRows={tags}
                            selectedRows={selectedRows}
                            onApplySelected={tagRows}
                            onManageTagsClick={() => setIsManageTagsModalOpen(!isManageTagsModalOpen)}
                            isLoading={tagsDataIsLoading}
                        />
                    </div>
                )}
            </PopOver>

            <TableTagManagerModal
                show={isManageTagsModalOpen}
                onClose={() => setIsManageTagsModalOpen(false)}
                translate={translate}
                onCreateTag={createTag}
                tagsData={tagsData}
                isLoading={tagsDataIsLoading}
            />
        </React.Fragment>
    )
}
