import {Field} from "../../../../data/services/fields";
import {
    BuildingOfficeIcon,
    CalendarIcon,
    CurrencyDollarIcon,
    EnvelopeIcon,
    HashtagIcon,
    InformationCircleIcon,
    ReceiptPercentIcon
} from "@heroicons/react/24/solid";
import React from "react";
import moment from "moment";
import {toFrontDate} from "../../../../common/util/util-dates";
import {classNames} from "../../../../common/util/util-helpers";
import {BanknotesIcon, ChartBarSquareIcon, ScaleIcon} from "@heroicons/react/20/solid";
import {CreditCardIcon} from "@heroicons/react/24/outline";
import {DEFAULT_DATABASE_DATE_FORMAT} from "../../../../common/util/util-consts";
import DataCard from "../../../../common/components/display-data/data-card";

export default function ReviewLoanModalInfoTab({selectedItem, translate}) {
    const data = Object.assign({}, selectedItem);

    const fields = Object.values(getFields());
    const startDate = moment(data.StartDate);

    data.Amount = data.Amount ?? "0";
    data.LoanOrganization = data.LoanOrganization ?? "0";
    data.DownPayment = data.DownPayment ?? "0";
    data.ResidualValue = data.ResidualValue ?? "0";
    data.LoanTerm = data.LoanTerm ?? "0";

    data.MaturityDate = toFrontDate(startDate.clone().add(data.LoanTerm, "months").format(DEFAULT_DATABASE_DATE_FORMAT));
    data.StartDate = toFrontDate(startDate.clone().format(DEFAULT_DATABASE_DATE_FORMAT));
    data.InterestRate = data.InterestRate + "%";
    data.CurrentBalance = data.Amount - data.UsedAmount;

    function getFields() {
        return {
            LoadName: new Field('LoadName', '', [''], false, "text", {
                    icon: () => HashtagIcon,
                    label: "loan_number",
                    addContainerClass: "col-span-full"
                },
            ),
            Description: new Field('Description', '', [''], false, "text", {
                icon: () => InformationCircleIcon,
                label: "purpose",
                addContainerClass: "col-span-full"
            }),
            Amount: new Field('Amount', '', [''], false, "money", {
                icon: () => CurrencyDollarIcon,
                label: "original_amount"
            }),
            LoanOrganization: new Field('LoanOrganization', '', [''], false, "text", {
                icon: () => BuildingOfficeIcon,
                // fieldOptions: () => {
                //     return (
                //         <div>
                //             <Tippy content={<span>Visit Organization Page</span>}>
                //                 <button
                //                     className="btn btn-icon w-8 h-8 p-1 text-tm-gray-600 hover:text-tm-gray-900"
                //                     onClick={() => {
                //                         console.log('selectedItem', data)
                //                         if (selectedItem?.Customer) {
                //                             window.open(`/customers/info/${selectedItem.OrganizationID}/${selectedItem.Customer.CustomerID}`, '_blank')
                //                         } else if (selectedItem?.Carrier) {
                //                             window.open(`/carriers/info/${selectedItem.OrganizationID}/${selectedItem.Carrier.CarrierID}`, '_blank')
                //                         } else if (selectedItem?.Vendor) {
                //                             window.open(`/vendors/info/${selectedItem.OrganizationID}/${selectedItem.Vendor.VendorID}`, '_blank')
                //                         }
                //                     }}
                //                 >
                //                     <ArrowTopRightOnSquareIcon
                //                         className={'w-5 h-5'}/>
                //                 </button>
                //
                //             </Tippy>
                //         </div>)
                // }
            }),
            DownPayment: new Field('DownPayment', '', [], false, "money", {
                    icon: () => EnvelopeIcon,
                    addContainerClass: "col-span-full"
                }
            ),
            ResidualValue: new Field('ResidualValue', '', [], false, "money", {
                icon: () => ReceiptPercentIcon,
                addContainerClass: "col-span-full"
            }),
            StartDate: new Field('StartDate', '', [], false, "text", {icon: () => CalendarIcon}),
            MaturityDate: new Field('MaturityDate', '', [], false, "text", {
                icon: () => CalendarIcon,
            }),
            LoanTerm: new Field('LoanTerm', '', [], false, "text", {
                icon: () => CreditCardIcon,
            }),
            InterestRate: new Field('InterestRate', '', [], false, "text", {
                icon: () => ChartBarSquareIcon,
            }),
            MonthlyPayment: new Field('MonthlyPayment', '', [], false, "money", {
                icon: () => BanknotesIcon,
            }),
            CurrentBalance: new Field('CurrentBalance', '', [], false, "money", {
                icon: () => ScaleIcon,
            }),
        }
    }


    return <div className="px-4 py-5 max-w-2xl mx-auto">
        <div
            className="grid grid-cols-2 bg-inverse rounded-xl border-tm-gray-300 border shadow-card mb-6">
            {fields.map((field, i) => {
                return (
                    <DataCard
                        key={field.name}
                        displayField={field}
                        fieldsData={data}
                        className={classNames(
                            'py-1.5 flex items-center px-6',
                            i < fields.length - (i % 2 ? 1 : 2) ? 'border-b border-tm-gray-300' : undefined)
                        }
                        translate={translate}
                    />
                )
            })}
        </div>
    </div>
}