import {
    DRIVER_STATUS_BOOKED,
    DRIVER_STATUS_ON_LOAD,
    DRIVER_STATUS_TYPE_TIPPY_TEXT,
    DRIVER_STATUS_TYPES, UPDATE_PERM
} from "../../../../util/util-constants";
import Badge from "../index";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import React from "react";
import Tooltip from "../../tooltip";
import Resources from "../../../../data/services/resources";
import {checkPerm, classNames, getDriverBadgeClass, openInNewTab} from "../../../util/util-helpers";

export default function DriverStatusBadge({item, translate, addClass}) {
    return (
        <Tooltip
            content={<span>{translate(DRIVER_STATUS_TYPE_TIPPY_TEXT[item.StatusID])}</span>}
        >
            <span>
                <Badge
                    addClass={classNames("items-center", addClass ?? "")}
                    onClick={(e) => {
                        if (!!item.OnLoadID && (item.StatusID === DRIVER_STATUS_BOOKED || item.StatusID === DRIVER_STATUS_ON_LOAD)) {
                            e.stopPropagation();
                            checkPerm(Resources.LoadInfo, UPDATE_PERM) && openInNewTab(`/loads/info/${item.OnLoadID}`);
                        }
                    }}
                    type={getDriverBadgeClass(item.StatusID)}
                >
                    <div className="flex">
                        {DRIVER_STATUS_TYPES[item.StatusID]}

                        {!!item.OnLoadID && (item.StatusID === DRIVER_STATUS_BOOKED || item.StatusID === DRIVER_STATUS_ON_LOAD) && (
                            <ArrowTopRightOnSquareIcon
                                className={'w-5 h-5 ml-4'}
                            />
                        )}
                    </div>
                </Badge>
            </span>
        </Tooltip>
    )
}