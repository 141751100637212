import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../../data/services/resources'
import {hideConfirmDialog, showConfirmDialog} from '../../../../data/actions/ui'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {createResource, getResource, resetResource, updateResource} from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import InfoTab from './infoTab'
import moment from 'moment-timezone'
import {deleteDocument, download} from '../../../../data/actions/download'
import {CREATE_PERM, FIXED_ASSET_TYPES} from '../../../../util/util-constants'
import {
    checkPerm,
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getDocumentTypesFor,
    getProp
} from '../../../../common/util/util-helpers'
import {currentDate} from '../../../../common/util/util-dates'
import Tippy from '@tippyjs/react'
import {InformationCircleIcon} from '@heroicons/react/24/outline'
import GroupsViewDialog from '../../../my-company/groups-view/groups-view-dialog'
import RedirectDialog from "../../../../common/components/modal/redirect-dialog";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";
import DocumentsCreateTab from "../../../../common/components/tabs/documents-tab/create-documents";
import DocumentsUpdateTab from "../../../../common/components/tabs/documents-tab/update-documents";
import InfoParagraph from "../../../../common/components/info-paragraph";
import MobileTabs from "../../../../common/components/tabs-navigation/mobile-tabs";
import NavResponsive from "../../../../common/components/nav-responsive";
import GoBackButton from "../../../../common/components/layout/layout-components/go-back-button";
import Page from "../../../../common/components/layout/layout-components/page";
import Layout from "../../../../common/components/layout";
import FieldOptions from "../../../../common/components/fields/field-options";
import {fillFieldsFromData} from "../../../../common/util/util-fields";

class AccountingAssetCreateView extends Component {

    constructor(props) {
        super(props)
        const tabs = [
            {
                name: 'AssetsAssetsInfo',
                resource: Resources.AssetsAssetsInfo,
                current: true,
                visible: true
            },
            {
                name: 'AssetsAssetsDocuments',
                resource: Resources.AssetsAssetsDocuments,
                current: false,
                visible: true
            }
        ]

        this.state = {
            tabs: tabs,
            selectedTab: Resources.AssetsAssetsInfo,

            fields_info: this.getFieldsInfo(),
            fields_documents: this.getDocuments(),

            footerIsSticky: true,
            canSwapTabs: true,
            emailExist: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {},

        }
    }

    componentDidMount() {
        if (this.getID()) {
            this.fetchData()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.resource !== prevProps.resource && !!this.props.resource.create && !this.getID()) {
            this.toggleRedirectDialog()
        }
        if (!!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS') && !this.props.ui.updateOfficeDialog) {
            this.setState({emailExist: true, selectedTab: Resources.AssetsAssetsInfo})
        }
        if (this.props.resource !== prevProps.resource || this.props.secondResource !== prevProps.secondResource) {
            this.setState({fields_info: this.getFieldsInfo(), fields_documents: this.getDocuments()})
        }
        if (prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === Resources.AssetsAssetsInfo && !!this.getID()) {
            this.fetchData()
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AssetsAssetsInfo,
            query: {id: this.getID()}
        }))
    }

    getDocuments = () => {
        if (this.getID()) {
            return getProp(this.props.secondResource, 'data', [])
        }
        return []
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }
    getFieldsInfo = () => {
        const colSpan3 = "col-span-3";
        const colSpan4 = "col-span-4";
        let fields = {
            FixedAsset: new Field('FixedAsset', this.props.location?.query?.input ? this.props.location.query.input : '', ['empty'], false, 'text', {
                addContainerClass: colSpan3,
            }),
            AssetNumber: new Field('AssetNumber', '', [], false, 'text', {
                addContainerClass: colSpan3,
            }),
            SerialNumber: new Field('SerialNumber', '', [], false, 'text', {
                addContainerClass: colSpan3,
            }),
            FixedAssetTypeID: new Field('FixedAssetTypeID', '', ['empty'], false, 'select', {
                addContainerClass: colSpan3,
            }, {
                values: FIXED_ASSET_TYPES
            }),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                addContainerClass: colSpan4,
                label: 'PurchaseAmount',
            }), StartingAmount: new Field('StartingAmount', '', ['empty'], false, 'money', {
                addContainerClass: colSpan4,
            }),
            SalvageAmount: new Field('SalvageAmount', '', ['empty'], false, 'money', {
                addContainerClass: colSpan4,
            }),
            PurchaseDate: new Field('PurchaseDate', '', ['empty'], false, 'date', {
                addContainerClass: colSpan3,
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                addContainerClass: colSpan3,
            }, {}),
            EndDate: new Field('EndDate', '', ['empty'], true, 'date', {
                addContainerClass: colSpan3,
            }, {}),
            WarrantyExpirationDate: new Field('WarrantyExpirationDate', '', [], false, 'date', {
                addContainerClass: colSpan3
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                addContainerClass: colSpan4,
                label: 'FixedAssetAccountID'
            }),
            AccumulatedAccountID: new Field('AccumulatedAccountID', '', [], false, 'select-search', {
                addContainerClass: colSpan4
            }),
            DepExpenseAccountID: new Field('DepExpenseAccountID', '', [], false, 'select-search', {
                addContainerClass: colSpan4
            }),
            PurchaseOrderID: new Field('PurchaseOrderID', '', [], false, 'select-search', {
                addContainerClass: colSpan4,
            }),
            OfficeID: new Field('OfficeID', !this.getID() ? getDefaultUserOfficeValues() : '', ['empty'], false, 'select-search', {
                addContainerClass: colSpan4,
            }),
            ContactGroupID: new Field('ContactGroupID',
                !this.getID() ? getDefaultContactGroup() ? getDefaultContactGroup() : null : null,
                ['empty'], false, 'select-search',
                {
                    addContainerClass: colSpan4,
                    label: 'AssetsGroupID',
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                        isVisible: !!it.value
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    isClearable: true,
                    api: 'api/' + Resources.ContactGroupsQuick,
                    query: {},
                    searchMap: (it) => ({
                        label: it.ContactGroupName,
                        value: it.ContactGroupID,
                        metadata: it
                    })
                }
            ),
            Description: new Field('Description', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full'
            })
        }

        if (this.getID()) {
            let data = getProp(this.props.resource, 'data.accounting/assets/info', {})
            fields = fillFieldsFromData(fields, data)

            fields.EndDate.props.minDate = moment(data.StartDate).add('1', 'months') ?? ''

            const disabledFields = ['StartingAmount', 'Amount', 'PurchaseOrderID', 'SalvageAmount', 'StartDate', 'EndDate', 'FixedAssetTypeID', 'AccountID', 'AccumulatedAccountID', 'DepExpenseAccountID']
            disabledFields.forEach(field => fields[field].disabled = true)
        } else {
            fields.PurchaseOrderID.type = 'hidden'
        }

        return fields
    }

    handleCancelClick = () => {
        this.setState({
            fields_info: this.getFieldsInfo(),
            canSubmit: false
        })
    }

    toggleRedirectDialog = () => {
        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.id,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,
            canSubmit: false
        })
    }

    showConfirmDialog = () => {
        this.setState({
            officeConfirmText: `Accounting Asset created successfully`,
        })
        this.props.dispatch(showConfirmDialog())
    }

    handleScroll = () => {
        if (this.state.footerIsSticky && window.pageYOffset > document.documentElement.scrollHeight - document.documentElement.clientHeight - 150) {
            this.setState({
                footerIsSticky: false
            })
        }

        if (!this.state.footerIsSticky && window.pageYOffset < document.documentElement.scrollHeight - document.documentElement.clientHeight - 150) {
            this.setState({
                footerIsSticky: true
            })
        }
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    confirmModal = () => this.setState({selectedTab: this.state.reserveTab, canSwapTabs: true})

    hideConfirmDialog = () => this.props.dispatch(hideConfirmDialog())

    // mergeDateTime = (date, time) => {
    //     if (time) {
    //         if (LocalStorage.get('user')?.Contact?.DateTimeFormat.split(' ').length > 2) {
    //             return date.split(' ')[0] + ' ' + convertTime12to24(moment(time).format(getUserTimeFormat()))
    //         }
    //         return date.split(' ')[0] + ' ' + moment(time).format(getUserTimeFormat())
    //     }
    //     return date
    // }

    submit = () => {
        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info)
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info)) {
                if (this.getID()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.fields_info), {
                                AccountID: this.state.fields_info.AccountID.value?.value,
                                AccumulatedAccountID: this.state.fields_info.AccumulatedAccountID.value?.value,
                                DepExpenseAccountID: this.state.fields_info.DepExpenseAccountID.value?.value,
                                FixedAssetID: this.getID(),
                            }),
                        query: {
                            id: this.getID()
                        },
                        resource: Resources.AssetsAssetsInfo,
                        piggyResource: Resources.AssetsAssetsInfo,
                        errorMessage: true, successMessage: this.props.translate('text.asset_edited'),
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields_info)),
                        resource: Resources.AssetsAssets,
                        document: this.state.fields_documents,
                        documentResource: Resources.AssetsAssetsDocuments,
                    }))
                }
            } else {
                this.handleTabChange(Resources.AssetsAssetsInfo)
            }
        })
    }

    acceptDocuments = acceptedFiles => {
        this.setState({
            fields_documents: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })),
        })
    }

    removeDocument = (index) => {
        let fields_documents = this.state.fields_documents
        fields_documents.splice(index, 1)
        this.setState({
            fields_documents: Array.from(fields_documents),
            canSubmit: true
        })
    }

    // uploadDocument = (files, descriptions) => {
    //     this.props.dispatch(uploadDocument({
    //         user: LocalStorage.get('user'),
    //         files: files,
    //         id: this.getID(),
    //         query: {
    //             FixedAssetID: this.getID(),
    //         },
    //         Descriptions: descriptions,
    //         resource: Resources.AssetsAssetsDocuments,
    //         piggySecondResource: Resources.AssetsAssetsDocuments
    //     }))
    // }

    deleteDocument = (document) => {
        this.props.dispatch(deleteDocument({
            user: LocalStorage.get('user'),
            query: {
                FixedAssetID: this.getID(),
            },
            params: {
                FixedAssetDocumentID: document.FixedAssetDocumentID,
                FixedAssetID: this.getID(),
            },
            resource: Resources.AssetsAssetsDocuments,
            piggySecondResource: Resources.AssetsAssetsDocuments
        }))
    }

    downloadDocument = (document) => {
        const fileExt = document?.OriginalFilename.split('.').pop()

        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AssetsAssetsDocuments,
            query: Object.assign({
                FixedAssetDocumentID: document.FixedAssetDocumentID,
                FixedAssetID: this.getID(),
                format: fileExt,
                name: 'document_' + currentDate() + '.' + fileExt
            })
        }))
    }

    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields_info, name, value)
        if (name === 'StartDate') {
            fields.EndDate.props.minDate = moment(value).add('1', 'months') ?? ''
            fields.EndDate.disabled = this.getID()
            const isAfter = moment(fields.EndDate.props.minDate).isAfter(fields.EndDate.value)

            if (isAfter) {
                fields.EndDate.value = ''
            }
        }
        this.setState({canSubmit: true, fields_info: fields})
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate, resource} = this.props

        const {FixedAsset, ArchivedDate} = getProp(resource, 'data.accounting/assets/info', {})

        return (
            <Layout
                hasFooter={!this.getID() || this.state.selectedTab === Resources.AssetsAssetsInfo} {...this.props}>
                <Page>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flex justify-between items-center">
                                    <div className="mb-2 flex justify-start items-center">
                                        <GoBackButton
                                            translate={this.props.translate}
                                            history={this.props.history}
                                            path={'/accounting-assets-inventory'}
                                        />
                                        <h1 className={'mr-5 text-3xl ml-2'}>
                                            {translate(`text.${this.getID() ? 'Edit' : 'create'}`)} {translate('page.heading.AccountingAsset')}
                                            {FixedAsset && (
                                                <span className="text-tm-gray-500"> - {FixedAsset}
                                                    {ArchivedDate && (
                                                        <Tippy content={<span>Archived</span>}>
                                                            <i className="iconsminds-delete-file"/>
                                                        </Tippy>
                                                    )}
                                                </span>
                                            )}
                                        </h1>
                                    </div>
                                </div>

                                <div className="mb-5 hidden sm:block">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                {!this.getID() && (
                                    <InfoParagraph
                                        message={translate("text.AssetCreation")}
                                        className={"mb-2"}
                                    />
                                )}

                                <div className="tab-content">
                                    <div className="tab-pane show active">
                                        <div className="row">
                                            <div className="col">
                                                {this.state.selectedTab === Resources.AssetsAssetsInfo && (
                                                    <InfoTab
                                                        handleInputChange={this.handleInputChange}
                                                        handleCancelClick={this.handleCancelClick}
                                                        submit={this.submit}
                                                        footerIsSticky={this.state.footerIsSticky}
                                                        canSubmit={this.state.canSubmit}
                                                        fields_info={this.state.fields_info}
                                                        {...this.props}
                                                    />
                                                )}
                                                {this.state.selectedTab === Resources.AssetsAssetsDocuments && !!this.getID() && (
                                                    <DocumentsUpdateTab
                                                        id={this.getID()}
                                                        dispatch={this.props.dispatch}
                                                        translate={this.props.translate}
                                                        resource={this.props.resource}
                                                        thirdResource={this.props.thirdResource}

                                                        dialogTitle={'Assets Documents'}

                                                        documentTypes={getDocumentTypesFor('IsAccountingDocument')}
                                                        sortBy={'Description'}
                                                        primaryKey={'FixedAssetDocumentID'}

                                                        download={this.props.download}
                                                        resourceName={Resources.AssetsAssetsDocuments}

                                                        searchFields={{
                                                            query: new Field('query', '', [], false, 'text', {
                                                                containerClass: 'col-md-4'
                                                            }),
                                                            DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                                                containerClass: 'col-md-4'
                                                            }, {
                                                                all: true,
                                                                values: getDocumentTypesFor('IsAccountingDocument')
                                                            }),
                                                            DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                                                containerClass: 'col-md-4'
                                                            }, {
                                                                all: true
                                                            }),
                                                        }}
                                                    />
                                                )}

                                                {this.state.selectedTab === Resources.AssetsAssetsDocuments && !this.getID() && (
                                                    <div className="card">
                                                        <div className="card-body bg-inverse">
                                                            <DocumentsCreateTab
                                                                fields={this.state.fields_documents}
                                                                translate={this.props.translate}

                                                                dialogTitle={'Assets Documents'}

                                                                documentTypes={getDocumentTypesFor('IsAccountingDocument')}
                                                                handleUploadDocument={this.acceptDocuments}
                                                                handleDeleteDocument={this.removeDocument}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!this.getID() && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleCancelClick}
                            actionSubmit={checkPerm(Resources.Claims, CREATE_PERM) && this.submit}
                        />
                    )}

                </Page>

                <RedirectDialog
                    show={this.state.isRedirectDialogVisible}
                    title={translate('card_header.accounting_asset_created')}
                    onClose={() => {
                        this.props.dispatch(resetResource())
                        this.setState({
                            createdResourceID: null,
                            isRedirectDialogVisible: false
                        })
                    }}

                    createdLabel={translate('btn.accounting_asset')}
                    createdLink={`/accounting-assets/${this.state.createdResourceID}`}

                    newLabel={translate('btn.create_new')}

                    listLabel={translate('btn.list')}
                    listLink="/accounting-assets-inventory"

                    dashboardLabel={translate('btn.dashboard')}

                    {...this.props}
                >
                    <p>{translate('message.accounting_asset_created')}</p>
                </RedirectDialog>

                <GroupsViewDialog
                    groupID={this.state.fields_info?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />

            </Layout>
        )
    }
}

export default connect(state => state)(AccountingAssetCreateView)

