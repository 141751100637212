import React from "react";
import Env from "../../../util/env";
import {getJWT} from "../../util/util-auth";

export default function Image({resourcePath, className, params, onError, onLoad, alt = ""}) {
    return (
        <img
            className={className}
            src={
                Env.getApiUrl("api/" + resourcePath, Object.assign({
                    token: getJWT().access_token,
                }, params))
            }
            onError={onError}
            onLoad={onLoad}
            alt={alt}
        />
    )
}