import moment from "moment-timezone";
import {classNames, parseHtmlMessageWithMentions} from "../../../../common/util/util-helpers";
import React, {useEffect, useRef} from "react";
import {showModal} from "../../../../data/actions/ui";
import {useDispatch} from "react-redux";
import ImageAvatarLoader from "../../../../common/components/image/image-avatar-loader";
import {DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE} from "../../../../util/util-constants";
import {getUserTimezone} from "../../../../common/util/util-dates";


export default function ChatMessages({messages = [], myID}) {
    const dispatch = useDispatch();

    /** Constants
     ================================================================= */
    let prevMessageTime = ''
    let prevMessageFromID = ''
    let isSameDay = false

    const messagesRef = useRef({});

    /** Helper functions
     ================================================================= */
    const handleButtonClick = (e) => {
        const contactID = e.target.getAttribute('data-contact-id');

        if (contactID) {
            dispatch(showModal('ViewContactCard', {ContactID: contactID}));
        }
    }

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        Object.values(messagesRef.current).forEach((message, messageIndex) => {
            const buttons = messagesRef.current[messageIndex].getElementsByTagName('button');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', handleButtonClick);
            }
        })
    }, [messagesRef]);

    /** Render
     ================================================================= */
    return messages.map((message, messageIndex) => {
        let samePersonMessage = message.ContactID === prevMessageFromID
        prevMessageFromID = message.ContactID

        const myMessage = message.ContactID === myID
        let duration = '';
        let areMessagesCloseTime = false
        const messageDate = moment.utc(message.DateTime, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone())

        isSameDay = false
        if (prevMessageTime) {
            if (prevMessageTime.isSame(messageDate, 'day')) {
                isSameDay = true
            }
        }

        if (prevMessageTime) {
            duration = moment.duration(messageDate.diff(prevMessageTime))
            const diff = duration.asMinutes()
            if (diff < 1) {
                areMessagesCloseTime = true
            }
            prevMessageTime = moment.utc(message.DateTime, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone())
        } else {
            prevMessageTime = moment.utc(message.DateTime, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(getUserTimezone())
        }

        return (
            <div>
                {!isSameDay && (
                    <div
                        className="text-center mb-2 text-tm-gray-500">{messageDate.isSame(new Date(), 'day') ? 'Today' : moment(messageDate).format('YYYY-MM-DD')}</div>
                )}

                {(!areMessagesCloseTime || !samePersonMessage) && (
                    <p className={classNames(
                        "text-sm text-tm-gray-600 mb-1",
                        !myMessage ? "text-right" : undefined
                    )}>
                            <span
                                className="font-bold">{message.Contact}
                            </span>

                        {" "}

                        <span
                            className="text-xs">
                                {formatChatTime(messageDate)}
                            </span>
                    </p>
                )}

                <div
                    className={classNames(myMessage ? 'chat-message flex' : 'flex items-end justify-end')}>
                    <div className="flex items-end">
                        <div
                            className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                            <div>
                                    <span
                                        ref={(element) => (messagesRef.current[messageIndex] = element)}
                                        dangerouslySetInnerHTML={{ __html: parseHtmlMessageWithMentions(message.Message) }}
                                        className={classNames(
                                            'px-4 py-2 rounded-lg inline-block text-tm-gray-900 space-y-1',
                                            myMessage ? 'bg-inverse border border-tm-gray-200 rounded-bl-none' : 'ring-4 ring-primary-transparent bg-inverse rounded-br-none'
                                        )}
                                    />
                            </div>
                        </div>
                    </div>

                    {!myMessage && (
                        <ImageAvatarLoader
                            addClass="bg-tm-gray-100 rounded-full"
                            ContactID={message.ContactID}
                            size="sm"
                            hasImagePath={true}
                        />
                    )}
                </div>
            </div>
        )
    })
}

const formatChatTime = (messageDate) => {
    const dateFormat = 'HH:mm'

    return moment(messageDate, 'YYYY-MM-DD HH:mm:ss').format(dateFormat)
}
