import React, {useState} from "react";
import Tippy from "@tippyjs/react";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import ModalConfirm from "../../../modal/modal-confirm";

export default function GoBackButton({translate, history, path, canSubmit}) {

    const [confirmDialog, setConfirmDialog] = useState(false)

    const closeConfirmRedirectDialog = () => {
        setConfirmDialog(false)
    }

    const goBack = () => {
        if (canSubmit) {
            setConfirmDialog(true)
        } else {
            history.push(path);
        }
    }

    return (
        <>
            <Tippy
                content={
                    <span>{translate("text.back_to")} {translate("path." + path.substring(1))} {translate("text.list")}</span>
                }
            >
                <button onClick={() => goBack()} className="btn btn-header">
                    <ArrowLeftIcon className="w-5 h-5"/>
                </button>
            </Tippy>

            <ModalConfirm
                type="default"
                title={translate("dialog_heading.unsaved_changes_on_tab")}
                show={confirmDialog}
                onClose={closeConfirmRedirectDialog}
                buttonLabel={translate("btn.proceed")}
                closeButtonLabel={translate('btn.stay_on_current_tab')}
                translate={translate}
                onConfirm={() => history.push(path)}
            >
                <p>{translate("text.unsaved_changes_on_tab")}</p>
                <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
            </ModalConfirm>
        </>
    )
}
