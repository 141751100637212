import React, {useEffect, useState} from 'react'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {getResource, updateResource} from '../../../data/actions/resource'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import {UPDATE_PERM} from "../../../common/util/util-consts";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {LoaderSmall} from "../../../common/components/loader";
import FieldContainer from "../../../common/components/fields/field-container";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";

const GeneralTab = (props) => {
    const {translate, resource, dispatch} = props
    /** Get default fields
     ================================================================= */
    const getFields = () => {
        return {
            WeekStart: new Field('WeekStart', getProp(props, 'resource.data.WeekStart', ''), [''])
        }
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [canSubmit, setCanSubmit] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setFields(getFields())
    }, [resource])

    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.CompanyGeneral
        }))
    }

    /** UI Events
     ================================================================= */
    const submit = () => {
        if (canSubmit) {
            setCanSubmit(false)
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(fields)),
                errorMessage: true, successMessage: `General updated`,
                resource: Resources.CompanyGeneral,
                piggyResource: Resources.CompanyGeneral,
            }))
        }
    }

    const cancel = () => {
        setFields(getFields())
        setCanSubmit(false)
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
        setCanSubmit(true)
    }

    const DayID = getLookup('Day', 'DayID', 'Day')

    return (
        <React.Fragment>
            <Card>
                <PageHeader
                    title={translate('text.general')}
                    titleClass="mr-5 text-2xl"
                />

                {resource.isLoading && (
                    <div className="m-5 text-center">
                        <LoaderSmall/>
                    </div>
                )}

                {!resource.isLoading && (
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6">
                            <FieldContainer
                                item={fields.WeekStart}
                                translate={translate}
                            >
                                <FieldSelectSearch
                                    className="form-control"
                                    values={DayID}
                                    onChange={handleInputChange}

                                    {...fields.WeekStart}
                                    placeholder={''}
                                    keySort={true}
                                    addClass={'form-control'}/>
                            </FieldContainer>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                )}
            </Card>

            <PageFooter
                translate={translate}
                canSubmit={canSubmit}
                actionCancel={cancel}
                actionSubmit={checkPerm(Resources.CompanyGeneral, UPDATE_PERM) && submit}
            />
        </React.Fragment>
    )
}

export default GeneralTab
