import DisplayDataField from "./display-data-field";
import React from "react";

export default function DisplayDataFieldContainer({fields, data, translate}) {
    return (
        <div className="max-w-md mx-auto space-y-3">
            {Object.values(fields).filter(it => data[it.name] !== undefined && data[it.name] !== " ").map(field => {
                return (
                    <DisplayDataField
                        key={field.name}
                        displayField={field}
                        fieldsData={data}
                        translate={translate}
                    />
                )
            })}
        </div>
    )
}