import React, {useEffect, useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {useDispatch, useSelector} from 'react-redux'
import {DEFAULT_CRUD_STATE, UPDATE_PERM} from '../../../util/util-constants'
import Resources from '../../../data/services/resources'
import {getSecondResource} from '../../../data/actions/secondResource'
import PayDateFilter from '../../accounting/carrier-pay/carrier-pay-date-filter'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    mileageToErrorMessage,
    openInNewTab,
    returnMileage,
    saveTableColumns
} from '../../../common/util/util-helpers'
import EyeIcon from '@heroicons/react/24/outline/EyeIcon'
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import Tippy from "@tippyjs/react";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import {ArrowPathIcon, ArrowsPointingOutIcon} from "@heroicons/react/24/outline";
import {ArrowsPointingInIcon} from "@heroicons/react/20/solid";
import {download} from "../../../data/actions/download";
import {currentDate} from "../../../common/util/util-dates";
import {getThirdResource} from "../../../data/actions/thirdResource";
import {showModal} from "../../../data/actions/ui";
import moment from "moment-timezone";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ReactPortal from "../../../common/components/layout/layout-components/react-portal";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Tooltip from "../../../common/components/tooltip";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";

export default function CommissionsTab({
                                           translate,
                                           pagePath
                                       }) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const secondResource = useSelector((state) => state.secondResource)
    const thirdResource = useSelector((state) => state.thirdResource)
    const isLoading = secondResource.isLoading
    const data = secondResource?.data?.list ?? []
    const dataLoads = thirdResource?.data?.list ?? []
    const isLoadingLoads = thirdResource.isLoading

    /** Helpers
     ================================================================= */
    const renderNameField = (item) => {
        if (!item) return null
        return (
            <button
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                }}
                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
            >
                {item['LegalName'] ? item['LegalName'] : (item['FirstName'] + ' ' + item['LastName'])}
            </button>
        )
    }

    const handleCustomersClick = (item) => {
        dispatch(showModal('ViewCustomerCard', item));
    }

    const getFields = () => {
        return {
            Name: new Field('Name', '', [''], false, 'text', {
                addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                render: (item) => renderNameField(item)
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            Price: new Field('Price', '', [''], false, 'money', {omitSort: true}),
            CalculationPrice: new Field('CalculationPrice', '', [''], false, 'money', {omitSort: true}),
            LoadCount: new Field('LoadCount', '', [''], false, 'integer', {omitSort: true}),
            LoadRefs: new Field('LoadRefs', '', [''], false, 'custom', {
                render: (it) =>
                    <div className={'flex items-center'}>
                        <div className={''}>{it.LoadRefs?.slice(0, 19)}</div>
                        <button
                            className="btn btn-icon text-tm-gray-600 hover:text-tm-gray-900"
                            title={translate('btn.view_loads')}
                            onClick={() => {
                                handleOpenLoadsModal(it)
                            }}
                        >
                            <EyeIcon className={'w-5 h-5'}/>
                        </button>
                    </div>
                , omitSort: true
            })
        }
    }

    const getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {addContainerClass: 'relative z-40'}, {isClearable: true}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {addContainerClass: 'relative z-40'}, {isClearable: true}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                data: {1: 'Pickup', 2: 'Delivery'},
                addClass: 'w-full',
                addButtonClass: 'uppercase font-semibold',
                labelType: 'stack',
                isRequiredFilter: true
            }, {}),
            ContactID: new Field('ContactID', '', [], false, 'select-search'),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search'),
        }
    }

    const getLoadsFields = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellLink
                            to={"/loads/info/" + it.LoadID}
                            openInNewTab={true}
                        >
                            <div>{it.LoadNumber}</div>
                        </CellLink>
                    )
                }
            }),
            Customer: new Field('Customer', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <CellButton
                            onClick={() => handleCustomersClick(item)}
                        >

                            <div>{item.Customer}</div>
                        </CellButton>
                    )
                }
            }),
            PickupDate: new Field('PickupDate', '', [''], false, 'datetime'),
            Pickup: new Field('Pickup', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div>{item.PickupCityName}, {item.PickupState} {item.PickupPostalCode}</div>
                    )
                }
            }),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
            Destination: new Field('Destination', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div>{item.DestinationCityName}, {item.DestinationState} {item.DestinationPostalCode}</div>
                    )
                }
            }),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                render: (item) => <div className={"text-right"}>{returnMileage(item.EmptyMiles)}</div>
            }),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                render: (item) => <div
                    className={"text-right"}>{mileageToErrorMessage(returnMileage(item.TotalMiles), translate)}</div>
            }),
            Price: new Field('Price', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.Price)}</div>,
                colFilter: true
            }, {icon: false}),
            CalculationPrice: new Field('CalculationPrice', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.CalculationPrice)}</div>,
                colFilter: true
            }, {icon: false}),
            Amount: new Field('Amount', '', [''], false, "float", {
                render: (it) => <div className={"text-right"}>{genericMoneyFormatter(it.Amount)}</div>,
                colFilter: true
            }, {icon: false}),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {})
        }
    }


    const getResourceName = () => {
        return Resources.CommissionReport
    }

    const getQuery = () => {
        return {
            ...DEFAULT_CRUD_STATE,
            ...FieldsManager.getFieldKeyValues(queryFilterFields),
            sort: sort,
            sortBy: sortBy
        }
    }

    const getLoadsTableOptions = (pagePath, translate) => {
        const tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: false
            },
            columns: { // for performance table mode 20+ limit
                PickupDate: {
                    minWidth: 220,
                },
                Pickup: {
                    minWidth: 220,
                },
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getLoadsFields(), tablePageDefaults, pagePath, translate)
    }

    /** State
     ================================================================= */
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFields())
    const [isLoadsModalOpen, setIsLoadsModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isTableLoadsFullScreen, setIsTableLoadsFullScreen] = useState(false)
    const [tableOptionsLoads, setTableOptionsLoads] = useState(getLoadsTableOptions(pagePath, translate))
    const [sort, setSort] = useState("DESC")
    const [sortBy, setSortBy] = useState("Name")

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: getQuery()
        }))
    }

    const fetchLoadsData = (item) => {
        let ContactID = item?.ContactID
        let LoadIDs = item?.LoadIDs.split(',').map((it) => {
            return it
        })

        dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({}, getQuery(), {LoadIDs: LoadIDs, ContactID: ContactID})
        }))
    }

    const downloadExcelLoads = () => {
        let ContactID = selectedItem?.ContactID
        let LoadIDs = selectedItem?.LoadIDs.split(',').map((it) => {
            return it
        })

        dispatch(download({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'commissions_report_loads' + currentDate() + '.xlsx'
            }, getQuery(), {LoadIDs: LoadIDs, ContactID: ContactID})
        }))
    }

    function downloadExcel() {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_commissions_' + currentDate() + '.xlsx'
            }, getQuery())
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [sort, sortBy, queryFilterFields])

    /** UI events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        let queryFieldsUpdate = FieldsManager.updateField(queryFilterFields, name, value)

        if (name === 'StartDate') {
            queryFieldsUpdate.EndDate.props.minDate = value
        }
        if (name === 'EndDate') {
            queryFieldsUpdate.StartDate.props.maxDate = value
        }

        if (name === 'DateType' && !value) {
            queryFieldsUpdate.DateType.value = 1

        }

        setQueryFilterFields(queryFieldsUpdate)
    }

    const handleOpenLoadsModal = (it) => {
        setSelectedItem(it)
        setIsLoadsModalOpen(true)
        fetchLoadsData(it)
    }

    const handleClearFiltersClick = () => {
        let queryFilterFieldsUpdate = queryFilterFields;

        queryFilterFieldsUpdate = Object.values(queryFilterFieldsUpdate).reduce((memo, it) => {
            it.value = "";
            memo[it.name] = it;

            return memo;
        }, {});

        setQueryFilterFields(queryFilterFieldsUpdate);
    }
    const handleLoadsFullScreenToggle = () => {
        setIsTableLoadsFullScreen(!isTableLoadsFullScreen)
    }

    const setOptionsLoads = (options) => {
        setTableOptionsLoads(options)
        saveTableColumns(pagePath + 'Loads', options)
    }

    const handleUpdateSort = (sortByTmp) => {
        setSort((sortByTmp === sortBy) ? (sort === "ASC" ? "DESC" : "ASC") : "ASC")
        setSortBy(sortByTmp)
    };
    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div className="min-h-[2.25rem] flex flex-wrap max-w-full items-center">
                <ActiveFilters
                    addClass={'flex-wrap'}
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className="flex ml-auto">
                    <Tooltip content={translate('text.download_excel')}>
                        <button
                            className="btn-icon"
                            onClick={downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tooltip>
                </div>
            </div>

            <TableCard addClass={'relative'}>
                <TableFilters
                    hideLimit
                    filterFields={queryFilterFields}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    largeGrid={true}
                    forceDialog
                    customHtml={
                        <PayDateFilter
                            queryFields={queryFilterFields}
                            translate={translate}
                            updateQueryFields={(queryFieldsUpdate) => {
                                setQueryFilterFields(queryFieldsUpdate)
                            }}
                            onQueryChange={handleFilterInputChange}
                            isDateTypeClearable={false}
                            gridCols={"grid-cols-1"}
                        />
                    }
                />

                {!(data.length === 0 && !isLoading) && (
                    <ResourceTable
                        maxHeightClass={'max-h-[calc(100vh-20rem)]'}
                        data={data}
                        fields={getFields()}
                        isLoading={isLoading}
                        translate={translate}
                        limit={1000}

                        sort={sort}
                        sortBy={sortBy}
                        onSortChange={handleUpdateSort}
                        onRowClick={handleOpenLoadsModal}
                    />
                )}

                {(queryFilterFields.StartDate.value || queryFilterFields.EndDate.value) && (
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                )}

                {!(queryFilterFields.StartDate.value || queryFilterFields.EndDate.value) && (
                    <NoRecords
                        show={!isLoading}
                        title={translate('text.atLeastOneDate')}
                        addClass={'pt-16 pb-10'}
                    />
                )}

                {/*<NoRecordsTable*/}
                {/*    show={(data.length === 0) && !isLoading}*/}
                {/*    title={translate('text.atLeastOneDate')}*/}
                {/*    className={'pb-12 pt-16 px-6'}*/}
                {/*/>*/}

            </TableCard>

            <ModalDefault
                show={isLoadsModalOpen}
                widthClass={"max-w-full"}
                title={translate('text.Loads')}
                closeButtonLabel={translate('btn.close')}
                onClose={() => {
                    setIsLoadsModalOpen(false)
                }}
            >
                <ReactPortal
                    isActive={isTableLoadsFullScreen}
                    parent={document.getElementById('headlessui-portal-root')}
                >

                    <TableCard
                        className={
                            classNames(
                                "md:bg-inverse rounded-card md:border border-tm-gray-300 md:shadow-card",
                                isTableLoadsFullScreen
                                    ? "fixed inset-1 z-50"
                                    : "relative"
                            )}
                    >

                        <div className={"flex justify-content-end space-x-3 m-4"}>
                            <div className={"-mr-1"}>
                                <Tippy content={translate('text.download_excel')}>
                                    <button
                                        className="btn-icon"
                                        onClick={downloadExcelLoads}
                                    >
                                        <DocumentArrowDownIcon className="w-6 h-6"/>
                                    </button>
                                </Tippy>
                            </div>

                            <div>
                                <button className={
                                    classNames(
                                        "btn btn-header",
                                        isTableLoadsFullScreen ? "bg-primary text-primary-contrast" : undefined
                                    )
                                }
                                        onClick={handleLoadsFullScreenToggle}
                                >
                                    {!isTableLoadsFullScreen && (
                                        <ArrowsPointingOutIcon className="w-5 h-5"/>
                                    )}

                                    {!!isTableLoadsFullScreen && (
                                        <ArrowsPointingInIcon className="w-5 h-5"/>
                                    )}

                                </button>
                            </div>

                            <div>
                                <div className="flex relative">
                                    <button
                                        className={"btn btn-header z-10"}
                                        onClick={() => {
                                            fetchLoadsData(selectedItem)
                                        }}
                                    >
                                        <ArrowPathIcon className={
                                            classNames(
                                                "w-5 h-5",
                                                isLoadingLoads ? "animate-spin" : undefined
                                            )
                                        }/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <ResourceTable
                            tableKey={'LoadID'}
                            data={dataLoads}
                            fields={getLoadsFields()}
                            options={tableOptionsLoads}
                            saveTableOptions={setOptionsLoads}
                            isLoading={isLoadingLoads}
                            translate={translate}

                            onRowClick={(it) => checkPerm(Resources.LoadInfo, UPDATE_PERM) && openInNewTab('/loads/info/' + it.LoadID)}
                            maxHeightClass={
                                classNames(
                                    isTableLoadsFullScreen ? "max-h-[calc(100vh-9.5rem)]" : undefined,
                                    !isTableLoadsFullScreen && 'max-h-[calc(100vh-29rem)]'
                                )
                            }
                        />


                    </TableCard>
                </ReactPortal>
            </ModalDefault>

        </React.Fragment>
    )
}
