import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    classNames,
    getLookup,
    getProp,
    longTableColumn,
    mileageToErrorMessage,
    returnMileage
} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {DEFAULT_CRUD_STATE, READ_PERM} from "../../../util/util-constants";
import {getSecondResource} from "../../../data/actions/secondResource";
import Tippy from "@tippyjs/react";
import StarSolidIcon from "@heroicons/react/20/solid/StarIcon";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import {toFrontDate} from "../../../common/util/util-dates";
import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import {XMarkIcon} from "@heroicons/react/20/solid";
import StatusProgressTable from "../../../common/components/resource-table/table-components/status-progress-table";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import ResourceTable from "../../../common/components/resource-table";
import NoRecords from "../../../common/components/no-records-found/no-records";
import FireIcon from "@heroicons/react/20/solid/FireIcon";
import Card from "../../../common/components/card";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Badge from "../../../common/components/badge";

export default class SummaryTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            offsetBills: 0,
            fields: this.getFields(),
            canSubmit: false,
            queryFilterFields: this.getQueryFilterFields(),
            queryFilterFieldsBills: this.getQueryFilterFieldsBills(),
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
        this.fetchDataBills()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields()
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadsQuick,
            query: this.getQuery()
        }))
    }

    fetchDataBills = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.Expense,
            query: this.getQueryBills()
        }))
    }

    /** UI Events
     ================================================================= */
    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleFilterInputChangeBills = (name, value) => {
        this.setState({
            queryFilterFieldsBills: FieldsManager.updateField(this.state.queryFilterFieldsBills, name, value),
            offsetBills: 0,
            paginationPage: 1
        }, () => {
            this.fetchDataBills()
        })
    }

    handleUpdateOffsetBills = (offset, num) => {
        this.setState({
            offsetBills: offset,
            paginationPage: num
        }, () => {
            this.fetchDataBills()
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                CarrierID: this.props.id
            })
        }
    }

    getQueryBills = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offsetBills,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFieldsBills),
            OrganizationID: this.props.organizationID
        }
    }

    getStatusBadge = (it) => {
        switch (+it.ExpenseStatusID) {
            case 3:
                return (
                    <div className="inline-block">
                        <Badge type="success"
                               className="block flex-grow-0 text-center px-3 py-0.5 rounded-btn text-sm font-medium">
                            {it.ExpenseStatus}
                        </Badge>

                        <div className="text-tm-gray-600 mt-px">
                            {(!!it?.ExpensePaidDate ? " " + toFrontDate(it.ExpensePaidDate) : "")}
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="inline-block">
                        <Badge type="info"
                               className="block flex-grow-0 text-center px-3 py-0.5 rounded-btn text-sm font-medium"
                        >{it.ExpenseStatus}</Badge>
                        <div className="text-tm-gray-600 mt-px">
                            {it.IsDelayedPayment ? " (Loan)" : ""}
                        </div>
                    </div>
                )
        }
    }

    renderIsApproved = (item) => {
        return (
            <div className={'pr-3'}>
                {!!item.IsApproved ?
                    <div className="pl-4">
                        <div className={"flex justify-center"}>
                            <CheckCircleIcon className="w-5 h-5 text-green-600" />
                        </div>
                        <div className={"text-center"}>{item.ApprovedByContact}</div>
                    </div>
                    :
                    <div className="pl-4 flex justify-center">
                        <XMarkIcon className="w-5 h-5 text-tm-gray-400"/>
                    </div>
                }
            </div>
        )
    }

    /** Fields/Data definitions
     ================================================================= */
    getFields = (item = null) => {
        const fieldTemplates = {
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div key={item.LoadNumber} className="flex">
                            {item.LoadNumber}

                            {(!!item.Favourite || !!item.Marked) && (
                                <div className="ml-auto flex pl-5">
                                    {!!item.Favourite && (
                                        <Tippy
                                            content={this.props.translate('btn.favourite_load')}
                                        >
                                            <StarSolidIcon
                                                className={classNames('h-5 w-5 cursor-pointer text-yellow-400')}
                                            />
                                        </Tippy>
                                    )}

                                    {!!item.Marked && (
                                        <Tippy
                                            content={this.props.translate('btn.load_needs_attention')}
                                        >
                                            <span><FireIcon className='relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse' /></span>
                                        </Tippy>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                },
                colFilter: {
                    name: "LoadID"
                }
            }, {icon: false}),

            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            IsBroker: new Field('IsBroker', '', [''], false, 'custom', {
                render: (it) => !!it.IsBroker ?
                    <span className={'w-5 h-5 font-bold text-indigo-500'}>{this.props.translate("text.brokerage")}</span> :
                    <span className={'w-5 h-5 font-bold text-green-600'}>{this.props.translate("text.carrier")}</span>
            }),
            LoadStatus: new Field('LoadStatus', '', [''], false, 'select', {
                render: (item) => {
                    return (
                        <StatusProgressTable
                            addClass=""
                            LoadStatusID={item.LoadStatusID}
                            translate={this.props.translate}
                        />
                    )
                },
                colFilter: {
                    name: "LoadStatusID",
                    type: "select",
                    select: getLookup("LoadStatus", "LoadStatusID", "LoadStatus")
                }
            }, {all: true, addClass: "min-w-[9rem] form-control", isMulti: true, hasPortal: true}),
            LoadTypeID: new Field('LoadTypeID', "", [''], false, 'select', {}),
            LoadSubTypeID: new Field('LoadSubTypeID', "", [''], false, 'select', {}),
            Customer: new Field('Customer', "", [''], false, 'select-search', {
                render: (item) => {
                    if (!item.CustomerID) return null;
                    return (
                        <button
                            disabled={!checkPerm(Resources.OrganizationsQuick, READ_PERM)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.dispatch(showModal('ViewCustomerCard', {CustomerID: item.CustomerID, OrganizationID: item.OrganizationID}));
                            }}
                            className="btn btn-text hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                        >
                            {item.Customer}
                        </button>
                    )
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            BookedBy: new Field('BookedBy', '', [''], false, 'select-search', {
            }, {isClearable: true}),
            CoveredBy: new Field('CoveredBy', '', [''], false, 'select-search', {
            }, {isClearable: true}),
            Driver: new Field('Driver', '', [''], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (!!item.DriverContactID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.Contacts, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.DriverContactID}));
                                }}
                                className="btn btn-text hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Driver}
                            </button>
                        )
                    } else {
                        return (<span>{item.Driver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CoDriver: new Field('CoDriver', '', [''], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (!!item.CoDriverContactID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.Contacts, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showModal('ViewContactCard', { ContactID: item.CoDriverContactID }));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.CoDriver}
                            </button>
                        )
                    } else {
                        return (<span>{item.CoDriver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            Truck: new Field('Truck', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (!!item.TruckID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.TrucksInfo, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Truck}
                            </button>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Trailer: new Field('Trailer', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (!!item.TrailerID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.Trailer, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Trailer}
                            </button>
                        )
                    } else {
                        return (<span>{item.Trailer}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            SecondTrailer: new Field('SecondTrailer', '', ['']),
            ThirdTrailer: new Field('ThirdTrailer', '', ['']),
            TrailerType: new Field('TrailerType', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Carrier: new Field('Carrier', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true,
                colFilter: true
            }),
            Price: new Field('Price', '', [''], false, "float", {
                render: (it) => genericMoneyFormatter(it.Price),
                colFilter: true
            }, {icon: false}),
            AccessorialAmount: new Field('AccessorialAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            DriverPayAmount: new Field('DriverPayAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            AccessorialDriverPayAmount: new Field('AccessorialDriverPayAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            CarrierAmount: new Field('CarrierAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            CommisionAmount: new Field('CommisionAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            // ExtraChargesAmount: new Field('ExtraChargesAmount', '', [''], false, "float", {
            //     colFilter: true
            // }, {icon: false}),

            PickupDate: new Field('PickupDate', '', [''], false, 'date'),
            Pickup: new Field('Pickup', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupCityName: new Field('PickupCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupPostalCode: new Field('PickupPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupState: new Field('PickupState', '', [''], false, "select", {colFilter: true}, {icon: false, isClearable: true}),
            Destination: new Field('Destination', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationCityName: new Field('DestinationCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationPostalCode: new Field('DestinationPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationState: new Field('DestinationState', '', [''], false, "select", {colFilter: true}, {icon: false, isClearable: true}),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
            NumberOfStops: new Field('NumberOfStops', '', [''], false, 'integer', {hideDialog: true}),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div className={"text-right"}>{mileageToErrorMessage(returnMileage(item.TotalMiles), this.props.translate)}</div>
            }, {icon: false}),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div className={"text-right"}>{returnMileage(item.EmptyMiles)}</div>
            }, {icon: false}),
            TotalWeight: new Field('TotalWeight', '', [''], false, "custom", {
                render: (it) => {
                    return <div className="text-right">{it.TotalWeight}</div>
                }
            }),// TODO Add unit suffix, will be return by API in LBS
            TotalVolume: new Field('TotalVolume', '', [''], false, 'float'), // TODO Add unit suffix, will be return by API in inches
            TotalPieces: new Field('TotalPieces', '', [''], false, "float", {colFilter: true}, {icon: false}),
            LastDetectedAddress: new Field('LastDetectedAddress', '', [''], false, "text", {colFilter: true}, {icon: false}),
            LastDetectedAddressEtaTime: new Field('LastDetectedAddressEtaTime', '', ['']),
            IsHazmat: new Field('IsHazmat', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        item.IsHazmat ?
                            <div className="pl-4"><ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>
                            </div> : null
                    )
                },
                colFilter: {type: "checkbox"}
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsTonu: new Field('IsTonu', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsPODReady: new Field('IsPODReady', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAccessorialHold: new Field('IsAccessorialHold', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            LastCustomerUpdateDate: new Field('LastCustomerUpdateDate', '', [''], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
            CreateDate: new Field('CreateDate', '', [''], false, 'datetimezone', {hideDialog: true}),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)

        if (!!item) {
            filledFields.Customer.value = !!item.Customer ? {label: item.Customer, value: item.CustomerID} : "";
            filledFields.Driver.value = !!item.Driver ? {label: item.Driver, value: item.DriverID} : "";
            filledFields.CoDriver.value = !!item.CoDriver ? {label: item.CoDriver, value: item.CoDriverID} : "";
            filledFields.BookedBy.value = !!item.BookedBy ? {label: item.BookedBy, value: item.BookedByID} : "";
        }

        return filledFields;
    }


    getFieldsBills = () => {
        return {
            ExpenseID: new Field('ExpenseID', '', [], false, 'text'),
            RefNumber: new Field('RefNumber', '', [], false, 'text'),
            Labels: new Field('Labels', '', ['empty'], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            ExpenseTypeID: new Field('ExpenseTypeID', '', [], false, 'select'),
            Date: new Field('Date', '', [], false, 'date'),
            PayTo: new Field('PayTo', '', [''], false, 'custom', {
                render: (it) => it.Organization ?? it.Contact
            }),
            ExpenseStatusID: new Field('ExpenseStatusID', '', [''], false, 'custom', {
                render: (it) => this.getStatusBadge(it)
            }),
            Office: new Field('Office', '', [], false, 'text'),
            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: "ExpenseGroupID"}),
            Amount: new Field('Amount', '', [], false, 'money'),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                addTableHeaderClass:"ml-auto",
                render: (item) => {
                    return <div className={"text-right"}>{genericMoneyFormatter(item.Amount - item.AmountTransferred)}</div>
                }
            }),
            AmountAdvance: new Field('AmountAdvance', '', [''], false, 'custom', {
                render: (item) => {
                    return genericMoneyFormatter(item.AmountAdvance);
                }
            }),
            AmountAdvanceTransferred: new Field('AmountAdvanceTransferred', '', [''], false, 'custom', {
                render: (item) => {
                    return genericMoneyFormatter(item.AmountAdvance - item.AmountAdvanceTransferred);
                }
            }),
            InternalNotes: new Field('InternalNotes', '', [], false, 'custom', {
                render: (item) => (
                    <div>{longTableColumn(item.InternalNotes)}</div>
                )
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetime'),
            IsApproved: new Field('IsApproved','',[''],false,'custom',{
                render: this.renderIsApproved
            })
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getQueryFilterFieldsBills = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props
        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const expenseData = getProp(this.props, "secondResource.data.list", []);
        const expenseCount = getProp(this.props, "secondResource.data.count", 0);

        return (
            <div className={"pb-5"}>
                <Card>
                    <React.Fragment>
                        <PageHeader
                            title={translate('page.heading.Loads')}
                            titleClass="mb-5 text-2xl"
                        />

                        <TableCard addClass="relative z-10">
                            <TableFilters
                                hideLimit
                                filterFields={this.state.queryFilterFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                                onRefreshTable={this.fetchData}
                                isLoading={resource.isLoading}
                            />


                            <ResourceTable
                                data={data}
                                fields={this.getFields()}

                                translate={translate}
                                isLoading={resource.isLoading}

                                options={this.state.tableOptions}

                                limit={this.state.queryFilterFields.limit.value}

                                tableKey={'ID'}
                            />

                            {/*Table footer*/}
                            {!(!data.length && !resource.isLoading) && (
                                <div
                                    className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                >
                                    <div className={"flex items-center w-full pl-3 h-9"}>
                                        <Pagination
                                            count={count}
                                            isLoading={resource.isLoading}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffset(value, currentPage)
                                                    : this.handleFilterInputChange(name, value)
                                            }
                                            pageOffset={this.state.offset}
                                            queryFields={this.state.queryFilterFields}
                                            translate={translate}
                                        />
                                    </div>
                                </div>
                            )}
                            <NoRecords
                                show={(data.length === 0) && !resource.isLoading}
                                title={translate('text.no_records')}
                                addClass={"pt-16 pb-10"}
                            />
                        </TableCard>
                    </React.Fragment>
                </Card>


                <Card addClass={"mt-5"}>
                    <PageHeader
                        title={translate('page.heading.Bills')}
                        titleClass="mb-5 mt-5 text-2xl"
                    />

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFieldsBills}
                            handleInputChange={this.handleFilterInputChangeBills}
                            translate={translate}
                            onRefreshTable={this.fetchDataBills}
                            isLoading={secondResource.isLoading}
                        />

                        <ResourceTable
                            data={expenseData}
                            fields={this.getFieldsBills()}

                            translate={translate}
                            isLoading={secondResource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFieldsBills.limit.value}
                        />

                        {/*Table footer*/}
                        {!(!expenseData.length && !expenseData.isLoading) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >
                                <Pagination
                                    count={expenseCount}
                                    isLoading={expenseData.isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffsetBills(value, currentPage)
                                            : this.handleFilterInputChangeBills(name, value)
                                    }
                                    pageOffset={this.state.offsetBills}
                                    queryFields={this.state.queryFilterFieldsBills}
                                    translate={translate}
                                />
                            </div>
                        )}

                        <NoRecords
                            show={(expenseData.length === 0) && !expenseData.isLoading}
                            title={translate('text.no_records')}
                            addClass={"pt-16 pb-10"}
                        />
                    </TableCard>
                </Card>
            </div>
        )
    }
}
