import React, { Fragment, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Popover, Transition } from '@headlessui/react'
import { ArrowSmallDownIcon, ArrowSmallUpIcon, EllipsisVerticalIcon, EyeSlashIcon, MinusIcon, PaperClipIcon, PencilSquareIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { BarsArrowUpIcon, BarsArrowDownIcon, NoSymbolIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../util/util-helpers';
export default function ColumnMenu({
    columnKey, 
    content, 
    sort, 
    sortBy, 
    secondSortBy, 
    secondSort, 
    onSecondSortChange, 
    onSortChange,
    isDefaultSort,
    options,
    saveTableOptions,
    onOpenDialog,
    isSortable,
    defaultSort,
    translate
}) {
    const panelRef = useRef();
    const iconRef = useRef();
    const isSorted = sortBy === columnKey;
    const isSecondSorted = secondSortBy === columnKey;
    const [openCount, setOpenCount] = useState(0);
    const [isPanelLeft, setIsPanelLeft] = useState(true);
    const [menuPosition, setMenuPosition] = useState({});

    function toggleStickyColumn() {
        options.columns[columnKey].frozen = !options?.columns?.[columnKey]?.frozen;

        saveTableOptions(Object.assign({}, options));
    }

    function hideColumn() {
        options.columns[columnKey].show = false;

        saveTableOptions(Object.assign({}, options));
    }

    const menuOptions = [
        {
            label: "Default sort",
            icon: ArrowsUpDownIcon,
            action: (!isDefaultSort || !!secondSort) ? (() => onSortChange(defaultSort)) : undefined,
            isHidden: !onSortChange
        },{
            label: "Sort by " + translate("field." + columnKey) + " ascending",
            icon: BarsArrowUpIcon,
            action: !(isSorted && sort === "ASC") ? (() => onSortChange(columnKey)) : undefined,
            isHidden: !isSortable
        },{
            label: "Sort by " + translate("field." + columnKey) + " descending",
            icon: BarsArrowDownIcon,
            action: !(isSorted && sort === "DESC") ? (() => onSortChange(columnKey)) : undefined,
            separator: true,
            isHidden: !isSortable
        },{
            label: "Clear secondary sort",
            icon: NoSymbolIcon,
            action: secondSortBy ? (() => onSecondSortChange()) : undefined,
            isHidden: isSorted || !onSecondSortChange
        },{
            label: "Secondary sort by " + translate("field." + columnKey) + " ascending",
            icon: ArrowSmallUpIcon,
            action: !(isSecondSorted && secondSort === "ASC") ? (() => onSecondSortChange(columnKey)) : null,
            isHidden: isSorted || !onSecondSortChange
        },{
            label: "Secondary sort by " + translate("field." + columnKey) + " descending",
            icon: ArrowSmallDownIcon,
            action: !(isSecondSorted && secondSort === "DESC") ? (() => onSecondSortChange(columnKey)) : null,
            separator: true,
            isHidden: isSorted || !onSecondSortChange
        },{
            label: options?.columns?.[columnKey]?.frozen ? "Unsticky this column" : "Make this column sticky",
            icon: PaperClipIcon,
            action: toggleStickyColumn
        },{
            label: "Rename column",
            icon: PencilSquareIcon,
            action: () => onOpenDialog("reLabelColumn", columnKey)
        },{
            label: "Update column width",
            icon: MinusIcon,
            action: () => onOpenDialog("updateColumnWidth", columnKey)
        },{
            label: "Hide column",
            icon: EyeSlashIcon,
            action: hideColumn
        },
    ]

    useEffect(() => {
        if (openCount) {
            const iconEdges = iconRef.current.getBoundingClientRect();
            setMenuPosition(iconRef.current.getBoundingClientRect());

            setIsPanelLeft(document.body.clientWidth - iconEdges.right > 300);
            if (iconRef.current) {
                setMenuPosition(iconEdges);
            }
        }
    }, [openCount])

    return  <React.Fragment>
        {isSortable && (
            <button 
                className={
                    classNames(
                        "inline-block truncate font-bold hover:text-primary",
                        isSorted || isSecondSorted ? "max-w-[calc(100%-1.5rem)]" : "max-w-full"
                    )}
                onClick={() => onSortChange(columnKey)}
            >
                {content}
            </button>
        )}

        {!isSortable && (
            <div className="inline-block truncate font-bold">
                {content}
            </div>
        )}

        {isSortable && sortBy === columnKey && sort === "ASC" && (
            <BarsArrowUpIcon className='inline-flex flex-shrink-0 ml-2 w-5 h-5' />
        )}

        {isSortable && sortBy === columnKey && sort === "DESC" && (
            <BarsArrowDownIcon className='inline-flex flex-shrink-0 ml-2 w-5 h-5' />
        )}

        {secondSortBy === columnKey && secondSort === "ASC" && (
            <ArrowSmallUpIcon className='inline-flex flex-shrink-0 ml-2 w-5 h-5' />
        )}

        {secondSortBy === columnKey && secondSort === "DESC" && (
            <ArrowSmallDownIcon className='inline-flex flex-shrink-0 ml-2 w-5 h-5' />
        )}

        <Popover
            className="absolute right-2 top-2 w-5 h-5 rounded-full hover:bg-tm-gray-200"
        >
            {({ open }) => (
                <>
                    <Popover.Button
                        onClick={() => setOpenCount(openCount + 1)}
                        className={`
${open ? '' : 'text-opacity-90'}
absolute z-1 inset-0 group w-full rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <EllipsisVerticalIcon
                            ref={iconRef}
                            className='w-5 h-5 relative' 
                        />
                    </Popover.Button>
                    {createPortal(
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel 
                                className={
                                    classNames(
                                        "fixed z-[100] mt-3 translate-y-8",
                                        isPanelLeft ? undefined : "-translate-x-[calc(100%-1rem)]"
                                    )
                                }
                                style={{"top": menuPosition.top ? menuPosition.top - 20 : 0, "left": menuPosition.left}}
                            >
                                <div 
                                    className="border border-tm-gray-100 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                    ref={panelRef}
                                >
                                    <div className="relative space-y-1 bg-popup py-2">
                                        {menuOptions.filter(option => !option.isHidden).map(option => 
                                            <MenuOption key={option.label} option={option} />
                                        )}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                        ,
                        document.body
                    )}
                </>
            )}
        </Popover>
    </React.Fragment>
}

function MenuOption({option}) {
    return <React.Fragment>
    <button 
        onClick={option.action}
        className={
                classNames(
                    'flex text-tm-gray-700 w-full items-center p-2 transition duration-150 ease-in-out',
                    option.action ? "hover:bg-tm-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-tint" : "opacity-50 cursor-default"
                )
            }
    >
        {!!option?.icon && <option.icon className="w-5 h-5 text-tm-gray-500 mr-2 flex-shrink-0" />}
        <div className='whitespace-nowrap'>{option.label}</div>

    </button>

    {option.separator && (
        <div className='border-t border-tm-gray-200'/>
    )}

    </React.Fragment>
}

