import { classNames } from '../../../../common/util/util-helpers'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import { numberWithCommasToBack } from '../../../../util/util-formaters'
import React from 'react'
import { ArrowTrendingDownIcon } from '@heroicons/react/20/solid'
import {EyeIcon, PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import Card from "../../../../common/components/card";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import {excludeFields} from "../../../../common/util/util-fields";

export default function LoadPricingCommission({
                                                  fields,
                                                  toggleCreateDialog,
                                                  toggleEditDialog,
                                                  toggleViewDialog,
                                                  handleDeleteClick,
                                                  getFields,
                                                  translate
                                              }) {
    const hasItems = !!Object.values(fields).length
    const commissionAmount = Number(Object.values(fields).reduce((memo, it) => {
        memo = memo + numberWithCommasToBack(it.AmountCalculated)
        return memo
    }, 0))

    return (
        <React.Fragment>
            <Card
                addClass={classNames(
                    'col-span-2 xl:col-span-1 overflow-hidden min-h-[13rem] flex z-0 relative'
                )}
                addBodyClass='border-0 border-l-4 border-red-700'
                bodyClass="flex flex-col w-full grow justify-center"
            >
                {!!hasItems && (
                    <div className="px-4 py-3 flex justify-between items-center">
                        <h2 className="text-lg text-tm-gray-700 flex gap-2">
                            <ArrowTrendingDownIcon className="w-5 h-5 text-red-700"/>

                            {translate('page.heading.commissions')}
                        </h2>

                        <button
                            className="btn btn-outline-secondary py-1"
                            onClick={toggleCreateDialog}
                        >
                            <PlusIcon className="w-5 h-5 mr-1"/>
                            {translate('btn.commission')}
                        </button>
                    </div>
                )}

                <ResourceTable
                    data={fields}
                    fields={excludeFields(getFields(), ['LoadCommissionNotes'])}
                    translate={translate}
                    isLoading={false}

                    options={{
                        style: {
                            verticalLines: true,
                            floatingActions: false,
                        },
                        classes: {
                            headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                            primaryTextClass: ''
                        }
                    }}

                    onRowClick={(it) =>  {
                        if (it.IsPayed) {
                            toggleViewDialog(it, it.dataIndex);
                        } else {
                            toggleEditDialog(it, it.dataIndex);
                        }
                    }}

                    actions={[
                        {
                            action: (it) =>  toggleViewDialog(it, it.dataIndex),
                            icon: EyeIcon,
                            visible: (it) => it.IsPayed,
                        },
                        {
                            action: (it) =>  toggleEditDialog(it, it.dataIndex),
                            icon: PencilIcon,
                            visible: (it) => !it.IsPayed,
                        },
                        {
                            action: (it) =>  handleDeleteClick(it, it.dataIndex),
                            icon: TrashIcon,
                            visible: (it) => !it.IsPayed,
                        }
                    ]}

                    tfoot={{
                        ContactID: () => <div className={"px-5"}>{translate('text.total')}</div>,
                        AmountCalculated: commissionAmount
                    }}
                />

                <NoRecords
                    show={!hasItems}
                    onBtnClick={toggleCreateDialog}
                    addClass="px-4 py-10"
                    title={translate('title.no_commission')}
                    text={translate('text.no_commission')}
                    btnLabel={translate('btn.add_commission')}
                />
            </Card>
        </React.Fragment>
    )
}