import React, {Component} from 'react'
import LocalStorage from '../../../../../util/localStorage'
import Resources from '../../../../../data/services/resources'
import {getDialogResource} from '../../../../../data/actions/dialogResource'
import {Field, FieldsManager} from '../../../../../data/services/fields'
import InformationCircleIcon from '@heroicons/react/20/solid/InformationCircleIcon'
import Tippy from '@tippyjs/react'
import {feeTypes} from '../../../../../util/util-constants'
import {classNames, getProp} from '../../../../../common/util/util-helpers'
import {cloneDeep, genericMoneyFormatter} from '../../../../../common/util/util-vanilla'
import ModalHeader from "../../../../../common/components/modal/modal-header";
import InfoParagraph from "../../../../../common/components/info-paragraph";
import FieldCheckbox from "../../../../../common/components/fields/field-checkbox";
import {LoaderSmall} from "../../../../../common/components/loader";
import ModalFooter from "../../../../../common/components/modal/modal-footer";
import Badge from "../../../../../common/components/badge";

// Set disabled for now, until backend is done.
const DISABLE_CUSTOMER_SETTINGS = false
const DISABLE_INPUT_CHANGE = false
const DISABLE_ADJUSTMENTS = true

export default class LoadCustomerSettingsDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            Accessorial: [],
            AccessorialAll: true,
            Adjustment: [],
            AdjustmentAll: true,
            Commission: [],
            CommissionAll: true,
            fields: {
                Accessorials: new Field('Accessorials', DISABLE_CUSTOMER_SETTINGS ? 0 : 1, [], DISABLE_CUSTOMER_SETTINGS, 'checkbox'),
                Commission: new Field('Commission', DISABLE_CUSTOMER_SETTINGS ? 0 : 1, [], DISABLE_CUSTOMER_SETTINGS, 'checkbox'),
                Adjustment: new Field('Adjustment', DISABLE_CUSTOMER_SETTINGS ? 0 : 1, [], DISABLE_CUSTOMER_SETTINGS, 'checkbox'),
            }
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.dialogResource !== prevProps.dialogResource) {
            const Accessorial = getProp(this.props.dialogResource, 'data.Accessorial.list', []).map(it => {
                it.checked = !DISABLE_CUSTOMER_SETTINGS
                return it
            })

            let Adjustment = []
            if (!DISABLE_ADJUSTMENTS) {
                Adjustment = getProp(this.props.dialogResource, 'data.ExtraCharges.list', []).map(it => {
                    it.checked = !DISABLE_CUSTOMER_SETTINGS
                    return it
                })
            }

            const Commission = getProp(this.props.dialogResource, 'data.Commision.list', []).map(it => {
                it.checked = !DISABLE_CUSTOMER_SETTINGS
                return it
            })

            this.setState({
                Accessorial,
                Adjustment,
                // Commission
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadCustomerSettings,
            query: {OrganizationID: this.props.OrganizationID}
        }))
    }

    submit = () => {
        this.setState({
            SkipCustomerDefaults: !this.state.SkipCustomerDefaults
        }, () => {
            this.props.onButtonClick({
                Accessorial: this.state.Accessorial,
                Adjustment: this.state.Adjustment,
                Commission: this.state.Commission,
                SkipCustomerDefaults: !this.props.SkipCustomerDefaults
            });
            this.props.onClose();
        })

    }

    handleInputChange = (name, value) => {
        if (DISABLE_INPUT_CHANGE) return
        let Accessorial = this.state.Accessorial
        let AccessorialAll = this.state.AccessorialAll
        let Adjustment = this.state.Adjustment
        let AdjustmentAll = this.state.AdjustmentAll
        let Commission = this.state.Commission
        let CommissionAll = this.state.CommissionAll

        if ('Accessorials' === name) {
            if (this.state.AccessorialAll) {
                Accessorial.map(it => {
                    it.checked = false
                    return it
                })
                AccessorialAll = false
            } else {
                Accessorial.map(it => {
                    it.checked = true
                    return it
                })

                AccessorialAll = true
            }
        }

        if ('Adjustment' === name) {
            if (this.state.Adjustment) {
                Accessorial.map(it => {
                    it.checked = false
                    return it
                })
                AdjustmentAll = false
            } else {
                Accessorial.map(it => {
                    it.checked = true
                    return it
                })

                AdjustmentAll = true
            }
        }

        if ('Commission' === name) {
            if (this.state.CommissionAll) {
                Commission.map(it => {
                    it.checked = false
                    return it
                })
                CommissionAll = false
            } else {
                Commission.map(it => {
                    it.checked = true
                    return it
                })

                CommissionAll = true
            }
        }

        this.setState({
            fields: FieldsManager.updateField(this.state.fields, name, value),
            Accessorial,
            AccessorialAll,
            Adjustment,
            AdjustmentAll,
            Commission,
            CommissionAll
        })
    }

    handleAccessorialChange = (i) => {
        if (!!DISABLE_INPUT_CHANGE) return
        const Accessorial = cloneDeep(this.state.Accessorial)

        Accessorial[i].checked = !Accessorial[i].checked

        let AccessorialAll = 1

        Accessorial.forEach(it => {
            if (!it.checked) {
                AccessorialAll = 0
            }
        })

        this.setState({
            fields: FieldsManager.updateField(this.state.fields, 'Accessorials', AccessorialAll),
            Accessorial,
            AccessorialAll
        })
    }

    handleCommissionChange = (i) => {
        if (!!DISABLE_INPUT_CHANGE) return
        const Commission = cloneDeep(this.state.Commission)

        Commission[i].checked = !Commission[i].checked

        let CommissionAll = 1

        Commission.forEach(it => {
            if (!it.checked) {
                CommissionAll = 0
            }
        })

        this.setState({
            fields: FieldsManager.updateField(this.state.fields, 'Commission', CommissionAll),
            Commission,
            CommissionAll
        })
    }

    handleAdjustmentChange = (i) => {
        if (DISABLE_INPUT_CHANGE) return
        const Adjustment = cloneDeep(this.state.Adjustment)

        Adjustment[i].checked = !Adjustment[i].checked

        let AdjustmentAll = 1

        Adjustment.forEach(it => {
            if (!it.checked) {
                AdjustmentAll = 0
            }
        })

        this.setState({
            fields: FieldsManager.updateField(this.state.fields, 'Adjustment', AdjustmentAll),
            Adjustment,
            AdjustmentAll
        })
    }

    render() {
        const {dialogResource, title, onClose, closeButtonLabel, buttonLabel, translate} = this.props

        const isLoading = getProp(dialogResource, 'isLoading', [])

        const hasAccessorial = !!this.state.Accessorial.length
        const hasCommission = !!this.state.Commission.length
        let hasAdjustment = !!this.state.Adjustment.length

        if (DISABLE_ADJUSTMENTS) hasAdjustment = false

        const doesntHaveAny = !hasAccessorial && !hasCommission && !hasAdjustment

        const doesntHaveAnySelected = !this.state.Accessorial.filter(it => it.checked).length && !this.state.Commission.filter(it => it.checked).length

        return (
            <React.Fragment>
                <ModalHeader
                    title={title}
                    onClose={onClose}
                />

                <div className="px-6 pt-6 pb-9 relative max-h-[calc(100vh-12rem)] overflow-auto">
                    <div className={classNames(isLoading ? 'invisible' : '', 'space-y-12')}>
                        {doesntHaveAny && (
                            <InfoParagraph
                                message="This customer does not have any default settings set. To set settings go to customer page."
                            />
                        )}

                        {hasAccessorial && (
                            <fieldset>
                                <legend className="text-lg font-medium text-gray-900 flex w-full">
                                    <div className="grid gap-4 grid-cols-5 flex-grow">
                                        <div className="col-span-2 items-center text-tm-gray-900">Accessorials</div>
                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-center">Units
                                        </div>
                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-end">Amount
                                        </div>
                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-end ">Total
                                        </div>
                                    </div>

                                    <div className="ml-3 items-center">
                                        <FieldCheckbox
                                            {...this.state.fields.Accessorials}
                                            id="Accessorials"
                                            name="Accessorials"
                                            onChange={(name, value) => this.handleInputChange(name, value)}
                                            value={this.state.fields.Accessorials.value}
                                            className={classNames('checkbox')}
                                            disabled={!!DISABLE_INPUT_CHANGE}
                                        />
                                    </div>
                                </legend>

                                <div
                                    className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                    {this.state.Accessorial.map((accessorial, i) => (
                                        <div key={i} className="relative flex items-center">
                                            <div className="min-w-0 flex-1 text-sm">
                                                <label htmlFor={'accessorial-' + i}
                                                       className="py-4 font-medium text-gray-700 select-none grid gap-4 grid-cols-5 cursor-pointer hover:text-primary">
                                                    <span className="col-span-2 text-tm-gray-900">
                                                        {accessorial.Accessorial}

                                                        {!!accessorial.CustomerAccessorialNotes && (
                                                            <Tippy content={<div><p className="font-bold">Customer
                                                                notes:</p>
                                                                <p>{accessorial.CustomerAccessorialNotes}</p></div>}>
                                                                <button
                                                                    className="rounded-md text-tm-gray-700 px-2 py-1 relative -top-0.5 hover:text-tm-gray-900"
                                                                    onClick={this.toggleCustomerSettingsDialog}
                                                                >
                                                                    <InformationCircleIcon className="w-5 h-5"/>
                                                                </button>
                                                            </Tippy>
                                                        )}
                                                    </span>

                                                    <div
                                                        className="flex items-center justify-center text-tm-gray-900">{accessorial.CustomerAccessorialUnits}</div>

                                                    <div
                                                        className="flex items-center justify-end text-tm-gray-900">{genericMoneyFormatter(accessorial.CustomerAccessorialAmount)}
                                                    </div>

                                                    <div className="flex items-center justify-end">
                                                        <Badge>
                                                            <div className="flex flex-nowrap">
                                                                {genericMoneyFormatter(accessorial.CustomerAccessorialAmount * accessorial.CustomerAccessorialUnits)}
                                                            </div>
                                                        </Badge>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="ml-3 items-center">
                                                <FieldCheckbox
                                                    id={'accessorial-' + i}
                                                    name={`accessorial-${accessorial.Accessorial}`}
                                                    onChange={() => this.handleAccessorialChange(i)}
                                                    value={this.state.Accessorial[i].checked}
                                                    className="checkbox"
                                                    disabled={!!DISABLE_INPUT_CHANGE}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        )}

                        {hasAdjustment && (
                            <fieldset>
                                <legend className="text-lg font-medium text-gray-900 flex w-full">
                                    <div className="grid gap-4 grid-cols-5 flex-grow">
                                        <div className="col-span-2 items-center">Adjustment</div>

                                        <div className="invisible">hidden</div>

                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-end ">Amount
                                        </div>

                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-end ">Fee
                                            type
                                        </div>
                                    </div>

                                    <div className="ml-3 items-center">
                                        <FieldCheckbox
                                            {...this.state.fields.Adjustment}
                                            id="Adjustment"
                                            name="Adjustment"
                                            onChange={(name, value) => this.handleInputChange(name, value)}
                                            value={this.state.fields.Adjustment.value}
                                            className="checkbox"
                                            disabled={!!DISABLE_INPUT_CHANGE}
                                        />
                                    </div>
                                </legend>

                                <div
                                    className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                    {this.state.Adjustment.map((it, i) => (
                                        <div key={i} className="relative flex items-center">
                                            <div className="min-w-0 flex-1 text-sm">
                                                <label htmlFor={'commission-' + i}
                                                       className="py-4 font-medium text-gray-700 select-none grid gap-4 grid-cols-5 cursor-pointer hover:text-primary">
                                                    <span className="col-span-2">
                                                        {it.Contact}

                                                        {!!it.Notes && (
                                                            <Tippy
                                                                content={<div><p className="font-bold">Extra charge
                                                                    notes:</p>
                                                                    <p>{it.Notes}</p></div>}
                                                            >
                                                                <button
                                                                    className="rounded-md text-tm-gray-700 px-2 py-1 relative -top-0.5 hover:text-tm-gray-900"
                                                                    onClick={this.toggleCustomerSettingsDialog}
                                                                >
                                                                    <InformationCircleIcon className="w-5 h-5"/>
                                                                </button>
                                                            </Tippy>
                                                        )}
                                                    </span>

                                                    <div className="invisible">hidden</div>

                                                    <div
                                                        className="flex items-center justify-end">{it.Amount} $
                                                    </div>

                                                    <div className="flex items-center justify-end">
                                                        {it.Type ? 'Expense' : 'Charge'}
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="ml-3 items-center">
                                                <FieldCheckbox
                                                    id={'commission-' + i}
                                                    name={`commission-${it.Email}`}
                                                    onChange={() => this.handleAdjustmentChange(i)}
                                                    value={this.state.Adjustment[i].checked}
                                                    className="checkbox"
                                                    disabled={!!DISABLE_INPUT_CHANGE}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        )}

                        {hasCommission && (
                            <fieldset>
                                <legend className="text-lg font-medium text-gray-900 flex w-full">
                                    <div className="grid gap-4 grid-cols-5 flex-grow">
                                        <div className="col-span-2 items-center">Commission</div>
                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-start">Business
                                            Email
                                        </div>

                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-end ">Amount
                                        </div>

                                        <div
                                            className="text-sm uppercase text-tm-gray-700 flex items-end justify-end ">Fee
                                            type
                                        </div>
                                    </div>

                                    <div className="ml-3 items-center">
                                        <FieldCheckbox
                                            {...this.state.fields.Commission}
                                            id="Commission"
                                            name="Commission"
                                            onChange={(name, value) => this.handleInputChange(name, value)}
                                            value={this.state.fields.Commission.value}
                                            className="checkbox"
                                            disabled={!!DISABLE_INPUT_CHANGE}
                                        />
                                    </div>
                                </legend>

                                <div
                                    className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                    {this.state.Commission.map((it, i) => (
                                        <div key={i} className="relative flex items-center">
                                            <div className="min-w-0 flex-1 text-sm">
                                                <label htmlFor={'commission-' + i}
                                                       className="py-4 font-medium text-gray-700 select-none grid gap-4 grid-cols-5 cursor-pointer hover:text-primary">
                                                    <span className="col-span-2">
                                                        {it.Contact}

                                                        {!!it.LoadCommissionNotes && (
                                                            <Tippy content={<div><p className="font-bold">Commission
                                                                notes:</p>
                                                                <p>{it.LoadCommissionNotes}</p></div>}>
                                                                <button
                                                                    className="rounded-md text-tm-gray-700 px-2 py-1 relative -top-0.5 hover:text-tm-gray-900"
                                                                    onClick={this.toggleCustomerSettingsDialog}
                                                                >
                                                                    <InformationCircleIcon className="w-5 h-5"/>
                                                                </button>

                                                            </Tippy>
                                                        )}
                                                    </span>

                                                    <div
                                                        className="flex items-center justify-start">{it.Email}</div>

                                                    <div
                                                        className="flex items-center justify-end">{it.Amount} $
                                                    </div>

                                                    <div className="flex items-center justify-start"
                                                         title={translate(feeTypes[it.FeeTypeID])}>
                                                        <p
                                                            className=" overflow-ellipsis overflow-hidden whitespace-nowrap">{translate(feeTypes[it.FeeTypeID])}</p>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="ml-3 items-center">
                                                <FieldCheckbox
                                                    id={'commission-' + i}
                                                    name={`commission-${it.Email}`}
                                                    onChange={() => this.handleCommissionChange(i)}
                                                    value={this.state.Commission[i].checked}
                                                    className="checkbox"
                                                    disabled={!!DISABLE_INPUT_CHANGE}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        )}
                    </div>

                    {isLoading && (
                        <div className="absolute position-absolute-center">
                            <LoaderSmall/>
                        </div>
                    )}
                </div>

                <ModalFooter
                    onClose={onClose}
                    buttonLabel={buttonLabel}
                    buttonDisabled={doesntHaveAny || doesntHaveAnySelected}
                    onButtonClick={() => {
                        this.submit()
                    }}
                    closeButtonLabel={closeButtonLabel}
                />
            </React.Fragment>
        )
    }
}
