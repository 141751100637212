import React from "react";

export default function LoaderContainer({disable, stripesBg, children}) {
    return (
        <span className={!disable ? "absolute inset-0 flex justify-center items-center z-40" : undefined}>
            {stripesBg && !disable && (
                <div className="absolute inset-0 bg-stripes opacity-20"/>
            )}

            {children}
        </span>
    )
}