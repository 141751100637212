import {checkUserHelper} from "../services/api-util";
import Api from "../services/api";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    doneCreateAccountingResource,
    doneDeleteAccountingResource, doneGetAccountingResource, errorCreateAccountingResource,
    errorDeleteAccountingResource,
    errorGetAccountingResource
} from "../actions/accountingResource";
import {pushNotification} from "../actions/ui";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";


export function* getAccountingResourceCall(action) {
    let user;

    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result;
    if (action.data.request === 'POST') {
        result = yield call(Api.postResource, user, action.data.query, action.data.resource);
    } else {
        result = yield call(Api.getResource, user, action.data.query, action.data.resource);
    }

    if (result && result.status === 0) {
        yield put(doneGetAccountingResource(result.data));
    } else {
        yield put(errorGetAccountingResource(result.data));
    }
}

export function* watchGetAccountingResource() {
    yield takeLatest('GET_ACCOUNTING_RESOURCE', getAccountingResourceCall);
}


export function* createAccountingResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action?.data?.file) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetAccountingResource(resPiggy.data));
                yield put(doneCreateAccountingResource(result.data));
            }
        } else {
            yield put(doneCreateAccountingResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateAccountingResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateAccountingResource() {
    yield takeLatest('CREATE_ACCOUNTING_RESOURCE', createAccountingResourceCall);
}


export function* deleteAccountingResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetAccountingResource(resPiggy.data));
                yield put(doneDeleteAccountingResource(result.data));
            }
        } else {
            yield put(doneDeleteAccountingResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteAccountingResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchDeleteAccountingResource() {
    yield takeLatest('DELETE_ACCOUNTING_RESOURCE', deleteAccountingResourceCall);
}