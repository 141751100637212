import React from "react";
import {classNames} from "../../../../util/util-helpers";

const TabTitle = ({ text, addClass }) => {

    return (
        <h5 className={classNames(addClass, 'text-tm-gray-900 text-2xl')}>{text}</h5>
    )
}

export default TabTitle
