export function getInfoResource(data) {
    return {
        type: 'GET_INFO_RESOURCE',
        data: data
    };
}

export function doneGetInfoResource(data) {
    return {
        type: 'DONE_GET_INFO_RESOURCE',
        data: data
    };
}

export function errorGetInfoResource(data) {
    return {
        type: 'ERROR_GET_INFO_RESOURCE',
        data: data
    };
}

export function resetInfoResource() {
    return {
        type: 'RESET_INFO_RESOURCE',
        data: []
    }
}
