
import React, { useEffect, useState } from "react";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import {
    CreditCardIcon,
    ExclamationTriangleIcon,
    HomeIcon,
    MapPinIcon,
    PhoneIcon
} from "@heroicons/react/24/outline";
import DisplayDataFieldContainer from "../../display-data/display-data-field-container";
import DisplayDataFieldGroupContainer from "../../display-data/display-data-field-group-container";
import DisplayDataFieldTextarea from "../../display-data/display-data-field-textarea";
import LocalStorage from "../../../util/localStorage";
import { useDispatch, useSelector } from "react-redux";
import LoaderLarge from "../../loader/loader-large";
import {Field} from "../../../../data/services/fields";
import {getDialogInfoResource} from "../../../../data/actions/dialogInfoResource";

export default function GlobalVendorInfoDialog({translate, item, show, resourcePath,id}) {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogInfoResource);

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const fields = getFields();
    const secondaryFields = getSecondaryFields();


    const fetchData = () => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: resourcePath,
            query: {id: item[id]}
        }));
    }

    useEffect(() => {
        setIsLoading(true);
        fetchData(item);
    }, [item, show])

    useEffect(() => {
        if (!!resource?.data && resource.data[id] === item[id]) {
            setData(resource.data);
            setIsLoading(false);
        }
    }, [resource]);

    return (
        <div className="bg-tm-gray-50 px-3 py-8 text-tm-gray-700 space-y-8 relative min-h-[27rem]">
            {isLoading && (
                <LoaderLarge stripesBg/>
            )}

            {!isLoading && (
                <React.Fragment>
                    <DisplayDataFieldContainer
                        fields={fields}
                        data={data}
                        translate={translate}
                    />

                    <DisplayDataFieldGroupContainer
                        fields={secondaryFields}
                        data={data}
                        translate={translate}
                    />

                    {(!!data.CreditLimit || !!data.CreditHold || data.CreditLimit === 0) && (
                        <div className={'max-w-md mx-auto'}>
                            <p className="font-bold leading-5 mb-2">
                                {translate("text.Credit")}
                            </p>

                            <DisplayDataFieldGroupContainer
                                fields={getCreditFields(data)}
                                data={data}
                                translate={translate}
                            />
                        </div>
                    )}

                    <DisplayDataFieldTextarea
                        fieldName={"Notes"}
                        translate={translate}
                        data={data}
                    />
                </React.Fragment>
            )}
        </div>
    );
}

const getFields = () => {
    return {
        LegalName: new Field('LegalName', '', [''], false, "text", {icon: () => BuildingOfficeIcon}),
        LocationName: new Field('LocationName', '', [''], false, "text", {icon: () => HomeIcon}),
        AddressName: new Field('AddressName', '', [''], false, "address", {icon: () => MapPinIcon}),
        PhoneNumber: new Field('PhoneNumber', '', [''], false, "phone", {icon: () => PhoneIcon}),
    }
}

const getCreditFields = (data) => {
    let fields = {
        CreditLimit: new Field('CreditLimit', '', [''], false, "money", {icon: () => CreditCardIcon}),
        AvailableCredit: new Field('AvailableCredit', '', [''], false, "money", {icon: () => CreditCardIcon}),
        CreditHold: new Field('CreditHold', '', [''], false, "checkbox", {
            customIcon: () => <ExclamationTriangleIcon className="text-red-600 w-5 h-5" />,
            label: "creditOnHold"
        }),
    }

    if (!data.CreditHold) {
        delete fields.CreditHold;
    }

    return fields;
}

const getSecondaryFields = () => {
    return {
        OrganizationType: new Field('OrganizationType', '', [''], false, "text"),
        DBAName: new Field('DBAName', '', [''], false, "text"),
        MCNumber: new Field('MCNumber', '', [''], false, "text"),
        USDOTNumber: new Field('USDOTNumber', '', [''], false, "text"),
        SCAC: new Field('SCAC', '', [''], false, "text"),
        FederalID: new Field('FederalID', '', [''], false, "text"),
        W9: new Field('W9', '', [''], false, "text"),
        ContractOnFile: new Field('ContractOnFile', '', [''], false, "checkbox"),
    }
}
