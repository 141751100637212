const notificationsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATIONS':
            return Object.assign({}, state, {
                data: action.data
            });
        case 'ADD_NOTIFICATION': {
            const newData = action.data;
            if (newData.notifications.list.length > 10) {
                newData.notifications.list = newData.notifications.list.slice(-10);
            }
            return Object.assign({}, state, {
                data: newData
            });
        }
        case 'GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                listIsLoading: true,
                unreadCount: 0
            });

        case 'DONE_GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                listIsLoading: false,
                list: action.data
            });
        case 'ERROR_GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                listIsLoading: false,
                errorNotificationList: true
            });
        default:
            return state;
    }
};

export default notificationsReducer;
