import React, {Component} from 'react'
import Layout from '../../../common/components/layout-public'
import connect from 'react-redux/es/connect/connect'
import {Link} from 'react-router-dom'
import {Field, FieldsManager} from '../../../data/services/fields'
import {register, resetUserMessage} from '../../../data/actions/user'
import Resources from '../../../data/services/resources'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import Env from '../../../util/env'
import {processError, processResponse} from '../../../data/services/api-util'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import icons from '../../../data/themes/icons'
import {
    DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    SCAC_CODE_FIELD_LENGTH
} from '../../../util/util-constants'
import {formatExpDate} from '../../../util/util-formaters'
import {LoaderSmall} from "../../../common/components/loader";
import FieldText from "../../../common/components/fields/field-text";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import AuthLayout from "../auth-layout";
import PencilAltOutlineIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import {BuildingOfficeIcon, CheckCircleIcon, CreditCardIcon} from "@heroicons/react/24/outline";
import {classNames, handleMaskedFocus} from "../../../common/util/util-helpers";
import Card from "../../../common/components/card";
import FieldContainer from "../../../common/components/fields/field-container";
import Button from "../../../common/components/button";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import ArrowRightIcon from "@heroicons/react/20/solid/ArrowRightIcon";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import FieldTextMask from "../../../common/components/fields/field-text-mask";
import InfoParagraph from "../../../common/components/info-paragraph";

class RegisterView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {
                CompanyName: new Field('CompanyName', '', ['empty'], false, 'text', {addContainerClass: "col-span-full"}),
                Email: new Field('Email', '', ['empty', 'email'], false, 'text', {addContainerClass: "col-span-6"}),
                EmailConfirm: new Field('EmailConfirm', '', ['empty', 'email', 'email_confirm'], false, 'text', {addContainerClass: "col-span-6 col-start-1"}),
                CompanyLogoUrl: new Field('CompanyLogoUrl', null, []),
                CountryID: new Field('CountryID', 1, [], false, 'select', {addContainerClass: "col-span-3"}),
                AreaCode: new Field('AreaCode', '', ['empty'], false, 'text', {addContainerClass: "col-start-1 col-span-3"}),
                PhoneNumber: new Field('PhoneNumber', '', ['empty'], false, 'text', {addContainerClass: "col-span-3"}),
                PhoneExtension: new Field('PhoneExtension', '', [''], false, 'text', {addContainerClass: "col-span-3"}),
                CityName: new Field('CityName', '', ['empty'], false, "text", {addContainerClass: "col-span-5"}),
                StateID: new Field('StateID', '', ['empty'], false, 'select', {addContainerClass: "col-span-5"}),
                PostalCode: new Field('PostalCode', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                AddressName: new Field('AddressName', '', ['empty'], false, "text", {addContainerClass: "col-span-full"}),
                AddressName2: new Field('AddressName2', '', [''], false, "text", {addContainerClass: "col-span-full"}),
                BusinessTypeID: new Field('BusinessTypeID', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
                SCAC: new Field('SCAC', '', ['empty'], false, "text", {addContainerClass: "col-span-3"}),
                FederalID: new Field('FederalID', '', ['empty'], false, "text", {addContainerClass: "col-span-3"}),
                LineOfBusiness: new Field('LineOfBusiness', [], ['empty']),
                LineOfBusinessPrimary: new Field('LineOfBusinessPrimary', '', ['']),

                FirstName: new Field('FirstName', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
                LastName: new Field('LastName', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
                BillingStateID: new Field('BillingStateID', '', ['empty'], false, 'select', {addContainerClass: "col-span-5"}),
                BillingCountryID: new Field('BillingCountryID', 1, ['empty'], false, 'select', {addContainerClass: "col-span-3"}),
                BillingCity: new Field('BillingCity', '', ['empty'], false, 'select', {addContainerClass: "col-span-5"}),
                BillingZip: new Field('BillingZip', '', ['empty'], false, 'select', {addContainerClass: "col-span-2"}),
                BillingAddress1: new Field('BillingAddress1', '', ['empty'], false, 'select', {addContainerClass: "col-span-full"}),
                BillingAddress2: new Field('BillingAddress2', '', [''], false, 'select', {addContainerClass: "col-span-full"}),
                CardNumber: new Field('CardNumber', '', ['empty'], false, 'select', {addContainerClass: "col-span-3"}),
                CVV: new Field('CVV', '', ['empty'], false, 'select', {addContainerClass: "col-span-3"}),
                ExpDate: new Field('ExpDate', '', ['empty'], false, 'select', {addContainerClass: "col-span-3"}),
            },
            files: [],
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 2 / 1,
            },
            progress: 1,
            currentStep: 1,
            passMatch: false,
            brokerage: false,
            carrier: false,
            data: {},
            State: {},
            Country: {},
            BusinessType: {},
            steps: this.getSteps()
        }


        this.fileImageObject = null
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage())
        this.fetchData()
    }

    fetchData = async () => {
        let result = await axios.get(
            Env.getApiUrl('api/' + Resources.LookupsPublic)
        )
            .then((response) => {
                return processResponse(response)
            })
            .catch((error) => {
                return processError(error)
            })
        this.receiveLookupsHandler(result.data)
    }

    receiveLookupsHandler = (lookups) => {
        Object.keys(lookups).forEach(name => {
            const result = lookups[name] && lookups[name].reduce(
                (memo, item) => {
                    memo[item[`${name}ID`]] = item[name]
                    return memo
                }, {})
            this.setState({[name]: result})
        })
    }

    handleEnterClick = (key) => {
        if (key === 'Enter') {
            this.nextStep()
        }
    }

    handleInputChange = (name, value) => {
        if (name === 'CountryID') {
            let fields = this.state.fields
            if (value == 1) { // USA
                fields.StateID.validate = ['empty']
            } else { // SERBIA
                fields.StateID.validate = ['']
                fields.StateID.value = ''
            }
            this.setState({fields: fields})
        }
        if (name === 'BillingCountryID') {
            let fields = this.state.fields
            if (value == 1) { // USA
                fields.BillingStateID.validate = ['empty']
            } else { // SERBIA
                fields.BillingStateID.validate = ['']
                fields.BillingStateID.value = ''
            }
            this.setState({fields: fields})
        }
        if (name === 'ExpDate') {
            value = formatExpDate(value)
        }
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event)
        }
    }

    submit = (event) => {
        event && event.preventDefault()
        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && (!!this.state.carrier || !!this.state.brokerage)) {
                let arr = []
                let LineOfBusinessPrimary = this.state.fields.LineOfBusinessPrimary.value
                if (this.state.carrier) {
                    if (!LineOfBusinessPrimary && !this.state.brokerage) LineOfBusinessPrimary = '2'
                    arr.push('2')
                }
                if (this.state.brokerage) {
                    if (!LineOfBusinessPrimary && !this.state.carrier) LineOfBusinessPrimary = '1'
                    arr.push('1')
                }
                this.props.dispatch(register({
                    params: Object.assign({
                        CardHolderName: this.state.fields.FirstName.value + ' ' + this.state.fields.LastName.value
                    }, FieldsManager.getFieldKeyValues(this.state.fields), {
                        LineOfBusiness: arr,
                        LineOfBusinessPrimary: LineOfBusinessPrimary,
                    }),
                    file: this.fileImageObject,
                    resource: Resources.Company,
                    fileResource: Resources.CompanyImage
                }))
            }
        })
    }

    nextStep = () => {
        let list = []

        if (this.state.currentStep === 1) {
            list = ['CompanyName', 'Email', 'EmailConfirm']
        } else if (this.state.currentStep === 2) {
            list = ['CompanyName', 'PhoneNumber', 'AreaCode', 'PhoneExtension', 'CityName', 'StateID', 'PostalCode', 'AddressName', 'CountryID', 'BusinessTypeID', 'FederalID']
        } else if (this.state.currentStep === 3) {
            list = ['FirstName', 'LastName', 'BillingStateID', 'BillingCountryID', 'BillingCity', 'BillingZip', 'BillingAddress1', 'CardNumber', 'CVV', 'ExpDate']
        }

        this.validateFields(this.state.fields, list, () => {
            if (this.checkFieldsForError(list)) {
                this.setState({
                    currentStep: this.state.currentStep + 1,
                    progress: this.state.progress + 1
                })
            }
        })
    }

    validateFields = (fields, list, callback = () => {
    }) => {
        this.setState({no_match: false, fields: FieldsManager.validateFields(fields, list)}, () => {
            callback()
        })
    }

    checkFieldsForError = (list) => {
        let valid = true
        if (this.state.currentStep === 2 && (!this.state.carrier && !this.state.brokerage)) {
            this.setState({lineOfBusinessError: true})
            valid = false
        } else {
            this.setState({lineOfBusinessError: false})
        }
        if (!FieldsManager.checkFieldsForErrors(this.state.fields, list)) {
            valid = false
        }

        return valid
    }

    setStep = (step) => {
        if (step <= this.state.progress) {
            this.setState({currentStep: step})
        }
    }

    onSelectFile = acceptedFiles => {
        if (acceptedFiles) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    canSubmit: true
                })
            )
            reader.readAsDataURL(acceptedFiles[0])
        }
    }
    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop)
    }

    onCropChange = (crop) => {
        this.setState({crop})
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.setState({croppedImageUrl})
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                this.fileImageObject = new File([blob], 'company-logo.png')
                resolve(this.fileUrl)
            }, 'image/png')
        })
    }

    getSteps() {
        return [
            {
                id: 1,
                name: "Account info",
                icon: PencilAltOutlineIcon
            }, {
                id: 2,
                icon: BuildingOfficeIcon,
                name: "Company info",
            }, {
                id: 3,
                icon: CreditCardIcon,
                name: "Billing",
            }, {
                id: 4,
                icon: CheckCircleIcon,
                name: "Complete",
            }
        ]
    }

    render() {
        const {crop, croppedImageUrl, src} = this.state
        const {translate} = this.props
        const lastStep = 4
        const files = this.state.files.map((it, i) => {
            return (
                <div key={i}>{it.name}</div>
            )
        })
        return (
            <Layout {...this.props}>
                <AuthLayout>
                    <div className="mx-auto w-full ">
                        <div>
                            <img
                                className="h-14 w-auto"
                                src="/images/logo.png"
                                alt="Your Company"
                            />
                            <h2 className="mt-8 mb-4 text-2xl font-bold leading-9 tracking-tight">
                                Register your account
                            </h2>

                            <p className="mt-2 text-sm leading-6 text-tm-gray-500">
                                Already registered?{' '}
                                <a
                                    href="/login"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-primary hover:text-primary-shade font-semibold"
                                >
                                    Login here
                                </a>
                            </p>

                            {!this.props.user.isLoading && !this.props.user.register && (
                                <nav className="flex mt-10 mb-6" aria-label="Breadcrumb">
                                    <ol role="list"
                                        className="flex space-x-2 rounded-md bg-white px-6 shadow w-full">
                                        {this.state.steps.map((step, i) => (
                                            <li key={step.id} className="flex w-full cursor-default">
                                                <div className="flex items-center">
                                                    {!!i && (
                                                        <svg
                                                            className="h-full w-6 flex-shrink-0 text-gray-200"
                                                            viewBox="0 0 24 44"
                                                            preserveAspectRatio="none"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                                                        </svg>
                                                    )}

                                                    <button
                                                        onClick={() => this.setStep(i + 1)}
                                                        className={
                                                            classNames(
                                                                this.state.currentStep === i + 1
                                                                    ? "font-bold text-primary hover:text-primary-shade"
                                                                    : this.state.progress <= i + 1
                                                                        ? "text-gray-400 cursor-default"
                                                                        : "text-gray-500 hover:text-gray-700",
                                                                "flex items-center ml-4 text-sm font-medium"
                                                            )
                                                        }
                                                        aria-current={step.current ? 'page' : undefined}
                                                    >
                                                        <step.icon
                                                            className="h-5 w-5 flex-shrink-0 mr-1 -ml-1"
                                                            aria-hidden="true"
                                                        />

                                                        {step.name}
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ol>
                                </nav>
                            )}
                        </div>
                    </div>

                    <form onKeyPress={this.submitForm}>
                        <div className="fixed-background login-bg"/>
                        <main className="main-bg">

                            {(!!this.props.user.access_token || this.props.user.register) && (
                                <div className="container">
                                    <div className="row h-full">
                                        <div className="col-12 col-md-10 mx-auto my-auto">
                                            <div className="card auth-card register">
                                                <div className="registration-complete">
                                                    <div
                                                        className="registration-complete-icon">{icons.CheckCircle}</div>
                                                    <h1 className="font-bold p-4">Submitted successfully.</h1>
                                                    <h3>
                                                        An email has been sent to your mailbox. Please check your email
                                                        and
                                                        confirm.
                                                    </h3>
                                                    <h4 className="font-weight-bold text-primary cursor-pointer font-semibold">
                                                        <Link to={`/login`}>{translate('btn.back_to_login')}</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!this.props.user.isLoading && !this.props.user.register && (
                                <Card>
                                    <div className="row h-full">
                                        <div className="col-12 col-md-10 mx-auto my-auto">
                                            <div className="card auth-card register">
                                                {/*<div className="relative image-side bg-size-cover">*/}

                                                {/*    <p className=" text-white h2">MAGIC IS IN THE DETAILS</p>*/}

                                                {/*    <p className="white">*/}

                                                {/*    </p>*/}

                                                {/*    <p className="white">Already registered? <Link*/}
                                                {/*        className="white white-underline-link" to="/login">Login*/}
                                                {/*        here</Link>*/}
                                                {/*    </p>*/}





                                                {this.props.user.register && (
                                                    <InfoParagraph type="success">
                                                        <div className="flex items-center justify-between">
                                                            <div
                                                                className="text-base">{translate('text.register_success')}!</div>
                                                            <Link
                                                                className="btn btn-outline py-1 px-4"
                                                                to={`/login`}>{translate('btn.back_to_login')}</Link>
                                                        </div>
                                                    </InfoParagraph>
                                                )}
                                                {/*</div>*/}
                                                <div className="form-side bg-inverse">
                                                    <form>
                                                        {/*<div className="col-12 p-0">*/}
                                                        {/*    <h1>Register</h1>*/}
                                                        {/*    <nav*/}
                                                        {/*        className="breadcrumb-container d-none d-sm-block d-lg-inline-block register-breadcrumb"*/}
                                                        {/*        aria-label="breadcrumb">*/}
                                                        {/*        <ol className="breadcrumb pt-0">*/}
                                                        {/*            <li*/}
                                                        {/*                className={`breadcrumb-item ${this.state.currentStep === 1 ? 'font-weight-bold' : 'text-tm-gray-500'} ${this.state.progress > 1 ? 'hover' : ''}`}>*/}
                                                        {/*            <span*/}
                                                        {/*                onClick={() => this.setStep(1)}>Account info</span>*/}
                                                        {/*            </li>*/}
                                                        {/*            <li*/}
                                                        {/*                className={`breadcrumb-item ${this.state.currentStep === 2 ? 'font-weight-bold' : 'text-tm-gray-500'} ${this.state.progress > 2 ? 'hover' : ''}`}>*/}
                                                        {/*            <span*/}
                                                        {/*                onClick={() => this.setStep(2)}>Company info</span>*/}
                                                        {/*            </li>*/}
                                                        {/*            <li*/}
                                                        {/*                className={`breadcrumb-item ${this.state.currentStep === 3 ? 'font-weight-bold' : 'text-tm-gray-500'} ${this.state.progress > 3 ? 'hover' : ''}`}>*/}
                                                        {/*                <span*/}
                                                        {/*                    onClick={() => this.setStep(3)}>Billing</span>*/}
                                                        {/*            </li>*/}
                                                        {/*            <li*/}
                                                        {/*                className={`breadcrumb-item ${this.state.currentStep === 4 ? 'font-weight-bold' : 'text-tm-gray-500'} ${this.state.progress === 4 ? 'hover' : ''}`}>*/}
                                                        {/*            <span*/}
                                                        {/*                onClick={() => this.setStep(4)}>Complete</span>*/}
                                                        {/*            </li>*/}
                                                        {/*        </ol>*/}
                                                        {/*    </nav>*/}
                                                        {/*    <div className="separator mb-5"></div>*/}
                                                        {/*</div>*/}

                                                        {this.state.currentStep === 1 && (
                                                            <React.Fragment>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <h5
                                                                            className="mb-4 text-tm-gray-700 text-base">{translate('card_header.company_logo')}</h5>


                                                                        <p className="text-tm-gray-500">Please use image
                                                                            that is
                                                                            at least 200px wide and
                                                                            100px high.</p>


                                                                        {!this.state.altImage && !croppedImageUrl && (
                                                                            <div
                                                                                className="text-left relative mb-5">
                                                                                <i
                                                                                    className="absolute position-absolute-center"></i>

                                                                                <img
                                                                                    className="z-index-10 d-inline-block relative"
                                                                                    style={{width: '200px'}}
                                                                                    src={(this.state.files.length > 0) ? this.state.files[0].preview : ''}
                                                                                    alt={''}
                                                                                />
                                                                            </div>
                                                                        )}

                                                                        {croppedImageUrl && (
                                                                            <React.Fragment>
                                                                                <h6 className="text-tm-gray-500">Preview:</h6>
                                                                                <div style={{
                                                                                    width: '200px',
                                                                                    height: '100px'
                                                                                }}
                                                                                     className="mb-4 border border-color-table">
                                                                                    <img alt="Crop"
                                                                                         style={{width: '100%'}}
                                                                                         src={croppedImageUrl}/>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}

                                                                        {src && (
                                                                            <React.Fragment>
                                                                                <h6 className="text-tm-gray-500">Select
                                                                                    area
                                                                                    to
                                                                                    crop image to:</h6>

                                                                                <div className="row mb-3">
                                                                                    <div className="col">
                                                                                        <ReactCrop
                                                                                            src={src}
                                                                                            crop={crop}
                                                                                            ruleOfThirds
                                                                                            onImageLoaded={this.onImageLoaded}
                                                                                            onComplete={this.onCropComplete}
                                                                                            onChange={this.onCropChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <button
                                                                                        onClick={() => this.setState({
                                                                                            src: null,
                                                                                            croppedImageUrl: null
                                                                                        })}
                                                                                        className="btn btn-xs btn-outline">
                                                                                        Cancel
                                                                                    </button>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}

                                                                        {!src && (
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div
                                                                                        className="mb-5 w-full text-center">
                                                                                        <Dropzone
                                                                                            onDrop={(acceptedFiles) => {
                                                                                                this.onSelectFile(acceptedFiles)
                                                                                            }}
                                                                                            onDragEnter={this.onDragEnter}
                                                                                            onDragLeave={this.onDragLeave}
                                                                                            onDropAccepted={this.onDrop}
                                                                                            maxFiles={1}
                                                                                            accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                                                                                            maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                                                                            multiple={false}
                                                                                        >
                                                                                            {({
                                                                                                  getRootProps,
                                                                                                  getInputProps
                                                                                              }) => (
                                                                                                <section>
                                                                                                    <div {...getRootProps()}
                                                                                                         className={'p-6 border-primary border-dashed border-2 rounded-card text-center flex justify-center items-center ' + (this.state.dropzoneActive ? 'active' : '')}>
                                                                                                        <div>
                                                                                                            <input {...getInputProps()} />
                                                                                                            <p>{translate('field.drag_n_drop')}</p>
                                                                                                            <em>{translate('field.image_types')}</em>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </section>
                                                                                            )}
                                                                                        </Dropzone>
                                                                                        {files}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                </div>


                                                                <div className="grid grid-cols-12 gap-4 pb-8">
                                                                    <FieldContainer
                                                                        item={this.state.fields.CompanyName}
                                                                        translate={translate}
                                                                    >
                                                                        <FieldText
                                                                            addClass="form-control"
                                                                            onChange={this.handleInputChange}
                                                                            {...this.state.fields.CompanyName}
                                                                            onFocus={false}
                                                                            placeholder={''}
                                                                        />
                                                                    </FieldContainer>

                                                                    <FieldContainer
                                                                        item={this.state.fields.Email}
                                                                        translate={translate}
                                                                    >
                                                                        <FieldText
                                                                            className="form-control"
                                                                            onChange={this.handleInputChange} {...this.state.fields.Email}
                                                                            onFocus={false}
                                                                            translate={translate}
                                                                            placeholder={''}
                                                                            addClass={'form-control'}
                                                                        />
                                                                    </FieldContainer>

                                                                    <FieldContainer
                                                                        item={this.state.fields.EmailConfirm}
                                                                        translate={translate}
                                                                    >
                                                                        <FieldText
                                                                            className="form-control"
                                                                            onChange={this.handleInputChange}
                                                                            {...this.state.fields.EmailConfirm}
                                                                            onKeyDown={(e) => this.handleEnterClick(e.key)}
                                                                            translate={translate}
                                                                            onFocus={false}
                                                                            placeholder={''}
                                                                            addClass={'form-control'}/>
                                                                    </FieldContainer>

                                                                </div>
                                                            </React.Fragment>
                                                        )}

                                                        {this.state.currentStep === 2 && (
                                                            <div className="grid grid-cols-12 gap-4">
                                                                <FieldContainer
                                                                    item={this.state.fields.CountryID}
                                                                    translate={translate}
                                                                >
                                                                    <FieldSelectSearch
                                                                        className="form-control"
                                                                        values={this.state.Country}
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange} {...this.state.fields.CountryID}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.AreaCode}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.AreaCode}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}/>
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.PhoneNumber}
                                                                    translate={translate}
                                                                >
                                                                    <FieldTextMask
                                                                        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                        showMask
                                                                        onFocus={handleMaskedFocus}
                                                                        id={'PhoneNumber'}
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.PhoneNumber}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}/>
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.PhoneExtension}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.PhoneExtension}
                                                                        placeholder={''}/>
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.AddressName}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        onFocus={false}
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.AddressName}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.AddressName2}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange} {...this.state.fields.AddressName2}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>


                                                                <FieldContainer
                                                                    item={this.state.fields.CityName}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.CityName}
                                                                        onFocus={false}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                {this.state.fields.CountryID.value == 1 && (
                                                                    <FieldContainer
                                                                        item={this.state.fields.StateID}
                                                                        translate={translate}
                                                                    >
                                                                        <FieldSelectSearch
                                                                            className="form-control"
                                                                            values={this.state.State}
                                                                            onFocus={false}
                                                                            onChange={this.handleInputChange} {...this.state.fields.StateID}
                                                                            placeholder={''}
                                                                            addClass={'form-control'}
                                                                        />
                                                                    </FieldContainer>
                                                                )}

                                                                <FieldContainer
                                                                    item={this.state.fields.PostalCode}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange} {...this.state.fields.PostalCode}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                <div className="col-span-full">
                                                                    <table
                                                                        className={`table table-borderless ${this.state.lineOfBusinessError ? 'red-border' : ''}`}>
                                                                        <thead className={'thead-light'}>
                                                                        <tr>
                                                                            <th>Line of business *</th>
                                                                            <th className="pl-4">{translate('field.select')}</th>
                                                                            <th className="pl-4">{translate('field.primary')}</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td className="text-base">Brokerage</td>
                                                                            <td className="pl-4">
                                                                                <FieldCheckbox
                                                                                    onFocus={false}
                                                                                    value={this.state.brokerage}
                                                                                    onChange={() => this.setState({brokerage: !this.state.brokerage})}
                                                                                />
                                                                            </td>
                                                                            <td className="pl-4">

                                                                                <input
                                                                                    type="radio"
                                                                                    value={'1'}
                                                                                    disabled={this.state.brokerage ? false : true}
                                                                                    name={'LineOfBusinessPrimary'}
                                                                                    checked={this.state.fields.LineOfBusinessPrimary.value == 1 ? true : false}
                                                                                    onChange={(e) => {
                                                                                        this.handleInputChange('LineOfBusinessPrimary', e.target.value)
                                                                                    }}
                                                                                />

                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-base">Carrier</td>
                                                                            <td className="pl-4">
                                                                                <FieldCheckbox
                                                                                    value={this.state.carrier}
                                                                                    onFocus={false}
                                                                                    onChange={() => this.setState({carrier: !this.state.carrier})}
                                                                                />
                                                                            </td>
                                                                            <td className="pl-4">
                                                                                <input
                                                                                    type="radio"
                                                                                    value={'2'}
                                                                                    disabled={this.state.carrier ? false : true}
                                                                                    name={'LineOfBusinessPrimary'}
                                                                                    checked={this.state.fields.LineOfBusinessPrimary.value == 2 ? true : false}
                                                                                    onChange={(e) => {
                                                                                        this.handleInputChange('LineOfBusinessPrimary', e.target.value)
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <FieldContainer
                                                                    item={this.state.fields.BusinessTypeID}
                                                                    label={translate('field.business_type')}
                                                                    translate={translate}
                                                                >
                                                                    <FieldSelectSearch
                                                                        className="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange} {...this.state.fields.BusinessTypeID}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                        values={this.state.BusinessType}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.FederalID}
                                                                    translate={translate}
                                                                >
                                                                    <FieldTextMask
                                                                        mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                        showMask
                                                                        onFocus={(event) => {
                                                                            setSelectionRange('FederalID', event.target.value)
                                                                        }}
                                                                        id={'FederalID'}
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.FederalID}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.SCAC}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.SCAC}
                                                                        max={SCAC_CODE_FIELD_LENGTH}
                                                                        upperCaseOnly
                                                                        onKeyDown={(e) => this.handleEnterClick(e.key)}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>
                                                            </div>
                                                        )}

                                                        {this.state.currentStep === 3 && (
                                                            <div className="grid grid-cols-12 gap-4">
                                                                <FieldContainer
                                                                    item={this.state.fields.FirstName}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.FirstName}
                                                                        placeholder={''}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.LastName}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.LastName}
                                                                        onFocus={false}
                                                                        placeholder={''}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.BillingCountryID}
                                                                    label={translate('field.CountryID') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldSelectSearch
                                                                        className="form-control"
                                                                        values={this.state.Country}
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange} {...this.state.fields.BillingCountryID}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                {this.state.fields.BillingCountryID.value == 1 && (
                                                                    <FieldContainer
                                                                        item={this.state.fields.BillingStateID}
                                                                        label={translate('field.StateID') + " *"}
                                                                        translate={translate}
                                                                    >
                                                                        <FieldSelectSearch
                                                                            className="form-control"
                                                                            values={this.state.State}
                                                                            onFocus={false}
                                                                            onChange={this.handleInputChange} {...this.state.fields.BillingStateID}
                                                                            placeholder={''}
                                                                            addClass={'form-control'}
                                                                        />
                                                                    </FieldContainer>
                                                                )}


                                                                <FieldContainer
                                                                    item={this.state.fields.BillingCity}
                                                                    label={translate('field.city') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        onFocus={false}
                                                                        addClass="form-control"
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.BillingCity}
                                                                        placeholder={''}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.BillingZip}
                                                                    label={translate('field.zip') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onFocus={false}
                                                                        onChange={this.handleInputChange} {...this.state.fields.BillingZip}
                                                                        placeholder={''}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.BillingAddress1}
                                                                    label={translate('field.AddressName') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.BillingAddress1}
                                                                        onFocus={false}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.BillingAddress2}
                                                                    label={translate('field.AddressName2') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.BillingAddress2}
                                                                        onFocus={false}
                                                                        placeholder={''}
                                                                        addClass={'form-control'}
                                                                    />
                                                                </FieldContainer>


                                                                <FieldContainer
                                                                    item={this.state.fields.CardNumber}
                                                                    label={translate('field.card_number') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.CardNumber}
                                                                        onFocus={false}
                                                                        placeholder={''}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.CVV}
                                                                    label={translate('field.cvv') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.CVV}
                                                                        onFocus={false}
                                                                        placeholder={'123'}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer
                                                                    item={this.state.fields.ExpDate}
                                                                    label={translate('field.exp_date') + " *"}
                                                                    translate={translate}
                                                                >
                                                                    <FieldText
                                                                        addClass="form-control"
                                                                        onChange={this.handleInputChange} {...this.state.fields.ExpDate}
                                                                        onFocus={false}
                                                                        placeholder="MM / YY"
                                                                        onKeyDown={(e) => this.handleEnterClick(e.key)}
                                                                    />
                                                                </FieldContainer>
                                                            </div>
                                                        )}

                                                        {this.state.currentStep === 4 && (
                                                            <React.Fragment>
                                                                <h2 className="mb-4 text-xl font-semibold leading-9 tracking-tight">Welcome
                                                                    to ACCUR8 TMS!</h2>

                                                                <p className='text-base'>You have completed the
                                                                    registration process, please
                                                                    check
                                                                    your email in order to start your account.</p>
                                                            </React.Fragment>
                                                        )}

                                                        <div
                                                            className={`mt-8 pb-4 flex items-center ${this.state.currentStep > 1 ? 'justify-between' : 'justify-end'}`}>

                                                            {this.state.currentStep > 1 && (
                                                                <Button
                                                                    className="btn btn-primary btn-lg btn-shadow"
                                                                    onClick={() => this.setStep(this.state.currentStep - 1)}
                                                                >
                                                                    <ArrowLeftIcon className="w-5 h-5 mr-1 -ml-1"/>
                                                                    {translate('btn.back')}
                                                                </Button>
                                                            )}

                                                            {this.state.currentStep !== lastStep && (
                                                                <Button className="btn btn-primary btn-lg btn-shadow"
                                                                        onClick={() => this.nextStep()}
                                                                >
                                                                    {translate('btn.next')}

                                                                    <ArrowRightIcon className="w-5 h-5 -mr-1 ml-1"/>
                                                                </Button>
                                                            )}

                                                            {this.state.currentStep === lastStep && (
                                                                <Button
                                                                    className="btn btn-primary btn-lg btn-shadow btn:focus"
                                                                    onClick={this.submit}
                                                                >
                                                                    <CheckIcon className="w-5 h-5 mr-1 ml-1"/>
                                                                    {translate('btn.register')}
                                                                </Button>
                                                            )}
                                                        </div>


                                                        {this.props.user.error && (
                                                            <InfoParagraph type="danger" message={translate(this.props.user.errorMessage)}/>
                                                        )}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )}
                            {this.props.user.isLoading && (
                                <div className="absolute position-absolute-cover h-full">
                                    <LoaderSmall addClass={'absolute position-absolute-center'}/>
                                </div>
                            )}
                        </main>
                    </form>
                </AuthLayout>
            </Layout>
        )
    }
}

export default connect(state => state)(RegisterView)



