import CalendarIcon from "@heroicons/react/20/solid/CalendarIcon";
import PopOver from "../index";
import React from "react";
import moment from "moment";
import LocalStorage from "../../../util/localStorage";
import {FieldsManager} from "../../../../data/services/fields";
import {classNames} from "../../../util/util-helpers";
import {toFrontDate} from "../../../util/util-dates";
import {fieldsToHtml, includeFields} from "../../../util/util-fields";

export default function PopoverDatePicker({queryFields, filterLabel, onQueryInputChange, updateFields, translate}) {

    const getDateFilterLabel = () => {
        if (filterLabel) {
            return filterLabel;
        }

        let defaultText = (!queryFields.StartDate.value || !queryFields.EndDate.value) && translate('text.select_date_range');

        let from = '*'
        let to = '*'

        if (queryFields.StartDate.value) {
            from = toFrontDate(queryFields.StartDate.value);

        }

        if (queryFields.EndDate.value) {
            to = toFrontDate(queryFields.EndDate.value)
        }

        return defaultText ? defaultText : (from + ' - ' + to)
    }

    const setTimeSpan = (timeUnit, timeUnitType = 'day', operation = 'subtract') => {
        let fields = queryFields;

        if (operation === 'subtract') {
            fields = FieldsManager.updateField(fields, 'StartDate', moment().subtract(timeUnit, timeUnitType).format('YYYY-MM-DD HH:mm:ss'));
            fields = FieldsManager.updateField(fields, 'EndDate', currentDate());
        } else {
            fields = FieldsManager.updateField(fields, 'StartDate', currentDate());
            fields = FieldsManager.updateField(fields, 'EndDate', moment().add(timeUnit, timeUnitType).format('YYYY-MM-DD HH:mm:ss'));
        }

        updateFields(fields);
    }

    const handleSetTimePeriodClick = (StartDate, EndDate) => {
        let fields = queryFields;

        fields = FieldsManager.updateField(fields, 'StartDate', StartDate);
        fields = FieldsManager.updateField(fields, 'EndDate', EndDate);

        updateFields(fields);
    }

    return (
        <PopOver
            className="relative"
            btnClass="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-3"
            BtnIcon={CalendarIcon}
            chevronIcon={true}
            btnLabel={getDateFilterLabel()}
            btnIconClass="h-4 w-4 mr-1"
            widthClass="max-w-2xl"
            positionClass="absolute translate-x-0 left-0"
        >
            <div className="px-6 pb-6 pt-4 bg-popup border border-tm-gray-300 rounded-lg">
                <React.Fragment>
                    <p className="text-lg text-tm-gray-700 mb-4">Select date range</p>

                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-4 flex flex-col gap-4">
                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(queryFields, ['StartDate', 'EndDate']))), translate, onQueryInputChange)}
                        </div>

                        <div className="col-span-6 col-start-7">
                            <label
                                className="flex items-center text-sm font-semibold text-tm-gray-700 mb-1">
                                Quick selection
                            </label>

                            <div className="grid gap-2 grid-cols-2">
                                {dateButtons(translate).map((it, i) => (
                                    <button
                                        key={i}
                                        onClick={() => setTimeSpan(it.unit, it.unitType, it.operation)}
                                        className="btn btn-outline w-full justify-center h-6"
                                    >
                                        {it.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="flex border border-tm-gray-200 mt-4 rounded-md overflow-hidden">
                        {getPreviousMonths(handleSetTimePeriodClick)}
                    </div>
                </React.Fragment>
            </div>
        </PopOver>
    )
}

const getPreviousMonths = (onSetTimePeriodClick) => {
    const currentYear = moment().startOf('year')
    return [...Array(12).keys()].map((it, i) => {
        let StartDate = moment().subtract(11 - it, 'month').startOf('month')
        const label = StartDate.format('MMM')
        StartDate = StartDate.format('YYYY-MM-DD HH:mm:ss')
        const EndDate = moment().subtract(11 - it, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')

        return (
            <button
                key={i}
                className={classNames(
                    'hover:bg-primary border-tm-gray-200 justify-center hover:text-white text-tm-gray-900 p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-2 flex-grow',
                    (moment(StartDate, 'YYYY-MM-DD HH:mm:ss').isBefore(currentYear)) ? 'bg-tm-gray-100' : '',
                    i !== 11 ? "border-r" : undefined
                )}
                onClick={() => onSetTimePeriodClick(StartDate, EndDate)}
            >
                {label}
            </button>
        )
    })
}

const currentDate = () => {
    if (!LocalStorage.get('user')) return

    return moment().format('YYYY-MM-DD HH:mm:ss')
}

const dateButtons = (translate) => [
    {
        unit: 1,
        unitType: 'day',
        operation: 'subtract',
        label: translate('text.yesterday')
    },
    {
        unit: 1,
        unitType: 'day',
        operation: 'add',
        label: translate('text.tomorrow')
    },
    {
        unit: 3,
        unitType: 'day',
        operation: 'subtract',
        label: translate('text.last_3_days')
    },
    {
        unit: 3,
        unitType: 'day',
        operation: 'add',
        label: translate('text.next_3_days')
    },
    {
        unit: 7,
        unitType: 'day',
        operation: 'subtract',
        label: translate('text.last_7_days')
    },
    {
        unit: 7,
        unitType: 'day',
        operation: 'add',
        label: translate('text.next_7_days')
    },
    {
        unit: 1,
        unitType: 'month',
        operation: 'subtract',
        label: translate('text.last_30_days')
    },
    {
        unit: 1,
        unitType: 'month',
        operation: 'add',
        label: translate('text.next_30_days')
    }]