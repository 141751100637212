import { WindowIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import ChatSection from './chat-section';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import ModalDefault from "../../../modal/modal-default";
import {layoutUpdate} from "../../../../../data/actions/ui";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {getUser} from "../../../../util/util-auth";
import {classNames} from "../../../../util/util-helpers";

export default function AIChat({translate, onHelpChatToggleClick}) {
    const textboxRef = useRef();
    const modalBodyRef = useRef();

    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    
    const isOpen = ui.isAIChatVisible;
    const botChatDisplayType = ui.AIChatPosition;

    const [fields, setFields] = useState(() => getFields());
    const [messages, setMessages] = useState([]);
    const [streamMessage, setStreamMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userGroups = (getUser("ContactGroups")?.map((it) => {
        it = it.ContactGroupName;
        return it;
    }).join(", "));

    const B_INST = '[INST]';
    const E_INST = '[/INST]';
    const B_SYS = B_INST + ' <<SYS>> ';
    const E_SYS = '<</SYS>>';

    const baseQuestion = `You are a dedicated customer support agent for ACCUR8 TMS, a Transportation Management Software. Your primary role is to assist users with inquiries related to ACCUR8 TMS, ranging from how to navigate and utilize the system effectively to addressing broader queries about the transportation industry that directly relate to the use of ACCUR8 TMS.
Instructions:
1. Respond to questions about ACCUR8 TMS with accurate and helpful information. If the question is about how to use specific features of ACCUR8 TMS or general advice on making the most out of the software, provide clear and detailed guidance.
2. If a question pertains to the transportation industry and is relevant to the application of ACCUR8 TMS, offer knowledgeable insights that reflect industry practices, regulations, or trends as they relate to the use of TMS in such contexts.
3. Should you encounter a question that falls outside your scope of knowledge about ACCUR8 TMS or if the information needed is not available to you, respond truthfully with "I don't know. I am one dimensional bot specializing in ACCUR8 TMS only" Do not speculate or provide fictional answers.
4. Maintain a professional tone throughout your interactions, ensuring users feel supported and guided in their ACCUR8 TMS experience.`;

    const initialPrompt =
        B_SYS + `${baseQuestion} My name is ${getUser("Contact.FirstName")} ${getUser("Contact.LastName")}, and my job title is ${getUser("Contact.Title")}`
        + (userGroups
            ? (`, I am member of ${userGroups} groups.`)
            : ".") + `.` + E_SYS;

    function setBotChatDisplayType(displayType) {
        dispatch(layoutUpdate(
            {   
                isAIChatVisible: true,
                AIChatPosition: displayType
            }
        ));
    }

    function getFields() {
        return {
            UserInput: new Field('UserInput', "", [''], false, 'textarea', {
                addContainerClass: "relative",
                hideLabel: true, labelType: "float", heightClass: "leading-tight py-2", 
            }),
        }
    }

    function handleInputChange(name, value) {
        let fieldsClone = Object.assign({}, fields);

        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);
        
        if (fieldsClone.UserInput.value === '') {
            textboxRef.current.textContent = '';
        }

        //fieldsClone.UserInput.props.onKeyPress = handleInputKeyPress;

        setFields(fieldsClone);
    }

    function handleClearConversation() {
        setMessages([]);
        handleInputChange("UserInput", "");
    }

    function handleRegenerateConversation() {
        const messagesClone = [...messages];
        messagesClone.pop();
        setMessages(messagesClone)
        makeCompletionRequest(messagesClone, generateRequestParams(messagesClone))
    }
    
    function handleInputKeyPress(e) {
        if (e.key === "Enter" && !e.shiftKey && !!fields.UserInput.value && !isLoading) {
            e.preventDefault();
            submit();
        }
    }

    function handleSubmit() {
        if (!isLoading) {
            submit();
        }
    }

    function handleHelpChatToggleClick() {
        dispatch(
            layoutUpdate(
                {
                    isAIChatVisible: false,
                    AIChatPosition: ui.AIChatPosition
                }
            )
        )
    }

    function generateRequestParams(messages) {
        let newPrompt = messages.reduce((memo, it, i) => {
            if (i % 2 === 0) {
                memo += ` ${it.content}${E_INST}`;
            } else {
                memo += `${it.content}${B_INST}`;
            }
            return memo
        }, initialPrompt);

        return {
            "stream": false,
            "n_predict": 1024,
            "temperature": 0.6,
            "stop": [
            "</s>"
        ],
            "repeat_last_n": 256,
            "repeat_penalty": 1.2,
            "top_k": 50,
            "top_p": 0.9,
            "tfs_z": 1,
            "typical_p": 1,
            "presence_penalty": 0,
            "frequency_penalty": 0,
            "mirostat": 0,
            "mirostat_tau": 5,
            "mirostat_eta": 0.1,
            "grammar": "",
            "prompt": newPrompt
        }
    }

    function makeCompletionRequest(messagesClone, params) {
        setIsLoading(true);
        axios.post(
            'http://192.168.0.128:6578/completion',
            params,
            {
                headers: {}
            }
        )
            .then((response) => {
                const botResponse = response.data.content;
                const messagesCloneResponse = [...messagesClone]
                messagesCloneResponse.push({
                    id: messages.length + 2,
                    user: "bot",
                    content: botResponse
                });
                setStreamMessage(true);
                setMessages(messagesCloneResponse);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

    function submit() {
        const messagesClone = [...messages];
        const userMessage = fields.UserInput.value;
        messagesClone.push({
            id: messages.length + 1,
            user: "me",
            content: userMessage
        });
        setTimeout(() => {
            modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
            textboxRef.current.focus();
        }, 200)
        setStreamMessage(true);
        setMessages(messagesClone);
        handleInputChange("UserInput", "");
        
        
        makeCompletionRequest(messagesClone, generateRequestParams(messagesClone))
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                textboxRef.current.focus();
                modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
            }, 400);
        }

        setStreamMessage(false);
    }, [isOpen])

    return (
        <React.Fragment>
                <ModalDefault
                    show={isOpen && botChatDisplayType === "modal"}
                    widthClass={"max-w-3xl"}
                    title={translate("modal_heading.assist_bot")}
                    closeButtonLabel={translate("btn.close")}
                    options={
                        <button 
                        className='btn btn-icon'
                        onClick={() => {
                            dispatch(
                                layoutUpdate(
                                    {
                                        isAIChatVisible: true,
                                        AIChatPosition: 'bottom'
                                    }
                                )
                            )
                            setStreamMessage(false);
                            setTimeout(() => {
                                modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
                            }, 200)
                        }}
                    >
                        <WindowIcon className='w-5 h-5 text-tm-gray-600 rotate-180' />
                    </button>
                }
                onClose={handleHelpChatToggleClick}
            >
                <ChatSection
                    bodyClass='p-5 space-y-5 bg-tm-gray-100 max-h-[calc(100vh-24rem)] overflow-auto scroll-smooth'
                    bodyRef={modalBodyRef}
                    messages={messages} 
                    streamMessage={streamMessage} 
                    fields={fields} 
                    onInputChange={handleInputChange} 
                    setMessages={setMessages}
                    onClearConversation={handleClearConversation}
                    onRegenerateConversation={handleRegenerateConversation}
                    translate={translate}
                    isLoading={isLoading}
                    onInputKeyPress={handleInputKeyPress}
                    onSubmit={handleSubmit}
                    textboxRef={textboxRef}
                />
            </ModalDefault>

            {botChatDisplayType === "sidebar" &&  isOpen && (
                <div className='animate-slide-in-right fixed top-0 bottom-0 right-0 w-96 bg-inverse z-50 border-l border-tm-gray-300'>
                    <div className='h-16 flex items-center justify-between px-5'>
                        <div className='text-lg font-medium'>Ask our AI assistant for help</div>

                        <button 
                            className='btn btn-icon'
                            onClick={() => {
                                dispatch(
                                    layoutUpdate(
                                        {
                                            isAIChatVisible: true,
                                            AIChatPosition: 'bottom'
                                        }
                                    )
                                )
                                setStreamMessage(false);
                                setTimeout(() => {
                                    modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight
                                }, 200)
                            }}
                        >
                            <WindowIcon className='w-5 h-5 text-tm-gray-600' />
                        </button>

                        <button
                            onClick={onHelpChatToggleClick}
                            className='btn btn-close'
                        >
                            <XMarkIcon className='w-6 h-6' />
                        </button>
                    </div>

                    <ChatSection
                        bodyClass={
                            classNames(
                                'pt-5 px-5 space-y-5 bg-tm-gray-100 overflow-auto scroll-smooth',
                                messages.length ? 'h-[calc(100vh-18rem)]' : 'h-[calc(100vh-18rem)]'
                            )
                        }
                        bodyRef={modalBodyRef}
                        messages={messages} 
                        streamMessage={streamMessage} 
                        fields={fields} 
                        onInputChange={handleInputChange} 
                        setMessages={setMessages}
                        onClearConversation={handleClearConversation}
                        onRegenerateConversation={handleRegenerateConversation}
                        translate={translate}
                        isLoading={isLoading}
                        nInputKeyPress={handleInputKeyPress}
                        onSubmit={handleSubmit}
                        textboxRef={textboxRef}
                    />
                </div>
            )}

            {botChatDisplayType === "bottom" &&  isOpen && (
                <div className='fixed text-tm-gray-700 h-[24rem] bottom-0 left-0 right-0 bg-inverse z-50 border-t border-tm-gray-300'>
                    <ChatSection
                        displayType={"bottom"}
                        bodyClass={
                            classNames(
                                'space-y-5 bg-tm-gray-100 overflow-auto scroll-smooth h-[24rem]'
                            )
                        }
                        bodyRef={modalBodyRef}
                        messages={messages} 
                        streamMessage={streamMessage} 
                        fields={fields} 
                        onInputChange={handleInputChange} 
                        setMessages={setMessages}
                        onClearConversation={handleClearConversation}
                        onRegenerateConversation={handleRegenerateConversation}
                        translate={translate}
                        isLoading={isLoading}
                        onInputKeyPress={handleInputKeyPress}
                        onSubmit={handleSubmit}
                        textboxRef={textboxRef}
                        setDisplayType={setBotChatDisplayType}
                        onHelpChatToggleClick={handleHelpChatToggleClick}
                        setStreamMessage={setStreamMessage}
                    />
                </div>
            )}

        </React.Fragment>
    )
}
