import React from "react";
import {classNames} from "../../../../util/util-helpers";

export default function Page({addClass, children}) {
    return (
        <div className={classNames(addClass, "flex flex-col space-y-2 h-full")}>
            {children}
        </div>
    )
}
