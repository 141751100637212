import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, resetResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import InfoTab from './infoTab'
import {CREATE_PERM, READ_PERM} from '../../../util/util-constants'
import {checkPerm, getDocumentTypesFor} from '../../../common/util/util-helpers'
import {scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import {getInfoResource} from "../../../data/actions/infoResource";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import Layout from "../../../common/components/layout";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import Card from "../../../common/components/card";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import {resourceIsLoaded} from '../../../common/util/util-helpers';
class ClaimCreateView extends Component {

    constructor(props) {
        super(props)
        const tabs = [
            {
                name: 'ClaimsInfo',
                resource: Resources.ClaimsInfo,
                current: true,
                visible: true
            },
            {
                name: 'ClaimsDocuments',
                resource: Resources.ClaimsDocuments,
                current: false,
                visible: true
            },
        ].filter((it) => checkPerm(it.resource, READ_PERM))

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.ClaimsInfo,

            // Fields
            fields_info: this.getFieldsInfo(),
            fields_documents: [],

            // Modals
            redirectModalOpen: false,
            isUnitSwitchDialogOpen: false,

            // Helpers
            canSwapTabs: true,
            emailExist: false,
            canSubmit: false
        }

        const query = new URLSearchParams(this.props.location.search);
        this.LoadID = query.get('LoadID');

        this.splitDispatchDialogRef = React.createRef()
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.LoadID) {
            this.fetchShortInfoData(this.LoadID);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }

        if (!!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS') && !this.props.ui.updateOfficeDialog) {
            this.setState({emailExist: true, selectedTab: 'info'})
        }

        if (resourceIsLoaded(this.props.info, prevProps.info)) {
            let fieldsInfoUpdate = this.state.fields_info;
            const load = this.props.info.data ?? {};

            if (!!load.IsBroker) {
                fieldsInfoUpdate.DriverID.value = {label: load.DriverNameTxt, value: -1}; // TODO

            } else {
                fieldsInfoUpdate.DriverID.value = {label: load.Driver, value: load.DriverID};
            }

            fieldsInfoUpdate.TruckID.value = {label: load.Truck, value: load.TruckID};
            fieldsInfoUpdate.TrailerID.value = {label: load.Trailer, value: load.TrailerID};
            fieldsInfoUpdate.CustomerID.value = {label: load.Customer, value: load.CustomerID};
            fieldsInfoUpdate.LoadNumber.value = {label: load.LoadID, value: load.LoadID};

            fieldsInfoUpdate.DriverID.disabled = true;
            fieldsInfoUpdate.TruckID.disabled = true;
            fieldsInfoUpdate.TrailerID.disabled = true;
            fieldsInfoUpdate.CustomerID.disabled = true;
            fieldsInfoUpdate.LoadNumber.disabled = true;

            this.setState({fields_info: fieldsInfoUpdate});
        }
    }

    /** Data events
     ================================================================= */
    onSubmit = () => {
        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info)) {
                const vals = FieldsManager.getFieldKeyValues(this.state.fields_info);
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({
                        LoadID: vals.LoadNumber
                    }, vals),
                    resource: Resources.Claims,
                    showRedirectDialog: true,
                    errorMessage: true,
                    document: this.state.fields_documents,
                    documentResource: Resources.ClaimsDocuments,
                }))
            } else {
                this.handleTabChange(Resources.ClaimsInfo)
                scrollErrorIntoViewFields([this.state.fields_info])
            }
        })
    }
    fetchShortInfoData = (LoadID) => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadShort,
            query: {
                id: LoadID
            }
        }))
    }

    /** UI events
     ================================================================= */
    handleToggleRedirectDialog = () => {
        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.id,
            redirectModalOpen: !this.state.redirectModalOpen,
            fields_info: this.getFieldsInfo(),
            fields_documents: [],
            canSubmit: false
        })
    }

    handleFormCancel = () => {
        this.setState({
            fields_info: this.getFieldsInfo(),
            canSubmit: false
        })
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }
        this.tabChange(resource)
    }

    tabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    setStateInChild = (fieldName, label, value, metadata = {}) => {
        this.setState({
            fields_info: FieldsManager.updateField(this.state.fields_info, fieldName, {
                label: label,
                value: value,
                metadata: metadata
            })
        })
    }

    toggleSelectFromUnit = () => {
        this.setState({
            isUnitSwitchDialogOpen: !this.state.isUnitSwitchDialogOpen
        });
    }

    applySelectedUnit = (unit) => {
        let fieldsInfoUpdate = this.state.fields_info;

        fieldsInfoUpdate.DriverID.value = {label: unit.Driver, value: unit.DriverID};
        fieldsInfoUpdate.TruckID.value = {label: unit.Truck, value: unit.TruckID};
        fieldsInfoUpdate.TrailerID.value = {label: unit.Trailer, value: unit.TrailerID};

        fieldsInfoUpdate.DriverID.disabled = true;
        fieldsInfoUpdate.TruckID.disabled = true;
        fieldsInfoUpdate.TrailerID.disabled = true;

        fieldsInfoUpdate.LoadNumber.disabled = false;
        fieldsInfoUpdate.CustomerID.disabled = false;

        this.setState({
            isUnitSwitchDialogOpen: false,
            fields_info: fieldsInfoUpdate
        });
    }

    /** Fields/Tables onChange events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields_info, name, value)

        if (name === 'ClaimClosed') {
            fields["DateClosed"].disabled = !value
            if (!value) fields["DateClosed"].value = ''
        }
        if (name === 'Approved' && !!value) {
            fields['Denied'].value = false
        }
        if (name === 'Denied' && !!value) {
            fields['Approved'].value = false
        }

        if (name === "LoadNumber") {
            if (value?.metadata) {
                fields = FieldsManager.updateField(fields, "DriverID", {
                    label: value?.metadata?.Driver,
                    value: value?.metadata?.DriverID,
                })
                fields = FieldsManager.updateField(fields, "TruckID", {
                    label: value?.metadata?.Truck,
                    value: value?.metadata?.TruckID,
                })
                fields = FieldsManager.updateField(fields, "TrailerID", {
                    label: value?.metadata?.Trailer,
                    value: value?.metadata?.TrailerID,
                })
                fields = FieldsManager.updateField(fields, "CustomerID", {
                    label: value?.metadata?.Customer,
                    value: value?.metadata?.CustomerID,
                })
            }

            fields['ChargeDriver'].metadata.label = !!value?.metadata?.IsBroker ? 'ChargeCarrier' : 'ChargeDriver';
        }
        if (name === "ChargeDriver") {
            fields.Amount.disabled = !value;
        }
        this.setState({
            canSubmit: true,
            fields_info: fields
        })
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    /** Fields/Data definitions
     ================================================================= */
    getFieldsInfo = () => {

        return {
            LoadNumber: new Field('LoadNumber', '', [], false, 'select-search'),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search'),
            ClaimNumber: new Field('ClaimNumber', '', ['empty']),
            ClaimCode: new Field('ClaimCode', '', []),
            ClaimOpened: new Field('ClaimOpened', '', [], false, 'date', {}, {}),
            ClaimDate: new Field('ClaimDate', '', [], false, 'date', {}, {}),
            DriverID: new Field('DriverID', '', [], false, 'select-search'),
            TruckID: new Field('TruckID', '', [], false, 'select-search'),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search'),
            InsuranceID: new Field('InsuranceID', '', [], false, 'select-search'),

            // Claim Details
            Lost: new Field('Lost', '', ['float_or_empty'], false, 'float'),
            Damaged: new Field('Damaged', '', ['float_or_empty'], false, 'float'),
            ClaimAmount: new Field('ClaimAmount', '', ['float_or_empty'], false, 'float'),
            ContactName: new Field('ContactName', '', []),
            ClaimDescription: new Field('ClaimDescription', '', [], false, 'textarea', {
                addContainerClass: "col-span-full"
            }),

            // Claim Disposition
            ClaimClosed: new Field('ClaimClosed', '', [], false, 'checkbox'),
            DateClosed: new Field('DateClosed', '', [], true, 'date', {}, {}),
            Approved: new Field('Approved', '', [], false, 'checkbox'),
            Denied: new Field('Denied', '', [], false, 'checkbox'),
            SettledAmount: new Field('SettledAmount', '', ['float_or_empty'], false, 'float', {
                addContainerClass: "col-span-2"
            }),
            ChargeDriver: new Field('ChargeDriver', '', [], false, 'checkbox', {}),
            Amount: new Field('Amount', '', ['float_or_empty'], true, 'float'),
            FinalNotes: new Field('FinalNotes', '', [], false, 'textarea', {
                addContainerClass: "col-span-full"
            }),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, info} = this.props
        const isLoading = info.isLoading;
        return (
            <Layout
                {...this.props}
                hasFooter={true}
                isDirty={this.state.canSubmit}
                customPageTitle={"Claim"}
            >
                <Page>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flex justify-between items-center">
                                    <div className="mb-2 flex justify-start items-center">
                                        <GoBackButton
                                            translate={this.props.translate}
                                            history={this.props.history}
                                            path={'/claims'}
                                        />

                                        <h1 className={'mr-5 text-3xl ml-2'}>
                                            {translate('text.create')} {translate('page.heading.Claims')}
                                        </h1>
                                    </div>
                                </div>

                                <div className="mb-5 hidden sm:block">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="tab-content">
                                    <div className="tab-pane show active">
                                        <div className="row">
                                            <div className="col">
                                                {this.state.selectedTab === Resources.ClaimsInfo && (
                                                    <InfoTab
                                                        handleInputChange={this.handleInputChange}
                                                        fields_info={this.state.fields_info}
                                                        setStateInChild={this.setStateInChild}
                                                        onSelectFromUnitClick={this.toggleSelectFromUnit}
                                                        isLoading={isLoading}
                                                        hideSelectUnit={!!this.LoadID || this.state.fields_info.LoadNumber.value}
                                                        {...this.props}/>
                                                )}
                                                {this.state.selectedTab === Resources.ClaimsDocuments && (
                                                    <Card>
                                                        <DocumentsCreateTab
                                                            fields={this.state.fields_documents}
                                                            translate={this.props.translate}

                                                            documentTypes={getDocumentTypesFor("IsSafetyDocument")}
                                                            documentUploadFields={(index = 0) => {
                                                                return Object.assign({}, {
                                                                    DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {}, {
                                                                        values: getDocumentTypesFor("IsSafetyDocument")
                                                                    }),
                                                                    ExpiryDate: new Field("ExpiryDate", '', [], false, 'date'),
                                                                    Description: new Field("Description", '', [], false, 'textarea'),
                                                                })
                                                            }}

                                                            handleUploadDocument={this.handleUploadDocument}
                                                            handleDeleteDocument={this.handleDeleteDocument}
                                                        />
                                                    </Card>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PageFooter
                        translate={translate}
                        canSubmit={this.state.canSubmit}
                        actionCancel={this.handleFormCancel}
                        actionSubmit={checkPerm(Resources.Claims, CREATE_PERM) && this.onSubmit}
                    />
                </Page>

                <RedirectDialog
                    show={this.state.redirectModalOpen}
                    title={translate('card_header.claim_created')}
                    onClose={() => {
                        this.props.dispatch(resetResource())
                        this.setState({
                            createdResourceID: null,
                            redirectModalOpen: false
                        })
                    }}

                    createdLabel={translate('btn.claim')}
                    createdLink={`/claims/info/${this.state.createdResourceID}`}

                    newLabel={translate('btn.create_new')}

                    listLabel={translate('btn.list')}
                    listLink="/claims"

                    dashboardLabel={translate('btn.dashboard')}

                    {...this.props}
                >
                    <p>{translate('message.claim_created')}</p>
                </RedirectDialog>


                <ResourceTableDialog
                    show={this.state.isUnitSwitchDialogOpen}
                    dialogResource={this.props.dialogResource}
                    initialFocusRef={this.splitDispatchDialogRef}
                    resource={Resources.Units}
                    title={translate("text.select_from_dispatch_manager")}
                    dispatch={this.props.dispatch}
                    fields={{
                        DriverID: new Field('DriverID', '', [''], false, 'select-search'),
                        CoDriverID: new Field('CoDriverID', '', [''], false, 'select-search'),
                        TruckID: new Field('TruckID', '', [''], false, 'select-search'),
                        TrailerID: new Field('TrailerID', '', [''], false, 'select-search'),
                    }}
                    sortBy={'DriverID'}
                    sort={'DESC'}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}, {innerRef: this.splitDispatchDialogRef}),
                    }}

                    defaultAction={this.applySelectedUnit}

                    onCustomActions={[
                        {
                            tooltipText: () => translate('btn.select_unit'),
                            action: (it) => this.applySelectedUnit(it),
                            icon: ArrowDownTrayIcon
                        }
                    ]}
                    onClose={this.toggleSelectFromUnit}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(ClaimCreateView)

