import React, {Component} from 'react'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getLookup,
    groupListBySCAC,
    returnSameKeyValueObject
} from '../../../common/util/util-helpers'
import {fieldsToHtml} from "../../../common/util/util-fields";
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {LoaderSmall} from "../../../common/components/loader";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";
import Card from "../../../common/components/card";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";

export default class InfoTab extends Component {
    constructor(props) {
        super(props)
    }

    returnVehicleHeaderNaming = (index) => {
        switch (index) {
            case 0:
                return '2nd'
            case 1:
                return '3rd'
            default:
                return (index + 2) + 'th'

        }
    }

    getFieldTypes = () => {
        return Object.values(this.props.fields_info).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    render() {
        const {translate, resource} = this.props
        const selects = {
            AccidentCodeID: this.props.AccidentCode,
            CitationsID: {1: 'None', 2: 'Truck', 3: 'Vehicle', 4: 'Both'},
            AccidentCountryID: getLookup('Country'),
            CompanyAttorneyCountryID: getLookup('Country'),
            OtherDriverCountryID: getLookup('Country'),
            OtherAttorneyCountryID: getLookup('Country'),
            AccidentStateID: getLookup('State'),
            CompanyAttorneyStateID: getLookup('State'),
            OtherAttorneyStateID: getLookup('State'),
            OtherDriverStateID: getLookup('State'),
            OtherFatalities: returnSameKeyValueObject(0, 50),
            OtherInjuries: returnSameKeyValueObject(0, 50),
            AccidentInjuries: returnSameKeyValueObject(0, 50),
            AccidentFatalities: returnSameKeyValueObject(0, 50),
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            CoDriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                customizeList: (list) => {
                    return groupListBySCAC(list, 'Truck')
                }
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                customizeList: (list) => {
                    return groupListBySCAC(list, 'Trailer')
                }
            },
            CompanyAttorneyContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => {
                    return {
                        label: it.FirstName + ' ' + it.LastName,
                        value: it.ContactID
                    }
                }
            },
            InsuranceContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => {
                    return {
                        label: it.FirstName + ' ' + it.LastName,
                        value: it.ContactID
                    }
                }
            },
            OtherAttorneyContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => {
                    return {
                        label: it.FirstName + ' ' + it.LastName,
                        value: it.ContactID
                    }
                }
            }
        }

        const fieldsHtmlAccidentInfo = fieldsToHtml(Object.values(Object.assign({}, this.props.fields_info)).slice(0, 18), translate, this.props.handleInputChange, selects)
        const fieldsHtmlAccidentDetails = fieldsToHtml(Object.values(Object.assign({}, this.props.fields_info)).slice(18, 43), translate, this.props.handleInputChange, selects)

        return (
            <React.Fragment>
                {!!resource.isLoading && (
                    <div className="h-48 flex items-center justify-center">
                        <LoaderSmall addClass={''}/>
                    </div>
                )}

                {
                    !resource.isLoading && this.props.match.params.id &&
                    <div className={'flex items-center justify-between mb-4'}>

                        <div className="flex btn btn-primary ml-auto mr-2"
                             onClick={this.props.addAnotherVehicleHandler}>
                            Add Another Vehicle
                        </div>

                    </div>
                }

                {!this.props.match.params.id && (
                    <div className="flex btn btn-primary ml-auto mr-2"
                         onClick={this.props.addAnotherVehicleHandler}>
                        Add Another Vehicle
                    </div>
                )}

                {!resource.isLoading && (
                    <div className="grid grid-cols-12 gap-4 pb-20">
                        <div className="col-span-full 2xl:col-span-4 lg:col-span-6">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.AccidentInfo')}
                                />
                                <div className="grid grid-cols-12 gap-5">
                                    {fieldsHtmlAccidentInfo}
                                </div>
                            </Card>
                        </div>

                        <div className="col-span-full 2xl:col-span-4 lg:col-span-6">
                            <Card addClass={'h-full'}>
                                <CardSubTitle
                                    text={translate('text.AccidentDetails')}
                                />
                                <div className="grid grid-cols-12 gap-5">
                                    {fieldsHtmlAccidentDetails}
                                </div>
                            </Card>
                        </div>

                        {this.props.another_vehicle_fields.map((item, index) => {
                            const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, item)), translate, (name, value) => this.props.handleAnotherVehicleInputChange(name, value, index), selects)
                            return (
                                <div key={index} className="col-span-full 2xl:col-span-4 lg:col-span-6">
                                    <Card addClass={'h-full'}>
                                        <div className="grid grid-cols-2">
                                            <CardSubTitle
                                                text={`${this.returnVehicleHeaderNaming(index)} Vehicle`}
                                            />
                                            <div className="col text-right">
                                                <div className="flex-end">
                                                    <button
                                                        onClick={() => this.props.removeAnotherVehicleHandler(index)}>
                                                        <MinusIcon
                                                            className={"w-5 h-5 rounded-full border border-black"}/>
                                                    </button>
                                                    <button onClick={this.props.addAnotherVehicleHandler}>
                                                        <PlusIcon
                                                            className={"w-5 h-5 ml-2 rounded-full border-green-600 border text-green-700"}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 gap-5">
                                            {itemToHtml}
                                        </div>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                )}

                {this.props.match.params.id && (
                    <PageFooter
                        translate={translate}
                        canSubmit={this.props.canSubmit}
                        actionCancel={this.props.cancel}
                        actionSubmit={checkPerm(Resources.Claims, UPDATE_PERM) && this.props.submit}
                    />
                )}

            </React.Fragment>
        )
    }
}
