import React from "react";
import {classNames} from "../../util/util-helpers";

export default function Nav({tabs, onTabChange, addClass, addTabButtonClass}) {
    return (
        <div className={classNames(addClass, "relative border-b-4 border-tm-gray-300")}>
            <div>
                <nav
                    className="-mb-px flex"
                    aria-label="Tabs"
                >
                    {tabs.map((tab) => {
                        return (
                            <button
                                key={tab.name}
                                onClick={() => onTabChange(tab.resource ?? tab.key)}
                                className={classNames(
                                    tab.current
                                        ? 'border-primary text-primary'
                                        : 'border-transparent text-tm-gray-700 hover:text-tm-gray-900',
                                    'tracking-wide whitespace-nowrap py-2 px-4 border-b-2 font-semibold text-xs uppercase focus-visible:bg-tm-gray-200 rounded-tr-lg rounded-tl-lg',
                                    addTabButtonClass
                                )}
                            >
                                {tab.name}
                            </button>
                        )
                    })}
                </nav>
            </div>
        </div>
    )
}
