import {classNames} from "../../../util/util-helpers";
import React, {useEffect} from "react";

export default function FieldTimeFocusElement({
                                                  innerRef,
                                                  dropdownRef,
                                                  hoursInputRef,
                                                  inputsContainerRef,
                                                  minutesInputRef,
                                                  periodInputRef,
                                                  hideDropdown,
                                                  minutesValue,
                                                  hoursValue,
                                                  periodValue,
                                                  onArrowUpDown,
                                                  isWholeFieldFocused
                                              }) {

    function handleKeyDown(e) {
        if (e.key === 'Tab' && !e.shiftKey) {
            e.preventDefault();
            hideDropdown();
        }

        if (e.key === 'Tab' && e.shiftKey) {
            hideDropdown();
        }

        if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
            e.preventDefault();
            onArrowUpDown();
        }
    }

    useEffect(() => {
        const outsideClick = (event) => {
            if (
                innerRef.current !== event.target
                && hoursInputRef.current !== event.target
                && inputsContainerRef.current !== event.target
                && minutesInputRef.current !== event.target
                && periodInputRef.current !== event.target
                && dropdownRef.current !== event.target?.parentNode
                && dropdownRef.current !== event.target?.parentNode?.parentNode?.parentNode
            ) {
                hideDropdown();
            }
        }

        document.addEventListener("click", outsideClick, false);

        return () => document.removeEventListener("click", outsideClick, false);
    }, [minutesValue, hoursValue, periodValue]);

    return <button
        ref={innerRef}
        onKeyDown={handleKeyDown}
        onFocus={() => {
            if (!isWholeFieldFocused) {
                hoursInputRef.current.focus();
            }
        }}
        className={classNames(
            "absolute opacity-0 inset-0 -z-10"
        )}
    />
}