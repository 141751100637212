import React, { useEffect, useRef, useState } from "react"
import { Field, FieldsManager } from "../../../../data/services/fields";
import { getDialogResourceDetails, updateDialogResource } from "../../../../data/actions/dialogResource";
import { useDispatch, useSelector } from "react-redux";
import Resources from "../../../../data/services/resources";
import LocalStorage from "../../../../util/localStorage";
import LoaderLarge from "../../loader/loader-large";
import { classNames } from "../../../util/util-helpers";
import { resourceIsUpdated } from "../../../util/util-helpers";
import ModalSaveResource from "../../modal/modal-save-resource";
import InfoBar from "../../info-paragraph/info-bar";
import Nav from "../../nav";
import FieldsToHtml from "../../fields/fields-to-html";

export default function TableProfilesDialog({translate, storeKey, currentOptions, setOptions, setOpen, isOpen}) {
    const bufferOption = useRef();
    const isUpdated = useRef(false);
    const dispatch = useDispatch();
    const dialogResource = useSelector((state) => state.dialogResource);
    const isLoading = dialogResource.isLoading;
    const data = dialogResource?.detailsData?.list?.ProfileMap?.[storeKey] ?? {};

    const [tableProfileOptions, setTableProfileOptions] = useState(getTableOptions(storeKey));
    const [fields, setFields] = useState(getFields(tableProfileOptions));
    const [tabs, setTabs] = useState(getTabs());
    const [isSaveProfileDialogOpen, setIsSaveProfileDialogOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(-1);
    const currentTab = tabs.find(tab => tab.current);

    function fetchData() {
        dispatch(getDialogResourceDetails({
            user: LocalStorage.get('user'),
            resource: Resources.UserTableProfiles,
        }));
    }

    function getFields(tableProfileOptions) {
        return {
            TableProfiles: new Field('TableProfiles', '', [], false, 'radio', {
                options: tableProfileOptions,
                addContainerClass: 'col-span-full'
            }),
        }
    }

    function getTabs() {
        return [
            {
                name: 'Save',
                resource: "save-profile",
                current: true,
                visible: true
            },
            {
                name: 'Select',
                resource: "select-profile",
                current: false,
                visible: true
            }
        ]
    }

    function getTableOptions() {
        let options = [];
        for (let i = 0; i < 3; i++) {
            options.push({name: data?.[i]?.name ?? "Free slot " + (i + 1), value: i + 1})
        }

        return options;
    }

    function handleInputChange(name, value) {
        let fieldsClone = Object.assign({}, fields);
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);
        setFields(fieldsClone);
    }

    function handleTabChange(resource) {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    function handleSaveClick(profileName) {
        const tableProfilesData = data;

        tableProfilesData[fields.TableProfiles.value - 1] = {
            name: profileName,
            value: fields.TableProfiles.value,
            data: currentOptions
        };

        const tablesData = {
            [storeKey]: tableProfilesData
        };

        isUpdated.current = true;
        dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: tablesData,
            resource: Resources.UserTableProfiles,
            errorMessage: true, successMessage: `Table profile saved`
        }));
    }

    function handleOptionSelectClick(optionsClicked) {
        const option = data.find(options => options.value === optionsClicked.value);

        if (option?.data) {
            bufferOption.current = currentOptions;
            setOptions(option.data);
            setSelectedOption(optionsClicked.value - 1);
        }
    }

    function handleApplyOptionsClick() {
        bufferOption.current = null;
        setOpen(false);
    }

    function handleResetSettings() {
        setOptions(bufferOption.current);
        bufferOption.current = null;
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (dialogResource?.detailsData?.list?.ProfileMap?.[storeKey]) {
            const tableProfileOptions = getTableOptions();
            setTableProfileOptions(getTableOptions(tableProfileOptions));
            setFields(getFields(tableProfileOptions));

            if (dialogResource.detailsData.list.ProfileMap[storeKey].find(it => it.data)) {
                setTabs(tabs.map(it => {
                    it.current = it.resource === "select-profile"; 
                    return it;
                }));
            }
        }

        if (resourceIsUpdated(dialogResource) && isUpdated.current) {
            isUpdated.current = false;
            fetchData();
        }
    }, [dialogResource]);

    useEffect(() => {
        if (!isOpen && !!bufferOption.current) {
            setOptions(bufferOption.current);
        }
    }, [isOpen])

    return (
        <div className="p-5 space-y-8 relative text-tm-gray-900">
            {isLoading && (
                <LoaderLarge stripesBg={true} />
            )}

            <Nav
                addTabButtonClass={"w-full"}
                tabs={tabs}
                onTabChange={handleTabChange}
                translate={translate}
            />

            {currentTab.resource === 'save-profile' && (
                <React.Fragment>
                    <FieldsToHtml
                        fieldsState={fields}    
                        onInputChange={handleInputChange}
                        translate={translate}
                    />

                    <button
                        disabled={fields.TableProfiles.value === ''}
                        className="btn btn-primary"
                        onClick={() => setIsSaveProfileDialogOpen(true)}
                    >
                        Save current settings
                    </button>
                </React.Fragment>
            )}

            {currentTab.resource === 'select-profile' && (
                <React.Fragment>
                    <div className="space-y-1">
                        <div className="label-stack">{translate("text.SelectTableProfiles")}</div>

                        {tableProfileOptions.filter(it => !it.isEmpty).map((it, i) => {
                            return <button 
                                key={i}
                                disabled={!data?.[i]?.data}
                                className={
                                    classNames(
                                        "btn w-full disabled:bg-tm-gray-50",
                                        selectedOption === i ? "btn-outline" : "btn-outline-secondary"
                                    )
                                }
                                onClick={() => handleOptionSelectClick(it)}
                            >
                                {it.name}
                            </button>
                        })}
                    </div>

                    <div className="space-y-4">
                        <button
                            disabled={!bufferOption.current}
                            className="btn btn-primary w-full justify-center"
                            onClick={handleApplyOptionsClick}
                        >
                            Apply
                        </button>

                        <button
                            disabled={!bufferOption.current}
                            className="btn btn-outline-secondary w-full justify-center"
                            onClick={handleResetSettings}
                        >
                            Reset
                        </button>
                    </div>
                </React.Fragment>
            )}

            <ModalSaveResource
                title={"Save profile"}
                widthClass="max-w-xl"
                show={isSaveProfileDialogOpen}
                gridColsClass="grid-cols-12"

                onClose={() => setIsSaveProfileDialogOpen(false)}
                fields={{
                    ProfileName: new Field('ProfileName', "", ['empty'], false, 'text', {addContainerClass: "col-span-full", lableRaw: "Profile name"}),
                }}
                onSubmit={(params) => {
                    if (params) {
                        handleSaveClick(params.ProfileName);
                        setIsSaveProfileDialogOpen(false); 
                    }
                }}
                htmlAfter={
                    <div className="m-1">
                        {!!data?.[fields.TableProfiles.value - 1]?.data && (
                            <InfoBar type="danger" message={"This will overwrite previous profile!"}/>
                        )}
                    </div>
                }
                translate={translate}
            />
        </div>
    )
}
