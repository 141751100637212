import { CheckCircleIcon } from "@heroicons/react/20/solid";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import React from "react";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import Tippy from "@tippyjs/react";
import {toFrontDate, toFrontDateTime, toFrontDateTimeFromUTC} from "../../../util/util-dates";
import {getLookup} from "../../../util/util-helpers";
import {formatMoney, numberWithCommas} from "../../../util/util-formaters";
import {genericMoneyFormatter} from "../../../util/util-vanilla";

export const fieldToCell = (fields, name, item) => {
    let field = fields[name];
    let value = item[name];

    if (!!fields[name]?.metadata?.render) {
        return fields[name].metadata.render(item);
    }

    switch (field?.type) {
        case 'hidden':
            return null;
        case 'date':
            return <div className="text-center">{toFrontDate(value)}</div>;
        case 'datetime':
            return <div className="text-center">{toFrontDateTime(value)}</div>;
        case 'datetimezone':
            return <div className="text-center">{toFrontDateTimeFromUTC(value)}</div>;
        case 'checkbox':
        case 'switch':
            // yes, no, empty, customize labels and icons (make default imports)
            return value ?
                <div className="text-center">
                    <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
                </div> :
                <div className="text-center">
                    <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
                </div>
        case 'select':
            if (fields[field.name]?.props?.values) {
                return fields[field.name].props.values[value]
            } else if (!!item[field.name.replace("ID", "")]) {
                return item[field.name.replace("ID", "")];
            } else {
                return getLookup(field.name.replace("ID", ""))?.[value]
            }
        case 'select-search':
            return item[name.replace("ID", "")];
        case 'float':
        case 'float_up_to_12_not_require':
        case 'float_not_require':
        case 'float_up_to_100':
            return <div className="text-right">{numberWithCommas(value)}</div>
        case 'integer':
        case 'integer_or_empty':
        case 'integer_not_require':
        case 'integer_up_to_100':
        case 'integer_up_to_256':
            return <div className="text-right">{formatMoney(value, 0, ".", ",")}</div>
        case 'money':
            return <div className="text-right">{genericMoneyFormatter(value, 0, ".", ",")}</div>
        case 'textarea':
            if (!!fields[name]?.metadata?.limitWidth) {
                return <div className="max-w-sm"><p className="text-ellipsis overflow-hidden">{value}</p></div>
            } else {
                return value;
            }
        default:
            return value;
    }
}

export const getSkeletonData = (fields, limit) => {
    const columns = Object.keys(fields);
    const rows = [...Array(Number(limit)).keys()];

    return rows.map(() => {
            let min = 0;
            let max = columns.length - 1;

            return columns.reduce((memo, it) => {
                const randomColumnHeader = columns[Math.floor(Math.random() * (max - min + 1) + min)];
                memo[it] = randomColumnHeader + randomColumnHeader;
                return memo
            }, {});
        }
    );
}

export const getLabel = (fieldName, columnsData, translate, customLabel, customRawLabel = false) => {
    if (customRawLabel) {
        return customRawLabel;
    }

    if (customLabel) {
        return translate("field." + customLabel)
    }

    return columnsData[fieldName]?.label ? columnsData[fieldName].label : translate("field." + fieldName)
}

export const ActionsWidth = React.forwardRef(({translate, actions, paddingClass}, ref) => {
    return (
        <div
            ref={ref}
            className={"invisible flex absolute whitespace-nowrap space-x-2 right-0 -top-10" + " " + paddingClass}
        >
            {/* Invisible actions, for max width calculation only */}
            {!!actions && actions.map((Button, btnIndex) => {
                return (
                    <div
                        key={btnIndex}
                        onClick={null}
                        className={!!Button.class ? Button.class : "btn-table-action"}
                    >
                        {!!Button.icon && (
                            <PencilIcon // placeholder
                                className={!!Button.iconClass ? Button.iconClass : "w-5 h-5"}
                            />
                        )}

                        {!!Button.customContent && Button.customContent()}

                        {typeof Button.label === "function" ? () => Button.label() : Button.label}
                    </div>
                )
            })}
        </div>
    )
});

export const TableActions = ({actions, item, rowIndex, isLoading}) => {
    return (
        <React.Fragment>
            {!!actions && actions
                .filter(Button => Button.visible === undefined || Button.visible(item, rowIndex))
                .sort((a, b) => a.order - b.order)
                .map((Button, btnIndex) => {
                    let buttonIcon = null;
                    let buttonIconClass = "w-5 h-5"
                    if (Button.iconClass) {
                        buttonIconClass = typeof Button.iconClass === 'function'
                            ? Button.iconClass(item, rowIndex)
                            : Button.iconClass
                    }

                    if (Button.icon) {
                        if (typeof Button.icon !== 'function') {
                            buttonIcon = <Button.icon
                                className={buttonIconClass}
                            />
                        }

                        if (typeof Button.icon === 'function') {
                            let ButtonIcon = Button.icon(item, rowIndex);

                            buttonIcon = <ButtonIcon
                                className={buttonIconClass}
                            />
                        }
                    }
                    return (
                        <Tippy
                            key={Button.key ?? btnIndex}
                            content={typeof Button.title === "function" ? Button.title(item, rowIndex) : Button.title}
                            disabled={!Button.title}
                        >
                            <button
                                disabled={isLoading}

                                onClick={() => !!Button.action && Button.action(item, rowIndex)}
                                className={Button.class ? Button.class : "btn-table-action"}
                            >


                                {buttonIcon}

                                {!!Button.customContent && Button.customContent(item)}

                                {typeof Button.label === "function" ? () => Button.label(item, rowIndex) : Button.label}
                            </button>
                        </Tippy>
                    )
                })}
        </React.Fragment>
    )
}
