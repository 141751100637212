import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import LoadStepsPopup from "../load-steps-popup";

export default function LoadStepsReadyStatus({
                                                 stops,
                                                 fields,
                                                 element,
                                                 isCarrier,
                                                 setElement,
                                                 fieldsRefs,
                                                 loadStatus,
                                                 submitButtonRef,
                                                 coveredStatusRef,
                                                 setAreStepsActive,
                                                 suggestedStopField,
                                                 suggestNextStopField,
                                                 setSuggestedStopField,
                                                 findNextEmptyStopField,
                                                 currentlyFocusedElement,
                                                 areAllRequiredStopsFieldFilled
                                             }) {
    const totalSteps = 4;

    const initialLoadRef = useRef(true);
    const [currentField, setCurrentField] = useState("");


    function handleStepCloseClick() {
        setElement(undefined); // Erase popup element
        setAreStepsActive(false);
    }

    function suggestNextLoadStatus() {
        if (coveredStatusRef.current) {

            setElement(createPortal(
                <LoadStepsPopup
                    title="Update load status"
                    currentElement={coveredStatusRef.current}
                    currentlyFocusedElement={currentlyFocusedElement}
                    addClass="w-[500px]"
                    totalSteps={4}
                    currentStep={3}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                You can now advance to Covered status.
                            </p>

                            <p>
                                Press the Covered status or Next button.
                            </p>
                        </>
                    }
                    onNextClick={() => {
                        coveredStatusRef.current.click();
                    }}
                />,
                coveredStatusRef.current
            ));
        }
    }

    function saveReadyStep() {
        if (submitButtonRef.current.parentNode) {

            setElement(createPortal(
                <LoadStepsPopup
                    position="top-left"
                    addClass="mt-8"
                    title="Save stops"
                    stepText={
                        <>
                            <p>
                                Now you can save selected stops.
                            </p>

                            <p>
                                After that we can set Load to READY status.
                            </p>
                        </>
                    }
                    totalSteps={4}
                    currentStep={4}
                    onCloseClick={handleStepCloseClick}
                    onNextClick={() => {
                        submitButtonRef.current.click();
                    }}
                />,
                submitButtonRef.current.parentNode
            ));
        }
    }

    function suggestSelectCarrier() {
        if (!fields.DriverID?.value?.value) {
            setCurrentField("DriverID");
        } else if (!fields.TruckID?.value?.value) {
            setCurrentField("TruckID");
        } else if (loadStatus !== 4) {
            setCurrentField("");
            suggestNextLoadStatus();
        } else {
            saveReadyStep();
        }
    }

    function suggestFillBrokerageFields() {
        if (!fields.CarrierID?.value?.value) {
            setCurrentField("CarrierID");
        } else if (loadStatus !== 4) {
            setCurrentField("");
            suggestNextLoadStatus();
        } else {
            saveReadyStep();
        }
    }

    function getSuggestedStep() {
        const areStopFieldsFilled = !findNextEmptyStopField();

        if (areStopFieldsFilled) {
            setSuggestedStopField(null)
        }

        if (!areStopFieldsFilled) {
            setSuggestedStopField(findNextEmptyStopField())
        } else if (isCarrier) {
            suggestSelectCarrier();
        } else {
            suggestFillBrokerageFields();
        }
    }

    useEffect(() => {
        getSuggestedStep();
    }, []);

    useEffect(() => {
        if (!initialLoadRef) {
            handleStepCloseClick();
        }

        initialLoadRef.current = false;
    }, [isCarrier]);

    useEffect(() => {
        if (loadStatus === 4 && isCarrier && fields.DriverID?.value?.value && fields.TruckID?.value?.value) {
            saveReadyStep();
        }

        if (loadStatus === 4 && !isCarrier && fields.CarrierID?.value?.value) {
            saveReadyStep();
        }
    }, [loadStatus]);

    // Logic for adding new stops
    useEffect(() => {
        if (suggestedStopField && !areAllRequiredStopsFieldFilled) {
            suggestNextStopField(getSuggestedStep)
        }
    }, [suggestedStopField, stops]);

    useEffect(() => {
        if (currentField) {
            if (currentField === 'DriverID') {
                const inputRef = fieldsRefs.DriverID?.current?.select?.select?.inputRef;
                if (inputRef) {
                    setElement(createPortal(
                        <LoadStepsPopup
                            title="Select driver for the load"
                            currentElement={inputRef}
                            currentlyFocusedElement={currentlyFocusedElement}
                            position="top"
                            totalSteps={totalSteps}
                            currentStep={1}
                            onCloseClick={handleStepCloseClick}
                            stepText={
                                <>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aperiam asperiores, at consequatur culpa deserunt ea et, explicabo fugit illum minus necessitatibus nulla possimus quidem rem repellendus, veritatis voluptates voluptatum.
                                    </p>
                                </>
                            }
                            isNextDisabled={!fields.DriverID?.value?.value}
                            onNextClick={() => {
                                getSuggestedStep();
                            }}
                        />,
                        inputRef.parentNode.parentNode.parentNode.parentNode.parentNode
                    ));
                }
            }

            if (currentField === 'TruckID') {
                const inputRef = fieldsRefs.TruckID?.current?.select?.select?.inputRef;
                if (inputRef) {

                    setElement(createPortal(
                        <LoadStepsPopup
                            title="Select truck for the load"
                            position="top"
                            currentElement={inputRef}
                            currentlyFocusedElement={currentlyFocusedElement}
                            addClass="w-[500px]"
                            totalSteps={totalSteps}
                            currentStep={2}
                            onCloseClick={handleStepCloseClick}
                            stepText={
                                <>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aut cum debitis illum nam nihil totam veniam vitae! Aperiam dolores enim fugiat nisi quae quas similique! Deserunt dolores illo illum.</p>
                                </>
                            }

                            isNextDisabled={!fields.TruckID?.value?.value}
                            onNextClick={() => {
                                getSuggestedStep();
                            }}
                        />,
                        inputRef.parentNode.parentNode.parentNode.parentNode.parentNode
                    ));
                }
            }

            if (currentField === 'CarrierID') {
                const inputRef = fieldsRefs.CarrierID?.current?.select?.select?.inputRef;
                if (inputRef) {

                    setElement(createPortal(
                        <LoadStepsPopup
                            title="Select Carrier for the load"
                            currentElement={inputRef}
                            currentlyFocusedElement={currentlyFocusedElement}
                            position="top"
                            addClass="w-[500px]"
                            totalSteps={totalSteps}
                            currentStep={2}
                            onCloseClick={handleStepCloseClick}
                            stepText={
                                <>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aut cum debitis illum nam nihil totam veniam vitae! Aperiam dolores enim fugiat nisi quae quas similique! Deserunt dolores illo illum.</p>
                                </>
                            }

                            isNextDisabled={!fields.CarrierID?.value?.value}
                            onNextClick={() => {
                                getSuggestedStep();
                            }}
                        />,
                        inputRef.parentNode.parentNode.parentNode.parentNode.parentNode
                    ));
                }
            }
        }
    }, [currentField, fields])

    return element ? element : null;
}
