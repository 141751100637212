import React, {useEffect, useMemo, useRef, useState} from "react";
import {createPortal} from "react-dom";
import LoadStepsPopup from "../load-steps-popup";

export default function sLoadStepsInTransitStatus({
                                                      loadStatus,
                                                      onStopClick,
                                                      stopsCombined,
                                                      submitButtonRef,
                                                      setAreStepsActive,
                                                      deliveredStatusRef,
                                                      completeStopFormRef,
                                                      completeStopButtonRef,
                                                      currentlyFocusedElement,
                                                      isCompleteStopModalOpen,
                                                      isStopCompletedCheckboxRef,
                                                      loadStepsCompleteStopState,
                                                      completeStopSubmitButtonRef,

                                                      stops,
                                                      element,
                                                      setElement,
                                                      suggestedStopField,
                                                      suggestNextStopField,
                                                      setSuggestedStopField,
                                                      findNextEmptyStopField,
                                                      areAllRequiredStopsFieldFilled
                                                  }) {
    const totalSteps = 3;
    const [currentField, setCurrentField] = useState("");
    const uncompletedStopIndexRef = useRef(-2);


    const areStopsCompleted = useMemo(() => {
        return stopsCombined.findIndex(stop => !stop.IsStopCompleted) === -1;
    }, [stopsCombined]);

    function handleStepCloseClick() {
        setElement(undefined); // Erase popup element
        setAreStepsActive(false);
    }

    function suggestNextLoadStatus() {
        if (deliveredStatusRef.current) {
            setElement(createPortal(
                <LoadStepsPopup
                    title="Update load status"
                    currentElement={deliveredStatusRef.current}
                    currentlyFocusedElement={currentlyFocusedElement}
                    position="bottom-left"
                    addClass="w-[500px]"
                    totalSteps={totalSteps}
                    currentStep={1}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                You can now advance to In Transit status.
                            </p>

                            <p>
                                Press the In Transit status or Next button.
                            </p>
                        </>
                    }
                    onNextClick={() => {
                        deliveredStatusRef.current.click();
                    }}
                />,
                deliveredStatusRef.current
            ));
        }
    }

    function saveDeliveredStep() {
        if (submitButtonRef.current.parentNode) {

            setElement(createPortal(
                <LoadStepsPopup
                    position="top-left"
                    addClass="mt-5 z-50"
                    title="Save changes"
                    stepText={
                        <>
                            <p>
                                Now you can save dispatch status change.
                            </p>
                        </>
                    }
                    totalSteps={totalSteps}
                    currentStep={3}
                    onCloseClick={handleStepCloseClick}
                    onNextClick={() => {
                        submitButtonRef.current.click();
                    }}
                />,
                submitButtonRef.current.parentNode
            ));
        }
    }

    function suggestStopComplete() {
        const notCompletedStopIndex = stopsCombined.findIndex(stop => !stop.IsStopCompleted);

        onStopClick(notCompletedStopIndex);

        setTimeout(() => {
            if (completeStopButtonRef?.[notCompletedStopIndex] && !isCompleteStopModalOpen) {
                const inputRef = completeStopButtonRef?.[notCompletedStopIndex].current;
                setElement(createPortal(
                    <LoadStepsPopup
                        position="bottom"
                        addClass="mt-5 z-50"
                        title="Complete selected stop"
                        currentElement={inputRef.parentNode}
                        currentlyFocusedElement={currentlyFocusedElement}
                        stepText={
                            <>
                                <p>
                                    {`Pressing the 'Complete this stop' button will open the complete stop form.`}
                                </p>
                            </>
                        }
                        totalSteps={totalSteps}
                        currentStep={2}
                        onCloseClick={handleStepCloseClick}
                        onNextClick={() => {
                            inputRef.click();
                        }}
                    />,
                    inputRef.parentNode
                ));
            }

        }, 300)
    }

    function suggestCompleteStopModalFields() {
        if (currentField === '') {

            if (completeStopFormRef.current) {
                setElement(createPortal(
                    <LoadStepsPopup
                        containerClass="w-full absolute"
                        position="top"
                        addClass="mt-24"
                        title="Fill in times"
                        currentElement={completeStopFormRef.current}
                        currentlyFocusedElement={currentlyFocusedElement}
                        stepText={
                            <>
                                <p>
                                    Fill in Time in and Time out. You can autofill fields by clicking on the 'Auto fill
                                    times' button.
                                </p>
                            </>
                        }
                        totalSteps={totalSteps}
                        currentStep={1}
                        onCloseClick={handleStepCloseClick}
                        isNextDisabled={!loadStepsCompleteStopState.ActualArrivalDate.value || !loadStepsCompleteStopState.ActualDepartureDate.value}
                        onNextClick={() => {
                            setCurrentField("isStopCompleted");
                        }}
                    />,
                    completeStopFormRef.current
                ));
            }
        } else if (currentField === 'isStopCompleted') {
            if (isStopCompletedCheckboxRef.current) {
                setElement(createPortal(
                    <LoadStepsPopup
                        containerClass={" "}
                        position="bottom-left"
                        addClass="mt-12 -right-20"
                        title="Is stop completed?"
                        currentElement={isStopCompletedCheckboxRef.current}
                        currentlyFocusedElement={currentlyFocusedElement}
                        stepText={
                            <>
                                <p>
                                    {`Check 'Is stop completed' field to complete stop.`}
                                </p>
                            </>
                        }
                        totalSteps={totalSteps}
                        currentStep={2}
                        onCloseClick={handleStepCloseClick}
                        isNextDisabled={!loadStepsCompleteStopState?.IsStopCompleted?.value}
                        onNextClick={() => {
                            setCurrentField("saveCompleteStop");
                        }}
                    />,
                    isStopCompletedCheckboxRef.current.parentNode.parentNode
                ));
            }
        } else if (currentField === 'saveCompleteStop') {
            if (completeStopSubmitButtonRef.current) {
                setElement(createPortal(
                    <LoadStepsPopup
                        containerClass={" "}
                        position="bottom-left"
                        title="Save changes"
                        currentElement={completeStopSubmitButtonRef.current}
                        currentlyFocusedElement={currentlyFocusedElement}
                        stepText={
                            <>
                                <p>
                                    Save changes to mark stop as completed.
                                </p>
                            </>
                        }
                        totalSteps={totalSteps}
                        currentStep={3}
                        onCloseClick={handleStepCloseClick}
                        onNextClick={() => {
                            completeStopSubmitButtonRef.current.click();
                        }}
                    />,
                    completeStopSubmitButtonRef.current.parentNode
                ));
            }
        }
    }

    function getSuggestedStep() {
        const areStopFieldsFilled = !findNextEmptyStopField();

        if (areStopFieldsFilled) {
            setSuggestedStopField(null)
        }

        if (!areStopFieldsFilled) {
            setSuggestedStopField(findNextEmptyStopField())
        } else if (!areStopsCompleted && !isCompleteStopModalOpen) {
            suggestStopComplete();
        } else if (isCompleteStopModalOpen) {
            suggestCompleteStopModalFields();
        } else if (loadStatus !== 7) {
            suggestNextLoadStatus()
        } else {
            saveDeliveredStep();
        }
    }

    useEffect(() => {
        if (loadStatus === 7) {
            getSuggestedStep();
        }
    }, [loadStatus]);

    useEffect(() => {
        getSuggestedStep();
    }, []);

    useEffect(() => {
        if (isCompleteStopModalOpen) {
            setCurrentField("");
            getSuggestedStep();
        }
    }, [isCompleteStopModalOpen])

    useEffect(() => {
        getSuggestedStep();
    }, [currentField]);

    useEffect(() => {
        getSuggestedStep();
    }, [loadStepsCompleteStopState])

    useEffect(() => {
        const firstUncompletedStop = stopsCombined.findIndex(stop => !stop.IsStopCompleted);
        if (uncompletedStopIndexRef.current !== firstUncompletedStop) {
            uncompletedStopIndexRef.current = firstUncompletedStop;

            getSuggestedStep();
        }
    }, [stopsCombined]);

    // Logic for adding new stops
    useEffect(() => {
        if (suggestedStopField && !areAllRequiredStopsFieldFilled) {
            suggestNextStopField(getSuggestedStep)
        }
    }, [suggestedStopField, stops]);

    useEffect(() => {
        if (!!loadStepsCompleteStopState?.ActualArrivalDate?.value && !!loadStepsCompleteStopState?.ActualDepartureDate?.value) {
            setCurrentField("isStopCompleted");
        }
    }, []);

    return element ? element : null;
}
