import React, {useEffect, useState} from 'react';
import {longTableColumn, resourceIsCreated} from "../../util/util-helpers";
import {excludeFields} from "../../util/util-fields";
import LocalStorage from "../../util/localStorage";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import Tooltip from "../tooltip";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import Card from "../card";
import {useSelector} from "react-redux";
import TableCardFooter from "../resource-table/table-components/table-card-footer";
import {showGlobalModal} from "../../../data/actions/ui";
import {deleteResource} from "../../../data/actions/resource";
import {Field, FieldsManager} from "../../../data/services/fields";
import PersonalNoteForm from "../modal/personal-note-form";
import ResourceTable from '../resource-table'
import StackedList from "../display-data/stacked-list";
import Pagination from "../resource-table/table-components/pagination";
import ModalConfirm from "../modal/modal-confirm";
import {RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS} from "../../util/util-consts";
import Resources from '../../../data/services/resources'

const WidgetNotes = (props) => {
    const {notes, count, isLoading, translate, onFetchData, dispatch, isCompact} = props;
    const resource = useSelector((state) => state.resource);

    const [queryFields, setQueryFields] = useState(getQueryFields());
    const [confirmNoteTitle, setConfirmNoteTitle] = useState("");
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [sort, setSort] = useState("DESC")
    const [sortBy, setSortBy] = useState("CreateUpdateDate")
    const [deleteID, setDeleteID] = useState("");

    const handleUpdateSort = (sortByTmp) => {
        setSort((sortByTmp === sortBy) ? (sort === "ASC" ? "DESC" : "ASC") : "ASC")
        setSortBy(sortByTmp)
    };

    const handleNewNoteClick = () => {
        dispatch(showGlobalModal('PersonalNoteDialog'));
        props.onSetActiveResource(Resources.NotesPersonal)
    }

    const handleUpdateNoteClick = (note) => {
        dispatch(showGlobalModal('PersonalNoteDialog', {
            Title: note.Title,
            Description: note.Description,
            ID: note.PersonalNotesID
        }));
        props.onSetActiveResource(Resources.NotesPersonal)
    }

    const handleCreateTaskFromNoteClick = (note) => {
        dispatch(showGlobalModal('createTaskDialog', {TaskName: note.Title, Description: note.Description}));
    }

    const handleDeleteNoteClick = (note) => {
        setConfirmNoteTitle(note.Title);
        setDeleteID(note.PersonalNotesID);
        setConfirmModalOpen(true)
    }

    const handleDeleteNote = () => {
        props.onSetActiveResource(Resources.NotesPersonal)

        dispatch(deleteResource({
            user: LocalStorage.get("user"),
            query: {
                id: deleteID
            },
            errorMessage: true, successMessage: translate("text.note_deleted"),
            resource: Resources.NotesPersonal,
        }))

        setConfirmModalOpen(false)
    }

    const handleQueryChange = (name, value) => {
        setQueryFields(FieldsManager.updateField(Object.assign({}, queryFields), name, value));
    }

    const getFields = () => {
        return {
            Title: new Field("Title", '', [], false, 'custom', {

                render: (item) => (
                    <div>{longTableColumn(item.Title, 16)}</div>
                )
            }),
            Description: new Field("Description", '', [], false, 'custom', {
                render: (item) => (
                    <div>{longTableColumn(item.Description)}</div>
                )
            }),
            CreateUpdateDate: new Field("CreateUpdateDate", '', [], false, 'datetimezone'),
        }
    }

    function getQueryFields() {
        return {
            limit: new Field('limit', isCompact ? "5" : "10", [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }),
            offset: new Field('offset', "0", ['']),
        }
    }

    const getQuery = () => {
        if (isCompact) {
            return {
                limit: queryFields.limit.value,
                offset: queryFields.offset.value,
                sort: "DESC",
                sortBy: "CreateUpdateDate",
            }
        }

        return {
            limit: queryFields.limit.value,
            offset: queryFields.offset.value,
            sort: sort,
            sortBy: sortBy,
        }
    }

    useEffect(() => {
        onFetchData('NotesPersonal', getQuery());
    }, [sort, sortBy, queryFields])

    useEffect(() => {
        if (resourceIsCreated(resource) && resource.resource === Resources.NotesPersonal) {
            onFetchData('NotesPersonal', getQuery());
        }
    }, [resource]);

    return (
        <React.Fragment>
            <Card bodyClass="p-0">
                <div className="p-6">
                    <div className="flex items-center">
                        <h5 className="text-lg">{translate("text.personal_notes")}</h5>
                        <Tooltip
                            content={
                                <div>
                                    <div>Notes are only visible by you, <br/>write anything you want.</div>
                                    <div className="m-0">You can create a task from existing notes.</div>
                                </div>
                            }
                        >
                            <InformationCircleIcon className="w-5 h-5 ml-1 text-primary"/>
                        </Tooltip>

                        {!isCompact && (
                            <button
                                className="btn btn-outline py-0.5 ml-auto"
                                onClick={() => handleNewNoteClick()}
                            >
                                New note
                                <PlusIcon className="h-5 w-5 ml-2"/>
                            </button>
                        )}
                    </div>

                    {!count && !isLoading && (
                        <div className={"text-center mt-20 text-lg"}>
                            {translate('text.did_not_made_notes')}
                        </div>
                    )}
                </div>

                {isCompact && (
                    <PersonalNoteForm
                        isHome={true}
                        query={getQuery()}
                        isLoading={isLoading}
                        translate={translate}
                    />
                )}

                {!isCompact && (
                    <ResourceTable
                        data={notes}
                        fields={getFields()}
                        translate={props.translate}
                        options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}
                        tableID="widget-notes"
                        isLoading={isLoading}
                        limit={queryFields.limit.value}
                        onSortChange={handleUpdateSort}
                        sort={sort}
                        sortBy={sortBy}
                        actions={[
                            {
                                tooltipText: () => translate('text.update_note'),
                                action: handleUpdateNoteClick,
                                icon: () => PencilIcon,
                            },
                            {
                                tooltipText: () => translate('text.create_task_from_note'),
                                action: handleCreateTaskFromNoteClick,
                                icon: () => ClipboardDocumentIcon
                            },
                            {
                                tooltipText: () => translate('text.delete_note'),
                                action: handleDeleteNoteClick,
                                icon: () => TrashIcon
                            },
                        ]}
                    />
                )}

                {isCompact && (
                    <StackedList
                        data={notes}
                        isLoading={isLoading}
                        listKey="PersonalNotesID"
                        onTapRow={(row) => handleUpdateNoteClick(row)}
                        fields={excludeFields(getFields(), ['CreateUpdateDate'])}
                    />
                )}

                <TableCardFooter
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value) => {
                                handleQueryChange(name, value)
                            }
                        }
                        queryFields={queryFields}
                        hideRowsPerPage={isCompact}
                        translate={translate}
                    />
                </TableCardFooter>

                <ModalConfirm
                    title={"Confirm delete"}
                    show={!!confirmModalOpen}
                    text={`${translate("text.confirm_delete_note")} '${confirmNoteTitle}'?`}
                    onClose={() => setConfirmModalOpen(false)}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={handleDeleteNote}
                />
            </Card>
        </React.Fragment>
    )
}

export default WidgetNotes;
