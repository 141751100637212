import { classNames, getLookup, getProp } from "../../../util/util-helpers";
import PopOver from "../../popover";
import EllipsisVerticalIcon from "@heroicons/react/20/solid/EllipsisVerticalIcon";
import BriefcaseIcon from "@heroicons/react/20/solid/BriefcaseIcon";
import { concatFullPhoneNumber } from "../../../util/util-helpers";
import { getJWT } from "../../../util/util-auth";
import { cloneDeep } from "../../../util/util-vanilla";
import DataCard from "../../display-data/data-card";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import React, { useState } from "react";
import Env from "../../../../util/env";
import Resources from "../../../../data/services/resources";
import { Field } from "../../../../data/services/fields";
import EnvelopeIcon from "@heroicons/react/20/solid/EnvelopeIcon";
import PhoneIcon from "@heroicons/react/20/solid/PhoneIcon";
import CalendarIcon from "@heroicons/react/20/solid/CalendarIcon";
import PencilSquareIcon from "@heroicons/react/20/solid/PencilSquareIcon";
import Badge from "../../badge";
import { hideInfoOfficeDialog, hideModal } from "../../../../data/actions/ui";
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import PhoneArrowUpRightIcon from "@heroicons/react/24/outline/PhoneArrowUpRightIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import CopyToClipboardButton from "../../buttons/copy-to-clipboard-button";
import {useSelector} from "react-redux";
import {fillFieldsFromData, includeFields} from "../../../util/util-fields";
import {SECTIONS_VISIBILITY} from "../../../../util/util-constants";
export default function ContactCard({isLoading, userData, dispatch, translate}) {
    const ui = useSelector(state => state.ui);
    const phoneTypes = getLookup('PhoneType')

    const [visibleBelongings, setVisibleBelongings] = useState({});

    const getFields = (item = {}) => {
        const primaryPhoneData = getProp(userData, "Phones", [])[0];
        let primaryPhone = null;
        if (primaryPhoneData && primaryPhoneData?.PhoneNumber) {
            primaryPhone = (primaryPhoneData.AreaCode ? `${primaryPhoneData.AreaCode} ` : ``) + primaryPhoneData.PhoneNumber.replaceAll(".", "");
        }

        let fieldTemplates = {
            Email: new Field('Email', '', [], false, 'email', {icon: () => EnvelopeIcon}),
            PrivateEmail: new Field('PrivateEmail', '', [], false, "email", {icon: () => EnvelopeIcon}),
            PrimaryPhone: new Field('PrimaryPhone', primaryPhone, [], false, "phone", {
                icon: () => PhoneIcon,
                morePhones: morePhones
            }),
            DateOfBirth: new Field('DateOfBirth', '', [], false, "date", {icon: () => CalendarIcon}),
            Notes: new Field('Notes', '', [], false, "textarea", {
                icon: PencilSquareIcon,
                readOnlyFieldGroupClass: "col-span-2"
            }),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);

        return fieldTemplates;
    }

    const morePhones = () => {
        return phones?.length && phones.length > 1
            ? <PopOver
                className={"relative"}
                btnClass="btn-icon text-tm-gray-600 hover:text-tm-gray-900"
                widthClass={"max-w-xs"}
                positionClass="absolute translate-x-0 top-6 right-0"
                BtnIcon={EllipsisVerticalIcon}
                btnIconClass={classNames("h-4 w-4")}
            >
                <div className="bg-popup rounded-md">
                    {phones}
                </div>
            </PopOver>
            : null
    }

    const fields = getFields(userData);

    const contactGroups = getProp(userData, "Groups", []).map((group, i) => {
        return (
            <Badge key={i}>
                <div className="flex items-center justify-center">
                    {group.ContactGroupName}
                </div>
            </Badge>
        )
    });

    const contactRoles = getProp(userData, "Roles", []).map((role, i) => {
        return (
            <Badge key={i}>
                <div className="flex items-center justify-center">
                    {role.role_name}
                </div>
            </Badge>
        )
    });

    const phones = userData.Phones && userData.Phones.map((phone, index) => {
        if (!index) return;
        const fullPhoneNumber = concatFullPhoneNumber(phone);

        return (
            <div key={index} className={classNames(
                "p-4 flex flex-between w-full",
                index > 1 ? "border-t border-dashed border-tm-gray-300" : undefined
            )}>
                <div>
                    <p className="font-bold leading-5">{fullPhoneNumber}</p>
                    <p className="text-tm-gray-500 text-xs font-medium leading-4">{phoneTypes[phone.PhoneTypeID]}</p>
                </div>

                <div className="ml-auto flex">
                    <CopyToClipboardButton
                        clipboardText={fullPhoneNumber}
                        translate={translate}
                    />
                    <button
                        className="btn btn-icon p-1 text-tm-gray-600 hover:text-tm-gray-900"
                        title={translate("btn.call")}
                        onClick={(e) => {
                            window.open('tel:' + fullPhoneNumber?.replace(/\D/g, ''))
                        }}
                    >
                        <PhoneArrowUpRightIcon className={"w-5 h-5"}/>
                    </button>
                </div>
            </div>
        )
    })

    const getBelongingHandler = () => {
        let tmp = {}
        if (userData) {
            if ("DriverID" in userData) {
                tmp.DriverID = userData.DriverID
            }
            if ("EmployeeID" in userData) {
                tmp.EmployeeID = userData.EmployeeID
            }
            if ("AgentID" in userData) {
                tmp.AgentID = userData.AgentID
            }
            if ("Carriers" in userData) {
                tmp.Carriers = userData.Carriers
            }
            if ("Customers" in userData) {
                tmp.Customers = userData.Customers
            }
            if ("Vendors" in userData) {
                tmp.Vendors = userData.Vendors
            }
        }
        return tmp !== {} ? tmp : false
    }
    let data = getBelongingHandler()

    const handleLinkClick = (link) => {
        dispatch(hideInfoOfficeDialog())
        dispatch(hideModal())
        setTimeout(() => {
            window.open(`${link}?new-tab=true`, '_blank');
        }, 500);
    }

    const handleToggleBelongings = item => {
        setVisibleBelongings(prevBelonging => {
            return {
                ...prevBelonging,
                [item]: !prevBelonging[item]
            }
        })
    }

    const belongings = userData && Object.keys(data).map((it, i) => {
        if (Array.isArray(data[it])) {
            return (
                <div key={i} className="flex grow flex-wrap">
                    <button
                        onClick={() => handleToggleBelongings(`belongings${it}`)}
                        className="w-full flex px-6 py-3 hover:bg-tm-gray-50 font-bold leading-5">
                        {translate(`text.${it}`)}

                        <ChevronRightIcon
                            className={classNames(
                                "w-5 h-5 ml-auto",
                                visibleBelongings[`belongings${it}`] ? "rotate-90" : undefined
                            )}
                        />
                    </button>

                    {!!visibleBelongings[`belongings${it}`] && data[it].map((item, itemIndex) => {
                        if (item[`${it.slice(0, -1)}`]) {
                            if (
                                (it === "Customers" && (SECTIONS_VISIBILITY.hasCustomersSubSection === false || SECTIONS_VISIBILITY.hasCompaniesSection === false))
                                || (it === "Carriers" && (SECTIONS_VISIBILITY.hasCarriersSubSection === false || SECTIONS_VISIBILITY.hasCompaniesSection === false))
                                || (it === "Vendors" && (SECTIONS_VISIBILITY.hasVendorsSubSection === false || SECTIONS_VISIBILITY.hasCompaniesSection === false))

                            ) {
                                return <div className="w-full flex px-6 py-3 font-bold leading-5">
                                      <span>
                                        {!!data[it].length > 1 && (
                                            <span>{itemIndex + 1}. </span>
                                        )}

                                          {item[`${it.slice(0, -1)}`]}
                                    </span>
                                </div>
                            }

                            return (
                                <button
                                    key={itemIndex}
                                    onClick={() => handleLinkClick(`/${it.toLowerCase()}/info/${item.OrganizationID}/${item[`${it.slice(0, -1)}ID`]}`)}
                                    className={`py-2 pl-10 pr-6 flex justify-between w-full border-t border-dashed border-tm-gray-300 hover:bg-tm-gray-50 cursor-pointer`}
                                >
                                    <span>
                                        {!!data[it].length > 1 && (
                                            <span>{itemIndex + 1}. </span>
                                        )}

                                        {item[`${it.slice(0, -1)}`]}
                                    </span>

                                    <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                                </button>
                            )
                        }
                        return null;
                    })}
                </div>
            )
        } else {
            if (
                (it === "EmployeeID" && SECTIONS_VISIBILITY.hasEmployeesSection === false)
                ||  (it === "DriverID" && SECTIONS_VISIBILITY.hasEmployeesSection === false)
                ||  (it === "AgentID" && SECTIONS_VISIBILITY.hasEmployeesSection === false)
            ) {
                return <div className="w-full flex px-6 py-3 font-bold leading-5">
                    {translate(`text.${it}`)}
                </div>
            }

            return (
                <div key={i}>
                    <div
                        className='w-full flex px-6 py-3 hover:bg-tm-gray-50 font-bold leading-5 justify-between cursor-pointer'
                        onClick={() => handleLinkClick(`/${it.toLowerCase().replace("id", "")}s/info/${data[it]}`)}
                    >
                        {translate(`text.${it}`)}

                        <ArrowTopRightOnSquareIcon className="w-5 h-5"/>
                    </div>
                </div>
            )
        }
    })

    return (
        <div className={classNames(!!isLoading ? "invisible" : "", "")}>
            <div className="gap-y-8 h-dialog-body">
                <div className="col-span-5 md:col-span-6 border-r bg-tm-gray-50 border-tm-gray-200 p-6">
                    <div className="w-full max-w-md mx-auto text-tm-gray-700">

                        <div className="sm:flex items-center space-x-4 mb-6">
                            <div className="flex-shrink-0">
                                {getImage(userData)}
                            </div>

                            <div className="relative w-full">
                                <div className="text-2xl font-bold mr-6 text-tm-gray-900 flex">
                                    {getName(userData)}

                                    {(!!userData.PersonalTitle || !!userData.MiddleName || !!userData.Nickname) && (
                                        <PopOver
                                            className={"relative ml-auto"}
                                            btnClass="btn-icon"
                                            widthClass={"max-w-xs"}
                                            positionClass="absolute translate-x-0 right-0"
                                            BtnIcon={EllipsisVerticalIcon}
                                            btnIconClass={classNames("h-4 w-4 text-tm-gray-600")}
                                        >
                                            <div className="bg-popup rounded-md z-index-10">
                                                {getFullName(userData)}
                                            </div>
                                        </PopOver>
                                    )}
                                </div>

                                {userData.Title && (
                                    <div className="mt-1 flex items-center text-sm text-tm-gray-400">
                                        <BriefcaseIcon className="w-5 h-5 mr-2"/>
                                        <p className="text-tm-gray-500 font-medium">{userData.Title}</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="space-y-2">
                            {Object.values(includeFields(cloneDeep(fields), ['Email', 'PrivateEmail', 'PrimaryPhone', 'DateOfBirth'])).filter(it => it.type !== 'hidden' && (!!it?.value?.value || !!it.value)).map(field => {
                                if (!userData.PrimaryPhone && !!userData?.Phones?.[0]?.PhoneNumber) {
                                    userData.PrimaryPhone = userData?.Phones?.[0]?.PhoneNumber;
                                }
                                return (
                                    <DataCard
                                        key={field.name}
                                        displayField={field}
                                        fieldsData={userData}
                                        translate={translate}
                                    />
                                )
                            })}
                        </div>

                        {(!!userData.HighImportance || !!userData.IsSystemUser) && (
                            <div className="mt-6">
                                <p className="font-bold leading-5 mb-2">{translate("text.system_flags")}</p>

                                <div
                                    className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center gap-4">
                                    {!!userData.HighImportance && (
                                        <div
                                            className="flex items-center font-medium bg-tm-gray-100 py-1 pl-2 pr-3 rounded-lg">
                                            <ExclamationTriangleIcon className="w-5 h-5 mr-2 text-red-600"/>

                                            {translate("text.HighImportance")}
                                        </div>
                                    )}

                                    {!!userData.IsSystemUser && (
                                        <div
                                            className="flex items-center font-medium bg-tm-gray-100 py-1 pl-2 pr-3 rounded-lg">
                                            <Cog6ToothIcon className="w-5 h-5 mr-2 text-green-600"/>

                                            {translate("text.SystemUser")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {!!fields?.Notes?.value && (
                            <div className="mt-6">
                                <p className="font-bold leading-5 mb-2">Notes</p>
                                <div
                                    className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center">
                                    {fields?.Notes?.value}
                                </div>
                            </div>
                        )}

                        {!!belongings.length && (
                            <div className="mt-6">
                                <p className="font-bold leading-5 mb-2">Belongs to</p>
                                <div
                                    className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card overflow-hidden">
                                    <div className="flex flex-col divide-y divide-tm-gray-300">
                                        {belongings}
                                    </div>
                                </div>
                            </div>
                        )}


                        {!!contactRoles.length && (
                            <div className="mt-6">
                                <p className="font-bold leading-5 mb-2">{translate("field.Roles")}</p>

                                <div
                                    className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex flex-wrap items-center gap-4">
                                    {contactRoles}
                                </div>
                            </div>
                        )}

                        {!!contactGroups.length && (
                            <div className="mt-6">
                                <p className="font-bold leading-5 mb-2">{translate("field.Groups")}</p>

                                <div
                                    className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex flex-wrap items-center gap-4">
                                    {contactGroups}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const getImage = (userData) => {
    if (userData.ImagePath) {
        return (
            <img
                className="inline-block h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 bg-tm-gray-100"
                src={Env.getApiUrl("api/" + Resources.ContactImage, {
                    token: getJWT().access_token,
                    ContactID: userData.ContactID
                })}
                onLoad={() => null}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/avatar-light.png";
                }}
            />
        )
    } else {
        return (
            <img alt=""
                 className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                // src={`/images/avatar-${ui.currentTheme}.png`}
                 src={`/images/avatar-light.png`}
            />
        )
    }
}

const getName = (item) => {
    return [item.PersonalTitle, item.FirstName, item.LastName].filter(it => !!it).join(" ");
}

const getFullName = (item) => {
    return <div>
        {!!item.PersonalTitle && (
            <div className="px-4 py-2 border-t border-dashed border-tm-gray-300">
                <p className="font-bold leading-5">
                    {item.PersonalTitle}
                </p>
                <p className="text-tm-gray-500 text-xs font-medium leading-4">Title</p>
            </div>
        )}

        <div className="px-4 py-2 border-t border-dashed border-tm-gray-300">
            <p className="font-bold leading-5">
                {[item.FirstName, item.MiddleName, item.LastName].filter(it => !!it).join(" ")}
            </p>
            <p className="text-tm-gray-500 text-xs font-medium leading-4">Full name</p>
        </div>

        {!!item.Nickname && (
            <div className="px-4 py-2 border-t border-dashed border-tm-gray-300">
                <p className="font-bold leading-5">
                    {item.Nickname}
                </p>
                <p className="text-tm-gray-500 text-xs font-medium leading-4">Nick name</p>
            </div>
        )}
    </div>
}
