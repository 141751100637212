const dialogInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case 'RESET_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                create: null,
                isLoading: false,
                error: false,
                errorMessage: null,
                errorStatus: null,
                resource: null
            });
        case 'GET_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                resource: action.data.resource
            });
        case 'DONE_GET_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'DELETE_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: true,
                resource: action.data.resource,
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CLEAR_DIALOG_INFO_RESOURCE':
            return Object.assign({}, state, {
                data: null,
            });
        default:
            return state;
    }
};

export default dialogInfoReducer;