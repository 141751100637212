import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {resourceIsCreated} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_PHONES_FIELDS, READ_PERM} from '../../../util/util-constants'
import {checkPerm, focusOnAddedPhone, getProp} from '../../../common/util/util-helpers'
import CreateSettlementTemplateTab from "./create-settlement-template-tab";
import CreateSettlementTemplateDeductionTab from "./create-settlement-template-deduction-tab";
import {createSecondResource} from "../../../data/actions/secondResource";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import InfoParagraph from "../../../common/components/info-paragraph";

class DriverSettlementsTemplateCreateEdit extends Component {

    constructor(props) {
        super(props)
        this.tabs = [
            {
                name: 'DriverSettlements',
                resource: Resources.DriverSettlements,
                current: true,
                visible: checkPerm(Resources.DriverSettlements, READ_PERM)
            },
        ]

        this.state = {
            fields_settlements: this.getSettlementFields(),
            isRedirectDialogVisible: false,
            fields_restrictions: [],
            tabs: this.tabs,
            selectedTab: this.tabs[0].resource,
            payAdjustments: [],
            submit:false

        }

    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.handleCheckIfRedirected()
        }

        this.getPropName()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }

        if (resourceIsCreated(this.props.resource,prevProps.resource)) {
            this.setState({
                payAdjustments: []
            })
        }
    }

    /** Data Events
     ================================================================= */

    /** UI Events
     ================================================================= */

    handleToggleRedirectDialog = () => {
        // and reset state
        this.fileImageObject = null // reset image

        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.DriverID,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,
            // Reset state
            fields_info: this.getFieldsInfo(),
            phones_info: this.getPhonesInfo(),
            src: null,
            croppedImageUrl: null,
            files_info: [],
        })
    }

    handleSettlementInputCreateChange = (name, value) => {
        let tmp = {
            value: -1,
            label: value
        }
        let newValue = this.state.fields_settlements[name].value ? [...this.state.fields_settlements[name].value, tmp] : [tmp]
        this.setState({
            fields_settlements: FieldsManager.updateField(this.state.fields_settlements, name, newValue),
            isDirty: true
        })
    }

    handleAddPayAdjustments = (params) => {
        let payAdjustments = this.state.payAdjustments;
        payAdjustments.push(params);
        this.setState({
            payAdjustments,
            isDirty: true
        });
    }

    handleDeletePayAdjustments = (params) => {
        let payAdjustments = this.state.payAdjustments;
        payAdjustments.splice(payAdjustments.indexOf(params), 1)
        this.setState({
            payAdjustments,
            isDirty: true
        })
    }

    handleEditPayAdjustments = (id,params) => {
        let payAdjustments = this.state.payAdjustments;
        payAdjustments[payAdjustments.indexOf(id)] = params
        this.setState({
            payAdjustments,
            isDirty: true
        })
    }

    /** Fields
     ================================================================= */
    getSettlementFields = () => {
        return {
            DriverSettlementTemplate: new Field('DriverSettlementTemplate', '', ['empty'], false, 'text', { addContainerClass: 'col-span-full' }),
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-6'}),
            PayMethodValue: new Field('PayMethodValue', '', ['empty'], false, 'text', {addContainerClass: 'col-span-6'}),
            PayMethodValueExtra: new Field('PayMethodValueExtra', '', [], false, 'hidden', {addContainerClass: 'col-span-3'}),

            // PAY TO
            IsPaysIFTA: new Field('IsPaysIFTA', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),
            IsPaysFuel: new Field('IsPaysFuel', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),

            Send1099: new Field('Send1099', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),

            PayOnlyWhenPODReady: new Field('PayOnlyWhenPODReady', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                labelType: "float"
            }),

            IsFactoringActive: new Field('IsFactoringActive', '', [], false, 'switch', {
                addContainerClass: "-mx-4 pr-4 col-span-full flex items-center hover:bg-sky-600/10 rounded-xl",
                htmlBefore: (it) =>
                    <FieldSwitchLabel
                        onClick={() => this.handleSettlementsInputChange("IsFactoringActive", !it.value)}
                        label={this.props.translate("field.PayToOverride")}
                        note={this.props.translate("text.PayToOverrideInfo")}
                    />
            }),
            Notes: new Field('Notes', '', ['empty'], false, 'textarea',{addContainerClass: 'col-span-full'})
        }
    }

    handleSettlementsInputChange = (name, value) => {
        let fields = this.state.fields_settlements;

        fields = FieldsManager.updateField(fields, name, value);

        if (name === 'IsFactoringActive') {
            fields.OrganizationID.value = !value ? "" : fields.OrganizationID.value;
            fields.OrganizationID.disabled = !value;

            fields.Send1099.value = value ? "" : fields.Send1099.value;
            fields.Send1099.disabled = !!value;
        }

        this.setState({
            fields_settlements: fields
        })
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')
        if (tab) this.handleTabChange(tab)
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!this.getID()) {
            this.setState({
                tabs: this.state.tabs.map((it) => {
                    it.current = it.resource === resource
                    return it
                }),
                selectedTab: resource
            })
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: () => {
                    this.tabChange(resource)
                }
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        }, () => this.props.history.push('?tab=' + resource))
    }

    onSubmit = () => {
        this.props.dispatch(createSecondResource({
            user: LocalStorage.get('user'),
            query: this.getQueryDeduction(),
            params: this.state.payAdjustments,
            resource: this.getResourceDeductions(),
            piggyResource: this.getResourceDeductions(),
            errorMessage: true, successMessage: this.props.translate('text.driver_deduction_created')
        }))
    }
    /** Helpers
     ================================================================= */

    getResource = () => {
        return Resources.DriverSettlementsTemplate;
    }
    getResourceDeductions = () => {
        return Resources.DriverSettlementsTemplateDeductions
    }
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset
        }
    }
    getQueryDeduction = () => {
        return {
            searchFields: JSON.stringify({DriverSettlementTemplateID: this.props.match.params.id})
        }
    }
    getPropName = () => {
        if (this.props.location?.query?.input) {
            let value = this.props.location.query.input.split(' ')
            this.setState({fields_info: FieldsManager.updateField(this.state.fields_info, 'FirstName', value[0])})
            if (value.length > 1) {
                let tmp = ''
                value.forEach((it, i) => {
                    if (i != 0) {
                        tmp += it + ' '
                    }
                })
                this.setState({fields_info: FieldsManager.updateField(this.state.fields_info, 'LastName', tmp)})
            }
        }
    }


    getID = () => {
        return this.props.match.params.id
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const hasFooter = !this.getID() ? true : (this.state.selectedTab === Resources.DriverSettlements)

        return (
            <Layout
                {...this.props}
                hasFooter={hasFooter}
                isDirty={this.state.isDirty}
                isAccessible={!(this.props.resource.errorStatus === 2)}>
                <Page>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flex justify-between items-center">
                                    <div className="mb-2 flex justify-start items-center">
                                        <GoBackButton
                                            translate={this.props.translate}
                                            history={this.props.history}
                                            path={'/settlements-template'}
                                        />
                                        <h1 className={'mr-5 text-3xl ml-2'}>
                                            {translate("page.heading.SettlementsTemplates")}

                                            {!!this.getID() && (<span
                                                className="text-tm-gray-500"> - {translate('text.Edit_template')}
                                        </span>)}

                                            {!this.getID() && (
                                            <span
                                                className="text-tm-gray-500"> - {translate('text.Create_template')}
                                        </span>)}
                                        </h1>
                                    </div>

                                </div>

                                <div className="mb-5 hidden sm:block">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                 <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div>
                                    {this.state.selectedTab === Resources.DriverSettlements && (
                                        <div>
                                            <div className={"w-2/5"}>
                                        <InfoParagraph
                                            className="mt-2.5 mb-2"
                                            message={translate("text.ChangingPayTemplateWontAffect")}
                                        />
                                            </div>

                                        <div className="grid grid-cols-12 gap-x-5 gap-y-10">
                                            <div className="col-span-full 3xl:col-span-4 mt-1">
                                                <CreateSettlementTemplateTab
                                                    fields={this.state.fields_settlements}
                                                    handleIsDirty={(item) => this.setState({
                                                        isDirty: item
                                                    })}
                                                    isDirty={this.state.isDirty}
                                                    handleInputCreateChange={this.handleSettlementInputCreateChange}
                                                    submit={() => {this.onSubmit()}}
                                                    {...this.props}
                                                    DeductionItems={this.state.payAdjustments}
                                                />
                                            </div>

                                            <div className="col-span-full 3xl:col-span-8">
                                                <CreateSettlementTemplateDeductionTab
                                                    dispatch={this.props.dispatch}
                                                    secondResource={this.props.secondResource}
                                                    onAddPayAdjustment={this.handleAddPayAdjustments}
                                                    onDeletePayAdjustment={this.handleDeletePayAdjustments}
                                                    onEditPayAdjustments={this.handleEditPayAdjustments}
                                                    payAdjustments={this.state.payAdjustments}
                                                    translate={this.props.translate}
                                                    resourceName={Resources.DriverSettlementsTemplateDeductions}
                                                    {...this.props}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </Page>

            </Layout>
        )
    }
}

export default connect(state => state)(DriverSettlementsTemplateCreateEdit)

