import React from 'react';
import LoadPlaningStops from './load-planing-stops';
import LoadPlaningCommodities from './load-planing-commidities';
import {FieldsManager} from '../../../../../../data/services/fields';
import {checkPerm} from "../../../../../../common/util/util-helpers";
import Resources from "../../../../../../data/services/resources";
import {CREATE_PERM} from "../../../../../../util/util-constants";
import Button from "../../../../../../common/components/button";
import InfoParagraph from "../../../../../../common/components/info-paragraph";

export default function LoadPlaningView({
                                            stops,
                                            setStops,
                                            onClose,
                                            onSubmitClick,
                                            commodityData,
                                            setCommodityData,
                                            isStateDirty,
                                            setIsStateDirty,
                                            translate
                                        }) {

    function handleSubmitClick() {
        let areStopsValid = true;
        stops = stops.map(stop => {
            const stopValidated = FieldsManager.validateFields(stop);

            if (FieldsManager.checkFieldsForErrors(stopValidated)) {
                return stop;
            } else {
                areStopsValid = false;
                return stopValidated;
            }
        });
        if (areStopsValid) {
            onSubmitClick({
                Commodities: commodityData,
                Multistop: stops.map(it => {
                    it = FieldsManager.getFieldKeyValues(it);
                    //delete it.GooglePlaces;
                    return it;
                })
            });
        } else {
            setStops([...stops]);
        }
    }

    return (
        <React.Fragment>
            <div className='h-dialog-body space-y-8'>
                <div className="p-1">
                    <InfoParagraph>
                        <p>{translate("message.load_plan_info")}</p>
                    </InfoParagraph>
                </div>

                <LoadPlaningStops
                    stops={stops}
                    setStops={setStops}
                    setIsStateDirty={setIsStateDirty}
                    translate={translate}
                />

                <LoadPlaningCommodities
                    commodityData={commodityData}
                    setCommodityData={setCommodityData}
                    translate={translate}
                />
            </div>

            <footer
                className="min-h-[4rem] flex items-center border-tm-gray-200 border-t gap-3 bg-inverse rounded-b-lg"
            >
                <div className="pl-3 pr-6 flex grow w-full relative">
                    <div className="ml-auto space-x-3">
                        <Button
                            hasPerm={checkPerm(Resources.LoadPreview, CREATE_PERM)}
                            className="btn btn-primary"
                            disabled={!isStateDirty}
                            onClick={handleSubmitClick}
                        >
                            {translate("btn.preview_load")}
                        </Button>

                        <button
                            className="btn btn-outline"
                            onClick={onClose}
                        >
                            {translate("btn.close")}
                        </button>
                    </div>
                </div>
            </footer>
        </React.Fragment>

    )
}
