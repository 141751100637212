import React from 'react'
import {classNames} from "../../../util/util-helpers";

const DialogTabs = (props) => {
    const {className, tabs, onTabChange, translate} = props;
    return (
        <ul className={className ?? "my-5 space-y-px"}>
            {tabs.filter(it => it.visible).map(item => (
                <li key={item.id} className="">
                    <button
                        className={classNames(
                            item.current ? "bg-tm-gray-200 hover:bg-tm-gray-300" : "hover:bg-tm-gray-100",
                            item.current && !item.invalid ? "text-tm-gray-900" : undefined,
                            item.current && item.invalid ? "text-red-600" : undefined,
                            !item.current && item.invalid ? "text-red-600" : undefined,
                            !item.current && !item.invalid ? "text-tm-gray-700" : undefined,
                            "group w-full flex pl-5 py-2 pr-4 text-sm font-medium rounded-r-btn focus-visible:ring focus-visible:ring-1 focus-visible:ring-primary focus-visible:z-10"
                        )}
                        onClick={() => onTabChange(item.name)}
                    >
                        <item.icon
                            className={
                                classNames(
                                    item.invalid
                                        ? "text-red-500"
                                        : (item.current ? "text-tm-gray-900" : "text-tm-gray-700"),

                                    "flex-shrink-0 h-6 w-6 opacity-70"
                                )
                            }
                        />

                        <span className="ml-4 text-left pt-0.5">{translate("tab." + item.name)}</span>
                    </button>
                </li>
            ))}
        </ul>
    )
}

export default DialogTabs
