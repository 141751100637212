import React from "react";
import { classNames } from "../../util/util-helpers";

export default function Buttons({buttons = []}) {
    return (
        <React.Fragment>
            {buttons.filter(it => !it.isHidden).map((button, i) => (
                <button
                    key={button.key ?? i}
                    onClick={button.onClick}
                    className={button.className ?? getType(button.type)}
                    disabled={button.disabled}
                    title={button.title}
                >
                    {!!button.iconLeading && (
                        <button.iconLeading
                            className={
                                classNames(
                                    "w-5 h-5",
                                    button.label ? "mr-1" : undefined
                                )
                            }
                        />
                    )}

                    {button.label}

                    {!!button.iconTrailing && (
                        <button.iconTrailing className="w-5 h-5 ml-auto"/>
                    )}
                </button>

            ))}
        </React.Fragment>
    )
}

function getType(type) {
    switch (type) {
        case "primary":
            return "btn btn-primary";
        default:
            return "btn btn-outline";
    }
}

