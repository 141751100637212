import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    JournalEntryTypeID,
    READ_PERM
} from '../../../util/util-constants'
import InvoiceExpenseDetailsDialog from './invoice-expense-details-dialog'
import {getThirdResource} from '../../../data/actions/thirdResource'
import LocalStorage from '../../../util/localStorage'
import {
    checkPerm,
    getDefaultTableOptions,
    getProp,
    longTableColumn,
    renderExpenseStatusBadge,
    renderInvoiceStatusBadge
} from '../../../common/util/util-helpers'
import {EyeIcon} from '@heroicons/react/24/outline'
import {getDialogResource} from "../../../data/actions/dialogResource";
import Tippy from "@tippyjs/react";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {excludeFields, fieldsToHtml} from "../../../common/util/util-fields";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {formatMoney} from "../../../common/util/util-formaters";

export default class AccountReportDialog extends Component {

    constructor(props) {
        super(props)

        this.pagePath = "account_report_dialog"

        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {
                style: {
                    condensed: true,
                    horizontalLines: true,
                    verticalLines: true,
                },
                columns: {
                    Date: {
                        minWidth: 150
                    },
                    Credit: {
                        minWidth: 150
                    },
                    Debit: {
                        minWidth: 150
                    }
                }
            }, this.pagePath, this.props.translate),
            selectedItem: null,
            showReceiptModal: false,
            isTransactionsDialogVisible: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            let fields = this.state.queryFilterFields
            fields = FieldsManager.updateField(fields, 'AccountID', {
                value: this.props.AccountID,
                label: this.props.AccountName
            })
            fields = FieldsManager.updateField(fields, 'StartDate', this.props.StartDate)
            fields = FieldsManager.updateField(fields, 'EndDate', this.props.EndDate)
            this.setState({
                queryFilterFields: fields,
                offset: 0,
                limit: 10,
                sort: 'ASC',
                sortBy: '',
                paginationPage: 1,
            }, this.fetchData)
        }
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.getTransactions(this.getQuery());
    }

    fetchExpenseDetailsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                SendBatchPaymentID: this.state.selectedItem?.ReferenceType === "tbl_SendBatchPayment" ? this.state.selectedItem?.ReferenceID : null,
                SendPaymentID: this.state.selectedItem?.ReferenceType === "tbl_SendPayment" ? this.state.selectedItem?.ReferenceID : null
            },
            resource: Resources.ExpenseTransactionsDetails
        }))
    }

    fetchIncomeDetailsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: this.state.selectedItem?.ReferenceType === "tbl_RecvBatchPayment" ? this.state.selectedItem?.ReferenceID : null,
                RecvPaymentID: this.state.selectedItem?.ReferenceType === "tbl_RecvPayment" ? this.state.selectedItem?.ReferenceID : null
            },
            resource: Resources.IncomeTransactionsDetails
        }))
    }
    /** UI Events
     ================================================================= */
    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleToggleReceiptDialog = (item = null) => {
        let receiptModalFetchKey = null
        let receiptModalFetchResource = null
        if (item) {
            receiptModalFetchKey = item.ReferenceType === 'tbl_Invoice' ? 'InvoiceID' :
                item.ReferenceType === 'tbl_Expense' ? 'ExpenseID' : ''

            receiptModalFetchResource = item.ReferenceType === 'tbl_Invoice' ? Resources.InvoicesInvoice :
                item.ReferenceType === 'tbl_Expense' ? Resources.ExpenseExpense : ''
        }
        this.setState({
            selectedItem: item,
            showReceiptModal: !this.state.showReceiptModal,
            receiptModalFetchKey: receiptModalFetchKey,
            receiptModalFetchResource: receiptModalFetchResource
        })
    }

    toggleShowDetails = (item = null) => {
        let receiptModalFetchKey = null
        let receiptModalFetchResource = null
        if (item) {
            receiptModalFetchKey = item.ExpenseID ? 'ExpenseID' : 'InvoiceID'


            receiptModalFetchResource = item.ExpenseID ? Resources.ExpenseExpense : Resources.InvoicesInvoice
        }
        this.setState({
            selectedItem: item,
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
            receiptModalFetchKey: receiptModalFetchKey,
            receiptModalFetchResource: receiptModalFetchResource
        })
    }
    handleToggleShowTransactions = (item = null) => {
        this.setState({
            selectedItem: item,
            selectedTransactionsItem: item,
            isTransactionsDialogVisible: !this.state.isTransactionsDialogVisible,
        }, () => {
            this.state.isTransactionsDialogVisible && (item.ReferenceType === 'tbl_SendBatchPayment' || item.ReferenceType === 'tbl_SendPayment') ? this.fetchExpenseDetailsData() : this.fetchIncomeDetailsData();
            !this.state.isTransactionsDialogVisible && this.fetchData();
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            AccountID: new Field('AccountID', {
                value: this.props.AccountID,
                label: this.props.AccountName
            }, [''], false, 'select-search', {
                addContainerClass: 'col-span-6  xl:col-span-2 3xl:col-span-3'
            }),
            StartDate: new Field('StartDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-3 xl:col-span-2 3xl:col-span-1',
            }, {isClearable: true}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-3 xl:col-span-2 3xl:col-span-1',
            }, {isClearable: true}),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-6 xl:col-span-2 3xl:col-span-3'}, {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.FirstName + " " + item.LastName,
                    value: item.ContactID
                })
                ,
                isClearable: true
            }),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-5 xl:col-span-3 3xl:col-span-3',
            }, {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName,
                    value: item.OrganizationID
                })
                ,
                isClearable: true
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: 'top'})

        }
    }

    getDetailsFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "PayTo",
                render: (it) => it.Organization ?? it.Contact
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                omitSort: true,
                label: "PaymentAmount",
            }),
            ExpenseAmount: new Field('ExpenseAmount', '', [], false, 'money', {
                hideTable: !getProp(this.props.dialogResource, "data.list", [])[0]?.ExpenseAmount,
                omitSort: true
            }),
            ExpenseStatus: new Field('ExpenseStatus', '', [''], false, 'custom', {
                hideTable: !getProp(this.props.dialogResource, "data.list", [])[0]?.ExpenseStatus,
                omitSort: true,
                render: (it) => renderExpenseStatusBadge(it)
            }),
            InvoiceAmount: new Field('InvoiceAmount', '', [], false, 'money', {
                hideTable: !getProp(this.props.dialogResource, "data.list", [])[0]?.InvoiceAmount,
                omitSort: true
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                hideTable: !getProp(this.props.dialogResource, "data.list", [])[0]?.InvoiceStatus,
                omitSort: true,
                render: (it) => renderInvoiceStatusBadge(it)
            })
        }
    }

    getFields = () => {
        return {
            Description: new Field('Description', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <div
                        onClick={() => {
                            if (item.ReferenceType === 'tbl_Invoice' || item.ReferenceType === 'tbl_Expense') {
                                this.handleToggleReceiptDialog(item)
                            }
                        }}
                    >{longTableColumn(item.Description)}</div>
                )
                , omitSort: true
            }),
            Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full', omitSort: true}),
            Debit: new Field('Debit', '', [], false, 'float', {omitSort: true}),
            Credit: new Field('Credit', '', [], false, 'float', {omitSort: true}),
            Balance: new Field('Balance', '', [], false, 'float', {
                omitSort: true
            }),
            TranRef: new Field('TranRef', '', ['empty'], false, 'custom', {
                render: (item) => {
                    if (item.Organization) {
                        return item.Organization;
                    } else if (item.Contact) {
                        return item.Contact
                    }
                    return ""
                }
                , omitSort: true
            }),
            JournalEntryTypeID: new Field('JournalEntryTypeID', '', [], false, 'custom', {
                render: (item) => (
                    <>{JournalEntryTypeID[item.JournalEntryTypeID]}</>
                )
                , omitSort: true
            })
        }
    }

    /** Render
     ================================================================= */

    render() {
        const {translate, isLoading, transactions, dialogResource} = this.props

        const filterFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, excludeFields(this.state.queryFilterFields, ['limit']))), translate, this.handleFilterInputChange, {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        })

        const EndAmount = getProp(transactions, 'EndAmount', 0)
        const StartAmount = getProp(transactions, 'StartAmount', 0)

        const data = getProp(transactions, 'Transactions', [])
        const count = getProp(transactions, 'count', 0)

        const transactionsData = getProp(dialogResource, "data.list", [])
        const transactionsDataIsLoading = getProp(dialogResource, 'isLoading', false)

        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-full'}
                limitHeight={true}
                title={translate('text.AccountTransactions')}

                closeButtonLabel={translate('btn.Close')}
                onClose={this.props.onClose}
            >
                <div className="p-5 grid grid-cols-12 gap-4">
                    {filterFieldsHtml}

                    {typeof this.props.downloadExcelReports === 'function' && (
                        <div className="col-span-1 flex item-center pt-5 justify-end">
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={() => this.props.downloadExcelReports(this.getQuery())}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>
                        </div>
                    )}
                </div>


                <div className="px-5 py-2.5 border-y border-tm-gray-200">
                    <div className={"flex justify-between font-bold text-base"}>
                        <div>
                            Starting balance: <span>{formatMoney(StartAmount ?? 0)}</span>
                        </div>
                        <div className={"mr-4"}>
                            End balance: <span>{formatMoney(EndAmount ?? 0)}</span>
                        </div>
                    </div>

                </div>

                <div>
                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        translate={translate}
                        isLoading={isLoading}
                        maxHeightClass={"max-h-[calc(100vh-30rem)] xl:max-h-[calc(100vh-25rem)]"}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                        onRowClick={(item) => {
                            if (checkPerm(Resources.InvoicesInvoice, READ_PERM) && item.ReferenceType === 'tbl_Invoice') {
                                this.handleToggleReceiptDialog(item)
                            }

                            if (checkPerm(Resources.ExpenseExpense, READ_PERM) && item.ReferenceType === 'tbl_Expense') {
                                this.handleToggleReceiptDialog(item)
                            }

                            if (checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_SendPayment') {
                                this.handleToggleShowTransactions(item)
                            }

                            if (checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_SendBatchPayment') {
                                this.handleToggleShowTransactions(item);
                            }

                            if (checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_RecvPayment') {
                                this.handleToggleShowTransactions(item)
                            }

                            if (checkPerm(Resources.IncomeTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_RecvPayment') {
                                this.handleToggleShowTransactions(it);
                            }
                        }}
                        actions={[
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.InvoicesInvoice, READ_PERM) && item.ReferenceType === 'tbl_Invoice',
                                action: (it) => this.handleToggleReceiptDialog(it),
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.ExpenseExpense, READ_PERM) && item.ReferenceType === 'tbl_Expense',
                                action: (it) => this.handleToggleReceiptDialog(it),
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_SendPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.ExpenseTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_SendBatchPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.IncomeTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_RecvPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            },
                            {
                                title: () => translate('text.click_to_view_details'),
                                visible: (item) => checkPerm(Resources.IncomeTransactionsDetails, READ_PERM) && item.ReferenceType === 'tbl_RecvBatchPayment',
                                action: (it) => {
                                    this.handleToggleShowTransactions(it)
                                },
                                icon: () => EyeIcon
                            }
                        ]}
                    />
                </div>

                <TableCardFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value, currentPage) => name === 'offset'
                                ? this.handleUpdateOffset(value, currentPage)
                                : this.handleFilterInputChange(name, value)
                        }
                        pageOffset={this.state.offset}
                        queryFields={this.state.queryFilterFields}
                        translate={translate}
                    />
                </TableCardFooter>

                <NoRecordsTable
                    show={(transactions.length === 0) && !isLoading}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                />

                <ModalDefault
                    show={this.state.showReceiptModal}
                    widthClass={'max-w-5xl w-screen'}
                    title={this.state.receiptModalFetchKey ? translate('text.' + this.state.receiptModalFetchKey) : ''}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleReceiptDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.receiptModalFetchKey]: this.state.selectedItem.ReferenceID
                                    },
                                    resource: this.state.receiptModalFetchResource
                                }))
                            }
                        }}
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.ExpenseID')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.receiptModalFetchKey]: this.state.selectedItem.ReferenceID ?? this.state.selectedItem[this.state.receiptModalFetchKey]
                                    },
                                    resource: this.state.receiptModalFetchResource
                                }))
                            }
                        }}
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isTransactionsDialogVisible}
                    widthClass={"max-w-6xl"}
                    title={translate('text.Payments')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowTransactions()}
                >
                    <div>
                        <ResourceTable
                            data={transactionsData}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={transactionsDataIsLoading}

                            limit={(transactionsData?.length ?? 10)}

                            actions={[
                                {
                                    action: this.toggleShowDetails,
                                    icon: EyeIcon,
                                    visible: () => checkPerm(Resources.InvoicesInvoice, READ_PERM),// TODO Check perm
                                    title: translate('text.show_details'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(transactionsData.length === 0) && !transactionsDataIsLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                        />
                    </div>

                </ModalDefault>

            </ModalDefault>
        )
    }
}

