import React from "react";

export default function DisplayDataFieldTextarea({fieldName, data, label, translate}) {
    return (
        <React.Fragment>
            {!!data[fieldName] && (
                <div className={'max-w-md mx-auto'}>
                    <div className="mt-6">
                        <p className="font-bold leading-5 mb-2">{label ?? translate("field." +fieldName)}</p>
                        <div
                            className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center">
                            {data[fieldName]}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
