import React from "react";
import { SketchPicker } from "react-color";

// Example of value object

/*
{
  "hsl": {
    "h": 0,
    "s": 0.32740426096767766,
    "l": 0.41675322000000004,
    "a": 1
  },
  "hex": "#8d4747",
  "rgb": {
    "r": 141,
    "g": 71,
    "b": 71,
    "a": 1
  },
  "hsv": {
    "h": 0,
    "s": 0.49329999999999996,
    "v": 0.5532,
    "a": 1
  },
  "oldHue": 0,
  "source": "hsv"
}
 */
const ColorPicker = ({name, value, onChange, className = ""}) => {

    return (
        <div className={className}>
            <SketchPicker color={value} onChangeComplete={(newColor) => {
                onChange(name, newColor)
            }} />
        </div>
    );
};

export default ColorPicker;