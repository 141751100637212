import React, {Component} from 'react'
import Layout from '../../../common/components/layout'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ClaimCard from "./claim-card";
import Page from "../../../common/components/layout/layout-components/page";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table/index"
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";

class ClaimsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ClaimNumber',

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            confirmDialog: false,
            infoDialog: false,
            breakpoint: {},
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'claims_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'claims_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.ClaimNumber}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            id: item.ClaimID
                        }),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.ClaimNumber]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_restore_claim')}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.ClaimID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Claim restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleShowCreateUpdateResource = (selectedItem = null) => {
        if (selectedItem) {
            this.handleLinkClick(`/claims/info/${selectedItem.ClaimID}`)
        } else {
            this.handleLinkClick(`/claims/create`)
        }
    }

    handleOpenInfoDialog = (item) => {
        this.setState({selectedItem: item, infoCardDialog: true})
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }
    /** Helpers
     ================================================================= */

    getFields = () => {
        const cellButtonClass = 'btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0';
        return {
            ClaimNumber: new Field('ClaimNumber', '', [''], false, 'custom', {
                render: (item) =>
                    <CellLink
                        openInNewTab={true}
                        className={cellButtonClass}
                        disabled={!checkPerm(Resources.ClaimsInfo, UPDATE_PERM) || !item.ClaimID}
                        to={`/claims/info/${item.ClaimID}`}
                    >
                        {item.ClaimNumber}
                    </CellLink>
            }),
            Customer: new Field('Customer', '', [''], false, 'custom', {
                render: (item) =>
                    <CellLink
                        openInNewTab={true}
                        className={cellButtonClass}
                        disabled={!checkPerm(Resources.CustomersInfo, UPDATE_PERM) || !item.CustomerID}
                        to={`/customers/info/${item.OrganizationID}/${item.CustomerID}`}
                    >
                        {item.Customer}
                    </CellLink>
            }),
            Driver: new Field('Driver', '', [''], false, 'custom', {
                render: (item) =>
                    <CellLink
                        openInNewTab={true}
                        className={cellButtonClass}
                        disabled={!checkPerm(Resources.DriverInfo, UPDATE_PERM) || !item.DriverID}
                        to={`/drivers/info/${item.DriverID}`}
                    >
                        {item.Driver}
                    </CellLink>
            }),
            Truck: new Field('Truck', '', [''], false, '', {
                render: (item) =>
                    <CellLink
                        openInNewTab={true}
                        className={cellButtonClass}
                        disabled={!checkPerm(Resources.TrucksInfo, UPDATE_PERM) || !item.TruckID}
                        to={`/trucks/info/${item.TruckID}`}
                    >
                        {item.Truck}
                    </CellLink>
            }),
            Trailer: new Field('Trailer', '', [''], false, '', {
                render: (item) =>
                    <CellLink
                        openInNewTab={true}
                        className={cellButtonClass}
                        disabled={!checkPerm(Resources.Trailer, UPDATE_PERM) || !item.TrailerID}
                        to={`/trailers/info/${item.TrailerID}`}
                    >
                        {item.Trailer}
                    </CellLink>
            }),
            LoadNumber: new Field('LoadNumber', '', [''], false, '', {
                render: (item) =>
                    <CellLink
                        openInNewTab={true}
                        className={cellButtonClass}
                        disabled={!checkPerm(Resources.LoadInfo, UPDATE_PERM) || !item.LoadID}
                        to={`/loads/info/${item.LoadID}`}
                    >
                        {item.LoadNumber}
                    </CellLink>
            }),
            ClaimCode: new Field('ClaimCode', '', ['empty']),
            ClaimOpened: new Field('ClaimOpened', '', ['empty'], false, 'date'),
            ClaimDate: new Field('ClaimDate', '', ['empty'], false, 'date'),
            ClaimClosed: new Field('ClaimClosed', '', ['empty'], false, 'checkbox'),
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it))
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.Claims
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.Claims')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleShowCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />
                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {/*<Tippy content={translate('text.download_template')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcelTemplate}
                                >
                                    <RectangleGroupIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}
                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(Resources.ClaimsInfo, UPDATE_PERM) ? this.handleShowCreateUpdateResource : null}

                            onView={this.handleOpenInfoDialog}
                            onEdit={this.handleShowCreateUpdateResource}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(Resources.ClaimsInfo, READ_PERM)}
                            hasEditPerm={checkPerm(Resources.ClaimsInfo, UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new claim'}
                            btnLabel="Create claim"
                            onBtnClick={() => {
                                this.handleLinkClick(`/claims/create?ClaimNumber=${this.state.queryFilterFields.query.value}`)
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    {(!!this.state.infoCardDialog &&
                        <ClaimCard
                            show={this.state.infoCardDialog}
                            ClaimID={this.state.selectedItem?.ClaimID}
                            translate={translate}
                            onClose={() => this.setState({infoCardDialog: false, selectedItem: null})}
                        />)}

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(ClaimsView)
