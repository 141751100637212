import React, {useEffect, useRef, useState} from "react";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import Buttons from "../buttons";
import ImageAvatarLoader from "../image/image-avatar-loader";
import ChatBubbleBottomCenterTextIcon from "@heroicons/react/24/outline/ChatBubbleBottomCenterTextIcon";
import {cloneDeep} from "../../util/util-vanilla";
import {classNames} from "../../util/util-helpers";

export default function NotificationsContainer({notification, position, closeNotification, clearNotifications}) {
    const DEFAULT_TIMEOUT = 3000;

    const [notifications, setNotifications] = useState([]);
    const positionClasses = getPositionClasses(position);

    let notificationTimeout = useRef();

    const handleCloseNotificationClick = (notification) => {
        let notificationsUpdate = cloneDeep(notifications);
        const currentNotificationID = notification?.id;

        notificationsUpdate = notificationsUpdate.map((it, i) => {
            it.isOpen = it.id === currentNotificationID ? false : it.isOpen;

            if (it.id === currentNotificationID) {
                it.isCurrent = false;
                if (notificationsUpdate[i - 1]) {
                    notificationsUpdate[i - 1].isCurrent = true;
                }
            }

            return it;
        })

        setNotifications(notificationsUpdate);
    }

    const closeCurrentNotification = () => {
        handleCloseNotificationClick(notifications.find(it => it.isCurrent));
    }

    const resetTimeout = () => {
        if (notificationTimeout.current) {
            clearTimeout(notificationTimeout.current);
        }

        const currentNotification = notifications.find(it => it.isCurrent);

        if (!!currentNotification && currentNotification.timeout) {
            const timeout = currentNotification.timeout;
            notificationTimeout.current = setTimeout(function () {
                closeCurrentNotification();
            }, timeout === true ? DEFAULT_TIMEOUT : timeout);
        }
    }

    useEffect(() => {
        if (notification?.position === position) {
            setNotifications(generateNotification(notification, notifications));
        }
    }, [notification]);

    useEffect(() => {
        if (notifications.length) {
            resetTimeout();
        }
    }, [notifications]);

    useEffect(() => {
        if (closeNotification?.position === position) {
            closeCurrentNotification();
        }
    }, [closeNotification]);

    useEffect(() => {
        setNotifications([]);
    }, [clearNotifications]);

    return (
        <div className={
            classNames(
                "absolute h-px w-px",
                positionClasses.container
            )}
        >
            {notifications
                .map((it) => (
                    <div
                        key={it.id}
                        className={
                            classNames(
                                "absolute w-[400px] transition duration-500",
                                positionClasses.position,
                                it.isOpen && it.isCurrent ? positionClasses.current : undefined,
                                it.isOpen && !it.isCurrent ? positionClasses.notCurrent : undefined,
                                !it.isOpen ? positionClasses.closing : undefined,
                            )
                        }
                    >
                        <div
                            className="pointer-events-auto border border-tm-gray-200 flex w-full max-w-sm rounded-lg bg-inverse shadow-card ring-1 ring-black ring-opacity-5">
                            <div
                                className={
                                    classNames(
                                        "p-4 flex-1",
                                        it.onNotificationClick ? "cursor-pointer" : undefined
                                    )
                                }
                                onClick={
                                    () => it.onNotificationClick
                                        ? it.onNotificationClick()
                                        : null
                                }
                            >
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {it.messageType === 'message' && (
                                            <ChatBubbleBottomCenterTextIcon
                                                className="h-6 w-6 text-primary"
                                                aria-hidden="true"
                                            />
                                        )}

                                        {it.messageType === 'error' && (
                                            <ExclamationCircleIcon className="h-6 w-6 text-red-400"
                                                                   aria-hidden="true"/>
                                        )}

                                        {it.messageType === 'success' && (
                                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>
                                        )}

                                        {it.messageType === 'avatar' && (
                                            <ImageAvatarLoader
                                                hasImagePath={true}
                                                ContactID={it.ContactID}
                                                size="md"
                                            />
                                        )}
                                    </div>

                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-tm-gray-900">{it.title}</p>
                                        <div className="mt-1 text-sm text-tm-gray-500 link-color"
                                             dangerouslySetInnerHTML={{__html: it.content}}>
                                        </div>

                                        {!!it.bottom && (
                                            <div className="mt-3 flex space-x-7">
                                                {it.bottom?.buttons && (
                                                    <Buttons
                                                        buttons={it.bottom.buttons(it)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className={classNames(
                                                "inline-flex rounded-md bg-inverse text-tm-gray-400 hover:bg-tm-gray-200 hover:text-tm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-inverse focus:ring-primary focus:ring-offset-2",
                                                it.isCurrent ? undefined : "opacity-0"
                                            )}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                !!it.isCurrent && handleCloseNotificationClick(it);
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {!!it?.side && (
                                <div className="flex">
                                    {it.side?.buttons && (
                                        <div
                                            className="flex flex-col divide-y divide-tm-gray-200 border-l border-tm-gray-300">
                                            <Buttons
                                                buttons={it.side.buttons(it)}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
        </div>
    )
}

const getPositionClasses = (position) => {
    switch (position) {
        case "top":
            return {
                container: "left-1/2 top-8",
                position: "top-0 -translate-x-1/2 animate-[notificationEntersTop_0.4s_linear]",
                current: "translate-y-0",
                notCurrent: "-translate-y-[calc(100%+1rem)]",
                closing: "scale-0 opacity-0 pointer-events-none duration-1000"
            }
        case "top-left":
            return {
                container: "left-12 top-8",
                position: "top-0 left-0 animate-[notificationEntersLeft_0.4s_linear]",
                current: "translate-y-0",
                notCurrent: "-translate-y-[calc(100%+1rem)]",
                closing: "-translate-x-[28rem] opacity-0 pointer-events-none duration-1000"
            }
        case "top-right":
            return {
                container: "right-12 top-8",
                position: "top-0 right-0 animate-[notificationEntersRight_0.4s_linear]",
                current: "translate-y-0",
                notCurrent: "-translate-y-[calc(100%+1rem)]",
                closing: "translate-x-[28rem] opacity-0 pointer-events-none duration-1000"
            }
        case "bottom":
            return {
                container: "left-1/2 bottom-12",
                position: "bottom-0 -translate-x-1/2 animate-[notificationEntersBottom_0.4s_linear]",
                current: "translate-y-0",
                notCurrent: "translate-y-[calc(100%+1rem)]",
                closing: "scale-0 opacity-0 pointer-events-none duration-1000"
            }
        case "bottom-left":
            return {
                container: "bottom-12 left-12",
                position: "bottom-0 left-0 animate-[notificationEntersLeft_0.4s_linear]",
                current: "translate-y-0",
                notCurrent: "translate-y-[calc(100%+1rem)]",
                closing: "-translate-x-[28rem] opacity-0 pointer-events-none duration-1000"
            }
        case "bottom-right":
            return {
                container: "bottom-12 right-12",
                position: "bottom-0 right-0 animate-[notificationEntersRightVa_0.4s_linear]",
                current: "translate-y-0",
                notCurrent: "translate-y-[calc(100%+1rem)]",
                closing: "translate-x-[28rem] opacity-0 pointer-events-none duration-1000"
            }

        default:
            return {
                container: "bottom-12 left-12",
                position: "bottom-0 left-0 animate-notification-enters-left",
                current: "translate-y-0",
                notCurrent: "translate-y-[calc(100%+1rem)]",
                closing: "-translate-x-[28rem] opacity-0 pointer-events-none duration-1000"
            }
    }
}

const generateNotification = (notification, notificationStack) => {
    let notificationStackUpdate = cloneDeep(notificationStack);
    notificationStackUpdate = notificationStackUpdate
        .filter(it => it.isOpen) // delete already viewed
        .map(it => {
            it.isCurrent = false;
            return it;
        });

    notificationStackUpdate.push(
        Object.assign({},
            notification
        )
    );

    return notificationStackUpdate;
}