import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {checkPerm} from '../../../common/util/util-helpers'
import {READ_PERM} from '../../../util/util-constants'
import AccessorialsTemplatesTab from "./accessorials-templates-tab";
import CommoditiesTemplatesTab from "./commodities-templates-tab";
import EventTemplatesTab from "./event-templates-tab";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import Layout from "../../../common/components/layout";

class DispatchTemplatesView extends Component {
    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'AccessorialTemplates',
                resource: Resources.Accessorial,
                current: false,
                visible: checkPerm(Resources.Accessorial, READ_PERM)
            },
            {
                name: 'CommoditiesTemplates',
                resource: Resources.Commodities,
                current: false,
                visible: checkPerm(Resources.Commodities, READ_PERM)
            },
            {
                name: 'EventTemplates',
                resource: Resources.LoadEventTemplates,
                current: false,
                visible: checkPerm(Resources.LoadEventTemplates, READ_PERM)
            }
        ]

        tabs[0].current = true

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,
            breakpoint: {}
        }
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        });
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.DispatchTemplates')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.Accessorial && (
                        <AccessorialsTemplatesTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.Commodities && (
                        <CommoditiesTemplatesTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.LoadEventTemplates && (
                        <EventTemplatesTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(DispatchTemplatesView)
