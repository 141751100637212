import React, {useEffect} from "react";
import {createPortal} from "react-dom";
import LoadStepsPopup from "../load-steps-popup";

export default function LoadStepsDispatchedStatus({
                                                      loadStatus,
                                                      submitButtonRef,
                                                      setAreStepsActive,
                                                      inTransitStatusRef,
                                                      currentlyFocusedElement,

                                                      stops,
                                                      element,
                                                      setElement,
                                                      suggestedStopField,
                                                      suggestNextStopField,
                                                      setSuggestedStopField,
                                                      findNextEmptyStopField,
                                                      areAllRequiredStopsFieldFilled
                                                  }) {
    const totalSteps = 2;

    function handleStepCloseClick() {
        setElement(undefined); // Erase popup element
        setAreStepsActive(false);
    }

    function suggestNextLoadStatus() {
        if (inTransitStatusRef.current) {

            setElement(createPortal(
                <LoadStepsPopup
                    title="Update load status"
                    currentElement={inTransitStatusRef.current}
                    currentlyFocusedElement={currentlyFocusedElement}
                    position="bottom-left"
                    addClass="w-[500px]"
                    totalSteps={totalSteps}
                    currentStep={1}
                    onCloseClick={handleStepCloseClick}
                    stepText={
                        <>
                            <p>
                                You can now advance to In Transit status.
                            </p>

                            <p>
                                Press the In Transit status or Next button.
                            </p>
                        </>
                    }
                    onNextClick={() => {
                        inTransitStatusRef.current.click();
                    }}
                />,
                inTransitStatusRef.current
            ));
        }
    }

    function saveCoveredStep() {
        if (submitButtonRef.current.parentNode) {
            setElement(createPortal(
                <LoadStepsPopup
                    position="top-left"
                    addClass="mt-5 z-50"
                    title="Save changes"
                    stepText={
                        <>
                            <p>
                                Now you can save dispatch status change.
                            </p>
                        </>
                    }
                    totalSteps={totalSteps}
                    currentStep={2}
                    onCloseClick={handleStepCloseClick}
                    onNextClick={() => {
                        submitButtonRef.current.click();
                    }}
                />,
                submitButtonRef.current.parentNode
            ));
        }
    }

    function getSuggestedStep() {
        const areStopFieldsFilled = !findNextEmptyStopField();

        if (areStopFieldsFilled) {
            setSuggestedStopField(null)
        }

        if (!areStopFieldsFilled) {
            setSuggestedStopField(findNextEmptyStopField())
        } else if (loadStatus !== 6) {
            suggestNextLoadStatus();
        } else {
            saveCoveredStep();
        }
    }

    useEffect(() => {
        if (loadStatus === 6) {
            getSuggestedStep();
        }
    }, [loadStatus]);

    useEffect(() => {
        getSuggestedStep();
    }, []);

    // Logic for adding new stops
    useEffect(() => {
        if (suggestedStopField && !areAllRequiredStopsFieldFilled) {
            suggestNextStopField(getSuggestedStep)
        }
    }, [suggestedStopField, stops]);

    return element ? element : null;
}
