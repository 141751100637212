import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    FIXED_ASSET_TYPES,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'
import Button from '../../../common/components/button'

class AssetsAssetsTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + '_asset'

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'FixedAsset',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    canAdjustWidth: true
                },
                style: {
                    condensed: true,
                },
            }, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_delete_AssetsAssets')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({
                        FixedAssetID: item.FixedAssetID
                    }, this.getQuery()),
                    errorMessage: true, successMessage: `${item.FixedAsset} deleted`,
                    resource: this.getResource(),
                    piggyResource: this.getResource()
                }))
                this.handleToggleConfirmDialog()
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_restore_AssetsAssets')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: {
                        FixedAssetID: item.FixedAssetID,
                        ArchivedDate: 1
                    },
                    errorMessage: true, successMessage: `${item.FixedAsset} AssetsAssets restored`,
                    query: this.getQuery(),
                    resource: this.getResource(),
                    piggyResource: this.getResource()
                }))
                this.handleToggleConfirmDialog()
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleEditClick = (item) => {
        this.handleLinkClick(`/accounting-assets/${item.FixedAssetID}`)
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'input', 'archived'])
    }

    getFields = () => {
        return {
            FixedAsset: new Field('FixedAsset', '', ['empty'], false, 'text'),
            AssetNumber: new Field('AssetNumber', '', ['empty'], false, 'text'),
            PurchaseOrderID: new Field('PurchaseOrderID', '', ['empty'], false, 'select-search'),
            StartingAmount: new Field('StartingAmount', '', ['empty'], false, 'money'),
            Amount: new Field('Amount', '', ['empty'], false, 'money'),
            SalvageAmount: new Field('SalvageAmount', '', ['empty'], false, 'money'),
            FixedAssetTypeID: new Field('FixedAssetTypeID', '', ['empty'], false, 'select', {}, {
                values: FIXED_ASSET_TYPES
            }),
            SerialNumber: new Field('SerialNumber', '', ['empty']),
            PurchaseDate: new Field('PurchaseDate', '', ['empty'], false, 'date'),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date'),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date'),
            WarrantyExpirationDate: new Field('WarrantyExpirationDate', '', ['empty'], false, 'date'),
            Description: new Field('Description', '', [], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Description)}</div>
                )
            }),
            IsSold: new Field('IsSold', '', [''], false, 'checkbox', {}),
            IsDeprecated: new Field('IsDeprecated', '', [''], false, 'checkbox', {}),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            showSold: new Field('showSold', '', [''], false, 'checkbox'),
            hideDeprecated: new Field('hideDeprecated', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
        }
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getResource = () => {
        return Resources.AssetsAssets
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <React.Fragment>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <div className={'flex mr-4'}>
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => this.handleLinkClick('/accounting-assets/create')}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditClick : null}
                        onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditClick : null}
                        onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(AssetsAssetsTab)
