import {formatMoney} from "../common/util/util-formaters";

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.toString();
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

export function floatUpTo16InputChange(n) {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(n)) {
        if (n.length > 17) {
            return n.slice(0, -1)
        }
        return n
    } else {
        return n.replace(/\D+/g, '');
    }
}

export function formatExpDate(value) {
    value = value.replace(/\D/g, '');

    if (value.length > 4) {
        value = value.substring(0, 4)
    }
    const month = value.substring(0, 2);
    const year = value.substring(2, 4);

    if (value.length > 2) {
        value = `${month} / ${year}`
    } else {
        value = value.replace(/\D/g, '');
    }

    return value
}

export function numberWithCommas(n, decimalCount = 2) {
    if (n == 0) {
        return "0.00"
    }
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, decimalCount, ".", ",");
    }

    return n;
}

export function integerWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, 0, "", ",");
    }

    return "";
}

export function numberWithCommasToBack(n, def = null) {
    if (n) {
        let parts = n.toString().split(".");
        return Number(parts[0].replace(/,/g, "") + (parts[1] ? "." + parts[1] : (n.toString().includes(".") ? "." : "")));
    }

    return def ?? n;
}
