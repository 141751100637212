import React, {useState} from 'react'
import moment from 'moment'
import {XMarkIcon} from "@heroicons/react/20/solid";
import LocalStorage from "../../../util/localStorage";
import Tooltip from "../../tooltip";
import NavResponsive from "../../nav-responsive";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import {LoaderSmall} from "../../loader";
import CardSubTitle from "../../card/card-sub-title";
import ModalDefault from "../../modal/modal-default";
import FieldGooglePlaces from "../../fields/field-google-places";
import Card from "../../card";
import {getProp, getTemperaturePref} from "../../../util/util-helpers";

const api = {
    key: process.env.REACT_APP_OPEN_WEATHER_MAP_KEY,
    base: "https://api.openweathermap.org/data/2.5/"
}

const MEASURE_TEMPERATURE_ID_CELSIUS = 1;
const MEASURE_TEMPERATURE_ID_FAHRENHEIT = 2;

const WeatherWidget = ({translate}) => {
    /** State
     ================================================================= */
    const [temperature, setTemperature] = useState(getTemperaturePref())
    const [wind, setWind] = useState(LocalStorage.get("user")?.Contact?.DistanceUnitID ?? 1)
    const [tabs, setTabs] = useState([])
    const [locationModalOpen, setLocationModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);

    // Icon
    const imgName = selectedItem?.list?.[0]?.weather?.[0]?.icon;
    const imagePath = `/images/weather-icons/${imgName}.svg`

    // TEMPERATURE
    const TempCurrent = selectedItem?.list?.[0]?.main?.temp;

    /** Data Events
     ================================================================= */
    const fetchData = (fields) => {
        const {Latitude, Longitude, query} = fields

        if (Latitude && Longitude) {
            const apiPath = `${api.base}forecast?lat=${Latitude}&lon=${Longitude}&units=metric&APPID=${api.key}`

            setIsLoading(true)
            fetch(apiPath)
                .then(res => res.json())
                .then(result => {
                    result.data = result
                    result.name = query

                    if (tabs.some(it => it.resource === result.name)) {
                        handleTabChange(tabs.find(it => it.resource === result.name).resource)
                    } else {
                        const oldTabs = [...tabs].map(it => {
                            it.current = false
                            return it
                        })

                        oldTabs.push(getTab(result))
                        setTabs(oldTabs);
                        setSelectedItem(result)
                    }

                    setIsLoading(false)
                });
            handleToggleLocationModal()
        }
    }

    /** UI Events
     ================================================================= */
    const handleToggleLocationModal = () => {
        setLocationModalOpen(!locationModalOpen)
    }

    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                if (it.current) {
                    setSelectedItem(it.data)
                }
                return it
            })
        )
    }

    const handleRemoveLocation = () => {
        const index = tabs.findIndex(it => !!it.current)
        if (tabs.length === 1) {
            setTabs([])
            setSelectedItem(null)
        } else {
            setTabs(
                tabs
                    .filter((_, idx) => idx !== index)
                    .map((item, idx) => {
                        item.current = idx === 0
                        if (item.current) {
                            setSelectedItem(item.data)
                        }
                        return item
                    })
            )
        }
    }

    /** Helpers
     ================================================================= */
    const getTab = (data) => {
        return {
            labelRaw: data.name,
            resource: data.name,
            current: true,
            visible: true,
            data: data.data
        }
    }

    const getTemperatureIcon = () => {
        if (temperature == MEASURE_TEMPERATURE_ID_FAHRENHEIT) {
            return <>&#8457;</>
        }
        if (temperature == MEASURE_TEMPERATURE_ID_CELSIUS) {
            return <>&#8451;</>
        }
    }

    const formatTemperature = (temp) => {
        if (!temp) {
            return 0
        }

        if (temperature == MEASURE_TEMPERATURE_ID_FAHRENHEIT) {
            return ((temp) * 2 + 33).toFixed(0)
        }
        if (temperature == MEASURE_TEMPERATURE_ID_CELSIUS) {
            return temp.toFixed(0)
        }
    }

    const dateBuilder = (d) => {
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        let day = days[d.getDay()];
        let date = d.getDate();
        let month = months[d.getMonth()];

        return `${day}, ${month} ${date}`
    }

    /** Render
     ================================================================= */
    const hourly = getProp(selectedItem, 'list', [])
        .map((item) => {
            const time = moment(new Date(item.dt * 1000)).format('h a - dddd, MMMM Do ')

            return (
                <Tooltip
                    key={item?.dt}
                    maxWidth={600}
                    content={(
                        <div className={'w-full m-auto'}>
                            <h5 className="text-md capitalize font-bold">{time}</h5>
                            <div className="flex justify-start items-center">
                                <img
                                    className={'w-auto h-[50px] object-cover'}
                                    src={imagePath}
                                />
                                <div>
                                    <h5 className="text-md capitalize font-bold">{item.weather[0].description}.</h5>
                                </div>
                            </div>
                            <div className="border-l-2 border-primary-tint ">
                                <h5 className="text-md ml-2">Humidity: {item?.main?.humidity}%</h5>
                                <h5 className="text-md ml-2">Pressure: {item?.main?.pressure}hPa</h5>
                                {/*<h5 className="text-md ml-2">Dew point: {formatTemperature(item.dew_point)}{getTemperatureIcon()}</h5>*/}
                                <h5 className="text-md ml-2">Wind Speed: {item?.wind?.speed} m/s W</h5>
                                {/*<h5 className="text-md ml-2">UV: {item.uvi}</h5>*/}
                            </div>
                        </div>
                    )}
                    trigger={'click'}
                    interactive
                >
                    <div
                        className={'flex flex-col items-center cursor-pointer hover:bg-tm-gray-400 px-5 border rounded-md m-3'}
                        key={item.dt}>
                        <p className={'whitespace-nowrap text-md'}>
                            {new Date(item.dt * 1000).toLocaleTimeString('en-us', {hour: 'numeric'})}
                        </p>
                        <img
                            className={'w-auto h-[50px] object-cover'}
                            src={imagePath}
                        />
                        <p className={'text-xl'}>{formatTemperature(item?.main?.temp)}{getTemperatureIcon()}</p>
                    </div>
                </Tooltip>
            )
        })

    const weekly = getProp(selectedItem, 'daily', []).map((item, index) => {
        const day = moment().add(index, 'day').format('dddd')
        const date = moment().add(index, 'day').format('dddd, MMMM Do YYYY')

        return (
            <Tooltip
                maxWidth={600}
                content={(
                    <div className={'w-full m-auto'}>
                        <h5 className="text-md capitalize font-bold">{date}</h5>
                        <div className="flex justify-start items-center">
                            <img
                                className={'w-auto h-[50px] object-cover'}
                                src={imagePath}
                            />
                            <div>
                                <h5 className="text-md capitalize font-bold">{item.weather[0].main}. {item.weather[0].description}.</h5>
                                <p>The high will be {formatTemperature(item.temp.max)}{getTemperatureIcon()}, the low
                                    will be {formatTemperature(item.temp.min)}{getTemperatureIcon()}.</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="border-l-2 border-primary-tint ">
                                <h5 className="text-xs ml-2">Humidity: {item?.main?.humidity}%</h5>
                                <h5 className="text-xs ml-2">Pressure: {item.pressure}hPa</h5>
                                <h5 className="text-xs ml-2">Dew
                                    point: {formatTemperature(item.dew_point)}{getTemperatureIcon()}</h5>
                                <h5 className="text-xs ml-2">Wind Speed: {item.wind.speed} m/s</h5>
                                <h5 className="text-xs ml-2">UV: {item.uvi}</h5>
                            </div>
                            <div className="border-l-2 border-primary-tint ">
                                <h5 className="text-xs ml-2">Sunrise: {new Date(item.sunrise * 1000).toLocaleTimeString('en-us', {
                                    hour: 'numeric',
                                    minute: 'numeric'
                                })}</h5>
                                <h5 className="text-xs ml-2">Sunset: {new Date(item.sunset * 1000).toLocaleTimeString('en-us', {
                                    hour: 'numeric',
                                    minute: 'numeric'
                                })}</h5>
                                <h5 className="text-xs ml-2">Moonrise: {new Date(item.moonrise * 1000).toLocaleTimeString('en-us', {
                                    hour: 'numeric',
                                    minute: 'numeric'
                                })}</h5>
                                <h5 className="text-xs ml-2">Moonset: {new Date(item.moonset * 1000).toLocaleTimeString('en-us', {
                                    hour: 'numeric',
                                    minute: 'numeric'
                                })}</h5>
                            </div>
                        </div>

                        <div className="grid grid-cols-5 gap-3 text-xs mt-2">
                            <div/>
                            <div className={'text-right'}>Morning</div>
                            <div className={'text-right'}>Afternoon</div>
                            <div className={'text-right'}>Evening</div>
                            <div className={'text-right'}>Night</div>

                            <hr className={'col-span-full border-tm-gray-300 border-t w-full'}/>
                            <div>Temperature</div>
                            <div
                                className={'text-right'}>{formatTemperature(item.temp.morn)}{getTemperatureIcon()}</div>
                            <div className={'text-right'}>{formatTemperature(item.temp.day)}{getTemperatureIcon()}</div>
                            <div className={'text-right'}>{formatTemperature(item.temp.eve)}{getTemperatureIcon()}</div>
                            <div
                                className={'text-right'}>{formatTemperature(item.temp.night)}{getTemperatureIcon()}</div>

                            <hr className={'col-span-full border-tm-gray-300 border-t w-full'}/>
                            <div>Feels like</div>
                            <div
                                className={'text-right'}>{formatTemperature(item.feels_like.morn)}{getTemperatureIcon()}</div>
                            <div
                                className={'text-right'}>{formatTemperature(item.feels_like.day)}{getTemperatureIcon()}</div>
                            <div
                                className={'text-right'}>{formatTemperature(item.feels_like.eve)}{getTemperatureIcon()}</div>
                            <div
                                className={'text-right'}>{formatTemperature(item.feels_like.night)}{getTemperatureIcon()}</div>
                        </div>
                    </div>
                )}
                trigger={'click'}
                interactive
            >
                <div
                    className={'flex flex-col items-center cursor-pointer hover:bg-tm-gray-300 px-2 border rounded-md m-3'}
                    key={item.dt}>
                    <p className={'font-bold text-center'}>
                        {day}
                    </p>
                    <img
                        className={'w-[50px] h-[50px] object-cover mx-auto'}
                        src={imagePath}
                    />
                    <p className={'flex justify-between items-center font-bold'}>
                        <span>{formatTemperature(item.temp.max)}{getTemperatureIcon()}</span>
                        <div className={'w-[1px] h-[20px] mx-1 bg-tm-gray-400'}/>
                        <span>{formatTemperature(item.temp.min)}{getTemperatureIcon()}</span>
                    </p>
                </div>
            </Tooltip>
        )
    })

    return (
        <div>
            <Card>
                <div className="flex justify-between items-center">
                    <CardSubTitle
                        text={translate('text.Weather')}
                    />

                    <button
                        className="btn btn-primary"
                        onClick={handleToggleLocationModal}
                    >
                        {translate('btn.AddNewLocation')}
                    </button>
                </div>

                {!!tabs.length && (
                    <div className={'relative z-0'}>
                        <div className="mb-5 hidden sm:block">
                            <NavResponsive
                                tabs={tabs}
                                onTabChange={handleTabChange}
                                translate={translate}
                            />
                        </div>

                        <div className="mb-5">
                            <MobileTabs
                                tabs={tabs}
                                onTabChange={handleTabChange}
                                translate={translate}
                            />
                        </div>
                    </div>
                )}

                {isLoading && (
                    <div className="mt-2 text-center">
                        <LoaderSmall/>
                    </div>
                )}

                {selectedItem && (
                    <div>
                        <div className="flex justify-end">
                            <button
                                className="btn-close hover:bg-red-500 hover:text-tm-gray-100 flex items-center justify-center"
                                onClick={handleRemoveLocation}>
                                <XMarkIcon className="w-5 h-5"/>
                            </button>
                        </div>

                        <div className={'grid grid-cols-12 gap-4'}>
                            <div className={'flex items-center justify-center col-span-2'}>
                                <p className={'text-5xl flex'}>
                                    {formatTemperature(TempCurrent)}
                                    <div className={'flex text-xl text-tm-gray-400 cursor-pointer'}>
                                            <span
                                                onClick={() => setTemperature(MEASURE_TEMPERATURE_ID_CELSIUS)}
                                                className={temperature === MEASURE_TEMPERATURE_ID_CELSIUS && 'text-tm-gray-900'}
                                            >&#8451;</span>
                                        <div className={'w-[2px] h-[20px] mt-1 mx-1 bg-tm-gray-500'}/>
                                        <span
                                            onClick={() => setTemperature(MEASURE_TEMPERATURE_ID_FAHRENHEIT)}
                                            className={temperature === MEASURE_TEMPERATURE_ID_FAHRENHEIT && 'text-tm-gray-900'}
                                        >&#8457;</span>
                                    </div>
                                </p>
                            </div>

                            <div className="col-span-2 flex justify-center items-center">
                                <img
                                    className={'w-32 max-w-full'}
                                    src={imagePath}
                                />
                            </div>


                            <div className="col-span-3 flex flex-col items-start justify-center">
                                <div className={'col-span-1 flex items-start justify-center flex-col'}>
                                    <h5 className="text-lg ">{dateBuilder(new Date())}</h5>
                                    <h5 className="text-lg capitalize">{selectedItem?.list?.[0]?.weather?.[0]?.description}.</h5>
                                    <h5 className="text-lg capitalize">Feels
                                        like {formatTemperature(selectedItem?.list?.[0]?.main?.feels_like)}{getTemperatureIcon()}</h5>
                                </div>
                            </div>

                            <div className="col-span-3 flex flex-col items-start justify-center">
                                <div className={'col-span-1 flex items-start justify-center flex-col'}>
                                    <h5 className="text-lg capitalize ml-2">Pressure: {selectedItem?.list?.[0]?.main?.pressure}hPa</h5>
                                    <h5 className="text-lg capitalize ml-2">Humidity: {selectedItem?.list?.[0]?.main?.humidity}%</h5>
                                    <h5 className="text-lg capitalize ml-2">
                                        Wind Speed: {" "}
                                        {wind === 2 && selectedItem?.list?.[0]?.wind?.speed + ' Km/h'}
                                        {wind === 1 && (selectedItem?.list?.[0]?.wind?.speed * 0.621371192).toFixed(2) + ' mph'}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <hr className={'border-tm-gray-300 border w-full my-3'}/>
                        <h5 className="text-lg ">48-Hour Forecast</h5>
                        <div className="flex gap-3 overflow-x-scroll">
                            {hourly}
                        </div>

                        <hr className={'border-tm-gray-300 border w-full my-3'}/>
                        <h5 className="text-lg ">8-day forecast</h5>
                        <div className="mt-5 flex gap-3 justify-between overflow-x-scroll">
                            {weekly}
                        </div>
                    </div>
                )}
            </Card>

            <ModalDefault
                show={locationModalOpen}
                widthClass={'max-w-2xl'}
                title={<h5>Add new location</h5>}

                closeButtonLabel={translate('btn.cancel')}
                onClose={handleToggleLocationModal}
            >
                <div className="p-5">
                    <FieldGooglePlaces
                        searchForAll
                        autoFocus
                        stateFields={{Latitude: true, Longitude: true}}
                        setLocations={fetchData}
                        placeholderText={translate('text.enter_place')}
                    />
                </div>
            </ModalDefault>
        </div>
    )
}

export default WeatherWidget
