import Tippy from "@tippyjs/react";
import React from "react";

export default function Tooltip({children, trigger, placement, animation, onClickOutside, interactive = false, theme, delay = [0, 0], content, disabled, maxWidth}) {
    return (
        <Tippy
            animation={animation}
            content={content}
            disabled={disabled}
            delay={delay}
            maxWidth={maxWidth}
            placement={placement}
            theme={theme}
            trigger={trigger}
            onClickOutside={onClickOutside}
            interactive={interactive}
        >
            {children}
        </Tippy>
    )
}
