import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import {classNames} from "../../../../../common/util/util-helpers";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";
import EnvelopeOpenIcon from "@heroicons/react/24/outline/EnvelopeOpenIcon";
import React, {useState} from "react";
import {copyToClipboard} from "../../../../../common/util/util-vanilla";
import Env from "../../../../../util/env";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import {LoaderLarge} from "../../../../../common/components/loader";

export default function LoadCarrierShareContent({
                                                    loadInfoData,
                                                    carrierLinkOptions,
                                                    toggleCarrierLinkAllOptions,
                                                    toggleCarrierLinkOption,
                                                    toggleSendURLDialog,
                                                    onClose,
                                                    isLoading,
                                                    translate
                                                }) {
    const [isCarrierUrlCopied, setIsCarrierUrlCopied] = useState(false);

    let allOptionsSelected = true
    carrierLinkOptions.forEach(it => {
        if (!it.value) allOptionsSelected = false
    })

    const allowShareOption = carrierLinkOptions.find(it => it.name === 'CarrierAllowShare');

    const handleSendURLClick = () => {
        setTimeout(toggleSendURLDialog(Env.getTruckMainUrl(getExternalCarrierURL()), 'carrierLinkOptions'), 400);
        onClose();
    }

    const getExternalCarrierURL = () => {
        return `/load/carrier/public/${loadInfoData.ExternalCarrierAccessToken}`
    }

    const copyCarrierUrlToClipboard = () => {
        copyToClipboard(Env.getTruckMainUrl(getExternalCarrierURL()));
        setIsCarrierUrlCopied(true);

        setTimeout(() => {
            setIsCarrierUrlCopied(false);
        }, 2000)
    }

    return (
        <div className="relative">
            {isLoading && (
                <LoaderLarge stripesBg/>
            )}

            <div className="py-2">
                <button
                    className={

                        classNames(
                            "btn btn-outline ml-4",
                            allOptionsSelected ? "text-red-900 hover:bg-red-600/10" : "text-green-900 hover:bg-green-600/10"
                        )
                    }
                    onClick={() => {
                        toggleCarrierLinkAllOptions(!allOptionsSelected);
                    }}
                >
                    {!allOptionsSelected
                        ? <CheckCircleIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                        : <XCircleIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                    }

                    {!allOptionsSelected ? 'Allow all' : 'Disallow all'}
                </button>
            </div>
            <div className="relative grid gap-8 bg-tm-gray-50 p-7 border-y border-tm-gray-300">
                {
                    !allowShareOption.value && (
                        <div>
                            <InfoBar
                                addClass="-mx-3"
                                type="warning"
                                message={"Link is deactivated and not visible to the carrier"}
                            />
                        </div>
                    )
                }

                {
                    !!allowShareOption.value && (
                        <div>
                            <InfoBar
                                addClass="-mx-3"
                                type="success"
                                message={"Link is active and visible to the carrier"}
                            />
                        </div>
                    )
                }

                {carrierLinkOptions.map((item, i) => {
                    const isDisabled = !!i && !carrierLinkOptions?.[0]?.value;

                    return (
                        <button
                            disabled={isDisabled}
                            key={item.name}
                            className={
                                classNames('flex shadow group items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-tint outline-0 focus-visible:ring-opacity-50',
                                    item.value ? 'bg-green-600/10' : '',
                                    !isDisabled ? "bg-inverse hover:bg-green-600/30" : "bg-stripes opacity-50"
                                )}
                            onClick={() => {
                                toggleCarrierLinkOption(item.name);
                            }}
                        >
                            <div
                                className={classNames('flex items-center justify-center flex-shrink-0 w-10 h-10 sm:h-8 sm:w-8', item.value ? 'text-green-600' : 'text-tm-gray-600')}>

                                {item.value
                                    ? <CheckCircleIcon className="h-5 w-5" aria-hidden="true"/>
                                    : <XCircleIcon className="h-5 w-5" aria-hidden="true"/>
                                }
                            </div>

                            <div className="ml-4 text-left">
                                <p className="text-sm font-medium text-tm-gray-900">
                                    {translate('text.' + item.name)}
                                </p>
                                <p className="text-sm text-tm-gray-600 group-hover:text-tm-gray-700">
                                    {item.description}
                                </p>
                            </div>
                        </button>
                    )
                })}
            </div>

            <div className="bg-tm-gray-50 py-4">
                <div
                    className="px-5 pb-5 space-y-6 sm:space-y-0 sm:px-8">
                    <button
                        className={classNames(!isCarrierUrlCopied ? 'text-tm-gray-900 hover:bg-tm-gray-100' : 'text-green-900 hover:bg-green-100', 'w-full p-3 flex items-center text-left rounded-md text-base font-medium transition ease-in-out duration-150')}
                        onClick={() => copyCarrierUrlToClipboard(loadInfoData)}
                    >
                        <ClipboardDocumentIcon className="h-5 w-5 "/>
                        <div className="ml-3">
                        <span
                            className="">{!isCarrierUrlCopied ? 'Copy link to clipboard' : 'Link copied to clipboard!'}
                        </span>
                        </div>
                    </button>

                    <button
                        className="w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150 text-tm-gray-900 hover:bg-tm-gray-100"
                        onClick={handleSendURLClick}
                    >
                        <EnvelopeOpenIcon className="h-5 w-5"/>
                        <span className="ml-3">{translate("text.share_url_email")}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
