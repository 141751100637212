import React, {Component} from "react";
import {FieldsManager} from "../../../../data/services/fields";
import {LoaderSmall} from "../../loader";
import InfoParagraph from "../../info-paragraph";
import {classNames} from "../../../util/util-helpers";
import {cloneDeep, scrollErrorIntoView} from "../../../util/util-vanilla";
import ModalDefault from "../modal-default";
import {fieldsToHtml} from "../../../util/util-fields";

export default class ModalSaveResource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: cloneDeep(this.props.fields),
            canSubmit: !!this.props.canSubmit
        }
    }

    componentDidUpdate(prevProps) {
        if (((prevProps.show !== this.props.show) && !!this.props.show) || (this.props.refreshFields !== prevProps.refreshFields)) {
            this.setState({
                fields: cloneDeep(this.props.fields),
                canSubmit: !!this.props.canSubmit
            })
        }

        if (prevProps.canSubmit !== this.props.canSubmit) {
            this.setState({canSubmit: this.props.canSubmit})
        }

        if (this.props.buttonDisabled !== prevProps.buttonDisabled) {
            this.setState({
                canSubmit: !this.props.buttonDisabled
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : this.updateFieldAndClearValidation(name, value);

        this.setState({fields: fields, canSubmit: true});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        validatedFields = this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if (this.props.getRawFields) {
                return validatedFields
            }
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    updateFieldAndClearValidation = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value);
        fields[name].errorMessage = "";
        return fields;
    }

    render() {
        const {
            onSubmit,
            onClose,
            translate,
            title,
            metadata,
            gridClass,
            gridColsClass,
            limitHeight,
            show,
            widthClass,
            buttonLabel,
            closeButtonLabel,
            htmlBefore,
            htmlAfter,
            hideForm,
            customButtonsHTML,
            buttonDisabled,
            secondaryButtonLabel,
            secondaryButtonDisabled,
            disableSecondaryIfNotDirty,
            onSecondaryButtonClick,
            isLoading,
            initialFocusRef,
            options
        } = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <ModalDefault
                show={show}
                title={title}
                widthClass={widthClass}
                translate={translate}
                onClose={onClose}
                onButtonClick={() => onSubmit(this.getValidFields(), this.state.fields)}
                buttonDisabled={!this.state.canSubmit || isLoading}
                buttonLabel={buttonLabel ?? translate("btn.save")}
                closeButtonLabel={closeButtonLabel ?? translate("btn.close")}
                customButtonsHTML={customButtonsHTML}
                limitHeight={limitHeight}
                secondaryButtonLabel={secondaryButtonLabel}
                initialFocusRef={initialFocusRef}
                options={options}
                secondaryButtonDisabled={
                    disableSecondaryIfNotDirty
                        ? !this.state.canSubmit || isLoading
                        : secondaryButtonDisabled
                }
                onSecondaryButtonClick={() => onSecondaryButtonClick(this.getValidFields(), this.state.fields)}
            >
                {isLoading && (
                    <div className="text-center">
                        <LoaderSmall/>
                    </div>
                )}
                {!isLoading && (
                    <div className="flex">
                        <div className="flex-shrink-0 flex-1">
                            {this.props.errorMessage && (
                                <div className={"pt-6 px-6"}>
                                    <InfoParagraph type="danger">
                                        {translate(this.props.errorMessage)}
                                    </InfoParagraph>
                                </div>
                            )}

                            {(typeof htmlBefore === 'function') ? htmlBefore(this.state.fields) : htmlBefore}

                            {!hideForm && (
                                <div
                                    className={gridClass ?? classNames("grid gap-4 p-6", gridColsClass ?? "grid-cols-6", "grid gap-4")}>
                                    {fieldsHtml}
                                </div>
                            )}

                            {(typeof htmlAfter === 'function') ? htmlAfter(this.state.fields) : htmlAfter}
                        </div>

                        {this.props.htmlRight}
                    </div>
                )}
            </ModalDefault>
        );
    }
}
