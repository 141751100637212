import React, {useEffect, useState} from "react";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {classNames, getLookup} from "../../../util/util-helpers";

import {
    ACCOUNTING_DOCUMENT_ADDRESS,
    ACCOUNTING_DOCUMENT_LOGO, ACCOUNTING_DOCUMENT_NAME,
    DEFAULT_CRUD_STATE, DISPATCH_DOCUMENT_ADDRESS, DISPATCH_DOCUMENT_LOGO,
    DISPATCH_DOCUMENT_NAME
} from "../../../../util/util-constants";
import TableCard from "../../resource-table/table-components/table-card";
import TableFilters from "../../resource-table/table-components/table-filters";
import ResourceTable from "../../resource-table";
import Pagination from "../../resource-table/table-components/pagination";
import NoRecordsTable from "../../no-records-found/no-records-table";
import Tippy from "@tippyjs/react";
import {AdjustmentsVerticalIcon, ChevronDoubleRightIcon, Bars4Icon} from "@heroicons/react/24/outline";
import {toFrontDate, toFrontDateTime, toFrontDateTimeFromUTC, toLocalTimeFromUTC} from "../../../util/util-dates";
import Env from "../../../../util/env";
import Resources from "../../../../data/services/resources";
import {getJWT} from "../../../util/util-auth";
import {XCircleIcon, CheckCircleIcon} from "@heroicons/react/20/solid";
import {numberWithCommas} from "../../../util/util-formaters";
import {genericMoneyFormatter} from "../../../util/util-vanilla";
import ModalFooter from "../modal-footer";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {WorkingHoursColumn} from "../../display-data/working-hours-display";
import {LoaderSmall} from "../../loader";

const processData = (item, key, type, translate, inTable) => {
    const value = item[key]

    // Custom fields
    if (item.Field === 'CDLState') { return getLookup('State')[value] }
    if (item.Field === 'DispatchDocumentName') { return DISPATCH_DOCUMENT_NAME[value] }
    if (item.Field === 'DispatchDocumentAddress') { return DISPATCH_DOCUMENT_ADDRESS[value] }
    if (item.Field === 'DispatchDocumentLogo') { return DISPATCH_DOCUMENT_LOGO[value] }
    if (item.Field === 'AccountingDocumentName') { return ACCOUNTING_DOCUMENT_NAME[value] }
    if (item.Field === 'AccountingDocumentAddress') { return ACCOUNTING_DOCUMENT_ADDRESS[value] }
    if (item.Field === 'AccountingDocumentLogo') { return ACCOUNTING_DOCUMENT_LOGO[value] }

    if (item.Field === 'IsBroker') { return value ? 'Brokerage' : 'Carrier' }

    if (item.Field === 'ShippingHours' || item.Field === 'ReceivingHours') {
        const arr = JSON.parse(value || "[]")

        return !!arr.length && (
            <div className={classNames(inTable ? 'min-w-max' : '',
                "grid divide-x divide-tm-gray-200 grid-cols-3"
            )}>
                <div className="bg-inverse flex items-center flex-col divide-y divide-tm-gray-300">
                    <div className="px-3 font-bold text-tm-gray-700 grow w-full flex items-center h-10">
                        {translate("text.day")}
                    </div>

                    {arr.map(it => (
                        <div className="px-3 grow w-full flex items-center h-10">
                            {it.Day}
                        </div>
                    ))}
                </div>

                <WorkingHoursColumn
                    colTitle={translate("text." + item.Field)}
                    hours={arr}
                    translate={translate}
                />
            </div>
        )
    }

    // System fields
    switch (type) {
        case "date":
            return toFrontDate(value)
        case "datetime":
            return toFrontDateTime(value)
        case "datetimezone":
        case "timezone-custom":
            return toLocalTimeFromUTC(value)
        case 'checkbox':
            return !!value ?
                <div className="pl-4">
                    <CheckCircleIcon className="w-5 h-5 text-green-600" />
                </div> :
                <div className="pl-4">
                    <XCircleIcon className="w-5 h-5 text-tm-red-400"/>
                </div>
        case 'float':
            return <div className="">{numberWithCommas(value)}</div>
        case 'integer':
            return <div className="">{value ? value.toString().replace(/\D/, '') : ''}</div>
        case 'money':
            return <div className="">{genericMoneyFormatter(value)}</div>
        default:
            return value;
    }
}

export const DEFAULT_HISTORY_TABLE_FIELDS = (translate, fieldTypes = {}) => ({
    UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'text', {}),
    Date: new Field('Date', '', [], false, 'datetimezone'),
    Field: new Field('Field', '', [], false, 'custom', {
        render: (item) => translate("field." + item.Field)
    }),
    OldValue: new Field('OldValue', '', [], false, 'text', {
        render: (item) => processData(item, 'OldValue', fieldTypes[item.Field], translate,true),
    }),
    NewValue: new Field('NewValue', '', [], false, 'text', {
        render: (item) => processData(item, 'NewValue', fieldTypes[item.Field], translate,true),
    })
})

const ModalHistory = ({
                          translate,
                          tableFields,
                          filterFields,
                          showUserAvatar,
                          onClose,
                          title,
                          onFetchData,
                          data,
                          count,
                          isLoading,
                          onFilterInputChange,
                          onRowClick,
                          fieldTypes
                      }) => {

    /** Helpers
     ================================================================= */
    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields),
        }
    }

    const groupBy = (arr, property) => {
        return arr
            .sort((a, b) => new Date(b.Date) - new Date(a.Date))
            .reduce(function (memo, x) {
                if (!memo[x[property]]) {
                    memo[x[property]] = [];
                }
                memo[x[property]].push(x);
                return memo;
            }, {});
    }

    /** State
     ================================================================= */
    const [queryFilterFields, setQueryFilterFields] = useState(() => filterFields ?? {})
    const [query, setQuery] = useState(DEFAULT_CRUD_STATE)
    const [selectedView, setSelectedView] = useState('timeline') // table, timeline
    const [selectedListDate, setSelectedListDate] = useState('')
    const [currentData, setCurrentData] = useState([])

    /** UI Events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        const fields = onFilterInputChange
            ? onFilterInputChange(queryFilterFields, name, value)
            : FieldsManager.updateField(queryFilterFields, name, value);
        setQueryFilterFields(fields)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleClose = () => {
        onClose()
    }

    const handleSetSelectedView = (view) => {
        if (view !== selectedView) setSelectedView(view)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (selectedView === 'table') {
            onFetchData(getQuery())
        }
        if (selectedView === 'timeline') {
            const queryTmp = Object.assign({}, getQuery())
            queryTmp.limit = 500
            queryTmp.offset = 0

            onFetchData(queryTmp)
        }
    }, [queryFilterFields, query, selectedView])

    useEffect(() => {
        if (!isLoading && !!data.length && selectedView === 'timeline') {
            const first = Object.keys(groupedData)[0]
            if (first) {
                setCurrentData(groupedData[first])
                setSelectedListDate(first)
            }
        }
    }, [isLoading])

    /** Render
     ================================================================= */
    const groupedData = groupBy(data, "Date")
    const dates = selectedView === "timeline" && !isLoading && Object.keys(groupedData).map((item, index) => {
        return (
            <button
                onClick={() => {
                    setCurrentData(groupedData[item])
                    setSelectedListDate(item)
                }}
                className={classNames(
                    "hover:bg-tm-gray-100 text-tm-gray-900 w-full pl-2 py-4 text-sm font-medium rounded-r-md text-left",
                    index !== 0 ? "border-t border-gray-200" : "",
                    selectedListDate === item ? "bg-tm-gray-200" : ""
                )}>
                <div className={'flex justify-start items-center'}>
                    <img
                        className="h-5 w-5 rounded-full bg-inverse flex items-center justify-center ring-2 ring-white mr-2"
                        src={showUserAvatar ? Env.getApiUrl("api/" + Resources.ContactImage, {
                            token: getJWT().access_token,
                            ContactID: groupedData[item][0]["ContactID"]
                        }) : "/images/avatar-light.png"}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/avatar-light.png";
                        }}
                        alt=""
                    />
                    <div>
                        <div>
                            {toFrontDateTimeFromUTC(item)}
                        </div>
                        <div>
                            {groupedData[item][0]["UpdatedByContactID"]}
                        </div>
                    </div>
                </div>
            </button>
        )
    })
    const currentDataHtml = selectedView === "timeline" && !isLoading && currentData
        .filter(item => !!item.OldValue || !!item.NewValue)
        .map((item, index) => {

            return (
                <li key={index}>
                    <div className="relative pb-8">
                        {index !== currentData.filter(item => !!item.OldValue || !!item.NewValue).length - 1 && (
                            <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-300"
                                  aria-hidden="true"/>
                        )}
                        <div className="relative flex items-start space-x-3">
                            <div className="relative">
                                <div className={'h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center'}>
                                    <div className={'h-4 w-4 rounded-full bg-white'}/>
                                </div>
                            </div>
                            <div className="min-w-0 flex-1">
                                <div className='flex justify-between items-center'>
                                    <div>
                                        <p className="mt-0.5 text-sm text-tm-gray-500">
                                            User updated field <span className={'font-bold text-tm-gray-900'}>"{translate(`field.${item.Field}`)}"</span>.
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-2 text-sm grid grid-cols-5">
                                    <div className="col-span-2 rounded-md bg-red-50 p-2 text-center w-full inline-block border-solid border-2 border-red-800">
                                        <h3 className={`text-sm font-medium text-red-800  flex justify-center overflow-hidden`}>{processData(item, 'OldValue', fieldTypes[item.Field], translate)}</h3>
                                    </div>
                                    <div className="col-span-1 flex justify-center items-center">
                                        <ChevronDoubleRightIcon className="w-5 h-5"/>
                                    </div>
                                    <div className="col-span-2 rounded-md bg-green-50 p-2 text-center w-full inline-block border-solid border-2 border-green-800">
                                        <h3 className={`text-sm font-medium text-green-800 flex justify-center overflow-hidden`}>{processData(item, 'NewValue', fieldTypes[item.Field], translate)}</h3>
                                    </div>
                                </div>
                                <div className="w-full separator pt-5"/>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })

    return (
        <div className="relative">
            <header
                className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900">
                <h2 className="text-lg text-current truncate">{title}</h2>

                <button
                    className="absolute right-5 top-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary rounded-full"
                    aria-label="Close"
                    onClick={handleClose}>
                    <XMarkIcon className="w-5 h-5"/>
                </button>
            </header>

            <div className={classNames("")}>
                {isLoading && (
                    <div className="text-center p-5">
                        <LoaderSmall/>
                    </div>
                )}
                <div className="">
                    {selectedView === 'table' && (
                        <TableCard addClass={'relative h-dialog-body'}>
                            <TableFilters
                                filterFields={queryFilterFields}
                                handleInputChange={handleFilterInputChange}
                                translate={translate}
                            />

                            <ResourceTable
                                data={data}
                                fields={tableFields}

                                translate={translate}
                                isLoading={isLoading}

                                limit={queryFilterFields.limit.value}

                                sort={query.sort}
                                sortBy={query.sortBy}
                                onSortChange={handleUpdateSort}

                                onRowClick={onRowClick}
                            />

                            {/*Table footer*/}
                            {!(!data.length && !isLoading) && (
                                <div
                                    className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                >
                                    <Pagination
                                        count={count}
                                        isLoading={isLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === "offset"
                                                ? handleUpdateOffset(value, currentPage)
                                                : handleFilterInputChange(name, value)
                                        }
                                        pageOffset={query.offset}
                                        queryFields={queryFilterFields}
                                        translate={translate}
                                        hideRowsPerPage
                                    />
                                </div>
                            )}

                            <NoRecordsTable
                                show={(data.length === 0) && !isLoading}
                                title={'No matching records found'}
                            />
                        </TableCard>
                    )}

                    {selectedView === 'timeline' && !isLoading && (
                        <div>
                            <TableFilters
                                filterFields={queryFilterFields}
                                handleInputChange={handleFilterInputChange}
                                translate={translate}
                                hideLimit
                            />
                            <div className={'grid grid-cols-4'}>
                                <div className={'px-4 py-5 col-span-1 max-h-[calc(100vh-16rem)] overflow-auto'}>
                                    {dates}
                                </div>

                                <div className={'border-l border-gray-200 px-4 py-5 col-span-3 max-h-[calc(100vh-16rem)] overflow-auto'}>
                                    <ul role="list" className="-mb-8 list-none">
                                        {currentDataHtml}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <ModalFooter
                closeButtonLabel={translate('btn.close')}
                onClose={handleClose}
            >
                {!!data.length && (
                    <div className="">
                        <Tippy content={translate('text.timeline_view')}>
                            <button
                                onClick={() => handleSetSelectedView('timeline')}
                                className={classNames(selectedView === 'timeline' ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', "h-full rounded-l-input relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                            >
                                <AdjustmentsVerticalIcon className="h-5 w-5"/>
                            </button>
                        </Tippy>
                        <Tippy content={translate('text.table_view')}>
                            <button
                                onClick={() => handleSetSelectedView('table')}
                                className={classNames(selectedView === 'table' ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', "h-full rounded-r-input  relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                            >
                                <Bars4Icon className="h-5 w-5"/>
                            </button>
                        </Tippy>
                    </div>
                )}
            </ModalFooter>
        </div>
    )
}

export default ModalHistory
