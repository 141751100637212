import React, {Component} from "react";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import {classNames, getProp} from "../../../util/util-helpers";
import {copyToClipboard, dirtyClone, scrollErrorIntoView} from "../../../util/util-vanilla";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";
import ModalFooter from "../../modal/modal-footer";

export default class FormEmailUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            fieldsEmail: this.getEmailFields(),
            isFormVisible: true,
            dirty: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.thirdResource.isLoading !== this.props.thirdResource.isLoading && !this.props.thirdResource.isLoading) {
            const item = getProp(this.props.thirdResource, "data", {});

            this.setState({
                fieldsEmail: this.getEmailFields(item),
                isFormVisible: true
            })
        }

        if (!prevState.fields.sharedUrl.metadata.copied && !!this.state.fields.sharedUrl.metadata.copied) {
            setTimeout(() => {
                let fields = dirtyClone(this.state.fields);
                fields.sharedUrl.metadata.copied = false;
                this.setState({
                    fields
                })
            }, 1000);
        }
    };

    /** Data Events
     ================================================================= */
    fetchEmailTemplate = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get("user"),
            query: {
                LoadID: this.props?.infoData?.LoadID,
                EmailTemplateID: this.state.fields.EmailTemplateID.value?.value
            },
            resource: Resources.EmailTemplatesParse
        }))

        this.setState({
            isFormVisible: false
        });
    };

    handleInputChange = (name, value) => {
        let fields = dirtyClone(this.state.fields);
        if (name === "sharedUrl") {
            fields.sharedUrl.metadata.copied = true;
            copyToClipboard(value)
        }

        if ("AddEmailFromContacts" === name) {
            let emails = fields.emails.value;
            if (!Array.isArray(emails)) {
                emails = [];
            }
            if (!emails.some(it => it.value === value.value)) {
                emails.push({
                    "label": value.value,
                    "value": value.value,
                    "__isNew__": true
                });
            }

            fields.emails.value = emails;
            value = null
        }

        this.setState({
            fields: FieldsManager.updateField(fields, name, value),
            dirty: true
        }, () => name === "EmailTemplateID" ? this.fetchEmailTemplate(value) : null);
    };

    handleEmailInputChange = (name, value) => {
        this.setState(
            {fieldsEmail: FieldsManager.updateField(this.state.fieldsEmail, name, value), dirty: true});
    };

    /** Helper functions
     ================================================================= */
    getID = () => {
        return this.props.id;
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            sharedUrl: new Field("sharedUrl", this.props.url, [], false, 'link', {}),
            EmailTemplateID: new Field("EmailTemplateID", '', [''], false, 'select-search'),
            AddEmailFromContacts: new Field("AddEmailFromContacts", '', [], false, 'select-search'),
            emails: new Field("emails", '', ['empty'], false, 'creatable', {}, {isMulti: true}),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        let data = fillFieldsFromData(fieldTemplates, item);

        if (this?.props?.emailPrefill) {
            data.emails.value = Object.values(this.props.emailPrefill).map(it => {
                it = {label: it.Email, value: it.Email}
                return it;
            });
        }

        return data;
    };

    getEmailFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            subject: new Field("subject", '', ['empty'], false, 'text'),
            content: new Field("content", '', [], false, 'rich-text'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleOptionClick = (it) => {
        this.props.toggleLinkOption(it);
    }

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        let validatedEmailFields = FieldsManager.validateFields(this.state.fieldsEmail);
        validatedFields = this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;
        validatedEmailFields = this.props.validateFields ? this.props.validateFields(validatedEmailFields) : validatedEmailFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields) && FieldsManager.checkFieldsForErrors(validatedEmailFields)) {
            return Object.assign(FieldsManager.getFieldKeyValues(validatedFields), FieldsManager.getFieldKeyValues(validatedEmailFields));
        } else {
            this.setState({
                fields: validatedFields,
                fieldsEmail: validatedEmailFields
            });

            scrollErrorIntoView(validatedFields);
        }
    }

    render() {
        const {translate} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            EmailTemplateID: {
                api: 'api/' + Resources.EmailTemplates,
                query: {},
                searchMap: (item) => ({
                    value: item.EmailTemplateID,
                    label: item.EmailTemplateName
                })
            },
            AddEmailFromContacts: {
                api: 'api/' + Resources.Contacts,
                query: {},
                searchMap: (item) => ({
                    value: item.Email,
                    label: item.FirstName + " " + item.LastName + " - " + item.Email,
                })
            }
        });

        const emailFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fieldsEmail)), translate, this.handleEmailInputChange);

        const linkOptions = getProp(this.props, "linkOptions", []).map((item, i) => {
            const isDisabled = !!i && !getProp(this.props, "linkOptions", [])?.[0]?.value;

            return (
                <button
                    key={item.name}
                    disabled={isDisabled}
                    className={classNames(
                        "flex  items-center p-2 transition duration-150 ease-in-out rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-tint focus-visible:ring-opacity-50",
                        item.value ? 'bg-green-600/10 text-green-600' : 'hover:bg-tm-gray-100',
                        item.value && !isDisabled ? "hover:bg-green-600/30" : undefined,
                        !isDisabled ? "" : "bg-stripes opacity-50"
                    )
                    }
                    onClick={() => this.handleOptionClick(item.name)}
                >
                    <div
                        className={classNames("flex items-center justify-center flex-shrink-0 w-10 h-10 sm:h-8 sm:w-8")}>

                        {item.value
                            ? <CheckCircleIcon className="h-5 w-5" aria-hidden="true"/>
                            : <XCircleIcon className="h-5 w-5" aria-hidden="true"/>
                        }
                    </div>
                    <div className="ml-4 text-left">
                        <p className="text-sm font-medium text-tm-gray-900">
                            {translate("text." + item.name)}
                        </p>
                        <p className="text-sm text-gray-500">
                            {item.description}
                        </p>
                    </div>
                </button>
            )
        })

        return (
            <React.Fragment>
                <div className="p-6 h-[calc(100vh-12rem)] overflow-auto">
                    <div className="w-full">
                        <dt className="mb-2 text-tm-gray-900">Shared link options</dt>

                        <dd>
                            <div className="grid md:grid-cols-2 gap-4 mb-3">
                                {linkOptions}
                            </div>
                        </dd>
                    </div>

                    <div className="grid gap-4 grid-cols-1 pt-6">
                        {fieldsHtml}
                        {this.state.isFormVisible && emailFieldsHtml}
                    </div>
                </div>

                <ModalFooter
                    buttonLabel={translate("btn.send_email")}
                    buttonDisabled={!this.state.dirty}
                    onButtonClick={() => {
                        this.props.onSubmit(this.getValidFields())
                    }}
                    onClose={this.props.onClose}
                    closeButtonLabel={translate("btn.close")}
                />
            </React.Fragment>
        )
    }
}
