import React, {useEffect, useState} from 'react';
import ModalDefault from "../../../../common/components/modal/modal-default";
import {
    BanknotesIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    DocumentTextIcon,
    InformationCircleIcon
} from "@heroicons/react/24/outline";
import {CalendarDaysIcon} from "@heroicons/react/20/solid";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import ReviewLoanModalInfoTab from "./review-loan-modal-info-tab";
import {createDialogResource, deleteDialogResource, getDialogResource} from "../../../../data/actions/dialogResource";
import ReviewLoanModalScheduledPaymentsTab from "./review-loan-modal-scheduled-payments-tab";
import ReviewLoanModalActualPaymentsTab from "./review-loan-modal-actual-payments-tab";
import ReviewLoanModalLinksTab from "./review-loan-modal-links-tab";
import {Field} from "../../../../data/services/fields";
import {checkPerm, getProp} from "../../../../common/util/util-helpers";
import {READ_PERM} from "../../../../util/util-constants";
import CommentsDialogTab from "../../../../common/components/tabs/comments-tab";
import DocumentsList from "../../../../common/components/tabs/documents-list";
import NavigationTabsSidebar from "../../../../common/components/navigation/navigation-tabs-sidebar";

export default function ReviewLoanModal({show, selectedItem, cachedChangedFields, translate, onClose}) {

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(() => getTabs());
    const resource = useSelector((state) => state.dialogResource)

    /** Constants
     ================================================================= */
    const dispatch = useDispatch();
    const currentTab = tabs.find(tab => tab.isCurrent);

    const comments = getProp(resource, 'data.Comments', [])
    const commentCount = comments.length

    /** Data events
     ================================================================= */
    function fetchData() {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ExpenseExpense,
            query: {
                ExpenseID: selectedItem?.ExpenseID
            }
        }))
    }

    /** UI events
     ================================================================= */
    function handleTabClick(tab) {
        let tabsUpdate = [...tabs];
        tabsUpdate = tabsUpdate.map(it => {
            it.isCurrent = it.id === tab.id;

            return it;
        })

        setTabs(tabsUpdate);
    }

    /** Helpers
     ================================================================= */
    function getTabs() {
        return [
            {
                id: 'info',
                name: 'info',
                icon: InformationCircleIcon,
                isCurrent: true
            },
            {
                id: 'scheduled_payments',
                name: 'scheduled_payments',
                icon: CalendarDaysIcon,
            },
            {
                id: 'actual_payments',
                name: 'actual_payments',
                icon: BanknotesIcon
            },
            {
                id: 'documents',
                name: 'documents',
                hasPerm: checkPerm(Resources.AccountingExpenseDocumentsAdditional, READ_PERM),
                icon: DocumentTextIcon
            },
            {
                id: 'reference_items',
                name: 'reference_items',
                icon: ClipboardDocumentIcon
            },
            {
                id: 'comments',
                name: 'COMMENTS',
                hasPerm: checkPerm(Resources.AccountingExpenseBillComment, READ_PERM),
                icon: ChatBubbleOvalLeftEllipsisIcon
            }
        ];
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (show) {
            fetchData();
        } else {
            setTabs(getTabs());
        }
    }, [show]);

    /** Render
     ================================================================= */
    return <>
        <ModalDefault
            show={show}
            widthClass={'max-w-full'}
            title={translate("text.loan")}
            closeButtonLabel={translate('btn.close')}
            onClose={onClose}
        >
            <div className="grid grid-cols-5">
                <div className="pr-4 py-5 col-span-1">
                    <NavigationTabsSidebar
                        tabs={tabs}
                        onTabClick={handleTabClick}
                        translate={translate}
                        commentCount={commentCount}
                    />
                </div>
                <div
                    className="border-l border-tm-gray-300 col-span-4 max-h-[calc(100vh-20rem)] overflow-y-scroll text-tm-gray-900"
                >
                    {currentTab.id === 'info' && (
                        <ReviewLoanModalInfoTab
                            selectedItem={selectedItem}
                            translate={translate}
                        />
                    )}

                    {currentTab.id === 'scheduled_payments' && (
                        <ReviewLoanModalScheduledPaymentsTab
                            cachedChangedFields={cachedChangedFields}
                            translate={translate}
                        />
                    )}

                    {currentTab.id === 'actual_payments' && (
                        <ReviewLoanModalActualPaymentsTab
                            translate={translate}
                        />
                    )}

                    {currentTab.id === 'comments' && (
                        <CommentsDialogTab
                            Comments={comments}
                            commentsStoreResource={'dialogResource'}
                            commentsResourceName={Resources.AccountingExpenseBillComment}
                            parentResourceName={Resources.ExpenseExpense}
                            translate={translate}
                            primaryKey={'ExpenseID'}
                            selectedItem={selectedItem}
                            createDispatchAction={createDialogResource}
                            deleteDispatchAction={deleteDialogResource}
                        />
                    )}

                    {currentTab.id === 'documents' && (
                        <DocumentsList
                            id={selectedItem?.ExpenseID}
                            tableTopBarClass="px-6 py-1"
                            deleteDocumentKey={"DocumentID"}
                            storeResource={"thirdResource"}
                            //primarySortKey={"CreateUpdateDate"}
                            disableEdit={false}
                            hasUpdatePerm={true}
                            documentsResource={Resources.AccountingExpenseDocumentsAdditional}
                            downloadDocumentResource={Resources.AccountingExpenseDocumentsAdditional}
                            locationPath={"expenses-liabilities-documents-tab"}
                            // primaryDocumentKey={"WorkOrderID"}
                            getDocumentUploadFields={(item) => {
                                return {
                                    "ExpenseID": new Field('ExpenseID', selectedItem?.ExpenseID ?? '', ['empty'], false, 'hidden'),
                                    "Description": new Field('Description', item?.Description ?? '', [''], false, 'textarea', {addContainerClass: "col-span-full"})
                                }
                            }}
                            getDocumentTableFields={() => {
                                return {
                                    "DocumentName": new Field('DocumentName', '', [''], false),
                                    "Description": new Field('Description', '', [''], false),
                                    "CreateUpdateDate": new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
                                    "UploadedBy": new Field('UploadedBy', '', [''], false, 'custom', {
                                        render: (item) => {
                                            return (<div>{item.FirstName} {item.LastName}</div>)
                                        },
                                        omitSort: true
                                    })
                                }
                            }}
                            onGetQueryFields={() => {
                                return {
                                    "searchFields": new Field('searchFields', JSON.stringify({ExpenseID: selectedItem?.ExpenseID}), [''], false, 'hidden'),
                                }
                            }}
                            translate={translate}
                        />
                    )}

                    {currentTab.id === 'reference_items' && (
                        <ReviewLoanModalLinksTab
                            selectedExpenseItem={selectedItem}
                            translate={translate}
                        />
                    )}
                </div>
            </div>
        </ModalDefault>
    </>
}