import DisplayDataGrid from "../../../display-data/display-data-grid";
import ResourceTable from "../../../resource-table";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import Loader from "../../../loader/loader";
import NoRecordsTable from "../../../no-records-found/no-records-table";
import FieldSearch from "../../../fields/field-text/search";
import {getDialogResource} from "../../../../../data/actions/dialogResource";
import {getContactResource} from "../../../../../data/actions/contactResource";
import Resources from "../../../../../data/services/resources";
import {TASK_GROUP_MEMBER_ID} from "../../../../../util/util-constants";
import {cloneDeep} from "../../../../util/util-vanilla";
import ModalDefault from "../../modal-default";
import {Field} from "../../../../../data/services/fields";
import {showModal} from "../../../../../data/actions/ui";
import Pagination from "../../../resource-table/table-components/pagination";
import TableCardFooter from "../../../resource-table/table-components/table-card-footer";

export default function BoardViewDialog({isDialogOpen, TaskGroupID, onClose, translate}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const contactResource = useSelector((state) => state.contactResource);
    const dialogResource = useSelector((state) => state.dialogResource);

    /** State
     ================================================================= */
    const [contactInputValue, setContactInputValue] = useState('');
    const [contactList, setContactList] = useState([]);
    const [contactListLimit, setContactListLimit] = useState(10); // Selecting limit can be added
    const [contactListOffset, setContactListOffset] = useState(0);
    const [contactListCount, setContactListCount] = useState(0);

    /** Data events
     ================================================================= */
    const fetchBoardData = () => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.BoardTasks,
            query: {searchFields: JSON.stringify({TaskGroupID: TaskGroupID})}
        }))
    }

    // TaskGroupID
    const fetchGroupContactsData = () => {
        dispatch(getContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskGroupMembers,
            query: {
                GroupMemberTypeID: TASK_GROUP_MEMBER_ID,
                TaskGroupID: TaskGroupID
            }
        }))
    }

    /** UI events
     ================================================================= */
    const handleQueryInputChange = (name, value) => {
        if (name === 'offset') {
            setContactListOffset(value);
        }
    }

    const handleContactQueryChange = (_, value) => {
        setContactInputValue(value);
        const results = [];

        if (!!contactResource?.data?.list?.length) {
            contactResource.data.list.forEach(it => {
                if (
                    it.Contact.toLowerCase().search(value.toLowerCase()) > -1
                    // || it.Email.toLowerCase().search(value.toLowerCase()) > -1
                ) {
                    results.push(it)
                }
            })
        }

        setContactList(results);
        setContactListCount(results.length);
        setContactListOffset(0);
    }

    /** UI events
     ================================================================= */
    useEffect(() => {
        if (contactResource?.data && !contactResource?.isLoading && !!contactResource.data?.list) {
            setContactList(cloneDeep(contactResource.data.list));
            setContactListCount(contactResource.data.count);
        }
    }, [contactResource]);

    useEffect(() => {
        if (isDialogOpen) {
            fetchGroupContactsData();
            fetchBoardData();
        }
    }, [isDialogOpen]);

    /** Render
     ================================================================= */
    const boardData = dialogResource?.data?.list?.find(it => TaskGroupID === it.TaskGroupID);

    const tableData = cloneDeep(contactList).slice(contactListOffset, contactListOffset + contactListLimit);

    return (
        <ModalDefault
            show={isDialogOpen}
            title={translate("dialog_heading.task_group_details")}
            widthClass={"max-w-3xl w-screen"}
            hideDialogFooter={true}
            onClose={onClose}
            closeButtonLabel={translate("btn.close")}
            translate={translate}
        >
            <div className="relative">
                {dialogResource.isLoading && (
                    <Loader stripesBg/>
                )}
                <DisplayDataGrid
                    addGridClass={"grid-cols-3 border-b border-tm-gray-300 p-5 text-tm-gray-700 font-bold"}
                    displayList={Object.values({
                        TaskGroupName: new Field('TaskGroupName', '', [''], false),
                        TaskGroupDesc: new Field('TaskGroupDesc', '', [''], false, '', {addContainerClass: "col-span-2"}),
                    })}
                    data={boardData ?? {}}
                    translate={translate}
                />
            </div>

            <div className="py-2 px-4 bg-tm-gray-50 border-b border-tm-gray-300">
                <div className="max-w-full w-72">
                    <FieldSearch
                        placeholder={translate("text.search")}
                        addClass="form-control form-group"
                        value={contactInputValue}
                        onChange={handleContactQueryChange}
                    />
                </div>
            </div>

            <ResourceTable
                commonTable={true}
                data={tableData}
                count={contactListCount}

                fields={{
                    Contact: new Field('Contact', '', [''], false),
                    // Email: new Field('Email', '', [''], false),
                }}
                translate={translate}
                isLoading={contactResource?.isLoading}

                onRowClick={(it) => dispatch(showModal('ViewContactCard', {ContactID: it.ContactID}))}
                onView={(it) => dispatch(showModal('ViewContactCard', {ContactID: it.ContactID}))}
            />

            <NoRecordsTable
                className={"pt-10 pb-4"}
                show={(tableData.length === 0) && !contactResource.isLoading}
                canCreate={false}
                title={contactInputValue ? translate("text.no_matching_records") : translate("text.no_contacts_in_group")}
            />

            <TableCardFooter>
                <Pagination
                    count={contactListCount}
                    isLoading={contactResource?.isLoading}
                    handleQueryChange={handleQueryInputChange}
                    pageLimit={contactListLimit}
                    pageOffset={contactListOffset}
                    translate={translate}
                />
            </TableCardFooter>
        </ModalDefault>
    )
}