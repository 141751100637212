import React from 'react'
import Resources from '../../../../data/services/resources'
import Env from '../../../../util/env'
import { Field } from "../../../../data/services/fields";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../../common/util/util-helpers";
import DataCard from "../../../../common/components/display-data/data-card";
import {excludeFields, includeFields} from "../../../../common/util/util-fields";
import {getJWT} from "../../../../common/util/util-auth";

const DivisionInfoDialog = ({selectedItem, translate}) => {
    let basicFields = {
        AddressName: new Field('AddressName', '', [''], false, "address", {icon: () => MapPinIcon}),
        PhoneNumber: new Field('PhoneNumber', '', [''], false, "phone", {icon: () => PhoneIcon}),
        Description: new Field('Description', '', [''], false, "textarea", {addContainerClass: "pt-6"}),
    };

    let groupOneFields = {
        SCAC: new Field('SCAC', '', [''], false, "text"),
        MC: new Field('MC', '', [''], false, "text"),
    };

    let moreFields = {
        BankName: new Field('BankName', '', [''], false, "text"),
        RoutingNumber: new Field('RoutingNumber', '', [''], false, "text"),
        AccountName: new Field('AccountName', '', [''], false, "text"),
        AccountNumber: new Field('AccountNumber', '', [''], false, "text"),
    };

    groupOneFields = Object.values(groupOneFields);
    moreFields = Object.values(moreFields);

    return (
        <React.Fragment>
            <div className="h-dialog-body bg-tm-gray-50 text-tm-gray-900">
                <div className="max-w-md mx-auto py-8">
                    <div className="sm:flex items-center space-x-4 mb-6">
                        {selectedItem?.ImagePath && (
                            <div className="flex-shrink-0">
                                <img
                                    className="h-24 w-24 bg-inverse flex items-center justify-center"
                                    src={Env.getApiUrl('api/' + Resources.DivisionsImage, {
                                        token: getJWT().access_token,
                                        id: selectedItem.DivisionID
                                    })}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null // prevents looping
                                        currentTarget.src = '/images/avatar-light.png'
                                    }}
                                    alt=""
                                />
                            </div>
                        )}

                        <div className="relative w-full">
                            <div className="text-2xl font-bold mr-6 text-tm-gray-900 flex">
                                {selectedItem.DivisionName}
                            </div>
                        </div>
                    </div>

                    <div className="space-y-2">
                        {Object.values(excludeFields(basicFields, ['Description'])).filter(it => selectedItem[it.name] !== undefined && it.type !== 'hidden').map(field => {
                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={selectedItem}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>

                    <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card mt-6">
                        {groupOneFields.filter(it => selectedItem[it.name] !== undefined).map((field, i) => {
                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={selectedItem}
                                    className={classNames("px-3 py-1.5 flex items-center px-6", i < groupOneFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>

                    <div className="space-y-2">
                        {Object.values(includeFields(basicFields, ['Description'])).filter(it => selectedItem[it.name] !== undefined && it.type !== 'hidden').map(field => {
                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={selectedItem}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>

                    <p className="text-lg font-bold truncate text-tm-gray-900 mt-6 mb-2">
                        {translate("text.accounting")}
                    </p>

                    <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                        {moreFields.filter(it => selectedItem[it.name] !== undefined).map((field, i) => {
                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={selectedItem}
                                    className={classNames("px-3 py-1.5 flex items-center px-6", i < moreFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DivisionInfoDialog
