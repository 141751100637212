import React, {useEffect, useState} from "react";
import {getPublicResource} from "../../../data/actions/publicResource";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../data/services/resources";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import FleetShareTable from "../../../common/components/command-center-table/fleet-share-table";

export default function PublicSchedulerView({match, translate}) {
    const dispatch = useDispatch();
    const resource = useSelector(state => state.publicResource);
    const [isInfoVisible, setIsInfoVisible] = useState(true);

    const fetchData = () => {
        dispatch(getPublicResource({
            resource: Resources.SchedulerSharePublic,
            query: {
                ExternalAccessToken: match.params.token
            }
        }));
    }

    function handleCloseInfoClick() {
        setIsInfoVisible(false);
    }

    // function fetchUpdateData() {
    //     dispatch(getSecondResource({
    //         user: LocalStorage.get("user"),
    //         resource: Resources.UnitsSchedule,
    //         query: {}
    //     }));
    // }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="p-4 bg-tm-gray-100 min-h-screen flex flex-col space-y-2 h-screen">
            <div className="xl:flex gap-4">
                <h1
                    className="text-2xl shrink-0"
                >
                    Fleet share
                </h1>

                {!isInfoVisible && (
                    <button
                        onClick={() => setIsInfoVisible(true)}
                        className="btn text-primary relative -top-2"
                    >
                        <InformationCircleIcon className="w-5 h-5"/>
                    </button>
                )}
            </div>

            {resource?.data?.ExternalNotesCustomer && isInfoVisible && (
                <div className="w-full relative">
                    <InfoBar>
                        <div className="whitespace-pre-wrap w-full">
                            <button
                                onClick={handleCloseInfoClick}
                                className="btn btn-close ml-1 float float-right bg-transparent"
                            >
                                <XMarkIcon className="w-5 h-5"/>
                            </button>

                            {resource?.data?.ExternalNotesCustomer}
                        </div>
                    </InfoBar>
                </div>
            )}

            <FleetShareTable
                list={resource?.data?.list ?? []}
                comments={resource?.data?.comments ?? []}
                isLoading={resource.isLoading}
                onFetchData={fetchData}
                translate={translate}
            />
        </div>
    )
}


