import React, {useRef, useEffect, useState} from 'react';
import { classNames } from '../../../util/util-helpers';

export default function ColumnWidthAdjuster({onSetColumnWidth, onSaveColumnWidth, headerKey, headersRefs}) {
    const cursorXPosition = useRef(0);
    const startingElementWidth = useRef(0);
 
    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleMouseDown = (e) => {
        e.preventDefault();
        cursorXPosition.current = e.clientX;
        setIsMouseDown(true);
    }

    useEffect(() => {
        const resize = (e) => {
            let mouseMoveXDelta = e.clientX - cursorXPosition.current;
            if (Number(startingElementWidth.current) + Number(mouseMoveXDelta) >= 50) {
                onSetColumnWidth(Number(startingElementWidth.current) + Number(mouseMoveXDelta), Number(mouseMoveXDelta));
            } 
        }

        const handleMouseUp = () => {
            setIsMouseDown(false);
            onSaveColumnWidth();
        }

        if (isMouseDown) {
            document.addEventListener("mousemove", resize, false);
            document.addEventListener("mouseup", handleMouseUp, false);
            startingElementWidth.current = headersRefs.current?.[headerKey]?.offsetWidth;
        } else {
            document.removeEventListener("mousemove", resize, false);
            document.removeEventListener("mouseup", handleMouseUp, false);
        }

        return () => {
            document.removeEventListener("mousemove", resize, false);
            document.removeEventListener("mouseup", handleMouseUp, false); 
        }
    }, [isMouseDown])

    return (

        <div 
            className={
                classNames(
                    'z-20 flex justify-end w-1 h-6 absolute right-0.5 top-1.5 cursor-col-resize rounded-xl hover:bg-tm-gray-400',
                    isMouseDown ? "bg-primary" : "bg-tm-gray-300"
                )
            }

            onMouseDown={handleMouseDown}
        />

    )
}
