import {useEffect, useRef} from "react";

export default function TableObserver({tableContainerRef, onTableWidthChange, onTableHeightChange}) {
    const tableScrollWidthRef = useRef(0);
    const tableScrollHeightRef = useRef(0);
    const heightTimeoutRef = useRef();
    const widthTimeoutRef = useRef();

    useEffect(() => {


        let resizeTableHeightObserver;


        resizeTableHeightObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!entries?.length) {
                    return;
                }

                const width = entries[0].contentRect.width;
                const height = entries[0].contentRect.height;

                if (width !== tableScrollWidthRef.current) {
                    if (widthTimeoutRef.current) {
                        clearTimeout(widthTimeoutRef.current);
                    }

                    widthTimeoutRef.current = setTimeout(() => {
                        if (tableContainerRef.current) {
                            onTableWidthChange(!!(tableContainerRef.current.scrollWidth - tableContainerRef.current.clientWidth));
                        }
                    }, 500);
                    tableScrollWidthRef.current = width;
                }

                if (height !== tableScrollHeightRef.current) {
                    if (heightTimeoutRef.current) {
                        clearTimeout(heightTimeoutRef.current);
                    }

                    heightTimeoutRef.current = setTimeout(() => {
                        if (tableContainerRef.current) {
                            onTableHeightChange(!!(tableContainerRef.current.scrollHeight - tableContainerRef.current.clientHeight));
                        }
                    }, 500);

                    tableScrollHeightRef.current = height;
                }
            });
        });


        if (tableContainerRef.current) {
            resizeTableHeightObserver.observe(tableContainerRef.current);
        }

        return () => {
            if (resizeTableHeightObserver) {
                resizeTableHeightObserver.disconnect();
            }
        }

    }, []);

    return null;
}
