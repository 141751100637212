import React, {useEffect, useRef, useState} from "react";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import {useDispatch} from "react-redux";
import {patchTableData} from "../../../../data/actions/tablesDataResource";
import {classNames} from "../../../util/util-helpers";

export default function MarkerLastRowVisited({
                                                 rowHeight,
                                                 lastVisitedMarkerHeight,
                                                 tableID,
                                                 onClearLastVisitedRowID,
                                                 tableContainerRef,
                                                 tableKey
                                             }) {
    const mouseEnterTimeoutRef = useRef();
    const markerRef = useRef();
    const [isMarkerExpanded, setIsMarkerExpanded] = useState(false);
    const dispatch = useDispatch();

    function handleMouseEnter() {
        mouseEnterTimeoutRef.current = void setTimeout(() => setIsMarkerExpanded(true), 1000);
    }

    function handleMouseLeave() {
        clearTimeout(mouseEnterTimeoutRef.current);
        setIsMarkerExpanded(false);
    }

    function handleRemoveClick() {
        clearTimeout(mouseEnterTimeoutRef.current);
        if (onClearLastVisitedRowID) {
            onClearLastVisitedRowID();
        } else {
            dispatch(patchTableData({key: tableID, data: {lastVisited: {[tableKey]: 0}}}));
        }
    }

    useEffect(() => {
        if (tableContainerRef?.current && markerRef.current && lastVisitedMarkerHeight) {
            tableContainerRef.current.scroll({
                top: lastVisitedMarkerHeight,
                behavior: "smooth",
            });
        }
    }, [lastVisitedMarkerHeight]);

    return <div
        ref={markerRef}
        style={{height: rowHeight, top: lastVisitedMarkerHeight}}
        className="absolute left-0 z-50 w-full pointer-events-none"
    >
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={classNames(
                "sticky z-50 left-0 h-full bg-primary overflow-hidden flex items-center justify-center pointer-events-auto",
                isMarkerExpanded ? "transition-width w-16 rounded-r-btn" : "w-2"
            )}
        >
            {isMarkerExpanded && (
                <span className="text-primary-contrast font-semibold px-2">Last visited</span>
            )}

            <button
                onClick={handleRemoveClick}
                className="absolute top-1 right-1 text-primary-contrast"
            >
                <XMarkIcon className="w-5 h-5"/>
            </button>
        </div>
    </div>
}