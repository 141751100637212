import React from "react";
import EmptyMilesDisplay from "./load-components/empty-miles";
import TotalMilesDisplay from "./load-components/total-miles";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_PICKUP_AND_DELIVERY,
    STOP_TYPE_STOP_BY
} from "../../../../util/util-constants";
import {getStopIcon} from "../load-utils";
import ArrowSmallUpIcon from "@heroicons/react/20/solid/ArrowSmallUpIcon";
import {classNames, getHighlightedFullAddressName, openInNewTab} from "../../../../common/util/util-helpers";
import ArrowSmallDownIcon from "@heroicons/react/20/solid/ArrowSmallDownIcon";
import ArrowsUpDownIcon from "@heroicons/react/20/solid/ArrowsUpDownIcon";
import {toFrontDate, toFrontDateTime} from "../../../../common/util/util-dates";
import {useDispatch} from "react-redux";
import moment from 'moment-timezone'
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import InfoParagraph from "../../../../common/components/info-paragraph";

export default function LoadInfoReadLocations({
                                                  TotalMiles,
                                                  disableTotalMilesClick,
                                                  TotalMilesNoStopBy,
                                                  EmptyMiles,
                                                  stopsCombined,
                                                  stops,
                                                  stopBys,
                                                  onStopClick,
                                                  loadedMilesText,
                                                  onCompleteStopClick,
                                                  isStopInfoVisible,
                                                  isLocationAssetsInfoVisible,
                                                  isLoadInternational,
                                                  translate
                                              }) {
    const dispatch = useDispatch();

    function getLocationAssetsInfo(data) {
        if (!isLocationAssetsInfoVisible) {
            return {};
        }

        let isVisible = false;

        if (data.IsDroppingTrailer || data.IsSwitchDispatch || data.PickingTrailerID) {
            isVisible = true
        }

        return {
            IsDroppingTrailer: data.IsDroppingTrailer,
            PickingTrailer: data.PickingTrailer,
            PickingTrailerID: data.PickingTrailerID,
            IsSwitchDispatch: data.IsSwitchDispatch,
            Driver: data.Driver,
            DriverID: data.DriverID,
            CoDriver: data.CoDriver,
            CoDriverID: data.CoDriverID,
            Truck: data.Truck,
            Trailer: data.Trailer,
            TruckID: data.TruckID,
            TrailerID: data.TrailerID,
            isVisible: isVisible
        }
    }

    const StopTag = onStopClick ? "button" : "div";


    return (<div className="mx-auto pt-8">
        <p className="text-lg leading-6 font-medium text-tm-gray-900 my-2">Locations</p>

        {!isLoadInternational && (
            <div className="mt-3 flex space-x-10 mb-4 ml-6">
                {Number(TotalMiles) === -2 && (
                    <InfoParagraph type="warning">
                        <p>{translate("text.can_not_calculate_miles_message_line_1")}</p>
                        <p className="font-bold text-tm-gray-900">{translate("text.can_not_calculate_miles_message_line_2")}</p>
                    </InfoParagraph>
                )}

                {Number(TotalMiles) !== -2 && (
                    <React.Fragment>
                        <TotalMilesDisplay
                            TotalMiles={TotalMiles}
                            TotalMilesNoStopBy={TotalMilesNoStopBy}
                            disableTotalMilesClick={disableTotalMilesClick}
                            loadedMilesText={loadedMilesText}
                            dispatch={dispatch}
                            translate={translate}
                        />

                        <EmptyMilesDisplay
                            EmptyMiles={EmptyMiles}
                            translate={translate}
                        />
                    </React.Fragment>
                )}
            </div>
        )}

        <div className="grid gap-4">
            {!stopsCombined.length && (
                <div className="py-8 border border-tm-gray-300 rounded-xl bg-inverse shadow">
                    <div className="flex flex-col items-center justify-center gap-2">
                        <MapPinIcon className="w-6 h-6 text-tm-gray-600"/>
                        <div className="text-lg">{translate("text.no_stops_on_load")}</div>
                    </div>
                </div>
            )}

            {stopsCombined.sort((a, b) => a?.metadata?.StopOrder - b?.metadata?.StopOrder).map((stop, i) => {
                let StopIcon;
                if (stop.StopType === STOP_TYPE_STOP_BY) {
                    StopIcon = getStopIcon(Number(stopBys[stop.index]?.StopByTypeID?.value))
                }

                const StopTime = toFrontDate(stops[stop.index]?.StopDate.value) + ' ' + stops[stop.index]?.StopTime.value;
                const StopEndTime = toFrontDate(stops[stop.index]?.StopEndDate.value) + ' ' + stops[stop.index]?.StopEndTime.value;

                const actualArrivalDateTime = stop?.metadata?.ActualArrivalDateTime;
                const actualDepartureDateTime = stop?.metadata?.ActualDepartureDateTime;
                const areSameDates = moment(actualArrivalDateTime, DEFAULT_DATABASE_DATETIME_FORMAT).isSame(moment(actualDepartureDateTime, DEFAULT_DATABASE_DATETIME_FORMAT), 'day')
                let actualDepartureTime = toFrontDateTime(stop.metadata.ActualDepartureDateTime).split(' ');
                actualDepartureTime.shift();

                const isStopCompleted = !!stop?.metadata?.IsStopCompleted;
                const palletsLoaded = stop?.metadata?.PalletsLoaded;
                const palletsUnLoaded = stop?.metadata?.PalletsUnLoaded;
                const signedByName = stop?.metadata?.SignedByName;

                actualDepartureTime = actualDepartureTime.join(" ");

                const locationAssetsInfo = getLocationAssetsInfo(stop.metadata);
                const isNotOnTime = stop?.metadata?.NotOnTime;

                return (
                    <div key={i}>
                        <StopTag
                            className={classNames(
                                "bg-inverse rounded-xl border-tm-gray-300 border shadow-card divide-y divide-tm-gray-300",
                                onStopClick ? "cursor-pointer hover:bg-tm-gray-50 overflow-hidden text-left w-full" : undefined
                            )}
                            onClick={() => !!onStopClick && onStopClick(stop, i)}
                        >
                            <div className="flex flex-column md:flex-row items-center py-3 px-6">
                                <div className="flex items-center">
                                    <div className="w-6 h-6 text-base font-bold text-center text-tm-gray-500 mr-2">
                                        {i + 1}.
                                    </div>

                                    <div className={
                                        classNames(
                                            "w-6 h-6 rounded-md flex-shrink-0 relative mr-4",
                                            stop.IsStopCompleted ? "bg-green-600 text-white" : undefined,
                                            !stop.IsStopCompleted && Number(stop.StopType) !== STOP_TYPE_STOP_BY
                                                ? "text-tm-gray-600 border-2 border-tm-gray-400"
                                                : undefined
                                        )
                                    }>
                                        {Number(stop.StopType) === STOP_TYPE_PICKUP && (
                                            <ArrowSmallUpIcon
                                                className={classNames("absolute inset-0")}/>
                                        )}

                                        {Number(stop.StopType) === STOP_TYPE_DELIVERY && (
                                            <ArrowSmallDownIcon
                                                className={classNames("absolute inset-0")}/>
                                        )}

                                        {Number(stop.StopType) === STOP_TYPE_PICKUP_AND_DELIVERY && (
                                            <ArrowsUpDownIcon
                                                className={classNames("absolute inset-0")}/>
                                        )}

                                        {Number(stop.StopType) === STOP_TYPE_STOP_BY && (
                                            <StopIcon
                                                className={
                                                    classNames(
                                                        "w-6 h-6 p-1 rounded-md text-white",
                                                        stop?.isStopCompleted ? "bg-red-600" : "bg-green-600"
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {getHighlightedFullAddressName(stop.StopType === STOP_TYPE_STOP_BY ? stop.metadata : stops[stop.index]?.LocationData?.value)}
                                    </div>
                                </div>

                                {isStopCompleted && !!onCompleteStopClick && stop.StopType !== STOP_TYPE_STOP_BY && (
                                    <button
                                        className="btn-icon md:ml-auto flex-shrink-0 text-green-600 font-bold flex items-center gap-2 bg-green-300/10 hover:bg-green-600/10 hover:text-green-600"
                                        onClick={() => onCompleteStopClick(stop, i)}
                                    >
                                        Stop completed - View details
                                        <InformationCircleIcon className="w-6 h-6"/>
                                    </button>
                                )}
                            </div>

                            {isStopInfoVisible && Number(stop.StopType) !== STOP_TYPE_STOP_BY && (
                                <div className="mt-1 text-sm text-tm-gray-900 sm:mt-0 sm:col-span-3">
                                    <div
                                        className={
                                            classNames(
                                                !stop?.metadata?.Notes ? "rounded-b-xl" : undefined,
                                                "md:pl-20 flex flex-col md:flex-row md:divide-y-0 divide-y md:divide-x divide-tm-gray-200 bg-inverse overflow-hidden"
                                            )
                                        }
                                    >
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">{translate("field.StopTimeDate")}</p>
                                            <div className="truncate text-tm-gray-900">
                                                <div className="text-right">
                                                    <div>{StopTime === " " ? "/" : StopTime}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">{translate("field.StopEndTimeDate")}</p>
                                            <div className="truncate text-tm-gray-900">
                                                <div className="text-right">
                                                    <div>{StopEndTime === " " ? "/" : StopEndTime}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">Time in / out</p>
                                            <div
                                                className={
                                                    classNames(
                                                        "truncate",
                                                       isNotOnTime ? "text-red-600" : "text-tm-gray-900"
                                                    )
                                                }
                                            >
                                                <div className="text-right">
                                                    {isStopCompleted && (
                                                        <div>{toFrontDateTime(stop.metadata.ActualArrivalDateTime)} / {areSameDates ? actualDepartureTime : toFrontDateTime(stop.metadata.ActualDepartureDateTime)}</div>
                                                    )}

                                                    {!isStopCompleted && (
                                                        <p>Stop is not yet completed</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">{stop?.metadata?.TotalUnitsType ?? "Units"} loaded
                                                / unloaded</p>
                                            <div className="truncate text-tm-gray-900">
                                                <div className="text-right">
                                                    {palletsLoaded ?? 0}
                                                    {" / "}
                                                    {palletsUnLoaded ?? 0}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">Signed by</p>
                                            <div className="truncate text-tm-gray-900">
                                                {signedByName}
                                            </div>
                                        </div>
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">{translate("field.ReferenceNumber")}</p>
                                            <div className="text-tm-gray-900 space-x-2">
                                                {stop?.metadata?.ReferenceNumber?.split(",")?.filter(it => !!it)?.map((it, i) => {
                                                    return <span key={i}
                                                                 className="bg-primary-transparent rounded-btn px-1 py-0.5">{it}</span>
                                                })}
                                            </div>
                                        </div>
                                        <div className="px-3 py-1 flex justify-between md:block">
                                            <p className="font-medium text-tm-gray-500">{translate("field.AptReferenceNumber")}</p>
                                            <div className="text-tm-gray-900 space-x-2">
                                                {stop?.metadata?.AptReferenceNumber?.split(",")?.filter(it => !!it)?.map((it, i) => {
                                                    return <span key={i}
                                                                 className="bg-primary-transparent rounded-btn px-1 py-0.5">{it}</span>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {locationAssetsInfo.isVisible && (
                                <div
                                    className="flex px-6 py-2 mt-1 text-sm text-tm-gray-900 sm:mt-0 sm:col-span-3 bg-primary-transparent">
                                    <InformationCircleIcon className="text-primary w-5 h-5 mr-1"/>
                                    <div className="space-y-2">
                                        {!!locationAssetsInfo.IsDroppingTrailer && (
                                            <p className="leading-5 text-sm px-3 text-tm-gray-900">Trailer was dropped
                                                at this
                                                location</p>
                                        )}

                                        {!!locationAssetsInfo.PickingTrailerID && (
                                            <div>
                                                <button
                                                    onClick={() => openInNewTab("/trailers/info/" + locationAssetsInfo.PickingTrailerID)}
                                                    className="leading-5 text-sm btn btn-text hover:bg-primary-transparent py-0 h-5"
                                                >
                                                    {locationAssetsInfo.PickingTrailer
                                                        ? translate("message.trailer_label_was_picked_at_location", [locationAssetsInfo.PickingTrailer])
                                                        : translate("message.trailer_was_picked_at_location")
                                                    }
                                                </button>
                                            </div>
                                        )}

                                        {!!locationAssetsInfo.DriverID && (
                                            <div>
                                                <button
                                                    onClick={() => openInNewTab("/drivers/info/" + locationAssetsInfo.DriverID)}
                                                    className="leading-5 text-sm btn btn-text hover:bg-primary-transparent py-0 h-5"
                                                >
                                                    {translate("message.driver_switched_at_location", [locationAssetsInfo.Driver])}
                                                </button>
                                            </div>
                                        )}

                                        {!!locationAssetsInfo.CoDriverID && (
                                            <div>
                                                <button
                                                    onClick={() => openInNewTab("/drivers/info/" + locationAssetsInfo.CoDriverID)}
                                                    className="leading-5 text-sm btn btn-text hover:bg-primary-transparent py-0 h-5"
                                                >
                                                    {translate("message.co_driver_switched_at_location", [locationAssetsInfo.CoDriver])}
                                                </button>
                                            </div>
                                        )}

                                        {!!locationAssetsInfo.TruckID && (
                                            <div>
                                                <button
                                                    onClick={() => openInNewTab("/trucks/info/" + locationAssetsInfo.TruckID)}
                                                    className="leading-5 text-sm btn btn-text hover:bg-primary-transparent py-0 h-5"
                                                >
                                                    {translate("message.truck_switched_at_location", [locationAssetsInfo.Truck])}
                                                </button>
                                            </div>
                                        )}

                                        {!!locationAssetsInfo.TrailerID && (
                                            <div>
                                                <button
                                                    onClick={() => openInNewTab("/trailers/info/" + locationAssetsInfo.TrailerID)}
                                                    className="leading-5 text-sm btn btn-text hover:bg-primary-transparent py-0 h-5"
                                                >
                                                    {translate("message.trailer_switched_at_location", [locationAssetsInfo.Trailer])}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {
                                Object.keys(stop).filter(it => (it === 'ReferenceNumber' || it === 'FromTo') && !!stop[it]).map(stopValue => {
                                    return (
                                        <div
                                            key={stop?.key}
                                            className="pl-[5.5rem] px-3 py-1.5 flex flex-col px-6 border-b border-tm-gray-300">
                                            <p className="font-bold leading-5">
                                                {stop[stopValue]}
                                            </p>

                                            <p className="text-tm-gray-500 text-xs leading-4">
                                                {translate("field." + stopValue)}
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </StopTag>
                    </div>
                )
            })}
        </div>
    </div>)
}
