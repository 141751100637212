import React, { Component } from 'react'
import { createResource, deleteResource, getResource } from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import { Field, FieldsManager } from '../../../data/services/fields'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import {CREATE_PERM, DELETE_PERM} from "../../../common/util/util-consts";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class DriverEndorsementsTab extends Component {
    constructor (props) {
        super(props)
        this.state = {
            canSubmit: false,
            id: null,
            items: [],

            addEndorsementModal: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount () {
        !!this.getID() && this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: {
                id: this.getID()
            }
        }))
    }

    /** UI Events
     ================================================================= */
    addNewEndorsement = fields => {
        if (this.getID()) {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(fields),
                    {
                        DriverID: this.getID()
                    }),
                query: {
                    id: this.getID()
                },
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true, successMessage: `Driver Endorsements has been created.`,
            }))
        } else {
            this.props.addNewEndorsement(fields)
        }
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }
    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            DriverEndorsementTypeID: new Field('DriverEndorsementTypeID', '', ['empty'], false, 'select', { addContainerClass: 'col-span-full' }),
            Notes: new Field('Notes', '', [], false, 'textarea', { addContainerClass: 'col-span-full' }),
        }
    }

    getFormattedPropValues = () => {
        const DriverEndorsementType = getLookup('DriverEndorsementType')
        return this.props.fields_endorsement.map((it, index) => {
            return {
                DriverEndorsementType: DriverEndorsementType[it.DriverEndorsementTypeID.value],
                DriverEndorsementTypeID: it.DriverEndorsementTypeID.value,
                Notes: it.Notes.value,
                id: index
            }
        })
    }

    getID = () => {
        return this.props.match.params.id
    }

    getResource = () => {
        return Resources.DriverEndorsements
    }

    render () {
        const { translate, resource } = this.props

        const data = !!this.getID() ? getProp(this.props, 'resource.data.list', []) : this.getFormattedPropValues()
        const count = !!this.getID() ? getProp(this.props, 'resource.data.count', []) : 0

        return (
            <React.Fragment>
                <PageHeader
                    title={translate('text.Endorsements')}
                    titleClass="mr-5 text-2xl ml-4 mb-4"
                    buttonLabel={translate('btn.create_new')}
                    onButtonClick={() => this.setState({ createModalOpen: true })}
                    hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                >
                    <button className="btn btn-header ml-auto mr-2" onClick={this.fetchData}>
                        <ArrowPathIcon className="w-5 h-5" />
                    </button>

                </PageHeader>

                <Card>
                    <TableCard>
                        <ResourceTable
                            addClass="rounded-card"
                            data={data}
                            count={count}

                            commonTable={true}

                            fields={this.getFields()}
                            translate={translate}
                            isLoading={resource.isLoading}

                            onDelete={checkPerm(this.getResource(), DELETE_PERM) && this.handleToggleConfirmModal}
                        />

                        <NoRecordsTable
                            show={data.length === 0 && !resource.isLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                            className={"pb-12 pt-16 px-6"}
                        />
                    </TableCard>
                </Card>

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('text.CreateNewDriverEndorsement')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    getRawFields
                    onSubmit={(params) => {
                        if (params) {
                            this.addNewEndorsement(params)
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('message.are_you_sure_delete_endorsements')}
                    onClose={() => this.handleToggleConfirmModal()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (!!this.getID()) {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    DriverEndorsementID: this.state.selectedItem?.DriverEndorsementID,
                                    id: this.getID()
                                },
                                errorMessage: true, successMessage: `Driver Endorsement  has been deleted`,
                                resource: this.getResource(),
                                piggyResource: this.getResource()
                            }))
                            this.handleToggleConfirmModal(false)
                        } else {
                            this.props.removeNewEndorsement(this.state.selectedItem['id'])
                            this.handleToggleConfirmModal(false)
                        }
                    }}
                />
            </React.Fragment>
        )
    }
}
