import {fieldsToHtml, includeFields} from "../../../common/util/util-fields";
import React from "react";
import moment from "moment";
import {classNames} from "../../../common/util/util-helpers";
import {FieldsManager} from "../../../data/services/fields";

export default function TasksDateTimePicker({typeIsStartDate = false, title, queryFields, updateQueryFields, onQueryChange, translate, offset}) {

    const startDateKey = typeIsStartDate ? 'StartStartDate' : 'DueStartDate'
    const endDateKey = typeIsStartDate ? 'StartEndDate' : 'DueEndDate'

    const setTimePeriod = (StartDate, EndDate) => {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        queryFieldsUpdate[startDateKey] = Object.assign({}, queryFieldsUpdate[startDateKey], {value: StartDate});
        queryFieldsUpdate[endDateKey] = Object.assign({}, queryFieldsUpdate[endDateKey], {value: EndDate});

        queryFieldsUpdate[startDateKey].props.maxDate = queryFieldsUpdate[endDateKey].value;
        queryFieldsUpdate[endDateKey].props.minDate = queryFieldsUpdate[startDateKey].value;

        if (queryFieldsUpdate[startDateKey].value && queryFieldsUpdate[endDateKey].value) {
            updateQueryFields(queryFieldsUpdate);
        }
    }

    const handleClearDateTimeClick = () => {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        queryFieldsUpdate[startDateKey].value = "";
        queryFieldsUpdate[endDateKey].value = "";

        queryFieldsUpdate[startDateKey].props.maxDate = '';
        queryFieldsUpdate[endDateKey].props.minDate = '';

        updateQueryFields(queryFieldsUpdate);
    }

    const setTimeSpan = (timeUnit, timeUnitType = 'day', operation = 'subtract') => {
        let fields = {...queryFields};
        let currentData = moment().format('YYYY-MM-DD HH:mm:ss');
        if (operation === 'subtract') {
            fields = FieldsManager.updateField(fields, startDateKey, moment().subtract(timeUnit, timeUnitType).format('YYYY-MM-DD HH:mm:ss'))
            fields = FieldsManager.updateField(fields, endDateKey, currentData)
        } else {
            fields = FieldsManager.updateField(fields, startDateKey, currentData)
            fields = FieldsManager.updateField(fields, endDateKey, moment().add(timeUnit, timeUnitType).format('YYYY-MM-DD HH:mm:ss'))
        }

        fields[startDateKey].props.maxDate = fields[endDateKey].value;
        fields[endDateKey].props.minDate = fields[startDateKey].value;

        updateQueryFields(fields);
    }

    const getPreviousMonths = () => {
        const currentYear = moment().startOf('year')
        return [...Array(12).keys()].map((it, i) => {
            let StartDate = moment().subtract(11 - it, 'month').startOf('month')
            const label = StartDate.format('MMM')
            StartDate = StartDate.format('YYYY-MM-DD HH:mm:ss')
            const EndDate = moment().subtract(11 - it, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')

            return (
                <button
                    key={i}
                    className={classNames(
                        'hover:bg-primary border-tm-gray-200 justify-center hover:text-white text-tm-gray-900 p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-2 flex-grow',
                        (moment(StartDate, 'YYYY-MM-DD HH:mm:ss').isBefore(currentYear)) ? 'bg-tm-gray-100' : '',
                        i !== 11 ? "border-r" : undefined
                    )}
                    onClick={() => setTimePeriod(StartDate, EndDate)}
                >
                    {label}
                </button>
            )
        })
    }

    const dateButtons = [
        {
            unit: 1,
            unitType: 'day',
            operation: 'subtract',
            label: translate('text.yesterday')
        },
        {
            unit: 1,
            unitType: 'day',
            operation: 'add',
            label: translate('text.tomorrow')
        },
        {
            unit: 3,
            unitType: 'day',
            operation: 'subtract',
            label: translate('text.last_3_days')
        },
        {
            unit: 3,
            unitType: 'day',
            operation: 'add',
            label: translate('text.next_3_days')
        },
        {
            unit: 7,
            unitType: 'day',
            operation: 'subtract',
            label: translate('text.last_7_days')
        },
        {
            unit: 7,
            unitType: 'day',
            operation: 'add',
            label: translate('text.next_7_days')
        },
        {
            unit: 1,
            unitType: 'month',
            operation: 'subtract',
            label: translate('text.last_30_days')
        },
        {
            unit: 1,
            unitType: 'month',
            operation: 'add',
            label: translate('text.next_30_days')
        }];

    return (
        <div className="col-span-full p-4 border border-tm-gray-200 rounded-card">
            <div className="flex items-center justify-between mb-3">
                <p className="text-base text-tm-gray-700">{title}</p>

                <button
                    className="btn btn-outline-secondary py-1"
                    onClick={handleClearDateTimeClick}
                    disabled={!queryFields[startDateKey].value && !queryFields[endDateKey].value}
                >
                    Clear dates
                </button>
            </div>

            <div className="grid grid-cols-12 gap-4">
                <div className="grid grid-cols-12 col-span-6">
                    {fieldsToHtml(Object.values(Object.assign({}, includeFields(queryFields, typeIsStartDate ? [ 'StartStartDate', 'StartStartTime', 'StartEndDate', 'StartEndTime'] : ['DueStartDate', 'DueStartTime', 'DueEndDate', 'DueEndTime']))), translate, onQueryChange)}
                </div>

                <div className="col-span-6">
                    <label
                        className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap mb-0.5">
                        {translate("text.quick_selection")}
                    </label>

                    <div className="grid gap-2 grid-cols-2">
                        {dateButtons.map((it, i) => (
                            <button
                                key={i}
                                onClick={() => setTimeSpan(it.unit, it.unitType, it.operation)}
                                className="btn btn-outline w-full justify-center h-6"
                            >
                                {it.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex border border-tm-gray-200 mt-4 rounded-md overflow-hidden">
                {getPreviousMonths()}
            </div>
        </div>
    )
}



