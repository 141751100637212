import Tippy from "@tippyjs/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon";
import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import React from "react";
import {fieldsToHtml} from "../../../util/util-fields";
import Resources from "../../../../data/services/resources";
import RichTextEditor from "../../fields/rich-text-editor";
import {LoaderSmall} from "../../loader";
import ModalFooter from "../../modal/modal-footer";

export default function KnowledgeBaseHelp({data, onSubmit, fields, setIsEditMode, onCancel, isEditMode, handleInputChange, updateEditorKey, isLoading, canSubmit, translate}) {
    const title = data?.list?.Title;

    const form = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, {
        HelpArticleParentID: {
            api: 'api/' + Resources.Help,
            query: {
                flat: 1
            },
            customizeList: (list) => {
                return list
                    .filter(it => it.Title !== data?.list?.Title)
                    .map(it => ({
                        label: it.Title,
                        value: it.HelpArticleID
                    }))
            }
        }
    });

    const hasContent = !!fields.Content.value && fields.Content.value.length === 1 && fields.Content.value[0].children[0].text === "";

    return (
        <React.Fragment>
            {!isEditMode && !isLoading && (
                <div className="">
                    <div
                        className="py-4 flex justify-between items-center px-6 mb-6 bg-tm-gray-50 border-b border-tm-gray-300"
                    >
                        <div className="relative">
                            <p className="absolute text-xs text-tm-gray-500 whitespace-nowrap font-bold -top-2">{translate("text.help_for")}</p>
                            <h1 className="text-xl relative top-2">{title}</h1>
                        </div>

                        {!hasContent && (
                            <Tippy
                                content={translate("btn.edit")}
                                delay={[400, 0]}
                                trigger={"mouseenter"}
                            >
                                <button
                                    className="rounded-btn flex items-center justify-center w-7 h-7 text-tm-gray-600 bg-inverse border border-tm-gray-300 hover:bg-tm-gray-200 hover:text-tm-gray-900 absolute right-6"
                                    onClick={() => setIsEditMode(true)}
                                >
                                    <PencilIcon
                                        className="h-5 w-5"/>
                                </button>
                            </Tippy>
                        )}
                    </div>

                    <div className='overflow-y-auto max-h-[calc(100vh-13rem)] px-6'>
                        {hasContent && (
                            <div className="text-center mt-8">
                                <div className="flex justify-center">
                                    <DocumentIcon className="w-20 h-20 text-tm-gray-300"/>
                                </div>

                                <h3 className="mt-2 text-base font-bold text-tm-gray-900">
                                    No content
                                </h3>

                                <p className="mt-1 text-sm text-tm-gray-500 max-w-xs mx-auto">
                                    Start creating some content by pressing the edit button.
                                </p>

                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className="btn btn-outline"
                                        onClick={() => setIsEditMode(true)}
                                    >
                                        <PencilIcon
                                            className="-ml-1 mr-2 h-5 w-5"
                                            aria-hidden="true"
                                        />

                                        {translate("btn.edit")}
                                    </button>
                                </div>
                            </div>
                        )}

                        {!isLoading && !!fields.Content.value && (
                            <RichTextEditor
                                key={updateEditorKey}
                                readOnly={true}
                                value={fields.Content.value}
                                placeholder={""}
                            />
                        )}

                        {!!isLoading && (
                            <LoaderSmall/>
                        )}
                    </div>
                </div>
            )}

            {isEditMode && !isLoading && (
                <div>
                    <div
                        className="py-4 flex items-center px-6 mb-6 bg-tm-gray-50 border-b border-tm-gray-300 relative">
                        <button
                            className="btn btn-icon left-3 absolute"
                            onClick={() => onCancel()}
                        >
                            <ChevronLeftIcon className="w-5 h-5"/>
                        </button>
                        <p className="text-xl pl-10">{translate("text.edit_help_for", [title])} </p>
                    </div>

                    <div className="px-6 grid gap-4">
                        {form}
                    </div>

                    <div className={"mt-6"}>
                        <ModalFooter
                            buttonLabel={translate("btn.update")}
                            buttonDisabled={!canSubmit}
                            onButtonClick={() => {
                                onSubmit()
                            }}
                            onClose={() => {
                                onCancel()
                            }}
                            closeButtonLabel={translate("btn.cancel")}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}