import React, {useEffect, useState} from "react";
import LoaderLarge from "../../loader/loader-large";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../util/localStorage";
import {getProp, openInNewTab} from "../../../util/util-helpers";
import {getContactResource} from "../../../../data/actions/contactResource";
import ModalDefault from "../modal-default";
import Resources from "../../../../data/services/resources";
import ContactCard from "../contact-card-dialog/contact-card";

export default function ContactInfoDialog({translate, contactID, show, handleClose}) {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.contactResource);
    const isLoading = getProp(resource, 'isLoading', false)

    const [data, setData] = useState({})

    const fetchData = () => {
        dispatch(getContactResource({
            user: LocalStorage.get("user"),
            resource: Resources.ContactsQuick,
            query: {id: contactID}
        }));
    }

    useEffect(() => {
        if (contactID) {
            fetchData()
        }
    }, [show])

    useEffect(() => {
        if (resource.data && resource.data.ContactID) {
            setData(resource.data)
        }
    }, [resource])

    const getContactSubtype = () => {
        if (data.DriverID) {
            return {
                route: 'drivers',
                key: 'DriverID'
            }
        } else if (data.EmployeeID) {
            return {
                route: 'employees',
                key: 'EmployeeID'
            }
        } else if (data.AgentID) {
            return {
                route: 'agents',
                key: 'AgentID'
            }
        } else {
            return null
        }
    }

    const handleViewDetails = () => {
        const {route, key} = getContactSubtype()
        openInNewTab(`${route}/info/${data[key]}`)
    }

    return (
        <ModalDefault
            show={show}
            title={translate("text.contact_info")}
            widthClass={"max-w-3xl"}
            translate={translate}
            buttonLabel={!!getContactSubtype() && translate("btn.view")}
            onButtonClick={handleViewDetails}
            onClose={handleClose}
            closeButtonLabel={translate("btn.close")}
        >
            <div className="bg-tm-gray-50 px-3 py-8 text-tm-gray-700 space-y-8 relative min-h-[27rem]">
                {isLoading && (
                    <LoaderLarge stripesBg/>
                )}

                {!isLoading && (
                    <ContactCard
                        userData={data}
                        translate={translate}
                        dispatch={dispatch}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </ModalDefault>
    );
}

