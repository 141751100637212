import React, {Component} from 'react'
import {connect} from 'react-redux'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import BillingDialog from '../dialogs/create-load-invoice-dialog'
import {Field, FieldsManager} from '../../../data/services/fields'
import Env from '../../../util/env'
import axios from 'axios'
import {download} from '../../../data/actions/download'
import Tippy from '@tippyjs/react'
import {DocumentTextIcon,} from '@heroicons/react/24/outline'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {createDataSelect, getDataSelect} from '../../../data/selectors/resourceSelectors'
import {getSecondDataSelect} from '../../../data/selectors/secondResourceSelectors'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import BillingBulkBatchModal from './billing-bulk-batch-modal'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultFieldOrder,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    openInNewTab,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon'
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import EmailDocumentDialog from "../../../common/components/modal/modal-email-documents/modal-email-document";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import {LoaderSmall} from "../../../common/components/loader";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import {getJWT} from "../../../common/util/util-auth";
import {excludeFields} from "../../../common/util/util-fields";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

class BillingView extends Component {
    constructor(props) {
        super(props)
        this.colors = ['#171219', '#225560', '#E9DF00', '#F0803C', '#FF66B3', '#084B83', '#42BFDD', '#72BDA3', '#03FCBA', '#770058']
        this.pagePath = this.props.location.pathname.substring(1)
        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true
            },
            columns: {
                LoadID: {
                    minWidth: 120
                },
                Labels: {minWidth: 120, show: true},
                LoadStatus: {minWidth: 150},
                LoadTypeID: {minWidth: 80},
                Price: {minWidth: 120},
                AccessorialAmount: {minWidth: 120},
                BillType: {minWidth: 120},
                IsRateConfirmed: {minWidth: 120},
                IsTonu: {minWidth: 120},
                LoadSubTypeID: {minWidth: 130},
                Customer: {minWidth: 180},
                CustomerReferenceNumber: {minWidth: 175},
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LoadID',

            // Fields
            fields: this.getFields(),
            fieldsOrder: getDefaultFieldOrder(this.getFields(), this.pagePath),
            emailFields: this.getEmailFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            selectedItem: null,
            previewDocument: undefined,
            emailModalOpen: false,
            selectedItems: {},
            selectedRows: {},
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: this.getResource()})
    }

    downloadExcel = () => {
        this.props.downloadExcel({
            query: Object.assign({
                format: 'EXCEL',
                name: 'billing_' + currentDate() + '.xlsx'
            }, this.getQuery())
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleBillingDialog = (item = null) => {
        this.setState({billingDialog: !this.state.billingDialog, selectedItem: item})
    }

    handleToggleDocumentsModal = (item = null) => {
        this.setState({selectedItem: item, documentsModal: !this.state.documentsModal})
    }

    handleProcessPreview = (item) => {
        this.toggleSendEmailDialog(item)
        this.handleToggleBillingDialog()
    }

    toggleSendEmailDialog = (item = null) => {
        // ignore event
        item = item?.current ? null : item

        if (item) {
            item.DocumentName = item.InvoiceID + '.pdf'
        }

        let emailFields = this.state.emailFields
        emailFields.emails.value = item?.InvoiceSendEmails?.split(',').map(it => ({label: it, value: it})) ?? ''

        this.setState({
            emailModalOpen: !this.state.emailModalOpen,
            previewDocument: item
        })
    }

    handleDownloadDocument = async () => {
        try {
            await axios
                .get(Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                        InvoiceID: this.state.previewDocument.InvoiceID,
                        token: getJWT().access_token,
                        type: 3
                    })),
                    {responseType: 'blob'})
                .then((response) => {
                    //Create a Blob from the PDF Streamwork
                    const file = new Blob([response.data], {type: 'application/pdf'})
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file)
                    //Open the URL on new Window
                    const pdfWindow = window.open(fileURL)
                    pdfWindow.location.href = fileURL
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            return {error}
        }
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleBulkDialog = (fetchData = false) => {
        let stateToBeSet = {batchActionDialog: !this.state.batchActionDialog}
        if (fetchData) {
            this.fetchData()
            stateToBeSet['selectedRows'] = {}
        }
        this.setState(stateToBeSet)
    }

    handleSelectRowClick = (item) => {
        const itemID = item.LoadID
        let selectedRows = cloneDeep(this.state.selectedRows)

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            const item = getProp(this.props.resource.data, 'list', []).find(it => it.LoadID === itemID)
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.LoadID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.LoadID.toString())) {
                    delete selectedRows[it.LoadID]
                }
            })
        }

        this.setState({selectedRows})
    }
    /** Helpers
     ================================================================= */

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getEmailFields = () => {
        return {
            EmailTemplateID: new Field('EmailTemplateID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full md:col-span-6'}),
            emails: new Field('emails', '', ['empty'], false, 'creatable', {
                addContainerClass: 'col-span-8',
                onEnterDown: true
            }, {
                isMulti: true,
                components: {DropdownIndicator: null},
                menuIsOpen: false,
                placeholder: 'Type email...'
            }),
            AddContactID: new Field('AddContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4 -ml-px'}, {placeholder: 'Filter contacts...'}),
            subject: new Field('subject', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            emailMessage: new Field('emailMessage', '', [], false, 'rich-text', {addContainerClass: 'col-span-full'}),
        }
    }

    getFields = () => {
        return {
            LoadID: new Field('LoadID', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                openInNewTab(`/loads/info/${item.LoadID}`)
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.LoadNumber}
                        </button>
                    )
                }
            }),
            Labels: new Field('Labels', '', ['empty'], false, 'custom', {
                omitSort: true,
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search', {
                render: (item) => {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                checkPerm(Resources.CustomersInfo, UPDATE_PERM) && item.CustomerID && openInNewTab(`/customers/info/${item.OrganizationID}/${item.CustomerID}`)
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Customer}
                        </button>
                    )
                }
            }),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            Price: new Field('Price', '', [], false, 'money'),
            AccessorialAmount: new Field('AccessorialAmount', '', ['empty'], false, 'money'),
            BillType: new Field('BillType', '', [], false, 'text'),
            IsRateConfirmed: new Field('IsRateConfirmed', 1, [], false, 'checkbox'),
            IsTonu: new Field('IsTonu', '', ['empty'], false, 'checkbox'),
            IsPODReady: new Field('IsPODReady', '', ['empty'], false, 'custom', {
                render: (it) => {
                    return (
                        it.IsPODReady
                            ?
                            <>
                                <CheckCircleIcon className="w-5 h-5 text-green-600"/>

                                ({it.DocumentsReadyCount ?? 0} of {it.DocumentsRequiredCount ?? 0})
                            </>
                            :
                            <>
                                <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                                ({it.DocumentsReadyCount ?? 0} of {it.DocumentsRequiredCount ?? 0})
                            </>

                    )
                }
            }),
            IsPODReviewed: new Field('IsPODReviewed', '', [], false, 'checkbox'),
            IsAccessorialHold: new Field('IsAccessorialHold', '', ['empty'], false, 'custom', {
                altIcon: <ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>,
                render: (it) => {
                    if (it.IsAccessorialHold === 1) {
                        return (
                            <div className="flex justify-center">
                                <CheckCircleIcon className="w-5 h-5 text-yellow-600"/>
                            </div>)
                    } else {
                        return (
                            <div className="flex justify-center">
                                <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                            </div>
                        )

                    }
                }
            }),
            PickupDate: new Field('PickupDate', '', ['empty'], false, 'date'),
            Pickup: new Field('Pickup', '', ['empty']),
            Destination: new Field('Destination', '', ['empty']),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
            TotalMiles: new Field('TotalMiles', '', [''], false, 'float'),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, 'float')
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            IsBroker: new Field('IsBroker', '', [''], false, 'select', {}, {
                all: true
            }),
            customers: new Field('customers', '', [''], false, 'multi-select-search', {
                query: {
                    loadCustomer: 1
                },
            }, {
                isClearable: true,
                multi: true
            }),
            IsRateConfirmed: new Field('IsRateConfirmed', 1, [], false, 'checkbox'),
            IsAccessorialHold: new Field('IsAccessorialHold', '', [], false, 'checkbox'),
            PODReady: new Field('PODReady', '0', [''], false, 'select', {}, {
                values: {
                    0: this.props.translate('text.All'),
                    1: this.props.translate('text.Ready'),
                    2: this.props.translate('text.NotReady'),
                },
            }),
            IsPODReviewed: new Field('IsPODReviewed', '0', [''], false, 'select', {}, {
                values: {
                    0: this.props.translate('text.All'),
                    1: this.props.translate('text.Reviewed'),
                    2: this.props.translate('text.NotReviewed'),
                },
            }),
            Marked: new Field('Marked', '', [''], false, 'checkbox'),
            IsHazmat: new Field('IsHazmat', '', [''], false, 'checkbox'),
            IsTonu: new Field('IsTonu', '', [''], false, 'checkbox'),
            StartDate: new Field('StartDate', '', [], false, 'date'),
            EndDate: new Field('EndDate', '', [], false, 'date'),
            DateType: new Field('DateType', 1, [''], false, 'radio', {
                    options: [{name: 'Pickup', value: 1}, {name: 'Delivery', value: 2}],
                    labelType: 'stack'
                }
            ),

            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-3 limit-field-container float-right', hideLabel: true, labelType: 'float'
            }, {menuPlacement: 'top'})
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    getResource = () => {
        return Resources.Billing
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length

        const ProcessMultipleBillingsData = data.filter(it => !!this.state.selectedRows[it.LoadID])

        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + (it.Price + (it.AccessorialAmount ?? 0)), 0))

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.Billing')}
                        afterTitle={(<>
                                <PageHeaderInfo
                                    dispatch={this.props.dispatch}
                                />
                                {!!resource.isLoading && (
                                    <LoaderSmall/>
                                )}
                                {!resource.isLoading && (
                                    <div>Total amount: {
                                        genericMoneyFormatter(
                                            getProp(this.props.resource.data, 'totals.TotalPrice', 0)
                                            +
                                            getProp(this.props.resource.data, 'totals.TotalAccessorialAmount', 0)
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    />

                    <div className="sm:flex mb-4">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass="relative mb-6">
                        <TableFilters
                            hideLimit
                            filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            selects={{
                                limit: {10: '10', 20: '20', 30: '30', 40: '40', 50: '∞'},
                                IsBroker: {0: 'Carrier', 1: 'Brokerage'},
                                customers: {
                                    api: 'api/' + Resources.CustomersQuick,
                                    query: this.state.queryFilterFields.customers?.metadata?.query ?? DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName || item.Organization,
                                        value: item.CustomerID
                                    })
                                }
                            }}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={this.handleToggleBillingDialog}
                            actions={[
                                {
                                    action: this.handleToggleBillingDialog,
                                    icon: Cog6ToothIcon, // make this a function
                                    visible: () => checkPerm(this.getResource(), CREATE_PERM),
                                    label: false, // make this a function
                                    title: translate('text.process'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.handleToggleDocumentsModal,
                                    icon: DocumentTextIcon, // make this a function
                                    visible: () => true,
                                    label: false, // make this a function
                                    title: translate('text.LoadDocuments'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                }
                            ]}

                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={'LoadID'}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey="LoadID"
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            footerContent={
                                hasSelectedRows && (
                                    <div className="px-0 py-1 border-tm-gray-200  flex items-center">
                                        <div className="mr-4 flex space-x-4 h-9">
                                        </div>
                                        <div className="text-sm flex justify-between w-full">
                                            <div className="mr-4">
                                                Total amount for selected entries:
                                                <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount}
                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        >
                            <div className="flex divide-tm-gray-300">
                                <div className="flex">
                                    <Tippy content={translate('text.ProcessMultipleBillings')}>
                                        <button
                                            onClick={() => this.setState({batchActionDialog: true})}
                                            disabled={!areSomeItemsSelected}
                                            className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                        >
                                            <Cog6ToothIcon
                                                className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                        </button>
                                    </Tippy>
                                </div>
                            </div>
                        </TableBulkActions>

                        <NoRecords
                            show={(data.length === 0) && !resource.isLoading}
                            title={translate('text.no_records')}
                            addClass={'pt-16 pb-10'}
                        />
                    </TableCard>

                    <BillingBulkBatchModal
                        show={this.state.batchActionDialog}
                        selectedItems={ProcessMultipleBillingsData}
                        onClose={this.handleToggleBulkDialog}
                        translate={translate}
                        onCreateBillingBulk={this.props.createBillingBulk}
                    />

                    <ModalDefault
                        show={this.state.documentsModal}
                        widthClass={' '}
                        title={translate('text.LoadDocuments')}

                        closeButtonLabel={translate('btn.close')}
                        onClose={this.handleToggleDocumentsModal}
                    >
                        {this.state.selectedItem && (
                            <DocumentsUpdateTab
                                onFetchData={this.props.getDocuments}
                                id={this.state.selectedItem.LoadID}
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.documentsResource}
                                readOnly
                                disableCreate
                                disableDelete
                                disableSendViaEmail

                                sortBy={'Description'}

                                download={this.props.download}
                                resourceName={Resources.LoadDocuments}

                                searchFields={{
                                    query: new Field('query', '', [], false, 'text', {
                                        containerClass: 'col-md-4'
                                    }),
                                    DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                        containerClass: 'col-md-4'
                                    }, {
                                        all: true
                                    }),
                                    DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                        containerClass: 'col-md-4'
                                    }, {
                                        all: true
                                    }),
                                }}
                            />
                        )}
                    </ModalDefault>

                    <EmailDocumentDialog
                        show={this.state.emailModalOpen}
                        title={translate('text.invoice_created_successfully')}
                        widthClass={'max-w-5xl'}
                        onClose={this.toggleSendEmailDialog}
                        fields={this.state.emailFields}
                        metadata={{
                            AddContactID: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: {offset: 0, sort: 'ASC', limit: 10},
                                searchMap: (item) => ({
                                    value: item.Email,
                                    label: item.FirstName + ' ' + item.LastName
                                })
                            },
                            EmailTemplateID: {
                                api: 'api/' + Resources.EmailTemplates,
                                query: {offset: 0, sort: 'ASC', limit: 10},
                                searchMap: (item) => ({
                                    value: item.EmailTemplateID,
                                    label: item.EmailTemplateName
                                })
                            }
                        }}

                        fetchEmailTemplateQuery={{
                            InvoiceID: this.state?.previewDocument?.InvoiceID,
                            OrganizationID: this.state?.previewDocument?.OrganizationID
                        }}

                        onSubmit={(params) => {
                            if (params) {
                                let emails = params.emails.filter(it => !it.invalid).reduce((memo, it) => {
                                    memo += it.value + ','
                                    return memo
                                }, '')

                                this.props.getData({
                                    query: Object.assign({}, {
                                        status: 1,
                                        InvoiceID: this.state.previewDocument.InvoiceID,
                                        type: 2,
                                        name: this.state.previewDocument.DocumentName,
                                        InvoiceSendEmails: emails.substring(0, emails.length - 1),
                                        subject: params.subject,
                                        message: params.emailMessage
                                    }, this.getQuery()),
                                    resource: Resources.BillingDocuments,
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: 'Sent invoice to: ' + emails.substring(0, emails.length - 1)
                                })

                                this.toggleSendEmailDialog()
                            }
                        }}
                        onPrint={() => this.handleDownloadDocument()}
                        document={this.state.previewDocument}
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                            InvoiceID: this.state.previewDocument?.InvoiceID,
                            token: getJWT().access_token,
                            type: 1,
                            name: 'invoice_' + this.state.previewDocument?.DocumentName
                        }))}
                        {...this.props}
                    />

                    <TableOptionsDialog
                        show={this.state.columnsModalOpen}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <BillingDialog
                        show={this.state.billingDialog}
                        selectedItem={this.state.selectedItem}
                        query={this.getQuery()}
                        onClose={this.handleToggleBillingDialog}
                        onProcessPreview={this.handleProcessPreview}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resource: state.resource,
        documentsResource: state.secondResource,
        colorLabels: state.thirdResource,
        ...state
    }
}

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get('user')

    return {
        dispatch: dispatch,
        getData: getDataSelect({dispatch, user}),
        getDocuments: getSecondDataSelect({dispatch, user}),
        createBillingBulk: createDataSelect({dispatch, user}),

        downloadExcel: ({query}) => {
            dispatch(download({
                user: user,
                query: query,
                resource: Resources.Billing
            }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingView)
