import React, {Component} from 'react';
import LocalStorage from "../../../util/localStorage";
import NoRecordsTable from "../../no-records-found/no-records-table";
import ResourceTable from "../../resource-table";
import {DEFAULT_CRUD_STATE, READ_PERM, UPDATE_PERM} from "../../../../util/util-constants";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {getResource} from "../../../../data/actions/resource";
import {checkPerm, getDefaultTableOptions, getProp, openInNewTab, returnMileage} from "../../../util/util-helpers";
import Resources from "../../../../data/services/resources";
import StatusProgressTable from "../../resource-table/table-components/status-progress-table";
import TableCard from "../../resource-table/table-components/table-card";
import TableFilters from "../../resource-table/table-components/table-filters";
import Pagination from "../../resource-table/table-components/pagination";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import {cloneDeep} from "../../../util/util-vanilla";

class LoadsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            ...DEFAULT_CRUD_STATE,
            query: "",
            sortBy: "LoadNumber",
            sort: "DESC",
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), {}, "LoadsTab", this.props.translate)
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResource(),
            query: this.getQuery()
        }));
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, this.fetchData)
    };

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        });
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
                queryFilterFields: queryFilterFields,
                offset: 0,
                paginationPage: 1
            }, () =>
                this.fetchData()
        )
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', [], false, 'custom', {
                render: (item) => {
                    if (!item.LoadNumber) {
                        return null;
                    }

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.LoadInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/loads/info/${item.LoadID}`);
                                }
                            }}
                            disabled={!checkPerm(Resources.LoadInfo, READ_PERM)}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.LoadNumber}
                        </button>
                    )
                }
            }),
            LoadStatusID: new Field('LoadStatusID', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return (
                        <StatusProgressTable
                            addClass=""
                            LoadStatusID={item.LoadStatusID}
                            translate={this.props.translate}
                        />
                    )
                }
            }),
            DriverID: new Field('DriverID', '', [], false, 'custom', {
                render: (item) => {
                    if (!item.DriverID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.DriverInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleEditDriverClick(item.DriverID);
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Driver}
                        </button>
                    )
                }
            }),
            CoDriverID: new Field('CoDriverID', '', [], false, 'custom', {
                render: (item) => {
                    if (!item.CoDriverID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.DriverInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleEditDriverClick(item.CoDriverID);
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.CoDriver}
                        </button>
                    )
                }
            }),
            TruckID: new Field('TruckID', '', [], false, 'text', {
                render: (item) => {
                    if (!item.TruckID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.TrucksInfo, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/trucks/info/${item.TruckID}`);
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Truck}
                        </button>
                    )
                }
            }),
            TrailerID: new Field('TrailerID', '', [], false, 'custom', {
                render: (item) => {
                    if (!item.TrailerID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                if (checkPerm(Resources.Trailer, READ_PERM)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openInNewTab(`/trailers/info/${item.TrailerID}`);
                                }
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.Trailer}
                        </button>
                    )
                }
            }),
            TrailerType: new Field('TrailerType', '', []),
            Customer: new Field('Customer', '', []),

            PickupDate: new Field('PickupDate', '', [], false, 'date'),
            Pickup: new Field('Pickup', '', [], false, 'text'),
            PickupCityName: new Field('PickupCityName', '', []),
            PickupState: new Field('PickupState', '', []),
            PickupPostalCode: new Field('PickupPostalCode', '', []),

            DestinationDate: new Field('DestinationDate', '', [], false, 'date'),
            Destination: new Field('Destination', '', [], false, 'text'),
            DestinationCityName: new Field('DestinationCityName', '', []),
            DestinationState: new Field('DestinationState', '', []),
            DestinationPostalCode: new Field('DestinationPostalCode', '', []),

            EmptyMiles: new Field('EmptyMiles', '', [''], false, "text", {
                render: (item) => returnMileage(item.EmptyMiles)
            }),
            TotalMiles: new Field('TotalMiles', '', [''], false, "text", {
                render: (item) => returnMileage(item.TotalMiles)
            }),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field("query", '', [''], false, 'search', {}, {}),
            LoadStatusID: new Field("LoadStatusID", '', [''], false, 'select', {}, {
                all: true
            }),
            limit: new Field("limit", 10, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getQuery = () => {
        return {
            [this.props.tabKey]: this.getID(),
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getResource = () => {
        return this.props.tabResource;
    }

    getID = () => {
        return this.props.id;
    }
    handleEditDriverClick = (DriverID) => {
        openInNewTab(`/drivers/info/${DriverID}`, "_blank");
    }

    render() {
        const {translate, resource} = this.props;
        const dataItems = getProp(resource, 'data.list', []);
        const count = getProp(resource, 'data.count', 0)

        return (
            <>
                <ActiveFilters
                    filterFields={this.state.queryFilterFields}
                    onLabelClick={this.handleFilterInputChange}
                    onClearFiltersClick={this.handleClearFiltersClick}
                    translate={translate}
                    addClass={"mb-2"}
                />
                <TableCard>
                    <TableFilters
                        hideLimit
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                    />

                    <ResourceTable
                        data={dataItems}
                        count={count}

                        commonTable={true}
                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.queryFilterFields.limit.value}

                        tableKey={"LoadID"}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                        options={this.state.tableOptions}
                        onRowClick={(item) => (checkPerm(Resources.LoadInfo, UPDATE_PERM) && openInNewTab(`/loads/info/${item.LoadID}`))}
                    />

                    <TableCardFooter
                        show={!(!dataItems.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                            pageOffset={this.state.offset}
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={(dataItems.length === 0) && !resource.isLoading}
                        canCreate={false}
                        title={translate("text.no_matching_records")}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>
            </>
        )
    }
}

export default LoadsTab;
