import React, {useRef, useState} from "react";
import {checkPerm, classNames, getHighlightedFullAddressName} from "../../../../common/util/util-helpers";
import {ClockIcon, EllipsisVerticalIcon, PencilSquareIcon, UserIcon as UserIconSolid} from "@heroicons/react/20/solid";
import PencilIcon from "@heroicons/react/20/solid/PencilIcon";
import ArrowSmallUpIcon from "@heroicons/react/20/solid/ArrowSmallUpIcon";
import ArrowSmallDownIcon from "@heroicons/react/20/solid/ArrowSmallDownIcon";
import ArrowsUpDownIcon from "@heroicons/react/20/solid/ArrowsUpDownIcon";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DRIVER_STATUS_TYPES,
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_POSSIBLE,
    LOAD_STATUS_RESERVED,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_PICKUP_AND_DELIVERY,
    STOP_TYPE_STOP_BY
} from "../../../../util/util-constants";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import CalendarSolidIcon from "@heroicons/react/20/solid/CalendarDaysIcon";
import Tippy from "@tippyjs/react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import {toFrontDate, toFrontDateTime, toFrontDateTimeFromUTC} from "../../../../common/util/util-dates";
import moment from "moment-timezone";
import TotalMilesDisplay from "./load-components/total-miles";
import EmptyMilesDisplay from "./load-components/empty-miles";
import {ArrowDownTrayIcon, CalendarIcon} from '@heroicons/react/24/outline'
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import MapPinIcon from "@heroicons/react/20/solid/MapPinIcon";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {getStopIcon} from "../load-utils";
import TrashIcon from "@heroicons/react/20/solid/TrashIcon";
import UserGroupIcon from "@heroicons/react/20/solid/UserGroupIcon";
import PalletsNumberWarning from "./load-components/pallets-number-warning";
import Resources from "../../../../data/services/resources";
import GetDispatchManagerFields from "../../dispatch-manager-view/dispatch-manager-fields";
import {useSelector} from "react-redux";
import LoadsEventsList from "../load-sidebar/load-events-list";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import FlagIcon from "@heroicons/react/24/outline/FlagIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import DropLoadButton from "./drop-load-button";
import ModalDefault from "../../../../common/components/modal/modal-default";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ResourceTableDialogWithFilters from "../../../../common/components/modal/resource-table-dialog-with-filters";
import {Loader, LoaderLarge} from "../../../../common/components/loader";
import InfoParagraph from "../../../../common/components/info-paragraph";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import PopOver from "../../../../common/components/popover";
import Tooltip from "../../../../common/components/tooltip";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import CopyToClipboardButton from '../../../../common/components/buttons/copy-to-clipboard-button'
import ButtonIcon from "../../../../common/components/button/button-icon";
import {CREATE_PERM, READ_PERM} from "../../../../common/util/util-consts";
import StopPalletWarning from "./load-components/stop-pallet-warning";

export default function LoadInfoLocations({
                                              fieldSelects,
                                              onStopByInputChange,
                                              onStopInputChange,
                                              isQuote,
                                              stops,
                                              stopBys,
                                              stopsCombined,
                                              onLocationClick,
                                              onStopDragEnter,
                                              onStopDragStart,
                                              onStopDragEnd,
                                              draggedStopIndex,
                                              onRemoveStopsClick,
                                              onAddLocationClick,
                                              onAddStopByClick,
                                              onAddStopsClick,
                                              onDeleteLocationClick,
                                              onDeleteStopByClick,
                                              TotalMiles,
                                              TotalMilesNoStopBy,
                                              dispatch,
                                              EmptyMiles,
                                              onCompleteStopClick,
                                              onCompleteStopByClick,
                                              toggleLocationDialog,
                                              onStopAppointmentChange,
                                              onUpdateStopClick,
                                              initialLoadStatusID,
                                              loadStatusID,
                                              isStateDirty,
                                              toggleWorkingHoursModal,
                                              toggleContactsDialog,
                                              toggleExpandSection,
                                              isSectionExpanded,
                                              onSelectLocationClick,
                                              isEditMode,
                                              innerRef,
                                              onLocationEnterPress,
                                              needsUpdate,
                                              isLoading,
                                              isLoadInternational,
                                              isLoadPlaning,
                                              hasUpdatedStopTypeWarning,
                                              hasIncludedInMileageResetMessage,
                                              hasChangeStopOrderWarning,
                                              commodityData,
                                              defaultWeightUnit,
                                              toggleIncludeInMileage,
                                              applySelectedLocationUnit,
                                              stopUpdates = [],
                                              hasTrailer,
                                              translate,
                                              areLocationAssetsVisible,
                                              tailgateParentID,
                                              isEmptyMilesWarning,
                                              dropLoadParams,
                                              stepsAddStopsRef,
                                              stepsLocationsFormRef,
                                              stopFieldsRefs,
                                              completeStopButtonRef
                                          }) {
    const dropLoadButtonRef = useRef();
    const numberOfStops = stops.length;
    let selectedLocationIndex;
    let IsSelectedStopCompleted;
    let IsSelectedLoadDropped;
    let isPreviousStopCompleted;
    let shouldSelectedStopBeCompleted;
    let arrivalDate;
    let departureDate;
    let IsStopBySelected;
    let selectedCombinedStopIndex;
    let selectedStopName;
    const hasStopUpdates = !!stopUpdates.length;
    if (hasStopUpdates) {
        stopUpdates = stopUpdates.sort((a, b) =>
            moment(b.EventDate, DEFAULT_DATABASE_DATETIME_FORMAT).unix()
            - moment(a.EventDate, DEFAULT_DATABASE_DATETIME_FORMAT).unix()
        );
    }
    const latestStopUpdate = stopUpdates[0]

    const dispatchManagerInitialFocusRef = useRef();
    const dialogResource = useSelector((state) => state.dialogResource);

    const hasLocations = !!stops.length;
    const isDispatched = initialLoadStatusID >= LOAD_STATUS_DISPATCHED;
    const isCovered = initialLoadStatusID >= LOAD_STATUS_RESERVED;
    let canToggleMileageCalculation = !!stopsCombined.length
        && (stopsCombined.reduce((memo, it) => {
            if (Number(it.IncludeInMileage) === 1 || it.IncludeInMileage === null) {
                memo = memo + 1;
            }

            return memo;
        }, 0) > 1);

    const [isDispatchManagerDialogVisible, setIsDispatchManagerDialogVisible] = useState(false);
    const [isNotesDialogVisible, setIsNotesDialogVisible] = useState(false);
    const [locationNotes, setLocationNotes] = useState({});
    const [isStopUpdatesDialogOpen, setIsStopUpdatesDialogOpen] = useState(false);

    const getDropTrailerFields = (stopIndex) => {
        let dropTrailerFields = ['IsDroppingTrailer']

        if (stops[stopIndex].IsDroppingTrailer.value) {
            dropTrailerFields = dropTrailerFields.concat(['EstimatedRetrievalDate', 'PickingTrailerID'])
        }

        return dropTrailerFields;
    }

    const getPickUpTrailerFields = (stopIndex) => {
        let dropTrailerFields = ['IsPickingTrailer']

        if (stops[stopIndex]?.IsPickingTrailer?.value) {
            dropTrailerFields = dropTrailerFields.concat(['PickingTrailerID'])
        }

        return dropTrailerFields;
    }

    const getSwitchDispatchFields = (stopIndex) => {
        let switchDispatchFields = ['IsSwitchDispatch']

        if (stops[stopIndex].IsSwitchDispatch.value) {
            switchDispatchFields = switchDispatchFields.concat(['DriverID', 'CoDriverID', 'TruckID', 'TrailerID'])
        }

        return switchDispatchFields;
    }

    const getStopByDropTrailerFields = (stopIndex) => {
        let dropTrailerFields = ['IsDroppingTrailer']

        if (stopBys[stopIndex].IsDroppingTrailer.value) {
            dropTrailerFields = dropTrailerFields.concat(['EstimatedRetrievalDate', 'PickingTrailerID'])
        }

        return dropTrailerFields;
    }

    const getStopByPickUpTrailerFields = (stopIndex) => {
        let dropTrailerFields = ['IsPickingTrailer']

        if (stopBys[stopIndex].IsPickingTrailer.value) {
            dropTrailerFields = dropTrailerFields.concat(['PickingTrailerID'])
        }

        return dropTrailerFields;
    }

    const getStopBysSwitchDispatchFields = (stopIndex) => {
        let switchDispatchFields = ['IsSwitchDispatch']

        if (stopBys[stopIndex].IsSwitchDispatch.value) {
            switchDispatchFields = switchDispatchFields.concat(['DriverID', 'CoDriverID', 'TruckID', 'TrailerID'])
        }

        return switchDispatchFields;
    }

    const stopFields = [
        "StopID",
        "StopType",
        "ReferenceNumber",
        "RequiresAppointment",
        "AptReferenceNumber",
        "StopDate",
        "StopTime",
        "StopEndDate",
        "StopEndTime",
        "Notes",
        "LoadStopID"
    ]

    const stopByFields = [
        "StopByTypeID",
        "ActualArrivalDate",
        "ActualArrivalDateTime",
        "ActualDepartureDate",
        "ActualDepartureDateTime",
        "Notes",
    ]

    let currentTrailer = hasTrailer;

    const trailerLocationMap = stopsCombined.reduce((memo, it, i) => {
        const IsStopBy = it.StopType === STOP_TYPE_STOP_BY;
        memo[i + 1] = currentTrailer;
        if (IsStopBy) {
            if (stopBys[it.index]?.IsDroppingTrailer.value) {
                currentTrailer = null;
            }
            if (stopBys[it.index]?.PickingTrailerID.value) {
                currentTrailer = true;
            }
        } else {
            if (stops[it.index]?.IsDroppingTrailer.value) {
                currentTrailer = null;
            }
            if (stops[it.index]?.PickingTrailerID.value) {
                currentTrailer = true;
            }
        }
        return memo;
    }, {});


    const getStopTimes = (it, isStopBy, stops, stopBys) => {
        const stopDate = isStopBy ? stopBys?.[it.index]?.ActualArrivalDate.value : stops?.[it.index]?.StopDate?.value;
        const stopStartTime = isStopBy ? stopBys?.[it.index]?.ActualArrivalDateTime.value : stops?.[it.index]?.StopTime?.value;
        const stopEndDate = isStopBy ? stopBys?.[it.index]?.ActualDepartureDate.value : stops?.[it.index]?.StopEndDate?.value;
        const stopEndTime = isStopBy ? stopBys?.[it.index]?.ActualDepartureDateTime.value : stops?.[it.index]?.StopEndTime?.value;

        const stopDateHtml = !!stopDate &&
            <span key={it?.index} className="font-bold">{toFrontDate(stopDate)}{" "}</span>;

        const stopStartDateTime = [stopDateHtml, stopStartTime].map((it) => {
            if (it) {
                return it;
            }
        }).filter(it => !!it);

        const stopEndDateHtml = !!stopEndDate && <span key={"end_" + it?.index} className="font-bold">
            {toFrontDate(stopEndDate)}{" "}
        </span>
        const stopEndDateTime = [stopEndDateHtml, stopEndTime].map((it) => {
            if (it) {
                return it;
            }
        }).filter(it => !!it);
        const hasStopTime = !!stopStartDateTime.length || !!stopEndDateTime.length;

        return [stopStartDateTime, stopEndDateTime, hasStopTime];
    }

    return (
        <div ref={innerRef} className={classNames(
            "grid grid-cols-12 border-t border-tm-gray-300 relative"
        )}>
            <div className="col-span-full space-y-1">
                {isDispatched && (
                    <div>
                        <InfoBar className="flex gap-2 py-2 px-6"
                                 message={translate("text.location_dispatched_message")}/>
                    </div>
                )}

                {hasUpdatedStopTypeWarning && (
                    <div>
                        <InfoBar className="flex gap-2 py-2 px-6" type="warning"
                                 message={translate("text.changed_stop_type_message")}/>
                    </div>
                )}

                {hasIncludedInMileageResetMessage && (
                    <div>
                        <InfoBar className="flex gap-2 py-2 px-6" type="warning"
                                 message={translate("text.included_in_mileage_reset_message")}/>
                    </div>
                )}

                {hasChangeStopOrderWarning && (
                    <div>
                        <InfoBar className="flex gap-2 py-2 px-6" type="warning"
                                 message={translate("text.changed_stop_order_warning")}/>
                    </div>
                )}

                {hasStopUpdates && (
                    <div>
                        <InfoBar addClass="rounded-none">
                            <div
                                onClick={() => stopUpdates.length > 1 ? setIsStopUpdatesDialogOpen(true) : null}
                                className="w-full"
                            >
                                <div className="space-x-2">
                                    <span className="font-bold leading-6">{latestStopUpdate.EventKey}</span>
                                    <span>{latestStopUpdate.EventValue}</span>

                                    {stopUpdates.length > 1 && (
                                        <button
                                            onClick={() => setIsStopUpdatesDialogOpen(true)}
                                            className="h-auto py-0 px-2 font-bold btn-text float-right"
                                        >
                                            View updates
                                        </button>
                                    )}
                                </div>

                                <div className="flex w-full justify-between text-tm-gray-700">
                                    <div className="flex">
                                        {<UserIconSolid className="w-5 h-5 mr-2 text-tm-gray-500"/>}
                                        {latestStopUpdate.Contact}
                                    </div>

                                    <div className="flex pr-2">
                                        {<CalendarSolidIcon className="w-5 h-5 mr-2 text-tm-gray-500"/>}
                                        {toFrontDateTimeFromUTC(latestStopUpdate.EventDate)}
                                    </div>
                                </div>
                            </div>
                        </InfoBar>
                    </div>
                )}
            </div>

            <div className="col-span-full xl:col-span-6 3xl:col-span-5 py-3 flex flex-col justify-between pl-6">
                <div>
                    <div className="flex items-center pr-4">
                        {!!toggleExpandSection && (
                            <button
                                className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                                onClick={() => toggleExpandSection("LoadInfoLocations")}
                            >
                                <ChevronRightIcon
                                    className={
                                        classNames(
                                            "w-5 h-5",
                                            isSectionExpanded ? "rotate-90" : undefined
                                        )
                                    }
                                />
                            </button>
                        )}

                        <Subtitle subtitle={translate("text.locations")}/>

                        {((initialLoadStatusID === LOAD_STATUS_POSSIBLE && loadStatusID === LOAD_STATUS_POSSIBLE) || !isEditMode) &&
                            hasLocations &&
                            isSectionExpanded && (
                                <div className="ml-auto">
                                    <button
                                        className="btn btn-text py-1 gap-2 hover:bg-red-600/10 text-primary pr-"
                                        onClick={onRemoveStopsClick}
                                    >
                                        <TrashIcon className="w-5 h-5 text-red-600"/>

                                        {translate("btn.remove_locations")}
                                    </button>
                                </div>
                            )}
                    </div>

                    {isSectionExpanded && hasLocations && (
                        <div>
                            <div className="py-3 space-y-4 pl-4 pr-4">
                                {!isLoadInternational && (
                                    <>
                                        {Number(TotalMiles) === -2 && !tailgateParentID && (
                                            <InfoParagraph type="warning">
                                                <p>{translate("text.can_not_calculate_miles_message_line_1")}</p>
                                                <p className="font-bold text-tm-gray-900">
                                                    {translate("text.can_not_calculate_miles_message_line_2")}
                                                </p>
                                            </InfoParagraph>
                                        )}

                                        {Number(TotalMiles) !== -2 && !tailgateParentID && (
                                            <div className="flex space-x-8 pl-3">
                                                <TotalMilesDisplay
                                                    needsUpdate={needsUpdate}
                                                    TotalMiles={TotalMiles}
                                                    TotalMilesNoStopBy={TotalMilesNoStopBy}
                                                    dispatch={dispatch}
                                                    translate={translate}
                                                />

                                                <Tooltip
                                                    content={
                                                        isDispatched
                                                            ? translate("text.empty_miles_message_after_dispatch")
                                                            : translate("text.empty_miles_message_before_dispatch")
                                                    }
                                                >
                                                    <button className="cursor-default">
                                                        <EmptyMilesDisplay
                                                            EmptyMiles={EmptyMiles}
                                                            translate={translate}
                                                        />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        )}

                                        {!!tailgateParentID && (
                                            <InfoBar>
                                                {translate("text.tailgate_mileage")}
                                            </InfoBar>
                                        )}
                                    </>

                                )}

                                {isEditMode && (
                                    <StopPalletWarning
                                        stops={stops}
                                        commodityData={commodityData}
                                        translate={translate}
                                    />
                                )}

                                {isEditMode && (
                                    <PalletsNumberWarning
                                        stops={stops}
                                        selectedLocation={stops[stopsCombined.find(item => item.IsSelected).index]}
                                        commodityData={commodityData}
                                        defaultWeightUnit={defaultWeightUnit}
                                        translate={translate}
                                    />
                                )}

                                {(isEmptyMilesWarning > 0) && (
                                    <InfoBar
                                        addClass="text-sm leading-6"
                                        iconClass="w-6 h-6 shrink-0 text-yellow-500"
                                        type="warning"
                                        Icon={ExclamationTriangleIcon}
                                    >
                                        {translate("text.EmptyMilesWarning", [isEmptyMilesWarning])}
                                    </InfoBar>
                                )}
                            </div>

                            <div className="mt-4 space-y-6 relative">
                                {stopsCombined.map((it, i) => {
                                    const IsStopBy = it.StopType === STOP_TYPE_STOP_BY;
                                    const IsStopCompleted = it.IsStopCompleted;
                                    const shouldBeCompleted = !IsStopCompleted && (!!stopsCombined[i + 1]?.IsStopCompleted); // this stop is not completed but next one is
                                    const canBeCompleted = stopsCombined[i - 1]?.IsStopCompleted && !stopsCombined[i]?.IsStopCompleted && (!stopsCombined[i + 1]?.IsStopCompleted);


                                    const IsSelected = it.IsSelected;

                                    const hasLocationNotes = !!it.metadata?.InternalNotes || !!it.metadata?.DispatchDeliveryNotes || !!it.metadata?.DispatchPickupNotes;

                                    // Stop times
                                    const [stopStartDateTime, stopEndDateTime, hasStopTime] = getStopTimes(it, IsStopBy, stops, stopBys)

                                    const hasErrors = !IsStopBy
                                        ? !!stops[it.index]?.StopID?.errorMessage || !!stops[it.index]?.StopDate?.errorMessage || !!stops[it.index]?.StopType?.errorMessage || !!stops[it.index]?.IsStopCompleted?.errorMessage || !!stops[it.index]?.PickingTrailerID?.errorMessage
                                        : !FieldsManager.checkFieldsForErrors(stopBys[it.index], ['ActualArrivalDate', 'ActualArrivalDateTime', 'ActualDepartureDate', 'ActualDepartureDateTime', 'PickingTrailerID']);

                                    const StopIcon = getStopIcon(stopBys[it.index]?.StopByTypeID?.value);

                                    let addressName = getHighlightedFullAddressName(IsStopBy ? it.metadata : stops[it.index]?.LocationData?.value);

                                    const canContinueLoad = !!canBeCompleted && !!dropLoadParams?.IsDropped;
                                    const canDropLoad = !!canBeCompleted && !dropLoadParams?.IsDropped;

                                    if (IsSelected) {
                                        selectedLocationIndex = it.index;
                                        selectedCombinedStopIndex = i;

                                        IsStopBySelected = !!IsStopBy;
                                        IsSelectedStopCompleted = IsStopCompleted;

                                        isPreviousStopCompleted = (i === 0) || (!!stopsCombined[i - 1]?.IsStopCompleted);

                                        shouldSelectedStopBeCompleted = shouldBeCompleted;

                                        selectedStopName = getHighlightedFullAddressName(IsStopBy ? it.metadata : stops[it.index]?.LocationData?.value, "text-lg font-bold leading-5", true);
                                        IsSelectedLoadDropped = canContinueLoad;
                                    }

                                    if (IsStopCompleted) {
                                        if (IsStopBy) {
                                            arrivalDate = getArrivalDate(stopBys[it.index].ActualArrivalDate.value);
                                            departureDate = getArrivalDate(stopBys[it.index].ActualDepartureDate.value);
                                        } else {
                                            arrivalDate = getArrivalDate(stops[it.index]?.LocationData?.value.ActualArrivalDateTime);
                                            departureDate = getArrivalDate(stops[it.index]?.LocationData?.value.ActualDepartureDateTime);
                                        }
                                    }


                                    //const isCompleteButtonVisible = isSectionExpanded && hasLocations && IsSelected;
                                    const isCompleteButtonVisible = isDispatched && !canContinueLoad;
                                    const areThisAndPreviousCompleted = stopsCombined[i]?.IsStopCompleted && stopsCombined[i - 1]?.IsStopCompleted;
                                    const isRequiresAppointmentVisible = !IsStopBy && stops?.[it.index]?.RequiresAppointment?.type !== 'hidden';
                                    const areLocationsNotesVisible = hasLocationNotes && !IsStopBy;
                                    const hasAnyDate = !!stopStartDateTime.length || !!stopEndDateTime.length;

                                    const isNotOnTime = it?.metadata?.NotOnTime;

                                    let completeButtonMessage = isPreviousStopCompleted || IsStopCompleted || !IsSelected ? null : translate("text.previous_stop_not_completed")
                                    completeButtonMessage = isStateDirty
                                        ? translate("text.stop_update_disabled")
                                        : completeButtonMessage;

                                    //const hasContextMenuOptions = canContinueLoad ||;

                                    const IsDraggable = !IsStopCompleted && !shouldBeCompleted && !canContinueLoad;

                                    return (
                                        <div
                                            key={it.StopType + "_" + it.index}
                                            className="relative"
                                        >
                                            {draggedStopIndex !== undefined && !IsStopCompleted && draggedStopIndex !== i && !shouldBeCompleted && IsDraggable && (
                                                <div
                                                    onDragEnter={() => onStopDragEnter(i)}
                                                    onDrop={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        return false;
                                                    }}
                                                    onDragOver={e => e.preventDefault()}
                                                    className="absolute inset-0 z-10"
                                                />
                                            )}

                                            <div
                                                draggable={IsDraggable}
                                                onDragStart={() => onStopDragStart(i)}
                                                onDragEnd={() => onStopDragEnd()}
                                                onDragOver={e => e.preventDefault()}
                                                className={
                                                    classNames(
                                                        "flex relative items-start gap-x-1 group max-w-full",
                                                        //  it.IsSelected ? "bg-tm-gray-100 border-primary border-y border-l " : undefined
                                                    )}
                                            >
                                                {it.IsSelected && (
                                                    <div
                                                        className="bg-tm-gray-100 absolute top-px bottom-px left-full -right-1"></div>
                                                )}
                                                <button
                                                    className={classNames(
                                                        "flex invisible text-tm-gray-500 hover:cursor-grab w-6",
                                                        IsDraggable ? "group-hover:visible" : undefined
                                                    )}
                                                >
                                                    <div className="w-6 h-6 absolute top-1/2 -translate-y-1/2">
                                                        <EllipsisVerticalIcon
                                                            className="w-5 h-5 flex-shrink-0 absolute -left-1"/>
                                                        <EllipsisVerticalIcon
                                                            className="w-5 h-5 flex-shrink-0 absolute right-0.5"/>
                                                    </div>
                                                </button>

                                                <div
                                                    className={classNames(
                                                        "w-6 h-6 text-base font-bold absolute text-center text-tm-gray-500 top-1/2 -translate-y-1/2",
                                                        IsDraggable ? "group-hover:hidden" : undefined
                                                    )}
                                                >
                                                    {i + 1}.
                                                </div>

                                                <div className={
                                                    classNames(
                                                        "w-6 relative -left-1",
                                                        isCompleteButtonVisible || canContinueLoad ? "h-32" : "h-24"
                                                    )}
                                                >
                                                    {!!stopsCombined[i - 1] && (
                                                        <button
                                                            onClick={() => !!toggleIncludeInMileage && toggleIncludeInMileage(i)}
                                                            disabled={
                                                                initialLoadStatusID >= LOAD_STATUS_DELIVERED
                                                                || (!canToggleMileageCalculation && it.IncludeInMileage === 1)
                                                                || !toggleIncludeInMileage
                                                                || stopsCombined.length < 3
                                                                || !!tailgateParentID
                                                            }
                                                            className={
                                                                classNames(
                                                                    "absolute z-10 text-tm-gray-400 left-0.5 bottom-0 -translate-y-[calc(50%+12px)] h-full w-5  rounded-btn",
                                                                    initialLoadStatusID < LOAD_STATUS_DELIVERED
                                                                    && (canToggleMileageCalculation && it.IncludeInMileage !== 1)
                                                                    && !!toggleIncludeInMileage ? "hover:bg-tm-gray-100" : undefined
                                                                )
                                                            }
                                                        >

                                                            <div
                                                                className={
                                                                    classNames(
                                                                        stopsCombined[i].IncludeInMileage === 0 ? "border-red-600" : undefined,
                                                                        stopsCombined[i].IncludeInMileage !== 0 && areThisAndPreviousCompleted ? "border-green-600" : "border-tm-gray-300",
                                                                        "absolute top-1 bottom-1 left-2 border-dotted border-l-4"
                                                                    )}
                                                            ></div>


                                                            {stopsCombined[i].IncludeInMileage === 0 && (
                                                                <div
                                                                    className="relative -left-0.5 w-6 h-6 flex items-center justify-center z-10 rounded-full bg-inverse border-2 border-red-600">
                                                                    <XMarkIcon
                                                                        className="w-5 h-5 text-red-600"/>
                                                                </div>
                                                            )}
                                                        </button>
                                                    )}

                                                    <div
                                                        className="flex items-center justify-center absolute top-1/2 -translate-y-1/2">
                                                        <button
                                                            tabIndex={IsStopCompleted ? undefined : "-1"}
                                                            className={classNames(
                                                                "w-6 h-6 rounded-md flex-shrink-0 relative focus:ring-2",
                                                                IsStopCompleted ? "border-green-600 bg-green-600" : "text-tm-gray-500 cursor-default",
                                                                Number(it.StopType) !== STOP_TYPE_STOP_BY && !IsStopCompleted ? "border-2 border-tm-gray-400" : undefined,
                                                            )}
                                                            onClick={() => {
                                                                it.StopType
                                                                    ? onCompleteStopClick(stops[selectedLocationIndex], i)
                                                                    : onCompleteStopByClick(stops[selectedLocationIndex], i)
                                                            }}
                                                            disabled={!IsStopCompleted}
                                                        >
                                                            {Number(it.StopType) === STOP_TYPE_PICKUP && (
                                                                <ArrowSmallUpIcon
                                                                    className={classNames("absolute inset-0", IsStopCompleted ? "text-white" : "text-tm-gray-500")}/>
                                                            )}

                                                            {Number(it.StopType) === STOP_TYPE_DELIVERY && (
                                                                <ArrowSmallDownIcon
                                                                    className={classNames("absolute inset-0", IsStopCompleted ? "text-white" : "text-tm-gray-500")}/>
                                                            )}

                                                            {Number(it.StopType) === STOP_TYPE_PICKUP_AND_DELIVERY && (
                                                                <ArrowsUpDownIcon
                                                                    className={classNames("absolute inset-0", IsStopCompleted ? "text-white" : "text-tm-gray-500")}/>
                                                            )}

                                                            {Number(it.StopType) === STOP_TYPE_STOP_BY && (
                                                                <StopIcon
                                                                    className={
                                                                        classNames(
                                                                            IsStopCompleted ? "bg-tm-green-600" : "bg-red-600",
                                                                            "w-6 h-6 p-1 rounded-md text-white"
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>


                                                <div
                                                    tabIndex="0"
                                                    onClick={() => onLocationClick(i, trailerLocationMap)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            onLocationEnterPress()
                                                        }
                                                    }}
                                                    className={
                                                        classNames(
                                                            hasAnyDate || isCompleteButtonVisible || canContinueLoad ? "justify-between" : "justify-center",
                                                            "relative flex flex-col rounded-l-card items-start px-0 grow hover:cursor-pointer",
                                                            IsStopBy && !IsSelected ? "" : undefined,
                                                            IsSelected ? "bg-tm-gray-100 border-primary border-y-2 border-l-2" : "bg-inverse border-tm-gray-300 border mr-5 shadow",
                                                            isCompleteButtonVisible || canContinueLoad ? "h-32" : "h-24"
                                                        )
                                                    }
                                                >
                                                    {hasAnyDate && (
                                                        <div
                                                            className={classNames(
                                                                IsSelected ? "w-[calc(100%+1px)]" : "w-full rounded-tr-card",
                                                                "text-right relative z-1 bottom-px text-tm-gray-500 rounded-l-card"
                                                            )}>
                                                            <div className="flex">
                                                                <div
                                                                    className="text-center grow h-5 leading-5 rounded-tl-card border-b border-tm-gray-300">
                                                                    {stopStartDateTime?.length ? stopStartDateTime :
                                                                        <span
                                                                            className="text-tm-gray-300">--/--/---- --:--</span>}
                                                                </div>

                                                                <div
                                                                    className="w-5 block border-b relative top-px h-[19px] grow-0 border-tm-gray-200"
                                                                >
                                                                    <svg
                                                                        className="h-full w-full text-tm-gray-300"
                                                                        viewBox="0 0 22 80"
                                                                        fill="none"
                                                                        preserveAspectRatio="none"
                                                                    >
                                                                        <path
                                                                            d="M0 -2L20 40L0 82"
                                                                            vectorEffect="non-scaling-stroke"
                                                                            stroke="currentcolor"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>

                                                                <div
                                                                    className={classNames(
                                                                        IsSelected ? "rounded-br-card border-r" : "rounded-tr-card",
                                                                        "text-center grow h-5 leading-5 border-b border-tm-gray-300"
                                                                    )}>
                                                                    {stopEndDateTime?.length ? stopEndDateTime :
                                                                        <span
                                                                            className="text-tm-gray-300">--/--/---- --:--</span>}
                                                                </div>
                                                            </div>

                                                            {/*{!!stopStartDateTime.length && !!stopEndDateTime.length ? " - " : ""}*/}

                                                        </div>
                                                    )}

                                                    <div className="flex py-1.5">
                                                        <div
                                                            className="flex flex-col gap-1 px-0.5">
                                                            {isRequiresAppointmentVisible && (
                                                                <button
                                                                    className="icon-btn flex items-center justify-center h-7 w-7 flex-shrink-0"
                                                                    onClick={() => onStopAppointmentChange("RequiresAppointment", !stops[it.index]?.RequiresAppointment?.value, i)}
                                                                    disabled={IsStopCompleted}
                                                                >
                                                                    {!stops?.[it.index]?.RequiresAppointment?.value && (
                                                                        <CalendarIcon className="w-5 h-5"/>
                                                                    )}

                                                                    {!!stops?.[it.index]?.RequiresAppointment?.value && (
                                                                        <CalendarSolidIcon
                                                                            className="w-5 h-5 text-primary"/>
                                                                    )}
                                                                </button>
                                                            )}

                                                            {areLocationsNotesVisible && (
                                                                <button
                                                                    onClick={() => {
                                                                        setIsNotesDialogVisible(true);
                                                                        setLocationNotes({
                                                                            title: it.metadata.Multistop,
                                                                            InternalNotes: it.metadata?.InternalNotes,
                                                                            DispatchDeliveryNotes: it.metadata?.DispatchDeliveryNotes,
                                                                            DispatchPickupNotes: it.metadata?.DispatchPickupNotes
                                                                        });
                                                                    }}
                                                                    className="icon-btn flex items-center justify-center h-7 w-7 flex-shrink-0"
                                                                >
                                                                    <InformationCircleIcon
                                                                        className="w-5 h-5 text-primary"/>
                                                                </button>
                                                            )}
                                                        </div>

                                                        <div
                                                            className={
                                                                classNames(
                                                                    isRequiresAppointmentVisible || areLocationsNotesVisible ? "pl-1.5" : "pl-10",
                                                                    "pr-1.5 h-full flex items-center"
                                                                )
                                                            }
                                                        >
                                                            <div className={
                                                                classNames(
                                                                    "leading-4 text-xs text-left w-full",
                                                                    IsSelected ? "text-tm-gray-900" : "text-tm-gray-500"
                                                                )}
                                                            >
                                                                {addressName}
                                                            </div>

                                                            {(hasErrors || shouldBeCompleted) && (
                                                                <div
                                                                    className="absolute flex items-center w-8 -right-1 top-0 bottom-0"
                                                                >
                                                                    <ExclamationTriangleIcon
                                                                        className={
                                                                            classNames(
                                                                                "w-8 h-8 p-1.5 text-red-600 absolute bg-inverse rounded-full",
                                                                                !IsSelected ? "border-r border-tm-gray-300 group-hover:border-primary -right-2" : "right-2"
                                                                            )}
                                                                    />
                                                                </div>
                                                            )}

                                                            {!(hasErrors || shouldBeCompleted) && canDropLoad && Number(it.StopType) === STOP_TYPE_STOP_BY && (
                                                                <div
                                                                    className="absolute flex items-center w-8 -right-1 top-0 bottom-0"
                                                                >
                                                                    <PopOver
                                                                        className={
                                                                            classNames(
                                                                                " text-tm-gray-700 absolute bg-inverse rounded-full",
                                                                                !IsSelected ? "border-r border-tm-gray-300 group-hover:border-primary -right-2" : "right-2"
                                                                            )}
                                                                        btnClass="w-8 h-8 p-1.5"
                                                                        BtnIcon={EllipsisVerticalIcon}
                                                                        btnIconClass="h-5 w-5 text-primary"
                                                                        widthClass="w-[calc(100vw-2.5rem)] md:max-w-[50vw] lg:max-w-lg"
                                                                        positionClass="z-20 fixed md:absolute right-0 mx-5 md:mx-0 translate-x-0 "
                                                                    >
                                                                        <div
                                                                            className="bg-popup border border-tm-gray-300 rounded-card p-4">
                                                                            <button
                                                                                onClick={() => {
                                                                                    if (dropLoadButtonRef.current) {
                                                                                        dropLoadButtonRef.current.click();
                                                                                    }
                                                                                }}
                                                                                className="btn btn-text w-full hover:bg-tm-gray-100 p-3 justify-start text-base">
                                                                                Drop without exchange at this location
                                                                            </button>
                                                                        </div>
                                                                    </PopOver>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>

                                                    {canBeCompleted && (
                                                        <DropLoadButton
                                                            innerRef={dropLoadButtonRef}
                                                            isLoadDirty={isStateDirty}
                                                            translate={translate}
                                                            stop={it}
                                                            isLoading={isLoading}
                                                            buttonClass={classNames(
                                                                !dropLoadParams.IsDropped ? "hidden" : undefined,
                                                                IsSelected ? "bg-inverse border-t rounded-tr-card border-r w-[calc(100%+1px)] bg-green-600 text-white hover:bg-green-700" : "bg-green-600 text-white w-full animate-pulse",
                                                                "relative flex-shrink-0 z-1 btn border-tm-gray-300 rounded-bl-card items-center justify-center gap-x-2 px-4 h-8 pr-5"
                                                            )}
                                                            IsDropped={dropLoadParams.IsDropped}
                                                            initialLoadStatusID={dropLoadParams.initialLoadStatusID}
                                                            LoadID={dropLoadParams.LoadID}
                                                            DriverID={dropLoadParams.DriverID}
                                                            CoDriverID={dropLoadParams.CoDriverID}
                                                            TruckID={dropLoadParams.TruckID}
                                                            TrailerID={dropLoadParams.TrailerID}
                                                            getQuery={dropLoadParams.getQuery}

                                                        />
                                                    )}

                                                    {isCompleteButtonVisible && (
                                                        <>
                                                            {!IsStopCompleted && isDispatched && (
                                                                <Tippy
                                                                    disabled={!completeButtonMessage}
                                                                    content={completeButtonMessage}
                                                                >
                                                                    <span className="w-full">
                                                                        <button
                                                                            ref={(ref) => {
                                                                                if (!completeStopButtonRef[i]) {
                                                                                    completeStopButtonRef[i] = React.createRef();
                                                                                }

                                                                                completeStopButtonRef[i].current = ref;
                                                                            }}
                                                                            className={classNames(
                                                                                IsSelected
                                                                                    ? isStateDirty || !isPreviousStopCompleted
                                                                                        ? "bg-inverse border-t rounded-tr-card border-r w-[calc(100%+1px)]  text-tm-gray-500"
                                                                                        : "bg-inverse border-t rounded-tr-card border-r w-[calc(100%+1px)] hover:bg-primary hover:text-primary-contrast text-tm-gray-900"
                                                                                    : "text-tm-gray-500 w-full",
                                                                                "relative flex-shrink-0 z-1 btn border-tm-gray-300 rounded-bl-card h-8 pr-5 items-center justify-center gap-x-2 px-4"
                                                                            )}
                                                                            onClick={() => {
                                                                                if (!IsSelected || isStateDirty || !isPreviousStopCompleted) {
                                                                                    return onLocationClick(i, trailerLocationMap)
                                                                                } else if (stopsCombined[selectedCombinedStopIndex].StopType) {
                                                                                    onCompleteStopClick(stopsCombined[selectedCombinedStopIndex])
                                                                                } else {
                                                                                    onCompleteStopByClick(stopsCombined[selectedCombinedStopIndex])
                                                                                }
                                                                            }}
                                                                        >
                                                                            <React.Fragment>
                                                                                <FlagIcon
                                                                                    className="w-5 h-5 text-tm-primary"/>
                                                                                {translate("btn.set_completed")}
                                                                            </React.Fragment>
                                                                        </button>
                                                                    </span>
                                                                </Tippy>
                                                            )}


                                                            {!!IsStopCompleted && isDispatched && (
                                                                <Tippy
                                                                    disabled={!completeButtonMessage}
                                                                    content={completeButtonMessage}
                                                                >
                                                                <span className="w-full">
                                                                    <button
                                                                        ref={(ref) => {
                                                                            if (!completeStopButtonRef[i]) {
                                                                                completeStopButtonRef[i] = React.createRef();
                                                                            }

                                                                            completeStopButtonRef[i].current = ref;
                                                                        }}
                                                                        className={classNames(
                                                                            IsSelected ? "bg-inverse border-t border-b-2 border-b-primary rounded-tr-card border-r w-[calc(100%+1px)] hover:text-primary hover:border-primary" : " text-tm-gray-600 w-full border-t",
                                                                            "relative divide-x divide-tm-gray-200 flex-shrink-0 z-1 btn border-tm-gray-300 rounded-bl-card h-full items-center justify-center gap-x-2 px-2"
                                                                        )}
                                                                        onClick={() => {
                                                                            if (!IsSelected) {
                                                                                return onLocationClick(i, trailerLocationMap);
                                                                            } else if (stopsCombined[selectedCombinedStopIndex].StopType) {
                                                                                onCompleteStopClick(stopsCombined[selectedCombinedStopIndex])
                                                                            } else {
                                                                                onCompleteStopByClick(stopsCombined[selectedCombinedStopIndex])
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="flex">
                                                                            <CheckIcon
                                                                                className="w-5 h-5 text-green-600"/>

                                                                            <div className="flex flex-col items-start">
                                                                                <span className={
                                                                                    classNames(
                                                                                        "text-sm font-bold"
                                                                                    )}
                                                                                >
                                                                                    {translate("text.stop_completed")}
                                                                                </span>
                                                                            </div>
                                                                        </div>


                                                                          <span
                                                                              className={
                                                                                  classNames(
                                                                                      isNotOnTime ? "text-red-600" : "text-tm-gray-500",
                                                                                      "flex px-2 items-center h-8 ml-1 text-xs relative grow"
                                                                                  )
                                                                              }
                                                                          >
                                                                                {!!arrivalDate && (
                                                                                    <div
                                                                                        className="flex items-center justify-center grow leading-3">
                                                                                        <div
                                                                                            className="font-bold mr-1">{arrivalDate[0]}</div>
                                                                                        <div
                                                                                            className="text-center">{arrivalDate[1]}</div>
                                                                                    </div>
                                                                                )}

                                                                              {!!arrivalDate && !!departureDate && (
                                                                                  <svg
                                                                                      className="h-[calc(100%-1px)] w-5 text-tm-gray-300 mx-1"
                                                                                      viewBox="0 0 22 80"
                                                                                      fill="none"
                                                                                      preserveAspectRatio="none"
                                                                                  >
                                                                                      <path
                                                                                          d="M0 -2L20 40L0 82"
                                                                                          vectorEffect="non-scaling-stroke"
                                                                                          stroke="currentcolor"
                                                                                          strokeLinejoin="round"
                                                                                      />
                                                                                  </svg>
                                                                              )}
                                                                              {!!departureDate && (
                                                                                  <div
                                                                                      className="flex items-center justify-center grow leading-3">
                                                                                      {!!departureDate[0] && departureDate[0] !== arrivalDate[0] && (
                                                                                          <div
                                                                                              className="font-bold mr-1">{departureDate[0]}</div>
                                                                                      )}
                                                                                      <div
                                                                                          className="text-center">{departureDate[1]}</div>
                                                                                  </div>
                                                                              )}
                                                                                </span>
                                                                    </button>
                                                                </span>
                                                                </Tippy>
                                                            )}

                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="space-y-2">
                                <div className="mt-6 pl-4">
                                    <button
                                        className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                        onClick={onAddLocationClick}
                                    >
                                        <PlusCircleIcon className="w-5 h-5"/>
                                        {translate("btn.add_location")}
                                    </button>
                                </div>

                                {!isQuote && (
                                    <div className=" pl-4">
                                        <button
                                            className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                            onClick={onAddStopByClick}
                                        >
                                            <PlusCircleIcon className="w-5 h-5"/>
                                            {translate("btn.add_stop_by")}
                                        </button>
                                    </div>
                                )}

                            </div>
                        </div>
                    )}
                </div>
            </div>

            {isSectionExpanded && !hasLocations && (
                <div className="text-center col-span-full pb-6">
                    <NoRecords
                        buttonRef={stepsAddStopsRef}
                        show={true}
                        addClass="p-4"
                        title="No Stops"
                        text={"You haven’t added any stops yet."}
                        btnLabel="Add stops"
                        onBtnClick={onAddStopsClick}
                    />
                </div>
            )}

            {isSectionExpanded && hasLocations && (
                <div
                    className="my-2 mr-2 rounded-card pl-5 col-span-full xl:col-span-6 3xl:col-span-7 space-y-2 bg-tm-gray-100 pr-6 border-2 border-primary-tint pb-5">
                    <div className="sticky top-0 z-10">
                        <div className="pt-3">
                            <div className="flex relative">

                                <div className="min-h-[3.5rem] flex items-center pr-4">
                                    {selectedStopName}
                                </div>

                                <div className="ml-auto flex items-center gap-x-2 flex-shrink-0">
                                    {IsStopBySelected && (
                                        <React.Fragment>
                                            <Tippy
                                                content={translate("btn.update_location")}
                                                delay={[400, 0]}
                                                trigger={"mouseenter"}
                                            >
                                                <button
                                                    className="btn btn-icon"
                                                    onClick={() => onSelectLocationClick(stopBys[selectedLocationIndex])}
                                                >
                                                    <MapPinIcon className="w-5 h-5"/>
                                                </button>
                                            </Tippy>

                                            {!(IsSelectedStopCompleted || IsSelectedLoadDropped) && (
                                                <button
                                                    className="btn btn-close bg-tm-gray-100 hover:bg-tm-gray-300 ml-3"
                                                    onClick={() => onDeleteStopByClick(selectedCombinedStopIndex)}
                                                >
                                                    <XMarkIcon className="w-5 h-5"/>
                                                </button>
                                            )}
                                        </React.Fragment>

                                    )}

                                    {!IsStopBySelected && (
                                        <React.Fragment>
                                            {(stops?.[selectedLocationIndex]?.StopID?.value?.value != -1 && stops?.[selectedLocationIndex]?.StopID?.value?.value != null) && !isLoadPlaning && (
                                                <ButtonIcon
                                                    appearance="border"
                                                    tooltip={translate("btn.edit_location")}
                                                    onClick={() => toggleLocationDialog(stops[selectedLocationIndex]?.LocationData?.value, stops[selectedLocationIndex])}
                                                    hasPerm={checkPerm(Resources.Locations, CREATE_PERM)}
                                                >
                                                    <PencilIcon className="h-4 w-4 text-tm-gray-500"/>
                                                </ButtonIcon>
                                            )}

                                            {(selectedLocationIndex !== undefined && !!stops[selectedLocationIndex]?.LocationData?.value?.ReceivingHours?.length || !!stops[selectedLocationIndex]?.LocationData?.value?.ShippingHours?.length) && (
                                                <ButtonIcon
                                                    appearance="border"
                                                    tooltip={translate("btn.working_hours")}
                                                    onClick={toggleWorkingHoursModal}
                                                    hasPerm={checkPerm(Resources.LocationsContacts, READ_PERM)}
                                                >
                                                    <ClockIcon className="h-4 w-4 text-tm-gray-500"/>
                                                </ButtonIcon>
                                            )}

                                            {(stops?.[selectedLocationIndex]?.StopID?.value?.value != -1 && stops?.[selectedLocationIndex]?.StopID?.value?.value != null) && !isLoadPlaning && (
                                                <ButtonIcon
                                                    appearance="border"
                                                    tooltip={translate("btn.contacts")}
                                                    onClick={() => toggleContactsDialog(stops[selectedLocationIndex])}
                                                    hasPerm={checkPerm(Resources.LocationsContacts, READ_PERM)}
                                                >
                                                    <UserGroupIcon className="h-4 w-4 text-tm-gray-500"/>
                                                </ButtonIcon>
                                            )}

                                            {numberOfStops > 2 && !IsSelectedStopCompleted && (
                                                <button
                                                    className="btn btn-close bg-tm-gray-100 hover:bg-tm-gray-300 ml-3"
                                                    onClick={() => onDeleteLocationClick(selectedCombinedStopIndex)}
                                                >
                                                    <XMarkIcon className="w-5 h-5"/>
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                classNames(
                                    "grid grid-cols-12 gap-4",
                                    IsStopBySelected ? "mt-6" : undefined
                                )
                            }
                        >
                            {/* Stop offs */}
                            {IsStopBySelected && (
                                <React.Fragment>
                                    <FieldsToHtml
                                        fieldsState={stopBys[selectedLocationIndex]}
                                        onInputChange={onStopByInputChange} // include fields

                                        includeFields={stopByFields}
                                        translate={translate}
                                        selects={fieldSelects}
                                    />

                                    {areLocationAssetsVisible && !isQuote && (
                                        <React.Fragment>
                                            <div className="text-lg leading-8 col-span-full">
                                                Location assets
                                            </div>

                                            <div
                                                className="px-4 py-2 rounded-btn border border-tm-gray-200 col-span-full grid gap-4 grid-cols-12 bg-inverse">
                                                {!!trailerLocationMap[selectedCombinedStopIndex + 1] && (
                                                    <FieldsToHtml
                                                        fieldsState={stopBys[selectedLocationIndex]}
                                                        includeFields={getStopByDropTrailerFields(selectedLocationIndex)}
                                                        onInputChange={onStopByInputChange}
                                                        translate={translate}
                                                        selects={fieldSelects}
                                                    />
                                                )}

                                                {!trailerLocationMap[selectedCombinedStopIndex + 1] && (
                                                    <FieldsToHtml
                                                        fieldsState={stopBys[selectedLocationIndex]}
                                                        includeFields={getStopByPickUpTrailerFields(selectedLocationIndex)}
                                                        onInputChange={onStopByInputChange}
                                                        translate={translate}
                                                        selects={fieldSelects}
                                                    />
                                                )}
                                            </div>

                                            {selectedCombinedStopIndex !== 0 && selectedCombinedStopIndex !== stopsCombined.length - 1 && (
                                                <div
                                                    className="px-4 py-2 rounded-btn border border-tm-gray-200 col-span-full grid gap-4 grid-cols-12 bg-inverse">
                                                    <FieldsToHtml
                                                        fieldsState={stopBys[selectedLocationIndex]}
                                                        includeFields={getStopBysSwitchDispatchFields(selectedLocationIndex)}
                                                        onInputChange={onStopByInputChange}
                                                        translate={translate}
                                                        selects={fieldSelects}
                                                    />

                                                    {!stopBys[selectedLocationIndex]?.IsStopCompleted.value && !!stopBys?.[selectedLocationIndex]?.IsSwitchDispatch?.value && (
                                                        <div className="col-span-full">
                                                            <button
                                                                className="btn flex gap-2 text-xs  py-1 text-tm-gray-700 hover:text-primary"
                                                                onClick={() => setIsDispatchManagerDialogVisible(true)}
                                                            >
                                                                <Cog6ToothIcon className="w-5 h-5"/>
                                                                {translate("text.select_from_dispatch_manager")}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}

                            {/* Regular stops */}
                            {!IsStopBySelected && (
                                <React.Fragment>
                                    <div ref={stepsLocationsFormRef}/>

                                    <FieldsToHtml
                                        fieldsState={stops[selectedLocationIndex]}
                                        includeFields={stopFields}
                                        onInputChange={onStopInputChange}
                                        translate={translate}
                                        selects={fieldSelects}
                                        fieldRefs={stopFieldsRefs}
                                    />

                                    {areLocationAssetsVisible && !isQuote && (
                                        <React.Fragment>
                                            <div className="text-lg leading-8 col-span-full">
                                                Location assets
                                            </div>

                                            <div
                                                className="px-4 py-2 rounded-btn border border-tm-gray-200 col-span-full grid gap-4 grid-cols-12 bg-inverse">
                                                {!!trailerLocationMap[selectedCombinedStopIndex + 1] && (
                                                    <FieldsToHtml
                                                        fieldsState={stops[selectedLocationIndex]}
                                                        includeFields={getDropTrailerFields(selectedLocationIndex)}
                                                        onInputChange={onStopInputChange}
                                                        translate={translate}
                                                        selects={fieldSelects}
                                                    />
                                                )}

                                                {!trailerLocationMap[selectedCombinedStopIndex + 1] && (
                                                    <FieldsToHtml
                                                        fieldsState={stops[selectedLocationIndex]}
                                                        includeFields={getPickUpTrailerFields(selectedLocationIndex)}
                                                        onInputChange={onStopInputChange}
                                                        translate={translate}
                                                        selects={fieldSelects}
                                                    />
                                                )}
                                            </div>

                                            {selectedCombinedStopIndex !== stopsCombined.length - 1 && (
                                                <div
                                                    className="px-4 py-2 rounded-btn border border-tm-gray-200 col-span-full grid gap-4 grid-cols-12 bg-inverse">
                                                    <FieldsToHtml
                                                        fieldsState={stops[selectedLocationIndex]}
                                                        includeFields={getSwitchDispatchFields(selectedLocationIndex)}
                                                        onInputChange={onStopInputChange}
                                                        translate={translate}
                                                        selects={fieldSelects}
                                                    />

                                                    {!stops[selectedLocationIndex]?.IsStopCompleted.value && !!stops?.[selectedLocationIndex]?.IsSwitchDispatch?.value && (
                                                        <div className="col-span-full">
                                                            <button
                                                                className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                                                onClick={() => setIsDispatchManagerDialogVisible(true)}
                                                            >
                                                                <Cog6ToothIcon className="w-5 h-5"/>
                                                                {translate("text.select_from_dispatch_manager")}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>

                        {!IsStopBySelected && (
                            <div className="">
                                {shouldSelectedStopBeCompleted && (
                                    <div className="flex justify-end">
                                        <InfoParagraph
                                            className="mt-4"
                                            type="danger"
                                            message={translate("text.stop_should_be_completed")}
                                        />
                                    </div>
                                )}

                                <div className="mt-4 flex items-center justify-end h-14">
                                    {((isCovered && !selectedLocationIndex) || isDispatched) && (
                                        <div className="flex items-center gap-4 w-full">
                                            <Tippy
                                                content={translate("text.stop_update_disabled")}
                                                disabled={!isStateDirty}
                                            >
                                                <span>
                                                    <button
                                                        className="btn btn-text gap-2 text-tm-gray-700 hover:bg-tm-gray-50"
                                                        onClick={() => onUpdateStopClick(stop)}
                                                        disabled={isStateDirty}
                                                    >
                                                        <PencilSquareIcon className="w-5 h-5 text-tm-gray-400"/>
                                                        {translate("btn.post_update")}
                                                    </button>
                                                </span>
                                            </Tippy>
                                        </div>
                                    )}

                                    {stopsCombined.length !== selectedLocationIndex + 1 && !IsSelectedStopCompleted && (
                                        <button
                                            className="btn-icon ml-2"
                                            onClick={() => onLocationClick(selectedLocationIndex + 1, trailerLocationMap)}
                                        >
                                            <ChevronRightIcon className="w-5 h-5"/>
                                        </button>
                                    )}
                                </div>

                                {!!stops[selectedLocationIndex]?.IsStopCompleted?.errorMessage && (
                                    <InfoBar type="warning">
                                        {translate("message.confirm_delivered_status_not_completed")}
                                    </InfoBar>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {isLoading && (
                <React.Fragment>
                    {isSectionExpanded && (
                        <LoaderLarge stripesBg={true}/>
                    )}

                    {!isSectionExpanded && (
                        <Loader stripesBg={true}/>
                    )}
                </React.Fragment>
            )}

            <ResourceTableDialogWithFilters
                show={isDispatchManagerDialogVisible}
                widthClass={"max-w-[1366px]"}
                dialogResource={dialogResource}
                initialFocusRef={dispatchManagerInitialFocusRef}
                resource={Resources.Units}
                title={translate("text.select_from_dispatch_manager")}
                dispatch={dispatch}
                fields={GetDispatchManagerFields(translate, dispatch)}
                fieldsFilter={{
                    query: new Field('query', '', [''], false, 'search', {
                        addContainerClass: "col-span-2",
                        hideLabel: true,
                        labelType: "float"
                    }, {autoFocus: true}),
                    offset: new Field('offset', 0, [''], false, 'hidden'),
                    limit: new Field("limit", 10, [''], false, 'select', {hideLabel: true, labelType: "float"}),
                    sort: new Field('sort', "ASC", [''], false, 'hidden'),
                    sortBy: new Field('sortBy', "UnitID", [''], false, 'hidden'),
                    DriverID: new Field('DriverID', '', [], false, 'select-search', {addContainerClass: "col-span-3 col-start-1"}, {isClearable: true}),
                    TruckID: new Field('TruckID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),

                    DispatchContactID: new Field('DispatchContactID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),
                    StatusID: new Field('StatusID', '', [], false, 'select', {addContainerClass: "col-span-3"}, {isClearable: true}),
                    IsTeamDriver: new Field('IsTeamDriver', '', [], false, 'checkbox', {
                        addContainerClass: "col-span-2",
                        hideLabel: true,
                        labelType: "float"
                    }, {isClearable: true}),
                    StatusMismatch: new Field('StatusMismatch', '', [], false, 'checkbox', {
                        addContainerClass: "col-span-2",
                        hideLabel: true,
                        labelType: "float"
                    }, {isClearable: true})
                }}
                defaultAction={(it) => {
                    applySelectedLocationUnit(it, selectedLocationIndex, IsStopBySelected);
                    setIsDispatchManagerDialogVisible(false);
                }}
                onCustomActions={[
                    {
                        tooltipText: () => translate('btn.select_unit'),
                        action: (it) => {
                            applySelectedLocationUnit(it, selectedLocationIndex, IsStopBySelected);
                            setIsDispatchManagerDialogVisible(false);
                        },
                        icon: ArrowDownTrayIcon
                    }
                ]}
                onClose={() => setIsDispatchManagerDialogVisible(false)}
                selects={{
                    DriverID: {
                        api: 'api/' + Resources.DriversQuick,
                        query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                        searchMap: (item) => ({
                            value: item.DriverID,
                            label: item.FirstName + ' ' + item.LastName
                        })
                    },
                    CoDriverID: {
                        api: 'api/' + Resources.DriversQuick,
                        query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                        searchMap: (item) => ({
                            value: item.DriverID,
                            label: item.FirstName + ' ' + item.LastName
                        })
                    },
                    TruckID: {
                        api: 'api/' + Resources.TrucksQuick,
                        query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                        customizeList: (list) => {
                            return groupListBySCAC(list, 'Truck')
                        }
                    },
                    TrailerID: {
                        api: 'api/' + Resources.TrailersQuick,
                        query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {NotOnLoadInAction: 1}),
                        customizeList: (list) => {
                            return groupListBySCAC(list, 'Trailer')
                        }
                    },
                    DispatchContactID: {
                        api: "api/" + Resources.ContactsQuick,
                        query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {IsDispatch: 1}),
                        searchMap: (item) => ({
                            value: item.ContactID,
                            label: item.FirstName + " " + item.LastName,
                            Contact: item
                        })
                    },
                    StatusID: DRIVER_STATUS_TYPES
                }}
                translate={translate}
            />

            <ModalDefault
                show={isNotesDialogVisible}
                title={translate("modal_heading.stop_notes")}
                widthClass={"max-w-xl"}
                translate={translate}
                onClose={() => setIsNotesDialogVisible(false)}
                closeButtonLabel={translate('btn.close')}
            >
                <div className="p-0.5 bg-tm-gray-100">
                    <InfoBar Icon={MapPinIcon}>
                        {locationNotes?.title}
                    </InfoBar>
                </div>

                <div className="p-5 bg-tm-gray-100">
                    {
                        Object.keys(locationNotes)
                            .filter(it => !!locationNotes[it] && it !== 'title')
                            .map(it => {
                                return (
                                    <div key={it} className="mb-8">
                                        <div className="flex gap-x-4 items-center mb-4">
                                            <p className="text-lg">{translate("field." + it)}</p>

                                            <CopyToClipboardButton
                                                clipboardText={locationNotes[it]}
                                                translate={translate}
                                            />
                                        </div>

                                        <div
                                            className="text-base p-5 bg-inverse rounded-card whitespace-pre-line shadow"
                                        >
                                            {locationNotes[it]}
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </ModalDefault>

            <ModalDefault
                show={isStopUpdatesDialogOpen}
                title={translate("modal_heading.stop_notes")}
                widthClass={"max-w-xl"}
                limitHeight={true}
                translate={translate}
                onClose={() => setIsStopUpdatesDialogOpen(false)}
                closeButtonLabel={translate('btn.close')}
            >
                <div className="bg-tm-gray-50">
                    <LoadsEventsList
                        events={stopUpdates}
                        isLoading={isLoading}
                        translate={translate}
                    />
                </div>
            </ModalDefault>
        </div>
    )
}

function getArrivalDate(date) {
    if (!date) return undefined;

    const dateTime = toFrontDateTime(date).split(" ");

    const dateOnly = dateTime.shift();

    const time = dateTime.join(" ");

    return [dateOnly, time];
}
