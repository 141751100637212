import React, { Component } from 'react'
import { connect } from 'react-redux'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import { getResource, updateResource } from '../../../data/actions/resource'
import { Field, FieldsManager } from '../../../data/services/fields'
import axios from 'axios'
import Env from '../../../util/env'
import { processResponse } from '../../../data/services/api-util'
import { getSecondResource } from '../../../data/actions/secondResource'
import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import { getProp } from '../../../common/util/util-helpers'
import FieldDropdownSelect from "../../../common/components/fields/field-dropdown-select";
import RichTextEditor from "../../../common/components/fields/rich-text-editor";
import FieldText from "../../../common/components/fields/field-text";
import Card from "../../../common/components/card";
import {LoaderSmall} from "../../../common/components/loader";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import slateHTMLToValue from "../../../common/components/fields/rich-text-editor/slate-deserialize";
import {getJWT} from "../../../common/util/util-auth";


class ArticlesView extends Component {
    constructor (props) {
        super(props)
        this.state = {
            expanded: getProp(this.props.resource, 'data.list', []),
            selectedItem: null,
            fields: {},
            query: '',
        }
    }

    componentDidMount () {
        this.fetchData()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevState.selectedItem !== this.state.selectedItem) {
            this.updateFields()
        }
        if (prevState.query !== this.state.query) {
            this.fetchData()
        }
        if (prevProps.secondResource !== this.props.secondResource && !!this.props.secondResource.data) {
            this.updateFields()
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Help,
            query: {
                query: this.state.query
            }
        }))
    }

    fetchSingle = (item) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.Help,
            query: {
                pageKey: item.PageKey
            }
        }))
    }

    submit = (event) => {
        event && event.preventDefault()
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            initialContent: undefined
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        HelpArticleParentID: this.state.fields.HelpArticleParentID.value?.value
                    }),
                    resource: Resources.Help,
                    piggyResource: Resources.Help,
                    request: 'PUT',
                    errorMessage: true, successMessage: 'Article Updated Successfully!'
                }))
            }
        })
    }

    updateFields = () => {
        let data = getProp(this.props.secondResource, 'data.list', {})

        this.setState({
            fields: {
                HelpArticleParentID: new Field('HelpArticleParentID', data.HelpArticleParentID, ['']),
                Content: new Field('Content', slateHTMLToValue(data.Content), ['empty'], false, 'rich-text'),
                Keywords: new Field('Keywords', data.Keywords, ['empty']),
                PageKey: new Field('PageKey', data.PageKey, ['']),
                Title: new Field('Title', data.Title, ['empty']),
            }
        })
    }

    handleInputChange = (name, value) => {
        this.setState({ canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value) })
    }

    setDirty = () => {
        this.setState({ canSubmit: true })
    }

    returnMenuItem = (it) => {
        return (
            it.childrens?.length > 0 ?
                <li key={it.HelpArticleID} className="m-0">
                    <div
                        onClick={() => {
                            this.fetchSingle(it)
                            this.setState({ [`test${it.HelpArticleID}`]: !this.state[`test${it.HelpArticleID}`] })
                        }}
                        className="px-3 py-2 flex items-center border-b border-tm-gray-200"
                    >
                        {it.Title}

                        <div className="ml-auto">
                            {this.state[`test${it.HelpArticleID}`]
                                ? <ChevronRightIcon className="h-5 w-5 text-tm-gray-700"/>
                                : <ChevronDownIcon className="h-5 w-5 text-tm-gray-700"/>
                            }
                        </div>
                    </div>

                    {this.state[`test${it.HelpArticleID}`] && (
                        <ul className="">
                            {it.childrens.map((item, index) => this.returnMenuItem(item, index))}
                        </ul>
                    )}
                </li>
                : (
                    <li key={it.Title}>
                        <div
                            onClick={() => this.fetchSingle(it)}
                            className="px-3 py-2 flex items-center border-b border-tm-gray-200">
                            {it.Title}
                        </div>
                    </li>
                )
        )
    }

    render () {
        const { translate, resource, secondResource } = this.props
        const dataItems = Array.isArray(getProp(resource, 'data.list', [])) && getProp(resource, 'data.list', []).map((it, i) => {
            return this.returnMenuItem(it, i)
        })

        return (
            <Layout {...this.props}>
                <Page>
                    <PageHeader
                        title={translate('text.articles')}
                    />

                    <div className="">
                        <label className="form-group has-float-label mb-4">
                            <input type="text"
                                   className="form-control max-w-lg"
                                   value={this.state.query}
                                   placeholder="Search..."
                                   onChange={(e) => this.setState({ query: e.target.value })}
                            />
                            <span>{translate('field.Search')}</span>
                        </label>
                    </div>

                    {(resource.isLoading || secondResource.isLoading) && (
                        <div className="p-6 text-center">
                            <LoaderSmall/>
                        </div>
                    )}
                    {!resource.isLoading && !secondResource.isLoading && (
                        <div className="">
                            <div className="">
                                <div className="xl:grid gap-4 grid-cols-3 space-y-5 xl:space-y-0">
                                    <div className="">
                                        <Card bodyClass="p-0">
                                            <div className="">
                                                <ul className={''}>
                                                    {dataItems}
                                                </ul>
                                            </div>
                                        </Card>
                                    </div>

                                    <div className="col-span-1 xl:col-span-2">
                                        <Card bodyClass="py-8 px-6">
                                            <React.Fragment>
                                                {getProp(this.props.secondResource, 'data.list', false) && !this.props.secondResource.isLoading && (
                                                    <>
                                                        <div className="grid gap-4 grid-cols-6">
                                                            <div className="col-span-6 lg:col-span-4">
                                                                <div className="form-group">
                                                                    <label
                                                                        className="label-float">{translate('field.Title')} *</label>

                                                                    <FieldText
                                                                        addClass={'form-control'}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.Title}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-span-6 lg:col-span-2">
                                                                <div className="form-group">
                                                                    <label
                                                                        className="label-float">{translate('field.Keywords')} *</label>

                                                                    <FieldText
                                                                        addClass={'form-control'}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.Keywords}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-span-6 lg:col-span-4">
                                                                <div className="col-span-6 lg:col-span-3">
                                                                    <div className="form-group">
                                                                        <label
                                                                            className="label-float">{translate('field.HelpArticleParentID')} *</label>

                                                                        <FieldDropdownSelect
                                                                            onChange={this.handleInputChange}
                                                                            {...this.state.fields.HelpArticleParentID}
                                                                            addClass="form-control"
                                                                            isClearable
                                                                            defaultOptions={true}
                                                                            placeholder={''}
                                                                            loadOptions={
                                                                                (inputValue, callback) => {
                                                                                    axios.get(
                                                                                        Env.getApiUrl('api/' + Resources.Help, {
                                                                                            query: inputValue,
                                                                                            flat: 1
                                                                                        }),
                                                                                        {
                                                                                            headers: {
                                                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                        .then((response) => {
                                                                                            const result = processResponse(response)
                                                                                            if (result && result.status === 0) {
                                                                                                const list = result.data.map((it) => {
                                                                                                    if (it.HelpArticleID == this.state.fields.HelpArticleParentID?.value) {
                                                                                                        this.handleInputChange('HelpArticleParentID', {
                                                                                                            label: it.Title,
                                                                                                            value: it.HelpArticleID
                                                                                                        })
                                                                                                    }
                                                                                                    return {
                                                                                                        label: it.Title,
                                                                                                        value: it.HelpArticleID
                                                                                                    }
                                                                                                })
                                                                                                list.push({
                                                                                                    label: 'No Parent',
                                                                                                    value: null
                                                                                                })
                                                                                                callback(list)
                                                                                            }
                                                                                        })
                                                                                }
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mt-4 prose text-sm">
                                                            <label
                                                                className="label-float">{translate('field.Content')} *</label>

                                                            <RichTextEditor
                                                                name={'Content'}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.fields?.Content?.value}
                                                                placeholder={translate("text.editor_no_content")}
                                                            />
                                                        </div>

                                                        <div className="flex justify-end mt-6">
                                                            <button className={'btn btn-outline'}
                                                                    onClick={this.updateFields}>{translate('btn.cancel')}
                                                            </button>
                                                            <button
                                                                className={'btn btn-primary ml-3  ' + (this.state.canSubmit ? '' : ' disabled')}
                                                                onClick={this.submit}>{translate('btn.update')}
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(ArticlesView)

