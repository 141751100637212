import React, {Component} from 'react'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM} from '../../../common/util/util-consts'
import {createResource, deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class DriverPreferencesTab extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            searchFields: this.props.searchFields ?? {},

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            confirmModalOpen: false,

            PreferenceTypeSubItem: {}
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        !!this.getID() && this.fetchData()
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    /** UI Events
     ================================================================= */
    handleSubmit = (params) => {
        if (!!this.getID()) {
            let paramsTmp = FieldsManager.getFieldKeyValues(params)
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, paramsTmp,
                    {
                        id: this.getID(),
                        Preferences: Array.isArray(paramsTmp.PreferenceTypeSubItem) ? paramsTmp.PreferenceTypeSubItem.map(it => {
                            return it.__isNew__ ? {
                                value: -1,
                                label: it.label,
                            } : it
                        }) : []
                    }),
                query: this.getQuery(),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName(),
                errorMessage: true, successMessage: `Driver Preference has been created.`,
                refreshLocalStorageLookups: paramsTmp.PreferenceTypeSubItem.some(it => !!it.__isNew__)
            }))
        } else {
            this.props.handleAddNewPreference(params)
        }
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            errorMessage: ''
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            errorMessage: ''
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleInputChange = (fields, name, value) => {
        let fieldsTmp = FieldsManager.updateField(fields, name, value)
        if (name === 'PreferenceTypeID') {
            fieldsTmp = FieldsManager.updateField(fields, 'PreferenceTypeSubItem', '')
            const PreferenceTypeSubItems = getProp(LocalStorage.get('lookup'), 'PreferenceType', []).find(it => it.PreferenceTypeID == value)
            fieldsTmp['PreferenceTypeSubItem'].props.values = PreferenceTypeSubItems.PreferenceTypeSubItems.reduce(
                (memo, item) => {
                    memo[item['PreferenceTypeSubItemID']] = item['PreferenceTypeSubItem']
                    return memo
                }, {})
        }
        return fieldsTmp
    }
    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.match.params.id
    }

    getPrimaryKey = () => {
        return 'PreferenceTypeItemID'
    }

    getResourceName = () => {
        return Resources.DriverPreferences
    }

    getQuery = () => {
        return {
            id: this.getID()
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            PreferenceTypeID: new Field('PreferenceTypeID', '', ['empty'], false, 'select', {
                addContainerClass: 'col-span-full lg:col-span-3',
                omitSort: true
            }),
            PreferenceTypeSubItem: new Field("PreferenceTypeSubItem", '', ['empty'], false, 'creatable', {
                addContainerClass: "col-span-full"
            }, {
                isMulti: true
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full lg:col-span-3',
                omitSort: true
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFormatedPropValues = () => {
        const PreferenceType = getLookup('PreferenceType')
        return this.props.fields_willing.map(it => {
            return {
                PreferenceType: PreferenceType[it.PreferenceTypeID.value],
                PreferenceTypeID: it.PreferenceTypeID.value,
                PreferenceTypeSubItem: it.PreferenceTypeSubItem.value.map(it => it.label).join(', '),
                Notes: it.Notes.value
            }
        })
    }

    getPreferenceType = () => {
        return getProp(LocalStorage.get('lookup'), 'PreferenceType', []).reduce((memo, it) => {
            memo[it.PreferenceTypeID] = `${it.PreferenceType}${it.PreferenceTypeSubItems.length === 0 ? ' (Has no SubItems)' : ''}`
            return memo
        }, {})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const metadata = {
            PreferenceTypeID: this.getPreferenceType(),
            PreferenceTypeSubItem: {}
        }

        const data = !!this.getID() ? getProp(this.props, 'resource.data.list', []) : this.getFormatedPropValues()
        const count = getProp(this.props, 'resource.data.count', 0)
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <div>
                <div className={"mb-4 ml-4"}>
                    <PageHeader
                        title={translate('text.driverPreferences')}
                        titleClass="mr-5 text-2xl"
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        hasPerm={checkPerm(this.getResourceName(), CREATE_PERM)}
                    >
                        <button className="btn btn-header ml-auto mr-2" onClick={this.fetchData}>
                            <ArrowPathIcon className="w-5 h-5" />
                        </button>

                    </PageHeader>
                </div>

                <Card>
                    <div className="space-y-5">
                        <TableCard>
                            <ResourceTable
                                addClass="rounded-card"
                                data={data}
                                count={count}

                                commonTable={true}

                                fields={this.props.tableFields ?? this.state.fields}
                                translate={this.props.translate}
                                isLoading={isLoading}

                                onDelete={checkPerm(this.getResourceName(), DELETE_PERM) ? this.handleToggleConfirmModal : null}
                                limit={10}

                                tableKey='DriverPreferenceID'
                            />

                            <NoRecordsTable
                                show={(data.length === 0) && !isLoading}
                                canCreate={false}
                                title={translate('text.no_matching_records')}
                                className={"pb-12 pt-16 px-6"}
                            />
                        </TableCard>

                        <ModalSaveResource
                            show={this.state.createModalOpen}
                            title={translate('text.CreateNewDriverPreference')}
                            widthClass="max-w-md"
                            gridColsClass="grid-cols-3"
                            errorMessage={this.state.errorMessage}
                            onClose={this.handleToggleCreateModal}
                            fields={this.getFields()}
                            getRawFields
                            onSubmit={(params) => {
                                if (params) {
                                    this.handleSubmit(params)
                                    this.handleToggleCreateModal()
                                }
                            }}
                            translate={this.props.translate}
                            metadata={metadata}
                            handleInputChange={this.handleInputChange}
                        />

                        <ModalConfirm
                            title={'Confirm'}
                            show={!!this.state.confirmModalOpen}
                            text={translate('message.are_you_sure_delete_preference')}
                            onClose={this.handleToggleConfirmModal}
                            buttonLabel={translate('btn.confirm')}
                            closeButtonLabel={'Cancel'}
                            translate={translate}
                            onConfirm={() => {
                                if (!!this.getID()) {
                                    this.props.dispatch(deleteResource({
                                        user: LocalStorage.get('user'),
                                        query: Object.assign({
                                            [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                        }, this.getQuery()),
                                        resource: this.getResourceName(),
                                        piggyResource: this.getResourceName(),
                                        errorMessage: true, successMessage: 'Driver Preference deleted.'
                                    }))
                                } else {
                                    let index = data.findIndex(item => item.PreferenceTypeID == this.state.selectedItem['PreferenceTypeID'])
                                    this.props.handleRemovePreference(index)
                                }
                                this.handleToggleConfirmModal(false)
                            }}
                        />
                    </div>
                </Card>
            </div>
        )
    }
}
