import React from "react";
import {classNames} from "../../../../common/util/util-helpers";

export default function CalendarMonthView({days, weekFormat, onDayClick, renderMonthlyViewEvents, renderMonthlyViewMobileEvents}) {
    return (
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
            <div
                className="grid grid-cols-7 gap-px border-b border-tm-gray-300 bg-tm-gray-200 text-center text-xs font-semibold leading-6 text-tm-gray-700 lg:flex-none">
                {weekFormat.map(day => (
                    <div
                        key={day.abbrExt}
                        className="bg-inverse py-2"
                    >
                        {day.abbr}<span className="sr-only sm:not-sr-only">{day.abbrExt}</span>
                    </div>
                ))}
            </div>

            <div className="flex bg-tm-gray-200 text-xs leading-6 text-tm-gray-700 lg:flex-auto">
                <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                    {days.map((day) => (
                        <div
                            key={day.date}
                            className={classNames(
                                day.isCurrentMonth ? 'bg-inverse' : 'bg-tm-gray-50 text-tm-gray-500',
                                'min-h-[15.5rem] relative py-2 px-1'
                            )}
                        >
                            <time
                                dateTime={day.date}
                                className={classNames(
                                    "relative z-10 p-1 rounded-full w-7 h-7 inline-flex items-center justify-center",
                                    !day.isToday && 'justify-center rounded-full bg-tm-gray-100 font-semibold',
                                    day.isToday
                                        ? 'bg-primary text-primary-contrast font-bold'
                                        : undefined
                                )}
                            >
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>

                            {!!renderMonthlyViewEvents && renderMonthlyViewEvents(day)}

                            {/*Hover bg*/}
                            <div
                                className="hover:bg-tm-gray-100 cursor-pointer absolute inset-0"
                                 onClick={() => onDayClick(day)}
                            />
                        </div>
                    ))}
                </div>

                <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                    {days.map((day) => (
                        <button
                            key={day.date}
                            onClick={() => onDayClick(day)}
                            type="button"
                            className={classNames(
                                day.isCurrentMonth ? 'bg-inverse' : 'bg-tm-gray-50',
                                (day.isSelected || day.isToday) && 'font-semibold',
                                day.isSelected && 'text-inverse',
                                !day.isSelected && day.isToday && 'text-primary',
                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-tm-gray-500',
                                'flex h-14 flex-col py-2 px-3 hover:bg-tm-gray-100 focus:z-10'
                            )}
                        >
                            <time
                                dateTime={day.date}
                                className={classNames(
                                    day.isToday && 'bg-primary text-primary-contrast w-6 h-6 rounded-full',
                                    !day.isToday && 'text-tm-gray-700',
                                    'ml-auto'
                                )}
                            >
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                            <p className="sr-only">{day.events.length} events</p>
                            {day.events.length > 0 && (
                                <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                    {renderMonthlyViewMobileEvents(day.events, day.date)}
                                </div>
                            )}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}