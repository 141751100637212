import React, {Component} from 'react'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import {checkPerm, classNames, getProp} from '../../../common/util/util-helpers'
import {currentDate, toFrontDateNoTime} from '../../../common/util/util-dates'
import AccountReportDialog from '../dialogs/account-report-dialog'
import {READ_PERM} from '../../../util/util-constants'
import {getSecondResource} from '../../../data/actions/secondResource'
import PayDateFilter from '../carrier-pay/carrier-pay-date-filter'
import {download} from '../../../data/actions/download'
import FunnelIcon from '@heroicons/react/20/solid/FunnelIcon'
import {LoaderSmall} from "../../../common/components/loader";
import {ChartLine} from "../../../common/components/chart/chart-line";
import {ChartPie} from "../../../common/components/chart/chart-pie";
import {CharVerticalBar} from "../../../common/components/chart/chart-vertical-bar";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {fieldsToHtml} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import ModalDefault from "../../../common/components/modal/modal-default";

export default class AccountingDashboardTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {
                StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), ['date'], false, 'date', {
                    addContainerClass: 'col-span-4'
                }, {}),
                EndDate: new Field('EndDate', moment().format('YYYY-MM-DD HH:mm:ss'), ['date'], false, 'date', {
                    addContainerClass: 'col-span-4'
                }, {})
            },
            accountDetailsDialog: false,
            selectedItem: null,
            datesFilterDialogOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingDashboard,
            query: FieldsManager.getFieldKeyValues(this.state.fields)
        }))
    }

    downloadExcelReports = (query) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingAccountReport,
            query: Object.assign({
                format: 'EXCEL',
                name: 'income_statement_transactions_' + currentDate() + '.xlsx'
            }, query)
        }))
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, this.fetchData)
    }

    handleToggleAccountDetails = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            accountDetailsDialog: !this.state.accountDetailsDialog
        })
    }

    handleToggleFilterDialog = () => {
        this.setState({
            datesFilterDialogOpen: !this.state.datesFilterDialogOpen
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange)

        const incomeDataSet = getProp(this.props.resource, 'data.Sales', []).reduce((memo, it) => {
            memo[it[0]] = {
                label: toFrontDateNoTime(it[0]),
                value: (it[1])
            }
            return memo
        }, {})
        const incomeGraphData = [{
            label: 'Income amount total',
            data: incomeDataSet,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
        }]

        const profitAndLoss = getProp(this.props, 'resource.data.ProfitAndLoss', {})

        const profitAndLossDataSet = {
            Income: {
                label: 'Income',
                value: profitAndLoss.Income
            },
            Expense: {
                label: 'Expense',
                value: profitAndLoss.Expense
            },
            NetIncome: {
                label: 'Net Income',
                value: profitAndLoss.NetIncome
            }
        }

        const profitAndLossGraphData = [{
            label: 'Profit and Loss',
            data: profitAndLossDataSet,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
                'rgb(255, 99, 132)',
                'rgb(75, 192, 192)',
                'rgb(153, 102, 255)',
            ],
            borderWidth: 1
        }]

        const invoicesGraphData = [{
            label: 'Invoices',
            data: {
                Open: {
                    label: 'Open',
                    value: getProp(this.props, 'resource.data.Invoices.Open', 0)
                },
                Overdue: {
                    label: 'Overdue',
                    value: getProp(this.props, 'resource.data.Invoices.Overdue', 0)
                },
                Paid: {
                    label: 'Paid',
                    value: getProp(this.props, 'resource.data.Invoices.Paid', 0)
                }
            },
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
                'rgb(75, 192, 192)',
                'rgb(255, 206, 86)',
                'rgb(153, 102, 255)',
            ],
            borderWidth: 1
        }]

        const receivableDataSet = getProp(this.props.resource, 'data.Receivables', []).reduce((memo, it) => {
            memo[it[0]] = {
                label: toFrontDateNoTime(it[0]),
                value: (it[1])
            }
            return memo
        }, {})
        const receivableGraphData = [{
            label: 'Receivable amount total',
            data: receivableDataSet,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
        }]
        const payableDataSet = getProp(this.props.resource, 'data.Payable', []).reduce((memo, it) => {
            memo[it[0]] = {
                label: toFrontDateNoTime(it[0]),
                value: (it[1])
            }
            return memo
        }, {})
        const payableGraphData = [{
            label: 'Payable amount total',
            data: payableDataSet,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
        }]

        return (
            <Card>
                <div className="mb-5 flex items-center">
                    <PageHeader
                        title={translate('page.heading.Summary')}
                    />

                    <div className="flex justify-end items-end w-full">
                        <button
                            className="btn btn-icon"
                            title={translate('text.Filters')}
                            onClick={this.handleToggleFilterDialog}
                        >
                            <FunnelIcon className="h-4 w-4"/>
                        </button>

                        <button
                            className={
                                classNames(
                                    "btn btn-header z-10 ml-8"
                                )
                            }
                            onClick={this.fetchData}
                        >
                            <ArrowPathIcon className={
                                classNames(
                                    "w-5 h-5",
                                    this.props.resource?.isLoading || this.props.secondResource?.isLoading ? "animate-spin" : undefined
                                )
                            }/>
                        </button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col p-5 grid grid-cols-12 gap-4">
                        {fieldsHtml}
                    </div>
                </div>

                {!this.props.resource.isLoading && (
                    <div className="grid gap-8 grid-cols-12 pb-8">
                        <div className="col-span-full">
                            <p className="text-xl mb-6">Income</p>
                            <div className="h-72 w-full">
                                <ChartLine
                                    hideLegend={true}
                                    data={incomeGraphData}
                                />
                            </div>
                        </div>

                        <div className="col-span-6">
                            <p className="text-xl mb-6">Profit and Loss</p>
                            <div className="h-72 w-full">
                                <CharVerticalBar
                                    hideLegend={true}
                                    data={profitAndLossGraphData}
                                />
                            </div>
                        </div>

                        <div className="col-span-6">
                            <p className="text-xl mb-6">Invoices</p>
                            <div className="h-72 w-full">
                                <ChartPie
                                    data={invoicesGraphData}
                                    legendPosition={'right'}
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <p className="text-xl mb-6">Receivables over period of time</p>
                            <div className="h-72 w-full">
                                <ChartLine
                                    hideLegend={true}
                                    data={receivableGraphData}
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <p className="text-xl mb-6">Payable over period of time</p>
                            <div className="h-72 w-full">
                                <ChartLine
                                    hideLegend={true}
                                    data={payableGraphData}
                                    legendPosition={'right'}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {this.props.resource.isLoading && (
                    <LoaderSmall/>
                )}

                <AccountReportDialog
                    AccountID={this.state.selectedItem?.AccountID}
                    AccountName={this.state.selectedItem?.AccountName}
                    StartDate={this.state.fields.StartDate?.value}
                    EndDate={this.state.fields.EndDate?.value}
                    onClose={this.handleToggleAccountDetails}
                    show={checkPerm(Resources.AccountingAccountReport, READ_PERM) && this.state.accountDetailsDialog}
                    translate={this.props.translate}
                    getTransactions={(query) => {
                        this.props.dispatch(getSecondResource({
                            user: LocalStorage.get('user'),
                            resource: Resources.AccountingAccountReport,
                            query: query
                        }))
                    }}
                    downloadExcelReports={(query) => this.downloadExcelReports(query)}
                    isLoading={getProp(this.props, 'secondResource.isLoading', false)}
                    transactions={getProp(this.props, 'secondResource.data', [])}
                    thirdResource={this.props.thirdResource}
                    dialogResource={this.props.dialogResource}
                    dispatch={this.props.dispatch}
                />

                <ModalDefault
                    show={!!this.state.datesFilterDialogOpen}
                    widthClass={'max-w-3xl'}
                    title={"Dates filter"}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleFilterDialog}

                    translate={translate}
                >
                    <PayDateFilter
                        queryFields={this.state.fields}
                        translate={translate}
                        updateQueryFields={(queryFieldsUpdate) => {
                            this.setState({
                                fields: queryFieldsUpdate
                            }, () => {
                                this.fetchData()
                            })
                        }}
                        hasDateType={false}
                        isDateTypeClearable={false}
                        isClearDatesButtonVisible={false}
                        onQueryChange={this.handleInputChange}
                    />
                </ModalDefault>
            </Card>
        )
    }
}
