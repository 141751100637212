import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingTab from "../../general/settings/billing-tab";
import Resources from "../../../data/services/resources";
import DispatchSettingsGeneralTab from "./dispatch-settings-general-tab";
import Lookups from "../../general/settings/lookupTab";
import {DISPATCH_SETTINGS_INCLUDED_LOOKUPS, REQUIRED_DOCUMENTS_TYPES} from "../../../util/util-constants";
import DocumentEntitiesTab from "../../general/settings/document-entities-tab";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import DocumentTypesTab from "../../../common/components/tabs/document-types-tab";
import Layout from "../../../common/components/layout";

export default function DispatchSettingsView({translate, match, history, location}) {
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);
    const resource = useSelector((state) => state.resource);

    const [tabs, setTabs] = useState(getTabs());
    const [breakpoint, setBreakpoint] = useState({})
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }
    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }

    const currentTab = tabs.find(tab => tab.current);

    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            hasFooter={currentTab.name === 'general' || currentTab.name === 'pricing' || currentTab.name === 'RequiredDocuments'}
            onBreakpointChange={handleBreakpointChange}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.DispatchSettings')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'general' && (
                    <DispatchSettingsGeneralTab
                        translate={translate}
                        resourcePath={currentTab.resource}
                    />
                )}

                {currentTab.name === 'pricing' && (
                    <BillingTab
                        translate={translate}
                        resourcePath={currentTab.resource}
                    />
                )}

                {currentTab.name === 'customFields' && (
                    <Lookups
                        customLookupFilter={(item, name) => {
                            if (name === "DocumentType") {
                                return !!item.IsDispatchDocument
                            }
                            return true
                        }}
                        includeLookups={DISPATCH_SETTINGS_INCLUDED_LOOKUPS}
                        translate={translate}
                        resource={resource}
                        dispatch={dispatch}
                    />
                )}

                {currentTab.name === "DocumentTypes" && (
                    <DocumentTypesTab
                        translate={translate}
                        primaryType={'IsDispatchDocument'}
                        breakpoint={breakpoint}
                    />
                )}

                {currentTab.name === "RequiredDocuments" && (
                    <DocumentEntitiesTab
                        requiredTypes = {REQUIRED_DOCUMENTS_TYPES.Dispatch}
                        translate={translate}
                        resource={resource}
                        dispatch={dispatch}
                    />
                )}
            </Page>
        </Layout>
    )
}

const getTabs = () => {
    return [
        {
            name: 'general',
            resource: Resources.DispatchSettings,
            current: true,
            visible: true
        },
        {
            name: 'pricing',
            resource: Resources.CompanyBilling,
            current: false,
            visible: true
        },
        {
            name: 'customFields',
            resource: Resources.Lookup,
            current: false,
            visible: true
        },
        {
            name: 'DocumentTypes',
            resource: Resources.DocumentTypes,
            current: false,
            visible: true
        },
        {
            name: 'RequiredDocuments',
            resource: Resources.DocumentEntities,
            current: false,
            visible: true
        }
    ]
}
