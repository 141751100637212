import React, {useState} from "react";
import {Field} from "../../../../data/services/fields";
import Tippy from "@tippyjs/react";
import {toFrontDateTime} from "../../../../common/util/util-dates";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import {checkPerm, getDefaultTableOptions, getProp} from "../../../../common/util/util-helpers";
import {useDispatch, useSelector} from "react-redux";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {DELETE_PERM} from "../../../../util/util-constants";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {deleteDialogResource} from "../../../../data/actions/dialogResource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import InfoParagraph from "../../../../common/components/info-paragraph";

export default function ReviewLoanModalActualPaymentsTab({translate}) {
    const dispatch = useDispatch();
    const dialogResource = useSelector(state => state.dialogResource);
    const isLoading = dialogResource.isLoading;
    const data = getProp(dialogResource, 'data.Payments', []).filter(it => !!it.PaymentTypeID);

    const [selectedItem, setSelectedItem] = useState({});
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const limit = 12;
    let dataSlice = [];

    function handleToggleConfirmDialog(item) {
        setSelectedItem(item);
        setIsConfirmModalOpen(!isConfirmModalOpen);
    }

    const tableOptions = getTableOptions("loan_modal_actual_payments", translate)

    if (data?.length) {
        dataSlice = data.slice(Number(offset), Number(offset) + Number(limit));
    }

    function getTableOptions(pagePath, translate) {
        return getDefaultTableOptions(getFields(translate), {
            behaviour: {
                rowSelect: true,
            }
        }, pagePath, translate)
    }

    const handleUpdateOffset = (name, value) => {
        if (name === 'offset') {
            setOffset(value)
        }
    }

    function getFields() {
        return {
            Date: new Field('Date', '', [], false, 'date'),
            Amount: new Field('Amount', '', [], false, 'money', {
                render: (item) => {
                    if (item.ReconciliationDate) {
                        return (
                            <Tippy content={"Reconciled: " + toFrontDateTime(item.ReconciliationDate)}>
                                <div className={"text-right flex items-center gap-2"}><CheckCircleIcon
                                    className="w-5 h-5 text-green-600"/> {genericMoneyFormatter(item.Amount)}</div>
                            </Tippy>
                        )
                    }
                    return <div className={"text-right"}>{genericMoneyFormatter(item.Amount)}</div>
                }
            }),
            Account: new Field('Account', '', [], false, 'text'),
            PaymentType: new Field('PaymentType', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <span>
                            {item.PaymentType} {item.CheckNumber ? "(" + item.CheckNumber + ")" : ""}
                        </span>
                    )
                }
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
    }

    return <>
        {(data.length > 0) && !isLoading && (
            <div className="p-1">
                <InfoParagraph>
                    {translate("text.delayed_payment_delete")}
                </InfoParagraph>
            </div>
        )}

        {(data.length === 0) && !isLoading && (
            <div className="h-full flex justify-center items-center">
                <NoRecordsTable
                    className="pt-12 px-6 pb-6"
                    show={true}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                />
            </div>
        )}

        <ResourceTable
            data={dataSlice}
            options={tableOptions}
            fields={getFields()}
            isLoading={dialogResource?.isLoading}
            actions={[
                {
                    action: handleToggleConfirmDialog,
                    hasPerm: checkPerm(Resources.ExpensePayment, DELETE_PERM),
                    icon: TrashIcon, // make this a function
                    visible: (it) => {
                        if (it.SendPaymentID != data?.[data.length - 1]?.SendPaymentID) {
                            return null
                        }
                        return !it.ReconciliationDate
                            && !it.SendBatchPaymentID
                            && !it.RecvBatchPaymentID
                    },
                    label: false, // make this a function
                    title: translate('btn.delete'),
                    disabled: false,
                    class: false,
                    iconClass: false
                }
            ]}
            translate={translate}
        />

        {(data?.length > limit) && (
            <div className="px-6 py-2">
                <Pagination
                    hideRowsPerPage
                    count={data?.length ?? 0}
                    handleQueryChange={
                        (name, value) => handleUpdateOffset(name, value)}
                    pageLimit={limit}
                    pageOffset={offset}
                    translate={translate}
                />
            </div>
        )}

        <ModalConfirm
            title={translate('text.Confirm')}
            show={isConfirmModalOpen}
            text={translate('message.confirm_delete_payment')}
            onClose={() => {
                setIsConfirmModalOpen(false);
                setSelectedItem({});
            }}
            buttonLabel={translate('btn.confirm')}
            closeButtonLabel={translate('btn.Cancel')}
            translate={translate}
            onConfirm={() => {
                dispatch(deleteDialogResource({
                    user: LocalStorage.get('user'),
                    query: {
                        SendPaymentID: selectedItem.SendPaymentID
                    },
                    resource: Resources.ExpensePayment,
                    piggyResource: Resources.ExpenseExpense,
                    piggyQuery: {
                        ExpenseID: selectedItem.ExpenseID
                    },
                    errorMessage: true,
                    successMessage: translate('text.PaymentDeletedSuccessfully')
                }))

                setIsConfirmModalOpen(false);
                setSelectedItem({});
            }}
        />
    </>
}