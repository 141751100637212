import {useState} from "react";
import LocalStorage from "../util/localStorage";
import {fillFieldsFromData} from "../common/util/util-fields";
import {FieldsManager} from "../data/services/fields";

export default function useQuery(defaultFields, key) {
    const savedQuery = getSavedQuery(defaultFields, key);

    const [query, setQuery] = useState(savedQuery ? savedQuery : defaultFields)

    function saveQuery(query, updatedKey = undefined) {
        if ((updatedKey ?? key) && updatedKey !== null) {
            saveCurrentQuery(query, updatedKey ?? key)
        }

        setQuery(() => query);
    }

    return [query, saveQuery];
}

const getSavedQuery = (defaultFields, key) => {
    if (!key) {
        return undefined;
    }

    const storeKey = key + "_query";
    let storedQuery = LocalStorage.get(storeKey) ?? {};
    return fillFieldsFromData(defaultFields, storedQuery);
}

const saveCurrentQuery = (query, key) => {
    const storeKey = key + "_query";
    const queryToStore = FieldsManager.getFieldKeyValues(query);
    LocalStorage.set(storeKey, queryToStore);
}