import React, { useEffect } from 'react'
import { clearThirdResource, getThirdResource } from '../../../../data/actions/thirdResource'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import { Field } from '../../../../data/services/fields'
import { getProp } from '../../../../common/util/util-helpers'
import Modal from "../../../../common/components/modal";
import {LoaderSmall} from "../../../../common/components/loader";
import ResourceTable from "../../../../common/components/resource-table";

export default function TrackTimeInfo(props) {
    const { translate, thirdResource, ui } = props

    const fetchData = () => {
        props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.TrackTime,
            query: { ContactID: props.item.ContactID }
        }))
    }

    useEffect(() => {
        fetchData()

        return () => {
            props.dispatch(clearThirdResource())
        }
    }, [])

    const data = getProp(thirdResource, 'data.list', [])

    const close = () => {
        props.close()
    }

    const getFields = () => {
        return {
            Name: new Field('Name', '', ['empty'], false, '', {
                omitSort: true
            }),
            Time: new Field('Time', '', ['empty'], false, 'datetimezone', {
                omitSort: true
            }),
            TimeSpent: new Field('TimeSpent', '', ['empty'], false, '', {
                omitSort: true
            }),
            TrackTimeTask: new Field('TrackTimeTask', '', ['empty'], false, '', {
                omitSort: true
            }),
        }
    }

    return (
        <Modal
            title={'Details for ' + (props.item.Name ?? '')}
            close={close}
            large
            dialogHideAnimation={ui.HideModalAnimation}
            onClose={close}
            closeButtonLabel={translate('btn.close')}
            translate={translate}
            {...props}>
            <div className="relative" style={{ minHeight: props.minHeight ? props.minHeight : 0 }}>

                {thirdResource.isLoading && (
                    <div className="text-center absolute position-absolute-center">
                        <LoaderSmall/>
                    </div>
                )}

                {!!thirdResource.data && !thirdResource.isLoading && (
                    <React.Fragment>
                        <div className="modal-body">
                            <ResourceTable
                                data={data}
                                fields={getFields()}
                                translate={translate}
                                isLoading={thirdResource.isLoading}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    )
}
