import {DocumentTextIcon, InformationCircleIcon} from "@heroicons/react/24/outline";
import React from "react";

export default function DzItemFile({translate, name, description, type, onClick, additionalInfo, onRemoveFile, index}) {
    return (
        <li
            className="relative py-4 pl-4 pr-6 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6 m-0"
            onClick={onClick}
        >
            <div className="flex items-center justify-between space-x-4">

                <div className="min-w-0 space-y-3">
                    {!!description && (
                        <span
                            className="relative flex items-center space-x-2.5">
                            <InformationCircleIcon
                                className="flex-shrink-0 w-5 h-5 text-tm-gray-600"/>
                            <span
                                className="truncate text-sm font-medium text-gray-500 group-hover:text-gray-900">{description}</span>
                        </span>
                    )}

                    <div className="flex items-center space-x-3">
                        <DocumentTextIcon
                            className="flex-shrink-0 w-5 h-5 text-tm-gray-600"/>

                        <h2 className="text-sm font-medium truncate">
                            {name}
                        </h2>
                    </div>
                </div>

                <div
                    className="flex flex-shrink-0 flex-col items-end space-y-3">
                    <p className="flex items-center space-x-4">
                        {!!onRemoveFile && (
                            <button
                                className="btn-text py-0 hover:text-red-600"
                                onClick={() => onRemoveFile(index)}
                            >
                                {translate("btn.remove")}
                            </button>
                        )}
                    </p>

                    {!!type && !!additionalInfo && (
                        <p className="flex space-x-2 text-sm text-gray-500">
                            <span>{type}</span>

                            {!!type && !!additionalInfo && (
                                <span aria-hidden="true">&middot;</span>
                            )}
                            <span>{additionalInfo}</span>
                        </p>
                    )}
                </div>
            </div>
        </li>
    )
}