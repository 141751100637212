import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {hideConfirmDialog, showConfirmDialog} from '../../../data/actions/ui'
import {deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import icons from '../../../data/themes/icons'
import {Field} from '../../../data/services/fields'
import {getDefaultTableOptions, getProp} from '../../../common/util/util-helpers'
import Page from "../../../common/components/layout/layout-components/page";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Layout from "../../../common/components/layout";
import ResourceTable from "../../../common/components/resource-table";

class LoggedDevicesView extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            items: {},
            offset: 0,
            limit: 10,
            sort: 'ASC',
            sortBy: '',
            paginationPage: 1,
            AccessorialItem: null,
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_delete_device')}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {
                            user_agent: item.user_agent,
                        }),
                        errorMessage: true, successMessage: `Device has been deleted`,
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                })
            }, item)
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            id: LocalStorage.get('user')?.Contact?.Email
        }
    }

    getFields = () => {
        return {
            login_time: new Field('login_time', '', ['empty'], false, 'datetimezone', {
                omitSort: true
            }),
            os: new Field('os', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <div className="flex inline-center">
                        <span className="h-5 w-5 mr-2">{this.returnOSIcon(item.os)}</span>
                        <span className="text-capitalize">{item.os}</span>
                    </div>
                ),
                omitSort: true
            }),
            Browser: new Field('Browser', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <div className="flex inline-center">
                        <span className="h-5 w-5 mr-2">{this.returnBrowserIcon(item.Browser)}</span>
                        <span className="text-capitalize">{item.Browser}</span>
                    </div>
                ),
                omitSort: true
            }),
            user_agent: new Field('user_agent', '', ['empty'], false, 'text', {
                omitSort: true
            }),
            ip: new Field('ip', '', ['empty'], false, 'text', {
                omitSort: true
            }),
            location: new Field('location', '', ['empty'], false, 'text', {
                omitSort: true
            })
        }
    }

    showConfirmDialog = (submit, item) => {
        this.setState({
            officeConfirmText: item.AccessorialName,
            confirmModal: submit
        })
        this.props.dispatch(showConfirmDialog())
    }

    hideConfirmDialog = () => {
        this.props.dispatch(hideConfirmDialog())
    }

    returnOSIcon = (os) => {
        switch (os) {
            case 'linux':
                return icons.linux
            case 'mac':
                return icons.mac
            case 'windows':
                return icons.windows
            case 'iPad':
                return icons.iPad
            case 'iPhone':
                return icons.iPhone
            case 'Android':
                return icons.Android
            default:
                return icons.QuestionMark
        }
    }

    returnBrowserIcon = (browser) => {
        let icon
        if (browser) {
            ['Chrome', 'Safari', 'Firefox', 'Opera', 'Explorer', 'Edge'].forEach(it => {
                if (browser.includes(it)) {
                    icon = icons[it]
                }
            })
        }
        if (icon) {
            return icon
        } else {
            return icons.QuestionMark
        }
    }

    getResourceName = () => {
        return Resources.UserLoggedDevices
    }

    render() {
        const {translate, resource} = this.props

        const data = Array.isArray(getProp(resource.data, 'list', [])) && getProp(resource.data, 'list', [])

        return (
            <Layout {...this.props}>
                <Page>
                    <h5 className="mr-5 text-3xl text-tm-gray-600">{translate('text.Devices')}</h5>
                    <TableCard>

                        <ResourceTable
                            data={data}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}
                            //onDelete={checkPerm(this.getResourceName(), DELETE_PERM) ? this.archiveResource : null}
                        />

                        <ModalConfirm
                            title={'Confirm'}
                            show={!!this.state.confirmDialog}
                            text={this.state.text}
                            onClose={() => this.setState({confirmDialog: false})}
                            buttonLabel={translate('btn.confirm')}
                            closeButtonLabel={'Cancel'}
                            translate={translate}
                            onConfirm={this.state.confirmModal}
                        />
                    </TableCard>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(LoggedDevicesView)
