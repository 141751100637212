import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
    plugins: {
        legend: {},
        title: {},
    },
};

export function ChartPie({
                             data,
                             rawData,
                             options,
                             title,
                             maintainAspectRatio = false,
                             responsive = true,
                             legendPosition = "left",
                             hideLegend = false
                         }) {
    let chartData = rawData;
    if (!rawData) {
        chartData = {
            labels: Object.values(data[0]?.data).map(it => {
                return it.label;
            }),
            datasets: data.reduce((memo, set) => {
                memo.push({
                    label: set.label,
                    data: Object.values(set.data).map(it => {
                        return it.value;
                    }),
                    borderColor: set.borderColor,
                    backgroundColor: set.backgroundColor,
                });
                return memo;
            }, [])
        }
    }

    let chartOptions = options;
    if (!options) {
        chartOptions = defaultOptions;
    }

    if (!options?.plugins) {
        chartOptions.plugins = {};
    }

    chartOptions.plugins.title = {
        text: title,
        display: !!title
    };

    chartOptions.plugins.legend = {
        position: legendPosition,
        display: !hideLegend
    };

    chartOptions.responsive = responsive;
    chartOptions.maintainAspectRatio = maintainAspectRatio;

    return <Pie data={chartData} options={chartOptions} />;
}
