import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import LocalStorage from "../../util/localStorage";
import {
    doneCreateSecondResource,
    doneDeleteSecondResource,
    doneGetSecondResource,
    doneUpdateSecondResource,
    errorCreateSecondResource,
    errorDeleteSecondResource, errorGetSecondResource,
    errorUpdateSecondResource
} from '../actions/secondResource'
import { pushNotification } from "../actions/ui";
import { doneGetResource } from "../actions/resource";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getSecondResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result;
    if (action.data.request === 'POST') {
        result = yield call(Api.postResource, user, action.data.query, action.data.resource);
    } else {
        result = yield call(Api.getResource, user, action.data.query, action.data.resource);
    }

    if (result && result.status === 0) {
        yield put(doneGetSecondResource(result.data));
    } else {
        yield put(errorGetSecondResource(result.data));
    }
}

export function* watchGetSecondResource() {
    yield takeLatest('GET_SECOND_RESOURCE', getSecondResourceCall);
}

export function* createSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {

        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        }

        if (action.data.document && action.data.document.length > 1) {
            let id
            if (action.data.resource === "drivers") {
                id = result.data.DriverID
            } else if (action.data.resource === "carriers") {
                id = result.data.CarrierID
            } else if (action.data.resource === "customers") {
                id = result.data.CustomerID
            } else {
                id = result.data.id
            }
            yield call(Api.uploadDocuments,
                user,
                id,
                action.data.document,
                null,
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource
            );
        } else if (action.data.document && action.data.document[0]) {
            yield call(Api.uploadDocument,
                user,
                null,
                action.data.document,
                null,
                action.data.documentResource ? action.data.documentResource : action.data.resource
            );
        }
        if (action.data.resource === 'lookup') {
            let lookup = LocalStorage.get("lookup")
            let newObject = {
                [`${action.data.params.Lookup}ID`]: result.data.id,
                [`${action.data.params.Lookup}`]: action.data.params.Value
            }
            lookup[action.data.params.Lookup].push(newObject)
            LocalStorage.set("lookup", lookup)
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateSecondResource(result.data));
            }
        } else {
            yield put(doneCreateSecondResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateSecondResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateSecondResource() {
    yield takeLatest('CREATE_SECOND_RESOURCE', createSecondResourceCall);
}


export function* deleteResourceSecondContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                if (action.data.onPiggyResultCallback) {
                    action.data.onPiggyResultCallback(resPiggy)
                }
            }
        }

        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        } else {
            yield put(doneDeleteSecondResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteSecondResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchDeleteSecondResource() {
    yield takeLatest('DELETE_SECOND_RESOURCE', deleteResourceSecondContactCall);
}


export function* updateSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneUpdateSecondResource(result.data));
            }
        } else {
            yield put(doneUpdateSecondResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateSecondResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateSecondResource() {
    yield takeLatest('UPDATE_SECOND_RESOURCE', updateSecondResourceCall);
}
