import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Resources from "../../../data/services/resources";
import TasksSettingsGeneralTab from "./tasks-settings-general-tab";
import TasksSettingsAutoTasksTab from './tasks-settings-auto-tasks'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import Layout from "../../../common/components/layout";
import {checkPerm} from '../../../common/util/util-helpers'
import {READ_PERM} from "../../../util/util-constants";

export default function TasksSettingsView({translate, match, history, location}) {
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);
    const contactResource = useSelector((state) => state.contactResource);
    const resource = useSelector((state) => state.resource);

    const [tabs, setTabs] = useState(getTabs());

    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const currentTab = tabs.find(tab => tab.current);

    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            contactResource={contactResource}
            isAccessible={!(resource.errorStatus === 2)}
            hasFooter
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.TasksSettings')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'general' && (
                    <TasksSettingsGeneralTab
                        translate={translate}
                        resourcePath={currentTab.resource}
                    />
                )}

                {currentTab.name === 'auto-tasks' && (
                    <TasksSettingsAutoTasksTab
                        translate={translate}
                        resourcePath={currentTab.resource}
                    />
                )}
            </Page>
        </Layout>
    )
}

const getTabs = () => {
    return [
        {
            name: 'general',
            resource: Resources.TasksSettings,
            current: true,
            visible: checkPerm(Resources.TasksSettings, READ_PERM)
        },
        {
            name: 'auto-tasks',
            resource: Resources.TasksGroupDefaults,
            current: false,
            visible: checkPerm(Resources.TasksGroupDefaults, READ_PERM)
        }
    ]
}
