import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {ACCOUNT_TYPE_CASH, CREATE_PERM, DEFAULT_CRUD_STATE, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import Env from '../../../util/env'
import axios from 'axios'

import FileViewer from "../../../common/components/file-viewer/components"
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import {LoaderLarge} from "../../../common/components/loader";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";
import Page from "../../../common/components/layout/layout-components/page";
import ModalDefault from "../../../common/components/modal/modal-default";
import Button from '../../../common/components/button'

class AccountingReconciliationsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sort: "DESC",
            sortBy: "CreateUpdateDate",
            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    canAdjustWidth: true
                },
                style: {
                    condensed: true,
                },
            }, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            createUpdateDialog: false,
            createUpdateDialogHideAnimation: false,
            confirmDialog: false,
            breakpoint: {},
            selectedItem: {},
            isPreviewDocumentModalOpen: false,
            isPreviewDocumentError: false,
            previewFileURL: "",
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadDocument = () => {
        if (this.state.previewFileURL) {
            const pdfWindow = window.open(this.state.previewFileURL)
            pdfWindow.location.href = this.state.previewFileURL
        }
    }

    /** UI Events
     ================================================================= */
    handleEditResource = (item) => {
        this.props.history.push(`/accounting/reconciliation/${item[this.getPrimaryKey()]}`)
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleCreateDialog = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handlePreviewDocumentClick = (it) => {
        this.setState({
            isPreviewDocumentModalOpen: true,
            isPreviewDocumentLoading: true,
            isPreviewDocumentError: false
        }, async () => {
            try {
                await axios
                    .get(Env.getApiUrl('api/' + Resources.AccountingReconciliation, Object.assign({}, {
                            ReconciliationBatchID: it.ReconciliationBatchID,
                            ExportPDF: 1
                        })),
                        {
                            responseType: 'blob',
                            headers: {
                                Authorization: "Bearer " + getJWT().access_token,
                            }
                        })
                    .then((response) => {
                        //Create a Blob from the PDF Streamwork
                        const file = new Blob([response.data], {type: 'application/pdf'})
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);

                        this.setState({
                            previewFileURL: fileURL,
                            isPreviewDocumentLoading: false
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            isPreviewDocumentLoading: false,
                            isPreviewDocumentError: true
                        })
                        console.log(error)
                    })
            } catch (error) {
                return {error}
            }
        })
    }

    /** Helpers
     ================================================================= */
    getFields = (item = null) => {
        const fieldTemplates = {
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            StartingBalance: new Field('StartingBalance', '', ['empty'], false, 'money', {addContainerClass: 'col-span-6'}, {
                isNegativeAllowed: true
            }),
            EndingBalance: new Field('EndingBalance', '', ['empty'], false, 'money', {addContainerClass: 'col-span-6'}, {
                isNegativeAllowed: true
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            IsCompleted: new Field('IsCompleted', '', [], false, 'checkbox', {
                hideDialog: true
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()))
    }

    getQuery = () => {
        const IsCompleted = this.state.queryFilterFields.IsCompleted.value;
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                AccountID: this.state.queryFilterFields.AccountID.value?.value,
                IsCompleted: IsCompleted == "-1" ? null : [
                    'IsCompleted', '=', IsCompleted
                ],
            })
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            AccountID: new Field('AccountID', '', [''], false, 'select-search', {}, {isClearable: true}),
            IsCompleted: new Field('IsCompleted', '0', [''], false, 'button-group', {
                data: {
                    "-1": "All",
                    "0": "No",
                    "1": "Yes"
                }
            }),
            limit: new Field('limit', 50, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getResource = () => {
        return Resources.AccountingReconciliationBatch
    }

    getPrimaryKey = () => {
        return 'ReconciliationBatchID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.Reconciliations')}
                    />

                    <div className="sm:flex mb-2">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <div className={'flex mr-4'}>
                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>
                            <Button
                                hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                                className={`btn btn-primary`}
                                onClick={this.handleToggleCreateDialog}
                            >
                                {translate('btn.create_new')}
                            </Button>
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            selects={{
                                AccountID: {
                                    api: 'api/' + Resources.AccountsQuick,
                                    query: {
                                        AccountTypeID: ACCOUNT_TYPE_CASH,
                                    },
                                    searchMap: (item) => ({
                                        value: item.AccountID,
                                        label: item.AccountNumber + ' ' + item.AccountName
                                    })
                                }
                            }}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditResource : null}

                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditResource : null}
                            actions={[
                                {
                                    action: this.handlePreviewDocumentClick,
                                    customContent: () => <MagnifyingGlassIcon className="w-5 h-5"/>,
                                    visible: () => checkPerm(Resources.AccountingReconciliation, READ_PERM),
                                    title: translate('text.preview_document'),
                                    order: 20
                                }
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            title={'No matching records found'}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalSaveResource
                        title={'Reconciliation'}
                        widthClass="max-w-xl"
                        gridColsClass="grid-cols-12"
                        show={this.state.createModalOpen}
                        onClose={this.handleToggleCreateDialog}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true,
                                    successMessage: 'Reconciliation batch created successfully!'
                                }))
                                this.handleToggleCreateDialog()
                            }
                        }}
                        translate={this.props.translate}
                        metadata={{
                            AccountID: {
                                api: 'api/' + Resources.AccountsQuick,
                                query: {
                                    AccountTypeID: ACCOUNT_TYPE_CASH,
                                },
                                searchMap: (item) => ({
                                    value: item.AccountID,
                                    label: item.AccountNumber + ' ' + item.AccountName
                                })
                            }
                        }}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.setTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>

                <ModalDefault
                    show={this.state.isPreviewDocumentModalOpen}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    title={translate('modal_heading.recon_pdf')}

                    closeButtonLabel={translate('Close')}
                    onClose={() => this.setState({isPreviewDocumentModalOpen: false})}
                    buttonLabel={
                        !this.state.isPreviewDocumentLoading
                        && !this.state.isPreviewDocumentError
                        && translate('Download')
                    }
                    onButtonClick={this.downloadDocument}
                >

                    {this.state.isPreviewDocumentLoading && (
                        <div className='relative flex items-center justify-center h-64'>
                            <LoaderLarge/>
                        </div>
                    )}

                    {!this.state.isPreviewDocumentLoading && this.state.isPreviewDocumentError && (
                        <InfoBar type="danger">
                            {translate("text.document_load_fail")}
                        </InfoBar>
                    )}

                    {!this.state.isPreviewDocumentLoading && !this.state.isPreviewDocumentError && (
                        <FileViewer
                            fileType={'pdf'}
                            filePath={this.state.previewFileURL}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(AccountingReconciliationsView)
