export function getThirdResource(data) {
    return {
        type: 'GET_THIRD_RESOURCE',
        data: data
    };
}

export function doneGetThirdResource(data) {
    return {
        type: 'DONE_GET_THIRD_RESOURCE',
        data: data
    };
}

export function errorGetThirdResource(data) {
    return {
        type: 'ERROR_GET_THIRD_RESOURCE',
        data: data
    };
}

export function clearThirdResource() {
    return {
        type: 'CLEAR_THIRD_RESOURCE',
    };
}

export function createThirdResource(data) {
    return {
        type: 'CREATE_THIRD_RESOURCE',
        data: data
    };
}

export function doneCreateThirdResource(data) {
    return {
        type: 'DONE_CREATE_THIRD_RESOURCE',
        data: data
    };
}

export function errorCreateThirdResource(data) {
    return {
        type: 'ERROR_CREATE_THIRD_RESOURCE',
        data: data
    };
}

export function deleteThirdResource(data) {
    return {
        type: 'DELETE_THIRD_RESOURCE',
        data: data
    };
}

export function doneDeleteThirdResource(data) {
    return {
        type: 'DONE_DELETE_THIRD_RESOURCE',
        data: data
    };
}

export function errorDeleteThirdResource(data) {
    return {
        type: 'ERROR_DELETE_THIRD_RESOURCE',
        data: data
    };
}

export function updateThirdResource(data) {
    return {
        type: 'UPDATE_THIRD_RESOURCE',
        data: data
    };
}

export function doneUpdateThirdResource(data) {
    return {
        type: 'DONE_UPDATE_THIRD_RESOURCE',
        data: data
    };
}

export function errorUpdateThirdResource(data) {
    return {
        type: 'ERROR_UPDATE_THIRD_RESOURCE',
        data: data
    };
}
