import ImageContactAvatar from "./image-contact-avatar";
import ImageDefaultAvatar from "./image-default-avatar";
import React, { useEffect, useState } from "react";
import { getSizeClass } from "./image-utils";
import {LoaderSmall} from "../loader";
import {classNames} from "../../util/util-helpers";

export default function ImageAvatarLoader({ContactID, size, addClass, hasImagePath}) {
    const [isImageMissing, setIsImageMissing] = useState(!hasImagePath);
    const [isImageLoading, setIsImageLoading] = useState(true);

    useEffect(() => {
        setIsImageMissing(isImageMissing);
        setIsImageLoading(hasImagePath)
    }, [ContactID])

    return (
        <div className={classNames("relative inline-block", addClass)}>
            {!isImageMissing && (
                <ImageContactAvatar
                    size={size}
                    ContactID={ContactID}
                    onError={() => {
                        setIsImageMissing(true);
                        setIsImageLoading(false);
                    }}
                    onLoad={() => setIsImageLoading(false)}
                />
            )}

            {isImageLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <LoaderSmall/>
                </div>
            )}

            {isImageMissing && (
                <ImageDefaultAvatar
                    className={
                        classNames(
                            "rounded-full overflow-hidden ring-2 ring-inverse text-tm-gray-600",
                            getSizeClass(size)
                        )
                    }
                />
            )}

        </div>
    )
}