export function copyToClipboard(text) {
    let textField = window.document.createElement('textarea')
    textField.innerText = text;
    window.document.body.appendChild(textField);
    textField.select();
    window.document.execCommand('copy');
    textField.remove();
}

export function dirtyClone(object) {
    if (object) {
        return JSON.parse(JSON.stringify(object));
    }
}

export function cloneDeep(entity, cache = new WeakMap()) {
    const referenceTypes = ['Array', 'Object', 'Map', 'Set', 'WeakMap', 'WeakSet'];
    const entityType = Object.prototype.toString.call(entity);
    if (!new RegExp(referenceTypes.join('|')).test(entityType)) return entity;
    if (cache.has(entity)) {
        return cache.get(entity);
    }
    const c = new entity.constructor();

    if (entity instanceof Map || entity instanceof WeakMap) {
        entity.forEach((value, key) => c.set(cloneDeep(key), cloneDeep(value)));
    }
    if (entity instanceof Set || entity instanceof WeakSet) {
        entity.forEach((value) => c.add(cloneDeep(value)));
    }
    cache.set(entity, c);
    return Object.assign(c, ...Object.keys(entity).map((prop) => ({[prop]: cloneDeep(entity[prop], cache)})));
}

export function evaluate(str) {
    return Function(`'use strict'; return (${str})`)()
}

export const emailValid = (address) => {
    return !!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi.test(address)
}
export const genericMoneyFormatter = (number = 0) => {
    const currencyFormatter = new Intl.NumberFormat(undefined, {
        currency: "usd",
        style: "currency",
        minimumFractionDigits: 2,
    })

    return currencyFormatter.format(number)
}
export const setSelectionRange = (id, value) => {
    let underscore = value.indexOf("_")
    let input = document.getElementById(id)
    input.focus();
    input.setSelectionRange(underscore, underscore + 1);
}
export const getRandomID = (length = 10) => {
    let result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}
export const scrollIntoView = (e) => {
    const rect = e.target.getBoundingClientRect();
    const elementTop = rect.top
    const elementBottom = window.innerHeight - rect.bottom;
    if (elementTop < 95 || elementBottom < 95) {
        e.target.scrollIntoView({block: "center", behavior: "smooth"});
        return true;
    }

    return false;
}
export const scrollErrorIntoView = (fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (value.errorMessage) {
            const firstErrorInput = document.querySelector('input[name=' + key + ']');

            if (firstErrorInput) {
                firstErrorInput.parentElement.scrollIntoView({block: "center", behavior: "smooth"});
                return true;
            }
        }
    }

    return false;
}
export const scrollErrorIntoViewFields = (array) => {
    for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
            array[i].forEach((item) => {
                scrollErrorIntoView(item)
            })
        } else {
            scrollErrorIntoView(array[i])
        }
    }

    return false;
}

export function toggleBodyScroll() {
    let html = document.querySelector("html")
    html.style.overflow = html.getAttribute("style")?.includes("overflow: hidden") ? "auto" : "hidden"
}

export function isEmpty(value) {
    return value.trim().length > 1;
}

// Limit characters in a cell table where it's not possible to use CSS for that
export function ellipsis(str, characterLimit) {
    if (str && str.length > characterLimit) {
        return str.slice(0, characterLimit) + "...";
    }

    return str;
}

export function checkPasswordStrength(password) {

    // Regular Expressions.
    let regex = [];
    regex.push("[A-Z]"); // Uppercase Alphabet.
    regex.push("[a-z]"); // Lowercase Alphabet.
    regex.push("[0-9]"); // Digit.
    regex.push("[!@#$%^&*()\\-_=+{};:,<.>?~\"|']"); // Special Character.

    let passed = 0;

    // Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            passed++;
        }
    }

    // Validate for length of Password.
    if (passed > 2 && password.length >= 8) {
        passed++;
    } else {
        passed--;
    }

    // Display status.
    let color = "";
    let strength = "";
    switch (passed) {
        case -1:
            strength = "";
            color = "var(--color-gray-900)";
            break;
        case 0:
            strength = "Very weak";
            color = "var(--color-gray-900)";
            break;
        case 1:
            strength = "Very weak";
            color = "#c43d4b";
            break;
        case 2:
            strength = "Weak";
            color = "#b69329";
            break;
        case 3:
            strength = "Average";
            color = "#b69329";
            break;
        case 4:
            strength = "Strong";
            color = "#3e884f";
            break;
        case 5:
            strength = "Very Strong";
            color = "#3e884f";
            break;
        default:
    }

    return {
        passed: passed,
        strength: strength,
        color: color
    }
}

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
