import React, {Component} from 'react';
import {connect} from "react-redux";
import {LinkIcon, StarIcon} from "@heroicons/react/24/outline";
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon";
import NoRecordsTable from "../no-records-found/no-records-table";
import CellLink from "../resource-table/table-components/cell-link";
import StatusProgressTable from "../resource-table/table-components/status-progress-table";
import CellButton from "../resource-table/table-components/cell-button";
import TableCard from "../resource-table/table-components/table-card";
import TableCardFooter from "../resource-table/table-components/table-card-footer";
import Pagination from "../resource-table/table-components/pagination";
import {getProp, openInNewTab} from "../../util/util-helpers";
import {includeFields} from "../../util/util-fields";
import ResourceTable from "../../components/resource-table";
import StackedList from "../display-data/stacked-list";
import {Field} from "../../../data/services/fields";
import {showModal} from "../../../data/actions/ui";

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            sort: "DESC",
            sortBy: "LoadNumber",
            paginationPage: 1,
            limit: this.props.isCompact ? 5 : 10,
            favorites: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.sort !== this.state.sort) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== "") || (prevProps.limit !== this.props.limit)) {
            this.fetchData();
        }
    };

    fetchData = () => {
        this.props.onFetchData("Routes", {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            favorites: this.state.favorites
        })
    }

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    updateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        })
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            favorites: this.state.favorites
        }
    }

    handleLinkClick = (e) => {
        this.props.history.push(e);
    }

    toggleFavorites = () => {
        this.setState({
            favorites: this.state.favorites ? 0 : 1,
            offset: 0,
            limit: this.state.limit,
            sort: "DESC",
            sortBy: "LoadNumber",
            paginationPage: 1,
        }, this.fetchData);
    }

    setLimit = (limit) => {
        this.setState({
            offset: 0,
            paginationPage: 1
        })
        this.props.onSetLimit('routesLimit', limit)
    }

    getFields = () => {
        return {
            LoadNumber: new Field("LoadNumber", '', [''], false, "custom", {
                render: (it) => <CellLink to={`/loads/info/${it.LoadID}`} openInNewTab={true}>{it.LoadNumber}</CellLink>
            }),
            RouteStatusID: new Field("RouteStatusID", '', [''], false, 'custom', {
                render: (it) => <StatusProgressTable
                    addClass=""
                    LoadStatusID={it.LoadStatusID}
                    translate={this.props.translate}
                />
            }),
            Customer: new Field('Customer', "", [''], false, 'select-search', {
                render: (item) => {
                    if (!item.CustomerID) return null;
                    return (
                        <CellButton
                            onClick={() => {
                                this.props.dispatch(showModal('ViewCustomerCard', {
                                    CustomerID: item.CustomerID,
                                    OrganizationID: item.OrganizationID
                                }));
                            }}
                        >
                            {item.Customer}
                        </CellButton>
                    )
                }
            }),
            PickupDate: new Field("PickupDate", '', [''], false, 'datetime'),
            PickupCityName: new Field("PickupCityName", '', [''], false, 'text'),
            PickupState: new Field('PickupState', '', [''], false, "select"),
            PickupEndDate: new Field("PickupEndDate", '', [''], false, 'datetime'),
            DestinationCityName: new Field('DestinationCityName', '', [''], false, "text"),
            DestinationState: new Field('DestinationState', '', [''], false, "select"),
        }
    }

    render() {
        const {translate, stackedResource, isLoading, isCompact, routes} = this.props;
        return (
            <TableCard>
                <div className="px-5 py-3 flex justify-between">
                    <div
                        className="group flex items-center gap-2 relative cursor-pointer"
                    >
                        <div onClick={() => openInNewTab('/loads')}>
                            <LinkIcon
                                className="hidden hover:cursor-pointer group-hover:block w-5 h-5 text-primary absolute -left-4"/>

                            <h5 className="text-lg pl-2 ">
                                {translate("text.routes")}
                            </h5>
                        </div>
                    </div>

                    <div className="flex items-center">
                        {!!this.state.favorites && (
                            <button
                                className="btn btn-outline-secondary"
                                onClick={this.toggleFavorites}
                            >
                                <ListBulletIcon className="w-5 h-5 mr-1 -lm-1 text-tm-gray-500"/>
                                {translate('text.show_all')}
                            </button>

                        )}

                        {!this.state.favorites && (
                            <button
                                className="btn btn-outline-secondary"
                                onClick={this.toggleFavorites}
                            >
                                <StarIcon className="w-5 h-5 mr-1 -lm-1 text-tm-gray-500"/>
                                {translate('text.show_bookmarked')}
                            </button>
                        )}
                    </div>
                </div>

                {!isCompact && (
                    <ResourceTable
                        fields={this.getFields()}
                        data={routes}
                        isLoading={isLoading}
                        translate={translate}
                    />
                )}

                {isCompact && (
                    <StackedList
                        data={routes}
                        isLoading={isLoading}
                        listKey="LoadID"
                        onTapRow={(it) => openInNewTab(`/loads/info/${it.LoadID}`)}
                        fields={includeFields(this.getFields(), ['LoadNumber', 'RouteStatusID'])}
                    />
                )}

                <NoRecordsTable
                    show={(routes.length === 0) && !isLoading}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                />

                <TableCardFooter
                    show={!isLoading && routes && routes.length > 0}
                >
                    <Pagination
                        count={getProp(stackedResource.data, "dashboard/routes.dashboard/routes.count", 0)}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value, currentPage) => this.updateOffset(value, currentPage)
                        }
                        hideRowsPerPage={true}
                        pageOffset={this.state.offset}
                        pageLimit={this.state.limit}
                        translate={translate}
                    />
                </TableCardFooter>
            </TableCard>
        )
    }
}

export default connect(state => state)(Routes);
