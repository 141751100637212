import React from "react";

export default function TableFooterBulkActions({children, pagination, translate, areActionsVisible = true}) {
    return (
        <div className="flex flex-col md:flex-row w-full md:divide-x-2">
            {areActionsVisible && (
                <div className="flex items-center border-b md:border-none border-tm-gray-200 pb-2 md:pb-0">
                    <div className="flex-shrink-0 pl-3 md:hidden">
                        {translate("text.bulk_actions")}:
                    </div>

                    <div
                        className="flex divide-x-2 divide-tm-gray-200 border-tm-gray-200 w-full md:-ml-2"
                    >
                        {children}
                    </div>
                </div>
            )}
            <div className={"flex items-center w-full pl-3 w-full md:pt-0 pt-3"}>
                {pagination}
            </div>
        </div>
    )
}