import React, {Component} from 'react'
import {classNames, getProp} from '../../../../common/util/util-helpers'
import {toFrontDateNoTime} from '../../../../common/util/util-dates'
import {getInfoResource} from "../../../../data/actions/infoResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import {ChartLine} from "../../../../common/components/chart/chart-line";
import PopOver from "../../../../common/components/popover";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {fieldsToHtml, includeFields} from "../../../../common/util/util-fields";


export default class AccountingLineChartDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.fetchData();
        }

        if (this.props.filters !== prevProps.filters) {
            if (this.props.show) {
                this.fetchData();
            }
        }
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            query: Object.assign({}, this.props.query, {PerDate: 1}),
            resource: Resources.AccountingAccountReport
        }))
    }

    /** Render
     ================================================================= */

    render() {
        const {translate, info} = this.props

        const datePicker = this.props.children

        const filterFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(this.props.filters, ['StartDate', 'EndDate']))).map(it => {
            if (it.props) {
                it.props.isClearable = false
            }

            if (it.metadata) {
                it.metadata.addContainerClass = "col-span-1 mt-5"
            }

            return it
        }), translate, this.props.handleInputChange)

        let dataSet = []
        let data = getProp(info, 'data', [])

        if (Array.isArray(data)) {
            dataSet = data.reduce((memo, it) => {
                memo[it[0]] = {
                    label: toFrontDateNoTime(it[0]),
                    value: (it[1])
                }
                return memo
            }, {})
        }

        const graphData = [{
            label: 'Graph',
            data: dataSet,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
        }]

        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-full'}
                title={this.props.title}
                closeButtonLabel={translate('btn.Close')}
                onClose={this.props.onClose}
                afterTitle={<div className={"relative grid grid-cols-6 space-x-2 ml-4 mb-4 mr-10"}>
                    {filterFieldsHtml}
                    <div className={"mt-5"}>
                        <PopOver
                            className="block"
                            btnClass={classNames("form-control p-0 w-auto text-tm-gray-700 flex items-center")}
                            widthClass={"max-w-2xl"}
                            positionClass="absolute"
                            btnCustomHtml={(
                                <React.Fragment>
                                    <div className="py-2 px-3">
                                        <span className="relative">Filters</span>
                                    </div>

                                    <span className="h-5 w-px bg-tm-gray-200"/>

                                    <div className="py-1 px-2">
                                        <ChevronDownIcon className="w-5 h-5"/>
                                    </div>
                                </React.Fragment>
                            )}
                        >
                            <div className="bg-popup p-6 border border-tm-gray-300 rounded-lg">
                                <div
                                    className={classNames("grid gap-4 grid-cols-3")}>
                                    {datePicker}
                                </div>
                            </div>
                        </PopOver>
                    </div>
                </div>}
            >
                <div className="h-[calc(100vh-15rem)] w-full p-2">
                    <ChartLine
                        hideLegend={true}
                        data={graphData}
                    />
                </div>
            </ModalDefault>
        )
    }
}

