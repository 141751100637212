import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EyeIcon } from '@heroicons/react/24/outline'
import {Field} from "../../../../data/services/fields";
import ResourceTableDialog from "../resource-table-dialog";
import ModalDefault from "../modal-default";
import InvoiceExpenseDetailsDialog from "../invoice-expense-details-dialog";
import {download} from "../../../../data/actions/download";
import LocalStorage from "../../../../util/localStorage";
import {currentDate} from "../../../util/util-dates";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import Tooltip from "../../tooltip";

export default function AdvancesDialog ({
                                            resourceName,
                                            translate,
                                            show,
                                            onClose,
                                            ContactID,
                                            ContactName,
                                            AdvanceValue,
                                            expenseModalOnFetchData,
                                            expenseModalResource,
                                            downloadExcel
                                        }) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const dialogResource = useSelector((state) => state.dialogResource)

    /** State
     ================================================================= */
    const [isExpanseModalOpen, setIsExpanseModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})

    /** UI Events
     ================================================================= */
    function handleShowDetailsClick (it) {
        setSelectedItem(it)
        setIsExpanseModalOpen(true)
    }

    return (
        <React.Fragment>
            <ResourceTableDialog
                widthClass={'max-w-6xl'}
                show={show}
                dialogResource={dialogResource}
                resource={resourceName}
                title={ContactName ? translate('text.advances_details_name', [ContactName, AdvanceValue ?? 0]) : translate('text.advances_details')}
                dispatch={dispatch}
                isFooterVisible={true}
                limit={50}
                fields={{
                    Date: new Field('Date', '', [''], false, 'date', { omitSort: false }),
                    Name: new Field('Name', '', [''], false, 'text', { omitSort: true }),
                    Description: new Field('Description', '', [''], false, 'textarea', { omitSort: true }),
                    Account: new Field('Account', '', [''], false, 'text', { omitSort: true }),
                    Amount: new Field('Amount', '', [''], false, 'money', { omitSort: true })
                }}

                fieldsFilter={{
                    query: new Field('query', '', [''], false, 'search', { addContainerClass: 'col-span-2' }),
                    showOnlyBillAdvances: new Field('showOnlyBillAdvances', '', [''], false, 'checkbox', {
                        addContainerClass: 'col-span-full'
                    })
                }}
                onCustomActions={[
                    {
                        action: (it, i) => handleShowDetailsClick(it, i),
                        title: translate("btn.view"),
                        icon: EyeIcon,
                        visible: (it) => it.ExpenseID,
                    }]
                }

                tableKey="ExpenseID"
                defaultQuery={{ ContactID: ContactID }}
                onClose={onClose}
                translate={translate}

                htmlBefore={(typeof downloadExcel === 'function') ? (<div className={"flex justify-end mt-4 mr-4"}>
                    <Tooltip content={translate('text.download_excel')}>
                        <button
                            className="btn-icon"
                            onClick={downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tooltip>
                </div>) : null}
                htmlAfter={() => {
                    return !!selectedItem && <ModalDefault
                        show={isExpanseModalOpen}
                        widthClass={'max-w-5xl w-screen'}
                        title={'Expense'}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={() => setIsExpanseModalOpen(false)}
                    >
                        <InvoiceExpenseDetailsDialog
                            resource={expenseModalResource}
                            isLoading={expenseModalResource.isLoading}
                            translate={translate}
                            dispatch={dispatch}
                            showLoadReferenceLinks={true}
                            disableComments={true}
                            canDeletePayment={false}
                            disableAddReference={true}
                            disableTransactionImport={true}
                            expenseID={selectedItem?.ExpenseID}
                            onFetchData={() => expenseModalOnFetchData(selectedItem)}
                        />
                    </ModalDefault>
                }}
            />
        </React.Fragment>
    )
}
