import React from 'react'
import LoadInfoReadLocations from "../../../dispatch/load-view/load-sections/load-info-read-locations";
import LoadInfoCommodities from "../../../dispatch/load-view/load-sections/load-info-commodities";
import {
    convertMeasurements,
    generateStops,
    getCommodityFields,
    mergeStops
} from "../../../dispatch/load-view/load-utils";
import {cnv, getProp, getWeightUnitName} from "../../../../common/util/util-helpers";
import { numberWithCommas } from "../../../../util/util-formaters";
import Card from "../../../../common/components/card";

export default function PublicCarrierInfo({fieldsHtml, data, translate}) {

    const infoData = getProp(data, 'load/info', []);
    const commoditiesData = getProp(data, 'load/commodities', []);
    const multistopsData = getProp(data, 'load/multistops', []);

    const areLocationsAllowed = !!getProp(data, 'load/info.CarrierAllowLocation', false);

    let stops = generateStops(multistopsData, translate);

    let mergedStops = [];

    if (stops.length) {
        mergedStops = mergeStops(
            multistopsData,
            []
        );
    }

    const defaultWeightUnit = 'lb';
    const hasStops = !!stops.length;
    const hasCommodities = !!commoditiesData.length;

    let totalCommodityPieces = 0;

    const totalCommodityPallets = commoditiesData.reduce((memo, it) => {
        totalCommodityPieces += it.CountPieces;
        memo = memo + Number(it.CountPallets)
        return memo
    }, 0);

    let commodityUnitType = undefined;

    const totalCommodityWeight = numberWithCommas(commoditiesData.reduce((memo, it) => {
        if (!commodityUnitType) {
            commodityUnitType = it.UnitType
        }

        if (!!commodityUnitType && commodityUnitType !== it.UnitType) {
            commodityUnitType = translate("text.unit_s");
        }

        if (!it.Weight) {
            return memo
        }

        memo = memo + Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit))
        return memo
    }, 0))

    if (!commodityUnitType) {
        commodityUnitType = translate("text.unit_s");
    }

    return (
        <React.Fragment>
            <div className="max-w-3xl mx-auto py-8 space-y-4">
                <Card bodyClass={"p-6"}>
                    <div className="space-y-4 max-w-xl mx-auto">
                        <div
                            className="text-lg leading-6 font-medium text-tm-gray-900">
                            {translate('text.assets')}
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            {fieldsHtml}
                        </div>
                    </div>
                </Card>

                {hasStops && areLocationsAllowed && (
                    <LoadInfoReadLocations
                        loadedMilesText={translate("text.miles")}
                        TotalMiles={infoData.TotalMilesNoStopBy}
                        disableTotalMilesClick={true}
                        stopsCombined={mergedStops}
                        stops={stops}
                        stopBys={[]}
                        translate={translate}
                    />
                )}

                {hasCommodities && (
                    <LoadInfoCommodities
                        addClass="pt-9"
                        disableEdit={true}
                        data={convertMeasurements(commoditiesData)}
                        getCommodityFields={getCommodityFields}
                        totalCommodityPieces={totalCommodityPieces}
                        totalCommodityPallets={totalCommodityPallets}
                        commodityUnitType={commodityUnitType}
                        totalCommodityWeight={totalCommodityWeight}
                        defaultWeightUnit={defaultWeightUnit}

                        isSectionExpanded={true}
                        translate={translate}
                    />
                )}
            </div>
        </React.Fragment>
    )
}
