
import moment from "moment";
import {parseHtmlMessageWithMentions} from "../../../common/util/util-helpers";

const loadPresenceReducer = (state = {}, action) => {
    switch (action.type) {
        case 'JOIN_LOAD':
            return Object.assign({}, state, {
                data: {},
                messages: {
                    UserMessages: [],
                    CustomerMessages: []
                }
            });
        case 'LEFT_LOAD':
        case 'JOINED_LOAD':
            return Object.assign({}, state, {
                data: action.data
            });
        case 'EDITED_LOAD':
            return Object.assign({}, state, {
                data: action.data
            });
        case 'LOAD_MESSAGES':
            action.data.UserMessages = action.data.UserMessages.sort((a, b) => {
                return moment(a.CreateUpdateDate).isAfter(moment(b.CreateUpdateDate)) ? 1 : -1;
            });

            action.data.CustomerMessages = action.data.CustomerMessages.sort((a, b) => {
                return moment(a.CreateUpdateDate).isAfter(moment(b.CreateUpdateDate)) ? 1 : -1;
            });

            console.log('action.data.CustomerMessages', action.data.CustomerMessages)

            return Object.assign({}, state, {
                messages: action.data ?? {
                    UserMessages: [],
                    CustomerMessages: []
                }
            });
        case 'LOAD_USER_MESSAGE':
            if (!!action.data.Message) {
                action.data.Message = parseHtmlMessageWithMentions(action.data.Message);
            }

            let prevMessages = state.messages;
            if (!!state?.messages?.UserMessages && state?.messages?.UserMessages.length > 0) {
                prevMessages.UserMessages.push(action.data);
            } else {
                prevMessages.UserMessages = [action.data];
            }

            return Object.assign({}, state, {
                messages: prevMessages,
                UserMessagesCount: prevMessages?.UserMessages?.length ?? 0
            });
        case 'LOAD_CUSTOMER_MESSAGE':
            let prevMessagesCustomer = state.messages;
            if (!!state?.messages?.CustomerMessages && state?.messages?.CustomerMessages.length > 0) {
                prevMessagesCustomer.CustomerMessages.push(action.data);
            } else {
                prevMessagesCustomer.CustomerMessages = [action.data];
            }
            return Object.assign({}, state, {
                messages: prevMessagesCustomer,
                CustomerMessagesCount: prevMessagesCustomer?.CustomerMessages?.length ?? 0
            });
        case 'LOAD_EXTERNAL_MESSAGES':
            // Sort messages by time (it may not be 100% accurate as milliseconds are not included)
            action.data.CustomerMessages = action.data.CustomerMessages.sort((a, b) => {
                return moment(a.CreateUpdateDate).isAfter(moment(b.CreateUpdateDate)) ? 1 : -1;
            });

            return Object.assign({}, state, {
                messages: action.data ?? {
                    CustomerMessages: []
                }
            });
        case 'LOAD_EVENT':
            return Object.assign({}, state, {
                loadEvent: action.data.Data
            });
        default:
            return state;
    }
};

export default loadPresenceReducer;