import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    mileageToErrorMessage,
    returnMileage,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {showGlobalModal, showModal} from '../../../data/actions/ui'
import {createDialogResource} from '../../../data/actions/dialogResource'
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Layout from "../../../common/components/layout";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFooter from "../../../common/components/resource-table/table/table-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";

class TripsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)
        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            columns: {
                LoadQuoteID: {
                    minWidth: 220,
                    subColumns: [{'Labels': {show: true, name: 'Labels'}}]
                },
                Customer: {minWidth: 300},
                Truck: {minWidth: 200},
                Trailer: {minWidth: 200},
                Labels: {minWidth: 200, show: false},
                LoadStatus: {minWidth: 200}
            }
        }

        this.state = {
            offset: 0,
            limit: 50,
            paginationPage: 1,

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),

            sortBy: 'LoadTripID',
            sort: 'DESC',

            createModalShow: false,

            confirmSubmit: undefined,
            confirmTitle: "",
            confirmText: "",
            isConfirmDeleteDialogOpen: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.dialogResource.create && !prevProps.dialogResource.create) {
            this.setState({
                loadPreviewData: this.props.dialogResource.create
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    deleteResource = () => {
        this.setState({
            ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
        }, () => {
            this.props.dispatch(deleteResource({
                user: LocalStorage.get('user'),
                query: Object.assign({}, this.getQuery(),
                    {
                        LoadQuoteID: this.state.selectedItem.LoadQuoteID,
                    }
                ),
                errorMessage: true, successMessage: this.props.translate("message.trip_deleted_successfully"),
                resource: Resources.QuotesLoad,
                piggyResource: Resources.Trip
            }));

            this.setState({
                isConfirmDeleteDialogOpen: false,
                selectedItem: {}
            });
        })

    }

    /** UI Events
     ================================================================= */
    handleCreateResourceClick = () => {
        this.handleToggleCreateModal();
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleFilterInputChange = (name, value) => {
        let fields = this.state.fields;

        if ("ColumnFilters" === name) {
            fields = Object.values(fields).map(it => {
                it.value = "";
                return it;
            });

            fields = fields.reduce((memo, it) => {
                memo[it.name] = it;
                return memo
            }, [])
        }

        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1,
            fields: fields
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)
        let fields = this.state.fields;

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            if ("ColumnFilters" === it.name && !!it.value) {
                fields = Object.values(fields).map(it => {
                    it.value = "";
                    return it;
                });

                fields = fields.reduce((memo, it) => {
                    memo[it.name] = it;
                    return memo
                }, []);

            }

            if ('DateType' !== it.name) {
                FieldsManager.updateField(queryFilterFields, it.name, '')
            }
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            tableColumnFields: {},
            fields: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters();
            this.fetchData(true)
        })
    }

    handleTableRowClick = (it) => {
        this.props.history.push("/trip/info/" + it.LoadTripID);
    }

    handleEditResourceClick = (it) => {
        this.props.history.push("/trip/info/" + it.LoadTripID);
    }

    handleDeleteResourceClick = (it) => {
        this.setState({
            selectedItem: it,
            confirmSubmit: this.deleteResource,
            confirmTitle: this.props.translate("text.delete_trip"),
            confirmText: this.props.translate("text.delete_trip_confirm", [it.LoadQuoteID]),
            isConfirmDeleteDialogOpen: true
        })
    }

    handleCloseConfirmDialogClick = () => {
        this.setState({
            isConfirmDeleteDialogOpen: false,
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    /** UI Events dialogs
     ================================================================= */
    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    toggleLoadPreviewDialog = () => {
        this.setState({
            isLoadPreviewDialogOpen: !this.state.isLoadPreviewDialogOpen,
            loadPreviewData: {}
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalShow: !this.state.createModalShow
        })
    }

    /** UI Events table
     ================================================================= */
    handleFilterChange = (name, value) => {
        const fieldsUpdate = FieldsManager.updateField(this.state.fields, name, value);
        let queryFields = this.state.queryFilterFields

        queryFields = FieldsManager.updateField(queryFields, 'ColumnFilters', true)

        this.setState({
            fields: fieldsUpdate,
            queryFilterFields: queryFields
        }, () => {
            this.fetchData();
            this.persistFilters(fieldsUpdate);
            this.saveFilters();
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        const queryFilterFields = FieldsManager.getFieldKeyValues(this.state.queryFilterFields);
        const queryFilterFieldsWithValues = Object.keys(queryFilterFields).filter(it => !!queryFilterFields[it]).reduce((memo, it) => {
            memo[it] = queryFilterFields[it];
            return memo;
        }, {});

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...queryFilterFieldsWithValues,
            limit: this.state.queryFilterFields.limit.value
        }
    }

    getQueryFilterFields = (item = {}) => {
        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 10, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: "top"})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            LoadTripID: new Field('LoadTripID', '', [''], false, 'custom', {icon: false}),

            IsBroker: new Field('IsBroker', '', [''], false, 'custom', {
                render: (it) => it.IsBroker ?
                    <span
                        className={'w-5 h-5 font-bold text-indigo-500'}>{this.props.translate("text.brokerage")}</span> :
                    <span className={'w-5 h-5 font-bold text-green-600'}>{this.props.translate("text.carrier")}</span>
            }),

            Driver: new Field('Driver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "DriverID",
                    select: {
                        DriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (item.DriverContactID) {
                        return (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.DriverContactID}));
                                }}
                                className="btn btn-text hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Driver}
                            </button>
                        )
                    } else {
                        return (<span>{item.Driver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CoDriver: new Field('CoDriver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoDriver",
                    select: {
                        CoDriver: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (item.CoDriverContactID) {
                        return (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.CoDriverContactID}));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.CoDriver}
                            </button>
                        )
                    } else {
                        return (<span>{item.CoDriver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            Truck: new Field('Truck', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Truck}
                            </button>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Trailer: new Field('Trailer', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Trailer}
                            </button>
                        )
                    } else {
                        return (<span>{item.Trailer}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            SecondTrailer: new Field('SecondTrailer', '', ['']),
            ThirdTrailer: new Field('ThirdTrailer', '', ['']),
            TrailerType: new Field('TrailerType', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Carrier: new Field('Carrier', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true,
                colFilter: true
            }),
            PickupDate: new Field('PickupDate', '', [''], false, 'date'),
            Pickup: new Field('Pickup', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupCityName: new Field('PickupCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupPostalCode: new Field('PickupPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupState: new Field('PickupState', '', [''], false, "select", {colFilter: true}, {
                icon: false,
                isClearable: true
            }),
            Destination: new Field('Destination', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationCityName: new Field('DestinationCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationPostalCode: new Field('DestinationPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationState: new Field('DestinationState', '', [''], false, "select", {colFilter: true}, {
                icon: false,
                isClearable: true
            }),
            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => mileageToErrorMessage(returnMileage(item.TotalMiles), this.props.translate)
            }, {icon: false}),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => returnMileage(item.EmptyMiles)
            }, {icon: false}),
            TotalWeight: new Field('TotalWeight', '', [''], false, "custom", {
                render: (it) => {
                    return <div className="text-right">{it.TotalWeight}</div>
                }
            }),// TODO Add unit suffix, will be return by API in LBS
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
            CreateDate: new Field('CreateDate', '', [''], false, 'datetimezone', {hideDialog: true}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['offset', 'sort', 'sortBy', 'paginationPage', 'limit'])
        LocalStorage.persistFilters(this.pagePath, this.state.queryFilterFields);
    }

    toggleCreateNewTagModal = () => {
        this.setState({isCreateNewTagModalOpen: !this.state.isCreateNewTagModalOpen})
    }

    getPrimaryKey = () => {
        return 'LoadTripID'
    }


    getResource = () => {
        return Resources.Trips
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])

        const isLoading = resource.isLoading
        const count = resource?.data?.count

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <div className="space-y-4 lg:col-span-12">
                    <PageHeader
                        title={translate('page.heading.trips')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateResourceClick()}
                        buttonHidden={!checkPerm(Resources.Trip, CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                    </PageHeader>

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            excludedFields={['limit', 'DateType']}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.toggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass="relative">
                        <TableFilters
                            hideLimit
                            filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType', 'ColumnFilters'])}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            selects={{
                                limit: {
                                    10: '10',
                                    20: '20',
                                    30: '30',
                                    40: '40',
                                    50: '50',
                                    100: '100',
                                }
                            }}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(Resources.QuotesLoad, UPDATE_PERM) ? this.handleTableRowClick : null}
                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEditResourceClick : null}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.handleDeleteResourceClick : null}

                            actions={[]}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(Resources.Trip, CREATE_PERM)}
                            title={translate("text.no_matching_records")}
                            text={translate("text.create_new_trip")}
                            btnLabel={translate("btn.create_trip")}
                            onBtnClick={() => {
                                this.handleCreateResourceClick()
                            }}
                            excludeFields={['query', 'limit']}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        {/*Table footer*/}
                        {!(!data.length && !isLoading) && (
                            <TableFooter addClass={"px-6"}>
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableFooter>
                        )}
                    </TableCard>
                </div>

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.toggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalConfirm
                    show={this.state.isConfirmDeleteDialogOpen}
                    title={this.state.confirmTitle}
                    text={this.state.confirmText}
                    onClose={this.handleCloseConfirmDialogClick}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmSubmit}
                />

                <ModalSaveResource
                    title={"Create trip"}
                    widthClass="max-w-md"
                    show={this.state.createModalShow}
                    gridClass="grid gap-4 grid-cols-1 p-6"
                    onClose={this.handleToggleCreateModal}
                    fields={{
                        LoadNumber: new Field("LoadNumber", '', ['empty'], false, 'text'),
                        InternalNotes: new Field("InternalNotes", '', [''], false, "textarea")
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Trip,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: "Trip created."
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    metadata={{}}
                    translate={this.props.translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(TripsView)
