import {checkUserHelper} from "../services/api-util";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateContactResource,
    doneGetContactResource,
    doneUpdateContactResource,
    errorCreateContactResource,
    errorUpdateContactResource
} from "../actions/contactResource";
import {pushNotification} from "../actions/ui";
import {doneDeleteResource, doneGetResource} from "../actions/resource";
import {errorDeleteSecondResource} from "../actions/secondResource";

export function* updateContactResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;

    result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetContactResource(resPiggy.data));
                yield put(doneUpdateContactResource(result.data));
            }
        } else {
            yield put(doneUpdateContactResource(result.data));
        }

        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateContactResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchUpdateContactResource() {
    yield takeLatest('UPDATE_CONTACT_RESOURCE', updateContactResourceCall);
}

export function* createContactResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetContactResource(resPiggy.data));
                yield put(doneCreateContactResource(result.data));
            }
        } else {
            yield put(doneCreateContactResource(result.data));
        }

        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }

        } else {
            yield put(doneDeleteResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateContactResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchCreateContactResource() {
    yield takeLatest('CREATE_CONTACT_RESOURCE', createContactResourceCall);
}

export function* deleteContactResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetContactResource(resPiggy.data));
                if (action.data.onPiggyResultCallback) {
                    action.data.onPiggyResultCallback(resPiggy)
                }
            }
        }

        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteSecondResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchDeleteContactResource() {
    yield takeLatest('DELETE_CONTACT_RESOURCE', deleteContactResourceCall);
}