import React, {Component} from "react";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {cloneDeep, scrollErrorIntoView} from "../../../util/util-vanilla";
import {FieldsManager} from "../../../../data/services/fields";
import ModalDefault from "../modal-default";
import {LoaderSmall} from "../../loader";
import InfoParagraph from "../../info-paragraph";
import DropZoneAlt from "../../dropzone/drop-zone-alt";
import {classNames} from "../../../util/util-helpers";
import FileViewer from "../../../components/file-viewer/components";
import {fieldsToHtml} from "../../../util/util-fields";

export default class ModalSaveResourceWithDropZone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: cloneDeep(this.props.fields),
            canSubmit: !!this.props.canSubmit,
            isDropZoneActive: false
        }

        this.dropZoneRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (((prevProps.show !== this.props.show) && !!this.props.show) || (this.props.refreshFields !== prevProps.refreshFields)) {
            this.setState({
                fields: cloneDeep(this.props.fields),
                canSubmit: !!this.props.canSubmit
            })
        }

        if (prevProps.canSubmit !== this.props.canSubmit) {
            this.setState({canSubmit: this.props.canSubmit})
        }

        if (this.props.buttonDisabled !== prevProps.buttonDisabled) {
            this.setState({
                canSubmit: !this.props.buttonDisabled
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields, canSubmit: true});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        validatedFields = !!this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if (!!this.props.getRawFields) {
                return validatedFields
            }
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    render() {
        const {
            onSubmit,
            onClose,
            translate,
            title,
            metadata,
            gridClass,
            gridColsClass,
            limitHeight,
            show,
            widthClass,
            buttonLabel,
            htmlBefore,
            htmlAfter,
            customButtonsHTML,
            secondaryButtonLabel,
            secondaryButtonDisabled,
            disableSecondaryIfNotDirty,
            onSecondaryButtonClick,
            isLoading,
            addClass,
            onAcceptFiles,
            accept,
            fileUploadInfoText,
            maxFilesAccepted,
            files = [],
            documentPaths = [],
            filePreview = {
                show: false,
                fileType: "",
                filePath: ""
            },
            onClosePreview
        } = this.props;

        const canUploadMoreFiles = (files.length + documentPaths.length) < maxFilesAccepted;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <ModalDefault
                show={show}
                addClass={addClass}
                title={title}
                widthClass={widthClass}
                translate={translate}
                onClose={onClose}
                onButtonClick={() => onSubmit(this.getValidFields(), this.state.fields)}
                buttonDisabled={!this.state.canSubmit || isLoading}
                buttonLabel={buttonLabel ?? translate("btn.save")}
                closeButtonLabel={translate("btn.close")}
                customButtonsHTML={customButtonsHTML}
                limitHeight={limitHeight}
                secondaryButtonLabel={secondaryButtonLabel}
                secondaryButtonDisabled={
                    disableSecondaryIfNotDirty
                        ? !this.state.canSubmit || isLoading
                        : secondaryButtonDisabled
                }
                onSecondaryButtonClick={() => onSecondaryButtonClick(this.getValidFields(), this.state.fields)}
            >
                {isLoading && (
                    <div className="text-center">
                        <LoaderSmall/>
                    </div>
                )}

                {!isLoading && (
                    <div
                        className="relative max-h-[calc(100vh-12rem)] overflow-y-auto"
                        onDragEnter={() => {
                            this.setState({
                                isDropZoneActive: true
                            })
                        }}
                    >
                        <div>
                            {canUploadMoreFiles && (
                                <button
                                    onClick={() => this.dropZoneRef.current.click()}
                                    className="flex gap-x-1 sticky top-0 w-full h-7 px-4 cursor-pointer py-1 border-b border-primary border-dotted bg-primary-transparent backdrop-blur-md z-20">
                                    Attach files by dropping them or <span
                                    className="text-primary-tint font-semibold">click here</span> to
                                    select them.

                                    {fileUploadInfoText && (
                                        <Tippy content={fileUploadInfoText}>
                                            <InformationCircleIcon className="ml-2 text-primary w-5 h-5"/>
                                        </Tippy>
                                    )}
                                </button>
                            )}

                            {this.props.errorMessage && (
                                <div className={"pt-6 px-6"}>
                                    <InfoParagraph type="danger">
                                        {translate(this.props.errorMessage)}
                                    </InfoParagraph>
                                </div>
                            )}

                            {htmlBefore}

                            <div
                                className={gridClass ?? classNames("grid gap-4 p-6", gridColsClass ?? "grid-cols-6", "grid gap-4")}>
                                {fieldsHtml}
                            </div>

                            {htmlAfter}
                        </div>

                        <DropZoneAlt
                            className={
                                classNames(
                                    "absolute inset-0 ",
                                    this.state.isDropZoneActive && canUploadMoreFiles ? "z-50" : "-z-10"
                                )
                            }
                            maxFilesAccepted={maxFilesAccepted}
                            accept={accept}
                            translate={translate}
                            onAcceptFiles={(files) => {
                                onAcceptFiles(files);
                                this.setState({
                                    isDropZoneActive: false
                                })
                            }}
                            content={(isDragAccept, isDragReject) => {
                                return (
                                    <div
                                        ref={this.dropZoneRef}
                                        onDragLeave={() => {
                                            this.setState({
                                                isDropZoneActive: false
                                            })
                                        }}
                                        className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "pb-4 min-h-[8rem] absolute top-7 left-0 right-0 bottom-0 z-50"
                                        )}>

                                    </div>
                                )
                            }}
                        />
                    </div>
                )}


                <ModalDefault
                    show={!!filePreview.filePath}
                    widthClass={"max-w-7xl"}
                    limitHeight={true}
                    title={translate("text.document_preview")}

                    closeButtonLabel={translate("btn.close")}
                    onClose={onClosePreview}
                >
                    <FileViewer
                        fileType={filePreview.fileType}
                        filePath={filePreview.filePath}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                </ModalDefault>


            </ModalDefault>
        );
    }
}
