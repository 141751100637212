import React, { Fragment, useEffect, useState } from 'react';
import moment from "moment";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, } from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import CalendarMonthView from "./calendar-month-view";
import CalendarWeekView from "./calendar-week-view";
import CalendarDayView from "./calendar-day-view";
import CalendarYearView from "./calendar-year-view";
import {classNames, getProp} from "../../../common/util/util-helpers";
import LocalStorage from "../../../common/util/localStorage";

const viewMenu = [
    {id: "day", name: "Day view",},
    {id: "week", name: "Week view"},
    {id: "month", name: "Month view"},
    // {id: "year", name: "Year view"},
]

const weekFormat = [
    {id: 0, name: "Sunday", abbr: "S", abbrExt: "un"},
    {id: 1, name: "Monday", abbr: "M", abbrExt: "on"},
    {id: 2, name: "Tuesday", abbr: "T", abbrExt: "ue"},
    {id: 3, name: "Wednesday", abbr: "W", abbrExt: "ed"},
    {id: 4, name: "Thursday", abbr: "T", abbrExt: "hu"},
    {id: 5, name: "Friday", abbr: "F", abbrExt: "ri"},
    {id: 6, name: "Saturday", abbr: "S", abbrExt: "at"},
];

const isoWeekFormat = [ // may be used in future
    {id: 0, name: "Monday", abbr: "M", abbrExt: "on"},
    {id: 1, name: "Tuesday", abbr: "T", abbrExt: "ue"},
    {id: 2, name: "Wednesday", abbr: "W", abbrExt: "ed"},
    {id: 3, name: "Thursday", abbr: "T", abbrExt: "hu"},
    {id: 4, name: "Friday", abbr: "F", abbrExt: "ri"},
    {id: 5, name: "Saturday", abbr: "S", abbrExt: "at"},
    {id: 6, name: "Sunday", abbr: "S", abbrExt: "un"},
];

const getWeekFormat = () => {
    return weekFormat;
}

const getMonth = () => {
    return moment().month() + 1;  // +1 since months are zero indexed
}

const storedCalendar = LocalStorage.get("dashboard_calendar")

export default function Calendar( {events, renderMonthlyViewEvents, renderMonthlyViewMobileEvents, renderDayViewEvents, onFetchData, onDayClick = () => null, onTimeClick, hasCreatePerm, infoIcon, actions, calendarHeight = "", customViewMenuItems = [], addContainerClass} ) {

    const numOfDaysDisplayed = 42;
    const today = moment().format("YYYY-MM-DD");

    const [activeDay, setActiveDay] = useState(storedCalendar?.day ? storedCalendar.day : moment().format("YYYY-MM-DD"));
    const [activeView, setActiveView] = useState(storedCalendar?.view ? storedCalendar.view :"month");

    const [activeWeek, setActiveWeek] = useState(storedCalendar?.week ? storedCalendar.week : moment().week())
    const [activeMonth, setActiveMonth] = useState(storedCalendar?.month ? storedCalendar.month : getMonth())
    const [activeYear, setActiveYear] = useState(storedCalendar?.year ? storedCalendar.year : moment().year());

    const updateActiveView = (activeView) => {
        setActiveView(activeView);
        let storage = LocalStorage.get("dashboard_calendar", {});
        LocalStorage.set("dashboard_calendar", Object.assign(storage, {view: activeView}))
    }

    const updateActiveDay = (activeDay) => {
        setActiveDay(activeDay);
        let storage = LocalStorage.get("dashboard_calendar", {});
        LocalStorage.set("dashboard_calendar", Object.assign(storage, {day: activeDay}))
    }

    const updateActiveWeek = (activeWeek) => {
        setActiveWeek(activeWeek);
        let storage = LocalStorage.get("dashboard_calendar", {});
        LocalStorage.set("dashboard_calendar", Object.assign(storage, {week: activeWeek}))
    }

    const updateActiveMonth = (activeMonth) => {
        setActiveMonth(activeMonth);
        let storage = LocalStorage.get("dashboard_calendar", {});
        LocalStorage.set("dashboard_calendar", Object.assign(storage, {month: activeMonth}))
    }

    const updateActiveYear = (activeYear) => {
        setActiveYear(activeYear);
        let storage = LocalStorage.get("dashboard_calendar", {});
        LocalStorage.set("dashboard_calendar", Object.assign(storage, {year: activeYear}))
    }

    const firstDay = moment(activeMonth + " " + activeYear, "M YYYY").startOf('month');
    const activeMonthDisplay = moment(activeMonth + " " + activeYear, "M YYYY").format("MMMM YYYY");

    const buffer = firstDay.day() ? firstDay.day() : 7; // Draw one whole buffer row is there are no buffer days

    let calendarDay = firstDay.subtract("days", buffer);
    const calendarDays = Array.from(Array(numOfDaysDisplayed).keys());

    const days = calendarDays.map(( it, i ) => {
        const thisDay = calendarDay.clone().add("days", i).format('YYYY-MM-DD');
        return (
            {
                date: thisDay,
                day: moment(thisDay, 'YYYY-MM-DD').format("DD"),
                isCurrentMonth: moment(activeMonth + " " + activeYear, "M YYYY").isSame(thisDay, 'month'),
                isCurrentWeek: moment(moment().week(activeWeek)).isSame(thisDay, 'week'),
                isSelected: thisDay === activeDay,
                isToday: thisDay === today,
                events: getProp(events, thisDay, [])
            }
        );
    })

    let selectedDay = days.find(day => day.date === activeDay);

    if (!selectedDay) {
        days.find(day => day.date === activeDay)
    }

    const setNextMonth = () => {
        if (activeMonth === 12) {
            updateActiveMonth(1);
            updateActiveYear(Number(activeYear) + 1);
        } else {
            updateActiveMonth(Number(activeMonth) + 1);
        }
    }

    const setPrevMonth = () => {
        if (activeMonth === 1) {
            updateActiveMonth(12);
            updateActiveYear(Number(activeYear) - 1);
        } else {
            updateActiveMonth(Number(activeMonth) - 1);
        }
    }

    const setNextWeek = () => {
        const nextWeek = Number(activeWeek) + 1;
        const nextWeekMonth = moment(nextWeek, 'w').format('M');

        if (activeMonth !== Number(nextWeekMonth)) {
            updateActiveMonth(Number(activeMonth) + 1);
        } else {
            updateActiveWeek(nextWeek);
        }
    }

    const setPrevWeek = () => {
        const prevWeek = activeWeek - 1;
        const prevWeekMonth = moment(activeWeek, 'w').format('M');

        if (activeMonth !== Number(prevWeekMonth)) {
            updateActiveMonth(Number(activeMonth) - 1);
        } else {
            updateActiveWeek(prevWeek);
        }
    }

    const setNextDay = () => {
        const nextDay = moment(activeDay, 'YYYY-MM-DD').add(1, "days");
        const currentDayMonth = moment(activeDay, 'YYYY-MM-DD').format('M');
        const nextDayMonth = nextDay.format('M');

        if (currentDayMonth !== nextDayMonth) {
            updateActiveMonth(Number(currentDayMonth) + 1);
        }

        if (Number(currentDayMonth) !== Number(activeMonth)) {
            updateActiveMonth(currentDayMonth); // Brings back focus to activeDay month in day view
        }

        if (nextDay.week() !== activeWeek) {
            updateActiveWeek(nextDay.week());
        }

        updateActiveDay(nextDay.format('YYYY-MM-DD'));
    }

    const setPrevDay = () => {
        const prevDay = moment(activeDay, 'YYYY-MM-DD').subtract(1, "days");
        const currentDayMonth = moment(activeDay, 'YYYY-MM-DD').format('M');
        const prevDayMonth = prevDay.format('M');

        if (activeMonth !== Number(prevDayMonth)) {
            updateActiveMonth(Number(activeMonth) - 1);
        }

        if (Number(currentDayMonth) !== Number(activeMonth)) {
            updateActiveMonth(currentDayMonth); // Brings back focus to activeDay month in day view
        }

        if (prevDay.week() !== activeWeek) {
            updateActiveWeek(prevDay.week());
        }

        updateActiveDay(prevDay.format('YYYY-MM-DD'));
    }

    const resetActiveDate = () => {
        updateActiveWeek(storedCalendar?.week ? storedCalendar.week : moment().week());
        updateActiveMonth(storedCalendar?.month ? storedCalendar.month : getMonth());
        updateActiveYear(storedCalendar?.year ? storedCalendar.year : moment().year());

        updateActiveDay(storedCalendar?.day ? storedCalendar.day : today);
    }

    const handleNextClick = () => {
        if ("month" === activeView) {
            setNextMonth();
        }

        if ("week" === activeView) {
            setNextWeek();
        }

        if ("day" === activeView) {
            setNextDay();
        }
    }

    const handlePrevClick = () => {
        if ("month" === activeView) {
            setPrevMonth()
        }

        if ("week" === activeView) {
            setPrevWeek();
        }

        if ("day" === activeView) {
            setPrevDay();
        }
    }

    const handleViewChangeClick = ( id ) => {
        updateActiveView(id);
        resetActiveDate();
    }

    useEffect(() => {
        onFetchData(activeYear, activeMonth);
    }, [activeYear, activeMonth])

    return (
        <React.Fragment>
            <div className="sm:mb-6 lg:mb-8 rounded-lg border border-tm-gray-300 overflow-hidden">
                <header
                    className="relative flex items-center justify-between border-b border-tm-gray-300 py-4 px-6 lg:flex-none z-20 bg-tm-gray-50">
                    <h1 className="flex text-lg font-semibold text-tm-gray-900">
                        <span>{activeMonthDisplay}</span>

                        {infoIcon}
                    </h1>

                    <div className="flex items-center">
                        <div className="flex items-center rounded-full shadow-sm md:items-stretch">
                            <button
                                onClick={handlePrevClick}
                                type="button"
                                className="flex items-center justify-center rounded-l-full border border-r-0 border-tm-gray-300 bg-inverse py-2 pl-3 pr-4 text-tm-gray-400 hover:text-tm-gray-900 focus:relative md:w-9 md:px-2 md:hover:bg-tm-gray-50"
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                            <button
                                onClick={resetActiveDate}
                                type="button"
                                className="hidden border-t border-b border-tm-gray-300 bg-inverse px-3.5 text-sm font-medium text-tm-gray-700 hover:bg-tm-gray-50 hover:text-tm-gray-900 focus:relative md:block"
                            >
                                Today
                            </button>
                            <span className="relative -mx-px h-5 w-px bg-tm-gray-300 md:hidden"/>
                            <button
                                onClick={handleNextClick}
                                type="button"
                                className="flex items-center justify-center rounded-r-full border border-l-0 border-tm-gray-300 bg-inverse py-2 pl-4 pr-3 text-tm-gray-400 hover:text-tm-gray-900 focus:relative md:w-9 md:px-2 md:hover:bg-tm-gray-50"
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>

                        {viewMenu.length > 1 && (
                            <div className="hidden md:ml-4 md:flex md:items-center">
                                <Menu as="div" className="relative">
                                    {( {open} ) => (
                                        <>
                                            <Menu.Button
                                                type="button"
                                                className="flex items-center rounded-full border border-tm-gray-300 bg-inverse py-2 pl-5 pr-2 text-sm font-medium text-tm-gray-700 shadow-sm hover:bg-tm-gray-50"
                                            >
                                                <span><span className="capitalize">{activeView}</span> view</span>
                                                <ChevronDownIcon className="ml-2 h-5 w-5 text-tm-gray-400" aria-hidden="true"/>
                                            </Menu.Button>

                                            <Transition
                                                as={Fragment}
                                                show={open}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="focus:outline-none absolute text-left right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-inverse shadow-lg ring-1 ring-black ring-opacity-5">
                                                    <div className="py-1">
                                                        {viewMenu.map(it => (
                                                            <Menu.Item key={it.id}>
                                                                {( {active} ) => (
                                                                    <button
                                                                        onClick={() => handleViewChangeClick(it.id)}
                                                                        className={classNames(
                                                                            active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                            'text-left w-full block px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        {it.name}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        ))}

                                                        {customViewMenuItems.map(it => (
                                                            <Menu.Item key={it.id}>
                                                                {( {active} ) => (
                                                                    <button
                                                                        onClick={() => it.onMenuItemClick(it.id)}
                                                                        className={classNames(
                                                                            active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                            'text-left w-full block px-4 py-2 text-sm'
                                                                        )}
                                                                    >
                                                                        {it.name}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>

                                {!!actions?.length && (
                                    <React.Fragment>
                                        <div className="ml-6 h-6 w-px bg-tm-gray-300 md:mr-6"/>

                                        <div className="space-x-4">
                                            {actions?.filter(button => button.visible).map(button => (
                                                <button
                                                    onClick={() => button.onClick(activeDay)}
                                                    type="button"
                                                    className={button.className}
                                                >
                                                    {!!button.icon && <button.icon className="h-4 w-4 mr-1"/>}
                                                    {button.label}
                                                </button>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}

                        <Menu as="div" className="relative ml-6 md:hidden">
                            {( {open} ) => (
                                <>
                                    <Menu.Button
                                        className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-tm-gray-400 hover:text-tm-gray-300 hover:text-tm-gray-600">
                                        <span className="sr-only">Open menu</span>
                                        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-body overflow-hidden rounded-md bg-inverse shadow-lg ring-1 ring-black ring-opacity-5">
                                            {hasCreatePerm && (
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {( {active} ) => (
                                                            <button
                                                                onClick={() => onDayClick(activeDay)}
                                                                className={classNames(
                                                                    active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                    'w-full text-left block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                Create
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            )}
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {( {active} ) => (
                                                        <button
                                                            onClick={resetActiveDate}
                                                            className={classNames(
                                                                active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                'w-full text-left block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Go to today
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                            <div className="py-1">
                                                {viewMenu.map(it => (
                                                    <Menu.Item key={it.id}>
                                                        {( {active} ) => (
                                                            <button
                                                                onClick={() => handleViewChangeClick(it.id)}
                                                                className={classNames(
                                                                    active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                    'text-left w-full block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                {it.name}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}

                                                {customViewMenuItems.map(it => (
                                                    <Menu.Item key={it.id}>
                                                        {( {active} ) => (
                                                            <button
                                                                onClick={() => it.onMenuItemClick(it.id)}
                                                                className={classNames(
                                                                    active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                    'text-left w-full block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                {it.name}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    </div>
                </header>

                {activeView === 'day' && (
                    <CalendarDayView
                        days={days}
                        weekFormat={getWeekFormat()}
                        selectedDay={selectedDay}
                        activeMonthDisplay={activeMonthDisplay}
                        onSetActiveDay={updateActiveDay}
                        setNextMonth={setNextMonth}
                        setPrevMonth={setPrevMonth}
                        onDayClick={onDayClick}
                        onTimeClick={() => onTimeClick}
                        renderDayViewEvents={renderDayViewEvents}
                        addContainerClass={addContainerClass}
                    />
                )}

                {activeView === 'week' && (
                    <CalendarWeekView
                        days={days}
                        onDayClick={onDayClick}
                        weekFormat={getWeekFormat()}
                        calendarHeight={calendarHeight}
                        selectedDay={selectedDay}
                        activeMonth={activeMonth}
                        setActiveDay={updateActiveDay}
                        activeMonthDisplay={activeMonthDisplay}
                        renderDayViewEvents={renderDayViewEvents}
                        addContainerClass={addContainerClass}
                    />
                )}

                {activeView === 'month' && (
                    <CalendarMonthView
                        days={days}
                        weekFormat={getWeekFormat()}
                        onDayClick={onDayClick}
                        renderMonthlyViewEvents={renderMonthlyViewEvents}
                        renderMonthlyViewMobileEvents={renderMonthlyViewMobileEvents}
                    />
                )}

                {activeView === 'year' && (
                    <CalendarYearView/>
                )}
            </div>
        </React.Fragment>
    )
}
