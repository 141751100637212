import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useRef, useState} from "react";
import DocumentListUploadDialog from "./document-list-upload-dialog";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../util/localStorage";
import DocumentPreviewModal from "./documents-list-preview-slide";
import DocumentVersionsModal from "./document-versions-modal";
import NoRecords from "../../no-records-found/no-records";
import DropZoneAlt from "../../dropzone/drop-zone-alt";
import {getDefaultTableOptions, saveTableColumns} from "../../../util/util-helpers";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {
    createDocumentResource, deleteDocumentResource,
    getDocumentResource,
    updateDocumentResource
} from "../../../../data/actions/documentResource";
import {getUser} from "../../../util/util-auth";
import {
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DEFAULT_LIMIT,
    DEFAULT_OFFSET,
    DEFAULT_SORT,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE
} from "../../../util/util-consts";
import {classNames, resourceIsCreated, resourceIsUpdated} from "../../../util/util-helpers";
import ResourceTableTopBar from "../../resource-table/table-components/resource-table-top-bar";
import TableFilters from "../../resource-table/table-components/table-filters";
import ModalSaveResource from "../../modal/modal-save-resource";
import ModalDefault from "../../modal/modal-default";
import ModalConfirm from "../../modal/modal-confirm";
import ResourceTable from "../../resource-table";
import useQuery from "../../../../hooks/use-query";
import {download} from "../../../../data/actions/download"
import {HourglassIcon} from "../../../../data/themes/icons";
import {updateResource} from "../../../../data/actions/resource";


export default function DocumentsList({
                                          translate,
                                          getDocumentTableFields,
                                          getDocumentUploadFields,
                                          titleNoDocuments,
                                          documentsResource,
                                          downloadDocumentResource,
                                          accept,
                                          onGetQueryFields,
                                          //additionalQueryFields,
                                          primarySortKey,
                                          id,
                                          fileUploadPiggyQuery,
                                          fileUploadPiggyResource = documentsResource,
                                          hasUpdatePerm = true,
                                          locationPath,
                                          primaryDocumentKey = "DocumentID",
                                          deleteDocumentKey = primaryDocumentKey,
                                          uploadDocumentButtonRef = undefined,
                                          tableTopBarClass,
                                          disableEdit
                                      }) {

    /** Store
     ================================================================= */
        //const resource = useSelector((state) => storeResource ? state[storeResource] : state.resource);
    const resource = useSelector((state) => state.documentResource);

    const count = resource?.data?.count ?? 0;

    const data = resource?.data?.list ?? [];
    const isLoading = resource?.isLoading;

    const dispatch = useDispatch();

    /** Constants
     ================================================================= */
    const dropZoneClickRef = useRef();

    /** State
     ================================================================= */
    const [files, setFiles] = useState([]);
    const [documentIndex, setDocumentIndex] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isDocumentDragged, setIsDocumentDragged] = useState(false);
    const [isPreviewDocumentOpen, setIsPreviewDocumentOpen] = useState(false);
    const [isVersionsModalOpen, setIsVersionsModalOpen] = useState(false);
    const [queryFields, setQueryFields] = useQuery(getQueryFields(), locationPath);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const [tableOptions, setTableOptions] = useState(
        getDefaultTableOptions(getDocumentUploadFields(), {
            columns: {
                DocumentTypeID: {frozen: false},
            },
            behaviour: {
                rowSelect: true,
                hasMenu: false,
                canAdjustWidth: true
            }
        }, locationPath, translate)
    );

    /** Data events
     ================================================================= */
    function fetchData() {
        dispatch(getDocumentResource({
            user: getUser(),
            resource: documentsResource,
            query: FieldsManager.getFieldKeyValues(queryFields)
        }));
    }

    function handleUploadFiles(files) {
        if (documentsResource) {
            const filesToUpload = files.map(file => Object.assign(file, {
                params: Object.assign({}, FieldsManager.getFieldKeyValues(file.fields), {
                    Version: 0,
                    uuid: 0
                })
            }))

            dispatch(createDocumentResource({
                user: LocalStorage.get("user"),
                files: filesToUpload,
                id: id,
                query: fileUploadPiggyQuery ?? FieldsManager.getFieldKeyValues(queryFields),
                resource: documentsResource,
                errorMessage: true,
                successMessage: `Attachment${files.length > 1 ? "s" : ""} ${files.length > 1 ? "have" : "has"} been uploaded successfully.`,
                piggyResource: fileUploadPiggyResource
            }))
        }
    }

    function onUploadVersion(document, params = {}) {
        const files = [Object.assign(document, {
            params: Object.assign({}, {
                uuid: selectedItem?.uuid,
                name: document.name
            }, params)
        })]

        dispatch(createDocumentResource({
            user: LocalStorage.get("user"),
            files: files,
            id: id,
            query: Object.assign({}, {
                id: id,
            }, fileUploadPiggyQuery ?? FieldsManager.getFieldKeyValues(queryFields)),
            resource: documentsResource,
            errorMessage: true,
            successMessage: `New Version has been uploaded successfully.`,
            piggyResource: fileUploadPiggyResource
        }))
    }

    /** UI events
     ================================================================= */
    function handleToggleViewDocument(item = null) {
        let documentIndex = null
        if (item) {
            documentIndex = data.findIndex(elem => elem[primaryDocumentKey] === item[primaryDocumentKey])
        }

        setDocumentIndex(documentIndex);
        setIsPreviewDocumentOpen(!isPreviewDocumentOpen);
    }

    function handleToggleConfirmModal(item = null) {
        setIsConfirmModalOpen(!isConfirmModalOpen)
        setSelectedItem(item)
    }

    function handleUpdateClick(item) {
        setSelectedItem(item);
        setIsUpdateModalOpen(true);
    }


    function handleQueryChange(name, value) {
        let queryFieldsUpdate = Object.assign({}, queryFields);
        if ("sortBy" === name && queryFields.sortBy.value === value) {
            queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === "ASC" ? "DESC" : "ASC";
        } else {
            queryFieldsUpdate.sort.value = "ASC"
        }

        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        setQueryFields(Object.assign({}, queryFieldsUpdate));
    }

    function handleHistoryClick(item) {
        setIsVersionsModalOpen(true);
        setSelectedItem(item);
    }

    const handleDownloadDocumentClick = (item) => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: downloadDocumentResource,
            query: Object.assign({}, {
                DocumentID: item.DocumentID,
                name: item.DocumentName
            }),
            notificationMessage: 'Successfully downloaded document!'
        }))
    }

    function handleOnPreviousDocumentPreviewClick() {
        if (documentIndex > 0) {
            setDocumentIndex(documentIndex - 1)
        }
    }

    function handleOnNextDocumentPreviewClick() {
        if (documentIndex < (data.length - 1)) {
            setDocumentIndex(documentIndex + 1);
        }
    }

    /** Helpers
     ================================================================= */
    function getQueryFields() {
        let fieldsTemplate = {
            query: new Field('query', '', [''], false, 'search', {
                hasActiveBadge: true,
                labelType: "float"
            }, {placeholder: translate("text.search")}),
            sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
            sortBy: new Field('sortBy', primarySortKey ?? "DocumentID", [''], false, 'hidden'),
            offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
            limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {
                hasPortal: true,
                placeholder: "100",
                menuPlacement: "top"
            })
        }

        if (onGetQueryFields) {
            fieldsTemplate = Object.assign({}, fieldsTemplate, onGetQueryFields());
        }

        return fieldsTemplate;
    }

    function handleSetTableOptions(options) {
        setTableOptions(options);
        if (locationPath) {
            saveTableColumns(locationPath, options);
        }
    }

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [queryFields]);

    useEffect(() => {
        let updatedItem = undefined;
        if (selectedItem) {
            updatedItem = data.find(it => it.uuid === selectedItem.uuid);
        }

        if (updatedItem) {
            setSelectedItem(updatedItem)
        } else if (resourceIsCreated(resource)) {
            setFiles([]); // clear files
        }

        if (resourceIsCreated(resource) || resourceIsUpdated(resource)) {
            setIsUpdateModalOpen(false);
            setFiles([]);
        }
    }, [resource]);

    // useEffect(() => {
    //     if (documentResource?.data?.id) {
    //         setFiles([]); // clear files
    //     }
    // }, [documentResource]);

    useEffect(() => {
        setQueryFields(Object.assign({}, queryFields, onGetQueryFields()));
    }, [id]);

    /** Render
     ================================================================= */
    return (
        <>
            <div
                className="relative z-0 h-full min-h-[10rem] pb-9"
                onDragEnter={() => {
                    setIsDocumentDragged(true)
                }}
            >
                <ResourceTableTopBar className={tableTopBarClass}>
                    <TableFilters
                        hideLimit
                        addClass={"items-center gap-x-2 items-center text-tm-gray-900 inline-block w-full"}
                        className={"flex gap-2"}
                        filterFields={queryFields}
                        handleInputChange={handleQueryChange}
                        translate={translate}
                    />
                </ResourceTableTopBar>

                <ResourceTable
                    data={data}
                    fields={getDocumentTableFields()}

                    tableKey={primaryDocumentKey}
                    queryFields={queryFields}
                    translate={translate}
                    isLoading={isLoading}
                    onSortChange={(fieldName) => {
                        handleQueryChange("sortBy", fieldName)
                    }}
                    options={tableOptions}
                    saveTableOptions={handleSetTableOptions}

                    onRowClick={handleToggleViewDocument}
                    onView={handleToggleViewDocument}
                    onDelete={handleToggleConfirmModal}
                    onEdit={!disableEdit ? handleUpdateClick : undefined}
                    actions={[
                        {
                            action: handleHistoryClick,
                            customContent: () => <HourglassIcon className="w-5 h-5"/>, // make this a function
                            visible: (it) => {
                                return hasUpdatePerm && it?.Version !== undefined
                            },
                            title: translate('text.version_manager'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                        {
                            action: handleDownloadDocumentClick,
                            icon: ArrowDownTrayIcon,
                            visible: () => true,
                            title: translate('btn.download'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                    ]}
                />

                <NoRecords
                    show={!isLoading && !count}
                    addClass="px-10 pt-10 pb-16"
                    title={
                        queryFields.query.value
                            ? `No results for '${queryFields.query.value}'`
                            : titleNoDocuments ?? "No documents found in database"
                    }
                    text={
                        hasUpdatePerm && !queryFields.query.value
                            ? "Upload a document by dropping a file or clicking the button."
                            : undefined
                    }
                    btnLabel={
                        queryFields.query.value
                            ? "Clear query"
                            : hasUpdatePerm
                                ? translate("btn.upload_document")
                                : undefined
                    }
                    isButtonIconHidden={!!queryFields.query.value}
                    onBtnClick={() => queryFields.query.value
                        ? handleQueryChange('query', '')
                        : dropZoneClickRef.current && dropZoneClickRef.current.click()}
                />

                {hasUpdatePerm && !isLoading && !queryFields.query.value && (
                    <DropZoneAlt
                        className={
                            classNames(
                                "",
                                isDocumentDragged ? "bg-green-600 inset-0 z-50" : "absolute left-0 right-0 h-9"
                            )
                        }
                        maxFilesAccepted={10}
                        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                        accept={accept ?? DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                        translate={translate}
                        onAcceptFiles={(acceptedFiles) => {
                            setFiles(acceptedFiles.map((file, index) => Object.assign(file, {
                                preview: URL.createObjectURL(file),
                                errorMessage: '',
                                fields: Object.assign({}, getDocumentUploadFields(index))
                            })));
                            setIsDocumentDragged(false);
                        }}
                        content={(isDragAccept, isDragReject) => {
                            return (
                                <>
                                    <div
                                        onDragLeave={() => setIsDocumentDragged(false)}
                                        className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "pb-4 absolute inset-0 rounded-card"
                                        )}>

                                    </div>

                                    <div
                                        ref={uploadDocumentButtonRef}
                                        className="h-9 flex text-sm items-center gap-1 absolute bottom-0 left-0 right-0 px-6 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                        Attach files by dragging and dropping them or
                                        <span className="text-primary-tint font-semibold"
                                              ref={dropZoneClickRef}>{" "} click here</span> to
                                        select them.
                                    </div>
                                </>
                            )
                        }}
                    />
                )}
            </div>

            <DocumentListUploadDialog
                files={files}
                setFiles={setFiles}
                onUploadFiles={handleUploadFiles}
                isLoading={isLoading}
                locationPath={locationPath}
                translate={translate}
            />

            <ModalSaveResource
                title={"Edit document"}
                widthClass="max-w-3xl"
                gridColsClass={"grid-cols-12"}
                show={isUpdateModalOpen}
                translate={translate}
                fields={getDocumentUploadFields(selectedItem)}
                onClose={() => setIsUpdateModalOpen(false)}
                onSubmit={(params) => {
                    if (params) {
                        params.id = selectedItem[primaryDocumentKey];
                        if (params.DocumentType) { // Fix this inside the modal
                            delete params.DocumentType
                        }

                        dispatch(updateDocumentResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: fileUploadPiggyQuery ?? FieldsManager.getFieldKeyValues(queryFields),
                            resource: documentsResource,
                            piggyResource: fileUploadPiggyResource,
                            errorMessage: true,
                            successMessage: `Document metadata has been updated`,
                        }));

                        setIsUpdateModalOpen(false);
                    }
                }}
                resourceName={documentsResource}
            />

            <DocumentPreviewModal
                show={isPreviewDocumentOpen}
                translate={translate}
                item={data[documentIndex]}

                onButtonClick={() => handleDownloadDocumentClick(data[documentIndex])}
                buttonLabel={translate("btn.download")}
                // leftButtons={
                //     [{
                //         label: translate("btn.print"),
                //         type: "outline",
                //         onClick: () => handlePrintDocumentClick(data[documentIndex])
                //     }]
                //
                // }
                closeButtonLabel={translate("btn.close")}
                onClose={handleToggleViewDocument}
                isLoading={isLoading}

                showPreviousButton={documentIndex > 0}
                showNextButton={documentIndex < (data.length - 1)}
                onNextDocumentPreviewClick={handleOnNextDocumentPreviewClick}
                onPreviousDocumentPreviewClick={handleOnPreviousDocumentPreviewClick}

                id={id}
                primaryKey={primaryDocumentKey}
                resourceName={downloadDocumentResource}
            />

            <ModalDefault
                show={isVersionsModalOpen}
                widthClass={'max-w-3xl'}
                title={translate("text.version_manager")}
                closeButtonLabel={translate('btn.close')}
                onClose={() => setIsVersionsModalOpen(false)}
            >
                <DocumentVersionsModal
                    translate={translate}
                    isLoading={isLoading}
                    selectedItem={selectedItem}
                    onUploadVersion={onUploadVersion}
                    id={id}
                    resourceName={documentsResource}
                    handleDownloadDocument={handleDownloadDocumentClick}
                    onEditDocument={(params) => {
                        if (params) {
                            if (params?.DocumentType) { // Fix this inside the modal
                                delete params.DocumentType
                            }

                            dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: fileUploadPiggyQuery,
                                resource: documentsResource,
                                piggyResource: fileUploadPiggyResource,
                                errorMessage: true,
                                successMessage: `Document has been updated`,
                            }))
                        }
                    }}
                    onDocumentDelete={(params) => {
                        if (params) {
                            if (params?.DocumentType) { // Fix this inside the modal
                                delete params.DocumentType
                            }

                            dispatch(deleteDocumentResource({
                                user: LocalStorage.get("user"),
                                query: {
                                    [primaryDocumentKey]: params[primaryDocumentKey]
                                },
                                piggyQuery: FieldsManager.getFieldKeyValues(queryFields),
                                resource: documentsResource,
                                piggyResource: fileUploadPiggyResource,
                                errorMessage: true, successMessage: "Document deleted successfully"
                            }));
                        }
                    }}
                />
            </ModalDefault>

            <ModalConfirm
                title={"Confirm"}
                show={isConfirmModalOpen}
                content={translate("text.confirm_delete_document")}
                onClose={handleToggleConfirmModal}
                buttonLabel={translate("btn.delete")}
                type={"danger"}
                closeButtonLabel={translate('btn.cancel')}
                translate={translate}
                onConfirm={() => {
                    dispatch(deleteDocumentResource({
                        user: LocalStorage.get("user"),
                        query: {
                            [deleteDocumentKey ?? primaryDocumentKey]: selectedItem[deleteDocumentKey ?? primaryDocumentKey]
                        },
                        piggyQuery: fileUploadPiggyQuery ?? FieldsManager.getFieldKeyValues(queryFields),
                        resource: documentsResource,
                        piggyResource: fileUploadPiggyResource,
                        errorMessage: true, successMessage: "Document deleted successfully"
                    }));
                    handleToggleConfirmModal();
                }}
            >
                {translate("text.confirm_delete_document")}
                <p className="font-bold">{selectedItem?.DocumentName}</p>
            </ModalConfirm>
        </>
    )
}