import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import InvoicesTab from './invoicesTab'
import CollectionsTab from './collectionsTab'
import CheckTab from './checkTab'
import {Field} from '../../../data/services/fields'
import {CREATE_PERM, READ_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import TransactionsTab from './transactionsTab'
import TransfersTab from './transfersTab'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import {LoaderSmall} from "../../../common/components/loader";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import IncomeRecurringTab from "./income-recurring-tab";
import VendorCreditTab from "../expense/vendorCreditTab";
import CreditMemoTab from "./creditMemoTab";

class IncomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: this.getTabs(),
            breakpoint: {},
            collectionsTotalAmount: 0,
            collectionsTotalBalance: 0
        }
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            })
        })
    }

    /** Helpers
     ================================================================= */
    getEmailFields = () => {
        return {
            EmailTemplateID: new Field('EmailTemplateID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full md:col-span-6'}),
            emails: new Field('emails', '', ['empty'], false, 'creatable', {
                addContainerClass: 'col-span-8',
                onEnterDown: true
            }, {
                isMulti: true,
                components: {DropdownIndicator: null},
                menuIsOpen: false,
                placeholder: 'Type email...'
            }),
            AddContactID: new Field('AddContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4 ml-4'}, {placeholder: 'Filter contacts...'}),
            subject: new Field('subject', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            emailMessage: new Field('emailMessage', '', [], false, 'rich-text', {addContainerClass: 'col-span-full'}),
        }
    }

    getTabs = () => {
        return [
            {
                name: 'Invoices',
                resource: Resources.Invoices,
                current: true,
                visible: checkPerm(Resources.Invoices, READ_PERM)
            },
            {
                name: 'IncomeTransactions',
                resource: Resources.IncomeTransactions,
                current: false,
                visible: checkPerm(Resources.IncomeTransactions, READ_PERM)
            },
            {
                name: 'CreditMemo',
                resource: Resources.IncomeCredit,
                current: false,
                visible: checkPerm(Resources.IncomeCredit, READ_PERM)
            },
            {
                name: 'IncomeCollections',
                resource: Resources.Collections,
                current: false,
                visible: checkPerm(Resources.Invoices, READ_PERM)
            },
            {
                name: 'IncomeTransfers',
                resource: Resources.IncomeTransfers,
                current: false,
                visible: checkPerm(Resources.IncomeTransfers, READ_PERM)
            },
            {
                name: 'IncomeChecks',
                resource: Resources.IncomeChecks,
                current: false,
                visible: checkPerm(Resources.IncomeChecks, READ_PERM)
            },
            {
                name: 'IncomeRecurring',
                resource: Resources.IncomeRecurring,
                current: false,
                // visible: checkPerm(Resources.IncomeRecurring, READ_PERM)
            }
        ]
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const activeTab = this.state.tabs.find(it => it.current);

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('text.income')}
                        buttonHidden={!checkPerm(Resources.Journal, CREATE_PERM)}
                        afterTitle={(<>
                                <PageHeaderInfo
                                    dispatch={this.props.dispatch}
                                />
                                {!!getProp(this.props.resource, 'isLoading', true) && this.state.activeTab === Resources.Collections && (
                                    <LoaderSmall/>
                                )}
                                {(activeTab.resource === Resources.Collections && !getProp(this.props.resource, 'isLoading', true)) && (
                                    <div>Total
                                        amount: {genericMoneyFormatter(getProp(this.props.resource.data, 'TotalAmount', 0))} (Balance: {genericMoneyFormatter(getProp(this.props.resource.data, 'TotalBalance', 0))})</div>
                                )}
                            </>
                        )}
                    />

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="block sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {activeTab.resource === Resources.Invoices && (
                        <InvoicesTab
                            onDownloadDocument={this.handleDownloadDocument}
                            getEmailFields={this.getEmailFields}
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.IncomeTransactions && (
                        <TransactionsTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.IncomeCredit && (
                        <CreditMemoTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.Collections && (
                        <CollectionsTab
                            getEmailFields={this.getEmailFields}
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {activeTab.resource === Resources.IncomeTransfers && (
                        <TransfersTab
                            {...this.props}
                        />
                    )}

                    {activeTab.resource === Resources.IncomeChecks && (
                        <CheckTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}

                    {activeTab.resource === Resources.IncomeRecurring && (
                        <IncomeRecurringTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(IncomePage)
