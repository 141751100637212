import {checkUserHelper} from "../services/api-util";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneGetHelpResource,
    doneUpdateHelpResource,
    errorGetHelpResource,
    errorUpdateHelpResource
} from "../actions/helpResource";
import {pushNotification} from "../actions/ui";

export function* getHelpResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetHelpResource(result.data));
    } else {
        yield put(errorGetHelpResource(result.data));
    }
}

export function* watchGetHelpResource() {
    yield takeLatest('GET_HELP_RESOURCE', getHelpResourceCall);
}

export function* updateHelpResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetHelpResource(resPiggy.data));
                yield put(doneUpdateHelpResource(result.data));
            }
        } else {
            yield put(doneUpdateHelpResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateHelpResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchUpdateHelpResource() {
    yield takeLatest('UPDATE_HELP_RESOURCE', updateHelpResourceCall);
}
