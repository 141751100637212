export default function loadUpdateReducer(state = {}, action) {
    switch (action.type) {
        case 'LOAD_UPDATED':
                return Object.assign({}, state, {
                    data: action.data
                });
        case 'CLEAR_LOAD_UPDATED':
            return {};
        default:
            return state;
    }
}