import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {toFrontDateNoTime} from '../../../../common/util/util-dates'
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../../util/util-constants'
import {genericMoneyFormatter} from '../../../../common/util/util-vanilla'
import ModalDefault from "../../../../common/components/modal/modal-default";
import {fieldsToHtml, includeFields} from "../../../../common/util/util-fields";
import {LoaderLarge} from "../../../../common/components/loader";
import {getProp, renderInvoiceStatusBadge, resourceIsCreated} from "../../../../common/util/util-helpers";

export default class CreateBatchDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            invoices: this.getInvoices()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.setState({
                fields: this.getFields(),
                invoices: this.getInvoices()
            })
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource) && this.props.resource.resource === 'accounting/income/invoice/batch') {
            this.props.onClose();
        }
    }

    submit = (zip) => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.onSubmit(this.state.fields, this.state.invoices, zip)
            }
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields;

        this.setState({
            fields: FieldsManager.updateField(fields, name, value)
        });
    }

    getFields = () => {
        return {
            Description: new Field('Description', '', [], false, 'textarea', {addContainerClass: 'col-span-6'}),
            AssignedToContactID: new Field('AssignedToContactID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-6'}),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {label: 'Completed'}),

        }
    }

    getInvoices = () => {
        const compareFunction = (a, b) => a.RefNumber?.localeCompare(b.RefNumber, 'en', {numeric: true})
        return Object.keys(this.props.selectedItems).map(it => {
            return {
                [this.getItemKeyLabel()]: this.props.selectedItems[it][this.getItemKeyLabel()],
                ...this.props.selectedItems[it]
            }
        }).sort(compareFunction).map(it => {
            it.balance = it.Amount - it.AmountTransferred;
            it.EnteredAmount = it.balance; // Initial Amount assigned
            return it;
        })
    }


    getItemKeyLabel = () => {
        return this.props.itemKeyLabel ?? ''
    }

    render() {
        const {translate} = this.props

        const isLoading = getProp(this.props.resource, 'isLoading', false)
        const selects = {
            AssignedToContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
        }
        const invoices = this.state.invoices.map((elem) => {
            return (
                <div key={elem.InvoiceID} className="table-row">
                    <div className="table-cell lead-5 py-3 pl-3">{elem.AutoCounter}</div>
                    <div className="table-cell lead-5 py-3 pl-3">{elem.RefNumber}</div>
                    <div className="table-cell lead-5 text-center py-3 pl-3">{toFrontDateNoTime(elem.Date)}</div>
                    <div className="table-cell lead-5 py-3 pl-3">{elem.Organization ?? elem.Contact}</div>
                    <div className="table-cell lead-5 py-3 pl-3">{genericMoneyFormatter(elem.Amount)}</div>
                    <div
                        className="table-cell lead-5 py-3 pl-3">{genericMoneyFormatter(elem.Amount - elem.AmountTransferred)}</div>
                    <div className="table-cell lead-5 py-3 pl-3">
                        <div className={"relative top-4"}>{renderInvoiceStatusBadge(elem)}</div>
                    </div>
                </div>
            )
        })


        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-7xl'}
                title={translate('title.create_invoices_batch')}
                onButtonClick={() => this.submit(0)}
                buttonLabel={translate('btn.save')}
                middleButtonLabel={translate("btn.save_and_zip")}
                onMiddleButtonClick={() => this.submit(1)}
                buttonDisabled={!invoices.length}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={this.props.onClose}
            >
                {!!isLoading && (
                    <div className={"h-32"}>
                        <LoaderLarge stripesBg={true}/>
                    </div>
                )}

                {!isLoading && (
                    <div className="space-y-4 p-5">
                        <div className="grid grid-cols-12 gap-x-8 gap-y-2">
                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.fields, ['Description', 'AssignedToContactID','IsPayed']))), translate, this.handleInputChange, selects)}
                        </div>

                        <div className="table break-words mb-1 w-full">
                            <div
                                className="table-header-group text-tm-gray-900 font-semibold p-2 mt-4 bg-tm-gray-100">
                                <div className="table-row">
                                    <div className="table-cell lead-5 py-2 pl-3">{translate('table.Number')}</div>
                                    <div className="table-cell lead-5 py-2 pl-3">{translate('table.RefNumber')}</div>
                                    <div
                                        className="table-cell lead-5 py-2 text-center px-1.5">{translate('table.Date')}</div>
                                    <div className="table-cell lead-5 py-2 pl-3">{translate('table.ChargeTo')}</div>
                                    <div className="table-cell lead-5 py-2 pl-3">{translate('table.Amount')}</div>
                                    <div
                                        className="table-cell lead-5 py-2 pl-3">{translate('table.AmountTransferred')}</div>
                                    <div className="table-cell lead-5 py-2 pl-3">{translate('table.Status')}</div>
                                </div>
                            </div>

                            <div className="table-row-group text-tm-gray-700">
                                {invoices}
                            </div>
                        </div>
                    </div>
                )}
            </ModalDefault>
        )
    }
}

