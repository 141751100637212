import {Field} from "../../../data/services/fields";
import Tippy from "@tippyjs/react";
import FireIcon from "@heroicons/react/24/outline/FireIcon";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import {TruckIcon} from "@heroicons/react/24/outline";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {checkPerm, longTableColumn, renderInvoiceStatusBadge} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {READ_PERM} from "../../../common/util/util-consts";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import {toFrontDate} from "../../../common/util/util-dates";
import {XMarkIcon} from "@heroicons/react/20/solid";
import React from "react";

export default function getInvoicesFields() {
    return {
        AutoCounter: new Field('AutoCounter', '', [''], false, 'text', {
            render: (item) => {
                return (
                    <div className="flex">
                        {item.AutoCounter}
                        {!!item.Marked && (
                            <div className="ml-auto flex pl-5">
                                <Tippy
                                    content={this.props.translate('field.Marked')}
                                >
                                        <span className="relative">
                                            <FireIcon
                                                className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/>
                                        </span>
                                </Tippy>
                            </div>
                        )}
                    </div>
                )
            }
        }),
        Labels: new Field('Labels', '', ['empty'], false, 'custom', {
            render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
        }),
        RefNumber: new Field('RefNumber', '', [''], false, 'text'),
        CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, 'text', {}),
        IsLoadInvoice: new Field('IsLoadInvoice', '', ['empty'], false, 'custom', {
            render: (it) => !!it.IsLoadInvoice &&
                <Tippy content={<span>Load Invoice</span>}><TruckIcon className={'w-5 h-5'}/></Tippy>
        }),
        InvoiceType: new Field('InvoiceType', '', [''], false, 'text'),
        Date: new Field('Date', '', [''], false, 'date'),
        BookDate: new Field('BookDate', '', [''], false, 'date', {hideDialog: true}),
        DueDate: new Field('DueDate', '', [''], false, 'date'),
        ChargeTo: new Field('ChargeTo', '', [], false, 'custom', {
            omitSort: true,
            label: 'ChargeTo'
        }),
        Amount: new Field('Amount', '', [''], false, 'money', {}),
        AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
            addTableHeaderClass: 'ml-auto',
            render: (item) => {
                return (
                    <div className="flex justify-end w-full">
                        {genericMoneyFormatter(item.Amount - item.AmountTransferred)}
                    </div>
                )
            }
        }),
        InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
            render: (it) => renderInvoiceStatusBadge(it)
        }),
        Batch: new Field('Batch', '', [''], false, 'custom', {
            render: (it) => {
                return (<CellButton disabled={!checkPerm(Resources.InvoicesBatch, READ_PERM)}
                                    onClick={() => it.InvoiceBatchID && this.setState({
                                        batchDetailsDialog: true,
                                        batchID: it.InvoiceBatchID
                                    })}>
                    <div className="flex justify-center w-full text-primary">{it.InvoiceBatchNumber ?? "/"}</div>
                </CellButton>)
            }
        }),
        IsDigitalSent: new Field('IsDigitalSent', '', [''], false, 'checkbox'),
        IsDigitalConfirmed: new Field('IsDigitalConfirmed', '', [''], false, 'checkbox'),
        Office: new Field('Office', '', [''], false, 'text'),
        ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: 'ExpenseGroupID'}),
        IsRevised: new Field('IsRevised', '', [''], false, 'custom', {
            addTableHeaderClass: 'mx-auto',
            render: (it) => it.IsRevised
                ? <div className="flex">
                    <div className="text-center">
                        <CheckIcon className="w-5 h-5 text-green-600 mr-2 inline"/>
                    </div>
                    {toFrontDate(it.RevisedDate)}
                </div>
                :
                <div className="text-center"><XMarkIcon className="w-5 h-5 text-tm-gray-600 inline"/></div>
        }),
        RevisedDate: new Field('RevisedDate', '', [''], false, 'date', {hideTable: true}),
        ExternalNotes: new Field('ExternalNotes', '', [''], false, 'custom', {
            render: (item) => (
                <div>{longTableColumn(item.ExternalNotes)}</div>
            )
        }),
        InternalNotes: new Field('InternalNotes', '', [''], false, 'custom', {
            render: (item) => (
                <div>{longTableColumn(item.InternalNotes)}</div>
            )
        }),
        IsFactoringActive: new Field('IsFactoringActive', '', [''], false, 'checkbox'),
        CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
        UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
    }
}