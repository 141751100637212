import {toFrontDateTime} from "../../../util/util-dates";
import {Link} from "react-router-dom";
import PhoneArrowUpRightIcon from "@heroicons/react/24/outline/PhoneArrowUpRightIcon";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import React from "react";
import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/20/solid/XCircleIcon";
import {showModal} from "../../../../data/actions/ui";
import {genericMoneyFormatter} from "../../../util/util-vanilla";
import {classNames, concatFullPhoneNumber} from "../../../util/util-helpers"
import {integerWithCommas, numberWithCommas} from "../../../util/util-formaters";
import {AtSymbolIcon} from "@heroicons/react/24/outline";
import CopyToClipboardButton from "../../buttons/copy-to-clipboard-button";

// TODO: update all to display-data-field.js and delete this file
export default function DataCard({displayField, fieldsData, selects, className, addClass, dispatch, translate}) {
    displayField = getDisplayFieldValue(displayField, fieldsData, selects, translate);

    if (displayField.value === null || displayField.value === undefined || displayField.value === "" || displayField.value === " " || displayField?.value?.label === " ") {
        return null;
    }

    let action = getDataCardAction(displayField, fieldsData, dispatch, translate);

    return (
        <React.Fragment>
            {displayField.type === "textarea" && (
                <div className={displayField?.metadata?.addContainerClass}>
                    <p className="font-bold leading-5 mb-2">{displayField.name}</p>

                    <div
                        className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center"
                    >
                        {displayField.value}
                    </div>
                </div>
            )}

            {displayField.type !== "textarea" && (
                <div className={
                    classNames(
                        "-mx-6 px-6 group relative",
                        displayField?.metadata?.addContainerClass
                    )
                }>
                    <div className="absolute h-full flex items-center">
                        {displayField.type !== 'checkbox' && (
                            <CopyToClipboardButton
                                addClass={"group-hover:visible invisible -left-10  text-tm-gray-600"}
                                clipboardText={displayField.value}
                                translate={translate}
                            />
                        )}
                    </div>

                    <div
                        className={className ?? (classNames("bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-3 py-1.5 flex items-center", addClass))}
                    >
                        {displayField?.iconComponent && (
                            <div className="w-8">
                                {displayField.iconComponent}
                            </div>
                        )}

                        <div className="w-full">
                            <p className="font-bold leading-5 word-break">{displayField?.value?.label ?? displayField.value}</p>

                            {!!displayField.name && (
                                <p className="text-tm-gray-500 text-xs leading-4">
                                    {displayField?.metadata?.labelRaw ?? translate("field." + (displayField?.metadata?.label ?? displayField.name))}
                                </p>
                            )}
                        </div>

                        {!!action && (
                            <div className="ml-auto pr-2">
                                {action}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

function getDisplayFieldValue(field, data, selects, translate) {
    if (data === undefined || data === "") return null;

    if (!!field?.metadata?.icon) {
        let Icon = field.metadata.icon();
        field.iconComponent = <Icon className={"w-5 h-5 text-tm-gray-600"}/>
    }

    switch (field?.type) {
        case 'date':
            field.value = toFrontDateTime(data[field.name]).split(" ")[0];
            break;
        case 'phone':
            field.value = concatFullPhoneNumber(data.Phones ? data.Phones.find(it => it.IsPrimary) : data);
            break;
        case 'driverPhone':
            field.value = data[field.name]
            break;
        case 'address':
            field.value = [data.AddressName, data.AddressName2, data.CityName, [data.State, data.PostalCode].filter(it => !!it).join(" "), data.Country].filter(it => !!it).join(", \n");
            break;
        case 'checkbox': {
            let customIcon = !!field?.metadata?.customIcon && field.metadata.customIcon();
            field.iconComponent = data[field.name]
                ? customIcon
                    ? customIcon
                    : <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                : <XCircleIcon className="w-5 h-5 text-red-600"/>;
            field.value = translate("field." + (field?.metadata?.label ?? field.name));
            field.name = null;
            break
        }
        case 'select':
            field.value = selects[field.name][data[field.name]]
            break;
        case 'money':
            field.value = genericMoneyFormatter(data[field.name])
            break;
        case 'integer':
            field.value = integerWithCommas(data[field.name])
            break;
        case 'float':
            field.value = numberWithCommas(data[field.name])
            break;
        case 'custom':
            field.value = field?.metadata?.render(data)
            break;
        default:
            field.value = data[field.name];
    }

    return field;
}

export function getDataCardAction(field, data, dispatch, translate) {
    if (!!field?.metadata?.fieldOptions) {
        return field.metadata.fieldOptions(data[field.name])
    }

    switch (field.type) {
        case "email":
            return (
                <Link
                    className="btn btn-icon p-1 text-tm-gray-600 hover:text-tm-gray-900"
                    title={translate("btn.send_mail")}
                    to='#'
                    onClick={(e) => {
                        window.location.href = "mailto:" + data[field.name];
                        e.preventDefault();
                    }}
                >
                    <AtSymbolIcon className={"w-5 h-5"}/>
                </Link>
            )
        case "phone":
            return (
                <div className="flex gap-x-2">
                    {!!field?.metadata?.morePhones && field.metadata.morePhones()}

                    <button
                        className="btn btn-icon p-1 text-tm-gray-600 hover:text-tm-gray-900"
                        title={translate("btn.call")}
                        onClick={(e) => {
                            window.open('tel:' + data[field.name]?.replace(/\D/g, ''))
                        }}
                    >
                        <PhoneArrowUpRightIcon className={"w-5 h-5"}/>
                    </button>
                </div>
            )
        case "driverPhone":
            return (
                <div className="flex gap-x-2">
                    {!!field?.metadata?.morePhones && field.metadata.morePhones()}

                    <button
                        className="btn btn-icon p-1 text-tm-gray-600 hover:text-tm-gray-900"
                        title={translate("btn.call")}
                        onClick={(e) => {
                            window.open('tel:' + data[field.name]?.replace(/\D/g, ''))
                        }}
                    >
                        <PhoneArrowUpRightIcon className={"w-5 h-5"}/>
                    </button>
                </div>
            )
        case "contact":
            if (!dispatch) return null;

            return (
                <button
                    className="btn btn-icon p-1 text-tm-gray-600 hover:text-tm-gray-900"
                    title={translate("btn.quick_view")}
                    onClick={() => {
                        dispatch(showModal('ViewContactCard', {ContactID: data.ContactID}))
                    }}
                >
                    <EyeIcon className={"w-5 h-5"}/>
                </button>
            )
        default:
            return null;
    }
}
