const previewDocumentReducer = (state = {}, action) => {
    switch (action.type) {
        case 'PREVIEW_DOCUMENT_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_PREVIEW_DOCUMENT_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_PREVIEW_DOCUMENT_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'RESET_PREVIEW_DOCUMENT_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                create: null,
                isLoading: false,
                error: false,
                errorMessage: null,
                errorStatus: null,
                showRedirectDialog: false
            });
        default:
            return state;
    }
};

export default previewDocumentReducer;
