import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import { getProp } from '../../util/util-helpers'
import {LoaderSmall} from "../loader";

export default class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            darkTheme: LocalStorage.get("IS_THEME_DARK") ?? false,
            logout: false,
            loader: false
        }
    }

    componentDidMount() {
        this.state.darkTheme && document.documentElement.classList.add('dark')
    }

    componentDidUpdate() {
        if (this.props.location.pathname) {
            let pageTitle;
            const path = this.props.location.pathname.split("/");
            if (path[2] === 'info') {
                pageTitle = this.props.translate("page.title." + path[1] + ".info") + (path[3] ? " - " + path[3] : "");
            } else {
                pageTitle = this.props.translate("page.title." + path[1]);
            }

            let compName = "ACCUR8 TMS";
            if (LocalStorage.has("user")) {
                compName = getProp(LocalStorage.get("user"), 'Contact.CompanyName', "ACCUR8 TMS");
            }
            if (document.title !== pageTitle) {
                document.title = pageTitle + " | " + compName;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={"rounded"}>
                    {this.state.loader && (
                        <div className="absolute position-absolute-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!this.state.loader && (
                        this.props.children
                    )}
                </div>
            </React.Fragment>
        )
    }
}
