import React, {Component} from 'react'

import {
    LOAD_PRICING_FLAT,
    LOAD_PRICING_PER_MILE,
    LOAD_PRICING_PER_PIECE,
    LOAD_PRICING_PER_VOLUME,
    LOAD_PRICING_PER_WEIGHT
} from '../../../../util/util-constants'
import {classNames, getLookup} from '../../../../common/util/util-helpers'
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import {CalculatorIcon} from '@heroicons/react/20/solid'
import Tippy from '@tippyjs/react'
import {SquaresPlusIcon} from '@heroicons/react/24/outline'
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import {fieldsToHtml, includeFields} from "../../../../common/util/util-fields";
import Card from "../../../../common/components/card";

export default class LoadInfoAdditional extends Component {
    render() {
        const {
            translate,
            fields,
            onHandleInputChange,
            onSetPriceMatrixClick,
            onCustomerSettingsDialogClick,
            IsInvoiced,
            matrixApplied,
            onRemoveSelectedMatrix
        } = this.props

        const selects = {
            BillTypeIDs: getLookup('BillType', 'BillTypeID', 'BillType'),
            PriceTypeID: {
                [LOAD_PRICING_FLAT]: 'Flat',
                [LOAD_PRICING_PER_MILE]: 'Per mile',
                [LOAD_PRICING_PER_WEIGHT]: 'Per weight',
                [LOAD_PRICING_PER_VOLUME]: 'Per volume',
                [LOAD_PRICING_PER_PIECE]: 'Per piece'
            }
        }

        fields.CalculationPrice.disabled = !fields.IsCalculationPriceOverride.value

        const dropDowns = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['PriceBase', 'PriceTypeID', 'Price', 'BillTypeID', 'CalculationPrice', 'PriceMilesCalculationTypeID', 'CustomTotalMiles']))), translate, onHandleInputChange, selects)
        const buttonGroups = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['IsCalculationPriceOverride', 'IsRateConfirmed', 'IsAccessorialHold']))), translate, onHandleInputChange, selects)

        return (
            <Card
                addClass="col-span-2 xl:col-span-1"
                addBodyClass="border-0 border-l-4 border-green-700 rounded-md"
                bodyClass="pt-3 pb-5 px-6 space-y-4 flex flex-col w-full grow justify-center"
            >
                <div
                    className={classNames(!matrixApplied ? 'mb-5' : undefined, 'flex justify-between items-center flex-wrap')}>
                    <div>
                        <h2 className="text-lg text-tm-gray-700 flex gap-2">
                            <ArrowTrendingUpIcon className="w-5 h-5 text-green-700"/>

                            {translate('title.billing')}
                        </h2>
                    </div>

                    {!IsInvoiced && (
                        <div className="flex space-x-3">
                            <Tippy
                                content={translate('btn.add_customer_defaults')}
                                trigger={'mouseenter'}
                                delay={500}
                            >
                                <button
                                    onClick={onCustomerSettingsDialogClick}
                                    className="p-2 hover:bg-tm-gray-200 rounded-full group"
                                >
                                    <SquaresPlusIcon className="w-5 h-5 text-tm-gray-400 group-hover:text-tm-gray-600"/>
                                </button>
                            </Tippy>

                            <Tippy
                                content={translate('text.set_pricing_matrix')}
                                trigger={'mouseenter'}
                                delay={500}
                            >
                                <button
                                    onClick={onSetPriceMatrixClick}
                                    className="p-2 hover:bg-tm-gray-200 rounded-full group"
                                >
                                    <CalculatorIcon className="w-5 h-5 text-tm-gray-400 group-hover:text-tm-gray-600"/>
                                </button>
                            </Tippy>
                        </div>
                    )}
                </div>

                {matrixApplied && (
                    <div className="rounded-lg border border-tm-gray-300 shadow-md">
                        <div
                            className="p-2 rounded-lg flex items-center justify-between flex-wrap border-l-[.2rem] border-primary sm:px-3 py-2">
                            <div className="w-0 flex-1 flex items-center">

                                <CalculatorIcon className="h-6 w-6 text-primary" aria-hidden="true"/>

                                <p className="ml-3 font-medium text-tm-gray-700 truncate">
                                    <span>{translate('text.matrix_applied', [matrixApplied])}</span>
                                </p>
                            </div>

                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                {!IsInvoiced && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={onRemoveSelectedMatrix}
                                    >
                                        {translate('btn.remove')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="grid grid-cols-12 gap-4">
                    {dropDowns}
                </div>

                <div className="divide-y divide-tm-gray-200">
                    {buttonGroups}
                </div>

                {Number(fields.IsAccessorialHold.value ?? 0) === 1 && (
                    <div
                        className="relative flex items-center pb-6 pt-2 border-b border-tm-gray-200 col-span-full">
                        <div className="min-w-0 flex-1 text-sm"><span
                            className="font-medium text-tm-gray-900 select-none">Accesorial on hold</span><p
                            className="text-tm-gray-700 pr-4">Full list of accessorials is not yet confirmed.</p></div>
                        <div className="flex items-center">
                            <div className="bg-tm-gray-100 rounded-lg border border-tm-gray-200">
                                <InformationCircleIcon className="w-10 h-10 text-red-600"/>
                            </div>
                        </div>
                    </div>
                )}
            </Card>
        )
    }
}
