import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import Lookups from './lookupTab'
import GeneralTab from './generalTab'
import DocumentEntitiesTab from './document-entities-tab'
import VacationsTab from './vacations-tab'
import CommandsTab from './commands-tab'
import EmailTemplatesTab from './email-templates-tab'
import MailingSettingsTab from './mailing-settings-tab'
import {READ_PERM, SETTINGS_LOOKUP_GROUPING} from '../../../common/util/util-consts'
import {checkPerm} from '../../../common/util/util-helpers'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import DocumentTypesTab from "../../../common/components/tabs/document-types-tab";
import NavResponsive from "../../../common/components/nav-responsive";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";

class SettingsView extends Component {
    constructor(props) {
        super(props)

        const tabs = [
            {name: 'CompanyGeneral', resource: Resources.CompanyGeneral, current: false, visible: true},
            {name: 'CustomFields', resource: Resources.Lookup, current: false, visible: true},
            {name: 'UserCommands', resource: Resources.UserCommands, current: false, visible: true},
            {name: 'DocumentTypes', resource: Resources.DocumentTypes, current: false, visible: true},
            {name: 'DocumentEntities', resource: Resources.DocumentEntities, current: false, visible: true},
            {name: 'CompanyVacation', resource: Resources.CompanyVacation, current: false, visible: true},
            {name: 'EmailTemplates', resource: Resources.EmailTemplates, current: false, visible: true},
            {name: 'EmailSettings', resource: Resources.CompanyMailingSettings, current: false, visible: true},
        ].filter((it) => checkPerm(it.resource, READ_PERM))
        tabs[0].current = true

        this.state = {
            isDirty: false,
            tabs: tabs,
            selectedTab: tabs[0].resource,
            confirmModal: false,
            breakpoint: {}
        }
    }

    componentDidMount() {
        if (this.props.location?.from?.prevPath === "/money-codes") {
            this.handleTabChange(Resources.IntegrationsMy)
        }
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: () => this.tabChange(resource)
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    render() {
        const {translate, resource, secondResource, dispatch} = this.props

        return (
            <Layout
                {...this.props}
                hasFooter={[Resources.UserCommands, Resources.CompanyGeneral, Resources.CompanyVacation].includes(this.state.selectedTab)}
                onBreakpoinChange={this.handleBreakpointChange}
            >
                <Page>
                    <div className="mb-2">
                        <h1 className="mr-5 text-3xl">{translate('text.settings')}</h1>
                    </div>

                    <NavResponsive
                        tabs={this.state.tabs}
                        onTabChange={this.handleTabChange}
                        translate={translate}
                    />

                    {this.state.selectedTab === Resources.Lookup && (
                        <Lookups
                            groupLookups={SETTINGS_LOOKUP_GROUPING}
                            {...this.props} />
                    )}

                    {this.state.selectedTab === Resources.DocumentTypes && (
                        <DocumentTypesTab
                            translate={this.props.translate}
                            breakpoint={this.state.breakpoint}
                        />
                    )}

                    {this.state.selectedTab === Resources.UserCommands && (
                        <CommandsTab
                            translate={translate}
                            resource={resource}
                            dispatch={dispatch}
                        />
                    )}
                    {this.state.selectedTab === Resources.CompanyGeneral && (
                        <GeneralTab
                            translate={translate}
                            resource={resource}
                            dispatch={this.props.dispatch}/>
                    )}
                    {this.state.selectedTab === Resources.CompanyVacation && (
                        <VacationsTab
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            dispatch={this.props.dispatch}/>
                    )}
                    {this.state.selectedTab === Resources.DocumentEntities && (
                        <DocumentEntitiesTab
                            {...this.props}
                            sortOrder={['Load', 'Customer', 'Carrier', 'Vendor', 'Truck', 'Trailer', 'Claim', 'Accident', 'Driver', 'Employee', 'Agent']}
                        />
                    )}

                    {this.state.selectedTab === Resources.EmailTemplates && (
                        <EmailTemplatesTab
                            tabResource={this.state.selectedTab}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.CompanyMailingSettings && (
                        <MailingSettingsTab {...this.props} />
                    )}
                </Page>

                <ModalConfirm
                    type="default"
                    onClose={() => this.setState({confirmModal: false})}
                    onConfirm={this.state.confirmModal}
                    buttonLabel="Proceed"
                    visible={!!this.state.confirmModal}
                    title={this.props.translate('modal_heading.confirm')}
                    translate={this.props.translate}
                >
                    <p>{translate('text.changes_on_tab')}</p>
                    <p>{translate('text.changes_will_be_lost')}</p>
                </ModalConfirm>
            </Layout>
        )
    }
}

export default connect(state => state)(SettingsView)

