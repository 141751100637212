import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {toFrontDateNoTime} from '../../../../common/util/util-dates'
import moment from 'moment'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    PAYMENT_METHOD_CHECK,
    PAYMENT_METHOD_FACTOR
} from '../../../../util/util-constants'
import {genericMoneyFormatter} from '../../../../common/util/util-vanilla'
import {getLookupWithFilter, getProp} from '../../../../common/util/util-helpers'
import {numberWithCommas} from "../../../../util/util-formaters";
import {PaymentAccountTypeExpense, PaymentAccountTypeIncome} from "../../../../common/util/util-accounting";
import ModalDefault from "../../../../common/components/modal/modal-default";
import InfoParagraph from "../../../../common/components/info-paragraph";
import {excludeFields, fieldsToHtml, includeFields} from "../../../../common/util/util-fields";
import FieldCellMoney from "../../../../common/components/fields/field-cell-money";

export default class BulkPaymentDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            invoices: this.getInvoices()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.setState({
                fields: this.getFields(),
                invoices: this.getInvoices()
            })
        }
    }

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.onSubmit(this.state.fields, this.state.invoices)
                this.setState({
                    fields: this.getFields(),
                    invoices: this.getInvoices()
                })
                this.props.onClose()
            }
        })
    }

    handleAccountType = (value) => {
        let QueryAccountTypeID = ACCOUNT_TYPE_CASH
        if (!(this.props.payment === 'send')) {//income
            QueryAccountTypeID = PaymentAccountTypeIncome(QueryAccountTypeID, value)
        } else {//expense
            QueryAccountTypeID = PaymentAccountTypeExpense(QueryAccountTypeID, value)
        }
        return QueryAccountTypeID
    }

    handleEnteredAmountChange = (name, value, index) => {
        const invoicesClone = [...this.state.invoices];
        let fields = Object.assign({}, this.state.fields);

        invoicesClone[index][name] = value;

        fields.Amount.value = invoicesClone.reduce((memo, it) => {
            memo = memo + Number(it.EnteredAmount);
            return memo;
        }, 0).toFixed(2);

        this.setState({
            fields: fields,
            invoices: invoicesClone
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields;
        let invoices = this.state.invoices;

        if (name === 'PaymentTypeID') {
            if (value !== PAYMENT_METHOD_CHECK) {
                fields.CheckNumber.type = 'hidden'
            }

            let QueryAccountTypeID = ACCOUNT_TYPE_CASH
            if (!(this.props.payment === 'send')) {//income
                QueryAccountTypeID = PaymentAccountTypeIncome(QueryAccountTypeID, value)
                if (value == PAYMENT_METHOD_CHECK) {
                    fields.AccountID.value = this.props.defaults.PreferredUndepositedAccountID ? {
                        label: this.props.defaults.PreferredUndepositedAccount,
                        value: this.props.defaults.PreferredUndepositedAccountID
                    } : ''
                    fields.CheckNumber.type = 'text'
                } else {
                    fields.AccountID.value = ''
                }
            } else {//expense
                QueryAccountTypeID = PaymentAccountTypeExpense(QueryAccountTypeID, value)
                fields.AccountID.value = ''
            }

            fields.FactorFee.type = ((Number(value) === 5) || (Number(value) === 6)) ? 'float' : 'hidden'
            fields.IsPercentageFactor.type = ((Number(value) === 5) || (Number(value) === 6)) ? 'button-group' : 'hidden'
            fields.FactorAccountID.type = ((Number(value) === 5) || (Number(value) === 6)) ? 'select-search' : 'hidden'
            fields.FactorFee.validate = ((Number(value) === 5) || (Number(value) === 6)) ? ['empty'] : ['']
            fields.FactorAccountID.validate = ((Number(value) === 5) || (Number(value) === 6)) ? ['empty'] : ['']
            fields.FactorFee.value = ((Number(value) === 5) || (Number(value) === 6)) ? this.props.defaults.FactorFee : ''
            fields.IsPercentageFactor.value = ((Number(value) === 5) || (Number(value) === 6)) ? this.props.defaults.IsPercentageFactor ?? 0 : ''
            fields.FactorAccountID.value = (((Number(value) === 5) || (Number(value) === 6)) && this.props.defaults.FactorExpenseAccountID) ? {
                value: this.props.defaults.FactorExpenseAccountID,
                label: this.props.defaults.FactorExpenseAccount
            } : ''
            
            this.setState({
                QueryAccountTypeID: QueryAccountTypeID
            })
        }

        if (name === 'Amount') {
            let amount = (+value)
            invoices = invoices.map(item => {
                if (!value || value === '0' || value >= item.balance || value <= item.balance) {
                    item.EnteredAmount = 0
                }

                if (amount >= item.balance) {
                    item.EnteredAmount = item.balance
                    amount -= item.balance
                } else if (amount > 0 && amount < item.balance) {
                    item.EnteredAmount = amount
                    amount = 0
                }
                return item
            });
        }

        this.setState({
            fields: FieldsManager.updateField(fields, name, value),
            invoices: invoices
        });
    }

    getFields = () => {
        let prefAccount = getProp(this.props, 'item.PreferredPaymentAccount', false) ?
            {
                label: this.props.item.PreferredPaymentAccount !== ' ' ? this.props.item.PreferredPaymentAccount : this.props.defaults.PreferredPaymentAccount,
                value: this.props.item.PreferredPaymentAccountID ?? this.props.defaults.PreferredPaymentAccountID
            }
            : ''
        let prefMethod = this.props.item?.PreferredPaymentMethodID ?? this.props?.defaults?.PreferredPaymentMethodID
        if (!(this.props.payment === 'send')) {//income
            prefAccount = this.props.defaults.PreferredReceiveAccountID ? {
                label: this.props.defaults.PreferredReceiveAccount,
                value: this.props.defaults.PreferredReceiveAccountID
            } : ''
            prefMethod = this.props?.defaults?.PreferredReceiveMethodID
        }
        let defaultAmount = 0;

        this.getInvoices().map((elem,) => {
            defaultAmount += (+elem.Amount - elem.AmountTransferred)
        })

        return {
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'col-span-6'}, {minDate: this.props.defaults.LockedDate}),
            Amount: new Field('Amount', numberWithCommas(defaultAmount), ['empty'], false, 'money', {addContainerClass: 'col-span-6'}),
            PaymentTypeID: new Field('PaymentTypeID', prefMethod, ['empty'], false, 'select', {addContainerClass: 'col-span-6'}),
            AccountID: new Field('AccountID', prefAccount
                , ['empty'], false, 'select-search', {
                    addContainerClass: 'col-span-6',
                    label: (this.props.payment === 'send' ? 'payment_account' : 'deposit_to')
                }),
            CheckNumber: new Field('CheckNumber', '', [''], false, 'hidden', {addContainerClass: 'col-span-6'}),
            Description: new Field('Description', '', [], false, 'textarea'),
            FactorFee: new Field('FactorFee', '', [], false, 'hidden', {addContainerClass: 'col-span-6'}),
            FactorAccountID: new Field('FactorAccountID', '', [], false, 'hidden', {addContainerClass: 'col-span-6'}),
            IsPercentageFactor: new Field('IsPercentageFactor', 0, [], false, 'hidden', {
                data: {
                    0: 'Flat',
                    1: 'Percentage'
                }
            })
        }
    }

    getInvoices = () => {
        const compareFunction = (a, b) => a.RefNumber?.localeCompare(b.RefNumber, 'en', {numeric: true})
        return Object.keys(this.props.selectedItems).map(it => {
            return {
                [this.getItemKeyLabel()]: this.props.selectedItems[it][this.getItemKeyLabel()],
                ...this.props.selectedItems[it]
            }
        }).sort(compareFunction).map(it => {
            it.balance = it.Amount - it.AmountTransferred;
            it.EnteredAmount = it.balance; // Initial Amount assigned
            return it;
        })
    }

    getItemKeyLabel = () => {
        return this.props.itemKeyLabel ?? ''
    }

    render() {
        const {translate} = this.props

        let totalAmount = 0

        const selects = {
            PaymentTypeID: (this.props.payment === 'send') ? getLookupWithFilter('PaymentType', 'PaymentTypeID', 'PaymentType', (it) => {
                return it.PaymentTypeID !== PAYMENT_METHOD_FACTOR
            }) : null, // Null will take automatically all values
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    IncludeUndepositedAccount: this.props.itemKeyLabel === 'InvoiceID' ? 1 : 0,
                    AccountTypeID: this.state.QueryAccountTypeID ?? this.handleAccountType(this.props?.item?.PreferredPaymentMethodID ?? this.props?.defaults?.PreferredPaymentMethodID)
                },
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
            FactorAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        }
        const invoices = this.state.invoices.map((elem, index) => {
            totalAmount += (+elem.Amount - elem.AmountTransferred)

            return (
                <div key={elem.InvoiceID} className="table-row">
                    <div className="table-cell lead-5 py-3 pl-3 h-11">{elem.AutoCounter}</div>
                    <div className="table-cell lead-5 py-3 pl-3 h-11">{elem.RefNumber}</div>
                    <div className="table-cell lead-5 py-3 pl-3 h-11">{elem.Organization ?? elem.Contact}</div>
                    <div className="table-cell lead-5 text-center py-3 pl-3 h-11">{toFrontDateNoTime(elem.Date)}</div>
                    <div className="table-cell lead-5 py-3 pl-3 h-11">{toFrontDateNoTime(elem.DueDate)}</div>
                    <div
                        className="table-cell lead-5 py-3 pl-3 h-11">{genericMoneyFormatter(elem.Amount)}</div>
                    <div
                        className="table-cell lead-5 py-3 pl-3 h-11">{genericMoneyFormatter(elem.Amount - elem.AmountTransferred)}</div>
                    <div className="table-cell p-1 align-middle relative pr-6 h-11">
                        <FieldCellMoney
                            value={elem?.EnteredAmount}
                            onChange={(name, innerValue) => this.handleEnteredAmountChange("EnteredAmount", innerValue, index)}
                        />
                    </div>
                </div>
            )
        })

        let isSameVendorCustomer = true;

        let currVendor = this.state.invoices[0]?.Organization ?? this.state.invoices[0]?.Contact;
        this.state.invoices.forEach((it) => {
            const iterateVendor = it.Organization ?? it.Contact
            if (currVendor !== iterateVendor) {
                isSameVendorCustomer = false;
            }
            currVendor = iterateVendor;
        })

        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-7xl'}
                title={
                    this.props.payment === "send" ? translate("text.SendMultiplePayment") : translate('text.ReceiveMultiplePayment')
                }
                onButtonClick={this.submit}
                buttonLabel={translate('btn.save')}
                buttonDisabled={!invoices.length}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={this.props.onClose}
            >
                <div className="space-y-4 p-5">
                    {!isSameVendorCustomer && (
                        <InfoParagraph type="warning">
                            {"You are creating a multiple payment for a different vendors/customers, please make sure this is correct. System will write payment as a single bank transaction."}
                        </InfoParagraph>
                    )}

                    {this.props.containsInvalidEntry && (
                        <InfoParagraph type="warning">
                            {this.props.payment === 'send' ?
                                "Selected entries contain an expense(s) which are not  eligible to receive payments" :
                                "Selected entries contain a receipt(s) which cannot be included for payments."}
                        </InfoParagraph>
                    )}

                    <div className="grid grid-cols-12 gap-x-8 gap-y-2">
                        <div className="col-span-full xl:col-span-6 grid grid-cols-6 md:grid-cols-12 gap-4">
                            {fieldsToHtml(Object.values(Object.assign({}, excludeFields(this.state.fields, ['Description']))), translate, this.handleInputChange, selects)}
                        </div>

                        <div className="col-span-full xl:col-span-6">
                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.fields, ['Description']))), translate, this.handleInputChange)}
                        </div>
                    </div>

                    <div className="table break-words mb-1 w-full">
                        <div
                            className="table-header-group text-tm-gray-900 font-semibold p-2 mt-4 bg-tm-gray-100">
                            <div className="table-row">
                                <div className="table-cell lead-5 py-2 pl-3">{translate('table.Number')}</div>
                                <div className="table-cell lead-5 py-2 pl-3">{translate('table.RefNumber')}</div>
                                <div
                                    className="table-cell lead-5 py-2 pl-3">{!(this.props.payment === 'send') ? translate('table.ChargeTo') : translate('table.PayTo')}</div>
                                <div
                                    className="table-cell lead-5 py-2 text-center px-1.5">{translate('table.Date')}</div>
                                <div className="table-cell lead-5 py-2 pl-3">{translate('table.DueDate')}</div>
                                <div className="table-cell lead-5 py-2 pl-3">{translate('table.Amount')}</div>
                                <div
                                    className="table-cell lead-5 py-2 pl-3">{translate('table.AmountTransferred')}</div>
                                <div className="table-cell lead-5 py-2 pl-3">{translate('table.AmountEntered')}</div>
                            </div>
                        </div>

                        <div className="table-row-group text-tm-gray-700">
                            {invoices}
                        </div>

                        <div className="table-row-group text-tm-gray-900">
                            <div className={"table-row"}>
                                <div
                                    className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3 font-bold">Totals
                                </div>
                                <div className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3"></div>
                                <div className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3"></div>
                                <div className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3"></div>
                                <div className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3"></div>
                                <div className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3"></div>
                                <div
                                    className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3 font-bold">{genericMoneyFormatter(totalAmount)}</div>
                                <div className="border-t-2 border-tm-gray-300 table-cell lead-5 py-2 pl-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDefault>
        )
    }
}

