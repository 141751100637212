import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import { doneGetContactResource, errorGetContactResource } from "../actions/contactResource";
import { pushNotification } from "../actions/ui";

export function* getContactResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetContactResource(result.data));

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }

    } else {
        yield put(errorGetContactResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchGetContactResource() {
    yield takeLatest('GET_CONTACT_RESOURCE', getContactResourceCall);
}
