import {logout} from "../data/actions/user";

const eventsMiddleware = store => next => action => {
    if (action.data === "invalid_token") {
        store.dispatch(logout());
        return store.getState();
    }

    const nextState = next(action);

    return nextState;
};

export default eventsMiddleware
