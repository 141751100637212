import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_CRUD_STATE, DEFAULT_QUERY_LIMIT, READ_PERM} from '../../../util/util-constants'
import {checkPerm, getDefaultTableOptions} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import FileViewer from "../../../common/components/file-viewer/components"

import moment from "moment";
import {
    ArrowPathIcon,
    ArrowSmallDownIcon,
    ArrowSmallLeftIcon,
    ArrowSmallRightIcon,
    ArrowSmallUpIcon
} from "@heroicons/react/24/outline";
import Env from "../../../util/env";
import {download} from "../../../data/actions/download";
import Tooltip from "../../../common/components/tooltip";
import ModalDefault from "../../../common/components/modal/modal-default";
import {fieldsToHtml} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";

export default class Accounting1096ReportsView extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
            },
            columns: {}
        }

        this.pagePath = "reports-1096"

        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getTableQueryFilterFields(),
            mainQueryFilterFields: this.getMainQueryFilterFields(),
            offsetFields: this.getOffsetFields(),
            previewKey: 1,
            previewParams: {},

            selectedRows: {},

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate)
        }

        this.intervalRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */

    /** Data Events
     ================================================================= */

    onDownloadDocument = () => {
        this.props.dispatch(download({
            resource: Resources.Accounting1096Reports,
            query: {
                ...this.state.previewParams,
                name: "1096 Report.pdf",
                token: getJWT().access_token,
                Year: FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields).Year
            }
        }))
    }

    /** UI Events
     ================================================================= */
    handleOffsetInputChange = (name) => {
        let value = (+this.state.offsetFields[name].value) + 1
        let fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, name, value)
        if (name === 'OffsetDown') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetUp', -value)
        }
        if (name === 'OffsetUp') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetDown', -value)
        }
        if (name === 'OffsetRight') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetLeft', -value)
        }
        if (name === 'OffsetLeft') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetRight', -value)
        }

        fieldsUpdate[name].errorMessage = ''

        this.setState({
            offsetFields: fieldsUpdate,
            isOffsetStateDirty: true
        })
    }

    handleOffsetButtonHoldStart = (name) => {
        if (this.intervalRef.current) return
        this.intervalRef.current = setInterval(() => {
            this.handleOffsetInputChange(name)
        }, 50)
    }

    handleOffsetButtonHoldStop = () => {
        if (this.intervalRef.current) {
            clearInterval(this.intervalRef.current)
            this.intervalRef.current = null
        }
    }

    handleOffsetSubmitClick = () => {
        let areOffsetsValid = true
        let fields = Object.values(this.state.offsetFields).map(it => {
            if (isNaN(it.value)) {
                it.errorMessage = ['error.value_isNaN']
                areOffsetsValid = false
            }
            return it
        })

        fields = fields.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        if (!areOffsetsValid) {
            this.setState({
                offsetFields: fields,
                isOffsetStateDirty: false
            })
        } else {
            this.submitOffset()
        }
    }

    submitOffset = () => {
        const {OffsetDown, OffsetRight} = FieldsManager.getFieldKeyValues(this.state.offsetFields)
        const params = Object.assign(
            this.state.previewParams,
            {
                OffsetX: OffsetRight,
                OffsetY: OffsetDown,
            }
        )

        this.setState({
            previewParams: params,
            previewKey: this.state.previewKey + 1,
            isOffsetStateDirty: false
        })
    }

    handleTogglePdfPreview = () => {
        this.setState({isBulkPdfPreviewDialogOpen: !this.state.isBulkPdfPreviewDialogOpen})
    }

    handleMainQueryInputChange = (name, value) => {
        this.setState({
            mainQueryFilterFields:
                FieldsManager.updateField(this.state.mainQueryFilterFields, name, value)
        }, () => {
            this.saveFilters()
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.Accounting1096Reports
    }

    getPrimaryKey = () => {
        return 'ReportID'
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            ...FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields),
        }
    }

    getFields = () => {
        return {
            ContactID: new Field('ContactID', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Contacts, READ_PERM) ? this.handleQuickContactView(item) : null}>
                        <div>
                            {item.Type === 'Contact' ? item.Pay : ''}
                        </div>
                    </CellButton>
            }),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Organizations, READ_PERM) ? this.handleToggleOrganizationInfoDialog(item) : null}>
                        <div>
                            {item.Type === 'Organization' ? item.Pay : ''}
                        </div>
                    </CellButton>
            }),
            Send1099: new Field('Send1099', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
            }),
            Amount: new Field('Amount', '', [''], false, 'float', {}),
        }
    }

    getTableQueryFilterFields = () => {
        return {
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {
                addContainerClass: "min-w-[231px] max-w-full"
            }, {isClearable: true, hasPortal: true}),
            ContactID: new Field('ContactID', '', [], false, 'select-search', {
                addContainerClass: "min-w-[231px] max-w-full"
            }, {isClearable: true, hasPortal: true}),
            Send1099: new Field('Send1099', '', [''], false, 'checkbox', {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    getMainQueryFilterFields = () => {
        const numOfYears = 10

        const years = [...Array(numOfYears).keys()].reduce((memo, it) => {
            const year = moment().startOf('year').add(-it, 'year').format("YYYY")
            let yearLabel = year
            if (it === 0) {
                yearLabel = 'Current Year'
            } else if (it === 1) {
                yearLabel = 'Previous Year'
            }
            memo[year] = yearLabel
            return memo
        }, {})

        return {
            Year: new Field('Year', moment().startOf('year').format("YYYY"), [''], false, 'select', {
                addContainerClass: 'w-96 ml-2',
            }, {
                values: years,
                reverseKeySort: true,
                hasPortal: true
            })
        }
    }

    getOffsetFields = () => {
        return {
            OffsetDown: new Field('OffsetDown', 0, [''], false, 'integer'),
            OffsetUp: new Field('OffsetUp', 0, [''], false, 'integer'),
            OffsetLeft: new Field('OffsetLeft', 0, [''], false, 'integer'),
            OffsetRight: new Field('OffsetRight', 0, [''], false, 'integer'),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const mainQueryField = fieldsToHtml(Object.values(Object.assign({}, this.state.mainQueryFilterFields)), translate, this.handleMainQueryInputChange, {})

        const {
            OffsetDown,
            OffsetUp,
            OffsetLeft,
            OffsetRight
        } = FieldsManager.getFieldKeyValues(this.state.offsetFields)

        return (
            <React.Fragment>
                <div className={'flex justify-between items-center'}>
                    {mainQueryField}

                    <div>
                        <button
                            data-name="submit-button-shortcut"
                            className={`btn btn-primary w-fit`}
                            onClick={this.handleTogglePdfPreview}
                        >
                            Preview Report
                        </button>
                    </div>
                </div>

                <ModalDefault
                    show={this.state.isBulkPdfPreviewDialogOpen}
                    widthClass={'max-w-7xl'}
                    title={"Document preview"}
                    limitHeight={true}

                    buttonLabel={translate('btn.download')}
                    onButtonClick={this.onDownloadDocument}
                    closeButtonLabel={translate('btn.Close')}

                    onClose={this.handleTogglePdfPreview}
                >
                    {this.state.isPDFOffsetFormVisible && (
                        <div
                            className="bg-tm-gray-50 border-b border-tm-gray-300 px-6 pt-1.5 pb-3 flex gap-x-4">
                            <div>
                                <div className="grid grid-cols-5 gap-1">
                                    {/* ROW 1 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>
                                        {+OffsetUp}
                                    </div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>

                                    {/* ROW 2 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900 focus:outline-none"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetUp')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallUpIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>

                                    {/* ROW 3 */}
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetLeft}</div>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetLeft')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallLeftIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onClick={() => this.setState({offsetFields: this.getOffsetFields()})}
                                        >
                                            <ArrowPathIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetRight')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallRightIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetRight}</div>

                                    {/* ROW 4 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetDown')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallDownIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>

                                    {/* ROW 5 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetDown}</div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                </div>
                            </div>

                            <div className="space-x-4 mt-5 ml-auto">
                                {!(this.state.isConfirmCheckPrintVisible && !this.state.isOffsetStateDirty) && (
                                    <Tooltip
                                        content="Offset is unchanged"
                                    >
                                        <div>
                                            <button
                                                className="btn btn-outline h-10"
                                                onClick={() => {
                                                    this.handleOffsetSubmitClick()
                                                }}
                                            >
                                                {translate('btn.preview_offset_changes')}
                                            </button>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    )}

                    <FileViewer
                        key={this.state.previewKey}
                        onPDFLoad={() => {
                            this.setState({
                                isPDFOffsetFormVisible: true
                            })
                        }}
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.Accounting1096Reports, Object.assign({}, this.state.previewParams, {
                            Year: FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields).Year,
                            token: getJWT().access_token
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                </ModalDefault>
            </React.Fragment>
        )
    }
}
