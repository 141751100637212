import React from 'react'
import Resources from '../../../data/services/resources'
import {fieldsToHtml, includeFields} from "../../../common/util/util-fields";
import {FieldsManager} from "../../../data/services/fields";
import { DEFAULT_METADATA_SELECT_SEARCH_QUERY } from '../../../util/util-constants'
import Card from "../../../common/components/card";
import {LoaderLarge} from "../../../common/components/loader";

const InfoTab = ({fields_info, translate, handleInputChange, setStateInChild, onSelectFromUnitClick, isLoading, hideSelectUnit}) => {
    const {ClaimDate, DateClosed, ClaimOpened} = FieldsManager.getFieldKeyValues(fields_info)

    fields_info['ClaimOpened'].props.maxDate = (ClaimDate && !DateClosed) ? ClaimDate : (!ClaimDate && DateClosed) ? DateClosed : (ClaimDate > DateClosed) ? DateClosed : ClaimDate
    fields_info['ClaimDate'].props.minDate = ClaimOpened
    fields_info['DateClosed'].props.minDate = ClaimOpened

    const getFieldsToHtmlForIncludedFields = (arr = []) => {
        const selects = {
            InsuranceID: {
                api: 'api/' + Resources.Insurance,
                query: {},
                searchMap: (item) => ({
                    value: item.InsuranceID,
                    label: item.InsuranceName
                })
            },
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: {},
                searchMap: (item) => {
                    if (fields_info.DriverID.value == item.DriverID) {
                        setStateInChild('DriverID', item.FirstName + ' ' + item.LastName, item.DriverID)
                    }
                    return {
                        value: item.DriverID,
                        label: item.FirstName + ' ' + item.LastName
                    }
                }
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: {},
                searchMap: (item) => {
                    if (fields_info.TruckID.value == item.TruckID) {
                        setStateInChild('TruckID', item.SCAC + ' ' + item.TruckNumber, item.TruckID)
                    }
                    return {
                        label: item.SCAC + ' ' + item.TruckNumber,
                        value: item.TruckID,
                        metadata: item
                    }
                }
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: {},
                searchMap: (item) => {
                    if (fields_info.TrailerID.value == item.TrailerID) {
                        setStateInChild('TrailerID', item.SCAC + ' ' + item.TrailerNumber, item.TrailerID)
                    }
                    return {
                        label: item.SCAC + ' ' + item.TrailerNumber,
                        value: item.TrailerID,
                        metadata: item
                    }
                }
            },
            LoadNumber: {
                api: 'api/' + Resources.LoadsQuick,
                query: {},
                searchMap: (item) => {
                    if (fields_info.LoadNumber.value == item.LoadID) {
                        setStateInChild('LoadID', item.LoadID, item.LoadID, item)
                        setStateInChild('DriverID', item.Driver, item.DriverID)
                        setStateInChild('TruckID', item.Truck, item.TruckID)
                        setStateInChild('TrailerID', item.Trailer, item.TrailerID)
                        setStateInChild('CustomerID', item.Customer, item.CustomerID)
                    }
                    return {
                        label: item.LoadNumber,
                        value: item.LoadID,
                        metadata: item
                    }
                }
            },
            CustomerID: {
                api: 'api/' + Resources.CustomersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => {
                    if (fields_info.CustomerID.value == item.CustomerID) {
                        setStateInChild('CustomerID', item.LegalName, item.CustomerID)
                    }
                    return {
                        label: item.LegalName,
                        value: item.CustomerID,
                    }
                }
            },
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(fields_info, arr)))), translate, handleInputChange, selects)
    }

    const fieldsHtmlClaimInfo1 = getFieldsToHtmlForIncludedFields(['ClaimNumber', 'ClaimCode', 'ClaimOpened', 'ClaimDate','LoadNumber','CustomerID'])
    const fieldsHtmlClaimInfo2 = getFieldsToHtmlForIncludedFields(['DriverID', 'TruckID', 'TrailerID', 'InsuranceID','Lost', 'Damaged', 'ClaimAmount','ContactName', 'ClaimDescription'])

    const fieldsHtmlClaimDisposition = getFieldsToHtmlForIncludedFields(['ClaimClosed', 'DateClosed', 'Approved', 'Denied', 'SettledAmount', 'ChargeDriver', 'Amount', 'FinalNotes'])

    return (
        <div className="mx-auto max-w-3xl space-y-8 pb-20">
            <Card addClass={'h-full py-2 relative'}>
                <h5 className="mb-4 text-tm-gray-700 text-base">Claim Info</h5>
                <div className="grid grid-cols-2 gap-5">
                    {fieldsHtmlClaimInfo1}

                    {!hideSelectUnit && (
                        <button
                            onClick={onSelectFromUnitClick}
                            className="btn btn-outline-secondary h-10 mt-5"
                        >
                            {translate("btn.select_from_unit")}
                        </button>
                    )}

                    {fieldsHtmlClaimInfo2}
                </div>

                {isLoading && (
                    <LoaderLarge stripesBg />
                )}
            </Card>

            <Card addClass={'h-full py-2 relative'}>
                <h5 className="mb-4 text-tm-gray-700 text-base">Claim Disposition</h5>
                <div className="grid grid-cols-4 gap-5">
                    {fieldsHtmlClaimDisposition}
                </div>

                {isLoading && (
                    <LoaderLarge stripesBg />
                )}
            </Card>
        </div>
    )
}

export default InfoTab
