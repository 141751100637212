import React, { Component } from 'react'
import Resources from '../../../data/services/resources'
import { getResource, updateResource } from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import { Field, FieldsManager } from '../../../data/services/fields'
import { UPDATE_PERM } from '../../../common/util/util-consts'
import { checkPerm, getProp } from '../../../common/util/util-helpers'
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import FieldContainer from "../../../common/components/fields/field-container";
import FieldText from "../../../common/components/fields/field-text";
import {LoaderSmall} from "../../../common/components/loader";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../common/components/card";
import {fillFieldsFromData} from "../../../common/util/util-fields";
export default class MailingSettingsTab extends Component {
    constructor (props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            footerIsSticky: true,
            isPasswordVisible: false
        }
    }
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate (prevProps) {
        if (prevProps.resource !== this.props.resource && !this.props.resource.isLoading) {
            this.setState({ fields: this.getFields(getProp(this.props.resource, 'data', {})) })
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.CompanyMailingSettings
        }))
    }

    submit = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: FieldsManager.getFieldKeyValues(this.state.fields),
            resource: Resources.CompanyMailingSettings,
            piggyResource: Resources.CompanyMailingSettings,
            errorMessage: true, successMessage: 'Mailing settings updated successfully!'
        }))
    }

    cancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            })
        }
    }

    handleInputChange = (name, value) => {
        this.setState({ canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value) })
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            SMTPServer: new Field('SMTPServer', '', []),
            Port: new Field('Port', '', []),
            SSL: new Field('SSL', '', [], false, 'checkbox'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    render () {

        const { translate, resource } = this.props

        const isLoading = getProp(resource, 'isLoading', true)

        return (
            <React.Fragment>
                <Card>
                    <PageHeader
                        title={translate('text.MailingSettings')}
                    />
                    {isLoading && (
                        <div className="m-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!isLoading && (
                        <>
                            <div className="grid grid-cols-4 gap-x-8">
                                <div className="col-span-2">
                                    <FieldContainer
                                        item={this.state.fields.SMTPServer}
                                        translate={translate}
                                    >
                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SMTPServer}
                                            addClass={'form-control'}/>
                                    </FieldContainer>
                                </div>

                                <div className="col-span-1">
                                    <FieldContainer
                                        item={this.state.fields.Port}
                                        translate={translate}
                                    >
                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.Port}
                                            addClass={'form-control'}/>
                                    </FieldContainer>
                                </div>

                                <div className="col-span-1">
                                    <FieldContainer
                                        item={this.state.fields.SSL}
                                        translate={translate}
                                    >
                                        <FieldCheckbox
                                            onChange={this.handleInputChange}
                                            className="checkbox"
                                            id="SSL"
                                            {...this.state.fields.SSL}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </>
                    )}
                </Card>

                <PageFooter
                    canSubmit={this.state.canSubmit}
                    actionCancel={this.cancel}
                    actionSubmit={checkPerm(Resources.CompanyMailingSettings, UPDATE_PERM) && this.submit}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}
