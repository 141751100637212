import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import { numberWithCommas } from "../../../../../util/util-formaters";
import React from "react";

export default function EmptyMilesDisplay({EmptyMiles, translate}) {
    if (!EmptyMiles || EmptyMiles < 0) return null;

    return (
        <div className="flex items-center gap-2 leading-5">
            <InformationCircleIcon className="w-5 h-5 text-tm-gray-600"/>

            <div className="flex flex-col justify-start text-left leading-5">
                <span className="leading-4">
                    {translate("text.EmptyMiles")}
                </span>

                <span className="font-bold">
                    {numberWithCommas(EmptyMiles)}
                </span>
            </div>
        </div>
    )
}