import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource,} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    longTableColumn,
    returnOffsetAndPagination,
    saveTableColumns,
} from'../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import cronstrue from 'cronstrue'
import UpdateRecurringLoadsDialog from './update-dialog'
import {DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp, mergeDeep} from '../../../common/util/util-helpers'
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import {cloneDeep} from "../../../common/util/util-vanilla";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import Layout from "../../../common/components/layout";


class RecurringLoadsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LoadID',

            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            queryFilterFields: this.getQueryFilterFields(),

            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_delete')} ${item.LoadID}?`}, () => {
            this.handleShowConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {id: item.LoadRepeatID},
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: `${item.LoadNumber} deleted`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleShowConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateResource = (items) => {
        this.setState({createUpdateDialog: true, selectedItem: items})
    }

    handleCloseCreateUpdateDialog = () => {
        this.setState({createUpdateDialogHideAnimation: false, createUpdateDialog: false})
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    hasCron = (cron = "") => {
        return (cron.split(" ").length > 4)
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            limit: new Field('limit', 10, [''], false, 'select', {
                containerClass: 'col-md-9 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: "top"})
        }
    }

    getFields = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', ['empty']),
            CustomerID: new Field('CustomerID', '', ['empty'], false, 'select-search'),
            DriverID: new Field('DriverID', '', ['empty'], false, 'select-search'),
            TruckID: new Field('TruckID', '', ['empty'], false, 'select-search'),
            TrailerID: new Field('TrailerID', '', ['empty'], false, 'select-search'),
            SecondTrailerID: new Field('SecondTrailerID', '', ['empty'], false, 'select-search'),

            Pickup: new Field('Pickup', '', ['empty'], {}, {}, {omitSort: true}),
            PickupCityName: new Field('PickupCityName', '', ['empty'], {}, {}, {omitSort: true}),
            PickupPostalCode: new Field('PickupPostalCode', '', ['empty'], "", "", {omitSort: true}),
            PickupState: new Field('PickupState', '', ['empty'], {}, {}, {omitSort: true}),
            Destination: new Field('Destination', '', ['empty'], {}, {}, {omitSort: true}),
            DestinationCityName: new Field('DestinationCityName', '', ['empty'], {}, {}, {omitSort: true}),
            DestinationPostalCode: new Field('DestinationPostalCode', '', ['empty'], "", "", {omitSort: true}),
            DestinationState: new Field('DestinationState', '', ['empty'], {}, {}, {omitSort: true}),

            TotalMiles: new Field('TotalMiles', '', [''], false, 'float'),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, 'float'),
            TotalWeight: new Field('TotalWeight', '', [''], false, 'float'),// TODO Add unit suffix, will be return by API in LBS
            TotalVolume: new Field('TotalVolume', '', [''], false, 'float'), // TODO Add unit suffix, will be return by API in inches
            TotalPieces: new Field('TotalPieces', '', [''], false, 'integer'),
            IsHazmat: new Field('IsHazmat', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return (
                        item.IsHazmat ?
                            <div className="pl-4"><ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>
                            </div> : null
                    )
                }
            }),
            Cron: new Field('Cron', '', [''], false, 'custom', {
                render: (item) => {
                    return <div>{this.hasCron(item?.Cron) ? cronstrue.toString(item.Cron) : ''}</div>
                }
            }),
            Notes: new Field('Notes', '', [], false, 'text', {
                render: (item) => (
                    <>{longTableColumn(item.Notes)}</>
                )
            }),
            NextRunDate: new Field('NextRunDate', '', [], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.LoadRecurring
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.recurring_loads')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <div className="flex">
                            <div className="ml-auto flex">
                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>
                        </div>
                    </PageHeader>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleUpdateResource : null}
                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleUpdateResource : null}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                        />

                        {/*Table footer*/}
                        {!(!data.length && !resource.isLoading) && (
                            <TableCardFooter>
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        )}

                        <NoRecords
                            show={data.length === 0 && !resource.isLoading}
                            title={'No matching records found'}
                            addClass={'pt-16 pb-10'}
                        />
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm delete'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <ModalDefault
                        show={this.state.createUpdateDialog}
                        widthClass={'max-w-7xl'}
                        title={translate('text.update_recurring_load')}
                        closeButtonLabel={translate('btn.Cancel')}
                        onClose={this.handleCloseCreateUpdateDialog}
                        hideDialogFooter={true}
                    >
                    {this.state.createUpdateDialog &&
                        <UpdateRecurringLoadsDialog
                            items={this.state.selectedItem}
                            translate={translate}
                            ui={{HideModalAnimation: this.state.createUpdateDialogHideAnimation}}
                            onClose={this.handleCloseCreateUpdateDialog}
                            dispatch={this.props.dispatch}
                        />
                    }
                    </ModalDefault>

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(RecurringLoadsView)
