import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import DescriptionListLeftAligned
    from "../../../../common/components/display-data/description-list/description-list-left-aligned";
import {Field} from "../../../../data/services/fields";
import CarriersImportDialogNumber from "../carriers-import-dialog/carriers-import-dialog-number";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ResourceTable from "../../../../common/components/resource-table";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import {checkPerm} from "../../../../common/util/util-helpers";
import {CREATE_PERM} from "../../../../common/util/util-consts";
import moment from "moment";
import {getUserDateTimeFormat} from "../../../../common/util/util-dates";
import Badge from "../../../../common/components/badge";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {LoaderLarge} from "../../../../common/components/loader";

export default function FmcsaImportDialog({translate, onClose, show, onImportCarrierClick, MCNumber, USDOTNumber}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogResource);

    const data = resource.data ?? [];

    const mappedData = {
        LegalName: data?.dbaName,
        FederalID: data?.dotNumber,
        USDOTNumber: data?.ein,
        MCNumber: data?.dotNumber,
        AddressName: data?.phyStreet && (data?.phyStreet ? data?.phyStreet + ",\n" : undefined) + [data?.phyCity, data?.phyState, data?.phyZipcode, data?.phyCountry].join(", "),
        NumOfTrucks: data?.totalDrivers,
        NumOfTrailers: data?.totalPowerUnits,
        IsBrokerAuth: data?.brokerAuthorityStatus === "A",
        IsCommonAuth: data?.commonAuthorityStatus === "A",
        IsContractAuth: data?.contractAuthorityStatus === "A"
    }


    const tableData = [
        {
            InspectionType: 'Inspections',
            Driver: data?.driverInsp,
            Vehicle: data?.vehicleInsp,
            Hazmat: data?.hazmatInsp,
        },
        {
            InspectionType: 'Out of Service',
            Driver: data?.driverOosInsp,
            Vehicle: data?.vehicleOosInsp,
            Hazmat: data?.hazmatOosInsp,
        },
        {
            InspectionType: 'Out of Service %',
            Driver: Math.round(data?.driverOosRate * 100) / 100,
            Vehicle: Math.round(data?.vehicleOosRate * 100) / 100,
            Hazmat: Math.round(data?.hazmatOosRate * 100) / 100
        },
        {
            InspectionType: 'Nat Average',
            Driver: data?.driverOosRateNationalAverage,
            Vehicle: data?.vehicleOosRateNationalAverage,
            Hazmat: data?.hazmatOosRateNationalAverage
        }
    ];

    const crashTableData = [
        {
            Type: 'Crashes',
            Fatal: data?.fatalCrash,
            Injury: data?.injCrash,
            Tow: data?.towawayCrash,
            Total: data?.crashTotal,
        }
    ];

    /** Const
     ================================================================= */
    const isLoading = resource.isLoading;
    const message = resource?.data?.Message ?? null;
    const [hasCarrierData, setHasCarrierData] = useState(false);


    /** Data events
     ================================================================= */
    const fetchCarrier = (type, number) => {
        if (type === "MC") {
            type = "MCNumber"
        }

        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.FmcsaIntegrations,
            query: {[type]: number},
            errorMessage: true
        }))
    }

    function handleClose() {
        setHasCarrierData(false);
        onClose();
    }

    useEffect(() => {
        setHasCarrierData(!!Object.keys(data).length);
    }, [isLoading]);

    useEffect(() => {
        if (show) {
         setHasCarrierData(false);
        }
    }, [show]);

    useEffect(() => {
        if (MCNumber) {
            fetchCarrier('MCNumber', MCNumber);
        } else if (USDOTNumber) {
            fetchCarrier('USDOTNumber', USDOTNumber);
        }
    }, [MCNumber, USDOTNumber, show]);

    /** UI events
     ================================================================= */

    return (
        <ModalDefault
            show={show}
            widthClass={"max-w-3xl rounded-b-none"}
            limitHeight={true}
            title={translate("modal_heading.import_from_FMCSA")}
            closeButtonLabel={translate("btn.close")}
            onClose={handleClose}
            hideDialogFooter={true}
        >
            <div className="relative">
                {isLoading && (
                    <LoaderLarge/>
                )}

                {!!message && (
                    <div className="p-1">
                        <InfoBar type="danger">
                            {message}
                        </InfoBar>
                    </div>
                )}

                {!hasCarrierData && (
                    <CarriersImportDialogNumber
                        translate={translate}
                        MCNumberPlaceholder="123456"
                        fetchCarrier={fetchCarrier}
                        isLoading={isLoading}
                        resource={resource}
                    />
                )}

                {hasCarrierData && (
                    <>
                        <div className="p-6">
                            <div
                                className="bg-inverse rounded-card border-tm-gray-300 border shadow-card pt-6 overflow-hidden">
                                <DescriptionListLeftAligned
                                    isStriped={true}
                                    hasPadding={true}
                                    title="USDOT INFORMATION"
                                    // subtitle="Personal details and application."
                                    fields={{
                                        "LegalName": new Field('LegalName', "", [''], true, 'text'),
                                        "FederalID": new Field('FederalID', "", [''], true, 'text'),
                                        "USDOTNumber": new Field('USDOTNumber', "", [''], true, 'text'),
                                        "AddressName": new Field('AddressName', "", [''], true, 'text'),
                                        "NumOfTrucks": new Field('NumOfTrucks', "", [''], true, 'text'),
                                        "NumOfTrailers": new Field('NumOfTrailers', "", [''], true, 'text'),
                                        "AuthorizedFor": new Field('AuthorizedFor', "", [''], true, 'custom', {
                                            render: (data) => {
                                                return <div className="flex gap-4">
                                                    {["IsBrokerAuth", "IsCommonAuth", "IsContractAuth"].filter(it => data[it]).map(it => {
                                                        return <Badge key={it}
                                                                      type={"success"}>{translate("field." + it)}</Badge>
                                                    })}
                                                </div>
                                            }
                                        })
                                    }}
                                    data={mappedData}
                                    translate={translate}
                                />
                            </div>
                        </div>

                        <div className="px-6 text-center mb-2 font-bold">
                            <span className="text-sm">US Inspection results for 24 months prior to: <span
                                className="text-primary">
                                {moment().format(getUserDateTimeFormat()).split(" ")[0]}
                            </span></span>
                            <p className="text-tm-gray-600"><span className="font-bold">Note:</span> Total inspections
                                may be less than the sum of vehicle, driver, and hazmat inspections.</p>
                        </div>

                        <ResourceTable
                            fields={{
                                "InspectionType": new Field('InspectionType', "", [''], true, 'text'),
                                "Driver": new Field('Driver', "", [''], true, 'text'),
                                "Vehicle": new Field('Vehicle', "", [''], true, 'text'),
                                "Hazmat": new Field('Hazmat', "", [''], true, 'text')
                            }}
                            data={tableData}
                            translate={translate}
                        />

                        <div className="px-6 text-center mb-2 font-bold mt-6">
                            <span className="text-sm">Crashes reported to FMCSA by states for 24 months prior to <span
                                className="text-primary">
                                {moment().format(getUserDateTimeFormat()).split(" ")[0]}
                            </span></span>


                            <p className="text-tm-gray-600 max-w-md mx-auto"><span
                                className="font-bold">Note:</span> {`Crashes listed represent a motor carrier's involvement in reportable crashes, without any determination as to responsibility.`}
                            </p>
                        </div>

                        <ResourceTable
                            fields={{
                                "Type": new Field('Type', "", [''], true, 'text'),
                                "Fatal": new Field('Fatal', "", [''], true, 'text'),
                                "Injury": new Field('Injury', "", [''], true, 'text'),
                                "Tow": new Field('Tow', "", [''], true, 'text'),
                                "Total": new Field('Total', "", [''], true, 'text')
                            }}
                            data={crashTableData}
                            translate={translate}
                        />
                    </>
                )}

                <div className="fixed top-full w-full">
                    <ModalFooter
                        closeButtonLabel={translate('btn.close')}
                        onClose={handleClose}

                        buttonDisabled={!checkPerm(Resources.Carriers, CREATE_PERM)}
                        buttonLabel={hasCarrierData && onImportCarrierClick ? translate('btn.import_carrier') : undefined}
                        onButtonClick={() => {
                            setHasCarrierData(false);
                            onImportCarrierClick(data);
                            onClose();
                        }}
                    />
                </div>
            </div>
        </ModalDefault>
    )
}
