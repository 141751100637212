import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import {getJWT} from "../../../../util/util-auth";
import {timePickerValueToServerTime, timeZoneToUTC} from "../../../../util/util-dates";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {hideGlobalModal, hideResourceDialog, showModal, showResourceDialog} from "../../../../../data/actions/ui";
import Dropzone from "react-dropzone";
import {deleteDocument, download, uploadDocument} from "../../../../../data/actions/download";
import DialogDefault from "../../../modal/modal-default";
import FireIcon from "@heroicons/react/20/solid/FireIcon";
import Tippy from "@tippyjs/react";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import CalendarIcon from "@heroicons/react/20/solid/CalendarIcon";
import {getTaskResource, updateTaskResource} from "../../../../../data/actions/taskResource";
import {
    CREATE_PERM,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    READ_PERM,
    TASK_STATUS_IN_PROGRESS,
    TASK_STATUS_OPEN
} from '../../../../../util/util-constants'
import {checkPerm, classNames, getLookup, getProp} from "../../../../util/util-helpers";
import {currentDate, toCurrentDateTime, toFrontDateTime} from "../../../../util/util-dates";
import {
    createCommentResource,
    deleteCommentResource,
    updateCommentResource
} from "../../../../../data/actions/commentResource";
import ResourceTableTags from "../../../resource-table/table-components/resource-table-tags";
import FieldTextarea from "../../../fields/field-textarea";
import FieldText from "../../../fields/field-text";
import DocumentCard from "../../../card/document-card";
import Env from "../../../../../util/env";
import FileViewer from "../../../../components/file-viewer/components";
import AddContactsDialog from "../../contacts-select-modal";
import FieldTime from "../../../fields/field-time"
import ModalHeader from "../../modal-header";
import ContactBadge from "../../../contact/contact-badge";
import ContactCard from "../../contact-card-dialog/contact-card";
import Modal from "../../index";
import ModalConfirm from "../../modal-confirm";
import LinkedTasks from "../../../card/card-linked-tasks";
import {LoaderSmall} from "../../../loader";
import CommentCard from "../../../card/card-comment";
import TaskPostponeForm from "../../../task/task-postopne-form";
import PopoverWatchers from "../../../task/popover-watchers";
import TaskHistory from "../../../card/card-task-history";
import FieldDate from "../../../fields/field-date";
import FieldSelectSearch from "../../../fields/field-select-search";
import {fillFieldsFromData} from "../../../../util/util-fields";

class TasksInfoDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                TaskComment: new Field("TaskComment", '', ['empty'])
            },
            edit_fields: this.getFields(),
            editedComment: {
                id: null,
                value: null,
            },
            linkedTasks: this.props.id ? getProp(this.props.dialogResource, 'data.linkedTasks', []) : [],
            DocumentTypeID: null,
            documents: [],
            watchers: [],
            activeTab: "info",
            DescErrors: {},
            role: this.props.role ? this.props.role : null,
            previewDocumentModal: false,
            viewContactCard: false,
            selectedItem: false,
            confirmModal: false,
            isPostponeTaskModalVisible: false,
            confirmBtnLabel: "",
            comments: []
        };
        this.clickOutsideTextArea = createRef();

        this.STATUS_CLOSED = 4;
    }

    componentDidMount() {
        this.fetchData()
        document.addEventListener("mousedown", this.handleClickOutsideFilter)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.taskResource !== this.props.taskResource) {
            this.scrollToLatestComment();

            this.setState({
                linkedTasks: this.props.id ? getProp(this.props.taskResource, 'data.linkedTasks', []) : [],
                edit_fields: this.getFields(),
                documents: getProp(this.props.taskResource, "data.documents", []),
                comments: getProp(this.props.taskResource, "data.comments", []),
                watchers: getProp(this.props, "taskResource.data.watchers", []).filter(it => it.ContactID)
            })
        }

        if ((prevProps.download.data !== this.props.download.data) && !!this.props.download.data?.id) {
            this.fetchData();
        }
        if ((prevProps.download.deleteDocument !== this.props.download.deleteDocument) && !!this.props.download.deleteDocument) {
            this.fetchData();
        }

        if (prevProps.commentResource !== this.props.commentResource && this.props.commentResource?.data?.comments) {
            this.scrollToLatestComment();

            this.setState({
                comments: getProp(this.props.commentResource, "data.comments", []),
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutsideFilter);
    }

    fetchData = () => {
        this.props.dispatch(getTaskResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId(),
            },
            resource: Resources.TasksInfo
        }))
    };

    handleAssignWatchers = (contacts) => {
        this.setState({watchers: Object.values(contacts), canSubmit: true})
        this.handleCloseContactsModal('Watcher')
    }

    getFields = () => {
        const items = getProp(this.props.taskResource, "data.task", {});

        const fieldTemplates = {
            TaskID: new Field("TaskID", items.TaskID, ['empty']),
            TaskName: new Field("TaskName", items.TaskName, ['empty']),
            TaskPriorityID: new Field("TaskPriorityID", items.TaskPriorityID, ['empty']),
            TaskPriority: new Field("TaskPriority", items.TaskPriority, []),
            AssigneeID: new Field("AssigneeID", {value: items.AssigneeID, label: items.Assignee}, []),
            TaskStatusID: new Field("TaskStatusID", items.TaskStatusID, [], false),
            StartDate: new Field("StartDate", items.StartDate, [], false, "datetimezone"),
            DueDate: new Field("DueDate", items.DueDate, [], false, "datetimezone"),
            StartDateTime: new Field("StartDateTime", '', [], false, "timezone-custom"),
            DueDateTime: new Field("DueDateTime", '', [], false, "timezone-custom"),
            Description: new Field("Description", items.Description, []),
            TaskGroup: new Field("TaskGroup", items.TaskGroup, []),
            Marked: new Field("Marked", items.Marked, [], false, 'checkbox'),
        }

        return fillFieldsFromData(fieldTemplates, items)
    }

    handleClickOutsideFilter = (event) => {
        const {current: wrap} = this.clickOutsideTextArea
        if (wrap && !wrap.contains(event.target) && (this.state.editedComment.id != null)) {
            this.setState({editedComment: {id: null, value: null,}})
        }
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
            filesUploadDialog: true
        });
        this.props.dispatch(showResourceDialog())
    }

    hideModal = () => {
        this.props.onClose ? this.props.onClose() : this.props.dispatch(hideGlobalModal('TasksInfoDialog'));
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleMarkedClick = () => {
        const isMarked = !this.state.edit_fields.Marked.value;

        this.props.dispatch(updateTaskResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: [this.getId()],
                Marked: isMarked ? 1 : 0
            },
            resource: Resources.TasksMark,
            query: this.props.getQuery ? this.props.getQuery() : {id: this.getId()},
            piggyResource: Resources.TasksInfo,
            errorMessage: true,
            successMessage: isMarked ? this.props.translate('message.task_marked') : this.props.translate('message.task_unmarked')
        }))
    };

    handleEditInputChange = (name, value) => {
        this.setState({edit_fields: FieldsManager.updateField(this.state.edit_fields, name, value)});
    }

    handleDocInputChange = (name, value, i) => {
        let descriptions = this.state.Descriptions;
        descriptions[i] = value;
        this.setState({Descriptions: descriptions});
    };

    handleLinkClick = (e) => {
        if (e.includes("http")) {
            window.open(`${e}`, '_blank');
        } else {
            window.open(`${window.location.origin}${e}`, '_blank');
        }
    }

    handleHistoryContactClick = (contactID) => {
        if (!contactID) return null;

        this.props.dispatch(showModal('ViewContactCard', {ContactID: contactID}));
    }

    addCommentHandler = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createCommentResource({
                    user: LocalStorage.get('user'),
                    params: {
                        TaskID: this.getId(),
                        TaskComment: this.state.fields.TaskComment.value
                    },
                    query: {
                        id: this.getId()
                    },
                    resource: Resources.TasksComment,
                    piggyResource: Resources.TasksInfo,
                    errorMessage: true, successMessage: `You successfully added comment`,
                }));
                this.handleInputChange("TaskComment", "")
            }
        });
    }

    deleteCommentHandler = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmBtnLabel: this.props.translate("btn.delete_comment"),
            confirmText: this.props.translate("text.delete_comment_confirm")
        });
    }

    editComment = (id, value = null) => {
        this.setState({editedComment: {id: id, value: value}}, () => {
            if (value) {
                document.getElementById("textInput").focus();
                this.scrollToLatestComment();
            }
        })
    }

    setActiveTab = tab => {
        this.setState({activeTab: tab}, () => {
            if (this.state.activeTab === 'comments') {
                this.scrollToLatestComment();
            }
        })
    }

    editCommentHandler = () => {
        this.props.dispatch(updateCommentResource({
            user: LocalStorage.get('user'),
            params: {
                id: this.state.editedComment.id,
                TaskComment: this.state.editedComment.value,
            },
            query: {
                id: this.getId()
            },
            resource: Resources.TasksComment,
            piggyResource: Resources.TasksInfo,
            errorMessage: true, successMessage: `Comment updated`
        }));
        this.setState({editedComment: {id: null, value: null,}})
    }

    uploadDocument = () => {
        const errors = this.state.Descriptions.reduce((memo, it, i) => {
            if (!it) {
                memo[i] = true;
            }
            return memo;
        }, {});
        this.setState({
            DescErrors: errors
        });
        if (Object.keys(errors).length === 0) {
            this.props.dispatch(uploadDocument({
                user: LocalStorage.get("user"),
                files: this.state.files,
                id: this.getId(),
                query: {
                    id: this.getId(),
                },
                Descriptions: this.state.Descriptions,
                resource: Resources.TasksDocuments,
            }))
            this.setState({
                filesUploadDialog: false,
                Descriptions: [],
                files: []
            })
        }
    }
    togglePreviewDocument = (document = null) => {
        this.setState({
            previewDocumentModal: !this.state.previewDocumentModal,
            previewDocument: document
        });
    }

    handleClosePreview = () => {
        this.setState({
            previewDocumentModal: false
        })
    }

    togglePostponeModal = () => {
        this.setState({
            isPostponeTaskModalVisible: !this.state.isPostponeTaskModalVisible
        })
    }

    cancelUpload = () => {
        this.props.dispatch(hideResourceDialog())
        this.setState({
            Descriptions: [],
            files: [],
            DocumentTypeID: null,
            filesUploadDialog: false
        })
    }

    downloadDocument = (document) => {
        const fileExt = this.state.previewDocument ? this.state.previewDocument?.OriginalFilename?.split('.').pop() : document?.OriginalFilename?.split('.').pop()
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.TasksDocuments,
            query: Object.assign({
                DocumentID: this.state.previewDocument ? this.state.previewDocument.DocumentID : document.DocumentID,
                format: fileExt,
                name: 'document_' + currentDate() + '.' + fileExt
            })
        }))
        this.setState({previewDocument: false}, this.handleClosePreview)
    }

    showConfirmDialog = (submit, document) => {
        this.setState({
            confirmModal: submit,
            confirmBtnLabel: this.props.translate("btn.delete_document"),
            confirmText: this.props.translate("text.delete_document_confirm", [document.DocumentName])
        })
    }

    toggleContactCardDialog = (item = null) => {
        this.setState({
            viewContactCard: !this.state.viewContactCard,
            selectedItem: item
        })
    }

    handleToggleContactsModal = (type) => {
        this.setState({
            canSubmit: true,
            [`add${type}Modal`]: !this.state[`add${type}Modal`]
        })
    }

    handleCloseContactsModal = (type) => {
        this.setState({
            [`add${type}Modal`]: false
        })
    }

    handleBadgeCloseClick = (id, state) => {
        this.setState({
            [state]: this.state[state].filter((contact) => id !== contact.ContactID),
            canSubmit: true
        })
    }

    submit = () => {
        this.setState({edit_fields: FieldsManager.validateFields(this.state.edit_fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.edit_fields)) {
                this.props.dispatch(updateTaskResource({
                    user: LocalStorage.get("user"),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.edit_fields), {
                        Watchers: this.state.watchers.map(it => it.ContactID),
                        AssigneeID: this.state.edit_fields.AssigneeID.value.value,
                        id: this.getId()
                    }),
                    query: this.props.getQuery ? this.props.getQuery() : {id: this.getId()},
                    resource: Resources.MineTasks,
                    piggyResource: Resources.TasksInfo,
                    errorMessage: true, successMessage: "Task updated successfully!"
                }));
                this.hideModal()
            }
        });
    }

    scrollToLatestComment = () => {
        let objDiv = document.getElementById("commentSection");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    getId = () => this.props.id;

    render() {
        const {translate, taskResource, download, onClose} = this.props;

        const items = getProp(taskResource, "data.task", {});

        const user = LocalStorage.get("user");

        const canChangeStatus = checkPerm(Resources.Tasks, READ_PERM) || items.AssigneeID === user?.ContactID;

        const isTaskInProgress = Number(items.TaskStatusID) === TASK_STATUS_IN_PROGRESS

        const tags = getProp(taskResource, "data.task.ColorTag", "");

        let isUploadingFile = getProp(download, "isLoading", false);

        const comments = this.state.comments.map((item, i) => {
            item.ContactID = item.UpdatedByContactID;
            let isMe = item.ContactID == LocalStorage.get("user")?.Contact?.ContactID

            return (
                <CommentCard
                    key={i}
                    item={item}
                    isMe={isMe}
                    editComment={this.editComment}
                    confirmDeleteCommentHandler={() =>
                        this.deleteCommentHandler(() => {
                            this.props.dispatch(deleteCommentResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    id: item['TaskCommentID']
                                },
                                piggyQuery: {
                                    id: item.TaskID
                                },
                                resource: Resources.TasksComment,
                                piggyResource: Resources.TasksInfo,
                                errorMessage: true,
                                successMessage: this.props.translate('text.successfully_deleted_comment'),
                            }))

                            this.setState({confirmModal: false})
                        })
                    }
                    editCommentHandler={this.editCommentHandler}
                    translate={translate}
                    editedComment={this.state.editedComment}
                    onAvatarClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                />
            )
        })

        const docsTypes = getLookup('DocumentType', 'DocumentTypeID', 'DocumentType');

        const documents = this.state.documents.map((document, i) => {
            return (
                <div key={i}>
                    <DocumentCard
                        canNotDelete={Boolean(Number(getProp(this.props.taskResource, 'data.task.TaskStatusID', 0)) > 2)}
                        layoutSmall={true}
                        documentID={document.DocumentID}
                        description={document.Description}
                        fileName={document.DocumentName}
                        category={docsTypes[document.DocumentTypeID]}
                        date={toFrontDateTime(document.CreateUpdateDate)}
                        downloadDocument={() =>  this.downloadDocument(document)}
                        onPreviewDocument={() => this.togglePreviewDocument(document)}
                        onDeleteDocument={document.UpdatedByContactID === LocalStorage.get("user")?.ContactID ? () => this.showConfirmDialog(() => {
                                this.props.dispatch(deleteDocument({
                                    user: LocalStorage.get("user"),
                                    query: {
                                        id: this.getId(),
                                    },
                                    params: {
                                        DocumentID: document.DocumentID,
                                        id: this.getId(),
                                    },
                                    resource: Resources.TasksDocuments,
                                    piggydialogResource: Resources.TasksInfo
                                }));

                                this.setState({confirmModal: false});
                            }, document
                        ) : null}
                        translate={translate}
                    />
                </div>
            )
        })
        const TaskPriority = getLookup('TaskPriority', 'TaskPriorityID', 'TaskPriority');

        let TaskStatus = {
            1: "Open",
            2: "In progress",
            3: "Completed"
        }

        if (!checkPerm(Resources.Tasks, CREATE_PERM)) {
            delete TaskStatus[4];
        }

        let watchersContactsList = {}

        const watchersContacts = this.state.watchers.map((contact, i) => {
            if (!contact.ContactName) {
                contact.ContactName = contact.FirstName + " " + contact.LastName;
            }

            Object.assign(watchersContactsList, {
                [contact.ContactID]: contact
            });

            return (
                <ContactBadge
                    key={i}
                    className="block"
                    ContactEmail={contact.Email}
                    ContactName={contact.ContactName}
                    ContactID={contact.ContactID}
                    ImagePath={contact.ImagePath}
                    dispatch={this.props.dispatch}
                    onAvatarClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: contact.ContactID}))}
                    onRemoveBadge={() => this.handleBadgeCloseClick(contact.ContactID, 'watchers')}
                />
            )
        })

        return (
            <React.Fragment>
                <ModalHeader
                    className="border-tm-gray-200 border-b text-tm-gray-900"
                    title={this.state.edit_fields.TaskName.value}
                    onClose={() => onClose()}
                >
                    <div className={classNames("flex flex-grow w-full justify-end px-4 space-x-3 mr-4")}>

                        {!!items.ResourcePath && (
                            <Tippy content={translate("text.resource_link")}>
                                <button
                                    onClick={() => this.handleLinkClick(`${items?.ResourcePath}`)}
                                    className={classNames("btn btn-icon text-primary mr-auto")}
                                >
                                    <ArrowTopRightOnSquareIcon className="h-4 w-4"/>
                                </button>
                            </Tippy>
                        )}

                        {taskResource.isLoading && (
                            <LoaderSmall/>
                        )}

                        <Tippy content={<span>{translate("text.needsAttention")}</span>}>
                            <button
                                onClick={this.handleMarkedClick}
                                className={classNames("btn btn-icon relative z-10 m-0 relative overflow-hidden", !this.state.edit_fields.Marked?.value ? undefined : "text-red-500")}
                            >
                                <FireIcon className="h-4 w-4"/>

                                {!!this.state.edit_fields.Marked?.value && (
                                    <span
                                        className="inset-0 animate-ping absolute inline-flex rounded-full border-8 border-red-600 opacity-75 z-0"
                                    />
                                )}
                            </button>
                        </Tippy>

                        {isTaskInProgress && (
                            <div>
                                <Tippy content={translate("text.postpone_task")}>
                                    <button
                                        onClick={this.togglePostponeModal}
                                        className="btn btn-icon"
                                    >
                                        <CalendarIcon className={"h-4 w-4"}/>
                                    </button>
                                </Tippy>
                            </div>
                        )}

                        <div>
                            <PopoverWatchers
                                watchersContacts={watchersContacts}
                                translate={translate}
                                onToggleContactsModal={this.handleToggleContactsModal}
                                onRemoveAllClick={() =>
                                    this.setState({
                                        watchers: []
                                    })
                                }
                            />
                        </div>
                    </div>
                </ModalHeader>

                <div className="grid grid-cols-2 gap-4 h-dialog-body">
                    <div className="col-span-2 md:col-span-1 p-6">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-2 md:col-span-1">

                                {(items.TaskStatusID === TASK_STATUS_OPEN && this.state.role === "assignor") ? (
                                        <label className='form-group has-float-label mb-3'>
                                            Can this be displayed ???
                                            <FieldText
                                                addClass={"form-control"}
                                                onChange={this.handleEditInputChange}
                                                {...this.state.edit_fields.TaskName}
                                            />
                                            <span>{translate('field.TaskName')}</span>
                                        </label>
                                    ) :
                                    null
                                }
                            </div>
                            <div className="col-span-2 md:col-span-1">
                                {(items.TaskStatusID === TASK_STATUS_OPEN && this.state.role === "assignor") ? (
                                        <label className='form-group has-float-label mb-3'>
                                            <FieldSelectSearch
                                                all={!!this.state.edit_fields.TaskPriorityID.value}
                                                className="form-control"
                                                values={TaskPriority}
                                                onChange={this.handleEditInputChange}
                                                {...this.state.edit_fields.TaskPriorityID}
                                                placeholder={translate("text.filter_tasks_priority")}
                                                addClass={"form-control"}
                                            />
                                            <span>{translate('field.TaskPriorityID')}</span>
                                        </label>
                                    ) :
                                    null
                                }
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="col-span-2 md:col-span-1">
                                    <span className='form-group mb-3'>
                                        <p className="text-tm-gray-500 font-weight-bold mb-1">{translate("field.TaskStatusID")}</p>
                                        {(this.state.edit_fields.TaskStatusID.value < this.STATUS_CLOSED && canChangeStatus) ? (
                                                <FieldSelectSearch
                                                    className="form-control"
                                                    values={TaskStatus}
                                                    onChange={this.handleEditInputChange}
                                                    {...this.state.edit_fields.TaskStatusID}
                                                    omitSort={true}
                                                    placeholder={translate("text.filter_tasks_status")}
                                                    addClass={"form-control"}
                                                />)
                                            :
                                            <p className="text-tm-gray-700">{items.TaskStatus}</p>
                                        }
                                    </span>
                            </div>
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            {(items.TaskStatusID === TASK_STATUS_OPEN && this.state.role === "assignor") ? (
                                    <label className="form-group has-float-label mb-3">
                                        <FieldTextarea
                                            addClass={"form-control whitespace-pre-wrap"}
                                            onChange={this.handleEditInputChange}
                                            {...this.state.edit_fields.Description}
                                        />
                                        <span>{translate('field.Description')}</span>
                                    </label>
                                ) :
                                <div className="mb-6">
                                    <p className="text-tm-gray-500 font-bold mb-2">{translate("table.description")}</p>
                                    <p className="text-tm-gray-700">{this.state.edit_fields.Description.value}</p>
                                </div>
                            }
                        </div>

                        {(items.TaskStatusID === TASK_STATUS_OPEN && this.state.role === "assignor") ? (
                                <div className="grid grid-cols-2 gap-4 mt-3">
                                    <div className="col-span-2 md:col-span-1">
                                            <span className="text-tm-gray-500 mb-1 font-weight-bold block mb-3">
                                                <FieldDate
                                                    showTimeSelect={false}
                                                    addClass={"form-control text-center"}
                                                    onChange={this.handleEditInputChange}
                                                    {...this.state.edit_fields.StartDate}
                                                />
                                                <span>{translate('field.StartDate')}</span>
                                            </span>
                                    </div>
                                    <div className="col-span-2 md:col-span-1">
                                        <label className="text-tm-gray-500 mb-1 font-weight-bold block mb-3">
                                            <FieldTime
                                                onChange={this.handleEditInputChange}
                                                addClass={"form-control text-center "}
                                                {...this.state.edit_fields.StartDate}
                                            />
                                            <span>{translate('field.StartDateTime')}</span>
                                        </label>
                                    </div>
                                </div>
                            ) :
                            null
                        }

                        {(items.TaskStatusID === TASK_STATUS_OPEN && this.state.role === "assignor") ? (
                                <div className="grid grid-cols-2 gap-4 mt-3">
                                    <div className="col-span-2 md:col-span-1">
                                            <span className="text-tm-gray-500  font-weight-bold block mb-3">
                                                <FieldDate
                                                    showTimeSelect={false}
                                                    addClass={"form-control text-center"}
                                                    onChange={this.handleEditInputChange}
                                                    {...this.state.edit_fields.DueDate}
                                                />
                                                <span>{translate('field.DueDate')}</span>
                                            </span>
                                    </div>
                                    <div className="col-span-2 md:col-span-1">
                                        <label className="text-tm-gray-500 font-weight-bold block mb-3">
                                            <FieldTime
                                                onChange={this.handleEditInputChange}
                                                addClass={"form-control text-center "}
                                                {...this.state.edit_fields.DueDate}
                                            />
                                            <span>{translate('field.DueDateTime')}</span>
                                        </label>
                                    </div>
                                </div>
                            ) :
                            null
                        }

                        <h5 className="mb-2 mt-3 font-weight-bold text-tm-gray-500">{translate("tab.attachments")}</h5>

                        <div className="">
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    this.setState({
                                        files: acceptedFiles.map(file => Object.assign(file, {
                                            preview: URL.createObjectURL(file)
                                        })),
                                        Descriptions: acceptedFiles.map(() => ""),
                                        canSubmit: true,
                                    });
                                }}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDropAccepted={this.onDrop}
                                accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                multiple={true}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}
                                             className={"border-2 border-dashed border-tm-gray-300 p-6 flex items-center justify-center mb-6" + (this.state.dropzoneActive ? " active" : "")}>
                                            <input {...getInputProps()} />

                                            <p className="select-none text-tm-gray-500">{translate("field.drag_n_drop")}</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="">
                            {!!isUploadingFile && (
                                <LoaderSmall
                                    addClass={"m-10-auto"}
                                />
                            )}

                            {!isUploadingFile && (
                                <div className="space-y-2">
                                    {documents}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-span-2 md:col-span-1 border-l border-tm-gray-300">
                        <div className="-mb-px flex border-b border-tm-gray-300 px-6 bg-tm-gray-50" role="tablist">
                            {["info", "comments", "history"].map((it, i) => (
                                <button
                                    key={i}
                                    onClick={() => this.setActiveTab(it)}
                                    className={classNames("whitespace-nowrap py-2 px-4 font-semibold text-xs uppercase focus-visible:bg-tm-gray-200 rounded-tr-lg rounded-tl-lg", this.state.activeTab === it ? "text-primary border-primary border-b-2" : "text-tm-gray-700 hover:text-tm-gray-900")}
                                >
                                    {translate("tab." + it)}
                                </button>
                            ))
                            }
                        </div>

                        {this.state.activeTab === "info" && (
                            <div className="space-y-5 sm:divide-y sm:divide-tm-gray-200 py-5 px-6">
                                <dl className="">
                                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-xs font-medium text-tm-gray-700">Requester</dt>
                                        <dd className="mt-1 text-xs text-tm-gray-900 sm:mt-0 sm:col-span-2">{items.Requester}</dd>
                                    </div>

                                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-xs font-medium text-tm-gray-700">{translate("table.AssigneeID")}</dt>
                                        <dd className="mt-1 text-xs text-tm-gray-900 sm:mt-0 sm:col-span-2">{items.Assignee}</dd>
                                    </div>

                                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-xs font-medium text-tm-gray-700">{translate("table.start_date")}</dt>
                                        <dd className="mt-1 text-xs text-tm-gray-900 sm:mt-0 sm:col-span-2">{toCurrentDateTime(this.state.edit_fields.StartDate.value)}</dd>
                                    </div>

                                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-xs font-medium text-tm-gray-700">{translate("table.due_date")}</dt>
                                        <dd className="mt-1 text-xs text-tm-gray-900 sm:mt-0 sm:col-span-2">{toCurrentDateTime(this.state.edit_fields.DueDate.value)}</dd>
                                    </div>

                                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-xs font-medium text-tm-gray-700">{translate("table.priority")}</dt>
                                        <dd className="mt-1 text-xs text-tm-gray-900 sm:mt-0 sm:col-span-2">{this.state.edit_fields.TaskPriority.value}</dd>
                                    </div>
                                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-xs font-medium text-tm-gray-700">{translate("field.TaskGroupID")}</dt>
                                        <dd className="mt-1 text-xs text-tm-gray-900 sm:mt-0 sm:col-span-2">{this.state.edit_fields.TaskGroup.value}</dd>
                                    </div>
                                </dl>

                                <div>
                                    <h5 className="mb-2 mt-3 font-weight-bold text-tm-gray-500">{translate("card_header.linked_issues")}</h5>

                                    {!this.state.linkedTasks.length &&
                                    <p className="text-tm-gray-500">{translate("text.no_linked_tasks")}</p>}

                                    <LinkedTasks
                                        data={this.state.linkedTasks}
                                        disableCreate
                                        translate={translate}
                                        handleUnlinkIssue={this.handleUnlinkIssue}
                                        handleUpdateDataValues={this.handleUpdateLinkedTasksValues}

                                    />
                                </div>

                                <div>
                                    <h5 className="mb-2 mt-3 font-weight-bold text-tm-gray-500">{translate("text.Tags")}</h5>

                                    {taskResource.isLoading && (
                                        <div className="relative">
                                            <LoaderSmall />
                                        </div>
                                    )}

                                    {!!tags.length && !taskResource.isLoading && (
                                        <ResourceTableTags data={getProp(taskResource, "data.task.ColorTag", "")}/>
                                    )}

                                    {!tags.length && !taskResource.isLoading && (
                                        <p className="text-tm-gray-500">{translate("text.task_no_tags")}</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {this.state.activeTab === "comments" && (
                            <div className="px-6 mb-6 relative">
                                {this.props.commentResource.isLoading && (
                                    <div
                                        className="absolute inset-0 flex items-center justify-center opacity-75 bg-inverse z-10">
                                        <LoaderSmall className=""/>
                                    </div>
                                )}

                                <div className="tab-pane fade active show pt-5" id="second" role="tabpanel"
                                     aria-labelledby="second-tab">
                                    <div className="comment-section" id='commentSection'>
                                        {comments}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label className="form-group has-float-label mb-3">
                                            <FieldTextarea
                                                onChange={this.handleInputChange} {...this.state.fields.TaskComment}
                                                placeholder={""} addClass={"form-control whitespace-pre-wrap"}/>
                                            <span>{translate('field.Comment')} *</span>
                                        </label>
                                    </div>
                                </div>


                                <div className="text-right">
                                    <button className="btn btn-outline-secondary"
                                            disabled={!this.state.fields.TaskComment.value}
                                            onClick={() => {
                                                this.addCommentHandler()
                                            }}>{translate("btn.add_comment")}
                                    </button>
                                </div>
                            </div>
                        )}

                        {this.state.activeTab === "history" && (
                            <div className="p-6">
                                {!taskResource.isLoading && (
                                    <TaskHistory
                                        history={getProp(taskResource, 'data.changes', [])}
                                        onContactClick={this.handleHistoryContactClick}
                                        translate={translate}
                                    />
                                )}

                                {(getProp(taskResource, 'data.changes', []).length === 0) && !taskResource.isLoading && (
                                    <span>{translate("text.no_history")}</span>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className="h-16 flex justify-end  items-center border-tm-gray-300 border-t px-6 space-x-3">
                    <button className="btn btn-primary" onClick={() => {
                        this.submit()
                    }}>{translate("btn.update")}
                    </button>
                    <button
                        ref={this.props.innerRef}
                        className="btn btn-outline" onClick={() => {
                        this.hideModal()
                    }}>{translate("btn.close")}
                    </button>
                </div>

                <ModalConfirm
                    title={translate("modal_heading.confirm")}
                    show={!!this.state.confirmModal}
                    text={this.state.confirmText}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={this.state.confirmBtnLabel}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <DialogDefault
                    show={!!this.state.filesUploadDialog}
                    title={translate("modal_heading.upload_confirm")}
                    className={"modal-md"}
                    translate={translate}
                    onClose={() => this.cancelUpload()}
                    closeButtonLabel={translate('Close')}
                    buttonLabel={translate('Upload')}
                    onButtonClick={this.uploadDocument}
                >
                    {getProp(this.state, "files", []).map((it, i) => {
                        return (
                            <div key={i} className="p-6">
                                <label
                                    className="text-tm-gray-700">{translate("text.desc_for_file")}: {this.state?.files[i]?.name}</label>

                                <FieldText
                                    addClass="form-control"
                                    onChange={(name, value) => this.handleDocInputChange(name, value, i)}
                                    value={this.state.Descriptions[i]}
                                    placeholder=""
                                    errorMessage={!!this.state.DescErrors[i]}
                                />
                                <div className="col-md-3"/>
                            </div>
                        )
                    })}
                </DialogDefault>

                <DialogDefault
                    show={!!this.state.previewDocumentModal}
                    title={translate("preview") + " - " + this.state.previewDocument?.Description}
                    className={"modal-lg"}
                    widthClass={"max-w-7xl"}
                    limitHeight={true}
                    translate={translate}
                    onClose={() => this.togglePreviewDocument()}
                    closeButtonLabel={translate('Close')}
                    buttonLabel={translate('Download')}
                    onButtonClick={this.downloadDocument}
                >
                    <FileViewer
                        fileType={this.state.previewDocument?.OriginalFilename ? this.state.previewDocument?.OriginalFilename.split('.').pop() : ""}
                        filePath={Env.getApiUrl("api/" + Resources.TasksDocuments, {
                            DocumentID: this.state?.previewDocument?.DocumentID,
                            token: getJWT().access_token
                        })}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </DialogDefault>

                {/*Contact card dialog*/}
                <DialogDefault
                    show={!!this.state.viewContactCard}
                    title={translate("modal_heading.contact_details")}
                    widthClass={"max-w-7xl"}
                    closeButtonLabel={translate("btn.close")}
                    onClose={() => this.toggleContactCardDialog(null)}
                    secondaryButtonLabel={this.state.selectedItem?.DriverID ? translate("btn.go_to_driver_page") : null}
                    onSecondaryButtonClick={this.state.selectedItem?.DriverID ? this.props.history.push("/drivers/info/" + this.state.selectedItem.DriverID) : null}
                    translate={translate}
                >
                    <ContactCard
                        translate={translate}
                        ContactID={this.state.selectedItem?.ContactID || this.state.selectedItem?.UpdatedByContactID}
                        minHeight={"425px"}
                        {...this.props}
                    />
                </DialogDefault>

                <Modal
                    show={this.state.isPostponeTaskModalVisible}
                    widthClass="max-w-sm"
                    onClose={() => this.togglePostponeModal()}
                >
                    <TaskPostponeForm
                        StartDate={this.state.edit_fields.StartDate.value}
                        StartDateTime={this.state.edit_fields.StartDateTime.value}
                        DueDate={this.state.edit_fields.DueDate.value}
                        DueDateTime={this.state.edit_fields.DueDateTime.value}
                        onClose={() => this.togglePostponeModal()}
                        onSubmit={(params) => {
                            if (params) {
                                let StartDate = params.PostponeStartDate.split(' ')[0] + ' ' + timePickerValueToServerTime(params.PostponeStartDateTime);
                                StartDate = timeZoneToUTC(StartDate);

                                let DueDate = params.PostponeDueDate.split(' ')[0] + ' ' + timePickerValueToServerTime(params.PostponeDueDateTime);
                                DueDate = timeZoneToUTC(DueDate);

                                const Notes = params.Notes;
                                const id = this.getId();

                                this.props.dispatch(updateTaskResource({
                                    user: LocalStorage.get('user'),
                                    params: {StartDate, DueDate, Notes, id},
                                    resource: Resources.TaskPostpone,
                                    piggyResource: Resources.TasksInfo,
                                    query: {
                                        id: this.getId(),
                                    },
                                    errorMessage: true, successMessage: translate('text.task_dates_updated')
                                }))

                                this.togglePostponeModal()
                            }
                        }}

                        translate={translate}
                    />
                </Modal>

                {/*Add watcher for a task*/}
                <DialogDefault
                    show={!!this.state.addWatcherModal}
                    title={translate('text.add_watcher_for_task')}
                    widthClass={'max-w-4xl'}
                    limitHeight={true}
                    translate={translate}
                    hideDialogFooter={true}
                    onClose={() => this.handleCloseContactsModal('Watcher')}
                >
                    <AddContactsDialog
                        {...this.props}
                        onClose={() => this.handleCloseContactsModal('Watcher')}
                        onSubmitClick={this.handleAssignWatchers}
                        contactsList={watchersContactsList}
                        addQuery={{
                            TaskGroupID: getProp(this.props.taskResource, "data.task.TaskGroupID", 0)
                        }}
                    />
                </DialogDefault>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(TasksInfoDialog);
