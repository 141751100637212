import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    createResource,
    deleteResource,
    getResource,
    resetCreateUpdateResource,
    updateResource
} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    openInNewTab,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getSecondResource, resetSecondResource} from '../../../data/actions/secondResource'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, getLookup, getProp, mergeDeep} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import * as PropTypes from "prop-types";
import Layout from "../../../common/components/layout";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ResourceTable from "../../../common/components/resource-table";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ButtonIntegration from "../../../common/components/button/button-integration";


Page.propTypes = {children: PropTypes.node};

class FuelCardsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'CreateUpdateDate',
            sort: 'DESC',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedItem && prevProps.secondResource !== this.props.secondResource && !this.props.secondResource.isLoading) {
            this.handleToggleUpdateModal(this.state.selectedItem)
        }

        if (this.props.resource !== prevProps.resource && !this.props.resource.isLoading && this.props.resource?.create?.id) {
            this.props.dispatch(resetCreateUpdateResource())
            this.setState({
                createModalOpen: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.FuelCard}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {FuelCardID: item.FuelCardID},
                        piggyQuery: this.getQuery(),
                        resource: this.getResource(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.FuelCard]),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_restore_generic')} ${item.FuelCard}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            FuelCardID: item.FuelCardID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Fuel Card restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'fuel_cards_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'fuel_cards_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleCreateModal = (prefilled = '') => {
        this.setState({
            prefilled: prefilled,
            selectedItem: null,
            createModalOpen: !this.state.createModalOpen,
        })
    }

    handleToggleUpdateModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
        if (!item) this.props.dispatch(resetSecondResource())
    }

    handleEFSIntegration = (item) => {
        this.setState({selectedItem: item})
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.FuelCardInfo,
            query: {
                FuelCardID: item.FuelCardID
            }
        }))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleDriverClick = (item) => {
        openInNewTab(`/drivers/info/${item.DriverID}`)
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Assigned: new Field('Assigned', '', [''], false, 'select', {}, {
                values: {
                    2: 'Show only Assigned',
                    3: 'Show only Unassigned'
                },
                isClearable: true
            }),
            DriverID: new Field('DriverID', '', [], false, 'select-search', {}, {isClearable: true}),
            archived: new Field('archived', '', [''], false, 'checkbox', {}, {isClearable: true}),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getFields = (item = null) => {
        let integrationData = getProp(this.props.secondResource, 'data.EFS.header', false)

        // BASIC FIELDS
        let fieldTemplates = {
            FuelCard: new Field('FuelCard', '', ['empty'], item?.FuelCardID ? true : false, 'text', {addContainerClass: 'col-span-full'}),
            DriverID: new Field('DriverID', '', [], false, 'select-search', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    if (!item.DriverID) return null;
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                checkPerm(Resources.DriverInfo, READ_PERM) && this.handleDriverClick(item)
                            }}
                            className="btn btn-text hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                        >
                            {item.Driver}
                        </button>
                    )
                }
            }),
            TrackingMethodID: new Field('TrackingMethodID', '', ['empty'], item?.FuelCardID ? true : false, 'select', {
                addContainerClass: 'col-span-full'
            }, {all: true})
        }

        // ADDITIONAL FIELDS FOR EFS INTEGRATION
        /*
        if (integrationData) {
            fieldTemplates.Status = new Field('Status', integrationData.status, [], false, `select`, {
                hideTable: true,
                addContainerClass: 'col-span-full'
            })
            Object.keys(integrationData)
                .filter(elem => elem != 'status')
                .forEach((elem) => {
                    let isDate = moment(integrationData[elem]).isValid()
                    fieldTemplates[elem] = new Field(elem, integrationData[elem], [], true, `readonly${isDate ? '-date' : ''}`, {
                        hideTable: true
                    })
                })
        }
        */

        return fillFieldsFromData(fieldTemplates, item)
    }

    getResource = () => {
        return Resources.FuelCard
    }

    getSelects = () => {
        return {
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            Status: {
                ACTIVE: 'ACTIVE',
                INACTIVE: 'INACTIVE',
                HOLD: 'HOLD',
                DELETE: 'DELETE',
            },
            TrackingMethodID: this.getFuelCardIntegrations()
        }
    }

    getFuelCardIntegrations = () => {
        const integrationMethods = getLookup('TrackingMethod')
        const availableMethods = ['1003', '1008']

        return Object.fromEntries(Object.entries(integrationMethods).filter(method => availableMethods.includes(method[0])))
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const dataIntegrations = getProp(resource, 'data.integrations', {})
        const hasIntegrations = dataIntegrations && Object.keys(dataIntegrations).some(it => !!dataIntegrations[it].hasIntegration)

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.FuelCardsView')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <ButtonIntegration
                            id="integration-btn"
                            hasIntegration={hasIntegrations}
                            history={this.props.history}
                            hasPerm={checkPerm(Resources.FuelCard, CREATE_PERM)}
                            onClick={() => this.handleToggleCreateModal()}
                            integrationName={"EFS | Relay"}
                        >
                            {translate('btn.create_new')}
                        </ButtonIntegration>
                    </PageHeader>

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            selects={this.getSelects()}
                            isLoading={resource.isLoading || secondResource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading || secondResource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleEFSIntegration : null}

                            onEdit={this.handleEFSIntegration}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >

                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new fuel card'}
                            btnLabel="Create a fuel card"
                            onBtnClick={() => {
                                this.handleToggleCreateModal(this.state.queryFilterFields.query.value)
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalSaveResource
                        title={'Edit Fuel Card'}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        show={this.state.editModalOpen}
                        errorMessage={this.state.errorMessage}
                        onClose={this.handleToggleUpdateModal}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params.FuelCardID = this.state.selectedItem.FuelCardID
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Fuel Card updated.'
                                }))
                                this.handleToggleUpdateModal(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={this.getSelects()}
                    />

                    <ModalSaveResource
                        title={translate('text.create_fuel_card')}
                        show={this.state.createModalOpen}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        onClose={this.handleToggleCreateModal}
                        errorMessage={this.props.resource.errorMessage}
                        fields={this.getFields({FuelCard: this.state.prefilled})}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Fuel Card created.'
                                }))
                                this.handleToggleCreateModal()
                            }
                        }}
                        translate={this.props.translate}
                        metadata={this.getSelects()}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(FuelCardsView)
