import React, {Component} from "react";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getLookup} from "../../util/util-helpers";
import RatingIcon from "./rating-icon";
import PageHeader from "../layout/layout-components/page/page-header";
import ResourceTable from "../resource-table";
import NoRecordsTable from "../no-records-found/no-records-table";
import {LoaderSmall} from "../loader";
import ModalSaveResource from "../modal/modal-save-resource";
import Resources from "../../../data/services/resources";
import {CREATE_PERM} from "../../../util/util-constants";
import Card from "../card";
import {fillFieldsFromData} from "../../util/util-fields";

export default class DriverReviewsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            createModalOpen: false,
            hoverRating: 0,
            rating: 0,
        }
    }

    /** UI Events
     ================================================================= */
    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleEnterRatingHandler = (index) => {
        this.setState({ hoverRating: index })
    }

    handleLeaveRatingHandler = () => {
        this.setState({ hoverRating: 0 })
    }

    handleSaveRatingHandler = (index) => {
        this.setState({ rating: index })
    }

    /** Field definitions
     ================================================================= */
    getFields = (item = null) => {
        let fieldTemplates = {
            Rating: new Field('Rating', '', [], false, 'custom', {
                hideDialog: true,
            }),
            ReviewTypeID: new Field('ReviewTypeID', '', ['empty'], false, 'select', { addContainerClass: 'col-span-full' }),
            ReviewNote: new Field('ReviewNote', '', ['empty'], false, 'textarea', { addContainerClass: 'col-span-full' }),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
            return (
                <RatingIcon
                    index={index}
                    rating={this.state.rating}
                    hoverRating={this.state.hoverRating}
                    onMouseEnter={this.handleEnterRatingHandler}
                    onMouseLeave={this.handleLeaveRatingHandler}
                    onSaveRating={this.handleSaveRatingHandler}
                />
            )
        })

        const ReviewType = getLookup('ReviewType')

        const dataItems = this.props.fields_reviews.map((fields, index) => {
            return {
                ...FieldsManager.getFieldKeyValues(fields),
                ReviewType: ReviewType[FieldsManager.getFieldKeyValues(fields)['ReviewTypeID']],
                index: index
            }
        })

        return (
            <React.Fragment>
                <Card>
                    <PageHeader
                        title={translate("page.heading.Reviews")}
                        titleClass="mr-5 text-2xl"
                        buttonLabel={translate("btn.create_new")}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        buttonHidden={!checkPerm(Resources.DriverReviews, CREATE_PERM)}
                    />
                    <React.Fragment>
                        <ResourceTable
                            addClass="rounded-card"
                            data={dataItems}

                            fields={this.getFields()}
                            translate={this.props.translate}

                            onDelete={(item) => this.props.removeNewReview(item.index)}
                        />

                        <NoRecordsTable
                            show={(dataItems.length === 0) && !resource.isLoading}
                            canCreate={false}
                            title={translate("text.no_matching_records")}
                        />
                        {resource.isLoading && (
                            <div className="w-full text-center">
                                <LoaderSmall/>
                            </div>
                        )}

                        <ModalSaveResource
                            show={this.state.createModalOpen}
                            title={'Create review'}
                            widthClass="max-w-md"
                            gridColsClass="grid-cols-3"
                            onClose={this.handleToggleCreateModal}
                            fields={this.getFields()}
                            onSubmit={(params) => {
                                if (params) {
                                    params.Rating = this.state.rating
                                    this.props.addNewReview(this.getFields(params))
                                    this.handleToggleCreateModal()
                                    this.setState({
                                        hoverRating: 0,
                                        rating: 0,
                                    })
                                }
                            }}
                            translate={this.props.translate}
                            htmlBefore={
                                <>
                                    <div className={'px-5 mt-3'}>
                                        <label className="">
                                            <span className="flex items-center text-sm font-medium h-5 whitespace-nowrap mb-1">{translate('field.ReviewRating')}</span>
                                            <div className="flex form-control items-center">
                                                {ratings}
                                            </div>
                                        </label>
                                    </div>
                                </>
                            }
                        />
                    </React.Fragment>
                </Card>
            </React.Fragment>
        )
    }
}
