import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const defaultOptions = {
    plugins: {
        legend: {},
        title: {},
    },
};

export function CharVerticalBar({
                                    data,
                                    rawData,
                                    options,
                                    title,
                                    maintainAspectRatio = false,
                                    responsive = true,
                                    legendPosition = "left",
                                    hideLegend = false
                                }) {

    let chartData = rawData;
    if (!rawData) {
        chartData = {
            labels: Object.values(data[0]?.data).map(it => {
                return it.label;
            }),
            datasets: data.reduce((memo, set) => {
                memo.push({
                    label: set.label,
                    data: Object.values(set.data).map(it => {
                        return it.value;
                    }),
                    borderColor: set.borderColor,
                    backgroundColor: set.backgroundColor,
                    borderWidth: set.borderWidth ?? 1
                });
                return memo;
            }, [])
        }
    }

    let chartOptions = options;
    if (!options) {
        chartOptions = defaultOptions;
    }

    if (!options?.plugins) {
        chartOptions.plugins = {};
    }

    chartOptions.plugins.title = {
        text: title,
        display: !!title
    };

    chartOptions.plugins.legend = {
        position: legendPosition,
        display: !hideLegend
    };

    chartOptions.responsive = responsive;
    chartOptions.maintainAspectRatio = maintainAspectRatio;

    return <Bar options={chartOptions} data={chartData} />;
}
