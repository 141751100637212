import React from 'react';
import Layout from "../../../common/components/layout";
import {useDispatch, useSelector} from "react-redux";
import LoadsResourceTable from "./loads-resource-table";
import {
    createResourceDiff,
    deleteResourceDiff,
    getResourceDiff,
    updateResourceDiff
} from "../../../data/actions/resourceDiff";
import {
    createSecondResourceDiff,
    deleteSecondResourceDiff,
    getSecondResourceDiff,
    updateSecondResourceDiff
} from "../../../data/actions/secondResourceDiff";

export default function LoadsViewSplit({translate, match, history, location}) {

    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resourceDiff);
    const data = resource?.data?.list ?? [];
    const dataDiff = resource?.diffData ?? []
    const isLoading = resource.isLoading;
    const isLoadingSilent = resource.isLoadingSilent;
    const dataCount = resource?.data?.count ?? 0;

    const secondResource = useSelector((state) => state.secondResourceDiff);
    const secondData = secondResource?.data?.list ?? [];
    const secondDataDiff = secondResource?.diffData ?? []
    const secondIsLoading = secondResource.isLoading;
    const isSecondLoadingSilent = secondResource.isLoadingSilent;
    const secondDataCount = secondResource?.data?.count ?? 0;

    const tableDefaults = {
        behaviour: {
            rowSelect: true,
            hasMenu: true,
            canAdjustWidth: true,
        },
        columns: {
            LoadNumber: {
                minWidth: 150,
            },
            IsBroker: {
                show: false,
            },
            LoadStatus: {minWidth: 150},
            LoadTypeID: {minWidth: 80},
            LoadSubTypeID: {minWidth: 130},
            Customer: {minWidth: 180},
            CustomerReferenceNumber: {minWidth: 175},
            Truck: {minWidth: 150},
            Trailer: {minWidth: 150},
            Labels: {minWidth: 150, show: false},
            TrailerType: {minWidth: 130, show: false},
            SecondTrailer: {minWidth: 150, show: false},
            LabelsCustomer: {minWidth: 215}
        },
        style: {
            condensed: true,
            isGPUAccelerated: true,
            columnFilters: false
        }
    }

    return (<Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={true}
        >
            <div className="flex flex-col h-full">
                <LoadsResourceTable
                    history={history}
                    onGetDiffResource={getResourceDiff}
                    onCreateResource={createResourceDiff}
                    onUpdateResource={updateResourceDiff}
                    onDeleteResource={deleteResourceDiff}
                    translate={translate}
                    isLoading={isLoading}
                    isLoadingSilent={isLoadingSilent}
                    data={data}
                    count={dataCount}
                    diffData={dataDiff}
                    defaultPagePath={location.pathname.substring(1) + "_primary"}
                    tableDefaults={tableDefaults}
                />

                <LoadsResourceTable
                    history={history}
                    filtersClass="sm:flex mt-4"
                    onGetDiffResource={getSecondResourceDiff}
                    onCreateResource={createSecondResourceDiff}
                    onUpdateResource={updateSecondResourceDiff}
                    onDeleteResource={deleteSecondResourceDiff}
                    translate={translate}
                    isLoading={secondIsLoading}
                    isLoadingSilent={isSecondLoadingSilent}
                    data={secondData}
                    count={secondDataCount}
                    diffData={secondDataDiff}
                    defaultPagePath={location.pathname.substring(1) + "_secondary"}
                    tableDefaults={tableDefaults}
                />
            </div>
        </Layout>
    )
}