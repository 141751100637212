import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import roadblock from '../../../assets/images/roadblock.svg'
import Card from "../../../common/components/card";

export default function NotFound({user = {}, translate, backLink, backBtnLabel, accessibleMessageResource}) {
    const resource = useSelector(state => state.resource);
    let errorCode = "";
    switch (accessibleMessageResource?.errorStatus ?? resource.errorStatus) {
        case 2:
            errorCode = 403;
            break;
        default:
            errorCode = 404;
    }

    return (
        <div className="h-full flex items-center justify-center">
            {errorCode === 403 && (
                <div className="flex flex-col justify-center items-center">
                    <div className="text-center pr-4 text-base">
                        <img className="w-64 h-64 inline-block" src={roadblock} alt="" />
                        <h1 className="mt-12 mb-3 text-primary text-3xl uppercase">Access denied</h1>
                        <p>You do not have sufficient permissions to view this page.</p>
                        <p>Please contact your system administrator.</p>
                    </div>
                </div>
            )}

            {errorCode === 404 && (
                <Card
                    className="bg-inverse rounded-lg border-tm-gray-200 shadow-card z-10 overflow-hidden mx-6"
                    bodyClass="lg:grid grid-cols-10 max-w-4xl"
                >
                    <div className="image-auth relative py-10 sm:py-20 px-10 col-span-4">
                        {/*<p className=" text-white h2">MAGIC IS IN THE DETAILS</p>*/}

                        {/*<p className="white mb-0">*/}
                        {/*    Yes, it is indeed!*/}
                        {/*</p>*/}

                        {!!user.register && (
                            <React.Fragment>
                                <p>
                                    <div>{translate('text.register_success')}</div>
                                    <Link to={`/login`}>{translate('btn.back_to_login')}</Link>
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="p-10 lg:p-10 col-span-6">
                        <Link to={`/`}>
                            <span className="logo-single"></span>
                        </Link>

                        <h6
                            className="mb-8 text-tm-gray-700 text-base text-base">{translate('page.404.heading_main')}</h6>

                        <p className="mb-2 text-tm-gray-700 text-sm">
                            <span>{translate('page.404.code')}</span>
                        </p>

                        <p className="text-8xl font-bold text-primary-shade mb-7">{errorCode}</p>

                        <div className="flex">
                            <Link
                                className="btn btn-primary text-sm hover:text-white"
                                to={backLink ?? `/home`}
                            >
                                {
                                    backBtnLabel ?? translate('btn.back_to_home')
                                }
                            </Link>
                        </div>

                    </div>
                </Card>
            )}
        </div>
    )
}