import {getDialogResource, updateDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LoaderLarge} from "../../../../common/components/loader";
import {checkPerm, getLocalstorageItemsForSave, resourceIsUpdated} from "../../../../common/util/util-helpers";
import {CREATE_PERM} from "../../../../common/util/util-consts";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {Field} from "../../../../data/services/fields";
import Button from "../../../../common/components/button";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import packageJson from '../../../../../package.json';
import ProfileRow from "./profile-row";

export default function UserSettingsProfiles({onClose, onReLogin, translate}) {
    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const dialogResource = useSelector((state) => state.dialogResource);
    const isLoading = dialogResource.isLoading;
    const profiles = dialogResource?.data?.list?.[getSystemProfileKey()];

    const hasProfiles  = !!Object.keys(profiles ?? {}).length;

    const numberOfProfiles = Object.keys(profiles ?? {})?.length ?? 0;

    const [isNewProfileFormOpen, setIsNewProfileFormOpen] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isConfirmApplyModalOpen, setIsConfirmApplyModalOpen] = useState(false);
    const [isConfirmRestoreModalOpen, setIsConfirmRestoreModalOpen] = useState(false);
    const [isConfirmDeleteRestoreModalOpen, setIsConfirmDeleteRestoreModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");

    const InitialStoreState = LocalStorage.get('initial_storage_state');

    const getFields = () => {
        return {
            ProfileName: new Field('ProfileName', "", ['empty'], false, 'text'),
            Comment: new Field('Comment', "", [''], false, 'textarea')
        }
    }

    function handleDeleteProfile() {
        if (!profiles[selectedItem]) {
            return null;
        }

        delete profiles[selectedItem];


        dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {
                ProfileMapKey: 'ProfileMapMain',
                ProfileMapValue: JSON.stringify(profiles)
            },
            resource: Resources.UserTableProfiles,
            piggyResource: Resources.UserTableProfiles,
            errorMessage: true, successMessage: `Table profile saved`
        }));
        setSelectedItem("");
    }

    function handleApplyProfile() {
        if (profiles?.[selectedItem]?.metadata) {
            delete profiles[selectedItem].metadata;
        }
        setIsConfirmApplyModalOpen(false);
        setSelectedItem("");

        Object.keys(profiles[selectedItem]).forEach(key => {
            LocalStorage.set(key, profiles[selectedItem][key]);
        })
        onReLogin();
        onClose();
    }

    function handleRestoreProfile() {
        Object.keys(InitialStoreState).forEach(key => {
            LocalStorage.set(key, InitialStoreState[key]);
        });

        onReLogin();
        onClose();
    }

    function fetchData() {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.UserTableProfiles,
        }));
    }

    function handleDeleteProfileClick(key) {
        setIsConfirmDeleteModalOpen(true);
        setSelectedItem(key);
    }

    function handleRestoreProfileClick() {
        setIsConfirmRestoreModalOpen(true);
    }

    function handleDeleteRestoreClick() {
        setIsConfirmDeleteRestoreModalOpen(true);
    }

    function handleDeleteRestore() {
        LocalStorage.remove('initial_storage_state')
    }

    function handleApplyProfileClick(key) {
        setIsConfirmApplyModalOpen(true);
        setSelectedItem(key);
    }

    function getSystemProfileKey() {
        switch (packageJson?.name ?? "") {
            case 'truck-wo-frontend':
                return 'ProfileMapWO'
            case 'truck-rental-frontend':
                return 'ProfileMapRental'
            case 'truck-tracking-frontend':
                return 'ProfileMapTracking'
            default:
                return "ProfileMapMain"
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (resourceIsUpdated(dialogResource)) {
            setIsNewProfileFormOpen(false);
        }
    }, [dialogResource]);

    return <div className="p-6">
        {isLoading && (
            <LoaderLarge/>
        )}

        {!!InitialStoreState && !isLoading && (
            <div className="mb-6">
                <ProfileRow
                    profileKey="Initial state"
                    profileComment="Revert all changes back to the state at login."
                    onApplyClick={handleRestoreProfileClick}
                    onDeleteClick={handleDeleteRestoreClick}
                />
            </div>
        )}

        {hasProfiles && (
            <>
                <h2 className="mb-4 font-bold text-base">Manually created profiles</h2>

                <div className="space-y-4">
                    {Object.keys(profiles).map(key => {
                        return <ProfileRow
                            key={key}
                            profileKey={key}
                            profiles={profiles}
                            onApplyClick={handleApplyProfileClick}
                            onDeleteClick={handleDeleteProfileClick}
                        />
                    })}
                </div>

                {!!numberOfProfiles && numberOfProfiles < 3 && (
                    <div className="text-right mt-4">
                        <Button
                            appearance="primary"
                            onClick={() => setIsNewProfileFormOpen(true)}
                        >
                            {translate("btn.create_new")}
                        </Button>
                    </div>
                )}

                {numberOfProfiles > 2 && (
                    <div className="mt-4">
                        <InfoBar>
                            <div>
                                <p className="font-bold">Maximum number of profiles reached.</p>
                                <p className="text-tm-gray-700">You must delete the existing one if you wish to create a
                                    new one.</p>
                            </div>
                        </InfoBar>
                    </div>
                )}
            </>
        )}

        <NoRecords
            addClass={"py-8"}
            show={(profiles === null || Object.keys(profiles ?? {})?.length === 0) && !isLoading}
            canCreate={checkPerm(Resources.UserTableProfiles, CREATE_PERM)}
            title={translate('text.no_profiles_found')}
            btnLabel={translate('btn.create_new_profile')}
            onBtnClick={() => setIsNewProfileFormOpen(true)}
        />

        <ModalSaveResource
            show={isNewProfileFormOpen}
            widthClass="max-w-lg"
            gridColsClass="grid-cols-1"
            title={translate('modal_heading.create_new_profile')}
            onClose={() => setIsNewProfileFormOpen(false)}
            closeButtonLabel={translate('btn.close')}
            buttonDisabled={isLoading}
            initialFocusRef={inputRef}
            translate={translate}
            fields={getFields()}
            onSubmit={(params) => {
                if (params) {
                    let relevantStorageItems = getLocalstorageItemsForSave();

                    if (params.Comment) {
                        relevantStorageItems = Object.assign(relevantStorageItems, {metadata: {Comment: params.Comment}})
                    }

                    dispatch(updateDialogResource({
                        user: LocalStorage.get('user'),
                        params: {
                            ProfileMapKey: getSystemProfileKey(),
                            ProfileMapValue: JSON.stringify(Object.assign(
                                {},
                                profiles, // Existing profiles
                                {[params.ProfileName]: relevantStorageItems})
                            )
                        },
                        resource: Resources.UserTableProfiles,
                        piggyResource: Resources.UserTableProfiles,
                        errorMessage: true, successMessage: `Table profile saved`
                    }));
                }
            }}
            htmlAfter={
                isLoading && <LoaderLarge/>
            }
        />

        <ModalConfirm
            title={"Delete profile"}
            show={isConfirmDeleteModalOpen}
            onClose={() => {
                setIsConfirmDeleteModalOpen(false)
                setSelectedItem("");
            }}
            buttonLabel={'Delete'}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={() => {
                setIsConfirmDeleteModalOpen(false);
                handleDeleteProfile();
            }}
        >
            Are you sure you want to delete profile named `{selectedItem}`?
        </ModalConfirm>

        <ModalConfirm
            type="warning"
            title={"Apply profile"}
            show={isConfirmApplyModalOpen}
            onClose={() => {
                setIsConfirmApplyModalOpen(false)
                setSelectedItem("");
            }}
            buttonLabel={'Apply profile'}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={() => {
                setIsConfirmDeleteModalOpen(false);
                handleApplyProfile();
            }}
        >
            <p className="text-base text-tm-gray-900">Are you sure you want to apply profile named <span className="font-bold">`{selectedItem}`</span>?</p>
            <p className="text-base text-tm-gray-900">This will overwrite your current settings!</p>
            <p className="mt-4 text-sm text-tm-gray-700">Existing table settings that are not loaded in this profile will remain unaffected.
            If you wish to restore the exact state from the selected profile, consider clearing your local storage first.</p>
        </ModalConfirm>

        <ModalConfirm
            type="warning"
            title={"Restore state"}
            show={isConfirmRestoreModalOpen}
            onClose={() => {
                setIsConfirmRestoreModalOpen(false)
                setSelectedItem("");
            }}
            buttonLabel={'Apply profile'}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={() => {
                setIsConfirmRestoreModalOpen(false);
                handleRestoreProfile();
            }}
        >
            <p className="text-base text-tm-gray-900">Are you sure you want to revert all changes to the state at login?</p>
            <p className="text-base text-tm-gray-900">This will overwrite your current settings!</p>
            <p className="mt-4 text-sm text-tm-gray-700">Please note that table settings that differ from the default will remain unchanged.
            If you wish to restore the exact state from login, consider clearing your local storage first.</p>
        </ModalConfirm>


        <ModalConfirm
            title={"Delete stored table settings"}
            show={isConfirmDeleteRestoreModalOpen}
            onClose={() => {
                setIsConfirmDeleteRestoreModalOpen(false)
                setSelectedItem("");
            }}
            buttonLabel={'Delete'}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={() => {
                setIsConfirmDeleteRestoreModalOpen(false);
                handleDeleteRestore();
            }}
        >
            Are you sure you want to delete the settings state saved at login?
        </ModalConfirm>
    </div>
}