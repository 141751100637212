import React from 'react'
import { Field } from '../../../../data/services/fields'
import { fieldsToInfoHtml} from '../../../../common/util/util-helpers'
import { FIELD_MASK_PHONE } from '../../../../util/util-constants'
import { classNames, getLookup } from '../../../../common/util/util-helpers'
import { setSelectionRange } from '../../../../common/util/util-vanilla'
import ModalFooter from "../../../../common/components/modal/modal-footer";
import ModalHeader from "../../../../common/components/modal/modal-header";

const CommodityInfo = (props) => {
    const { translate, title, selectedItem, onClose, onEdit } = props

    const getCommodityFields = (item = {}) => {
        const colFull = 'col-span-12'
        const colspan6 = 'col-span-12 md:col-span-4 xl:col-span-6'
        const colspan3 = 'col-span-6 md:col-span-4 xl:col-span-3'

        const IsLTL = !item || !!item?.IsLTL

        const fieldTemplates = {
            ProductName: new Field('ProductName', '', [''], '', 'text', { addContainerClass: colFull }),
            ProductCode: new Field('ProductCode', '', [], '', 'text', { addContainerClass: colspan3 }),
            FreightClassID: new Field('FreightClassID', '', [IsLTL ? 'empty' : ''], '', IsLTL ? 'select' : 'hidden', { addContainerClass: colspan3 }),
            ProductNMFC: new Field('ProductNMFC', '', [IsLTL ? 'empty' : ''], '', IsLTL ? 'text' : 'hidden', { addContainerClass: colspan3 }),
            UnitTypeID: new Field('UnitTypeID', '', [], '', 'select', { addContainerClass: colspan3 }),

            CountPieces: new Field('CountPieces', '', ['integer_or_empty'], false, 'float_or_empty', { addContainerClass: colspan3 }),
            CountPallets: new Field('CountPallets', '', ['integer_or_empty'], false, 'float_or_empty', { addContainerClass: colspan3 }),
            MeasureUnitID: new Field('MeasureUnitID', '', [''], '', 'select', { addContainerClass: colspan3 }),
            Length: new Field('Length', 0, ['float_or_empty'], false, 'float_or_empty', { addContainerClass: colspan3 }),
            Width: new Field('Width', 0, ['float_or_empty'], false, 'float_or_empty', { addContainerClass: colspan3 }),
            Height: new Field('Height', 0, ['float_or_empty'], false, 'float_or_empty', { addContainerClass: colspan3 }),

            Weight: new Field('Weight', 0, ['float_or_empty'], false, 'float_or_empty', { addContainerClass: colspan3 }),

            CommodityReferenceNumber: new Field('CommodityReferenceNumber', '', [''], false, 'text', { addContainerClass: colspan3 }),
            Stackable: new Field('Stackable', '', [''], false, 'checkbox', { addContainerClass: colspan3 }),
            Hazmat: new Field('Hazmat', '', [''], false, 'checkbox', { addContainerClass: colspan3 }),

            CommodityDescription: new Field('CommodityDescription', '', [''], false, 'textarea', {
                addContainerClass: colFull,
                formLabelClass: 'hidden'
            }),

            PackingGroupID: new Field('PackingGroupID', '', [''], false, 'select', { addContainerClass: colspan6 }),
            HazmatClassID: new Field('HazmatClassID', '', [''], false, 'select', { addContainerClass: colspan6 }),
            HazmatCode: new Field('HazmatCode', '', [''], false, 'text', { addContainerClass: colspan6 }),
            CommonChemical: new Field('CommonChemical', '', [''], false, 'text', { addContainerClass: colspan6 }),
            EmergencyContact: new Field('EmergencyContact', '', [''], false, 'mask', { addContainerClass: colspan6 }, {
                mask: FIELD_MASK_PHONE,
                onFocus: setSelectionRange
            }),
            EmergencyContactName: new Field('EmergencyContactName', '', [''], false, 'text', { addContainerClass: colspan6 }, { mask: FIELD_MASK_PHONE }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const isHazmat = selectedItem?.Hazmat

    const selects = {
        UnitTypeID: getLookup('UnitType', 'UnitTypeID', 'UnitType'),
        MeasureUnitID: getLookup('MeasureUnit', 'MeasureUnitID', 'MeasureUnit'),
        FreightClassID: getLookup('FreightClass', 'FreightClassID', 'FreightClass'),
    }

    const ShippingItemsHtml = fieldsToInfoHtml((Object.values(Object.assign({}, excludeFields(getCommodityFields(selectedItem), ['IsLTL', 'CommodityDescription', 'PackingGroupID', 'HazmatClassID', 'HazmatCode', 'CommonChemical', 'EmergencyContact', 'EmergencyContactName'])))), translate, null, selects)
    const HazmatHtml = fieldsToInfoHtml((Object.values(Object.assign({}, includeFields(getCommodityFields(selectedItem), ['PackingGroupID', 'HazmatClassID', 'HazmatCode', 'CommonChemical', 'EmergencyContact', 'EmergencyContactName'])))), translate, null, selects)
    const DescHtml = fieldsToInfoHtml((Object.values(Object.assign({}, includeFields(getCommodityFields(selectedItem), ['CommodityDescription'])))), translate, null, selects)

    return (
        <React.Fragment>
            <ModalHeader
                title={title}
                onClose={() => onClose()}
            >
                <p className="text-lg text-current ml-3">( {selectedItem?.IsLTL ? 'LTL' : 'LT'} )</p>
            </ModalHeader>

            <div className="">
                <div className="px-6 pt-4 pb-6 border-b border-tm-gray-200">
                    <p className="mb-4 text-tm-gray-700 text-base">Shipment Items</p>

                    <div className="grid gap-x-4 gap-y-8 grid-cols-12">
                        {ShippingItemsHtml}
                    </div>
                </div>

                {!!isHazmat && (
                    <div className="px-6 pt-4 pb-6 bg-tm-gray-100 border-b border-tm-gray-200">
                        <p className="mb-4 text-tm-gray-700 text-base">Hazmat details</p>

                        <div className="grid gap-x-4 gap-y-8 grid-cols-12">
                            {HazmatHtml}
                        </div>
                    </div>
                )}

                <div className={classNames('px-6 pt-4 pb-6', isHazmat ? '' : 'bg-tm-gray-100')}>
                    <p className="mb-4 text-tm-gray-700 text-base">Description</p>

                    <div className="grid gap-x-4 gap-y-8 grid-cols-12">
                        {DescHtml}
                    </div>
                </div>
            </div>

            <ModalFooter
                buttonLabel={translate('btn.edit')}
                onButtonClick={onEdit}
                closeButtonLabel={translate('btn.close')}
                onClose={() => {
                    onClose()
                }}
            />
        </React.Fragment>
    )
}

export default CommodityInfo
