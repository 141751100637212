import escapeHtml from 'escape-html'
import { Text, Element } from 'slate'


const serialize = node => {
    if (Element.isElement(node) && node.type === 'mention') {
        return `@{{@${node.contact}|${node.id}}}`; // If this is updated please update REGEX_MENTION_IN_STRING constant also
    }

    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.italic) {
            string = `<em>${string}</em>`
        }
        if (node.underline) {
            string = `<u>${string}</u>`
        }
        if (node.strikethrough) {
            string = `<s>${string}</s>`
        }
        return string
    }

    const children = node.children.map(n => serialize(n)).join('')

    let align = "";
    if (node.align) {
        align = ` align="${node.align}"`;
    }

    switch (node.type) {
        case 'quote':
            return `<blockquote${align}>${children}</blockquote>`
        case 'paragraph':
            return `<p${align}>${children}</p>`
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`
        case 'heading-one':
            return `<h1${align}>${children}</h1>`
        case 'heading-two':
            return `<h2${align}>${children}</h2>`
        case 'bulleted-list':
            return `<ul${align}>${children}</ul>`
        case 'numbered-list':
            return `<ol${align}>${children}</ol>`
        case 'list-item':
            return `<li>${children}</li>`
        default:
            return children
    }
}

export default function slateValueToHTML( value ) {
    if (!value) return "";

    return value.reduce(( memo, it ) => {
        memo = memo + serialize(it);
        return memo
    }, "");
}