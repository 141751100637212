import React, {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {
    ClipboardDocumentIcon,
    ClipboardDocumentListIcon,
    MapPinIcon,
    PaperAirplaneIcon,
    PencilSquareIcon,
    TruckIcon,
    UserPlusIcon
} from '@heroicons/react/24/outline'
import Squares2X2Icon from "@heroicons/react/24/outline/Squares2X2Icon";
import {CREATE_PERM, LINE_OF_BUSINESS_PRIMARY_CARRIER, READ_PERM} from "../../../../util/util-consts";
import {checkPerm, getProp} from "../../../../util/util-helpers";
import LocalStorage from "../../../../util/localStorage";
import {showGlobalModal} from "../../../../../data/actions/ui";
import Badge from "../../../badge";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {TrailerIcon} from "../../../../../data/themes/icons";
import Resources from "../../../../../data/services/resources";

const commands = LocalStorage.get("quick_commands", [])

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function QuickMenu() {
    const history = useHistory();
    const dispatch = useDispatch();

    const menuItems = [
        {
            name: 'New Load',
            description: 'Create a new load entry.',
            onClick: () => history.push('/loads/create'),
            icon: PaperAirplaneIcon,
            shortcut: commands['NewLoad'],
            hasPerm: checkPerm(Resources.Load, CREATE_PERM)
        },
        {
            name: 'New Driver',
            isVisible: getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER),
            description: 'Create a new driver entry.',
            onClick: () => history.push('/driver/create'),
            icon: UserPlusIcon,
            shortcut: commands['NewDriver'],
            hasPerm: checkPerm(Resources.Drivers, CREATE_PERM)
        },
        {
            name: 'New Trailer',
            isVisible: getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER),
            description: 'Create a new trailer entry.',
            onClick: () => history.push('/trailer/create'),
            icon: TrailerIcon,
            shortcut: commands['NewTrailer'],
            hasPerm: checkPerm(Resources.Trailers, CREATE_PERM)
        },
        {
            name: 'New Truck',
            isVisible: getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER),
            description: 'Create a new truck entry.',
            onClick: () => history.push('/truck/create'),
            icon: TruckIcon,
            shortcut: commands['NewTruck'],
            hasPerm: checkPerm(Resources.Trucks, CREATE_PERM)
        },
        {
            name: 'New Location',
            description: 'Create a new location entry.',
            onClick: () => dispatch(showGlobalModal('locationFormDialog', null)),
            icon: MapPinIcon,
            shortcut: commands['NewLocation'],
            hasPerm: checkPerm(Resources.Locations, CREATE_PERM)
        },
        {
            name: 'Create Task',
            description: 'Create a new task',
            onClick: () => dispatch(showGlobalModal('createTaskDialog', {globalDialog: true})),
            icon: ClipboardDocumentIcon,
            shortcut: commands['CreateTask'],
            hasPerm: checkPerm(Resources.Tasks, CREATE_PERM)
        },
        {
            name: 'All Tasks',
            description: 'See all tasks',
            onClick: () => history.push('/all-tasks'),
            icon: ClipboardDocumentListIcon,
            shortcut: commands['MyTasks'],
            hasPerm: checkPerm(Resources.Tasks, READ_PERM)
        },
        {
            name: 'New note',
            description: 'Create a new personal note',
            onClick: () => dispatch(showGlobalModal('PersonalNoteDialog')),
            icon: PencilSquareIcon,
            shortcut: commands['NewNote'],
            hasPerm: checkPerm(Resources.NotesPersonal, READ_PERM)
        },
    ]

    return (
        <Popover className="relative">
            {({open}) => (
                <>
                    <Popover.Button
                        className={classNames(open ? 'text-tm-gray-900' : '', 'icon-btn')}
                    >
                        <Squares2X2Icon
                            className={classNames('h-6 w-6 group-hover:text-tm-gray-900')}
                            aria-hidden="true"
                        />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className="absolute z-10 left-1/2 transform -translate-x-3/4 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
                            <div
                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden border border-tm-gray-200">
                                <div
                                    className="relative grid gap-6 bg-inverse px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                    {menuItems.filter(it => it.isVisible !== false && it.hasPerm !== false).map((item) => (
                                        !!item.icon && (
                                            <span
                                                key={item.name}
                                                onClick={item.onClick}
                                                className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-tm-gray-50 transition ease-in-out duration-150"
                                            >
                                            <div
                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primary text-white sm:h-12 sm:w-12">
                                                <item.icon className="h-6 w-6" aria-hidden="true"/>
                                            </div>
                                            <div className="ml-4 w-full">
                                                <div className="text-base font-medium text-tm-gray-900 flex">
                                                    {item.name}

                                                    {!!item.shortcut && (
                                                        <div className="ml-auto text-tm-gray-700">
                                                            <Badge addClass="text-xs py-0">{item.shortcut}</Badge>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="mt-1 text-sm text-tm-gray-700">{item.description}</div>
                                            </div>
                                        </span>
                                        )))}
                                </div>
                                <div className="p-5 bg-popup sm:p-8 border-t border-tm-gray-200">
                                    <span
                                        className="-m-3 p-3 flow-root rounded-md transition ease-in-out duration-150"
                                    >
                                        <span className="flex items-center">
                                          <span
                                              className="text-base font-medium text-tm-gray-900">ACCUR8</span>
                                          <span
                                              className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-tm-gray-300 text-tm-gray-900">
                                            TMS
                                          </span>
                                        </span>
                                        {/*<span className="mt-1 block text-sm text-tm-gray-700">*/}
                                        {/*  Magic is in the details.*/}
                                        {/*</span>*/}
                                    </span>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}
