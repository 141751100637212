import React, { Component } from 'react'
import { connect } from 'react-redux'
import InfoTab from './infoTab'
import BillingTab from './billingTab'
import PaymentsTab from './paymentsTab'
import Resources from '../../../data/services/resources'
import { hideConfirmDialog, showConfirmDialog } from '../../../data/actions/ui'
import Page from "../../../common/components/layout/layout-components/page";
import {LoaderSmall} from "../../../common/components/loader";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Layout from "../../../common/components/layout";

class AccountView extends Component {
    constructor (props) {
        super(props)
        this.state = {
            activeTab: Resources.UserInfo,
            canSwapTabs: true,
            canSubmit: false
        }
    }

    setActiveTab = (resource) => {
        if (this.state.canSwapTabs) {
            this.setState({ activeTab: resource })
        } else {
            this.setState({
                reserveTab: resource,
                text: this.props.translate('text.all_fields_will_be_lost')
            }, () => {
                this.props.dispatch(showConfirmDialog())
            })
        }
    }

    canSwapTabsHandler = () => {
        this.setState({ canSwapTabs: false })
    }

    confirmModal = () => this.setState({ activeTab: this.state.reserveTab, canSwapTabs: true })

    hideConfirmDialog = () => this.props.dispatch(hideConfirmDialog())

    setSubmit = (canSubmit) => {
        this.setState({ canSubmit })
    }

    render () {
        const { translate } = this.props

        return (
            <Layout
                {...this.props}
                hasFooter={this.state.activeTab === Resources.UserInfo || this.state.activeTab === Resources.UserInfo}
                isDirty={this.state.canSubmit}
            >
                <Page>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-2">
                                    <h1 className="mr-5 text-3xl">{translate('text.account')}</h1>
                                </div>
                                {/*<ul className="nav nav-tabs separator-tabs ml-0 mb-5" role="tablist">*/}
                                {/*    {*/}
                                {/*        checkPerm(Resources.Company, READ_PERM) &&*/}
                                {/*        <li className="nav-item">*/}
                                {/*            <span*/}
                                {/*                onClick={() => this.setActiveTab(Resources.UserInfo)}*/}
                                {/*                className={`nav-link ${this.state.activeTab === Resources.UserInfo ? 'active' : ''}`}*/}
                                {/*            >*/}
                                {/*                {translate('tab.info')}*/}
                                {/*            </span>*/}
                                {/*        </li>*/}
                                {/*    }*/}
                                {/*    /!* Temp remove*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <span*/}
                                {/*            onClick={() => this.setActiveTab('billing')}*/}
                                {/*            className={`nav-link ${this.state.activeTab === 'billing' ? 'active' : ''}`}*/}
                                {/*            href="#">{translate('tab.billing')}</span>*/}
                                {/*    </li>*/}

                                {/*    <li className="nav-item">*/}
                                {/*        <span*/}
                                {/*            onClick={() => this.setActiveTab('payments')}*/}
                                {/*            className={`nav-link ${this.state.activeTab === 'payments' ? 'active' : ''}`}*/}
                                {/*            href="#">{translate('tab.payments')}</span>*/}
                                {/*    </li>*/}
                                {/*    *!/*/}
                                {/*</ul>*/}

                                {this.props.resource.isLoading && (
                                    <div className="m-5 text-center">
                                        <LoaderSmall/>
                                    </div>
                                )}

                                {this.state.activeTab === Resources.UserInfo && (
                                    <InfoTab
                                        setSubmit={this.setSubmit}
                                        canSwapTabsHandler={this.canSwapTabsHandler}
                                        {...this.props}/>
                                )}

                                {this.state.activeTab === 'billing' && (
                                    <BillingTab {...this.props} />
                                )}

                                {this.state.activeTab === 'payments' && (
                                    <PaymentsTab/>
                                )}
                            </div>
                        </div>
                    </div>
                </Page>

                {this.props.ui.confirmDialog && this.state.activeTab === Resources.UserInfo && (
                    <ModalConfirm
                        text={this.state.text}
                        name={this.state.officeConfirmText}
                        submit={this.confirmModal}
                        cancel={this.hideConfirmDialog}
                        {...this.props}
                    />
                )}
            </Layout>
        )
    }
}

export default connect(state => state)(AccountView)

