import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function CreateSuccessModal({translate, buttons = []}) {
    return (
        <div className="px-6 pt-6 pb-8 flex flex-col w-full gap-y-4">

            <p className="text-base text-tm-gray-900">{translate("text.where_go_next")}</p>

            {buttons.map(button => (
                <button
                    ref={button.ref}
                    onClick={button.onClick}
                    className={button.className ?? "btn btn-outline pr-3 text-primary font-bold focus:bg-primary focus:text-white"}
                >
                    {!!button.iconLeading && (
                        <button.iconLeading className="w-5 h-5 mr-2"/>
                    )}

                    {button.label}

                    {!button.iconTrailing && button.iconTrailing === undefined && (
                        <ChevronRightIcon className="w-5 h-5 ml-auto"/>
                    )}

                    {!!button.iconTrailing && (
                        <button.iconTrailing className="w-5 h-5 ml-auto"/>
                    )}
                </button>
            ))}
        </div>
    )
}
