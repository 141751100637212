import { classNames } from '../../../../common/util/util-helpers'
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import React, {useMemo} from 'react'
import {CalculatorIcon} from '@heroicons/react/20/solid'
import Tippy from '@tippyjs/react'
import {EyeIcon, PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import {cloneDeep} from "../../../../common/util/util-vanilla";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../../common/components/resource-table";
import Card from "../../../../common/components/card";

export default function LoadPricingAccessorials({
                                                    fields,
                                                    toggleCreateDialog,
                                                        toggleEditDialog,
                                                    toggleViewDialog,
                                                    handleDeleteClick,
                                                    getFields,
                                                    onSetDefaultAccesorialClick,
                                                    isInvoiced,
                                                    translate,
                                                    title,
                                                    isAdjustmentTable = false
                                                }) {

    fields = useMemo(() => {
        let fieldsUpdate = cloneDeep(fields);
        if (isAdjustmentTable) {
            return fieldsUpdate.filter(it => !!it.IsAdjust)
        } else {
            return fieldsUpdate.filter(it => !it.IsAdjust)
        }
    }, [fields])

    const accessorialTotal = Number(Object.values(fields).reduce((memo, it) => {
        memo += ((it.Amount ?? 0) * (it.LoadAccessorialUnits ?? 0))
        return memo
    }, 0))

    const hasAccessorials = !!Object.values(fields).length

    return (
        <React.Fragment>
            <div className="">
                <Card
                    addClass={classNames(
                        'col-span-2 xl:col-span-1 overflow-hidden min-h-[13rem] flex z-0 relative'
                    )}
                    addBodyClass='border-0 border-l-4 border-green-700'
                    bodyClass="flex flex-col w-full grow justify-center"
                >

                    <div className="px-4 py-3 flex justify-between items-center">
                        {!!hasAccessorials && (
                            <>
                                <h2 className="text-lg text-tm-gray-700 flex gap-2">
                                    <ArrowTrendingUpIcon className="w-5 h-5 text-green-700"/>

                                    {title ?? translate('text.accessorials')}
                                </h2>

                                <div className="flex items-center space-x-4">
                                    {!isInvoiced && !isAdjustmentTable && (
                                        <Tippy content={translate('text.set_pricing_matrix')} delay={500}>
                                            <button
                                                onClick={onSetDefaultAccesorialClick}
                                                className="btn-icon hover:bg-tm-gray-200 rounded-full group "
                                            >
                                                <CalculatorIcon
                                                    className="w-5 h-5 text-tm-gray-400 group-hover:text-tm-gray-600"/>
                                            </button>
                                        </Tippy>
                                    )}
                                    <button
                                        className="btn btn-outline-secondary py-1"
                                        onClick={toggleCreateDialog}
                                    >
                                        <PlusIcon className="w-5 h-5 mr-1"/>
                                        {translate('btn.accessorial')}
                                    </button>
                                </div>

                            </>
                        )}
                    </div>

                    <ResourceTable
                        data={fields}
                        fields={getFields()}
                        translate={translate}
                        isLoading={false}

                        options={{
                            style: {
                                verticalLines: true,
                                floatingActions: false,
                            },
                            classes: {
                                headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                                primaryTextClass: ''
                            }
                        }}

                        onRowClick={(it) => {
                            if (it.IsPayed) {
                                toggleViewDialog(it, it.dataIndex);
                            } else {
                                toggleEditDialog(it, it.dataIndex);
                            }
                        }}

                        actions={[
                            {
                                action: (it) => toggleViewDialog(it, it.dataIndex),
                                icon: EyeIcon,
                                visible: (it) => it.IsPayed,
                            },
                            {
                                action: (it) => toggleEditDialog(it, it.dataIndex),
                                icon: PencilIcon,
                                visible: (it) => !it.IsPayed || !isInvoiced,
                            },
                            {
                                action: (it) => handleDeleteClick(it, it.dataIndex),
                                icon: TrashIcon,
                                visible: (it) => !it.IsPayed || !isInvoiced,
                            }
                        ]}

                        tfoot={{
                            AccessorialID: () => <div className={"px-5"}>{translate('text.total')}</div>,
                            AmountCalculated: accessorialTotal
                        }}
                    />

                    <NoRecords
                        show={!hasAccessorials}
                        onBtnClick={toggleCreateDialog}
                        addClass="px-4 py-10"
                        title={title ?? translate('title.no_accessorial')}
                        text={translate('text.no_accessorial')}
                        btnLabel={!(isInvoiced && !isAdjustmentTable) ? translate('btn.accessorial') : null}

                        htmlAfter={(
                            <React.Fragment>
                                {!isInvoiced && (
                                    <div className="mt-6">
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={onSetDefaultAccesorialClick}
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true"/>

                                            {translate('btn.fuel_surcharge_matrix')}
                                        </button>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    />

                    {!!isInvoiced && !!isAdjustmentTable && (
                        <div className="m-1">
                            <InfoBar type={"warning"}>Load is already invoiced, accessorial adjustments can be added but will impact invoice price
                                and may alter its status</InfoBar>
                        </div>
                    )}
                </Card>
            </div>
        </React.Fragment>
    )
}