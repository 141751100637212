import {IdentificationIcon} from "@heroicons/react/24/outline";
import TruckIcon from "@heroicons/react/24/outline/TruckIcon";
import {TrailerIcon} from "../../../data/themes/icons";
import React from "react";
import {classNames} from "../../../common/util/util-helpers";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import {LoaderSmall} from "../../../common/components/loader";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import Badge from "../../../common/components/badge";


export default function AssetNotAssignedBadges({
                                                   isLoading,
                                                   notAssignedDriversData,
                                                   notAssignedTrucksData,
                                                   notAssignedTrailersData,
                                                   onToggleDriversDialogClick,
                                                   onToggleTrucksDialogClick,
                                                   onToggleTrailersDialogClick,
                                                   addClass,
                                                   translate
                                               }) {


    if (isLoading) {
        return <InfoBar addClass={classNames(addClass, "items-center")}>Checking for unassigned assets... <LoaderSmall
            disableContainer={true}/></InfoBar>
    }

    if (!notAssignedDriversData.length && !notAssignedTrucksData.length && !notAssignedTrailersData.length) {
        return <InfoBar Icon={CheckIcon} type="success" addClass={classNames(addClass, "items-center")}>
            All assets are assigned!</InfoBar>
    }

    return <InfoBar type="warning" addClass={classNames(addClass, "items-center")}>
        {translate("text.not_assigned_to_unit")}:

        {!isLoading && (
            <>
                {!!notAssignedDriversData.length && (
                    <Badge
                        type="warning"
                        addClass="gap-1.5 hover:bg-yellow-700 items-center"
                        onClick={onToggleDriversDialogClick}
                    >
                        <IdentificationIcon className="w-5 h-5"/>
                        <span className="text-base leading-5">{notAssignedDriversData.length}</span>
                        <span className="hidden sm:inline">{translate("text.drivers")}</span>
                    </Badge>
                )}

                {!!notAssignedTrucksData.length && (
                    <Badge
                        type="warning"
                        addClass="gap-1.5 hover:bg-yellow-700"
                        onClick={onToggleTrucksDialogClick}
                    >
                        <TruckIcon className="w-5 h-5"/>
                        <span className="text-base leading-5">{notAssignedTrucksData.length}</span>
                        <span className="hidden sm:inline">{translate("text.trucks")}</span>
                    </Badge>
                )}

                {!!notAssignedTrailersData.length && (
                    <Badge
                        type="warning"
                        addClass="gap-1.5 hover:bg-yellow-700"
                        onClick={onToggleTrailersDialogClick}
                    >
                        <TrailerIcon
                            className="w-5 h-5"/>
                        <span className="text-base leading-5">{notAssignedTrailersData.length}</span>
                        <span className="hidden sm:inline">{translate("text.trailers")}</span>
                    </Badge>
                )}
            </>
        )}
    </InfoBar>
}