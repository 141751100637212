import React, {useState} from 'react'
import Tippy from '@tippyjs/react'
import {
    Bars2Icon,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/24/outline'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import ArrowUturnLeftIcon from '@heroicons/react/24/outline/ArrowUturnLeftIcon'
import {checkPerm, classNames} from "../../../common/util/util-helpers";
import {UPDATE_PERM} from "../../../common/util/util-consts";
import Resources from "../../../data/services/resources";
import {LoaderSmall} from "../../../common/components/loader";

export default function BoardView({
                                      columns,
                                      data,
                                      translate,
                                      isLoading,
                                      isDraggable,
                                      handleUnlinkIssue,
                                      handleRestoreIssue,
                                      handleCreateUpdateResource,
                                      handleDroppedItem
                                  }) {

    const [draggedItem, setDraggedItem] = useState(null)

    const getTypeColor = (type) => {
        switch (+type) {
            case 1:
                return '#7c68ed'
            case 2:
                return '#fc991f'
            case 3:
                return '#4bade8'
            case 4:
                return '#188038'
            default:
                return '#4bade8'
        }
    }

    const getTaskPriorityIcon = (item) => {
        let Icon;
        let colorClass;
        switch (+item.TicketLevelID) {
            case 1:
                Icon = ChevronDoubleDownIcon
                colorClass = 'text-primary'
                break
            case 2:
                Icon = ChevronDownIcon
                colorClass = 'text-primary'
                break
            case 3:
                Icon = Bars2Icon
                colorClass = 'text-orange-600'
                break
            case 4:
                Icon = ChevronUpIcon
                colorClass = 'text-red-600'
                break
            case 5:
                Icon = ChevronDoubleUpIcon
                colorClass = 'text-red-600'
                break
            default:
                Icon = false
                break
        }

        return Icon && (
            <Tippy
                content={<span>Bug Level: {item.TicketLevel}</span>}
            >
                <button
                    className="table-action mr-1 bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse"
                >
                    <div className={
                        classNames("cursor-pointer w-fit", colorClass)
                    }>
                        <Icon className="h-5 w-5"/>
                    </div>
                </button>
            </Tippy>
        )
    }

    const handleDragStart = (e, item) => {
        setDraggedItem(item)
    }

    const handleDragDrop = (e, Status) => {
        if (checkPerm(Resources.Tickets, UPDATE_PERM) && draggedItem.TicketStatusID != Status) {
            handleDroppedItem(draggedItem, Status)
        }
    }

    const grid = Object.keys(columns).map((item, index) => {
        const itemData = data
            .filter(it => (+it.TicketStatusID) === (+item))
            .map((it) => {
                return (
                    <div
                        draggable={isDraggable}
                        onDragStart={(e) => handleDragStart(e, it)}
                        onDrop={(e) => handleDragDrop(e, it.TicketStatusID)}
                        className={`${it.ArchivedDate ? 'bg-stripes' : 'bg-inverse'} p-3 shadow rounded mb-3 ${isDraggable && 'cursor-move'}`}
                        style={{borderLeft: `3px solid ${getTypeColor(it.TicketStatusID)}`, padding: '10px'}}
                    >
                        <div
                            onClick={() => checkPerm(Resources.Tickets, UPDATE_PERM) ? handleCreateUpdateResource(it) : it.disable}
                            className={`font-bold text-primary cursor-pointer mb-1 ${isDraggable && 'cursor-move'}`}>
                            {it.Title} (#{it.TicketsID})
                        </div>
                        <div className={'mb-2 text-tm-gray-500'}>
                            {it.Email ?
                                <Tippy content={<span>Requester: {it.Email}</span>}>
                                    <span>{it.Email}</span>
                                </Tippy>
                                :
                                <span></span>
                            }
                        </div>
                        <div className="flex justify-between items-center">
                            <div className="flex">
                                {getTaskPriorityIcon(it)}
                            </div>

                            {handleUnlinkIssue && !it.ArchivedDate && (
                                <Tippy
                                    content={translate('btn.archive')}
                                >
                                    <button
                                        className="table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700  hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() => {
                                            handleUnlinkIssue(it)
                                        }}
                                    >
                                        <TrashIcon className="h-5 w-5"/>
                                    </button>
                                </Tippy>
                            )}

                            {handleRestoreIssue && !!it.ArchivedDate && (
                                <Tippy
                                    content={translate('btn.restore')}
                                >
                                    <button
                                        className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700  hover:bg-tm-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() => {
                                            handleRestoreIssue(it)
                                        }}
                                    >
                                        <ArrowUturnLeftIcon className="h-5 w-5"/>
                                    </button>
                                </Tippy>
                            )}
                        </div>
                    </div>
                )
            })

        let boardGridItem = document.getElementById(`board-grid-item-${index}`)
        const draggedOverGridClassList = ['bg-tm-gray-200', 'border-dotted', 'border-2', 'border-primary']

        return (
            <div
                id={`board-grid-item-${index}`}
                key={index}
                className={'relative'}
                onDragOver={(e) => {
                    e.preventDefault()

                    if (boardGridItem) boardGridItem.classList.add(...draggedOverGridClassList)
                }}
                onDragLeave={(e) => {
                    e.preventDefault()

                    if (boardGridItem) boardGridItem.classList.remove(...draggedOverGridClassList)
                }}
                onDrop={(e) => {
                    handleDragDrop(e, item)
                    if (boardGridItem) boardGridItem.classList.remove(...draggedOverGridClassList)
                }}
            >
                <div className={`text-tm-gray-500 p-3 no-select sticky top-0 bg-tm-gray-100`}>
                    <div
                        className="  bg-inverse p-3 shadow rounded border-t font-weight-bolder uppercase"
                        style={{borderTop: `3px solid ${getTypeColor(item)}`, padding: '10px'}}>
                        {columns[item]} ({itemData.length})
                    </div>
                </div>

                <div className="p-3 h-full ">
                    {itemData}
                </div>
            </div>
        )
    })

    const styles = {
        display: 'grid',
        gridTemplateColumns: `repeat(${Object.keys(columns).length}, ${100 / Object.keys(columns).length}%)`,
        height: 'calc(100vh - 350px)',
        overflowX: 'scroll'
    }

    return (
        isLoading ?
            <div className="p-5 text-center">
                <LoaderSmall/>
            </div>
            :
            data.length > 0 && (
                <div style={styles} className={'bg-tm-gray-100'}>
                    {grid}
                </div>
            )
    )
}
