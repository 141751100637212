import React, {Component} from 'react'
import Resources from '../../../data/services/resources'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {Field} from '../../../data/services/fields'
import {DEFAULT_CRUD_STATE, READ_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Button from "../../../common/components/button";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import ClaimCard from "../../safety/claims/claim-card";

export default class ClaimsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            infoCardDialog: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();

        if ((!!this.props?.fetchInfoData && !this.props?.info?.isLoading && this.props?.info?.data?.LoadID !== Number(this.props?.id)) || !this.props.LoadNumber) {
            this.props.fetchInfoData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResource()
        }))
    }

    /** UI Events
     ================================================================= */
    handleShowDetails = item => {
        this.setState({selectedItem: item, infoCardDialog: true})
    }

    handleLinkClick = (url) => {
        this.props.history.push(url);
    }

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }
    /** Helpers
     ================================================================= */
    getFields = () => {
        return {
            ClaimNumber: new Field('ClaimNumber', '', [], false, 'text'),
            ClaimCode: new Field('ClaimCode', '', [], false, 'text'),
            ClaimOpened: new Field('ClaimOpened', '', [], false, 'date'),
            ClaimDate: new Field('ClaimDate', '', [], false, 'date'),
        }
    }

    getQuery = () => {
        return {
            id: this.getID(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
        }
    }

    getResource = () => {
        return Resources.LoadClaims
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', []);
        const isLoading = getProp(resource, 'isLoading', [])
        const count = getProp(resource.data, 'count', 0);

        return (
            <React.Fragment>
                <div className="space-y-5 mb-4">
                    <PageHeader
                        title={translate('page.heading.claims')}
                        titleClass="mr-5 text-2xl ml-4"
                    >
                        <Button
                            hasPerm={checkPerm(this.getResource(), READ_PERM)}
                            className="btn btn-header"
                            onClick={this.fetchData}
                        >
                            <ArrowPathIcon className="w-5 h-5"/>
                        </Button>

                    </PageHeader>
                </div>

                <TableCard>
                    <ResourceTable
                        options={{style: {floatingActions: true, horizontalLines: true}}}

                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        paginationButtonLimit={5}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                        onRowClick={checkPerm(this.getResource(), READ_PERM) ? this.handleShowDetails : null}

                        onView={this.handleShowDetails}
                        hasViewPerm={checkPerm(this.getResource(), READ_PERM)}
                    />

                    {!(!data.length && !isLoading) && (
                        <div
                            className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                pageLimit={this.state.limit}
                                translate={translate}
                            />
                        </div>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={false}
                        title={'No matching records found'}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>

                {(!!this.state.infoCardDialog &&
                    <ClaimCard
                        show={this.state.infoCardDialog}
                        ClaimID={this.state.selectedItem?.ClaimID}
                        translate={translate}
                        onClose={() => this.setState({infoCardDialog: false, selectedItem: null})}
                    />)}

            </React.Fragment>
        )
    }
}
