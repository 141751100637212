import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {ACCOUNT_TYPE_CASH} from '../../../../util/util-constants'
import ModalDefault from "../../../../common/components/modal/modal-default";
import {fieldsToHtml} from "../../../../common/util/util-fields";

export default class BulkPaymentCheckDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            claims: this.getClaims()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.setState({
                fields: this.getFields(),
                claims: this.getClaims()
            })
        }
    }

    getFields = () => {
        return {
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full'
            }),
            Date: new Field('Date', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            }),
            Description: new Field('Description', '', [], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
        }
    }

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.onSubmit(this.state.fields, this.state.claims)
                this.setState({
                    fields: this.getFields(),
                    claims: this.getClaims()
                })
                this.props.onClose()
            }
        })
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    getClaims = () => {
        const selectedItems = Object.values(this.props.selectedItems).filter(x => x.CheckStatusID === 1)
        return Object.keys(selectedItems).map(it => {
            return {
                [this.getItemKeyLabel()]: selectedItems[it][this.getItemKeyLabel()],
                ...selectedItems[it]
            }
        })
    }

    getItemKeyLabel = () => {
        return this.props.itemKeyLabel ?? ''
    }

    render() {
        const {translate} = this.props

        const selects = {
            AccountID: {
                api: 'api/accounting/accounts',
                query: {
                    AccountTypeID: ACCOUNT_TYPE_CASH
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        }

        const items = Object.values(this.props.selectedItems).filter(x => x.CheckStatusID === 1)
        const selectedItems = Object.keys(items).map((elem) => {
            return (
                <div>
                    <div>
                        <span className="font-weight-bolder">{translate('text.CheckNumber')}: </span>
                        {items[elem].CheckNumber}
                    </div>

                    <div className={'mb-4'}>
                        <span className="font-weight-bolder">{translate('text.Customer')}: </span>
                        {items[elem]['Organization'] ?? items[elem]['Contact']}
                    </div>
                </div>
            )
        })

        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-2xl'}
                title={translate('text.ClaimChecks')}

                onButtonClick={this.submit}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={this.props.onClose}
            >
                <div className="p-5">
                    <div className="row mb-3">
                        <div className="col">
                            <div className="card">
                                <div className="card-body bg-inverse">
                                    <div className="grid-auto-fit-30">
                                        {selectedItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col grid grid-cols-12 gap-x-8 gap-y-2">
                        {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, selects)}
                    </div>
                </div>
            </ModalDefault>
        )
    }
}

