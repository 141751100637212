import React, {Component} from 'react'
import Resources from '../../../../data/services/resources'
import moment from 'moment'
import {
    ACCOUNT_TYPE_EXPENSE,
    ACCOUNT_TYPE_FIXED_ASSETS,
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    FIXED_ASSET_TYPES, MAJOR_ACCOUNT_TYPE_EXPENSE
} from '../../../../util/util-constants'
import {checkPerm, getProp} from '../../../../common/util/util-helpers'
import {toFrontDateNoTime} from '../../../../common/util/util-dates'
import {numberWithCommas, numberWithCommasToBack} from '../../../../util/util-formaters'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {FieldsManager} from '../../../../data/services/fields'
import {fieldsToHtml} from "../../../../common/util/util-fields";
import Card from "../../../../common/components/card";
import {LoaderSmall} from "../../../../common/components/loader";
import CardSubTitle from "../../../../common/components/card/card-sub-title";
import Badge from "../../../../common/components/badge";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";

export default class InfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: props.fields_info,
            canSubmit: false
        }
    }

    monthDiff = (d1, d2) => {
        return Math.floor(moment(d2).diff(moment(d1), 'months', true))
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate, resource} = this.props

        let assetDataStartValue = getProp(resource, 'data.accounting/assets/info.StartingAmount', 0);
        const assetDataDep = getProp(resource, 'data.accounting/assets/info.DepTransactions', []);

        const {
            StartDate,
            EndDate,
            Amount,
            SalvageAmount,
            StartingAmount
        } = FieldsManager.getFieldKeyValues(this.props.fields_info)

        let writeOffCalculation = !!(StartDate && EndDate && Amount && SalvageAmount)

        let MonthDifference = writeOffCalculation && this.monthDiff(StartDate, EndDate)

        let MonthlyAmount = writeOffCalculation ? (((numberWithCommasToBack(StartingAmount)) - (numberWithCommasToBack(SalvageAmount))) / (numberWithCommasToBack(MonthDifference))) : 0

        let arr = []
        for (let i = 0; i < MonthDifference; i++) {
            arr.push({
                Date: toFrontDateNoTime(moment(StartDate).add(i, 'months')),
                MonthlyAmount: MonthlyAmount,
                AccumulativeAmount: MonthlyAmount * (i + 1)
            })
        }
        const writeOffData = arr.map(item => {
            return (
                <>
                    <div>{item.Date}</div>
                    <div>{numberWithCommas(item.MonthlyAmount)}</div>
                    <div>{numberWithCommas(item.AccumulativeAmount)}</div>
                </>
            );
        })

        const writeOffActualData = assetDataDep.map(item => {
            assetDataStartValue -= item.Amount;
            return (
                <>
                    <div>{toFrontDateNoTime(item.Date)}</div>
                    <div>{numberWithCommas(item.Amount)}</div>
                    <div>{numberWithCommas(assetDataStartValue)}</div>
                </>
            )
        })

        const data = getProp(this.props.resource, 'data.accounting/assets/info', {})

        const selects = {
            FixedAssetTypeID: FIXED_ASSET_TYPES,
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {AccountTypeID: ACCOUNT_TYPE_FIXED_ASSETS},
                searchMap: (it) => ({
                    label: it.AccountName,
                    value: it.AccountID
                })
            },
            AccumulatedAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {AccountTypeID: ACCOUNT_TYPE_FIXED_ASSETS},
                searchMap: (it) => ({
                    label: it.AccountName,
                    value: it.AccountID
                })
            },
            DepExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    limit: 20,
                    offset: 0,
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (it) => ({
                    label: it.AccountName,
                    value: it.AccountID
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.props.fields_info)), translate, this.props.handleInputChange, selects)

        return (
            <React.Fragment>
                {this.props.resource.isLoading && (
                    <Card>
                        <div className="text-center">
                            <LoaderSmall/>
                        </div>
                    </Card>
                )}

                {!resource.isLoading && (
                    <React.Fragment>
                        <Card>
                            <div className="flex justify-between items-center">
                                <CardSubTitle
                                    text={translate('modal_heading.AssetInfo')}
                                    addClass={'mb-4'}
                                />
                                {!!data.ReferenceID && (
                                    <Tippy
                                        content={<span>See Truck</span>}
                                    >
                                        <Badge
                                            onClick={() => {
                                                window.open(`${window.location.origin}/trucks/info/${data.ReferenceID}`, '_blank')
                                            }}>
                                            <div className="flex justify-between items-center cursor-pointer">
                                                <span>Check Truck</span>
                                                <ArrowTopRightOnSquareIcon
                                                    className={'w-5 h-5 ml-4'}
                                                />
                                            </div>
                                        </Badge>
                                    </Tippy>
                                )}
                            </div>

                            <div className="grid grid-cols-12 gap-5">
                                {fieldsHtml}
                            </div>

                            {writeOffCalculation && (
                                <Card>
                                    {(!!writeOffActualData && writeOffActualData.length > 0) &&
                                        (
                                            <>
                                                <h3 className={"font-bold text-lg"}>Actual deprecation</h3>
                                                <div className="grid grid-cols-3 gap-5 border-b pb-1">
                                                    <div className={'font-bold'}>Date</div>
                                                    <div className={'font-bold'}>Depreciation amount</div>
                                                    <div className={'font-bold'}>Book value</div>
                                                </div>
                                                <div className="grid grid-cols-3 gap-5">
                                                    {writeOffActualData}
                                                </div>
                                            </>
                                        )}

                                    <h3 className={"font-bold text-lg"}>Depreciation plan</h3>
                                    <div className="grid grid-cols-3 gap-5 border-b pb-1">
                                        <div className={'font-bold'}>Date</div>
                                        <div className={'font-bold'}>Monthly Amount</div>
                                        <div className={'font-bold'}>Accumulative Amount</div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-5">
                                        {writeOffData}
                                    </div>
                                </Card>
                            )}
                        </Card>
                    </React.Fragment>
                )}

                {!!this.getID() && (
                    <PageFooter
                        translate={translate}
                        canSubmit={this.props.canSubmit}
                        actionCancel={this.props.handleCancelClick}
                        actionSubmit={checkPerm(Resources.Claims, CREATE_PERM) && this.props.submit}
                    />
                )}
            </React.Fragment>
        )
    }
}
