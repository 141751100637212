export function uploadOcrFillDocument(data) {
    return {
        type: 'UPLOAD_OCR_FILL_DOCUMENT',
        data: data
    };
}

export function doneUploadAutofillDocument(data) {
    return {
        type: 'DONE_UPLOAD_OCR_FILL_DOCUMENT',
        data: data
    };
}

export function errorUploadAutofillDocument(data) {
    return {
        type: 'ERROR_UPLOAD_OCR_FILL_DOCUMENT',
        data: data
    };
}
