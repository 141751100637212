import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {CREATE_PERM, DEFAULT_CRUD_STATE} from "../../../../util/util-constants";
import {getResource, deleteResource, createResource} from "../../../../data/actions/resource";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {classNames, getProp} from "../../../util/util-helpers";
import PageHeader from "../../layout/layout-components/page/page-header";
import TableCard from "../../resource-table/table-components/table-card";
import ModalSaveResource from "../../modal/modal-save-resource";
import ModalConfirm from "../../modal/modal-confirm";
import Pagination from "../../resource-table/table-components/pagination";
import ResourceTable from "../../resource-table";
import {checkPerm, getHeaderFilterValues, returnOffsetAndPagination} from "../../../util/util-helpers";
import {fillFieldsFromData} from "../../../util/util-fields";
import NoRecordsTable from "../../no-records-found/no-records-table";

export default class PreferencesUpdateTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? "",

            searchFields: this.props.searchFields ?? {},
            headerFilterFields: this.getHeaderFilterFields(),

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            searchFields: FieldsManager.updateField(this.state.searchFields, name, value),
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleFilterClear = () => {
        this.setState({
            searchFields: this.props.searchFields ?? {},
            headerFilterFields: this.getHeaderFilterFields(),
        }, () => {
            this.fetchData();
        });
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,

            ...getHeaderFilterValues(this.state.headerFilterFields),
            ...FieldsManager.getFieldKeyValues(this.state.searchFields)
        }
    }

    getHeaderFilterFields = () => {
        return this.props.headerFilterFields ?? {}
    }

    getFields = (item = {}) => {
        const fieldTemplates = this.props.fields ?? {
            PreferenceTypeID: new Field("PreferenceTypeID", '', ['empty'], false, 'select', {addContainerClass: "col-span-full"}),
            PreferenceTypeSubItem: new Field("PreferenceTypeSubItem", '', ['empty'], false, 'creatable', {
                addContainerClass: "col-span-full"
            }, {
                isMulti: true
            }),
            Notes: new Field("Notes", '', [], false, 'textarea', {addContainerClass: "col-span-full"}),
        };
        return fillFieldsFromData(fieldTemplates, item);
    };

    getPreferenceType = () => {
        return getProp(LocalStorage.get('lookup'), 'PreferenceType', []).reduce((memo, it) => {
            memo[it.PreferenceTypeID] = `${it.PreferenceType}${it.PreferenceTypeSubItems.length === 0 ? ' (Has no SubItems)' : ''}`
            return memo
        }, {})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, disableDelete, resource} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            PreferenceTypeSubItem: {},
            PreferenceTypeID: this.getPreferenceType()
        }

        return (
            <div>
                <div className={"mb-2"}>
                    <PageHeader
                        buttonLabel={translate("btn.create_new")}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        buttonHidden={!checkPerm(this.getResourceName(), CREATE_PERM)}
                    >
                    </PageHeader>
                </div>

                <TableCard>
                    <div className={"flex justify-end p-5 border-tm-gray-300 border-b"}>
                        <button className="btn btn-header" onClick={this.fetchData}>
                            <ArrowPathIcon className={
                                classNames(
                                    "w-5 h-5",
                                    isLoading ? "animate-spin" : undefined,
                                )
                            }/>
                        </button>
                    </div>

                    <ResourceTable
                        options={{style: {floatingActions: true, horizontalLines: true}}}

                        data={data}
                        count={count}

                        fields={this.props.tableFields ?? this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onDelete={!disableDelete ? this.handleToggleConfirmModal : null}

                        actions={this.props.onCustomActions ? this.props.onCustomActions(this.getQuery()) : []}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !isLoading) && (
                        <div
                            className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                pageLimit={this.state.limit}
                                translate={translate}
                            />
                        </div>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate("text.no_records")}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>

                <ModalSaveResource
                    title={"Create " + (this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-xl"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    show={this.state.createModalOpen}
                    onSubmit={(params) => {
                        if (params) {
                            params.Preferences = Array.isArray(params.PreferenceTypeSubItem) ? params.PreferenceTypeSubItem.map(it => {
                                return it.__isNew__ ? {
                                    value: -1,
                                    label: it.label,
                                } : it
                            }) : []

                            params.id = this.getId();
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: "Preference created.",
                                refreshLocalStorageLookups: params.PreferenceTypeSubItem.some(it => !!it.__isNew__)
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        let fieldsTmp = FieldsManager.updateField(fields, name, value)
                        if (name === 'PreferenceTypeID') {
                            fieldsTmp = FieldsManager.updateField(fields, 'PreferenceTypeSubItem', '')
                            const PreferenceTypeSubItems = getProp(LocalStorage.get('lookup'), 'PreferenceType', []).find(it => it.PreferenceTypeID == value)
                            fieldsTmp['PreferenceTypeSubItem'].props.values = PreferenceTypeSubItems.PreferenceTypeSubItems.reduce(
                                (memo, item) => {
                                    memo[item['PreferenceTypeSubItemID']] = item['PreferenceTypeSubItem']
                                    return memo
                                }, {})
                        }
                        return fieldsTmp
                    }}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    show={!!this.state.confirmModalOpen}
                    text={"Are you sure you want to delete " + this.state.selectedItem?.PreferenceType + "?"}
                    onClose={() => this.setState({confirmModalOpen: false})}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                        }, () => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                                    id: this.getId()
                                }),
                                piggyQuery: this.getQuery(),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: "Preference deleted."
                            }));
                            this.handleToggleConfirmModal(false)
                        })
                    }}
                />
            </div>
        )
    }
}
