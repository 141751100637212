import {mergeDeep} from "../../common/util/util-helpers";

const uiReducer = (state = {}, action) => {
    let dialogState = state.globalDialogs ? mergeDeep({}, state.globalDialogs) : {};

    switch (action.type) {
        case 'CURRENT_THEME':
            return Object.assign({}, state, {
                currentTheme: action.data
            })
        case 'SHOW_GLOBAL_MODAL': {
            const globalDialogData = Object.assign(dialogState, {
                [action.data.modalName]: {
                    name: action.data.modalName,
                    visible: true,
                    data: action.data.data
                }
            });

            return Object.assign({}, state, {
                globalDialogs: globalDialogData
            })
        }
        case 'HIDE_GLOBAL_MODAL':
            if (dialogState[action.data.modalName]) {
                dialogState[action.data.modalName].visible = false
            }

            return Object.assign({}, state, {
                globalDialogs: dialogState
            })

        case 'SHOW_MODAL':
            return Object.assign({}, state, {
                data: action.data.data,
                modalName: action.data.modalName,
                HideModalAnimation: false
            })
        case 'HIDE_MODAL':
            return Object.assign({}, state, {
                HideModalAnimation: true,
                refresh: action.data
            })
        case 'DESTROY_MODAL':
            return Object.assign({}, state, {
                modalName: false,
                HideModalAnimation: false,
                refresh: null
            });
        case 'SHOW_SECOND_MODAL':
            return Object.assign({}, state, {
                secondData: action.data.data,
                secondModalName: action.data.secondModalName,
                secondHideModalAnimation: false
            })
        case 'HIDE_SECOND_MODAL':
            return Object.assign({}, state, {
                secondHideModalAnimation: true,
                refresh: action.data
            })
        case 'DESTROY_SECOND_MODAL':
            return Object.assign({}, state, {
                secondModalName: false,
                secondHideModalAnimation: false,
                refresh: null
            });
        case 'SHOW_HISTORY_MODAL':
            return Object.assign({}, state, {
                viewHistoryDialog: true,
                historyDialogHideAnimation: false
            })
        case 'HIDE_HISTORY_MODAL':
            return Object.assign({}, state, {
                viewHistoryDialog: true,
                historyDialogHideAnimation: true
            });
        case 'DESTROY_HISTORY_MODAL':
            return Object.assign({}, state, {
                viewHistoryDialog: false,
                historyDialogHideAnimation: true
            });
        case 'SHOW_CHAT_SIDEBAR':
            return Object.assign({}, state, {
                chatSidebar: true,
                chatSidebarDialogHideAnimation: false
            })
        case 'HIDE_CHAT_SIDEBAR':
            return Object.assign({}, state, {
                chatSidebar: true,
                chatSidebarDialogHideAnimation: true
            });
        case 'DESTROY_CHAT_SIDEBAR':
            return Object.assign({}, state, {
                chatSidebar: false,
                chatSidebarDialogHideAnimation: true
            });
        case 'SHOW_HELP_SIDEBAR':
            return Object.assign({}, state, {
                viewHelpSidebar: true,
                viewHelpSidebarHideAnimation: false
            })
        case 'HIDE_HELP_SIDEBAR':
            return Object.assign({}, state, {
                viewHelpSidebar: true,
                viewHelpSidebarHideAnimation: true
            });
        case 'DESTROY_HELP_SIDEBAR':
            return Object.assign({}, state, {
                viewHelpSidebar: false,
                viewHelpSidebarHideAnimation: false
            });
        // case 'SHOW_ONBOARD_MODAL':
        //     return Object.assign({}, state, {
        //         viewOnBoardDialog: true,
        //         showUserDropdown: false,
        //         OnBoardDialogHideAnimation: false
        //     })
        // case 'HIDE_ONBOARD_MODAL':
        //     return Object.assign({}, state, {
        //         viewOnBoardDialog: true,
        //         OnBoardDialogHideAnimation: true
        //     });
        // case 'DESTROY_ONBOARD_MODAL':
        //     return Object.assign({}, state, {
        //         viewOnBoardDialog: false,
        //         OnBoardDialogHideAnimation: true
        //     });
        case 'SHOW_CREATE_OFFICE_DIALOG':
            return Object.assign({}, state, {
                viewCreateOfficeDialog: true,
                createOfficeDialogHideAnimation: false
            });
        case 'HIDE_CREATE_OFFICE_DIALOG':
            return Object.assign({}, state, {
                viewCreateOfficeDialog: true,
                createOfficeDialogHideAnimation: true
            });
        case 'DESTROY_CREATE_OFFICE_DIALOG':
            return Object.assign({}, state, {
                viewCreateOfficeDialog: false,
                createOfficeDialogHideAnimation: false
            });
        case 'SHOW_DELETE_OFFICE_DIALOG':
            return Object.assign({}, state, {
                viewDeleteOfficeDialog: true,
                deleteOfficeDialogHideAnimation: false
            });
        case 'HIDE_DELETE_OFFICE_DIALOG':
            return Object.assign({}, state, {
                viewDeleteOfficeDialog: true,
                deleteOfficeDialogHideAnimation: true
            });
        case 'DESTROY_DELETE_OFFICE_DIALOG':
            return Object.assign({}, state, {
                viewDeleteOfficeDialog: false,
            });
        case 'SHOW_CONFIRM_DIALOG':
            return Object.assign({}, state, {
                confirmDialog: true,
                confirmDialogHideAnimation: false
            });
        case 'HIDE_CONFIRM_DIALOG':
            return Object.assign({}, state, {
                confirmDialog: true,
                confirmDialogHideAnimation: true
            });
        case 'DESTROY_CONFIRM_DIALOG':
            return Object.assign({}, state, {
                confirmDialog: false,
                confirmDialogHideAnimation: false
            });
        case 'SHOW_CONFIRM_REDIRECTION_DIALOG':
            return Object.assign({}, state, {
                confirmRedirectionDialog: true,
                confirmRedirectionDialogHideAnimation: false
            });
        case 'HIDE_CONFIRM_REDIRECTION_DIALOG':
            return Object.assign({}, state, {
                confirmRedirectionDialog: true,
                confirmRedirectionDialogHideAnimation: true
            });
        case 'DESTROY_CONFIRM_REDIRECTION_DIALOG':
            return Object.assign({}, state, {
                confirmRedirectionDialog: false,
                confirmRedirectionDialogHideAnimation: false
            });
        case 'SHOW_UPDATE_DIALOG':
            return Object.assign({}, state, {
                updateOfficeDialog: true,
                updateDialogHideAnimation: false
            });
        case 'HIDE_UPDATE_DIALOG':
            return Object.assign({}, state, {
                updateOfficeDialog: true,
                updateDialogHideAnimation: true
            });
        case 'DESTROY_UPDATE_DIALOG':
            return Object.assign({}, state, {
                updateOfficeDialog: false,
                updateDialogHideAnimation: false
            });
        case 'CREATE_TASK_DIALOG':
            return Object.assign({}, state, {
                createTaskDialog: true,
                createTaskResource: action.data,
                data: action.data
            });
        case 'CLOSE_TASK_DIALOG':
            return Object.assign({}, state, {
                createTaskDialog: true,
                TaskDialogHideAnimation: true
            });
        case 'DESTROY_TASK_DIALOG':
            return Object.assign({}, state, {
                createTaskDialog: false,
                TaskDialogHideAnimation: false
            });
        case 'SHOW_INFO_DIALOG':
            return Object.assign({}, state, {
                infoOfficeDialog: true,
                infoDialogHideAnimation: false
            });
        case 'HIDE_INFO_DIALOG':
            return Object.assign({}, state, {
                infoOfficeDialog: true,
                infoDialogHideAnimation: true
            });
        case 'DESTROY_INFO_DIALOG':
            return Object.assign({}, state, {
                infoOfficeDialog: false,
                infoDialogHideAnimation: true
            });
        case 'SHOW_MAP_DIALOG':
            return Object.assign({}, state, {
                mapData: action.data,
                mapDialog: true,
                mapDialogHideAnimation: false
            });
        case 'HIDE_MAP_DIALOG':
            return Object.assign({}, state, {
                mapDialog: true,
                mapDialogHideAnimation: true
            });
        case 'DESTROY_MAP_DIALOG':
            return Object.assign({}, state, {
                mapData: {},
                mapDialog: false,
                mapDialogHideAnimation: false
            })
        case 'SHOW_RESOURCE_DIALOG':
            return Object.assign({}, state, {
                resourceDialog: true,
                dialogHideAnimation: false
            });
        case 'HIDE_RESOURCE_DIALOG':
            return Object.assign({}, state, {
                resourceDialog: true,
                dialogHideAnimation: false
            });
        case 'DESTROY_RESOURCE_DIALOG':
            return Object.assign({}, state, {
                resourceDialog: false,
                dialogHideAnimation: false
            })
        case 'PUSH_LOAD_FIELDS':
            // TODO: Refactor to one variable instead of dozen objects
            return Object.assign({}, state, {
                selectedLocationsData: action.data.data.selectedLocationsData,
                cache: action.data.data.cache,
                fields: action.data.data.fields,
                fields_text: action.data.data.fields_text,
                fields_pickup: action.data.data.fields_pickup,
                fields_destination: action.data.data.fields_destination,
                fields_truck: action.data.data.fields_truck,
                fields_trailer: action.data.data.fields_trailer,
                fields_customer: action.data.data.fields_customer,
                fields_driver: action.data.data.fields_driver,
                fields_codriver: action.data.data.fields_codriver,
                fields_commodities: action.data.data.fields_commodities,
                fields_stop_locations: action.data.data.fields_stop_locations,
                fields_carrier: action.data.data.fields_carrier,
                fields_Accessorials: action.data.data.fields_Accessorials,
            })
        case 'CLOSE_LOAD_FIELDS':
            return Object.assign({}, state, {
                fields: null,
                fields_pickup: null,
                fields_destination: null,
                fields_truck: null,
                fields_trailer: null,
                fields_customer: null,
                fields_driver: null,
                fields_codriver: null,
                fields_carrier: null,
                fields_commodities: null,
                fields_stop_locations: null,
            })
        case 'PUSH_NOTIFICATION':
            // if (action.data.position !== "silent") {
                return Object.assign({}, state, {
                    notification: action.data,
                });
            // } else {
            //     return state
            // }
        case 'CLOSE_NOTIFICATION':
            return Object.assign({}, state, {
                closeNotification: action.data,
            });
        case 'DISPLAY_UNREAD_NOTIFICATIONS_COUNT':
            return Object.assign({}, state, {
                unreadCount: action.data.unreadNotifications ? action.data.unreadNotifications : state.unreadCount,
                unreadMessagesCount: action.data.unreadMessages
            });

        case 'GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                notificationListLoading: true,
                unreadCount: 0
            });
        case 'DONE_GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                notificationListLoading: false,
                notificationList: action.data
            });
        case 'ERROR_GET_NOTIFICATION_LIST':
            return Object.assign({}, state, {
                notificationListLoading: false,
                errorNotificationList: true
            });

        case 'GET_COMMUNICATION_LIST':
            return Object.assign({}, state, {
                communicationsListIsLoading: true,
            });
        case 'DONE_GET_COMMUNICATION_LIST':
            return Object.assign({}, state, {
                communicationList: action.data,
                communicationsListIsLoading: false,
            });
        case 'ERROR_GET_COMMUNICATION_LIST':
            return Object.assign({}, state, {
                communicationsListIsLoading: false,
            });

        case 'GET_CONVERSATION':
            return Object.assign({}, state, {
                conversationIsLoading: true,
            });
        case 'DONE_GET_CONVERSATION':
            return Object.assign({}, state, {
                conversation: action.data,
                conversationIsLoading: false,
            });
        case 'ERROR_GET_CONVERSATION':
            return Object.assign({}, state, {
                conversationIsLoading: false,
            });

        case 'SHOW_GLOBAL_SEARCH_POPUP':
            return Object.assign({}, state, {
                globalSearchPopup: true,
                globalSearchPopupHideAnimation: false
            });
        case 'HIDE_GLOBAL_SEARCH_POPUP':
            return Object.assign({}, state, {
                globalSearchPopup: true,
                globalSearchPopupHideAnimation: true
            });
        case 'DESTROY_GLOBAL_SEARCH_POPUP':
            return Object.assign({}, state, {
                globalSearchPopup: false,
                globalSearchPopupHideAnimation: false
            })
        case 'SHOW_GLOBAL_INFO_SEARCH_POPUP':
            return Object.assign({}, state, {
                globalInfoSearchPopup: true,
                globalInfoSearchPopupHideAnimation: false
            });
        case 'HIDE_GLOBAL_INFO_SEARCH_POPUP':
            return Object.assign({}, state, {
                globalInfoSearchPopup: true,
                globalInfoSearchPopupHideAnimation: true
            });
        case 'DESTROY_GLOBAL_INFO_SEARCH_POPUP':
            return Object.assign({}, state, {
                globalInfoSearchPopup: false,
                globalInfoSearchPopupHideAnimation: false
            })
        case 'SHOW_SETUP_NEW_LOAD_DIALOG':
            return Object.assign({}, state, {
                setupNewLoadDialog: true,
                setupNewLoadDialogHideAnimation: false
            });
        case 'HIDE_SETUP_NEW_LOAD_DIALOG':
            return Object.assign({}, state, {
                setupNewLoadDialog: true,
                setupNewLoadDialogHideAnimation: true
            });
        case 'DESTROY_SETUP_NEW_LOAD_DIALOG':
            return Object.assign({}, state, {
                setupNewLoadDialog: false,
                setupNewLoadDialogHideAnimation: false
            })
        case 'SHOW_SETUP_NEW_LOAD_DIALOG_SECCOND':
            return Object.assign({}, state, {
                setupNewLoadDialogSeccond: true,
                setupNewLoadDialogHideAnimationSeccond: false
            });
        case 'HIDE_SETUP_NEW_LOAD_DIALOG_SECCOND':
            return Object.assign({}, state, {
                setupNewLoadDialogSeccond: true,
                setupNewLoadDialogHideAnimationSeccond: true
            });
        case 'DESTROY_SETUP_NEW_LOAD_DIALOG_SECCOND':
            return Object.assign({}, state, {
                setupNewLoadDialogSeccond: false,
                setupNewLoadDialogHideAnimationSeccond: false
            })
        case 'CHART_WIDTH_CHANGED':
            return Object.assign({}, state, {
                refreshCharts: !state.refreshCharts,
            })
        case 'CHANGE_THEME': {
            let theme = "light"
            if (action.data) {
                theme = action.data
            }
            return Object.assign({}, state, {
                theme
            })
        }
        case 'GRAB_UPDATE':
            return Object.assign({}, state, {
                grabUpdate: (state.grabUpdate ?? 0) + 1,
            })
        case 'LAYOUT_UPDATE':
            return Object.assign({}, state, {
                AIChatPosition: action?.data?.AIChatPosition,
                isAIChatVisible: action?.data?.isAIChatVisible
            })
        case 'RESET_IDLE_TIMER':
            return Object.assign({}, state, {
                remountCount: state.remountCount ? state.remountCount + 1 : 1,
            })
        default:
            return state;
    }
};

export default uiReducer;


