import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import {classNames, getProp, JSONParseFix, resourceIsCreated} from '../../../common/util/util-helpers'
import moment from 'moment-timezone'
import {createDataSelect, deleteDataSelect, getDataSelect} from '../../../data/selectors/resourceSelectors'
import {DEFAULT_CRUD_STATE, DEFAULT_DATABASE_DATETIME_FORMAT} from '../../../util/util-constants'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {ChevronRightIcon, EllipsisHorizontalIcon, PlusIcon} from '@heroicons/react/24/outline'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon'
import {getSecondResource} from '../../../data/actions/secondResource'
import AccountReportDialog from '../dialogs/account-report-dialog'
import MinusIcon from '@heroicons/react/20/solid/MinusIcon'
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Tooltip from "../../../common/components/tooltip";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableFooterBulkActions from "../../../common/components/resource-table/table-components/table-footer-actions";
import FieldsToHtml from "../../../common/components/fields/fields-to-html";
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

const DepreciationTab = ({translate, breakpoint}) => {
    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)
    const secondResource = useSelector((state) => state.secondResource)
    const thirdResource = useSelector((state) => state.thirdResource)
    const downloadResource = useSelector((state) => state.download)
    const dialogResource = useSelector((state) => state.dialogResource)

    const getData = getDataSelect({dispatch: dispatch, user: user.data})
    const createData = createDataSelect({dispatch: dispatch, user: user.data})
    const deleteData = deleteDataSelect({dispatch: dispatch, user: user.data})

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    const downloadIsLoading = getProp(downloadResource, 'isLoading', false)

    /** Constants
     ================================================================= */
    const pagePath = 'PendingJournalTab'

    /** Fields Data Definitions
     ================================================================= */
    const getFields = (removeFields = []) => {
        let fields = {
            IsPayed: new Field('IsPayed', '', [], false, 'custom', {
                render: (it) => isPayedFieldRender(it)
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date'),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date'),
            LastRunDate: new Field('LastRunDate', '', [], false, 'date'),
            NextRunDate: new Field('NextRunDate', '', [], false, 'date'),
            Name: new Field('Name', '', [], false, 'text'),
            Description: new Field('Description', '', [], false, 'text'),
            Transactions: new Field('Transactions', '', ['empty'], false, 'custom', {
                render: (item) => transactionsFieldRender(item),
                omitSort: true
            }),
            Debit: new Field('Debit', '', [''], false, 'custom', {
                render: (item) => getDebitCreditFieldRender(item, 'Debit'),
                omitSort: true
            }),
            Credit: new Field('Credit', '', [''], false, 'custom', {
                render: (item) => getDebitCreditFieldRender(item, 'Credit'),
                omitSort: true
            })
        }

        if (!!removeFields.length) {
            removeFields.forEach(fieldName => {
                delete fields[fieldName]
            })
        }

        return fields
    }

    const getPostJournalFields = () => {
        return {
            PendingJournalEntryID: new Field('PendingJournalEntryID', '', [''], false, 'hidden'),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [''], false, 'date')
        }
    }

    const getBulkSelectFields = () => {
        return {
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [''], false, 'date')
        }
    }

    const getTransactionFields = () => {
        return {
            Account: new Field('Account', '', ['empty'], false, 'text', {
                omitSort: true
            }),
            Debit: new Field('Debit', '', [''], false, 'money', {
                    omitSort: true
                }
            ),
            Credit: new Field('Credit', '', [''], false, 'money', {
                omitSort: true
            })
        }
    }

    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {
                isRequiredFilter: true
            }, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {
                isRequiredFilter: true
            }, {
                isClearable: true
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    /** Helpers
     ================================================================= */
    const getPrimaryKey = () => {
        return 'PendingJournalEntryID'
    }

    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    const getResourceName = () => {
        return Resources.JournalPending
    }

    /** State
     ================================================================= */
        // Query definitions
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSONParseFix(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE
    })
    const [transactionsQuery, setTransactionsQuery] = useState(DEFAULT_CRUD_STATE)

    // Modals
    const [transactionsModalOpen, setTransactionsModalOpen] = useState(false)
    const [receiptModalOpen, setReceiptModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedJournals, setSelectedJournals] = useState({})
    const [selectedPreviewJournals, setSelectedPreviewJournals] = useState({})
    const [bulkPostModalOpen, setBulkPostModalOpen] = useState(false)
    const [postPendingJournalModalOpen, setPostPendingJournalModalOpen] = useState(false)
    const [unPostPendingJournalModalOpen, setUnPostPendingJournalModalOpen] = useState(false)
    const [bulkSelectFields, setBulkSelectFormFields] = useState(getBulkSelectFields())

    /** UI events - QUERY HANDLERS - main table
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter((it) => !excludedFields.includes(it.name)).forEach((it) => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    /** UI events
     ================================================================= */
    const handleBulkSelectFormInputChange = (name, value) => {
        setBulkSelectFormFields(FieldsManager.updateField(bulkSelectFields, name, value))
    }

    const handleSelectJournalPendingPreviewItem = (row, id) => {
        const selectedJournalsUpdate = cloneDeep(selectedPreviewJournals)

        if (!!selectedJournalsUpdate[id]) {
            delete selectedJournalsUpdate[id]
        } else {
            selectedJournalsUpdate[id] = row
        }

        setSelectedPreviewJournals(selectedJournalsUpdate)
    }

    const handleSelectAllJournalPendingPreviewItems = (areAllSelected) => {
        const selectedJournalsUpdate = cloneDeep(selectedPreviewJournals)
        Object.values(selectedJournals).forEach(item => {
            if (!areAllSelected) {
                selectedJournalsUpdate[item[getPrimaryKey()]] = item
            } else {
                delete selectedJournalsUpdate[item[getPrimaryKey()]]
            }
        })

        setSelectedPreviewJournals(selectedJournalsUpdate)
    }

    const handleSelectJournalPendingItem = (row, id) => {
        const selectedJournalsUpdate = cloneDeep(selectedJournals)

        if (!!selectedJournalsUpdate[id]) {
            delete selectedJournalsUpdate[id]
        } else {
            selectedJournalsUpdate[id] = row
        }

        setSelectedJournals(selectedJournalsUpdate)
    }

    const handleSelectAllJournalPendingItems = (areAllSelected) => {
        const selectedJournalsUpdate = cloneDeep(selectedJournals)
        getProp(resource, 'data.list', []).forEach(item => {
            if (!areAllSelected) {
                if (!item.IsPayed) {
                    selectedJournalsUpdate[item[getPrimaryKey()]] = item
                }
            } else {
                delete selectedJournalsUpdate[item[getPrimaryKey()]]
            }
        })

        setSelectedJournals(selectedJournalsUpdate)
    }

    const handleToggleBulkPostModal = () => {
        setSelectedPreviewJournals(cloneDeep(selectedJournals))
        setBulkPostModalOpen(!bulkPostModalOpen)
    }

    const handleTogglePostPendingJournal = (item = null) => {
        setPostPendingJournalModalOpen(!postPendingJournalModalOpen)
        setSelectedItem(item)
    }

    const handleToggleUnPostPendingJournal = (item = null) => {
        setUnPostPendingJournalModalOpen(!unPostPendingJournalModalOpen)
        setSelectedItem(item)
    }

    const handleToggleReceiptModal = (item = null) => {
        setReceiptModalOpen(!receiptModalOpen)
        setSelectedItem(item)
    }

    const handleToggleTransactionsModal = (item = null) => {
        setTransactionsModalOpen(!transactionsModalOpen)
        setSelectedItem(item)
        setTransactionsQuery(DEFAULT_CRUD_STATE)
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        getData({query: getQuery(), resource: getResourceName()})
    }

    const postPendingJournal = (params) => {
        if (!params) {
            return false
        }

        const paramsValues = FieldsManager.getFieldKeyValues(params)
        paramsValues.PendingJournalEntryIDs = [paramsValues[getPrimaryKey()]]
        delete paramsValues[getPrimaryKey()]
        createData({
            params: paramsValues,
            resource: getResourceName(),
            piggyResource: getResourceName(),
            errorMessage: true,
            successMessage: translate('message.post_pending_journal_success'),
            query: getQuery()
        })
    }

    const unPostPendingJournal = () => {
        deleteData({
            params: {},
            resource: getResourceName(),
            piggyResource: getResourceName(),
            errorMessage: true,
            successMessage: translate('message.unpost_pending_journal_success'),
            query: Object.assign({PendingJournalEntryID: selectedItem.PendingJournalEntryID}, getQuery())
        })
    }

    const onSubmitBulkPendingJournals = () => {
        const params = Object.assign({},
            FieldsManager.getFieldKeyValues(bulkSelectFields),
            {
                PendingJournalEntryIDs: Object.values(cloneDeep(selectedPreviewJournals)).map(it => {
                    return it[getPrimaryKey()]
                })
            }
        )

        createData({
            params: params,
            resource: getResourceName(),
            piggyResource: getResourceName(),
            errorMessage: true,
            successMessage: translate('message.post_pending_journal_success'),
            query: getQuery()
        })
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setSelectedJournals({})
        }
    }, [resource])

    useEffect(() => {
        if (breakpoint && breakpoint.index <= 1 && getProp(queryFilterFields, "limit.value", 10) !== 10) {
            handleFilterInputChange('limit', 10);
        }
    }, [breakpoint])

    /** Render
     ================================================================= */
    const transactionsFieldRender = (item) => {
        if (!Array.isArray(item?.Transactions)) {
            return null
        }

        return (
            <div>
                {item.Transactions.map((it, i) => (
                    i < 5 && (
                        <>
                            <button
                                onClick={() => handleToggleReceiptModal(it)}
                                className="w-full my-1 inline-flex items-center justify-between px-2.5 py-0.5 border border-tm-gray-300 shadow-sm text-xs font-medium rounded text-tm-gray-700 bg-field hover:bg-tm-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                                <span>{it.Account}</span>
                                <ChevronRightIcon className={'w-2 h-2'}/>
                            </button>
                            <br/>
                        </>
                    )
                ))}
                {item.Transactions.length > 4 && (
                    <>
                        <button
                            onClick={() => handleToggleTransactionsModal(item)}
                            className="font-bold w-full my-1 inline-flex items-center justify-between px-2.5 py-0.5 border border-tm-gray-300 shadow-sm text-xs font-medium rounded text-tm-gray-700 bg-field hover:bg-tm-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            <span>Show all transactions ({item.Transactions.length})</span>
                        </button>
                        <br/>
                    </>
                )}
                <span className="font-semibold pl-2.5">Total</span> <br/>
            </div>
        )
    }

    const getDebitCreditFieldRender = (item, key) => {
        if (!Array.isArray(item?.Transactions)) {
            return null
        }

        const total = item.Transactions.reduce((memo, it) => memo + (+it[key]), 0)

        return (
            <div>
                {item.Transactions.map((it, i) => (
                    i < 5 && (
                        <>
                            <button
                                className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 border-b border-tm-gray-400 text-xs font-medium pointer-events-none"
                            >
                                <span>{genericMoneyFormatter(it[key])}</span>
                            </button>
                            <br/>
                        </>
                    )
                ))}
                {item.Transactions.length > 4 && (
                    <>
                        <EllipsisHorizontalIcon className={'w-5 h-5 m-auto'}/>
                        <br/>
                    </>
                )}
                <span className="font-bold pl-2.5">{genericMoneyFormatter(total)}</span> <br/>
            </div>
        )
    }

    const hasSelectedContact = Object.keys(selectedJournals).length

    return (
        <div>
            <div className="flex">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />
            </div>

            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFilterFields}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    isLoading={isLoading}
                />

                <ResourceTable
                    data={Array.isArray(data) ? data : []}
                    commonTable={true}
                    fields={getFields()}
                    verticalTableIsVisible={breakpoint.index <= 1}

                    translate={translate}
                    isLoading={isLoading || downloadIsLoading}


                    limit={queryFilterFields.limit.value}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    tableKey={getPrimaryKey()}
                    onSelectRow={handleSelectJournalPendingItem}
                    isSelectRowDisabled={(it) => it?.IsPayed}
                    onSelectAllClick={handleSelectAllJournalPendingItems}
                    selectedRows={selectedJournals}
                    onRowClick={(row) => handleSelectJournalPendingItem(row, row[getPrimaryKey()])}

                    disableEdit
                    disableDelete
                    disableCreate

                    actions={[
                        {
                            tooltipText: () => translate('btn.post'),
                            visible: (item) => !item?.IsPayed,
                            action: handleTogglePostPendingJournal,
                            icon: () => PlusIcon
                        },
                        {
                            tooltipText: () => translate('btn.unpost'),
                            visible: (item) => item.ExecuteCount > 0,
                            action: handleToggleUnPostPendingJournal,
                            icon: () => MinusIcon
                        }
                    ]}
                />

                {/*Table footer*/}
                <TableCardFooter
                    show={!(!data.length && !resource.isLoading)}
                >
                    <TableFooterBulkActions
                        translate={translate}
                        pagination={
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                hideRowsPerPage={breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? handleUpdateOffset(value, currentPage)
                                        : handleFilterInputChange(name, value)
                                }
                                pageOffset={query.offset}
                                queryFields={queryFilterFields}
                                translate={translate}
                            />
                        }
                    >
                        <div className={'ml-5'}>
                            <Tooltip
                                content={
                                    hasSelectedContact ? translate('text.bulk_post_pending_journals') : translate('text.select_before_bulk_post_pending_journals')
                                }
                            >
                                <span>

                                    <button
                                        className="icon-btn p-2 flex items-center -ml-2.5"
                                        disabled={!hasSelectedContact}
                                        onClick={handleToggleBulkPostModal}
                                    >
                                        <PlusIcon
                                            className={
                                                classNames(
                                                    'w-5 h-5',
                                                    hasSelectedContact ? 'text-primary' : 'text-tm-gray-300'
                                                )
                                            }
                                        />
                                    </button>
                                </span>
                            </Tooltip>
                        </div>
                    </TableFooterBulkActions>
                </TableCardFooter>

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={'No matching records found'}

                    className={'pb-12 pt-16 px-6'}
                    onClearFilterClick={handleClearFiltersClick}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters')}
                    filters={queryFilterFields}
                />
            </TableCard>

            <ModalConfirm
                title={'Undo'}
                show={!!unPostPendingJournalModalOpen}
                text={"Undo journal posting"}
                onClose={handleToggleUnPostPendingJournal}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={(params) => {
                    unPostPendingJournal(params)
                    handleToggleUnPostPendingJournal()
                }}
            />

            <ModalSaveResource
                show={postPendingJournalModalOpen}
                title={translate('text.post_pending_journal')}
                widthClass="max-w-xs"
                gridColsClass="grid-cols-1"
                canSubmit={true}
                onClose={handleTogglePostPendingJournal}
                buttonLabel={'Post'}
                fields={getPostJournalFields()}
                getRawFields
                onSubmit={(params) => {
                    postPendingJournal(params)
                    handleTogglePostPendingJournal()
                }}
                translate={translate}
            />

            <ModalDefault
                show={bulkPostModalOpen}
                widthClass={'max-w-xl'}
                limitHeight={true}
                title={translate('text.bulk_post_pending_journals')}
                buttonDisabled={!Object.keys(selectedPreviewJournals).length}
                onButtonClick={() => {
                    onSubmitBulkPendingJournals()
                    handleToggleBulkPostModal()
                }}
                buttonLabel={translate('btn.post_selected')}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={handleToggleBulkPostModal}
            >
                <div className="p-6 bg-inverse border-b border-tm-gray-200">
                    <div className="grid sm:grid-cols-3">
                        <div className="sm:col-start-2">
                            <FieldsToHtml
                                fieldsState={bulkSelectFields}
                                onInputChange={handleBulkSelectFormInputChange}
                                translate={translate}
                            />
                        </div>
                    </div>
                </div>

                <div className="min-h-[20rem]">
                    <ResourceTable
                        commonTable={true}
                        data={Object.values(selectedJournals)}
                        fields={getFields(['IsPayed', 'Description', 'Transactions', 'Debit', 'Credit'])}

                        tableKey={'PendingJournalEntryID'}
                        onSelectRow={handleSelectJournalPendingPreviewItem}
                        onSelectAllClick={handleSelectAllJournalPendingPreviewItems}
                        selectedRows={selectedPreviewJournals}
                        onRowClick={(row) => handleSelectJournalPendingPreviewItem(row, row[getPrimaryKey()])}

                        translate={translate}
                    />
                </div>
            </ModalDefault>

            <AccountReportDialog
                AccountID={selectedItem?.AccountID}
                AccountName={selectedItem?.Account}
                StartDate={queryFilterFields.StartDate?.value}
                EndDate={queryFilterFields.EndDate?.value}
                onClose={() => handleToggleReceiptModal(false)}
                show={receiptModalOpen}
                translate={translate}
                getTransactions={(query) => {
                    dispatch(getSecondResource({
                        user: LocalStorage.get('user'),
                        resource: Resources.AccountingAccountReport,
                        query: query
                    }))
                }}
                isLoading={getProp(secondResource, 'isLoading', false)}
                transactions={getProp(secondResource, 'data', [])}
                thirdResource={thirdResource}
                dialogResource={dialogResource}
                dispatch={dispatch}
            />

            <ModalDefault
                show={transactionsModalOpen}
                widthClass={'max-w-5xl'}
                title={translate('text.Transactions')}

                translate={translate}
                closeButtonLabel={translate('btn.close')}
                onClose={handleToggleTransactionsModal}
            >
                <TableCard addClass={'p-5'}>
                    <ResourceTable
                        data={getProp(selectedItem, 'Transactions', []).filter((_, i) => i < (10 + transactionsQuery.offset) && i >= transactionsQuery.offset)}
                        fields={getTransactionFields()}

                        translate={translate}

                        limit={10}

                        tfoot={{
                            Account: translate('text.total'),
                            Debit: getProp(selectedItem, 'Transactions', []).reduce((memo, it) => memo + (+it.Debit), 0),
                            Credit: getProp(selectedItem, 'Transactions', []).reduce((memo, it) => memo + (+it.Credit), 0)
                        }}
                    />

                    <div
                        className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                    >
                        <Pagination
                            count={getProp(selectedItem, 'Transactions', []).length}
                            handleQueryChange={(name, value, currentPage) => {
                                setTransactionsQuery((prevState) => ({
                                    ...prevState,
                                    offset: value,
                                    paginationPage: currentPage
                                }))
                            }}
                            pageOffset={transactionsQuery.offset}
                            pageLimit={10}
                            translate={translate}
                        />
                    </div>
                </TableCard>
            </ModalDefault>
        </div>
    )
}
export default DepreciationTab

const isPayedFieldRender = (it) => {
    return (
        it.IsPayed
            ?
            <>
                <CheckCircleIcon className="w-5 h-5 text-green-600"/>

                ({it.ExecuteCount ?? 0} of {it.EndAfter ?? 0} months)
            </>
            :
            <>
                <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                ({it.ExecuteCount ?? 0} of {it.EndAfter ?? 0} months)
            </>

    )
}
