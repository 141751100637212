import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getCustomStyles} from "../../../../data/themes";
import moment from "moment";
import {isMilitaryTimeSet} from "../../../util/util-dates";
import {scrollIntoView} from "../../../util/util-vanilla";

export default class FieldTime extends Component {
    render() {
        // let mask = [/\d/, /\d/, ":", /\d/, /\d/, " ", /a|p/, /m/];
        let timeFormat = "hh:mma";
        let placeholder = "__:__am"

        if (isMilitaryTimeSet()) {
            timeFormat = "HH:mm";
            // mask = [/\d/, /\d/, ":", /\d/, /\d/];
            placeholder = "__:__"
        }

        return (
            <React.Fragment>
                <DatePicker
                    ref={this.props.innerRef}
                    open={false}
                    autoFocus={this.props.autoFocus}
                    disabledKeyboardNavigation
                    styles={getCustomStyles()}
                    selected={moment(this.props.value).isValid() ? moment(this.props.value).toDate() : null}

                    onChange={(date) => {
                        this.props.onChange(this.props.name, date);
                    }}
                    placeholderText={placeholder}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat={timeFormat}
                    timeFormat={timeFormat}
                    className={"datepickerIcon " + this.props.addClass + (this.props.errorMessage ? " is-invalid" : "")}
                    disabled={this.props.disabled}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    // customInput={
                    //     <MaskedTextInput
                    //         type="text"
                    //         mask={mask}
                    //         showMask
                    //     />
                    // }
                />
                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold display-block"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Field required*"}
                    </div>
                )}
            </React.Fragment>
        )
    }
}

