import React, {useEffect, useState} from 'react'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DELETE_PERM,
    MonthsSimple,
    RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import {emailValid} from "../../../common/util/util-vanilla";
import {getSecondResource, updateSecondResource} from "../../../data/actions/secondResource";
import CopyToClipboardButton from "../../../common/components/buttons/copy-to-clipboard-button";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {Loader} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import ResourceTable from "../../../common/components/resource-table";

const VacationsTab = (props) => {
    const {translate, resource, secondResource, dispatch} = props

    const secondResourceIsLoading = getProp(secondResource, 'isLoading', false)
    const secondData = getProp(secondResource, 'data', {})

    /** Helpers
     ================================================================= */
    const getFields = (item = {}) => {
        let fieldTemplates = {
            Months: new Field('Months', '', ['empty'], false, 'select', {
                addContainerClass: 'col-span-3',
                label: "RestartMonth",
                render: item => MonthsSimple[item.Months]
            }, {
                omitSort: true
            }),
            Days: new Field('Days', '', ['float'], false, 'float', {
                omitSort: true,
                addContainerClass: 'col-span-3',
                label: 'DaysPerYear'
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getAdditionalFields = (item = {}) => {
        const fieldTemplates = {
            CompanyTimeOffEmailReceivers: new Field('CompanyTimeOffEmailReceivers', '', ['empty'], false, 'creatable-select-search', {
                addContainerClass: 'col-span-full',
                htmlAfterField: (it) =>
                    <div className="h-5 relative top-2 flex">
                        <CopyToClipboardButton
                            clipboardText={(it.value ? it.value : []).reduce((memo, it) => {
                                memo.push(it.label);
                                return memo;
                            }, []).join(", ")}
                            addClass="relative -top-2"
                            translate={translate}
                        />
                    </div>
            }, {
                onCreateOption: (_, value) => handleCreateEmail(value),
                isMulti: true,
                formatCreateLabel: userInput => translate('text.add_email') + ' "' + userInput + '"'
            }),
        }

        if (item && item.CompanyTimeOffEmailReceivers) {
            fieldTemplates.CompanyTimeOffEmailReceivers.value = item.CompanyTimeOffEmailReceivers.split(";").map(it => ({
                label: it,
                value: it
            }))
        }

        return fieldTemplates
    }

    const getResourceName = () => {
        return Resources.CompanyVacation
    }

    const data = getProp(resource, 'data.list', [])

    const metadata = {
        Months: MonthsSimple,
        CompanyTimeOffEmailReceivers: {
            api: 'api/' + Resources.Contacts,
            query: {
                IsSystemUser: 1,
            },
            searchMap: (item) => ({
                value: item.Email,
                label: item.Email
            })
        }
    }

    /** State
     ================================================================= */
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)

    const [additionalFields, setAdditionalFields] = useState(getAdditionalFields())
    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    const fetchAdditionalData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.TimeOffEmailReceivers
        }))
    }

    const submit = (params) => {
        if (params) {
            if (params.VacationTemplateID) {
                dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, params),
                    errorMessage: true, successMessage: translate('text.TemplateUpdated'),
                    resource: getResourceName(),
                    piggyResource: getResourceName(),
                }))
                handleToggleUpdateModal()
            } else {
                dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, params),
                    errorMessage: true, successMessage: translate('text.TemplateCreated'),
                    resource: getResourceName(),
                    piggyResource: getResourceName(),
                }))
                handleToggleCreateModal()
            }
        }
    }

    const onFormSubmit = () => {
        const validatedFields = FieldsManager.validateFields(additionalFields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            const {CompanyTimeOffEmailReceivers} = FieldsManager.getFieldKeyValues(validatedFields)
            const params = {
                CompanyTimeOffEmailReceivers: CompanyTimeOffEmailReceivers.map(it => it.value).join(';')
            }
            dispatch(updateSecondResource({
                user: LocalStorage.get('user'),
                params: params,
                errorMessage: true,
                successMessage: translate('text.TemplateUpdated'),
                resource: Resources.TimeOffEmailReceivers,
                piggyResource: Resources.TimeOffEmailReceivers
            }))
        } else {
            setAdditionalFields(validatedFields)
        }
    }

    const archiveResource = () => {
        props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {id: selectedItem.VacationTemplateID},
            errorMessage: true,
            successMessage: `Template deleted`,
            resource: getResourceName(),
            piggyResource: getResourceName()
        }))
        hideConfirmDialog()
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
        fetchAdditionalData()
    }, [])

    useEffect(() => {
        if (!secondResourceIsLoading && !!Object.keys(secondData).length) {
            setAdditionalFields(getAdditionalFields(secondData))
        }
    }, [secondResourceIsLoading])

    /** UI Events
     ================================================================= */
    const handleArchiveResource = (item) => {
        setConfirmModalOpen(true)
        setSelectedItem(item)
    }

    const hideConfirmDialog = () => {
        setConfirmModalOpen(false)
    }

    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
    }

    const handleToggleUpdateModal = (item) => {
        setUpdateModalOpen(!updateModalOpen)
        setSelectedItem(item)
    }

    const handleFormCancel = () => {
        setAdditionalFields(getAdditionalFields())
        setCanSubmit(false)
    }

    const handleInputChange = (name, value) => {
        setAdditionalFields(FieldsManager.updateField(additionalFields, name, value))
        setCanSubmit(true)
    }

    const handleCreateEmail = (value) => {
        handleInputChange('CompanyTimeOffEmailReceivers', [...getProp(additionalFields, 'CompanyTimeOffEmailReceivers.value', []), {
            value: value,
            label: value,
            invalid: !emailValid(value),
            manual: true
        }])
    }

    const addFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, additionalFields)), translate, handleInputChange, metadata);

    return (
        <React.Fragment>
            <div className="grid grid-cols-12 gap-5">
                <Card addClass={'col-span-8'}>
                    <PageHeader
                        title={translate('text.VacationDays')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={handleToggleCreateModal}
                        buttonHidden={!checkPerm(getResourceName(), CREATE_PERM)}
                    />
                    <TableCard>
                        <ResourceTable
                            data={data}

                            options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}

                            fields={getFields()}
                            translate={translate}
                            isLoading={resource.isLoading}

                            onDelete={checkPerm(getResourceName(), DELETE_PERM) ? handleArchiveResource : null}
                            onEdit={checkPerm(getResourceName(), UPDATE_PERM) ? handleToggleUpdateModal : null}
                        />
                    </TableCard>
                </Card>
                <Card addClass={'col-span-4'}>
                    {!secondResourceIsLoading && addFieldsHtml}
                    {!!secondResourceIsLoading && (
                        <div className={'relative flex justify-center items-center'}>
                            <Loader disableContainer/>
                        </div>
                    )}
                </Card>
            </div>

            <ModalSaveResource
                show={createModalOpen}
                title={translate('text.CreateNewTemplate')}
                widthClass="max-w-md"
                gridColsClass="grid-cols-3"
                onClose={handleToggleCreateModal}
                fields={getFields()}
                onSubmit={(params) => {
                    submit(params)
                }}
                translate={translate}
                metadata={metadata}
            />

            <ModalSaveResource
                show={updateModalOpen}
                title={translate('text.UpdateTemplate')}
                widthClass="max-w-md"
                gridColsClass="grid-cols-3"
                onClose={handleToggleUpdateModal}
                fields={getFields(selectedItem)}
                onSubmit={(params) => {
                    params.VacationTemplateID = selectedItem.VacationTemplateID
                    submit(params)
                }}
                translate={translate}
                metadata={metadata}
            />

            <ModalConfirm
                title={translate('text.Confirm')}
                show={!!confirmModalOpen}
                text={translate('text.ConfirmDeleteTemplate')}
                onClose={hideConfirmDialog}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={archiveResource}
            />

            <PageFooter
                translate={translate}
                canSubmit={canSubmit}
                actionCancel={handleFormCancel}
                actionSubmit={checkPerm(Resources.CompanyGeneral, UPDATE_PERM) && onFormSubmit}
            />
        </React.Fragment>
    )
}

export default VacationsTab
