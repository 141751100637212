import {useEffect, useState} from "react";
import LocalStorage from "../../util/localStorage";
import Cookie from "../../util/cookie";
import {_ENV_MODE} from "../../util/env";

export default function useTheme(newTheme) {
    const [theme, setTheme] = useState(!!newTheme ? newTheme : getTheme())

    function saveTheme(theme) {
        saveCurrentTheme(theme);
        updateDocumentClasses(theme);

        setTheme(() => theme);
    }

    useEffect(() => {
        if (!newTheme) { // Update document classes on initialization
            updateDocumentClasses(theme);
        }
    }, []);

    return [theme, saveTheme];
}

const getTheme = () => {
    const cookieTheme = Cookie.getCookie('APP_THEME_TYPE_'  + _ENV_MODE);
    const localStorageTheme = LocalStorage.get("APP_THEME_TYPE");

    if (!!cookieTheme) {
        LocalStorage.set("APP_THEME_TYPE", cookieTheme);
        return cookieTheme;
    } else if (!!localStorageTheme) {
        return localStorageTheme;
    } else {
        return getSystemTheme();
    }
}

const saveCurrentTheme = (theme) => {
    LocalStorage.set("APP_THEME_TYPE", theme);
    Cookie.setCookie(
        "APP_THEME_TYPE",
        theme,
        86400
    );
}

const updateDocumentClasses = (theme) => {
    if (theme === 'dark') { // could use toggle class but this is way more specific
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
}

function getSystemTheme() {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

    if (!!matchMedia.matches) {
        return "dark"
    }

    return "light";
}