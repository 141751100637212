import StopsShortDisplay from "../load-view/load-sections/stops-short-display";
import {getLoadSelects, getStopByFields, mergeStops} from "../load-view/load-utils";
import {MapPinIcon, PencilSquareIcon} from "@heroicons/react/20/solid";
import {MagnifyingGlassIcon, MapIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useRef, useState} from "react";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import {getDialogInfoResource} from "../../../data/actions/dialogInfoResource";
import {
    getFullAddressName,
    getHighlightedFullAddressName,
    getLookup,
    getProp,
    classNames
} from "../../../common/util/util-helpers";
import axios from "axios";
import Env from "../../../util/env";
import {getCurrentTimeSeconds, toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import {LoaderLarge, LoaderSmall} from "../../../common/components/loader";
import LocationInfo from "../../../common/components/modal/modal-global/global-location-info/location-info";
import DragAndDrop from "../../../common/components/modal/truckDialogs/createTruckLocationDialog/DragAndDrop";
import ModalTabbed from "../../../common/components/modal/modal-tabbed";
import {getJWT} from "../../../common/util/util-auth";
import {FieldsManager} from "../../../data/services/fields";
import moment from "moment";
import Tippy from "@tippyjs/react";

export default function LoadsViewStopUpdate({translate, selectedItem, isPingUpdateModalOpen, postUpdatePingLocation, setPostUpdatePingLocation, setIsPingUpdateModalOpen}) {
    const countries = useRef(getLookup('Country', 'CountryID', 'CountryName'));
    const states = useRef(getLookup('State', 'StateID', 'StateAbbreviation'));

    const dispatch = useDispatch();
    const resource = useSelector(state => state.dialogInfoResource)
    const isLoading = resource?.isLoading;

    const [isAxiosRequesting, setIsAxiosRequesting] = useState(false);
    const [isEldGPSLoading, setIsEldGPSLoading] = useState(false);
    const [pingLocationDateTime, setPingLocationDateTime] = useState(null);
    const [pingZone, setPingZone] = useState('');

    const mergedStops = mergeStops(
        getProp(resource, 'data.load/multistops', []),
        getProp(resource, 'data.load/stopby', [])
    );

    let selectedStop = mergedStops.find((it) => !!it.StopType && !it.IsStopCompleted);

    if (!selectedStop) {
        mergedStops.forEach(it => {
            if (!!it.StopType && it.IsStopCompleted) {
                selectedStop = it; // Select last stop if all are completed
            }
        })
    }

    useEffect(() => {
        setPostUpdatePingLocation({})
    }, [])

    const selectedStopID = selectedStop?.metadata?.LoadStopID;

    function fetchData() {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadInfo,
            query: {

                id: selectedItem.LoadID
            }
        }))
    }

    function submitPingUpdateLocation(locationData, tabsData) {
        const selectedTab = tabsData.find(it => it.isCurrent);

        if (selectedTab.name === "select_location") {
            setIsAxiosRequesting(true);
            setIsPingUpdateModalOpen(false);

            axios.get(Env.getApiUrl('api/load/stops/eta', {
                startLat: locationData.Latitude?.value,
                startLon: locationData.Longitude?.value,
                destLat: mergedStops.find(it => !!it.IsSelected).metadata?.Latitude,
                destLon: mergedStops.find(it => !!it.IsSelected).metadata?.Longitude
            }), {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }).then((response) => {
                setPostUpdatePingLocation({
                    address: getFullAddressName(locationData.LocationID.value.metadata),
                    LocationID: locationData.LocationID.value.value,
                    lat: locationData.Latitude?.value,
                    lng: locationData.Longitude?.value,
                    eta: response.data.data.last_detected_address + " - " +
                        "ETA " + toFrontDateTimeFromUTC(response.data.data.last_detected_eta)
                })
                setIsAxiosRequesting(false);
            });
        }

        if (selectedTab.name === "enter_manually") {
            const GooglePlacesString = [
                locationData.AddressName.value,
                locationData.CityName.value,
                states.current[locationData.StateID.value],
                locationData.PostalCode.value,
                countries.current[locationData.CountryID.value]
            ].filter(it => !!it).join(", ");

            setIsAxiosRequesting(true);
            setIsPingUpdateModalOpen(false);

            axios.get(Env.getApiUrl('api/load/stops/eta', {
                startLat: locationData.Latitude?.value,
                startLon: locationData.Longitude?.value,
                destLat: mergedStops.find(it => !!it.IsSelected).metadata?.Latitude,
                destLon: mergedStops.find(it => !!it.IsSelected).metadata?.Longitude
            }), {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }).then((response) => {

                setPostUpdatePingLocation({
                    address: GooglePlacesString,
                    lat: locationData.Latitude?.value,
                    lng: locationData.Longitude?.value,
                    eta: response.data.data.last_detected_address + " - " +
                        "ETA " + toFrontDateTimeFromUTC(response.data.data.last_detected_eta)
                });
                setIsAxiosRequesting(false);
            });
        }

        if (selectedTab.name === "select_on_map" || selectedTab.name === "take_from_eld_gps") {
            const lat = locationData.Latitude.value;
            const lng = locationData.Longitude.value;

            setIsAxiosRequesting(true);
            setIsPingUpdateModalOpen(false);

            axios.get(Env.getApiUrl('api/user/geocode', {Latitude: lat, Longitude: lng}), {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }).then((response) => {
                let address = response.data.data;

                axios.get(Env.getApiUrl('api/load/stops/eta', {
                    startLat: lat,
                    startLon: lng,
                    destLat: mergedStops.find(it => !!it.IsSelected).metadata?.Latitude,
                    destLon: mergedStops.find(it => !!it.IsSelected).metadata?.Longitude
                }), {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }).then((response) => {
                    setPostUpdatePingLocation({
                        address: address?.FormatedAddress,
                        lat: lat,
                        lng: lng,
                        eta: response.data?.data?.last_detected_address + " - " +
                            " ETA " + toFrontDateTimeFromUTC(response.data.data.last_detected_eta)
                    });
                    setIsAxiosRequesting(false);
                });
            });
        }

        const latVal = locationData.Latitude?.value ? locationData.Latitude?.value : locationData.LocationID.value.metadata?.Latitude;
        const longVal = locationData.Longitude?.value ? locationData.Longitude?.value : locationData.LocationID.value.metadata?.Longitude;

        if (latVal && longVal) {
            axios.get(Env.getApiUrl('api/user/timezone', {Latitude: latVal, Longitude: longVal, Timestamp: getCurrentTimeSeconds()}), {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }).then((response) => {
                const zone = response.status === 200 ? response.data.data.timeZoneId : null
                setPingZone(zone);
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <React.Fragment>
            <div className="relative min-h-[10rem]">
                {(isAxiosRequesting || isLoading) && (
                    <div className="inset-0 absolute flex items-center justify-center z-50 bg-tm-gray-50">
                        <div className="text-center">
                            <LoaderLarge/>
                        </div>
                    </div>
                )}

                <StopsShortDisplay
                    isLoading={isAxiosRequesting}
                    stopsCombined={mergedStops}
                    selectedStopID={selectedStopID}
                    postUpdatePingLocation={postUpdatePingLocation}
                    translate={translate}
                    pingZone={pingZone}
                    pingLocationDateTime={pingLocationDateTime}
                />
            </div>

            <ModalTabbed
                title={translate(`modal_heading.add_ping_update`) + " #" + selectedItem?.LoadNumber}
                show={isPingUpdateModalOpen}
                onClose={() => setIsPingUpdateModalOpen(false)}
                onSubmit={submitPingUpdateLocation}
                validateOnlyCurrentTab={true}
                sidebarWidth={null}
                onTabChange={(tabName, fields, updateFields, setIsStateDirty) => {
                    if (tabName === "take_from_eld_gps") {
                        setIsEldGPSLoading(true);
                        axios.get(Env.getApiUrl('api/load/stops/eta', {
                            LoadID: selectedItem.LoadID
                        }), {
                            headers: {
                                'Authorization': 'Bearer ' + getJWT().access_token
                            }
                        }).then((response) => {
                            const data = response.data.data;
                            fields.AddressName.value = data.AddressName;
                            fields.StateID.value = data.StateID;
                            fields.PostalCode.value = data.PostalCode;
                            fields.CityName.value = data.CityName;
                            fields.Latitude.value = data.Latitude;
                            fields.Longitude.value = data.Longitude;
                            updateFields(fields)

                            setIsEldGPSLoading(false);
                            if (!!data.Latitude) {
                                setPingLocationDateTime(data.LocationDate)
                                setIsStateDirty(true)
                            }
                        }).catch((e) => {
                            console.log(e);
                            setIsEldGPSLoading(false);
                        });
                    } else {
                        setPingLocationDateTime(null)
                    }
                }}
                tabFields={Object.values(getStopByFields({}, translate)).reduce((memo, it) => {
                    if ((it.name === 'AddressName') || (it.name === 'PostalCode')) {
                        it.validate = [''];
                    }
                    memo[it.name] = it;
                    return memo;
                }, {})}
                selects={getLoadSelects()}
                tabsData={{
                    "enter_manually": {
                        name: 'enter_manually',
                        icon: PencilSquareIcon,
                        containerClass: "p-6 w-full max-w-lg mx-auto grid grid-cols-12 gap-4",
                        fields: ["CountryID", "GooglePlaces", "AddressName", "CityName", "StateID", "PostalCode"],
                    },
                    "select_location": {
                        name: 'select_location',
                        icon: MagnifyingGlassIcon,
                        fields: ['LocationID'],
                        componentAfter: (fields) => {
                            return (
                                <React.Fragment>
                                    {!!fields?.LocationID?.value?.metadata && (
                                        <div
                                            className="relative px-3 py-8 text-tm-gray-700 bg-tm-gray-50">
                                            <div className="max-w-md mx-auto space-y-6">
                                                <LocationInfo
                                                    data={fields.LocationID.value.metadata}
                                                    translate={translate}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                        }
                    },
                    "select_on_map": {
                        name: 'select_on_map',
                        icon: MapIcon,
                        fields: ["Latitude", "Longitude"],
                        component: (fields, handleInputChange, updateFields, setDirty) => {
                            return (
                                <React.Fragment>
                                    <DragAndDrop
                                        className="w-full h-full relative"
                                        locations={[]}
                                        isLoading={isAxiosRequesting}
                                        loaderMessage={translate("text.fetching_data_from_google")}
                                        Latitude={fields.Latitude.value}
                                        Longitude={fields.Longitude.value}
                                        addMarker={(data) => {
                                            setDirty(true);
                                            fields.Latitude.value = data.Latitude;
                                            fields.Longitude.value = data.Longitude;
                                            updateFields(fields);
                                        }}
                                    />
                                </React.Fragment>
                            )
                        }
                    },
                    "take_from_eld_gps": {
                        name: 'take_from_eld_gps',
                        icon: MapPinIcon,
                        fields: ["Latitude", "Longitude"],
                        component: (fields, handleInputChange, updateFields, setDirty) => {
                            const Latitude = !!fields.Latitude.value
                            const LocationDate = !!Latitude ? pingLocationDateTime : "";
                            const currentDate = moment();

                            const dateDifference = !!Latitude ? moment.duration(moment(LocationDate).diff(currentDate)).asDays() : "";

                            return (
                                <React.Fragment>
                                    {!!isEldGPSLoading && !isEldGPSLoading && (
                                        <div className='flex gap-x-4 bg-primary-transparent text-primary rounded-btn'>
                                            <LoaderSmall disableContainer={true}/>
                                            Fetching latest GPS/ELD location for the load ...
                                        </div>
                                    )}
                                    <div className={"p-4"}>
                                        {!Latitude && !isEldGPSLoading && (
                                            <span>
                                                No GPS/ELD location detected for the selected load.
                                            </span>
                                        )}
                                        {!!Latitude && getHighlightedFullAddressName(FieldsManager.getFieldKeyValues(fields))}

                                        {!!Latitude && (
                                            <Tippy content={translate("text.GPS_older_than_7_days")} disabled={dateDifference > -7}>
                                                <div className={classNames("text-left", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                                                    <div>({toFrontDateTimeFromUTC(LocationDate)})</div>
                                                </div>
                                            </Tippy>
                                        )}
                                    </div>
                                </React.Fragment>
                            )
                        }
                    }
                }}
                translate={translate}
            />
        </React.Fragment>
    )
}