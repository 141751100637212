import React from 'react'
import { fieldsToHtml } from '../../util/util-fields'
import { FieldsManager } from '../../../data/services/fields'
import {getCountryCodes} from "../../util/countryCodes";
import {getLookup} from "../../util/util-helpers";


export function getPhoneFieldsKeyValues (fields) {
    return fields.map((phone, i) => {
        phone.IsPrimary.value = (i === 0) ? 1 : 0
        return FieldsManager.getFieldKeyValues(phone)
    })
}

export default function PhoneFields(props) {
    const { translate } = props

    const phoneFields = props?.phonesFields?.map((item, i) => {
        item.PhoneNumber.metadata.label = i === 0 ? `main_phone_primary` : `phone_number`

        const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, item)), translate, (name, value) => props.handleItemInputChange(i, name, value), {
            PhoneTypeID: getLookup('PhoneType'),
            AreaCode: getCountryCodes()
        })

        return (
            <React.Fragment>
                {i !== 0 && (
                    <div className="border-top text-right" style={{ height: 64 }}>
                        <button className="btn icon-button mt-1 color-primary "
                                onClick={() => props.removePhoneField(i)}>
                            <i className="simple-icon-close"/>
                        </button>
                    </div>
                )}

                <div className="grid gap-4 grid-cols-1 pb-5">
                    {itemToHtml}
                </div>
            </React.Fragment>
        )
    })

    return (
        <React.Fragment>
            {phoneFields}
        </React.Fragment>
    )
}
