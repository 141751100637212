import ModalDefault from "../../../../common/components/modal/modal-default";
import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import FieldsForm from "../../../../common/components/fields/fields-form";
import Resources from "../../../../data/services/resources";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE
} from "../../../../util/util-constants";
import {numberWithCommas, numberWithCommasToBack} from "../../../../util/util-formaters";
import {
    checkPerm,
    classNames,
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getLookup,
    getProp
} from "../../../../common/util/util-helpers";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import {LoaderLarge} from "../../../../common/components/loader";
import Button from "../../../../common/components/button";
import VendorCreditItemTable from "../vendor-credit-item-table";
import FieldCellTextNew from "../../../../common/components/fields/field-cell-text-new";
import FieldCellSelectSearch from "../../../../common/components/fields/field-cell-select-search";
import FieldCellMoney from "../../../../common/components/fields/field-cell-money";
import {dirtyClone, genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import {EyeIcon} from "@heroicons/react/24/outline";
import Dropzone from "react-dropzone";
import FileList from "../../../../common/components/dropzone/file-list";
import {useSelector} from "react-redux";
import FileViewer from "../../../../common/components/file-viewer/components";
import Env from "../../../../util/env";
import {getJWT} from "../../../../common/util/util-auth";
import {currentDate} from "../../../../common/util/util-dates";

export default function VendorCreditModal({
                                              show,
                                              onClose,
                                              isLoading,
                                              selectedItem,
                                              itemList,
                                              fetchOnMount,
                                              onSubmitEdit,
                                              onSubmitCreate,
                                              translate,
                                              dispatch,
                                              isReadOnly
                                          }) {
    // const dispatch = useDispatch();
    const isEditMode = !!selectedItem;
    const [fields, setFields] = useState(null);
    const [items, setItems] = useState(itemList)
    const [files, setFiles] = useState([])
    const [dropzoneActive, setDropzoneActive] = useState(itemList)
    const [filePreview, setFilePreview] = useState(false)
    const [attachedDocument, setAttachedDocument] = useState(null)

    let totalAmount = (items ?? []).reduce((memo, it) => {
        memo = memo + numberWithCommasToBack(it.TotalAmount.value)
        return memo
    }, 0);

    const dialogResource = useSelector(state => state.dialogResource);

    const filesList = files.map((item, i) => {
        return (
            <span key={i}>{item.name}</span>
        )
    })

    const haveDocument = getProp(dialogResource, 'data.AttachPath', null);

    function handleSubmitButtonClick() {
        const validatedFields = FieldsManager.validateFields(fields);
        let validatedItems = [...items];
        let itemsAreValid = true;

        if (items?.length) {
            validatedItems = validatedItems.map(item => {
                const validatedItem = FieldsManager.validateFields(item);
                if (!FieldsManager.checkFieldsForErrors(validatedItem)) {
                    itemsAreValid = false;
                }

                return validatedItem;
            })
        }

        if (FieldsManager.checkFieldsForErrors(validatedFields) && itemsAreValid) {
            const params = FieldsManager.getFieldKeyValues(fields);

            params.Items = (items ?? []).map(it => {
                return FieldsManager.getFieldKeyValues(it);
            });

            params.Amount = totalAmount;

            if (isEditMode) {
                params.VendorCreditID = selectedItem.VendorCreditID
                if (files) {
                    params.id = selectedItem.VendorCreditID
                }

                onSubmitEdit(params, files);
            } else {
                onSubmitCreate(params, files);
            }
        } else {
            setFields(validatedFields);
            setItems(validatedItems);
        }
    }

    function handleInputChange(name, value) {
        let fieldsUpdate = Object.assign({}, fields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);

        if ("PayTo" === name) {
            fieldsUpdate.ContactID.type = value === "1" ? "hidden" : "select-search";
            fieldsUpdate.ContactID.validate = value === "1" ? [''] : ['required'];
            fieldsUpdate.ContactID.value = '';
            fieldsUpdate.OrganizationID.type = value !== "1" ? "hidden" : "select-search"
            fieldsUpdate.OrganizationID.validate = value !== "1" ? [''] : ['required'];
            fieldsUpdate.OrganizationID.value = '';
        }

        fieldsUpdate[name].errorMessage = '';

        setFields(fieldsUpdate);
    }

    function handleAddInlineClick() {
        const itemsUpdate = [...items];
        itemsUpdate.push(getExpenseItemField())
        setItems(itemsUpdate);
    }

    function handleEditExpenseItem(item, index) {
        const itemsUpdate = [...items];

        itemsUpdate[index] = item;
        setItems(itemsUpdate);
    }

    function addNewExpenseItem(item) {
        const itemsUpdate = [...items];
        itemsUpdate.push(item);
        setItems(itemsUpdate);
    }

    function removeItem(index) {
        const itemsUpdate = [...items];

        itemsUpdate.splice(index, 1);
        setItems(itemsUpdate);
    }

    function handleItemInputChange(name, value, item) {
        const itemsUpdate = [...items];

        itemsUpdate[item.index][name].value = value;
        itemsUpdate[item.index][name].errorMessage = [];

        if ("Amount" === name || "Qty" === name) {
            itemsUpdate[item.index]["TotalAmount"].value
                = Number(itemsUpdate[item.index]["Amount"].value) * Number(itemsUpdate[item.index]["Qty"].value);
        }

        setItems(itemsUpdate);
    }

    const handleToggleViewModal = (attached) => {
        setFilePreview(!filePreview);
        setAttachedDocument(attached);
    }

    const onDrop = (acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }

    const onDragLeave = () => {
        setDropzoneActive(false)
    }

    const onDropAccepted = () => {
        setDropzoneActive(false)
    }

    const removeDocument = () => {
        setFiles([])
    }

    const renderCells = {
        Description: (item) => {
            return <FieldCellTextNew
                name="Description"
                value={item?.['Description']}
                onChange={(name, value) => handleItemInputChange(name, value, item)}
                autoFocus={!item.Description}
                disabled={isReadOnly}
                errorMessage={items?.[item.index]?.['Description']?.errorMessage}
            />
        },
        AccountID: (item) => <FieldCellSelectSearch
            name="AccountID"
            value={{value: item?.['AccountID'], label: item?.['Account']}}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            placeholder={translate("text.no_account_selected")}
            isClearable={true}
            disabled={isReadOnly}
            select={getFieldsSelects()["AccountID"]}
            errorMessage={items?.[item.index]?.['AccountID']?.errorMessage}
        />,
        Amount: (item) => <FieldCellMoney
            name="Amount"
            value={item?.['Amount']}
            disabled={isReadOnly}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            errorMessage={items?.[item.index]?.['Amount']?.errorMessage}
        />,
        Qty: (item) => <FieldCellTextNew
            align="right"
            type="integer"
            name="Qty"
            value={item?.['Qty']}
            disabled={isReadOnly}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            errorMessage={items?.[item.index]?.['Qty']?.errorMessage}
        />
    }

    function getFields(item = {}) {
        let fieldTemplates = {
            OrganizationID: new Field('OrganizationID', "", ['required'], isReadOnly, 'select-search', {addContainerClass: "col-span-6"}),
            Date: new Field('Date', currentDate(), ['required'], isReadOnly, 'date', {addContainerClass: "col-span-6"}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['required'], isReadOnly, 'select-search', {addContainerClass: "col-start-1 col-span-6"}),
            ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['required'], isReadOnly, 'select-search', {
                addContainerClass: "col-span-6",
                label: 'ContactDepartments'
            }),
            Description: new Field('Description', "", [''], isReadOnly, 'textarea', {
                heightClass: "!h-[11.25rem]",
                addContainerClass: "col-span-6"
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getExpenseItemField(item = {}) {

        const fieldTemplates = {
            ItemNumber: new Field('ItemNumber', '', [''], false, 'hidden', {
                minWidth: "75",
                render: (it) => <div className="text-right">{it.index + 1}</div>
            }),
            ProductServiceID: new Field('ProductServiceID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: 'choose_from_product_template',
                hideTable: true
            }, {isClearable: true}),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                minWidth: "168",
                addContainerClass: 'col-span-full'
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                minWidth: "300",
                addContainerClass: 'col-span-full'
            }, {}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                minWidth: "150",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {
                isNegativeAllowed: true
            }),
            Qty: new Field('Qty', 1, ['empty'], false, 'float', {
                minWidth: "100",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {min: 1}),
            TotalAmount: new Field('TotalAmount', '', [], false, 'float', {
                render: (item) => (
                    <div className="text-right mr-7">{genericMoneyFormatter(item.TotalAmount)}</div>
                )
            }),        }

        if (Object.keys(item).length > 0) {
            return fillFieldsFromData(fieldTemplates, item);
        }

        return fieldTemplates;
    }

    function getFieldsSelects() {
        return {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName + " - " + (item.AddressName ?? "")
                        + ", " + (item.CityName ?? "")
                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                        + (item.PostalCode ?? ""),
                    value: item.OrganizationID
                })
            },
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                    value: it.ContactID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }
    }

    useEffect(() => {
        if (show) {
            setFields(getFields(selectedItem ?? null));
            setFiles([]);
            setAttachedDocument(null);
        }
    }, [show, selectedItem]);

    useEffect(() => {
        if (!isEditMode && show) {
            setItems([]);
        }

        if (isEditMode && fetchOnMount && !!show) {
            fetchOnMount();
        }
    }, [show]);

    useEffect(() => {
        if (!isLoading && itemList && isEditMode) {
            setItems(itemList.map(it => {
                return getExpenseItemField(it);
            }))
        }
    }, [itemList]);

    return (
        <ModalDefault
            show={show}
            title={isEditMode
                ? (isReadOnly ? translate("text.VendorCreditDetails") : translate('text.EditVendorCredit')) : translate('text.create_vendor_credit')}
            widthClass={'max-w-[100rem]'}
            buttonLabel={translate('btn.save')}
            onButtonClick={handleSubmitButtonClick}
            buttonDisabled={(items ?? []).length === 0 || !checkPerm(Resources.ExpenseRecurring, CREATE_PERM) || isReadOnly}
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
            translate={translate}
        >
            <div>
                {isLoading && (
                    <LoaderLarge/>
                )}

                <div className="p-6 grid grid-cols-12 gap-6">
                    {/* Left side */}
                    <div className="gap-4 col-span-7 grid grid-cols-12">
                        <FieldsForm
                            fieldsState={fields}
                            onChange={handleInputChange}
                            isLoading={isLoading}
                            selects={getFieldsSelects()}
                            translate={translate}
                        />
                    </div>

                    {/* Right side */}
                    <div className="col-span-5">
                        <div className="mb-4">
                            <TotalAmount
                                totalAmount={totalAmount}
                                translate={translate}
                            />
                        </div>

                       <div className="col-span-full pb-8">
                            {haveDocument && !!isEditMode && (
                                <div className={"flex items-center"}>

                                    <button onClick={() => {
                                        handleToggleViewModal(false)
                                    }}>
                                        <EyeIcon className={'w-5 h-5'}/>
                                    </button>


                                    <div
                                        className={classNames(files[0]?.name ? 'line-through' : undefined, 'font-bold ml-2 mt-0.5')}>{translate('text.current_document')}</div>

                                    {/*<button*/}
                                    {/*    onClick={this.removeAttachment}*/}
                                    {/*    className="btn-text ml-4"*/}
                                    {/*>( Remove )</button>*/}
                                </div>
                            )}

                           {!isReadOnly && (
                               <div>
                                   <label
                                       className="font-bold m-1">{haveDocument ? translate('text.replace_document') : translate('text.upload_attachment')}</label>

                                   <Dropzone
                                       onDrop={(acceptedFiles) => {
                                           onDropAccepted(acceptedFiles)
                                       }}
                                       // onDragEnter={this.onDragEnter}
                                       onDragLeave={onDragLeave}
                                       onDropAccepted={onDrop}
                                       maxFiles={1}
                                       accept={'image/jpg,image/jpeg, image/png, .pdf'}
                                       maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                       multiple={false}
                                   >
                                       {({getRootProps, getInputProps}) => (
                                           <section>
                                               <div {...getRootProps()}
                                                    className={
                                                        'px-6 py-10 rounded-card hover:border-primary border border-dashed border-tm-gray-400 mb-5 text-center text-break flex items-center justify-center' + (dropzoneActive ? ' active' : '')}>
                                                   <input {...getInputProps()} />
                                                   <div>
                                                       <p>
                                                           <em>{translate('field.image_types_and_pdf')}</em>
                                                       </p>

                                                       <em>{filesList.length !== 0 ? filesList : translate('text.no_file_selected')}</em>
                                                   </div>
                                               </div>
                                           </section>
                                       )}
                                   </Dropzone>
                               </div>
                           )}


                           {!!filesList.length && (
                               <FileList
                                   onFileView={() => handleToggleViewModal(true)}
                                   files={files}
                                   onFileDelete={(file, i) => removeDocument(i)}
                               />
                           )}
                       </div>

                    </div>
                </div>

                <div className="bg-inverse mt-6 p-6">
                    <div
                        className="col-12 bg-tm-gray-100 py-5 rounded-card shadow-card border border-tm-gray-300 relative"
                    >
                        {isLoading && (
                            <LoaderLarge/>
                        )}

                        <VendorCreditItemTable
                            handleSelectFirstElement={() => null}
                            getFields={getExpenseItemField}
                            // addBtnRef={this.addBtnRef}
                            renderCells={renderCells}
                            items={(items ?? [])}
                            setItems={setItems}
                            onRemoveItem={removeItem}
                            translate={translate}
                            onAddNewItem={addNewExpenseItem}
                            onEditItem={handleEditExpenseItem}
                            isIncome={false} // true
                            dispatch={dispatch}
                            hideAddInlineItemButton={true}
                            isReadOnly={isReadOnly}
                        />

                        {!!items.length && (
                            <div
                                className="text-right my-5 mx-6"
                            >
                                <Button
                                    disabled={isReadOnly}
                                    onClick={handleAddInlineClick}
                                >
                                    Add and edit inline
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                <ModalDefault
                    show={!!filePreview}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    title={translate('modal_heading.preview_document')}

                    close={handleToggleViewModal}
                    closeButtonLabel={translate('Close')}
                    onClose={handleToggleViewModal}
                >
                    {!!files[0] && !!attachedDocument && (
                        <FileViewer
                            fileType={files[0]?.name.split('.').pop()}
                            filePath={files[0]?.preview}
                            onError={(e) => {
                                console.log(e)
                            }}/>
                    )}

                    {!!haveDocument && !attachedDocument && (
                        <FileViewer
                            fileType={haveDocument.split('.').pop()}
                            filePath={Env.getApiUrl("api/" + (Resources.ExpenseVendorCreditDocuments), {
                                VendorCreditID: selectedItem?.VendorCreditID,
                                token: getJWT().access_token
                            })}
                            onError={(e) => {
                                console.log(e)
                            }}/>
                    )}
                </ModalDefault>
            </div>
        </ModalDefault>
    )
}


function TotalAmount({totalAmount, translate}) {
    return <div className="text-right col-start-9 col-span-4">
        <div className="font-bold">
            <div
                className="text-lg">{translate('field.total_amount')}</div>
        </div>

        <div className="text-lg">{numberWithCommas(totalAmount)}</div>
    </div>
}