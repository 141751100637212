import {Popover, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import Tippy from "@tippyjs/react";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import {classNames} from "../../util/util-helpers";

export default function PopOver(props) {
    const {
        className,
        widthClass,
        positionClass,
        btnLabel,
        btnLabelClass,
        btnClass,
        BtnIcon,
        btnIconClass,
        children,
        tippy,
        chevronIcon,
        customIcon,
        btnCustomHtml,
        onOpen,
        chevronIconClass,
        PopoverButtonRef
    } = props;
    const defaultWidth = "max-w-xl";
    const defaultPosition = "absolute -translate-x-1/2 left-1/2" // change to middle, left, right

    return (

        <Popover className={className ? className : "relative"}>
            {({open}) => {
                if (open) {
                    onOpen && onOpen();
                }

                return (
                    <>
                        <Tippy
                            content={tippy}
                            disabled={!tippy}
                            delay={[400, 0]}
                            trigger={"mouseenter"}
                        >
                            <Popover.Button
                                ref={PopoverButtonRef}
                                className={classNames(open ? '' : 'text-opacity-90', btnClass ? btnClass : "text-white group bg-orange-700 px-3 py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75")}
                            >
                                {!!BtnIcon && (
                                    <BtnIcon
                                        className={classNames(open ? '' : 'text-opacity-70', btnIconClass ? btnIconClass : "ml-2 h-5 w-5 text-tm-gray-700 group-hover:text-opacity-80 transition ease-in-out duration-150")}
                                        aria-hidden="true"
                                    />
                                )}

                                {!!btnLabel && (
                                    <span className={btnLabelClass}>{btnLabel}</span>
                                )}

                                {!!chevronIcon && (
                                    <ChevronDownIcon
                                        className={chevronIconClass ?? "ml-2 h-4 w-4 text-tm-gray-700 group-hover:text-opacity-80 transition ease-in-out duration-150"}/>
                                )}

                                {customIcon}

                                {btnCustomHtml}
                            </Popover.Button>
                        </Tippy>

                        {/*TODO: Add overlay option*/}
                        {/*<Popover.Overlay className="fixed inset-0 bg-black opacity-30" />*/}

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className={classNames("z-50 px-4 mt-3 transform sm:px-0 w-screen", widthClass ? widthClass : defaultWidth, positionClass ? positionClass : defaultPosition)}>
                                <div
                                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    {children}
                                </div>
                            </Popover.Panel>
                        </Transition>

                    </>
                )
            }}
        </Popover>
    )
}
