export function getDocumentResource(data) {
    return {
        type: 'GET_DOCUMENT_RESOURCE',
        data: data
    };
}

export function doneGetDocumentResource(data) {
    return {
        type: 'DONE_GET_DOCUMENT_RESOURCE',
        data: data
    };
}

export function errorGetDocumentResource(data) {
    return {
        type: 'ERROR_GET_DOCUMENT_RESOURCE',
        data: data
    };
}

export function createDocumentResource(data) {
    return {
        type: 'CREATE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function doneCreateDocumentResource(data) {
    return {
        type: 'DONE_CREATE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function errorCreateDocumentResource(data) {
    return {
        type: 'ERROR_CREATE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function deleteDocumentResource(data) {
    return {
        type: 'DELETE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function doneDeleteDocumentResource(data) {
    return {
        type: 'DONE_DELETE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function errorDeleteDocumentResource(data) {
    return {
        type: 'ERROR_DELETE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function updateDocumentResource(data) {
    return {
        type: 'UPDATE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function doneUpdateDocumentResource(data) {
    return {
        type: 'DONE_UPDATE_DOCUMENT_RESOURCE',
        data: data
    };
}

export function errorUpdateDocumentResource(data) {
    return {
        type: 'ERROR_UPDATE_DOCUMENT_RESOURCE',
        data: data
    };
}