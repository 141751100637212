import Image from "./index";
import Resources from "../../../data/services/resources";
import React from "react";
import { classNames } from "../../util/util-helpers";
import { getSizeClass } from "./image-utils";

export default function ImageContactAvatar({ContactID, size, hasRing, onError, onLoad}) {
    return (
        <Image
            className={
                classNames(
                    "max-w-none rounded-full",
                    getSizeClass(size),
                    hasRing ? "ring-2 ring-inverse" : undefined
                )
            }
            resourcePath={Resources.ContactImage}
            params={{ContactID: ContactID}}
            onError={onError}
            onLoad={onLoad}
        />
    )
}

