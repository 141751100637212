import React from 'react'
import {getCountryCodes} from "../../../common/util/countryCodes";
import {fieldsToHtml, includeFields} from "../../../common/util/util-fields";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import {getLookup} from "../../../common/util/util-helpers";
import Card from "../../../common/components/card";
import Button from "../../../common/components/button";

const InfoTab = ({
                     fields_info,
                     translate,
                     handleInputChange,
                     onCopyFromPhysicalAddressClick,
                     onCopyFromMailingAddressClick
                 }) => {

    const getFieldsToHtmlForIncludedFields = (arr = []) => {
        const selects = {
            AreaCode: getCountryCodes(),
            BankAccountTypeID: {
                1: "Business Savings",
                2: "Business Checking",
                3: "Personal Checking",
                4: "Personal Savings",
            },
            NaicsID: getLookup('Naics'),
            PhysicalCountryID: getLookup('Country'),
            PhysicalStateID: getLookup('State')
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(fields_info, arr)))), translate, handleInputChange, selects)
    }

    const fieldsHtmlBasicInfo = getFieldsToHtmlForIncludedFields(['LegalName', 'DBAName', 'ContactGroupID', 'OfficeID', 'UserDefinedField1', 'UserDefinedField2', 'Notes']);
    const fieldsHtmlAddresses = getFieldsToHtmlForIncludedFields(['CountryID', 'GooglePlaces', 'AddressName', 'AddressName2', 'CityName', 'StateID', 'PostalCode']);
    const fieldsHtmlPhysicalAddresses = getFieldsToHtmlForIncludedFields(['PhysicalCountryID', 'PhysicalGooglePlaces', 'PhysicalAddressName', 'PhysicalAddressName2', 'PhysicalCityName', 'PhysicalStateID', 'PhysicalPostalCode']);
    const fieldsHtmlOrganizationInfo = getFieldsToHtmlForIncludedFields(['AreaCode', 'PhoneNumber', 'PhoneExtension', 'PrimaryEmail', 'SCAC', 'OrganizationEntityTypeID', 'MCNumber', 'FFNumber', 'USDOTNumber', 'FederalID', 'NaicsID', 'SendPromotionalEmails'])

    return (
        <div className="grid lg:grid-cols-2 3xl:grid-cols-3 gap-4">
            <div className="">
                <Card addClass={'h-full pb-4'}>
                    <div className="h-9 flex items-center">
                        <CardSubTitle
                            text={translate('text.BasicInfo')}
                        />
                    </div>

                    <div className="grid grid-cols-12 gap-5">
                        {fieldsHtmlBasicInfo}
                    </div>
                </Card>
            </div>

            <div className="">
                <Card addClass={'h-full pb-4'}>
                    <div className="h-9 flex items-center">
                        <CardSubTitle
                            text={translate('text.OrganizationInfo')}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-5">
                        {fieldsHtmlOrganizationInfo}
                    </div>
                </Card>
            </div>

            <div className="">
                <Card addClass={'h-full pb-4'}>
                    <div className="flex items-center justify-between">
                        <CardSubTitle
                            text={translate('text.MailingAddress')}
                        />

                        <Button onClick={onCopyFromPhysicalAddressClick}>
                            {translate('text.copyPhysicalAddress')}
                        </Button>
                    </div>

                    <div className="grid grid-cols-12 gap-5">
                        {fieldsHtmlAddresses}
                    </div>
                </Card>
            </div>


            <div className="">
                <Card addClass={'h-full pb-4'}>
                    <div className="flex items-center justify-between">
                        <CardSubTitle
                            text={translate('text.PhysicalAddress')}
                        />

                        <Button onClick={onCopyFromMailingAddressClick}>
                            {translate('text.copyMailingAddress')}
                        </Button>
                    </div>

                    <div className="grid grid-cols-12 gap-5">
                        {fieldsHtmlPhysicalAddresses}
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default InfoTab
