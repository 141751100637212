import {checkPerm, classNames} from "../../../../../common/util/util-helpers";
import Resources from "../../../../../data/services/resources";
import {READ_PERM} from "../../../../../util/util-constants";
import {showGlobalModal} from "../../../../../data/actions/ui";
import MapPinIcon from "@heroicons/react/20/solid/MapPinIcon";
import {numberWithCommas} from "../../../../../util/util-formaters";
import React from "react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import Tippy from "@tippyjs/react";

export default function TotalMilesDisplay({
                                              TotalMiles,
                                              TotalMilesNoStopBy,
                                              dispatch,
                                              disableTotalMilesClick,
                                              needsUpdate,
                                              loadedMilesText,
                                              translate
                                          }) {
    if (!TotalMiles) return null;

    return (
        <div>
            <Tippy
                disabled={!needsUpdate}
                content={
                    <div>
                        <p>
                            {translate("text.stops_updated")}
                        </p>
                        <p>
                            {translate("text.miles_calculation_needs_update")}
                        </p>
                    </div>
                }>
                <button
                    className={classNames(
                        "border-0 flex items-center gap-2 group",
                        checkPerm(Resources.LoadMileage, READ_PERM) && !disableTotalMilesClick ? "text-primary hover:text-tm-gray-700" : "cursor-default text-tm-gray-700"
                    )}
                    onClick={() =>
                        checkPerm(Resources.LoadMileage, READ_PERM)
                        && !disableTotalMilesClick
                        && dispatch(showGlobalModal('StateMileageDialog')
                        )
                    }
                >
                    {!!needsUpdate && (
                        <ExclamationTriangleIcon
                            className={
                                classNames(
                                    "w-5 h-5 text-yellow-600",
                                    !disableTotalMilesClick ? "group-focus:ring-2 ring-offset-2 rounded-full" : undefined
                                )
                            }
                        />
                    )}

                    {!needsUpdate && (
                        <MapPinIcon
                            className={
                                classNames(
                                    "w-5 h-5 rounded-full",
                                    !disableTotalMilesClick ? "group-focus:ring-2 ring-offset-2" : undefined
                                )
                            }/>
                    )}

                    <div className="flex flex-col justify-start text-left leading-5">
                        <div className="leading-4">
                            {loadedMilesText ?? translate("text.LoadedMiles")}
                        </div>

                        <div className={
                            classNames(
                                needsUpdate ? "text-yellow-600" : undefined
                            )}
                        >
                            <span className="font-bold">{numberWithCommas(TotalMiles)}</span>

                            {TotalMilesNoStopBy && (
                                <span className="ml-1.5">( {TotalMilesNoStopBy} )</span>
                            )}
                        </div>
                    </div>
                </button>
            </Tippy>
        </div>
    )
}