export function getAccountingInfoResource(data) {
    return {
        type: 'GET_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function doneGetAccountingInfoResource(data) {
    return {
        type: 'DONE_GET_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function errorGetAccountingInfoResource(data) {
    return {
        type: 'ERROR_GET_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function createAccountingInfoResource(data) {
    return {
        type: 'CREATE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function doneCreateAccountingInfoResource(data) {
    return {
        type: 'DONE_CREATE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function errorCreateAccountingInfoResource(data) {
    return {
        type: 'ERROR_CREATE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function deleteAccountingInfoResource(data) {
    return {
        type: 'DELETE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function doneDeleteAccountingInfoResource(data) {
    return {
        type: 'DONE_DELETE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function errorDeleteAccountingInfoResource(data) {
    return {
        type: 'ERROR_DELETE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function resetAccountingInfoResource() {
    return {
        type: 'RESET_ACCOUNTING_INFO_RESOURCE',
        data: []
    }
}

export function updateAccountingInfoResource(data) {
    return {
        type: 'UPDATE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function doneUpdateAccountingInfoResource(data) {
    return {
        type: 'DONE_UPDATE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}

export function errorUpdateAccountingInfoResource(data) {
    return {
        type: 'ERROR_UPDATE_ACCOUNTING_INFO_RESOURCE',
        data: data
    };
}