import React from "react";
import {classNames} from "../../../../util/util-helpers";

export default function DescriptionListLeftAligned({
                                                       title,
                                                       subtitle,
                                                       hasPadding, // when in cards
                                                       isStriped,
                                                       fields,
                                                       data,
                                                       className,
                                                       translate
                                                   }) {
    const rows = Object.values(fields);

    function processData(data, row) {
        switch (row.type) {
            case "custom":
                return row.metadata.render(data, row);
            default:
                return data[row.name]
        }
    }

    return (
        <div className={className}>
            {(title || subtitle) && (
                <div
                    className={
                        classNames(
                            "px-4",
                            hasPadding ? "sm:px-6" : "sm:px-0"
                        )
                    }
                >
                    {title && (
                        <h3 className="text-base/7 font-semibold text-tm-gray-900">{title}</h3>
                    )}

                    {subtitle && (
                        <p className="mt-1 max-w-2xl text-sm/6 text-tm-gray-500">{subtitle}</p>
                    )}
                </div>
            )}

            <div className="mt-6 border-t border-tm-gray-200">
                <dl className="divide-y divide-tm-gray-200">
                    {rows.map((row, index) => {
                        const rowLabel = row?.metadata?.label ? translate("field." + row?.metadata?.label) : translate("field." + row.name);
                        return <div
                            key={row.name}
                            className={
                                classNames(
                                    "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4",
                                    isStriped && index % 2 === 0 ? "bg-tm-gray-50" : undefined,
                                    hasPadding ? "sm:px-6" : "sm:px-0"
                                )
                            }
                        >
                            <dt className="text-sm/6 font-medium text-tm-gray-900">{rowLabel}</dt>
                            <dd className="mt-1 text-sm/6 text-tm-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre">
                                {processData(data, row)}
                            </dd>
                        </div>

                    })}
                </dl>
            </div>
        </div>
    )
}