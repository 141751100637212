import React from 'react';
import LoadEventItem from './event-item';
import moment from 'moment';
import { fromUtcToTz, getUserDateTimeFormat, getUserTimeFormat, getUserTimezone } from '../../../../common/util/util-dates';
import { classNames } from '../../../../common/util/util-helpers';

export default function LoadsEventsList({events, isLoading, handleShareEventClick, timezone, translate}) {
    let backgroundClass = 'bg-tm-gray-50';
    let avatarRingClass = 'ring-tm-gray-50';
    let day = -1;
    let dateBadge;

    return (<ul className="bg-inverse relative">
        {events.map((event, index) => {
            const tz = timezone ?? getUserTimezone();
            const date = fromUtcToTz(event.EventDate, tz);
            const time = moment(date, getUserDateTimeFormat()).format(getUserTimeFormat());
           
            let currentDay = date?.split(" ")?.[0];
            let isSameNextDay = date.split(" ")?.[0] === fromUtcToTz(events?.[index + 1]?.EventDate, tz)?.split(" ")?.[0];
            let currentDayName = moment(currentDay, getUserDateTimeFormat()?.split(" ")?.[0]).format('dddd');

            if (day !== currentDay) {
                day = currentDay;
                backgroundClass = backgroundClass === 'bg-tm-gray-50' ? 'bg-inverse' : 'bg-tm-gray-50';
                avatarRingClass = backgroundClass === 'bg-tm-gray-50' ? 'ring-tm-gray-50' : 'ring-inverse';
                dateBadge = <li className={classNames('pl-4 text-lg pt-4 flex sticky top-0 z-10 border-b border-dashed border-tm-gray-200 pb-2', backgroundClass)}>
                    {currentDayName}, {currentDay}
                </li>
            } else {
                dateBadge = undefined;
            }

            return (
                <>
                    {dateBadge}

                    <LoadEventItem
                        key={index}
                        bgClass={backgroundClass}
                        isLastInSection={!isSameNextDay}
                        event={event}
                        eventDate={date}
                        eventTime={time}
                        avatarRingClass={avatarRingClass}
                        onShareEventClick={handleShareEventClick}
                        isLoading={isLoading}
                        translate={translate}
                    />
                </>
            )
        })}
    </ul>)
}
