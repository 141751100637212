export function getTaskResource(data) {
    return {
        type: 'GET_TASK_RESOURCE',
        data: data
    };
}

export function doneGetTaskResource(data) {
    return {
        type: 'DONE_GET_TASK_RESOURCE',
        data: data
    };
}

export function errorGetTaskResource(data) {
    return {
        type: 'ERROR_GET_TASK_RESOURCE',
        data: data
    };
}

export function createTaskResource(data) {
    return {
        type: 'CREATE_TASK_RESOURCE',
        data: data
    };
}

export function doneCreateTaskResource(data) {
    return {
        type: 'DONE_CREATE_TASK_RESOURCE',
        data: data
    };
}

export function errorCreateTaskResource(data) {
    return {
        type: 'ERROR_CREATE_TASK_RESOURCE',
        data: data
    };
}


export function updateTaskResource(data) {
    return {
        type: 'UPDATE_TASK_RESOURCE',
        data: data
    };
}

export function doneUpdateTaskResource(data) {
    return {
        type: 'DONE_UPDATE_TASK_RESOURCE',
        data: data
    };
}

export function errorUpdateTaskResource(data) {
    return {
        type: 'ERROR_UPDATE_TASK_RESOURCE',
        data: data
    };
}

export function getGrabTaskResource(data) {
    return {
        type: 'GRAB_TASK_RESOURCE',
        data: data
    };
}

export function doneGrabTaskResource(data) {
    return {
        type: 'DONE_GRAB_TASK_RESOURCE',
        data: data
    };
}

export function errorGrabTaskResource(data) {
    return {
        type: 'ERROR_GRAB_TASK_RESOURCE',
        data: data
    };
}

export function getCountTaskResource(data) {
    return {
        type: 'COUNT_TASK_RESOURCE',
        data: data
    };
}

export function doneCountTaskResource(data) {
    return {
        type: 'DONE_COUNT_TASK_RESOURCE',
        data: data
    };
}

export function errorCountTaskResource(data) {
    return {
        type: 'ERROR_COUNT_TASK_RESOURCE',
        data: data
    };
}
