import {
    RENT_STATUS_TYPE_TIPPY_TEXT,
    RENT_STATUS_AVAILABLE,
    RENT_STATUS_RESERVED,
    RENT_STATUS_RENTED,
    RENT_STATUS_OUT_OF_SERVICE,
    RENT_STATUS_RETIRED,
    RENT_STATUS_SOLD,
    RENT_STATUS_TYPES,
    UPDATE_PERM
} from "../../../../common/util/util-consts";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import React from "react";
import {checkPerm} from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import Badge from "../../../../common/components/badge";

export default function TrucksTableBadgeRental({item, itemType = 'Truck', translate}) {
    const rentStatusID = item?.RentStatusID
    
    if (!item || !rentStatusID) {
        return null;
    }
    
    const canFollowLink = checkPerm(Resources.RentalInfo, UPDATE_PERM) && (rentStatusID === RENT_STATUS_RESERVED || rentStatusID === RENT_STATUS_RENTED)

    return (
        <Tippy
            disabled={!rentStatusID}
            content={<span>{`${itemType} ${translate(RENT_STATUS_TYPE_TIPPY_TEXT[rentStatusID])}`}</span>}
        >
            <div>
                <Badge
                    onClick={(e) => {
                        e.stopPropagation();
                        if (canFollowLink) {
                            window.open(`${window.location.origin}/rentals/${item.RentalID}`, '_blank');
                        }
                    }}
                    type={getBadgeClass(rentStatusID)}>
                    <div className="flex">
                        {RENT_STATUS_TYPES[rentStatusID]}
                        {canFollowLink && (rentStatusID === RENT_STATUS_RESERVED || rentStatusID === RENT_STATUS_RENTED) && (
                            <ArrowTopRightOnSquareIcon
                                className={'w-5 h-5 ml-4'}
                            />
                        )}
                    </div>
                </Badge>
            </div>
        </Tippy>
    )
}

const getBadgeClass = (type) => {
    switch (type) {
        case RENT_STATUS_AVAILABLE:
            return "success"
        case RENT_STATUS_RESERVED:
        case RENT_STATUS_RENTED:
            return "primary"
        case RENT_STATUS_SOLD:
        case RENT_STATUS_RETIRED:
            return "danger"
        case RENT_STATUS_OUT_OF_SERVICE:
            return "warning"
        default:
            return "danger"
    }
}
