import React from 'react'
import {fieldsToHtml, includeFields} from "../../../../util/util-fields";
import CardSubTitle from "../../../../components/card/card-sub-title";
import {getCountryCodes} from "../../../../util/countryCodes";
import {handleMaskedFocus} from "../../../../util/util-helpers";
import {Field} from "../../../../../data/services/fields";
import {FIELD_MASK_PHONE} from "../../../../../util/util-constants";

export const DEFAULT_EMERGENCY_CONTACT_FIELDS = () => {
    return {
        EmergencyContactName: new Field('EmergencyContactName', '', []),
        EmergencyContactRelationship: new Field('EmergencyContactRelationship', '', []),
        EmergencyContactCountryCode: new Field('EmergencyContactCountryCode', '+1', [], false, 'select'),
        EmergencyContactPhone: new Field('EmergencyContactPhone', '', [], false, 'mask', {}, {
            mask: FIELD_MASK_PHONE,
            showMask: true,
            onFocus: handleMaskedFocus
        }),
        EmergencyContactPhoneExtension: new Field('EmergencyContactPhoneExtension', '', [], false, 'text'),
    }
}

const EmergencyContactFields = ({handleInputChange, fields, translate}) => {
    const fieldsHtml = fieldsToHtml((Object.values(Object.assign({}, includeFields(fields, Object.keys(DEFAULT_EMERGENCY_CONTACT_FIELDS()))))), translate, handleInputChange, {
        EmergencyContactCountryCode: getCountryCodes()
    })

    return (
        <>
            <CardSubTitle
                text={translate('text.emergency_contact')}
                addClass={''}
            />

            {fieldsHtml}
        </>
    )
}

export default EmergencyContactFields
