import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, resetCreateUpdateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {getSecondResource} from '../../../data/actions/secondResource'
import moment from 'moment-timezone'
import {ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon, PrinterIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {Link} from 'react-router-dom'
import {
    BookDateDefaultInvoice,
    BookDateDefaultPickup,
    DEFAULT_DATABASE_DATETIME_FORMAT
} from '../../../util/util-constants'
import {classNames, cnv, getMeasurementUnitName, getProp, getWeightUnitName} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {genericMoneyFormatter, scrollErrorIntoView} from '../../../common/util/util-vanilla'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon'
import {numberWithCommas} from '../../../util/util-formaters'
import EnvelopeIcon from "@heroicons/react/24/outline/EnvelopeIcon";
import InfoParagraph from "../../../common/components/info-paragraph";
import {LoaderSmall} from "../../../common/components/loader";
import ModalDefault from "../../../common/components/modal/modal-default";
import {fieldsToHtml} from "../../../common/util/util-fields";

class BillingDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            invoiceCreated: false,
            showLoadAccessorials: true,
            showLoadCommodities: true,
            showLoadMultistopsSection: true,
            showLoadMultistops: true,
            showAllMultistops: false,
        }
        this.defaultWeightUnit = LocalStorage.get('user')?.Contact?.WeightUnit ?? 'lb'
        this.standardLengthMeasure = this.isMetric ? 'm' : 'ft'
        this.standardWeightMeasure = this.isMetric ? 'kg' : 'lb'
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show && this.props.show) {
            this.setState({
                fields: this.getFields(),
                invoiceCreated: false,
            })
        }
        if (this.props.resource !== prevProps.resource && !!this.props.resource.create && !!this.props.resource.create.id && !!this.props.show) {
            this.props.onProcessPreview(Object.assign({}, this.props.selectedItem ?? {}, {InvoiceID: this.props.resource.create.id}))
            this.props.dispatch(resetCreateUpdateResource())
        }
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    submit = (event) => {
        event && event.preventDefault()
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {showAllMultistops: this.state.showAllMultistops ? 1 : 0}),
                    resource: Resources.Billing,
                    query: this.props.query,
                    piggyResource: Resources.Billing,
                }))
            } else {
                scrollErrorIntoView(this.state.fields)
            }
        })
    }

    convertMeasurements = (fields = []) => {
        const defaultMeasureUnit = getMeasurementUnitName()

        return fields.map(it => {
            const length = cnv(it.Length, getMeasurementUnitName(it.MeasureUnitID), getMeasurementUnitName()).toFixed(2)
            const width = cnv(it.Width, getMeasurementUnitName(it.MeasureUnitID), getMeasurementUnitName()).toFixed(2)
            const height = cnv(it.Height, getMeasurementUnitName(it.MeasureUnitID), getMeasurementUnitName()).toFixed(2)
            const weight = cnv(it.Weight, getWeightUnitName(it.WeightUnit), this.defaultWeightUnit).toFixed(2)

            const lengthStandard = cnv(it.Length, getMeasurementUnitName(it.MeasureUnitID), this.standardLengthMeasure).toFixed(2)
            const widthStandard = cnv(it.Width, getMeasurementUnitName(it.MeasureUnitID), this.standardLengthMeasure).toFixed(2)
            const heightStandard = cnv(it.Height, getMeasurementUnitName(it.MeasureUnitID), this.standardLengthMeasure).toFixed(2)
            const weightStandard = cnv(it.Weight, getWeightUnitName(it.WeightUnit), this.standardWeightMeasure).toFixed(2)

            const volume = lengthStandard * widthStandard * heightStandard
            it.VolumeCalculated = <div>{Number(volume).toFixed(2)} {defaultMeasureUnit}<sup>3</sup></div>

            const density = !weightStandard || !volume ? 0 : (Number(weightStandard) / (volume)).toFixed(2)

            it.DensityCalculated =
                <div>{density} {this.standardWeightMeasure + '/' + this.standardLengthMeasure}<sup>3</sup></div>

            it.LengthConverted = length + ' ' + defaultMeasureUnit
            it.WidthConverted = width + ' ' + defaultMeasureUnit
            it.HeightConverted = height + ' ' + defaultMeasureUnit

            it.WeightConverted = weight + ' ' + this.defaultWeightUnit

            return it
        })
    }

    hideModal = () => {
        this.props.onClose()
    }

    getResourceName = () => {
        return Resources.Billing
    }

    getFields = () => {
        const colSpan1 = 'relative md:col-span-1'
        const colSpan2 = 'relative md:col-span-2'
        return {
            TotalAmount: new Field('TotalAmount', Number(this.props.selectedItem?.Price) + Number(this.props.selectedItem?.AccessorialAmount), ['empty'], false, 'hidden', {}),
            Customer: new Field('CustomerID', this.props.selectedItem?.Customer, ['empty'], false, 'hidden', {readOnlyFieldGroupClass: colSpan1}),
            CustomerID: new Field('CustomerID', this.props.selectedItem?.CustomerID, ['empty'], false, 'hidden', {}),
            LoadID: new Field('LoadID', this.props.selectedItem?.LoadID, ['empty'], true, 'hidden', {readOnlyFieldGroupClass: colSpan2}),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {
                addContainerClass: colSpan1,
                label: 'InvoiceDate'
            }),
            DueDate: new Field('DueDate', moment().add(this.props.selectedItem?.CustomerNetTermDays ? this.props.selectedItem?.CustomerNetTermDays : this.props.selectedItem?.CompanyNetTermDays ? this.props.selectedItem?.CompanyNetTermDays : 15, 'days').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {
                addContainerClass: colSpan1,
                label: ''
            }),
            BookDate: new Field('BookDate',
                this.props.selectedItem?.BookDateDefault === BookDateDefaultInvoice ? moment().format(DEFAULT_DATABASE_DATETIME_FORMAT) :
                    this.props.selectedItem?.BookDateDefault === BookDateDefaultPickup ? this.returnValidDate(this.props.selectedItem?.PickupDate) :
                        this.returnValidDate(this.props.selectedItem?.DestinationDate),
                ['empty']
                , false, 'date', {
                    addContainerClass: colSpan1,
                    label: 'PostingDate'
                }),
            IsFactoringActive: new Field('IsFactoringActive', this.props.selectedItem?.IsReceivableFactoringActive ? 1 : 0, [''], false, 'checkbox'),
        }
    }

    returnValidDate = (date) => {
        const validationDate = moment(date)
        return (date && validationDate.isValid()) ? date : ''
    }

    render() {
        const {translate} = this.props

        const isLoading = this.props.resource?.isLoading

        const LoadStopTypes = {
            1: 'Pickup',
            4: 'Delivery & Pickup',
            5: 'Delivery'
        }

        const Load = getProp(this.props, 'selectedItem', {})
        const multistops = getProp(this.props, 'selectedItem.multistops', [])
        const accessorials = getProp(this.props, 'selectedItem.accessorial', [])
        const commodities = this.convertMeasurements(getProp(this.props, 'selectedItem.commodities', []))

        const LoadMultistops = multistops
            .map((it, i) => (
                <tr key={i} className="border-b border-tm-gray-200">
                    <td className=" py-2 text-left text-sm text-tm-gray-900 sm:table-cell">{i + 1}</td>
                    <td
                        className="py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{LoadStopTypes[it.StopType]}</td>
                    <td className="py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.Multistop}</td>
                    <td className="py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.CityName}</td>
                    <td
                        className="py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.StateAbbreviation} / {it.PostalCode}</td>
                    <td className="py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.ReferenceNumber}</td>
                </tr>
            ))

        const totalCommodityWeight = numberWithCommas(commodities.reduce((memo, it) => {
            if (!it.Weight) return memo

            memo = memo + Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), this.defaultWeightUnit).toFixed(2))
            return memo
        }, 0))

        const totalCommodityVolume = numberWithCommas(commodities.reduce((memo, it) => {
            if (!it.Width || !it.Length || !it.Height) return memo

            memo = memo +
                (cnv(it.Width, getMeasurementUnitName(it.MeasureUnitID), getMeasurementUnitName()).toFixed(2)
                    * cnv(it.Length, getMeasurementUnitName(it.MeasureUnitID), getMeasurementUnitName()).toFixed(2)
                    * cnv(it.Height, getMeasurementUnitName(it.MeasureUnitID), getMeasurementUnitName()).toFixed(2))
            return memo
        }, 0))

        const LoadCommodities = commodities.map((it, i) => {
            return (
                <tr key={i} className="border-b border-tm-gray-200">
                    <td className=" py-2 text-left text-sm text-tm-gray-900 sm:table-cell">{i + 1}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.ProductName}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">
                        {!it.Hazmat ?
                            <XMarkIcon className="w-5 h-5 text-tm-gray-700"/>
                            :
                            <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                        }
                    </td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.CountPallets}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.CountPieces}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.WeightConverted}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.WidthConverted}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.LengthConverted}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.HeightConverted}</td>
                </tr>
            )
        })

        const LoadAccessorials = accessorials.map((it, i) => {
            return (
                <tr key={i + 2} className="border-b border-tm-gray-200">
                    <td className=" py-2 text-left text-sm text-tm-gray-900 sm:table-cell">{i + 2}</td>
                    <td className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.Accessorial}</td>
                    <td
                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{it.LoadAccessorialUnits}</td>
                    <td
                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{genericMoneyFormatter(it.Amount)}</td>
                    <td
                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{genericMoneyFormatter(it.Amount * it.LoadAccessorialUnits)}</td>
                </tr>
            )
        })

        const fieldsToHTML = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {})

        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-5xl'}
                title={translate('text.ProcessInvoice')}
                onButtonClick={this.submit}
                buttonLabel={translate('btn.save')}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={this.hideModal}
                focusXButton={true}
            >
                <div className="p-5">
                    {isLoading && (
                        <div className="p-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!isLoading && !!this.state.invoiceCreated && (
                        <div className="space-y-4">
                            <div className="text-center">
                                <h3 className="text-lg flex justify-center items-center gap-2">
                                    <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                                    {translate('text.invoice_created_successfully')}
                                </h3>
                            </div>

                            <div className="flex items-center justify-center gap-4">
                                <button
                                    className={
                                        classNames(
                                            "border border-tm-gray-200 shadow rounded-btn flex gap-2 flex-col items-center justify-center p-6 focus:ring-2 focus:ring-offset-inverse focus:ring-offset-2 focus:ring-primary",
                                            this.state.selectedOption === 'print' ? "bg-primary text-primary-contrast" : "bg-inverse"
                                        )
                                    }
                                    onClick={() => {
                                        this.setState({selectedOption: 'print'})
                                        this.props.dispatch(download({
                                            user: LocalStorage.get('user'),
                                            resource: Resources.BillingDocuments,
                                            query: Object.assign({
                                                format: 'PDF',
                                                name: 'invoice_' + this.props.resource.create.id + currentDate() + '.pdf'
                                            }, {
                                                InvoiceID: this.props.resource.create.id,
                                                type: '1'
                                            })
                                        }))
                                    }}
                                >
                                    <PrinterIcon className="w-10 h-10"/>

                                    <p className="text-base">Print Invoice</p>
                                </button>

                                <button
                                    className={
                                        classNames(
                                            "border border-tm-gray-200 shadow rounded-btn flex gap-2 flex-col items-center justify-center p-6 focus:ring-2 focus:ring-offset-inverse focus:ring-offset-2 focus:ring-primary",
                                            this.state.selectedOption === 'email' ? "bg-primary text-primary-contrast" : "bg-inverse"
                                        )
                                    }
                                    onClick={() => {
                                        this.setState({selectedOption: 'email'})
                                        this.props.dispatch(getSecondResource({
                                            user: LocalStorage.get('user'),
                                            resource: Resources.BillingDocuments,
                                            query: Object.assign({}, {
                                                InvoiceID: this.props.resource.create.id,
                                                type: '2'
                                            })
                                        }))
                                    }}

                                >
                                    <EnvelopeIcon className="w-10 h-10"/>

                                    <p className="text-base">Email Invoice</p>
                                </button>
                            </div>
                        </div>
                    )}

                    {!isLoading && !this.state.invoiceCreated && (
                        <>
                            <div
                                className={'grid gap-4 grid-cols-3 pb-5'}>
                                <div className="sm:col-span-1 relative">
                                    <dt className="text-sm font-medium text-tm-gray-700 flex justify-between">
                                        <span>Customer</span>
                                        {this.props.selectedItem?.CustomerID && (
                                            <span>
                                                <Tippy content={'Visit customer page'}>
                                                    <Link
                                                        to={'/customers/info/' + this.props.selectedItem?.OrganizationID + '/' + this.props.selectedItem?.CustomerID}
                                                        target="_blank">
                                                        <ArrowTopRightOnSquareIcon className="h-5 w-5"/>
                                                    </Link>
                                                </Tippy>
                                            </span>
                                        )}
                                    </dt>
                                    <dd className="mt-1 text-sm text-tm-gray-900">{this.props.selectedItem?.Customer}</dd>
                                </div>
                                <div className="sm:col-span-1 relative">
                                    <span>Office</span>
                                    <dd className="mt-1 text-sm text-tm-gray-900">{this.props.selectedItem?.Office}</dd>
                                </div>
                                <div className="sm:col-span-1 relative">
                                    <dt className="text-sm font-medium text-tm-gray-700 flex justify-between">
                                        <span>Load</span>
                                        {this.props.selectedItem?.LoadID && (
                                            <span>
                                                <Tippy content={'Visit load page'}>
                                                    <Link to={'/loads/info/' + this.props.selectedItem?.LoadID}
                                                          target="_blank">
                                                        <ArrowTopRightOnSquareIcon className="h-5 w-5"/>
                                                    </Link>
                                                </Tippy>
                                            </span>
                                        )}
                                    </dt>
                                    <dd className="mt-1 text-sm text-tm-gray-900">{this.props.selectedItem?.LoadNumber}</dd>
                                </div>
                                {fieldsToHTML}
                            </div>
                            <div className="flex justify-between">
                                <div className="h-10 flex items-center">
                                    <InfoParagraph>
                                        {translate('text.ProcessInvoiceInfoDefault')}
                                    </InfoParagraph>
                                </div>
                            </div>

                            {LoadMultistops.length > 0 && (
                                <div className={'mb-5'}>
                                    <div
                                        className="capitalize pl-1 group w-full items-center py-2 text-sm font-medium rounded-md mb-2 flex justify-between">
                                        <div>
                                            <p className="text-lg text-tm-gray-900">
                                                Stops {!this.state.showLoadMultistopsSection ? ` (Total Miles: ${Load.TotalMiles})` : ''}</p>
                                        </div>
                                        <button
                                            onClick={() => this.setState({showLoadMultistopsSection: !this.state.showLoadMultistopsSection})}
                                            className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 ring-offset-inverse ring-link ">
                                            {this.state.showLoadMultistopsSection &&
                                                <ChevronUpIcon className={'h-4 w-4'}/>}
                                            {!this.state.showLoadMultistopsSection &&
                                                <ChevronDownIcon className={'h-4 w-4'}/>}
                                        </button>
                                    </div>

                                    {this.state.showLoadMultistopsSection && (
                                        <div className="px-2">
                                            <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
                                                <table className="min-w-full divide-y divide-tm-gray-300 ">
                                                    <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-tm-gray-900 sm:pl-6 md:pl-0"
                                                        >#
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Type
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Location Name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >City
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >State
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Ref
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {LoadMultistops}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={'flex flex-col w-1/4 ml-auto mt-4'}>
                                                <div className="flex justify-between items-center">
                                                    <dt className="text-sm font-bold">Total Miles</dt>
                                                    <dd className="ml-5 text-sm text-tm-gray-900">{Load.TotalMiles}</dd>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}


                            {LoadCommodities.length > 0 && (
                                <div className={'mb-5'}>
                                    <div
                                        className="capitalize pl-1 group w-full items-center py-2 text-sm font-medium rounded-md mb-2 flex justify-between">
                                        <p className="text-lg text-tm-gray-900">
                                            Commodities
                                            {!this.state.showLoadCommodities ? ` (Weight: ${totalCommodityWeight})` : ''}
                                        </p>
                                        <button
                                            onClick={() => this.setState({showLoadCommodities: !this.state.showLoadCommodities})}
                                            className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 ring-offset-inverse ring-link ">
                                            {this.state.showLoadCommodities &&
                                                <ChevronUpIcon className={'h-4 w-4'}/>}
                                            {!this.state.showLoadCommodities &&
                                                <ChevronDownIcon className={'h-4 w-4'}/>}
                                        </button>
                                    </div>

                                    {this.state.showLoadCommodities && (
                                        <div className="px-2">
                                            <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
                                                <table className="min-w-full divide-y divide-tm-gray-300 ">
                                                    <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-tm-gray-900 sm:pl-6 md:pl-0"
                                                        >#
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Product Name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Hazmat
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Count Pallets
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Count Pieces
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Weight
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Width
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Length
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                        >Height
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {LoadCommodities}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={'flex flex-col w-1/4 ml-auto mt-4'}>
                                                <div className="flex justify-between items-center">
                                                    <dt className="text-sm font-medium text-tm-gray-700">Total Volume
                                                    </dt>
                                                    <dd
                                                        className="ml-5 text-sm text-tm-gray-900">{totalCommodityVolume + ' ' + getMeasurementUnitName()}<sup>3</sup>
                                                    </dd>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <dt className="text-sm font-bold">Total Weight</dt>
                                                    <dd
                                                        className="ml-5 text-sm text-tm-gray-900">{totalCommodityWeight + ' ' + this.defaultWeightUnit}</dd>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className={'mb-5'}>
                                <div
                                    className="capitalize pl-1 group w-full items-center py-2 text-sm font-medium rounded-md mb-2 flex justify-between">
                                    <p className="text-lg text-tm-gray-900">
                                        Freight
                                        Charges {!this.state.showLoadAccessorials ? ` (Total: ${genericMoneyFormatter(Number(this.props.selectedItem?.Price) + Number(this.props.selectedItem?.AccessorialAmount))})` : ''}</p>
                                    <button
                                        onClick={() => this.setState({showLoadAccessorials: !this.state.showLoadAccessorials})}
                                        className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 ring-offset-inverse ring-link ">
                                        {this.state.showLoadAccessorials &&
                                            <ChevronUpIcon className={'h-4 w-4'}/>}
                                        {!this.state.showLoadAccessorials &&
                                            <ChevronDownIcon className={'h-4 w-4'}/>}
                                    </button>
                                </div>

                                {this.state.showLoadAccessorials && (
                                    <div className="px-2">
                                        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
                                            <table className="min-w-full divide-y divide-tm-gray-300 ">
                                                <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-tm-gray-900 sm:pl-6 md:pl-0"
                                                    >#
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                    >Item
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                    >Units
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                    >Amount
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" py-2 px-1 text-left text-sm font-semibold text-tm-gray-900 sm:table-cell"
                                                    >Calculated Amount
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr key={1} className="border-b border-tm-gray-200">
                                                    <td
                                                        className=" py-2 text-left text-sm text-tm-gray-900 sm:table-cell">{1}</td>
                                                    <td
                                                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{translate('text.LineHaul')}</td>
                                                    <td
                                                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{1}</td>
                                                    <td
                                                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{genericMoneyFormatter(this.props.selectedItem?.Price)}</td>
                                                    <td
                                                        className=" py-2 px-1 text-left text-sm text-tm-gray-900 sm:table-cell">{genericMoneyFormatter(this.props.selectedItem?.Price)}</td>
                                                </tr>
                                                {LoadAccessorials}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={'flex flex-col w-1/4 ml-auto mt-4'}>
                                            <div className="flex justify-between items-center">
                                                <dt className="text-sm font-medium text-tm-gray-700">Freight</dt>
                                                <dd
                                                    className="ml-5 text-sm text-tm-gray-900">{genericMoneyFormatter(Number(this.props.selectedItem?.Price))}</dd>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <dt className="text-sm font-medium text-tm-gray-700">Accessorials</dt>
                                                <dd
                                                    className="ml-5 text-sm text-tm-gray-900">{genericMoneyFormatter(Number(this.props.selectedItem?.AccessorialAmount))}</dd>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <dt className="text-sm font-bold">Invoice Total</dt>
                                                <dd
                                                    className="ml-5 text-sm text-tm-gray-900 font-bold">{genericMoneyFormatter(Number(this.props.selectedItem?.Price) + Number(this.props.selectedItem?.AccessorialAmount))}</dd>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    <div className="separator mb-4"/>

                    <div className="flex justify-between">
                        <div className="h-10 flex items-center">
                            <InfoParagraph>
                                {translate('text.ProcessInvoiceInfo')}
                            </InfoParagraph>
                        </div>
                    </div>
                </div>
            </ModalDefault>
        )
    }
}

export default connect(state => state)(BillingDialog)
