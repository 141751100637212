import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createResource, getResource} from '../../../../data/actions/resource'
import LocalStorage from "../../../../util/localStorage";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {cloneDeep, getRandomID} from "../../../../common/util/util-vanilla";
import Resources from '../../../../data/services/resources'
import {PlusCircleIcon} from '@heroicons/react/20/solid'
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import GroupsViewDialog from "../../../my-company/groups-view/groups-view-dialog";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {showGlobalModal} from "../../../../data/actions/ui";
import CardSubTitle from "../../../../common/components/card/card-sub-title";
import InfoParagraph from "../../../../common/components/info-paragraph";
import {LoaderLarge} from "../../../../common/components/loader";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";
import FieldOptions from "../../../../common/components/fields/field-options";

const DEFAULT_TASK_TOPICS = {
    "General": "General",
    "Dispatch": "Dispatch",
    "Safety": "Safety",
    "Fleet": "Fleet",
    "Accounting": "Accounting",
    "Companies": "Companies",
    "Personnel": "Personnel"
};

export default function TasksSettingsAutoTasksTab({translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading;

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }
    /** Data events
     ================================================================= */
    const onSubmit = () => {
        const validatedFields = FieldsManager.validateFields(fields)
        const validatedAdditionalFields = additionalFields.map((it) => FieldsManager.validateFields(it));
        if (FieldsManager.checkFieldsForErrors(validatedFields) && validatedAdditionalFields.reduce((memo, it) => {
            return memo && FieldsManager.checkFieldsForErrors(it);
        }, true)) {
            const fieldsValues = FieldsManager.getFieldKeyValues(validatedFields);
            const fieldValuesArray = Object.keys(fieldsValues).reduce((memo, it) => {
                memo.push({
                    TaskGroupName: it,
                    TaskGroupID: fieldsValues[it]
                })
                return memo;
            }, []);

            const additionalFieldsValues = additionalFields.reduce((memo, it) => {
                memo.push(FieldsManager.getFieldKeyValues(it))
                return memo;
            }, fieldValuesArray)
            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: {
                    Items: additionalFieldsValues
                },
                resource: getResourceName(),
                piggyResource: getResourceName(),
                errorMessage: true,
                successMessage: translate("message.settings_updated")
            }))
        } else {
            setFields(validatedFields)
            setAdditionalFields(validatedAdditionalFields)
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(cloneDeep(fields), name, value));
        setIsStateDirty(true)
    }

    const handleInputChangeAdditional = (name, value, index) => {
        let newFields = cloneDeep(additionalFields);
        newFields[index] = FieldsManager.updateField(cloneDeep(newFields[index]), name, value)
        setAdditionalFields(newFields);
        setIsStateDirty(true)
    }

    const handleGroupInfoClick = (groupData, selectedGroupIndex) => {
        setIsGroupInfoDialogOpen(true);
        setGroupInfoData(groupData);
        setSelectedGroupIndex(selectedGroupIndex);
    }

    const handleBoardInfoClick = (it) => {
        dispatch(showGlobalModal('BoardInfoInfoDialog', it?.value?.value));
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState({});
    const [additionalFields, setAdditionalFields] = useState([]);
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [isGroupInfoDialogOpen, setIsGroupInfoDialogOpen] = useState(false);
    const [groupInfoData, setGroupInfoData] = useState({});
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.TasksGroupDefaults
    }

    const loadFieldsFromResource = () => {
        const items = resource.data.Items;
        let groupIndex = -1;
        const newFields = items.reduce((memo, it) => {
            const entry = it;
            const entryKey = it.TaskGroupName;
            if (!entry.ContactGroupID) {
                memo['defaults'][entryKey] = new Field(entryKey, {
                    value: entry.TaskGroupID,
                    label: entry.TaskGroup
                }, ['empty'], false, 'select-search', {
                    addContainerClass: "col-span-full",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleBoardInfoClick(it),
                                        isVisible: !!it.value
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    api: 'api/' + Resources.BoardTasks,
                    query: {},
                    searchMap: (it) => ({
                        label: it.TaskGroupName,
                        value: it.TaskGroupID,
                        metadata: it
                    })
                });
            } else {
                groupIndex = groupIndex + 1;
                const add = {
                    TaskGroupName: new Field('TaskGroupName', entry.TaskGroupName, ['empty'], false, 'select', {
                        addContainerClass: "col-span-2 relative bottom-3",
                        hideLabel: true
                    }, {
                        menuPlacement: "top",
                        values: DEFAULT_TASK_TOPICS
                    }),
                    TaskGroupID: new Field('TaskGroupID', {
                        value: entry.TaskGroupID,
                        label: entry.TaskGroup
                    }, ['empty'], false, 'select-search', {
                        addContainerClass: "col-span-2 relative bottom-3",
                        key: getRandomID(),
                        hideLabel: true,
                        fieldOptions: (it) => {
                            return (
                                <FieldOptions
                                    options={[
                                        {
                                            icon: InformationCircleIcon,
                                            onClick: () => handleBoardInfoClick(it),
                                            isVisible: !!it.value
                                        }
                                    ]}
                                />
                            )
                        }
                    }, {
                        menuPlacement: "top",
                        api: 'api/' + Resources.BoardTasks,
                        query: {},
                        searchMap: (it) => ({
                            label: it.TaskGroupName,
                            value: it.TaskGroupID
                        })
                    }),
                    ContactGroupID: new Field('ContactGroupID', {
                        value: entry.ContactGroupID,
                        label: entry.ContactGroup
                    }, ['empty'], false, 'select-search', {
                        addContainerClass: "col-span-2 relative bottom-3",
                        hideLabel: true,
                        fieldOptions: (it) => {
                            return (
                                <FieldOptions
                                    options={[
                                        {
                                            addClass: "top-10",
                                            icon: InformationCircleIcon,
                                            onClick: () => handleGroupInfoClick(it?.value?.metadata, groupIndex),
                                            isVisible: !!it.value
                                        }
                                    ]}
                                />
                            )
                        }
                    }, {
                        menuPlacement: "top",
                        api: 'api/' + Resources.ContactGroupsQuick,
                        query: {},
                        searchMap: (it) => ({
                            label: it.ContactGroupName,
                            value: it.ContactGroupID,
                            metadata: it
                        })
                    })
                }
                memo['additional'].push(add);
            }

            return memo;
        }, {defaults: {}, additional: []});
        newFields.defaults.Personnel.props.menuPlacement = "top";
        newFields.defaults.Safety.props.menuPlacement = "top";
        setFields(newFields.defaults);
        setAdditionalFields(newFields.additional);
    }

    const handleAddAdditionalFields = () => {
        let newFields = cloneDeep(additionalFields);

        const index = newFields.length ?? 0;

        newFields.push({
            TaskGroupName: new Field('TaskGroupName', '', ['empty'], false, 'select', {
                addContainerClass: "col-span-2 relative bottom-3",
                hideLabel: true
            }, {
                menuPlacement: "top",
                values: DEFAULT_TASK_TOPICS
            }),
            TaskGroupID: new Field('TaskGroupID', '', ['empty'], false, 'select-search', {
                addContainerClass: "col-span-2 relative bottom-3",
                key: getRandomID(),
                hideLabel: true,
            }, {
                menuPlacement: "top",
                api: 'api/' + Resources.BoardTasks,
                query: {},
                searchMap: (it) => ({
                    label: it.TaskGroupName,
                    value: it.TaskGroupID,
                    metadata: it
                })
            }),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {
                addContainerClass: "col-span-2 relative bottom-3",
                hideLabel: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => handleGroupInfoClick(it?.value?.metadata, index),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {
                menuPlacement: "top",
                isClearable: true,
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.ContactGroupName,
                    value: it.ContactGroupID,
                    metadata: it
                })
            })
        });

        setAdditionalFields(newFields);
        setIsStateDirty(true)
    }

    const handleRemoveAdditionalFields = (i) => {
        let newFields = cloneDeep(additionalFields);
        newFields.splice(i, 1);
        setAdditionalFields(newFields);
        setIsStateDirty(true)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (resource?.data && !resource?.isLoading && !!resource.data?.Items) {
            loadFieldsFromResource()
        }
    }, [resource]);

    const renderBasicFields = () => {
        return fieldsToHtml(Object.values(fields), translate, handleInputChange);
    }

    const renderAdditionalFields = () => {
        return <div className="bg-tm-gray-50 rounded-card shadow-card border border-tm-gray-200">
            <div
                className="grid grid-cols-3 gap-4 px-8 py-2 rounded-card font-bold text-sm text-tm-gray-700 border-b border-tm-gray-200">
                <div className="pl-3">
                    {translate("field.Category")}
                </div>

                <div className="pl-3">
                    {translate("field.TaskGroupID")}
                </div>

                <div className="pl-3">
                    {translate("text.group")}
                </div>
            </div>

            <div className="space-y-2 pt-4 pb-2">
                {
                    additionalFields.map((fields, i) => {
                        return (
                            <div
                                key={fields.TaskGroupID?.metadata?.key}
                                className="grid grid-cols-6 gap-4 px-8 relative">
                                {fieldsToHtml(Object.values(fields), translate, (name, value) => {
                                    handleInputChangeAdditional(name, value, i)
                                })
                                }

                                <button
                                    onClick={() => handleRemoveAdditionalFields(i)}
                                    className="absolute right-1.5 top-5 text-tm-gray-600 hover:bg-tm-gray-200 hover:text-gray-900 rounded-full p-1"
                                >
                                    <XMarkIcon className="w-3 h-3"/>
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    }

    return (
        <div className="pb-20">
            <div className="grid gap-4 py-8 max-w-3xl mx-auto relative min-h-[27rem]">
                <CardSubTitle
                    text={translate('page.heading.auto-tasks')}
                />
                <InfoParagraph
                    type="info"
                >
                    {translate("text.default-tasks.info")}
                </InfoParagraph>

                <CardSubTitle
                    text={translate('text.default-tasks')}
                />
                {renderBasicFields()}

                <CardSubTitle
                    text={translate('text.group-specific-tasks')}
                />

                {renderAdditionalFields()}


                <div className="col-span-full">
                    <button
                        className="btn-icon flex items-center gap-x-4 pr-6 text-tm-gray-600 hover:text-tm-gray-900"
                        onClick={handleAddAdditionalFields}
                    >
                        <PlusCircleIcon className="w-5 h-5"/> {translate('text.add-group-specific-tasks')}
                    </button>
                </div>

                {!!isLoading && (
                    <LoaderLarge/>
                )}
            </div>

            <PageFooter
                translate={translate}
                canSubmit={isStateDirty}
                actionCancel={() => {
                    setIsStateDirty(false);
                    loadFieldsFromResource();
                }}
                actionSubmit={onSubmit}
            />

            <GroupsViewDialog
                isGroupInfoDialogOpen={isGroupInfoDialogOpen}
                groupInfoData={groupInfoData}
                groupID={additionalFields[selectedGroupIndex]?.ContactGroupID?.value?.value}
                onClose={() => setIsGroupInfoDialogOpen(false)}
                translate={translate}
            />
        </div>
    )
}
