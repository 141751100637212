import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {
    CREATE_PERM,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    IFTA_PER_DRIVER_CALC_TYPE,
    MAJOR_ACCOUNT_TYPE_ASSET,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_INCOME,
    READ_PERM,
} from '../../../util/util-constants'
import {checkPerm, getDefaultTableOptions, getLookup, getProp, openInNewTab} from '../../../common/util/util-helpers'
import LocalStorage from "../../../util/localStorage";
import {createDataSelect, getDataSelect} from "../../../data/selectors/resourceSelectors";
import {Field, FieldsManager} from "../../../data/services/fields";
import moment from 'moment'
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import Tippy from "@tippyjs/react";
import {ArrowDownTrayIcon, CurrencyDollarIcon, DocumentIcon, PlusCircleIcon} from '@heroicons/react/24/outline'
import Env from "../../../util/env";
import {download} from "../../../data/actions/download";
import {currentDate, toFrontDateNoTime} from "../../../common/util/util-dates";
import {numberWithCommas} from "../../../util/util-formaters";
import {getSecondDataSelect} from "../../../data/selectors/secondResourceSelectors";
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {showGlobalModal} from '../../../data/actions/ui'
import {getThirdResource} from '../../../data/actions/thirdResource'
import {ListBulletIcon} from '@heroicons/react/20/solid'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import Layout from "../../../common/components/layout";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {LoaderSmall} from "../../../common/components/loader";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import DisplayDataGrid from "../../../common/components/display-data/display-data-grid";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../common/components/resource-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import InfoParagraph from "../../../common/components/info-paragraph";
import FileViewer from "../../../common/components/file-viewer/components";
import {fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";
import InfoBar from "../../../common/components/info-paragraph/info-bar";


class FuelReportsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1);

        const storedFilters = LocalStorage.get(this.pagePath + '_state') ?? {};

        this.tablePageDefaults = {
            style: {
                verticalLines: true
            },
        }

        this.tableDefaultsIFTAperDriver = {
            behaviour: {
                rowSelect: false,
                hasMenu: false,
                canAdjustWidth: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        const tabs = [
            {
                name: 'IFTAMileageReports',
                resource: Resources.IFTAMileageReports,
                pdfResource: Resources.IFTAMileageReportsPDF,
                MileageTaxType: 'IFTA',
                current: false,
                visible: checkPerm(Resources.IFTAMileageReports, READ_PERM)
            },
            {
                name: 'NewMexicoMileageReports',
                resource: Resources.NewMexicoMileageReports,
                pdfResource: Resources.NewMexicoMileageReportsPDF,
                MileageTaxType: 'NEW_MEXICO',
                current: false,
                visible: checkPerm(Resources.NewMexicoMileageReports, READ_PERM)
            },
            {
                name: 'NewYorkMileageReports',
                resource: Resources.NewYorkMileageReports,
                pdfResource: Resources.NewYorkMileageReportsPDF,
                MileageTaxType: 'NY',
                current: false,
                visible: checkPerm(Resources.NewYorkMileageReports, READ_PERM)
            },
            {
                name: 'OregonMileageReports',
                resource: Resources.OregonMileageReports,
                pdfResource: Resources.OregonMileageReportsPDF,
                MileageTaxType: 'OREGON',
                current: false,
                visible: checkPerm(Resources.OregonMileageReports, READ_PERM)
            },
            {
                name: 'CTMileageReports',
                resource: Resources.CTMileageReports,
                pdfResource: Resources.CTMileageReportsPDF,
                MileageTaxType: 'CT',
                current: false,
                visible: checkPerm(Resources.CTMileageReports, READ_PERM)
            },
        ]

        tabs[0].current = true

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,
            selectedTabKey: tabs[0].name,
            selectedPDFResource: tabs[0].pdfResource,
            selectedMileageTaxType: tabs[0].MileageTaxType,
            iftaPerDriverModalOpen: false,

            tableOptions: getDefaultTableOptions(this[`get${tabs[0].name}QueryFilterFields`](), this.tablePageDefaults, this.pagePath, this.props.translate),
            [`${Resources.IFTAMileageReports}QueryFilterFields`]: this.getIFTAMileageReportsQueryFilterFields(storedFilters[Resources.IFTAMileageReports]),
            [`${Resources.NewMexicoMileageReports}QueryFilterFields`]: this.getNewMexicoMileageReportsQueryFilterFields(storedFilters[Resources.NewMexicoMileageReports]),
            [`${Resources.NewYorkMileageReports}QueryFilterFields`]: this.getNewYorkMileageReportsQueryFilterFields(storedFilters[Resources.NewYorkMileageReports]),
            [`${Resources.OregonMileageReports}QueryFilterFields`]: this.getOregonMileageReportsQueryFilterFields(storedFilters[Resources.OregonMileageReports]),
            [`${Resources.CTMileageReports}QueryFilterFields`]: this.getCTMileageReportsQueryFilterFields(storedFilters[Resources.CTMileageReports]),

            perDriverIftaTypeFields: {
                PerDriver: new Field('PerDriver', 2, [''], false, 'select', {
                    addContainerClass: 'w-96 ml-2',
                    label: 'Type'
                }, {
                    values: IFTA_PER_DRIVER_CALC_TYPE
                })
            },
            // Mileage Tax table modal query definitions
            mileageTaxQueryFilterFields: this.getMileageTaxQueryFilterFields(),
            mileageTaxOffset: 0,
            mileageTaxPaginationPage: 1,
            breakpoint: {}
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: this.getResource()})
    }

    fetchDataIftaPerDriver = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            query: {PerDriver: this.state.perDriverIftaTypeFields.PerDriver?.value, ...this.getQuery()},
            resource: Resources.IFTAMileageReports
        }))
    }

    fetchMileageTax = () => {
        this.props.getMileageTaxData({
            query: {
                searchFields: JSON.stringify({MileageTaxType: this.state.selectedMileageTaxType}),
                ...this.getMileageTaxQuery()
            }, resource: Resources.MileageTaxes
        })
    }

    downloadFuelReport = () => {
        this.props.downloadPDF({
            query: Object.assign({
                format: 'PDF',
                name: this.state.selectedTabKey + '_' + currentDate() + '.pdf',
            }, this.getQuery()),
            resource: this.state.selectedPDFResource
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: (this.state.selectedTab === Resources.NewYorkMileageReports) ? this.state.selectedTab : this.state.selectedPDFResource,
            query: Object.assign({
                format: 'EXCEL', name: this.state.selectedTabKey + '_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    downloadCreatedReport = (item) => {
        this.props.downloadPDF({
            query: Object.assign({
                format: 'PDF',
                name: item.MileageTaxEntry + '.pdf',
                MileageTaxEntryID: item.MileageTaxEntryID
            }, this.getQuery()),
            resource: Resources.MileageTaxesDownload
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        });
    }

    handleTogglePDFModal = () => {
        this.setState({
            pdfPreviewModalOpen: !this.state.pdfPreviewModalOpen
        })
    }

    handleToggleMileageTaxModal = () => {
        this.setState({
            mileageTaxModalOpen: !this.state.mileageTaxModalOpen
        }, () => !!this.state.mileageTaxModalOpen && this.fetchMileageTax())
    }

    handleToggleCreateMileageTaxModal = () => {
        this.setState({
            createMileageTaxModalOpen: !this.state.createMileageTaxModalOpen
        })
    }

    handleToggleIftaPerDriverModal = () => {
        this.setState({
            iftaPerDriverModalOpen: !this.state.iftaPerDriverModalOpen
        }, () => {
            if (this.state.iftaPerDriverModalOpen) {
                this.fetchDataIftaPerDriver()
            }
        })
    }

    handleTabChange = (selectedTab) => {
        let selectedTabKey = this.state.selectedTabKey
        let selectedPDFResource = this.state.selectedPDFResource
        let selectedMileageTaxType = this.state.selectedMileageTaxType
        if (selectedTab !== this.state.selectedTab) this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === selectedTab
                if (it.resource === selectedTab) {
                    selectedTabKey = it.name
                    selectedPDFResource = it.pdfResource
                    selectedMileageTaxType = it.MileageTaxType
                }
                return it
            }),
            selectedTab: selectedTab,
            selectedTabKey: selectedTabKey,
            selectedPDFResource: selectedPDFResource,
            selectedMileageTaxType: selectedMileageTaxType,
        }, this.fetchData)
    }

    handleFilterClear = () => {
        this.setState({
            [`${this.state.selectedTab}QueryFilterFields`]: this[`get${this.state.selectedTabKey}QueryFilterFields`](),
        }, this.fetchData)
    }

    handleFilterInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state[`${this.state.selectedTab}QueryFilterFields`], name, value);
        
        if ("Quarter" === name) {
            const quartalValues = this.getQuartalValues(value);
            fields.StartDate.value = quartalValues.StartDate;
            fields.EndDate.value = quartalValues.EndDate;
        }

        this.setState({
            [`${this.state.selectedTab}QueryFilterFields`]: fields
        }, () => {
            this.fetchData();
            this.saveTabQuery(fields);
        })
    }

    handleMileageTaxFilterInputChange = (name, value) => {
        this.setState({
            mileageTaxQueryFilterFields: FieldsManager.updateField(this.state.mileageTaxQueryFilterFields, name, value),
            mileageTaxOffset: 0,
            mileageTaxPaginationPage: 1
        }, this.fetchMileageTax)
    }

    handleUpdateMileageTaxOffset = (offset, num) => {
        this.setState({
            mileageTaxOffset: offset,
            mileageTaxPaginationPage: num
        }, this.fetchMileageTax)
    }

    handleIFTAPerDriverInputChange = (name, value) => {
        this.setState({
            perDriverIftaTypeFields: FieldsManager.updateField(this.state.perDriverIftaTypeFields, name, value),
        }, this.fetchDataIftaPerDriver)
    }

    /** Helpers
     ================================================================= */
    getQuartalValues = (quartal) => {
        return {
            StartDate: moment().quarter(Number(quartal) - 4).startOf('quarter').format(getProp(LocalStorage.get('user'), "Contact.DateTimeFormat", "MM/DD/YYYY")),
            EndDate: moment().quarter(Number(quartal) - 4).endOf('quarter').format(getProp(LocalStorage.get('user'), "Contact.DateTimeFormat", "MM/DD/YYYY"))
        }
    }

    getQuery = () => {
        return {
            ...FieldsManager.getFieldKeyValues(this.state[`${this.state.selectedTab}QueryFilterFields`])
        }
    }
    getMileageTaxQuery = () => {
        return {
            offset: this.state.mileageTaxOffset,
            paginationPage: this.state.mileageTaxPaginationPage,
            ...FieldsManager.getFieldKeyValues(this.state.mileageTaxQueryFilterFields),
            sortBy: 'EntryCreatedDate',
            sort: "DESC"
        }
    }

    getResource = () => {
        return this.state.selectedTab ?? ""
    }

    getLoadedMileageTotal = () => {
        const total = getProp(this.props.resource, 'data.list', []).reduce((memo, it) => {
            memo += it.MileageLoaded
            return memo
        }, 0)

        return numberWithCommas(total)
    }
    /** Fields/Data definitions
     ================================================================= */
    getMileageTaxQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 10, [''], false, 'select', {})
        }
    }

    getIftaFieldList = () => {
        const data = getProp(this.props.resource, "data", {})
        const fieldTemplates = {
            State: new Field('State', '', [''], false, 'text', {
                label: "BaseState"
            }),
            TotalMileage: new Field('TotalMileage', '', [''], false, 'float'),
            TotalTaxPaidGallons: new Field('TotalTaxPaidGallons', '', [''], false, 'float'),
            MPG: new Field('MPG', '', [''], false, 'float'),
        }

        return fillFieldsFromData(fieldTemplates, data);
    }

    getIFTATableFields = () => {
        return {
            State: new Field('State', '', [''], false, 'text'),
            TaxableMiles: new Field('TaxableMiles', '', [''], false, 'float'),
            TaxableGallons: new Field('TaxableGallons', '', [''], false, 'float'),
            TaxPaidGallons: new Field('TaxPaidGallons', '', [''], false, 'float'),
            NetTaxableGallons: new Field('NetTaxableGallons', '', [''], false, 'float'),
            TaxRate: new Field('TaxRate', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.TaxRate, 4)}</div>
            }),
            TaxDue: new Field('TaxDue', '', [''], false, 'float'),
        }
    }

    getIFTAPerDriverTableFields = () => {
        return {
            Unit: new Field('Unit', '', [''], false, 'text'),
            Driver: new Field('Driver', '', [''], false, 'text'),
            IsPaysIFTA: new Field('IsPaysIFTA', '', [''], false, 'checkbox'),
            TaxableMiles: new Field('TaxableMiles', '', [''], false, 'float'),
            TaxDue: new Field('TaxDue', '', [''], false, 'float'),
        }
    }

    getNewMexicoTableFields = () => {
        return {
            WeightClass: new Field('WeightClass', '', [''], false, 'text'),
            OneWay: new Field('OneWay', '', [''], false, 'checkbox'),
            MileageEmpty: new Field('MileageEmpty', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.MileageEmpty, 0)}</div>
            }),
            Mileage: new Field('Mileage', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.Mileage, 0)}</div>
            }),
            MillRate: new Field('MillRate', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.MillRate, 4)}</div>
            }),
            TaxDue: new Field('TaxDue', '', [''], false, 'float'),
        }
    }

    getNewYorkTableFields = () => {
        return {
            TruckNumber: new Field('TruckNumber', '', [''], false, 'text', {
                render: (item) => {
                    if (!item.TruckID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.TruckNumber}
                        </button>
                    )
                }
            }),
            PermitNumber: new Field('PermitNumber', '', [''], false, 'text'),
            GrossWeight: new Field('GrossWeight', '', [''], false, 'float'),
            UnloadedWeight: new Field('UnloadedWeight', '', [''], false, 'float'),
            MileageEmpty: new Field('MileageEmpty', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.MileageEmpty, 0)}</div>
            }),
            MileageLoaded: new Field('MileageLoaded', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.MileageLoaded, 0)}</div>
            }),
            MillRate: new Field('MillRate', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.MillRate, 4)}</div>
            }),
            EmptyMillRate: new Field('EmptyMillRate', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.EmptyMillRate, 4)}</div>
            }),
            TaxDue: new Field('TaxDue', '', [''], false, 'float')
        }
    }

    getOregonTableFields = () => {
        return {
            TruckNumber: new Field('TruckNumber', '', [''], false, 'text', {
                render: (item) => {
                    if (!item.TruckID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.TruckNumber}
                        </button>
                    )
                }
            }),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, 'text'),
            TruckLicenceStateID: new Field('TruckLicenceStateID', '', [''], false, 'text'),
            Manufacturer: new Field('Manufacturer', '', [''], false, 'text'),
            GrossWeight: new Field('GrossWeight', '', [''], false, 'float'),
            OdometerStart: new Field('OdometerStart', '', [''], false, 'float'),
            OdometerEnd: new Field('OdometerEnd', '', [''], false, 'float'),
            Mileage: new Field('Mileage', '', [''], false, 'float', {
                render: (item) => <div className={"text-right"}>{numberWithCommas(item.Mileage, 0)}</div>
            }),
            MillRate: new Field('MillRate', '', [''], false, 'float'),
            TaxDue: new Field('TaxDue', '', [''], false, 'float'),
        }
    }

    getCTTableFields = () => {
        return {
            TruckNumber: new Field('TruckNumber', '', [''], false, 'text', {
                render: (item) => {
                    if (!item.TruckID) return null;

                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID))
                            }}
                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {item.TruckNumber}
                        </button>
                    )
                }
            }),
            VIN: new Field('VIN', '', [''], false, 'text'),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, 'text'),
            CountryID: new Field('CountryID', '', [''], false, 'custom', {
                render: () => 'USA'
            }),
            StateName: new Field('StateName', '', [''], false, 'text', {
                label: "TruckLicenceStateID"
            }),
            MileageAll: new Field('MileageAll', '', [''], false, 'float'),
            GrossWeight: new Field('GrossWeight', '', [''], false, 'text'),
            MileageState: new Field('MileageState', '', [''], false, 'float', {
                label: "MileageCT"
            }),
        }
    }


    getIFTAMileageReportsQueryFilterFields = (item) => {
        const currentQuartal = (moment().quarter() + 4).toString();
        const {StartDate, EndDate} = this.getQuartalValues(currentQuartal)

        const fieldTemplates = {
            Quarter: new Field('Quarter', currentQuartal, [''], false, 'select', {
                isRequiredFilter: true
            }, {reverseKeySort: true}),
            StartDate: new Field('StartDate', StartDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            EndDate: new Field('EndDate', EndDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            StateID: new Field('StateID', '', [''], false, 'select', {}, {
                all: true
            }),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            })
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getNewMexicoMileageReportsQueryFilterFields = (item) => {
        const currentQuartal = (moment().quarter() + 4).toString();
        const {StartDate, EndDate} = this.getQuartalValues(currentQuartal)

        const fieldTemplates = {
            Quarter: new Field('Quarter', currentQuartal, [''], false, 'select', {
                isRequiredFilter: true
            }, {reverseKeySort: true}),
            StartDate: new Field('StartDate', StartDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            EndDate: new Field('EndDate', EndDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getNewYorkMileageReportsQueryFilterFields = (item) => {
        const currentQuartal = (moment().quarter() + 4).toString();
        const {StartDate, EndDate} = this.getQuartalValues(currentQuartal);

        const fieldTemplates = {
            Quarter: new Field('Quarter', currentQuartal, [''], false, 'select', {
                isRequiredFilter: true
            }, {reverseKeySort: true}),
            StartDate: new Field('StartDate', StartDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            EndDate: new Field('EndDate', EndDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            })
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getOregonMileageReportsQueryFilterFields = (item) => {
        const currentQuartal = (moment().quarter() + 4).toString();
        const {StartDate, EndDate} = this.getQuartalValues(currentQuartal);

        const fieldTemplates = {
            Quarter: new Field('Quarter', currentQuartal, [''], false, 'select', {
                isRequiredFilter: true
            }, {reverseKeySort: true}),
            StartDate: new Field('StartDate', StartDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            EndDate: new Field('EndDate', EndDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            })
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getCTMileageReportsQueryFilterFields = (item) => {
        const currentQuartal = (moment().quarter() + 4).toString();
        const {StartDate, EndDate} = this.getQuartalValues(currentQuartal)

        const fieldTemplates = {
            Quarter: new Field('Quarter', currentQuartal, [''], false, 'select', {
                isRequiredFilter: true
            }, {reverseKeySort: true}),
            StartDate: new Field('StartDate', StartDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            EndDate: new Field('EndDate', EndDate, [''], false, 'date', {
                isRequiredFilter: true
            }),
            TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            })
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getMileageTaxFields = () => {
        return {
            MileageTaxEntry: new Field('MileageTaxEntry', '', [''], false, 'text', {}, {}),
            MileageTaxType: new Field('MileageTaxType', '', [''], false, 'text', {}, {}),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {}, {}),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date', {}, {}),
            EntryAmount: new Field('EntryAmount', '', [''], false, 'float', {
                render: (item) => genericMoneyFormatter(item.EntryAmount)
            }, {}),
            EntryCreatedDate: new Field('EntryCreatedDate', '', [''], false, 'datetimezone', {}, {}),
            Notes: new Field('Notes', '', [''], false, 'text', {}, {}),
        }
    }

    getCreateMileageTaxFields = () => {
        const fields = {
            MileageTaxEntry: new Field('MileageTaxEntry', this.getMileageData()['MileageTaxEntry'], [''], true, 'readonly', {hideDialog: true}, {}),
            Amount: new Field('Amount', this.getMileageData()['TotalTaxDue'], [''], false, 'readonly-money', {hideDialog: true}, {}),
            OrganizationID: new Field('OrganizationID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}, {}),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-3"}, {}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-3"}, {}),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: "col-span-2"}, {}),
            BookDate: new Field('BookDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: "col-span-2"}, {}),
            DueDate: new Field('DueDate', moment().add(15, 'days').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: "col-span-2"}, {}),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}, {}),
            AdvanceAccountID: new Field('AdvanceAccountID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}, {}),
            AdditionAmount: new Field('AdditionAmount', '', ['empty'], false, 'money', {addContainerClass: "col-span-full"}, {}),
            PerDriver: new Field('PerDriver', 2, [''], false, 'select', {
                addContainerClass: 'w-96 ml-2',
                label: 'Type'
            }, {
                values: IFTA_PER_DRIVER_CALC_TYPE
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}, {}),
        }

        if ((this.state.selectedTab !== Resources.IFTAMileageReports)) {
            delete fields['AdvanceAccountID'];
            delete fields['PerDriver'];
            delete fields['AdditionAmount'];
        }

        return fields;
    }

    getMileageData = () => {
        const StartDate = this.getQuery()['StartDate'];
        const EndDate = this.getQuery()['EndDate'];
        const Date1 = toFrontDateNoTime(StartDate)
        const Date2 = toFrontDateNoTime(EndDate)
        const MileageTaxEntry = `Road tax for period ${Date1}-${Date2}`
        const TotalTaxDue = getProp(this.props.resource, 'data.TotalTaxDue', 0);
        return {
            MileageTaxEntry: MileageTaxEntry,
            Amount: genericMoneyFormatter(TotalTaxDue),
            TotalTaxDue: TotalTaxDue,
            StartDate: StartDate,
            EndDate: EndDate
        }
    }

    saveTabQuery = (fields) => {
        let storageObject = LocalStorage.get(this.pagePath + "_state");

        if (!storageObject) {
            storageObject = this.state.tabs.reduce((memo, it) => {
                memo[it.resource] = null
                return memo;
            }, {});
        }

        storageObject = Object.assign({}, storageObject, {[this.state.selectedTab]: FieldsManager.getFieldKeyValues(fields)});

        LocalStorage.set(this.pagePath + "_state", storageObject);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, download, mileageTaxResource} = this.props

        const data = getProp(resource, "data.list", [])
        const metadata = {
            Quarter: {
                "8": `4th Quarter ${moment().year()}`,
                "7": `3rd Quarter ${moment().year()}`,
                "6": `2nd Quarter ${moment().year()}`,
                "5": `1st Quarter ${moment().year()}`,
                "4": `4th Quarter ${moment().subtract(1, 'year').year()}`,
                "3": `3rd Quarter ${moment().subtract(1, 'year').year()}`,
                "2": `2nd Quarter ${moment().subtract(1, 'year').year()}`,
                "1": `1st Quarter ${moment().subtract(1, 'year').year()}`,
            },
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.Driver,
                    value: item.DriverID
                })
            },
            StateID: getLookup("State"),
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.Truck,
                    value: item.TruckID
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE,
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            AdvanceAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_ASSET,
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            IncomeAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_INCOME,
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            ContactGroupID: {
                api: "api/" + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName + " City: " + item.CityName + " State: " + getLookup('State')[item.StateID] + " Zip: " + item.PostalCode
                })
            },
        }

        const mileageTaxData = getProp(mileageTaxResource, 'data.list', [])
        const mileageTaxCount = getProp(mileageTaxResource, 'data.count', 0)
        const mileageTaxIsLoading = getProp(mileageTaxResource, 'isLoading', false)
        const downloadIsLoading = getProp(download, 'isLoading', false)

        const iftePerDriverData = getProp(this.props.thirdResource, 'data.list', [])
        const iftePerDriverDataIsLoading = getProp(this.props.thirdResource, 'isLoading', [])

        let emptyMilesTotal = 0;
        if ((this.state.selectedTab === Resources.NewYorkMileageReports || this.state.selectedTab === Resources.NewMexicoMileageReports) && !resource.isLoading) {
            emptyMilesTotal = getProp(resource, "data.list", []).reduce((memo, it) => {
                memo += it.MileageEmpty;
                return memo;
            }, 0);
        }

        const DriversWithoutCompleteSetup = getProp(resource, 'data.DriversWithoutCompleteSetup', [])

        const isLoading = resource.isLoading;

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.fuel_reports')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="flex relative z-[32]">
                        <ActiveFilters
                            filterFields={this.state[`${this.state.selectedTab}QueryFilterFields`]}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            clearButtonLabel={translate("text.ResetFilters")}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <Tippy content={"View tax per driver"}>
                                <button
                                    className="btn-icon"
                                    onClick={this.handleToggleIftaPerDriverModal}
                                >
                                    <ListBulletIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            {checkPerm(Resources.MileageTaxes, CREATE_PERM) && (
                                <Tippy content={translate('btn.CreateMileageTax')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.handleToggleCreateMileageTaxModal}
                                    >
                                        <PlusCircleIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                            )}
                            <Tippy content={translate('btn.PreviewMileageTax')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.handleToggleMileageTaxModal}
                                >
                                    <CurrencyDollarIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <Tippy content={translate('btn.PreviewPDF')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.handleTogglePDFModal}
                                >
                                    <DocumentIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>


                            {!downloadIsLoading ?
                                <Tippy content={translate('btn.download')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.downloadFuelReport}
                                    >
                                        <ArrowDownTrayIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                                :
                                <LoaderSmall/>
                            }
                            {
                                (
                                    this.state.selectedTab === Resources.IFTAMileageReports
                                    ||
                                    this.state.selectedTab === Resources.CTMileageReports
                                    ||
                                    this.state.selectedTab === Resources.NewYorkMileageReports
                                ) && (
                                    <>
                                        {!downloadIsLoading ?
                                            <Tippy content={translate('text.download_excel')}>
                                                <button
                                                    className="btn-icon"
                                                    onClick={this.downloadExcel}
                                                >
                                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                                </button>
                                            </Tippy>
                                            :
                                            <LoaderSmall/>
                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>

                    <TableCard>
                        <TableFilters
                            className="md:px-5 pb-4 pt-5 flex md:ring-1 ring-black ring-opacity-5 ring-b-none"
                            popoverClass="relative"
                            filterFields={this.state[`${this.state.selectedTab}QueryFilterFields`]}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            isLoading={isLoading}
                            onRefreshTable={this.fetchData}
                            selects={metadata}
                            hideLimit
                        />

                        {this.state.selectedTab === Resources.IFTAMileageReports && (
                            <>
                                <DisplayDataGrid
                                    addGridClass={"grid-cols-4 border-y border-tm-gray-300 py-4 px-5 text-tm-gray-700 font-bold"}
                                    displayList={Object.values(this.getIftaFieldList())}
                                    data={getProp(this.props.resource, "data", {})}
                                    translate={translate}
                                />

                                <ResourceTable
                                    verticalTableIsVisible={this.state.breakpoint.index <= 1}
                                    data={data}
                                    fields={this.getIFTATableFields()}
                                    translate={translate}
                                    isLoading={isLoading}
                                    commonTable={true}

                                    options={getDefaultTableOptions(this.getIFTATableFields(), this.tablePageDefaults, this.pagePath, this.props.translate)}

                                    tfoot={{
                                        TaxableMiles:
                                            <div className="text-right font-bold">
                                                {numberWithCommas(getProp(resource, "data.TotalMileage", 0))}
                                            </div>,
                                        NetTaxableGallons:
                                            <div className="text-right font-bold">
                                                {numberWithCommas(getProp(resource, "data.TotalNetTaxableGallons", 0))}
                                            </div>,
                                        TaxPaidGallons:
                                            <div className="text-right font-bold">
                                                {numberWithCommas(getProp(resource, "data.TotalTaxPaidGallons", 0))}
                                            </div>,
                                        TaxDue:
                                            <div className="text-right font-bold">
                                                {genericMoneyFormatter(getProp(resource, "data.TotalTaxDue", 0))}
                                            </div>
                                    }}
                                />
                            </>
                        )}

                        {this.state.selectedTab === Resources.NewMexicoMileageReports && (
                            <ResourceTable
                                data={getProp(resource, "data.list", [])}
                                verticalTableIsVisible={this.state.breakpoint.index <= 1}
                                fields={this.getNewMexicoTableFields()}
                                translate={translate}
                                isLoading={isLoading}
                                options={getDefaultTableOptions(this.getNewMexicoTableFields(), this.tablePageDefaults, this.pagePath, this.props.translate)}

                                tfoot={{
                                    MileageEmpty:
                                        <div className="text-right font-bold">
                                            {numberWithCommas(Math.floor(emptyMilesTotal))}
                                        </div>,
                                    Mileage:
                                        <div className="text-right font-bold">
                                            {numberWithCommas(Math.floor(getProp(resource, "data.TotalMiles", 0)))}
                                        </div>,
                                    TaxDue:
                                        <div className="text-right font-bold">
                                            {numberWithCommas(Math.floor(getProp(resource, "data.TotalTaxDue", 0)))}
                                        </div>,
                                }}
                            />
                        )}

                        {this.state.selectedTab === Resources.NewYorkMileageReports && (
                            <ResourceTable
                                data={getProp(resource, "data.list", [])}
                                verticalTableIsVisible={this.state.breakpoint.index <= 1}
                                fields={this.getNewYorkTableFields()}
                                translate={translate}
                                isLoading={isLoading}
                                options={getDefaultTableOptions(this.getNewYorkTableFields(), this.tablePageDefaults, this.pagePath, this.props.translate)}

                                tfoot={{
                                    MileageEmpty:
                                        <div className="text-right font-bold">
                                            {numberWithCommas(Math.floor(emptyMilesTotal))}
                                        </div>,
                                    MileageLoaded:
                                        <div className="text-right font-bold">
                                            {this.getLoadedMileageTotal()}
                                        </div>,
                                    TaxDue:
                                        <div className="text-right font-bold">
                                            {genericMoneyFormatter(getProp(resource, "data.TotalTaxDue", 0))}
                                        </div>,
                                }}
                            />
                        )}

                        {this.state.selectedTab === Resources.OregonMileageReports && (
                            <ResourceTable
                                data={getProp(resource, "data.list", [])}
                                verticalTableIsVisible={this.state.breakpoint.index <= 1}
                                fields={this.getOregonTableFields()}
                                translate={translate}
                                isLoading={isLoading}
                                commonTable={true}
                                options={getDefaultTableOptions(this.getOregonTableFields(), this.tablePageDefaults, this.pagePath, this.props.translate)}

                                tfoot={{
                                    TotalMiles:
                                        <div className="text-right font-bold">
                                            {getProp(resource, "data.TotalMiles", 0)}
                                        </div>
                                }}
                            />
                        )}

                        {this.state.selectedTab === Resources.CTMileageReports && (
                            <ResourceTable
                                data={getProp(resource, "data.list", [])}
                                verticalTableIsVisible={this.state.breakpoint.index <= 1}
                                fields={this.getCTTableFields()}
                                translate={translate}
                                isLoading={isLoading}
                                commonTable={true}
                                options={getDefaultTableOptions(this.getCTTableFields(), this.tablePageDefaults, this.pagePath, this.props.translate)}

                                tfoot={{
                                    MileageAll:
                                        <div className="text-right font-bold">
                                            {numberWithCommas(getProp(resource, "data.TotalMiles", 0), 0)}
                                        </div>,
                                    MileageState:
                                        <div className="text-right font-bold">
                                            {numberWithCommas(getProp(resource, "data.TaxableMiles", 0), 0)}
                                        </div>,
                                }}
                            />
                        )}

                        <NoRecords
                            addClass={"py-12"}
                            title={translate('text.no_records')}
                            canCreate={false}
                            show={!data.length && !isLoading}
                        />
                    </TableCard>
                </Page>

                <ModalDefault
                    show={this.state.pdfPreviewModalOpen}
                    widthClass={"max-w-7xl"}
                    limitHeight={true}
                    title={translate("text.FilePreview")}

                    onClose={this.handleTogglePDFModal}
                    onButtonClick={this.downloadFuelReport}
                    buttonLabel={translate('btn.download')}
                >
                    <FileViewer
                        fileType={'pdf'}
                        filePath={Env.getApiUrl("api/" + this.state.selectedPDFResource, Object.assign({}, {
                            token: getJWT().access_token,
                            ...this.getQuery()
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                </ModalDefault>

                <ModalSaveResource
                    title={"Create Mileage Tax"}
                    widthClass="max-w-3xl"
                    show={this.state.createMileageTaxModalOpen}
                    gridColsClass="grid-cols-6"
                    onClose={this.handleToggleCreateMileageTaxModal}
                    fields={this.getCreateMileageTaxFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.MileageTaxType = this.state.selectedMileageTaxType
                            const mileageData = this.getMileageData();
                            params = Object.assign({}, params, mileageData, {
                                Amount: mileageData['TotalTaxDue']
                            });
                            this.props.createMileageTax({
                                params: params,
                                resource: Resources.MileageTaxes,
                                query: this.getQuery(),
                                piggyResource: this.getResource(),
                                successMessage: "Tax created successfully!"
                            })
                            this.handleToggleCreateMileageTaxModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    htmlBefore={(
                        <div>
                            {!!DriversWithoutCompleteSetup.length && (
                                <div className="p-1">
                                    <InfoBar type='warning'>
                                        <div className="flex flex-col">
                                            <div>
                                                Following drivers do not have initial payroll setup completed, consider
                                                completing setup before continuing:
                                            </div>

                                            <div className="-ml-2">
                                                {DriversWithoutCompleteSetup.map(it => {
                                                    return <button
                                                        onClick={() => openInNewTab("/drivers/info/" + it.DriverID)}
                                                        className="btn-text py-0 px-2 hover:underline"
                                                        key={it.DriverID}
                                                    >
                                                        {it.Driver}
                                                    </button>
                                                })}
                                            </div>
                                        </div>
                                    </InfoBar>
                                </div>
                            )}

                            <DisplayDataGrid
                                addGridClass={"grid-cols-1 rounded-lg px-5 pt-4 text-tm-gray-700 font-bold"}
                                displayList={Object.values(Object.assign({}, includeFields(this.getCreateMileageTaxFields(), ['MileageTaxEntry', 'Amount']))).filter(it => it.type !== 'hidden')}
                                data={this.getMileageData()}
                                translate={translate}
                            />
                            <div className={"px-5"}>
                                <InfoParagraph>
                                    {translate("text.text.willCreateTax")}

                                    {(this.state.selectedTab === Resources.IFTAMileageReports) && (<span>
                                        For IFTA tax bill entries will be split per truck/driver, total fleet mileage and fuel will be used for MPG calculation. If driver is selected to pay IFTA under payroll setup appropriate amount will be shown at next payroll.
                                    </span>)}
                                </InfoParagraph>
                            </div>
                        </div>
                    )}
                />

                <ModalDefault
                    show={this.state.mileageTaxModalOpen}
                    widthClass={"max-w-5xl"}
                    title={translate("text.MileageTax")}

                    onClose={this.handleToggleMileageTaxModal}

                    customButtonsHTML={(
                        <div className={'flex space-x-3'}>
                            {getProp(mileageTaxResource, 'data.NumberOfNewFuelEntries', 0) > 0 && (
                                <InfoParagraph
                                    type="danger"
                                >
                                    {translate("message.NumberOfNewFuelEntriesMessage", [getProp(mileageTaxResource, 'data.NumberOfNewFuelEntries', 0)])}
                                </InfoParagraph>
                            )}

                            {getProp(mileageTaxResource, 'data.NumberOfNewMileageEntries', 0) > 0 && (
                                <InfoParagraph
                                    type="danger"
                                >
                                    {translate("message.NumberOfNewMileageEntriesMessage", [getProp(mileageTaxResource, 'data.NumberOfNewMileageEntries', 0)])}
                                </InfoParagraph>
                            )}
                        </div>
                    )}
                >
                    <TableCard addClass={'p-5 relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.mileageTaxQueryFilterFields}
                            handleInputChange={this.handleMileageTaxFilterInputChange}
                            translate={translate}
                        />

                        <NoRecordsTable
                            show={(mileageTaxData.length === 0) && !mileageTaxIsLoading}
                            title={translate('text.no_matching_records')}
                            className={"pb-12 pt-16 px-6"}
                        />

                        <ResourceTable
                            data={mileageTaxData}
                            fields={this.getMileageTaxFields()}

                            translate={translate}
                            isLoading={mileageTaxIsLoading || downloadIsLoading}

                            limit={this.state.mileageTaxQueryFilterFields?.limit?.value}

                            actions={
                                [
                                    {
                                        action: this.downloadCreatedReport,
                                        icon: ArrowDownTrayIcon,
                                        visible: () => true,
                                        title: translate('btn.download'),
                                    }
                                ]
                            }
                        />

                        {!(!mileageTaxData.length && !(mileageTaxIsLoading || downloadIsLoading)) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >
                                <div className={"flex items-center w-full pl-3 h-9"}>
                                    <Pagination
                                        hideRowsPerPage
                                        count={mileageTaxCount}
                                        isLoading={mileageTaxIsLoading || downloadIsLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === "offset"
                                                ? this.handleUpdateMileageTaxOffset(value, currentPage)
                                                : this.handleMileageTaxFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.mileageTaxOffset}
                                        queryFields={this.state.mileageTaxQueryFilterFields}
                                        translate={translate}
                                    />
                                </div>
                            </div>
                        )}
                    </TableCard>
                </ModalDefault>

                <ModalDefault
                    show={this.state.iftaPerDriverModalOpen}
                    widthClass={"max-w-6xl"}
                    title={"IFTA Tax Per Driver"}
                    closeButtonLabel={translate('btn.Close')}

                    onClose={this.handleToggleIftaPerDriverModal}
                >
                    <TableCard addClass={'p-5 relative'}>
                        <TableFilters
                            filterFields={this.state.perDriverIftaTypeFields}
                            handleInputChange={this.handleIFTAPerDriverInputChange}
                            translate={translate}
                            hideLimit={true}
                        />

                        <NoRecordsTable
                            show={(iftePerDriverData.length === 0) && !iftePerDriverDataIsLoading}
                            title={translate('text.no_matching_records')}
                        />
                        <ResourceTable
                            data={iftePerDriverData}
                            fields={this.getIFTAPerDriverTableFields()}
                            translate={translate}
                            isLoading={iftePerDriverDataIsLoading || downloadIsLoading}
                            maxHeightClass={"max-h-[calc(100vh-20rem)]"}

                            options={getDefaultTableOptions(this.getIFTAPerDriverTableFields(), this.tableDefaultsIFTAperDriver, this.pagePath, this.props.translate)}
                        />
                    </TableCard>
                </ModalDefault>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        resource: state.resource,
        mileageTaxResource: state.secondResource,
        download: state.download,
    }
}
const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get('user')

    return {
        dispatch: dispatch,
        getData: getDataSelect({dispatch, user}),
        createMileageTax: createDataSelect({dispatch, user}),
        getMileageTaxData: getSecondDataSelect({dispatch, user}),

        downloadPDF: ({query, resource}) => {
            dispatch(download({
                user: user,
                query: query,
                resource: resource
            }))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FuelReportsView)
