import FieldText from "../field-text";
import React, {useState} from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {classNames} from "../../../util/util-helpers";

export default function FieldCellText({name, className, addClass, align, focusedValue, placeholder, unFocusedValue, onInputChange}) {
    focusedValue = focusedValue ?? unFocusedValue ?? "";
    unFocusedValue = unFocusedValue ?? focusedValue ?? "";

    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(focusedValue);

    return (
        <div className="relative">
            {!isFocused && (
                <PencilSquareIcon className={
                    classNames(
                        "pointer-events-none z-10 absolute top-1 w-5 h-5", 
                        align === 'right' ? "left-3" : "right-3"
                    )
                } />
            )}

            <FieldText
                name={name}
                addClass={classNames(
                    className ?? "-mx-1.5 text-tm-gray-700 w-full border border-transparent focus:border-tm-gray-300 placeholder:text-tm-gray-500 bg-field focus:shadow-sm py-1 px-1.5 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-50 disabled:text-tm-gray-500 focus:text-tm-gray-700 rounded-input",
                    addClass,
                    align === 'right' ? "text-right" : undefined
                )}
                value={isFocused ? value : unFocusedValue}
                onFocus={() => setIsFocused(true)}
                placeholder={placeholder}
                onChange={(_, value) => setValue(value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        document.activeElement.blur();
                    }
                }}
                onBlur={(e) => {
                    setIsFocused(false);
                    onInputChange(name, e.target.value);
                    setValue(e.target.value);
                }}
            />
        </div>
    );
}
