import React, {useEffect, useRef, useState} from 'react'
import Square2StackIcon from "@heroicons/react/24/outline/Square2StackIcon";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../../util/util-helpers";

export default function CellButtonWithCopy({onClick, className, copyText, disabled, addClass, children}) {
    const timeoutRef = useRef(setTimeout(() => {
    }));
    const [isCopied, setIsCopied] = useState(false);

    function handleCopyToClipboard(e) {
        e.preventDefault();
        e.stopPropagation();
        setIsCopied(true);
        navigator.clipboard.writeText(copyText).then(() => setIsCopied(true));
    }

    useEffect(() => {
        if (isCopied) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => setIsCopied(false), 2000);
        }
    }, [isCopied])

    if (!children) {
        return null;
    }

    return onClick
        ? (<div className={classNames("inline-flex max-w-full relative", addClass)}>
            <button
                className={
                    classNames(
                        className ?? "truncate leading-5 inline-flex py-0.5 -mx-2 px-2 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary truncate text-primary-shade font-btn font-medium hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0",
                    )}
                disabled={disabled}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick();
                }}
            >

                {children}
            </button>

            {copyText && children && (
                <button
                    className={classNames("p-0.5 group-hover:visible hover:text-primary hover:bg-primary-transparent rounded-btn ml-3 z-10 relative",
                            !isCopied ? "invisible" : undefined
                        )}
                    onClick={handleCopyToClipboard}
                >
                    {!isCopied && (
                        <Square2StackIcon className="w-5 h-5 rotate-180"/>
                    )}

                    {isCopied && (
                        <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                    )}
                </button>
            )}
        </div>)
        : (
            <button
                className={
                    classNames(
                        className ?? "max-w-full relative items-center leading-5 inline-flex py-0.5 -mx-2 pl-2 pr-8 rounded-btn text-left disabled:text-tm-gray-700 disabled:hover:text-tm-gray-700 disabled:hover:bg-transparent hover:text-primary truncate text-primary-shade font-btn font-medium hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0",
                        addClass
                    )}
                disabled={disabled}
                onClick={handleCopyToClipboard}
            >
                {children}

                {copyText && children && (
                    <div className={
                        classNames(
                            "p-0.5 group-hover:visible rounded-btn ml-1.5 absolute right-0 bg-transparent",
                            !isCopied ? "invisible" : undefined
                        )
                    }
                    >
                        {!isCopied && (
                            <Square2StackIcon className="w-5 h-5 rotate-180"/>
                        )}

                        {isCopied && (
                            <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                        )}
                    </div>
                )}
            </button>
        )
}
