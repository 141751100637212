import React from "react";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import {MapPinIcon, HashtagIcon, HomeIcon, InboxIcon, ScaleIcon} from "@heroicons/react/20/solid";
import {
    CalendarDaysIcon,
    CurrencyDollarIcon,
    EnvelopeIcon,
    ClockIcon,
    PhoneIcon,
} from "@heroicons/react/24/outline";
import {classNames} from "../../../util/util-helpers";
import Tooltip from "../../tooltip";

const INCH = 25.4; // mm

export default function OCRElement({
                                       element,
                                       location,
                                       documentDPI,
                                       elementID,
                                       selectedElement,
                                       onSelectElement,
                                       isElementFocused,
                                       isPaired,
                                       pairedFieldName,
                                       parentRef,
                                       isDisabled,
                                       processFragments,
                                   }) {
    if (isDisabled) {
        return null;
    }

    const padding = 10;

    let hiX = (Number(documentDPI) * Number(location?.hi?.[0]) / INCH) + (padding / 3) ?? 0;
    let hiY = (Number(documentDPI) * Number(location?.hi?.[1]) / INCH) ?? 0;
    let loX = (Number(documentDPI) * Number(location?.lo?.[0]) / INCH) - padding ?? 0;
    let loY = (Number(documentDPI) * Number(location?.lo?.[1]) / INCH);
    let width = hiX - loX + padding;
    let height = hiY - loY + (padding / 2);

    let left = 0, top = 0;

    function handleMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        // get the mouse cursor position at startup:
        left = e.clientX;
        top = e.clientY;

        const newEl = document.createElement("div");

        const newContent = document.createTextNode(element?.specific?.content ?? "");
        newEl.appendChild(newContent);
        newEl.classList.add("fixed", "z-50", "font-bold", 'bg-sky-600/10', 'px-3')
        document.body.style.cursor = "move";
        parentRef.current.append(newEl)

        const containerLeft = parentRef.current.getBoundingClientRect().left;
        newEl.style.top = (top - 10) + "px";
        newEl.style.left = (left - (containerLeft - 20)) + "px";

        document.onmouseup = () => handleMouseUp(e, newEl);
        document.onmousemove = (e) => elementDrag(e, newEl, containerLeft);

        onSelectElement(element);
    }

    function elementDrag(e, newEl, containerLeft) {
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();

        left = e.clientX;
        top = e.clientY;

        // set the element's new position:
        newEl.style.top = (top - 10) + "px";
        newEl.style.left = (left - (containerLeft - 20)) + "px";
    }

    function handleMouseUp(e, newEl) {
        newEl.remove();
        document.body.style.cursor = "default";
        document.onmouseup = null;
        document.onmousemove = null;
    }

    const styles = {
        elementClass: "cursor-grab z-10",
        idleElement: "border-4 border-sky-600/50 rounded-btn hover:bg-sky-600/10",
        activeElement: "bg-green-600/30",
        pairedElement: "bg-sky-600/20"
    }

    const renderElementFragmentsIcons = element => {
        const fragments = processFragments(element)
        let iconClassName = "w-8 h-8 font-extrabold"

        let fragmentIconElements = []
        fragments.forEach((fragment) => {
            let iconHtml = null

            // Adding icons based on fragment types
            switch (fragment.type) {
                case "TIME": {
                    iconHtml = <ClockIcon className={iconClassName + " fill-orange-400"}/>
                    break;
                }
                case "DATE": {
                    iconHtml = <CalendarDaysIcon className={iconClassName + " fill-cyan-400"}/>
                    break;
                }
                case "MONEY": {
                    iconHtml = <CurrencyDollarIcon className={iconClassName + " text-green-700 fill-green-500"}/>
                    break;
                }
                case "EMAIL": {
                    iconHtml = <EnvelopeIcon className={iconClassName + "  fill-yellow-400"}/>
                    break;
                }
                case "TELEPHONE_USA_LOCAL": {
                    iconHtml = <PhoneIcon className={iconClassName + " fill-green-700"}/>
                    break;
                }
                case "WEIGHT_VALUE": {
                    iconHtml = <ScaleIcon className={iconClassName + " fill-[#704322]"}/>
                    break;
                }
                case "ADDRESS_USA_NUMBER_NAME_TYPE": {
                    iconHtml = <HomeIcon className={iconClassName + " fill-indigo-600"}/>
                    break;
                }
            }

            fragmentIconElements.push(iconHtml)
        })

        return (
            !!fragmentIconElements.length && (
                <div className={"border-dashed border-2 border-sky-500 flex absolute right-12 -bottom-4"}>
                    {fragmentIconElements}
                </div>
            )
        )
    }

    const renderElementTypeIcon = (element) => {
        const formatName = element?.specific?.["format-name"]
        const iconClassname = "absolute -right-5 -top-7 w-10 h-10 font-extrabold"

        // Adding icons based on format name
        switch (formatName) {
            case "ADDRESS_CITY_STATE_ZIP":
            case "ADDRESS_USA_CITY_STATE_ZIP":
            case "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_ORIGIN":
            case "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_DESTINATION": {
                return <MapPinIcon className={iconClassname + " text-blue-500"}/>
            }
            case "MONEY": {
                return <CurrencyDollarIcon className={iconClassname + " text-green-700 fill-green-500"}/>
            }
            case "EMAIL": {
                return <EnvelopeIcon className={iconClassname + " fill-yellow-400"}/>
            }
            case "DATE":
            case "DATE_INTERVAL": {
                return <CalendarDaysIcon className={iconClassname + " fill-cyan-300"}/>
            }
            case "TIME":
            case "TIME_INTERVAL": {
                return <ClockIcon className={iconClassname + " fill-orange-400"}/>
            }
            case "STAMP":
            case "STAMP_INTERVAL": {
                return (
                    <div className="flex absolute -right-8 -top-10 w-16 h-16 font-extrabold">
                        <CalendarDaysIcon
                            className={"fill-cyan-300"}/>
                        <ClockIcon
                            className={"fill-orange-400"}/>
                    </div>
                )
            }
            case "TRUCKOCR_CHROBINSON_REFERENCE_NUMBER":
            case "TRUCKOCR_ARMSTRONG_REFERENCE_NUMBER":
            case "TRUCKOCR_ECHO_REFERENCE_NUMBER":
            case "TRUCKOCR_ATS_REFERENCE_NUMBER":
            case "TRUCKOCR_BNSF_REFERENCE_NUMBER":
            case "TRUCKOCR_ALLEN_REFERENCE_NUMBER":
            case "TRUCKOCR_SHIPEX_REFERENCE_NUMBER":
            case "TRUCKOCR_REDWOOD_REFERENCE_NUMBER":
            case "TRUCKOCR_RETRANS_REFERENCE_NUMBER":
            case "TRUCKOCR_YUSEN_REFERENCE_NUMBER":
            case "TRUCKOCR_NOLAN_REFERENCE_NUMBER":
            case "TRUCKOCR_TQL_REFERENCE_NUMBER":
            case "TRUCKOCR_JBS_REFERENCE_NUMBER":
            case "TRUCKOCR_JBHUNT_REFERENCE_NUMBER": {
                return <HashtagIcon className={iconClassname + " fill-rose-600"}/>
            }
            case "ADDRESS_USA_NUMBER_NAME_TYPE": {
                return <HomeIcon className={iconClassname + " fill-indigo-600"}/>
            }
            case "TELEPHONE_USA_LOCAL": {
                return <PhoneIcon className={iconClassname + " fill-green-700"}/>
            }
            case "ADDRESS_USA_POBOX": {
                return <InboxIcon className={iconClassname + " fill-gray-700"}/>
            }
            case "WEIGHT_VALUE": {
                return <ScaleIcon className={iconClassname + " fill-[#704322]"}/>
            }
            default: {
                return null
            }
        }
    }

    const hideElementBorder = (element) => {
        return Object.keys(element.specific).find((key) => key.startsWith('group-member'))
    }

    return (
        <Tooltip disabled={!pairedFieldName} content={"Paired with: " + pairedFieldName}>
            <div
                onMouseDown={!hideElementBorder(element) ? handleMouseDown : undefined}
                className={
                    classNames(
                        "absolute",
                        elementID === selectedElement ? styles.activeElement : hideElementBorder(element) ? "" : styles.idleElement,
                        isElementFocused ? styles.activeElement : undefined,
                        !isElementFocused && isPaired ? styles.pairedElement : undefined,
                        styles.elementClass
                    )
                }
                style={{left: loX + "px", width: width + "px", top: loY + "px", height: height + "px"}}
            >
                {isPaired && (
                    <CheckIcon
                        className="absolute -left-7 -bottom-6 w-14 h-14 text-green-700 font-extrabold"
                    />
                )}

                {renderElementTypeIcon(element)}
                {renderElementFragmentsIcons(element)}
            </div>
        </Tooltip>
    );
}
