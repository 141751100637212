import React, {useState} from 'react'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/themes/light.css';
import Tooltip from "../../../tooltip";
import {classNames} from "../../../../util/util-helpers";
import {_ENV_MODE} from "../../../../../util/env";
import Cookie from "../../../../../util/cookie";

const ToolTipTableCell = ({tooltips, cellValue, trigger = "click", placement = "right"}) => {
    const hasAnyID = tooltips.some(it => !!it.id)
    const theme = Cookie.getCookie("APP_THEME_TYPE_"  + _ENV_MODE) === "dark" ? "material" : "light"

    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const content = tooltips
        .filter(it => !!it.id)
        .map(it => {
            return (
                <Tooltip
                    key={it.id}
                    content={it.content}
                    animation="scale-subtle"
                    theme={theme}
                    arrow={false}
                    duration={200}
                    onClickOutside={hide}
                >
                    <button
                        className={`mx-1 p-2 rounded-full text-tm-gray-400 hover:text-tm-gray-500 hover:bg-tm-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2`}
                        onClick={() => {
                            it.onClick()
                            hide()
                        }}
                    >
                        <it.icon
                            className={classNames(
                                'text-tm-gray-700 group-hover:text-tm-gray-900',
                                'flex-shrink-0 h-5 w-5'
                            )}
                            aria-hidden="true"
                        />
                    </button>
                </Tooltip>
            )
        })

    // html za hover samo preko teksta
    return (
        <div className={hasAnyID && "text-primary cursor-pointer font-semibold"}>
            {hasAnyID && (
                <Tooltip
                    trigger={trigger}
                    interactive={true}
                    animation="scale-subtle"
                    theme={theme}
                    duration={200}
                    placement={placement}
                    visible={visible}
                    onClickOutside={hide}
                    content={<div className={'flex'}>{content}</div>}
                >
                    <span className='p-3' onClick={show}>{cellValue}</span>
                </Tooltip>
            )}
            {!hasAnyID && (
                <span>{cellValue}</span>
            )}
        </div>
    )

    // html za hover preko cele celije
    // return (
    //     hasAnyID ?
    //         <Tippy
    //             interactive={true}
    //             animation="scale-subtle"
    //             theme={theme}
    //             duration={200}
    //             distance={8}
    //             placement={props.placement ?? "right"}
    //             content={<>{content}</>}
    //         >
    //             <td className={hasAnyID && " text-primary cursor-pointer font-semibold"}>
    //                 <span>{cellValue}</span>
    //             </td>
    //         </Tippy>
    //         :
    //         <td>
    //             <span>{cellValue}</span>
    //         </td>
    // )
}

export default ToolTipTableCell
