import React from "react";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../util/util-helpers";

export default function NavigationTabsSidebar({tabs, onTabClick, translate, commentCount = 0}) {
    const selectedTab = tabs.find(it => it.isCurrent);

    return <ul className="space-y-1">
        {tabs.map((tab) => {
            const Icon = tab.icon;
            const isCurrent = selectedTab.id === tab.id;

            return (
                <li key={tab.id} className={tab.hasPerm === false ? "opacity-50" : undefined}>
                    <button
                        disabled={tab.hasPerm === false}
                        className={classNames(
                            isCurrent || tab.hasPerm === false ? 'bg-tm-gray-100' : 'hover:bg-tm-gray-50',
                            'group w-full flex items-center pl-5 py-2 pr-4 text-sm font-medium rounded-r-md'
                        )}
                        onClick={() => onTabClick(tab)}
                    >
                        <Icon
                            className={
                                classNames(
                                    (isCurrent ? 'text-tm-gray-900' : 'text-tm-gray-700'),
                                    'flex-shrink-0 h-6 w-6 opacity-70'
                                )
                            }
                        />

                        <span
                            className={classNames(isCurrent ? 'text-tm-gray-900' : 'text-tm-gray-700', 'ml-4 text-left pt-0.5')}
                        >
                            {translate('tab.' + tab.name)}
                            {tab.name === 'COMMENTS' && commentCount ? (
                                <span
                                    className="ml-3 bg-inverse inline-flex items-center px-2.5 py-0 rounded-md text-xs border uppercase font-normal bg-green-100 text-green-800">
                                    {commentCount}
                                </span>
                                ) : null}
                        </span>

                        {tab.hasPerm === false && (
                            <LockClosedIcon className="w-4 h-4 text-red-600 opacity-50 ml-2 -mr-1" />
                        )}
                    </button>
                </li>
            )
        })
        }
    </ul>
}