import React, {Component} from 'react';
import Resources from "../../../../../data/services/resources";
import LocalStorage from "../../../../util/localStorage";
import {connect} from "react-redux";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import axios from "axios";
import {processResponse} from "../../../../../data/services/api-util";
import {getInfoResource} from "../../../../../data/actions/infoResource";
import {updateResource} from "../../../../../data/actions/resource";
import Tippy from "@tippyjs/react";
import {getProp} from "../../../../util/util-helpers";
import {toFrontDateNoTime} from "../../../../util/util-dates";
import {toggleBodyScroll} from "../../../../util/util-vanilla";
import {getJWT} from "../../../../util/util-auth";
import ModalDefault from "../../../modal/modal-default";
import {LoaderSmall} from "../../../loader";
import Env from "../../../../../util/env";
import FieldCheckbox from "../../../fields/field-checkbox";
import FireIcon from "@heroicons/react/20/solid/FireIcon";
import Pagination from "../../../resource-table/table-components/pagination";
import TableCardFooter from "../../../resource-table/table-components/table-card-footer";
import FieldDropdownSelect from "../../../fields/field-dropdown-select";

class TaskReassignDialog extends Component {

    constructor() {
        super();
        this.state = {
            fields: {
                AssigneeID: new Field("AssigneeID", '', ['empty'])
            },
            Tasks: [],
            offset: 0,
            limit: 10,
            sort: "ASC",
            sortBy: "TaskName",
            query: '',
            paginationPage: 1,
            archived: false,
            activeTab: "all"
        }
        this.defaultColumns = ['TaskName', 'Assignee', 'DueDate', 'TaskPriority', 'TaskStatusID', 'CreateUpdateDate'];
    }

    componentDidMount() {
        toggleBodyScroll()
        this.fetchTasks()
    }

    componentDidUpdate = (prevProps, prevState) => {
        Object.keys(this.getQuery()).map(it => {
            if (prevState[it] !== this.state[it]) {
                this.fetchTasks();
            }
        })
    };

    componentWillUnmount() {
        toggleBodyScroll()
    }

    fetchTasks = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.Tasks,
            query: this.getQuery()
        }))
    };

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        Tasks: this.state.Tasks.map(it => it.TaskID),
                        AssigneeID: this.state.fields.AssigneeID.value.value
                    }),
                    resource: Resources.TasksBulkReassign,
                    errorMessage: true, successMessage: "Tasks successfully assigned!",
                    piggyResource: this.props.ui?.secondData?.piggyResourceName ?? false,
                    piggyQuery: this.props.ui?.secondData?.piggyQuery ?? false,
                }));
                this.hideModal();
            }
        })
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            TaskStatus: "1,2",
            ExcludeAssigneeID: this.state.fields.AssigneeID.value?.value ?? ''
        }
    }

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, () => {
            this.fetchTasks();
        });
    }

    setParentState = (data, callback = () => {
    }) => {
        this.setState(data, callback);
    };

    handleInputChange = (name, value) => {
        if (name === "Task") {
            let tasks = this.state.Tasks
            if (tasks.some(it => it.TaskID === value.TaskID)) {
                tasks = tasks.filter(it => it.TaskID !== value.TaskID)
            } else {
                tasks.push(value)
            }
            this.setState({Tasks: tasks, canSubmit: true})
        } else {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, name, value),
                canSubmit: true
            }, this.fetchTasks)
        }
    }

    setActiveTab = tab => {
        this.setState({activeTab: tab})
    }

    render() {
        const {translate, info} = this.props;
        const PinList = getProp(info.data, "TaskPin", [])
        const data = this.state.activeTab === "all" ? getProp(info.data, "list", []) : this.state.Tasks
        const count = info?.data?.count ?? 0;
        const dataItems = data
            .map(it => (
                <tr key={it.TaskID}>
                    <td>{it.TaskID}</td>
                    <td className={'flex items-center'}>
                        <i className={`simple-icon-pin mr-2 ${PinList.includes(it.TaskID) ? "color-darkorange" : ""}`}/>
                        {!!it.Marked && (
                            <Tippy content={<span>{translate("text.NeedsAttention")}</span>}>
                            <span className="relative">
                                <FireIcon className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse" />
                            </span>
                            </Tippy>
                        )}
                        {it.TaskName}
                    </td>
                    <td>{it.Assignee}</td>
                    <td>{toFrontDateNoTime(it.DueDate)}</td>
                    <td>{it.TaskPriority}</td>
                    <td>{it.TaskStatus}</td>
                    <td>{toFrontDateNoTime(it.CreateUpdateDate)}</td>
                    <td className="text-center">
                        <div className="custom-switch custom-switch-secondary-inverse custom-switch-small">
                            <FieldCheckbox
                                id={"Task" + it.TaskID}
                                className="custom-switch-input"
                                value={this.state.Tasks.some(elem => it.TaskID === elem.TaskID)}
                                onChange={() => this.handleInputChange("Task", it)}
                                name={"Task" + it.TaskID}
                            />
                            <label className="custom-switch-btn" htmlFor={"Task" + it.TaskID}/>
                        </div>
                    </td>
                </tr>
            ))

        return (
            <React.Fragment>
                <ModalDefault
                    show={this.props.show}
                    widthClass={'max-w-5xl'}
                    title={translate("text.all_filters")}
                    closeButtonLabel={translate('btn.cancel')}
                    buttonLabel={translate("btn.save")}
                    onButtonClick={this.submit}
                    onClose={this.props.onClose}
                    buttonDisabled={!this.state.canSubmit}
                >
                    <div className="p-5">
                        <label className='form-group has-float-label mb-3'>
                            <FieldDropdownSelect
                                placeholder={translate("text.select_assignee")}
                                {...this.state.fields.AssigneeID}
                                onChange={this.handleInputChange}
                                addClass="form-control"
                                value={this.state.fields.AssigneeID.value}
                                defaultOptions={true}
                                loadOptions={
                                    (inputValue, callback) => {
                                        axios.get(
                                            Env.getApiUrl("api/" + Resources.Contacts, {
                                                query: inputValue,
                                                IsSystemUser: 1
                                            }),
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                }
                                            }
                                        )
                                            .then((response) => {
                                                const result = processResponse(response);
                                                if (result && result.status === 0) {
                                                    const list = result.data.list.map((it) => {
                                                        return {
                                                            label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                                                            value: it.ContactID
                                                        };
                                                    });

                                                    callback(list);
                                                }
                                            })
                                    }
                                }
                            />
                            <span>{translate('field.AssigneeID')} *</span>
                        </label>

                        <ul className="nav nav-tabs separator-tabs ml-0 mb-5" role="tablist">
                            <li className="nav-item">
                                            <span
                                                onClick={() => this.setActiveTab("all")}
                                                className={`nav-link text-uppercase ${this.state.activeTab === "all" ? "active" : ""}`}>{translate('tab.AllTasks')}
                                            </span>
                            </li>
                            <li className="nav-item">
                                            <span
                                                onClick={() => this.setActiveTab("selected")}
                                                className={`nav-link text-uppercase ${this.state.activeTab === "selected" ? "active" : ""}`}>{translate('tab.SelectedTasks')}
                                            </span>
                            </li>
                        </ul>
                        {this.state.activeTab === "all" && (
                            <div className="row">
                                <div className="col">
                                    <input
                                        type="text"
                                        placeholder={translate("text.search")}
                                        className="form-control"
                                        value={this.state.query}
                                        onChange={e => {
                                            this.setState({
                                                query: e.target.value,
                                                paginationPage: 1,
                                                offset: 0
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row mt-2">
                            <div className="col">
                                {!info.isLoading && (
                                    <table
                                        className={"data-table data-table-feature dataTable no-footer text-tm-gray-600"}>
                                        <thead>
                                        <tr>
                                            <th className="">{translate("table.TaskID")}</th>
                                            <th className="">{translate("table.TaskName")}</th>
                                            <th className="">{translate("table.Assignee")}</th>
                                            <th className="">{translate("table.DueDate")}</th>
                                            <th className="">{translate("table.TaskPriority")}</th>
                                            <th className="">{translate("table.Status")}</th>
                                            <th className="">{translate("table.CreateUpdateDate")}</th>
                                            <th className="text-center">{translate("table.actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {dataItems}
                                        <tr>
                                            <td colSpan={7} className='text-center font-weight-bolder'>
                                                {dataItems.length === 0 && translate("text.no_record")}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                )}

                                <TableCardFooter
                                    show={count > this.state.limit && dataItems.length > 0 && this.state.activeTab === "all"}
                                >
                                    <Pagination
                                        count={count}
                                        isLoading={info.isLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => this.updateOffset(value, currentPage)
                                        }
                                        pageOffset={this.state.offset}
                                        pageLimit={this.state.limit}
                                        translate={translate}
                                    />
                                </TableCardFooter>
                                {info.isLoading && (
                                    <div className="absolute position-absolute-center">
                                        <LoaderSmall addClass={""}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ModalDefault>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(TaskReassignDialog);
