// Component for previewing files that are added with file picker
import React, {useEffect, useState} from "react";
import FileViewer from "../../file-viewer/components";
import ModalDefault from "../../modal/modal-default";

export default function FilePreviewModal({file, show, onClose, translate}) {
    const [previewUri, setPreviewUri] = useState("");

    const fileToDataUri = (file) => new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    })

    useEffect(() => {
        if (file && show) {
            fileToDataUri(file)
                .then(dataUri => {
                    setPreviewUri(dataUri)
                })
        } else {
            setPreviewUri("");
        }
    }, [file, show])

    return (
        <ModalDefault
            show={!!previewUri}
            widthClass={"max-w-5xl"}
            title={translate("text.document_preview")}
            limitHeight={true}
            closeButtonLabel={translate("btn.close")}
            onClose={() => {
                setPreviewUri("");
                onClose();
            }}
        >
            <FileViewer
                fileType={file?.path?.split('.')?.pop()}
                filePath={previewUri}
                onError={(e) => {
                    console.log(e)
                }}
            />
        </ModalDefault>
    )
}