import moment from "moment/moment";
import {toFrontDateNoTime} from "../../../util/util-dates";

export default function DocumentExpiryDate(expiryDate) {
    let today = moment().format("YYYY-MM-DD HH:mm:ss")
    let x = 10
    let xDaysBefore = moment(expiryDate).add(-x, 'days').format("YYYY-MM-DD HH:mm:ss")
    let styles = 'text-tm-gray-700 text-tm-gray-700 py-0.5 h-6'
    if (expiryDate) {
        if (xDaysBefore < today && today < expiryDate) {
            styles = 'btn-warning text-inverse'
        }
        if (today > expiryDate) {
            styles = 'btn-danger'
        }
    }

    return (
        <>
            <button className={`btn h-5 btn-xs active ${styles}`}>
                {toFrontDateNoTime(expiryDate)}
            </button>
        </>
    )
}