import React, { Fragment, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import {classNames} from "../../../../util/util-helpers";

export default function StackedListModal( {open, setOpen, list, getEvent, button, customQuery, noDataQueryMessage, noDataMessage} ) {
    const [query, setQuery] = useState('')

    const rows = !list ? [] : list.filter(( item ) => {
        return customQuery(item, query);
    })

    return (
        <Transition.Root show={open} open={open} as={Fragment} afterLeave={() => setQuery('')}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="mx-auto max-w-xl transform divide-y divide-tm-gray-300 overflow-hidden rounded-xl bg-popup shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Combobox>
                                <div className="relative">
                                    <MagnifyingGlassIcon
                                        className="pointer-events-none absolute top-3.5 left-6 h-5 w-5 text-tm-gray-400"
                                        aria-hidden="true"
                                    />
                                    <Combobox.Input
                                        className="h-12 w-full border-0 bg-transparent pl-14 pr-4 text-tm-gray-700 placeholder-icon focus:ring-0 sm:text-sm"
                                        placeholder="Search..."
                                        onChange={( event ) => setQuery(event.target.value)}
                                    />

                                    {!!button &&
                                        <button className="btn btn-outline py-1 absolute top-2.5 right-6">Create</button>
                                    }
                                </div>

                                {rows.length > 0 && (
                                    <Combobox.Options static
                                                      className="max-h-[80vh] scroll-py-2 overflow-y-auto text-sm text-tm-gray-700">
                                        {rows.map(( event ) => (
                                            <Combobox.Option
                                                key={event.id}
                                                className={( {active} ) =>
                                                    classNames('cursor-default select-none m-0', active && 'bg-tm-gray-50 text-white')
                                                }
                                            >
                                                {getEvent(event)}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}

                                {query !== '' && rows.length === 0 && (
                                    <p className="p-4 text-sm text-tm-gray-500">{noDataQueryMessage}</p>
                                )}

                                {query === '' && rows.length === 0 && !!noDataMessage && (
                                    <p className="p-4 text-sm text-tm-gray-500">{noDataMessage}</p>
                                )}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
