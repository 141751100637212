import React, {Component} from 'react'
import {connect} from 'react-redux'
import AdvancesReimbursementTab from "./advances-reimbursement-tab";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import Layout from "../../../common/components/layout";

class AccountingReimbursementPageView extends Component {
    constructor(props) {
        super(props)
        const tabs = [
            {
                name: 'Reimbursement',
                resource: 'Reimbursement',
                current: true,
                visible: true
            },
            {
                name: 'Advances',
                resource: 'Advances',
                current: false,
                visible: true
            }
        ]

        this.state = {
            tabs: tabs,
            selectedTab: 'Reimbursement',
            breakpoint: {}
        }
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    render() {
        const {translate, resource, dispatch} = this.props

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.accounting_reimbursement_advancements')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={dispatch}
                            />
                        )}
                    />

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === 'Reimbursement' && (
                        <AdvancesReimbursementTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}/>
                    )}

                    {this.state.selectedTab === 'Advances' && (
                        <AdvancesReimbursementTab
                            breakpoint={this.state.breakpoint}
                            advances
                            {...this.props}/>
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(AccountingReimbursementPageView)
