import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'

import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    resourceIsCreated,
    resourceIsUpdated,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {CheckIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {CheckCircleIcon} from '@heroicons/react/20/solid'
import moment from 'moment-timezone'
import AccountingLoadBreakdown from '../accounting-components/accounting-load-breakdown'
import AccountingMonthlyBreakdown from '../accounting-components/accounting-monthly-breakdown'
import Env from '../../../util/env'
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon'
import {fillFieldsFromData} from '../../../common/util/util-fields'
import ActiveFilters from '../../../common/components/resource-table/table-components/active-filters'
import TableSettingsPopOver from '../../../common/components/resource-table/table-components/table-settings-popover'
import TableCard from '../../../common/components/resource-table/table-components/table-card'
import TableFilters from '../../../common/components/resource-table/table-components/table-filters'
import Pagination from '../../../common/components/resource-table/table-components/pagination'
import NoRecordsTable from '../../../common/components/no-records-found/no-records-table'
import ModalSaveResourceWithDropZone from '../../../common/components/modal/modal-save-resource-with-drop-zone'
import ResourceTable from '../../../common/components/resource-table'
import ModalConfirm from '../../../common/components/modal/modal-confirm'
import TableOptionsDialog from '../../../common/components/resource-table/table-components/table-options-dialog'
import TableCardFooter from '../../../common/components/resource-table/table-components/table-card-footer'
import FileList from '../../../common/components/dropzone/file-list'
import {getJWT} from '../../../common/util/util-auth'
import Button from '../../../common/components/button'
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

class AdvancesReimbursementTab extends Component {

    constructor(props) {
        super(props)
        this.pagePath = this.props.advances ? this.props.location.pathname.substring(1) + '_advances' : this.props.location.pathname.substring(1) + '_reimbursement'

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Date',
            sort: 'DESC',

            // Fields
            fields: this.getFields(),
            cachedChangedFields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            isColumnsDialogVisible: false,

            // Dialogs
            selectedItem: null,
            isMonthlyBreakdownOpen: false,
            isLimitBelowRequiredDialogOpen: false,

            // Files
            files: [],
            existingFiles: [],
            previewShow: false,
            filePreview: {
                show: false,
                fileType: '',
                filePath: ''
            },
            canModalSubmit: false,
            approveReimbursementModalOpen: false,
            /*breakpoint: {}*/
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (resourceIsUpdated(this.props.resource, prevProps.resource)) {
            if (this.state.createModalOpen) {
                this.handleToggleCreateModal()
            }
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource)) {
            if (this.state.createModalOpen) {
                this.handleToggleCreateModal()
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    deleteResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_delete_entry')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {ReimbursementID: item['ReimbursementID']}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(this.props.advances ? 'message.advances_deleted' : 'message.reimbursement_deleted'),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            })
        })
    }

    /** UI Events (pagination, filters, sort)
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    /** UI Events (Dialogs)
     ================================================================= */
    handleToggleApproveModal = (item = null) => {
        const contactApprovalLimit = getProp(this.props.resource.data, 'Contact.MaxExpenseValue', 0)

        if (contactApprovalLimit < item?.Amount) {
            this.setState({
                isLimitBelowRequiredDialogOpen: !this.state.isLimitBelowRequiredDialogOpen
            })
            return
        }

        this.setState({
            selectedItem: item,
            approveReimbursementModalOpen: !this.state.approveReimbursementModalOpen
        })
    }

    handleToggleCreateModal = () => {
        let cachedChangedFields = this.state.cachedChangedFields

        if (!this.state.createModalOpen) { // clear fields before opening the dialog
            cachedChangedFields = this.getFields()
        }

        this.setState({
            cachedChangedFields,
            createModalOpen: !this.state.createModalOpen,
            previewShow: false,
            canModalSubmit: false,
            files: []
        })
    }

    handleToggleUpdateModal = (item = null) => {
        let cachedChangedFields = this.state.cachedChangedFields

        if (!this.state.createModalOpen) { // clear fields before opening the dialog
            cachedChangedFields = this.getFields(item)
        }

        this.setState({
            cachedChangedFields,
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            files: [],
            existingFiles: item?.DocumentPath ? [item?.DocumentPath] : []
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    toggleLimitBelowRequiredDialog = () => {
        this.setState({
            isLimitBelowRequiredDialogOpen: !this.state.isLimitBelowRequiredDialogOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleInputChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value)

        if (name === 'IsDelayedPayment') {
            fieldsUpdate.InterestRate.type = Number(value) === 1 ? 'float' : 'hidden'
            fieldsUpdate.LoanTerm.type = Number(value) === 1 ? 'float' : 'hidden'

            fieldsUpdate.InterestRate.validate = Number(value) === 1 ? ['integer'] : ['']
            fieldsUpdate.LoanTerm.validate = Number(value) === 1 ? ['integer'] : ['']
        }

        this.setState({cachedChangedFields: fieldsUpdate})
        return fieldsUpdate
    }

    handleShowMonthlyBreakdownClick = () => {
        this.setState({
            isMonthlyBreakdownOpen: !this.state.isMonthlyBreakdownOpen
        })
    }

    handleAcceptFiles = (files) => {
        this.setState({
            files: files,
            canModalSubmit: true
        })
    }

    closePreview = () => {
        this.setState({
            filePreview: {
                show: false,
                fileType: '',
                filePath: ''
            }
        })
    }

    handleFileView = (file, hasFilePath) => {
        this.setState({
            filePreview: {
                show: true,
                fileType: file.path.split('.').pop(),
                filePath: hasFilePath
                    ? Env.getApiUrl('api/' + Resources.AccountingReimbursementDocument, Object.assign({}, {
                        ReimbursementID: this.state.selectedItem?.ReimbursementID,
                        token: getJWT().access_token,
                        date: moment(this.state.selectedItem.CreateUpdateDate, DEFAULT_DATABASE_DATETIME_FORMAT).unix(),
                    }))
                    : URL.createObjectURL(file)
            }
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getFields = (item = null) => {
        const IsPayed = !!item?.IsPayed

        const fieldTemplates = {
            Notes: new Field('Notes', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full',
                label: 'Name'
            }, {
                disabled: IsPayed
            }),
            Deduction: new Field('Deduction', this.props.advances ? 0 : 1, [], false, 'hidden', {hideTable: true}),
            IncomePreAccountID: new Field('IncomePreAccountID', '', this.props.advances ? ['empty'] : [], false, this.props.advances ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                label: 'account',
            }),
            ExpensePreAccountID: new Field('ExpensePreAccountID', '', this.props.advances ? [] : ['empty'], false, !this.props.advances ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                label: 'account',
                render: (it) => it.Deduction ? it.ExpensePreAccount : it.IncomePreAccount
            }),
            IncomeAccountID: new Field('IncomeAccountID', '', this.props.advances ? ['empty'] : [], false, this.props.advances ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: !this.props.advances,
                label: 'advance_account'
            }),
            ExpenseAccountID: new Field('ExpenseAccountID', '', [], false, 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                label: 'advance_account',
                render: (it) => it.Deduction ? it.ExpenseAccount : it.IncomeAccount
            }),
            ContactID: new Field('ContactID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {
                disabled: IsPayed
            }),
            LoadID: new Field('LoadID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [''], false, 'date', {addContainerClass: 'col-span-full'}, {
                disabled: IsPayed
            }),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {addContainerClass: 'col-span-full'}, {
                disabled: IsPayed
            }),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                hideDialog: true
            }),
            IsDelayedPayment: new Field('IsDelayedPayment', '', [''], false, this.props.advances ? 'checkbox' : 'hidden', {
                addContainerClass: 'col-span-full'
            }),
            InterestRate: new Field('InterestRate', '', !item?.IsDelayedPayment ? [] : ['integer'], false, !item?.IsDelayedPayment ? 'hidden' : 'float', {addContainerClass: 'col-span-full'}, {}),
            LoanTerm: new Field('LoanTerm', '', !item?.IsDelayedPayment ? [] : ['integer'], false, !item?.IsDelayedPayment ? 'hidden' : 'integer', {addContainerClass: 'col-span-full'}, {}),
            IsApproved: new Field('IsApproved', '', [''], false, 'checkbox', {
                render: this.renderApprovedCell,
                hideDialog: true
            }, {})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {}, {isClearable: true}),
            LoadID: new Field('LoadID', '', [''], false, 'select-search', {}, {isClearable: true}),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}, {isClearable: true}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}, {isClearable: true}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getQuery = () => {
        const {
            ContactID,
            LoadID,
            StartDate,
            EndDate
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        const Deduction = this.props?.advances ? ['Deduction', '=', '0'] : ['Deduction', '=', '1']
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                Deduction: Deduction,
                ContactID: ContactID,
                LoadID: LoadID,
                ...(!!StartDate && {StartDate: ['Date', '>=', StartDate]}),
                ...(!!EndDate && {EndDate: ['Date', '<=', EndDate]}),
            })
        }
    }

    getResource = () => {
        return Resources.AccountingReimbursement
    }

    /** Render
     ================================================================= */
    renderApprovedCell = (item) => {
        return (
            <>
                {!!item.IsApproved && (
                    <div className="grid justify-items-center">
                        <CheckCircleIcon className={'w-5 h-5 text-green-600'}/>
                        <p className="text-xs">{item.ApprovedByContact}</p>
                    </div>
                )}
                {!item.IsApproved && (
                    <div className="grid justify-items-center">
                        <XMarkIcon className="text-tm-gray-400 w-5 h-5"/>
                    </div>

                )}
            </>
        )
    }

    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const contactCanApproveExpense = getProp(resource.data, 'Contact.CanApproveExpense', 0)
        const contactApprovalLimit = getProp(resource.data, 'Contact.MaxExpenseValue', 0)
        const lowLimitInfoMessage = `You are allowed to approve expenses up to $${contactApprovalLimit} only, please contact your manager.`

        const metadata = {
            ExpensePreAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE,
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },

            ExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_LIABILITY,
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },

            IncomePreAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },

            IncomeAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    AccountTypeID: ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
                    ...DEFAULT_METADATA_SELECT_SEARCH_QUERY()
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                    value: it.ContactID
                })
            },
            LoadID: {
                api: 'api/' + Resources.LoadsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.LoadNumber,
                    value: it.LoadID
                })
            },
        }

        return (
            <>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <div className={'flex mr-4'}>
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields({IsDelayedPayment: true})}
                        verticalTableIsVisible={this.props.breakpoint?.index ? this.props.breakpoint.index <= 1 : false}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={(item) => {
                            return (checkPerm(this.getResource(), UPDATE_PERM) && (item.IsPayed !== 1)) ? this.handleToggleUpdateModal(item) : null
                        }}

                        actions={[
                            {
                                action: this.handleToggleUpdateModal,
                                icon: PencilIcon,
                                visible: (item) => checkPerm(this.getResource(), UPDATE_PERM) && item.IsPayed !== 1,
                                label: false,
                                title: translate('text.edit'),
                                disabled: false,
                                class: false,
                                iconClass: false
                            },
                            {
                                action: this.handleToggleApproveModal,
                                icon: CheckIcon,
                                visible: (item) => contactCanApproveExpense && !item.IsApproved,
                                label: false,
                                title: translate('text.approve_reimbursement'),
                            },
                            {
                                action: this.deleteResource,
                                icon: TrashIcon, // make this a function
                                visible: (item) => checkPerm(this.getResource(), DELETE_PERM) && (item.IsPayed !== 1),
                                label: false, // make this a function
                                title: translate('text.delete'),
                                disabled: false,
                                class: false,
                                iconClass: false
                            },
                        ]}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >

                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint?.index ? this.props.breakpoint.index <= 1 : false}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={translate('text.no_matching_records')}
                        text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                        btnLabel={translate('btn.create_new')}
                        onBtnClick={() => {
                            this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                this.handleToggleCreateModal()
                            })
                        }}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate('text.clear_all_filters')}
                        clearFilterText={translate('text.try_without_filters')}
                        filters={this.state.queryFilterFields}
                    />
                </TableCard>

                <ModalSaveResourceWithDropZone
                    title={translate(this.props.advances ? 'text.EditAdvances' : 'text.EditReimbursement')}
                    onAcceptFiles={(files) => this.handleAcceptFiles(files)}
                    maxFilesAccepted={1}
                    fileUploadInfoText={'Max file size 25 MB.'}
                    files={this.state.files}
                    documentPaths={this.state.existingFiles}
                    accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                    canSubmit={this.state.canModalSubmit}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    errorMessage={this.state.errorMessage}
                    onClose={() => {
                        this.state.filePreview.show ? this.closePreview() : this.handleToggleUpdateModal()
                    }}
                    onClosePreview={this.closePreview}
                    fields={this.getFields(this.state.selectedItem)}
                    handleInputChange={this.handleInputChange}
                    filePreview={this.state.filePreview}
                    onSubmit={(params) => {
                        if (params) {
                            params.ReimbursementID = this.state.selectedItem?.ReimbursementID

                            if (!!this.state.selectedItem.DocumentPath && !this.state.existingFiles.length && !this.state.files?.length) {
                                params.DocumentPath = '1' // delete document
                            }

                            if (!this.props.advances) {
                                delete params.IncomeAccountID
                                delete params.IncomePreAccountID
                            }

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                file: this.state.files,
                                fileResource: Resources.AccountingReimbursementDocument,
                                errorMessage: true,
                                successMessage: this.props.translate(this.props.advances ? 'message.advances_updated' : 'message.reimbursement_updated')
                            }))
                            this.handleToggleUpdateModal(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    htmlBefore={
                        <React.Fragment>
                            {(!!this.state.files.length || !!this.state.existingFiles?.length) && (
                                <div className="px-6 pt-6 space-y-4">
                                    {!!this.state.existingFiles?.length && (
                                        <div>
                                            <h2
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                            >
                                                Files
                                            </h2>

                                            <FileList
                                                filePaths={this.state.existingFiles}
                                                onFileDelete={() => {
                                                    this.setState({
                                                        existingFiles: [],
                                                        canModalSubmit: true
                                                    })
                                                }}
                                                onFileView={(file) => this.handleFileView(file, this.state?.selectedItem?.DocumentPath)}
                                                // onFileDownload={() => null}
                                            />
                                        </div>
                                    )}

                                    {!!this.state.files?.length && (
                                        <div>
                                            <h2
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                            >
                                                Files to upload
                                            </h2>

                                            <FileList
                                                files={this.state.files}
                                                onFileDelete={() => this.setState({
                                                    files: []
                                                })}
                                                onFileView={(file) => {
                                                    this.handleFileView(file)
                                                }}
                                                // onFileDownload={() => null}
                                            />

                                        </div>
                                    )}

                                    <span className="text-xs text-tm-gray-600">
                                            One document allowed. Delete existing to upload a new one.
                                        </span>
                                </div>

                            )}
                        </React.Fragment>
                    }
                    htmlAfter={
                        <React.Fragment>
                            <AccountingLoadBreakdown
                                show={this.state.cachedChangedFields?.IsDelayedPayment?.value}
                                onShowMonthlyBreakdownClick={this.handleShowMonthlyBreakdownClick}
                                cachedChangedFields={this.state.cachedChangedFields}
                                translate={translate}
                            />

                            <AccountingMonthlyBreakdown
                                show={this.state.isMonthlyBreakdownOpen}
                                onShowMonthlyBreakdownClick={this.handleShowMonthlyBreakdownClick}
                                cachedChangedFields={this.state.cachedChangedFields}
                                translate={translate}
                            />
                        </React.Fragment>
                    }
                />

                <ModalSaveResourceWithDropZone
                    show={!!this.state.createModalOpen}
                    isLoading={resource.isLoading}
                    maxFilesAccepted={1}
                    title={translate(this.props.advances ? 'text.CreateAdvances' : 'text.CreateReimbursement')}
                    fileUploadInfoText={'Max file size 25 MB.'}
                    files={this.state.files}

                    onAcceptFiles={(files) => this.handleAcceptFiles(files)}
                    accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                    canSubmit={this.state.canModalSubmit}
                    filePreview={this.state.filePreview}
                    onClosePreview={this.closePreview}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                file: this.state.files,
                                fileResource: Resources.AccountingReimbursementDocument,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: this.props.translate(this.props.advances ? 'message.advances_created' : 'message.reimbursement_created'),
                            }))
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    htmlBefore={
                        <React.Fragment>
                            {!!this.state.files.length && (
                                <div className="px-6 pt-6">
                                    <h2
                                        className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                    >
                                        Files
                                    </h2>

                                    <FileList
                                        files={this.state.files}
                                        onFileDelete={() => this.setState({
                                            files: []
                                        })}
                                        onFileView={(file) => this.handleFileView(file)}
                                        // onFileDownload={() => null}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    }
                    htmlAfter={
                        <React.Fragment>
                            <AccountingLoadBreakdown
                                show={this.state.cachedChangedFields?.IsDelayedPayment?.value}
                                onShowMonthlyBreakdownClick={this.handleShowMonthlyBreakdownClick}
                                cachedChangedFields={this.state.cachedChangedFields}
                                translate={translate}
                            />

                            <AccountingMonthlyBreakdown
                                show={this.state.isMonthlyBreakdownOpen}
                                onShowMonthlyBreakdownClick={this.handleShowMonthlyBreakdownClick}
                                cachedChangedFields={this.state.cachedChangedFields}
                                translate={translate}
                            />

                            {/*display files here*/}
                        </React.Fragment>
                    }
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.approveReimbursementModalOpen}
                    text={translate('message.confirm_approve_reimbursement')}
                    onClose={this.handleToggleApproveModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={translate('btn.cancel')}
                    translate={translate}
                    onConfirm={() => {
                        const ReimbursementID = this.state.selectedItem.ReimbursementID
                        const reimbursementAmount = this.state.selectedItem.Amount

                        if (contactApprovalLimit >= reimbursementAmount) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: {
                                    ReimbursementID
                                },
                                query: this.getQuery(),
                                errorMessage: true,
                                successMessage: this.props.translate(`message.reimbursement_approved`),
                                resource: Resources.AccountingReimbursementApprove,
                                piggyResource: this.getResource()
                            }))
                            this.handleToggleApproveModal()
                        } else {
                            this.handleToggleApproveModal()
                            this.toggleLimitBelowRequiredDialog()
                        }

                    }}
                />

                {this.state.isLimitBelowRequiredDialogOpen && (
                    <ModalConfirm
                        title={translate('text.low_limit')}
                        type={'info'}
                        show={this.state.isLimitBelowRequiredDialogOpen}
                        text={lowLimitInfoMessage}
                        textClassName={'text-sm text-tm-gray-900'}
                        onClose={() => this.toggleLimitBelowRequiredDialog()}
                        buttonLabel={translate('btn.confirm')}
                        onConfirm={() => this.toggleLimitBelowRequiredDialog()}
                    />
                )}

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </>
        )
    }
}

export default connect(state => state)(AdvancesReimbursementTab)
