import React, { Component } from 'react'
import { connect } from 'react-redux'
import Resources from '../../../data/services/resources'
import { checkPerm } from '../../../common/util/util-helpers'
import {UPDATE_PERM} from '../../../util/util-constants'
import LoadPricingMatrixTab from './load-pricing-matrix-tab'
import CommodityPricingMatrixTab from './commodity-pricing-matrix-tab'
import FuelSurchargeMatrixTab from "./fuel-surcharge-matrix-tab";
import Page from "../../../common/components/layout/layout-components/page";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import Layout from "../../../common/components/layout";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";

class MatricesView extends Component {
    constructor (props) {
        super(props)

        const tabs = [
            {
                name: 'LoadPricingMatrix',
                resource: Resources.LoadPricingMatrix,
                current: false,
                visible: checkPerm(Resources.LoadPricingMatrix, UPDATE_PERM)
            },
            {
                name: 'CommodityPricingMatrix',
                resource: Resources.CommodityPricingMatrix,
                current: false,
                visible: checkPerm(Resources.CommodityPricingMatrix, UPDATE_PERM)
            },
            {
                name: 'FuelSurchargeMatrix',
                resource: Resources.FuelSurcharge,
                current: false,
                visible: checkPerm(Resources.FuelSurcharge, UPDATE_PERM)
            }
        ]
        tabs[0].current = true

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,
            breakpoint: {}
        }
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        });
    }

    /** Render
     ================================================================= */
    render () {
        const { translate, resource } = this.props

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.Matrices')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                     <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.LoadPricingMatrix && (
                        <LoadPricingMatrixTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.CommodityPricingMatrix && (
                        <CommodityPricingMatrixTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.FuelSurcharge && (
                        <FuelSurchargeMatrixTab
                            breakpoint={this.state.breakpoint}
                            {...this.props}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(MatricesView)
