import React, {useMemo} from "react";
import {FieldsManager} from "../../../../data/services/fields";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {classNames} from "../../../../common/util/util-helpers";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import Card from "../../../../common/components/card";
import {LoaderSmall} from "../../../../common/components/loader";

export default function DriverPayrollSummary({data, getSummaryFields, translate, toggleExpandSection, isSectionExpanded, isLoading}) {
    const fields = useMemo(() => {
        return FieldsManager.getFieldKeyValues(
            getSummaryFields(data)
        );
    }, [data]);

    return (
        <div>
            <div className="flex items-center">
                <button
                    className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                    onClick={() => toggleExpandSection("isSummarySectionExpanded")}
                >
                    <ChevronRightIcon
                        className={
                            classNames(
                                "w-5 h-5",
                                isSectionExpanded ? "rotate-90" : undefined
                            )
                        }
                    />
                </button>

                <Subtitle subtitle={translate("text.summary")}/>
            </div>

            {isSectionExpanded && (
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                    {Object.keys(fields).map((item) => (
                        <Card bodyClass="py-3 px-6" key={item}>
                            <dt className="truncate text-sm font-medium text-tm-gray-500">{translate("field." + item)}</dt>
                            <dd className="mt-1 text-2xl font-semibold tracking-tight text-primary">
                                {!isLoading && (
                                    genericMoneyFormatter(fields[item])
                                )}

                                {isLoading && (
                                    <div className="relative w-8 h-8">
                                        <LoaderSmall/>
                                    </div>
                                )}
                            </dd>
                        </Card>
                    ))}
                </dl>
            )}
        </div>
    )
}