import {useEffect} from 'react';

export default function TableStyles({
                                        data,
                                        tableID,
                                        headerData,
                                        options,
                                        rowMinHeight,
                                        setRowMinHeight,
                                        getDefaultRowMinHeight,
                                        setTableStyle,
                                        setTableBodyHeight,
                                        setTableBodyWidth,
                                        tableRef,
                                        isVirtualizedRowTable,
                                        isRowClickable,
                                        isLoading,
                                        limit,
                                        tfoot,
                                        sizeChangeCount,
                                        tableContainerRef,
                                        isInitialLoadingRef,
                                        tableWidthRef,
                                        hasActions
                                    }) {

    const getDefaultCellClass = () => {
        const defaultClassName = isVirtualizedRowTable ? `vtc-${tableID}-default` : `tc-${tableID}-default`;
        const horizontalLines = options?.style?.horizontalLines;
        const verticalLines = options?.style?.verticalLines;

        return `
.${defaultClassName} {
padding-left: 0.375rem;
border-color: var(--color-gray-200);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
${isVirtualizedRowTable ? "display: flex;" : "display: table-cell;"}
${isVirtualizedRowTable ? "align-items: center;" : "vertical-align: middle;"}
height: ${rowMinHeight}px;
${horizontalLines ? "border-bottom-width: 1px;" : ""}
${verticalLines ? 'border-right-width: 1px;' : ""}
}
`
    }

    const getFirstCellClass = () => {
        return `.tc-first {padding-left: 1.5rem!important;}`
    }

    const getLastCellClass = () => {
        return `.tc-last {padding-right: 1.5rem!important}`
    }


    const getDefaultHeaderCellClass = () => {
        const verticalLines = options?.style?.verticalLines;
        const defaultClassName = isVirtualizedRowTable ? `vth-${tableID}-default` : `th-${tableID}-default`;
        return `
.${defaultClassName} {
padding-right: 1.625rem;
padding-left: 0.375rem;
border-color: var(--color-gray-200);
background-color: var(--color-gray-100);
border-bottom-width: 1px;
white-space: nowrap;
${isVirtualizedRowTable ? "display: flex;" : "display: table-cell;"}
${isVirtualizedRowTable ? "align-items: center;" : "vertical-align: middle;"}
${verticalLines ? 'border-right-width: 1px;' : ""}
height: 2.25rem;
position: sticky;
top: 0;
}
`
    }

    const generateCellClass = (it, isFrozen, cumulativeFrozenLeft) => {
        const defaultClassName = isVirtualizedRowTable ? `vtc-${it.keyPlusID}` : `tc-${it.keyPlusID}`;

        const verticalLines = options?.style?.verticalLines;
        const horizontalLines = options?.style?.horizontalLines;
        const frozenActionColumn = options?.style?.frozenActionColumn;
        const floatingActions = options?.style?.floatingActions;

        switch (it.key) {

            case 'select-row-column':
                return `.vtc-${it.key} {
border-color: var(--color-gray-200);
border-right-width: 1px;
${isVirtualizedRowTable ? "display: flex;" : "display: table-cell;"}
${isVirtualizedRowTable ? "align-items: center;" : "vertical-align: middle;"}
${isVirtualizedRowTable ? "justify-content: center;" : "text-align: center;"}
width: 48px;
height: ${rowMinHeight}px;
position: sticky;
z-index: 10;
left: 0;
background: inherit;
${horizontalLines ? "border-bottom-width: 1px;" : ""};
${getTextAlignmentClass(it.align)}
}
`
            case 'actions':
                return `.vtc-${it.keyPlusID} {
border-color: var(--color-gray-200);
height: ${rowMinHeight}px;
padding: 0;
${isVirtualizedRowTable ? "display: flex;" : "display: table-cell;"}
${floatingActions || !isVirtualizedRowTable ? "width: 0;" : "width: calc(var(--vtc-actions) * 1px);"}
${frozenActionColumn || floatingActions ? "right: 0;" : ""}
${frozenActionColumn || floatingActions ? "position: sticky; z-index: 11;" : ""}
${frozenActionColumn ? "background-color: inherit;" : ""}
${frozenActionColumn ? "border-left-width: 1px;" : ""}
${!floatingActions && horizontalLines ? "border-bottom-width: 1px;" : ""}
}
`
            default:
                return `
.${defaultClassName} {
border-color: var(--color-gray-200);
width: calc(var(--vtc-${it.key}) * 1px);
${isFrozen ? 'position: sticky; z-index: 10;' : ""}
${isFrozen ? 'left: ' + cumulativeFrozenLeft + "px;" : ""}
${verticalLines || isFrozen ? 'border-right-width: 1px;' : ""}
${isFrozen ? 'background: inherit;' : ""}
${getTextAlignmentClass(it.align)}
${it.align === 'right' || it.align === 'center' ? "padding-right: 1.625rem;" : "padding-right: 0.375rem;"}
}
.${defaultClassName + "-filter"} {
width: calc(var(--vtc-${it.key}) * 1px);
${isFrozen ? 'position: sticky; z-index: 10;' : ""}
${isFrozen ? 'left: ' + cumulativeFrozenLeft + "px;" : ""}
}
`
        }
    }

    const generateHeaderColumnClass = (it, isFrozen, cumulativeFrozenLeft) => {
        const verticalLines = options?.style?.verticalLines;
        const frozenActionColumn = options?.style?.frozenActionColumn;
        const floatingActions = options?.style?.floatingActions;

        switch (it.key) {

            case 'select-row-column':
                return `.vth-${it.key} {
border-color: var(--color-gray-200);
border-bottom-width: 1px;
border-right-width: 1px;
background-color: var(--color-gray-50);
${isVirtualizedRowTable ? "display: flex;" : "display: table-cell;"}
${isVirtualizedRowTable ? "align-items: center;" : "vertical-align: middle;"}
${isVirtualizedRowTable ? "justify-content: center;" : "text-align: center;"}
width: 48px;
min-width: 48px;
height: 2.25rem;
position: sticky;
left: 0;
top: 0;
z-index: 21;
}
`

            case 'actions':
                return `.vth-${it.keyPlusID} {
height: 2.25rem;
border-color: var(--color-gray-200);
border-bottom-width: 1px;
background-color: var(--color-gray-100);
padding: 0;
position: sticky;
top: 0;
${isVirtualizedRowTable ? "display: flex;" : "display: table-cell;"}
${isVirtualizedRowTable ? "align-items: center;" : "vertical-align: middle;"}
${isVirtualizedRowTable ? "justify-content: center;" : "text-align: center;"}
${floatingActions ? "width: 0;" : "width: calc(var(--vtc-actions) * 1px); padding-left: 1.625rem; padding-right: 1.625rem;"}
${frozenActionColumn ? "z-index: 30;" : ""}
${frozenActionColumn ? "right: 0;" : ""}
${frozenActionColumn ? "border-left-width: 1px;" : ""}
}
`

            default: {
                const defaultClassName = isVirtualizedRowTable ? `vth-${it.keyPlusID}` : `th-${it.keyPlusID}`;

                return `
.${defaultClassName} {
width: calc(var(--vtc-${it.key}) * 1px);
${it.align === 'center' ? 'padding-left: 1.625rem;' : 'padding-left: 0.375rem;'}
${getTextAlignmentClass(it.align)}
${isFrozen ? 'left: ' + cumulativeFrozenLeft + "px;" : ""}
${isFrozen ? 'z-index: 10;' : ""}
${isFrozen || verticalLines ? 'border-right-width: 1px;' : ""}
}
`
            }
        }
    }

    const getRowClass = () => {
        const defaultClassName = isVirtualizedRowTable ? `vt-${tableID}-row` : `t-${tableID}-row`;

        return `.${defaultClassName}{
height: ${rowMinHeight}px;
${isRowClickable ? 'cursor: pointer;' : ''}
${isVirtualizedRowTable ? 'display: flex;' : 'display: table-row;'}
${isVirtualizedRowTable ? 'position: absolute;' : ''}
}
.vt-row:hover{
background-color: var(--color-primary);
}
.vt-footer-row{
height: ${rowMinHeight}px;
z-index: 29;
${isVirtualizedRowTable ? 'display: flex;' : 'display: table-row;'}
position: sticky;
}
`
    }

    function getTextAlignmentClass(align) {

        if (align === 'right') {
            return isVirtualizedRowTable ? "justify-content: flex-end;" : "text-align: right;"
        }

        if (align === 'center') {
            return isVirtualizedRowTable ? "justify-content: center;" : "text-align: center;"
        }

        return "";
    }

    function getDefaultColumnWidth() {
        let defaultColMinWidth = 200;
        let definedWidth = 0;
        let actionsWidth = hasActions && (headerData?.[headerData?.length - 1]?.columnWidth ?? 0);
        const tableContainerWidth = tableContainerRef?.current?.clientWidth;

        const columnsWithUndefinedWidth = Object.values(options?.columns).filter(col => col.show).reduce((memo, it) => {
            if (!it.minWidth) {
                memo.push(it.name);
            } else {
                definedWidth = definedWidth + Number(it.minWidth);
            }
            return memo;
        }, []);

        if (!!actionsWidth && !options?.style?.floatingActions) {
            if (actionsWidth < 55) {
                actionsWidth = 55; // Min actions width
            }
            definedWidth = definedWidth + actionsWidth;
        }

        if (tableContainerWidth > columnsWithUndefinedWidth.length * defaultColMinWidth) {

            const availableWidth = tableContainerWidth - (definedWidth + (headerData[0].key === 'select-row-column' ? 48 : 0));

            const minWidth = availableWidth / columnsWithUndefinedWidth.length;

            return minWidth > defaultColMinWidth ? minWidth : defaultColMinWidth;
        }



        return defaultColMinWidth;
    }

    useEffect(() => {
        let cumulativeFrozenLeft = headerData[0].key === 'select-row-column' ? 48 : 0;



        const cellDefaultClass = getDefaultCellClass();
        const headerCellDefaultClass = getDefaultHeaderCellClass();
        const defaultColumnWidth = getDefaultColumnWidth();
        let tableClasses = headerData.reduce((memo, it) => {

            let width = options?.columns[it.key]?.minWidth ?? defaultColumnWidth;

            let isFrozen = options?.columns?.[it.key]?.frozen;

            const cellClass = generateCellClass(it, isFrozen, cumulativeFrozenLeft);
            const headerColumnClass = generateHeaderColumnClass(it, isFrozen, cumulativeFrozenLeft);

            cumulativeFrozenLeft = isFrozen
                ? cumulativeFrozenLeft + Number(width.toString().replace("px", ""))
                : cumulativeFrozenLeft;

            memo = memo + cellClass + headerColumnClass;
            return memo;
        }, "")

        const style = document.createElement("style");
        document.head.appendChild(style);
        style.innerHTML = tableClasses + cellDefaultClass + headerCellDefaultClass + getFirstCellClass() + getLastCellClass() + getRowClass();

        return () => {
            style.remove();
        }
    }, [options, isVirtualizedRowTable, headerData, rowMinHeight, limit])

    useEffect(() => {
        if (isVirtualizedRowTable) {
            // Set table styles
            let rowMinHeight = getDefaultRowMinHeight(options);
            let maxStackedColumns = 0;
            let tableWidth = 0;
            let generatedTableStyle = {};
            const defaultColumnWidth = getDefaultColumnWidth();
            headerData.forEach(it => {
                let width = options?.columns[it.key]?.minWidth ?? defaultColumnWidth;
                if (it.key === 'select-row-column') {
                    width = 48;
                }

                if (it.key === 'actions') {
                    width = options?.style?.floatingActions ? 0 : it.columnWidth;
                }

                tableWidth = Number(tableWidth) + Number(width);
                generatedTableStyle = Object.assign(generatedTableStyle, {[`--vtc-${it.key}`]: width})
                if ((it?.subColumns?.length ?? 0) > maxStackedColumns) {
                    maxStackedColumns = it.subColumns?.length;
                    rowMinHeight = 24 + 8 + (maxStackedColumns * 24); // min height, padding, data + padding
                }
            });

            tableWidthRef.current = tableWidth;
            setRowMinHeight(rowMinHeight);
            setTableStyle(generatedTableStyle);


            // Define table body height
            if (!isLoading) {
                if (!!tfoot && isVirtualizedRowTable) {
                    setTableBodyHeight((data.length * rowMinHeight) + rowMinHeight);
                } else {
                    setTableBodyHeight(data.length * rowMinHeight);
                }

                setTableBodyWidth(tableWidth);
            }

            if (isInitialLoadingRef.current) {
                setTableBodyHeight(rowMinHeight);
            }
        }
    }, [data, headerData, tableRef, options, isLoading, sizeChangeCount])

    return null;
}
