import React from "react";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";

const ModalHeader = (props) => {
    const {onClose, title, className} = props;

    return (
        <header className={className ?? "border-tm-gray-200 border-b text-tm-gray-900"}>
            <div className="p-4 flex justify-between items-center">
                <h2 className="text-lg text-current text-nowrap font-semibold">{title}</h2>

                <div className="flex-grow hidden md:flex">
                    {props.children}
                </div>

                {!props.hideXbtn && (
                    <button
                        className="absolute right-5 top-4.5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary rounded-full"
                        aria-label="Close"
                        onClick={() => {
                            onClose();
                        }}>
                        <XMarkIcon className="w-5 h-5"/>
                    </button>
                )}
            </div>

            <div className="flex flex-grow md:hidden pb-4">
                {props.children}
            </div>
        </header>
    )
}

export default ModalHeader;