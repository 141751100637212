import React from "react";
import FieldSwitch from "../fields/field-switch";
import ButtonsGroup from "../buttons/buttons-group";
import FieldsToHtml from "../../../common/components/fields/fields-to-html"

export default function TableOptionsModal({
                                              queryFields,
                                              handleQueryChange,
                                              tableOptions,
                                              setTableOptions,
                                              selects,
                                              translate
                                          }) {

    const areAllFieldsHidden = Object.values(tableOptions.columns).find(it => it.name !== "Driver" && !it.hidden);

    function updateFieldsVisibility(name) {
        const tableOptionsUpdate = Object.assign({}, tableOptions);
        tableOptionsUpdate.columns[name].hidden = !tableOptionsUpdate.columns[name].hidden;
        tableOptionsUpdate.columns[name].width = tableOptionsUpdate.columns[name].hidden ? 0 : tableOptionsUpdate.columns[name].defaultWidth;
        setTableOptions(tableOptionsUpdate);
    }

    function toggleFieldVisibility() {
        let tableOptionsUpdate = Object.assign({}, tableOptions);

        tableOptionsUpdate.columns = Object.values(tableOptionsUpdate?.columns ?? {}).reduce((memo, field) => {
            memo[field.name] = field;

            if ("Driver" !== field.name) {
                memo[field.name].hidden = !!areAllFieldsHidden;
                memo[field.name].width = areAllFieldsHidden ? 0 : memo[field.name].defaultWidth;
            }
            return memo;
        }, {})

        setTableOptions(tableOptionsUpdate);
    }

    function updateTableStyles(key) {
        const tableOptionsUpdate = Object.assign({}, tableOptions);

        tableOptionsUpdate.style[key] = !tableOptionsUpdate.style[key];

        setTableOptions(tableOptionsUpdate);
    }

    return (
        <div className="grid grid-cols-12 gap-4 pb-8">
            <div className="p-4 col-span-7 text-base">
                <div className="text-xl mb-0.5">Table filters</div>
                <div className="mb-4 text-tm-gray-600">Filter data based on various parameters</div>

                <div className="flex flex-col gap-4">
                    <FieldsToHtml
                        excludeFields={['query']}
                        fieldsState={queryFields}
                        onInputChange={handleQueryChange}
                        selects={selects}
                        translate={translate}
                    />
                </div>

                <div className="text-xl mb-0.5 mt-6">Gross/Mileage calculation</div>
                <div className="mb-4 text-tm-gray-600">Calculate Gross/Mileage based on:</div>

                <ButtonsGroup
                    data={{
                        dispatch: <div className="flex"> {translate('btn.dispatch_price')}</div>,
                        total: <div className="flex"> {translate('btn.total_price')}</div>
                    }}
                    value={tableOptions?.grossMileageType}
                    onChange={(_, value) => setTableOptions(Object.assign({}, tableOptions, { 'grossMileageType': value }))}
                />

                <div className="mb-1"></div>
                <ButtonsGroup
                    data={{
                        pickup: <div className="flex"> {translate('btn.PickupDate')}</div>,
                        delivery: <div className="flex"> {translate('btn.DeliveryDate')}</div>
                    }}
                    value={tableOptions?.grossDateType}
                    onChange={(_, value) => setTableOptions(Object.assign({}, tableOptions, { 'grossDateType': value }))}
                />
            </div>

            <div className="col-span-5 p-4 text-base">
                <div className="text-xl mb-0.5">Table columns</div>
                <div className="mb-4 text-tm-gray-600">Select visible columns</div>

                <div className="flex gap-4 items-center">
                    <FieldSwitch
                        id="AssignedToMe"
                        size="small"
                        value={!areAllFieldsHidden}
                        onChange={toggleFieldVisibility}
                    />
                    <div>Hide all driver details</div>
                </div>

                <div className="space-y-2 mt-2 pt-2 border-t border-tm-gray-200">
                    {Object.values(tableOptions.columns).map(field => {
                        if (field.name !== 'Driver') {
                            return <div key={field.name}>
                                <div className="flex gap-4 items-center">
                                    <FieldSwitch
                                        name={field.name}
                                        size="small"
                                        value={!field.hidden}
                                        onChange={updateFieldsVisibility}
                                    />
                                    <div>{field.name}</div>
                                </div>
                            </div>
                        }
                    })}
                </div>

                <div className="text-xl mb-0.5 mt-6">Compressed view</div>
                <div className="mb-4 text-tm-gray-600">Show less information in cells</div>

                <div key={"compressedView"}>
                    <div className="flex gap-4 items-center">
                        <FieldSwitch
                            name={"compressedView"}
                            size="small"
                            value={tableOptions?.compressedView}
                            onChange={(_, value) => setTableOptions(Object.assign({}, tableOptions, { 'compressedView': value }))}
                        />
                        <div>Compressed view</div>
                    </div>
                </div>

                <div className="text-xl mb-0.5 mt-6">Table style</div>
                <div className="mb-4 text-tm-gray-600">Modify table look</div>

                <div className="space-y-2">
                    <div className="flex gap-4 items-center">
                        <FieldSwitch
                            name={'HorizontalLines'}
                            size="small"
                            value={tableOptions.style.showHorizontalLines}
                            onChange={() => updateTableStyles("showHorizontalLines")}
                        />
                        <div>Horizontal Lines</div>
                    </div>

                    <div className="flex gap-4 items-center">
                        <FieldSwitch
                            name={"VerticalLines"}
                            size="small"
                            value={tableOptions.style.showVerticalLines}
                            onChange={() => updateTableStyles("showVerticalLines")}
                        />
                        <div>Vertical Lines</div>
                    </div>
                </div>
            </div>
        </div>
    )
}