export function getCommentResource(data) {
    return {
        type: 'GET_COMMENT_RESOURCE',
        data: data
    };
}

export function doneGetCommentResource(data) {
    return {
        type: 'DONE_GET_COMMENT_RESOURCE',
        data: data
    };
}

export function errorGetCommentResource(data) {
    return {
        type: 'ERROR_GET_COMMENT_RESOURCE',
        data: data
    };
}

export function createCommentResource(data) {
    return {
        type: 'CREATE_COMMENT_RESOURCE',
        data: data
    };
}

export function doneCreateCommentResource(data) {
    return {
        type: 'DONE_CREATE_COMMENT_RESOURCE',
        data: data
    };
}

export function errorCreateCommentResource(data) {
    return {
        type: 'ERROR_CREATE_COMMENT_RESOURCE',
        data: data
    };
}

export function deleteCommentResource(data) {
    return {
        type: 'DELETE_COMMENT_RESOURCE',
        data: data
    };
}

export function doneDeleteCommentResource(data) {
    return {
        type: 'DONE_DELETE_COMMENT_RESOURCE',
        data: data
    };
}

export function errorDeleteCommentResource(data) {
    return {
        type: 'ERROR_DELETE_COMMENT_RESOURCE',
        data: data
    };
}

export function resetCommentResource() {
    return {
        type: 'RESET_COMMENT_RESOURCE',
        data: []
    }
}

export function updateCommentResource(data) {
    return {
        type: 'UPDATE_COMMENT_RESOURCE',
        data: data
    };
}

export function doneUpdateCommentResource(data) {
    return {
        type: 'DONE_UPDATE_COMMENT_RESOURCE',
        data: data
    };
}

export function errorUpdateCommentResource(data) {
    return {
        type: 'ERROR_UPDATE_COMMENT_RESOURCE',
        data: data
    };
}
