import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download, uploadDocument} from '../../../data/actions/download'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {fieldsToHtml, fillFieldsFromData} from '../../../common/util/util-fields';
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_ASSET,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep, scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import Dropzone from 'react-dropzone'
import {DocumentTextIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {createDialogResource, deleteDialogResource} from '../../../data/actions/dialogResource'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import InfoParagraph from "../../../common/components/info-paragraph";
import DocumentCard from "../../../common/components/card/document-card";
import {LoaderLarge} from "../../../common/components/loader";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";

class TranspondersView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)


        this.defaultTableOptions = {
            behaviour: {
                hasVirtualRows: true
            },
            style: {
                condensed: true,
                horizontalLines: true,
                verticalLines: true,
                headerHeightClass: 'h-8',
                floatingActions: false,
                frozenActionColumn: true,
            },
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'CreateUpdateDate',
            sort: 'DESC',

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            importFields: this.getImportFields(),

            metadata: {
                ChargeToContactID: this.getContactMetadata()
            },
            breakpoint: {},

            isImportDataModalOpen: false,
            isPreviewImportDataModalOpen: false,
            isImportPreviewListModalOpen: false,

            rawData: [],
            data: [],
            files: [],

            canSubmit: false,

        }

        this.tableOptions = this.lineHaulTableOptions = getDefaultTableOptions(this.getImportFields(), this.defaultTableOptions, this.pagePath, this.props.translate);

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.isPreviewImportDataModalOpen !== this.state.isPreviewImportDataModalOpen) {
            this.setState({
                importFields: this.getImportFields()
            })
        }

        if (!prevProps.download.data && this.props.download.data) {
            let formattedData = this.props.download.data.map(row => {
                    return row.reduce((memo, item, index) => {
                        memo['ColTrans' + index] = item
                        return memo
                    }, {})
                }
            )

            formattedData = formattedData.filter(row => !this.isRowEmpty(Object.values(row)))

            this.setState({
                rawData: this.props.download.data,
                data: formattedData,
                isPreviewImportDataModalOpen: true
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: this.getQuery()
        }))
    }

    handleSubmitDocument = () => {
        this.props.dispatch(uploadDocument({
            user: LocalStorage.get('user'),
            resource: Resources.TransponderImportPreview,
            files: this.state.files
        }))
        this.setState({
            isImportDataModalOpen: false,
            files: []
        })
    }

    handleConfirmImport = () => {
        this.setState({
            importFields: FieldsManager.validateFields(this.state.importFields)
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.importFields)) {
                this.props.dispatch(createDialogResource({
                    user: LocalStorage.get('user'),
                    resource: Resources.TransponderImport,
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.importFields), {
                        Items: this.state.rawData,
                    }),
                    query: this.getQuery(),
                    errorMessage: true,
                    successMessage: this.props.translate('text.import_success')
                }))
                this.setState({
                    isPreviewImportDataModalOpen: false
                })
            } else {
                scrollErrorIntoViewFields(this.state.importFields)
            }
        })
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.Transponder}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {TransponderID: item.TransponderID},
                        piggyQuery: this.getQuery(),
                        resource: this.getResourceName(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.Transponder]),
                        piggyResource: this.getResourceName()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_restore_generic')} ${item.Transponder}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            TransponderID: item.TransponderID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Transponder restored`,
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                })
            }, item)
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({format: 'EXCEL', name: 'transponders_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'transponders_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleCreateModal = (prefilled = '') => {
        this.setState({
            prefilled: prefilled,
            selectedItem: null,
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleUpdateModal = (item = null) => {
        let metadata = this.state.metadata
        if (item) {
            const GenericIDMetadata = item?.GenericTypeID == 1 ? this.getDriverMetadata() :
                item?.GenericTypeID == 2 ? this.getTruckMetadata() : {}
            metadata = {
                ...metadata,
                GenericID: GenericIDMetadata
            }
        }

        this.setState({
            metadata: metadata,
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleImportListModal = () => {
        this.setState({
            isImportPreviewListModalOpen: !this.state.isImportPreviewListModalOpen
        })
    }

    handleInputChange = (fields, name, value) => {
        if (name === 'ChargeToTypeID') {
            if (value == 1) {
                fields.ChargeToContactID.metadata.hideDialog = false
                fields.ChargeToContactID.validate = ['empty']
                fields.ChargeToContactID.key = value
                return FieldsManager.updateField(fields, name, value)
            } else {
                fields.ChargeToContactID.metadata.hideDialog = true
                fields.ChargeToContactID.value = ''
                fields.ChargeToContactID.validate = ['']
                fields.ChargeToContactID.key = value
                return FieldsManager.updateField(fields, name, value)
            }
        }

        if (name === 'GenericTypeID') {
            let GenericIDLabel = value == 1 ? 'Driver' :
                value == 2 ? 'Truck' : 'GenericID'

            fields.GenericID.metadata.hideDialog = false
            fields.GenericID.value = ''
            fields.GenericID.key = GenericIDLabel
            fields.GenericID.metadata.label = GenericIDLabel

            const GenericIDMetadata = value == 1 ? this.getDriverMetadata() :
                value == 2 ? this.getTruckMetadata() : {}
            this.setState({
                metadata: {
                    ...this.state.metadata,
                    GenericID: GenericIDMetadata
                }
            }, () => {
                return FieldsManager.updateField(fields, name, value)
            })
        }

        return FieldsManager.updateField(fields, name, value)
    }

    handleImportFieldsInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.importFields, name, value)
        this.setState({importFields: fields})
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleOpenImportDataModal = () => {
        this.setState({
            isImportDataModalOpen: true
        })
    }

    handleDeleteImport = (item) => {
        this.setState({
            selectedItem: item,
            isConfirmDeleteImportModalOpen: true
        })
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        })
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        })
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
        })
    }

    /** Helpers
     ================================================================= */
    getContactMetadata = () => {
        return {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + ' ' + item.LastName
            })
        }
    }

    getDriverMetadata = () => {
        return {
            api: 'api/' + Resources.DriversQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.DriverID,
                label: item.FirstName + ' ' + item.LastName
            })
        }
    }

    getTruckMetadata = () => {
        return {
            api: 'api/' + Resources.TrucksQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.TruckID,
                label: item.SCAC + ' - ' + item.TruckNumber
            })
        }
    }

    setParentState = (data, callback = () => {
    }) => {
        this.setState(data, callback)
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'archived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getCellFields = () => {
        return new Array(4).fill('').reduce((memo, row, index) => {
            memo['ColTrans' + index] = new Field('ColTrans' + index, '', [''], false, 'text')
            return memo
        }, {})
    }

    getFields = (item = null) => {
        let GenericIDLabel = item?.GenericTypeID == 1 ? 'Driver' : item?.GenericTypeID == 2 ? 'Truck' : 'GenericID'
        let fieldTemplates = {
            Transponder: new Field('Transponder', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            TransponderTypeID: new Field('TransponderTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            GenericTypeID: new Field('GenericTypeID', '', ['empty'], false, 'select', {
                label: 'BelongsTo',
                columnName: 'BelongsTo',
                addContainerClass: 'col-span-full'
            }, {
                values: {
                    1: 'Driver',
                    2: 'Truck'
                }
            }),
            GenericID: new Field('GenericID', '', ['empty'], false, 'select-search', {
                hideDialog: !item?.GenericTypeID,
                label: GenericIDLabel,
                addContainerClass: 'col-span-full'
            }),
            ChargeToTypeID: new Field('ChargeToTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                values: {
                    1: 'Driver',
                    2: 'Company'
                }
            }),
            ChargeToContactID: new Field('ChargeToContactID', '', ['empty'], false, 'select-search', {
                hideDialog: item?.ChargeToContactID != 1,
                addContainerClass: 'col-span-full'
            }),
            ExpireDate: new Field('ExpireDate', '', [], false, 'date', {addContainerClass: 'col-span-full'}),
            AssignDate: new Field('AssignDate', currentDate(), ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getImportFields = () => {
        return {
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-4'}),
            AccountAdvanceID: new Field('AccountAdvanceID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-4'}),
            TransponderTypeID: new Field('TransponderTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-4'}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }
    }

    getResourceName = () => {
        return Resources.Transponder
    }

    isRowEmpty = (row) => {
        function isEmptyString(item) {
            return item === ''
        }

        return row.every(isEmptyString)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const importFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.importFields)), translate, this.handleImportFieldsInputChange, {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
            AccountAdvanceID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_ASSET},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
            TransponderTypeID: getLookup('TransponderType')
        })

        const document = this.state.files[0]

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.TranspondersView')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        hasPerm={checkPerm(this.getResourceName(), CREATE_PERM)}
                        secondaryButtonLabel={translate('text.import_data')}
                        secondaryButtonHidden={!checkPerm(Resources.TransponderImportPreview, CREATE_PERM)}
                        onSecondaryButtonClick={this.handleOpenImportDataModal}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            {checkPerm(Resources.TransponderImport, CREATE_PERM) && (
                                <Tippy
                                    content={translate('text.preview_imports')}
                                >
                                    <button type="button"
                                            className="opacity-70 px-2 py-2 rounded-md inline-flex items-center text-base font-medium hover:opacity-90 transition ease-in-out duration-150 hover:bg-tm-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                            onClick={this.handleToggleImportListModal}>
                                        <DocumentTextIcon className="w-5 h-5 text-tm-gray-700 "/>
                                    </button>
                                </Tippy>
                            )}
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}
                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResourceName(), UPDATE_PERM) ? this.handleToggleUpdateModal : null}

                            onEdit={this.handleToggleUpdateModal}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasEditPerm={checkPerm(this.getResourceName(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResourceName(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResourceName(), UPDATE_PERM)}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResourceName(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new transponder'}
                            btnLabel="Create transponder"
                            onBtnClick={() => {
                                this.handleToggleCreateModal(this.state.queryFilterFields.query.value)
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalDefault
                        show={this.state.isImportDataModalOpen}
                        title={'Import data'}
                        widthClass="max-w-xl"
                        buttonLabel={'Submit document'}
                        onButtonClick={this.handleSubmitDocument}
                        buttonDisabled={!this.state.canSubmit}
                        onClose={() => this.setState({
                            isImportDataModalOpen: false,
                            files: []
                        })}
                    >
                        <div className="m-4 p-4">
                            <div className={'mb-5'}>
                                <InfoParagraph>
                                    {translate('text.preview_trans_import')}
                                </InfoParagraph>
                            </div>
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    this.setState({
                                        files: acceptedFiles,
                                        canSubmit: true
                                    })
                                }}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDropAccepted={this.onDrop}
                                multiple={false}
                                maxFiles={1}
                                accept={'.xlsx,.csv'}
                                maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}
                                             className={'border-2 flex items-center justify-center h-20' + (this.state.dropzoneActive ? ' border-primary border-solid text-primary bg-sky-100' : ' border-tm-gray-200 border-dashed text-tm-gray-700')}>
                                            <input {...getInputProps()} />

                                            <p className="select-none text-tm-gray-500">{translate('field.drag_n_drop')}</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        {!!this.state.files.length && (
                            <div>
                                <DocumentCard
                                    layoutSmall={true}
                                    fileName={document?.name}
                                    onDeleteDocument={() => this.setState({
                                        files: [],
                                        canSubmit: false
                                    })}
                                    translate={this.props.translate}
                                />
                            </div>
                        )}
                    </ModalDefault>

                    {this.props.download.isLoading ? (
                            <LoaderLarge/>
                        ) :
                        <ModalDefault
                            show={this.state.isPreviewImportDataModalOpen}
                            title={'Preview data to import'}
                            widthClass="max-w-full"
                            buttonLabel={'Confirm import'}
                            onButtonClick={this.handleConfirmImport}
                            onClose={() => this.setState({
                                isPreviewImportDataModalOpen: false
                            })}
                            limitHeight={true}
                        >
                            <div className={'grid gap-4 grid-cols-12 py-6 mx-6'}>
                                {importFieldsHtml}
                            </div>
                            <ResourceTable
                                data={this.state.data ?? []}
                                count={this.state.data?.length ?? 10}

                                headerVisible={false}
                                maxHeightClass={"max-h-[calc(100vh-28rem)]"}
                                fields={this.getCellFields()}

                                options={this.tableOptions}

                                translate={this.props.translate}

                                limit={this.state.queryFilterFields.limit}
                                offset={this.state.offset}
                                page={this.state.paginationPage}

                            />
                        </ModalDefault>
                    }

                    <ModalSaveResource
                        show={this.state.editModalOpen}
                        title={'Edit Transponder'}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleUpdateModal}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params.TransponderID = this.state.selectedItem.TransponderID
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true, successMessage: 'Transponder updated.'
                                }))
                                this.handleToggleUpdateModal(null)
                            }
                        }}
                        translate={this.props.translate}
                        handleInputChange={this.handleInputChange}
                        metadata={this.state.metadata}
                    />

                    <ResourceTableDialog
                        show={this.state.isImportPreviewListModalOpen}
                        dialogResource={this.props.dialogResource}
                        resource={Resources.TransponderImport}
                        title={translate('text.preview_imports')}
                        dispatch={this.props.dispatch}
                        fields={{
                            Date: new Field('Date', '', [], false, 'date'),
                            Notes: new Field('Notes', '', [], false, 'textarea'),
                            IsCompleted: new Field('IsCompleted', '', [], false, 'checkbox'),
                            TransponderTypeID: new Field('TransponderTypeID', '', [], false, 'select')
                        }}
                        sort={'DESC'}
                        sortBy={'Date'}

                        onDelete={(item) => this.handleDeleteImport(item)}

                        onClose={this.handleToggleImportListModal}
                        translate={translate}
                    />

                    <ModalSaveResource
                        show={this.state.createModalOpen}
                        title={'Create Transponder'}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields({Transponder: this.state.prefilled})}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true, successMessage: 'Transponder created.'
                                }))
                                this.handleToggleCreateModal()
                            }
                        }}
                        translate={this.props.translate}
                        handleInputChange={this.handleInputChange}
                        metadata={this.state.metadata}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.isConfirmDeleteImportModalOpen}
                        text={translate('text.confirm_remove_import')}
                        onClose={() => this.setState({isConfirmDeleteImportModalOpen: false, selectedItem: null})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={() => {
                            this.props.dispatch(deleteDialogResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.TransponderImport,
                                query: {
                                    TransponderImportID: this.state.selectedItem.TransponderImportID
                                },
                                piggyResource: Resources.TransponderImport,
                                piggyQuery: this.getQuery(),
                                errorMessage: true,
                                successMessage: translate('text.import_removed')
                            }))
                            this.setState({
                                isConfirmDeleteImportModalOpen: false
                            })
                        }
                        }
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TranspondersView)
