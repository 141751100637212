import React from "react";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {LoaderSmall} from "../loader";
import Tooltip from "../tooltip";
import {classNames} from "../../util/util-helpers";

const Button = ({
                    children,
                    appearance,
                    className = '',
                    addClass,
                    disabled,
                    onClick,
                    hasPerm = true,
                    data = {},
                    disabledIconClass,
                    onMouseDown,
                    isLoading,
                    innerRef,
                    type
                }) => {

    if (!className) {
        switch (appearance) {
            case 'primary':
                className = "btn btn-primary";
                break;
            case 'muted':
                className = "btn btn-outline-secondary";
                break;
            case 'danger':
                className = "btn btn-danger";
                break;
            default:
                className = "btn btn-outline";
        }
    }

    return <button
        ref={innerRef}
        type={type ?? 'button'}
        onClick={onClick}
        className={classNames(className, addClass)}
        disabled={!hasPerm || disabled || isLoading}
        onMouseDown={onMouseDown}
        {...data}
    >
        {isLoading && (
            <LoaderSmall disableContainer={true} addClass='mr-1 -ml-1'/>
        )}

        {!!hasPerm && children}

        {!hasPerm && (
            <Tooltip content={
                <div>
                    <p>Your account does not have sufficient permissions to perform this action.</p>
                </div>
            }>
                <div className={"flex items-center"}>
                    {children}
                    <LockClosedIcon className={disabledIconClass ?? "w-4 h-4 text-red-600 -mr-1 ml-1"}/>
                </div>
            </Tooltip>
        )}

    </button>
}

export default Button;