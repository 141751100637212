import React from "react";
import {classNames} from "../../util/util-helpers";

export default function Card(props) {
    const {className, bodyClass, addBodyClass, addClass, innerRef} = props;
    const defaultCardClass = "bg-inverse rounded-card border-tm-gray-300 border shadow-card";
    const defaultBodyClass = "py-3 px-6 space-y-4";

    return (
        <div className={classNames(className ?? defaultCardClass, addClass)} ref={innerRef}>
            <div className={classNames(bodyClass ?? defaultBodyClass, addBodyClass)}>
                {props.children}
            </div>
        </div>
    )
}

