import {Component} from 'react'

export default class TripPricingTab extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return 'Pricing Tab';
    }
}