import React, {Component} from 'react'
import Env from '../../../../../util/env'
import Resources from '../../../../../data/services/resources'
import {classNames} from '../../../../../common/util/util-helpers'
import {isTodayDate} from '../../../../../common/util/util-dates'
import CalendarIcon from "@heroicons/react/20/solid/CalendarIcon";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import FieldTextarea from "../../../../../common/components/fields/field-textarea";
import {getJWT} from "../../../../../common/util/util-auth";

export default class CommentCard extends Component {
    render() {
        const {item, translate, isMe, editedComment, onAvatarClick} = this.props

        return (
            <div
                className="flex pt-2 pb-4 group hover:bg-tm-gray-50 rounded-lg">
                <span
                    className={classNames("flex shrink-0 w-10", onAvatarClick ? 'text-primary' : '')}
                    onClick={() => onAvatarClick ? onAvatarClick(item) : null}
                >
                    <img
                        src={item.ImagePath ? Env.getApiUrl('api/' + Resources.ContactImage, {
                            token: getJWT().access_token,
                            ContactID: item.ContactID
                        }) : '/images/avatar.png'} alt={item.ImagePath}
                        className="w-10 h-10 rounded-full overflow-hidden object-cover"/>
                </span>

                <div className="pl-3 grow max-w-xs">
                    <span className="">
                        <p
                            onClick={() => onAvatarClick ? onAvatarClick(item) : null}
                            className={classNames(onAvatarClick ? "text-primary hover:text-tm-gray-900 cursor-pointer" : "text-tm-gray-900", "font-semibold")}
                        >
                            {item.FirstName} {item.LastName}
                        </p>

                        <p className="text-tm-gray-500 mb-0 text-sm flex items-center">
                            <CalendarIcon className="w-5 h-5 text-tm-gray-400 mr-2"/>
                            {isTodayDate(item.TaskCommentCreated, translate)}

                            {!!item.TaskCommentEdited && (<span className="ml-1">({translate('text.edited')})</span>)}
                        </p>
                    </span>

                    <div className="mt-3">
                        {!item.ArchivedDate ?
                            item.TaskCommentID != editedComment?.id && (<div
                                className="text-tm-gray-700 py-1 px-2 -mx-2 bg-tm-gray-50 border border-tm-gray-300 rounded-lg">{item.TaskComment}</div>)
                            :
                            <span
                                className="text-tm-gray-500 text-small font-italic font-weight-bolder has-link has-link-element">[{translate('text.message_deleted')}]</span>
                        }
                        {item.TaskCommentID == editedComment?.id && (
                            <div ref={this.clickOutsideTextArea}>
                                <div className={"form-group"}>
                                    <label className={"label-stack"}>{translate('text.Edit')}</label>
                                        <FieldTextarea
                                            id={'textInput'}
                                            onChange={(name, value) => this.props.editComment(item.TaskCommentID, value)}
                                            value={this.props.editedComment.value}
                                            addClass="form-control whitespace-pre-wrap"
                                        />
                                </div>

                                <div className="my-2 text-right">
                                    <div
                                        className={classNames('btn btn-outline', !this.props.editedComment.value ? 'disabled' : '')}
                                        onClick={() => (this.props.editedComment.value ? this.props.editCommentHandler() : null)}
                                    >
                                        {translate('btn.edit_comment')}
                                    </div>

                                    <div className="btn btn-outline-secondary ml-2" onClick={() => {
                                        this.props.editComment(null, null)
                                    }}>{translate('btn.close')}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-10 items-center ml-10">
                    {!!isMe && item.ArchivedDate == null && (
                        <span className="flex flex-wrap space-y-2">
                            <button
                                className="btn-table-action opacity-0 focus:opacity-100 group-hover:opacity-100"
                                onClick={() => {
                                    this.props.editComment(item.TaskCommentID, item.TaskComment)
                                }}
                            >
                                <PencilIcon className="w-5 h-5"/>
                            </button>

                            <button
                                className="btn-table-action opacity-0 focus:opacity-100 group-hover:opacity-100"
                                onClick={() => {
                                    this.props.confirmDeleteCommentHandler(item.TaskCommentID, item.TaskComment)
                                }}
                            >
                                <TrashIcon className="w-5 h-5"/>
                            </button>
                        </span>
                    )}
                </div>
            </div>
        )
    }
}
