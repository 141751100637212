import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateThirdResource,
    doneDeleteThirdResource,
    doneGetThirdResource, doneUpdateThirdResource,
    errorCreateThirdResource, errorDeleteThirdResource,
    errorGetThirdResource, errorUpdateThirdResource
} from '../actions/thirdResource'
import { pushNotification } from "../actions/ui";
import Resources from "../services/resources";
import LocalStorage from "../../util/localStorage";
import {getErrorNotificationWithExceptions} from "../../common/util/util-helpers";

export function* getThirdResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result;
    if (action.data.request === 'POST') {
        result = yield call(Api.postResource, user, action.data.query, action.data.resource);
    } else {
        result = yield call(Api.getResource, user, action.data.query, action.data.resource);
    }

    if (result && result.status === 0) {
        yield put(doneGetThirdResource(result.data));
    } else {
        yield put(errorGetThirdResource(result.data));
    }
}

export function* watchGetThirdResource() {
    yield takeLatest('GET_THIRD_RESOURCE', getThirdResourceCall);
}

export function* createThirdResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (!!action?.data?.file) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetThirdResource(resPiggy.data));
                yield put(doneCreateThirdResource(result.data));
            }
        } else {
            yield put(doneCreateThirdResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorCreateThirdResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchCreateThirdResource() {
    yield takeLatest('CREATE_THIRD_RESOURCE', createThirdResourceCall);
}

export function* deleteThirdResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.refreshLocalStorageLookups) {
            const lookups = yield call(Api.getResource, user, {}, Resources.Lookup);
            if (lookups && lookups.status === 0) {
                LocalStorage.set('lookup', lookups.data)
            }
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (action.data.onPiggyCallback && resPiggy) {
                action.data.onPiggyCallback(resPiggy)
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetThirdResource(resPiggy.data));
                yield put(doneDeleteThirdResource(result.data));
            }
        } else {
            yield put(doneDeleteThirdResource(result.data));
        }

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorDeleteThirdResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchDeleteThirdResource() {
    yield takeLatest('DELETE_THIRD_RESOURCE', deleteThirdResourceCall);
}

export function* updateThirdResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetThirdResource(resPiggy.data));
                yield put(doneUpdateThirdResource(result.data));
            }
        } else {
            yield put(doneUpdateThirdResource(result.data));
        }

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorUpdateThirdResource(result.data));

        if (action.data.errorMessage) {
            yield put(pushNotification(getErrorNotificationWithExceptions(result, action)));
        }
    }
}

export function* watchUpdateThirdResource() {
    yield takeLatest('UPDATE_THIRD_RESOURCE', updateThirdResourceCall);
}
