import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showModal} from '../../../data/actions/ui'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {
    checkPerm, classNames, getDefaultTableOptions, getProp, mergeDeep,
    getDefaultTableOptionsJSON,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {getContactResource} from '../../../data/actions/contactResource'
import ClipboardDocumentIcon from '@heroicons/react/24/outline/ClipboardDocumentIcon'
import {ArrowUturnLeftIcon, EyeIcon, EyeSlashIcon, TrashIcon} from '@heroicons/react/24/outline'
import {HourglassIcon} from "../../../data/themes/icons";
import {getHistoryResource} from "../../../data/actions/historyResource";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import Modal from "../../../common/components/modal";
import ContactForm from "../../../common/components/forms/contact-form";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Tooltip from "../../../common/components/tooltip";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";

class ContactsView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,

            sortBy: 'FirstName',
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            prefilled: '',
            isColumnsDialogVisible: false,

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Dialogs
            selectedItem: null,
            createUpdateDialog: false,
            confirmDialog: false,
            historyDialogHideAnimation: false,
            breakpoint: {},
            isTableFullScreen: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour);
        }

        this.fileImageObject = null
        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.handleCheckIfRedirected()
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS')) {
            this.setState({emailExist: true})
        }

        if (!!this.props.contactResource?.updated && (!!this.props.contactResource.update && !prevProps.contactResource.update)) {
            this.fetchData();
        }

        if (this.props?.ui?.AIChatPosition === 'bottom' && this.props?.ui?.isAIChatVisible) {
            if (this.state?.tableOptions?.classes?.maxHeightClass === 'max-h-[calc(100vh-23rem)]') {
                const tableOptionsClone = Object.assign({}, this.state.tableOptions);
                tableOptionsClone.classes.maxHeightClass = 'resource-table-height resource-table-height--with-bottom-chat';

                this.setState({
                    tableOptions: tableOptionsClone
                })
            }
        }

        if (this.state.tableOptions?.classes?.maxHeightClass === 'resource-table-height resource-table-height--with-bottom-chat' && (this.props?.ui?.AIChatPosition !== 'bottom' || !this.props?.ui?.isAIChatVisible)) {
            const tableOptionsClone = Object.assign({}, this.state.tableOptions);
            tableOptionsClone.classes.maxHeightClass = 'max-h-[calc(100vh-23rem)]';

            this.setState({
                tableOptions: tableOptionsClone
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            errorMessage: true,
            query: this.getQuery()
        }))
    }

    fetchContactData = () => {
        if (!this.state.selectedItem) {
            return false
        }

        this.props.dispatch(getContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.Contacts,
            query: {id: this.state.selectedItem.ContactID}
        }))
    }

    submitContactForm = (params, file, documents = []) => {
        if (this.state.selectedItem) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                errorMessage: true,
                successMessage: this.props.translate('message.ContactUpdatedSuccessfully'),
                query: this.getQuery(),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                file: !!file && [file],
                fileResource: Resources.ContactImage,
                document: documents,
                documentResource: Resources.ContactDocuments
            }))

            this.handleCloseCreateUpdateResource()
        } else {
            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: params,
                errorMessage: true, successMessage: this.props.translate('message.ContactCreatedSuccessfully'),
                query: this.getQuery(),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                file: !!file && [file],
                fileResource: Resources.ContactImage,
                onResultCallback: this.handleResultCallback,
                document: documents,
                documentResource: Resources.ContactDocuments
            }))
        }
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'contacts_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'contacts_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        const isMe = (LocalStorage.get('user')?.Contact.ContactID === item.ContactID)
        if (!isMe) {
            this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.FirstName} ${item.LastName}?`}, () => {
                this.handleToggleConfirmDialog(() => {
                    this.setState({
                        ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                        confirmDialog: false
                    }, () => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: Object.assign({}, this.getQuery(), {ContactID: item.ContactID}),
                            errorMessage: true,
                            successMessage: this.props.translate(`message.contact_archived`, [item.FirstName, item.LastName]),
                            resource: this.getResource(),
                            piggyResource: this.getResource()
                        }))
                    })
                }, item)
            })
        } else {
            return null
        }
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_restore_contact')} ${item.FirstName} ${item.LastName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.ContactID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Contact ${item.FirstName} ${item.LastName} restored.`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'FirstName'
            sort = 'ASC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleCreateUpdateResource = (item = null) => {
        this.setState({selectedItem: item, createUpdateDialog: true, historyID: item?.ContactID})
    }

    handleCloseCreateUpdateResource = () => {
        this.setState({
            createUpdateDialog: false,
            canSubmit: false,
            emailExist: null,
        })
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location.search)
        const ContactID = query.get('ContactID')
        if (ContactID) {
            this.handleCreateUpdateResource(Object.assign({}, {ContactID: ContactID}))
        }
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleResultCallback = (result) => {
        if (result && result?.status === 0 && result?.data?.password) {
            this.setState({temporaryPasswordModal: true, temporaryPassword: result.data.password})
            this.handleCloseCreateUpdateResource()
        } else if (result && result.status === 0) {
            this.handleCloseCreateUpdateResource()
        }
    }

    copyToClipboard = (password) => {
        this.setState({
            urlCopied: true
        }, () => {
            navigator.clipboard.writeText(password)
        })

        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            ContactType: new Field('ContactType', '', [''], false, 'select'),
            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getFields = () => {
        return {
            FirstName: new Field('FirstName', '', ['empty']),
            LastName: new Field('LastName', '', ['empty']),
            Nickname: new Field('Nickname', '', ['']),
            Email: new Field('Email', '', ['empty', 'email'], false, 'email'),
            PrivateEmail: new Field('PrivateEmail', '', ['empty', 'email'], false, 'email'),
            DateOfBirth: new Field('DateOfBirth', '', [], false, 'date'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date')
        }
    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, this.getFields())

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['HighImportance'] = 'checkbox'
        fieldsCombined['IsSystemUser'] = 'checkbox'
        fieldsCombined['DateOfBirth'] = 'date'

        return Object.assign({}, fieldsCombined);
    }
    saveFilters = () => {
        let filters = []

        Object.keys(this.getQuery()).forEach(it => filters.push(it))

        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    getResource = () => {
        return Resources.Contacts
    }

    handleClearErrorData = () => {
        this.setState({
            emailExist: false
        })
    }

    handleViewHistory = () => {
        this.setState({historyModalOpen: true})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, contactResource, historyResource} = this.props;
        const data = getProp(resource.data, 'list', [])
        const isLoading = resource.isLoading
        const count = resource?.data?.count
        const contactData = getProp(contactResource, 'data', null)
        const contactIsLoading = getProp(contactResource, 'isLoading', false)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.all_contacts')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex relative z-[32]">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {/*<Tooltip content={translate('text.download_template')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcelTemplate}*/}
                            {/*    >*/}
                            {/*        <RectangleGroupIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tooltip>*/}

                            <Tooltip content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tooltip>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.toggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('btn.create_contact')}
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleCreateUpdateResource()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        {/* TABLE_LOCATION="contacts/contacts|contacts" */}
                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}
                            tableKey="ContactID"
                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                            onView={this.handleShowDetails}
                            onEdit={this.handleCreateUpdateResource}

                            hasViewPerm={checkPerm(this.getResource(), READ_PERM)}
                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            actions={[
                                {
                                    action: this.archiveResource,
                                    icon: TrashIcon, // make this a function
                                    visible: (it) =>  !it.ArchivedDate && getProp(LocalStorage.get("user"), 'Contact.ContactID', 0) !== it.ContactID,
                                    hasPerm: () => checkPerm(this.getResource(), DELETE_PERM),
                                    label: false, // make this a function
                                    title: translate('btn.archive'),
                                    order: 40
                                },
                                {
                                    action: this.restoreFromArchive,
                                    icon: ArrowUturnLeftIcon, // make this a function
                                    visible: (it) => (checkPerm(this.getResource(), UPDATE_PERM) && !!it.ArchivedDate),
                                    label: false, // make this a function
                                    title: translate('btn.restore'),
                                    order: 50
                                }
                            ]}
                        />

                        <TableCardFooter
                            show={!(!data.length && !isLoading)}
                        >
                            <Pagination
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                count={count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </TableCard>
                </Page>

                <ModalConfirm
                    title={translate('text.confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.toggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalDefault
                    show={!!this.state.temporaryPasswordModal}
                    widthClass={'max-w-xl'}
                    title={'Contact Created Successfully'}
                    closeButtonLabel={translate('btn.cancel')}
                    onClose={() => this.setState({temporaryPasswordModal: false})}
                >
                    <div className="p-5">
                        <button
                            className={classNames(!this.state.urlCopied ? 'text-tm-gray-900 hover:bg-tm-gray-100' : 'text-green-900 hover:bg-green-100', 'w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150')}
                            onClick={() => {
                                this.copyToClipboard(this.state.temporaryPassword)
                            }}
                        >
                            <ClipboardDocumentIcon className="h-5 w-5"/>
                            <span
                                className="ml-3">{!this.state.urlCopied ? 'Copy password to clipboard' : 'Password copied to clipboard!'}</span>
                        </button>
                        <div
                            className={classNames('text-tm-gray-900 hover:bg-tm-gray-100', 'w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150')}
                            onMouseEnter={() => {
                                this.setState({temporaryPasswordVisible: true})
                            }}
                            onMouseLeave={() => {
                                this.setState({temporaryPasswordVisible: false})
                            }}
                        >
                            {this.state.temporaryPasswordVisible ?
                                <EyeIcon className="h-5 w-5"/>
                                :
                                <EyeSlashIcon className="h-5 w-5"/>
                            }

                            <span
                                className="ml-3">{this.state.temporaryPasswordVisible ? this.state.temporaryPassword : '****************'}</span>
                        </div>
                    </div>
                </ModalDefault>

                <Modal
                    show={this.state.createUpdateDialog}
                    widthClass={'max-w-3xl'}
                    onClose={this.handleCloseCreateUpdateResource}
                >
                    <ContactForm
                        data={!!this.state.selectedItem && contactData}
                        isLoading={contactIsLoading}
                        onMount={this.fetchContactData}
                        onClose={this.handleCloseCreateUpdateResource}
                        errorMessages={this.state.emailExist ? [{field: 'Email', message: 'text.email_exists'}] : []}
                        clearErrorData={this.handleClearErrorData}
                        onSubmit={this.submitContactForm}
                        onViewHistory={this.handleViewHistory}
                        translate={translate}
                        isEdit={!!this.state.historyID}
                        isSystemUser={false}
                    />
                </Modal>

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.ContactsHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.state.selectedItem.ContactID
                                    }),
                                    resource: Resources.ContactsHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>
            </Layout>
        )
    }
}

export default connect(state => state)(ContactsView)
