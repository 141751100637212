import ModalSaveResource from "../modal-save-resource";
import React from "react";
import FieldSwitchLabel from "../../fields/field-switch/field-switch-label";
import LocalStorage from "../../../util/localStorage";
import {useDispatch} from "react-redux";
import moment from "moment";
import {
    fromUserTzToUTC,
    getUserDateTimeFormat,
    getUserTimeFormat,
    toFrontDateTimeFromUTC,
    toFrontTimeFromUTC
} from "../../../util/util-dates";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../util/util-consts";
import {createResource} from "../../../../data/actions/resource";
import {fillFieldsFromData} from "../../../util/util-fields";
import Resources from "../../../../data/services/resources";


export default function ModalReminder({
                                          show,
                                          translate,
                                          onCloseReminderModalClick,
                                          noteFieldName = 'CommentNote',
                                          selectedItem,
                                          getAdditionalFields,
                                          onSubmit
                                      }) {
    const dispatch = useDispatch();

    function handleInputChange(fields, name, value) {
        let fieldsUpdate = Object.assign({}, fields);
        if ("IsUpdateReminderOn" === name) {
            fieldsUpdate.UpdateReminderInterval.disabled = !value;
            fieldsUpdate.Days.disabled = !value;
            fieldsUpdate.Hours.disabled = !value;
            fieldsUpdate.Minutes.disabled = !value;
            fieldsUpdate.StartReminderDate.disabled = !value;
            fieldsUpdate.StartReminderDateTime.disabled = !value;
        }

        if ("UpdateReminderInterval" === name
            || "Days" === name
            || "Hours" === name
            || "Minutes" === name
        ) {
            value = value.replace(/\D/g, '');
            fieldsUpdate = FieldsManager.updateField(fields, name, value);
            fieldsUpdate.UpdateReminderInterval.value = Number(fieldsUpdate.Days.value * 1440 ?? 0) + Number(fieldsUpdate.Hours.value * 60 ?? 0) + Number(fieldsUpdate.Minutes.value ?? 0);
        } else {
            fieldsUpdate = FieldsManager.updateField(fields, name, value);
        }

        return fieldsUpdate;
    }

    function getReminderFields(item = {}) {
        if (item.IsUpdateReminderOn) {
            let userLastUpdateDateTime = "";
            if (item.LastUpdateDateTime) {
                userLastUpdateDateTime = moment(toFrontDateTimeFromUTC(item.LastUpdateDateTime), getUserDateTimeFormat()).format(DEFAULT_DATABASE_DATETIME_FORMAT);
            }
            item.StartReminderDate = userLastUpdateDateTime
                ? userLastUpdateDateTime
                : moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);
            item.StartReminderDateTime = userLastUpdateDateTime
                ? toFrontTimeFromUTC(userLastUpdateDateTime) //toFrontDateTimeFromUTC(item.LastUpdateDateTime)
                : moment().format(getUserTimeFormat());

            if (item.UpdateReminderInterval) {
                item.Days = Math.floor(item.UpdateReminderInterval / 1440);
                item.Minutes = item.UpdateReminderInterval % 1440;
                item.Hours = Math.floor(item.Minutes / 60);
                item.Minutes = item.Minutes % 60;
            }
        }

        let fieldTemplates = {
            [noteFieldName]: new Field(noteFieldName, '', [''], false, 'textarea', {addContainerClass: "col-span-full"}),
            IsUpdateReminderOn: new Field('IsUpdateReminderOn', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-6 flex items-center rounded-xl",
                htmlAfter: () =>
                    <FieldSwitchLabel
                        label={translate("field.is_reminder_on")}
                        note={translate("text.StopUpdateReminderInfo")}
                    />
            }),
            UpdateReminderInterval: new Field('UpdateReminderInterval', "", [], !item.IsUpdateReminderOn, 'hidden', {addContainerClass: "col-span-6"}),
            StartReminderDate: new Field('StartReminderDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [], !item.IsUpdateReminderOn, 'date', {
                addContainerClass: "col-span-3 col-start-1 w-[calc(100%+1rem+1px)]",
                htmlBefore: () => <>
                    <div
                        className="text-lg font-medium text-tm-gray-700 col-start-1 col-span-6 relative -bottom-1">{translate('text.start_reminder_at')} </div>
                    <div
                        className="text-lg font-medium text-tm-gray-700 col-span-6 relative -bottom-1">{translate('text.set_reminder_interval')} </div>
                </>
            }, {addClass: "rounded-r-none focus:z-10"}),
            StartReminderDateTime: new Field('StartReminderDateTime', moment().format(getUserTimeFormat()), [], !item.IsUpdateReminderOn, 'time-custom', {addContainerClass: "col-span-3"}, {addClass: "rounded-l-none"}),
            LastUpdateDateTime: new Field('LastUpdateDateTime', "", [], !item.IsUpdateReminderOn, 'hidden'),
            Days: new Field('Days', "", [''], !item.IsUpdateReminderOn, 'text', {
                addContainerClass: "col-span-2 w-[calc(100%+1rem+1px)]",
            }, {addClass: "rounded-r-none focus:z-10 relative form-control"}),
            Hours: new Field('Hours', "", [''], !item.IsUpdateReminderOn, 'text', {addContainerClass: "col-span-2 w-[calc(100%+1rem+1px)]"}, {addClass: "rounded-none focus:z-10 relative form-control"}),
            Minutes: new Field('Minutes', "", [''], !item.IsUpdateReminderOn, 'text', {addContainerClass: "col-span-2"}, {addClass: "rounded-l-none focus:z-10 relative form-control"}),
        }

        if (getAdditionalFields) {
            fieldTemplates = Object.assign(fieldTemplates, getAdditionalFields());
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    return <ModalSaveResource
        show={show}
        title={"Update reminder"}
        widthClass="max-w-3xl"
        gridClass="px-6 pb-6 grid gap-4 grid-cols-12"
        onClose={onCloseReminderModalClick}
        fields={getReminderFields(selectedItem)}
        onSubmit={(params) => {
            if (params.StartReminderDate && params.StartReminderDateTime) {
                params.LastUpdateDateTime = fromUserTzToUTC(params.StartReminderDate.split(" ")[0] + " " + moment(params.StartReminderDateTime, getUserTimeFormat()).format(DEFAULT_DATABASE_DATETIME_FORMAT).split(" ")[1]);
            }

            if (!params.IsUpdateReminderOn) {
                params.UpdateReminderInterval = "";
                params.LastUpdateDateTime = "";
            }

            if (params && !onSubmit) {
                dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: params,
                    errorMessage: true,
                    successMessage: `Reminder added successfully`,
                    resource: Resources.AccountingIncomeInvoiceComment,
                }));
            }

            if (params && onSubmit) {
                onSubmit(params);
            }
        }}
        handleInputChange={handleInputChange}
        translate={translate}
        closeButtonLabel={translate('btn.cancel')}
        htmlBefore={(fields) => {
            return <div
                className="px-6 text-xs text-right relative -bottom-5">{translate('text.character_limit')} ( {fields?.[noteFieldName]?.value?.length} /
                8000 )</div>
        }}

        htmlAfter={(fields) => {
            if (!fields.IsUpdateReminderOn.value || !fields.UpdateReminderInterval.value) {
                return <div className="pb-6"/>;
            }
            return <div
                className="px-6 h-6 flex items-center justify-end gap-1 text-sm bg-tm-gray-100 border-t border-tm-gray-200">
                <div>Reminder interval is {fields.UpdateReminderInterval.value} minutes</div>
            </div>
        }}
    />
}