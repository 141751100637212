import React from "react";
import Buttons from "../buttons";

export default function CardTitle({title, buttons}) {
    return (
        <div className="flex items-center w-full h-9">
            <h2 className="text-2xl text-tm-gray-900">{title}</h2>

            <div className="ml-auto">
                <Buttons
                    buttons={buttons}
                />
            </div>
        </div>
    )
}
