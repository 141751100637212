import React, {Component} from 'react'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, classNames, getProp} from '../../../common/util/util-helpers'
import {cloneDeep} from "../../../common/util/util-vanilla";
import {DEFAULT_CRUD_STATE, DELETE_PERM} from "../../../util/util-constants";
import ModalDefault from "../../../common/components/modal/modal-default";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import {showModal} from '../../../data/actions/ui'
import {UPDATE_PERM} from "../../../common/util/util-consts";
import {ArrowPathIcon, BellAlertIcon, TrashIcon} from "@heroicons/react/24/outline";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";

export default class AddWatchersDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,

            // Fields
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            notificationSettingsFields: this.getNotificationSettingsFields(),

            // Selected watchers
            selectedWatchersToAdd: {},
            selectedWatchers: {},

            // Selected watchers - Review dialog
            reviewContacts: {},
            selectedReviewContacts: {},

            // Modals
            isAllContactsModalOpen: false,
            isReviewContactsForDeletionDialogOpen: false,
            isNotificationSettingsModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.isNotificationSettingsModalOpen && this.state.isNotificationSettingsModalOpen) {
            this.setState({
                notificationSettingsFields: this.getNotificationSettingsFields(this.state.selectedItem),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadWatchers,
            query: this.getQuery()
        }))
    }

    handleAddWatchers = () => {
        const Contacts = Object.keys(this.state.selectedWatchersToAdd).map(it => {
            return Object.assign({}, {
                ContactID: it,
                LoadWatcherTypeID: 1,
                ReceiveCustomerMessages: 0,
                ReceiveCarrierMessages: 0,
                ReceiveStatusUpdates: 0,
                ReceiveETAUpdates: 0
            })
        })

        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                Contacts: Contacts,
                LoadID: this.getID(),
            },
            query: this.getQuery(),
            resource: Resources.LoadWatchers,
            piggyResource: Resources.LoadWatchers,
            secondPiggyResource: Resources.LoadWatchers,

            errorMessage: true, successMessage: this.props.translate('text.load_watchers_updated')
        }))
        this.handleToggleAllContactsModal()
    }

    handleRemoveWatchers = () => {
        const item = this.state.selectedItem ?? null

        this.props.dispatch(deleteResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadWatchers,
            query: Object.assign({}, this.getQuery(), {
                LoadID: this.getID(),
                ContactIDs: item ? [item.ContactID] : Object.keys(this.state.selectedReviewContacts)
            }),
            piggyResource: Resources.LoadWatchers,
            secondPiggyResource: Resources.LoadWatchers,
            piggyQuery: this.getQuery(),
            secondPiggyQuery: this.getQuery(),

            errorMessage: true, successMessage: this.props.translate('message.watcher_deleted'),
        }))
        this.handleCloseReviewContactsForDeletionDialog();
        this.setSelectedRows({});
    }

    handleEditNotificationSettings = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                LoadID: this.getID(),
                ContactID: this.state.selectedItem.ContactID,
                ReceiveStatusUpdates: this.state.notificationSettingsFields.ReceiveStatusUpdates.value ? 1 : 0,
                ReceiveCustomerMessages: this.state.notificationSettingsFields.ReceiveCustomerMessages.value ? 1 : 0,
                ReceiveCarrierMessages: this.state.notificationSettingsFields.ReceiveCarrierMessages.value ? 1 : 0,
                ReceiveETAUpdates: this.state.notificationSettingsFields.ReceiveETAUpdates.value ? 1 : 0
            },
            resource: Resources.LoadWatchers,
            piggyResource: Resources.LoadWatchers,
            piggySecondResource: Resources.LoadWatchers,
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('message.notification_settings_updated')
        }))
        this.handleToggleNotificationSettingsModal()
    }

    handleEditNotificationSettingsMulti = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                LoadID: this.getID(),
                ContactIDs: Object.keys(this.state.selectedWatchers),
                ReceiveStatusUpdates: this.state.notificationSettingsFields.ReceiveStatusUpdates.value ? 1 : 0,
                ReceiveCustomerMessages: this.state.notificationSettingsFields.ReceiveCustomerMessages.value ? 1 : 0,
                ReceiveCarrierMessages: this.state.notificationSettingsFields.ReceiveCarrierMessages.value ? 1 : 0,
                ReceiveETAUpdates: this.state.notificationSettingsFields.ReceiveETAUpdates.value ? 1 : 0
            },
            resource: Resources.LoadWatchers,
            piggyResource: Resources.LoadWatchers,
            piggySecondResource: Resources.LoadWatchers,
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('message.notification_settings_updated')
        }))
        this.handleToggleNotificationSettingsMulti()
    }

    /** Field Definitions
     ================================================================= */
    getFields = () => {
        return {
            ContactGroupID: new Field('ContactGroupID', ''),
            LoadWatcherTypeID: new Field('LoadWatcherTypeID', '1'),
            ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
            ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
            ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
            ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
        }
    }

    getWatcherFields = () => {
        return {
            WatcherName: new Field('WatcherName', '', ['empty']),
            Email: new Field('Email', '', ['empty', 'email']),
            ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
            ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
            ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
            ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
        }
    }

    getNotificationSettingsFields = (item) => {
        let fieldTemplates = {
            ReceiveStatusUpdates: new Field('ReceiveStatusUpdates', '', [''], false, 'checkbox'),
            ReceiveCustomerMessages: new Field('ReceiveCustomerMessages', '', [''], false, 'checkbox'),
            ReceiveCarrierMessages: new Field('ReceiveCarrierMessages', '', [''], false, 'checkbox'),
            ReceiveETAUpdates: new Field('ReceiveETAUpdates', '', [''], false, 'checkbox')
        }
        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    /** Query Definitions
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 20, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: "top"})
        }
    }

    getQuery = () => {
        return {
            id: this.getID(),
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    /** UI Events
     ================================================================= */
    handleSelectContact = (row, id) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const selectedWatchersToAdd = cloneDeep(this.state.selectedWatchersToAdd);
        const selectedWatchers = cloneDeep(this.state.selectedWatchers);

        if (isAddContacts) {
            if (selectedWatchersToAdd[id]) {
                delete selectedWatchersToAdd[id]
            } else {
                selectedWatchersToAdd[id] = row
            }
        } else {
            if (selectedWatchers[id]) {
                delete selectedWatchers[id]
            } else {
                selectedWatchers[id] = row
            }
        }

        this.setState({
            selectedWatchersToAdd,
            selectedWatchers
        })
    }

    handleSelectAllContacts = (areAllSelected) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const prop = isAddContacts ?
            getProp(this.props.dialogResource, 'data.list', [])
            :
            getProp(this.props.resource, 'data.load/watchers.list', [])

        const selectedWatchersToAdd = cloneDeep(this.state.selectedWatchersToAdd);
        const selectedWatchers = cloneDeep(this.state.selectedWatchers);

        prop.map(item => {
            if (!areAllSelected) {
                isAddContacts ?
                    selectedWatchersToAdd[item.ContactID] = item
                    :
                    selectedWatchers[item.ContactID] = item;
            } else {
                isAddContacts ?
                    delete selectedWatchersToAdd[item.ContactID]
                    :
                    delete selectedWatchers[item.ContactID]
            }
        });

        this.setState({
            selectedWatchersToAdd,
            selectedWatchers
        });
    }

    handleSelectReviewContact = (row, id) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts);

        if (selectedReviewContacts[id]) {
            delete selectedReviewContacts[id];
        } else {
            selectedReviewContacts[id] = row;
        }

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        });
    }

    handleSelectAllReviewContacts = (areAllSelected) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts);

        getProp(this.props.resource, 'data.load/watchers.list', []).map(item => {
            if (!areAllSelected) {
                selectedReviewContacts[item.ContactID] = item;
            } else {
                delete selectedReviewContacts[item.ContactID];
            }
        });

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        });
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields
        let notificationFields = this.state.notificationSettingsFields

        this.setState({
            fields: FieldsManager.updateField(fields, name, value),
            notificationSettingsFields: FieldsManager.updateField(notificationFields, name, value)
        })
    }

    handleFilterInputChange = (name, value) => {
        const queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1,
        }, () => {
            this.fetchData();
        })
    }

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData())
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleToggleAllContactsModal = () => {
        this.setState({
            isAllContactsModalOpen: !this.state.isAllContactsModalOpen,
            selectedWatchersToAdd: {},
        })
    }

    handleToggleNotificationSettingsModal = (item = null) => {
        this.setState({
            selectedItem: item,
        }, () => this.setState({
            isNotificationSettingsModalOpen: !this.state.isNotificationSettingsModalOpen
        }))
    }

    handleToggleNotificationSettingsMulti = () => {
        this.setState({
            isNotificationSettingsModalOpen: !this.state.isNotificationSettingsModalOpen
        })
    }

    handleOpenReviewContactsForDeletionDialog = () => {
        this.setState({
            isReviewContactsForDeletionDialogOpen: true,
            reviewContacts: cloneDeep(this.state.selectedWatchers),
            selectedReviewContacts: cloneDeep(this.state.selectedWatchers)
        })
    }

    handleCloseReviewContactsForDeletionDialog = () => {
        this.setState({
            isReviewContactsForDeletionDialogOpen: false,
            reviewContacts: {},
            selectedReviewContacts: {},
            canSubmit: false
        })
    }

    handleToggleConfirmRemoveContactsModal = (item) => {
        this.setState({
            selectedItem: item
        }, () => this.setState({isRemoveContactsConfirmModalOpen: !this.state.isRemoveContactsConfirmModalOpen}))
    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedWatchers: selectedRows})
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, Resources.LoadWatchers + '.list', [])

        const count = getProp(resource.data, Resources.LoadWatchers + '.count', 0)

        const selectedContactsCount = Object.keys(this.state.selectedWatchers).length
        const notificationSettingsFields = fieldsToHtml(Object.values(Object.assign({}, this.state.notificationSettingsFields)), translate, this.handleInputChange)

        return (
            <React.Fragment>
                <div className="p-6">
                    <PageHeader
                        className="flex pb-4"
                        title={translate("text.Watchers")}
                        onButtonClick={this.handleToggleAllContactsModal}
                        buttonLabel={translate("btn.add_watcher_load")}
                        customHtml={<div className="flex relative">
                            <button
                                className={
                                    classNames(
                                        "btn btn-header z-10"
                                    )
                                }
                                onClick={this.fetchData}
                            >
                                <ArrowPathIcon className={
                                    classNames(
                                        "w-5 h-5",
                                        resource.isLoading ? "animate-spin" : undefined
                                    )
                                }/>
                            </button>
                        </div>}
                    />

                    <TableCard addClass={'rounded-none'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                        />

                        <ResourceTable
                            commonTable={true}

                            data={data}
                            count={count}

                            tableKey={"ContactID"}
                            onSelectRow={this.handleSelectContact}
                            onSelectAllClick={this.handleSelectAllContacts}
                            selectedRows={this.state.selectedWatchers}


                            onView={this.handleShowDetails}
                            onRowClick={(row) => this.handleSelectContact(row, row.ContactID)}
                            onEdit={(item) => this.handleToggleNotificationSettingsModal(item)}
                            onDelete={(item) => this.handleToggleConfirmRemoveContactsModal(item)}

                            translate={translate}

                            fields={this.getWatcherFields()}

                            isLoading={resource.isLoading}

                            limit={this.state.queryFilterFields.limit.value}
                            offset={this.state.offset}
                            page={this.state.paginationPage}
                            paginationButtonLimit={5}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            options={{
                                style: {
                                    floatingActions: true,
                                    stripedRows: true
                                }
                            }}
                        />

                        <NoRecords
                            addClass={"mt-5"}
                            show={(data.length === 0) && !resource.isLoading}
                            title={translate('text.no_records')}
                        />

                        <TableBulkActions
                            selectedRows={this.state.selectedWatchers}
                            tableKey="ContactID"
                            fields={this.getWatcherFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            // onSelectAllClick={this.handleSelectAllReviewContacts}
                        >
                            <div className="flex items-center h-8">
                                <div className={classNames('flex divide-x-2 divide-primary-shade')}>

                                    {checkPerm(Resources.LoadWatchers, DELETE_PERM) && (
                                        <div className="flex items-center gap-x-1 pr-4">
                                            <button
                                                className="flex p-1 mr-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                                title={translate('text.delete')}
                                                onClick={() => {
                                                    this.handleOpenReviewContactsForDeletionDialog()
                                                }}
                                            >
                                                <TrashIcon className="h-5 w-5"/>
                                            </button>
                                        </div>
                                    )}

                                    {checkPerm(Resources.LoadWatchers, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 px-4">
                                            <button
                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                                title={translate('text.Edit')}
                                                onClick={() => {
                                                    this.handleToggleNotificationSettingsModal(this.state.selectedWatchers)
                                                }}
                                            >
                                                <BellAlertIcon className="h-5 w-5"/>
                                            </button>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </TableBulkActions>

                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </TableCard>
                </div>
                {/*</ModalDefault>*/}

                <ModalDefault
                    show={this.state.isNotificationSettingsModalOpen}
                    widthClass={"max-w-xl"}
                    translate={translate}
                    title={translate("modal_heading.customize_notification_settings") + ' for ' + (!selectedContactsCount ? this.state.selectedItem?.WatcherName : 'all selected watchers')}
                    buttonLabel={translate("btn.apply")}
                    onButtonClick={selectedContactsCount ? this.handleEditNotificationSettingsMulti : this.handleEditNotificationSettings}
                    buttonDisabled={false}
                    onClose={this.handleToggleNotificationSettingsModal}
                    closeButtonLabel={translate("btn.cancel")}
                >
                    <div className='ml-4'>
                        {notificationSettingsFields}
                    </div>
                </ModalDefault>


                <ModalDefault
                    show={this.state.isReviewContactsForDeletionDialogOpen}
                    widthClass={"max-w-xl"}
                    translate={translate}
                    title={translate("modal_heading.review_selected_contacts")}
                    buttonLabel={translate("btn.delete_selected")}
                    onButtonClick={this.handleToggleConfirmRemoveContactsModal}
                    buttonDisabled={false}
                    onClose={this.handleCloseReviewContactsForDeletionDialog}
                    closeButtonLabel={translate("btn.cancel")}
                >
                    <ResourceTable
                        commonTable={true}
                        data={Object.values(this.state.reviewContacts)}
                        count={count}

                        tableKey={"ContactID"}

                        onRowClick={this.handleSelectReviewContact}
                        onSelectRow={this.handleSelectReviewContact}
                        selectedRows={this.state.selectedReviewContacts}
                        onSelectAllClick={this.handleSelectAllReviewContacts}

                        fields={{
                            WatcherName: new Field('WatcherName', '', ['empty']),
                            Email: new Field('Email', '', ['empty', 'email']),
                        }}

                        options={{
                            style: {
                                floatingActions: false,
                                stripedRows: true
                            }
                        }}

                        translate={translate}
                    />
                </ModalDefault>

                <ModalConfirm
                    title={translate("Confirm")}
                    show={this.state.isRemoveContactsConfirmModalOpen}
                    text={translate("message.are_you_sure_remove_contacts")}
                    onClose={this.handleToggleConfirmRemoveContactsModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={translate('Cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.handleRemoveWatchers();
                        this.handleToggleConfirmRemoveContactsModal()
                    }}
                />

                {/* All contacts */}
                <ResourceTableDialog
                    show={this.state.isAllContactsModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ContactsQuick}
                    title={translate("btn.add_contacts")}
                    dispatch={this.props.dispatch}

                    fields={{
                        FirstName: new Field('FirstName', '', [''], false),
                        LastName: new Field('LastName', '', [''], false),
                        Email: new Field('Email', '', [''], false)
                    }}

                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}),
                        ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'}, {
                            isClearable: true,
                            api: 'api/' + Resources.ContactGroupsQuick,
                            query: {},
                            searchMap: (it) => ({
                                label: it.ContactGroupName,
                                value: it.ContactGroupID,
                                metadata: it
                            })
                        }),
                    }}

                    defaultQuery={{ExcludeIDs: data.map(it => it.ContactID).join(", ")}}
                    defaultAction={this.handleSelectContact}

                    tableKey={"ContactID"}
                    onSelectRow={this.handleSelectContact}
                    onSelectAllClick={this.handleSelectAllContacts}
                    selectedRows={this.state.selectedWatchersToAdd}

                    buttonLabel={translate('btn.add_selected')}
                    onButtonClick={this.handleAddWatchers}
                    buttonDisabled={!Object.keys(this.state.selectedWatchersToAdd).length}

                    onClose={() => this.setState({isAllContactsModalOpen: false})}
                    translate={translate}
                    commonTable={true}
                />
            </React.Fragment>
        )
    }
}
