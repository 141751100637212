import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import Tippy from '@tippyjs/react'
import ArrowTrendingUpIcon from '@heroicons/react/24/outline/ArrowTrendingUpIcon'
import ArrowTrendingDownIcon from '@heroicons/react/24/outline/ArrowTrendingDownIcon'
import ScaleIcon from '@heroicons/react/24/outline/ScaleIcon'
import IncomeExpenseDialog from '../dialogs/income-expense-dialog'
import {EyeIcon} from '@heroicons/react/24/outline'
import PaymentDialog from '../dialogs/payment-dialog/payment-dialog'
import Env from '../../../util/env'
import {download} from '../../../data/actions/download'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    onDocumentPrint,
    resourceIsLoaded,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {numberWithCommas} from '../../../util/util-formaters'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {getSecondResource} from '../../../data/actions/secondResource'
import FireSolidIcon from '@heroicons/react/20/solid/FireIcon'
import FireIcon from '@heroicons/react/24/outline/FireIcon'
import PresentationChartLineIcon from '@heroicons/react/24/outline/PresentationChartLineIcon'
import {ChevronUpIcon as ChevronUpSolidIcon} from "@heroicons/react/20/solid";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import EmailDocumentDialog from "../../../common/components/modal/modal-email-documents/modal-email-document";
import FileViewer from "../../../common/components/file-viewer/components";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import {getJWT} from "../../../common/util/util-auth";

class SummaryPage extends Component {

    constructor(props) {
        super(props)

        this.colors = ['#171219', '#225560', '#E9DF00', '#F0803C', '#FF66B3', '#084B83', '#42BFDD', '#72BDA3', '#03FCBA', '#770058']
        this.infiniteTableRef = React.createRef()
        this.pagePath = this.props.location.pathname.substring(1)
        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            columns: {
                ID: {
                    minWidth: 220,
                    subColumns: [{'Labels': {show: true, name: 'Labels'}}]
                },
                Labels: {minWidth: 200, show: false}
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sort: 'DESC',
            limit: 100,

            // Fields
            fields: this.getFields(),

            emailFields: this.getEmailFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            columnsModalOpen: false,

            // Dialogs
            selectedItem: null,
            incomeExpenseDialog: false,
            incomeExpenseDetailsDialog: false,
            receivePaymentDialog: false,
            invoicePreviewDialog: false,
            deleteDialog: false,
            sendDocumentDialog: false,
            tagsData: [],
            selectedRows: {},
            isTableFullScreen: false,
            areStatsVisible: LocalStorage.has(this.pageSettingsName) ? JSON.parse(LocalStorage.get(this.pageSettingsName) ?? {})?.areStatsVisible : true,
            breakpoint: {}
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour);
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
        this.PopoverButtonRef = React.createRef()
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
        this.fetchLabels()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsLoaded(this.props.secondResource, prevProps.secondResource) && this.props.secondResource.resource === Resources.ColorTag) {
            this.setState({tagsData: this.props.secondResource.data})
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    handleSetMarkedClick = (isMarkAction = true) => {
        const selectedRows = this.state.selectedRows

        const {InvoiceIDs, ExpenseIDs} = this.getInvoiceExpenseIDs(selectedRows)

        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                InvoiceIDs: InvoiceIDs,
                ExpenseIDs: ExpenseIDs,
                Marked: isMarkAction ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.AccountingMark,
            piggyResource: this.getResource(),
            errorMessage: true,
            successMessage: isMarkAction ? this.props.translate('message.selection_add_marked') : this.props.translate('message.selection_remove_marked')
        }))

        this.setState({selectedRows: {}})
    }

    handleDownloadDocument = (markSent = false) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.BillingDocuments,
            query: Object.assign({}, {
                status: 1,
                InvoiceID: this.state.previewDocument.InvoiceID,
                type: 1,
                name: 'invoice_' + this.state.previewDocument.InvoiceID + currentDate() + '.pdf',
                MarkAsSent: markSent ? 1 : 0
            }, () => this.getQuery),
            errorMessage: true, successMessage: 'Successfully downloaded invoice!'
        }))
    }

    /** UI Events (pagination, filters, sort)
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = ''
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }


    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleSelectRowClick = (item) => {
        const itemID = item.ID

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!!selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            const item = getProp(this.props.resource.data, 'list', []).find(it => it.ID === itemID)
            const ReferenceType = this.isItemInvoice(item) ? 'tbl_Invoice' : 'tbl_Expense'
            Object.assign(selectedRows, {[itemID]: ReferenceType}, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.ID] = this.isItemInvoice(it) ? 'tbl_Invoice' : 'tbl_Expense'
                memo[it.ID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.ID.toString())) {
                    delete selectedRows[it.ID]
                }
            })
        }

        this.setState({selectedRows})
    }

    /** UI Events (Dialogs)
     ================================================================= */
    deleteResource = (item) => {
        this.handleToggleConfirmDialog(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, this.getQuery(), {
                        InvoiceID: item.InvoiceID,
                        ExpenseID: item.ExpenseID
                    }),
                    errorMessage: true, successMessage: `${item.ID} deleted.`,
                    resource: !!item.InvoiceID ? Resources.Invoices : Resources.Expense,
                    piggyResource: this.getResource()
                }))
                this.handleToggleConfirmDialog()
            })
        }, item)
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    toggleIncomeExpenseDialog = (item = null) => {
        this.setState({
            incomeExpenseDialog: !this.state.incomeExpenseDialog,
            selectedItem: item
        })
    }

    toggleIncomeExpenseDetailsDialog = (item = null) => {
        if (item?.Type === "Expense" && !checkPerm(Resources.ExpenseExpense, READ_PERM)) {
            return false;
        }

        if (item?.Type === "Income" && !checkPerm(Resources.InvoicesInvoice, READ_PERM)) {
            return false;
        }

        this.setState({
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
            selectedItem: item
        })
    }

    toggleProcessPreview = (item = null) => {
        this.setState({
            previewDocument: item,
            invoicePreviewDialog: !this.state.invoicePreviewDialog
        })
    }

    toggleReceivePaymentDialog = (item = null) => {
        this.setState({
            receivePaymentDialog: !this.state.receivePaymentDialog,
            selectedItem: item,
        })
    }

    toggleSendPaymentDialog = (item) => {
        this.setState({
            selectedItem: item,
            sendPaymentDialog: !this.state.sendPaymentDialog
        })
    }

    toggleSendEmailDialog = (item = null) => {
        // ignore event
        item = item?.current ? null : item

        if (item) {
            item.DocumentName = item.InvoiceID + '.pdf'
        }

        let emailFields = this.state.emailFields
        emailFields.emails.value = item?.InvoiceSendEmails?.split(',').map(it => ({label: it, value: it})) ?? ''

        this.setState({
            sendDocumentDialog: !this.state.sendDocumentDialog,
            previewDocument: item
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', ['empty'], false, 'custom', {
                omitSort: true,

                render: (item) => {
                    return (
                        <div onClick={() => this.toggleIncomeExpenseDialog(item)} key={item.ID}
                             className="flex cursor-pointer">
                            {item.AutoCounter} {item.InvoiceID ? '(Income)' : '(Expense)'}

                            {!!item.Marked && (
                                <div className="ml-auto flex pl-5">
                                    <Tippy
                                        content={this.props.translate('field.Marked')}
                                    >
                                        <span className="relative">
                                            <FireIcon
                                                className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/>
                                        </span>
                                    </Tippy>
                                </div>
                            )}
                        </div>
                    )
                }
            }),
            RefNumber: new Field('RefNumber', '', [''], false, 'text', {omitSort: true}),
            Labels: new Field('Labels', '', ['empty'], false, 'custom', {
                omitSort: true,
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            Type: new Field('Type', '', [''], false, 'text', {omitSort: true}),
            SubType: new Field('SubType', '', [''], false, 'text', {omitSort: true}),
            Date: new Field('Date', '', [''], false, 'date', {omitSort: true}),
            Organization: new Field('Organization', '', [''], false, 'text', {omitSort: true}),
            Status: new Field('Status', '', [''], false, 'text', {omitSort: true}),
            Amount: new Field('Amount', '', [''], false, 'money', {omitSort: true})
        }
    }

    getInvoiceExpenseIDs = (selectedRows) => {
        return {
            InvoiceIDs: Object.keys(selectedRows).filter(item => selectedRows[item].Type === 'Income'),
            ExpenseIDs: Object.keys(selectedRows).filter(item => selectedRows[item].Type === 'Expense'),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            Method: new Field('Method', 1, [''], false, 'select', {}, {isClearable: true}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getEmailFields = () => {
        return {
            EmailTemplateID: new Field('EmailTemplateID', '', [''], false, 'select-search', {addContainerClass: 'relative col-span-3 md:col-span-6'}),
            emails: new Field('emails', '', ['empty'], false, 'creatable', {
                addContainerClass: 'col-span-8 relative',
                onEnterDown: true
            }, {
                isMulti: true,
                components: {DropdownIndicator: null},
                menuIsOpen: false,
                placeholder: 'Type email...'
            }),
            AddContactID: new Field('AddContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4 relative -ml-px'}, {placeholder: 'Filter contacts...'}),
            subject: new Field('subject', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            emailMessage: new Field('emailMessage', '', [], false, 'rich-text', {addContainerClass: 'col-span-full'}),
        }
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getResource = () => {
        return Resources.AccountingSummary
    }

    getDialogTypeForItem = (item) => {
        if (item?.TypeID == 1) {
            if (item?.SubTypeID == 2) {
                return 'receipt'
            } else {
                return 'invoice'
            }
        } else if (item?.TypeID == 2) {
            if (item?.SubTypeID == 2) {
                return 'expense'
            } else {
                return 'bill'
            }
        }

        return null
    }

    isItemInvoice = (item) => {
        return item?.TypeID == 1
    }

    isItemExpense = (item) => {
        return item?.TypeID == 2
    }

    editHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'Settings.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item.Date) < 0))
            && checkPerm(Resources.Invoices, UPDATE_PERM)
            && !item.HasReference)
    }

    deleteHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'Settings.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item.Date) < 0))
            && checkPerm(Resources.Invoices, DELETE_PERM)
            && !item.HasReference)
    }

    canReceivePayment = (item) => {
        return !((item.StatusID == 1 || item.StatusID == 2))
    }

    canSendPayment = (item) => {
        return item.StatusID == 1
    }

    getKeysForInvoiceExpenseDetailsDialog = (item) => {
        if (item?.TypeID == 1) { // Invoice
            return {
                primaryKey: 'InvoiceID',

                commentIDKey: 'InvoiceCommentID',
                commentValueKey: 'InvoiceCommentNote',
                commentResource: Resources.AccountingIncomeInvoiceComment,

                paymentIDKey: 'RecvPaymentID',
                paymentResource: Resources.InvoicesPayment,

                documentsResource: Resources.InvoicesDocuments,

                piggyResource: Resources.InvoicesInvoice
            }
        } else if (item?.TypeID == 2) { // Expense
            return {
                primaryKey: 'ExpenseID',

                commentIDKey: 'ExpenseCommentID',
                commentValueKey: 'ExpenseCommentNote',
                commentResource: Resources.AccountingExpenseBillComment,

                paymentIDKey: 'SendPaymentID',
                paymentResource: Resources.ExpensePayment,

                documentsResource: Resources.ExpenseDocuments,

                piggyResource: Resources.ExpenseExpense
            }
        }
    }

    handleStatsToggleClick = () => {
        this.setState({areStatsVisible: !this.state.areStatsVisible}, () => {
            let pageSettings = {}

            if (LocalStorage.has(this.pageSettingsName)) {
                pageSettings = JSON.parse(LocalStorage.get(this.pageSettingsName))
            }

            Object.assign(pageSettings, {areStatsVisible: this.state.areStatsVisible})
            LocalStorage.set(this.pageSettingsName, JSON.stringify(pageSettings))
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = !!resource.isLoading;

        const TotalIncome = getProp(resource.data, 'TotalIncome', 0)
        //const PrevTotalIncome = getProp(resource.data, 'count', 0)

        const TotalExpense = getProp(resource.data, 'TotalExpense', 0)
        // const PrevTotalExpense = getProp(resource.data, 'count', 0)

        const TotalIncomeTaxes = getProp(resource.data, 'TotalIncomeTaxes', 0)

        const stats = [
            {
                id: 1,
                name: translate('text.income'),
                icon: ArrowTrendingUpIcon,
                iconClass: 'bg-green-500',
                value: TotalIncome,
                valuePrefix: '$',
                // valueOld: PrevTotalIncome,
                more: [
                    {
                        id: 1,
                        name: translate('text.income'),
                        amount: '$' + numberWithCommas(TotalIncome),
                        icon: ArrowTrendingUpIcon
                    }
                ]
            },
            {
                id: 2,
                name: translate('text.expenses'),
                icon: ArrowTrendingDownIcon,
                iconClass: 'bg-red-500',
                value: TotalExpense + TotalIncomeTaxes,
                valuePrefix: '$',
                // valueOld: PrevTotalExpense,
                more: [
                    {
                        id: 1,
                        name: translate('text.expenses'),
                        amount: '$' + numberWithCommas(TotalExpense),
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 1,
                        name: translate('text.IncomeTaxes'),
                        amount: '$' + numberWithCommas(TotalIncomeTaxes),
                        icon: ArrowTrendingDownIcon
                    },
                ]
            },
            {
                id: 3,
                name: translate('text.profitAndLoss'),
                icon: ScaleIcon,
                value: TotalIncome - TotalExpense - TotalIncomeTaxes,
                valuePrefix: '$',
                // valueOld: PrevTotalIncome - PrevTotalExpense,
                more: [
                    {
                        id: 1,
                        name: translate('text.income'),
                        amount: '$' + numberWithCommas(TotalIncome),
                        icon: ArrowTrendingUpIcon
                    },

                    {
                        id: 2,
                        name: translate('text.expenses'),
                        amount: '$' + numberWithCommas(TotalExpense),
                        icon: ArrowTrendingDownIcon
                    },
                    {
                        id: 3,
                        name: translate('text.IncomeTaxes'),
                        amount: '$' + numberWithCommas(TotalIncomeTaxes),
                        icon: ArrowTrendingDownIcon
                    },

                ]
            }
        ]
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length

        const defaults = getProp(resource.data, 'defaults', [])

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('tab.accounting_summary')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.toggleIncomeExpenseDialog()}
                        buttonHidden={!checkPerm(Resources.Invoices, CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <Tippy
                            content={translate(this.state.areStatsVisible ? 'text.hide_stats' : 'text.show_stats')}
                            delay={[400, 0]}
                            trigger="mouseenter"
                        >
                            <button
                                className={
                                    classNames(
                                        this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                        'btn btn-header'
                                    )
                                }
                                onClick={this.handleStatsToggleClick}
                            >
                                <PresentationChartLineIcon
                                    className="w-5 h-5"
                                />
                            </button>
                        </Tippy>
                    </PageHeader>

                    {this.state.areStatsVisible && (
                        <div>
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 relative z-[31]">
                                <WidgetStats
                                    stats={stats}
                                />
                            </div>
                        </div>
                    )}

                    <div className="flex justify-between">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="flex ml-auto">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            selects={{
                                limit: {10: '10', 20: '20', 30: '30', 40: '40', 50: '∞'},
                                Method: {1: 'Accrual', 2: 'Cash'},
                            }}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />


                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={this.toggleIncomeExpenseDetailsDialog}
                            saveTableOptions={this.setOptions}

                            actions={[
                                {
                                    action: this.toggleIncomeExpenseDetailsDialog,
                                    icon: EyeIcon,
                                    visible: (item) => {
                                        if (item.Type === "Expense") {
                                            return checkPerm(Resources.ExpenseExpense, READ_PERM);
                                        }

                                        if (item.Type === "Income") {
                                            return checkPerm(Resources.InvoicesInvoice, READ_PERM);
                                        }

                                        return true;
                                    },
                                    title: translate('text.show_details'),
                                },
                                /*
                                {
                                    action: this.toggleProcessPreview,
                                    icon: MagnifyingGlassIcon,
                                    visible: (it) => !!it.InvoiceID,
                                    title: translate('text.preview_document'),
                                },
                                {
                                    action: this.toggleIncomeExpenseDialog,
                                    icon: PencilIcon,
                                    visible: (item) => !this.editHidden(item),
                                    title: translate('text.edit'),
                                },
                                {
                                    action: this.toggleReceivePaymentDialog,
                                    icon: ReceiptRefundIcon,
                                    visible: (item) => !this.canReceivePayment(item) && !!this.isItemInvoice(item),
                                    title: translate('text.receive_payment'),
                                },
                                {
                                    action: this.toggleSendPaymentDialog,
                                    icon: CreditCardIcon,
                                    visible: (item) => this.canSendPayment(item) && !this.isItemInvoice(item),
                                    title: translate('text.send_payment'),
                                },
                                {
                                    action: this.toggleSendEmailDialog,
                                    icon: EnvelopeIcon,
                                    visible: (item) => this.isItemInvoice(item) && checkPerm(Resources.BillingDocuments, READ_PERM),
                                    title: translate('btn.send_document_via_email'),
                                },
                                {
                                    action: this.deleteResource,
                                    icon: TrashIcon,
                                    visible: (item) => !this.deleteHidden(item),
                                    title: translate('btn.delete'),
                                }*/
                            ]}

                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={'ID'}
                        />

                        {/*Table footer*/}
                        {!(!data.length && !resource.isLoading) && (
                            <TableCardFooter
                                show={!(!data.length && !resource.isLoading)}
                            >
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === 'offset'
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        )}

                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey="ID"
                            fields={this.getFields()}
                            translate={translate}
                            onSelectAllClick={this.handleSelectAllClick}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                        >
                            <div className='flex items-center h-8'>
                                <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>

                                    {checkPerm(Resources.AccountingMark, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 pr-4">
                                            <Tippy content={translate('btn.set_as_marked')}>
                                                <button
                                                    onClick={() => this.handleSetMarkedClick(true)}
                                                    disabled={!areSomeItemsSelected}
                                                    className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-1 flex justify-center items-center')}
                                                >
                                                    <FireSolidIcon
                                                        className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                                </button>
                                            </Tippy>

                                            <Tippy content={translate('btn.remove_from_marked')}>
                                                <button
                                                    onClick={() => this.handleSetMarkedClick(false)}
                                                    disabled={!areSomeItemsSelected}
                                                    className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-1 flex justify-center items-center')}
                                                >
                                                    <FireIcon
                                                        className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                                </button>
                                            </Tippy>
                                        </div>
                                    )}

                                    {checkPerm(Resources.AccountingColor, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 pl-4">
                                            <TableTagManager
                                                rowKey={'ID'}
                                                id={'ID'}
                                                selectedRows={this.state.selectedRows}
                                                requestMethod={'POST'}
                                                ExpenseIDs={this.getInvoiceExpenseIDs(this.state.selectedRows).ExpenseIDs}
                                                InvoiceIDs={this.getInvoiceExpenseIDs(this.state.selectedRows).InvoiceIDs}
                                                btnIconClass='w-5 h-5'
                                                customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                                resourceName={Resources.AccountingColor}
                                                piggyResourceName={Resources.AccountingSummary}
                                                query={this.getQuery()}
                                                onAfterTagRows={() => {
                                                    this.setState({selectedRows: {}})
                                                }}
                                                translate={translate}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </TableBulkActions>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                            className={'pb-12 pt-16 px-6'}
                        />
                    </TableCard>

                    <TableOptionsDialog
                        show={this.state.columnsModalOpen}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    {this.state.incomeExpenseDialog && (
                        <IncomeExpenseDialog
                            isInvoiceDialogType={this.state.selectedItem?.Type == 1}
                            isExpenseDialogType={this.state.selectedItem?.Type == 2}
                            onClose={this.toggleIncomeExpenseDialog}
                            query={this.getQuery()}
                            dialogType={this.getDialogTypeForItem(this.state.selectedItem)}
                            updateItem={this.state.selectedItem}
                            onSubmit={(params, resource, files, fileResource) => {
                                if (params) {
                                    if (params.id) {
                                        this.props.dispatch(updateResource({
                                            user: LocalStorage.get('user'),
                                            query: this.getQuery(),
                                            params: params,
                                            resource: resource,
                                            file: files,
                                            fileResource: fileResource,
                                            piggyResource: this.getResource(),
                                            errorMessage: true, successMessage: translate('text.entry_updated')
                                        }))
                                    } else {
                                        this.props.dispatch(createResource({
                                            user: LocalStorage.get('user'),
                                            query: this.getQuery(),
                                            params: params,
                                            resource: resource,
                                            file: files,
                                            fileResource: fileResource,
                                            piggyResource: this.getResource(),
                                            errorMessage: true, successMessage: translate('text.entry_created')
                                        }))
                                    }
                                    this.toggleIncomeExpenseDialog()
                                }
                            }}
                            {...this.props}
                        />
                    )}

                    <ModalDefault
                        show={this.state.isDetailsDialogVisible}
                        widthClass={'max-w-5xl w-screen'}
                        title={translate(`text.${this.state.selectedItem?.TypeID == 1 ? 'InvoiceID' : 'ExpenseID'}`)}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={() => this.toggleIncomeExpenseDetailsDialog()}
                    >
                        <InvoiceExpenseDetailsDialog
                            resource={this.props.secondResource}
                            isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading}
                            translate={translate}
                            dispatch={this.props.dispatch}

                            disableComments={true}
                            canDeletePayment={false}
                            disableAddReference={true}
                            disableTransactionImport={true}
                            showLoadReferenceLinks

                            onDocumentDownload={(item) => {
                                if (this.state.selectedItem) {
                                    const {
                                        primaryKey,
                                        documentsResource,
                                    } = this.getKeysForInvoiceExpenseDetailsDialog(this.state.selectedItem)
                                    const fileExt = item?.AttachPath.split('.').pop()

                                    this.props.dispatch(download({
                                        user: LocalStorage.get('user'),
                                        resource: documentsResource,
                                        query: Object.assign({
                                            id: this.state.selectedItem[primaryKey],
                                            format: fileExt,
                                            name: 'document_' + currentDate() + '.' + fileExt
                                        })
                                    }))
                                }
                            }}

                            onFetchData={() => {
                                if (this.state.selectedItem) {
                                    const {
                                        primaryKey,
                                        piggyResource
                                    } = this.getKeysForInvoiceExpenseDetailsDialog(this.state.selectedItem)
                                    this.props.dispatch(getSecondResource({
                                        user: LocalStorage.get('user'),
                                        query: {
                                            [primaryKey]: this.state.selectedItem[primaryKey]
                                        },
                                        resource: piggyResource
                                    }))
                                }
                            }}

                            onDeletePayment={(item) => {
                                const {
                                    paymentResource,
                                    paymentIDKey,
                                    piggyResource,
                                    primaryKey
                                } = this.getKeysForInvoiceExpenseDetailsDialog(this.state.selectedItem)
                                this.props.dispatch(deleteResource({
                                    user: LocalStorage.get('user'),
                                    resource: paymentResource,
                                    query: {
                                        [paymentIDKey]: item[paymentIDKey]
                                    },
                                    piggySecondResource: piggyResource,
                                    secondQuery: {
                                        [primaryKey]: this.state.selectedItem[primaryKey]
                                    },
                                    errorMessage: true, successMessage: translate('text.PaymentDeletedSuccessfully')
                                }))
                            }}
                        />
                    </ModalDefault>

                    <PaymentDialog
                        show={this.state.receivePaymentDialog}
                        payment={'receive'}
                        item={this.state.selectedItem}
                        resourcePath={Resources.InvoicesPayment}
                        piggyResourcePath={this.getResource()}
                        defaults={defaults}
                        query={this.getQuery()}
                        onClose={this.toggleReceivePaymentDialog}
                        {...this.props}
                    />

                    <PaymentDialog
                        show={this.state.sendPaymentDialog}
                        payment={'send'}
                        item={this.state.selectedItem}
                        resourcePath={Resources.ExpensePayment}
                        piggyResourcePath={this.getResource()}
                        query={this.getQuery()}
                        onClose={() => this.toggleSendPaymentDialog()}
                        {...this.props}
                    />

                    <ModalDefault
                        show={this.state.invoicePreviewDialog}
                        widthClass={'max-w-7xl'}
                        limitHeight={true}
                        title={translate('preview') + (this.state.previewDocument?.Description ? ' - ' + this.state.previewDocument?.Description : '')}

                        onButtonClick={this.handleDownloadDocument}
                        buttonLabel={translate('Download')}

                        customButtonsHTML={(
                            <div>
                                <button
                                    className={`btn btn-primary mr-2`}
                                    onClick={() => onDocumentPrint(Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                                        InvoiceID: this.state.previewDocument?.InvoiceID,
                                        token: getJWT().access_token,
                                        type: 1,
                                        name: 'invoice_' + this.state.previewDocument?.InvoiceID + '.pdf'
                                    })), 'pdf')}
                                >
                                    {translate('btn.Print')}
                                </button>
                            </div>
                        )}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={this.toggleProcessPreview}
                        isLoading={false}
                    >
                        {!!this.state.invoicePreviewDialog && (
                            <FileViewer
                                fileType={'pdf'}
                                filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                                    InvoiceID: this.state.previewDocument?.InvoiceID,
                                    token: getJWT().access_token,
                                    type: 1,
                                    name: 'invoice_' + this.state.previewDocument?.InvoiceID + '.pdf'
                                }))}
                                onError={(e) => {
                                    console.log(e)
                                }}
                            />
                        )}
                    </ModalDefault>

                    <ModalConfirm
                        title={translate('text.Confirm')}
                        show={!!this.state.confirmModalOpen}
                        text={translate('message.confirm_delete_resource')}
                        onClose={() => this.setState({confirmModalOpen: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={translate('btn.cancel')}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <EmailDocumentDialog
                        show={this.state.sendDocumentDialog}
                        title={translate('modal_heading.send_document_via_email')}
                        widthClass={'max-w-5xl'}
                        onClose={this.toggleSendEmailDialog}
                        fields={this.state.emailFields}
                        metadata={{
                            AddContactID: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: {offset: 0, sort: 'ASC', limit: 10},
                                searchMap: (item) => ({
                                    value: item.Email,
                                    label: item.FirstName + ' ' + item.LastName
                                })
                            },
                            EmailTemplateID: {
                                api: 'api/' + Resources.EmailTemplates,
                                query: {offset: 0, sort: 'ASC', limit: 10},
                                searchMap: (item) => ({
                                    value: item.EmailTemplateID,
                                    label: item.EmailTemplateName
                                })
                            }
                        }}

                        fetchEmailTemplateQuery={{
                            InvoiceID: this.state?.previewDocument?.InvoiceID,
                            OrganizationID: this.state?.previewDocument?.OrganizationID
                        }}

                        onSubmit={(params) => {
                            if (params) {
                                let emails = params.emails.filter(it => !it.invalid).reduce((memo, it, i) => {
                                    memo += it.value + ','
                                    return memo
                                }, '')

                                this.props.dispatch(getResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, {
                                        status: 1,
                                        InvoiceID: this.state.previewDocument.InvoiceID,
                                        type: 2,
                                        name: this.state.previewDocument.DocumentName,
                                        InvoiceSendEmails: emails.substring(0, emails.length - 1),
                                        subject: params.subject,
                                        message: params.emailMessage
                                    }, this.getQuery()),
                                    resource: Resources.BillingDocuments,
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Successfully sent invoice!'
                                }))

                                this.toggleSendEmailDialog()
                            }
                        }}
                        onPrint={() => {
                            this.handleDownloadDocument()
                            this.toggleSendEmailDialog()
                        }}
                        customButtonsHTML={(
                            <button
                                className={`btn btn-outline ml-2 inline-flex justify-content-center items-center h-3em sm:w-3em`}
                                onClick={() => {
                                    this.handleDownloadDocument(true)
                                    this.toggleSendEmailDialog()
                                }}>
                                <i className="iconsminds-printer"/>
                                <span className="d-none d-sm-inline-block ml-1">Print and Mark as Sent</span>
                            </button>
                        )}
                        document={this.state.previewDocument}
                        documentType={this.state.previewDocument?.InvoiceType}
                        documentDescription={this.state.previewDocument?.ExternalNotes}
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                            InvoiceID: this.state?.previewDocument?.InvoiceID,
                            token: getJWT().access_token,
                            type: 1,
                            name: 'invoice_' + this.state.previewDocument?.DocumentName
                        }))}
                        {...this.props}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(SummaryPage)
