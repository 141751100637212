import React from 'react';

export default function TableHeader({
                                        headerData,
                                        headerClass,
                                        headerRowClass,
                                        headerRender,
                                        hasColumnFilters,
                                        columnFilterRender,
                                        translate
                                    }) {
    return (
        <header className={headerClass ?? "table-header-group"}>
            {hasColumnFilters && columnFilterRender && (
                <div className="flex border-tm-gray-200 border-y">
                    {headerData.map((th) => {
                        return columnFilterRender(th)
                    })}
                </div>
            )}

            <div className={headerRowClass ?? "table-row"}>
                {headerData.map((th, thIndex) => {
                    return (
                        headerRender
                            ? headerRender(th, thIndex)
                            : <th
                                key={
                                    th?.key
                                    ?? (typeof th === 'string' ? th : thIndex)
                                }
                                className={th.class}
                                //style={th.style}
                                ref={th.ref}
                            >
                                {translate ? translate(th) : th}
                            </th>
                    )
                })}
            </div>
        </header>
    )
}
