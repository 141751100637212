import React, {Component} from "react";
import Dropzone from "react-dropzone";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {createResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    TicketTypes
} from "../../../../util/util-constants";
import {hideGlobalModal} from "../../../../data/actions/ui";
import ModalDefault from "../modal-default";
import {classNames, getLookup, getProp} from "../../../util/util-helpers";
import DocumentCard from "../../card/document-card";
import {toFrontDateTime} from "../../../util/util-dates";
import {LoaderSmall} from "../../loader";
import FileViewer from "../../file-viewer/components";
import {fieldsToHtml} from "../../../util/util-fields";

export default class ModalSupport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            files: [],
            canSubmit: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.resource !== prevProps.resource && !!this.props.resource.create && !!this.props.resource.create.id) {
            this.hideModal();
        }

        if (!prevProps.show && !!this.props.show) {
            this.setState({
                fields: this.getFields(),
                files: [],
                canSubmit: false
            })
        }
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDrop = (acceptedFiles) => {
        this.setState({
            files: this.state.files.concat(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))),
            canSubmit: true
        });
    };

    onDropAccepted = () => {
        this.setState({
            dropzoneActive: false,
            filesUploadDialog: true
        });
    }

    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value)
        if (name === "TicketTypeID") {
            fields['TicketLevelID'].type = value == 1 ? 'select' : 'hidden'
            fields['TicketLevelID'].validate = value == 1 ? ['empty'] : []
        }
        this.setState({fields: fields, canSubmit: true});
    };

    submit = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get("user"),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields)),
                    resource: Resources.Tickets,
                    piggyResource: this.props.history.location.pathname == "/ticketing" ? Resources.Tickets : null,
                    piggyQuery: this.props?.ui?.globalDialogs?.ticketingModal?.data?.piggyQuery ?? null,
                    query: {type: 3},
                    file: this.state.files,
                    fileResource: Resources.TicketsImages,
                    fileParams: {},
                    errorMessage: true,
                    successMessage: `Your ${TicketTypes[this.state.fields.TicketTypeID.value]} has been successfully sent to the administration!`,
                }))
                this.hideModal();
            }
        });
    };

    hideModal = () => {
        this.props.dispatch(hideGlobalModal('ticketingModal'));
    }

    handleTogglePreviewDocumentModal = (document = null) => {
        this.setState({previewDocument: document, previewDocumentModal: !this.state.previewDocumentModal});
    }

    removeFileFromState = index => {
        let arr = this.state.files
        if (index > -1) {
            arr.splice(index, 1);
        }
        this.setState({files: arr})
    }

    getFields = () => {
        return {
            Email: new Field('Name', LocalStorage.get("user")?.Contact?.Email, ['empty'], true),
            Title: new Field('Title', this.props?.title ?? "", ['empty']),
            TicketTypeID: new Field('TicketTypeID', "", ['empty'], false, 'select'),
            TicketLevelID: new Field('TicketLevelID', 2, [], false, 'hidden'),
            Description: new Field('Description', "", [], false, 'textarea'),
        }
    }

    render() {
        const {translate} = this.props;
        const docsTypes = getLookup('DocumentType', 'DocumentTypeID', 'DocumentType');

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, this.props.selects ?? {});

        const fileType = this.state.previewDocument?.path && this.state.previewDocument.path.split('.').pop();

        return (
            <React.Fragment>
                <ModalDefault
                    show={this.props.show}
                    title={translate("modal_heading.create_support_ticket")}
                    widthClass={"max-w-md"}
                    buttonLabel={translate("btn.Submit")}
                    onButtonClick={this.submit}
                    onClose={this.hideModal}
                    closeButtonLabel={translate("btn.cancel")}
                    translate={translate}
                >
                    <React.Fragment>
                        {getProp(this.props.user, 'isLoading', false) && (
                            <div className="p-5 text-center">
                                <LoaderSmall/>
                            </div>
                        )}

                        <div className="p-6 text-tm-gray-900">
                            {translate('text.ticket_form_info')}

                            <div className="mt-5">
                                <div className="grid grid-cols-1 gap-5">
                                    {fieldsHtml}
                                </div>

                                <div className="mt-5">
                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            this.onDrop(acceptedFiles)
                                        }}
                                        onDragEnter={this.onDragEnter}
                                        onDragLeave={this.onDragLeave}
                                        onDropAccepted={this.onDropAccepted}
                                        accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                        multiple={true}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}
                                                     className={
                                                         classNames(
                                                             'flex justify-center text-tm-gray-900 items-center h-32 rounded-input border border-dashed mb-5 text-center p-2',
                                                             this.state.dropzoneActive ? 'border-primary' : 'border-tm-gray-300')
                                                     }
                                                >
                                                    <input {...getInputProps()} />
                                                    <div>
                                                        <p className="m-0">{translate('field.dnd_attachment')}</p>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="my-5">
                                    {this.state.files.map((document, i) => {
                                        return (
                                            <DocumentCard
                                                key={i}
                                                layoutSmall={true}
                                                documentID={document.TicketsDocumentID}
                                                description={document.name}
                                                fileName={document.name}
                                                category={docsTypes[document.DocumentTypeID]}
                                                date={toFrontDateTime(document.CreateUpdateDate)}
                                                downloadDocument={this.downloadDocument}
                                                onPreviewDocument={() => this.handleTogglePreviewDocumentModal(document)}
                                                onDeleteDocument={() => this.removeFileFromState(i)}
                                                translate={translate}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <ModalDefault
                            show={this.state.previewDocumentModal}
                            widthClass={'max-w-7xl'}
                            limitHeight={true}
                            title={translate('modal_heading.Preview')}
                            onClose={this.handleTogglePreviewDocumentModal}
                            closeButtonLabel={translate("btn.close")}
                            translate={translate}
                        >
                            <div className="p-5">
                                {fileType && (
                                    <FileViewer
                                        fileType={fileType}
                                        filePath={!!this.state.previewDocument?.preview && this.state.previewDocument.preview}
                                        onError={(e) => {
                                            console.log(e)
                                        }}
                                    />
                                )}
                            </div>
                        </ModalDefault>
                    </React.Fragment>
                </ModalDefault>
            </React.Fragment>
        )
    }
}

