import React, { useEffect, useState } from "react";
import { TruckIcon } from "@heroicons/react/20/solid";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import { getResource } from "../../data/actions/resource";
import { classNames, getLookup } from "../../common/util/util-helpers";
import { toFrontDateTime, toLocalTimeFromUTC } from "../../common/util/util-dates";
import { Link } from "react-router-dom";
import ArrowDownTrayIcon from "@heroicons/react/20/solid/ArrowDownTrayIcon";
import ArrowUpTrayIcon from "@heroicons/react/20/solid/ArrowUpTrayIcon";
import ClipboardDocumentListIcon from "@heroicons/react/20/solid/ClipboardDocumentListIcon";
import PlayIcon from "@heroicons/react/20/solid/PlayIcon";
import FlagIcon from "@heroicons/react/20/solid/FlagIcon";
import moment from "moment";
import { showGlobalModal } from "../../data/actions/ui";
import { DEFAULT_DATABASE_DATETIME_FORMAT } from "../../util/util-constants";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import StackedListModal from "../../common/components/display-data/stacked-list/stacked-list-modal";
import Calendar from "./calendar";

const EVENT_LOAD = 2;
const EVENT_TASK = 1;

export default function CalendarTab( {translate, dispatch, data, isLoading} ) {
    const LoadStatuses = getLookup("LoadStatus", "LoadStatusID", "LoadStatus");

    const [isShowMoreModalOpen, setIsShowMoreModalOpen] = useState(false);
    const [selectedDayEvents, setSelectedDayEvents] = useState([]);

    const toggleShowMoreModal = ( day ) => {
        if (isLoading) return null;

        setIsShowMoreModalOpen(!isShowMoreModalOpen);
        setSelectedDayEvents(day.events);
    }

    const toggleShowSquashedEventsModal = ( events ) => {
        if (isLoading) return null;

        setIsShowMoreModalOpen(!isShowMoreModalOpen);
        setSelectedDayEvents(Object.values(events).flat(1));
    }

    const fetchCalendarData = ( year, month ) => {
        const StartDate = moment(year + "-" + month, "YYYY-MM").subtract(1, 'week').format("YYYY-MM-DD");
        const EndDate = moment(year + "-" + month, "YYYY-MM").endOf('month').add(1, 'week').format("YYYY-MM-DD");

        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Calendar,
            query: {StartDate, EndDate}
        }))
    }

    const [events, setEvents] = useState([]);

    useEffect(() => {
        let events = data.reduce(( memo, event ) => {
            event.Date = convertDate(event.Date, event.Type);
            event.RoundDownMinutesDate = roundDownMinutes(event.Date);

            const eventDate = event.Date.split(' ')[0];

            if (!memo[eventDate]) {
                memo[eventDate] = [];
            }
            memo[eventDate].push(event);
            return memo;
        }, {});

        // Sort daily events by time
        const days = Object.keys(events);
        days.forEach(day => {
            events[day].sort(( a, b ) => {
                return moment(a.Date, DEFAULT_DATABASE_DATETIME_FORMAT).unix() - moment(b.Date, DEFAULT_DATABASE_DATETIME_FORMAT).unix()
            });
        })

        setEvents(events);
    }, [data]);

    const openTaskDialog = ( e ) => {
        if (isShowMoreModalOpen) {
            setIsShowMoreModalOpen(!isShowMoreModalOpen);
        }
        dispatch(showGlobalModal('createTaskDialog', {TaskID: e.currentTarget.dataset.id}))
    }

    const getEvent = ( event ) => {
        switch (event.Type) {
            case 1:
                return (
                    <button
                        data-id={event.Action?.TaskID}
                        onClick={openTaskDialog}
                        className="w-full px-4 py-4 sm:px-6 border-b border-tm-gray-300"
                    >
                        <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-primary truncate font-semibold flex">
                                <Cog6ToothIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-500" aria-hidden="true"/>
                                {event.Title}

                            </p>
                            <div className="ml-2 flex-shrink-0 flex">
                                <p className="mt-2 flex items-center text-sm text-tm-gray-500 sm:mt-0">
                                    <PlayIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400" aria-hidden="true"/>
                                    <div className="w-36 text-right">{toFrontDateTime(event?.Action?.StartDate)}</div>
                                </p>
                            </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                                <p className="mt-2 flex items-center text-sm text-tm-gray-700 sm:mt-0">
                                    <ClipboardDocumentListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400"
                                                       aria-hidden="true"/>
                                    {event?.Action?.TaskStatus}
                                </p>
                            </div>
                            <div className="mt-2 flex items-center text-sm text-tm-gray-500 sm:mt-0">
                                <FlagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400" aria-hidden="true"/>
                                <div className="w-36 text-right">{toFrontDateTime(event?.Action?.DueDate)}</div>
                            </div>
                        </div>
                    </button>
                )
            case 2:
            default:
                return (
                    <Link
                        target={"_blank"} rel={"noopener noreferrer"} to={event.Action.content}
                        className="block px-4 py-4 sm:px-6 border-b border-tm-gray-300"
                    >
                        <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-primary truncate font-semibold flex">
                                <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-500" aria-hidden="true"/>
                                {event.Title}

                            </p>
                            <div className="ml-2 flex-shrink-0 flex">
                                <p className="mt-2 flex items-center text-sm text-tm-gray-500 sm:mt-0">
                                    <ArrowDownTrayIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400"
                                                  aria-hidden="true"/>
                                    <div className="w-36 text-right">{toFrontDateTime(event?.Action?.PickupDate)}</div>
                                </p>
                            </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                                <p className="mt-2 flex items-center text-sm text-tm-gray-700 sm:mt-0">
                                    <ClipboardDocumentListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400"
                                                       aria-hidden="true"/>
                                    {event?.Action?.LoadStatusID ? LoadStatuses[event?.Action?.LoadStatusID] : "N/A"}
                                </p>
                            </div>
                            <div className="mt-2 flex items-center text-sm text-tm-gray-500 sm:mt-0">
                                <ArrowUpTrayIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400" aria-hidden="true"/>
                                <div className="w-36 text-right">{toFrontDateTime(event?.Action?.PickupEndDate)}</div>
                            </div>
                        </div>
                    </Link>
                )
        }
    }

    const renderDayViewEvents = ( events, dayOfTheWeekClass = undefined ) => {
        if (!events) return null;



        events = squashEvents(events);

        return events.filter(event => !event.hidden).map(event => {
            const eventMinutes = moment(event.RoundDownMinutesDate, "YYYY-MM-DD HH:mm:ss").diff(moment(event.RoundDownMinutesDate, 'YYYY-MM-DD'), 'minutes');

            const eventStartPosition = !!eventMinutes ? (eventMinutes / 5) + 2 : 0;

            const eventDuration = 3;

            getEventProps(event);

            const ElementTag = !!event.squashedEvents ? "div" : event.ElementTag;
            const onClick = !!event.squashedEvents ? () => toggleShowSquashedEventsModal(event.squashedEvents) : event.onClick;

            return (
                <li
                    key={eventStartPosition}
                    className={classNames(
                        event.hidden ? "hidden" : "", "relative mt-px flex",
                        dayOfTheWeekClass
                    )}
                    style={{gridRow: eventStartPosition + ' / span ' + eventDuration}}
                >
                    <ElementTag
                        key={event.id}
                        onClick={onClick}
                        target={!!event.route ? "_blank" : undefined}
                        rel={!!event.route ? "noopener noreferrer" : undefined} to={event.route}
                        data-id={event.dataID}
                        className={classNames("flex-auto truncate font-medium text-tm-gray-400 leading-tight flex items-center px-2.5 bg-tm-gray-100 hover:bg-tm-gray-200")}>
                        {!!event.Icon && !event.squashedEvents && (
                            <event.Icon className={classNames(event.iconColor, "flex-shrink-0 w-5 h-5 mr-2")}/>
                        )}

                        <span className="font-semibold text-tm-gray-900">
                            {getDayViewTitle(event, translate)}
                        </span>

                        <span
                            className={classNames(" ml-1 block flex-none text-tm-gray-700 leading-tight")}
                        >
                        {getEventTime(event)}
                    </span>
                    </ElementTag>
                </li>
            )
        })
    }

    const renderMonthlyViewEvents = ( day ) => {
        const events = day.events;

        const maxNoVisible = 7;
        const eventsNo = events.length;
        const hasViewMoreButton = eventsNo > maxNoVisible

        // Skeleton loader
        if (isLoading) return (
            <div className="mt-1 animate-pulse space-y-2">
                {Array.from(Array(maxNoVisible).keys()).map(i => <div key={i} className="h-5 bg-tm-gray-100 rounded"/>)}
            </div>
        );

        let renderedEvents = events.filter(( event, i ) => i <= maxNoVisible - (hasViewMoreButton ? 2 : 1)).map(event => {
            getEventProps(event);

            return (
                <event.ElementTag key={event.Title} onClick={event.onClick} target={!!event.route ? "_blank" : undefined}
                                  rel={!!event.route ? "noopener noreferrer" : undefined} to={event.route}
                                  data-id={event.dataID}
                                  className={classNames("hover:bg-tm-gray-100", "flex w-full cursor-pointer relative z-10 p-1 rounded-btn group overflow-hidden")}
                >
                    <div className="">
                        <p className={classNames("flex-auto truncate font-medium text-tm-gray-400 leading-tight flex items-center")}>
                            {!!event.Icon && (
                                <event.Icon className={classNames(event.iconColor, "flex-shrink-0 w-5 h-5 mr-2")}/>
                            )}

                            <span className="font-semibold text-tm-gray-900">
                                {event.Title}
                            </span>

                            <span
                                className={classNames(" ml-1 block flex-none text-tm-gray-700 leading-tight")}
                            >
                            {getEventTime(event)}
                        </span>
                        </p>
                    </div>
                </event.ElementTag>
            )
        })

        {
            hasViewMoreButton && (
                renderedEvents.push(
                    <button
                        className="w-full text-left p-1 rounded-md text-tm-gray-500 relative cursor-pointer z-10 hover:text-primary hover:bg-tm-gray-100 z-10"
                        onClick={() => toggleShowMoreModal(day)}
                    >
                        + {eventsNo - (maxNoVisible - 1)} more
                    </button>
                )
            )
        }

        return <div className="mt-1">{renderedEvents}</div>;
    }

    const getEventProps = ( event ) => {
        event.ElementTag = "button";

        switch (event.Type) {
            case EVENT_LOAD:
                event.Icon = TruckIcon;
                event.iconColor = "text-green-500"
                event.route = event.Action.content;
                event.ElementTag = Link;
                break;
            case EVENT_TASK:
            default:
                event.Icon = Cog6ToothIcon;
                event.iconColor = "text-blue-500"
                event.onClick = openTaskDialog;
                event.dataID = event.Action?.TaskID;
        }

        return event;
    }

    const renderMonthlyViewMobileEvents = ( events ) => {
        const hasTask = !!events.find(event => event.Type === 1)
        const hasLoad = !!events.find(event => event.Type === 2)

        return (
            <div className="flex space-x-1">
                {hasTask && <Cog6ToothIcon className="w-3 h-3 text-blue-500"/>}
                {hasLoad && <TruckIcon className="w-3 h-3 text-green-500"/>}
            </div>
        )
    }

    const timeDateFormat = LocalStorage.get('user')?.Contact?.DateTimeFormat;
    const timeFormat = !!timeDateFormat && timeDateFormat.substring(timeDateFormat.indexOf(" ") + 1);

    const getEventTime = ( event ) => {
        if (!event?.Date) return null;

        return moment(event.Date, DEFAULT_DATABASE_DATETIME_FORMAT).format(timeFormat);
    }

    const customQuery = ( event, query ) => {
        return event.Title.toLowerCase().includes(query.toLowerCase()) ||
            event?.Action?.TaskStatus && event.Action.TaskStatus.toLowerCase().includes(query.toLowerCase()) ||
            event?.Action?.LoadStatusID && LoadStatuses[event.Action.LoadStatusID].toLowerCase().includes(query.toLowerCase()) ||
            (event?.Action?.StartDate && toFrontDateTime(event.Action.StartDate.toLowerCase()).includes(query.toLowerCase())) ||
            (event?.Action?.DueDate && toFrontDateTime(event.Action.DueDate.toLowerCase()).includes(query.toLowerCase())) ||
            (event?.Action?.PickupDate && toFrontDateTime(event.Action.PickupDate.toLowerCase()).includes(query.toLowerCase())) ||
            (event?.Action?.PickupEndDate && toFrontDateTime(event.Action.PickupEndDate.toLowerCase()).includes(query.toLowerCase()));
    }

    return (
        <React.Fragment>
            <Calendar
                onFetchData={fetchCalendarData}
                events={events}
                onDayClick={toggleShowMoreModal}
                onTimeClick={() => null} // update when create button is defined
                renderMonthlyViewEvents={renderMonthlyViewEvents}
                renderMonthlyViewMobileEvents={renderMonthlyViewMobileEvents}
                renderDayViewEvents={renderDayViewEvents}
                translate={translate}
                addContainerClass={"max-h-[calc(100vh-19rem)]"}
            />

            <StackedListModal
                open={isShowMoreModalOpen}
                setOpen={toggleShowMoreModal}
                list={selectedDayEvents}
                customQuery={customQuery}
                noDataMessage={translate("text.no_events_today")}
                noDataQueryMessage={translate("text.no_events_found")}
                getEvent={getEvent}
            />
        </React.Fragment>
    )
}

const convertDate = ( date, type ) => {
    // Convert all except load time
    if (type === EVENT_LOAD) {
        return date;
    }

    return toLocalTimeFromUTC(date);
}

const squashEvents = ( events ) => {
    let squashedEvents = {};
    return events.map(( event, i ) => {
        if (!!i && !!events[i - 1] && events[i].RoundDownMinutesDate === events[i - 1].RoundDownMinutesDate) {
            events[i - 1].hidden = true;

            if (!squashedEvents[events[i - 1].Type]) {
                squashedEvents[events[i - 1].Type] = [];
            }

            squashedEvents[events[i - 1].Type].push(events[i - 1]);

            if (!events[i + 1] || events[i].RoundDownMinutesDate !== events[i + 1].RoundDownMinutesDate) {
                if (!squashedEvents[events[i].Type]) {
                    squashedEvents[events[i].Type] = [];
                }

                squashedEvents[event.Type].push(events[i]);
                event.squashedEvents = squashedEvents;

                squashedEvents = {}; // reset
            }
        }

        return event;
    });
}

const getDayViewTitle = ( event, translate ) => {
    if (!!event.squashedEvents && !!Object.keys(event.squashedEvents).length) {
        const tasks = !!event.squashedEvents[EVENT_TASK] ? <span className="flex items-center">
            <Cog6ToothIcon className="w-5 h-5 mr-1 text-blue-500"/>
            <span className="hidden 2xl:block">{translate("text.tasks")}</span>
            <span
                className="ml-1 flex items-center justify-center min-w-[4] h-4 rounded-full text-white bg-blue-500 px-2">
                {event.squashedEvents[EVENT_TASK].length}
            </span>
        </span> : null;

        const loads = !!event.squashedEvents[EVENT_LOAD] ? <span className="flex items-center">
            <TruckIcon className="w-5 h-5 mr-1 text-green-500"/>
            <span className="hidden 2xl:block">{translate("text.Loads")}</span>
            <span
                className="ml-1 flex items-center justify-center min-w-[4] h-4 rounded-full text-white bg-green-500 px-2">
                {event.squashedEvents[EVENT_LOAD].length}
            </span>
        </span> : null;

        return <span className="flex items-center space-x-4 mr-3">{tasks} {loads}</span>
    }

    return event.Title
}

const roundDownMinutes = (date) => {
    let dateSplit = date.split(':');
    let minutes = dateSplit[1];
    minutes = Math.round(Number(minutes) / 15) * 15;
    dateSplit[1] = !minutes ? "00" : minutes.toString();
    date = dateSplit.join(":");

    return date;
}
