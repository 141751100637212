import React from "react";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import ArrowPathIcon from "@heroicons/react/20/solid/ArrowPathIcon";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import Tippy from "@tippyjs/react";
import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import { classNames } from "../../util/util-helpers";
import EyeIcon from "@heroicons/react/20/solid/EyeIcon";
import PopOver from "../popover";

export default function PopoverWatchers({watchersContacts, onToggleContactsModal, onRemoveAllClick, translate}) {
    const watcherInfo = (
        <Tippy content={translate('text.task_watch_explain')}>
            <div>
                <InformationCircleIcon className="w-5 h-5 text-blue-500 mr-2"/>
            </div>
        </Tippy>
    )

    return (
        <PopOver
            btnClass={classNames("btn btn-icon", watchersContacts.length ? "pr-8 bg-tm-gray-100" : "")}
            BtnIcon={EyeIcon}
            btnIconClass={classNames('h-4 w-4 relative', watchersContacts.length ? 'mr-1' : '')}
            btnLabelClass={"absolute right-0 top-0 bottom-0 w-8 bg-inverse border border-tm-gray-300 rounded-r-btn flex items-center justify-center"}
            widthClass={'max-w-xs'}
            positionClass="absolute translate-x-0 right-0"
            tippy={translate('text.task_watchers')}
            btnLabel={!!watchersContacts.length && watchersContacts.length}
        >
            <div className="bg-popup rounded-md overflow-hidden relative border border-tm-gray-300">
                {!!watchersContacts.length && (
                    <React.Fragment>
                        {!!onRemoveAllClick && (
                            <button
                                className="btn btn-text w-full hover:bg-tm-gray-100 p-3 justify-center text-base"
                                onClick={() => onRemoveAllClick()}
                            >
                                <XCircleIcon className="h-4 w-4 mr-3"/>

                                {translate('text.RemoveAll')}
                            </button>
                        )}

                        <div
                            className="p-3 space-y-3 max-h-[20rem] overflow-auto">
                            <p className="text-tm-gray-700 flex items-center font-semibold">
                                {watcherInfo}
                                {translate("text.watchers_watching")}
                            </p>

                            {watchersContacts}
                        </div>

                        {!!onToggleContactsModal && (
                            <button
                                className="btn btn-text w-full hover:bg-tm-gray-100 p-3 justify-center text-base"
                                onClick={() => onToggleContactsModal('Watcher')}
                            >
                                <ArrowPathIcon className="h-4 w-4 mr-3"/>

                                {translate('text.update_watchers')}
                            </button>
                        )}
                    </React.Fragment>
                )}

                {!watchersContacts.length && (
                    <React.Fragment>
                        <p className="text-tm-gray-700 font-semibold px-3 my-3 flex items-center">
                            {watcherInfo}
                            {translate("text.no_watchers")}
                        </p>

                        {!!onToggleContactsModal && (
                            <button
                                className="btn btn-text w-full hover:bg-tm-gray-100 p-3 justify-center text-base"
                                onClick={() => onToggleContactsModal('Watcher')}
                            >
                                <PlusIcon className="h-4 w-4 mr-3"/>
                                {translate('btn.add_watchers')}
                            </button>
                        )}
                    </React.Fragment>
                )}
            </div>
        </PopOver>
    )
}