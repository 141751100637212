import React, {useEffect, useState} from "react";
import Card from "../../card";
import WeatherCompactCard from "./weather-compact-card";
import FieldGooglePlaces from "../../fields/field-google-places";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import {ArrowPathIcon} from "@heroicons/react/20/solid";
import InfoBar from "../../info-paragraph/info-bar";
import {LoaderLarge} from "../../loader";
import LocalStorage from "../../../util/localStorage";
import ModalDefault from "../../modal/modal-default";

export default function WeatherCompactWidget({translate}) {

    /** State
     ================================================================= */
    const [weatherData, setWeatherData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isWrongInput, setIsWrongInput] = useState(false);
    const [locations, setLocations] = useState(getLocations());
    const [isKeyMissing, setIsKeyMissing] = useState(false);
    const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);

    /** Data Events
     ================================================================= */
    function fetchData() {
        const key = process.env.REACT_APP_OPEN_WEATHER_MAP_KEY;

        if (!key) {
            setIsKeyMissing(true);
            return null;
        }

        const location = !!locations.length && locations[locations.length - 1];

        if (!location) {
            return false;
        }

        const lon = location.Longitude;
        const lat = location.Latitude;

        const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${key}`;
        setIsLoading(true);

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    setIsLoading(false);
                    throw new Error('Network response was not ok');
                }

                return response.json();
            })
            .then(data => {
                setWeatherData(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }

    /** Helpers
     ================================================================= */
    function handleSetLocation(fields = {}) {

        if (!fields?.Latitude || !fields?.Longitude || !fields?.CityName || !fields?.query) {
            setIsWrongInput(true);
            return false;
        }

        setIsWrongInput(false);

        let locationsUpdate = [...locations];

        const removeIndex = locationsUpdate.findIndex(it => it.query === fields?.query);
        if (removeIndex !== -1) {
            locationsUpdate.splice(removeIndex, 1);
        }

        if (locationsUpdate.length > 6) {
            locationsUpdate.shift();
        }

        locationsUpdate.push({
            Latitude: fields?.Latitude,
            Longitude: fields?.Longitude,
            CityName: fields?.CityName,
            query: fields.query
        });


        setLocations(locationsUpdate);
        setIsLocationDialogOpen(false);
        LocalStorage.set('weather_locations', locationsUpdate);
    }

    function getLocations() {
        let locations = LocalStorage.get('weather_locations', null);

        if (!locations) {
            // Default hardcoded location
            return [{
                CityName: "Chicago",
                Latitude: 41.8781136,
                Longitude: -87.6297982,
                query: "Chicago, IL, USA"
            }];
        }

        return locations;
    }

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        if (locations[0]) {
            fetchData();
        }
    }, [locations]);

    /** Render
     ================================================================= */
    return (
        <>
            <div className="relative">
                <Card>
                    {!isLoading && (
                        <WeatherCompactCard
                            weatherData={weatherData}
                            locationData={locations.length ? locations[locations.length - 1] : undefined}
                        />
                    )}

                    {isLoading && (
                        <div className="relative h-20">
                            <LoaderLarge/>
                        </div>
                    )}

                    {isKeyMissing && (
                        <InfoBar type="danger">API key is missing.</InfoBar>
                    )}
                </Card>

                {!isKeyMissing && (
                    <button
                        className="absolute p-1 top-3 right-4 rounded-full bg-primary text-inverse hover:text-primary-contrast"
                        onClick={() => setIsLocationDialogOpen(true)}
                    >
                        {!locations.length && (
                            <PlusIcon className="w-7 h-7"/>
                        )}

                        {!!locations.length && (
                            <ArrowPathIcon className="w-7 h-7"/>
                        )}
                    </button>
                )}
            </div>

            <ModalDefault
                show={isLocationDialogOpen}
                widthClass={'max-w-2xl'}
                title={<h5>Add new location</h5>}

                closeButtonLabel={translate('btn.cancel')}
                onClose={() => {
                    setIsLocationDialogOpen(false);
                    setIsWrongInput(false);
                }}
            >
                <div className="p-5">
                    <FieldGooglePlaces
                        searchForAll
                        autoFocus
                        stateFields={{Latitude: true, Longitude: true}}
                        setLocations={handleSetLocation}
                        placeholderText={translate('text.enter_place')}
                    />

                    {isWrongInput && (
                        <InfoBar addClass="mt-4" type="warning">Please select more specific location.</InfoBar>
                    )}

                    {locations.length > 1 && (
                        <>
                            <p className="text-lg pl-2 mt-8">Previous locations</p>
                            <ul className="mt-4 divide-y divide-tm-gray-200 border-b border-t border-tm-gray-200">
                                {locations.reduce((memo, location, i) => {
                                    if (i !== locations.length - 1) {
                                        memo.push(<li
                                            onClick={() => handleSetLocation(location)}
                                            key={location.query}
                                            className="p-4 hover:bg-primary-transparent"
                                        >
                                            <p className="truncate text-sm font-medium text-tm-gray-900">{location.CityName}</p>
                                            <p className="truncate text-sm font-medium text-tm-gray-500">{location.query}</p>
                                        </li>)
                                    }

                                    return memo;
                                }, [])}
                            </ul>
                        </>

                    )}
                </div>
            </ModalDefault>
        </>

    )
}