import React from "react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import ModalFooter from "../modal-footer";
import {classNames} from "../../../util/util-helpers";
import Modal from "../index";

export default function ModalConfirm(props) {
    const {
        type,
        show,
        title,
        text,
        textClassName,
        onConfirm,
        buttonLabel,
        buttonDisabled,
        closeButtonLabel,
        onClose,
        secondaryButtonLabel,
        onSecondaryButtonClick,
        secondaryButtonDisabled,
        customButtonsHTML,
        customIcon,
        customIconBG = "transparent",
        width,
        hideModalFooter
    } = props;

    const getIcon = () => {
        let icon, iconBg;

        switch (type) {
            case 'success':
                icon = <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                iconBg = "bg-green-600/10"
                break;
            case 'info':
                icon = <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true"/>
                iconBg = "bg-blue-600/10"
                break;
            case 'warning':
                icon = <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true"/>
                iconBg = "bg-yellow-400/10"
                break;
            case 'custom':
                icon = customIcon ?? <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                iconBg = customIconBG
                break;
            default:
                iconBg = "bg-red-600/10"
                icon = <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
        }

        return (
            <div
                className={classNames(iconBg, "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10")}>
                {icon}
            </div>
        )
    }
    return (
        <Modal
            close={onClose}
            widthClass={getDialogWidth(width)}
            show={show}
            {...props}
        >
            <div className={
                classNames(
                    "sm:flex sm:items-start p-6 rounded-card",
                    type === "warning" ? "border-t-2 border-yellow-400" : undefined,
                    type === "info" ? "border-t-2 border-blue-600" : undefined,
                    type === "danger" ? "border-t-2 border-red-600" : undefined
                )
            }>
                {getIcon()}

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-[calc(100%-5rem)]">
                    <h3 className="text-lg leading-6 font-medium text-tm-gray-900">
                        {title}
                    </h3>

                    <div className="mt-2 text-tm-gray-700">
                        {text && (
                            <p className={textClassName ?? "text-sm text-tm-gray-900 truncate"}
                               dangerouslySetInnerHTML={{__html: text}}/>
                        )}

                        {!!show && props.children}
                    </div>
                </div>
            </div>

            {!hideModalFooter && (
                <ModalFooter
                    buttonDisabled={buttonDisabled}
                    buttonLabel={buttonLabel} // use nullable value to hide the button
                    onButtonClick={onConfirm}

                    closeButtonLabel={closeButtonLabel}
                    onClose={onClose}

                    secondaryButtonLabel={secondaryButtonLabel}
                    secondaryButtonDisabled={secondaryButtonDisabled}
                    onSecondaryButtonClick={onSecondaryButtonClick}
                >
                    {!!customButtonsHTML && (
                        props.customButtonsHTML
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
}

const getDialogWidth = (width) => {
    switch (width) {
        case "md":
            return "max-w-md";
        case "xl":
            return "max-w-xl";
        case "2xl":
            return "max-w-2xl";
        case "3xl":
            return "max-w-3xl";
        case "4xl":
            return "max-w-4xl";
        case "5xl":
            return "max-w-5xl";
        case "6xl":
            return "max-w-6xl";
        case "7xl":
            return "max-w-7xl";
        default:
            return "max-w-xl"
    }
}
