/* eslint-disable react/no-direct-mutation-state */
import React, {Component} from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';

export default class DailyCron extends Component {
    constructor(props) {
        super(props);
        this.state = {
            every: true
        };

        this.onDayChange = this.onDayChange.bind(this);
        this.onAtHourChange = this.onAtHourChange.bind(this);
        this.onAtMinuteChange = this.onAtMinuteChange.bind(this);
    }

    componentWillMount() {
        this.state.value = this.props.value;
    }

    onDayChange(e) {
        let val = [this.state.value[0], this.state.value[1], '*', this.state.value[3], this.state.value[4]]
        val[2] = e.target.value ? `*/${e.target.value}` : '*/1';
        this.props.onChange(val);
    }

    onAtHourChange(e) {
        let val = [this.state.value[0], '*', this.state.value[2], this.state.value[3], this.state.value[4]]
        val[1] = e.target.value ? `${e.target.value}` : '0';
        this.props.onChange(val);
    }

    onAtMinuteChange(e) {
        let val = ['*', this.state.value[1], this.state.value[2], this.state.value[3], this.state.value[4]]
        val[0] = e.target.value ? `${e.target.value}` : '0';
        this.props.onChange(val);
    }

    render() {
        const translateFn = this.props.translate;
        this.state.value = this.props.value;
        return (
            <div className="py-6 space-y-3">
                <div className="my-2 flex items-center justify-between">
                    <div className="space-x-3 flex items-center">
                        <input id="DailyRadio" type="radio" onChange={(e) => {
                            this.setState({every: true});
                            this.props.onChange([this.state.value[0], this.state.value[1], '*/1', '*', '*']);
                        }} value="1" name="DailyRadio" checked={this.state.every}
                        />

                        <label className="cursor-pointer text-sm font-semibold text-tm-gray-900"
                               htmlFor="DailyRadio">{translateFn('Every')}</label>
                    </div>

                    <div className="flex items-center space-x-3">
                        <input
                            id="days"
                            className="w-24 form-control bg-tm-gray-100"
                            type="Number"
                            maxLength="2"
                            onChange={this.onDayChange}
                            value={this.state.value[2].split('/')[1] ? this.state.value[2].split('/')[1] : ''}
                        />

                        <label htmlFor="days" className="w-12 cursor-pointer">{translateFn('day(s)')}</label>
                    </div>
                </div>

                <div className="my-2 flex items-center justify-between">
                    <div className="space-x-3 flex items-center">
                        <input
                            onChange={(e) => {
                                this.setState({every: false});
                                this.props.onChange([this.state.value[0], this.state.value[1], '*', '*', 'MON-FRI'])
                            }} type="radio" value="2" id="WeeklyRadio" name="DailyRadio" checked={!this.state.every}
                        />

                        <label className="text-sm font-semibold text-tm-gray-900"
                               htmlFor="WeeklyRadio">{translateFn('Every week day')}</label>
                    </div>

                    <div className="flex items-center pr-[3.75rem] space-x-3">
                        <span className="margin-right-10 ">{translateFn('At')}</span>
                        <Hour onChange={this.onAtHourChange}
                              value={this.state.value[1]}/>
                        <Minutes onChange={this.onAtMinuteChange}
                                 value={this.state.value[0]}/>
                    </div>
                </div>
            </div>
        )
    }
}