import React, {Component, Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import BellIcon from "@heroicons/react/24/outline/BellIcon";
import {connect} from "react-redux";
import {classNames, generateCustomNotification} from "../../../../util/util-helpers";
import {toFrontDateTime} from "../../../../util/util-dates";

class NotificationsMenu extends Component {

    render() {
        const {addClass, translate} = this.props;

        const notificationList = this.props.notifications?.data?.notifications?.list?.map(it => {
            it['content'] = it.Message
            it['notificationMetadata'] = it.Metadata
            it['title'] = it.Title

            return Object.assign(
                {...it},
                generateCustomNotification(it, this.props.dispatch, translate, this.props.history)
            )
        })?.reverse() ?? [];

        return (
            <Popover className={classNames(addClass, "relative")}>
                {({open}) => (
                    <>
                        <Popover.Button
                            className={classNames(open ? 'text-tm-gray-900' : '', 'icon-btn')}
                        >
                            <BellIcon
                                className={classNames('h-6 w-6 group-hover:text-tm-gray-900')}
                                aria-hidden="true"
                            />
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-sm">
                                <div
                                    className="overflow-auto  max-h-[calc(100vh-5rem)] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-popup border border-tm-gray-300">
                                    <div className="relative grid gap-8 p-7">
                                        {notificationList.map((item) => (
                                            <div
                                                key={item.ID}
                                                onClick={item.onClickEvent}
                                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out cursor-pointer rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-text-primary500 focus-visible:ring-opacity-50"
                                            >
                                                <div className="ml-4 w-full">
                                                    <p className="text-sm font-medium text-tm-gray-900 flex">
                                                        {item.title}

                                                        <div className="ml-auto">
                                                            {toFrontDateTime(item.DateTime)}
                                                        </div>
                                                    </p>

                                                    <div className="text-sm text-tm-gray-500"
                                                         dangerouslySetInnerHTML={{__html: item.content}}/>
                                                </div>
                                            </div>
                                        ))}

                                        {(!notificationList || (notificationList.length === 0)) && (
                                            <div className="text-center">
                                                <span className="text-sm text-tm-gray-900 text-center">
                                                    {translate("text.no_notifications_list")}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        )
    }
}

export default connect((state) => ({
    user: state.user,
    notifications: state.notifications
}))(NotificationsMenu);
