import React from 'react'

const MobileTabs = (props) => {
    const {className, tabs, onTabChange, translate} = props;

    return (
        <div className={className ?? "sm:hidden"}>
            <label htmlFor="tabs" className="sr-only">
                Select a tab
            </label>

            <select
                id="tabs"
                name="tabs"
                className="block w-full pl-3 pr-10 py-2 text-base border-tm-gray-300 focus:outline-none focus:ring-primary-600 focus:border-button sm:text-sm capitalize bg-field"
                value={tabs.find((tab) => tab.current).resource}
                onChange={(e) => onTabChange(e.target.value)}
            >
                {tabs.filter(it => it.visible).map((tab) => (
                    <option
                        className="capitalize"
                        key={tab.name}
                        value={tab.resource}
                    >{translate("tab." + tab.name)}</option>
                ))}
            </select>
        </div>
    )
}

export default MobileTabs

