import React, {useRef, useState} from 'react';
import LoadInfoCommodities from '../../load-info-commodities';
import { convertMeasurements, getCommodityFields } from '../../../load-utils';
import CommodityForm from '../../../../templates/dialogs/commodity-form';
import { cloneDeep } from '../../../../../../common/util/util-vanilla';
import {numberWithCommas} from "../../../../../../util/util-formaters";
import LocalStorage from "../../../../../../util/localStorage";
import ModalConfirm from "../../../../../../common/components/modal/modal-confirm";
import Modal from "../../../../../../common/components/modal";
import {cnv, getWeightUnitName} from "../../../../../../common/util/util-helpers";

export default function LoadPlaningCommodities({translate, commodityData, setCommodityData}) {
    const defaultWeightUnit = useRef(LocalStorage.get('user')?.Contact?.WeightUnit ?? 'lb');

    const [selectedCommodityItem, setSelectedCommodityItem] = useState(undefined);
    const [selectedCommodityItemIndex, setSelectedCommodityItemIndex] = useState(undefined);
    const [isCommodityDialogOpen, setIsCommodityDialogOpen] = useState(false);
    const [commodityDelete, setCommodityDelete] = useState({});
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    let commodityUnitType = undefined;

    const totalCommodityWeight = numberWithCommas(commodityData.reduce((memo, it) => {
        if (!commodityUnitType) {
            commodityUnitType = it.UnitType
        }

        if (!!commodityUnitType && commodityUnitType !== it.UnitType) {
            commodityUnitType = translate("text.unit_s");
        }

        if (!it.Weight) {
            return memo
        }

        memo = memo + Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), defaultWeightUnit?.current))
        return memo
    }, 0))

    const toggleCommodityFormModal = (selectedCommodityItem = null, selectedCommodityItemIndex = null) => {
        if (!!isCommodityDialogOpen || !!selectedCommodityItem?.target) {
            selectedCommodityItem = null
            selectedCommodityItemIndex = null
        }

        setIsCommodityDialogOpen(!isCommodityDialogOpen)
        setSelectedCommodityItem(selectedCommodityItem);
        setSelectedCommodityItemIndex(selectedCommodityItemIndex);

    }

    const handleCommodityFormSubmitClick = (params, fields, selects) => {
        if (params) {
            // Save commodity???
            if (params.CommodityID === -1 && !!params.updateCommodity) {
                params.saveCommodity = 1
            }

            let commodityDataClone = [...commodityData]

            if (!!selectedCommodityItemIndex || selectedCommodityItemIndex === 0) {
                commodityDataClone[selectedCommodityItemIndex] = params
            } else {
                commodityDataClone.push(params)
            }
            setCommodityData(commodityDataClone);
            toggleCommodityFormModal()
        }
    }

    function handleDeleteCommodityClick(it, i) {
            setCommodityDelete({
                index: i,
                data: it
            });
            setIsConfirmDialogOpen(true);
    }

    function handleConfirmButtonClick() {
        const CommodityData = cloneDeep(commodityData);

        CommodityData.splice(commodityDelete.it, 1)

        setCommodityData(CommodityData);
        setIsConfirmDialogOpen(false);
    }


    return (
        <React.Fragment>
            <LoadInfoCommodities
                addClass="p-6 border-t border-tm-gray-300"
                data={convertMeasurements(cloneDeep(commodityData))}
                originalData={commodityData}
                getCommodityFields={getCommodityFields}
                toggleCommodityFormModal={toggleCommodityFormModal}
                handleDeleteCommodityClick={handleDeleteCommodityClick}
                totalCommodityWeight={totalCommodityWeight}
                defaultWeightUnit={defaultWeightUnit?.current}
                isSectionExpanded={true}
                isCollapseDisabled={true}
                translate={translate}
            />

            <Modal
                show={!!isCommodityDialogOpen}
                widthClass="max-w-full md:max-w-2xl"
                onClose={toggleCommodityFormModal}
            >
                <CommodityForm
                    title={(selectedCommodityItem ? 'Edit' : 'Add') + ' commodity'}
                    onClose={toggleCommodityFormModal}
                    translate={translate}
                    selectedItem={selectedCommodityItem}
                    onSubmit={handleCommodityFormSubmitClick}
                />
            </Modal>

            <ModalConfirm
                title={translate('text.confirm')}
                show={isConfirmDialogOpen}
                text={translate('text.remove_commodity', [commodityDelete?.data?.ProductName])}
                onClose={() => setIsConfirmDialogOpen(false)}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmButtonClick}
            />
        </React.Fragment>
    )
}
