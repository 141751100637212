import React, {Component} from "react";
import {deleteResource} from "../../../../data/actions/resource";
import {hideGlobalModal} from "../../../../data/actions/ui";
import LocalStorage from "../../../util/localStorage";
import ModalDefault from "../modal-default";
import PersonalNoteForm from "../personal-note-form";
import Resources from "../../../../data/services/resources";

export default class PersonalNoteDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            confirmModalOpen: false
        }
        this.editMode = props.data && props.data.ID;
    }


    handleClose = () => {
        this.props.dispatch(hideGlobalModal("PersonalNoteDialog"));
    }

    setConfirmModalOpen = () => {
        this.setState({confirmModalOpen: !this.state.confirmModalOpen})
    }
    handleDeleteNote = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.data.ID
            },
            errorMessage: true, successMessage: this.props.translate("text.note_deleted"),
            resource: Resources.NotesPersonal,
        }))
        this.setConfirmModalOpen()
    }

    render() {
        const {translate, show, data, isLoading} = this.props;
        return (
            <ModalDefault
                hideDialogFooter={true}
                title={translate(this.editMode ? "modal_heading.edit_personal_note" : "modal_heading.create_personal_note")}
                show={show}
                onButtonClick={this.submit}
                // buttonLabel={translate("btn.save")}
                // buttonDisabled={!this.state.canSubmit}
                onClose={this.handleClose}
                closeButtonLabel={translate("btn.close")}
                translate={translate}
                {...this.props}
            >
                <PersonalNoteForm
                    Id={this.props?.data?.ID}
                    data={data}
                    isHome={this.props.location?.pathname === "/home"}
                    onClose={this.handleClose}
                    isLoading={isLoading}
                    translate={translate}
                />
            </ModalDefault>
        )
    }
}
