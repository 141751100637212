import React from "react";

export default function FormSubtitle({subtitle, strapline}) {
    return (
        <div className="mt-6 col-span-full">
            <div className="relative mb-2">
                <span className="text-lg bg-popup pr-3 leading-6 font-medium text-tm-gray-700 relative z-10">{subtitle}</span>

                <div className="border-b border-tm-gray-300 absolute left-0 right-0 top-1/2 bottom-auto" />
            </div>

            {!!strapline && (
                <p className="text-tm-gray-700 mt-2">{strapline}</p>
            )}
        </div>
    )
}