import React, {Component} from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';

export default class WeeklyCron extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onAtHourChange = this.onAtHourChange.bind(this);
        this.onAtMinuteChange = this.onAtMinuteChange.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    onAtHourChange(e) {
        let val = this.state.value;
        val[1] = `${e.target.value}`;
        this.props.onChange(val);
    }

    onAtMinuteChange(e) {
        let val = this.state.value;
        val[0] = `${e.target.value}`;
        this.props.onChange(val);
    }

    onCheck(e) {
        let val = this.state.value;
        val[0] = '0';
        if (e.target.checked) {
            this.onDayChecked(val, e);
        } else {
            this.onDayUnChecked(val, e);
        }
        this.props.onChange(val)
    }

    onDayChecked(val, e) {
        if (val[4] === '*') {
            val[4] = e.target.value;
        } else {
            val[4] = val[4] + '!' + e.target.value;
        }
    }

    onDayUnChecked(val, e) {
        val[4] = val[4].split('!');
        if (val[4].length > 1) {
            val[4].splice(val[4].indexOf(e.target.value), 1);
            val[4] = val[4].toString().replace(/,/g, '!');
        } else {
            val[4] = '*';
        }
    }

    render() {
        const translateFn = this.props.translate;
        this.state.value = this.props.value;
        return (
            <div className="my-2 py-6 flex flex-col justify-start space-y-3">
                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="1" onChange={this.onCheck}
                               checked={(this.state.value[4].search('1') !== -1) ? true : false}
                        />

                        {translateFn('Monday')}
                    </label>
                </div>

                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="2" onChange={this.onCheck}
                               checked={this.state.value[4].search('2') !== -1 ? true : false}/>{translateFn('Tuesday')}
                    </label>
                </div>

                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="3" onChange={this.onCheck}
                               checked={this.state.value[4].search('3') !== -1 ? true : false}/>{translateFn('Wednesday')}
                    </label>
                </div>

                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="4" onChange={this.onCheck}
                               checked={this.state.value[4].search('4') !== -1 ? true : false}/>{translateFn('Thursday')}
                    </label>
                </div>

                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="5" onChange={this.onCheck}
                               checked={(this.state.value[4].search('5') !== -1) ? true : false}/>{translateFn('Friday')}
                    </label>
                </div>

                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="7" onChange={this.onCheck}
                               checked={this.state.value[4].search('7') !== -1 ? true : false}/>{translateFn('Saturday')}
                    </label>
                </div>

                <div>
                    <label className="cursor-pointer">
                        <input className="mr-2" type="checkbox" value="6" onChange={this.onCheck}
                               checked={this.state.value[4].search('6') !== -1 ? true : false}/>{translateFn('Sunday')}
                    </label>
                </div>

                <div className="flex items-center justify-between space-x-3">
                    <div className="text-sm font-semibold text-tm-gray-900">{translateFn('Start time')}</div>

                    <div className="my-2 space-x-3">
                        <Hour onChange={this.onAtHourChange} value={this.state.value[1]}/>
                        <Minutes onChange={this.onAtMinuteChange} value={this.state.value[0]}/>
                    </div>
                </div>
            </div>
        )
    }
}

