import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, FieldsManager} from '../../../../data/services/fields'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {getSecondResource} from '../../../../data/actions/secondResource'
import {createResource} from '../../../../data/actions/resource'
import axios from 'axios'
import Env from '../../../../util/env'
import {processResponse} from '../../../../data/services/api-util'
import {UPDATE_PERM} from '../../../../util/util-constants'
import {checkPerm, classNames, getLookup, getProp} from "../../../util/util-helpers";
import {dirtyClone} from "../../../util/util-vanilla";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";
import {LoaderSmall} from "../../loader";
import Card from "../../card";
import FieldText from "../../fields/field-text";
import FieldSelectSearch from "../../fields/field-select-search";
import FieldGooglePlaces from "../../fields/field-google-places";
import FieldDropdownSelect from "../../fields/field-dropdown-select";
import {getJWT} from "../../../util/util-auth";
import DragAndDrop from "../truckDialogs/createTruckLocationDialog/DragAndDrop";
import ModalDefault from "../modal-default";

class CreateUpdateUnitDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: this.checkIfUpdate() ? 'Manually' : 'Select',
            fields: this.getFields(this.props.item),
            ManuallyFields: this.getManuallyFields(this.props.item),
            SelectFields: {
                LocationID: new Field('LocationID', '', ['empty'], false, 'select-search')
            },
            DragAndDropFields: {
                Latitude: new Field('Latitude', '', ['empty']),
                Longitude: new Field('Longitude', '', ['empty']),
            },
            canSubmit: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            if (this.props.fetchByKey) this.fetchData()
            if (!this.props.fetchByKey) {
                this.setState({
                    activeTab: this.checkIfUpdate() ? 'Manually' : 'Select',
                    fields: this.getFields(this.props.item),
                    ManuallyFields: this.getManuallyFields(this.props.item),
                    SelectFields: {
                        LocationID: new Field('LocationID', '', ['empty'], false, 'select-search')
                    },
                    DragAndDropFields: {
                        Latitude: new Field('Latitude', '', ['empty']),
                        Longitude: new Field('Longitude', '', ['empty']),
                    },
                    canSubmit: false
                })
            }
        }
        if (this.props.fetchByKey && prevProps.secondResource !== this.props.secondResource && !this.props.secondResource.isLoading) {
            let data = getProp(this.props.secondResource, 'data', {});
            const labelKey = this.props.fetchByKey.replace('ID', '');
            data[this.props.fetchByKey] = this.props.fetchByValue

            if (data[labelKey]) {
                data[labelKey] = this.props?.item[this.props.fetchByLabel] ?? "";
            }

            this.setState({
                activeTab: this.checkIfUpdate() ? 'Manually' : 'Select',
                fields: this.getFields(data),
                ManuallyFields: this.getManuallyFields(data),
                SelectFields: {
                    LocationID: new Field('LocationID', '', ['empty'], false, 'select-search')
                },
                DragAndDropFields: {
                    Latitude: new Field('Latitude', '', ['empty']),
                    Longitude: new Field('Longitude', '', ['empty']),
                },
                canSubmit: false
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.UnitInfo,
            query: {
                key: this.props.fetchByKey,
                value: this.props.fetchByValue
            }
        }))
    }

    handleInputChange = (name, value, tab) => {
        if (tab) {
            if (name === 'CountryID' && value != 1) {
                this.setState({
                    [`${tab}Fields`]: FieldsManager.updateField(this.state[`${tab}Fields`], 'StateID', ''),
                    canSubmit: true
                })
            }
            this.setState({
                [`${tab}Fields`]: FieldsManager.updateField(this.state[`${tab}Fields`], name, value),
                canSubmit: true
            })
        } else {
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true})
        }
    }

    addMarker = (cords) => {
        this.setState({
            DragAndDropFields: {
                Latitude: new Field('Latitude', cords.Latitude, []),
                Longitude: new Field('Longitude', cords.Longitude, []),
            },
            canSubmit: true
        })
    }

    hideModal = () => {
        this.props.onClose()
    }

    setActiveTab = (resource) => {
        this.setState({activeTab: resource})
    }

    submit = (event) => {
        event && event.preventDefault()
        this.setState({
            [`${this.state.activeTab}Fields`]: this.props.disableLocationEdit ? this.state[`${this.state.activeTab}Fields`] : FieldsManager.validateFields(this.state[`${this.state.activeTab}Fields`]),
            fields: FieldsManager.validateFields(this.state.fields)
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state[`${this.state.activeTab}Fields`]) && FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.props.onSubmit) {
                    this.props.onSubmit(Object.assign({},
                        FieldsManager.getFieldKeyValues(this.state[`${this.state.activeTab}Fields`]),
                        FieldsManager.getFieldKeyValues(this.state.fields)
                    ))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state[`${this.state.activeTab}Fields`]),
                            FieldsManager.getFieldKeyValues(this.state.fields)
                        ),
                        query: this.props.query,
                        errorMessage: true, successMessage: this.checkIfUpdate() ? 'Unit updated' : `Unit created`,
                        resource: this.props.resourcePath,
                        piggyResource: this.props.resourcePath
                    }))
                    this.hideModal()
                }
            }
        })
    }

    setLocations = (fields) => {
        let updatedFields = dirtyClone(this.state.ManuallyFields)

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.ManuallyFields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value);
            }
        }

        this.setState({
            ManuallyFields: updatedFields,
            canSubmit: true
        })
    }

    getFields = (item = {}) => {
        let fieldTemplates = this.props.fields

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    getManuallyFields = (item = {}) => {
        let fieldTemplates = {
            AddressName: new Field('AddressName', '', ['empty']),
            CityName: new Field('CityName', '', ['empty']),
            StateID: new Field('StateID', '', [], false, 'select'),
            PostalCode: new Field('PostalCode', '', ['empty']),
            CountryID: new Field('CountryID', 1, ['empty'], false, 'select'),
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    checkIfUpdate = () => !!this.props.item

    render() {
        const {translate, selects} = this.props

        const fields = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, selects)
        return (
            <ModalDefault
                onClose={this.hideModal}
                closeButtonLabel={'Close'}
                widthClass={'max-w-7xl w-screen'}
                show={this.props.show}
                title={translate(`text.${this.props.title}`)}

                buttonDisabled={!this.state.canSubmit}
                buttonLabel={checkPerm(Resources.Units, UPDATE_PERM) ? 'Save' : null}
                onButtonClick={this.submit}
            >
                {this.props.secondResource.isLoading && (
                    <div className="p-5 text-center">
                        <LoaderSmall/>
                    </div>
                )}

                <div className={classNames('grid gap-4 p-6 grid-cols-12')}>
                    {fields}
                </div>

                {!this.props.disableLocationEdit && !this.checkIfUpdate() && (
                    <ul className="nav nav-tabs separator-tabs ml-0 mb-5 p-3" role="tablist">
                        <li className="nav-item">
                            <button
                                onClick={() => this.setActiveTab('Select')}
                                className={`nav-link ${this.state.activeTab === 'Select' ? 'active' : ''}`}
                            >
                                {translate("text.select")}
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => this.setActiveTab('Manually')}
                                className={`nav-link ${this.state.activeTab === 'Manually' ? 'active' : ''}`}
                            >
                                {translate("text.manually")}
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => this.setActiveTab('DragAndDrop')}
                                className={`nav-link ${this.state.activeTab === 'DragAndDrop' ? 'active' : ''}`}
                            >
                                {translate("text.ping_the_map")}
                            </button>
                        </li>
                    </ul>
                )}
                {!this.props.disableLocationEdit && (
                    <div className="tab-content">
                        <div className="tab-pane show active">
                            <div className="row">
                                <div
                                    className={`col${this.state.activeTab === 'Manually' ? '-lg-12' : ''}`}>
                                    <Card>
                                        {this.props.resource.isLoading && (
                                            <div className="m-5 text-center">
                                                <LoaderSmall/>
                                            </div>
                                        )}
                                        {this.state.activeTab === 'Manually' && (
                                            <div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label className="form-group has-float-label mb-4 w-full">
                                                            <FieldGooglePlaces
                                                                key={this.state.ManuallyFields.CountryID?.value}
                                                                autoFocus
                                                                setLocations={this.setLocations}
                                                                stateFields={this.state.ManuallyFields}
                                                                placeholderText={translate('text.enter_a_city')}
                                                            />
                                                            <span>{translate('field.location_autocomplete')}</span>
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <label className={'form-group has-float-label mb-4'}>
                                                            <FieldText
                                                                addClass="form-control"
                                                                onChange={(name, value) => this.handleInputChange(name, value, 'Manually')}
                                                                {...this.state.ManuallyFields.AddressName}
                                                                placeholder={''}/>
                                                            <span>{translate('field.AddressName')} *</span>
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <label className={'form-group has-float-label mb-4'}>
                                                            <FieldText
                                                                addClass="form-control"
                                                                onChange={(name, value) => this.handleInputChange(name, value, 'Manually')}
                                                                {...this.state.ManuallyFields.CityName}
                                                                placeholder={''}/>
                                                            <span>{translate('field.CityName')} *</span>
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <label className={'form-group has-float-label mb-4'}>
                                                            <FieldText
                                                                addClass="form-control"
                                                                onChange={(name, value) => this.handleInputChange(name, value, 'Manually')}
                                                                {...this.state.ManuallyFields.PostalCode}
                                                                placeholder={''}/>
                                                            <span>{translate('field.postal_code')} *</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label className={'form-group has-float-label mb-4'}>
                                                            <FieldSelectSearch
                                                                addClass="form-control"
                                                                className={'form-control'}
                                                                values={getLookup('Country')}
                                                                onChange={(name, value) => this.handleInputChange(name, value, 'Manually')}
                                                                {...this.state.ManuallyFields.CountryID}
                                                            />
                                                            <span>{translate('field.CountryID')} *</span>
                                                        </label>
                                                    </div>
                                                    {this.state.ManuallyFields.CountryID.value == 1 && (
                                                        <div className="col">
                                                            <label className={'form-group has-float-label mb-4'}>
                                                                <FieldSelectSearch
                                                                    addClass="form-control"
                                                                    className={'form-control'}
                                                                    values={getLookup('State')}
                                                                    onChange={(name, value) => this.handleInputChange(name, value, 'Manually')}
                                                                    {...this.state.ManuallyFields.StateID}
                                                                />
                                                                <span>{translate('field.StateID')}</span>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {this.state.activeTab === 'DragAndDrop' && (
                                            <DragAndDrop
                                                {...this.props}
                                                error={this.state.DragAndDropFields.Latitude.errorMessage || this.state.DragAndDropFields.Longitude.errorMessage}
                                                Latitude={this.state.DragAndDropFields.Latitude.value}
                                                Longitude={this.state.DragAndDropFields.Longitude.value}
                                                addMarker={e => this.addMarker(e)}
                                            />
                                        )}
                                        {this.state.activeTab === 'Select' && (
                                            <div>
                                                <label
                                                    className="form-group has-float-label mb-3">
                                                    <FieldDropdownSelect
                                                        placeholder={translate('text.select_location')}
                                                        {...this.state.SelectFields.LocationID}
                                                        onChange={(name, value) => this.handleInputChange(name, value, 'Select')}
                                                        addClass="form-control"
                                                        value={this.state.SelectFields.LocationID.value}
                                                        defaultOptions={true}
                                                        loadOptions={
                                                            (inputValue, callback) => {
                                                                axios.get(
                                                                    Env.getApiUrl('api/' + Resources.Locations, {query: inputValue}),
                                                                    {
                                                                        headers: {
                                                                            'Authorization': 'Bearer ' + getJWT().access_token
                                                                        }
                                                                    }
                                                                )
                                                                    .then((response) => {
                                                                        const result = processResponse(response)
                                                                        if (result && result.status === 0) {
                                                                            const list = result.data.list.map((it) => {
                                                                                return {
                                                                                    label: it.LocationName,
                                                                                    value: it.LocationID
                                                                                }
                                                                            })

                                                                            callback(list)
                                                                        }
                                                                    })
                                                            }
                                                        }
                                                    />
                                                    <span>{translate('field.LocationID')} *</span>
                                                </label>
                                            </div>
                                        )}
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </ModalDefault>
        )
    }
}

export default connect(state => state)(CreateUpdateUnitDialog)
