import {cloneDeep} from "../../../util/util-vanilla";
import ContactSelectorCard from "./contact-selector-card"
import {useDispatch, useSelector} from "react-redux";
import {concatFullPhoneNumber, getLookup, getProp, openInNewTab} from "../../../util/util-helpers";
import React, { useEffect, useState } from 'react'
import Resources from "../../../../data/services/resources";
import {clearDialogResource, createDialogResource} from "../../../../data/actions/dialogResource";
import {getUser} from "../../../util/util-auth";
import ContentWithSidebar from "../modal-tabbed/content-with-sidebar";
import {ArrowUturnLeftIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import TableCellsIcon from "@heroicons/react/20/solid/TableCellsIcon";
import FieldSearch from "../../fields/field-text/search";
import ContactCard from "../contact-card-dialog/contact-card";
import {LoaderLarge} from "../../loader";
import Modal from "../index";
import ContactForm from "../../forms/contact-form";
import ResourceTableSection from "../../resource-table/table-components/resource-table-section";
import {Field} from "../../../../data/services/fields";
import {getContactResource} from "../../../../data/actions/contactResource";
import LocalStorage from "../../../util/localStorage";
import {AtSymbolIcon, EyeIcon, PhoneIcon} from "@heroicons/react/24/outline";

export default function ContactSelector({contactList = [], fields, id, onUpdateContactList, onSelectContactClick, undoAction, resourcePath, onClose, ContactOrganizationID, isCreateContactOrganizationHiddenTab, translate}) {
    const storeKey = "contact_list_selector";

    function fetchContact(dispatch, contactID) {
        dispatch(getContactResource({
            user: getUser(),
            resource: Resources.Contacts,
            query: {id: contactID}
        }))
    }

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.contactResource);
    const dialogResource = useSelector((state) => state.dialogResource);
    const isLoading = getProp(resource, "isLoading", true);

    /** State
     ================================================================= */
    const [contactData, setContactData] = useState({});
    const [isMainFocused, setIsMainFocused] = useState(false);
    const [customerContactsListFilter, setCustomerContactsListFilter] = useState("");
    const [contactListInitial, setContactListInitial] = useState({});
    const [isContactView, setIsContactView] = useState(false);

    const [isCreateContactDialogOpen, setIsCreateContactDialogOpen] = useState(false);
    const [isEmailExistError, setIsEmailExistError] = useState(false);

    const [contactCreated, setContactCreated] = useState(null);

    const [contactListClone, setContactListClone] = useState(contactList);

    const handleSelectAllContactsClick = (areNotAllSelected, contactData) => {
        let listClone = cloneDeep(contactListClone);

        contactData.forEach(it => {
            if (areNotAllSelected) {
                listClone[it.ContactID] = it;
            } else {
                delete listClone[it.ContactID];
            }
        })

        setContactListClone(listClone);
    }

    const updateCustomerContactsList = (item) => {
        let listClone = cloneDeep(contactListClone);

        const existingItem = !!listClone[item.ContactID];

        if (existingItem) {
            delete listClone[item.ContactID]
        } else {
            listClone[item.ContactID] = item;
        }

        setContactListClone(listClone);
    }

    /** Data events
     ================================================================= */
    const submitContactForm = (params, file) => {
        params.id = id;
        let storedQuery = LocalStorage.get(storeKey) ?? {};

        storedQuery.id = id;

        if (ContactOrganizationID) {
            params.OrganizationID = ContactOrganizationID;
        }

        dispatch(createDialogResource({
            user: getUser(),
            params: params,
            errorMessage: true,
            successMessage: translate('message.ContactCreatedSuccessfully'),
            query: storedQuery,
            resource: Resources.CustomersContacts,
            piggyResource: Resources.CustomersContacts,
            file: !!file && [file],
            fileResource: Resources.ContactImage,
        }));

        setContactCreated(params)
    }

    /** UI events
     ================================================================= */
    const handleContactClick = (contact) => {
        if (contact.ContactID) {
            fetchContact(dispatch, contact.ContactID);
            setIsMainFocused(true);
            setIsContactView(true)
        }
    }

    const handleUndoClick = () => {
        undoAction(cloneDeep(contactListInitial));
    }

    /** Constants
     ================================================================= */
    const selectedContacts = Object.values(contactListClone).filter(it =>
        (it.FirstName ?? "").toLowerCase().includes(customerContactsListFilter.toLowerCase()) ||
        (it.LastName ?? "").toLowerCase().includes(customerContactsListFilter.toLowerCase()))
        .map(it =>
            <ContactSelectorCard
                key={it.ContactID}
                contact={it}
                onContactClick={handleContactClick}
                onRemoveContact={updateCustomerContactsList}
            />
        )

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (resource?.data?.ContactID) {
            setContactData(resource.data);
        }
    }, [resource])

    useEffect(() => {
        setContactListInitial(cloneDeep(contactListClone));
    }, [])

    useEffect(() => {
        if (!!dialogResource.create && dialogResource.resource === Resources.CustomersContacts) {
            setIsCreateContactDialogOpen(false);
            if (contactCreated) {
                let contact = cloneDeep(contactCreated);
                contact.ImagePath = true;
                contact.Phone = concatFullPhoneNumber(contact?.Phones?.[0]);
                contact.ContactID = dialogResource.create.id;

                onSelectContactClick(contact);
                updateCustomerContactsList(contact);
                setContactCreated(null);
            }

            clearDialogResource();
        }

        if (dialogResource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS') {
            setIsEmailExistError(true)
        }

    }, [dialogResource])

    useEffect(() => {
        setContactListClone(contactList);
    }, [contactList])

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <ContentWithSidebar
                onClose={onClose}
                sidebarWidth={"xl"}
                addClass={"h-[calc(100vh-9.5rem)] max-h-[calc(100vh-9.5rem)] overflow-y-auto"}
                sidebarTitle={"Selected contacts"}
                onSave={() => onUpdateContactList(contactListClone)}
                translate={translate}
                sidebarBeforeTitle={
                    <button
                        onClick={() => setIsMainFocused(true)}
                        className="btn btn-icon lg:hidden mx-0"
                    >
                        <PlusCircleIcon className="w-5 h-5"/>
                    </button>
                }
                sidebarAfterTitle={
                    <button
                        onClick={handleUndoClick}
                        className="btn btn-icon mx-0"
                    >
                        <ArrowUturnLeftIcon className="w-5 h-5"/>
                    </button>
                }
                isMainFocused={isMainFocused}
                mainBeforeTitle={(
                    <div className="space-x-3">
                        <button
                            className="btn btn-icon lg:hidden"
                            onClick={() => {
                                setIsMainFocused(false);
                            }}
                        >
                            <ChevronLeftIcon className="w-5 h-5"/>
                        </button>

                        {isContactView && (
                            <button
                                className="btn btn-icon"
                                onClick={() => {
                                    setContactData(false);
                                    setIsContactView(false);
                                }}
                            >
                                <TableCellsIcon className="w-5 h-5"/>
                            </button>
                        )}
                    </div>
                )}
                sidebarContent={(
                    <div className="px-3 pt-3 space-y-2">
                        <FieldSearch
                            addClass={"form-control rounded-md w-full"}
                            onChange={(name, value) => setCustomerContactsListFilter(value)}
                            value={customerContactsListFilter}
                        />

                        {selectedContacts}

                        {!!customerContactsListFilter && !selectedContacts.length && (
                            <div className="text-center space-y-4">
                                <p className="text-lg font-medium text-tm-gray-700">
                                    "{customerContactsListFilter}" not found in any name.
                                </p>

                                <button
                                    className="btn btn-outline"
                                    onClick={() => setCustomerContactsListFilter("")}
                                >
                                    Clear filter
                                </button>
                            </div>
                        )}

                        {!customerContactsListFilter && !selectedContacts.length && (
                            <div className="text-center space-y-4">
                                <p className="text-lg font-medium text-tm-gray-700">
                                    No contacts selected.
                                </p>

                                <p className="text-sm font-medium text-tm-gray-600 hidden lg:block">
                                    Select some contact from the table.
                                </p>

                                <button
                                    className="btn btn-outline lg:hidden"
                                    onClick={() => setIsMainFocused(true)}
                                >
                                    Select contacts
                                </button>
                            </div>
                        )}
                    </div>
                )}
                mainTitle={"Customer contacts"}
                mainContent={(
                    <div className="h-full relative">
                        {isContactView && (
                            <div className="relative h-full">
                                {!isLoading && (
                                    <ContactCard
                                        userData={contactData}
                                        translate={translate}
                                        dispatch={dispatch}
                                    />
                                )}

                                {!!isLoading && (
                                    <LoaderLarge/>
                                )}
                            </div>
                        )}

                        {!isContactView && (
                            <ResourceTableSection
                                storeKey={storeKey}
                                tableOptions={{
                                    behaviour: {
                                        rowSelect: true,
                                    },
                                }}
                                tableKey={"ContactID"}
                                primaryField={"FirstName"}
                                translate={translate}
                                resourcePath={resourcePath}
                                selectedRows={Object.keys(contactListClone).reduce((memo, it) => {
                                    memo[it] = it
                                    return memo;
                                }, {})}
                                filterFieldsNames={['query', 'DepartmentID']}
                                onSelectAllClick={handleSelectAllContactsClick}
                                onSelectRowClick={(it) => updateCustomerContactsList(it)}
                                hideRowsPerPage
                                fields={fields}
                                addQueryFields={{
                                    DepartmentID: new Field('DepartmentID', "", [''], false, "select", {
                                        labelType: "float",
                                        addContainerClass: "col-span-4"
                                    }, {isClearable: true}),
                                    id: new Field('id', id, [''], false, "hidden", {doNotStore: true}),
                                }}
                                getNoRecordsTitle={(hasQuery) => hasQuery ? translate("text.NoCustomerContactsQueryTitle") : translate("text.NoCustomerContactsTitle")}
                                getNoRecordsText={(hasQuery) => hasQuery ? translate("text.NoCustomerContactsQueryText") : ""}
                                getNoRecordsBtnLabel={(hasQuery) => hasQuery ? translate("btn.ClearFilters") : ""}
                                isNoRecordsButtonIconHidden={true}
                                headerButtons={[{
                                    className: "btn btn-outline whitespace-nowrap",
                                    label: "Create contact",
                                    onClick: () => setIsCreateContactDialogOpen(true)
                                }]}
                                selects={{DepartmentID: getLookup('Department')}}
                                actions={[
                                    {
                                        action: (item) => {
                                            handleContactClick(item)
                                        },
                                        icon: EyeIcon,
                                        visible: () => true
                                    },
                                    {
                                        action: (item) => {
                                            openInNewTab("tel:" + item.PhoneNumber.replace(/\D/g, ''))
                                        },
                                        icon: PhoneIcon,
                                        visible: () => true
                                    },
                                    {
                                        action: (item) => {
                                            openInNewTab("mailTo:" + item.Email)
                                        },
                                        icon: AtSymbolIcon,
                                        visible: () => true
                                    }
                                ]}
                            />
                        )}
                    </div>
                )}
            />

            <Modal
                show={isCreateContactDialogOpen}
                widthClass={'max-w-3xl'}
                onClose={() => setIsCreateContactDialogOpen(false)}
            >
                <ContactForm
                    onClose={() => setIsCreateContactDialogOpen(false)}
                    errorMessages={isEmailExistError ? [{field: 'Email', message: 'text.email_exists'}] : []}
                    clearErrorData={() => setIsEmailExistError(false)}
                    isOrganizationHiddenTab={isCreateContactOrganizationHiddenTab}
                    hasDepartmentSection={true}
                    onSubmit={submitContactForm}
                    translate={translate}
                />
            </Modal>
        </React.Fragment>
    )
}

