import React, {Component} from "react";
import {connect} from "react-redux";
import {Circle, LayersControl, Map, TileLayer} from "react-leaflet";
import {LoaderSmall} from "../../loader";
import {layers} from "../../../../util/util-constants";
import ModalFooter from "../modal-footer";
import {toggleBodyScroll} from "../../../util/util-vanilla";

class LocationRadiusSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SearchLatitude: this.props?.radius?.SearchLatitude ?? NaN,
            SearchLongitude: this.props?.radius?.SearchLongitude ?? NaN,
            canSubmit: false,
            SearchRadius: this.props?.radius?.SearchRadius ?? 100,
            showMap: false
        };
    }

    componentDidMount() {
        toggleBodyScroll()
        document.addEventListener("keydown", this.escFunction, false);
        setTimeout(() => {
            this.setState({showMap: true})
        }, 200)
    }

    componentWillUnmount() {
        toggleBodyScroll()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.hideModal()
        }
    }

    submit = () => {
        this.props.handleSearchByRadius("radius", {
            SearchLongitude: this.state.SearchLongitude,
            SearchLatitude: this.state.SearchLatitude,
            SearchRadius: this.state.SearchRadius,
        })
        this.hideModal()
    }

    hideModal = () => {
        this.props.close()
    }

    addMarker = (data) => {
        this.setState({SearchLongitude: data.latlng.lng, SearchLatitude: data.latlng.lat, canSubmit: true})
    }

    render() {
        const {translate} = this.props;

        return (
            <div>
                {this.props.isLoading && (
                    <div className="p-5 text-center">
                        <LoaderSmall/>
                    </div>
                )}

                <div>
                    {!isNaN(this.state.SearchLatitude) && !isNaN(this.state.SearchLongitude) && (
                        <>
                            <div className="row">
                                <div className="col">
                                    <input
                                        className='w-full'
                                        onChange={(e) => this.setState({
                                            SearchRadius: e.target.value,
                                            canSubmit: true
                                        })}
                                        type="range"
                                        min="10"
                                        max="1000"
                                        value={this.state.SearchRadius}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-tm-gray-500 text-center">
                                    {this.state.SearchRadius} miles
                                </div>
                            </div>
                        </>
                    )}

                    <div className="w-full h-[calc(100vh-12rem)]">
                        {!!this.state.showMap && (
                            <div
                                className="leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
                                <Map
                                    center={[40, -100]}
                                    onClick={this.addMarker}
                                    zoom={5}>
                                    <LayersControl position="topright">
                                        {layers.map((layer, index) =>
                                            <LayersControl.BaseLayer
                                                checked={index === 0}
                                                name={layer.name}>
                                                <TileLayer
                                                    url={layer.url}
                                                    attribution={layer.attribution}
                                                />
                                            </LayersControl.BaseLayer>
                                        )}
                                        {!isNaN(this.state.SearchLatitude) && !isNaN(this.state.SearchLongitude) && (
                                            <Circle
                                                center={[
                                                    this.state.SearchLatitude,
                                                    this.state.SearchLongitude
                                                ]}
                                                radius={this.state.SearchRadius * 1610} // RADIJUS JE U METRIMA PO DEFAULT-U
                                            />
                                        )}
                                    </LayersControl>
                                </Map>
                            </div>
                        )}
                    </div>
                </div>

                <ModalFooter
                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.hideModal}
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate("btn.save")}
                    onButtonClick={this.state.canSubmit && this.submit}
                />
            </div>
        );
    }
}

export default connect(state => state)(LocationRadiusSearch);
