export function createSecondResourceDiff(data) {
    return {
        type: 'CREATE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function doneCreateSecondResourceDiff(data) {
    return {
        type: 'DONE_CREATE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function errorCreateSecondResourceDiff(data) {
    return {
        type: 'ERROR_CREATE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function getSecondResourceDiff(data) {
    return {
        type: 'GET_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function doneGetSecondResourceDiff(data) {
    return {
        type: 'DONE_GET_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function errorGetSecondResourceDiff(data) {
    return {
        type: 'ERROR_GET_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function updateSecondResourceDiff(data) {
    return {
        type: 'UPDATE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function doneUpdateSecondResourceDiff(data) {
    return {
        type: 'DONE_UPDATE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function errorUpdateSecondResourceDiff(data) {
    return {
        type: 'ERROR_UPDATE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function deleteSecondResourceDiff(data) {
    return {
        type: 'DELETE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function doneDeleteSecondResourceDiff(data) {
    return {
        type: 'DONE_DELETE_SECOND_RESOURCE_DIFF',
        data: data
    };
}

export function errorDeleteSecondResourceDiff(data) {
    return {
        type: 'ERROR_DELETE_SECOND_RESOURCE_DIFF',
        data: data
    };
}