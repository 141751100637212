import React, {useMemo, useState} from "react";
import {classNames} from "../../../common/util/util-helpers";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {LockClosedIcon} from "@heroicons/react/24/outline";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import NoRecords from "../../../common/components/no-records-found/no-records";
import {LoaderSmall} from "../../../common/components/loader";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import Tooltip from "../../../common/components/tooltip";
import FieldSearch from "../../../common/components/fields/field-text/search";


export default function PermissionsList({
                                            permissionsList = [],
                                            categoryName,
                                            subCategoryName,
                                            onPermClick,
                                            onSelectAll,
                                            compareContact,
                                            currentContact,
                                            compareData = [],
                                            clearCompare,
                                            onShowHelpClick,
                                            isLoading,
                                            translate
                                        }) {
    const [listFilter, setListFilter] = useState('');

    const handleListFilterInputChange = (value) => {
        setListFilter(value)
    }

    const compareList = useMemo(() => {
        return Object.values(compareData).find(it => it.Name === categoryName)?.ChildrenGroups?.find(it => it.Name === subCategoryName)?.ChildrenChildrenGroups
    }, [categoryName, subCategoryName, compareData])

    if (!permissionsList.length && !isLoading) {
        return (
            <div className="h-full flex items-center justify-center pt-12 pb-8">
                <NoRecords
                    show={true}
                    title="No category selected selected"
                    text="Please select category from the left menu"
                />
            </div>
        )
    }

    let areAllSelected = true;

    const list = permissionsList.reduce((memo, it, index) => {
        if (it.HavePerm === 0 || !it.HavePerm || it.isLocked) {
            areAllSelected = false;
        }
        if (it.Desc.toLowerCase().search(listFilter.toLowerCase()) > -1 || !listFilter) {
            memo.push(
                <ListItem
                    key={it.Desc}
                    it={it}
                    categoryName={categoryName}
                    subCategoryName={subCategoryName}
                    onPermClick={onPermClick}
                    compareHasPermission={compareList?.[index]?.HavePerm}
                    onShowHelpClick={onShowHelpClick}
                    isLoading={isLoading}
                />
            )
        }
        return memo;
    }, []);

    return (
        <div>
            <div className="h-12 px-4 w-full flex items-center justify-between font-bold text-tm-gray-800 bg-inverse">
                <div className="flex items-center">
                    {!isLoading && (
                        <FieldCheckbox
                            className={classNames("checkbox", areAllSelected ? "border-primary" : "border-tm-gray-400")}
                            value={areAllSelected}
                            onChange={() => onSelectAll(areAllSelected, categoryName, subCategoryName)}
                        />
                    )}

                    {isLoading && (
                        <div className="w-5 h-5">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}

                    <p className="mr-5 text-base ml-8 font-semibold text-tm-gray-700 leading-5">Permission list
                        for {subCategoryName}</p>
                </div>

                <div className="flex items-center justify-end">
                    <FieldSearch
                        type="text"
                        addClass="w-full form-control min-h-[2.25rem] h-9 ml-auto"
                        value={listFilter}
                        onChange={(_, value) => handleListFilterInputChange(value)}
                        placeholder={translate("text.filter_list")}
                    />
                </div>
            </div>

            {!!compareData.length && (
                <div
                    className="h-12 border-t border-tm-gray-300 bg-tm-gray-50 flex items-center justify-end w-full gap-x-2 font-bold text-tm-gray-800 text-base text-normal"
                >
                    <div className="ml-4 mr-auto font-normal text-tm-gray-700">Permission comparison</div>

                    <div className="mr-5">
                        <p className="truncate">{currentContact?.FirstName}</p>
                    </div>

                    <div
                        className="h-12 flex justify-center items-center font-bold w-40 border-l border-tm-gray-300 relative group text-ellipsis px-4"
                    >
                        <p className="truncate">{compareContact.FirstName}</p>

                        <Tooltip content={translate("btn.clear_compare")}>
                            <button
                                className="btn btn-close bg-transparent hover:bg-tm-gray-200 absolute right-0.5 top-0.5"
                                onClick={clearCompare}
                            >
                                <XMarkIcon/>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            )}

            {list}
        </div>
    )
}

function ListItem({it, onPermClick, categoryName, subCategoryName, compareHasPermission, onShowHelpClick, isLoading}) {
    return (
        <div className="flex items-center group relative">
            <button
                onClick={() => onPermClick(it, categoryName, subCategoryName)}
                className={
                    classNames(
                        "h-12 px-4 flex items-center w-full gap-x-8 font-bold text-tm-gray-800",
                        !!it?.HavePerm && !isLoading ? "hover:bg-green-600/30 bg-green-600/20" : undefined,
                        !it?.HavePerm && !isLoading ? "hover:bg-tm-gray-50" : undefined
                    )
                }
            >
                {!isLoading && (
                    <FieldCheckbox
                        className={classNames("checkbox", it?.HavePerm ? "border-primary" : "border-tm-gray-400")}
                        value={!!it?.HavePerm}
                        disabled={it.isLocked}
                        onChange={() => null}
                    />
                )}

                {isLoading && (
                    <div className="w-5 h-5">
                        <LoaderSmall disableContainer/>
                    </div>
                )}

                {!!it.DescHelp && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onShowHelpClick(it.DescHelp);
                        }}
                        className={
                            classNames(
                                "w-8 h-8 rounded-btn flex items-center justify-center absolute left-10",
                                "hover:bg-blue-600/10 hover:ring-2 hover:ring-blue-500"
                            )
                        }
                    >
                        <InformationCircleIcon className="w-5 h-5 text-blue-500"/>
                    </button>
                )}

                <div className="pl-4">
                    {it.Desc}
                </div>

                <div className="ml-auto flex gap-x-2">
                    {compareHasPermission !== undefined && (
                        <div
                            className={
                                classNames(
                                    "w-8 h-8 rounded-btn flex items-center justify-center ring-2 ring-inverse",
                                    !!it?.HavePerm ? "text-green bg-green-600/10" : "text-red-600 bg-red-600/10"
                                )
                            }
                        >
                            {!!it?.HavePerm
                                ? <CheckIcon className="w-5 h-5"/>
                                : <XMarkIcon className="w-5 h-5"/>
                            }
                        </div>
                    )}
                </div>
            </button>

            {it.isLocked && (
                <div className="absolute inset-0">
                    <div className="bg-stripes opacity-25 absolute inset-0" />
                    <Tooltip content="You're not allowed to update this permission">
                        <LockClosedIcon className="text-tm-gray-600 w-5 h-5 absolute top-3.5 left-9"/>
                    </Tooltip>
                </div>
            )}

            {compareHasPermission !== undefined && (
                <div
                    className={
                        classNames(
                            "px-4 w-40 flex-shrink-0 h-12 flex items-center justify-center border-l border-tm-gray-300",
                        )
                    }
                >
                    <div
                        className={
                            classNames(
                                "w-8 h-8 rounded-btn flex items-center justify-center",
                                compareHasPermission ? "text-green bg-green-600/10" : "text-red-600 bg-red-600/10"
                            )
                        }
                    >
                        {compareHasPermission
                            ? <CheckIcon className="w-5 h-5"/>
                            : <XMarkIcon className="w-5 h-5"/>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}