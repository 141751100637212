import React, {Fragment, useEffect, useState} from 'react';
import {Field, FieldsManager} from "../../../data/services/fields";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    PAY_METHOD_TYPES,
    UPDATE_PERM
} from '../../../util/util-constants'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {getResource, updateResource} from "../../../data/actions/resource";
import {checkPerm, ConstantTranslate, getLookup, getProp} from '../../../common/util/util-helpers'
import PayAdjustmentsSection from "./payAdjustmentsSection";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import Card from "../../../common/components/card";
import {LoaderLarge} from "../../../common/components/loader";
import {getThirdResource} from "../../../data/actions/thirdResource";

const EmployeePayrollTab = ({translate, dispatch, id, resource, secondResource, setIsStateDirty, thirdResource}) => {

    /** Helpers
     ================================================================= */
    const data = getProp(resource, 'data', {})
    const isLoading = getProp(resource, 'isLoading', false)
    const isThirdLoading = getProp(thirdResource, 'isLoading', false)
    const ContactID = getProp(thirdResource, 'data.ContactID','')

    const getResourceName = () => {
        return Resources.EmployeesSettlements
    }

    const getQuery = () => {
        return {
            id: id
        }
    }

    const getFields = (item = null) => {
        let fieldTemplates = {
            PayMethodType: new Field('PayMethodType', '', [''], false, 'select', {
                addContainerClass: 'col-span-6'
            }, {values: ConstantTranslate(PAY_METHOD_TYPES, translate)}),
            PayMethodValue: new Field('PayMethodValue', '', [''], false, 'money', {addContainerClass: 'col-span-6'}),
            Send1099: new Field('Send1099', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
            }),
            DepositAccountID: new Field('DepositAccountID', '', [], false, 'select-search', {addContainerClass: 'col-span-6 z-40'}, {
                api: 'api/' + Resources.AccountsQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }),
            DepositProvider: new Field('DepositProvider', '', [], false, 'text', {addContainerClass: 'col-span-6'}),
            IsFactoringActive: new Field('IsFactoringActive', '', [], false, 'switch', {
                addContainerClass: "-mx-4 pr-4 col-span-full flex items-center hover:bg-sky-600/10 rounded-xl",
                htmlBefore: () => (
                    <FieldSwitchLabel
                        label={translate("field.PayToOverride")}
                        note={translate("text.PayToOverrideInfo")}
                    />
                )
            }),
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {addContainerClass: 'col-span-full z-30'})
        }
        if (item) {
            fieldTemplates.OrganizationID.value = !item?.IsFactoringActive ? "" : item.OrganizationID;
            fieldTemplates.OrganizationID.disabled = !item?.IsFactoringActive;
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [canSubmit, setCanSubmit] = useState(false)

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
            dispatch(getResource({
                user: LocalStorage.get("user"),
                resource: getResourceName(),
                query: getQuery()
            }));

            dispatch(getThirdResource({
                user: LocalStorage.get('user'),
                resource: Resources.EmployeesInfo,
                query: getQuery()
            }))
        }, [])


    useEffect(() => {
        if (!isLoading && !!Object.keys(data).length) {
            setFields(getFields(data))
        }
    }, [isLoading])

    useEffect(() => {
        setIsStateDirty(canSubmit)
    }, [canSubmit])

    /** UI Events
     ================================================================= */
    const handleInputChange = (name, value) => {
        let fieldsTmp = FieldsManager.updateField(fields, name, value);

        if (name === 'IsFactoringActive') {
            fieldsTmp.OrganizationID.value = !value ? "" : fieldsTmp.OrganizationID.value;
            fieldsTmp.OrganizationID.disabled = !value;
        }

        setFields(fieldsTmp)
        setCanSubmit(true)
    }

    const handleFormCancel = () => {
        setFields(getFields(data))
        setCanSubmit(false)
    }


    /** Data Events
     ================================================================= */
    const onSubmit = () => {
        const fieldsTmp = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(fieldsTmp)) {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                    EmployeeSettlementID: getProp(resource, 'data.EmployeeSettlementID', 0)
                }),
                resource: getResourceName(),
                piggyResource: getResourceName(),
                piggyQuery: getQuery(),
                errorMessage: true, successMessage: 'Employee settlement successfully updated!',
            }))
        } else {
            setFields(fieldsTmp)
        }
        setCanSubmit(false)
    }

    /** Render
     ================================================================= */
    const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, {
        OrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => {
                return ({
                    value: item.OrganizationID,
                    label: item.LegalName + " City: " + item.CityName + " State: " + getLookup('State')[item.StateID] + " Zip: " + item.PostalCode,
                    metadata: item
                })
            }
        },
        DepositAccountID: {
            api: 'api/' + Resources.AccountsQuick,
            query: {},
            searchMap: (it) => {
                return {
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                }
            }
        }
    })

    return (
        <Fragment>
            <div className="grid grid-cols-9 gap-4">
                <div className="col-span-full 3xl:col-span-3">
                    <PageHeader className="pb-5"
                                title={translate('text.Settlement')}
                                titleClass="mr-5 text-2xl ml-4"
                                hasPerm={checkPerm(getResourceName(), CREATE_PERM)}
                    >
                    </PageHeader>
                    <Card addClass="relative p-1" bodyClass="py-3 px-6">
                        {isLoading && <LoaderLarge stripesBg/>}
                        <div className="grid grid-cols-12 gap-4">
                            {fieldsHtml}
                        </div>
                    </Card>
                </div>
                {!isThirdLoading && (
                <div className="col-span-full 3xl:col-span-6">
                    <PayAdjustmentsSection
                        translate={translate}
                        secondResource={secondResource}
                        resourceName={Resources.EmployeesDeduction}
                        ContactID={ContactID}
                        dispatch={dispatch}
                    />
                </div>)}
            </div>

            <PageFooter
                translate={translate}
                canSubmit={canSubmit}
                actionCancel={handleFormCancel}
                actionSubmit={checkPerm(getResourceName(), UPDATE_PERM) && onSubmit}
            />
        </Fragment>
    )
}

export default EmployeePayrollTab;