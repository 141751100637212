import React from 'react';
import LoadsEventsList from "../../dispatch/load-view/load-sidebar/load-events-list";
import { ClockIcon } from '@heroicons/react/24/outline';
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import Card from "../../../common/components/card";

export default function PublicStatusUpdatesTab({data, isLoading, translate}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <div className="max-w-xl mx-auto pt-8 space-y-4">
            <InfoBar Icon={ClockIcon}>
                {translate("text.displayed_timezone_below", [timezone])}
            </InfoBar>

            <Card bodyClass='py-3 space-y-4 pb-5'>
                <LoadsEventsList
                    events={data?.reverse()}
                    isLoading={isLoading}
                    translate={translate}
                    timezone={timezone}
                />
            </Card>
        </div>
    )
}
