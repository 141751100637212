import React, {Component} from 'react'
import {Field, FieldsManager} from "../../../data/services/fields";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    saveTableColumns
} from '../../../common/util/util-helpers';
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {cloneDeep, genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import Resources from "../../../data/services/resources";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import Button from '../../../common/components/button'


export default class DriverSettlementTemplateTab extends Component {

    constructor(props) {
        super(props);
        this.pagePath = this.props.location.pathname.substring(1);

        this.state = {
            ...DEFAULT_CRUD_STATE,

            fields: this.getFields(),
            tableFields: this.getTableFields(),
            queryFilterFields: this.getQueryFilterFields(),
            tableOptions: getDefaultTableOptions(this.getTableFields(), {}, this.pagePath, this.props.translate),

            selectedItem: null,
            columnsSettingsModalOpen: false,
            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10);
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResource()
        }))
    };

    onCreate = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields)),
                    resource: this.getResource(),
                    piggyResource: this.getResource(),
                    piggyQuery: this.getQuery(),
                    errorMessage: true, successMessage: 'Driver settlement template successfully created!'
                }))
                this.handleToggleCreateModal()
            }
        })
    }

    onUpdate = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                    }),
                    resource: this.getResource(),
                    piggyResource: this.getResource(),
                    piggyQuery: this.getQuery(),
                    errorMessage: true, successMessage: 'Driver settlement template successfully created!',
                }))
                this.handleToggleEditModal()
            }
        })
    }

    handleLinkClick = (link) => {
        this.props.history.push(link)
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEdit = (item = null) => {
        this.setState({
            selectedItem: item,
            fields: this.getFields(item),
        })
        this.handleLinkClick(`drivers/settlements/template/${item.DriverSettlementTemplateID}`)

    }

    handleToggleCreateModal = () => {
        this.handleLinkClick(`drivers/settlements/template`)
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsSettingsModalOpen: !this.state.columnsSettingsModalOpen
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsSettingsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getTableFields(), {}, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleInputChange = (name, value) => {
        const fieldsSource = this.state.fields
        let fields = FieldsManager.updateField(fieldsSource, name, value)

        if (name === 'LoadPayMethodID') {
            if (value == 7) {
                fields.PayMethodValueExtra.type = 'text'
            } else {
                fields.PayMethodValueExtra.type = 'hidden'
                fields.PayMethodValueExtra.value = ''
            }
        }

        this.setState({canSubmit: true, fields: fields})
    }
    /** Query Definitions
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),

            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    /** Fields/Data Definitions
     ================================================================= */
    getTableFields = () => ({
        DriverSettlementTemplate: new Field('DriverSettlementTemplate', '', ['empty'], false, 'text'),
        LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select'),
        PayMethodValue: new Field('PayMethodValue', '', ['empty'], false, 'custom', {
            addTableHeaderClass: "ml-auto",
            render: (item) => ((Number(item.LoadPayMethodID) >= 2 && Number(item.LoadPayMethodID) <= 5) ?
                <div className={"text-right"}>{item.PayMethodValue + '%'}</div> :
                <div className={"text-right"}>{genericMoneyFormatter(item.PayMethodValue)}</div>)
        }),
        PayMethodValueExtra: new Field('PayMethodValueExtra', '', ['empty'], false, 'custom', {
            addTableHeaderClass: "ml-auto",
            render: (item) => (<div className={"text-right"}>{genericMoneyFormatter(item.PayMethodValueExtra)}</div>)
        }),
        Notes: new Field('Notes', '', ['empty'], false, 'text')
    })

    getFields = (item = null) => {
        let fieldTemplates = {
            DriverSettlementTemplate: new Field('DriverSettlementTemplate', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'}),
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-3'}),
            PayMethodValue: new Field('PayMethodValue', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'}),
            PayMethodValueExtra: new Field('PayMethodValueExtra', '', [], false, (item?.LoadPayMethodID == 7) ? 'text' : 'hidden'),

            // PAY TO
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    };

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        let filters = []

        Object.keys(this.getQuery()).forEach(it => filters.push(it))

        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getPrimaryKey = () => {
        return "DriverSettlementTemplateID";
    }

    getResource = () => {
        return Resources.DriverSettlementsTemplate;
    }

    renderHtmlForm = () => {
        const fieldsHtmlColumnOne = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(0, 4), this.props.translate, this.handleInputChange)

        return (
            <div>
                <div className="grid grid-cols-6 gap-4">
                    {fieldsHtmlColumnOne}
                </div>
            </div>
        )
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        translate={translate}
                    />


                    <div className="flex ml-auto">
                        <div className={'flex mr-4'}>
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={this.handleToggleCreateModal}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>
                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getTableFields()}

                        translate={translate}
                        isLoading={isLoading}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEdit : null}

                        onEdit={this.handleToggleEdit}
                        onDelete={this.handleToggleConfirmModal}

                        hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={(data.length === 0) && !isLoading}
                        canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                        title={'No matching records found'}
                        btnLabel={translate("btn.create_new_template")}
                        onBtnClick={() => this.handleToggleCreateModal('?prefilled=' + FieldsManager.getFieldKeyValues(this.state.queryFilterFields)['query'])}
                        text={this.state.queryFilterFields.query.value ? translate('text.create_new_resource', [this.state.queryFilterFields.query.value]) : ''}
                    />
                </TableCard>

                <ModalDefault
                    show={this.state.createModalOpen}
                    widthClass={"max-w-5xl"}
                    title={translate("text.CreateDriverSettlementTemplate")}
                    isLoading={isLoading}

                    onButtonClick={this.onCreate}
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate("btn.save")}

                    closeButtonLabel={translate("btn.Cancel")}
                    onClose={this.handleToggleCreateModal}
                >
                    <div className="p-5">
                        {this.renderHtmlForm()}
                    </div>
                </ModalDefault>

                <ModalConfirm
                    title={"Confirm"}
                    show={!!this.state.confirmModalOpen}
                    text={"Are you sure you want to delete this template?"}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResource(),
                            piggyResource: this.getResource(),
                            errorMessage: true, successMessage: "Template deleted."
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                />

                <TableOptionsDialog
                    show={this.state.columnsSettingsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.setTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </>
        )
    }
}
