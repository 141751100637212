import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import React, {useState} from "react";
import ExclamationTriangleIcon from "@heroicons/react/20/solid/ExclamationTriangleIcon";
import {FieldsManager} from "../../../../data/services/fields";
import {cloneDeep} from "../../../util/util-vanilla";
import {fieldsToHtml, includeFields} from "../../../util/util-fields";
import ModalFooter from "../modal-footer";
import {classNames} from "../../../util/util-helpers";

export default function ModalTabbedBody({
                                            handleInputChangeExternal,
                                            onClose,
                                            onSubmit,
                                            selects,
                                            sidebarWidth,
                                            tabFields = {},
                                            tabsData,
                                            title,
                                            translate,
                                            validateFields,
                                            validateOnlyCurrentTab,
                                            onTabChange = null
                                        }) {


    if (!!tabFields.GooglePlaces && !tabFields?.GooglePlaces?.metadata?.setLocations) {
        tabFields.GooglePlaces.metadata['setLocations'] = (googleFields) => {
            let fieldsUpdate = fields;
            for (let [key, value] of Object.entries(googleFields)) {
                if (key in fieldsUpdate) {
                    fieldsUpdate = FieldsManager.updateField(fieldsUpdate, key, value);
                }
            }

            setFields(fieldsUpdate);
            setIsStateDirty(true);
        }
    }

    const [isStateDirty, setIsStateDirty] = useState(false)
    const [fields, setFields] = useState(cloneDeep(tabFields));


    const widthClasses = getWidthClasses(sidebarWidth);

    const [tabsMenu, setTabsMenu] = useState(generateTabsMenu(Object.values(tabsData)))

    const handleTabChange = (tabName) => {
        const tabsMenuUpdate = cloneDeep(tabsMenu);

        tabsMenuUpdate.map(it => {
            it.isCurrent = tabName === it.name;
            it.isFocused = tabName === it.name;
            it.hasWarnings = tabName === it.name ? false : it.hasWarnings
            it.hasMobileWarnings = tabName === it.name ? false : it.hasMobileWarnings
            return it;
        })

        setTabsMenu(tabsMenuUpdate);

        if (onTabChange) {
            onTabChange(tabName, fields, updateFields, setIsStateDirty)
        }
    }

    const handleInputChange = (name, value) => {
        let fieldsUpdate = cloneDeep(fields);

        fieldsUpdate = handleInputChangeExternal
            ? handleInputChangeExternal(fieldsUpdate, name, value)
            : FieldsManager.updateField(fieldsUpdate, name, value)

        fieldsUpdate[name].errorMessage = ""; // clear error messages

        setFields(fieldsUpdate);
        setIsStateDirty(true);
    }

    const updateFields = (fieldsUpdate) => {
        setFields(cloneDeep(fieldsUpdate));
    }

    const selectedTab = tabsMenu.find(it => it.isCurrent);

    const handleUnFocusTabClick = () => {
        const tabsMenuUpdate = cloneDeep(tabsMenu);

        tabsMenuUpdate.map(it => {
            it.isFocused = false;
            return it;
        })

        setTabsMenu(tabsMenuUpdate);
    }

    const handleSubmitClick = () => {
        let submitHasWarnings = false;

        let validFields = cloneDeep(fields);
        let tabsMenuUpdate = cloneDeep(tabsMenu);

        validFields = clearFieldErrors(validFields);

        if (validateFields) {
            validFields = validateFields(fields); // custom validation
        } else {
            tabsMenuUpdate.map(tab => {
                let validateThisTab = !!tab.fields.length

                if (validateThisTab && validateOnlyCurrentTab && tab.name !== selectedTab.name) {
                    validateThisTab = false;
                }

                if (!validateThisTab) {
                    return tab;
                }

                //validateOnlyCurrentTab
                validFields = FieldsManager.validateFields(validFields, tab.fields);

                if (!FieldsManager.checkFieldsForErrors(validFields, tab.fields)) {
                    if (!tab.isCurrent) {
                        tab.hasWarnings = true;
                    } else {
                        tab.hasMobileWarnings = true;
                    }
                    submitHasWarnings = true;
                } else {
                    tab.hasWarnings = false;
                    tab.hasMobileWarnings = false;
                }

                return tab;
            })
        }

        if (submitHasWarnings) {
            setFields(validFields);
            tabsMenuUpdate.map(it => {
                it.isFocused = false;
                return it
            })
            setTabsMenu(tabsMenuUpdate);
            setIsStateDirty(false);
        } else {
            onSubmit(validFields, tabsMenuUpdate);
            setTabsMenu(tabsMenuUpdate);
        }
    }

    const tabsBodyRender = (
        tabsMenu.map(tab => {
            const hasCustomComponent = !!tabsData[tab.name]?.component;
            return (
                <React.Fragment key={tab.name}>
                    {selectedTab.name === tab.name && (
                        <React.Fragment>
                            {hasCustomComponent && (
                                tabsData[tab.name].component(fields, handleInputChange, updateFields, setIsStateDirty)
                            )}

                            {!hasCustomComponent && (
                                <div className={tab.containerClass ?? "p-6 w-full max-w-lg mx-auto space-y-4"}>
                                    {fieldsToHtml(Object.values(includeFields(fields, tab.fields)), translate, handleInputChange, selects)}
                                </div>
                            )}

                            {!!tabsData[tab.name]?.componentAfter && tabsData[tab.name].componentAfter(fields, handleInputChange, updateFields, setIsStateDirty)}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )
        })
    )

    return (
        <React.Fragment>
            <div className={classNames(
                "flex h-[960px] max-h-[calc(100vh-8rem)]",
                widthClasses.container
            )}>
                <div
                    className={classNames(
                        "border-tm-gray-200 border-r bg-tm-gray-50",
                        "",
                        widthClasses.sidebar
                    )}>

                    {/* Sidebar title */}
                    <div
                        className="h-12 text-tm-gray-700 border-b border-tm-gray-200 flex items-center justify-center pt-0.5">
                        <h2 className="text-base font-bold">{title}</h2>
                    </div>

                    <div className="pr-3">
                        <DialogTabs
                            tabs={tabsMenu}
                            widthClasses={widthClasses}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>
                </div>

                <div className={classNames(
                    "w-full relative z-1 overflow-y-auto",
                    widthClasses.body,
                    selectedTab.isFocused ? widthClasses.bodyTransition : undefined
                )}
                >
                    {/* Tab title */}
                    <div
                        className="h-12 border-b border-tm-gray-200 text-tm-gray-700 flex items-center justify-center relative pt-0.5">
                        <button
                            className={classNames(
                                "btn btn-close absolute left-4",
                                widthClasses.backButton
                            )}
                            onClick={handleUnFocusTabClick}
                        >
                            <ChevronLeftIcon className="w-5 h-5"/>
                        </button>

                        <h2 className="text-base font-bold">{translate("tab." + selectedTab.name)}</h2>

                        <button
                            className="btn btn-close absolute right-4"
                            onClick={() => onClose()}
                        >
                            <XMarkIcon className="w-5 h-5"/>
                        </button>
                    </div>

                    {/* Body */}
                    {tabsBodyRender}
                </div>
            </div>

            <ModalFooter
                buttonDisabled={!isStateDirty}
                buttonLabel={translate("btn.save")}
                onButtonClick={handleSubmitClick}

                closeButtonLabel={translate("btn.close")}
                onClose={onClose}
            />
        </React.Fragment>
    )
}


const clearFieldErrors = (fields) => {
    return Object.values(fields).reduce((memo, it) => {
        it.errorMessage = ""

        memo[it.name] = it;
        return memo;
    }, {})
}

const generateTabsMenu = (data) => {
    return data.filter(it => !it.isHidden).map((it, i) => {
        it = {
            name: it.name,
            icon: it.icon,
            isCurrent: !i,
            isFocused: false,
            fields: it.fields ?? [],
            containerClass: it.containerClass,
            hasWarnings: false
        }

        return it;
    })
}

const getWidthClasses = (width) => {
    switch (width) {
        case 'lg':
            return {sidebar: "w-96", body: "md:w-[calc(100%-24rem)]"}
        case 'sm':
            return {sidebar: "w-52", body: "sm:w-[calc(100%-13rem)]"}
        default:
            return {
                container: "flex-nowrap overflow-hidden",
                sidebar: "sm:w-64 transition-all w-full shrink-0",
                body: "sm:w-[calc(100%-16rem)] shrink-0 transition-all",
                bodyTransition: "-translate-x-full sm:translate-x-0 rounded-lg",
                backButton: "sm:hidden",
                tabSelectorButton: "sm:bg-tm-gray-200 sm:hover:bg-tm-gray-300 hover:bg-tm-gray-100",
                tabSelectorCurrentIcon: "sm:text-tm-gray-900 text-tm-gray-700",
            }
    }
}

const DialogTabs = (props) => {
    const {className, tabs, onTabChange, translate, widthClasses} = props;

    return (
        <ul className={className ?? "my-5"}>
            {tabs.map(item => {
                return (
                    <li key={item.id} className="">
                        <button
                            className={classNames(
                                item.isCurrent ? widthClasses?.tabSelectorButton : "hover:bg-tm-gray-100",
                                "group w-full flex pl-5 py-2 pr-4 text-sm font-medium rounded-r-md text-tm-gray-900 relative"
                            )}
                            onClick={() => onTabChange(item.name)}
                        >
                            <item.icon
                                className={
                                    classNames(
                                        (item.isCurrent ? widthClasses?.tabSelectorCurrentIcon : "text-tm-gray-700"),
                                        "flex-shrink-0 h-6 w-6 opacity-70"
                                    )
                                }
                            />

                            <span className="ml-4 text-left pt-0.5">{translate("tab." + item.name)}</span>

                            {!!item.hasWarnings && (
                                <div className="absolute top-0 bottom-0 right-5 flex items-center">
                                    <ExclamationTriangleIcon className="w-7 h-7 text-red-600 p-1 rounded-full bg-inverse"/>
                                </div>
                            )}

                            {!!item.hasMobileWarnings && (
                                <div className={classNames(
                                    "absolute top-0 bottom-0 right-5 flex items-center",
                                    widthClasses?.backButton
                                )}
                                >
                                    <ExclamationTriangleIcon className="w-7 h-7 text-red-600 p-1 rounded-full bg-inverse"/>
                                </div>
                            )}
                        </button>
                    </li>
                )
            })}
        </ul>
    )
}

