import {ArrowUturnDownIcon, EllipsisVerticalIcon, GlobeAmericasIcon, PlusIcon} from '@heroicons/react/24/outline';
import React, {useEffect, useState} from 'react';
import {Field, FieldsManager} from '../../../../../../data/services/fields';
import {
    DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPES
} from '../../../../../../util/util-constants';
import {classNames} from '../../../../../../common/util/util-helpers';
import {getRandomID} from '../../../../../../common/util/util-vanilla';
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon";
import Resources from "../../../../../../data/services/resources";
import Tooltip from "../../../../../../common/components/tooltip";
import FieldsToHtml from "../../../../../../common/components/fields/fields-to-html";

export default function LoadPlaningStops({stops, setStops, setIsStateDirty, translate}) {
    const [isDragEnabled, setIsDragEnabled] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [stopFormToggleID, setStopFormToggleID] = useState(null);
    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const [draggedOverIndex, setDraggedOverIndex] = useState(null);
    const [lastDraggedIndex, setLastDraggedIndex] = useState(false);
    const [autoCompletedFields, setAutoCompletedFields] = useState();
    const [autocompleteID, setAutocompleteID] = useState();

    function handleInputChange(name, value, i) {
        let stop = stops[i];
        stop = FieldsManager.updateField(stop, name, value);
        stop[name].errorMessage = '';
        stops[i] = stop;

        if (name !== 'StopType' && name !== 'GooglePlaces') {
            // Don't send wrong Lat and Long to the server
            stop.Latitude.value = "";
            stop.Longitude.value = "";
        }

        if ("StopID" === name) {
            stop.PostalCode.value = value.metadata.PostalCode;
            stop.CityName.value = value.metadata.CityName;
            stop.StateID.value = value.metadata.StateID;
            stop.Longitude.value = value.metadata.Longitude;
            stop.Latitude.value = value.metadata.Latitude;
        }

        setStops([...stops]);
        setIsStateDirty(true);
    }

    function handleClearStopFieldsClick(i) {
        let stop = Object.assign({}, stops)[i];
        stop = Object.values(stop).reduce((memo, it) => {
            if (it.name !== "StopType" && it.name !== "CountryID") {
                it.value = "";
            }

            memo[it.name] = it;

            return memo;
        }, {});

        stops[i] = stop;
        setStops([...stops]);
    }

    function toggleStopForm(id) {
        setStopFormToggleID(id);
    }

    const getStopFields = (item = {}) => {
        const id = getRandomID();
        return {
            StopType: new Field('StopType', item?.StopType ?? "", ['empty'], false, 'select', {
                addContainerClass: "col-start-2 col-span-4",
                htmlBefore: () => <Tooltip content={translate("text.toggle_planing_form_select")}>
                    <div>
                        <button
                            onClick={() => toggleStopForm(id)}
                            className="text-primary border-primary btn btn-header w-7 h-[34px] relative top-5"
                        >
                            <ListBulletIcon className="w-5 h-5"/>
                        </button>
                    </div>
                </Tooltip>
            }),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: (fields) => setLocations(fields, id),
                addContainerClass: 'col-span-7',
                randomID: id
            }),
            StopID: new Field('StopID', '', [], false, "hidden", {addContainerClass: "col-span-full"}),
            AddressName: new Field('AddressName', '', [], false, "text", {addContainerClass: "col-span-full"}),
            CityName: new Field('CityName', '', [''], false, "text", {addContainerClass: "col-span-5"}),
            StateID: new Field('StateID', '', ['empty'], false, 'select', {addContainerClass: "col-span-4"}),
            CountryID: new Field('CountryID', 1, [''], false, 'hidden'),
            PostalCode: new Field('PostalCode', '', [''], false, "text", {addContainerClass: "col-span-3"}),
            Latitude: new Field('Latitude', '', [], false, "hidden"),
            Longitude: new Field('Longitude', '', [], false, "hidden"),
        }
    }

    function setLocations(fields, i) {
        setAutoCompletedFields(fields);
        setAutocompleteID(i);
    }

    function handleAddStop() {
        stops.push(getStopFields());
        setIsStateDirty(true);
        setStops([...stops]);
    }

    function handleMouseLeave() {
        if (!isDragging) {
            setIsDragEnabled(false);
        }
    }

    function handleDragStart(i) {
        setIsDragging(true);
        setDraggedItemIndex(i)
    }

    function handleDragEnd(e) {
        e.preventDefault();
        if (lastDraggedIndex !== null && draggedOverIndex !== null) {
            let temp = stops[lastDraggedIndex];
            let tempAutocomplete = stops[lastDraggedIndex].GooglePlaces;
            stops[lastDraggedIndex] = stops[draggedItemIndex];
            stops[lastDraggedIndex].GooglePlaces = stops[draggedItemIndex].GooglePlaces;

            stops[draggedItemIndex] = temp;
            stops[draggedItemIndex].GooglePlaces = tempAutocomplete;
            setStops([...stops]);
        }

        setIsDragging(false);
        setDraggedItemIndex(null);
        setDraggedOverIndex(null);
        setLastDraggedIndex(null);
    }

    function handleDragEnter(e, i) {
        e.preventDefault();
        setDraggedOverIndex(i);
        setLastDraggedIndex(i);
    }

    function handleDragLeave() {
        setDraggedOverIndex(null);
    }

    useEffect(() => {
        if (stops.length < 2) {
            setStops([getStopFields({StopType: STOP_TYPE_PICKUP}), getStopFields({StopType: STOP_TYPE_DELIVERY})]);
        } else {
            // Update method references after going back from preview screen and re-mounting this component
            stops = stops.map(it => {
                let isGoogleAutocompleteForm = it.GooglePlaces.type !== "hidden";
                let stopFormToggleID = it.GooglePlaces.metadata.randomID;
                it.StopType.metadata.htmlBefore = () => <Tooltip
                    content={isGoogleAutocompleteForm
                        ? translate("text.toggle_planing_form_select")
                        : translate("text.toggle_planing_form_autocomplete")
                    }
                >
                    <div>
                        <button
                            onClick={() => toggleStopForm(stopFormToggleID)}
                            className="text-primary border-primary btn btn-header w-7 h-[34px] relative top-5"
                        >
                            {isGoogleAutocompleteForm && (
                                <ListBulletIcon className="w-5 h-5"/>
                            )}

                            {!isGoogleAutocompleteForm && (
                                <GlobeAmericasIcon className="w-5 h-5"/>
                            )}
                        </button>
                    </div>
                </Tooltip>

                it.GooglePlaces.metadata.setLocations = (fields) => setLocations(fields, stopFormToggleID);

                return it;
            });

            setStops([...stops]);
        }
    }, [])

    useEffect(() => {
        if (stopFormToggleID) {
            stops = stops.reduce((memo, it) => {
                if (it.GooglePlaces.metadata.randomID === stopFormToggleID) {
                    let isGoogleAutocompleteForm = it.GooglePlaces.type === "hidden";

                    it.StopType.metadata.htmlBefore = () => <Tooltip
                        content={isGoogleAutocompleteForm
                            ? translate("text.toggle_planing_form_select")
                            : translate("text.toggle_planing_form_autocomplete")
                        }
                    >
                        <div>
                            <button
                                onClick={() => toggleStopForm(stopFormToggleID)}
                                className="text-primary border-primary btn btn-header w-7 h-[34px] relative top-5"
                            >
                                {isGoogleAutocompleteForm && (
                                    <ListBulletIcon className="w-5 h-5"/>
                                )}

                                {!isGoogleAutocompleteForm && (
                                    <GlobeAmericasIcon className="w-5 h-5"/>
                                )}
                            </button>
                        </div>
                    </Tooltip>

                    it.GooglePlaces.type = isGoogleAutocompleteForm ? "google-locations" : "hidden"
                    it.StopID.type = isGoogleAutocompleteForm ? "hidden" : "select-search";
                    it.StopID.value = "";
                    it.AddressName.value = "";
                    it.AddressName.type = isGoogleAutocompleteForm ? "text" : "hidden";
                    it.CityName.value = "";
                    it.CityName.disabled = !isGoogleAutocompleteForm;
                    it.StateID.value = "";
                    it.StateID.disabled = !isGoogleAutocompleteForm;
                    it.StateID.validate = isGoogleAutocompleteForm ? ['empty'] : [''];
                    it.PostalCode.value = "";
                    it.PostalCode.disabled = !isGoogleAutocompleteForm;
                    it.Latitude.value = "";
                    it.Longitude.value = "";
                }
                memo.push(it);
                return memo;
            }, [])
            setStopFormToggleID(null);
            setStops([...stops]);
        }
    }, [stops, stopFormToggleID])

    useEffect(() => {
        if (autoCompletedFields && autocompleteID !== undefined) {
            const index = stops.findIndex(it => it.GooglePlaces.metadata.randomID === autocompleteID);
            Object.keys(autoCompletedFields).forEach(key => {
                stops[index]["GooglePlaces"].value = autoCompletedFields.query;
                if (stops[index][key] && key !== 'GooglePlaces' && key !== 'StopType') {
                    stops[index][key].value = autoCompletedFields[key];
                    stops[index][key].errorMessage = "";
                }
            })
            setAutoCompletedFields(undefined);
            setAutocompleteID(undefined);
            setStops([...stops]);
            setIsStateDirty(true);
        }
    }, [autoCompletedFields, autocompleteID, stops])

    return (
        <React.Fragment>
            <h2 className="text-lg leading-6 font-medium text-tm-gray-900 px-6 mb-5">{translate("text.locations")}</h2>
            <div className='px-16'>
                <div className='mx-auto space-y-4 max-w-lg'>
                    {stops.map((stop, i) => {
                        const isDirty = !!(Object.values(stop ?? {})).find(field => field.name !== "StopType" && field.name !== "CountryID" && !!field.value);

                        return (
                            <div key={stops[i]?.GooglePlaces?.metadata?.randomID ?? i} className='relative group'>
                                <div
                                    onDragEnter={(e) => handleDragEnter(e, i)}
                                    onDragOver={e => e.preventDefault()}
                                    onDragLeave={handleDragLeave}
                                    className={
                                        classNames(
                                            'absolute rounded-card top-0 bottom-0 -left-16 -right-16 bg-sky-600/10  border  z-20',
                                            isDragging && draggedItemIndex !== i ? "block" : "hidden",
                                            draggedOverIndex === i ? "bg-green-600/10 border-green-300" : "bg-sky-600/10 border-sky-300"
                                        )
                                    }
                                />

                                <div
                                    draggable={isDragEnabled}
                                    onDragStart={() => handleDragStart(i)}
                                    onDragEnd={handleDragEnd}
                                    className={
                                        classNames(
                                            "border rounded-card relative border-tm-gray-200 p-4 bg-popup grid grid-cols-12 gap-4",
                                            isDirty ? "rounded-tr-none" : undefined
                                        )
                                    }
                                >
                                    <div
                                        className='flex items-center text-tm-gray-400 w-12 text-4xl absolute top-0 bottom-0 -left-12'>{i + 1}.
                                    </div>

                                    <div
                                        onMouseEnter={() => setIsDragEnabled(true)}
                                        onMouseLeave={handleMouseLeave}
                                        className='hidden group-hover:flex hover:cursor-grab w-8 h-8 absolute items-center justify-center border rounded-l-card border-tm-gray-200 bg-tm-gray-50 top-0 -left-8'
                                    >
                                        <div className="ml-1.5 w-6 h-6 relative text-tm-gray-700">
                                            <EllipsisVerticalIcon
                                                className="w-6 h-6 flex-shrink-0 absolute left-0"/>
                                            <EllipsisVerticalIcon
                                                className="w-6 h-6 flex-shrink-0 absolute right-1.5"/>
                                        </div>
                                    </div>

                                    {isDirty && (
                                        <Tooltip content={translate("btn.clear")}>
                                            <button
                                                onClick={() => handleClearStopFieldsClick(i)}
                                                className='w-8 h-8 absolute flex items-center justify-center border rounded-r-card border-tm-gray-200 hover:border-tm-gray-700 text-primary bg-tm-gray-50 focus:ring-2 focus:ring-primary focus:ring-offset-2 top-0 -right-8'
                                            >
                                                <ArrowUturnDownIcon className="w-5 h-5 rotate-90"/>
                                            </button>
                                        </Tooltip>
                                    )}

                                    <FieldsToHtml
                                        fieldsState={stop}
                                        onInputChange={(name, value) => handleInputChange(name, value, i)}
                                        translate={translate}
                                        selects={{
                                            StopType: STOP_TYPES,
                                            StopID: {
                                                api: 'api/' + Resources.LocationsQuick,
                                                query: DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY(),
                                                searchMap: (it) => ({
                                                    label: it.LocationName + " - " + it.AddressName + " " + it.CityName + ", " +
                                                        it.StateAbbreviation + " " + it.PostalCode,
                                                    value: it.LocationID,
                                                    metadata: it
                                                })
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    <div className='text-right'>
                        <button
                            className='btn btn-outline'
                            onClick={handleAddStop}
                        >
                            <PlusIcon className='w-4 h-4 -ml-1 mr-1'/>
                            Add stop
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
