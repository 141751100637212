import React, {Component} from 'react'
import Resources from "../../../data/services/resources";
import {checkPerm, classNames, getDocumentTypesFor, getProp} from "../../../common/util/util-helpers";
import {CREATE_PERM, READ_PERM, UPDATE_PERM} from "../../../common/util/util-consts";
import LocalStorage from "../../../util/localStorage";
import {getCountTaskResource} from "../../../data/actions/taskResource";
import {showGlobalModal} from "../../../data/actions/ui";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Tippy from "@tippyjs/react";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import InfoTab from "./infoTab";
import ClaimNotesTab from "./claim-notes-tab";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import {connect} from "react-redux";
import {Field} from "../../../data/services/fields";
import {DELETE_PERM} from "../../../util/util-constants";
import ModalDefault from "../../../common/components/modal/modal-default";
class ClaimView extends Component {

    constructor(props) {
        super(props)
        const tabs = [
            {
                name: 'ClaimsInfo',
                resource: Resources.ClaimsInfo,
                current: true,
                visible: true
            },
            {
                name: 'ClaimsNotes',
                resource: Resources.ClaimsNotes,
                current: false,
                visible: true
            },
            {
                name: 'ClaimsDocuments',
                resource: Resources.ClaimsDocuments,
                current: false,
                visible: true
            },
        ].filter((it) => checkPerm(it.resource, READ_PERM))

        this.state = {
            tabs: tabs,
            selectedTab: Resources.ClaimsInfo,
            canSwapTabs: true,
            confirmModal: false
        }
    }

    componentDidMount = () => {
        this.fetchTaskResourceCount()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource !== this.props.resource && this.state.selectedTab === Resources.ClaimsInfo) {
            this.setState({
                ClaimNumber: getProp(this.props.resource, 'data.ClaimNumber', ''),
                ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
            })
        }
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!this.state.canSwapTabs) {
            this.setState({
                confirmModal: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    canSwapTabsHandler = (canSwapTabs = false) => {
        this.setState({canSwapTabs: canSwapTabs})
    }

    handleToggleConfirmModal = () => {
        this.setState({
            confirmModal: !this.state.confirmModal
        })
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTasksModalOpen: !this.state.resourceTasksModalOpen
        })
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    toggleResourceTasks = () => {
        this.setState({
            isResourceTaskModalVisible: !this.state.isResourceTaskModalVisible
        })
    }

    getID = () => {
        return this.props.match.params.id
    }

    render() {
        const {translate} = this.props

        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const openTaskCountTwoDigits = TaskCount > 9;
        const pageTitle = `Claim #${this.state.ClaimNumber}`

        return (
            <Layout
                {...this.props}
                hasFooter={this.state.selectedTab === Resources.Claims}
                isDirty={!this.state.canSwapTabs}
                customPageTitle={pageTitle}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="mb-2 flex justify-start items-center">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/claims'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('text.Claim')}
                                <span className="text-tm-gray-500"> - {this.state.ClaimNumber}
                                    {this.state.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                            </span>
                            </h1>
                        </div>

                        <div className={'flex justify-end gap-3'}>
                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <>
                                    <button className="btn btn-outline"
                                            disabled={!checkPerm(Resources.Tasks, CREATE_PERM)}
                                            onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_claim_task')}
                                    </button>

                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -left-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.ClaimsInfo && (
                        <InfoTab
                            canSwapTabsHandler={this.canSwapTabsHandler}
                            {...this.props}/>
                    )}

                    {this.state.selectedTab === Resources.ClaimsNotes && (
                        <ClaimNotesTab
                            translate={translate}
                            id={this.getID()}
                            tabResource={Resources.ClaimsNotes}
                        />
                    )}

                    {this.state.selectedTab === Resources.ClaimsDocuments && (
                        <DocumentsUpdateTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            thirdResource={this.props.thirdResource}

                            dialogTitle={'Claim Documents'}

                            sortBy={'Description'}
                            primaryKey={'ClaimDocumentID'}

                            download={this.props.download}
                            resourceName={Resources.ClaimsDocuments}

                            documentUpdateFields={checkPerm(Resources.ClaimsDocuments, UPDATE_PERM) && {
                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                    values: getDocumentTypesFor("IsSafetyDocument")
                                }),
                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            documentTypes={getDocumentTypesFor("IsSafetyDocument")}
                            searchFields={{
                                query: new Field('query', '', [], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true,
                                    values: getDocumentTypesFor("IsSafetyDocument")
                                }),
                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true
                                }),
                            }}
                            readOnly={!checkPerm(Resources.ClaimsDocuments, CREATE_PERM)}
                            disableDelete={!checkPerm(Resources.ClaimsDocuments, DELETE_PERM)}
                        />
                    )}
                </Page>

                <ModalConfirm
                    type="default"
                    title={this.props.translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal && this.state.selectedTab === Resources.ClaimsInfo}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        canSwapTabs: true,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>

                </ModalConfirm>

                <ModalDefault
                    show={!!this.state.isResourceTaskModalVisible}
                    title={translate('modal_heading.claim_tasks')}
                    widthClass={'max-w-3xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={this.toggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.toggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>

                <ModalDefault
                    show={!!this.state.resourceTasksModalOpen}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.claim_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(ClaimView)
