import React, { Component } from 'react'
import { Field, FieldsManager } from '../../../data/services/fields'
import { getResource, resetResource, updateResource } from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import {checkPerm, getLookup, getProp} from '../../../common/util/util-helpers'
import {getDocumentTypesFor} from  '../../../common/util/util-helpers';
import {ALL_REQUIRED_TYPES, REQUIRED_DOCUMENTS_TYPES, UPDATE_PERM} from "../../../common/util/util-consts";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {LoaderSmall} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import FieldSwitch from "../../../common/components/fields/field-switch";
export default class DocumentEntitiesTab extends Component {
    constructor (props) {
        super(props)

        this.state = {
            fields: this.getFields(),

            selectedItem: null,
            footerIsSticky: true,
            canSubmit: false,
            currentDocumentTypes: ''
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount () {
        this.fetchData()
    };

    componentWillUnmount () {
        this.props.dispatch(resetResource())
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName()
        }))
    }

    submit = () => {
        const RequiredIDs = Object.keys(this.state.fields)
            .filter(item => !!this.state.fields[item].value)
            .map(it => {
                return {
                    DocumentTypeID: it,
                    Required: 1,
                    EntityTypeID: this.state.selectedItem.value
                }
            })
        const UnRequiredIDs = Object.keys(this.state.fields)
            .filter(item => !this.state.fields[item].value)
            .map(it => {
                return {
                    DocumentTypeID: it,
                    Required: 0,
                    EntityTypeID: this.state.selectedItem.value
                }
            })

        this.setState({ canSubmit: false , currentDocumentTypes: "IsPersonnelDocument"})
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            piggyResource: this.getResourceName(),
            params: {
                data: RequiredIDs.length > 0 ? RequiredIDs : UnRequiredIDs
            },
            errorMessage: true, successMessage: 'Resource updated successfully!'
        }))

        this.setState({selectedItem: null})
    }

    cancel = (data) => {
        this.setState({ fields: this.getFields(data[this.state.selectedItem.label]), canSubmit: false })
    }

    /** UI Events
     ================================================================= */
    handleToggleRequiredDocuments = (name, value) => {
        this.setState({ fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true })
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.DocumentEntities
    }

    getFields = (item = null) => {
        const RequiredDocs = getProp(item, 'RequiredDocs', []).map(it => it.DocumentTypeID)
        const DocumentType = getLookup('DocumentType')

        return item ? Object.keys(DocumentType).reduce((memo, it) => {
            let itemValue = false
            if (RequiredDocs.includes(+it)) {
                itemValue = true
            }
            memo[it] = new Field(it, itemValue, [])
            return memo
        }, {}) : {}
    }

    getRequiredDocuments = (item) => {
        const a = REQUIRED_DOCUMENTS_TYPES
        for (let i = 0;i < Object.keys(a).length;i++) {
            let c = Object.keys(a)[i]
            if (a[c].includes(item)) {
                this.setState({currentDocumentTypes: "Is" + Object.keys(a)[i] + "Document"})
            }
        }
    }
    /** Render
     ================================================================= */
    render () {
        const { translate, resource } = this.props
        const data = getProp(resource, 'data.list', {})

        const docs = getDocumentTypesFor(this.state.currentDocumentTypes)

        const types = this.props.requiredTypes ?? ALL_REQUIRED_TYPES

        const sortOrder = this.props.sortOrder

        const entities = Object.keys(data).filter((it,i) => types.some(b => Object.keys(data)[i].includes(b))).sort(function(a, b){
            if (sortOrder) {
                return sortOrder.indexOf(a) - sortOrder.indexOf(b);}
        }).map((item, i) => {
                return (
                    <button
                        key={i}
                        onClick={() => {
                            this.setState({
                                selectedItem: { label: item, value: data[item].EntityType },
                                fields: this.getFields(data[item]),
                                canSubmit: false
                            })
                            this.getRequiredDocuments(item)
                        }
                    }
                        type="button"
                        className={`${this.state.selectedItem?.label === item ? 'text-white bg-primary border-transparent' : 'text-primary border-tm-gray-300'} border font-bold w-full flex justify-between mb-2 cursor-pointer px-4 py-2  shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-popup`}>
                        {translate(`text.${item}`)}
                        <ArrowRightIcon className={'h-4 w-4'}/>
                    </button>
                )
            })

        const documents = this.state.selectedItem && Object.keys(docs)
            .map(item => (
                <Card bodyClass={'flex justify-between items-center w-full p-3'} className={'mb-3'}>
                    <div className="w-50">
                        <h6>
                            {docs[item]}
                        </h6>
                    </div>
                    <div className="w-50 text-right">
                        <FieldSwitch
                            name={item}
                            value={this.state.fields[item].value}
                            key={item.name}
                            onChange={this.handleToggleRequiredDocuments}
                        />
                    </div>
                </Card>
            ))

        const isLoading = getProp(this.props, 'resource.isLoading', true)

        return (
            <React.Fragment>
                <PageHeader
                    title={translate('text.DocumentEntities')}
                    titleClass="text-2xl m-4"
                />

                <Card>
                    {isLoading && (
                        <div className="m-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!isLoading && (
                        <div className="md:grid grid-cols-12 gap-8">
                            <div className="col-span-4">
                                <h5 className="text-xl m-0 p-0 mb-3">Entities</h5>
                                {entities}
                            </div>

                            <div className="col-span-8">
                                {this.state.selectedItem ? (
                                        <div>
                                            <h5
                                                className="text-xl m-0 p-2 mb-3 border-b border-tm-gray-200">{translate(`text.${this.state.selectedItem.label}`)}</h5>
                                            <div className="flex justify-between items-center">
                                                <h5 className="text-xl m-0 p-0 mb-3 ">Document Type</h5>
                                                <h5 className="text-xl m-0 p-0 mb-3 ">Required</h5>
                                            </div>
                                            {documents}
                                        </div>
                                    )
                                    :
                                    <h5 className="text-xl m-0 p-0 mb-3 text-center">Select Entity</h5>
                                }
                            </div>
                        </div>
                    )}
                </Card>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.canSubmit}
                    actionCancel={() => this.cancel(data)}
                    actionSubmit={checkPerm(Resources.DocumentEntities, UPDATE_PERM) && this.submit}
                />
            </React.Fragment>
        )
    }
}
