import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {returnOffsetAndPagination} from '../../../common/util/util-helpers'
import {fillFieldsFromData} from '../../../common/util/util-fields'
import {Field, FieldsManager} from '../../../data/services/fields'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp, longTableColumn} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import DriverScore from "../../../common/components/module-companies/driverScore";
import RatingIcon from "../../../common/components/module-companies/rating-icon";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import Card from "../../../common/components/card";

export default class ReviewsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Query
            ...DEFAULT_CRUD_STATE,

            // Fields
            fields: this.getFields(),
            rating: 0,
            hoverRating: 0,
            canSubmit: false,

            // Modals
            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery(),
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_delete_review')}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {
                            OrganizationReviewID: item.OrganizationReviewID,
                            id: this.props.match.params.OrganizationID,
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: this.props.translate('message.review_has_been_deleted'),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModalOpen: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            rating: 0,
            createModalOpen: !this.state.createModalOpen,
            canSubmit: false
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            rating: item?.Rating ?? 0,
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            canSubmit: false
        })
    }

    handleEnterRatingHandler = (index) => {
        this.setState({hoverRating: index})
    }

    handleLeaveRatingHandler = () => {
        this.setState({hoverRating: 0})
    }

    handleSaveRatingHandler = (index) => {
        this.setState({rating: index, canSubmit: true})
    }

    /** Fields/Data definitions
     ================================================================= */
    getFields = (item = null) => {
        const fieldTemplates = {
            UpdatedByContactName: new Field('UpdatedByContactName', '', [], false, 'text', {hideDialog: true}),
            Rating: new Field('Rating', '', [], false, 'custom', {
                hideDialog: true,
                render: (item) => <div>{item.Rating ? item.Rating : 0}/10</div>
            }),
            ReviewTypeID: new Field('ReviewTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            ReviewNote: new Field('ReviewNote', '', ['empty'], false, 'textarea', {
                addContainerClass: 'col-span-full',
                render: (item) => (
                    <div>{longTableColumn(item.ReviewNote)}</div>
                )
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone', {hideDialog: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** Query definitions
     ================================================================= */
    getQuery = () => {
        return {
            id: this.props.match.params.OrganizationID,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            limit: this.state.limit,
            offset: this.state.offset
        }
    }

    /** Helpers
     ================================================================= */
    getResource = () => {
        return this.props.resourceName
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const isLoading = !!resource?.isLoading;

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const hasRatings = !!getProp(resource.data, 'ratings', []).length

        const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
            return (
                <RatingIcon
                    index={index}
                    rating={this.state.rating}
                    hoverRating={this.state.hoverRating}
                    onMouseEnter={this.handleEnterRatingHandler}
                    onMouseLeave={this.handleLeaveRatingHandler}
                    onSaveRating={this.handleSaveRatingHandler}
                />
            )
        })

        return (
            <React.Fragment>
                <PageHeader
                    buttonLabel={translate('btn.create_new')}
                    onButtonClick={() => this.handleToggleCreateModal()}
                    buttonHidden={!checkPerm(this.getResource(), CREATE_PERM)}
                />

                <div className="flex flex-col xl:flex-row gap-4 mt-2">
                    {hasRatings && (
                        <div className="w-64 shrink-0">
                            <Card addClass={"pt-4"}>
                                <DriverScore
                                    translate={translate}
                                    resource={resource}
                                />
                            </Card>
                        </div>
                    )}

                    <div className="relative w-full">
                        <TableCard>
                            <ResourceTable
                                data={data}
                                count={count}

                                fields={this.state.fields}
                                translate={this.props.translate}
                                isLoading={isLoading}
                                options={{style: {floatingActions: true, horizontalLines: true}}}
                                limit={this.state.searchFields?.limit?.value ?? this.state.limit}
                                offset={this.state.offset}
                                page={this.state.paginationPage}
                                onOffsetChange={this.handleUpdateOffset}

                                onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEditModel : null}
                                onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                            />

                            <NoRecordsTable
                                show={(data.length === 0) && !isLoading}
                                canCreate={false}
                                title={translate('text.no_matching_records')}
                                className={"pb-12 pt-16 px-6"}
                            />
                        </TableCard>
                    </div>
                </div>

                <ModalSaveResource
                    title={'Edit review'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.OrganizationReviewID = this.state.selectedItem?.OrganizationReviewID
                            params.Rating = this.state.rating
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    id: this.props.id,
                                }),
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate("message.CustomerReviewUpdated"),
                            }))
                            this.handleToggleEditModel()
                        }
                    }}
                    translate={this.props.translate}
                    htmlBefore={
                        <>
                            <div className={'px-5 mt-3'}>
                                <label className="form-group has-float-label">
                                    <div className="flex form-control">
                                        {ratings}
                                    </div>
                                    <span>{translate('field.ReviewRating')}</span>
                                </label>
                            </div>
                        </>
                    }
                    canSubmit={this.state.canSubmit}
                    handleInputChange={(fields, name, value) => {
                        this.setState({canSubmit: true})
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={'Create review'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.Rating = this.state.rating
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    id: this.props.id,
                                }),
                                query: this.getQuery(),
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate("message.CustomerReviewCreated"),
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    htmlBefore={
                        <>
                            <div className={'px-5 mt-3'}>
                                <label className="form-group has-float-label">
                                    <div className="flex form-control">
                                        {ratings}
                                    </div>
                                    <span>{translate('field.ReviewRating')}</span>
                                </label>
                            </div>
                        </>
                    }
                    canSubmit={this.state.canSubmit}
                    handleInputChange={(fields, name, value) => {
                        this.setState({canSubmit: true})
                        return FieldsManager.updateField(fields, name, value)
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModalOpen}
                />
            </React.Fragment>
        )
    }
}
