import React, { Component } from "react";
import Async from "react-select/async";
import { getCustomStyles } from "../../../../data/themes";
import { scrollIntoView } from "../../../util/util-vanilla";
import { classNames } from "../../../util/util-helpers";
import {LoaderSmall} from "../../loader";

export default class FieldDropdownSelect extends Component {

    state = {
        isLoading: false,
        defaultOptions: []
    };

    render() {
        const pairs = this.props.values ? this.props.values : {};

        return (
            <React.Fragment>
                <Async
                    autoFocus={this.props.autoFocus}
                    ref={this.props.innerRef}
                    styles={this.props.inputStyles ?? getCustomStyles()}
                    className={
                        classNames(
                            "select-css-search p-0 relative",
                            this.props.addClass ? this.props.addClass : undefined,
                        this.props.errorMessage ? "is-invalid" : undefined
                        )
                    }
                    onKeyDown={this.props.onKeyDown}
                    isDisabled={!!this.props.disabled}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption);
                    }}
                    name={this.props.name}
                    value={!this.props.loadOptions ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    options={this.props.options}
                    loadOptions={this.props.loadOptions}
                    defaultOptions={this.state.defaultOptions}
                    isClearable={!!this.props.isClearable}
                    isMulti={this.props.multi}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        if (!this.state.defaultOptions?.length) {
                            this.setState({
                                isLoading: true,
                            });


                                this.props.loadOptions('', (options) => {
                                    if (options) {
                                        this.setState({isLoading: false, defaultOptions: options});
                                    }
                                });
                        }

                        scrollIntoView(event);
                    }}
                    onBlur={this?.props?.onBlur}
                    key={this.props.key}
                    openMenuOnFocus={this.props.openMenuOnFocus}
                    menuPlacement={this.props.menuPlacement}
                    menuPortalTarget={this.props.hasPortal ? document.body : null}
                    isLoading={this.state.isLoading}
                />

                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold"}>
                        {!!this.props.translate && (
                            this.props.errorMessage.reduce((memo, it) => {
                                return this.props.translate(it) + (memo ? "," + memo : "")
                            }, "")
                        )}
                        {!this.props.translate && (
                            <span>Field required*</span>
                        )}
                    </div>
                )}

                {this.props.isLoading && (
                    <LoaderSmall addClass={"absolute right-12 top-1/2"}/>
                )}
            </React.Fragment>
        )
    }
}
