export function download(data) {
    return {
        type: 'DOWNLOAD',
        data: data
    };
}

export function doneDownload(data) {
    return {
        type: 'DONE_DOWNLOAD',
        data: data
    };
}

export function errorDownload(data) {
    return {
        type: 'ERROR_DOWNLOAD',
        data: data
    };
}

export function uploadDocument(data) {
    return {
        type: 'UPLOAD_DOCUMENT',
        data: data
    };
}

export function doneUploadDocument(data) {
    return {
        type: 'DONE_UPLOAD_DOCUMENT',
        data: data
    };
}

export function errorUploadDocument(data) {
    return {
        type: 'ERROR_UPLOAD_DOCUMENT',
        data: data
    };
}

export function deleteDocument(data) {
    return {
        type: 'DELETE_DOCUMENT',
        data: data
    };
}

export function doneDeleteDocument(data) {
    return {
        type: 'DONE_DELETE_DOCUMENT',
        data: data
    };
}

export function errorDeleteDocument(data) {
    return {
        type: 'ERROR_DELETE_DOCUMENT',
        data: data
    };
}
