import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, resetResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import InfoTab from './infoTab'
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    FIELD_MASK_PHONE, READ_PERM,
    SCAC_CODE_FIELD_LENGTH
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultContactGroup, getDefaultUserOfficeValues, getDocumentTypesFor, getPreFillFromQuery,
    updateContractFields,
    updateStateFields
} from '../../../common/util/util-helpers'
import {scrollErrorIntoViewFields, setSelectionRange} from '../../../common/util/util-vanilla'
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import ReviewsTab from "../../../common/components/module-companies/reviewsTab";
import GroupsViewDialog from "../../../common/components/modal/groups-view-dialog";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Page from "../../../common/components/layout/layout-components/page";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import {LoaderLarge} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import Modal from "../../../common/components/modal";
import FieldOptions from "../../../common/components/fields/field-options";

class CarrierCreateView extends Component {
    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'CarrierInfo',
                resource: Resources.Carrier,
                current: true,
                visible: checkPerm(Resources.Carrier, READ_PERM),
            },
            {
                name: 'CarrierReviews',
                resource: Resources.CarrierReviews,
                current: false,
                visible: checkPerm(Resources.CarrierReviews, READ_PERM),
            },
            {
                name: 'CarrierDocuments',
                resource: Resources.CarrierDocuments,
                current: false,
                visible: checkPerm(Resources.CarrierDocuments, CREATE_PERM),
            },

        ]

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.Carrier,

            // Fields
            fields_info: this.getFieldsInfo(),
            fields_reviews: [],
            fields_contacts: [],
            contacts: [],
            fields_documents: [],

            redirectModalOpen: false,
            canSwapTabs: true,
            emailExist: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {},
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }
        if (!!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS') && !this.props.ui.updateOfficeDialog) {
            this.setState({emailExist: true, activeTab: 'info'})
        }
    }

    /** Data events
     ================================================================= */
    onSubmit = () => {
        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info)) {
                let params = Object.assign({},
                    FieldsManager.getFieldKeyValues(this.state.fields_info),
                    {Reviews: this.state.fields_reviews.map((fields) => FieldsManager.getFieldKeyValues(fields))},
                    {
                        Contacts: this.state.fields_contacts.map((item) => {
                            return {
                                ContactID: item.ContactID,
                                DepartmentID: item.DepartmentID,
                                Notes: item.Notes
                            }
                        })
                    }
                )
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: params,
                    resource: Resources.Carriers,
                    errorMessage: true,
                    showRedirectDialog: true,
                    document: this.state.fields_documents,
                    documentResource: Resources.CarrierDocuments,
                }))
            } else {
                this.handleTabChange(Resources.Carrier)
                scrollErrorIntoViewFields([this.state.fields_info])
            }
        })
    }

    /** UI events
     ================================================================= */
    handleFormCancel = () => {
        this.setState({
            fields_info: this.getFieldsInfo(),
            canSubmit: false
        })
    }

    handleToggleRedirectDialog = () => {
        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.CarrierID,
            createdOrganizationID: this.props.resource.create && this.props.resource.create.OrganizationID,
            redirectModalOpen: !this.state.redirectModalOpen,
            // Reset fields
            fields_info: this.getFieldsInfo(),
            fields_reviews: [],
            fields_contacts: [],
            contacts: [],
            fields_documents: [],
            canSubmit: false
        })
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }
        this.tabChange(resource)
    }

    tabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    /** Fields/Tables onChange events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields_info, name, value)

        if (name === 'Preferred' && !!value) fields.Blacklisted.value = 0
        if (name === 'Blacklisted' && !!value) fields.Preferred.value = 0

        fields = updateContractFields(fields, name, value)
        fields = updateStateFields(fields, name, value)

        this.setState({canSubmit: true, fields_info: fields})
    }

    handleItemInputChange = (i, name, value) => {
        let fields = this.state.phones_info[i]
        const newItems = this.state.phones_info
        newItems[i] = FieldsManager.updateField(fields, name, value)
        this.setState({canSubmit: true, phones_info: newItems})
    }

    handleSetLocations = (fields, isLocationPhysical = false) => {
        let updatedFields = this.state.fields_info

        for (let [key, value] of Object.entries(fields)) {
            if (isLocationPhysical) {
                key = "Physical" + key;
            }

            if (key in this.state.fields_info) {
                if (!(key === 'LegalName' && !!this.state.fields_info?.['LegalName']?.value)) {
                    updatedFields = FieldsManager.updateField(updatedFields, key, value)
                }
            }
        }

        this.setState({
            fields_info: updatedFields,
            canSubmit: true
        })
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    handleAddNewReview = (fields) => {
        let arr = this.state.fields_reviews
        arr.push(fields)
        this.setState({fields_reviews: arr, canSubmit: true})
    }

    handleRemoveReview = () => {
        let arr = this.state.fields_reviews
        arr.splice(this.state.confirmIndex, 1)
        this.setState({fields_reviews: arr, confirmModal: false})
    }

    handleConfirmRemoveReview = (i) => {
        this.setState({
            confirmText: this.props.translate('message.delete_review', [this.state.fields_reviews[i].ReviewNote.value]),
            confirmModal: this.handleRemoveReview,
            confirmIndex: i
        })
    }

    handleRemoveNewContact = () => {
        let arr = this.state.fields_contacts
        let arr2 = this.state.contacts
        arr.splice(this.state.confirmIndex, 1)
        arr2.splice(this.state.confirmIndex, 1)
        this.setState({fields_contacts: arr, contacts: arr2, confirmModal: false})
    }


    /** Fields/Data Definitions
     ================================================================= */
    getFieldsInfo = () => {
        const LegalName = getPreFillFromQuery()

        return {
            // Card 1
            CountryID: new Field('CountryID', 1, [], false, 'select', {addContainerClass: "col-span-full"}),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: this.handleSetLocations,
                addContainerClass: "col-span-full"
            }),
            LegalName: new Field('LegalName', LegalName ?? '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
            DBAName: new Field('DBAName', '', [''], false, "text", {addContainerClass: "col-span-6"}),
            AddressName: new Field('AddressName', '', [], false, "text", {addContainerClass: "col-span-full"}),
            AddressName2: new Field('AddressName2', '', [], false, "text", {addContainerClass: "col-span-full"}),
            CityName: new Field('CityName', '', [], false, "text", {addContainerClass: "col-span-4"}),
            StateID: new Field('StateID', '', [], false, 'select', {addContainerClass: "col-span-4"}),
            PostalCode: new Field('PostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),

            PhysicalCountryID: new Field('PhysicalCountryID', 1, [], false, 'select', {addContainerClass: "col-span-full"}),
            PhysicalGooglePlaces: new Field('PhysicalGooglePlaces', '', [], false, 'google-locations', {
                setLocations: (fields) => this.handleSetLocations(fields, true),
                countryKey: "PhysicalCountryID",
                addContainerClass: "col-span-full"
            }),
            PhysicalAddressName: new Field('PhysicalAddressName', '', [], false, "text", {addContainerClass: "col-span-full"}),
            PhysicalAddressName2: new Field('PhysicalAddressName2', '', [], false, "text", {addContainerClass: "col-span-full"}),
            PhysicalCityName: new Field('PhysicalCityName', '', [], false, "text", {addContainerClass: "col-span-4"}),
            PhysicalStateID: new Field('PhysicalStateID', '', [], false, 'select', {addContainerClass: "col-span-4"}, {menuPlacement: "top"}),
            PhysicalPostalCode: new Field('PhysicalPostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),

            ContactGroupID: new Field('ContactGroupID',
                getDefaultContactGroup() ? getDefaultContactGroup() : null,
                ['empty'], false, 'select-search',
                {addContainerClass: "col-span-full xl:col-span-6",
                    label: "CompaniesGroupID",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                        isVisible: !!it.value && checkPerm(Resources.ContactGroups, READ_PERM)
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    isClearable: true,
                    api: 'api/' + Resources.ContactGroupsQuick,
                    query: {},
                    searchMap: (it) => ({
                        label: it.ContactGroupName,
                        value: it.ContactGroupID,
                        metadata: it
                    })
                }
            ),

            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search',
                {
                    addContainerClass: "col-span-full xl:col-span-6",
                }, {
                    api: 'api/' + Resources.OfficesQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        label: item.OfficeName,
                        value: item.OfficeID
                    })
                }
            ),

            UserDefinedField1: new Field('UserDefinedField1', '', [], false, "test", {addContainerClass: "col-span-full"}),
            UserDefinedField2: new Field('UserDefinedField2', '', [], false, "test", {addContainerClass: "col-span-full"}),

            // Card 2
            AreaCode: new Field('AreaCode', '', [], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: FIELD_MASK_PHONE
            }),
            PhoneExtension: new Field('PhoneExtension', '', []),
            PrimaryEmail: new Field('PrimaryEmail', '', ['']),
            SCAC: new Field('SCAC', '', [''], false, 'text', {}, {
                max: SCAC_CODE_FIELD_LENGTH,
                upperCaseOnly: true
            }),
            OrganizationEntityTypeID: new Field('OrganizationEntityTypeID', '', [''], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            MCNumber: new Field('MCNumber', '', [''], false, 'text', {label: 'MCMXNumber'}, {
                max: 10,
            }),
            FFNumber: new Field('FFNumber', '', [''], false, 'text', {}, {
                max: 10,
            }),
            USDOTNumber: new Field('USDOTNumber', '', [''], false, 'text', {}, {
                max: 10,
            }),
            FederalID: new Field('FederalID', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                onFocus: (event) => {
                    setSelectionRange('FederalID', event.target.value)
                }
            }),
            NaicsID: new Field('NaicsID', '', [''], false, 'select'),
            SendPromotionalEmails: new Field('SendPromotionalEmails', 1, [], false, 'checkbox'),

            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: "col-span-full"}),

            // Card 4
            ContractOnFile: new Field('ContractOnFile', '', [], false, 'checkbox'),
            ContractStartDate: new Field('ContractStartDate', '', [''], true, 'date', {label: "CompanyContractStartDate"}, {}),
            ContractEndDate: new Field('ContractEndDate', '', [''], true, 'date', {}, {}),
            ContactNumber: new Field('ContactNumber', '', [''], true),

            // Card 5
            Blacklisted: new Field("Blacklisted", '', [], false, 'checkbox'),
            Preferred: new Field("Preferred", '', [], false, 'checkbox'),

            // Card 6
            NumOfTrucks: new Field("NumOfTrucks", '', ['integer_or_empty'], false, 'integer', {
                addContainerClass: 'col-span-full'
            }),
            NumOfTrailers: new Field("NumOfTrailers", '', ['integer_or_empty'], false, 'integer', {
                addContainerClass: 'col-span-full'
            }),

            // Card 7
            LiabilityInsuranceNo: new Field("LiabilityInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            LiabilityInsuranceExpiryDate: new Field("LiabilityInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            LiabilityInsuranceAmount: new Field("LiabilityInsuranceAmount", '', ['float_or_empty'], false, 'float', {
                hideLabel: true
            }),
            CargoInsuranceNo: new Field("CargoInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            CargoInsuranceExpiryDate: new Field("CargoInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            CargoInsuranceAmount: new Field("CargoInsuranceAmount", '', ['float_or_empty'], false, 'float', {
                hideLabel: true
            }),
            TrailerInteropInsuranceNo: new Field("TrailerInteropInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            TrailerInteropInsuranceExpiryDate: new Field("TrailerInteropInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            WorkCompInsuranceNo: new Field("WorkCompInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            WorkCompInsuranceExpiryDate: new Field("WorkCompInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),

            // Card 8
            IsCommonAuth: new Field('IsCommonAuth', '', [], false, 'checkbox'),
            IsContractAuth: new Field('IsContractAuth', '', [], false, 'checkbox'),
            IsBrokerAuth: new Field('IsBrokerAuth', '', [], false, 'checkbox'),
        }
    }

    handleCopyFromPhysicalAddressClick = () => {
        const fields_info = this.state.fields_info;

        fields_info.CountryID.value = fields_info.PhysicalCountryID.value;
        fields_info.AddressName.value = fields_info.PhysicalAddressName.value;
        fields_info.AddressName2.value = fields_info.PhysicalAddressName2.value;
        fields_info.CityName.value = fields_info.PhysicalCityName.value;
        fields_info.StateID.value = fields_info.PhysicalStateID.value;
        fields_info.PostalCode.value = fields_info.PhysicalPostalCode.value;

        this.setState({
            fields_info: fields_info
        })
    }

    handleCopyFromMailingAddressClick = () => {
        const fields_info = this.state.fields_info;

        fields_info.PhysicalCountryID.value = fields_info.CountryID.value;
        fields_info.PhysicalAddressName.value = fields_info.AddressName.value;
        fields_info.PhysicalAddressName2.value = fields_info.AddressName2.value;
        fields_info.PhysicalCityName.value = fields_info.CityName.value;
        fields_info.PhysicalStateID.value = fields_info.StateID.value;
        fields_info.PhysicalPostalCode.value = fields_info.PostalCode.value;

        this.setState({
            fields_info: fields_info
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const isLoading = resource.isLoading;

        return (
            <Layout
                {...this.props}
                hasFooter={true}
                isDirty={this.state.canSubmit}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="mb-2 flex justify-start items-center">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/carriers'}
                            />
                            <h1
                                className={'mr-5 text-3xl ml-2'}> {translate('text.create')} {translate('page.heading.carrier')} </h1>
                        </div>

                        <div>

                        </div>
                    </div>

                    <div className="mb-5">
                        <NavResponsive
                            addClass="hidden sm:block"
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />

                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="relative pb-20">
                        {isLoading && (
                            <LoaderLarge stripesBg={true}/>
                        )}

                        {this.state.selectedTab === Resources.Carrier && (
                            <InfoTab
                                setLocations={this.handleSetLocations}
                                handleItemInputChange={this.handleItemInputChange}
                                handleInputChange={this.handleInputChange}
                                fields_info={this.state.fields_info}
                                onCopyFromPhysicalAddressClick={this.handleCopyFromPhysicalAddressClick}
                                onCopyFromMailingAddressClick={this.handleCopyFromMailingAddressClick}
                                {...this.props}/>
                        )}

                        {this.state.selectedTab === Resources.CarrierDocuments && (
                            <Card>
                                <DocumentsCreateTab
                                    fields={this.state.fields_documents}
                                    translate={this.props.translate}

                                    dialogTitle={'Employee Documents'}

                                    documentTypes={getDocumentTypesFor("IsCompanyDocument")}
                                    handleUploadDocument={this.handleUploadDocument}
                                    handleDeleteDocument={this.handleDeleteDocument}
                                />
                            </Card>
                        )}

                        {this.state.selectedTab === Resources.CarrierReviews && (
                            <ReviewsTab
                                addNewReview={this.handleAddNewReview}
                                removeNewReview={this.handleConfirmRemoveReview}
                                fields_reviews={this.state.fields_reviews}
                                {...this.props}/>
                        )}

                    </div>

                    <PageFooter
                        translate={translate}
                        canSubmit={this.state.canSubmit}
                        actionCancel={this.handleFormCancel}
                        actionSubmit={checkPerm(Resources.Carriers, CREATE_PERM) && this.onSubmit}
                    />
                </Page>

                <RedirectDialog
                    show={this.state.redirectModalOpen}
                    title={translate('card_header.carrier_created')}
                    onClose={() => {
                        this.props.dispatch(resetResource())
                        this.setState({
                            createdResourceID: null,
                            redirectModalOpen: false
                        })
                    }}

                    createdLabel={translate('btn.carrier')}
                    createdLink={`/carriers/info/${this.state.createdOrganizationID}/${this.state.createdResourceID}`}

                    newLabel={translate('btn.create_new')}

                    listLabel={translate('btn.list')}
                    listLink="/carriers"

                    dashboardLabel={translate('btn.dashboard')}

                    {...this.props}
                >
                    <p>{translate('message.carrier_created')}</p>
                </RedirectDialog>

                {/*Confirm dialog*/}
                <Modal
                    show={!!this.state.confirmModal}
                    title={translate('modal_heading.confirm')}

                    onClose={() => this.setState({confirmModal: false})}
                    closeButtonLabel={translate('btn.cancel')}

                    buttonLabel={translate('btn.ok')}
                    onButtonClick={this.state.confirmModal}
                    {...this.props}
                >
                    <div className={'p-4'}>
                        {this.state.confirmText}
                    </div>
                </Modal>

                <GroupsViewDialog
                    groupID={this.state.fields_info?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(CarrierCreateView)

