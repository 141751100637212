import ArrowsRightLeftIcon from "@heroicons/react/20/solid/ArrowsRightLeftIcon";
import React, {useState} from "react";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import {classNames} from "../../../../../common/util/util-helpers";
import FlagIcon from "@heroicons/react/20/solid/FlagIcon";
import {getStopIcon} from "../../load-utils";
import {
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_PICKUP_AND_DELIVERY,
    STOP_TYPE_STOP_BY
} from "../../../../../util/util-constants";
import ArrowSmallUpIcon from "@heroicons/react/20/solid/ArrowSmallUpIcon";
import ArrowSmallDownIcon from "@heroicons/react/20/solid/ArrowSmallDownIcon";
import ArrowsUpDownIcon from "@heroicons/react/20/solid/ArrowsUpDownIcon";
import StopHistoryItem from "./stop-history-item";
import {metadata} from "../../../../../common/components/cron/meta";

export default function LoadDispatchHistory({stopsCombined, stops, stopBys, isLoading, translate, startingAssets, buttonClass, iconClass}) {
    const [isDispatchModalOpen, setIsDispatchModalOpen] = useState(false);
    const filteredStops = stopsCombined.filter(stop => {
            return stop?.IsStopCompleted && (
                (stop.StopType ? stops[stop.index]?.DriverID?.value : stopBys[stop.index]?.DriverID?.value)
                || (stop.StopType ? stops[stop.index]?.TruckID?.value : stopBys[stop.index]?.TruckID?.value)
                || (stop.StopType ? stops[stop.index]?.TrailerID?.value : stopBys[stop.index]?.TrailerID?.value)
                || (stop.StopType ? stops[stop.index]?.CoDriverID?.value : stopBys[stop.index]?.CoDriverID?.value)
                || (stop.StopType ? stops[stop.index]?.IsPickingTrailer?.value : stopBys[stop.index]?.IsPickingTrailer?.value)
                || (stop.StopType ? stops[stop.index]?.IsDroppingTrailer?.value : stopBys[stop.index]?.IsDroppingTrailer?.value)
                || (!!stop?.metadata?.IsSwitchDispatch)
            ) || (!!stop?.metadata?.IsDropType)
        }
    );

    if (!filteredStops.length) {
        return null;
    }


    let droppedTrailer = startingAssets.trailer;

    function getCombinedStopIcon(stop) {
        let StopIcon = null;
        if (Number(stop.StopType) === STOP_TYPE_STOP_BY) {
            StopIcon = getStopIcon(stopBys[stop.index]?.StopByTypeID?.value);
        }
        const IsStopCompleted = stop.IsStopCompleted;

        return (
            <div
                className={classNames(
                    "w-6 h-6 rounded-md flex-shrink-0 relative focus:ring-2",
                    IsStopCompleted ? "border-green-600 bg-green-600" : "text-tm-gray-500 cursor-default",
                    Number(stop.StopType) !== STOP_TYPE_STOP_BY && !IsStopCompleted ? "border-2 border-tm-gray-400" : undefined,
                )}
            >
                {Number(stop.StopType) === STOP_TYPE_PICKUP && (
                    <ArrowSmallUpIcon
                        className={classNames("absolute inset-0", IsStopCompleted ? "text-white" : "text-tm-gray-500")}/>
                )}

                {Number(stop.StopType) === STOP_TYPE_DELIVERY && (
                    <ArrowSmallDownIcon
                        className={classNames("absolute inset-0", IsStopCompleted ? "text-white" : "text-tm-gray-500")}/>
                )}

                {Number(stop.StopType) === STOP_TYPE_PICKUP_AND_DELIVERY && (
                    <ArrowsUpDownIcon
                        className={classNames("absolute inset-0", IsStopCompleted ? "text-white" : "text-tm-gray-500")}/>
                )}

                {StopIcon && Number(stop.StopType) === STOP_TYPE_STOP_BY && (
                    <StopIcon
                        className={
                            classNames(
                                IsStopCompleted ? "bg-tm-green-600" : "bg-red-600",
                                "w-6 h-6 p-1 rounded-md text-white"
                            )
                        }
                    />
                )}
            </div>
        )
    }

    function getDriverStatus(stop) {
        if (!stop.metadata?.DriverID) {
            return null;
        }

        return <span>{translate("text.driver_changed_to")}
            <span className="font-bold">
                {stop.metadata?.Driver}
            </span>
        </span>
    }

    function getCoDriverStatus(stop) {
        if (!stop.metadata?.CoDriverID) {
            return null;
        }

        return <span>{translate("text.co_driver_changed_to")}
            <span className="font-bold">
                {stop.metadata?.CoDriver}
            </span>
        </span>
    }

    function getTruckStatus(stop) {
        if (!stop.metadata?.TruckID) {
            return null;
        }

        return <span>{translate("text.truck_changed_to")}
            <span className="font-bold">
                {stop.metadata?.Truck}
            </span>
        </span>
    }

    function getTrailerStatus(stop) {
        if (stop.metadata?.TrailerID && !stop?.metadata?.IsDropType) {
            return <span>{translate("text.trailer_changed_to")}
                <span className="font-bold">
                {stop.metadata?.Trailer}
            </span>
        </span>
        }

        return null;
    }

    function getTrailerPickedStatus(stop) {
        if (stop.metadata?.PickingTrailerID) {
            return <span>{translate("text.picking_trailer")}
                <span className="font-bold">
                {stop.metadata?.PickingTrailer}
            </span>
        </span>
        }

        return null;
    }

    function getTrailerDroppedStatus(stop, currentTrailer) {
        if (stop.StopType ? stops[stop.index]?.IsDroppingTrailer?.value : stopBys[stop.index]?.IsDroppingTrailer?.value) {
            return <span>{translate("text.dropping_trailer_on_this_stop")}<span
                className="font-bold">{currentTrailer}</span></span>
        }

        return null;
    }

    return (
        <>
            {!isLoading && (
                <div>
                    <button
                        className={buttonClass ?? "btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"}
                        onClick={() => setIsDispatchModalOpen(true)}
                    >
                        <ArrowsRightLeftIcon className={classNames(iconClass ?? "w-5 h-5")}/>
                        {translate("text.dispatch_history")}
                    </button>
                </div>
            )}

            <ModalDefault
                show={isDispatchModalOpen}
                title={translate('text.dispatch_history')}
                widthClass="max-w-xl"
                onClose={() => setIsDispatchModalOpen(false)}
                closeButtonLabel={translate("btn.close")}
            >
                <div className="p-6">
                    <ul className="bg-inverse relative">
                        <StopHistoryItem
                            title="Starting assets"
                            icon={<FlagIcon className="w-6 h-6 text-green-600"/>}
                            isLastInSection={false}
                            bgClass={"bg-inverse"}
                            driver={startingAssets.driver}
                            coDriver={startingAssets.coDriver}
                            truck={startingAssets.truck}
                            trailer={startingAssets.trailer}
                        />

                        {filteredStops.reduce((memo, stop, i) => {
                            const pickingTrailer = stop.StopType ? stops[stop.index]?.PickingTrailerID?.value?.label : stopBys[stop.index]?.PickingTrailerID?.value?.label;
                            const isDroppingTrailer = stop.StopType ? stops[stop.index]?.IsDroppingTrailer?.value : stopBys[stop.index]?.IsDroppingTrailer?.value

                            memo.push(<StopHistoryItem
                                isCompleted={stop.IsStopCompleted}
                                key={stop.key}
                                title={"Stop " + (i + 1) + "."}
                                icon={getCombinedStopIcon(stop)}
                                driver={getDriverStatus(stop)}
                                coDriver={getCoDriverStatus(stop)}
                                truck={getTruckStatus(stop)}
                                trailer={getTrailerStatus(stop)}
                                trailerPicked={getTrailerPickedStatus(stop)}
                                trailerDropped={getTrailerDroppedStatus(stop, droppedTrailer)}
                                isLastInSection={filteredStops.length - 1 === i}
                                isDropped={(!!stop?.metadata?.IsDropType)}
                            />)

                            if (isDroppingTrailer) {
                                droppedTrailer = null;
                            }

                            if (pickingTrailer) {
                                droppedTrailer = pickingTrailer;
                            }

                            return memo;
                        }, [])}
                    </ul>
                </div>
            </ModalDefault>
        </>
    )
}

