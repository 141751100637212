import {classNames, getProp} from "../../../../../common/util/util-helpers";
import Resources from "../../../../../data/services/resources";
import {
    ACCESSORIAL_NOTIFICATION,
    BILL_OF_LADING,
    CUSTOMER_AGREEMENT,
    DISPATCH_CONFIRMATION,
    LOAD_MANIFEST_TYPES,
    LOAD_SHEET, LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_READY
} from "../../../../../util/util-constants";
import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {createResource} from "../../../../../data/actions/resource";
import LocalStorage from "../../../../../util/localStorage";
import Env from "../../../../../util/env";
import {useDispatch} from "react-redux";
import {download} from "../../../../../data/actions/download";
import {currentDate} from "../../../../../common/util/util-dates";
import PopOver from "../../../../../common/components/popover";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import EmailDocumentDialog from "../../../../../common/components/modal/modal-email-documents/modal-email-document";
import {getJWT} from "../../../../../common/util/util-auth";

export default function SendLoadDocuments({initialLoadStatusID, isStateDirty, translate, loadInfo, hasCommodities, loadID, lineOfBusiness, thirdResource, clearPrintLoadCommodityID, hasDocumentSetting, printLoadCommodityID}) {
    const sentDocuments = {
        [BILL_OF_LADING]: getProp(loadInfo, 'BOLSent', false),
        [CUSTOMER_AGREEMENT]: getProp(loadInfo, 'TransportAgreementSent', false),
        [DISPATCH_CONFIRMATION]: getProp(loadInfo, 'ManifestSent', false)
    }

    const [loadManifestTypeID, setLoadManifestTypeID] = useState(0);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    const [documentSettingsFields, setDocumentSettingsFields] = useState({})

    const dispatch = useDispatch();

    const isBroker = getProp(loadInfo, 'IsBroker', false)

    const setSendFieldsForType = (LoadManifestTypeID, isEmailModalOpen) => {
        if (!isEmailModalOpen) {
            const newFields = LoadManifestTypeID == BILL_OF_LADING ? {
                ShowCharges: new Field("ShowCharges", 0, [''], false, 'button-group', {
                    data: {0: translate('btn.no'), 1: translate('btn.yes')},
                    addContainerClass: 'col-span-full',
                    labelType: 'left',
                    note: 'Charges will be visible on the document'
                }),
                BlindShip: new Field("BlindShip", 0, [''], false, 'button-group', {
                    data: {0: translate('btn.no'), 1: translate('btn.yes')},
                    addContainerClass: 'col-span-full',
                    labelType: 'left',
                    note: 'Identity and address of the shipper or consignee (or both) are not visible in the document'
                }),
            } : LoadManifestTypeID == DISPATCH_CONFIRMATION ? (isBroker ? {
                DigitalSign: new Field("DigitalSign", 1, [''], false, 'button-group', {
                    data: {0: translate('btn.no'), 1: translate('btn.yes')},
                    addContainerClass: 'col-span-full',
                    labelType: 'left',
                    note: 'Document will be sent for digital signing to the entered email addresses. Once signed confirmation email will be sent to both parties.'
                })
            } :{
                ShowCharges: new Field("ShowCharges", 0, [''], false, 'button-group', {
                    data: {0: translate('btn.no'), 1: translate('btn.yes')},
                    addContainerClass: 'col-span-full',
                    labelType: 'left',
                    label: "ShowRate",
                    note: 'Payment data will be shown on a document'
                }),
                DigitalSign: new Field("DigitalSign", isBroker ? 1 : 0, [''], false, 'button-group', {
                    data: {0: translate('btn.no'), 1: translate('btn.yes')},
                    addContainerClass: 'col-span-full',
                    labelType: 'left',
                    note: 'Document will be sent for digital signing to the entered email addresses. Once signed confirmation email will be sent to both parties.'
                })
            }) : null;
            setDocumentSettingsFields(newFields);
        } else {
            setDocumentSettingsFields({});
        }
    }

    const toggleSendManifestDialog = (LoadManifestTypeID) => {

        setLoadManifestTypeID(LoadManifestTypeID);
        if (isEmailModalOpen) {
            clearPrintLoadCommodityID();
        }
        setSendFieldsForType(LoadManifestTypeID, isEmailModalOpen);
        setIsEmailModalOpen(!isEmailModalOpen);
    }

    const getDefaultSubjectForEmail = () => {
        return "Load #" + getProp(loadInfo, 'LoadNumber') + " "
            + getProp(loadInfo, 'PickupCityName', '') + ", " + getProp(loadInfo, 'PickupState', '') + " " + getProp(loadInfo, 'PickupPostalCode', '') + " - "
            + getProp(loadInfo, 'DestinationCityName', '') + ", " + getProp(loadInfo, 'DestinationState', '') + " " + getProp(loadInfo, 'DestinationPostalCode', '')
    }

    const getDefaultEmailsForManifestType = (LoadManifestTypeID) => {
        switch (Number(LoadManifestTypeID)) {
            case BILL_OF_LADING:
                return getProp(loadInfo, 'DriverEmail')?.split(',').map(it => ({
                    label: it,
                    value: it
                })) ?? ''
            case CUSTOMER_AGREEMENT:
                return getProp(loadInfo, 'InvoiceSendEmails')?.split(',').map(it => ({
                    label: it,
                    value: it
                })) ?? ''
            case DISPATCH_CONFIRMATION:
                if (lineOfBusiness === 'brokerage') {
                    return getProp(loadInfo, 'CarrierPrimaryEmail')?.split(',').filter(it => !!it).map(it => ({
                        label: it,
                        value: it
                    })) ?? ''
                } else {
                    return getProp(loadInfo, 'DriverEmail')?.split(',').map(it => ({
                        label: it,
                        value: it
                    })) ?? ''
                }
            default:
                return ''
        }
    }

    const handleDownloadDocument = (markSent = false) => {
        const documentFilePrefix = getDocumentFilePrefix();

        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.LoadManifest,
            query: Object.assign(printLoadCommodityID ? {LoadCommodityID: printLoadCommodityID} : {}, {
                id: loadID,
                name: documentFilePrefix + loadID + '_' + currentDate() + '.pdf',
                LoadManifestTypeID: loadManifestTypeID,
                MarkAsSent: markSent ? 1 : 0,
                ShowCharges: documentSettingsFields?.ShowCharges?.value ? 1 : 0,
                BlindShip: documentSettingsFields?.BlindShip?.value ? 1 : 0
            }),
            errorMessage: true, successMessage: 'Successfully downloaded document!'
        }))
    }

    const handleDocumentSettingsInputChange = (name, value) => {
        let documentSettingsFieldsUpdate = documentSettingsFields
        documentSettingsFieldsUpdate = FieldsManager.updateField(documentSettingsFieldsUpdate, name, value);

        setDocumentSettingsFields(prevState => (Object.assign({}, prevState, documentSettingsFieldsUpdate)));
    }

    const getDocumentFilePrefix = () => {
        switch (Number(loadManifestTypeID)) {
            case LOAD_SHEET:
                return translate("file.prefix.load_sheet");
            case BILL_OF_LADING:
                return translate("file.prefix.bill_of_lading");
            case CUSTOMER_AGREEMENT:
                return translate("file.prefix.customer_agreement");
            case DISPATCH_CONFIRMATION:
                return translate("file.prefix.dispatch_confirmation");
            case ACCESSORIAL_NOTIFICATION:
                return translate("file.prefix.accesorial_notification");
            default:
                return translate("file.prefix.manifest")
        }
    }

    useEffect(() => {
        if (printLoadCommodityID) {
            setLoadManifestTypeID(BILL_OF_LADING);
            setSendFieldsForType(BILL_OF_LADING, isEmailModalOpen);
            setIsEmailModalOpen(!isEmailModalOpen);
        }
    }, [printLoadCommodityID])

    return (
        <React.Fragment>
            <PopOver
                btnClass={
                    classNames(
                        "btn btn-outline-secondary bg-inverse",
                        isStateDirty ? "cursor-default border-tm-gray-200 text-tm-gray-700 hover:bg-transparent opacity-75" : undefined
                    )
                }
                widthClass={'max-w-xs'}
                positionClass="absolute translate-x-0 right-0"
                btnLabel={translate('btn.send_documents')}
                tippy={isStateDirty ? translate('text.please_save_changes_first') : null}
            >
                <div className="bg-popup rounded-card">
                    {isStateDirty && (
                        <InfoBar addClass="rounded-b-none">
                            {translate("text.please_save_changes_before_sending_documents")}
                        </InfoBar>
                    )}

                    {Object.keys(LOAD_MANIFEST_TYPES)
                        .filter(it => {
                            if (it == BILL_OF_LADING) {
                                if (initialLoadStatusID < LOAD_STATUS_READY || !hasCommodities) {
                                    return false
                                }
                            }
                            if (it == CUSTOMER_AGREEMENT) {
                                if (initialLoadStatusID < LOAD_STATUS_READY || isBroker) {
                                    return false
                                }
                            }
                            if (it == DISPATCH_CONFIRMATION) {
                                if (initialLoadStatusID < LOAD_STATUS_READY) {
                                    return false
                                }
                            }
                            if (it == ACCESSORIAL_NOTIFICATION) {
                                return false
                            }
                            return true
                        })
                        .map((it, i) => {
                            let documentLabel;
                            if (Number(it) === DISPATCH_CONFIRMATION) {
                                documentLabel = lineOfBusiness === 'brokerage'
                                    ? "Rate confirmation"
                                    : "Dispatch confirmation";
                            } else {
                                documentLabel = LOAD_MANIFEST_TYPES[it];
                            }
                            return (<div
                                    key={i}
                                    className={
                                        classNames("px-5 py-2",
                                            !isStateDirty ? "text-tm-gray-900 cursor-pointer hover:bg-primary hover:text-primary-contrast whitespace-nowrap" : "text-tm-gray-400"
                                        )
                                    }
                                    onClick={() => isStateDirty ? null : toggleSendManifestDialog(it)}
                                >
                                        <span
                                            className={`inline-block text-center mr-2 uppercase`}
                                        >
                                            {translate(documentLabel)}
                                            {" "}
                                            {sentDocuments[it]
                                                ? <span>(Already Sent)</span>
                                                : undefined
                                            }
                                        </span>
                                </div>
                            )
                        })}
                </div>
            </PopOver>

            <EmailDocumentDialog
                show={isEmailModalOpen}
                hasDocumentSetting={hasDocumentSetting}
                title={
                    sentDocuments[loadManifestTypeID]
                        ? translate('modal_heading.resend_manifest')
                        : translate('modal_heading.send_manifest')
                }
                widthClass="max-w-5xl"
                onClose={toggleSendManifestDialog}
                hideDocumentFields={true}
                primaryBtnLabel={translate('btn.send')}
                fields={{
                    EmailTemplateID: new Field('EmailTemplateID', '', [''], false, 'select-search', {addContainerClass: 'md:col-span-6'}),
                    emails: new Field('emails', getDefaultEmailsForManifestType(loadManifestTypeID), ['empty'], false, 'creatable', {
                        addContainerClass: 'col-span-8',
                        onEnterDown: true
                    }, {
                        isMulti: true,
                        components: {DropdownIndicator: null},
                        menuIsOpen: false,
                        placeholder: 'Type email...',
                        addClass: "rounded-r-none form-control"
                    }),
                    AddContactID: new Field('AddContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4 -ml-px'}, {
                        placeholder: 'Filter contacts...',
                        addClass: "form-control rounded-l-none"
                    }),
                    subject: new Field('subject', getDefaultSubjectForEmail(loadManifestTypeID), ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
                    emailMessage: new Field('emailMessage', '', [], false, 'rich-text', {
                        addContainerClass: 'col-span-full'
                    }),
                }}
                onDocumentSettingsInputChange={handleDocumentSettingsInputChange}
                documentSettingsFields={documentSettingsFields}
                fetchEmailTemplateQuery={{
                    LoadID: loadID,
                    CarrierID: loadInfo.CarrierID,
                    DriverID: loadInfo.DriverID,
                    CustomerID: loadInfo.CustomerID

                }}
                metadata={{
                    AddContactID: {
                        api: 'api/' + Resources.ContactsQuick,
                        query: {offset: 0, sort: 'ASC', limit: 10},
                        searchMap: (item) => ({
                            value: item.Email,
                            label: item.FirstName + ' ' + item.LastName
                        })
                    },
                    EmailTemplateID: {
                        api: 'api/' + Resources.EmailTemplates,
                        query: {offset: 0, sort: 'ASC', limit: 10},
                        searchMap: (item) => ({
                            value: item.EmailTemplateID,
                            label: item.EmailTemplateName
                        })
                    }
                }}

                onSubmit={(params) => {
                    if (params) {
                        let emails = params.emails.filter(it => !it.invalid).reduce((memo, it) => {
                            memo += it.value + ','
                            return memo
                        }, '')

                        // Add document settings parameters
                        params = Object.assign(params, FieldsManager.getFieldKeyValues(documentSettingsFields));

                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign(params, {
                                Emails: emails.substring(0, emails.length - 1),
                                id: loadID,
                                LoadManifestTypeID: loadManifestTypeID
                            }),
                            resource: Resources.LoadManifest,
                            errorMessage: true,
                            successMessage: 'Document sent successfully!'
                        }))

                        toggleSendManifestDialog();
                    }
                }}

                onPrint={() => {
                    handleDownloadDocument()
                    toggleSendManifestDialog()
                }}

                customButtonsHTML={[BILL_OF_LADING, CUSTOMER_AGREEMENT, DISPATCH_CONFIRMATION].includes(Number(loadManifestTypeID)) ? (
                    <button
                        className={`btn btn-outline-secondary ml-2`}
                        onClick={() => {
                            handleDownloadDocument(true)
                            toggleSendManifestDialog()
                        }}>
                        <i className="iconsminds-printer"/>
                        <span className="d-none d-sm-inline-block ml-1">Print and Mark as Sent</span>
                    </button>
                ) : null}
                fileType={'pdf'}
                filePath={Env.getApiUrl('api/' + Resources.LoadManifest, Object.assign(printLoadCommodityID ? {LoadCommodityID: printLoadCommodityID} : {}, {
                    id: loadID,
                    token: getJWT().access_token,
                    LoadManifestTypeID: loadManifestTypeID,
                    ShowCharges: documentSettingsFields?.ShowCharges?.value ? 1 : 0,
                    BlindShip: documentSettingsFields?.BlindShip?.value ? 1 : 0
                }))}
                translate={translate}
                dispatch={dispatch}
                thirdResource={thirdResource}
            />
        </React.Fragment>

    )
}
