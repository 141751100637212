import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getResource, updateResource,} from '../../../data/actions/resource'
import {ApprovalStatusID, DEFAULT_CRUD_STATE} from "../../../common/util/util-consts";
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON, getProp,
    mergeDeep,
    saveTableColumns
} from "../../../common/util/util-helpers";
import LocalStorage from "../../../common/util/localStorage";
import {Field, FieldsManager} from "../../../data/services/fields";
import {cloneDeep} from "../../../common/util/util-vanilla";
import Resources from "../../../data/services/resources";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {showModal} from "../../../data/actions/ui";
import {HandThumbDownIcon, HandThumbUpIcon} from '@heroicons/react/24/outline'

class NewCandidatesView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Email',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            tableColumns: {},
            isColumnsDialogVisible: false,

            // Dialogs
            confirmDialog: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    handleUpdateStatus = (item, StatusID) => {
        this.setState({text: `Are you sure you want to set status to be ${ApprovalStatusID[StatusID]}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({
                            CandidateApplicationID: item.CandidateApplicationID,
                            StatusID: StatusID
                        }),
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource(),
                        errorMessage: true, successMessage: `Status set to '${ApprovalStatusID[StatusID]}'`
                    }))
                })
            }, item)
        })
    }
    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            limit: new Field('limit', 10, [''], false, 'select', {
                hideLabel: true
            }, {menuPlacement: "top"})
        }
    }

    getFields = () => {
        return {
            Email: new Field('Email', '', ['empty']),
            FirstName: new Field('FirstName', '', ['empty'], false, ''),
            LastName: new Field('LastName', '', ['empty'], false, ''),
            Note: new Field('Note', '', ['empty']),
            Position: new Field('Position', '', ['empty']),
            StatusID: new Field('StatusID', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <>{ApprovalStatusID[item.StatusID]}</>
                )
            }),
            UpdatedByName: new Field('UpdatedByName', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <div
                        className={item.StatusID !== 1 && 'text-primary cursor-pointer font-semibold'}
                        onClick={() => item.StatusID !== 1 && this.props.dispatch(showModal('ViewContactCard', {ContactID: item.UpdatedByContactID}))}
                    >{item.StatusID !== 1 && item.UpdatedByName}</div>
                )
            }),
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.NewCandidates
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.new-candidates')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        buttonHidden={true}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                        <div className="ml-auto">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            actions={[
                                {
                                    action: (it) => this.handleUpdateStatus(it, 2),
                                    icon: HandThumbUpIcon, // make this a function
                                    visible: (it) => (it.StatusID === 1 || it.StatusID === 3),
                                    label: false, // make this a function
                                    title: translate('btn.accept'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: (it) => this.handleUpdateStatus(it, 3),
                                    icon: HandThumbDownIcon, // make this a function
                                    visible: (it) => (it.StatusID === 1 || it.StatusID === 2),
                                    label: false, // make this a function
                                    title: translate('btn.reject'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                }
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    hideRowsPerPage={this.state.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                            className={"pb-12 pt-16 px-6"}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.setTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(NewCandidatesView)
