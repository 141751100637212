import React, {useEffect, useState} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import moment from 'moment-timezone'
import {
    BookDateDefaultInvoice,
    BookDateDefaultPickup,
    DEFAULT_DATABASE_DATETIME_FORMAT
} from '../../../../util/util-constants'
import {CurrencyDollarIcon, TruckIcon} from '@heroicons/react/20/solid'
import {InformationCircleIcon} from '@heroicons/react/24/outline'
import {formatMoney} from "../../../../common/util/util-formaters"
import {classNames, openInNewTab} from '../../../../common/util/util-helpers'
import {cloneDeep, genericMoneyFormatter} from '../../../../common/util/util-vanilla'
import {createResource} from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {useDispatch, useSelector} from 'react-redux'
import FilePreviewModal from "../../../../common/components/dropzone/file-preview-modal";
import OrganizationInfoDialog from "../../../../common/components/modal/modal-global/organization-info-dialog";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import DropZoneAlt from "../../../../common/components/dropzone/drop-zone-alt";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import Tooltip from "../../../../common/components/tooltip";
import {LoaderLarge} from "../../../../common/components/loader";
import ButtonsGroup from "../../../../common/components/buttons/buttons-group";
import FileList from "../../../../common/components/dropzone/file-list";

export default function CarrierPayDialog({
                                             translate,
                                             selectedItems,
                                             onAcceptFiles,
                                             files,
                                             onRemoveFile,
                                             onClose,
                                             query
                                         }) {
    const dispatch = useDispatch();

    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading;

    const [fields, setFields] = useState({});
    const [groupBy, setGroupBy] = useState('none');
    const [payItems, setPayItems] = useState([]);
    const [draggedOver, setDraggedOver] = useState('');
    const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
    const [fileToPreview, setFileToPreview] = useState('')
    const [selectedOrganization, setSelectedOrganization] = useState({})
    const [isOrganizationInfoDialogOpen, setIsOrganizationInfoDialogOpen] = useState(false);

    const returnValidDate = (date) => {
        const validationDate = moment(date)
        return (date && validationDate.isValid()) ? date : ''
    }

    const getBillFields = (item) => {
        return {
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'col-span-3'}),
            BookDate: new Field('BookDate', item?.BookDateDefault === BookDateDefaultInvoice ? moment().format(DEFAULT_DATABASE_DATETIME_FORMAT) :
                item?.BookDateDefault === BookDateDefaultPickup ? returnValidDate(item?.PickupDate) :
                    returnValidDate(item?.DestinationDate), ['empty'], false, 'date', {
                addContainerClass: 'col-span-3'
            }),
            DueDate: new Field('DueDate', moment().add(item?.CarrierNetTermDays ? item?.CarrierNetTermDays : item?.CarrierNetTermDays ? item?.CarrierNetTermDays : 15, 'days').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'col-span-3'}),
            BillRef: new Field('BillRef', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'})
        }
    }

    const handleSubmitClick = () => {
        let hasErrors = false

        const validFields = payItems.map(it => it.key).reduce((memo, it) => {
            let fieldsUpdate = fields[it]
            fieldsUpdate = FieldsManager.validateFields(fieldsUpdate)

            if (!FieldsManager.checkFieldsForErrors(fieldsUpdate)) {
                hasErrors = true
            }

            memo[it] = fieldsUpdate
            return memo
        }, {})

        if (hasErrors) {
            setFields(validFields)
        } else {
            const params = payItems.reduce((memo, it) => {
                memo.push({
                    Bills: it.Bills,
                    BillRef: fields[it.key]?.BillRef?.value,
                    Date: fields[it.key]?.Date?.value,
                    DueDate: fields[it.key]?.DueDate?.value,
                    BookDate: fields[it.key]?.BookDate?.value,
                })

                return memo
            }, [])

            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: {Items: params},
                query: query,
                errorMessage: true,
                successMessage: translate('message.bill_created'),
                resource: Resources.CarrierPayroll,
                piggyResource: Resources.CarrierPayroll
            }))
        }
    }

    const handleInputChange = (name, value, CarrierPayID) => {
        let fieldsUpdate = cloneDeep(fields)
        fieldsUpdate[CarrierPayID] = FieldsManager.updateField(fieldsUpdate[CarrierPayID], name, value)
        fieldsUpdate[CarrierPayID][name].errorMessage = ''
        setFields(fieldsUpdate)
    }

    const isBulkDisplay = selectedItems.length > 1

    const handleFileViewClick = (file) => {
        setFileToPreview(file[0]);
        setIsFilePreviewModalOpen(true)
    }

    const handleViewOrganizationInfo = (it) => {
        setSelectedOrganization(it)
        setIsOrganizationInfoDialogOpen(true)
    }

    useEffect(() => {
        let fieldsNew = {}
        if (groupBy === 'carrier') {
            const items = selectedItems.reduce((memo, it) => {
                if (!memo[it.CarrierID]) {
                    memo[it.CarrierID] = {
                        Amounts: [],
                        Bills: [],
                        TotalAmount: 0,
                        Loads: [],
                        key: it.CarrierPayID,
                        name: it.Carrier,
                    }
                }
                memo[it.CarrierID].Amounts.push(it.TotalAmount ? it.TotalAmount : 0);
                memo[it.CarrierID].TotalAmount = memo[it.CarrierID].Amounts.reduce((m, i) => m + i, 0)
                memo[it.CarrierID].Bills.push(it.CarrierPayID)
                memo[it.CarrierID].Loads.push(it)

                if (!fieldsNew[it.CarrierPayID]) {
                    fieldsNew[it.CarrierPayID] = (getBillFields(it))
                }

                return memo
            }, {})

            setPayItems(Object.values(items))
        } else if (groupBy === 'load') {
            const items = selectedItems.reduce((memo, it) => {
                const key = it.CarrierID + '-' + it.LoadID
                if (!memo[key]) {
                    memo[key] = {
                        Amounts: [],
                        Bills: [],
                        TotalAmount: 0,
                        Loads: [],
                        key: it.CarrierPayID,
                        name: it.Carrier,
                    }
                }
                memo[key].Amounts.push(it.TotalAmount ? it.TotalAmount : 0)
                memo[key].TotalAmount = memo[key].Amounts.reduce((m, i) => m + i, 0)
                memo[key].Bills.push(it.CarrierPayID)
                memo[key].Loads.push(it)

                if (!fieldsNew[it.CarrierPayID]) {
                    fieldsNew[it.CarrierPayID] = (getBillFields(it))
                }

                return memo
            }, {})

            setPayItems(Object.values(items))
        } else {
            const items = selectedItems.reduce((memo, it) => {
                if (!memo[it.CarrierPayID]) {
                    memo[it.CarrierPayID] = {
                        Amounts: [],
                        Bills: [],
                        TotalAmount: 0,
                        Loads: [],
                        key: it.CarrierPayID,
                        name: it.Carrier
                    }
                }
                memo[it.CarrierPayID].Amounts.push(it.TotalAmount ? it.TotalAmount : 0)
                memo[it.CarrierPayID].TotalAmount = it.TotalAmount ? it.TotalAmount : 0;
                memo[it.CarrierPayID].Bills.push(it.CarrierPayID)
                memo[it.CarrierPayID].Loads.push(it)

                if (!fieldsNew[it.CarrierPayID]) {
                    fieldsNew[it.CarrierPayID] = (getBillFields(it))
                }

                return memo
            }, {})

            setPayItems(Object.values(items))
            setFields(fieldsNew)
        }
    }, [selectedItems, groupBy])


    return (
        <React.Fragment>
            <div className="relative">
                {isLoading && (
                    <LoaderLarge stripesBg={true}/>
                )}

                <div
                    className="p-6 space-y-4"
                >
                    {isBulkDisplay && (
                        <div className="grid grid-cols-3 gap-1 text-sm font-semibold text-tm-gray-700 sm:mt-px sm:pt-2">
                            <div className="flex items-center">Group by:</div>
                            <ButtonsGroup
                                addClass={'mx-auto'}
                                disabled={false}
                                data={{
                                    'none': translate('btn.none'),
                                    'carrier': translate('btn.carrier'),
                                    'load': translate('btn.load')
                                }}
                                value={groupBy}
                                onChange={(name, value) => setGroupBy(value)}
                            />
                        </div>
                    )}

                    {payItems.map(it => {
                        return (
                            <div
                                key={it.key}
                                className={
                                    classNames(
                                        'bg-popup border border-tm-gray-200 rounded-card relative',
                                        draggedOver === it.key ? 'pb-7' : undefined
                                    )
                                }
                                onDragEnter={() => setDraggedOver(it.key)}
                            >
                                <div
                                    className={
                                        classNames(
                                            'py-2 pt-3 px-6 flex',
                                            it.Loads.length < 2 ? 'items-center' : undefined
                                        )
                                    }
                                >
                                    <p className="text-tm-700 text-base leading-9">
                                        <div className="flex">
                                            {it.name}
                                            <button
                                                className={"ml-1"}
                                                onClick={() => handleViewOrganizationInfo(it)}>
                                                <InformationCircleIcon className={"w-5 h-5 text-blue-500"}/>
                                            </button>
                                        </div>


                                        {groupBy !== 'none' && (
                                            <span
                                                className="ml-2 inline-flex w-6 h-6 items-center whitespace-nowrap rounded-full bg-tm-gray-50 px-2 py-2 text-sm font-medium text-tm-gray-500">
                                            {it.Loads.length}
                                        </span>
                                        )}
                                    </p>

                                    <div
                                        className="ml-auto space-x-4 text-right"
                                    >
                                        {groupBy !== 'load' && it.Loads.length < 2 && (
                                            <Tooltip content={translate('text.load_number')}>
                                                <button
                                                    onClick={() => openInNewTab('/loads/info/' + it.Loads[0].LoadID)}
                                                    className="relative inline-flex items-center whitespace-nowrap rounded-full bg-tm-gray-50 px-2 py-2 text-sm font-medium text-tm-gray-500 hover:bg-tm-gray-100 sm:px-3"
                                                    aria-haspopup="listbox" aria-expanded="true"
                                                    aria-labelledby="listbox-label"
                                                >
                                                    <TruckIcon
                                                        className="h-5 w-5 flex-shrink-0 text-tm-gray-400 sm:-ml-1"/>

                                                    <span
                                                        className="hidden truncate sm:ml-2 sm:block"
                                                    >
                                                    # {it.Loads[0].LoadNumber}
                                                </span>
                                                </button>
                                            </Tooltip>
                                        )}

                                        <Tooltip
                                            disabled={groupBy === 'none'}
                                            content={
                                                <div className="grid grid-cols-2 gap-1">
                                                    {it.Amounts.map((it, index) => {
                                                        return (
                                                            <React.Fragment
                                                                key={it.CarrierPayID}
                                                            >
                                                                <div>{index + 1}.</div>
                                                                <div
                                                                    className="text-right">{genericMoneyFormatter(it)}</div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        >
                                            <button
                                                disabled={groupBy === 'none'}
                                                className="relative inline-flex items-center whitespace-nowrap rounded-full bg-tm-gray-50 px-2 py-2 text-sm font-medium text-tm-gray-500 hover:bg-tm-gray-100 sm:px-3"
                                                aria-haspopup="listbox" aria-expanded="true"
                                                aria-labelledby="listbox-label"
                                            >
                                                <CurrencyDollarIcon
                                                    className="h-5 w-5 flex-shrink-0 text-tm-gray-400 sm:-ml-1"/>

                                                <span
                                                    className="hidden truncate sm:ml-2 sm:block"
                                                >
                                                {formatMoney(it.TotalAmount)}
                                            </span>
                                            </button>
                                        </Tooltip>

                                        {groupBy !== 'load' && it.Loads.length > 1 && (
                                            <div className="flex justify-end gap-x-2 mt-2">
                                                {it.Loads.map(it =>
                                                    <Tooltip
                                                        key={it.CarrierPayID}
                                                        content={translate('text.load_number')}
                                                    >
                                                        <button
                                                            onClick={() => openInNewTab('/loads/info/' + it.LoadID)}
                                                            className="relative inline-flex items-center whitespace-nowrap rounded-full bg-tm-gray-50 px-2 py-2 text-sm font-medium text-tm-gray-500 hover:bg-tm-gray-100 sm:px-3"
                                                            aria-haspopup="listbox" aria-expanded="true"
                                                            aria-labelledby="listbox-label"
                                                        >
                                                            <TruckIcon
                                                                className="h-5 w-5 flex-shrink-0 text-tm-gray-400 sm:-ml-1"
                                                            />

                                                            <span
                                                                className="hidden truncate sm:ml-2 sm:block"># {it.LoadNumber}</span>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        )}

                                        {groupBy === 'load' && (
                                            <Tooltip content={translate('text.load_number')}>
                                                <button
                                                    onClick={() => openInNewTab('/loads/info/' + it.Loads[0].LoadID)}
                                                    className="relative inline-flex items-center whitespace-nowrap rounded-full bg-tm-gray-50 px-2 py-2 text-sm font-medium text-tm-gray-500 hover:bg-tm-gray-100 sm:px-3"
                                                    aria-haspopup="listbox" aria-expanded="true"
                                                    aria-labelledby="listbox-label"
                                                >
                                                    <TruckIcon
                                                        className="h-5 w-5 flex-shrink-0 text-tm-gray-400 sm:-ml-1"/>

                                                    <span
                                                        className="hidden truncate sm:ml-2 sm:block"
                                                    >
                                                    # {it.Loads[0].LoadNumber}
                                                </span>
                                                </button>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-4 px-6 pt-3 pb-6">
                                    <FieldsToHtml
                                        fieldsState={fields[it.key]}
                                        onInputChange={(name, value) => handleInputChange(name, value, it.key)}
                                        translate={translate}
                                    />
                                </div>

                                {!files[it.key] && (
                                    <DropZoneAlt
                                        key={it.key}
                                        className={
                                            classNames(
                                                'w-full pb-7 px-6',
                                                draggedOver === it.key ? 'z-10 absolute inset-0' : 'relative z-0'
                                            )
                                        }
                                        maxFilesAccepted={1}
                                        accept={'application/pdf, image/jpg,image/jpeg, image/png'}
                                        translate={translate}
                                        onAcceptFiles={(acceptedFiles) => {
                                            if (acceptedFiles.length) {
                                                onAcceptFiles(acceptedFiles, it.key)
                                            }
                                        }}
                                        content={(isDragAccept, isDragReject) => {
                                            return (
                                                <React.Fragment>
                                                    <div
                                                        onDragLeave={() => setDraggedOver(existingValues => {
                                                            if (existingValues === it.key) {
                                                                return false
                                                            }

                                                            return existingValues
                                                        })}
                                                        className={
                                                            classNames(
                                                                isDragAccept && draggedOver === it.key ? 'border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10' : undefined,
                                                                isDragReject && draggedOver === it.key ? ' border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10' : undefined,
                                                                !isDragAccept && !isDragReject ? 'border-transparent' : undefined,
                                                                'pb-4 w-full h-full absolute rounded-card top-0 left-0'
                                                            )}>

                                                    </div>

                                                    <div
                                                        className={
                                                            classNames(
                                                                'absolute bottom-0 px-6 left-0 right-0 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent',
                                                                draggedOver === it.key ? '-z-10' : undefined
                                                            )}
                                                    >
                                                        Attach a pdf file by dragging and dropping or <span
                                                        className="text-primary-tint font-semibold">click here</span> to
                                                        select it.
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}

                                {!!files[it.key] && (
                                    <div className="px-6 mb-6">
                                        <FileList
                                            files={files[it.key]}
                                            onFileDelete={() => onRemoveFile(it.key)}
                                            onFileView={() => handleFileViewClick(files[it.key])}
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>

                <ModalFooter
                    buttonLabel={translate('btn.save')}
                    onButtonClick={handleSubmitClick}
                    buttonDisabled={isLoading}
                    closeButtonLabel={translate('btn.cancel')}
                    onClose={onClose}
                />
            </div>

            {(isOrganizationInfoDialogOpen &&
                <OrganizationInfoDialog
                    show={isOrganizationInfoDialogOpen}
                    translate={translate}
                    organizationID={selectedOrganization?.Loads[0]?.CarrierOrganizationID}
                    handleClose={() => setIsOrganizationInfoDialogOpen(false)}
                />
            )}

            <FilePreviewModal
                show={isFilePreviewModalOpen}
                onClose={() => setIsFilePreviewModalOpen(false)}
                file={fileToPreview}
                translate={translate}
            />
        </React.Fragment>
    )
}

