import {createResource, deleteResource, getResource, updateResource} from "../actions/resource";

export const getDataSelect = ({dispatch, user}) => ({
                                                        query,
                                                        resource,
                                                        piggyResource,
                                                        successMessage,
                                                        errorMessage = true
                                                    }) => {
    dispatch(getResource({
        user: user,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        successMessage: successMessage,
        errorMessage: errorMessage
    }))
};

export const createDataSelect = ({dispatch, user}) => ({
                                                           params,
                                                           query,
                                                           resource,
                                                           piggyResource,
                                                           successMessage,
                                                           errorMessage = true,
                                                           onResultCallback
                                                       }) => {
    dispatch(createResource({
        user: user,
        params: params,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        successMessage: successMessage,
        errorMessage: errorMessage,
        onResultCallback: onResultCallback,
    }))
};

export const updateDataSelect = ({dispatch, user}) => ({
                                                           params,
                                                           query,
                                                           resource,
                                                           piggyResource,
                                                           secondPiggyResource,
                                                           successMessage,
                                                           errorMessage = true
                                                       }) => {
    dispatch(updateResource({
        user: user,
        params: params,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        secondPiggyResource: secondPiggyResource,
        successMessage: successMessage,
        errorMessage: errorMessage
    }))
};

export const deleteDataSelect = ({dispatch, user}) => ({
                                                           query,
                                                           piggyQuery,
                                                           resource,
                                                           piggyResource,
                                                           successMessage,
                                                           errorMessage = true
                                                       }) => {
    dispatch(deleteResource({
        user: user,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        piggyQuery: piggyQuery ?? query,
        successMessage: successMessage,
        errorMessage: errorMessage
    }))
}
