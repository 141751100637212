import React from "react";
import {classNames} from "../../util/util-helpers";

export default function Badge(props) {
    const {children, type, className, addClass, textClass, paddingClass, onClick} = props;

    const DynamicHtmlTag = onClick ? "button" : "div"

    const getBadgeClass = (type = null) => {
        switch (type) {
            case "outline":
                return "bg-transparent text-tm-gray-900 border border-tm-gray-200"
            case "success":
                return "bg-green-600 text-white"
            case "danger":
                return "bg-red-600 text-white"
            case "warning":
                return "bg-yellow-600 text-white"
            case "primary":
                return "bg-indigo-600 text-white"
            case "disabled":
                return "bg-tm-gray-300 text-tm-gray-900"
            case "custom":
                return ""
            default:
                return "bg-tm-gray-100 text-tm-gray-900"
        }
    }

    return (
        <DynamicHtmlTag
            className={classNames(
                className ?? "inline-flex text-center px-3 py-0.5 rounded-btn text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary",
                getBadgeClass(type),
                addClass,
                paddingClass ? paddingClass : "px-3 py-0.5",
                textClass ? textClass : "text-sm"
            )}
            onClick={onClick}
        >
            {children}
        </DynamicHtmlTag>
    );
}
