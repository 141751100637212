import React, { useState } from 'react';
import { Field, FieldsManager } from '../../../../data/services/fields';
import ModalFooter from '../../modal/modal-footer';
import FieldsToHtml from "../../fields/fields-to-html";

export default function EditColumnLabelDialog({translate, options, selectedItem, onClose, saveTableOptions}) {
    // TODO: Add default label value from Field props
    const [fields, setFields] = useState(getFields());
    const [isStateDirty, setIsStateDirty] = useState(false);

    function handleInputChange(name, value) {
        let fieldsClone = Object.assign({}, fields);
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);
        setFields(fieldsClone);
        setIsStateDirty(true);
    }

    function getFields() {
        return {
            ColumnLabel: new Field('ColumnLabel', options.columns[selectedItem.key].label, ['empty']),
        }
    }

    function updateLabel(e) {
        if (e) {
            e.preventDefault();
        }

        options.columns[selectedItem.key].label = !fields.ColumnLabel.value 
            ? translate("field." + selectedItem.key) 
            : fields.ColumnLabel.value;
        saveTableOptions(Object.assign({}, options));
        onClose();
    }

    function resetToDefault() {
        options.columns[selectedItem.key].label = translate("field." + selectedItem.key); // TODO: Add default label value from Field props

        saveTableOptions(Object.assign({}, options));
        onClose();
    }

    return (
        <React.Fragment>
            <form onSubmit={updateLabel} className="p-5">
                <FieldsToHtml
                    fieldsState={fields}
                    onInputChange={handleInputChange}
                    translate={translate}
                />
            </form>

            <div className="w-full">
                <ModalFooter
                    closeButtonLabel={translate('btn.close')}
                    onClose={onClose}

                    buttonLabel={translate('btn.updateLabel')}
                    buttonDisabled={!isStateDirty}
                    onButtonClick={updateLabel}

                    secondaryButtonLabel={"Reset to default"}
                    onSecondaryButtonClick={resetToDefault}
                />
            </div>
        </React.Fragment>
    )
}


