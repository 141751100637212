import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from "../../../util/localStorage";
import {scrollErrorIntoViewFields} from "../../../common/util/util-vanilla";
import {checkPerm, getProp} from '../../../common/util/util-helpers';
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import Resources from "../../../data/services/resources";
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    UPDATE_PERM
} from "../../../common/util/util-consts";
import Card from "../../../common/components/card";
import {LoaderLarge} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";


export default class InfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields()
            })
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        this.props.canSwapTabsHandler(true);
                        const vals = FieldsManager.getFieldKeyValues(this.state.fields);
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, vals, this.getQuery()),
                            query: this.getQuery(),
                            errorMessage: true, successMessage: `Claim updated`,
                            resource: this.getResource(),
                            piggyResource: this.getResource()
                        }))
                    })
                } else {
                    scrollErrorIntoViewFields([this.state.fields])
                }
            })
        }
    }

    /** UI events
     ================================================================= */
    handleInputChange = (name, value) => {
        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        let fields = FieldsManager.updateField(this.state.fields, name, value)

        if (name === 'ClaimClosed') {
            fields["DateClosed"].disabled = !value
            if (!value) fields["DateClosed"].value = ''
        }
        if (name === 'Approved' && !!value) {
            fields['Denied'].value = false
        }
        if (name === 'Denied' && !!value) {
            fields['Approved'].value = false
        }
        if (name === "LoadID") {
            if (value?.metadata) {
                fields = FieldsManager.updateField(fields, "DriverID", {
                    label: value?.metadata?.Driver,
                    value: value?.metadata?.DriverID,
                })
                fields = FieldsManager.updateField(fields, "TruckID", {
                    label: value?.metadata?.Truck,
                    value: value?.metadata?.TruckID,
                })
                fields = FieldsManager.updateField(fields, "TrailerID", {
                    label: value?.metadata?.Trailer,
                    value: value?.metadata?.TrailerID,
                })
                fields = FieldsManager.updateField(fields, "CustomerID", {
                    label: value?.metadata?.Customer,
                    value: value?.metadata?.CustomerID,
                })
            }

            fields['ChargeDriver'].metadata.label = value?.metadata?.IsBroker ? 'ChargeCarrier' : 'ChargeDriver';
        }
        if (name === "ChargeDriver") {
            fields.Amount.disabled = !value;
        }

        this.setState({
            canSubmit: true,
            fields: fields
        })
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields()
            }, () => this.props.canSwapTabsHandler(true))
        }
    }

    /** Fields/Data definitions
     ================================================================= */
    getFields = () => {
        let data = getProp(this.props, 'resource.data', {})

        let fieldTemplates = {
            // Claim Info
            LoadID: new Field('LoadID', '', [], false, 'select-search', {}, {isClearable: true}),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search'),
            ClaimNumber: new Field('ClaimNumber', '', ['empty']),
            ClaimCode: new Field('ClaimCode', '', []),
            ClaimOpened: new Field('ClaimOpened', '', [], false, 'date', {}, {}),
            ClaimDate: new Field('ClaimDate', '', [], false, 'date', {}, {}),

            DriverID: new Field('DriverID', '', [], false, 'select-search', {}, {isClearable: true}),
            TruckID: new Field('TruckID', '', [], false, 'select-search', {}, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search', {}, {isClearable: true}),
            InsuranceID: new Field('InsuranceID', '', [], false, 'select-search', {}, {isClearable: true}),

            // Claim Details
            Lost: new Field('Lost', '', ['float_or_empty'], false, 'float'),
            Damaged: new Field('Damaged', '', ['float_or_empty'], false, 'float'),
            ClaimAmount: new Field('ClaimAmount', '', ['float_or_empty'], false, 'float'),
            ContactName: new Field('ContactName', '', []),
            ClaimDescription: new Field('ClaimDescription', '', [], false, 'textarea', {
                addContainerClass: "col-span-full"
            }),

            // Claim Disposition
            ClaimClosed: new Field('ClaimClosed', '', [], false, 'checkbox'),
            DateClosed: new Field('DateClosed', '', [], !data.ClaimClosed, 'date', {}, {}),
            Approved: new Field('Approved', '', [], false, 'checkbox'),
            Denied: new Field('Denied', '', [], false, 'checkbox'),
            SettledAmount: new Field('SettledAmount', '', ['float_or_empty'], false, 'float', {
                addContainerClass: "col-span-2"
            }),
            ChargeDriver: new Field('ChargeDriver', '', [], false, 'checkbox', {}),
            Amount: new Field('Amount', '', ['float_or_empty'], false, 'float'),
            FinalNotes: new Field('FinalNotes', '', [], false, 'textarea', {
                addContainerClass: "col-span-full"
            }),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, data)

        if (Object.keys(data).length) {
            fieldTemplates['ChargeDriver'].metadata.label = data?.IsBroker ? 'ChargeCarrier' : 'ChargeDriver';
        }

        return fieldTemplates
    }

    getFieldTypes = () => {
        return Object.values(this.getFields()).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    /** Query definitions
     ================================================================= */
    getQuery = () => ({
        id: this.getID(),
    })

    getID = () => {
        return this.props.match.params.id
    }

    getResource = () => {
        return Resources.ClaimsInfo
    }

    /** Helpers
     ================================================================= */
    getFieldsToHtmlForIncludedFields = (arr = []) => {
        const fields = this.state.fields
        const {ClaimDate, DateClosed, ClaimOpened} = FieldsManager.getFieldKeyValues(fields)

        fields['ClaimOpened'].props.maxDate = (ClaimDate && !DateClosed) ? ClaimDate : (!ClaimDate && DateClosed) ? DateClosed : (ClaimDate > DateClosed) ? DateClosed : ClaimDate
        fields['ClaimDate'].props.minDate = ClaimOpened
        fields['DateClosed'].props.minDate = ClaimOpened

        const selects = {
            InsuranceID: {
                api: 'api/' + Resources.Insurance,
                query: {},
                searchMap: (item) => ({
                    value: item.InsuranceID,
                    label: item.InsuranceName
                })
            },
            DriverID: {
                api: 'api/' + Resources.DriversQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.DriverID,
                    label: item.FirstName + ' ' + item.LastName
                })
            },
            TruckID: {
                api: 'api/' + Resources.TrucksQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.SCAC + ' ' + item.TruckNumber,
                    value: item.TruckID,
                    metadata: item
                })
            },
            TrailerID: {
                api: 'api/' + Resources.TrailersQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.SCAC + ' ' + item.TrailerNumber,
                    value: item.TrailerID,
                    metadata: item
                })
            },
            LoadID: {
                api: 'api/' + Resources.LoadsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LoadNumber,
                    value: item.LoadID,
                    metadata: item
                })
            },
            CustomerID: {
                api: 'api/' + Resources.CustomersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName,
                    value: item.CustomerID,
                })
            },
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(fields, arr)))), this.props.translate, this.handleInputChange, selects)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const fieldsHtmlClaimInfo = this.getFieldsToHtmlForIncludedFields(['LoadID', 'ClaimNumber', 'ClaimCode', 'ClaimOpened', 'ClaimDate', 'CustomerID', 'DriverID', 'TruckID', 'TrailerID', 'InsuranceID', 'Lost', 'Damaged', 'ClaimAmount', 'ContactName', 'ClaimDescription'])
        const fieldsHtmlClaimDisposition = this.getFieldsToHtmlForIncludedFields(['ClaimClosed', 'DateClosed', 'Approved', 'Denied', 'SettledAmount', 'ChargeDriver', 'Amount', 'FinalNotes'])

        return (
            <React.Fragment>
                <div>
                    <div className="mx-auto max-w-3xl space-y-8 mb-20">
                        <Card addClass={'py-2 relative'}>
                            <h5 className="text-tm-gray-700 text-base">Claim Info</h5>

                            <div className="grid grid-cols-2 gap-5">
                                {fieldsHtmlClaimInfo}
                            </div>

                            {resource.isLoading && (
                                <LoaderLarge stripesBg/>
                            )}
                        </Card>

                        <Card addClass={'py-2 relative'}>
                            <h5 className="mb-4 text-tm-gray-700 text-base">Claim Disposition</h5>
                            <div className="grid grid-cols-4 gap-5">
                                {fieldsHtmlClaimDisposition}
                            </div>

                            {resource.isLoading && (
                                <LoaderLarge stripesBg/>
                            )}
                        </Card>
                    </div>
                </div>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.canSubmit}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(Resources.Claims, UPDATE_PERM) && this.onSubmit}
                />
            </React.Fragment>
        )
    }
}
