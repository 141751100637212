import React, {useEffect, useState} from "react";
import {checkPerm, getDefaultTableOptions} from '../../../common/util/util-helpers';
import {Field, FieldsManager} from "../../../data/services/fields";
import {currentDate} from "../../../common/util/util-dates";
import useQuery from "../../../hooks/use-query";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../data/actions/secondResource";
import LocalStorage from "../../../util/localStorage";
import {useDispatch, useSelector} from "react-redux";
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from "../../../util/util-constants";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableFooter from "../../../common/components/resource-table/table/table-footer";
import {getUser} from "../../../common/util/util-auth";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
export default function AccidentNotesTab({tabResource, id, translate}) {

    /** Const
     ================================================================= */
    const pagePath = tabResource;
    const tablePageDefaults = {
        behaviour: {
            canAdjustWidth: false,
            hasMenu: false
        },
        columns: {
            Date: {
                minWidth: 200
            },
            UpdatedByContact: {
                minWidth: 300
            }
        },
        style: {
            condensed: true,
            verticalLines: true,
            isGPUAccelerated: true
        }
    }

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const secondResource = useSelector((state) => state.secondResource);
    const isLoading = !!secondResource?.isLoading ?? true;
    const data = secondResource?.data?.list ?? [];
    const count = secondResource?.data?.count ?? 0;

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getResource());
    const [isNoteFormDialogOpen, setIsNoteFormDialogOpen] = useState(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate));

    /** Data events
     ================================================================= */
    function fetchData() {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: getResource(),
            query: getQuery(),
        }))
    }

    function deleteDataItem() {
        dispatch(deleteSecondResource({
            user: LocalStorage.get('user'),
            resource: getResource(),
            query: Object.assign(
                getQuery(),
                {
                    AccidentNotesID: selectedItem.AccidentNotesID
                }
            ),
            secondPiggyResource: getResource(),
            errorMessage: true,
            successMessage: translate("message.accident_note_deleted"),
        }));

        setIsConfirmDeleteDialogOpen(false);
    }

    /** UI events
     ================================================================= */
    function handleQueryInputChange(name, value) {
        let queryFieldsClone = Object.assign({}, queryFields);

        queryFieldsClone = FieldsManager.updateField(queryFieldsClone, name, value);

        setQueryFields(queryFieldsClone);
    }

    function handleCreateResourceClick() {
        setIsNoteFormDialogOpen(true);
        setSelectedItem({});
    }

    function handleUpdateResourceClick(item) {
        setIsNoteFormDialogOpen(true);
        setSelectedItem(item);
    }

    function handleDeleteResourceClick(item) {
        setIsConfirmDeleteDialogOpen(true);
        setSelectedItem(item);
    }

    /** Helpers
     ================================================================= */
    function getFields(item = {}) {
        const fieldTemplates = {
            Date: new Field('Date', currentDate(), ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
            UpdatedByContact: new Field('UpdatedByContact', '', [], false, 'text', {hideDialog: true}),
            Notes: new Field('Notes', '', ['empty'], false, 'textarea', {
                addContainerClass: "col-span-full",
                render: (it) => {
                    return <div className="truncate">{it.Notes}</div>
                }
            }, {rows: 20}),
            UpdatedByContactID: new Field('UpdatedByContactID', getUser('Contact.ContactID'), [], false, 'hidden', {
                hideDialog: true,
                hideTable: true
            }),

            AccidentID: new Field('AccidentID', id, [], false, 'hidden', {hideDialog: true, hideTable: true}),
            AccidentNotesID: new Field('AccidentNotesID', '', [], false, 'hidden', {hideDialog: true, hideTable: true})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}, {isClearable: true}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}, {isClearable: true}),
            sort: new Field('sort', '', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', '', [''], false, 'text', {}, {}),
            offset: new Field('offset', "", [''], false, 'text', {}, {}),
            limit: new Field('limit', '10', [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    function handleClearFiltersClick() {
        const queryFieldsClone = Object.assign({}, Object.values(queryFields).reduce((memo, it) => {
            if ("limit" !== it.name) {
                it.value = '';
            }

            memo[it.name] = it;

            return memo;
        }, {}));

        setQueryFields(queryFieldsClone);
    }

    function getResource() {
        return tabResource;
    }

    function getQuery() {
        let query = Object.assign(
            FieldsManager.getFieldKeyValues(queryFields),
            queryFields.StartDate.value || queryFields.EndDate.value
                ? {
                    searchFields: JSON.stringify({
                        StartDate: ['Date', '>=', queryFields.StartDate.value],
                        EndDate: ['Date', '<=', queryFields.EndDate.value],
                    })
                }
                : {}
        );

        delete query.StartDate;
        delete query.EndDate;

        query.id = id;

        return query;
    }

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [queryFields])

    useEffect(() => {
        if (secondResource.updated || secondResource?.create?.id) {
            setIsNoteFormDialogOpen(false);
        }
    }, [secondResource])

    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center"}>
                    <ActiveFilters
                        filterFields={queryFields}
                        onLabelClick={handleQueryInputChange}
                        onClearFiltersClick={handleClearFiltersClick}
                        translate={translate}
                    />
                </div>

                <PageHeader
                    buttonLabel={translate('btn.create_note')}
                    hasPerm={checkPerm(getResource(), CREATE_PERM)}
                    onButtonClick={handleCreateResourceClick}
                />
            </div>

            <TableCard>
                <TableFilters
                    hideLimit={true}
                    filterFields={queryFields}
                    handleInputChange={handleQueryInputChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    isLoading={isLoading}
                />

                <NoRecordsTable
                    className="py-12 px-6"
                    show={(data.length === 0) && !isLoading}
                    canCreate={checkPerm(getResource(), CREATE_PERM)}
                    title={translate('text.no_matching_records')}
                    text={queryFields?.query?.value && translate('text.create_new_resource', [queryFields.query.value])}
                    btnLabel={translate('btn.create_note')}
                    onBtnClick={handleCreateResourceClick}
                    onClearFilterClick={handleClearFiltersClick}
                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                    clearFilterText={translate('text.try_without_filters_dates')}
                    filters={queryFields}
                />

                <ResourceTable
                    data={data}
                    fields={getFields()}
                    tableKey="AccidentNotesID"

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}
                    queryFields={queryFields}

                    saveTableOptions={setTableOptions}

                    onRowClick={checkPerm(getResource(), UPDATE_PERM) ? handleUpdateResourceClick : null}
                    onEdit={checkPerm(getResource(), UPDATE_PERM) ? handleUpdateResourceClick : null}
                    onDelete={checkPerm(getResource(), DELETE_PERM) ? handleDeleteResourceClick : null}

                />

                <TableFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={handleQueryInputChange}
                        queryFields={queryFields}
                        translate={translate}
                    />
                </TableFooter>
            </TableCard>

            <ModalSaveResource
                title={selectedItem?.AccidentNotesID ? 'Edit accident note' : 'Create accident note'}
                widthClass="max-w-md"
                gridColsClass="grid-cols-12"
                show={isNoteFormDialogOpen}
                onClose={() => setIsNoteFormDialogOpen(false)}
                limitHeight={true}
                fields={getFields(selectedItem)}
                onSubmit={(params) => {
                    if (params?.AccidentNotesID) {
                        dispatch(updateSecondResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: getQuery(),
                            resource: getResource(),
                            piggyResource: getResource(),
                            errorMessage: true,
                            successMessage: translate("message.accident_note_updated"),
                        }))
                    } else {
                        delete params.AccidentNotesID;
                        params.id = id;
                        dispatch(createSecondResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: getQuery(),
                            resource: getResource(),
                            piggyResource: getResource(),
                            errorMessage: true,
                            successMessage: translate("message.accident_note_created"),
                        }))
                    }
                }}
                translate={translate}
            />

            <ModalConfirm
                title={translate('text.confirm')}
                show={isConfirmDeleteDialogOpen}
                text={translate("text.delete_note_confirm")}
                onClose={() => {
                    setIsConfirmDeleteDialogOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={deleteDataItem}
            />
        </React.Fragment>
    )
}