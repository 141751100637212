import React, {useEffect, useRef, useState} from "react";
import ButtonIcon from "../../button/button-icon";
import Resources from "../../../../data/services/resources";
import {Field} from "../../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import ResourceTableDialogNew from "../resource-table-dialog-new";
import {getDefaultTableOptions, openInNewTab} from "../../../util/util-helpers";
import {ArrowTopRightOnSquareIcon, LinkIcon, ReceiptPercentIcon} from "@heroicons/react/24/outline";
import CellButtonWithCopy from "../../resource-table/table-components/cell-button-copy";
import Badge from "../../badge";
import {formatMoney} from "../../../util/util-formaters";
import InvoicesListModal from "./invoices-list-modal";
import {getAccountingInfoResource} from "../../../../data/actions/accountingInfoResource";
import LocalStorage from "../../../util/localStorage";
import InvoiceModal from "../invoice-modal";
import {hideGlobalModal} from "../../../../data/actions/ui";

export default function StripePaymentLinksListModal({translate}) {
    const queryRef = useRef();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState("");
    const [isInvoicesDialogOpen, setIsInvoicesDialogOpen] = useState(false);

    const [isInvoiceDialogVisible, setIsInvoiceDialogVisible] = useState(false);

    const ui = useSelector(state => state.ui);

    function handleViewInvoices(InvoiceIDs) {
        setSelectedInvoices(InvoiceIDs);
        setIsInvoicesDialogOpen(true);
    }

    function handleViewSingleInvoice(InvoiceIDs) {
        setSelectedInvoices({InvoiceID: InvoiceIDs});
        setIsInvoiceDialogVisible(true);
    }

    function getFields() {
        return {
            Invoices: new Field('Invoices', '', [], false, 'custom', {
                omitSort: true,
                render: (it) => {
                    const InvoicesIDs = it.InvoiceIDs.split(",");
                    const AutoCounters = it.AutoCounters.split(",");
                    const count = InvoicesIDs.length;
                    const firstThreeInvoices = AutoCounters.slice(0, 3);

                    return <button
                        onClick={() => count === 1
                            ? handleViewSingleInvoice(it.InvoiceIDs)
                            : handleViewInvoices(it.InvoiceIDs)}
                        className="flex gap-2 hover:bg-primary-transparent py-1 px-2 rounded-md"
                    >
                        {firstThreeInvoices.map(AutoCounter => <Badge key={AutoCounter}>{AutoCounter}</Badge>)}
                        {count > 3 ? <Badge type="warning">+{count - 3}</Badge> : undefined}
                    </button>;
                }
            }),
            RefNumbers: new Field('RefNumbers', '', [], false, 'text', {
                omitSort: true,
                render: (it) => {
                    const InvoicesIDs = it.InvoiceIDs.split(",");
                    const RefNumbers = it?.RefNumbers?.split(",");
                    if (!RefNumbers) {
                        return null;
                    }
                    const count = InvoicesIDs.length;
                    const firstThreeInvoices = RefNumbers.slice(0, 3);

                    return <button
                        onClick={() => count === 1
                            ? handleViewSingleInvoice(it.InvoiceIDs)
                            : handleViewInvoices(it.InvoiceIDs)}
                        className="flex gap-2 hover:bg-primary-transparent py-1 px-2 rounded-md"
                    >
                        {firstThreeInvoices.map(RefNumber => <Badge key={RefNumber}>{RefNumber}</Badge>)}
                        {count > 3 ? <Badge type="warning">+{count - 3}</Badge> : undefined}
                    </button>;

                }
            }),
            PaymentLinkUUID: new Field('PaymentLinkUUID', '', [], false, 'text', {omitSort: true}),
            Amounts: new Field('Amounts', '', [], false, 'money', {
                omitSort: true,
                label: "TotalAmount",
                render: (it) => {
                    return "$" + formatMoney(it.Amounts.split(",").reduce((memo, it) => {
                        return parseFloat(((Number(it) / 100) + memo).toFixed(2));
                    }, 0));
                }
            }),
            PaymentLinkPayed: new Field('PaymentLinkPayed', '', [], false, 'checkbox'),
            PaymentLinkActive: new Field('PaymentLinkActive', '', [], false, 'custom', {
                render: (it) => {
                    return it.PaymentLinkActive
                        ? <Badge type="success">Active</Badge>
                        : <Badge>Deactivated</Badge>
                }
            }),
            PaymentLinkURL: new Field('PaymentLinkURL', '', [], false, 'custom', {
                omitSort: true,
                render: (it) => {
                    return <CellButtonWithCopy copyText={it.PaymentLinkURL} translate={translate}>
                        <div>{it.PaymentLinkURL}</div>
                    </CellButtonWithCopy>
                }
            }),
            UpdatedByContact: new Field('UpdatedByContact', '', [], false, 'text', {
                omitSort: true
            }),
            CreateDate: new Field('CreateDate', '', [], false, 'datetime'),
        }
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: "col-span-2",
                hideLabel: true,
                labelType: "float",
                autofocus: true,
            }),
            offset: new Field('offset', "0", [''], false, 'hidden'),
            limit: new Field("limit", "10", [''], false, 'hidden', {
                hideLabel: true,
                labelType: "float"
            }),
            sort: new Field('sort', "DESC", [''], false, 'hidden'),
            sortBy: new Field('sortBy', "TerminalPaymentIntentID", [''], false, 'hidden'),
        }
    }

    useEffect(() => {
        if (ui?.globalDialogs?.StripePaymentLinksListModal?.visible) {
            setShowModal(true);
            dispatch(hideGlobalModal("StripePaymentLinksListModal"));
        }
    }, [ui]);

    return (
        <>
            <ButtonIcon
                appearance="border"
                tooltip={translate("btn.stripe_payment_links")}
                onClick={() => setShowModal(true)}
            >
                <LinkIcon
                    className="w-5 h-5 text-[#6772e5] stroke-[2.5]"
                />
            </ButtonIcon>

            <ResourceTableDialogNew
                show={showModal}
                resourcePath={Resources.PaymentsLinks}
                tableKey={'PaymentLinkID'}

                title={translate("text.stripe_payment_links")}
                dispatch={dispatch}
                options={getDefaultTableOptions(getFields(), {
                    columns: {
                        Invoices: {
                            minWidth: 300
                        },
                        RefNumbers: {
                            minWidth: 300
                        },
                        PaymentLinkURL: {
                            minWidth: 400
                        }
                    }
                }, 'stripe_payment_links_state', translate)}
                widthClass={"max-w-full"}
                maxHeightClass="max-h-[calc(100vh-16rem)]"
                sortBy={'PaymentLinkID'}
                getCustomActions={[
                    {
                        action: (item) => {
                            openInNewTab(item.PaymentLinkURL);
                        },
                        icon: ArrowTopRightOnSquareIcon,
                        visible: (item) => !!item.PaymentLinkActive,
                        title: translate("text.view_payment_link")
                    },
                    {
                        action: (item) => {
                            openInNewTab(item?.PaymentLinkReceiptUrl);
                        },
                        icon: ReceiptPercentIcon,
                        visible: (item) => !!item.PaymentLinkReceiptUrl,
                        title: translate("text.view_payment_receipt")
                    }
                ]}
                noRecordsText={(hasQuery) => hasQuery ? translate("text.NoPaymentLinks", [hasQuery]) : undefined}

                fields={getFields()}
                getQueryFields={getQueryFields()}
                queryRef={queryRef}

                onClose={() => setShowModal(false)}
                hideDialogFooter={true}
                translate={translate}
                htmlAfter={
                    <>
                        <InvoicesListModal
                            show={isInvoicesDialogOpen}
                            selectedInvoices={selectedInvoices}
                            onClose={() => setIsInvoicesDialogOpen(false)}
                            translate={translate}
                        />

                        <InvoiceModal
                            show={isInvoiceDialogVisible}
                            selectedItem={selectedInvoices}
                            onClose={() => setIsInvoiceDialogVisible(false)}
                            onFetchData={() => {
                                dispatch(getAccountingInfoResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        InvoiceID: selectedInvoices?.InvoiceID
                                    },
                                    resource: Resources.InvoicesInvoice
                                }))
                            }}
                            translate={translate}
                        />
                    </>
                }
            />
        </>
    )
}