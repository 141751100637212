import React, {Component} from "react";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import CameraImageDialog from "./cameraImageDialog";
import {isFirefox, isSafari} from 'react-device-detect';
import {DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS, MAXIMUM_DOCUMENT_UPLOAD_SIZE} from "../../../../util/util-constants";
import ModalDefault from "../../modal/modal-default";
import {getJWT} from "../../../util/util-auth";
import Resources from "../../../../data/services/resources";
import Env from "../../../../util/env";
import InfoParagraph from "../../info-paragraph";


// TODO: Check if duplicate
export default class ContactImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cameraImageDialog: false,
            imagePreviewDialog: false,
            cameraPermission: false
        }

        this.isSafari = isSafari || isFirefox;
    }

    /** Lifecycle
     ================================================================= */
    async componentDidMount() {
        await this.getPermissions();

        if (!this.isSafari) {
            navigator.permissions.query({name: 'camera'}).then((result) => {
                if (result.state === 'granted') {
                    this.setState({cameraPermission: true})
                } else if (result.state === "prompt") {
                    this.setState({cameraPermission: false})
                }
            });
        }
    }

    /** UI
     ================================================================= */
    handleToggleImagePreviewDialog = () => {
        this.setState({imagePreviewDialog: !this.state.imagePreviewDialog})
    }

    /** WebRtc Helpers
     ================================================================= */
    getPermissions = async () => {
        await navigator.mediaDevices.getUserMedia({video: true})
            .then((stream) => {
                this.setState({
                    videoAvailable: true,
                });

                stream.getTracks().forEach((track) => {
                    track.stop()
                });

                if (this.isSafari) {
                    this.setState({
                        cameraPermission: true
                    });
                }
            })
            .catch(() => this.setState({
                videoAvailable: false
            }))
    }

    render() {
        const {translate, title, isCropVisible, src, crop, croppedImageUrl, imageID, imagePath} = this.props;

        return (
            <div className="row">
                <div className="col-md-12">
                    <h5 className="mb-4 text-tm-gray-700 text-base">{title}</h5>

                    {!!croppedImageUrl && !!isCropVisible && (
                        <h6 className="text-tm-gray-500">Select area to crop image to:</h6>
                    )}

                    <div className="flex">
                        <div>
                            <div className="relative">

                                <div className="avatar-preview">
                                    {!croppedImageUrl && !!imagePath && (
                                        <img
                                            className={"img-thumbnail border-0  mb-4 list-thumbnail cursor-pointer"}
                                            src={
                                                (this.props.files.length > 0) ? this.props.files[0].preview : imageID > -1 ? Env.getApiUrl("api/" + Resources.ContactImage, {
                                                    token: getJWT().access_token,
                                                    ContactID: imageID
                                                }) : ""
                                            }
                                            onClick={this.handleToggleImagePreviewDialog}
                                            alt={""}
                                        />
                                    )}

                                    {!croppedImageUrl && !imagePath && (
                                        <img alt="" className=""
                                             src="/images/avatar-light.png"/>
                                    )}

                                    {!!croppedImageUrl && (
                                        <img
                                            onClick={this.handleToggleImagePreviewDialog}
                                            className={'cursor-pointer w-full'}
                                            alt="Crop" src={croppedImageUrl}/>
                                    )}
                                </div>
                            </div>
                            {!!croppedImageUrl && !!isCropVisible && (
                                <p className="text-tm-gray-500 text-small text-center">Preview</p>
                            )}
                        </div>


                        <div className="ml-3">
                            {(!src || !isCropVisible) && (
                                <div>
                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            this.props.onSelectFile(acceptedFiles);
                                        }}
                                        maxFiles={1}
                                        accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                                        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                        multiple={false}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div
                                                    {...getRootProps()}
                                                    className="select-from-library-button sfl-single mb-3 py-2 px-4 flex items-center justify-center category-dropzone"
                                                >
                                                    <input {...getInputProps()} />
                                                    <p className="m-0 disable-text-selection">
                                                        <i className="simple-icon-cloud-upload mr-1"></i> Drop image to
                                                        upload,
                                                        or <span className="has-link">browse</span>.
                                                    </p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {this.state.cameraPermission ? (
                                            <div>
                                                <div className={'text-center mb-2'}>{translate("text.OR")}</div>
                                                <div className={'text-center'}>
                                                    <button onClick={() => this.setState({cameraImageDialog: true})}
                                                            className="btn btn-outline mb-2">{translate("text.TakeAPicture")}</button>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <InfoParagraph>
                                                {translate("text.AllowCameraPermissionInOrderToTakePhoto")}
                                            </InfoParagraph>
                                        )
                                    }

                                </div>
                            )}

                            {src && !!isCropVisible && (
                                <React.Fragment>
                                    <div className="mb-3">
                                        <ReactCrop
                                            src={src}
                                            crop={crop}
                                            ruleOfThirds
                                            onImageLoaded={this.props.onImageLoaded}
                                            onComplete={this.props.onCropComplete}
                                            onChange={this.props.onCropChange}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>

                    {(!croppedImageUrl || !isCropVisible) && (
                        <p className="text-tm-gray-500">Image should be at least 80 x 80 px in png or jpeg files. Make
                            sure
                            image is in proper format, and you click OK before proceeding.</p>
                    )}

                    {src && !!isCropVisible && (
                        <div className="text-right">
                            <div className="separator mb-3"></div>
                            <button
                                onClick={this.props.cancelImageCropping}
                                className="btn btn-xs btn-outline">
                                {translate("btn.cancel")}
                            </button>

                            <button
                                onClick={this.props.setCropOk}
                                className="btn btn-xs btn-outline ml-3">
                                {translate("btn.ok")}
                            </button>
                        </div>
                    )}
                </div>

                <ModalDefault
                    show={this.state.imagePreviewDialog}
                    widthClass={"max-w-5xl"}
                    title={translate("text.ProfileImagePreview")}

                    closeButtonLabel={translate("btn.close")}
                    onClose={this.handleToggleImagePreviewDialog}
                >
                    <div className={'p-3 flex justify-center'}>
                        {!croppedImageUrl && !!imagePath && (
                            <img
                                className={"w-5/6 h-5-6"}
                                src={
                                    (this.props.files.length > 0) ? this.props.files[0].preview : imageID > -1 ? Env.getApiUrl("api/" + Resources.ContactImage, {
                                        token: getJWT().access_token,
                                        ContactID: imageID
                                    }) : ""
                                }
                                alt={""}
                            />
                        )}

                        {!!croppedImageUrl && (
                            <img
                                className={'w-5/6 h-5-6'}
                                alt="Crop" style={{width: '100%'}} src={croppedImageUrl}/>
                        )}
                    </div>
                </ModalDefault>

                {this.state.cameraImageDialog && (
                    <CameraImageDialog
                        translate={translate}
                        onClose={() => this.setState({cameraImageDialog: false})}
                        onSelectFile={this.props.onSelectFile}
                    />
                )}
            </div>
        )
    }
}
