import ImageAvatarLoader from "../../image/image-avatar-loader";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import React from "react";

export default function ContactSelectorCard({contact, onRemoveContact, onContactClick}) {
    return (
        <div
            className="px-3 py-2 flex items-center rounded-lg hover:bg-inverse relative group cursor-pointer"
            onClick={() => onContactClick(contact)}
        >
            <div className="mr-4">
                <ImageAvatarLoader
                    hasImagePath={contact.ImagePath}
                    ContactID={contact.ContactID}
                    size="lg"
                />
            </div>

            <div>
                <p className="text-base">{contact.LastName + " " + contact.FirstName}</p>
                <p className="text-tm-gray-600 leading-5">{contact.Email}</p>
                <p className="text-tm-gray-600 leading-5">{contact.Phone}</p>
            </div>

            <button
                className="btn btn-icon absolute right-2 invisible group-hover:visible"
                onClick={(e) => {
                    e.stopPropagation();
                    onRemoveContact(contact);
                }}
            >
                <XMarkIcon className="w-5 h-5"/>
            </button>
        </div>
    )
}