import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {
    checkPerm,
    getDefaultTableOptions,
    getLookup,
    getProp,
    returnOffsetAndPagination
} from '../../../../common/util/util-helpers'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, MAJOR_ACCOUNT_TYPE_EXPENSE} from '../../../../util/util-constants'
import {TrashIcon} from '@heroicons/react/24/outline'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {createThirdResource, deleteThirdResource, getThirdResource} from '../../../../data/actions/thirdResource'
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../../common/components/resource-table";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import ActiveFilters from "../../../../common/components/resource-table/table-components/active-filters";
import InfoParagraph from "../../../../common/components/info-paragraph";
import {DEFAULT_QUERY_LIMIT} from '../../../../common/util/util-consts'

export default class DriverPayrollSettings extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + '_settings'
        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            createModalOpen: false,
            editModalOpen: false,
            selectedItem: null,
            confirmDialog: false

        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: this.getSettingsResource(),
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'query', 'offset'])
    }

    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value)

        this.setState({
            fields: fields
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    archiveResource = (item = null) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_delete_item')}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.thirdResource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteThirdResource({
                        user: LocalStorage.get('user'),
                        query: {
                            DriverPositionPayrollTypeID: item?.DriverPositionPayrollTypeID,
                        },
                        piggyQuery: this.getQuery(),
                        errorMessage: true, successMessage: `Item has been deleted`,
                        resource: this.getSettingsResource(),
                        piggyResource: this.getSettingsResource()
                    }))
                })
            }, item)
        })
    }

    handleFilterInputChange = (name, value) => {
        let queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }
    /** Helpers
     ================================================================= */
    getSettingsResource = () => {
        return Resources.PayrollSettings
    }

    getFields = () => {
        return {
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'},
                {values: getLookup('DriverPositionType', 'DriverPositionTypeID', 'DriverPositionType')}),
            ExpenseAccountID: new Field('ExpenseAccountID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full'
            })
        }
    }

    render() {
        const {translate, thirdResource} = this.props

        const data = getProp(this.props.thirdResource, 'data.list', [])
        const count = getProp(this.props.thirdResource, 'data.count', 0)

        return (
            <div>
                {checkPerm(this.getSettingsResource(), CREATE_PERM) && (
                    <div className="flex m-4 justify-between">
                        <div className={'max-w-5xl'}>
                            <InfoParagraph>
                                {translate('text.selectExpenseDriverForEachType')}
                            </InfoParagraph>
                        </div>
                        <button className={'btn btn-primary mt-2'} onClick={() => this.handleToggleCreateModal()}>
                            {translate('btn.create_new')}
                        </button>
                    </div>
                )}

                <div className="flex m-4">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={thirdResource.isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        // verticalTableIsVisible={this.state.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={thirdResource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                action: this.archiveResource,
                                icon: TrashIcon, // make this a function
                                visible: (it) => (!it.ArchivedDate && checkPerm(this.getSettingsResource(), DELETE_PERM)),
                                label: false, // make this a function
                                title: translate('btn.delete'),
                                disabled: false,
                                class: false,
                                iconClass: false
                            }
                        ]}
                    />

                    <TableCardFooter
                        show={!(!data.length && !thirdResource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={thirdResource.isLoading}
                            // hideRowsPerPage={this.state.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('text.create_money_code_type')}
                    widthClass="max-w-[32rem]"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createThirdResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getSettingsResource(),
                                piggyResource: this.getSettingsResource(),
                                errorMessage: true, successMessage: translate('message.item_created')
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    metadata={
                        {
                            ExpenseAccountID: {
                                api: 'api/' + Resources.AccountsQuick,
                                query: {
                                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                                },
                                searchMap: (item) => ({
                                    value: item.AccountID,
                                    label: item.AccountNumber + ' ' + item.AccountName
                                })
                            }
                        }
                    }
                    translate={translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </div>

        )
    }
}
