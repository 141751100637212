const grabTaskReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GRAB_TASK_RESOURCE':
            return Object.assign({}, state, {
                grab: null,
                isGrabLoading: true,
                grabError: false,
                grabErrorMessage: null
            });
        case 'DONE_GRAB_TASK_RESOURCE':
            return Object.assign({}, state, {
                grab: action.data,
                isGrabLoading: false,
                grabError: false,
                errorMessage: null
            });
        case 'ERROR_GRAB_TASK_RESOURCE':
            return Object.assign({}, state, {
                grab: null,
                isGrabLoading: false,
                grabError: true,
                grabErrorMessage: action.data
            });
        case 'CLEAR_GRAB_TASK_RESOURCE':
            return Object.assign({}, state, {
                grab: null,
            });
        default:
            return state;
    }
}

export default grabTaskReducer;