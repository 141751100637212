import React from "react";
import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";
import PopOver from "../../../popover";
import {showGlobalModal} from "../../../../../data/actions/ui";
import InfoParagraph from "../../../info-paragraph";

export default function PageHeaderInfo({infoBox, InfoBoxBtnLabel, dispatch}) {
    return (
        <React.Fragment>
            {!infoBox && (
                <button
                    className="field-btn rounded-full p-1 mx-2"
                    onClick={() => dispatch(showGlobalModal('knowledgeBaseSlideOver'))}
                >
                    <QuestionMarkCircleIcon className="h-4 w-4 text-primary" />
                </button>
            )}

            {!!infoBox && (
                <PopOver
                    btnClass="field-btn rounded-full p-1 mx-2"
                    BtnIcon={QuestionMarkCircleIcon}
                    btnIconClass="h-4 w-4 text-primary"
                    widthClass="w-[calc(100vw-2.5rem)] md:max-w-[50vw] lg:max-w-lg"
                    positionClass="z-20 fixed md:absolute left-0 mx-5 md:mx-0 translate-x-0 md:-top-5"
                >
                    <InfoParagraph
                        type="help"
                        className="text-base"
                    >
                        <div className="md:flex space-x-3">
                            <div className="w-full">
                                {infoBox}
                            </div>

                            {!!InfoBoxBtnLabel && (
                                <div className="mt-5 md:mt-0 text-right md:ml-auto md:flex md:items-center">
                                    <button
                                        onClick={() => dispatch(showGlobalModal('knowledgeBaseSlideOver'))}
                                        className="btn btn-outline-secondary py-1 whitespace-nowrap"
                                    >
                                        {InfoBoxBtnLabel}
                                    </button>
                                </div>
                            )}
                        </div>
                    </InfoParagraph>
                </PopOver>
        )}
        </React.Fragment>
    )
}