export function setTableData(data) {
    return {
        type: 'SET_TABLE_DATA',
        data: data
    };
}

export function patchTableData(data) {
    return {
        type: 'PATCH_TABLE_DATA',
        data: data
    };
}