import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";
import {classNames} from "../../../../util/util-helpers";
import {copyToClipboard} from "../../../../util/util-vanilla";

export default function TextCopy({text, copyText, copiedText}) {
    const [isFocus, setIsFocus] = useState(false);
    const [isAnimated, setIsAnimated] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        copyToClipboard(text);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    }

    return (
        <div
            className="flex relative"
            onMouseEnter={() => {
                setIsFocus(true)
                setIsAnimated(true)
            }}
            onClick={
                (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCopyClick();
                }
            }
            onMouseLeave={() => setIsFocus(false)}
        >
            <button
                className="text-primary py-2"
            >
                {text}
            </button>

            <Tippy
                content={isCopied ? copiedText : copyText}
            >
                <span
                    className={
                        classNames(
                            "w-8 h-9 relative right-8 px-2 flex items-center justify-center",
                            isFocus ? "animate-fade-in-right" : undefined,
                            !isFocus && !isAnimated ? "opacity-0" : undefined,
                            !isFocus && isAnimated ? "animate-fade-out-right" : undefined,
                        )}
                >
                    {!isCopied && (
                        <ClipboardDocumentIcon className="w-5 h-5 text-primary"/>
                    )}

                    {isCopied && (
                        <div className="bg-green-600 rounded-full p-0.5">
                            <CheckIcon className="w-3 h-3 text-white"/>
                        </div>
                    )}
                </span>

            </Tippy>
        </div>
    )
}