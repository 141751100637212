import React, {Component} from "react";
import {connect} from "react-redux";
import LocalStorage from "../../../util/localStorage";
import {checkPerm} from '../../../common/util/util-helpers'
import Resources from "../../../data/services/resources";
import {getResource} from "../../../data/actions/resource";
import Tippy from '@tippyjs/react'
import {READ_PERM} from '../../../util/util-constants'
import TripInfoTab from './tripInfoTab'
import TripStopsTab from './tripStopsTab'
import TripPricingTab from './tripPricingTab'
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";

class TripView extends Component {

    constructor(props) {
        super(props)

        this.isEditMode = !!this.props.match.params.id;
        this.ID = this.props?.match?.params?.id;

        const tabs = [
            {
                name: 'TripInfo',
                resource: Resources.Trip,
                current: true,
                visible: true
            },
            {
                name: 'TripStop',
                resource: Resources.TripStops,
                current: false,
                visible: !!this.ID
            },
            {
                name: 'TripPricing',
                resource: Resources.TripPricing,
                current: false,
                visible: !!this.ID
            }
        ].map((item) => {
            if (item.visible) {
                item.visible = checkPerm(item.resource, READ_PERM)
            }
            return item
        })

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.Trip,
        }

        this.layoutRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        if (this.isEditMode) {
            /*this.fetchData();*/
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.resource.isLoading && prevProps.resource.isLoading) {
            this.fillStateWithData()
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Trip,
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.ID) {
            if (this.state.canSubmit) {
                this.setState({
                    confirmModal: true,
                    reserveTab: resource
                })
            } else {
                this.tabChange(resource)
            }
            return false
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    /** Helpers
     ================================================================= */
    fillStateWithData = () => {
        // TODO
    }

    getQuery = () => {
        if (this.ID) {
            return {LoadTripID: this.ID}
        }

        if (this.props?.resource?.create?.id) {
            return {LoadTripID: this.props.resource.create.id}
        }

        return {};
    }

    render() {
        const {translate} = this.props;

        return (
            <Layout
                {...this.props}
                hasFooter
                onScroll={this.handleLayoutScroll}
                layoutRef={this.layoutRef}
                isDirty={this.state.isDirty}
            >
                <Page>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-2 flex justify-between items-center">
                                    <div className="flex items-center justify-center">
                                        <div className="flex items-center justify-center">
                                            <GoBackButton
                                                translate={this.props.translate}
                                                history={this.props.history}
                                                path={'/trips'}
                                            />
                                            <h1 className={'mr-5 text-3xl ml-2'}>
                                                {translate('text.trip')}
                                                <span
                                                    className="text-tm-gray-500"> {this.ID}
                                                    {this.state.ArchivedDate && (
                                                        <Tippy content={<span>Archived</span>}>
                                                            <i className="iconsminds-delete-file"/>
                                                        </Tippy>
                                                    )}
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                    <div>
                                        {/* TODO TASKS */}
                                    </div>
                                </div>

                                <div className="mb-5 hidden sm:block">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="mb-5">
                                    <MobileTabs
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                        translate={translate}
                                    />
                                </div>

                                <div className="tab-content">
                                    <div className="tab-pane show active">
                                        <div className="row">
                                            <div
                                                className={`col${this.state.selectedTab === Resources.Trip ? '-lg-12' : ''}`}>
                                                {/* TODO COMPLETE TABS */}
                                                {this.state.selectedTab === Resources.Trip && (
                                                    <TripInfoTab
                                                        translate={this.props.translate}
                                                        dispatch={this.props.dispatch}
                                                        dialogResource={this.props.dialogResource}
                                                        resource={this.props.resource}
                                                        loadTripID={this.ID}
                                                    />
                                                )}

                                                {this.state.selectedTab === Resources.TripStops && (
                                                    <TripStopsTab

                                                    />
                                                )}

                                                {this.state.selectedTab === Resources.TripPricing && (
                                                    <TripPricingTab

                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Page>

            </Layout>
        )
    }
}

export default connect(state => state)(TripView)
