import {useDispatch, useSelector} from "react-redux";
import {getProp} from "../../../../util/util-helpers";
import {useEffect, useState} from "react";
import {getDialogResource} from "../../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../../data/services/resources";
import {LoaderLarge} from "../../../loader";
import DisplayDataFieldContainer from "../../../display-data/display-data-field-container";
import DisplayDataFieldTextarea from "../../../display-data/display-data-field-textarea";
import {Field} from "../../../../../data/services/fields";

export default function GlobalPartsInfoModal({translate, partID}) {

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogResource);
    const isLoading = getProp(resource, 'isLoading', false)

    const [data, setData] = useState({})

    const fetchData = () => {
        dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: Resources.Parts,
            query: {id: partID}
        }));
    }

    function getFields() {
        return {
            PartID: new Field('PartID', '', ['']),
            PartName: new Field('PartName', '', ['empty']),
            OEMNumber: new Field('OEMNumber', '', ['']),
            CrossRefNumber: new Field('CrossRefNumber', '', ['']),
        }
    }

    useEffect(() => {
        if (partID) {
            fetchData()
        }
    }, [])

    useEffect(() => {
        if (resource?.data?.list) {
            const selectedPart = Object.values(resource.data.list).find((item) => item.PartID == partID)
            if (selectedPart) {
                setData(selectedPart)
            }
        }
    }, [resource])

    const fields = getFields();

    return (
        <div className="bg-tm-gray-50 px-3 py-8 text-tm-gray-700 space-y-8">
            {isLoading && (
                <LoaderLarge stripesBg/>
            )}

            {!isLoading && (
                <>
                    <DisplayDataFieldContainer
                        fields={fields}
                        data={data}
                        translate={translate}
                    />

                    <DisplayDataFieldTextarea
                        translate={translate}
                        fieldName={"Notes"}
                        data={data}
                    />
                </>
            )}
        </div>
    );
}
