import React, {Component} from 'react'
import {fillFieldsFromData} from '../../../common/util/util-fields'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import LocalStorage from '../../../util/localStorage'
import {getResource, updateResource} from '../../../data/actions/resource'
import {LoaderLarge} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import {toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import DisplayDataGrid from "../../../common/components/display-data/display-data-grid";
import Card from "../../../common/components/card";
import FieldsForm from "../../../common/components/fields/fields-form";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import FieldOptions from "../../../common/components/fields/field-options";
import {showModal} from "../../../data/actions/ui";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import {formatMoney} from "../../../common/util/util-formaters";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class CustomerCreditTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),

            isDirty: false,
            footerIsSticky: true,
            isCreditHoldConfirmDialogOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({fields: this.getFields(getProp(this.props.resource, 'data', {}))})
        }
        if (prevState.isDirty !== this.state.isDirty) {
            this.props.setIsStateDirty(this.state.isDirty)
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: {
                id: this.getID()
            }
        }))
    }

    onSubmit = () => {
        if (this.state.isDirty) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    const fieldValues = FieldsManager.getFieldKeyValues(this.state.fields);

                    fieldValues.CreditLimit = fieldValues.SetCreditLimit
                        ? fieldValues.CreditLimit
                        : 0;

                    fieldValues.AllowCreditLimitOverride = fieldValues.SetCreditLimit
                        ? fieldValues.AllowCreditLimitOverride
                        : 0;

                    delete fieldValues.SetCreditLimit;

                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, fieldValues, {
                            id: this.getID(),
                        }),
                        query: {
                            id: this.getID(),
                        },
                        errorMessage: true, successMessage: this.props.translate('text.CustomerSettingsUpdated'),
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                    this.setState({isDirty: false})
                }
            })
        }
    }

    /** UI events
     ================================================================= */

    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(),
            isDirty: false
        })
    }

    handleInputChange = (name, value) => {
        let isCreditHoldConfirmDialogOpen = false;

        if ("CreditHold" === name) {
            isCreditHoldConfirmDialogOpen = !value
            if (isCreditHoldConfirmDialogOpen) {
                this.setState({
                    isCreditHoldConfirmDialogOpen: true
                })
                value = true;
            }
        }

        const fieldsUpdate = FieldsManager.updateField(this.state.fields, name, value);

        if ("SetCreditLimit" === name) {
            fieldsUpdate.CreditLimit.disabled = !value;
            fieldsUpdate.CreditLimit.validate = !value ? [''] : ['min:1'];
            fieldsUpdate.AllowCreditLimitOverride.disabled = !value;
        }


        fieldsUpdate[name].errorMessage = "";

        this.setState({
            fields: fieldsUpdate,
            isDirty: true
        })
    }

    handleConfirmDisableCreditHold = () => {
        const fieldsUpdate = FieldsManager.updateField(this.state.fields, "CreditHold", false);
        this.setState({
            fields: fieldsUpdate,
            isDirty: true,
            isCreditHoldConfirmDialogOpen: false
        })
    }

    /** Fields/data definitions
     ================================================================= */
    getFields = (data = {}) => {
        const areCreditFieldsDisabled = !data?.CreditLimit;

        let fieldTemplates = {

            SetCreditLimit: new Field('SetCreditLimit', areCreditFieldsDisabled ? 0 : 1, [''], false, 'checkbox', {addContainerClass: "col-span-4"}),
            CreditLimit: new Field('CreditLimit', "", areCreditFieldsDisabled ? [''] : ['min:1'], !!data?.CreditHold || areCreditFieldsDisabled, 'money', {addContainerClass: "col-start-1 col-span-4"}),
            AllowCreditLimitOverride: new Field('AllowCreditLimitOverride', '', [''], areCreditFieldsDisabled, 'checkbox', {
                addContainerClass: "col-span-4"
            }),
            CreditHold: new Field('CreditHold', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-full"
            }),
            CreditHoldNote: new Field('CreditHoldNote', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}),
            Rating: new Field('Rating', '', [''], false, '', {addContainerClass: "col-span-full"}),
            Agency: new Field('Agency', '', [''], false, '', {addContainerClass: "col-span-full"}),
        }

        if (data?.CreditLimit) {
            data.CreditLimit = formatMoney(data.CreditLimit);
        }

        return fillFieldsFromData(fieldTemplates, data);
    }

    getCreditUpdateFields = (data = {}) => {
        let fieldTemplates = {
            CreditLimitUpdateDate: new Field('CreditLimitUpdateDate', '', [''], false, 'datetimezone', {hideDialog: true}, {addContainerClass: "col-span-full"}),
            CreditLimitUpdateBy: new Field('CreditLimitUpdateBy', '', [''], false, 'text', {
                hideDialog: true,
                options: () => (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: data?.CreditLimitUpdateByID ?? 0})),
                                isVisible: true
                            }
                        ]}
                    />
                )
            })
        }

        return fillFieldsFromData(fieldTemplates, data);
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.CustomersCredit
    }

    getID = () => {
        return this.props.match.params.CustomerID
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;
        const {data, isLoading} = this.props.resource;
        // const fieldsHTML = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {})

        return (
            <div className="max-w-4xl mx-auto">
                <Card className="bg-inverse rounded-card border-tm-gray-300 border shadow-card mb-4 relative pb-6">
                    <div className={'grid grid-cols-12 gap-4'}>
                        <FieldsForm
                            fieldsState={this.state.fields}
                            onChange={this.handleInputChange}
                            isLoading={isLoading}
                            translate={translate}
                        />

                        {this.state.fields?.CreditHold?.value && (
                            <InfoBar addClass="col-span-full text-base" type="danger">
                                <div className="max-w-lg">
                                    The creation of loads is not possible when the credit hold check is enabled. Loads
                                    that are already created will have an additional warning on the load pricing page,
                                    but they can be completed as they are.
                                </div>
                            </InfoBar>
                        )}

                        {!!getProp(this.props.resource, 'data.CreditLimitUpdateByID', 0) && (
                            <DisplayDataGrid
                                addGridClass={"col-span-full grid-cols-2 border border-tm-gray-300 rounded-lg p-5 text-tm-gray-700 font-bold"}
                                displayList={Object.values(this.getCreditUpdateFields(data)).filter(it => it.name === 'CreditLimitUpdateDate' || it.name === 'CreditLimitUpdateBy')}
                                data={{
                                    CreditLimitUpdateBy: getProp(this.props.resource, "data.CreditLimitUpdateBy", ''),
                                    CreditLimitUpdateDate: toFrontDateTimeFromUTC(getProp(this.props.resource, "data.CreditLimitUpdateDate", ''))
                                }}
                                translate={translate}
                            />
                        )}
                    </div>

                    {!!resource.isLoading && (
                        <LoaderLarge/>
                    )}
                </Card>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.isDirty}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.onSubmit}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={this.state.isCreditHoldConfirmDialogOpen}
                    onClose={() => this.setState({
                        isCreditHoldConfirmDialogOpen: false
                    })}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.handleConfirmDisableCreditHold}
                >
                    <div className="text-base">
                        {translate("text.confirm_credit_on_hold_disable")}
                    </div>
                </ModalConfirm>
            </div>
        )
    }
}
