import React, {Component} from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../util/util-fields";
import FieldText from "../../fields/field-text";
import FieldContainer from "../../fields/field-container";
import FieldDropdownSelect from "../../fields/field-dropdown-select";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Env from "../../../../util/env";
import FieldTextarea from "../../fields/field-textarea";
import {
    DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
    MAJOR_ACCOUNT_TYPE_EXPENSE, MAJOR_ACCOUNT_TYPE_INCOME,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE
} from "../../../util/util-consts";
import FieldCheckbox from "../../fields/field-checkbox";
import Resources from "../../../../data/services/resources";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import NavResponsive from "../../nav-responsive";
import ModalDefault from "../modal-default";
import {classNames, focusOnElement, getProp} from "../../../util/util-helpers";
import LocalStorage from "../../../util/localStorage";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import {createResource, updateResource} from "../../../../data/actions/resource";
import {getJWT} from "../../../util/util-auth";
import {processResponse} from "../../../../data/services/api-util";


export default class ProductServiceInfoDialog extends Component {
    constructor(props) {
        super(props);
        const tabs = [
            {
                name: 'Service',
                resource: 'Service',
                current: this.props.editedItem.ProductServiceTypeID ? (this.props.editedItem.ProductServiceTypeID === 3) : true,
                visible: true
            },
            {
                name: 'Inventory',
                resource: 'Inventory',
                current: this.props.editedItem.ProductServiceTypeID === 1,
                visible: true
            },
            {
                name: 'NonInventory',
                resource: 'NonInventory',
                current: this.props.editedItem.ProductServiceTypeID === 2,
                visible: true
            },
            /*
            {
                name: 'Asset',
                resource: 'Asset',
                current: this.props.editedItem.ProductServiceTypeID === 4,
                visible: (this.props.buyAsset || this.props.sellAsset)
            },*/
        ]

        this.state = {
            fields: this.getFields(),
            assetFields: this.getAssetFields(),
            HideModalAnimation: false,
            files: [],
            tabs: tabs
        };

        this.INVENTORY = 1;

    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thirdResource.isLoading && !this.props.thirdResource.isLoading && this.props.thirdResource.data) {
            const resource = getProp(this.props.thirdResource, "data", null);
            this.setState({
                fields: this.getFields(resource, this.props.editedItem)
            }, () => focusOnElement());
        }
    }

    getFields = (resource, item) => {
        const isEditMode = this.props.editedItem && this.props.editedItem.ProductServiceID;
        resource = isEditMode ? item : resource;

        const fieldTemplates = {
            ProductService: new Field('ProductService', this.props.name || "", ['empty'], false, 'text', {label: "name"}),
            SKU: new Field('SKU', '', [''], false, 'text', {label: 'sku'}),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], false, 'select-search'),
            IncomePrice: new Field('IncomePrice', '', [''], false, 'money'),
            ExpensePrice: new Field('ExpensePrice', '', [''], false, 'money'),
            IncomeDescription: new Field('IncomeDescription', '', [''], false, 'textarea'),
            IsSelling: new Field('IsSelling', this.props.isInvoiceDialogType ? "1" : "", [''], !!this.props.isInvoiceDialogType, 'checkbox'),
            ExpenseDescription: new Field('ExpenseDescription', '', [''], false, 'textarea'),
            IsPurchasing: new Field('IsPurchasing', this.props.isExpenseDialogType ? "1" : "", [''], !!this.props.isExpenseDialogType, 'checkbox'),
            IncomeAccountID: new Field('IncomeAccountID', '', [''], false, 'select-search'),
            ExpenseAccountID: new Field('ExpenseAccountID', '', ['empty'], false, 'select-search'),
            VendorID: new Field('VendorID', '', ['empty'], false, 'select', {label: 'recommendedVendor'}),
            ProductServiceTypeID: new Field('ProductServiceTypeID', 3, ['']),
            InventoryAccountID: new Field('InventoryAccountID', '', ['empty'], false, 'select-search'),
            ReorderPoint: new Field('ReorderPoint', '', ['integer_or_empty'], false, 'integer',  {
                label: "MinRecommendedStock",
            }),
            InventoryCount: new Field('InventoryCount', '', [], isEditMode, 'integer' )
        }

        return fillFieldsFromData(fieldTemplates, resource);
    }

    getAssetFields = () => {
        return {
            FixedAsset: new Field('FixedAsset', this.props.location?.query?.input ? this.props.location.query.input : '', ['empty']),
            Description: new Field('Description', '', []),
            PONumber: new Field('PONumber', "", []),
            SerialNumber: new Field('SerialNumber', "", []),
            Amount: new Field('Amount', "", ['float_up_to_12_not_require']),
            SalvageAmount: new Field('SalvageAmount', "", ['float_up_to_12_not_require']),
            StartDate: new Field('StartDate', "", []),
            EndDate: new Field('EndDate', "", []),
            WarrantyExpirationDate: new Field('WarrantyExpirationDate', "", []),
            AccountID: new Field('AccountID', "", ['empty'], false, 'select-search'),
            AccumulatedAccountID: new Field('AccumulatedAccountID', "", [], false, 'select-search'),
            DepExpenseAccountID: new Field('DepExpenseAccountID', "", [], false, 'select-search'),
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get("user"),
            resource: Resources.AccountingSettings,
        }));
    };

    handleInputChange = (name, value) => {
        let fields = this.state.fields;
        if (name === 'ProductServiceTypeID') {
            fields = FieldsManager.resetFieldsErrors(fields)

            fields.InventoryAccountID.validate = value === this.INVENTORY ? ['empty'] : [''];
        }

        this.setState({
            fields: FieldsManager.updateField(fields, name, value)
        });
    };

    handleRemoveImageClick = (i) => {
        const files = this.state.files;
        files.splice(i, 1);

        this.setState({files});
    }

    submit = () => {
        const list = this.state.fields.ProductServiceTypeID.value === this.INVENTORY ? ["ProductService", "InventoryCount", "InventoryAccountID"] : ["ProductService"];

        this.setState({
            fields: FieldsManager.validateFields(this.state.fields, list),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields, list)) {
                if (this.props.editedItem?.ProductServiceID) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            ExpenseAccountID: this.state.fields.ExpenseAccountID.value.value,
                            VendorID: this.state.fields.VendorID.value.value,
                            IncomeAccountID: this.state.fields.IncomeAccountID.value.value,
                            ProductServiceCategoryID: this.state.fields.ProductServiceCategoryID.value?.value,
                            ProductServiceID: this.props.editedItem.ProductServiceID,
                            id: this.props.editedItem.ProductServiceID,
                            ReorderPoint: this.state.fields.ReorderPoint.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.ReorderPoint.value : 0,
                            InventoryCount: this.state.fields.InventoryCount.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.InventoryCount.value : 0,
                        }),
                        piggyResource: Resources.ProductsServices,
                        query: this.props.query,
                        resource: Resources.ProductsServices,
                        file: this.state.files,
                        fileResource: Resources.AccountingProdServicesImage,
                        errorMessage: true,
                        successMessage: this.props.translate("text.product_service_information_saved"),
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            ExpenseAccountID: this.state.fields.ExpenseAccountID.value?.value,
                            VendorID: this.state.fields.VendorID.value?.value,
                            IncomeAccountID: this.state.fields.IncomeAccountID.value?.value,
                            ProductServiceCategoryID: this.state.fields.ProductServiceCategoryID.value?.value,
                            ReorderPoint: this.state.fields.ReorderPoint.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.ReorderPoint.value : 0,
                            InventoryCount: this.state.fields.InventoryCount.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.InventoryCount.value : 0,
                        }),
                        resource: Resources.ProductsServices,
                        query: this.props.query,
                        piggyResource: Resources.ProductsServices,
                        file: this.state.files,
                        fileResource: Resources.AccountingProdServicesImage,
                        errorMessage: true,
                        successMessage: this.props.translate("text.product_service_information_saved"),
                    }));

                    if (this.props.setCreatedItemName) {
                        this.props.setCreatedItemName(this.state.fields.ProductService.value)
                    }
                }

                this.close();
            }
        });
    }

    /** UI Events
     ================================================================= */
    handleAssetInputChange = (name, value) => {
        let fields = this.state.assetFields;
        if (name === 'ProductServiceTypeID') {
            fields = FieldsManager.resetFieldsErrors(fields)
        }

        this.setState({
            assetFields: FieldsManager.updateField(fields, name, value)
        });
    };

    close = () => {
        this.props.onCloseProductServiceInfoDialog();
    }
    handleTabChange = (resource) => {
        if (!this.props.editedItem.ProductServiceID) {
            if (resource === this.state.selectedTab) {
                return false
            }

            this.setState({
                tabs: this.state.tabs.map((it) => {
                    it.current = it.resource === resource
                    return it
                }),
                selectedTab: resource
            })

            if (resource === 'Service') {
                this.handleInputChange("ProductServiceTypeID", 3)
            }

            if (resource === 'Inventory') {
                this.handleInputChange("ProductServiceTypeID", 1)
            }

            if (resource === 'NonInventory') {
                this.handleInputChange("ProductServiceTypeID", 2)
            }
            if (resource === 'Asset') {
                this.handleInputChange("ProductServiceTypeID", 4)
            }
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <ModalDefault
                show={true}
                title={translate("modal_heading.product_service_information")}
                widthClass={"max-w-5xl"}
                buttonLabel={translate("btn.save")}
                onButtonClick={this.submit}
                onClose={this.close}
                closeButtonLabel={translate("btn.cancel")}
                translate={translate}
            >
                {/*<ModalDefault*/}
                {/*    isLoading={this.props.thirdResource.isLoading}*/}
                {/*    large*/}
                {/*    title={translate("modal_heading.product_service_information")}*/}
                {/*    close={this.close}*/}
                {/*    closeButtonLabel={translate('btn.cancel')}*/}
                {/*    onClose={this.close}*/}
                {/*    primaryButtonLabel={translate('Save')}*/}
                {/*    onPrimaryButtonClick={this.submit}*/}
                {/*    {...this.props}*/}
                {/*>*/}
                <div className="mb-5 hidden sm:block">
                    <NavResponsive
                        tabs={this.state.tabs}
                        onTabChange={this.handleTabChange}
                        translate={translate}
                    />
                </div>

                <div className="p-5 block sm:hidden">
                    <MobileTabs
                        tabs={this.state.tabs}
                        onTabChange={this.handleTabChange}
                        translate={translate}
                    />
                </div>

                {(this.state.fields.ProductServiceTypeID.value !== 4) && (
                    <div className="p-5">
                        <div className="grid grid-cols-12 gap-4">
                            <div className="md:col-span-7 col-span-full grid grid-cols-12 gap-4">
                                <div className="md:col-span-8 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ProductService}
                                        translate={translate}
                                    >
                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ProductService}
                                            placeholder={""}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-4 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.SKU}
                                        translate={translate}
                                    >
                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SKU}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ProductServiceCategoryID}
                                        translate={translate}
                                    >
                                        <FieldDropdownSelect
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ProductServiceCategoryID}
                                            addClass="form-control"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.ProductsServicesCategory, {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((it) => {
                                                                    return {
                                                                        label: it.ProductServiceCategory,
                                                                        value: it.ProductServiceCategoryID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </FieldContainer>
                                </div>
                            </div>

                            <div className="md:col-span-5 col-span-full">
                                <div className="relative">
                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            this.setState({
                                                files: acceptedFiles.map(file => Object.assign(file, {
                                                    preview: URL.createObjectURL(file)
                                                })),
                                                canSubmit: true
                                            });
                                        }}
                                        //     onDragEnter={() => null}
                                        // onDragLeave={() => {
                                        //     this.setState({dropzoneActive: false, dropzoneRejected: false})
                                        // }}
                                        // onDropAccepted={() => {
                                        //     this.setState({dropzoneActive: true})
                                        // }}
                                        // onDropRejected={() => {
                                        //     this.setState({dropzoneRejected: true})
                                        // }}
                                        multiple={false}
                                        maxFiles={1}
                                        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                        accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                                    >
                                        {({getRootProps, getInputProps, isDragReject, isDragAccept, isDragActive}) => (
                                            <section>
                                                <div {...getRootProps()}
                                                     className={
                                                         classNames(
                                                             "rounded-card flex justify-center items-center border-2 border-dashed h-32",
                                                             isDragAccept ? "border-green-600" : undefined,
                                                             isDragReject ? "border-red-600" : undefined,
                                                             isDragActive ? "border-primary" : undefined,
                                                             !isDragAccept && !isDragReject && !isDragActive ? "border-tm-gray-300" : undefined,
                                                         )
                                                     }>
                                                    <div>
                                                        <input {...getInputProps()} />
                                                        <div className="p-4 text-center text-break">
                                                            <em>{translate("field.image_types")}</em>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {!!this.state.files[0]?.preview && (
                                        <button type="button"
                                                className="close absolute top-0 right-0 mr-1 color-link"
                                                aria-label="Close"
                                                onClick={() => {
                                                    this.setState({files: []})
                                                }}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/*Files start*/}
                        <br/>
                        <img
                            className={"bg-inverse img-thumbnail border-0 mb-4 list-thumbnail"}
                            src={
                                (this.state.files.length > 0) ? this.state.files[0].preview : this.props.editedItem?.ProductServiceID > -1 ? Env.getApiUrl("api/" + Resources.AccountingProdServicesImage, {
                                    token: getJWT().access_token,
                                    id: this.props.editedItem.ProductServiceID + "&v=" + (this.props.editedItem.CreateUpdateDate ? this.props.editedItem.CreateUpdateDate.replace(/[^0-9]/g, '') : null) // version is to prevent browser caching from displaying original image after update
                                }) : ""
                            }
                            alt={""}
                        />

                        {/*Income start*/}
                        <br/>

                        <div className="flex justify-between border-b border-tm-gray-200 mb-5 py-0.5">
                            <h5 className="text-lg leading-6 font-medium text-tm-gray-900">{translate('text.income')}</h5>

                            <div className="flex items-center gap-2">
                                <FieldCheckbox
                                    id="IsSelling"
                                    className="checkbox"
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.IsSelling}
                                />

                                <label className="cursor-pointer"
                                       htmlFor='IsSelling'>{translate('field.IsSelling')}</label>
                            </div>
                        </div>

                        {!!this.state.fields.IsSelling.value && (
                            <div className="grid grid-cols-12 gap-4">
                                <div className="md:col-span-6 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.IncomeAccountID}
                                        translate={translate}
                                    >
                                        <FieldDropdownSelect
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.IncomeAccountID}
                                            addClass="form-control"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.Accounts, {
                                                            query: inputValue,
                                                            MainAccountTypeID: MAJOR_ACCOUNT_TYPE_INCOME
                                                        }),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((it) => {
                                                                    return {
                                                                        label: it.AccountName,
                                                                        value: it.AccountID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-6 col-span-full">
                                    <span className="form-group has-float-label mb-4">
                                        {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.fields, ['IncomePrice']))), translate, this.handleInputChange)}
                                    </span>
                                </div>

                                <div className="col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.IncomeDescription}
                                        translate={translate}
                                    >
                                        <FieldTextarea
                                            rows={3}
                                            addClass="form-control whitespace-pre-wrap"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.IncomeDescription}
                                            placeholder={"Description on sales form"}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        )}
                        {/*Income end*/}

                        {/*Expense start*/}
                        <br/>

                        <div className="flex justify-between border-b border-tm-gray-200 mb-5 py-0.5">
                            <h5 className="text-lg leading-6 font-medium text-tm-gray-900">{translate('text.expense')}</h5>

                            <div className="flex items-center gap-2">
                                <FieldCheckbox
                                    id="IsPurchasing"
                                    className="checkbox"
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.IsPurchasing}
                                />

                                <label className="cursor-pointer"
                                       htmlFor='IsPurchasing'>{translate('field.IsPurchasing')}</label>
                            </div>
                        </div>

                        {!!this.state.fields.IsPurchasing.value && (
                            <div className="grid grid-cols-12 gap-4">
                                <div className="md:col-span-6 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ExpenseAccountID}
                                        translate={translate}
                                    >
                                        <FieldDropdownSelect
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ExpenseAccountID}
                                            addClass="form-control"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.Accounts, {
                                                            query: inputValue,
                                                            MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                                                        }),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((it) => {
                                                                    return {
                                                                        label: it.AccountName,
                                                                        value: it.AccountID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-6 col-span-full">
                                    <span className="form-group has-float-label mb-4">
                                        {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.fields, ['ExpensePrice']))), translate, this.handleInputChange)}
                                    </span>
                                </div>

                                <div className="col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ExpenseDescription}
                                        translate={translate}
                                    >
                                        <FieldTextarea
                                            rows={3}
                                            addClass="form-control whitespace-pre-wrap"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ExpenseDescription}
                                            placeholder={"Description on purchase form"}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-6 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.VendorID}
                                        translate={translate}
                                    >
                                        <FieldDropdownSelect
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.VendorID}
                                            addClass="form-control"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.Vendors, {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((it) => {
                                                                    if (it.VendorID === this.state.fields.VendorID.value) {
                                                                        this.setState({
                                                                            fields: FieldsManager.updateField(this.state.fields, "VendorID", {
                                                                                label: it.LegalName + ", " + it.AddressName,
                                                                                value: it.VendorID
                                                                            })
                                                                        })
                                                                    }
                                                                    return {
                                                                        label: it.LegalName + ", " + it.AddressName,
                                                                        value: it.VendorID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        )}
                        {/*Expense end*/}
                        <br/>
                    </div>
                )}

                {this.state.fields.ProductServiceTypeID.value === 1 && (
                    <div className="p-5">
                        <div className="flex justify-between border-b border-tm-gray-200 mb-5 py-0.5">
                            <h5 className="text-lg leading-6 font-medium text-tm-gray-900">{translate('text.inventory')}</h5>
                        </div>

                        <div className="grid grid-cols-12 gap-x-8">
                            <div className="md:col-span-4 col-span-full">
                                <FieldContainer
                                    item={this.state.fields.InventoryAccountID}
                                    translate={translate}
                                >
                                    <FieldDropdownSelect
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.InventoryAccountID}
                                        addClass="form-control"
                                        defaultOptions={true}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                axios.get(
                                                    Env.getApiUrl("api/" + Resources.Accounts, {query: inputValue}),
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + getJWT().access_token
                                                        }
                                                    }
                                                )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((it) => {
                                                                return {
                                                                    label: it.AccountName,
                                                                    value: it.AccountID
                                                                };
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                            }
                                        }
                                    />
                                </FieldContainer>
                            </div>

                            <div className="md:col-span-4 col-span-full">
                                <FieldContainer
                                    item={this.state.fields.ReorderPoint}
                                    translate={translate}
                                >
                                    <FieldText
                                        addClass="form-control"
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.ReorderPoint}
                                        placeholder={0}
                                    />
                                </FieldContainer>
                            </div>

                            <div className="md:col-span-4 col-span-full">
                                <FieldContainer
                                    item={this.state.fields.InventoryCount}
                                    translate={translate}
                                >
                                    <FieldText
                                        addClass="form-control"
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.InventoryCount}
                                        placeholder={0}
                                    />
                                    {/*<span>{translate('field.InventoryCount')} {this.state.fields.ProductServiceTypeID.value === 1 ? "*" : ""}</span>*/}
                                </FieldContainer>
                            </div>
                        </div>
                    </div>
                )}

                {(this.state.fields.ProductServiceTypeID.value === 4) && (
                    <>
                        {!!this.props.sellAsset && (
                            <div
                                className={classNames("grid gap-4 p-6", "grid-cols-6", "grid gap-4")}>

                            </div>
                        )}

                        {!!this.props.buyAsset && (
                            <div
                                className={classNames("grid gap-4 p-6", "grid-cols-6", "grid gap-4")}>
                                {fieldsToHtml(Object.values(Object.assign({}, this.state.assetFields)), translate, this.handleAssetInputChange, {
                                    AccountID: {
                                        api: 'api/' + Resources.Accounts,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.AccountID,
                                            label: item.AccountNumber + " " + item.AccountName
                                        })
                                    },
                                    AccumulatedAccountID: {
                                        api: 'api/' + Resources.Accounts,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.AccountID,
                                            label: item.AccountNumber + " " + item.AccountName
                                        })
                                    },
                                    DepExpenseAccountID: {
                                        api: 'api/' + Resources.Accounts,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.AccountID,
                                            label: item.AccountNumber + " " + item.AccountName
                                        })
                                    },
                                })}
                            </div>
                        )}


                    </>
                )}
            </ModalDefault>
        )
    }
}
