import React, {useEffect, useRef, useState} from "react";
import {classNames} from "../../util/util-helpers";

// Top of the parent and sticky component must intersect (top must be negative, correct with padding on stickyClass)

export default function StickyElement({ children, className, stickyClass, notStickyClass}) {

    const [isSticky, ref] = useDetectSticky()
    return (
        <div
            className={
                classNames(
                    "sticky",
                    className,
                    isSticky ? stickyClass : notStickyClass
                )
            }
            ref={ref}>
            {children}
        </div>
    )
}

const useDetectSticky = (ref, observerSettings = {threshold: [1]}) => {
    const [isSticky, setIsSticky] = useState(false)
    const newRef = useRef()
    ref ||= newRef;

    // mount
    useEffect(()=>{
        const cachedRef = ref.current,
            observer = new IntersectionObserver(
                ([e]) => {
                    setIsSticky(e.intersectionRatio < 1)
                },
                observerSettings
            )

        observer.observe(cachedRef)

        // unmount
        return () => {
            observer.unobserve(cachedRef)
        }
    }, [])

    return [isSticky, ref, setIsSticky];
}