import React, {useEffect, useState} from "react";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {getProp, resourceIsCreated} from "../../../common/util/util-helpers";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import {Field, FieldsManager} from "../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../util/util-constants'
import Card from "../../../common/components/card";
import {LoaderSmall} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";

const FuelTaxSettingsTab = ({translate}) => {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);

    /** Constants
     ================================================================= */
    const data = getProp(resource, 'data', {})
    const isLoading = getProp(resource, 'isLoading', false)

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            MexicoPermitNumber: new Field('MexicoPermitNumber', '', [], false, 'text'),
            OregonPermitNumber: new Field('OregonPermitNumber', '', [], false, 'text'),
            EFSImportOrganizationID: new Field('EFSImportOrganizationID', '', [], false, 'select-search', {addContainerClass: "col-span-6"}),
            EFSDirectImportOrganizationID: new Field('EFSDirectImportOrganizationID', '', [], false, 'select-search', {addContainerClass: "col-span-6"}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields(data))
    const [canSubmit, setCanSubmit] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.MileageTaxSettings
    }

    const getQuery = () => {
        return {}
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: getQuery(),
            resource: getResourceName()
        }))
    }

    const onSubmit = () => {
        let validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            let params = FieldsManager.getFieldKeyValues(validatedFields);

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                request: 'PUT',
                params: params,
                resource: getResourceName(),
                piggyResource: getResourceName(),
                query: getQuery(),
                errorMessage: true, successMessage: translate('text.tax_settings_update_success')
            }))
        } else {
            setFields(validatedFields)
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
        setCanSubmit(true)
    }

    const handleFormCancel = () => {
        setFields(getFields(data))
        setCanSubmit(false)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        !isLoading && setFields(getFields(data))
    }, [isLoading])

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setCanSubmit(false);
        }
    })

    /** Render
     ================================================================= */
    const fieldsHtml = fieldsToHtml(Object.values(fields), translate, handleInputChange, {
        EFSImportOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        },
        EFSDirectImportOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        }
    });

    return (
        <div className="space-y-8">
            <TabTitle
                text={translate('text.MileageTaxSettings')}
            />

            <Card bodyClass={"py-8 px-6 space-y-4"}>
                {isLoading &&
                    <div className={'flex justify-center py-3'}>
                        <LoaderSmall/>
                    </div>
                }

                <div
                    className={'grid grid-cols-2 gap-4'}>
                    {!isLoading && fieldsHtml}
                </div>

                <PageFooter
                    translate={translate}
                    canSubmit={canSubmit}
                    actionCancel={handleFormCancel}
                    actionSubmit={onSubmit}
                />
            </Card>
        </div>
    )
}

export default FuelTaxSettingsTab
