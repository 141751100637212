import { BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { classNames } from '../../../util/util-helpers';

export default function HeaderSortButton({sort, sortBy, onSortChange, header}) {
    const isColumnSorted = header.key === sortBy;
    const isColumnDesc = isColumnSorted && sort === "DESC";

    return (
        <button 
            className='absolute inset-0 w-full flex items-center z-10 hover:bg-black/5'
            onClick={() => onSortChange(header.key)}
        >
            <span
                className={
                    classNames(
                        isColumnSorted ? "table-th-column-sorted" : "table-th-column-not-sorted",
                        "absolute right-2 z-20 flex items-center rounded",
                    )
                }
            >
                <BarsArrowDownIcon
                    className={classNames(isColumnDesc ? "hidden" : "", isColumnSorted ? "text-inherit" : "text-tm-gray-400", "w-5 h-5 transform table-th-icon")}/>

                <BarsArrowUpIcon
                    className={classNames(isColumnDesc ? "" : "hidden", isColumnSorted ? "text-inherit" : "text-tm-gray-400", "w-5 h-5 transform table-th-icon")}/>
            </span>
        </button>
    )
}
