import React, {useEffect, useRef, useState} from "react";
import {classNames} from "../../../util/util-helpers";
import {numberWithCommasToBack} from "../../../../util/util-formaters";
import PencilAltOutlineIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

export default function FieldCellMoney({
                                              classFocused,
                                              classNotFocused,
                                              value = numberWithCommasToBack(0).toLocaleString('en-US', {
                                                  style: 'currency',
                                                  currency: 'USD'
                                              }).replace("$", ""),
                                              name,
                                              onChange,
                                              disabled,
                                              errorMessage
                                          }) {
    const decimal = value.toString().split(".")[1];
    const hasErrors = !!errorMessage?.length;

    const inputRef = useRef();
    const prevValue = useRef(value.toString() ?? "");           // Initial and useEffect
    const prevDecimalValue = useRef(decimal);        // Initial and useEffect
    const prevSign = useRef(Number(value) < 0 ? "-" : "+")  // Initial and useEffect

    const [isFocused, setIsFocused] = useState(false);
    const [innerValue, setInnerValue] = useState(value);        // Initial and useEffect


    const [isNegative, setIsNegative] = useState(Number(value) < 0); // Initial and useEffect

    const inputClass = classNames(
        isFocused
            ? classFocused ?? "form-control text-xs text-right pl-2 pr-4"
            : classNotFocused ?? "text-right text-tm-gray-700 w-full border border-transparent focus:border-primary placeholder:text-tm-gray-500 bg-transparent focus:shadow-sm py-1 px-1.5 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-50 disabled:text-tm-gray-700 focus:text-tm-gray-700 rounded-input"
    )

    function handleInputChange(e) {
        const isNegativeCharacterDeleted = numberWithCommasToBack(e.target.value) === -numberWithCommasToBack(innerValue);
        updateValue(
            e.target.value,
            isNegativeCharacterDeleted ? false : isNegative
        );

        if (!!e.target.value && isNegativeCharacterDeleted) {
            setIsNegative(false);
            setTimeout(function () {
                if (inputRef?.current?.setSelectionRange) {
                    inputRef.current.setSelectionRange(0, 0);
                }
            }, 1);
        }
    }

    function updateValue(originalValue, negative) {
        const position = inputRef?.current?.selectionEnd;
        let adjustPosition = position;

        let val = originalValue.replace(/[^\d.]/g, '');

        let firstDotIndex = val.indexOf('.');

        if (firstDotIndex > -1) {
            val = val.replace(/\./g, (match, index) => index === firstDotIndex ? match : '');
        }

        const splitValue = val.split(".");

        splitValue[0] = Number(splitValue[0]).toLocaleString('en-US');
        splitValue[1] = splitValue[1] ? splitValue[1].substring(0, 2) : "";

        if (splitValue[0] === "0") {
            splitValue[0] = "";
        }

        val = splitValue.filter(it => !!it).join(".")

        if (firstDotIndex > -1 && !splitValue[1].length) {
            val = val + "."
        }

        if (!!prevDecimalValue.current.length && !splitValue[1].length) {
            val = val.replace(/\./g, '')
        }

        prevDecimalValue.current = splitValue[1];

        if ((val.match(/,/g) || []).length > (prevValue.current.match(/,/g) || []).length) {
            adjustPosition = position + 1;
        }

        if (val.length !== 1 && (val.match(/,/g) || []).length < (prevValue.current.match(/,/g) || []).length) {
            adjustPosition = position - 1;
        }

        adjustPosition = adjustPosition < 0 ? 0 : adjustPosition;


        if (isNaN(Number(originalValue.replace(/[.,-]/g, '')))) {
            adjustPosition = position - 1;
        } else {
            if (!!prevValue.current && !!val && prevSign.current === "-" && !negative) {
                adjustPosition = adjustPosition - 1
            }

            if (!!prevValue.current && !!val && prevSign.current === "+" && !!negative) {
                adjustPosition = adjustPosition + 1
            }

            prevValue.current = val;

            // Exceptions
            if (!val) {
                val = "0";
                adjustPosition = adjustPosition + 1;
            }

            if (val && originalValue.length === 1 && negative) {
                adjustPosition = adjustPosition + 1;
            }

            setInnerValue(
                negative ? "-" + val : val
            );

            if (!originalValue) {
                setIsNegative(false);
            }
        }

        setTimeout(function () {
            prevSign.current = negative ? "-" : "+";
            if (inputRef?.current?.setSelectionRange) {
                inputRef.current.setSelectionRange(adjustPosition, adjustPosition);
            }
        }, 0);
    }

    function handleFocus() {
        if (!disabled) {
            setIsFocused(true);
            updateValue(innerValue, isNegative);
            setTimeout(function () {
                if (inputRef?.current?.setSelectionRange) {
                    inputRef.current.setSelectionRange(0, inputRef.current?.value?.length)
                }
            }, 0);
        }
    }

    function handleKeyDown(e) {
        const val = (e.target.value ?? "");

        if (e.key === "Backspace" && Math.abs(val).toString().length === 1) {
            updateValue("", false);
        }

        if (e.key === "-") {
            e.preventDefault();
            setIsNegative(!isNegative);
            updateValue(val, !isNegative);
        }

        if (e.key === "+") {
            e.preventDefault();
            setIsNegative(false);
            updateValue(val, false);
        }

        if (e.key === "Enter") {
            if (isFocused) {
                onChange(name, numberWithCommasToBack((innerValue ?? "").toString().replace("$", "")));
                setInnerValue((val) => numberWithCommasToBack(val).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).replace("$", ""));
            } else {
                setIsFocused(true);
            }
        }
    }

    function handleClick() {
        if (!isFocused) {
            setIsFocused(true);
        }
    }

    function handleBlur() {
        setIsFocused(false);
        // if (onBlur) {
        //     let val = numberWithCommasToBack(innerValue);
        //     val = val === "-0" ? 0 : val;
        //     onBlur(val);
        // }

        onChange(name, numberWithCommasToBack((innerValue ?? "").replace("$", "")));
        setInnerValue((val) => numberWithCommasToBack((val ?? "").replace("$", "")).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        }).replace("$", ""));
    }

    useEffect(() => {
        prevValue.current = value.toString();
        prevDecimalValue.current = (decimal ?? "").toString();
        prevSign.current = Number(value) < 0 ? "-" : "+";

        setInnerValue(numberWithCommasToBack((value ?? "").toString()).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        }).replace("$", ""));
        setIsNegative(Number(value) < 0);
    }, [value]);

    return (
        <label
            className="bg-inherit w-full relative flex h-full items-center" onClick={(e) => {
            e.stopPropagation();
        }}
        >
            <div className="pointer-events-none absolute -left-1 top-0 flex items-center h-9 pl-3"><span className="text-gray-500 sm:text-sm">$</span></div>

            <input
                className={inputClass}
                ref={inputRef}
                type="text"
                name={name ?? ""}
                value={innerValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                disabled={disabled}
                onBlur={handleBlur}
            />

            {!isFocused && !hasErrors && !disabled && (
                <PencilAltOutlineIcon className="w-5 h-5 text-tm-gray-400 absolute -right-5 top-1/2 -translate-y-1/2"/>
            )}


            {(!isFocused && !!hasErrors) && (
                <>
                    <ExclamationCircleIcon className="w-5 h-5 text-red-600 absolute -right-4 top-1/2 -translate-y-1/2"/>
                    <div className="bg-red-600/10 absolute inset-0 -right-5 rounded-btn"/>
                </>
            )}


        </label>
    );
}
