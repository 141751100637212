import React, {useState} from 'react';
import LocalStorage from "../../../util/localStorage";
import Card from "../../card";
import {WorldClock} from "./world-clock";
import {Field, FieldsManager} from "../../../../data/services/fields";
import CardSubTitle from "../../card/card-sub-title";
import {classNames, getProp} from "../../../util/util-helpers";
import {fieldsToHtml} from "../../../util/util-fields";

let timezone = require('../../../../assets/json-helpers/timezone.json');

const WidgetWorldClocks = ({translate, isCompact}) => {
    const user = LocalStorage.get('user')
    const userTimezone = getProp(user, "Contact.Timezone", "UTC") ?? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    // Helpers
    const readUserSettings = () => {
        return LocalStorage.has('activeLocations') ? JSON.parse(LocalStorage.get('activeLocations')) : null;
    }

    const getActiveLocations = () => {
        let savedLocations = readUserSettings();

        if (savedLocations === null) {
            return [{
                timezone: userTimezone,
            }]
        } else if (savedLocations?.length > 0) {
            return savedLocations
        }

        return [];
    }
    const getTimezones = () => {
        const activeLocations = getActiveLocations();

        if (activeLocations) {
            return Object.keys(timezone)
                .reduce((memo, it) => {
                    if (!activeLocations.some(elem => elem.timezone === it)) {
                        memo[it] = formatTimezoneName(it)
                    }
                    return memo
                }, {})
        }
    }

    const getFields = () => {
        return {
            Location: new Field('Location', '', [], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {values: getTimezones()})
        }
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
        onAddLocationHandler(value)
        setFields(getFields())
    }

    const formatTimezoneName = (fullLocationString) => {
        let formattedTimezoneName = fullLocationString.replaceAll("/", " / ").replaceAll("_", " ")
        return formattedTimezoneName
    }
    const getCityName = (fullLocationString) => {
        const lastSlashInString = fullLocationString.lastIndexOf('/');
        let city = fullLocationString.substring(lastSlashInString + 1);
        city = city.replaceAll('_', ' ')
        return city
    }

    const [activeLocations, setActiveLocations] = useState(getActiveLocations());
    const [fields, setFields] = useState(getFields())

    const onAddLocationHandler = (location) => {
        let oldLocations = [...activeLocations]

        const addedLocation = {
            timezone: location,
            label: location
        }

        oldLocations.push({timezone: addedLocation.timezone})
        saveLocationList(oldLocations)
    }

    const onRemoveLocationHandler = (i) => {
        let oldLocations = [...activeLocations];
        oldLocations.splice(i, 1);
        saveLocationList(oldLocations);

        setFields(getFields())
    }

    const saveLocationList = (list) => {
        setActiveLocations(list);
        LocalStorage.set('activeLocations', JSON.stringify(list))
    }
    const activeLocationsHtml = activeLocations.map((item, i) => {
        return (
            <WorldClock
                key={item.timezone}
                timezone={item.timezone}
                translate={translate}
                getCityName={getCityName}
                onDelete={() => onRemoveLocationHandler(i)}
            />
        )
    })

    return (
        <React.Fragment>
            <Card bodyClass="relative py-3 px-6">
                <CardSubTitle
                    addClass={"font-bold text-xl text-tm-gray-700 capitalize"}
                    text={translate('text.WorldClock')}
                />

                <div>
                    <div>
                        {fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, {})}
                    </div>

                    <div
                        className={classNames(!isCompact ? "grid gap-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 grid-rows-3" : "mt-4 space-y-4")}
                    >
                        {activeLocationsHtml}
                    </div>
                </div>
            </Card>
        </React.Fragment>

    )
}

export default WidgetWorldClocks;