
import {Field} from "../../../../data/services/fields";
import {cloneDeep} from "../../../../common/util/util-vanilla";
import {showModal} from "../../../../data/actions/ui";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {getContactResource} from "../../../../data/actions/contactResource";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {Loader} from "../../../../common/components/loader";
import DisplayDataGrid from "../../../../common/components/display-data/display-data-grid";
import ResourceTable from "../../../../common/components/resource-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";

export default function GroupsViewDialog({isGroupInfoDialogOpen, groupInfoData = {}, groupID, onClose, translate}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const contactResource = useSelector((state) => state.contactResource);
    const dialogResource = useSelector((state) => state.dialogResource);

    /** State
     ================================================================= */
    const [contactInputValue, setContactInputValue] = useState('');
    const [contactList, setContactList] = useState([]);
    const [contactListLimit, setContactListLimit] = useState(10); // Selecting limit can be added
    const [contactListOffset, setContactListOffset] = useState(0);
    const [contactListCount, setContactListCount] = useState(0);

    /** Data events
     ================================================================= */
    const fetchGroupData = () => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ContactGroups,
            query: {searchFields: JSON.stringify({ContactGroupID: groupID})}
        }))
    }

    // TaskGroupID
    const fetchGroupContactsData = () => {
        dispatch(getContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.ContactGroupContacts,
            query: {id: groupInfoData?.ContactGroupID || groupID}
        }))
    }

    /** UI events
     ================================================================= */
    const handleQueryInputChange = (name, value) => {
        if (name === 'offset') {
            setContactListOffset(value);
        }
    }

    const handleContactQueryChange = (e) => {
        setContactInputValue(e.target.value);
        const results = [];

        if (!!contactResource?.data?.list?.length) {
            contactResource.data.list.forEach(it => {
                if (
                    it.FirstName.toLowerCase().search(e.target.value.toLowerCase()) > -1
                    || it.LastName.toLowerCase().search(e.target.value.toLowerCase()) > -1
                    || it.Email.toLowerCase().search(e.target.value.toLowerCase()) > -1
                ) {
                    results.push(it)
                }
            })
        }

        setContactList(results);
        setContactListCount(results.length);
        setContactListOffset(0);
    }

    /** UI events
     ================================================================= */
    useEffect(() => {
        if (contactResource?.data && !contactResource?.isLoading && !!contactResource.data?.list) {
            setContactList(cloneDeep(contactResource.data.list));
            setContactListCount(contactResource.data.count);
        }
    }, [contactResource]);

    useEffect(() => {
        if (isGroupInfoDialogOpen) {
            fetchGroupContactsData();
        }

        if (isGroupInfoDialogOpen && !groupInfoData?.ContactGroupID && !!groupID) {
            fetchGroupData();
        }
    }, [isGroupInfoDialogOpen]);

    /** Render
     ================================================================= */

    let groupData = {};

    if (groupInfoData?.ContactGroupID) {
        groupData = groupInfoData;
    } else if (dialogResource?.data?.list && Array.isArray(dialogResource?.data?.list)) {
        groupData = dialogResource.data.list.find(it => groupID === it.ContactGroupID);
    }

    return (
        <ModalDefault
            show={isGroupInfoDialogOpen}
            title={"Group details"}
            widthClass={"max-w-5xl w-screen"}
            hideDialogFooter={true}
            onClose={onClose}
            closeButtonLabel={translate("btn.close")}
            translate={translate}
        >
            <div className="relative">
                {dialogResource.isLoading && (
                    <Loader stripesBg/>
                )}
                <DisplayDataGrid
                    addGridClass={"grid-cols-3 border-b border-tm-gray-300 p-5 text-tm-gray-700 font-bold"}
                    displayList={Object.values({
                        ContactGroupName: new Field('ContactGroupName', '', [''], false),
                        ContactGroupManager: new Field('ContactGroupManager', '', [''], false),
                        ContactGroupEmail: new Field('ContactGroupEmail', '', [''], false),
                    })}
                    data={groupData ?? {}}
                    translate={translate}
                />
            </div>

            <div className="py-2 px-4 bg-tm-gray-50 border-b border-tm-gray-300">
                <div className="max-w-full w-72">
                    <input
                        type="text"
                        placeholder={translate("text.search")}
                        className="form-control"
                        value={contactInputValue}
                        onChange={handleContactQueryChange}
                    />
                </div>
            </div>

            <ResourceTable
                commonTable={true}
                data={cloneDeep(contactList).slice(contactListOffset, contactListOffset + contactListLimit)}
                count={contactListCount}

                fields={{
                    FirstName: new Field('FirstName', '', [''], false),
                    LastName: new Field('LastName', '', [''], false),
                    Email: new Field('Email', '', [''], false),
                }}
                translate={translate}
                isLoading={contactResource?.isLoading}

                onRowClick={(it) => dispatch(showModal('ViewContactCard', {ContactID: it.ContactID}))}
                onView={(it) => dispatch(showModal('ViewContactCard', {ContactID: it.ContactID}))}
            />

            <TableCardFooter>
                <Pagination
                    count={contactListCount}
                    isLoading={contactResource?.isLoading}
                    handleQueryChange={handleQueryInputChange}
                    pageLimit={contactListLimit}
                    pageOffset={contactListOffset}
                    translate={translate}
                />
            </TableCardFooter>
        </ModalDefault>
    )
}