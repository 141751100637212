import React, {Component} from "react";
import Tippy from "@tippyjs/react";
import EllipsisVerticalIcon from "@heroicons/react/20/solid/EllipsisVerticalIcon";
import {Switch} from "@headlessui/react";
import {cloneDeep, dirtyClone} from "../../../util/util-vanilla";
import {TABLE_SETTINGS_SUF} from "../../../util/util-consts";
import LocalStorage from "../../../util/localStorage";
import ModalDefault from "../modal-default";
import {classNames} from "../../../util/util-helpers";

export default class TableColumnsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { // spread operator doesn't clone
            itemIndexDragged: -1,
            tableColumns: cloneDeep(this.props.tableColumns),
            dirty: false
        }

        this.localStorageKey = this.props.pagePath + TABLE_SETTINGS_SUF;
    }

    close = () => {
        this.props.onToggleColumnSettingsDialog()
    }

    handleToggleSettingClick = (i, show) => {
        const tableColumns = this.state.tableColumns

        tableColumns[i].show = !show

        this.setState({
            tableColumns,
            dirty: true
        });
    }

    handleUndoClick = () => {
        this.setState({
            tableColumns: [...cloneDeep(this.props.tableColumns)],
            dirty: false
        });
    }

    handleSaveClick = () => {
        LocalStorage.set(this.localStorageKey, this.state.tableColumns);

        this.props.setTableColumns(this.state.tableColumns);
        this.close(true)
    }

    handleDragStart = (i) => {
        this.setState({itemIndexDragged: i})
    }

    handleDrop = () => {
        this.setState({itemIndexDragged: -1})
    }

    handleDragEnter = (i) => {
        let columnOrder = dirtyClone(this.state.tableColumns);

        [columnOrder[this.state.itemIndexDragged], columnOrder[i]] = [columnOrder[i], columnOrder[this.state.itemIndexDragged]];

        columnOrder[0].show = true;

        this.setState({
            tableColumns: columnOrder,
            itemIndexDragged: i,
            dirty: true
        })
    }

    render() {
        const {translate, pagePath, show} = this.props;

        const settingsList = this.state.tableColumns.map((item, index) => {

            const itemName = item.name;

            return (
                <tr
                    key={itemName}
                    onDragEnter={() => this.handleDragEnter(index)}
                    onDrop={this.handleDrop}
                >
                    <td className={classNames("p-3 rounded-tl-md rounded-bl-md",
                        this.state.itemIndexDragged === index ? " bg-tm-gray-100" : "")}
                    >
                        <div
                            className="flex items-center justify-center h-8 w-8 rounded-full hover:bg-tm-gray-200 cursor-move"
                            draggable
                            onDragStart={() => this.handleDragStart(index)}
                        >
                            <EllipsisVerticalIcon className="w-5 h-5 text-tm-gray-700 -mr-3"/>
                            <EllipsisVerticalIcon className="w-5 h-5 text-tm-gray-700"/>
                        </div>
                    </td>

                    <td
                        onClick={() => !index ? null : this.handleToggleSettingClick(index, item?.show)}
                        className={classNames("p-3 relative", this.state.itemIndexDragged === index ? " bg-tm-gray-100" : "")}
                    >
                        {!index && (
                            <div
                                className="bg-stripes w-14 h-14 rounded-full absolute -top-px left-1.5"/>
                        )}

                        <Tippy disabled={!!index} content={translate("text.primary_col_can_not_be_disabled")}>
                            <div>
                                <Switch
                                    checked={item?.show}
                                    onChange={() => null}
                                    className={classNames(
                                        item?.show ? 'bg-primary' : 'bg-tm-gray-200',
                                        'relative inline-flex items-center h-6 rounded-full w-11 transition-colors',
                                        !index ? "opacity-25" : "")}
                                >
                                    <span className="sr-only">Toggle {itemName}</span>

                                    <span
                                        className={`${
                                            item?.show ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-5 h-5 transform bg-white rounded-full transition-transform`}
                                    />
                                </Switch>
                            </div>
                        </Tippy>
                    </td>

                    <td
                        className={classNames("text-sm p-3 rounded-tr-md rounded-br-md", this.state.itemIndexDragged === index ? " bg-tm-gray-100" : "")}
                        onClick={() => !index ? null : this.handleToggleSettingClick(index, item?.show)}
                    >
                        <span
                            className={classNames(!index ? "text-tm-gray-700" : "text-primary cursor-pointer", "select-none")}
                        >
                            {translate("table." + (item.columnName ?? item.name))}</span>
                    </td>
                </tr>
            )
        });

        return (
            <ModalDefault
                show={show}
                widthClass={"max-w-xl"}
                title={translate('page.title.' + pagePath) + " " + translate("modal_heading.table_column_settings")}

                onButtonClick={this.handleSaveClick}
                buttonLabel={translate("btn.save")}
                buttonDisabled={!this.state.dirty}

                onSecondaryButtonClick={this.handleUndoClick}
                secondaryButtonLabel={translate("btn.undo")}
                secondaryButtonDisabled={!this.state.dirty}

                closeButtonLabel={translate("btn.cancel")}
                onClose={this.close}
            >
                <div className="p-6 h-dialog-body" style={{minHeight: this.props?.minHeight ? this.props.minHeight : 0}}>
                    <table className="mx-auto">
                        <thead>
                        <tr>
                            <th className="p-3 text-tm-gray-900">{translate("table.order")}</th>
                            <th className="p-3 text-tm-gray-900">{translate("table.enable")}</th>
                            <th className="p-3 text-tm-gray-900">{translate("table.table_column")}</th>
                        </tr>
                        </thead>
                        <tbody className="text-left">
                        {settingsList}
                        </tbody>
                    </table>
                </div>
            </ModalDefault>
        )
    }
}
