import React from "react";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import Tooltip from "../tooltip";
import {LoaderSmall} from "../loader";
import {classNames} from "../../util/util-helpers";

const Button = ({
                    children,
                    tooltip,
                    appearance,
                    className = '',
                    addClass,
                    disabled,
                    onClick,
                    hasPerm = true,
                    isLoading
                }) => {

    if (!className) {
        switch (appearance) {
            case 'border':
                className = "btn btn-icon justify-center p-1 w-9 h-9 border border-tm-gray-300";
                break;
            default:
                className = "btn-icon";
        }
    }

    return <>
        <Tooltip content={tooltip} disabled={!tooltip}>
            <button
                onClick={onClick}
                className={classNames("group relative", className, addClass)}
                disabled={!hasPerm || disabled || isLoading}
                type='button'
            >
                {isLoading && (
                    <LoaderSmall addClass='inset-1'/>
                )}

                {children}

                {!hasPerm && (
                    <Tooltip content={
                        <div>
                            <p>Your account does not have sufficient permissions to perform this action.</p>
                        </div>
                    }>
                        <LockClosedIcon className="absolute inset-1.5 opacity-0 group-hover:opacity-50 w-6 h-6 text-red-600"/>
                    </Tooltip>
                )}
            </button>
        </Tooltip>
    </>
}

export default Button;