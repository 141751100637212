import React, {useEffect, useState} from "react";
import {cloneDeep} from "../../../../common/util/util-vanilla";
import {classNames, getProp, openInNewTab} from "../../../../common/util/util-helpers";
import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import TruckIcon from "@heroicons/react/20/solid/TruckIcon";
import IdentificationIcon from "@heroicons/react/20/solid/IdentificationIcon";
import {Link} from "react-router-dom";
import {BuildingOfficeIcon, ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import {TrailerIcon} from "../../../../data/themes/icons";
import {Cog6ToothIcon} from "@heroicons/react/24/outline";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ButtonsGroup from "../../../../common/components/buttons/buttons-group";
import {Loader, LoaderLarge} from "../../../../common/components/loader";
import {TRUCK_STATUS_RETIRED, TRUCK_STATUS_SOLD} from "../../../../util/util-constants";
import {DRIVER_STATUS_ON_VACATION, TRAILER_STATUS_SOLD} from "../../../../common/util/util-consts";


export default function LoadInfoAssets({
                                           fields,
                                           hasErrors,
                                           warnings,
                                           fieldsData,
                                           lineOfBusiness,
                                           isLineOfBusinessChoiceVisible,
                                           lineOfBusinessSwitchDisabled,
                                           toggleLineOfBusiness,
                                           isDispatched,
                                           canAddSecondTrailer,
                                           canAddThirdTrailer,
                                           resource,
                                           translate,
                                           isSwitchDispatchVisible,
                                           areDriverNotesVisible,
                                           isSecondTrailerVisible,
                                           isThirdTrailerVisible,
                                           areCarrierNotesVisible,
                                           onAddNoteClick,
                                           toggleUnitSelectionDialog,
                                           toggleExpandSection,
                                           isSectionExpanded,
                                           inactiveDriverData,
                                           isCoBrokerVisible,
                                           isLoading,
                                           innerRef,
                                           tailgateParentID,
                                           dispatchHistory,
                                           htmlAfter
                                       }) {
    const DriverID = fieldsData.DriverID;
    const TruckID = fieldsData.TruckID;
    const [dispatchSwitchData, setDispatchSwitchData] = useState([]);
    const latestAssetChanges = dispatchSwitchData.length ? dispatchSwitchData[0] : null;
    const getInactiveDriverMessage = (StatusID) => {
        switch (StatusID) {
            case 4:
                return translate("text.driver_time_off", [warnings?.inactiveDriverData?.Driver]);
            case 6:
                return translate("text.driver_suspended", [warnings?.inactiveDriverData?.Driver]);
            case 7:
                return translate("text.driver_pending", [warnings?.inactiveDriverData?.Driver]);
            case 8:
                return translate("text.driver_terminated", [warnings?.inactiveDriverData?.Driver]);
            case 5:
            default:
                return translate("text.co_driver_is_not_active_driver", [warnings?.inactiveDriverData?.Driver])
        }
    }

    const getInactiveCoDriverMessage = (StatusID) => {
        switch (StatusID) {
            case 4:
                return translate("text.co_driver_time_off", [warnings?.inactiveCoDriverData?.Driver]);
            case 6:
                return translate("text.co_driver_suspended", [warnings?.inactiveCoDriverData?.Driver]);
            case 7:
                return translate("text.co_driver_pending", [warnings?.inactiveCoDriverData?.Driver]);
            case 8:
                return translate("text.co_driver_terminated", [warnings?.inactiveCoDriverData?.Driver]);
            case 5:
            default:
                return translate("text.co_driver_is_not_active_driver", [warnings?.inactiveCoDriverData?.Driver])
        }
    }

    const getInactiveTruckMessage = (StatusID) => {
        switch (StatusID) {
            case TRUCK_STATUS_SOLD:
                return translate("text.selected_truck_sold", [warnings?.inactiveTruck]);
            case TRUCK_STATUS_RETIRED:
                return translate("text.selected_truck_retired", [warnings?.inactiveTruck])
            default:
                return translate("text.selected_truck_out_of_service", [warnings?.inactiveTruck]);
        }
    }

    const getInactiveTrailerMessage = (StatusID) => {
        switch (StatusID) {
            case TRUCK_STATUS_SOLD:
                return translate("text.selected_trailer_sold", [warnings?.inactiveTrailer]);
            case TRUCK_STATUS_RETIRED:
                return translate("text.selected_trailer_retired", [warnings?.inactiveTrailer])
            default:
                return translate("text.selected_trailer_out_of_service", [warnings?.inactiveTrailer]);
        }
    }

    useEffect(() => {
        if (!resource.isLoading) {
            setDispatchSwitchData(cloneDeep(getProp(resource, 'data.load/dispatch', [])));
        }
    }, [resource])

    return (
        <div ref={innerRef} className={
            classNames(
                "grid grid-cols-12 px-6 border-t border-tm-gray-300 relative pt-3 pb-6"
            )
        }>
            <div className="col-span-5 py-3 pr-5">
                <div className="flex items-center">
                    <button
                        className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                        onClick={() => toggleExpandSection("LoadInfoAssets")}
                    >
                        <ChevronRightIcon
                            className={
                                classNames(
                                    "w-5 h-5",
                                    isSectionExpanded ? "rotate-90" : undefined
                                )
                            }
                        />
                    </button>

                    <Subtitle subtitle={translate("text.assets")}/>
                </div>

                {isSectionExpanded && (
                    <div className="mt-3 space-y-2">
                        {hasErrors && (
                            <InfoBar
                                type="danger"
                                addClass="text-sm leading-6"
                                iconClass="w-6 h-6 shrink-0 text-red-600"
                                Icon={ExclamationTriangleIcon}
                                message={translate("text.fill_required_fields")}
                            />
                        )}

                        {!!inactiveDriverData && DriverID === inactiveDriverData?.DriverID && (
                            <div
                                className={
                                    classNames(
                                        "flex gap-2 py-2 text-sm leading-6 px-2 rounded-md relative",
                                        warnings?.inactiveDriverData?.StatusID === DRIVER_STATUS_ON_VACATION
                                            ? "bg-yellow-600/10 hover:bg-yellow-600/20"
                                            : "bg-red-600/10 hover:bg-red-600/20"
                                    )
                                }
                            >
                                <Link
                                    to={"/drivers/info/" + inactiveDriverData.DriverID}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0"
                                />

                                <IdentificationIcon className={
                                    classNames(
                                        "w-6 h-6 shrink-0",
                                        warnings?.inactiveDriverData?.StatusID === DRIVER_STATUS_ON_VACATION ? "text-yellow-600" : "text-red-600"
                                    )}
                                />

                                {getInactiveDriverMessage(warnings?.inactiveDriverData?.StatusID)}
                            </div>
                        )}

                        {!!warnings?.driverOnLoadID && !tailgateParentID && lineOfBusiness === "carrier" && (
                            <div
                                className="flex gap-2 text-sm leading-6 py-2 bg-yellow-600/10 hover:bg-yellow-600/20 px-2 rounded-md relative"
                            >
                                <Link
                                    to={"/loads/info/" + warnings?.driverOnLoadID}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0"
                                />

                                <IdentificationIcon className="w-6 h-6 text-yellow-600"/>

                                {warnings?.driverOnLoadLoadNumber && (
                                    translate("text_driver_already_dispatched", [warnings?.driverOnLoadLoadNumber])
                                )}

                                {!warnings?.driverOnLoadLoadNumber && (
                                    translate("text_driver_already_dispatched_other_load")
                                )}
                            </div>
                        )}

                        {!!warnings?.hasInactiveCoDriver && lineOfBusiness === "carrier" && (
                            <div
                                className={
                                    classNames(
                                        "flex gap-2 text-sm leading-6 py-2 px-2 rounded-md relative",
                                        warnings?.inactiveCoDriverData?.StatusID === DRIVER_STATUS_ON_VACATION
                                            ? "bg-yellow-600/10 hover:bg-yellow-600/20"
                                            : "bg-red-600/10 hover:bg-red-600/20"
                                    )
                                }
                            >
                                <Link
                                    to={"/drivers/info/" + warnings.inactiveCoDriverData.DriverID}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0"
                                />

                                <IdentificationIcon className={
                                    classNames("w-6 h-6 shrink-0",
                                        warnings?.inactiveCoDriverData?.StatusID === DRIVER_STATUS_ON_VACATION
                                            ? "text-yellow-600"
                                            : "text-red-600"
                                    )}
                                />
                                {getInactiveCoDriverMessage(warnings?.inactiveCoDriverData?.StatusID)}
                            </div>
                        )}

                        {!!warnings?.coDriverOnLoadID && !tailgateParentID && !(!!warnings?.driverOnLoadID && !tailgateParentID) && lineOfBusiness === "carrier" && (
                            <div
                                className="flex text-sm leading-6 gap-2 py-2 bg-yellow-600/10 hover:bg-yellow-600/20 px-2 rounded-md relative"
                            >
                                <Link
                                    to={"/loads/info/" + warnings?.coDriverOnLoadID}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0"
                                />

                                <IdentificationIcon className="w-6 h-6 text-yellow-600"/>
                                {warnings?.coDriverOnLoadLoadNumber && (
                                    translate("text_driver_already_dispatched", [warnings?.coDriverOnLoadLoadNumber])
                                )}

                                {!warnings?.coDriverOnLoadLoadNumber && (
                                    translate("text_driver_already_dispatched_other_load")
                                )}
                            </div>
                        )}

                        {!!warnings?.isCarrierBlacklisted && lineOfBusiness === "brokerage" && (
                            <InfoBar
                                Icon={BuildingOfficeIcon}
                                addClass="text-sm leading-6"
                                iconClass="w-6 h-6 shrink-0 text-red-600"
                                type="danger"
                                message={translate(
                                    "text.carrier_is_blacklisted",
                                    [warnings?.blackListedCarrier?.BlacklistUpdatedByContact]
                                )}
                                onClick={() => openInNewTab('/carriers/info/' + warnings?.blackListedCarrier.OrganizationID + "/" + warnings.blackListedCarrier.CarrierID)}
                            />
                        )}

                        {warnings?.isNotPermittedTruck && !!warnings.notPermittedTruck.find(it => it?.Truck?.TruckID === TruckID) && lineOfBusiness === "carrier" && (
                            <React.Fragment>
                                {warnings.notPermittedTruck.map((it, i) => (
                                    <div
                                        key={i}
                                        className="flex text-sm leading-6 gap-2 py-2 bg-red-600/10 hover:bg-red-600/20 px-2 rounded-md relative"
                                    >
                                        <Link
                                            to={"/trucks/info/" + it.Truck.TruckID}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="absolute inset-0"
                                        />

                                        <TruckIcon className="w-6 h-6 text-red-600"/>
                                        {it.Message}
                                    </div>
                                ))}
                            </React.Fragment>
                        )}

                        {!!warnings?.isInactiveTruck && !!warnings?.inactiveTruckID && lineOfBusiness === "carrier" && (
                            <InfoBar
                                Icon={TruckIcon}
                                addClass="text-sm leading-6"
                                iconClass={
                                    classNames(
                                        "w-6 h-6 shrink-0",
                                        warnings.truckStatusID >= TRUCK_STATUS_SOLD ? "text-red-600" : "text-yellow-600"
                                    )
                                }
                                type={warnings.truckStatusID >= TRUCK_STATUS_SOLD ? "danger" : "warning"}
                                message={getInactiveTruckMessage(warnings.truckStatusID)}
                                onClick={() => openInNewTab("/trucks/info/" + warnings?.inactiveTruckID)}
                            />
                        )}

                        {!!warnings?.truckOnLoadID && !tailgateParentID && lineOfBusiness === "carrier" && (
                            <div
                                className="flex text-sm leading-6 gap-2 py-2 bg-yellow-600/10 hover:bg-yellow-600/20 px-2 rounded-md relative"
                            >
                                <Link
                                    to={"/loads/info/" + warnings.truckOnLoadID}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0"
                                />

                                <TruckIcon className="w-6 h-6 text-yellow-600"/>
                                {
                                    warnings?.truckStatusID === 3
                                        ? warnings.truckOnLoadLoadNumber
                                            ? translate("text.truck_already_dispatched", [warnings.truckOnLoadLoadNumber])
                                            : translate("text.truck_already_dispatched_some")
                                        : warnings.truckOnLoadLoadNumber
                                            ? translate("text.truck_already_booked", [warnings.truckOnLoadLoadNumber])
                                            : translate("text.truck_already_booked_some")
                                }
                            </div>
                        )}

                        {!!warnings?.isInactiveTrailer && !!warnings?.inactiveTrailerID && lineOfBusiness === "carrier" && (
                            <InfoBar
                                type={warnings.trailerStatusID >= TRAILER_STATUS_SOLD ? "danger" : "warning"}
                                addClass="text-sm leading-6"
                                iconClass={
                                    classNames(
                                        "w-6 h-6 shrink-0",
                                        warnings.trailerStatusID >= TRAILER_STATUS_SOLD ? "text-red-600" : "text-yellow-600"
                                    )
                                }
                                Icon={TrailerIcon}
                                message={getInactiveTrailerMessage(warnings?.trailerStatusID)}
                                onClick={() => openInNewTab("/trailers/info/" + warnings?.inactiveTrailerID)}
                            />
                        )}

                        {!!warnings?.trailerOnLoadID && lineOfBusiness === "carrier" && (
                            <div
                                className="flex text-sm leading-6 gap-2 py-2 bg-yellow-600/10 hover:bg-yellow-600/20 px-2 rounded-md relative"
                            >
                                <Link
                                    to={"/loads/info/" + warnings.trailerOnLoadID}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0"
                                />

                                <TrailerIcon className="w-6 h-6 text-yellow-600"/>

                                {
                                    warnings?.trailerStatusID === 3
                                        ? warnings.trailerOnLoadLoadNumber
                                            ? translate("text.trailer_already_dispatched", [warnings.trailerOnLoadLoadNumber])
                                            : translate("text.trailer_already_dispatched_some")
                                        : warnings.trailerOnLoadLoadNumber
                                            ? translate("text.trailer_already_dispatched", [warnings.trailerOnLoadLoadNumber])
                                            : translate("text.trailer_already_dispatched_some")
                                }
                            </div>
                        )}

                        {!isLoading && !isDispatched && lineOfBusiness === 'carrier' && !tailgateParentID && (
                            <div className="flex text-sm leading-6 gap-2 py-2 bg-sky-600/10 px-2 rounded-md">
                                <InformationCircleIcon className="w-6 h-6 text-sky-600"/>
                                {translate("text.load_assets_not_dispatched")}
                            </div>
                        )}

                        {!!tailgateParentID && (
                            <div className="flex text-sm leading-6 gap-2 py-2 bg-sky-600/10 px-2 rounded-md">
                                <InformationCircleIcon className="w-6 h-6 text-sky-600"/>
                                {translate("text.load_assets_tailgate")}
                            </div>
                        )}

                        {!isDispatched && lineOfBusiness === 'carrier' && !tailgateParentID && (
                            <div>
                                <button
                                    className="btn flex text-xs gap-2 py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={toggleUnitSelectionDialog}
                                >
                                    <Cog6ToothIcon className="w-5 h-5"/>
                                    {translate("text.select_from_dispatch_manager")}
                                </button>
                            </div>
                        )}

                        {(!!dispatchSwitchData.length && isSwitchDispatchVisible) && (
                            <div className="flex gap-2">
                                <InformationCircleIcon className="w-6 h-6 text-sky-600"/>
                                {translate("text.unit_changed")}
                            </div>
                        )}

                        {dispatchHistory}

                        {areDriverNotesVisible && lineOfBusiness === 'carrier' && (
                            <div>
                                <button
                                    className="btn flex gap-2 text-xs  py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={() => onAddNoteClick("ExternalDriverNotes")}
                                >
                                    <PlusCircleIcon className="w-5 h-5 text-tm-gray-600"/>
                                    {translate("btn.driver_notes")}
                                </button>
                            </div>
                        )}

                        {canAddSecondTrailer && !isSecondTrailerVisible && lineOfBusiness === 'carrier' && (
                            <div>
                                <button
                                    className="btn flex gap-2 text-xs  py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={() => onAddNoteClick("SecondTrailerID", "select-search")}
                                >
                                    <PlusCircleIcon className="w-5 h-5"/>
                                    {translate("btn.add_second_trailer")}
                                </button>
                            </div>
                        )}

                        {canAddThirdTrailer && !isThirdTrailerVisible && lineOfBusiness === 'carrier' && (
                            <div>
                                <button
                                    className="btn flex gap-2 text-xs  py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={() => onAddNoteClick("ThirdTrailerID", "select-search")}
                                >
                                    <PlusCircleIcon className="w-5 h-5"/>
                                    {translate("btn.add_third_trailer")}
                                </button>
                            </div>
                        )}

                        {!isCoBrokerVisible && lineOfBusiness === 'brokerage' && (
                            <div>
                                <button
                                    className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={() => onAddNoteClick("CoBrokerID", 'select-search')}
                                >
                                    <PlusCircleIcon className="w-5 h-5"/>
                                    {translate("btn.add_co_broker")}
                                </button>
                            </div>
                        )}

                        {!areCarrierNotesVisible && lineOfBusiness !== 'carrier' && (
                            <div>
                                <button
                                    className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                    onClick={() => onAddNoteClick("ExternalCarrierNotes")}
                                >
                                    <PlusCircleIcon className="w-5 h-5"/>
                                    {translate("btn.carrier_notes")}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isSectionExpanded && (
                <div className="pl-5 col-span-7 mt-3 space-y-2">
                    {!!isLineOfBusinessChoiceVisible && (
                        <div className="mb-4">
                            <span className="flex items-center text-sm font-semibold text-tm-gray-900">
                                {translate("field.line_of_business")}
                            </span>

                            <div className="flex">
                                <ButtonsGroup
                                    disabled={lineOfBusinessSwitchDisabled}
                                    data={{
                                        carrier: translate('btn.carrier'),
                                        brokerage: translate('btn.brokerage')
                                    }}
                                    value={lineOfBusiness}
                                    onChange={toggleLineOfBusiness}
                                />
                            </div>
                        </div>
                    )}

                    <div className="grid grid-cols-12 gap-4">
                        {fields}
                    </div>

                    {htmlAfter}
                </div>
            )}

            {isLoading && (
                <React.Fragment>
                    {isSectionExpanded && (
                        <LoaderLarge stripesBg={true}/>
                    )}

                    {!isSectionExpanded && (
                        <Loader stripesBg={true}/>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}
