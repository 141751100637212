import React from 'react'
import {classNames} from "../../../../common/util/util-helpers";

import {TrashIcon} from "@heroicons/react/24/outline";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import ModalDefault from "../../../../common/components/modal/modal-default";
import DzItemFile from "../../../../common/components/dropzone/dz-item-file";
import DropZoneAlt from "../../../../common/components/dropzone/drop-zone-alt";
import Card from "../../../../common/components/card";
import {DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS} from "../../../../util/util-constants";


export default function PublicCarrierDocuments({
                                                   onAddDocuments,
                                                   isDocumentUploadAllowed,
                                                   onRemoveDocumentToAdd,
                                                   onRemoveFile,
                                                   onSelectDocuments,
                                                   files,
                                                   filesToAdd,
                                                   isUploadModalOpen,
                                                   onFileInputChange,
                                                   onCancelUpload,
                                                   selects,
                                                   translate
                                               }) {

    return (
        <React.Fragment>
            <div className="max-w-3xl mx-auto pt-8 space-y-4">
                <Card bodyClass={"p-0 space-y-4 relative"}>
                    <div className="space-y-4 max-w-xl mx-auto">
                        <div
                            className="pt-6 text-lg leading-6 font-medium text-tm-gray-900">
                            {translate('card_header.documents')}
                        </div>
                    </div>

                    {isDocumentUploadAllowed && (
                        <DropZoneAlt
                            translate={translate}
                            maxFilesAccepted={10}
                            accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                            onAcceptFiles={onSelectDocuments}
                            content={(isDragAccept, isDragReject) => {
                                return (
                                    <div className={classNames(
                                        ""
                                    )}>
                                        <div className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "pb-4 min-h-[8rem] relative"
                                        )}>
                                            <React.Fragment>
                                                {!files.length && (
                                                    <div
                                                        className="absolute inset-0 flex items-center justify-center border-t-2 border-dashed border-tm-gray-300">
                                                        <div className="text-center">
                                                            <p className="text-lg">click here or drop files to
                                                                upload</p>
                                                            <p className="text-base text-tm-gray-500">(Max 10 files, 25
                                                                mb
                                                                per file, document and image files only)</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {!!files.length && (
                                                    <ul
                                                        className={classNames(
                                                            "rounded-lg mx-4",
                                                            files.length ? "divide-y divide-gray-200 border border-tm-gray-200" : undefined
                                                        )}
                                                    >
                                                        {files.map((file, i) => {
                                                            return (
                                                                <DzItemFile
                                                                    key={i}
                                                                    index={i}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    translate={translate}
                                                                    name={file.name}
                                                                    type={selects?.DocumentType?.[file.fields.DocumentTypeID.value]}
                                                                    description={file.fields.Description.value}
                                                                    onRemoveFile={file.isNew && onRemoveFile}
                                                                />
                                                            )
                                                        })}
                                                    </ul>
                                                )}
                                            </React.Fragment>
                                        </div>

                                        <div
                                            className="px-4 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                            Attach files by dragging and dropping them or <span
                                            className="text-primary-tint font-semibold">click here</span> to select
                                            them.
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    )}
                </Card>
            </div>

            <ModalDefault
                show={isUploadModalOpen}
                widthClass={'max-w-xl'}
                title={translate("modal_heading.upload_confirm")}

                close={onCancelUpload}
                closeButtonLabel={translate('Close')}
                onClose={onCancelUpload}

                buttonDisabled={false}
                buttonLabel={translate('btn.add')}
                onButtonClick={onAddDocuments}
            >
                <div className="">
                    {filesToAdd.map((file, i) => {
                        const itemToHtml = fieldsToHtml(Object.values(file.fields), translate, (name, value) => onFileInputChange(name, value, i), selects)

                        return (
                            <div className={
                                classNames(
                                    "p-6",
                                    i % 2 === 0 ? "bg-tm-gray-50" : undefined,
                                    i ? "border-t border-tm-gray-200" : undefined
                                )
                            }>
                                <div className="flex justify-between">
                                    <div className="flex items-center text-sm font-semibold text-tm-gray-900 break-all">
                                        <label>{translate("text.desc_for_file")}: {filesToAdd[i].name}</label>
                                    </div>
                                    <div className="text-right">
                                        <button
                                            className="btn btn-icon"
                                            onClick={() => onRemoveDocumentToAdd(i)}
                                        >
                                            <TrashIcon className="h-4 w-4"/>
                                        </button>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-4">
                                    {itemToHtml}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </ModalDefault>
        </React.Fragment>
    )
}

// const convertToLocale = (date, isDateOnly) => {
//     const dateObj = new Date(date);
//     if (isDateOnly) {
//         return dateObj.toLocaleString().split(",")[0];
//     }
//
//     return dateObj.toLocaleString()
// }