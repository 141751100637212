import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY, DELETE_PERM} from '../../../../util/util-constants'
import ModalDefault from "../../../../common/components/modal/modal-default";
import LocalStorage from "../../../../common/util/localStorage";
import {checkPerm, getDefaultTableOptions, getProp} from "../../../../common/util/util-helpers";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../../common/components/resource-table";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import {deleteDialogInfoResource, getDialogInfoResource} from "../../../../data/actions/dialogInfoResource";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import {READ_PERM} from "../../../../common/util/util-consts";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";

export default class BatchListDialog extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + 'batch_list_dialog'

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: false,
                verticalLines: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'AutoCounter',
            sort: 'DESC',
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFields(),
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.fetchData();
        }
    }

    fetchData = () => {
        this.props.dispatch(getDialogInfoResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: Resources.InvoicesBatchList
        }))
    }


    getFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text'),
            InvoiceBatch: new Field('InvoiceBatch', '', [], false, 'textarea', {addContainerClass: 'col-span-6'}),
            AssignedToContactID: new Field('AssignedToContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-6'}),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {label: 'Completed'})
        }
    }

    getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            IsPayed: new Field('IsPayed', '', [''], false, 'select', {label: 'Completed'}, {
                values: {
                    0: this.props.translate('No'),
                    1: this.props.translate('Yes'),
                },
                isClearable: true
            }),
            AssignedToContactID: new Field('AssignedToContactID', '', [''], false, 'select-search', {}, {isClearable: true}),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }


    getItemKeyLabel = () => {
        return this.props.itemKeyLabel ?? ''
    }

    getQuery = () => {
        const {
            IsPayed,
            AssignedToContactID
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                ...(!!IsPayed && {IsPayed: ['IsPayed', '=', IsPayed ]}),
                AssignedToContactID: AssignedToContactID
            })
        }
    }

    getResource = () => {
        return Resources.InvoicesBatchList
    }
    handleFilterInputChange = (name, value) => {
        const queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)
        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1,
        }, () => {
            this.fetchData();
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'AutoCounter'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
        })
    }

    handleShowDetails = (it) => {
        this.props.showDetails(it);
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedConfirmItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    deleteBatch = (item = null) => {
        this.props.dispatch(deleteDialogInfoResource({
            user: LocalStorage.get("user"),
            query: {InvoiceBatchID: item.InvoiceBatchID},
            resource: Resources.InvoicesBatch,
            piggyQuery: this.getQuery(),
            piggyResource: Resources.InvoicesBatchList,
            successMessage: this.props.translate('message.invoice_batch_deleted')
        }));
        this.handleToggleConfirmModal();
    }

    render() {
        const {translate, dialogInfoResource} = this.props

        const dialogData = getProp(dialogInfoResource, 'data.list', {})
        const count = getProp(dialogInfoResource.data, 'count', 0)
        const isLoading = getProp(dialogInfoResource, 'isLoading', true);

        const selects = {
            AssignedToContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
        }


        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-7xl'}
                title={this.props.title}
                closeButtonLabel={translate('btn.Close')}
                onClose={this.props.onClose}
            >
                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        selects={selects}
                    />

                    <ResourceTable
                        // maxHeightClass={"max-h-[calc(100vh-29rem)]"}
                        tableKey={'InvoiceBatchID'}
                        data={dialogData}
                        count={count}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                        options={this.state.tableOptions}

                        fields={this.getFields()}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        onRowClick={checkPerm(this.getResource(), READ_PERM) ? this.handleShowDetails : null}
                        onView={(it) => this.handleShowDetails(it)}
                        onDelete={(it) => this.handleToggleConfirmModal(it)}

                        hasViewPerm={checkPerm(Resources.InvoicesBatch, READ_PERM)}
                        hasDeletePerm={checkPerm(Resources.InvoicesBatch, DELETE_PERM)}
                    />
                    <TableCardFooter>
                        <Pagination
                            count={getProp(dialogInfoResource.data, 'count', [])}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    width={"2xl"}
                    text={translate('text.confirm_batch_delete', [this.state.selectedConfirmItem?.AutoCounter])}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (this.state.selectedConfirmItem) {
                            this.deleteBatch(this.state.selectedConfirmItem);
                        }
                    }}
                />
            </ModalDefault>
        )
    }
}

