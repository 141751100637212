import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../../util/util-constants'
import {cloneDeep, scrollErrorIntoView} from '../../../../common/util/util-vanilla'
import {
    getDefaultContactGroup,
    getDefaultTableOptions,
    getDefaultUserOfficeValues,
    getProp,
    openInNewTab
} from '../../../../common/util/util-helpers'
import moment from 'moment'
import {EyeIcon} from '@heroicons/react/24/outline'
import ResourceTable from "../../../../common/components/resource-table";
import ModalDefault from "../../../../common/components/modal/modal-default";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import ActiveFilters from "../../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import DriverStatusBadge from "../../../../common/components/badge/driver-status-badge";
import EmployeeStatusBadge from "../../../../common/components/badge/employee-status-badge";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";

const PAYROLL_BATCH_TYPES = [
    {label: 'Drivers', value: 'Drivers'},
    {label: 'Agents', value: 'Agents'},
    {label: 'Employees', value: 'Employees'}
]

export default class PayrollBatchCreateDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            queryFilterFields: this.getQueryFilterFields(),

            // Fields
            fields: this.getFields(),
            Contacts: [],
            selectedContacts: {},
            reviewContacts: {},
            tableOptions: getDefaultTableOptions(this.getTableFields(), {
                style: {
                    floatingActions: true,
                    stripedRows: true
                }
            }, "payroll-batch-create-dialog", this.props.translate),
            selectedReviewContacts: {},

            canSubmit: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.fetchData({query: this.getQuery()})
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleSave = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.onSave(Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                    Items: Object.values(this.state.selectedContacts).map((it) => {
                        return {
                            Name: it.FirstName + ' ' + it.LastName,
                            ContactID: it.ContactID
                        }
                    })
                }, {
                    Description: '', // TODO: removed field
                    PayrollBatchTypes: Object.values(this.state.fields.PayrollBatchTypes.value).map(it => {
                        return it?.value
                    }).join(',')
                }))
                this.props.onClose()
            } else {
                scrollErrorIntoView(this.state.fields)
            }
        })
    }

    handleReviewSelectedContactsClick = () => {
        this.setState({
            isViewContactsDialogOpen: true,
            reviewContacts: cloneDeep(this.state.selectedContacts),
            selectedReviewContacts: cloneDeep(this.state.selectedContacts)
        })
    }

    handleCloseReviewSelectedContactsClick = () => {
        this.setState({
            isViewContactsDialogOpen: false,
            reviewContacts: {},
            selectedReviewContacts: {},
            canSubmit: false
        })
    }

    updateSelectedContacts = () => {
        this.setState({
            selectedContacts: this.state.selectedReviewContacts,
            reviewContacts: {},
            selectedReviewContacts: {},
            isViewContactsDialogOpen: false,
            canSubmit: false
        })
    }

    handleViewContactInfoPage = (item) => {
        if (item.DriverID) {
            openInNewTab(`/drivers/info/${item.DriverID}`)
        } else if (item.AgentID) {
            openInNewTab(`/agents/info/${item.AgentID}`)
        } else if (item.EmployeeID) {
            openInNewTab(`/employees/info/${item.EmployeeID}`)
        }

    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = () => {
        return {
            StartDate: new Field('StartDate', this.getFirstDateOfTheCurrentWeek().add('-7', 'day'), ['empty'], false, 'date', {
                addContainerClass: 'col-span-3',
                htmlBefore: () => <div className="col-span-full">
                    <InfoBar>
                        {this.props.translate('text.selectedDateRange')}
                    </InfoBar>
                </div>
            }),
            EndDate: new Field('EndDate', this.getFirstDateOfTheCurrentWeek(), ['empty'], false, 'date', {
                addContainerClass: 'col-span-3',
            }),
            Date: new Field('Date', moment(), ['empty'], false, 'date', {
                labelRaw: 'Batch date',
                addContainerClass: 'col-span-3',
            }, {placement: 'top-start'}),
            BookDate: new Field('BookDate', moment(), ['empty'], false, 'date', {
                labelRaw: 'Check/Posting date',
                addContainerClass: 'col-span-3',
            }, {placement: 'top-start'}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search',
                {
                    addContainerClass: 'col-span-6',
                }
            ),
            ContactGroupID: new Field(
                'ContactGroupID',
                getDefaultContactGroup(),
                ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: 'col-span-6',
                },
                {}),

            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, 'select', {addContainerClass: 'col-span-full'}, {
                menuPlacement: 'top',
                options: this.props.payrollBatchOptions,
                isMulti: true
            }),
            Name: new Field('Name', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full',
                label: 'notes'
            }),
        }
    }

    getTableFields = () => {
        return {
            FirstName: new Field('FirstName', '', ['empty']),
            LastName: new Field('LastName', '', ['empty']),
            Email: new Field('Email', '', ['empty', 'email']),
            Status: new Field('Status', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    if (!item.DriverStatusID
                        && !item.EmployeeStatusID
                        && !item.AgentStatusID
                    ) {
                        return null
                    }

                    item.StatusID = item.DriverID ?
                        item.DriverStatusID : (item.EmployeeID ? item.EmployeeStatusID : item.AgentStatusID)
                    if (item.DriverID) {
                        return <DriverStatusBadge item={item} translate={this.props.translate}/>
                    } else {
                        return <EmployeeStatusBadge item={item}
                                                    translate={this.props.translate}/>
                    }
                }
            }),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [], false, 'select')
        }
    }

    /** Query Definitions
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            IncludeStatuses: 1,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            ExcludeIDs: this.state.Contacts.map(it => it.ContactID).join(', '),
            IsDriver: this.state.queryFilterFields?.PayrollBatchTypes?.value?.value === 'Drivers' ? 1 : 0,
            IsAgent: this.state.queryFilterFields?.PayrollBatchTypes?.value?.value === 'Agents' ? 1 : 0,
            IsEmployee: this.state.queryFilterFields?.PayrollBatchTypes?.value?.value === 'Employees' ? 1 : 0
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, 'select', {}, {
                isClearable: true,
                options: PAYROLL_BATCH_TYPES
            }),
            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            HasActiveEmployment: new Field('HasActiveEmployment', '1', [''], false, 'checkbox', {label: "isActive"}),
            limit: new Field('limit', 100, [''], false, 'select', {hideLabel: true, labelType: 'float'})
        }
    }

    handleSelectContact = (row, id) => {
        const selectedContacts = cloneDeep(this.state.selectedContacts)

        if (selectedContacts[id]) {
            delete selectedContacts[id]
        } else {
            selectedContacts[id] = row
        }

        this.setState({
            selectedContacts
        })
    }

    handleSelectAllContact = (areAllSelected) => {
        const selectedContacts = cloneDeep(this.state.selectedContacts)
        getProp(this.props.resource, 'list', []).map(item => {
            if (!areAllSelected) {
                selectedContacts[item.ContactID] = item
            } else {
                delete selectedContacts[item.ContactID]
            }
        })

        this.setState({
            selectedContacts,
        })
    }

    handleSelectReviewContact = (row, id) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts)

        if (selectedReviewContacts[id]) {
            delete selectedReviewContacts[id]
        } else {
            selectedReviewContacts[id] = row
        }

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        })
    }

    handleSelectAllReviewContact = (areAllSelected) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts)
        getProp(this.props.resource, 'list', []).map(item => {
            if (!areAllSelected) {
                selectedReviewContacts[item.ContactID] = item
            } else {
                delete selectedReviewContacts[item.ContactID]
            }
        })

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        })
    }

    /** Helpers
     ================================================================= */
    getFirstDateOfTheCurrentWeek = () => {
        return moment().startOf('isoWeek')
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, show, onClose, resource, isLoading} = this.props

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            }
        })

        const data = getProp(resource, 'list', [])
        const count = getProp(resource, 'count', 0)
        const selectedContactsCount = Object.keys(this.state.selectedContacts)?.length ?? 0

        return (
            // Create batch dialog
            <ModalDefault
                show={show}
                widthClass={'max-w-full'}
                limitHeight={true}
                title={translate('text.BatchCreate')}
                onButtonClick={this.handleSave}
                buttonDisabled={!selectedContactsCount}
                buttonLabel={translate('btn.save')}
                closeButtonLabel={translate('btn.Cancel')}
                secondaryButtonLabel={
                    <span className={selectedContactsCount ? 'pr-8' : undefined}>
                    {translate('btn.view_selected_contacts')}
                        {!!selectedContactsCount && (
                            <div
                                className="absolute right-0 top-0 bottom-0 w-10 bg-inverse border-l border-primary rounded-r-btn flex items-center justify-center">
                            <span className="leading-5 text-xs">
                                {selectedContactsCount}
                            </span>
                            </div>
                        )}
                </span>
                }
                secondaryButtonDisabled={!selectedContactsCount}
                onSecondaryButtonClick={this.handleReviewSelectedContactsClick}
                onClose={onClose}
            >
                <div className={'pb-5 space-y-5'}>
                    <div className="">
                        <TableCard addClass={'rounded-none'}>
                            <div className={'pl-5 pt-3'}>
                                <ActiveFilters
                                    filterFields={this.state.queryFilterFields}
                                    onLabelClick={this.handleFilterInputChange}
                                    onClearFiltersClick={this.handleClearFiltersClick}
                                    translate={translate}
                                />
                            </div>
                            <div className="grid grid-cols-5 gap-4 px-6 pt-2 pb-4 border-b border-tm-gray-300">
                                <FieldsToHtml
                                    fieldsState={this.state.queryFilterFields}
                                    excludeFields={['limit']}
                                    onInputChange={this.handleFilterInputChange}
                                    translate={translate}
                                    selects={{
                                        Groups: {
                                            api: 'api/' + Resources.ContactGroupsQuick,
                                            query: {},
                                            searchMap: (item) => ({
                                                label: item.ContactGroupName,
                                                value: item.ContactGroupID
                                            })
                                        }
                                    }}
                                />
                            </div>

                            <ResourceTable
                                data={data}
                                count={count}
                                maxHeightClass={'min-h-[17.3rem] max-h-[calc(100vh-52rem)]'}
                                tableKey={'ContactID'}
                                onSelectRow={this.handleSelectContact}
                                onSelectAllClick={this.handleSelectAllContact}
                                selectedRows={this.state.selectedContacts}

                                onRowClick={(row) => this.handleSelectContact(row, row.ContactID)}

                                fields={this.getTableFields()}

                                translate={this.props.translate}
                                isLoading={isLoading}

                                limit={this.state.queryFilterFields.limit.value}
                                offset={this.state.offset}
                                page={this.state.paginationPage}
                                paginationButtonLimit={5}
                                onOffsetChange={this.handleUpdateOffset}

                                sort={this.state.sort}
                                sortBy={this.state.sortBy}
                                onSortChange={this.handleUpdateSort}
                                options={this.state.tableOptions}
                                actions={[
                                    {
                                        action: (item) => this.handleViewContactInfoPage(item),
                                        icon: EyeIcon,
                                        visible: () => true,
                                        title: translate('btn.open_in_new_tab')
                                    }
                                ]}
                            />

                            <NoRecords
                                addClass={'mt-5'}
                                show={(data.length === 0) && !isLoading}
                                title={translate('text.no_records')}
                            />

                            <TableCardFooter
                                addClass=""
                                show={!(!data.length && !isLoading)}
                            >
                                <Pagination
                                    count={count}
                                    isLoading={isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === 'offset'
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </TableCard>
                    </div>

                    <div
                        className="grid gap-4 grid-cols-12 border border-tm-gray-300 rounded-card bg-tm-gray-100 p-6 mx-6">
                        {fieldsHtml}
                    </div>
                </div>

                <ModalDefault
                    show={this.state.isViewContactsDialogOpen}
                    widthClass={'max-w-xl'}
                    translate={translate}
                    title={translate('modal_heading.review_selected_contacts')}
                    buttonLabel={translate('btn.Update')}
                    onButtonClick={this.updateSelectedContacts}
                    buttonDisabled={!this.state.canSubmit}
                    onClose={this.handleCloseReviewSelectedContactsClick}
                    closeButtonLabel={translate('btn.cancel')}
                >
                    <ResourceTable
                        commonTable={true}
                        data={Object.values(this.state.reviewContacts)}
                        count={count}

                        tableKey={'ContactID'}
                        onSelectRow={this.handleSelectReviewContact}
                        selectedRows={this.state.selectedReviewContacts}
                        onSelectAllClick={this.handleSelectAllReviewContact}

                        fields={{
                            FirstName: new Field('FirstName', '', ['empty']),
                            LastName: new Field('LastName', '', ['empty']),
                            Email: new Field('Email', '', ['empty', 'email']),
                        }}
                        translate={this.props.translate}

                        options={{
                            style: {
                                floatingActions: false,
                                stripedRows: true
                            }
                        }}
                    />
                </ModalDefault>
            </ModalDefault>
        )
    }
}
