import {jsx} from 'slate-hyperscript'

const deserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent)
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null
    }

    const nodeAttributes = {...markAttributes}

    // define attributes for text nodes
    switch (el.nodeName) {
        case 'STRONG':
            nodeAttributes.bold = true;
            break;
        case 'EM':
            nodeAttributes.italic = true;
            break;
        case 'S':
            nodeAttributes.strikethrough = true;
            break;
        case 'U':
            nodeAttributes.underline = true;
    }

    const children = Array.from(el.childNodes)
        .map(node => deserialize(node, nodeAttributes))
        .flat()

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''))
    }

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children)
        case 'BR':
            return '\n'
        case 'BLOCKQUOTE':
            return jsx('element', {type: 'quote', align: el.getAttribute('align')}, children)
        case 'P':
            return jsx('element', {type: 'paragraph', align: el.getAttribute('align')}, children)
        case 'H1':
            return jsx('element', {type: 'heading-one', align: el.getAttribute('align')}, children)
        case 'H2':
            return jsx('element', {type: 'heading-two', align: el.getAttribute('align')}, children)
        case 'UL':
            return jsx('element', {type: 'bulleted-list', align: el.getAttribute('align')}, children)
        case 'OL':
            return jsx('element', {type: 'numbered-list', align: el.getAttribute('align')}, children)
        case 'LI':
            return jsx('element', {type: 'list-item'}, children)
        case 'A':
            return jsx(
                'element',
                {type: 'link', url: el.getAttribute('href')},
                children
            )
        default:
            return children
    }
}

export default function slateHTMLToValue(html) {
    if (!html) {
        return null;
    }

    if (html.charAt(0) !== '<') { // wrap with P tag if it's a string only (e.g. old data)
        html = "<p>" + html + "</p>";
    }

    const document = new DOMParser().parseFromString(html, 'text/html')

    return deserialize(document.body)
}