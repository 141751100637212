import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, Slate, useSlate, withReact } from 'slate-react'
import { createEditor, Editor, Element as SlateElement, Transforms, } from 'slate'
import { withHistory } from 'slate-history'
import {
    EditorAlignCenterIcon,
    EditorAlignJustifyIcon,
    EditorAlignLeftIcon,
    EditorAlignRightIcon,
    EditorBoldIcon,
    EditorItalicIcon,
    EditorListBulletedIcon,
    EditorListDecimalIcon,
    EditorQuoteIcon,
    EditorStrikethroughIcon,
    EditorUnderlineIcon
} from "../../../../data/themes/icons";
import {classNames} from "../../../util/util-helpers";

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

let oldValue = "";

export default function RichTextEditor( {className, name, onChange, value, placeholder, readOnly, addClass, keywords, innerRef} ) {
    const [editorKey, setEditorKey] = useState(1);

    // This will re-initiate editor after value is updated externally.
    useEffect(() => {
        if (!areSame(value, oldValue)) {
            setEditorKey(editorKey + 1);
        }
    }, [value])

    return (
        <RichTextEditorField
            key={editorKey}
            className={className}
            name={name}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            addClass={addClass}
            keywords={keywords}
            innerRef={innerRef}
        />
    )
}

const RichTextEditorField = ( {className, name, onChange, value, placeholder, readOnly, addClass, keywords, innerRef} ) => {
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    let editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const handleChange = ( value ) => {
        oldValue = value;
        !!onChange && onChange(name, value);
    }

    return (
        <div>
            <Slate editor={editor} value={!!value ? value : emptyValue} onChange={handleChange}>
                {!!keywords && (
                    <div className="pb-1">
                        {keywords.map(it => (
                            <EditorInsertTextButton key={it.text} text={it.text} label={it.label} desc={it.desc}
                                                    type={it.type}/>
                        ))}
                    </div>
                )}

                {!readOnly && (
                    <div className="flex space-x-1 py-0.5 mb-3 border-tm-gray-300 border-y">
                        <div className="flex justify-start flex-wrap space-x-0.5 items-center">
                            <div className="inline-flex flex-nowrap">
                                <EditorButton format='bold' Icon={EditorBoldIcon}/>
                                <EditorButton format='italic' Icon={EditorItalicIcon}/>
                                <EditorButton format='underline' Icon={EditorUnderlineIcon}/>
                                <EditorButton format='strikethrough' Icon={EditorStrikethroughIcon}/>
                                <EditorBlockButton format='quote' Icon={EditorQuoteIcon}/>
                            </div>

                            <div className="px-1.5">
                                <div className="w-px h-3 bg-tm-gray-300"/>
                            </div>

                            <div className="inline-flex flex-nowrap">
                                <EditorBlockButton format='heading-one' content={"H1"}/>
                                <EditorBlockButton format='heading-two' content={"H2"}/>
                                <EditorBlockButton format='heading-three' content={"H3"}/>
                            </div>

                            <div className="px-1.5">
                                <div className="w-px h-3 bg-tm-gray-300"/>
                            </div>

                            <div className="inline-flex flex-nowrap">
                                <EditorBlockButton format='left' Icon={EditorAlignLeftIcon}/>
                                <EditorBlockButton format='center' Icon={EditorAlignCenterIcon}/>
                                <EditorBlockButton format='justify' Icon={EditorAlignJustifyIcon}/>
                                <EditorBlockButton format='right' Icon={EditorAlignRightIcon}/>
                            </div>

                            <div className="px-1.5">
                                <div className="w-px h-3 bg-tm-gray-300"/>
                            </div>

                            <div className="inline-flex flex-nowrap">
                                <EditorBlockButton format='bulleted-list' Icon={EditorListBulletedIcon}/>
                                <EditorBlockButton format='numbered-list' Icon={EditorListDecimalIcon}/>
                            </div>
                        </div>
                    </div>
                )}

                <div className={!readOnly ? (!!className ? className : "form-control") : undefined}>
                    <Editable
                        className={classNames(addClass, "min-h-[6rem] overflow-y-auto prose max-w-full text-tm-gray-900 text-sm")} // prose class requires '@tailwindcss/typography' plugin
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={placeholder ?? "Type something here..."}
                        spellCheck
                        readOnly={readOnly}
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }}
                    />
                </div>
            </Slate>
        </div>
    )
}

const emptyValue = [
    {
        type: 'paragraph',
        children: [
            {text: ''},
        ],
    },
]

const EditorButton = ( {format, content, Icon} ) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);

    return (
        <button
            onMouseDown={( event ) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
            className={classNames(
                isActive ? "text-tm-gray-900" : "text-tm-gray-400",
                "btn-table-action mx-0 text-base text-bold w-9 h-9 flex justify-center items-center"
            )}
        >
            {content}
            {!!Icon && <Icon className={classNames(isActive ? "text-tm-gray-900" : "text-tm-gray-400", "w-5 h-5")}/>}
        </button>
    )
}

const EditorBlockButton = ( {format, Icon, content} ) => {
    const editor = useSlate();
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');

    return (
        <button
            onMouseDown={( event ) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
            className={classNames(
                isActive ? "text-tm-gray-900" : "text-tm-gray-400",
                "btn-table-action mx-0 text-base text-bold w-9 h-9 flex justify-center items-center"
            )}
        >
            {content}
            {!!Icon && <Icon className={classNames(isActive ? "text-tm-gray-900" : "text-tm-gray-400", "w-5 h-5")}/>}
        </button>
    )
}

const insertText = ( editor, text ) => {
    Editor.insertText(editor, text)
}

const EditorInsertTextButton = ( {text, Icon, type, label} ) => {
    const editor = useSlate();

    let addClass;
    switch (Number(type)) {
        case 2:
            addClass = "bg-indigo-600 text-white";
            break;
        case 3:
            addClass = "bg-green-600 text-white";
            break;
        case 4:
            addClass = "bg-purple-600 text-white";
            break;
        case 5:
            addClass = "bg-cyan-600 text-white";
            break;
        default:
            addClass = "bg-blue-600 text-white";
            break;
    }

    return (
        <button
            onMouseDown={( event ) => {
                event.preventDefault();
                insertText(editor, "{{$" + text + "}}");
            }}
            className={classNames(
                addClass,
                "cursor-pointer inline-flex m-1 items-center px-3 py-0.5 rounded-12 text-sm font-medium btn"
            )}
        >
            {label}
        </button>
    )
}


const toggleMark = ( editor, format ) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isMarkActive = ( editor, format ) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const toggleBlock = ( editor, format ) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )

    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = {type: format, children: []}
        Transforms.wrapNodes(editor, block)
    }
}

const isBlockActive = ( editor, format, blockType = 'type' ) => {
    const {selection} = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

const Element = ( {attributes, children, element} ) => {
    const style = {textAlign: element.align}
    switch (element.type) {
        case 'bulleted-list':
            return (
                <ul className="pl-5 list-disc" style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <p className="text-4xl" style={style} {...attributes}>
                    {children}
                </p>
            )
        case 'heading-two':
            return (
                <p className="text-2xl" style={style} {...attributes}>
                    {children}
                </p>
            )
        case 'heading-three':
            return (
                <p className="text-xl" style={style} {...attributes}>
                    {children}
                </p>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol className="pl-5 list-decimal" style={style} {...attributes}>
                    {children}
                </ol>
            )
        case 'quote':
            return (
                <blockquote className="pl-3 text-tm-gray-700" style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Leaf = ( {attributes, children, leaf} ) => {
    if (leaf.bold) {
        children = <span className="font-bold">{children}</span>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <span className="italic">{children}</span>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.strikethrough) {
        children = <span className="line-through">{children}</span>
    }

    return <span {...attributes}>{children}</span>
}

const areSame = (value, oldValue) => {
    return JSON.stringify(value) === JSON.stringify(oldValue);
}
