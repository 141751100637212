import React, { useState } from "react";
import {updateResource} from "../../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../../data/services/resources";
import LocationInfo from "./location-info";
import ModalFooter from "../../modal-footer";
import {checkPerm} from "../../../../util/util-helpers";
import {UPDATE_PERM} from "../../../../../util/util-constants";
import Modal from "../../index";
import CreateLocationDialog from "../../createLocationDialog";

export default function GlobalLocationInfo({translate, item, dispatch, dialogResource, secondResource, onClose}) {
    const piggyResource = item.resource;
    const query = item.query;

    const [isFormDialogVisible, setIsFormDialogVisible] = useState(false)
    const [itemData, setItemData] = useState(item)

    const submit = (params) => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.Locations,
            piggyResource: piggyResource,
            query: query,
            errorMessage: true, successMessage: `Location ${item.LocationName} updated`,
        }))

        setItemData(params);
    }

    return (
        <React.Fragment>
            <div className="relative px-3 py-8 text-tm-gray-700 bg-tm-gray-50">
                <div className="max-w-md mx-auto space-y-6">
                    <LocationInfo
                        data={itemData}
                        translate={translate}
                    />
                </div>
            </div>

            <ModalFooter
                buttonDisabled={!checkPerm(Resources.Locations, UPDATE_PERM)}
                buttonLabel={translate("btn.edit")}
                onButtonClick={() => setIsFormDialogVisible(true)}
                closeButtonLabel={translate("btn.cancel")}
                onClose={onClose}
            />

            <Modal
                show={isFormDialogVisible}
                widthClass={'max-w-7xl'}
                closeButtonLabel={translate('btn.close')}
                onClose={() => setIsFormDialogVisible(false)}
                translate={translate}
            >
                <CreateLocationDialog
                    onClose={() => setIsFormDialogVisible(false)}
                    onSubmit={submit}
                    updateItem={itemData}
                    dispatch={dispatch}
                    dialogResource={dialogResource}
                    secondResource={secondResource}
                    translate={translate}
                />
            </Modal>
        </React.Fragment>
    )
}
