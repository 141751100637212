import React, {Component} from "react";
import axios from "axios";
import Tippy from "@tippyjs/react";
import {ArrowUturnDownIcon} from "@heroicons/react/24/outline";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {getJWT} from "../../../util/util-auth";
import {getDefaultTableOptions} from "../../../util/util-helpers";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import Resources from "../../../../data/services/resources";
import {cloneDeep} from "../../../util/util-vanilla";
import {getProp} from "../../../util/util-helpers";
import FieldText from "../../fields/field-text";
import Env from "../../../../util/env";
import {processResponse} from "../../../../data/services/api-util";
import TableCard from "../../resource-table/table-components/table-card";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import Pagination from "../../resource-table/table-components/pagination";
import ModalFooter from "../modal-footer";
import NavResponsive from "../../nav-responsive";
import FieldDropdownSelect from "../../fields/field-dropdown-select";
import ResourceTable from "../../resource-table";


export default class AddContactsDialog extends Component {

    constructor(props) {
        super(props);

        this.path = "contacts/select";

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: false,
                verticalLines: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            queryFields: this.getQueryFields(),
            selectedQueryFields: this.getSelectedQueryFields(),
            fields: {
                query: new Field('query', ''),
                ContactGroupID: new Field('ContactGroupID', '')
            },
            paginationPage: 1,
            selectedContacts: this.props.contactsList ? this.props.contactsList : {},
            savedContactsState: {},
            selectedContactsTabList: {},
            canUndo: false,
            tabs: this.getTabs(this.props.contactsList ? this.props.contactsList : {}),
            groups: [],
            hideDialog: false,
            HideModalAnimation: false,
            dirty: false,
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.path, this.props.translate)
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thirdResource.isLoading && !this.props.thirdResource.isLoading) {
            this.addContactsFromGroup();
        }

        if (prevProps.ui.HideModalAnimation && !this.props.ui.HideModalAnimation) {
            this.setState({
                hideDialog: false
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: this.state.fields.ContactGroupID.value ? Resources.ContactGroupContacts : Resources.Contacts,
            query: this.getQuery()
        }));
    };

    updateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData(this.state.fields.query, this.state.fields.ContactGroupID.value?.value,));
    }

    handleQueryChange = (name, value) => {
        let queryFieldsClone = Object.assign({}, this.state.queryFields);

        queryFieldsClone = FieldsManager.updateField(queryFieldsClone, name, value);

        this.setState({
            queryFields: queryFieldsClone
        }, () => {
            this.fetchData();
        })
    }

    getQuery = () => {
        return Object.assign(
            FieldsManager.getFieldKeyValues(this.state.queryFields),
            FieldsManager.getFieldKeyValues(this.state.fields),
            {
                id: this.state.fields.ContactGroupID.value?.value,
                IsSystemUser: 1,
            }, this.props.addQuery ?? {});
    }

    close = () => {
        this.props.onClose();
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields;

        if (name === 'query') {
            Object.assign(fields.query, {value: value})
        }

        if (name === 'ContactGroupID') {
            Object.assign(fields.ContactGroupID, {value: value})
        }

        this.setState({
            fields: fields,
            canUndo: name === 'ContactGroupID' ? false : this.state.canUndo
        }, () => this.fetchData());
    };

    handleSelectedInputChange = (name, value) => {
        let fields = this.state.selectedQueryFields;

        fields = FieldsManager.updateField(fields, name, value)

        this.setState({
            selectedQueryFields: fields,
        });
    };

    handleToggleContactClick = (contact) => {
        const id = contact.ContactID;
        const ContactName = contact.FirstName + " " + contact.LastName;
        const contacts = this.state.selectedContacts;

        if (contacts[id]) {
            delete contacts[id];
        } else {
            contacts[id] = {
                ContactName: ContactName,
                Email: contact.Email,
                ImagePath: contact.ImagePath,
                ContactID: id
            };
        }

        this.setState({
            selectedContacts: contacts,
            dirty: true,
            canUndo: false
        })
    }

    handleAddContactsFromGroup = () => {
        this.setState({
            savedContactsState: this.state.selectedContacts,
            canUndo: true,
            dirty: true
        })

        this.props.dispatch(getThirdResource({
            user: LocalStorage.get("user"),
            query: {id: this.state.fields.ContactGroupID.value.value},
            resource: Resources.ContactGroupContacts,
        }));
    }

    handleTabChange = (tabResource) => {
        let selectedContactsTabList = {};
        if (tabResource === 'SelectedContacts') {
            selectedContactsTabList = Object.values(this.state.selectedContacts);
        }

        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === tabResource
                return it
            }),
            selectedContactsTabList: selectedContactsTabList
        })
    }

    addContactsFromGroup = () => {
        const contactGroup = getProp(this.props, 'thirdResource.data.list', []).reduce((memo, it) => {
            memo[it.ContactID] = {
                ContactName: it.FirstName + " " + it.LastName,
                Email: it.Email,
                ImagePath: it.ImagePath,
                ContactID: it.ContactID
            };
            return memo
        }, {});

        const mergedContacts = {...contactGroup, ...this.state.selectedContacts}

        const tabs = this.getSelectedContactsTabLabel(mergedContacts);

        this.setState({
            selectedContacts: mergedContacts,
            tabs: tabs
        })
    }

    handleUndoAdd = () => {
        const tabs = this.getSelectedContactsTabLabel(this.state.savedContactsState);

        this.setState({
            selectedContacts: this.state.savedContactsState,
            tabs: tabs,
            canUndo: false
        })
    }

    getFields = () => {
        return {
            Name: new Field('Name', '', [''], false, 'custom', {
                render: (it) => {
                    return it?.ContactName ?? `${it.LastName} ${it.FirstName}`;
                }
            }),
            Email: new Field('Email', '', [''], false, 'text')
        }
    }

    getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', 'FirstName', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),
            limit: new Field('limit', '10', [''], false, 'select', {
                labelType: 'float', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    getSelectedQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
        }
    }

    handleSelectRowClick = (item) => {
        const itemID = item.ContactID;

        let selectedRows = Object.assign({}, this.state.selectedContacts);

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        const tabs = this.getSelectedContactsTabLabel(selectedRows);

        this.setState({
            selectedContacts: selectedRows,
            tabs: tabs,
            dirty: true
        })
    }

    getSelectedContactsTabLabel = (selectedRows) => {
        let tabs = [...this.state.tabs];
        let tabIndex = 0;
        const selectedRowsNumber = Object.keys(selectedRows).length;
        let tab = tabs.find((it, i) => {
            tabIndex = i;
            return it.name === 'SelectedContacts'
        });
        tab.labelRaw = this.props.translate("text.selected_contacts_number", [selectedRowsNumber])
        tab.disabled = !selectedRowsNumber;

        tabs[tabIndex] = tab;

        return tabs;
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.dialogResource.data, 'list', []))

        let selectedRows = Object.assign({}, this.state.selectedContacts);

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.ContactID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.ContactID.toString())) {
                    delete selectedRows[it.ContactID]
                }
            })
        }
        const tabs = this.getSelectedContactsTabLabel(selectedRows);
        this.setState({
            selectedContacts: selectedRows,
            tabs: tabs,
            dirty: true
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        });
    }

    getTabs = (contacts) => {
        const selectedCount = Object.keys(contacts).length;
        return [
            {
                name: 'ContactsList',
                resource: 'ContactsList',
                visible: true,
                current: true
            },
            {
                name: 'SelectedContacts',
                resource: 'SelectedContacts',
                labelRaw: this.props.translate("text.selected_contacts_number", [selectedCount]),
                visible: true,
                current: false,
                disabled: !selectedCount
            }
        ]
    }

    render() {
        const {translate, dialogResource} = this.props;

        const contacts = getProp(dialogResource, 'data.list', [])
        const count = getProp(dialogResource, 'data.count', 0);
        const isLoading = dialogResource?.isLoading ?? true;

        const currentTab = this.state.tabs.find(tab => tab.current)

        let selectedContactsTableData = [];
        let selectedContactsTableDataCount = 0;

        const queryString = this.state.selectedQueryFields.query.value.toUpperCase();

        if (currentTab.name === 'SelectedContacts') {
            selectedContactsTableDataCount = this.state.selectedContactsTabList.length;

            selectedContactsTableData = this.state.selectedContactsTabList.filter(it => {
                return !queryString
                    || (it.LastName + " " + it.FirstName).toUpperCase().search(queryString) > -1
                    || it.Email.toUpperCase().search(queryString) > -1;
            });
        }

        return (
            <React.Fragment>
                <NavResponsive
                    tabs={this.state.tabs}
                    onTabChange={this.handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'ContactsList' && (
                    <React.Fragment>
                        <div className="md:grid grid-cols-12 gap-4 p-4 md:pb-0">
                            <div className="md:col-span-4">
                                <label className={"form-group has-float-label mb-4"}>
                                    <FieldText
                                        addClass="form-control"
                                        onChange={this.handleInputChange}
                                        placeholder={""}
                                        {...this.state.fields.query}
                                    />
                                    <span>{translate('field.filter_search')}</span>
                                </label>
                            </div>

                            <div className="md:col-span-4">
                                <label className={"form-group has-float-label mb-4"}>
                                    <FieldDropdownSelect
                                        placeholder={translate("text.select_contact_group")}
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.ContactGroupID}
                                        addClass="form-control"
                                        defaultOptions={true}
                                        isClearable={this.state.fields.ContactGroupID.value}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                axios.get(
                                                    Env.getApiUrl("api/" + Resources.ContactGroups, {query: inputValue}),
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + getJWT().access_token
                                                        }
                                                    }
                                                )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((it) => {
                                                                return {
                                                                    label: it.ContactGroupName,
                                                                    value: it.ContactGroupID
                                                                };
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                            }
                                        }
                                    />
                                    <span>{translate("field.filter_by_group")}</span>
                                </label>
                            </div>

                            <div className="md:col-span-4 text-right space-x-2">
                                {this.state.canUndo && (
                                    <Tippy content="Undo Add">
                                <span className="btn btn-outline p-2"
                                      onClick={() => this.handleUndoAdd()}>
                                        <ArrowUturnDownIcon className="w-5 h-5 rotate-90"/>
                                </span>
                                    </Tippy>
                                )}

                                <button className="btn btn-outline"
                                        disabled={!this.state.fields.ContactGroupID.value || this.state.canUndo}
                                        onClick={this.state.fields.ContactGroupID.value || this.state.canUndo ? () => this.handleAddContactsFromGroup() : null}
                                >
                                    Add all from group
                                </button>
                            </div>
                        </div>

                        <TableCard isRounded={false}>
                            {!count && !isLoading && (
                                <p className="text-center">{translate("text.no_matching_records")}</p>
                            )}

                            <ResourceTable
                                data={contacts}
                                count={count}
                                options={this.state.tableOptions}
                                tableKey="ContactID"
                                fields={this.getFields()}
                                translate={this.props.translate}
                                isLoading={isLoading}

                                queryFields={this.state.queryFields}

                                onSelectRow={this.handleSelectRowClick}
                                selectedRows={this.state.selectedContacts}
                                onSelectAllClick={this.handleSelectAllClick}

                                onRowClick={this.handleSelectRowClick}
                            />

                            <TableCardFooter show={true}>
                                <Pagination
                                    count={count}
                                    isLoading={isLoading}
                                    handleQueryChange={this.handleQueryChange}
                                    queryFields={this.state.queryFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </TableCard>
                    </React.Fragment>
                )}

                {currentTab.name === 'SelectedContacts' && (
                    <div className="">
                        <div className="md:grid grid-cols-12 gap-4 p-4 md:pb-0">
                            <label className={"md:col-span-4 form-group has-float-label mb-4"}>
                                <FieldText
                                    addClass="form-control"
                                    onChange={this.handleSelectedInputChange}
                                    placeholder={""}
                                    name='query'
                                    value={this.state.selectedQueryFields.query.value}
                                />
                                <span>{translate('field.filter_search')}</span>
                            </label>
                        </div>

                        <TableCard isRounded={false}>
                            {!selectedContactsTableData.length && !!queryString && (
                                <p className="text-center">{translate("text.no_matching_records")}</p>
                            )}

                            <ResourceTable
                                data={selectedContactsTableData}
                                count={selectedContactsTableDataCount}
                                options={this.state.tableOptions}
                                tableKey="ContactID"
                                fields={this.getFields()}
                                translate={this.props.translate}
                                isLoading={isLoading}

                                queryFields={this.state.queryFields}

                                onSelectRow={this.handleSelectRowClick}
                                selectedRows={this.state.selectedContacts}
                                onSelectAllClick={this.handleSelectAllClick}

                                onRowClick={this.handleSelectRowClick}
                            />
                        </TableCard>
                    </div>
                )}

                <ModalFooter
                    closeButtonLabel={translate("btn.cancel")}
                    onClose={this.close}
                    buttonDisabled={!this.state.dirty}
                    buttonLabel={translate("btn.apply_selected_watchers")}
                    onButtonClick={() => {
                        if (this.state.dirty) {
                            this.props.onSubmitClick(this.state.selectedContacts);
                            this.close()
                        }
                    }}
                />
            </React.Fragment>
        )
    }
}
