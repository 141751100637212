import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {getProp, longTableColumn} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import moment from "moment-timezone";
import {DEFAULT_CRUD_STATE} from "../../../util/util-constants";
import {getInfoResource} from "../../../data/actions/infoResource";
import {getSecondResource} from "../../../data/actions/secondResource";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../common/components/resource-table";
import Card from "../../../common/components/card";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";

export default class VendorSummaryTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            offsetRecurring: 0,
            offsetLiabilities: 0,
            fields: this.getFields(),
            canSubmit: false,
            queryFilterFields: this.getQueryFilterFields(),
            queryFilterFieldsRecurring: this.getQueryFilterFieldsRecurring(),
            queryFilterFieldsLiabilities: this.getQueryFilterFieldsLiabilities(),
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
        this.fetchDataRecurring()
        this.fetchDataLiabilities()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields()
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingSummary,
            query: this.getQuery()
        }))
    }

    fetchDataRecurring = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.ExpenseRecurring,
            query: this.getQueryRecurring()
        }))
    }

    fetchDataLiabilities = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.ExpenseLiabilities,
            query: this.getQueryLiabilites()
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchDataLiabilities()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleFilterInputChangeRecurring = (name, value) => {
        this.setState({
            queryFilterFieldsRecurring: FieldsManager.updateField(this.state.queryFilterFieldsRecurring, name, value),
            offsetRecurring: 0,
            paginationPage: 1
        }, () => {
            this.fetchDataRecurring()
        })
    }

    handleUpdateOffsetRecurring = (offset, num) => {
        this.setState({
            offsetRecurring: offset,
            paginationPage: num
        }, () => {
            this.fetchDataRecurring()
        })
    }

    handleFilterInputChangeLiabilities = (name, value) => {
        this.setState({
            queryFilterFieldsLiabilities: FieldsManager.updateField(this.state.queryFilterFieldsLiabilities, name, value),
            offsetLiabilities: 0,
            paginationPage: 1
        }, () => {
            this.fetchDataLiabilities()
        })
    }

    handleUpdateOffsetLiabilities = (offset, num) => {
        this.setState({
            offsetLiabilities: offset,
            paginationPage: num
        }, () => {
            this.fetchDataLiabilities()
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            OrganizationID: this.props.id
        }
    }

    getQueryRecurring = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offsetRecurring,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFieldsRecurring),
            searchFields: JSON.stringify({
                OrganizationID: this.props.id
            })
        }
    }
    getQueryLiabilites = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offsetLiabilities,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFieldsLiabilities),
            searchFields: JSON.stringify({
                LoanOrganizationID: this.props.id
            })
        }
    }

    /** Fields/Data definitions
     ================================================================= */
    getFields = () => {
        return {
            ID: new Field('ID', '', ['empty'], false, 'text', {}),
            RefNumber: new Field('RefNumber', '', [''], false, 'text', {}),
            Type: new Field('Type', '', [''], false, 'text', {}),
            SubType: new Field('SubType', '', [''], false, 'text', {}),
            Date: new Field('Date', '', [''], false, 'date', {}),
            Status: new Field('Status', '', [''], false, 'text', {}),
            Amount: new Field('Amount', '', [''], false, 'money', {})
        }
    }

    getFieldsRecurring = () => {
        return {
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-6'}),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {addContainerClass: 'col-span-6'}),
            LastPaidDate: new Field('LastPaidDate', '', [''], false, 'date', {addContainerClass: 'col-span-6'}),
            LastPaidAmount: new Field('LastPaidAmount', '', [''], false, 'money', {addContainerClass: 'col-span-6'}),
            Frequency: new Field('Frequency', '', ['empty'], false, 'select', {addContainerClass: 'col-span-6'})
        }
    }

    getFieldsLiabilities = () => {
        return {
            Description: new Field('Description', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <div>{longTableColumn(item.Description)}</div>
                    )
                }
            }),
            StartDate: new Field('StartDate', '', [], false, 'date'),
            Amount: new Field('Amount', '', [], false, 'money'),
            DownPayment: new Field('DownPayment', '', [], false, 'money'),
            LoanTerm: new Field('LoanTerm', '', [], false, 'custom', {
                render: (item) => (
                    <div>{item.LoanTerm} months</div>
                )
            }),
            InterestRate: new Field('InterestRate', '', [], false, 'custom', {
                render: (item) => (
                    <div>{item.InterestRate} % yearly</div>
                )
            }),
            MonthlyPayment: new Field('MonthlyPayment', '', [''], false, 'money'),
            // Payments made
            UsedAmount: new Field('UsedAmount', '', [], false, 'money', {
                label: "principal_left",
            }),
            UsedExpenseAmount: new Field('UsedExpenseAmount', '', [], false, 'money', {
                label: "interest_left",
            }),
            LastPaidDate: new Field('LastPaidDate', '', [''], false, 'date'),
            LastPaidAmount: new Field('LastPaidAmount', '', [''], false, 'money'),
            PaymentCounter: new Field('PaymentCounter', '', [], false, 'custom', {
                render: (item) => (
                    <div>Paid {item.PaymentCounter} rates {item.DownPaymentPaid ? "(Paid down payment)" : item.DownPayment > 0 ? "(Down payment not paid)" : ""}</div>
                )
            }),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox')
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getQueryFilterFieldsRecurring = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getQueryFilterFieldsLiabilities = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, info, secondResource} = this.props
        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const infoData = getProp(this.props, "info.data.list", []);
        const countInfo = getProp(this.props, "info.data.count", 0);
        const thirdData = getProp(this.props, "secondResource.data.list", []);
        const countThird = getProp(this.props, "secondResource.data.count", 0);
        return (
            <div className="space-y-8 pb-8">
                <Card>
                    <React.Fragment>
                        <PageHeader
                            title={translate('page.heading.Summary')}
                            titleClass="mb-5 text-2xl"
                        />

                        <TableCard addClass="relative z-10 min-h-[20rem]">
                            <TableFilters
                                hideLimit
                                filterFields={this.state.queryFilterFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                                onRefreshTable={this.fetchData}
                                isLoading={resource.isLoading}
                            />


                            <ResourceTable
                                data={data}
                                fields={this.getFields()}

                                translate={translate}
                                isLoading={resource.isLoading}

                                options={this.state.tableOptions}

                                limit={this.state.queryFilterFields.limit.value}

                                tableKey={'ID'}
                            />

                            {/*Table footer*/}
                            {!(!data.length && !resource.isLoading) && (
                                <div
                                    className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                >
                                    <div className={"flex items-center w-full pl-3 h-9"}>
                                        <Pagination
                                            count={count}
                                            isLoading={resource.isLoading}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.handleUpdateOffset(value, currentPage)
                                                    : this.handleFilterInputChange(name, value)
                                            }
                                            pageOffset={this.state.offset}
                                            queryFields={this.state.queryFilterFields}
                                            translate={translate}
                                        />
                                    </div>
                                </div>
                            )}
                            <NoRecords
                                show={(data.length === 0) && !resource.isLoading}
                                title={translate('text.no_records')}
                                addClass={"pt-16 pb-10"}
                            />
                        </TableCard>
                    </React.Fragment>
                </Card>


                <Card addClass="relative z-10 min-h-[20rem]">
                    <PageHeader
                        title={translate('page.heading.recurring')}
                        titleClass="mb-5 mt-5 text-2xl"
                    />

                    <TableCard addClass="relative z-10 min-h-[20rem]">
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFieldsRecurring}
                            handleInputChange={this.handleFilterInputChangeRecurring}
                            translate={translate}
                            onRefreshTable={this.fetchDataRecurring}
                            isLoading={info.isLoading}
                        />

                        <ResourceTable
                            data={infoData}
                            fields={this.getFieldsRecurring()}

                            translate={translate}
                            isLoading={info.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFieldsRecurring.limit.value}
                        />

                        {/*Table footer*/}
                        {!(!infoData.length && !info.isLoading) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >
                                <Pagination
                                    count={countInfo}
                                    isLoading={info.isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffsetRecurring(value, currentPage)
                                            : this.handleFilterInputChangeRecurring(name, value)
                                    }
                                    pageOffset={this.state.offsetRecurring}
                                    queryFields={this.state.queryFilterFieldsRecurring}
                                    translate={translate}
                                />
                            </div>
                        )}

                        <NoRecords
                            show={(infoData.length === 0) && !info.isLoading}
                            title={translate('text.no_records')}
                            addClass={"pt-16 pb-10"}
                        />
                    </TableCard>
                </Card>

                <Card addClass={"mt-5"}>
                    <PageHeader
                        title={translate('page.heading.liabilities')}
                        titleClass="mb-5 mt-5 text-2xl"
                    />

                    <TableCard addClass="relative z-10 min-h-[20rem]">
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFieldsLiabilities}
                            handleInputChange={this.handleFilterInputChangeLiabilities}
                            translate={translate}
                            onRefreshTable={this.fetchDataLiabilities}
                            isLoading={secondResource.isLoading}
                        />

                        <ResourceTable
                            data={thirdData}
                            fields={this.getFieldsLiabilities()}

                            translate={translate}
                            isLoading={secondResource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFieldsLiabilities.limit.value}


                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                        />

                        {/*Table footer*/}
                        {!(!thirdData.length && !secondResource.isLoading) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >
                                <Pagination
                                    count={countThird}
                                    isLoading={secondResource.isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffsetLiabilities(value, currentPage)
                                            : this.handleFilterInputChangeLiabilities(name, value)
                                    }
                                    pageOffset={this.state.offsetLiabilities}
                                    queryFields={this.state.queryFilterFieldsLiabilities}
                                    translate={translate}
                                />
                            </div>
                        )}

                        <NoRecords
                            show={(thirdData.length === 0) && !secondResource.isLoading}
                            title={translate('text.no_records')}
                            addClass={'pt-16 pb-10'}
                        />
                    </TableCard>
                </Card>
            </div>
        )
    }
}
