import React, {useState} from "react";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import ClockIcon from "@heroicons/react/20/solid/ClockIcon";
import moment from "moment-timezone";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../../util/util-constants";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import FieldsToHtml from "../../../../../common/components/fields/fields-to-html";
import ModalConfirm from "../../../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../../../common/util/util-fields";
import FormSubtitle from "../../../../../common/components/forms/form-subtitle";
import {
    getTimeFromServerDate,
    timePickerValueToServerTime,
    toMilitaryTime
} from "../../../../../common/util/util-dates";
import InfoParagraph from "../../../../../common/components/info-paragraph";
import {DEFAULT_DATABASE_DATE_FORMAT} from "../../../../../common/util/util-consts";

export default function LoadCompleteStopByDialog({
                                                     show,
                                                     stopsCombined,
                                                     selectedCombinedLocationIndex,
                                                     onClose,
                                                     onSubmit,
                                                     isLoadDropped,
                                                     translate
                                                 }) {
    if (!show) return null;
    // This dialog cannot be opened unless the previous stop is completed.
    // If the previous stop is complete, no check is needed.
    const currentStop = stopsCombined[selectedCombinedLocationIndex];
    const IsStopCompleted = currentStop?.IsStopCompleted ? 1 : 0;
    const canNextBeCompleted = !!stopsCombined[selectedCombinedLocationIndex - 1]?.IsStopCompleted
        && !!stopsCombined[selectedCombinedLocationIndex]?.IsStopCompleted
        && !stopsCombined[selectedCombinedLocationIndex + 1]?.IsStopCompleted;
    const isNextStopCompleted = !!stopsCombined?.[selectedCombinedLocationIndex + 1] && stopsCombined[selectedCombinedLocationIndex + 1].IsStopCompleted;

    const isNextStopDropped = canNextBeCompleted && isLoadDropped;

    const [fields, setFields] = useState(getFields(currentStop));

    const isTimeInAfterTimeOut = checkIsTimeInAfterTimeOut();
    const isTimeOutTooMuchInFuture = checkIsTimeOutTooMuchInFuture();
    const isBeforeLastCompletedStopDate = checkIsBeforeLastCompletedStopDate();

    function checkIsTimeOutTooMuchInFuture() {
        let minutesDifference = 0;

        if (stopsCombined[selectedCombinedLocationIndex - 1] && stopsCombined[selectedCombinedLocationIndex - 1]?.IsStopCompleted) { // prev stop exists and is completed

            const date1 = moment(fields.ActualArrivalDate.value?.split(" ")?.[0] + " " + toMilitaryTime(fields.ActualArrivalDateTime.value));
            const date2 = moment();

            minutesDifference = date1.diff(date2, 'minutes');
        }

        return minutesDifference > (1440 * 3);
    }

    function checkIsTimeInAfterTimeOut() {
        if (stopsCombined[selectedCombinedLocationIndex - 1] && stopsCombined[selectedCombinedLocationIndex - 1]?.IsStopCompleted) { // prev stop exists and is completed
            const date1 = moment(fields.ActualArrivalDate.value?.split(" ")?.[0] + " " + toMilitaryTime(fields.ActualArrivalDateTime.value));
            const date2 = moment(fields.ActualDepartureDate.value?.split(" ")?.[0] + " " + toMilitaryTime(fields.ActualDepartureDateTime.value));

            return date2.isBefore(date1);
        }

        return false;
    }

    function checkIsBeforeLastCompletedStopDate() {
        if (!fields?.ActualArrivalDateTime?.value) {
            return false;
        }

        if (stopsCombined[selectedCombinedLocationIndex - 1]) { // prev stop exists

            let arrivalTime = toMilitaryTime(fields.ActualArrivalDateTime.value);

            if (arrivalTime === 'Invalid date') {
                arrivalTime = "00:00"
            }

            const prevStopDepartureDateTime = stopsCombined[selectedCombinedLocationIndex - 1]?.metadata?.ActualDepartureDateTime?.replace(/:00$/, '');
            const selectedStopArrivalDateTime = moment(fields.ActualArrivalDate.value, DEFAULT_DATABASE_DATETIME_FORMAT).format(DEFAULT_DATABASE_DATE_FORMAT) + " " + arrivalTime

            if (!!prevStopDepartureDateTime && selectedStopArrivalDateTime) {
                return moment(selectedStopArrivalDateTime).isBefore(prevStopDepartureDateTime);
            }
        }

        return false;
    }

    function handleSubmit() {
        const validFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validFields)) {
            const params = FieldsManager.getFieldKeyValues(validFields);

            params.ActualArrivalDateTime = params.ActualArrivalDate.split(" ")[0] + " " + timePickerValueToServerTime(params.ActualArrivalDateTime);
            delete params.ActualArrivalDate;

            params.ActualDepartureDateTime = params.ActualDepartureDate.split(" ")[0] + " " + timePickerValueToServerTime(params.ActualDepartureDateTime);
            delete params.ActualDepartureDate;

            onSubmit(params);
        } else {
            setFields(validFields);
        }
    }

    function handleInputChange(name, value) {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value)

        fieldsUpdate.ActualDepartureDateTime.metadata.htmlAfter = () => {
            return (!!fieldsUpdate.ActualArrivalDate.value && !!fieldsUpdate.ActualDepartureDate.value) || IsStopCompleted
                ? null
                : (
                    <div className="relative col-span-full h-4">
                        <button
                            className="btn btn-text font-semibold absolute left-auto -top-2 right-0"
                            onClick={autoCompleteTimes}
                        >
                            <ClockIcon className="w-5 h-5 mr-1"/> Auto fill times
                        </button>
                    </div>
                )
        }

        fieldsUpdate[name].errorMessage = "";

        fieldsUpdate.IsStopCompleted.disabled = checkIsCompleteDisabled();

        if (name === "ActualArrivalDate") {
            fieldsUpdate.ActualArrivalDateTime.validate = value ? ["empty"] : [""];
        }

        if (name === "ActualDepartureDate") {
            fieldsUpdate.ActualDepartureDateTime.validate = value ? ["empty"] : [""];
        }

        setFields(fieldsUpdate)
    }

    function autoCompleteTimes() {
        let fieldsUpdate = fields;
        fieldsUpdate.ActualArrivalDate.value = moment().format(DEFAULT_DATABASE_DATETIME_FORMAT);
        fieldsUpdate.ActualArrivalDateTime.value = getTimeFromServerDate(moment().format(DEFAULT_DATABASE_DATETIME_FORMAT).split(" ")[1]);

        fieldsUpdate.ActualDepartureDate.value = moment().add(1, "hour").format(DEFAULT_DATABASE_DATETIME_FORMAT);

        let departureTime = moment().add(1, "hour").format(DEFAULT_DATABASE_DATETIME_FORMAT);
        departureTime = getTimeFromServerDate(departureTime.split(" ")[1]);
        fieldsUpdate.ActualDepartureDateTime.value = departureTime;

        if (!fieldsUpdate.ActualDepartureDateTime.metadata) {
            fieldsUpdate.ActualDepartureDateTime.metadata = {}
        }

        fieldsUpdate.ActualDepartureDateTime.metadata = Object.assign(fieldsUpdate.ActualDepartureDateTime.metadata, {
            ActualArrivalDate: fieldsUpdate.ActualArrivalDate.value,
            ActualDepartureDate: fieldsUpdate.ActualDepartureDate.value
        })

        fieldsUpdate.IsStopCompleted.disabled = false;

        setFields(fieldsUpdate);
    }

    function getFields(currentStop = {}) {
        const item = currentStop?.metadata ?? {};

        let fieldTemplates = {
            ActualArrivalDate: new Field('ActualArrivalDate', '', ['empty'], !!IsStopCompleted, 'datetime', {
                addContainerClass: 'col-start-1 w-[calc(100%+1rem+1px)]',
                label: "time_in",
                htmlBefore: () => <FormSubtitle subtitle={translate('text.time_in_out')}/>
            }, {isClearable: true, addClass: "rounded-r-none"}),
            ActualArrivalDateTime: new Field('ActualArrivalDateTime', '', item?.ActualArrivalDateTime ? ['empty'] :  [''], !!IsStopCompleted, 'time-custom', {hideLabel: true}, {addClass: "rounded-l-none"}),
            ActualDepartureDate: new Field('ActualDepartureDate', '', ['empty'], !!IsStopCompleted, 'datetime', {
                addContainerClass: 'col-start-1 w-[calc(100%+1rem+1px)]',
                label: "time_out",
            }, {isClearable: true, addClass: "rounded-r-none"}),
            ActualDepartureDateTime: new Field('ActualDepartureDateTime', '', item?.ActualDepartureDateTime ? ['empty'] :  [''], !!IsStopCompleted, 'time-custom', {
                hideLabel: true,
                htmlAfter: () => {
                    return (!!item.ActualArrivalDate && !!item.ActualDepartureDate) || IsStopCompleted
                        ? null
                        : (
                            <div className="relative col-span-full h-4">
                                <button
                                    className="btn btn-text font-semibold absolute left-auto -top-2 right-0"
                                    onClick={autoCompleteTimes}
                                >
                                    <ClockIcon className="w-5 h-5 mr-1"/> Auto fill times
                                </button>
                            </div>
                        )
                }
            }, {addClass: "rounded-l-none"}),
            LoadStopByID: new Field('LoadStopByID', currentStop.metadata.LoadStopByID, [''], false, 'hidden'),
            IsStopCompleted: new Field('IsStopCompleted', IsStopCompleted, [''], isNextStopCompleted || isNextStopDropped, 'checkbox', {note: translate("text.stop_completed_note")})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function checkIsCompleteDisabled() {
        if (isNextStopCompleted || isNextStopDropped) {
            return true;
        } else if (fields.IsStopCompleted.value) {
            return false;
        } else if (!fields.ActualArrivalDate.value
            || !fields.ActualArrivalDateTime.value
            || !fields.ActualDepartureDate.value
            || !fields.ActualDepartureDateTime.value) {
            return true;
        }

        return false;
    }

    return (
        <ModalConfirm
            title={currentStop?.IsStopCompleted ? translate("header.un_complete_stop_by") : translate("text.complete_stop_by")}
            show={show}
            onClose={onClose}
            buttonLabel={translate("text.save")}
            buttonDisabled={isNextStopCompleted || isBeforeLastCompletedStopDate || isTimeInAfterTimeOut}
            closeButtonLabel={'Cancel'}
            translate={translate}
            onConfirm={handleSubmit}
        >
            {!isNextStopCompleted && (
                <div className="text-base">
                    {currentStop?.IsStopCompleted
                        ? translate("text.confirm_un_complete_stop_by")
                        : translate("text.confirm_complete_stop_by")}
                </div>
            )}

            {isTimeInAfterTimeOut && (
                <div className="relative top-5 col-span-2 font-medium text-base">
                    <InfoParagraph type="danger" message={"Time In is after Time Out"}/>
                </div>
            )}

            {isBeforeLastCompletedStopDate && !isTimeInAfterTimeOut && (
                <div className="relative top-5 col-span-2 font-medium text-base">
                    <InfoParagraph type="danger"
                                   message={"Time in date should not be before last completed stop date"}/>
                </div>
            )}

            {isTimeOutTooMuchInFuture && !isTimeInAfterTimeOut && (
                <div className="relative top-5 col-span-2 font-medium text-base">
                    <InfoParagraph type="warning"
                                   message={"Time in must be within three days from today."}/>
                </div>
            )}

            <div className="mt-8 grid grid-cols-2 gap-4">
                <FieldsToHtml
                    fieldsState={fields}
                    onInputChange={handleInputChange}
                    translate={translate}
                />

                {isNextStopCompleted && (
                    <div className="col-span-full">
                        <InfoBar>
                            {translate("text.stop_completion_note")}
                        </InfoBar>
                    </div>
                )}

                {!!isNextStopCompleted && (
                    <div className="text-base col-span-full">
                        {translate("text.stop_off_completion_note")}
                    </div>
                )}

                {!!isNextStopDropped && (
                    <div className="text-base col-span-full">
                        {translate("text.stop_off_next_dropped_note")}
                    </div>
                )}

            </div>
        </ModalConfirm>
    );
}
