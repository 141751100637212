import { Field } from '../../../../../data/services/fields'
import React, { useMemo, useReducer } from 'react'
import { feeTypes } from '../../../../../util/util-constants'
import { cloneDeep } from 'tailwindcss/lib/util/cloneDeep'
import ResourceTable from "../../../../../common/components/resource-table";
import InfoParagraph from "../../../../../common/components/info-paragraph";
import ModalFooter from "../../../../../common/components/modal/modal-footer";

export default function ApplyCustomerSettingsForm ({
    accessorialsData,
    commissionData,
    extraChargesData,
    customerData,
    calculateValueBasedOnFee,
    onApplySelected,
    onClose,
    translate
}) {
    const hasData = !!accessorialsData?.length || !!commissionData.length || !!extraChargesData?.length || !!customerData?.length

    accessorialsData = useMemo(() => accessorialsData.map(it => {
        it.Total = (it.CustomerAccessorialUnits * it.CustomerAccessorialAmount)
        return it
    }), [accessorialsData])
    commissionData = useMemo(() => commissionData.map(it => {
        it.Total = calculateValueBasedOnFee(it)
        return it
    }), [commissionData])

    const reducer = (list, action) => {
        let listUpdate = cloneDeep(list)

        switch (action.type) {
            case 'toggle-row':
                const id = action.payload

                if (listUpdate[id]?.selected) {
                    delete listUpdate[id]
                    return listUpdate
                } else {
                    return Object.assign(listUpdate, { [id]: { id: id, selected: true } })
                }
            case 'toggle-all':
                const key = action.payload.key
                let data

                switch (action.payload.listName) {
                    case 'accessorialsData':
                        data = accessorialsData
                        break
                    case 'commissionData':
                        data = commissionData
                        break
                    default:
                        data = {}
                }
                const listValues = Object.values(listUpdate)

                const areAllSelected = data.length === listValues.filter(it => it.selected).length

                if (areAllSelected) {
                    return {}
                } else {
                    return data.reduce((memo, it) => {
                        memo[it[key]] = { id: it[key], selected: true }
                        return memo
                    }, {})
                }
            default:
                return listUpdate
        }
    }

    const [accessorials, dispatchAccessorials] = useReducer(reducer, {})
    const [commission, dispatchCommission] = useReducer(reducer, {})

    const handleAccessoryClick = (item) => {
        dispatchAccessorials({ type: 'toggle-row', payload: item.AccessorialID })
    }

    const handleCommissionClick = (item) => {
        dispatchCommission({ type: 'toggle-row', payload: item.CustomerCommisionID })
    }

    const handleSelectAllAccessorialsClick = () => {
        dispatchAccessorials({ type: 'toggle-all', payload: { key: 'AccessorialID', listName: 'accessorialsData' } })
    }

    const handleSelectAllCommissionClick = () => {
        dispatchCommission({ type: 'toggle-all', payload: { key: 'CustomerCommisionID', listName: 'commissionData' } })
    }

    return (
        <React.Fragment>
            <div className="space-y-8 pt-8">
                {!!accessorialsData?.length && (
                    <div>
                        <h2
                            className="mb-1 max-w-6xl mx-auto px-4 text-lg leading-6 font-medium text-tm-gray-900 sm:px-6 lg:px-8">
                            Accessorials
                        </h2>

                        <div className="mx-6 rounded-lg border border-tm-gray-300 overflow-hidden">
                            <ResourceTable
                                data={accessorialsData}

                                fields={{
                                    Accessorial: new Field('Accessorial', '', ['']),
                                    CustomerAccessorialUnits: new Field('CustomerAccessorialUnits', '', [''], false, 'float'),
                                    CustomerAccessorialAmount: new Field('CustomerAccessorialAmount', '', [''], false, 'float'),
                                    Total: new Field('Total', '', [''], false, 'float'),
                                }}

                                options={{
                                    behaviour: {
                                        rowSelect: true,
                                    },
                                    style: {
                                        verticalLines: true,
                                    },
                                }}

                                selectedRows={accessorials}
                                onSelectAllClick={handleSelectAllAccessorialsClick}
                                onSelectRow={handleAccessoryClick}
                                onRowClick={handleAccessoryClick}
                                tableKey={'AccessorialID'}

                                translate={translate}
                            />
                        </div>
                    </div>
                )}

                {!!commissionData?.length && (
                    <div>
                        <h2
                            className="mb-1 max-w-6xl mx-auto px-4 text-lg leading-6 font-medium text-tm-gray-900 sm:px-6 lg:px-8">
                            Commission
                        </h2>

                        <div className="mx-6 rounded-lg border border-tm-gray-300 overflow-hidden">
                            <ResourceTable
                                data={commissionData}
                                fields={{
                                    Contact: new Field('Contact', '', ['']),
                                    Email: new Field('Email', '', [''], false, 'float'),
                                    Amount: new Field('Amount', '', [''], false, 'float'),
                                    FeeTypeID: new Field('FeeTypeID', '', [''], false, 'select', {}, {
                                        values: Object.values(feeTypes).reduce((memo, it, i) => {
                                            memo[i + 1] = translate(it)
                                            return memo
                                        }, {})
                                    }),
                                    Total: new Field('Total', '', [''], false, 'float'),
                                }}

                                options={{
                                    behaviour: {
                                        rowSelect: true,
                                    },
                                    style: {
                                        verticalLines: true,
                                    },
                                }}

                                onSelectAllClick={handleSelectAllCommissionClick}
                                selectedRows={commission}
                                onSelectRow={handleCommissionClick}
                                onRowClick={handleCommissionClick}

                                tableKey={'CustomerCommisionID'}

                                translate={translate}
                            />
                        </div>
                    </div>
                )}

                {!hasData && (
                    <InfoParagraph
                        message="This customer does not have any default settings set. To set settings go to customer page."
                    />
                )}

                <ModalFooter
                    onClose={onClose}
                    closeButtonLabel={translate('btn.cancel')}
                    buttonDisabled={!hasData}
                    onButtonClick={() => onApplySelected(accessorialsData.filter(it => !!accessorials[it.AccessorialID]), commissionData.filter(it => !!commission[it.CustomerCommisionID]))}
                    buttonLabel={translate('btn.apply_selected')}
                />
            </div>
        </React.Fragment>
    )
}