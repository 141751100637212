import React, {useState} from 'react'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import {Field, FieldsManager} from '../../../data/services/fields'
import {classNames, getLookupWithFilter} from '../../../common/util/util-helpers'
import {
    ACCOUNT_CREDIT_CARD,
    ACCOUNT_TYPE_CASH,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    PAYMENT_METHOD_CACHE,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_CHECK,
    PAYMENT_METHOD_DEBIT,
    PAYMENT_METHOD_FACTOR
} from '../../../util/util-constants'
import moment from 'moment'
import Resources from '../../../data/services/resources'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon'
import ModalFooter from "../../../common/components/modal/modal-footer";
import InfoParagraph from "../../../common/components/info-paragraph";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import Subtitle from "../../../common/components/layout/layout-components/page/subtitle";

const PayMultipleBillsDialog = ({
                                    translate,
                                    onClose,
                                    onSubmit,
                                    onRemoveFromSelected,
                                    selectedItems
                                }) => {

    /** Helpers
     ================================================================= */
    const getAccountTypeForPayment = (value) => {
        let QueryAccountTypeID = ACCOUNT_TYPE_CASH
        switch (+value) {
            case PAYMENT_METHOD_CACHE:
            case PAYMENT_METHOD_DEBIT:
            case PAYMENT_METHOD_CHECK:
                QueryAccountTypeID = ACCOUNT_TYPE_CASH
                break
            case PAYMENT_METHOD_CARD:
                QueryAccountTypeID = ACCOUNT_CREDIT_CARD
                break
        }
        return QueryAccountTypeID
    }
    /** Constants
     ================================================================= */
    const selects = {
        PaymentTypeID: getLookupWithFilter('PaymentType', 'PaymentTypeID', 'PaymentType', (it) => {
            return it.PaymentTypeID !== PAYMENT_METHOD_FACTOR
        }),
        AccountID: {
            api: 'api/' + Resources.AccountsQuick,
            searchMap: (item) => ({
                label: item.AccountName,
                value: item.AccountID
            })
        },
        DepositOverpayToID: {
            api: 'api/' + Resources.AccountsQuick,
            query: {},
            searchMap: (item) => ({
                label: item.AccountName,
                value: item.AccountID
            })
        }
    }

    /** Fields/data definitions
     ================================================================= */
    const getSingleItemFields = (item, index) => {
        let fieldTemplates = Object.assign({},
            item.Organization ?
                {Organization: new Field('Organization', '', ['empty'], true, 'text', {})} :
                {Contact: new Field('Contact', '', ['empty'], true, 'text', {})},
            {
                Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {
                    fieldOptions: () => (
                        <span
                            onClick={() => handleCopyFieldValue('Date', index)}
                            className="ml-1 text-white inline-flex items-center px-2.5 py-0 rounded-md text-xs font-sm whitespace-nowrap max-w-[7rem] bg-primary cursor-pointer mb-1">
                        <div className="truncate">Copy to all</div>
                    </span>
                    )
                }),
                PaymentTypeID: new Field('PaymentTypeID', PAYMENT_METHOD_CACHE, ['empty'], false, 'select', {
                    fieldOptions: () => (
                        <span
                            onClick={() => handleCopyFieldValue('PaymentTypeID', index)}
                            className="ml-1 text-white inline-flex items-center px-2.5 py-0 rounded-md text-xs font-sm whitespace-nowrap max-w-[7rem] bg-primary cursor-pointer mb-1">
                        <div className="truncate">Copy to all</div>
                    </span>
                    )
                }),
                AccountID: new Field('AccountID', '', [''], false, 'select-search', {
                    fieldOptions: () => (
                        <span
                            onClick={() => handleCopyFieldValue('AccountID', index)}
                            className="ml-1 text-white inline-flex items-center px-2.5 py-0 rounded-md text-xs font-sm whitespace-nowrap max-w-[7rem] bg-primary cursor-pointer mb-1">
                        <div className="truncate">Copy to all</div>
                    </span>
                    )
                }, {
                    query: {
                        AccountTypeID: ACCOUNT_TYPE_CASH
                    },
                    key: 0
                }),
                Description: new Field('Description', '', [], false, 'textarea'),
                InvoiceID: new Field('InvoiceID', '', []),
                ExpenseID: new Field('ExpenseID', '', []),
                Amount: new Field('Amount', '', [''], false, 'money', {
                    addContainerClass: 'col-span-2',
                    originalAmount: item.Amount,
                    hideLabel: true,
                    labelType: 'float'
                }),
                DepositOverpayToID: new Field('DepositOverpayToID', '', [''], false, 'hidden')
            })

        fieldTemplates = fillFieldsFromData(fieldTemplates, item)
        fieldTemplates['Amount'].value = item.Amount - item.AmountTransferred

        return fieldTemplates
    }

    const getFields = () => {
        return Object.values(selectedItems)
            .filter(item => item.ExpenseStatusID == 1 && !item.IsDelayedPayment)
            .map((item, index) => {
                return getSingleItemFields(item, index)
            })
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [sectionsCollapsed, setSectionsCollapsed] = useState({})

    /** UI Events
     ================================================================= */
    const handleCopyFieldValue = (name, index) => {
        const value = fields[index][name].value
        let items = [...fields].map((item, i) => {
            if (name === 'AccountID') {
                // Check for same Payment Type
                if (item['PaymentTypeID'].value == fields[index]['PaymentTypeID'].value) {
                    item = FieldsManager.updateField(fields[i], name, value)
                }
            } else {
                item = FieldsManager.updateField(fields[i], name, value)
                if (name === 'PaymentTypeID') {
                    item['AccountID'].value = ''
                    item['AccountID'].props.key += 1
                    item['AccountID'].props.query = {
                        AccountTypeID: getAccountTypeForPayment(value)
                    }
                }
            }

            return item
        })
        setFields(items)
    }

    const handleSubmit = () => {
        const validatedFields = fields.map((it) => FieldsManager.validateFields(it))
        if (validatedFields.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)), true)) {
            onSubmit(validatedFields.map(it => FieldsManager.getFieldKeyValues(it)))
        } else {
            setFields(validatedFields)
        }
    }

    const handleInputChange = (name, value, index) => {
        let items = [...fields]
        items[index] = FieldsManager.updateField(items[index], name, value)
        if (name === 'PaymentTypeID') {
            items[index]['AccountID'].value = ''
            items[index]['AccountID'].props.key += 1
            items[index]['AccountID'].props.query = {
                AccountTypeID: getAccountTypeForPayment(value)
            }
        }
        if (name === 'Amount') {
            const currentItem = selectedItems[items[index].ExpenseID.value]
            items[index]['DepositOverpayToID'].validate = (currentItem.Amount < value) ? ['empty'] : []
            items[index]['DepositOverpayToID'].type = (currentItem.Amount < value) ? 'select-search' : 'hidden'
        }
        setFields(items)
    }

    const handleToggleSectionCollapse = (id) => {
        const sections = Object.assign({}, sectionsCollapsed)
        if (sections[id]) {
            delete sections[id]
        } else {
            sections[id] = id
        }
        setSectionsCollapsed(sections)
    }

    const handleRemoveFromSelected = (id, index) => {
        onRemoveFromSelected({ExpenseID: id})
        setFields(fields.filter((_, i) => i !== index))
    }

    /** Render
     ================================================================= */
    const getFieldsToHtml = (arr, fields, index) => {
        return fieldsToHtml(Object.values(includeFields(Object.assign({}, fields), arr)), translate, (name, value) => handleInputChange(name, value, index), selects)
    }

    const fieldsHtml = fields.map((item, index) => {
        const paymentFieldsHtml = getFieldsToHtml(['Organization', 'Contact', 'Date', 'PaymentTypeID', 'AccountID'], item, index)
        const descriptionFieldsHtml = getFieldsToHtml(['Description'], item, index)
        const tableFieldsHtml = getFieldsToHtml(['Amount'], item, index)
        const overpayFieldsHtml = getFieldsToHtml(['DepositOverpayToID'], item, index)

        const currentItem = selectedItems[item.ExpenseID.value]

        return (
            <div
                className={classNames(index !== 0 && 'border-t mt-3', index % 2 !== 0 ? 'bg-tm-gray-300' : '', 'px-5 py-2')}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <button
                            className="btn btn-icon -ml-2 md mr-2 w-8 h-8"
                            onClick={() => handleToggleSectionCollapse(currentItem.ExpenseID)}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        'w-5 h-5',
                                        sectionsCollapsed[currentItem.ExpenseID] ? 'rotate-90' : undefined
                                    )
                                }
                            />
                        </button>

                        <Subtitle subtitle={`${index + 1}. Expense: ${currentItem.AutoCounter}`}/>
                    </div>
                    <div>
                        <span
                            onClick={() => handleRemoveFromSelected(currentItem.ExpenseID, index)}
                            className="ml-1 text-white inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-sm whitespace-nowrap bg-red-600 cursor-pointer mb-1">
                        <div className="truncate">Remove from selected</div>
                    </span>
                    </div>
                </div>
                {!sectionsCollapsed[currentItem.ExpenseID] && (
                    <>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="grid grid-cols-2 gap-3">
                                {paymentFieldsHtml}
                            </div>
                            <div>
                                {descriptionFieldsHtml}
                            </div>
                        </div>
                        <div>
                            <div className="table table-borderless text-break mb-0">
                                <div
                                    className="grid grid-cols-4 bg-tm-gray-100 text-tm-gray-900 font-semibold p-2 mt-4">
                                    <p className="pl-3">{translate('table.ExpenseNumber')}</p>
                                    <p className="pl-3">{translate('table.Amount')}</p>
                                    <p className="pl-3 col-span-2">{translate('table.Amount')} *</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 gap-3 bg-tm-gray-50 p-2">
                                <p className="p-3">{currentItem.AutoCounter}</p>
                                <p className="p-3">{genericMoneyFormatter(item.Amount?.metadata?.originalAmount)}</p>
                                {tableFieldsHtml}
                            </div>
                        </div>
                        <div>{/*overpayFieldsHtml*/}</div>

                        {currentItem.AmountTransferred !== 0 && !!currentItem.AmountTransferred && (
                            <div
                                className="m-2 text-base font-bold"
                            >
                                <div>Transferred so far: {genericMoneyFormatter(currentItem.AmountTransferred)}.</div>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    })

    const paidExpenses = Object.values(selectedItems).map(item => {
        if (item.ExpenseStatusID != 1) {
            return (
                <div className={'px-5'}>
                    <InfoParagraph
                        type="success"
                        className="mb-4 text-sm "
                    >
                        Expense {item.AutoCounter} is already paid.
                    </InfoParagraph>
                </div>
            )
        }
    })

    return (
        <div className="relative">
            <header
                className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900">
                <h2 className="text-lg text-current truncate">{translate('text.PayMultipleBills')}</h2>

                <button
                    className="absolute right-5 top-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary rounded-full"
                    aria-label="Close"
                    onClick={() => {
                        onClose()
                    }}>
                    <XMarkIcon className="w-5 h-5"/>
                </button>
            </header>

            <div className={''}>
                {fieldsHtml}

                <div>
                    {paidExpenses}
                </div>
            </div>


            <ModalFooter
                buttonLabel={fields.length && translate('btn.save')}
                onButtonClick={handleSubmit}

                closeButtonLabel={translate('btn.cancel')}
                onClose={onClose}
            />
        </div>
    )
}

export default PayMultipleBillsDialog
