import {
    ACCOUNT_CREDIT_CARD,
    ACCOUNT_TYPE_CASH,
    ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
    PAYMENT_METHOD_CACHE, PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_CHECK, PAYMENT_METHOD_DEBIT
} from "./util-consts";

/**
 * Returns the monthly payment amount as a fixed decimal with 2 decimal places, and the monthly payment breakdown
 *
 * 1. 30/360 (Bond Basis)
 * Here, the interest rate will be adjusted assuming each month has 30 days and the year has 360 days. The monthly interest rate is calculated based on the 360-day year.
 *
 * 2. Actual/360
 * The interest for each month will be calculated based on the actual number of days in the month, but with the annual rate divided by 360.
 *
 * 3. Actual/365
 * Similar to Actual/360, but the annual interest rate is divided by 365, accounting for the actual number of days in a year.
 * @param principal - Iznos pozajmice
 * @param interestRate - Godisnja kamata
 * @param loanTerm (the loan term in months) - vreme vracanja na mesecnom nivou
 * @param downPayment - Ucesce
 * @returns {{monthlyBreakdown: *[], monthlyPayment: string, interestAmount: number, totalAmountPaid: number}}
 */
export const calculateLoanPayment = (principal, interestRate, loanTerm, downPayment = 0) => {
    principal = principal - downPayment;
    interestRate = (interestRate) / (12 * 100);
    loanTerm = (loanTerm);
    let x = Math.pow(1 + interestRate, loanTerm);
    let monthlyPayment = interestRate > 0 ? (principal * x * interestRate) / (x - 1) : (principal/(loanTerm > 0 ? loanTerm : 1));
    let interestAmount = (monthlyPayment * loanTerm) - principal;
    let totalAmountPaid = monthlyPayment * loanTerm;
    let currentPrincipal = principal;
    let monthlyBreakdown = [];
    let currentInterestLeft = 0;
    for (let i = 1; i <= loanTerm; i++) {
        let interest = currentPrincipal * interestRate;
        currentPrincipal -= (monthlyPayment - interest);
        currentInterestLeft += interest;
        monthlyBreakdown.push({
            month: i,
            monthlyPayment: monthlyPayment,
            interest: interest,
            currentPrincipal: currentPrincipal,
            currentInterestLeft: currentInterestLeft
        })
    }
    return {
        interestAmount: interestAmount,
        totalAmountPaid: totalAmountPaid,
        monthlyBreakdown: monthlyBreakdown,
        monthlyPayment: monthlyPayment
    };
}

export const daysInYearCount = (year) => {
    return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
}

export const calculateNextLoanPaymentPerDay = (principalLeft, yearlyInterestRate, customPaymentAmount, numberOfDays, year) => {
    // Convert the yearly interest rate to daily rate
    const daysInYear = daysInYearCount(year);
    const dailyInterestRate = (yearlyInterestRate / 100) / daysInYear;

    let dailyInterestAccrued = principalLeft * dailyInterestRate * numberOfDays;

    const convention = "Actual/360";
    switch (convention) {
        case '30/360':
            dailyInterestAccrued = principalLeft * ((yearlyInterestRate / 100)) * (numberOfDays / 360);
            break;
        case 'Actual/360': // 365/360
            dailyInterestAccrued = principalLeft * ((yearlyInterestRate / 100)) * (numberOfDays / 360);
            break;
        case 'Actual/365':
            dailyInterestAccrued = principalLeft * ((yearlyInterestRate / 100)) * (numberOfDays / 365);
            break;
    }

    // Calculate how much of the payment goes towards interest and principal
    const interestPayment = dailyInterestAccrued;
    const principalPayment = customPaymentAmount - interestPayment;

    return {
        principalPayment: principalPayment.toFixed(2),
        interestPayment: interestPayment.toFixed(2),
        principalAmount: parseFloat(principalPayment.toFixed(2)),
        interestAmount: parseFloat(interestPayment.toFixed(2))
    };
};

export const planLoanPayments = (
    StartDate, // Datetime first loan payment should be made
    Amount, // Total principal
    ResidualValue,
    InterestRate, // Annual interest rate e.g., 8.43
    LoanTerm, // Loan term in months, e.g., 12
    DownPayment = 0
) => {
    // Adjusting the principal after considering the down payment
    const principal = Amount - DownPayment;
    const monthlyInterestRate = InterestRate / 100 / 12;

    // Calculate the fixed monthly payment using the annuity formula
    const monthlyPayment = (principal - ResidualValue * Math.pow(1 + monthlyInterestRate, -LoanTerm)) * monthlyInterestRate /
        (1 - Math.pow(1 + monthlyInterestRate, -LoanTerm));

    let cumulativeInterest = 0;
    let beginningBalance = principal;

    const plannedPayments = [];

    for (let i = 0; i < LoanTerm; i++) {
        const date = new Date(new Date(StartDate).setMonth(new Date(StartDate).getMonth() + i)).toISOString().slice(0, 10);

        const data = calculateNextLoanPaymentPerDay(
            beginningBalance,
            InterestRate,
            monthlyPayment,
            30,
            date.slice(0, 4)
        );

        cumulativeInterest += data.interestAmount;
        const endingBalance = beginningBalance - data.principalPayment;

        plannedPayments.push({
            PaymentNumber: i + 1,
            PaymentDate: date,
            BeginningBalance: beginningBalance.toFixed(2),
            ScheduledPayment: monthlyPayment.toFixed(2),
            Principal: data.principalAmount.toFixed(2),
            Interest: data.interestAmount.toFixed(2),
            EndingBalance: endingBalance.toFixed(2),
            CumulativeInterest: cumulativeInterest.toFixed(2)
        });

        beginningBalance = parseFloat(endingBalance.toFixed(2));
    }

    return {
        monthlyBreakdown: plannedPayments
    };
}


export const PaymentAccountTypeExpense = (QueryAccountTypeID, value) => {
    //expense
    switch (+value) {
        case PAYMENT_METHOD_CACHE:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH
            break
        case PAYMENT_METHOD_CHECK:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH
            break
        case PAYMENT_METHOD_CARD:
            QueryAccountTypeID = ACCOUNT_CREDIT_CARD
            break
        case PAYMENT_METHOD_DEBIT:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH
            break
        default:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH;
            break;
    }

    return QueryAccountTypeID
}

export const PaymentAccountTypeIncome = (QueryAccountTypeID, value) => {
    //income
    switch (+value) {
        case PAYMENT_METHOD_CACHE:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH
            break
        case PAYMENT_METHOD_CHECK:
            QueryAccountTypeID = ACCOUNT_TYPE_OTHER_CURRENT_ASSETS
            break
        case PAYMENT_METHOD_CARD:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH
            break
        case PAYMENT_METHOD_DEBIT:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH
            break
        default:
            QueryAccountTypeID = ACCOUNT_TYPE_CASH;
            break;
    }
    return QueryAccountTypeID
}