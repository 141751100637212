import React, {Component} from "react";
import {FieldsManager} from "../../../../data/services/fields";
import {classNames, getProp} from "../../../util/util-helpers";
import InfoBar from "../../info-paragraph/info-bar";
import ResourceTable from "../../resource-table";
import Pagination from "../../resource-table/table-components/pagination";
import NoRecords from "../../no-records-found/no-records";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import Modal from "../index";
import ModalHeader from "../modal-header";
import {FunnelIcon} from "@heroicons/react/24/outline";
import FieldContainer from "../../fields/field-container";
import FieldSearch from "../../fields/field-text/search";
import Buttons from "../../../components/buttons";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import {excludeFields, fieldsToHtml} from "../../../util/util-fields";
import ModalFooter from "../modal-footer";

export default class ResourceTableDialogWithFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationButtonLimit: this.props.paginationButtonLimit ?? 5,
            fieldsFilter: this.props.fieldsFilter ?? [],
            isFilterSidebarVisible: false
        }
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.show && prevProps.show !== this.props.show) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: this.props.resource,
            query: this.getQuery()
        }));
    };

    handleQueryChange = (name, value) => {
        let filterFields = this.state.fieldsFilter;
        if ("query" === name) {
            filterFields = FieldsManager.updateField(filterFields, "offset", 0);
        }

        if ("sortBy" === name) {
            filterFields.sort.value = filterFields.sort.value === 'ASC' ? 'DESC' : 'ASC'
        }

        if ("clearAll" === name) {
            filterFields = Object.values(filterFields).reduce((memo, it) => {
                if (it.name !== "sortBy" && it.name !== "sort" && it.name !== "limit") {
                    it.value = "";
                }

                memo[it.name] = it;

                return memo;
            }, {});

            name = "query";
            value = "";
            filterFields.offset.value = 0;
        }


        this.setState({
            fieldsFilter: FieldsManager.updateField(filterFields, name, value)
        }, () => {
            this.fetchData()
        });
    }

    handleFiltersClick = () => {
        this.setState({
            isFilterSidebarVisible: !this.state.isFilterSidebarVisible
        });
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        let query = {
            ...FieldsManager.getFieldKeyValues(this.state.fieldsFilter),
            ...this.props.defaultQuery
        }

        if (this.props.id) {
            query.id = this.props.id;
        }

        if (this.props.storeKey) {
            LocalStorage.set(this.props.storeKey, query);
        }

        return this.props.onQueryTransform ? this.props.onQueryTransform(query) : query;
    }

    render() {
        const {
            translate,
            initialFocusRef,
            show,
            title,
            widthClass,
            fields,
            selects,
            defaultAction,
            onView,
            onCreate,
            onDelete,
            onCustomActions,
            selectedRows,
            onSelectRow,
            onSelectAllRows,
            tableKey,
            messages = [],
            onClose,
            closeButtonLabel,
            options,
            htmlBefore,
            htmlAfter,
            limitHeight,
            maxHeightClass,
            hasDialogFooter = false,
            footerButtonLabel,
            onFooterButtonClick,
            footerButtonDisabled,
            footerCloseButtonLabel,
            onFooterCloseButtonClick,
            buttons = []
        } = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);
        const isLoading = getProp(this.props, "dialogResource.isLoading", false);


        const filters = fieldsToHtml(Object.values(Object.assign({}, excludeFields(this.state.fieldsFilter, ['limit']))), translate, this.handleQueryChange, selects);

        const messagesRender = messages.map(message => {
            return (
                <div className="px-6 pt-3">
                    <InfoBar
                        type={message.messageType}
                        onClick={() => !!message.handler && message.handler(message)}
                        Icon={message.icon}
                    >
                        {message.text}

                        {message.content}
                    </InfoBar>
                </div>
            )
        })

        return (
            <React.Fragment>
                <Modal
                    show={show}
                    widthClass={widthClass ?? "max-w-5xl"}
                    title={title}
                    initialFocusRef={initialFocusRef}
                    closeButtonLabel={closeButtonLabel ?? translate("btn.cancel")}
                    onClose={onClose}
                    limitHeight={limitHeight}
                >
                    <ModalHeader
                        title={title}
                        onClose={onClose}
                    />

                    {htmlBefore}

                    <div className="flex max-h-[calc(100vh-12rem)] border-t border-tm-gray-200">
                        <div className={
                            classNames(
                                "space-y-2 items-center flex-shrink-0  bg-tm-gray-100 border-r border-r-tm-gray-300 self-stretch",
                                this.state.isFilterSidebarVisible ? "p-2 w-52" : "w-0 opacity-0"
                            )}
                        >
                            {this.state.isFilterSidebarVisible && filters}
                        </div>

                        <div className={classNames(
                            "w-full relative overflow-auto",
                            this.state.isFilterSidebarVisible ? "max-w-[calc(100%-13rem)]" : ""
                        )}>
                            {!!messages.length && messagesRender}

                            <header className="px-4 py-2 gap-x-2 flex">
                                <div className="flex flex-shrink-0 gap-x-2">
                                    <button
                                        className="btn btn-outline w-10 h-10 p-0 rounded-lg flex justify-center items-center"
                                        onClick={this.handleFiltersClick}
                                    >
                                        <FunnelIcon className="w-5 h-5"/>
                                    </button>

                                    {!this.state.isFilterSidebarVisible && (
                                        <FieldContainer
                                            key={this.state.fieldsFilter['query']?.name}
                                            item={this.state.fieldsFilter['query']}
                                            translate={translate}
                                        >
                                            <FieldSearch
                                                innerRef={initialFocusRef}
                                                className={"form-control p-0"}
                                                addClass={"form-control rounded-lg peer placeholder-transparent"}
                                                onChange={this.handleQueryChange}
                                                translate={translate}
                                                placeholder={""}
                                                {...this.state.fieldsFilter['query']}
                                                {...this.state.fieldsFilter['query']?.props}
                                                type="text"
                                            />
                                        </FieldContainer>
                                    )}
                                </div>

                                <div className="flex grow items-center">
                                    <div className="ml-auto">
                                        <Buttons buttons={buttons}/>
                                    </div>
                                </div>
                            </header>

                            <ActiveFilters
                                addClass="flex-nowrap h-11 px-6"
                                filterFields={this.state.fieldsFilter}
                                onLabelClick={this.handleQueryChange}
                                onClearFiltersClick={() => {
                                    this.handleQueryChange("clearAll");
                                }}
                                excludedFields={['limit', 'sort', 'sortBy', 'offset']}
                                translate={translate}
                            />

                            <div>
                                <ResourceTable
                                    data={data}
                                    count={count}
                                    maxHeightClass={maxHeightClass}
                                    fields={fields}
                                    translate={translate}
                                    isLoading={isLoading}

                                    limit={this.state.limit}
                                    offset={this.state.offset}
                                    page={this.state.page}
                                    paginationButtonLimit={this.state.paginationButtonLimit}
                                    onOffsetChange={this.handleQueryChange}

                                    options={options}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={(fieldName) => this.handleQueryChange("sortBy", fieldName)}

                                    tableKey={tableKey}
                                    selectedRows={selectedRows}
                                    onSelectRow={onSelectRow}
                                    onSelectAllClick={onSelectAllRows}

                                    onRowClick={defaultAction}

                                    onView={onView}
                                    onCreate={onCreate}
                                    onDelete={onDelete}
                                    actions={onCustomActions}
                                />
                            </div>

                            <NoRecords
                                addClass="py-12"
                                show={data.length === 0 && !isLoading}
                                title={'No matching records found'}
                            />

                            {htmlAfter}

                        </div>
                    </div>

                    <footer
                        className="min-h-[4rem] flex flex-wrap flex-row-reverse items-center border-tm-gray-200 border-t px-6 py-2 gap-3 bg-inverse rounded-b-lg"
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={this.handleQueryChange}
                            queryFields={this.state.fieldsFilter}
                            translate={translate}
                        />
                    </footer>

                    {hasDialogFooter && (
                        <ModalFooter
                            buttonLabel={footerButtonLabel}
                            buttonDisabled={footerButtonDisabled}
                            onButtonClick={onFooterButtonClick}

                            closeButtonLabel={footerCloseButtonLabel}
                            onClose={onFooterCloseButtonClick}
                        >
                        </ModalFooter>
                    )}

                </Modal>
            </React.Fragment>
        );
    }
}
