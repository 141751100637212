import DatePicker from "react-datepicker";
import React, {useEffect, useState} from "react";
import {classNames} from "../../../util/util-helpers";
import moment from "moment-timezone";
import {getUserDateTimeFormat, getUserTimezone, UTCToLocal} from "../../../util/util-dates";
import {DEFAULT_DATABASE_DATE_FORMAT, DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../util/util-consts";
import {FieldClearIcon} from "../../../../data/themes/icons";
import {scrollIntoView} from "../../../util/util-vanilla";

export default function FieldDateNew({
                                         name,
                                         value,
                                         onChange, // required props
                                         withTime = "00:00:00",
                                         disabled = false,
                                         isClearable = true, // if not required
                                         className = "form-control px-0 text-center relative",
                                         showTimeSelect = false,
                                         addClass = undefined,
                                         onFocus = undefined,
                                         isUTC = false,
                                         preventOpenOnFocus = true,
                                         dateFormat = getUserDateTimeFormat().split(" ")[0].toLowerCase().replace('mm', 'MM'),
                                         placement = "bottom-start",
                                         placeholderText = getUserDateTimeFormat().split(" ")[0].replace(/[a-zA-Z]/g, '_')
                                     }) {
    const [innerValue, setInnerValue] = useState("");
    function localToUTC(localDateTime) {
        const dateTime = moment.tz(localDateTime, getUserTimezone());
        return dateTime.isValid() ? dateTime : "";
    }

    function handleOnChange(date) {
        let outputDate = moment(date, DEFAULT_DATABASE_DATETIME_FORMAT);

        if (!date || !outputDate.isValid()) {
            onChange(name, '');
            return null;
        }

        let outputValue;

        if (isUTC && withTime) {
            outputValue = localToUTC(outputDate) ? localToUTC(outputDate).format(DEFAULT_DATABASE_DATE_FORMAT) + " " + withTime : '';
        } else if (isUTC && !withTime) {
            outputValue = localToUTC(outputDate) ? localToUTC(outputDate).format(DEFAULT_DATABASE_DATETIME_FORMAT) : '';
        } else {
            outputValue = outputDate ? outputDate.format(DEFAULT_DATABASE_DATE_FORMAT) + " 00:00:00" : "";
        }

        if (!date) {
            outputValue = '';
        }


        onChange(name, outputValue);
    }

    function handleOnFocus(e) {
        if (onFocus) {
            onFocus(e);
        }

        scrollIntoView(e)
    }

    useEffect(() => {
        if (isUTC) {
            let localValue = UTCToLocal(value);
            setInnerValue(localValue ? new Date(localValue) : '');
        } else {
            setInnerValue(value ? new Date(value) : '');
        }
    }, [value]);

    return <div className='relative w-full'>
        <DatePicker
            selected={innerValue}
            dateFormat={dateFormat}
            onChange={handleOnChange}
            preventOpenOnFocus={preventOpenOnFocus}
            popperPlacement={placement}
            placeholderText={placeholderText}
            onFocus={handleOnFocus}
            showTimeSelect={showTimeSelect}
            className={
                classNames(
                    addClass,
                    className
                )
            }
        />

        {isClearable && innerValue && !disabled && (
            <button
                tabIndex={null}
                onClick={() => handleOnChange("")}
                className="absolute z-10 inset-y-0 right-0 px-2 flex items-center cursor-pointer hover:text-tm-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-tint">

                <FieldClearIcon/>
            </button>
        )}
    </div>
}