import React, { Component } from 'react'
import LocalStorage from "../../../util/localStorage";
import InfoParagraph from "../../info-paragraph";
import NoRecordsTable from "../../no-records-found/no-records-table";
import Card from "../../card";
import ResourceTable from "../../resource-table";
import PageHeader from "../../layout/layout-components/page/page-header";
import {CREATE_PERM} from "../../../../util/util-constants";
import {checkPerm, getDefaultTableOptions} from "../../../util/util-helpers";
import TableCard from "../../resource-table/table-components/table-card";
import ModalSaveResource from "../../modal/modal-save-resource";
import ModalConfirm from "../../modal/modal-confirm";
import {getProp, mergeDeep} from "../../../util/util-helpers";
import {cloneDeep} from "../../../util/util-vanilla";
import {fillFieldsFromData} from "../../../util/util-fields";

export default class ResourceCreateListTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            tableOptions: this.props.tableOptions ?? getDefaultTableOptions(
                this.getFields(),
                {},
                this.props.tabKey,
                this.props.translate
            ),

            selectedItem: null,
            editModalOpen: false,
            confirmModalOpen: false
        };

        if (!!this.props.tabKey) {
            this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.props.tabKey));
        }
    }

    /** UI Events
     ================================================================= */
    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            errorMessage: ''
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            errorMessage: ''
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getFields = (item = null) => {
        const fieldTemplates = cloneDeep(this.props.fields);

        return this.props.fillFieldTemplate ? this.props.fillFieldTemplate(fieldTemplates, item) : fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
            disableEdit,
            disableDelete,
            disableCreate,
            disableRestore,
            onCreateButtonClick,
            onSecondaryButtonClick,
            secondaryButtonDisabled,
            secondaryButtonLabel,
            isSelectRowDisabled,
            onRowClick
        } = this.props;

        const data = getProp(this.props, "data", []);

        return (
            <Card>
                <PageHeader
                    title={translate("page.heading." + this.props.resourceName)}
                    titleClass="mr-5 text-2xl leading-10"
                    buttonLabel={translate("btn.create_new")}
                    onButtonClick={() => !!onCreateButtonClick ? onCreateButtonClick() : this.handleToggleCreateModal()}
                    buttonHidden={!!disableCreate || !checkPerm(this.getResourceName(), CREATE_PERM)}

                    onSecondaryButtonClick={onSecondaryButtonClick}
                    secondaryButtonDisabled={secondaryButtonDisabled}
                    secondaryButtonLabel={secondaryButtonLabel}
                />

                {this.props.infoParagraph && (
                    <div className="">
                        <InfoParagraph className="" message={this.props.infoParagraph}/>
                    </div>
                )}

                <TableCard>
                    <ResourceTable
                        data={Array.isArray(data) ? data : []}
                        commonTable={true}
                        fields={this.getFields()}

                        tableKey={this.props.tableKey}
                        onSelectRow={this.props.onSelectRow}
                        onSelectAllRows={this.props.onSelectAllRows}
                        isSelectRowDisabled={isSelectRowDisabled}
                        selectedRows={this.props.selectedRows}
                        onRowClick={onRowClick}

                        translate={translate}

                        options={this.state.tableOptions}

                        onEdit={!disableEdit ? this.handleToggleEditModel : null}
                        onDelete={!disableDelete ? this.handleToggleConfirmModal : null}
                        onRestore={(!disableDelete && !disableRestore) ? this.handleToggleConfirmModal : null}

                        actions={this.props.actions}
                    />

                    {/*Table footer*/}
                    <NoRecordsTable
                        show={(data.length === 0)}
                        canCreate={false}
                        title={'No matching records found'}

                        className={"pb-12 pt-16 px-6"}
                        filters={{}}
                    />
                </TableCard>

                <ModalSaveResource
                    title={"Edit " + (!!this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.onEditItem({...params, index: getProp(this.state.selectedItem, 'index', 0)})
                            this.handleToggleEditModel()
                        }
                    }}
                    translate={this.props.translate}
                    getRawFields={this.props.getRawFields}
                    metadata={this.props.metadata}
                    handleInputChange={this.props.handleInputChange ?? null}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={"Create " + (!!this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.onAddItem(params)
                            this.handleToggleCreateModal()
                        }
                    }}
                    getRawFields={this.props.getRawFields}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                    handleInputChange={this.props.handleInputChange ?? null}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalConfirm
                    title={"Confirm"}
                    show={!!this.state.confirmModalOpen}
                    text={"Are you sure you want to delete " + this.props.dialogTitle + "?"}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.onRemoveItem(this.state.selectedItem)
                        this.handleToggleConfirmModal()
                    }}
                />
            </Card>
        )
    }
}
