import React, {useEffect, useRef, useState} from "react";

export default function LoaderFake({
                                       estimatedTime = 20000,
                                       isFinished,
                                       onFinished,
                                       randomIntervalMax = 2000,
                                       randomIntervalMin = 1000,
                                       slowRandomIntervalMax = 4000,
                                       slowerandomIntervalMin = 1000,
                                       isProgressBarHidden = false
                                   }) {
    const [currentProgress, setCurrentProgress] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const timeoutRef = useRef();

    function fakeProgress() {
        const randomInterval = Math.floor(Math.random() * randomIntervalMax) + randomIntervalMin;

        timeoutRef.current = setTimeout(() => {
            const elapsedTimeSoFar = elapsedTime + randomInterval;
            let progress = (elapsedTimeSoFar / estimatedTime) * 100;

            if (progress < 90) {
                setCurrentProgress(Math.round(progress));
            } else {
                setCurrentProgress(90);
            }
            setElapsedTime(elapsedTimeSoFar)

        }, randomInterval);
    }

    function fakeProgressMoreThanEstimated() {
        const randomInterval = Math.floor(Math.random() * slowRandomIntervalMax) + slowerandomIntervalMin;

        timeoutRef.current = setTimeout(() => {
            const elapsedTimeSoFar = elapsedTime + randomInterval;
            let progress = currentProgress + 1;

            if (progress < 100) {
                setCurrentProgress(Math.round(progress));
            } else {
                setCurrentProgress(100);
            }
            setElapsedTime(elapsedTimeSoFar)

        }, randomInterval);
    }


    useEffect(() => {
        if (elapsedTime < estimatedTime && currentProgress < 99) {
            fakeProgress();
        } else if (currentProgress < 99) {
            fakeProgressMoreThanEstimated();
        }
    }, [elapsedTime]);

    useEffect(() => {
        if (isFinished) {
            setCurrentProgress(100);
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(onFinished, 300);
        }

        return () => clearTimeout(timeoutRef.current)
    }, [isFinished])

    return (
        !isProgressBarHidden ? <div className="w-full bg-tm-gray-200 rounded-full">
            <div
                className="transition-width bg-primary text-xs font-medium text-primary-contrast p-0.5 leading-none rounded-full h-5 flex items-center justify-center"
                style={{width: currentProgress + "%"}}> {currentProgress > 5 && (currentProgress + "%")}
            </div>
        </div> : null

    )
}