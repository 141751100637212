import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../pagination";
import NoRecords from "../../../no-records-found/no-records";
import ResourceTable from "../../index";
import Buttons from "../../../buttons";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import ResourceTableTopBar from "../../../../../common/components/resource-table/table-components/resource-table-top-bar";
import {getProp} from "../../../../util/util-helpers";
import ResourceTableSearch from "../resource-table-search";
import {getUser} from "../../../../util/util-auth";
import {getDialogResource} from "../../../../../data/actions/dialogResource";
import {cloneDeep} from "../../../../util/util-vanilla";
import {fillFieldsFromData} from "../../../../util/util-fields";
import LocalStorage from "../../../../util/localStorage";

export default function ResourceTableSection({
                                                 resourcePath,
                                                 actions,
                                                 onCreateResource,
                                                 selectedRows,
                                                 onSelectAllClick,
                                                 onSelectRowClick,
                                                 tableKey,
                                                 storeKey,
                                                 tableOptions,
                                                 fields,
                                                 addQueryFields,
                                                 filterFieldsNames = [],
                                                 selects,
                                                 primaryField,
                                                 onEdit,
                                                 onDelete,
                                                 getNoRecordsTitle = () => null,
                                                 getNoRecordsText = () => null,
                                                 getNoRecordsBtnLabel = () => null,
                                                 isNoRecordsButtonIconHidden,
                                                 headerButtons,
                                                 translate
                                             }) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogResource);
    const isLoading = getProp(resource, "isLoading", true);

    const count = getProp(resource.data, "count", 0);
    const data = getProp(resource.data, "list", {});

    const [queryFields, setQueryFields] = useState(getQueryFields(primaryField, addQueryFields, storeKey, translate));
    let query = FieldsManager.getFieldKeyValues(queryFields);
    const hasQuery = filterFieldsNames.find(name => !!queryFields[name]?.value)

    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, filterFieldsNames, name, value);

        setQueryFields(queryUpdate);

        if (storeKey) {
            LocalStorage.set(storeKey, FieldsManager.getFieldKeyValues(
                Object.values(queryUpdate).reduce((memo, it) => {
                if (!it?.metadata?.doNotStore) {
                    memo[it.name] = it;
                }
                return memo;
            }, {})));
        }

        if ("query" === name) {
            queryUpdate.offset.value = 0
        }

        fetchData(dispatch, resourcePath, FieldsManager.getFieldKeyValues(queryUpdate));
    }

    useEffect(() => {
        fetchData(dispatch, resourcePath, query);
    }, []);

    return (
        <React.Fragment>
            <ResourceTableTopBar>
                <ResourceTableSearch
                    queryFields={queryFields}
                    includedFields={filterFieldsNames}
                    selects={selects}
                    handleQueryChange={handleQueryChange}
                    translate={translate}
                />

                <Buttons
                    buttons={headerButtons}
                />
            </ResourceTableTopBar>

            <div className="h-[calc(100%-7.5rem)] overflow-y-auto">
                <ResourceTable
                    data={data}
                    fields={fields}

                    translate={translate}
                    isLoading={isLoading}
                    tableKey={tableKey}
                    selectedRows={selectedRows}
                    onSelectRow={onSelectRowClick}
                    onSelectAllClick={(areNotAllSelected) => onSelectAllClick(areNotAllSelected, data)}
                    options={tableOptions}

                    limit={query.limit}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={(fieldName) => handleQueryChange("sortBy", fieldName)}

                    onEdit={onEdit}
                    onDelete={onDelete}

                    actions={actions}
                />

                <NoRecords
                    show={!isLoading && !count}
                    addClass="p-10"
                    queryFields={queryFields}
                    title={getNoRecordsTitle(hasQuery)}
                    text={getNoRecordsText(hasQuery)}
                    btnLabel={getNoRecordsBtnLabel(hasQuery)}
                    isButtonIconHidden={isNoRecordsButtonIconHidden}
                    onBtnClick={hasQuery ? () => handleQueryChange("ALL_FIELDS") : onCreateResource}
                />
            </div>

            <div className="h-14 bg-inverse flex items-center px-6 border-t border-tm-gray-300">
                <Pagination
                    count={count}
                    isLoading={isLoading}
                    handleQueryChange={handleQueryChange}
                    queryFields={queryFields}
                    translate={translate}
                />
            </div>
        </React.Fragment>
    )
}

const fetchData = (dispatch, resourcePath, query) => {
    dispatch(getDialogResource({
        user: getUser(),
        resource: resourcePath,
        query: query
    }))
}

const getQueryFields = (primaryField, addQueryFields, storeKey, translate) => {
    const queryFields = (
        Object.assign(
            getDefaultQueryFields(primaryField, translate),
            {
                archived: new Field('archived', '', [''], false, 'checkbox', {
                    labelType: "float",
                    label: "archived",
                    addHoverClass: "hover:bg-primary-900"
                }),
            },
            addQueryFields
        )
    )

    if (storeKey) {
        return fillFieldsFromData(queryFields, LocalStorage.get(storeKey))
    }

    return queryFields;
}

export function updateQuery(queryFields, filterFieldsNames, name, value) {
    let queryClone = cloneDeep(queryFields);

    if ("sortBy" === name) {
        if (value === queryClone.sortBy.value) {
            queryClone.sort.value = queryClone.sort.value === 'ASC' ? 'DESC' : 'ASC'
        }
    }

    if ("ALL_FIELDS" === name) {
        Object.values(queryClone).forEach(field => {
            if (filterFieldsNames.includes(field.name)) {
                FieldsManager.updateField(queryClone, field.name, "")
            }
        })
    } else {
        queryClone = FieldsManager.updateField(queryClone, name, value);
    }

    return queryClone;
}

export function getDefaultQueryFields(primaryField, translate) {
    return {
        query: new Field('query', '', [''], false, 'search', {
            hideLabel: true,
            hasActiveBadge: true,
            labelType: "float",
            addContainerClass: "col-span-4"
        }, {placeholder: translate("text.search")}),
        sort: new Field('sort', 'ASC', [''], false, 'hidden'),
        sortBy: new Field('sortBy', primaryField, [''], false, 'hidden'),
        offset: new Field('offset', "0", [''], false, 'hidden'),
        limit: new Field('limit', "20", [''], false, 'select', {
            hideLabel: true,
            labelType: "float"
        }, {
            placeholder: "100",
            menuPlacement: "top"
        })
    }
}