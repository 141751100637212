import React, {useEffect, useState} from 'react';
import {
    LOAD_STATUS_DELIVERED,
    LOAD_STATUS_DISPATCHED,
    LOAD_STATUS_IN_TRANSIT
} from "../../../../../util/util-constants";
import {getProp} from "../../../../../common/util/util-helpers";
import {useSelector} from "react-redux";
import ModalConfirm from "../../../../../common/components/modal/modal-confirm";
import FieldCheckbox from "../../../../../common/components/fields/field-checkbox";

export default function ConfirmLoadStatusUpdateModal({
                                                         statusUpdateID,
                                                         isBroker,
                                                         areAllStopsCompleted,
                                                         onClose,
                                                         onConfirm,
                                                         isEmptyMilesWarning,
                                                         translate
                                                     }) {
    const canNotDeliver = statusUpdateID === LOAD_STATUS_DELIVERED && !areAllStopsCompleted;
    const [canSubmit, setCanSubmit] = useState(true);

    function getConfirmMessageBody() {
        switch (statusUpdateID) {
            case LOAD_STATUS_DISPATCHED:
                return <div>
                    <p className="mb-2">{translate('message.confirm_load_status_change')}</p>

                    <p className="mb-2">
                        {
                            !isBroker
                                ? translate('message.confirm_load_status_change_line_2a')
                                : translate('message.confirm_load_status_change_line_2b')
                        }
                    </p>
                    <p>{translate('message.confirm_status_save')}</p>
                </div>
            case LOAD_STATUS_IN_TRANSIT:
                return <div>
                    <p className="mb-2">{translate('message.confirm_transit_status_change')}</p>
                    <p>{translate('message.confirm_status_save')}</p>
                </div>
            case LOAD_STATUS_DELIVERED:
                if (areAllStopsCompleted) {
                    return <div>
                        <p className="mb-2">{translate('message.confirm_delivered_status_change')}</p>
                        <p className="mb-2">{
                            !isBroker
                                ? translate('message.confirm_delivered_status_change_line_2a')
                                : translate('message.confirm_delivered_status_change_line_2b')
                        }
                        </p>
                        <p>{translate('message.confirm_status_save')}</p>
                    </div>
                } else {
                    return <div>
                        <p>{translate('message.confirm_delivered_status_not_completed')}</p>
                    </div>
                }
            default:
                return "";
        }
    }

    useEffect(() => {
        setCanSubmit(!isEmptyMilesWarning);
    }, [statusUpdateID]);

    return <ModalConfirm
        type={isEmptyMilesWarning ? "warning" : "info"}
        onClose={onClose}
        width={"3xl"}

        onConfirm={() => {
            onConfirm(statusUpdateID, false);
            onClose();
        }}
        buttonLabel={canNotDeliver ? undefined : translate('btn.change_load_status')}
        buttonDisabled={!canSubmit}
        secondaryButtonLabel={canNotDeliver ? undefined : translate('btn.change_load_status_and_save')}
        secondaryButtonDisabled={!canSubmit}
        onSecondaryButtonClick={() => {
            onConfirm(statusUpdateID, true);
            onClose();
        }}
        closeButtonLabel={canNotDeliver ? translate('btn.ok') : translate('btn.cancel')}

        show={statusUpdateID !== null}
        title={translate('modal_heading.confirm_load_status_update')}
        translate={translate}
    >
        <div className="text-sm text-tm-gray-700 font-semibold">
            {getConfirmMessageBody()}

            {!!isEmptyMilesWarning && (
                <EmptyMilesWarning setCanSubmit={setCanSubmit}/>
            )}
        </div>
    </ModalConfirm>
}


function EmptyMilesWarning({setCanSubmit}) {
    const [check, setCheck] = useState("0");
    const resource = useSelector((state) => state.resource);
    const EmptyMilesWarningPercentage = getProp(resource.data, 'settings.EmptyMilesWarningPercentage', 0);

    return <div className="mt-12 flex flex-col gap-2 py-2 px-4 rounded-md bg-yellow-600/10 text-tm-gray-900">
        <p className="font-bold text-base">Efficiency Alert: Review Mileage Ratio</p>
        You must understand the consequence before continuing:
        <label
            className="h-9 flex flex-center justify-start px-2 -ml-2 w-full cursor-pointer hover:bg-yellow-600/10 rounded-card"
        >
            <FieldCheckbox
                className="checkbox"
                onChange={() => {
                    const value = check === "1" ? "0" : "1";
                    setCheck(value);
                    setCanSubmit(value === "1")
                }
                }
                value={check}
            />
            <div
                className="ml-2 flex flex-col text-sm font-semibold select-none text-tm-gray-900"
            >
                I am aware that proportion of empty miles compared to loaded miles exceeds {EmptyMilesWarningPercentage}%
            </div>
        </label>

        <div className="mt-1 pt-4 border-t border-yellow-600">
            <p className="font-bold text-tm-gray-900">Why is this message appearing?</p>
            <p className="text-tm-gray-700">This message is triggered because the proportion of empty miles
                exceeds the threshold set in the &apos;Max percentage of empty miles per load warning&apos; within the
                Load settings section per your company policy.</p>
        </div>
    </div>
}