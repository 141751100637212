import React from "react";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import {STOP_TYPE_DELIVERY, STOP_TYPE_PICKUP} from "../../../../../util/util-constants";

export default function StopPalletWarning({stops, commodityData, translate}) {
    return stops.map((stop, index) => {
        if (stop?.StopType?.value === STOP_TYPE_PICKUP) {
            const commodity = commodityData.find(it => it?.LoadCommodityPickupID === stop?.LoadStopID?.value);
            const CountPallets = Number(commodity?.CountPallets ?? -1);
            const PalletsLoaded = Number(stop.PalletsLoaded?.value ?? 0);
            const UnitType = commodity?.UnitType ? commodity.UnitType : translate("text.unit_s");

            return (!!CountPallets && !!PalletsLoaded && CountPallets !== PalletsLoaded)
                ? <InfoBar key={stop.LoadStopID} type="warning"
                           message={`The loaded ${UnitType} at the stop #${index + 1}. do not match the planned quota.`}/>
                : undefined
        }

        if (stop?.StopType?.value === STOP_TYPE_DELIVERY) {
            const commodity = commodityData.find(it => it?.LoadCommodityDestinationID === stop?.LoadStopID?.value);
            const CountPallets = Number(commodity?.CountPallets ?? -1);
            const PalletsUnLoaded = Number(stop.PalletsUnLoaded?.value ?? 0);
            const UnitType = commodity?.UnitType ? commodity.UnitType : translate("text.unit_s");

            return (!!CountPallets && !!PalletsUnLoaded && CountPallets !== PalletsUnLoaded)
                ? <InfoBar key={stop.LoadStopID} type="warning"
                           message={`The unloaded ${UnitType} at the stop #${index + 1}. do not match the planned quota.`}/>
                : undefined
        }

        return null;
    })
}