import React, {Component} from "react";
import {scrollIntoView} from "../../../util/util-vanilla";

export default class FieldRadio extends Component {

    render() {

        return (
            <React.Fragment>
                <input
                    id={this.props.id}
                    className={this.props.className ?? "text-primary"}
                    type={"radio"}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, event.target.value);
                    }}
                    checked={!!this.props.value}
                    name={this.props.name}
                    value={!!this.props.value}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    onClick={this.props.onClick}
                />
            </React.Fragment>
        )
    }
}
