export function getSidebarResource(data) {
    return {
        type: 'GET_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneGetSidebarResource(data) {
    return {
        type: 'DONE_GET_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorGetSidebarResource(data) {
    return {
        type: 'ERROR_GET_SIDEBAR_RESOURCE',
        data: data
    };
}

export function getInfiniteSidebarResource(data) {
    return {
        type: 'GET_INFINITE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneGetInfiniteSidebarResource(data) {
    return {
        type: 'DONE_GET_INFINITE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorGetInfiniteSidebarResource(data) {
    return {
        type: 'ERROR_GET_INFINITE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function createSidebarResource(data) {
    return {
        type: 'CREATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneCreateSidebarResource(data) {
    return {
        type: 'DONE_CREATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorCreateSidebarResource(data) {
    return {
        type: 'ERROR_CREATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function updateSidebarResource(data) {
    return {
        type: 'UPDATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneUpdateSidebarResource(data) {
    return {
        type: 'DONE_UPDATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorUpdateSidebarResource(data) {
    return {
        type: 'ERROR_UPDATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function resetSidebarResource() {
    return {
        type: 'RESET_SIDEBAR_RESOURCE',
        data: []
    }
}
