import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {checkPerm} from "../../../common/util/util-helpers";
import {READ_PERM} from "../../../common/util/util-consts";
import {useState} from "react";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import ExpiryItemsTab from "./expiry-items-tab";


const ExpiryItemsView = ({translate, match, history, location, resourceName}) => {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);

    const resource = useSelector((state) => state.resource);

    /** Fields Data Definitions
     ================================================================= */
    const getTabs = () => {
        return [
            {
                name: 'Expiring',
                resource: resourceName,
                current: true,
                visible: checkPerm(resourceName, READ_PERM)
            },
            /*
            {
                name: 'Settings',
                resource: '',
                current: false,
                visible: checkPerm(Resources.JournalPending, READ_PERM)
            }
             */
        ]
    }

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs());
    const [selectedTab, setSelectedTab] = useState(tabs[0].resource)
    const [breakpoint, setBreakpoint] = useState({})

    /** Helpers
     ================================================================= */

    /** UI events
     ================================================================= */
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        );
        setSelectedTab(resource)
    }

    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }
    /** Render
     ================================================================= */
    return (
        <Layout
            onBreakpoinChange={handleBreakpointChange}
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            isAccessible={!(resource.errorStatus === 2)}
            dispatch={dispatch}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.ExpiryItems')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {selectedTab === resourceName && (
                    <ExpiryItemsTab
                        breakpoint={breakpoint}
                        resourceName={resourceName}
                        dispatch={dispatch}
                        match={match}
                        resource={resource}
                        translate={translate}
                    />
                )}

            </Page>
        </Layout>
    )
}

export default ExpiryItemsView
