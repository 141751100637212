import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../data/services/resources'
import {Field} from '../../data/services/fields'
import {checkPerm} from "../../common/util/util-helpers";
import {CREATE_PERM, DELETE_PERM} from "../../util/util-constants";
import DocumentsUpdateTab from "../../common/components/tabs/documents-tab/update-documents";
import Page from "../../common/components/layout/layout-components/page";
import Layout from "../../common/components/layout";
import Card from "../../common/components/card";

class DocumentsView extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Layout {...this.props}>
                <Page>
                    <div className="container-fluid disable-text-selection">
                        <div className="row">
                            <div className="col">
                                <h1 className="mr-5 text-3xl mb-3">{this.props.translate('page_heading.general_documents')}</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <Card addClass="relative pb-7">
                                    <DocumentsUpdateTab
                                        dispatch={this.props.dispatch}
                                        translate={this.props.translate}
                                        resource={this.props.resource}
                                        thirdResource={this.props.thirdResource}

                                        dialogTitle={'Documents'}

                                        sortBy={'Description'}
                                        primaryKey={'DocumentID'}

                                        download={this.props.download}
                                        resourceName={Resources.Documents}

                                        searchFields={{
                                            query: new Field('query', '', [], false, 'text', {
                                                containerClass: 'col-md-4'
                                            }),
                                            DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                                containerClass: 'col-md-4'
                                            }, {
                                                all: true
                                            }),
                                            DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                                containerClass: 'col-md-4'
                                            }, {
                                                all: true
                                            }),
                                        }}
                                        readOnly={!checkPerm(Resources.Documents, CREATE_PERM)}
                                        disableDelete={!checkPerm(Resources.Documents, DELETE_PERM)}
                                        disableSendViaEmail={!checkPerm(Resources.DocumentsSend, CREATE_PERM)}
                                    />
                                </Card>
                            </div>
                        </div>
                    </div>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(DocumentsView)


