import {Field} from "../../../../../data/services/fields";
import CardSubTitle from "../../../card/card-sub-title";
import {fieldsToHtml, includeFields} from "../../../../util/util-fields";

export const DEFAULT_COMPANY_ROLE_FIELDS = () => {
    return {
        IsTechnician: new Field('IsTechnician', '', [], false, 'checkbox'),
        IsDispatcher: new Field('IsDispatcher', '', [], false, 'checkbox')
    }
}

const CompanyRoleFields = ({handleInputChange, fields, translate}) => {
    const fieldsHtml = fieldsToHtml((Object.values(Object.assign({}, includeFields(fields, Object.keys(DEFAULT_COMPANY_ROLE_FIELDS()))))), translate, handleInputChange, {

    })

    return (
        <>
            <CardSubTitle
                text={translate('text.company_role')}
                addClass={''}
            />

            {fieldsHtml}
        </>
    )
}

export default CompanyRoleFields
