import React, {useState} from "react";
import FieldsForm from "../../../../common/components/fields/fields-form";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {formatMoney} from "../../../../common/util/util-formaters";
import {numberWithCommasToBack} from "../../../../util/util-formaters";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import {classNames} from "../../../../common/util/util-helpers";

export default function StripePayConfirmationModal({
                                                       selectedItem,
                                                       onConfirm,
                                                       confirmButtonLabel,
                                                       onClose,
                                                       title,
                                                       translate
                                                   }) {
    const [fields, setFields] = useState(getFields());
    const currentBalance = formatMoney(parseFloat((selectedItem?.Amount - selectedItem?.AmountTransferred).toFixed(2)))
    const remainingBalance = numberWithCommasToBack(currentBalance) - numberWithCommasToBack(fields.Amount.value);
    const isRemainingBalanceNegative = !!remainingBalance && remainingBalance < 0;

    function getFields() {
        return {
            Amount: new Field('Amount', parseFloat((selectedItem?.Amount - selectedItem?.AmountTransferred).toFixed(2)), ['min:0.01'], false, 'money', {
                labelType: "float",
                hideCurrencyCode: true,
                hideLabel: true,
                className: "form-control pl-7 text-right w-40 font-bold text-base"
            })
        }
    }

    function handleInputChange(name, value) {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);
        fieldsUpdate[name].errorMessage = '';
        setFields(fieldsUpdate);
    }

    return (
        <div className="pb-16">
            <div
                className="text-base pb-4 mb-4 border-b border-tm-gray-300">
                {title}
            </div>

            <div className="text-right">
                <div className="text-base relative pr-44 mb-1">Current Balance: <span
                    className="font-bold absolute right-3">${currentBalance}</span></div>
                <div className="text-base flex items-center justify-end mb-1">
                    <span className="mr-4">Pay Amount:</span>
                    {/*<input className="ml-8 form-control w-32 text-right"></input>*/}
                    <FieldsForm
                        fieldsState={fields}
                        onChange={handleInputChange}
                        translate={translate}
                    />
                </div>

                <div className=" relative text-base pr-44 border-t border-tm-gray-300 pt-4 mt-4">
                    Remaining Balance: <span
                    className={
                        classNames(isRemainingBalanceNegative ? "text-red-600" : undefined,
                            "font-bold absolute right-3"
                        )
                    }
                >
                    ${remainingBalance.toFixed(2)}
                </span>
                </div>
            </div>

            <ModalFooter
                className="absolute bottom-0 right-0 left-0 min-h-[4rem] flex flex-wrap flex-row-reverse items-center border-tm-gray-200 border-t px-6 py-2 gap-3 bg-popup rounded-b-lg"
                buttonLabel={confirmButtonLabel}// use nullable value to hide the button
                onButtonClick={() => {
                    const validFields = FieldsManager.validateFields(fields);
                    if (FieldsManager.checkFieldsForErrors(validFields)) {
                        onConfirm(numberWithCommasToBack(fields.Amount.value))
                    } else {
                        setFields(validFields);
                    }
                }}
                buttonDisabled={isRemainingBalanceNegative}
                closeButtonLabel={'Cancel'}
                onClose={onClose}
            />
        </div>
    )
}