import React, {useEffect, useState} from "react";
import {getResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {checkPerm, getProp, resourceIsCreated} from "../../../../common/util/util-helpers";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../../common/util/util-fields";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import Card from "../../../../common/components/card";
import {LoaderSmall} from "../../../../common/components/loader";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";
import TabTitle from "../../../../common/components/layout/layout-components/tab-title";
import {MAJOR_ACCOUNT_TYPE_INCOME} from "../../../../common/util/util-consts";
import {UPDATE_PERM} from "../../../../util/util-constants";

const WorkOrderTab = ({translate}) => {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);

    /** Constants
     ================================================================= */
    const data = getProp(resource, 'data', {})
    const isLoading = getProp(resource, 'isLoading', false)

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            IncomeLaborAccountID: new Field('IncomeLaborAccountID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
            IncomePartsAccountID: new Field('IncomePartsAccountID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
            IncomeSurchargeAccountID: new Field('IncomeSurchargeAccountID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
            IncomeSuppliesAccountID: new Field('IncomeSuppliesAccountID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
        }

        return fillFieldsFromData(fieldTemplates, item);


    };

    /** State
     ================================================================= */
    const [fields, setFields] = useState({})
    const [canSubmit, setCanSubmit] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.AccountingSettings
    }

    const incomeAccountSelect = {
        api: 'api/' + Resources.AccountsQuick,
        query: {
            MainAccountTypeID: MAJOR_ACCOUNT_TYPE_INCOME,
        },
        searchMap: (item) => ({
            label: item.AccountNumber + ' ' + item.AccountName,
            value: item.AccountID
        })
    }

    const selects = {
            IncomeLaborAccountID: incomeAccountSelect,
            IncomePartsAccountID: incomeAccountSelect,
            IncomeSurchargeAccountID: incomeAccountSelect,
            IncomeSuppliesAccountID: incomeAccountSelect,
    }
    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: {},
            resource: getResourceName()
        }))
    }

    const onSubmit = () => {
        let validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            let params = FieldsManager.getFieldKeyValues(validatedFields);

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: Object.assign({
                    AccountingSettingsID: getProp(resource, 'data.AccountingSettingsID', 0)
                }, params),
                resource: Resources.AccountingSettings,
                piggyResource: Resources.AccountingSettings,
                errorMessage: true, successMessage: 'Settings saved successfully!'
            }))
        } else {
            setFields(validatedFields)
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
        setCanSubmit(true)
    }

    const handleFormCancel = () => {
        setFields(getFields(data))
        setCanSubmit(false)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        !isLoading && setFields(getFields(data))
    }, [isLoading])

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setCanSubmit(false);
        }
    })

    /** Render
     ================================================================= */
    const fieldsHtml = fieldsToHtml(Object.values(includeFields(fields, ['IncomeLaborAccountID', 'IncomePartsAccountID', 'IncomeSurchargeAccountID', 'IncomeSuppliesAccountID'])), translate, handleInputChange, selects);

    return (
        <div className="grid space-y-8 w-full mx-auto py-8 max-w-4xl">
            <Card bodyClass={"py-8 px-6 space-y-4"}>
                <TabTitle text={translate("title.accounting_wo_income")}/>
                {isLoading &&
                    <div className={'flex justify-center py-3'}>
                        <LoaderSmall/>
                    </div>
                }

                <div
                    className={'grid grid-cols-12 gap-4'}>
                    {!isLoading && fieldsHtml}
                </div>
            </Card>

            <PageFooter
                translate={translate}
                canSubmit={canSubmit && checkPerm(Resources.AccountingSettings, UPDATE_PERM)}
                actionCancel={handleFormCancel}
                actionSubmit={onSubmit}
            />
        </div>
    )
}

export default WorkOrderTab
