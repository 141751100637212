export function joinLoad(data) {
    return {
        type: 'JOIN_LOAD',
        data: data
    };
}

export function leaveLoad(data) {
    return {
        type: 'LEAVE_LOAD',
        data: data
    };
}

export function loadUpdated(data) {
    return {
        type: 'LOAD_UPDATED',
        data: data
    };
}

export function clearLoadUpdated() {
    return {
        type: 'CLEAR_LOAD_UPDATED',
    };
}


export function joinedLoad(data) {
    return {
        type: 'JOINED_LOAD',
        data: data
    };
}

export function editLoad(data) {
    return {
        type: 'EDIT_LOAD',
        data: data
    };
}

export function cancelLoad(data) {
    return {
        type: "CANCEL_LOAD",
        data: data
    }
}

export function editedLoad(data) {
    return {
        type: 'EDITED_LOAD',
        data: data
    };
}

export function leftLoad(data) {
    return {
        type: 'LEFT_LOAD',
        data: data
    };
}

export function sendUserMessage(data) {
    return {
        type: 'LOAD_SEND_MESSAGE',
        data: data
    };
}

export function sendCustomerMessage(data) {
    return {
        type: 'LOAD_SEND_CUSTOMER_MESSAGE',
        data: data
    };
}

export function loadMessage(data) {
    return {
        type: 'LOAD_MESSAGES',
        data: data
    };
}

export function newUserLoadMessage(data) {
    return {
        type: 'LOAD_USER_MESSAGE',
        data: data
    };
}

export function newCustomerLoadMessage(data) {
    return {
        type: 'LOAD_CUSTOMER_MESSAGE',
        data: data
    };
}


export function newLoadEvent(data) {
    return {
        type: 'LOAD_EVENT',
        data: data
    };
}

export function customerJoinedChat(data) {
    return {
        type: 'LOAD_EXTERNAL_JOINED_LOAD',
        data: data
    };
}

export function LoadExternalMessages(data) {
    return {
        type: 'LOAD_EXTERNAL_MESSAGES',
        data: data
    };
}

export function LoadExternalSendCustomerMessages(data) {
    return {
        type: 'LOAD_EXTERNAL_SEND_CUSTOMER_MESSAGE',
        data: data
    };
}
