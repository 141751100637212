import { call, put, takeLatest } from "redux-saga/effects";
import {
    doneGetUserData,
    doneLogin,
    doneRegister,
    doneResetPassword,
    doneResetPasswordConfirm,
    doneSendSupport,
    doneUpdatePassword,
    errorGetUserData,
    errorLogin,
    errorRegister,
    errorResetPassword,
    errorResetPasswordConfirm,
    errorSendSupport,
    errorUpdatePassword,
} from "../actions/user";
import Api from "../services/api";
import LocalStorage from "../../util/localStorage";
import {hideGlobalModal, pushNotification} from "../actions/ui";
import { checkUserHelper } from "../services/api-util";
import { keyboardShortcuts } from "../../util/util-constants";
import {getJWT, saveJWTToken} from "../../common/util/util-auth";
import {getCurrentTimeSeconds} from "../../common/util/util-dates";
import {getLocalstorageItemsForSave} from "../../common/util/util-helpers";

const storeUser = (user, lookupsResult, commandsResult) => {
    user.obtain_time = getCurrentTimeSeconds();
    LocalStorage.set('user', user);

    if (lookupsResult && lookupsResult.status === 0) {
        user.FeatureFlags = lookupsResult.data.FeatureFlags
        LocalStorage.set('lookup', lookupsResult.data);
    }
    if (commandsResult && commandsResult.status === 0) {
        let commandValues = Object.keys(keyboardShortcuts).reduce((memo, it) => {
            memo[it] = keyboardShortcuts[it].shortcut
            return memo
        }, {})

        let commands = Object.assign(commandValues, commandsResult.data.list.CommandMap)
        LocalStorage.set('quick_commands', commands);
    }
}

export function* loginCall(action) {
    const result = yield call(Api.login, action.data.username, action.data.password);

    if (result && result.status === 0) {
        const infoResult = yield call(Api.userInfo, result.data);

        if (infoResult && infoResult.status === 0) {
            yield call(saveJWTToken, result.data);
            let user = Object.assign(result.data, infoResult.data);

            let lookupsResult = yield call(Api.getLookups, user);
            let commandsResult = yield call(Api.getUserCommands, user);

            storeUser(user, lookupsResult, commandsResult);

            yield call([LocalStorage, 'set'], 'initial_storage_state', getLocalstorageItemsForSave());

            yield put(doneLogin(user));
        } else {
            yield put(errorLogin(infoResult.data));
        }
    } else {
        yield put(errorLogin(result.data));
    }
}

export function* watchLoginCall() {
    yield takeLatest('LOGIN', loginCall);
}

export function* getUserDataCall() {
    const userToken = {
        access_token: getJWT().access_token
    };

    const infoResult = yield call(Api.userInfo, userToken);

    if (infoResult && infoResult.status === 0) {
        const JWT = getJWT();

        let user = Object.assign(JWT, infoResult.data);

        let lookupsResult = yield call(Api.getLookups, user);
        let commandsResult = yield call(Api.getUserCommands, user);

        storeUser(user, lookupsResult, commandsResult);

        yield put(doneLogin(user));
        yield put(doneGetUserData(user));
    } else {
        yield put(errorGetUserData(infoResult.data));
    }
}

export function* watchGetUserDataCall() {
    yield takeLatest('GET_USER_DATA', getUserDataCall);
}

export function* resetPasswordCall(action) {
    const result = yield call(Api.resetPassword, action.data.username);

    if (result && result.status === 0) {
        yield put(doneResetPassword(result.data));
    } else {
        yield put(errorResetPassword(result.data));
    }
}

export function* watchResetPasswordCall() {
    yield takeLatest('RESET_PASSWORD', resetPasswordCall);
}

export function* resetPasswordConfirmCall(action) {
    const result = yield call(Api.resetPasswordConfirm, action.data.username, action.data.token, action.data.password);

    if (result && result.status === 0) {
        yield put(doneResetPasswordConfirm(result.data));
    } else {
        yield put(errorResetPasswordConfirm(result.data));
    }
}

export function* watchResetPasswordConfirmCall() {
    yield takeLatest('RESET_PASSWORD_CONFIRM', resetPasswordConfirmCall);
}

export function* registerCall(action) {

    const result = yield call(Api.register, action.data.params, action.data.file);

    if (result && result.status === 0) {
        yield put(doneRegister());
    } else {
        yield put(errorRegister(result.data));
    }
}

export function* watchRegisterCall() {
    yield takeLatest('REGISTER', registerCall);
}

export function* sendSupportCall(action) {
    const result = yield call(Api.sendSupport, action.data.user, action.data.params);

    if (result && result.status === 0) {
        yield put(doneSendSupport(result.data));
    } else {
        yield put(errorSendSupport(result.data));
    }
}

export function* watchSendSupportCall() {
    yield takeLatest('SEND_SUPPORT', sendSupportCall);
}

export function* updatePasswordCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updatePassword, user, action.data.params);

    if (result && result.status === 0) {
        yield put(doneUpdatePassword(result.data));

        if (action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage === true ? result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }

        yield put(hideGlobalModal('ChangePassword'));
    } else {
        yield put(errorUpdatePassword(result.data));
    }
}

export function* watchUpdatePasswordCall() {
    yield takeLatest('UPDATE_PASSWORD', updatePasswordCall);
}
