import React, {Component} from "react";
import {classNames} from "../../../util/util-helpers";
import {scrollIntoView} from "../../../util/util-vanilla";

export default class FieldCheckbox extends Component {

    render() {
        return (
            <React.Fragment>
                <input
                    ref={this.props.innerRef}
                    id={this.props.id}
                    type={"checkbox"}
                    className={classNames(
                        this.props.className ?? "checkbox",
                        this.props.disabled ? " checkbox-disabled" : ""
                    )
                    }
                    name={this.props.name}
                    checked={!!this.props.value && (this.props.value !== "0")}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, !!event.target.checked);
                    }}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    disabled={this.props.disabled}
                />
            </React.Fragment>
        )
    }
}
