import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import PaperClipIcon from '@heroicons/react/20/solid/PaperClipIcon'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Card from "../index";
import {classNames} from "../../../util/util-helpers";
import Resources from "../../../../data/services/resources";
import {fieldsToHtml} from "../../../util/util-fields";

const LinkedTasks = (props) => {
    const {
        data,
        handleUnlinkIssue,
        handleUpdateDataValues,
        translate,
        onAddLinkedTaskClick
    } = props

    // Types for linked tasks
    const LinkTypeID = {
        1: translate('text.RelatesTo'),
        2: translate('text.BlockedBy'),
        3: translate('text.Duplicates'),
    }

    const getLinkedTypeColor = (type) => {
        switch (Number(type)) {
            case 1:
                return '#7c68ed'
            case 2:
                return '#fc991f'
            default:
                return '#4bade8'
        }
    }

    const getFields = () => {
        return {
            LinkTypeID: new Field('LinkTypeID', '', ['empty'], false, 'select', {
                addContainerClass: 'col-span-full'
            }, { menuPlacement: 'top' }),
            LinkedTaskID: new Field('LinkedTaskID', '', ['empty'], false, 'multi-select-search', {
                addContainerClass: 'col-span-full'
            }, {
                multi: true,
                menuPlacement: 'top'
            }),
        }
    }

    const [showAddLinkedTasks, setShowAddLinkedTasks] = useState(false)
    const [fields, setFields] = useState(getFields())

    const handleLinkClick = (e) => {
        if (e.includes('http')) {
            window.open(`${e}`, '_blank')
        } else {
            window.open(`${window.location.origin}${e}`, '_blank')
        }
    }

    const handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(fields, name, value)
        setFields(newFields)
    }

    const handleCancel = () => {
        setFields(getFields())
        setShowAddLinkedTasks(false)
    }

    const handleSave = () => {
        const fieldsUpdate = FieldsManager.validateFields(fields);

        setFields(fieldsUpdate)
        if (fieldsUpdate.LinkTypeID.value && fieldsUpdate.LinkedTaskID.value) {
            handleUpdateDataValues(fieldsUpdate)
            handleCancel()
        }
    }

    const returnLinkID = (item) => {
        if ((+item.LinkedTaskID) === (props.TaskID)) {
            return item.TaskID
        } else {
            return item.LinkedTaskID
        }
    }

    const returnTaskName = (item) => {
        if ((+item.LinkedTaskID) === (props.TaskID)) {
            return `${item.TaskName} ${!item.__IsNew__ ? `(#${item.TaskID})` : ''}`
        } else {
            return `${item.LinkedTaskName} ${!item.__IsNew__ ? `(#${item.LinkedTaskID})` : ''}`
        }
    }

    const returnTaskCard = (item) => {

        return (
            <div className="mb-4">
                <div style={{ borderLeft: `3px solid ${getLinkedTypeColor(item.LinkTypeID)}` }}>
                    <Card addClass="relative rounded-tl-none rounded-bl-none bg-tm-gray-100">
                        <div className="flex">
                            <Tippy content={<span>Click to open in new tab</span>}>
                                <div
                                    onClick={() => {
                                        handleLinkClick(`/all-tasks?LinkedTaskID=${returnLinkID(item)}`)
                                    }}
                                    className="flex items-center text-primary cursor-pointer font-semibold">
                                    <span className="submenu-icon mr-2" style={{
                                        color: getLinkedTypeColor(item.LinkTypeID),
                                    }}>
                                        <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                    </span>
                                    <span>{returnTaskName(item)}</span>
                                </div>
                            </Tippy>

                            {props.handleUnlinkIssue && (
                                <div className="ml-auto">
                                    <Tippy
                                        content={
                                            <span>{translate('text.UnlinkIssue')} (Must click Save after removing task)</span>}>

                                        <button
                                            className="btn btn-close"
                                            aria-label="Close"
                                            onClick={() => handleUnlinkIssue(item)}
                                        >
                                            <XMarkIcon className="w-5 h-5" />
                                        </button>
                                    </Tippy>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    const selects = {
        LinkedTaskID: {
            api: 'api/' + Resources.Tasks,
            query: {
                limit: 20,
                offset: 0,
                sort: 'DESC',
                sortBy: 'TaskID',
                TaskInfo: 1,
                ExcludeTaskID: props.TaskID ?? 0
            },
            searchMap: (it) => ({
                label: `${it.TaskName} (#${it.TaskID})`,
                value: it.TaskID
            })
        },
        LinkTypeID: LinkTypeID
    }

    const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, selects)

    const readTypes = Object.assign({}, LinkTypeID, { 4: translate('text.Blocks') })

    const cards = Object.keys(readTypes).map(it => {
        const items = data
            .filter(item => (+item.LinkTypeID) === (+it))
            .map(item => returnTaskCard(item))
        if (items.length === 0) return null
        return (
            <React.Fragment>
                <div className="col-12">
                    <span className="font-weight-bolder text-small text-tm-gray-500">{readTypes[it]}</span>
                </div>
                {items}
            </React.Fragment>
        )
    })

    return (
        <div className="">
            {/* List of linked tasks*/}
            {data.length > 0 && cards}

            {!props.disableCreate && !showAddLinkedTasks && (
                <div className="">
                    <button
                        onClick={() => {
                            setShowAddLinkedTasks(true)
                            !!onAddLinkedTaskClick && onAddLinkedTaskClick()
                        }}
                        className="btn btn-outline"
                    >
                        <PaperClipIcon className="w-4 h-5 mr-1"/>
                        {translate('btn.link_new_issue')}
                    </button>
                </div>
            )}

            {showAddLinkedTasks && (
                <div className="">
                    <div className="border border-tm-gray-200 mb-3 py-3 px-2 w-full">
                        {/* Form for adding new linked tasks*/}
                        <div className="col-12 mt-2">
                            <div className={classNames('grid-cols-6', 'grid gap-4')}>
                                {fieldsHtml}
                            </div>

                            <div className="mt-3">
                                <div className="flex justify-end items-center space-x-5">
                                    <button className={'btn btn-text'}
                                            onClick={handleCancel}>{translate('btn.cancel')}
                                    </button>

                                    <Tippy content={<span>Must click Save after adding new tasks</span>}>
                                        <button
                                            className={'btn btn-outline'}
                                            onClick={fields.LinkedTaskID.value ? handleSave : null}
                                            disabled={!fields.LinkedTaskID.value}
                                        >
                                            <PlusIcon className="w-5 h-5 mr-1"/>

                                            {translate('text.Add')}
                                        </button>
                                    </Tippy>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LinkedTasks
