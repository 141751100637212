import React, {useEffect, useRef, useState} from "react";
import {Loader} from "../../common/components/loader";


export default function AuthLayout({children}) {
    const timeoutRef = useRef();

    const [isBuffering, setIsBuffering] = useState(true);
    const [hideLoader, setHideLoader] = useState(true);
    const [streamingError, setStreamingError] = useState(false);

    useEffect(() => {
        // Don't display loader if buffering is shorter than one second
        timeoutRef.current = setTimeout(() => setHideLoader(false), 1000)

        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (<div className="flex min-h-screen flex-1 bg-tm-gray-100 text-tm-gray-900">
            <div className="relative hidden w-0 max-w-1/2 flex-1 lg:block">
                <div
                    className="absolute inset-0 h-full w-full object-cover bg-[#1e2c47]"
                >
                    {isBuffering && !streamingError && !hideLoader && (
                        <Loader/>
                    )}
                </div>

                {streamingError && (
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="/src/assets/images/login/truck.jpg"
                        alt=""
                    />
                )}

                {!streamingError && (
                    <video loop autoPlay muted preload="auto"
                           onPlay={() => setIsBuffering(false)}
                           onError={() => setStreamingError(true)}
                           className="absolute inset-0 h-full w-full object-cover z-10"
                    >
                        {/* Video should be placed in a .env variable */}
                        <source src="https://cdn.accur8.services/video.mp4" type="video/mp4"/>
                    </video>
                )}

            </div>

            <div
                className="flex flex-1 w-1/2 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
            >
                {children}
            </div>
        </div>
    )
}