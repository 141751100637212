import React from 'react';
import ModalDefault from "../modal-default";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

export default function StripePaymentFailedModal({translate, onClose, data, show}) {
    return <ModalDefault
        show={show}
        // widthClass={widthClass}
        title={translate("text.payment_failed")}
        onClose={onClose}
        limitHeight={false}
        closeButtonLabel={"Close"}
    >
        <div className="p-6 text-center">
            <ExclamationCircleIcon className="w-10 h-10 text-red-600 relative -top-2 inline-block"/>

            {data?.metadata?.failure_code && (
                <p className="text-base text-tm-gray-600">{translate("message." + data?.metadata?.failure_code)}</p>
            )}

            {data?.metadata?.failure_message && (
                <p className="text-lg mb-8 text-red-600">{data?.metadata?.failure_message}</p>
            )}

            {data?.content && (
                <p className="text-base mb-4">{data.content}</p>
            )}
        </div>
    </ModalDefault>
}