import { useState } from "react";
import LocalStorage from "../../util/localStorage";
import Cookie from "../../util/cookie";
import {_ENV_MODE} from "../../util/env";

export default function useThemeColor(newThemeColor) {
    const [themeColor, setThemeColor] = useState(!!newThemeColor ? newThemeColor : getThemeColor())

    function saveThemeColor(themeColorUpdate) {
        saveCurrentThemeColor(themeColorUpdate);
        updateDocumentClasses(themeColor, themeColorUpdate);

        setThemeColor(() => themeColorUpdate);
    }

    return [themeColor, saveThemeColor];
}

const getThemeColor = () => {
    const cookieThemeColor = Cookie.getCookie('APP_THEME_COLOR_'  + _ENV_MODE);
    const localStorageThemeColor = LocalStorage.get("APP_THEME_COLOR");
    let currentColor = "";
    if (!!cookieThemeColor) {
        LocalStorage.set("APP_THEME_COLOR", cookieThemeColor);
        currentColor = cookieThemeColor;
    } else if (!!localStorageThemeColor) {
        currentColor = localStorageThemeColor;
    } else {
        currentColor = 'blue';
    }

    if (!!currentColor) {
        document.documentElement.classList.add(currentColor + "-theme");
    }

    return currentColor;
}

const updateDocumentClasses = (oldColorClass, newColorClass) => {
    if (!!oldColorClass) {
        document.documentElement.classList.remove(oldColorClass + "-theme");
    }

    document.documentElement.classList.add(newColorClass + "-theme");
}

const saveCurrentThemeColor = (theme) => {
    LocalStorage.set("APP_THEME_COLOR", theme);
    Cookie.setCookie(
        "APP_THEME_COLOR",
        theme,
        86400
    );
}
