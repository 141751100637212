import React from "react";
import {showModal} from "../../../../data/actions/ui";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import ImageAvatarLoader from "../../image/image-avatar-loader";

const ContactBadge = (props) => {
    const {ContactName, ContactID, ImagePath, onRemoveBadge, onAvatarClick} = props;
    const className = props.className ? " " + props.className : "";

    const handleAvatarClick = () => {
        props.dispatch(showModal('ViewContactCard', {"ContactID": ContactID}));
    }

    return (
        <div className={"contact-badge items-center flex bg-inverse " + className}>
            <div className="w-10 h-10 mr-2"
                 onClick={() => onAvatarClick ? onAvatarClick(ContactID) : handleAvatarClick()}>

                <ImageAvatarLoader
                    addClass="bg-tm-gray-100 rounded-full"
                    ContactID={ContactID}
                    size="sm"
                    hasImagePath={!!ImagePath}
                />
            </div>

            {ContactName}

            {!!onRemoveBadge && (
                <button type="button" className="contact-badge-remove" onClick={() => onRemoveBadge()}>
                    <XCircleIcon className="h-5 w-5"/>
                </button>
            )}
        </div>
    )
}

export default ContactBadge;
