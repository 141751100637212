import React, {useEffect, useState} from 'react';
import LocalStorage from "../../util/localStorage";
import {
    ArrowPathIcon,
    CheckBadgeIcon,
    CheckCircleIcon,
    EnvelopeOpenIcon,
    LinkIcon,
    PlayIcon,
    StarIcon,
    UserIcon
} from '@heroicons/react/24/outline'
import InfoBar from "../info-paragraph/info-bar";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import Tooltip from "../tooltip";
import NoRecordsTable from "../no-records-found/no-records-table";
import StarSolidIcon from "@heroicons/react/20/solid/StarIcon";
import UserSolidIcon from "@heroicons/react/20/solid/UserIcon";
import {useDispatch} from "react-redux";
import {checkPerm, classNames, getLookup, getProp, openInNewTab, renderTaskDueDate} from "../../util/util-helpers";
import {Field, FieldsManager} from "../../../data/services/fields";
import {READ_PERM} from "../../util/util-consts";
import Resources from "../../../data/services/resources";
import TableCard from "../resource-table/table-components/table-card";
import TaskStatusProgressTableV2
    from "../resource-table/table-components/status-progress-table/task-status-progress-table-v2";
import StackedList from "../display-data/stacked-list";
import TableCardFooter from "../resource-table/table-components/table-card-footer";
import Pagination from "../resource-table/table-components/pagination";
import ModalConfirm from "../modal/modal-confirm";
import TasksInfoDialog from "../modal/allTasksDialog/tasksInfoDialog";
import Modal from "../modal";
import TasksFormDialog from "../forms/task-form";
import ResourceTable from "../resource-table"
import {updateTaskResource} from "../../../data/actions/taskResource";
import {fillFieldsFromData} from "../../util/util-fields";

const WidgetTasks = ({
                         tasks, isLoading, translate, resource, onFetchData, taskResource, isCompact,
                     }) => {
    const dispatch = useDispatch();

    const count = tasks?.count ?? 0;
    const grabCount = tasks?.grabCount ?? 0;

    const [queryFields, setQueryFields] = useState(getQueryFields());

    const [taskInfoDialogOpen, setTaskInfoDialogOpen] = useState(false);
    const [taskFormDialogOpen, setTaskFormDialogOpen] = useState(false);
    const taskStatuses = getLookup('TaskStatus', 'TaskStatusID', 'TaskStatus');

    const saveFilters = (queryFieldsClone) => {
        queryFieldsClone.offset = Object.assign({}, queryFieldsClone.offset)
        queryFieldsClone.offset.value = 0;
        LocalStorage.set("widget_tasks", FieldsManager.getFieldKeyValues(queryFieldsClone));
    };

    useEffect(() => {
        // Fetch data after creating task from global dialog
        if (taskResource.create?.id) {
            fetchData();
        }
    }, [taskResource])


    const tasksList = getProp(tasks, 'list', []);

    const fetchData = () => {
        onFetchData(resource, FieldsManager.getFieldKeyValues(queryFields));
    }

    const getTaskAction = (task) => {
        if (checkPerm(Resources.Tasks, READ_PERM)) {
            setTaskFormDialogOpen(true);
        } else {
            setTaskInfoDialogOpen(true);
        }

        setSelectedItem(task);
    }

    const getTooltipText = (it) => {
        if (!taskStatuses) {
            return ""
        }

        return "Set to " + taskStatuses[(it.TaskStatusID + 1)];
    }

    const [confirmText, setConfirmText] = useState("");
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);

    const handleChangeTaskStatus = (item) => {
        if (!taskStatuses) {
            return null;
        }

        const nextTaskLabel = taskStatuses[(item.TaskStatusID + 1)];
        setConfirmText(translate("message.text_task_confirm") + " '" + nextTaskLabel + "'");
        setConfirmModalOpen(true);
        setSelectedItem(item);
    }

    const handleQueryChange = (name, value) => {
        let queryFieldsClone = Object.assign({}, queryFields);

        if (name === "Pinned" || name === "assignedToMe") {
            queryFieldsClone.offset.value = "0";
        }

        setQueryFields(FieldsManager.updateField(queryFieldsClone, name, value));
        saveFilters(queryFieldsClone);
    }

    const updateTaskStatus = () => {
        setConfirmModalOpen(false);

        dispatch(updateTaskResource({
            user: LocalStorage.get('user'),
            params: {
                id: selectedItem.TaskID, TaskStatusID: selectedItem.TaskStatusID + 1, SkipWatchers: 1
            },
            query: {
                offset: 0
            },
            resource: Resources.MineTasks,
            errorMessage: true,
            successMessage: translate("text.TaskStatusUpdatedTo", [taskStatuses[selectedItem.TaskStatusID + 1]]),
        }));
    }

    const getActionIcon = (it) => {
        switch (it.TaskStatusID) {
            case 1:
                return PlayIcon
            case 2:
                return CheckCircleIcon
            case 3:
                return CheckBadgeIcon
            default:
                return EnvelopeOpenIcon
        }
    }

    const getActionIconClass = (it) => {
        switch (it.TaskStatusID) {
            case 1:
                return "w-5 h-5 text-primary"
            case 2:
                return "w-5 h-5 text-green-600"
            case 3:
                return "w-5 h-5 text-green-600"
            default:
                return "w-5 h-5"
        }
    }

    function getQueryFields() {
        const item = LocalStorage.get("widget_tasks", {});

        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', 'DueDate', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),
            pinned: new Field('pinned', "0", [''], false, 'text', {}, {}),
            assignedToMe: new Field('assignedToMe', "0", [''], false, 'text', {}, {}),
            limit: new Field('limit', '10', [''], false, 'select', {
                labelType: 'float', hideLabel: true
            }, {menuPlacement: 'top'})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    useEffect(() => {
        if (taskResource.update) {
            fetchData();
        }
    }, [taskResource.update]);

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    return (
        <React.Fragment>
            <TableCard>
                <div className="px-5 py-3 flex justify-between">
                    <div
                        className="group flex items-center gap-2 relative cursor-pointer w-full"
                    >
                        <div onClick={() => openInNewTab('/all-tasks')}>
                            <LinkIcon
                                className="hidden hover:cursor-pointer group-hover:block w-5 h-5 text-primary absolute -left-4"/>

                            <h5 className="text-lg pl-2 ">
                                {translate("text.tasks")}
                            </h5>
                        </div>

                        <Tooltip
                            content={<div className="text-center">List of tasks that you are either watcher or assignee,
                                that are not completed or closed.</div>}
                        >
                            <InformationCircleIcon className="w-5 h-5 ml-1 text-primary"/>
                        </Tooltip>

                        {!grabCount && !isLoading && !isCompact && (<div>
                            <InfoBar addClass={
                                classNames(
                                    "ml-4 pr-4 mr-auto hidden lg:flex"
                                )}
                            >
                                {translate("text.no_tasks_available")}
                            </InfoBar>
                        </div>)}

                        <div className="ml-auto space-x-2">
                            <Tooltip
                                content={queryFields.pinned.value !== "1" ? "Filter favourite" : "Show all"}
                            >
                                <button className="btn btn-header"
                                        onClick={() => handleQueryChange("pinned", queryFields.pinned.value !== "1" ? "1" : "0")}>
                                    {queryFields?.pinned?.value === "1" ?
                                        <StarSolidIcon className="w-5 h-5 text-primary"/> :
                                        <StarIcon className="w-5 h-5"/>}
                                </button>
                            </Tooltip>


                            <Tooltip
                                content={queryFields.assignedToMe.value !== "1" ? "Filter tasks that are assigned to me" : "Show all"}
                            >
                                <button
                                    className="btn btn-header"
                                    onClick={() => handleQueryChange("assignedToMe", queryFields.assignedToMe.value !== "1" ? "1" : "0")}
                                >
                                    {queryFields?.assignedToMe?.value === "1" ?
                                        <UserSolidIcon className="w-5 h-5 text-primary"/> :
                                        <UserIcon className="w-5 h-5"/>}
                                </button>
                            </Tooltip>


                            <button className="btn btn-header" onClick={fetchData}>
                                <ArrowPathIcon
                                    className={classNames("w-5 h-5", isLoading ? "animate-spin" : undefined)}
                                />
                            </button>
                        </div>

                    </div>

                </div>

                {!isCompact && (
                    <ResourceTable
                        data={tasksList}
                        count={count}
                        isLoading={isLoading}
                        fields={{
                            TaskID: new Field('TaskID', '', ['']),
                            TaskName: new Field('TaskName', '', ['']),
                            Assignee: new Field('Assignee', '', ['']),
                            TaskStatus: new Field('TaskStatus', '', [], false, 'custom', {
                                render: (item) => (<TaskStatusProgressTableV2
                                    addClass=""
                                    TaskStatusID={item.TaskStatusID}
                                    translate={translate}
                                />)
                            }),
                            DueDate: new Field('DueDate', '', ['empty'], false, 'custom', {
                                render: (item) => {
                                    return renderTaskDueDate(item);
                                }
                            }),
                            TaskPriority: new Field('TaskPriority', '', [], false)
                        }}
                        translate={translate}

                        queryFields={queryFields}

                        onRowClick={getTaskAction}
                        onView={getTaskAction}
                        actions={[{
                            title: (it) => getTooltipText(it),
                            visible: (it) => it.TaskStatusID < 3 && !!taskStatuses,
                            action: handleChangeTaskStatus,
                            icon: (it) => getActionIcon(it),
                            iconClass: (it) => getActionIconClass(it)
                        },]}
                    />
                )}

                {isCompact && (
                    <StackedList
                        data={tasksList}
                        isLoading={isLoading}
                        listKey="TaskID"
                        colRightClass="sm:flex sm:flex-col sm:items-end"
                        onTapRow={getTaskAction}
                        fields={{
                            TaskID: new Field('TaskID', '', [''], false, "custom", {
                                render: (it) => {
                                    return <div>
                                        <span className="text-tm-gray-500">{it.TaskID}</span>
                                        <span>{it.TaskName}</span>
                                    </div>
                                }
                            }),

                            Assignee: new Field('Assignee', '', [''], false),
                            TaskStatus: new Field('TaskStatus', '', [], false, 'custom', {
                                render: (item) => (<TaskStatusProgressTableV2
                                    addClass=""
                                    TaskStatusID={item.TaskStatusID}
                                    translate={translate}
                                />)
                            }),
                        }}
                    />
                )}

                <TableCardFooter
                    show={!isLoading && tasksList && tasksList.length > 0}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value) => {
                                handleQueryChange(name, value)
                            }
                        }
                        queryFields={queryFields}
                        hideRowsPerPage={isCompact}
                        translate={translate}
                    />
                </TableCardFooter>

                <NoRecordsTable
                    show={!count && !isLoading}
                    canCreate={false}
                    title={translate('text.no_tasks')}
                />

            </TableCard>

            <ModalConfirm
                title={translate("text.Confirm")}
                show={confirmModalOpen}
                text={confirmText}
                textClassName={"break-word text-sm text-tm-gray-900"}
                onClose={() => setConfirmModalOpen(false)}
                buttonLabel={translate("btn.confirm")}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={updateTaskStatus}
            />

            <Modal
                show={taskInfoDialogOpen}
                title={translate("text.task_info")}
                className={"modal-xl"}
                onClose={() => setTaskInfoDialogOpen(false)}
                translate={translate}
            >
                <TasksInfoDialog
                    onClose={() => setTaskInfoDialogOpen(false)}
                    id={selectedItem.TaskID}
                    translate={translate}
                />
            </Modal>

            <Modal
                show={taskFormDialogOpen}
                widthClass={"max-w-5xl"}
                translate={translate}
                onClose={() => setTaskFormDialogOpen(false)}
            >
                <TasksFormDialog
                    onClose={() => setTaskFormDialogOpen(false)}
                    TaskID={selectedItem.TaskID}
                    dispatch={dispatch}
                    translate={translate}
                />
            </Modal>
        </React.Fragment>)
}

export default WidgetTasks;
