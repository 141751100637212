import React, {Component} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Dialog from "../../modal/modal-default";
import FileViewer from "../../file-viewer/components";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {getProp} from "../../../util/util-helpers";
import {cloneDeep, dirtyClone, scrollErrorIntoView} from "../../../util/util-vanilla";
import {LoaderSmall} from "../../loader";
import slateHTMLToValue from "../../fields/rich-text-editor/slate-deserialize";
import {fieldsToHtml} from "../../../util/util-fields";
import DisplayDataGrid from "../../display-data/display-data-grid";

export default class EmailDocumentDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: cloneDeep(this.props.fields),
            previewDocument: false,
            isFormVisible: true
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && !!this.props.show) {
            this.setState({fields: dirtyClone(this.props.fields), previewDocument: false})
        }

        if (prevProps.thirdResource.isLoading !== this.props.thirdResource.isLoading && !this.props.thirdResource.isLoading) {
            const item = getProp(this.props.thirdResource, "data", {});

            let fields = this.state.fields;

            fields = FieldsManager.updateField(fields, "subject", item.subject);
            fields = FieldsManager.updateField(fields, "emailMessage", slateHTMLToValue(item.content));

            this.setState({
                fields: fields,
                isFormVisible: true
            });
        }
    }

    fetchEmailTemplate = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get("user"),
            query: Object.assign({
                EmailTemplateID: this.state.fields.EmailTemplateID.value?.value
            }, this.props.fetchEmailTemplateQuery ?? {}),
            resource: Resources.EmailTemplatesParse
        }));

        this.setState({
            isFormVisible: false
        })
    };

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        if ("AddContactID" === name) {
            let emails = this.state.fields.emails.value;
            if (!emails) {
                emails = [];
            }
            emails.push(value);

            FieldsManager.updateField(this.state.fields, "emails", emails);
            FieldsManager.updateField(this.state.fields, "AddContactID", "");
        }

        this.setState({fields: fields}, () => name === "EmailTemplateID" ? this.fetchEmailTemplate(value) : null);
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);

        validatedFields = this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    toggleDocumentPreview = () => {
        this.setState({
            previewDocument: !this.state.previewDocument
        })
    }

    getReadOnlyFields = (fields = null) => {
        fields = {
            DocumentName: new Field("DocumentName", this.props?.document?.DocumentName, [], false, 'file', {
                addContainerClass: "col-span-2 break-all",
                filePath: this.props.filePath
            }),
            DocumentType: new Field("DocumentType", this.props?.document?.DocumentType ?? "N/A", [], false, 'text', {
                addContainerClass: "col-span-1"
            }),
            CreateUpdateDate: new Field("CreateUpdateDate", this.props?.document?.CreateUpdateDate, [], false, 'date', {
                addContainerClass: "col-span-1",
                label: "UploadDate"
            }),
            Description: new Field("Description", this.props?.document?.documentDescription, [], false, this.props?.document?.documentDescription ? 'text' : 'hidden', {addContainerClass: "col-span-full"})
        }

        return fields;
    }



    render() {
        const {
            onSubmit,
            onClose,
            translate,
            title,
            metadata,
            filePath,
            fileType,
            onPrint,
            customButtonsHTML,
            onDocumentSettingsInputChange,
            documentSettingsFields
        } = this.props;

        const customButtons = (
            <span className="flex mr-auto">
                {!!onPrint && (
                    <button
                        className={`btn btn-outline-secondary mr-2  inline-flex justify-content-center items-center h-3em sm:w-3em`}
                        onClick={() => onPrint()}>
                        <i className="iconsminds-printer"/>
                        <span className="d-none d-sm-inline-block ml-1">{translate("btn.print")}</span>
                    </button>
                )}

                <button
                    className={`btn btn-outline-secondary  inline-flex justify-content-center items-center h-3em sm:w-3em`}
                    onClick={() => this.toggleDocumentPreview()}
                >
                    <i className="simple-icon-eye"/>
                    <span
                        className="d-none d-sm-inline-block ml-1">{this.state.previewDocument ? translate("btn.closePreview") : translate("btn.preview")}</span>
                </button>

                {customButtonsHTML}
            </span>
        )

        return (
            <React.Fragment>
                <Dialog
                    show={this.props.show}
                    closeButtonLabel={translate("btn.cancel")}
                    buttonLabel={this.props.primaryBtnLabel ?? translate("btn.send_emails")}
                    onButtonClick={() => onSubmit(this.getValidFields())}
                    {...this.props}// Because of customButtonsHTML override put this first
                    widthClass={"max-w-7xl"}
                    title={this.state.previewDocument ? translate("modal_heading.preview_document") : title}
                    onClose={() => this.state.previewDocument ? this.toggleDocumentPreview() : onClose()}
                    customButtonsHTML={customButtons}
                >
                    <div className="p-6 relative h-[calc(100vh-12rem)] overflow-auto">
                        {!!this.props.thirdResource.isLoading && (
                            <LoaderSmall addClass="action-container-center"/>
                        )}

                        {this.state.isFormVisible && (
                            <div
                                className={this.props.thirdResource.isLoading ? "invisible" : ""}
                            >
                                <div className="max-w-7xl mx-auto space-y-8">
                                    {!!documentSettingsFields && (
                                        <div className=" px-5 pt-5 pb-2 rounded border border-tm-gray-200 bg-tm-gray-50">
                                            <div className="text-base leading-6 font-medium text-tm-gray-900">
                                                Document settings
                                            </div>

                                            <div className="divide-y divide-tm-gray-200">
                                                {fieldsToHtml(Object.values(Object.assign({}, this.props.documentSettingsFields)), translate, onDocumentSettingsInputChange, metadata)}
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className="grid grid-cols-12 gap-y-4"
                                    >
                                        {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}
                                    </div>

                                    {!this.props.hideDocumentFields && (
                                        <div className="mt-10">
                                            <DisplayDataGrid
                                                addGridClass={"md:grid-cols-4 mb-5 border border-tm-gray-300 rounded-lg p-5 text-tm-gray-700 font-bold"}
                                                displayList={Object.values(this.getReadOnlyFields())}
                                                data={getProp(this.props, "document", {})}
                                                translate={translate}
                                            />
                                        </div>
                                    )}
                                </div>

                                {!!this.state.previewDocument && (
                                    <React.Fragment>
                                        <div className="inset-0 absolute overflow-y-auto bg-popup z-40">
                                            <FileViewer
                                                fileType={fileType}
                                                filePath={filePath}
                                                onError={(e) => {
                                                    console.log(e)
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}
