import DataCard from "../../../display-data/data-card";
import { CalendarIcon, HomeIcon, MapPinIcon, MapIcon, PhoneIcon } from "@heroicons/react/20/solid";
import WorkingHoursDisplay from "../../../display-data/working-hours-display";
import React from "react";
import {Field} from "../../../../../data/services/fields";

export default function LocationInfo({translate, data}) {
    let basicFields = {
        LocationName: new Field('LocationName', '', [''], false, "text", {icon: () => HomeIcon}),
        AddressName: new Field('AddressName', '', [''], false, "address", {icon: () => MapPinIcon}),
        PhoneNumber: new Field('PhoneNumber', '', [''], false, "phone", {icon: () => PhoneIcon}),
        LocationZone: new Field('LocationZone', '', [''], false, "text", {icon: () => MapIcon}),
    };

    let noteFields = {
        InternalNotes: new Field('InternalNotes', '', [''], false, "textarea"),
        DispatchNotes: new Field('DispatchNotes', '', [''], false, "textarea"),
    };

    return (
        <React.Fragment>
            <div className="space-y-2">
                {Object.values(basicFields).filter(it => data[it.name] !== undefined).map(field => {
                    return (
                        <DataCard
                            key={field.name}
                            displayField={field}
                            fieldsData={data}
                            translate={translate}
                        />
                    )
                })}
            </div>

            <div className="max-w-3xl">
                <div className="flex justify-between items-center mb-2">
                    <p className="font-bold leading-5">Working hours</p>

                    <div className="flex">
                        <CalendarIcon className="w-5 h-5 mr-2 text-yellow-600"/>
                        <p>Requires appointment</p>
                    </div>
                </div>

                <div className="max-w-md shadow-card rounded-lg border border-tm-gray-300 overflow-hidden">
                    <WorkingHoursDisplay
                        locationData={data}
                        translate={translate}
                    />
                </div>
            </div>

            <div className="space-y-6">
                {Object.values(noteFields).filter(it => data[it.name] !== undefined).map(field => {
                    return (
                        <DataCard
                            key={field.name}
                            displayField={field}
                            fieldsData={data}
                            translate={translate}
                        />
                    )
                })}
            </div>
        </React.Fragment>
    )
}