import React from 'react';
import {getJWT, getUser} from "../../../util/util-auth";
import Resources from "../../../../data/services/resources";
import Env from "../../../../util/env";

export default function ProfileImage() {
    const user = getUser();

    if (!user) {
        return null;
    }

    return (
        <div
            className={`w-10 h-10 ${!user.ImagePath && "bg-blue-400"} rounded-full flex justify-center items-center select-none`}>
            {getUser("Contact.ImagePath") ? (
                    <img className="rounded-full h-full w-full" alt={user.LastName + " " + user.FirstName}
                         src={Env.getApiUrl("api/" + Resources.ContactImage, {
                             token: getJWT().access_token,
                             ContactID: getUser("Contact.ContactID"),
                         })}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null; // prevents looping
                             currentTarget.src = "/images/avatar-light.png";
                         }}
                    />
                )
                :
                (
                    <div className="text-inverse font-black">
                        {user.LastName.slice(0, 1) + user.FirstName.slice(0, 1)}
                    </div>
                )
            }
        </div>
    )
}
