import React from "react";
import Tippy from "@tippyjs/react";
import {classNames} from "../../../util/util-helpers";

export default function FieldOptions({options, className, addClass}) {
    const optionsRender = options
        .filter(option => option.isVisible)
        .map((option, i) => {
            return (
                <Tippy
                    key={i}
                    disabled={!option.toolTip}
                    content={option.toolTip}
                >
                    <button
                        className={classNames(option.buttonClass ?? "btn-field", option.addClass)}
                        onClick={option.onClick}
                    >
                        {!!option.icon && (
                            <option.icon/>
                        )}

                        {!!option.buttonLabel && (
                            option.buttonLabel
                        )}
                    </button>
                </Tippy>
            )
        })

    return (
        <div className={
            classNames(
            className ?? "gap-x-3 h-5 relative bottom-0.5 flex",
                addClass
            )
        }>
            {optionsRender}
        </div>
    )
}