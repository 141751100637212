import React, {Component} from 'react'
import Resources from '../../../../data/services/resources'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {updateResource} from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import ModalFooter from "../../../../common/components/modal/modal-footer";
import FieldTextarea from "../../../../common/components/fields/field-textarea";
import FieldText from "../../../../common/components/fields/field-text";
import FieldDate from "../../../../common/components/fields/field-date";
import Cron from "../../../../common/components/cron";

class UpdateRecurringLoadsDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            selectedRepeatOption: this.props.items.EndDate ? 'EndDate' : this.props.items.EndAfter ? 'EndAfter' : 'none',
            canSubmit: false
        }
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true})
    }

    createItem = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    query: {
                        offset: 0,
                        limit: 10,
                        sort: 'ASC',
                        sortBy: 'LoadNumber',
                        query: '',
                    },
                    resource: Resources.LoadRecurring,
                    piggyResource: Resources.LoadRecurring,
                    errorMessage: true, successMessage: `Recurring Load has been updated.`,
                }))
                this.props.onClose()
            }
        })
    }

    getFields = () => {
        return {
            Cron: new Field('Cron', this.props.items.Cron, ['empty']),
            id: new Field('id', this.props.items.LoadRepeatID, ['empty']),
            EndDate: new Field('EndDate', this.props.items.EndDate, []),
            EndAfter: new Field('EndAfter', this.props.items.EndAfter, ['integer_not_require']),
            Notes: new Field('Notes', this.props.items.Notes, []),
        }
    }


    render() {
        const {translate} = this.props

        return (
            <div className="space-y-4">

                <div className="p-4">
                    <div className="col-12">
                        <Cron
                            onChange={(e) => this.handleInputChange(this.state.fields.Cron.name, e)}
                            value={this.state.fields.value?.value}
                            showResultText={true}
                            options={{
                                //headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.DAILY]
                            }}
                        />
                    </div>
                </div>

                <div className="p-4">
                    <label className="flex gap-2">
                        <input
                            type={'radio'}
                            value={'none'}
                            name={'Repeat'}
                            checked={this.state.selectedRepeatOption === 'none'}
                            onChange={() => {
                                let fields = this.state.fields
                                fields.EndDate.value = ''
                                fields.EndAfter.value = ''
                                this.setState({fields: fields, selectedRepeatOption: 'none'})
                            }}
                        />

                        <span className="text-tm-gray-500 text-small ">{translate('field.None')}</span>
                    </label>
                </div>

                <div className="px-4">
                    <label className="flex gap-2">
                        <input
                            type={'radio'}
                            value={'EndDate'}
                            name={'Repeat'}
                            checked={this.state.selectedRepeatOption === 'EndDate'}
                            onChange={() => this.setState({
                                selectedRepeatOption: 'EndDate',
                                fields: FieldsManager.updateField(this.state.fields, 'EndAfter', '')
                            })}
                        />

                        <span className="text-tm-gray-500 text-small ">{translate('field.EndDate')}</span>
                    </label>

                    <div className="">
                        <label className="form-group has-float-label mb-3">
                            <FieldDate
                                showTimeSelect={false}
                                addClass={'form-control text-center'}
                                onChange={this.handleInputChange}
                                {...this.state.fields.EndDate}
                                disabled={this.state.selectedRepeatOption !== 'EndDate'}
                            />
                            <span>{translate('field.EndDate')}</span>
                        </label>
                    </div>
                </div>

                <div className="px-4">
                    <label className="flex gap-2">

                        <input
                            type={'radio'}
                            value={'EndAfter'}
                            name={'Repeat'}
                            checked={this.state.selectedRepeatOption === 'EndAfter'}
                            onChange={() => this.setState({
                                selectedRepeatOption: 'EndAfter',
                                fields: FieldsManager.updateField(this.state.fields, 'EndDate', '')
                            })}
                        />

                        <span className="text-tm-gray-500 text-small ">{translate('field.EndAfter')}</span>
                    </label>
                    <div className="">
                        <label className="form-group has-float-label mb-3">
                            <FieldText
                                showTimeSelect={false}
                                addClass={'form-control text-center'}
                                onChange={this.handleInputChange}
                                {...this.state.fields.EndAfter}
                                disabled={this.state.selectedRepeatOption !== 'EndAfter'}
                            />
                            <span>{translate('field.EndAfter')}</span>
                        </label>
                    </div>
                </div>

                <div className="px-4">
                    <div className="">
                        <label className="form-group has-float-label mb-3">
                            <FieldTextarea
                                addClass={'form-control whitespace-pre-wrap'}
                                onChange={this.handleInputChange}
                                {...this.state.fields.Notes}
                            />
                            <span>{translate('field.Notes')}</span>
                        </label>
                    </div>
                </div>

                <ModalFooter
                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.props.onClose}
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate('btn.save')}
                    onButtonClick={this.state.canSubmit && thiscreateItem}
                />
            </div>
        )
    }
}

export default UpdateRecurringLoadsDialog
