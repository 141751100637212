import React, {Component} from 'react';
import {deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import CreateNewLocationDialog from "./create-location-dialog";
import InfoParagraph from "../../info-paragraph";
import Tippy from "@tippyjs/react";
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import NoRecordsTable from "../../no-records-found/no-records-table";
import ResourceTable from "../../resource-table";
import {CREATE_PERM, DEFAULT_CRUD_STATE, READ_PERM} from "../../../../util/util-constants";
import {showMapDialog} from "../../../../data/actions/ui";
import {Field, FieldsManager} from "../../../../data/services/fields";
import PageHeader from "../../layout/layout-components/page/page-header";
import {checkPerm, getProp, openInNewTab} from "../../../util/util-helpers";
import Pagination from "../../resource-table/table-components/pagination";
import ModalSaveResource from "../modal-save-resource";
import ModalConfirm from "../modal-confirm";
import Modal from "../index";
import TableCard from "../../resource-table/table-components/table-card";
import TableFilters from "../../resource-table/table-components/table-filters";
import ToolTipTableCell from "../../resource-table/table-components/tooltip-table-cell";
import {fillFieldsFromData} from "../../../util/util-fields";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import Resources from "../../../../data/services/resources";
class LocationsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            ...DEFAULT_CRUD_STATE,
            limit: 50,
            sortBy: "LocationDate",
            sort: "DESC",
            showAllLocations: false,
            isMoving: false,
            queryFilterFields: this.getQueryFilterFields(),

            locationsModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.sort !== this.state.sort) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== "")) {
            this.fetchData();
        }
    };

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: this.getResource(),
            query: this.getQuery()
        }));
    };

    /** UI Events
     ================================================================= */

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => this.fetchData())
    }

    handleShowLocationDetails = (item) => {
        this.props.dispatch(showMapDialog(item))
    }

    handleShowAllLocationDetails = () => {
        const locations = getProp(this.props.resource.data, `list`, [])
            .filter(it => it.Longitude && it.Latitude);

        this.props.dispatch(showMapDialog(locations))
    }

    handleToggleLocationsModal = (item = null, isMoving) => {
        this.setState({
            showAllLocations: false,
            selectedItem: item,
            isMoving: isMoving,
            locationsModalOpen: !this.state.locationsModalOpen
        })
    }

    handleToggleNonManualEditDialog = (item = null) => {
        this.setState({
            editModalOpen: !this.state.editModalOpen,
            selectedItem: item
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            confirmModalOpen: !this.state.confirmModalOpen,
            selectedItem: item
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        });
    }

    /** Helpers
     ================================================================= */
    getNonManualEditFields = (item = null) => {
        const fieldTemplates = {
            LocationDate: new Field('LocationDate', '', [], false, 'datetime', {addContainerClass: "col-span-full"}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: "col-span-full"})
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates;
    }

    getFields = () => {
        return {
            LocationName: new Field("LocationName", '', [], false, 'text'),
            LocationDate: new Field("LocationDate", '', [], false, 'datetime', {
                columnName: "Date"
            }),
            AddressName: new Field("AddressName", '', [], false, 'text'),
            CityName: new Field("CityName", '', [], false, 'text'),
            State: new Field("State", '', [], false, 'text'),
            CountryName: new Field("CountryName", '', [], false, 'text'),
            Source: new Field("Source", '', [], false, 'text'),
            LoadID: new Field('LoadID', '', [], false, 'custom', {
                render: (item) => {
                    return <ToolTipTableCell
                        placement={"left"}
                        cellValue={item.LoadNumber}
                        tooltips={[
                            {
                                id: item.LoadID + "_quick",
                                content: this.props.translate("btn.quick_view"),
                                onClick: () => openInNewTab(`/loads/info/${item.LoadID}`),
                                icon: EyeIcon
                            },
                            {
                                id: item.LoadID + "_edit",
                                content: this.props.translate("btn.edit"),
                                onClick: () => this.props.history.push(`/loads/info/${item.LoadID}`),
                                icon: PencilIcon
                            },
                        ]}
                    />
                }
            }),
            Notes: new Field("Notes", '', [], false, 'text'),
        }
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? ""
    }

    getQueryFilterFields = () => {
        return {
            query: new Field("query", '', [''], false, 'search', {}, {}),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true})

        }
    }

    getQuery = () => {
        return {
            id: this.getID(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getID = () => {
        return this.props.match.params.id
    }

    getResource = () => {
        return this.props.resourcePath
    }

    render() {
        const {translate, resource} = this.props;
        const dataItems = getProp(resource.data, `list`, [])
        const onLoad = !!getProp(resource.data, `onLoad`, true)
        const count = getProp(resource.data, `count`, 0)

        const unit = getProp(resource.data, `unit`, {})

        const locations = getProp(this.props.resource.data, `list`, [])
            .filter(it => it.Longitude && it.Latitude)

        const hasLocations = locations.length > 0

        return (
            <div>
                <div className={"flex justify-between mb-2"}>
                    <div className={"flex items-center justify-center"}>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            translate={translate}
                        />
                    </div>

                    <PageHeader>
                        {!resource.isLoading && (
                            <div className="flex">
                                {checkPerm(this.getResource(), CREATE_PERM) && (
                                    <Tippy content={
                                        onLoad ?
                                          <span>{translate(this.getResource())} cannot currently be changed since asset is on load which is dispatched</span>
                                          :
                                          <span>Mileage will NOT be reported or written to tax logs, only position of the asset will be updated. Associated unit position will be updated as well.</span>
                                    }>
                                        <div className={"btn btn-primary " + (onLoad ? "disabled" : '')}
                                             onClick={() => !onLoad && this.handleToggleLocationsModal({}, false)}>{translate("btn.UpdateLocation")}
                                        </div>
                                    </Tippy>
                                )}

                                {(checkPerm(this.getResource(), CREATE_PERM) && this.props.resourcePath === Resources.TrucksLocations) && (
                                    <Tippy
                                           content={
                                               onLoad ?
                                                   <span>{translate(this.getResource())} cannot currently be changed since asset is on load which is dispatched</span>
                                                   : (!unit?.DriverID ? <span>Cannot move asset without assigned driver in the dispatch manager.</span> : <span>Mileage will be reported and written to the tax logs. Associated unit position will be updated as well.</span>)}>
                                        <div
                                            className={"btn btn-primary ml-2 " + (!!onLoad || !unit?.DriverID ? "disabled" : '')}
                                            onClick={() => (!onLoad && !!unit?.DriverID) && this.handleToggleLocationsModal({}, true)}>{translate("btn.MoveAsset")}
                                        </div>
                                    </Tippy>
                                )}

                                {checkPerm(this.getResource(), READ_PERM) && !!hasLocations && (
                                    <div className={"btn btn-primary ml-2 "}
                                         onClick={() => this.handleShowAllLocationDetails()}>{translate("btn.SeeLocations")}
                                    </div>
                                )}
                            </div>
                        )}
                    </PageHeader>
                </div>


                {this.props.resource.errorMessage && (
                    <div className="row">
                        <div className="col-md-6">
                            <InfoParagraph
                                type="danger"
                            >
                                {translate(this.props.resource.errorMessage)}
                            </InfoParagraph>
                        </div>
                    </div>
                )}

                <TableCard>
                    <TableFilters
                        hideLimit
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                    />
                    <ResourceTable
                        data={dataItems}
                        count={count}

                        fields={this.props.tableFields ?? this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.queryFilterFields?.limit?.value ?? this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                title: () => translate('btn.quick_view'),
                                visible: (item) => (item.Longitude || item.Latitude),
                                action: this.handleShowLocationDetails,
                                icon: () => EyeIcon,
                            },
                            {
                                title: () => translate('btn.edit'),
                                visible: (item) => !(item.Source !== "Manual" || !!onLoad),
                                action: this.handleToggleLocationsModal,
                                icon: () => PencilIcon,
                            },
                            {
                                title: () => translate('btn.edit'),
                                visible: (item) => !(item.Source === "Manual"),
                                action: this.handleToggleNonManualEditDialog,
                                icon: () => PencilIcon,
                            },
                            {
                                title: () => translate('btn.delete'),
                                visible: (item) => !(item.Source !== "Manual" || !!onLoad),
                                action: this.handleToggleConfirmModal,
                                icon: () => TrashIcon,
                            },
                        ]}
                    />
                    {!(!dataItems.length && !resource.isLoading) && (
                        <div
                            className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </div>
                    )}

                    <NoRecordsTable
                        show={(dataItems.length === 0) && !resource.isLoading}
                        canCreate={false}
                        title={translate("text.no_matching_records")}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>

                <ModalSaveResource
                    title={"Edit Location"}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={() => this.handleToggleNonManualEditDialog()}
                    fields={this.getNonManualEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getID();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: "Location updated.",
                            }));
                            this.handleToggleNonManualEditDialog()
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={"Confirm"}
                    show={!!this.state.confirmModalOpen}
                    text={"Are you sure you want to delete this location?"}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResource(),
                            piggyResource: this.getResource(),
                            errorMessage: true, successMessage: "Location deleted."
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                />

                <Modal
                    show={this.state.locationsModalOpen}
                    onClose={this.handleToggleLocationsModal}
                    widthClass={'max-w-[1600px]'}
                >
                    <CreateNewLocationDialog
                        onClose={this.handleToggleLocationsModal}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        resourcePath={this.getResource()}
                        resourceID={this.getID()}
                        getQuery={this.getQuery}
                        item={this.state.selectedItem}
                        additionalParams={{
                            isMoving: this.state.isMoving ? 1 : 0
                        }}
                        htmlAfterHeader={(
                            <div className={"p-2"}>{this.state.isMoving ? (
                                <InfoParagraph>{translate("text.isMoving")}</InfoParagraph>) : (
                                <InfoParagraph>{translate("text.notMoving")}</InfoParagraph>)}</div>
                        )}
                    />
                </Modal>
            </div>
        )
    }
}

export default LocationsTab;
