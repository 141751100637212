import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import 'react-image-crop/dist/ReactCrop.css'
import {UPDATE_PERM} from '../../../common/util/util-consts'
import {checkPerm, classNames, getLookup, getProp, getTranslatedLookup} from '../../../common/util/util-helpers'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import {SpeakerWaveIcon, SpeakerXMarkIcon} from '@heroicons/react/24/outline'
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import ModalDefault from "../../../common/components/modal/modal-default";
import {Loader} from "../../../common/components/loader";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import FieldText from "../../../common/components/fields/field-text";
import FieldContainer from "../../../common/components/fields/field-container";
import Card from "../../../common/components/card";
import ContactPhoto from "../../../common/components/forms/contact-form/contact-photo";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";

let timezone = require('../../../assets/json-helpers/timezone.json')
let dateTimeFormat = require('./datetimeformat.json')

class Profiles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userMenu: null,
            fields: this.getFields(),
            canSubmit: false,
            dropzoneActive: false,
            files: [],
            NotificationSetting: [],
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            isCropVisible: false,
            notificationDialog: false,
            notificationPreviewSoundFields: {},
            notificationPreviewSoundCanSubmit: false,
            hasImagePath: false,
            isPasswordVisible: false,
        }

        this.audio = React.createRef()
        this.fileImageObject = null
        this.measureUnit = getTranslatedLookup('MeasureUnit', this.props.translate)
    }

    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading && getProp(this.props, 'resource.data.Contact', false))) {
            this.setState({
                fields: this.getFields(),
                NotificationSetting: this.getNotificationSetting(),
                hasImagePath: !!this.props.resource?.data?.Contact?.ImagePath
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.User
        }))
    }

    handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(this.state.fields, name, value)

        this.setState({
            canSubmit: Object.values(newFields).reduce((memo, it) => {
                return memo || (getProp(this.props, 'resource.data.Contact.' + it.name) !== it.value)
            }, this.state.files.length > 0), fields: newFields
        })
    }

    handleNotificationSoundSubmit = () => {
        const fields = this.state.fields
        fields.NotificationSound.value = this.state.notificationPreviewSoundFields.NotificationSound.value
        fields.NotificationSoundMute.value = this.state.notificationPreviewSoundFields.NotificationSoundMute.value
        this.setState({fields, notificationDialog: false, canSubmit: true})
    }

    handleNotificationPreviewSoundInputChange = (name, value) => {
        this.setState({
            notificationPreviewSoundFields: FieldsManager.updateField(this.state.notificationPreviewSoundFields, name, value),
            notificationPreviewSoundCanSubmit: true
        })
    }

    handleToggleNotificationDialog = () => {
        this.setState({
            notificationDialog: !this.state.notificationDialog,
            notificationPreviewSoundFields: this.getNotificationSoundPreviewFields()
        })
    }

    handleCheckboxChange = (i, j, name, value) => {
        let fields = this.state.NotificationSetting[i][j]
        const newItems = this.state.NotificationSetting
        newItems[i][j] = FieldsManager.updateField(fields, name, value)
        this.setState({NotificationSetting: newItems, canSubmit: true})
    }

    submit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        // destruction multi dimensional array to a single array
                        const NotificationSettingArray = []

                        this.state.NotificationSetting.forEach((arrays) => arrays.forEach((item) => NotificationSettingArray.push(FieldsManager.getFieldKeyValues(item))))
                        let params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {NotificationSetting: NotificationSettingArray});

                        if (!this.state.hasImagePath) {
                            params.imagePath = 1;
                        }

                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            fileParams: {
                                id: getProp(this.props, 'resource.data.Contact.ContactID')
                            },
                            file: !!this.fileImageObject && [this.fileImageObject],
                            resource: Resources.User,
                            piggyResource: Resources.User,
                            fileResource: Resources.ContactImage,
                            onPiggyCallback: (result) => {
                                if (result && result.status === 0) {
                                    LocalStorage.set('user', Object.assign(LocalStorage.get('user'), result.data))
                                }
                                window.location.reload()
                            }
                        }))
                    })
                }
            })
        }
    }

    cancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(),
                NotificationSetting: this.getNotificationSetting(),
                src: null,
                croppedImageUrl: null,
                isPasswordVisible: false
            })
        }
    }

    getFields = () => {
        return {
            FirstName: new Field('FirstName', getProp(this.props, 'resource.data.Contact.FirstName', ''), ['empty']),
            LastName: new Field('LastName', getProp(this.props, 'resource.data.Contact.LastName', ''), ['empty']),
            Timezone: new Field('Timezone', getProp(this.props, 'resource.data.Contact.Timezone', ''), ['empty']),
            DateTimeFormat: new Field('DateTimeFormat', getProp(this.props, 'resource.data.Contact.DateTimeFormat', ''), ['empty']),
            MeasureTemperatureID: new Field('MeasureTemperatureID', getProp(this.props, 'resource.data.Contact.MeasureTemperatureID', ''), ['empty']),
            MeasureUnitID: new Field('MeasureUnitID', getProp(this.props, 'resource.data.Contact.MeasureUnitID', ''), ['empty']),
            WeightUnitID: new Field('WeightUnitID', getProp(this.props, 'resource.data.Contact.WeightUnitID', ''), ['empty']),
            DistanceUnitID: new Field('DistanceUnitID', getProp(this.props, 'resource.data.Contact.DistanceUnitID', ''), ['empty']),
            NotificationSound: new Field('NotificationSound', getProp(this.props, 'resource.data.Contact.NotificationSound', ''), ['empty']),
            NotificationSoundMute: new Field('NotificationSoundMute', getProp(this.props, 'resource.data.Contact.NotificationSoundMute', ''), [''], false, 'checkbox'),

            SMTPEmail: new Field('SMTPEmail', getProp(this.props, 'resource.data.Contact.SMTPEmail', ''), []),
            SMTPPassword: new Field('SMTPPassword', getProp(this.props, 'resource.data.Contact.SMTPPassword', ''), []),
        }
    }

    getNotificationSoundPreviewFields = () => {
        return {
            NotificationSound: new Field('NotificationSound', this.state.fields.NotificationSound.value, ['empty']),
            NotificationSoundMute: new Field('NotificationSoundMute', this.state.fields.NotificationSoundMute.value, [''], false, 'checkbox'),
        }
    }

    getNotificationSetting = () => {
        const NotificationSetting = getProp(this.props.resource, 'data.NotificationSetting', [])
        const NotificationSettingFields = []
        NotificationSetting.forEach((array) => {
            const secondDimensionArray = []
            array.forEach((item) => {
                secondDimensionArray.push({
                    NotificationChannel: new Field('NotificationChannel', item.NotificationChannel, ['']),
                    NotificationChannelID: new Field('NotificationChannelID', item.NotificationChannelID, ['']),
                    NotificationLevel: new Field('NotificationLevel', item.NotificationLevel, ['']),
                    NotificationLevelID: new Field('NotificationLevelID', item.NotificationLevelID, ['']),
                    Value: new Field('Value', item.Value, [], false, 'checkbox')
                })
            })
            NotificationSettingFields.push(secondDimensionArray)
        })
        return NotificationSettingFields
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        })
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        })
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        })
    }

    onSelectFile = acceptedFiles => {
        if (acceptedFiles) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    canSubmit: true,
                    isCropVisible: true
                })
            )
            reader.readAsDataURL(acceptedFiles[0])
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop)
    }

    onCropChange = (crop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop})
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.setState({croppedImageUrl})
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty')
                    return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                this.fileImageObject = new File([blob], 'profile-image.png')
                resolve(this.fileUrl)
            }, 'image/png')
        })
    }

    setImage = (file) => {
        this.fileImageObject = file
        this.setState({canSubmit: true})
    }

    handleRemoveAvatarClick = () => {
        this.setState({
            hasImagePath: null
        })
    }

    render() {
        const {translate, resource} = this.props
        const {hasImagePath} = this.state

        const NotificationChannel = Object.values(getLookup('NotificationChannel', 'NotificationChannelID', 'NotificationChannel'))
        NotificationChannel.unshift('')
        const NotificationLevel = Object.values(getLookup('NotificationLevel', 'NotificationLevelID', 'NotificationLevel'))

        return (
            <Layout {...this.props} hasFooter isDirty={this.state.canSubmit}>
                <Page>
                    <h1 className="mr-5 text-3xl mb-3">{translate('text.profile')}</h1>
                    {!this.props.resource.isLoading && (
                        <React.Fragment>
                            <div className="grid grid-cols-12 gap-4 pb-8">
                                <div className="col-span-full xl:col-span-8">
                                    <Card addClass="h-full">
                                        <h5 className="text-lg mb-2 text-tm-gray-500">{translate('text.profile_info')}</h5>

                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.FirstName}
                                                    translate={translate}
                                                >
                                                    <FieldText
                                                        autoFocus
                                                        className="form-control"
                                                        onChange={this.handleInputChange} {...this.state.fields.FirstName}
                                                        addClass={'form-control'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.LastName}
                                                    translate={translate}
                                                >
                                                    <FieldText
                                                        className="form-control"
                                                        onChange={this.handleInputChange} {...this.state.fields.LastName}
                                                        addClass={'form-control'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.Timezone}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        omitSort
                                                        className="form-control select-css-search"
                                                        values={timezone}
                                                        onChange={this.handleInputChange} {...this.state.fields.Timezone}
                                                        addClass={'form-control select-css-search'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.DateTimeFormat}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        className="form-control select-css-search"
                                                        values={dateTimeFormat}
                                                        onChange={this.handleInputChange} {...this.state.fields.DateTimeFormat}
                                                        addClass={'form-control select-css-search'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.MeasureUnitID}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        className="form-control select-css-search"
                                                        values={this.measureUnit}
                                                        onChange={this.handleInputChange} {...this.state.fields.MeasureUnitID}
                                                        addClass={'form-control select-css-search'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.WeightUnitID}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        className="form-control select-css-search"
                                                        values={getTranslatedLookup('WeightUnit', translate)}
                                                        onChange={this.handleInputChange} {...this.state.fields.WeightUnitID}
                                                        addClass={'form-control select-css-search'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.DistanceUnitID}
                                                    label={translate('field.distanceUnit')}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        className="form-control select-css-search"
                                                        values={getTranslatedLookup('DistanceUnit', translate)}
                                                        onChange={this.handleInputChange} {...this.state.fields.DistanceUnitID}
                                                        addClass={'form-control select-css-search'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full sm:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.MeasureTemperatureID}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        className="form-control select-css-search"
                                                        values={getLookup('MeasureTemperature')}
                                                        onChange={this.handleInputChange} {...this.state.fields.MeasureTemperatureID}
                                                        addClass={'form-control select-css-search'}/>
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full">
                                                <div className={'flex justify-between items-center'}>
                                                    <h5 className="text-lg mb-2 text-tm-gray-500">
                                                        Notification Sound
                                                    </h5>
                                                    <button
                                                        onClick={this.handleToggleNotificationDialog}
                                                        className="flex justify-between items-center btn btn-primary ml-5 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                    >
                                                        {translate('text.EDIT')} {this.state.fields.NotificationSoundMute.value ?
                                                        <SpeakerXMarkIcon className={'h-4 w-4 ml-3'}/>
                                                        : <SpeakerWaveIcon className={'h-4 w-4 ml-3'}/>}
                                                    </button>
                                                </div>
                                                <h5 className="text-md mb-2 text-tm-gray-500">Sound: &aposNotification
                                                    Sound {this.state.fields.NotificationSound.value}&apos</h5>
                                                <AudioPlayer
                                                    src={`/audio/${this.state.fields.NotificationSound.value}.mp3`}
                                                    showJumpControls={false}
                                                    autoPlayAfterSrcChange={false}
                                                    className={'bg-tm-gray-100'}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="col-span-full xl:col-span-4">
                                    <Card addClass="h-full">
                                        <h5 className="text-lg mb-2 text-tm-gray-500">{translate('text.profile_image')}</h5>

                                        <InfoBar addClass="text-sm"
                                                 message="Please use image that is at least 85px wide and 85px high"/>

                                        <ContactPhoto
                                            imageID={hasImagePath ? resource?.data?.Contact?.ContactID : null}
                                            lastUpdated={resource?.data?.Contact?.CreateUpdateDate ?? ""}
                                            hasImage={hasImagePath}
                                            stateChangerImagePath={(item) => this.setState({hasImagePath: item})}
                                            stateChangerIsDirty={(item) => this.setState({canSubmit: item})}
                                            onGetImage={(file) => this.setImage(file)}
                                            avatarSet={() => this.handleRemoveAvatarClick()}
                                            translate={translate}
                                        />
                                    </Card>
                                </div>


                                <div className="col-span-full lg:col-span-6">
                                    <Card addClass="h-full">
                                        <h5
                                            className="text-lg mb-2 text-tm-gray-500">{translate('text.profile_notification_setting')}</h5>

                                        <div className="h-full text-center">
                                            <div className="">
                                                <div className="grid grid-cols-6 gap-2">
                                                    {NotificationChannel.map((channel, i) => (
                                                        <div key={i} className="col mb-3">
                                                            {channel && translate('text.' + channel.replace(/\s/g, ''))}
                                                        </div>
                                                    ))}
                                                </div>
                                                {this.state.NotificationSetting.length > 0 && this.state.NotificationSetting.map((row, i) => (
                                                    <div className="grid grid-cols-6 gap-2" key={i}>
                                                        <div className="mb-3 text-left">
                                                            {translate('text.' + NotificationLevel[i])}
                                                        </div>
                                                        {row.map((col, j) => (
                                                                <div key={i}
                                                                     className="h-10 flex flex-center justify-center">

                                                                    <FieldCheckbox
                                                                        className="checkbox"
                                                                        onChange={(name, value) => this.handleCheckboxChange(i, j, name, value)}
                                                                        {...this.state.NotificationSetting[i][j].Value}
                                                                    />
                                                                    <label className="ml-2 text-sm select-none"/>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="col-span-full lg:col-span-6">
                                    <Card addClass="h-full">
                                        <h5
                                            className="text-lg mb-2 text-tm-gray-500">{translate('text.email_smtp')}</h5>

                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-full md:col-span-6 lg:col-span-full xl:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.SMTPEmail}
                                                    translate={translate}
                                                >
                                                    <FieldText
                                                        className="form-control"
                                                        onChange={this.handleInputChange} {...this.state.fields.SMTPEmail}
                                                        addClass={'form-control'}
                                                    />
                                                </FieldContainer>
                                            </div>

                                            <div className="col-span-full md:col-span-6 lg:col-span-full xl:col-span-6">
                                                <FieldContainer
                                                    item={this.state.fields.SMTPPassword}
                                                    translate={translate}
                                                >
                                                    <FieldText
                                                        className="form-control"
                                                        onChange={this.handleInputChange} {...this.state.fields.SMTPPassword}
                                                        addClass={'form-control'}
                                                    />
                                                </FieldContainer>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <PageFooter
                                canSubmit={this.state.canSubmit}
                                actionCancel={this.cancel}
                                actionSubmit={checkPerm(Resources.User, UPDATE_PERM) && this.submit}
                                translate={translate}
                            />

                        </React.Fragment>
                    )}

                    {this.props.resource.isLoading && (
                        <TableCard>
                            <div className="p-10 text-center">
                                <Loader/>
                            </div>
                        </TableCard>
                    )}
                </Page>

                <ModalDefault
                    show={this.state.notificationDialog}
                    widthClass={'max-w-xl'}
                    title={translate('text.NotificationSoundPreview')}

                    onButtonClick={this.handleNotificationSoundSubmit}
                    buttonLabel={translate('btn.update')}
                    buttonDisabled={!this.state.notificationPreviewSoundCanSubmit}

                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.handleToggleNotificationDialog}
                >
                    {this.state.notificationDialog && (
                        <div className="row p-4">
                            <div className="col-md-12">
                                <label className="form-group has-float-label mb-4">
                                    <FieldSelectSearch
                                        className="form-control"
                                        values={{
                                            1: 'Notification Sound 1',
                                            2: 'Notification Sound 2',
                                            3: 'Notification Sound 3',
                                            4: 'Notification Sound 4',
                                            5: 'Notification Sound 5',
                                            6: 'Notification Sound 6',
                                            7: 'Notification Sound 7',
                                            8: 'Notification Sound 8',
                                            9: 'Notification Sound 9',
                                            10: 'Notification Sound 10',
                                        }}
                                        onChange={this.handleNotificationPreviewSoundInputChange}
                                        valueSort
                                        all
                                        {...this.state.notificationPreviewSoundFields.NotificationSound}
                                        placeholder={translate('text.select')}
                                        addClass={'form-control'}
                                    />
                                    <span>{translate('field.NotificationSound')} *</span>
                                </label>
                            </div>
                            <div className="col-md-12">
                                <label
                                    className="mb-0 select-none p-2 flex items-center cursor-pointer hover:bg-tm-gray-100 rounded-md"
                                    htmlFor={'NotificationSoundMute'}>

                                    <FieldCheckbox
                                        id={'NotificationSoundMute'}
                                        className={classNames('checkbox', this.state.notificationPreviewSoundFields.NotificationSoundMute ? 'border-primary' : 'border-tm-gray-200')}
                                        onChange={this.handleNotificationPreviewSoundInputChange}
                                        {...this.state.notificationPreviewSoundFields.NotificationSoundMute}
                                    />

                                    <span
                                        className="ml-2 flex flex-col text-sm font-semibold text-tm-gray-900">
                                {translate('field.NotificationSoundMute')}
                            </span>
                                </label>
                            </div>
                            <div className="col-md-12">
                                <h5 className="text-lg mb-2 text-tm-gray-500">
                                    {translate('text.Preview')}
                                </h5>
                                <AudioPlayer
                                    src={`/audio/${this.state.notificationPreviewSoundFields.NotificationSound.value}.mp3`}
                                    showJumpControls={false}
                                    autoPlayAfterSrcChange={false}
                                    className={'bg-tm-gray-50'}
                                />
                            </div>
                        </div>
                    )}
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(Profiles)
