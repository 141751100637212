import React, {Component} from 'react'
import {fillFieldsFromData} from '../../../../common/util/util-fields'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import LocalStorage from '../../../../util/localStorage'
import {getResource, updateResource} from '../../../../data/actions/resource'
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";
import BillingSection from './billing-section'
import LoadProgressButtons from './load-progress-buttons'
import {UPDATE_PERM} from '../../../../util/util-constants'
import {checkPerm, getDocumentTypesFor, getLookup, getProp} from '../../../../common/util/util-helpers'
import {emailValid} from '../../../../common/util/util-vanilla'
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import axios from "axios";
import Env from "../../../../util/env";
import {processResponse} from "../../../../data/services/api-util";
import {LoaderSmall} from "../../../../common/components/loader";
import FieldTextarea from "../../../../common/components/fields/field-textarea";
import CardSubtitle from "../../../../common/components/card/card-sub-title";
import FieldDropdownSelect from "../../../../common/components/fields/field-dropdown-select";
import FieldCheckbox from "../../../../common/components/fields/field-checkbox";
import Card from "../../../../common/components/card";
import FieldContainer from "../../../../common/components/fields/field-container";
import {getJWT} from "../../../../common/util/util-auth";

export default class SettingsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            creditFields: this.getCreditFields(),
            LoadInstructions: [{
                Active: new Field('Active', '', ['empty']),
                CreateTask: new Field('CreateTask', '', ['empty']),
                Instruction: new Field('Instruction', '', ['empty']),
                TaskGroupID: new Field('TaskGroupID', '', ['']),
                'Type': '0'
            }],
            isDirty: false,
            activeTab: 1,
            footerIsSticky: true,
            CustomerSettingsDocuments: {}
        }
        this.loadStatusList = getLookup('LoadStatus', 'LoadStatusID', 'LoadStatus')
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                creditFields: this.getCreditFields(),
                CustomerSettingsDocuments: this.getDocumentsFields(),
                LoadInstructions: this.getLoadInstructions()
            })
        }
        if (prevState.isDirty !== this.state.isDirty) {
            this.props.setIsStateDirty(this.state.isDirty)
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.CustomersSettings,
            query: {
                id: this.props.match.params.CustomerID
            }
        }))
    }

    checkLoadInstructionsForErrors = () => {
        let hasErrors = false;
        let errorsData = this.state.LoadInstructions
        errorsData.reduce((memo, it) => {
            if (!!it.TaskGroupID.errorMessage) {
                hasErrors = true
            }
            return memo;
        })
        return hasErrors
    }
    onSubmit = () => {
        if (this.state.isDirty) {
            this.setState({
                creditFields: FieldsManager.validateFields(this.state.creditFields)
                , LoadInstructions: this.state.LoadInstructions.reduce((memo, it) => {
                    memo.push(FieldsManager.validateFields(it, ['TaskGroupID']))
                    return memo
                }, [])
            }, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.creditFields) && FieldsManager.checkFieldsForErrors(this.state.LoadInstructions[0]) && !this.checkLoadInstructionsForErrors()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.creditFields), {
                            id: this.props.match.params.CustomerID,
                            LoadSettings: this.state.LoadInstructions.map(setting => {
                                const item = {}
                                item.Active = setting.Active.value ? 1 : 0
                                item.Instruction = setting.Instruction.value
                                item.TaskGroupID = setting.TaskGroupID.value?.value
                                item.TaskGroup = setting.TaskGroupID.value?.label
                                item.CreateTask = setting.CreateTask.value ? 1 : 0
                                item.Type = setting.Type
                                return item
                            })
                        }),
                        query: {
                            id: this.props.match.params.CustomerID,
                        },
                        errorMessage: true, successMessage: this.props.translate('text.CustomerSettingsUpdated'),
                        resource: Resources.CustomersSettings,
                        piggyResource: Resources.CustomersSettings
                    }))
                    this.setState({isDirty: false})
                }
            })
        }
    }

    /** UI events
     ================================================================= */
    handleFormCancel = () => {
        this.setState({
            creditFields: this.getCreditFields(),
            LoadInstructions: this.getLoadInstructions(),
            CustomerSettingsDocuments: this.getDocumentsFields(),
            isDirty: false
        })
    }

    handleStatusClick = (i) => {
        this.setState({
            activeTab: i
        })
    }

    handleCreditInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.creditFields, name, value)

        if (name === 'Bond') {
            fields['Expires'].disabled = !value
            fields['Number'].disabled = !value
            fields['Bank'].disabled = !value
        }

        if (name === 'IsDocumentsOverridden') {
            fields['DocsSettings'].disabled = !value
        }

        if (name === 'Email') {
            const hasInvalidEmail = fields.Email.value && fields.Email.value.some(it => !emailValid(it.value))
            this.setState({
                hasInvalidEmail: hasInvalidEmail,
            })
        }

        this.setState({
            creditFields: fields,
            isDirty: true
        })
    }

    handleLoadInputChange = (name, value, i) => {
        let fields = this.state.LoadInstructions[i]

        const newFields = this.state.LoadInstructions

        if ('CreateTask' === name) {
            fields.TaskGroupID.validate = Number(value) === 1 ? ['empty'] : ['']
            fields.TaskGroupID.disabled = Number(value) === 1 ? false : true
            if (Number(value) === 0) {
                fields.TaskGroupID.value = null
            }
        }
        newFields[i] = FieldsManager.updateField(fields, name, value)

        this.setState({
            LoadInstructions: newFields,
            isDirty: true
        })
    }

    /** Fields/data definitions
     ================================================================= */
    getDocumentsFields = () => {
        const DocumentTypes = getDocumentTypesFor("IsDispatchDocument")
        const requiredDocs = getProp(this.props.resource, 'data.customers/settings/docs', [])

        return Object.keys(DocumentTypes).reduce((memo, it) => {
            memo[it] = {
                name: DocumentTypes[it],
                isChecked: requiredDocs.includes(+it)
            }
            return memo
        }, {})
    }

    getCreditFields = () => {
        let info = getProp(this.props.resource, 'data.' + Resources.CustomersSettingsCredit, {})

        const documentFields = this.getDocumentsFields()
        const docs = Object.keys(documentFields).reduce((memo, item) => {
            memo[item] = documentFields[item].name
            return memo
        }, {})

        let fieldTemplates = {
            // Profit Margin
            /*
            MinimumMargin: new Field('MinimumMargin', '', [''], false, 'float'),
            AllowMarginOverride: new Field('AllowMarginOverride', '', [''], false, 'checkbox'),
             */

            // Bonds
            Bond: new Field('Bond', '', [''], false, 'checkbox'),
            Expires: new Field('Expires', '', [''], true, 'datetimezone'),
            Number: new Field('Number', '', [''], true, ''),
            Bank: new Field('Bank', '', [''], true, 'textarea'),

            // Documents
            IsSendBackupDocumentsWithInvoice: new Field('IsSendBackupDocumentsWithInvoice', '', [''], false, 'checkbox'),
            IsDocumentsOverridden: new Field('IsDocumentsOverridden', '', [''], false, 'checkbox'),
            DocsSettings: new Field('DocsSettings', [], [''], true, 'multi-select', {}, {
                values: docs
            }),

            Email: new Field('Email', '', [''], false, 'email'),
            PreferredBillingMethodID: new Field('PreferredBillingMethodID', '', [''], false, 'select'),
            PreferredBillingNotes: new Field('PreferredBillingNotes', '', [''], false, 'textarea'),

            IncomeAccountID: new Field('IncomeAccountID', '', [''], false, 'select-search'),
            ExpenseAccountID: new Field('ExpenseAccountID', '', [''], false, 'select-search'),
            PaymentAccountID: new Field('PaymentAccountID', '', [''], false, 'select-search'),

            NetTermTypeID: new Field('NetTermTypeID', '', [''], false, 'select'),

            FactoringLegalName: new Field('FactoringLegalName', '', [''], false, ''),
            FactoringAddressName: new Field('FactoringAddressName', '', [''], false, ''),
            FactoringCityName: new Field('FactoringCityName', '', [''], false, ''),
            FactoringStateID: new Field('FactoringStateID', '', [''], false, ''),
            FactoringPostalCode: new Field('FactoringPostalCode', '', [''], false, ''),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, info)

        if (getProp(this.props.resource, 'data.customers/settings/docs', []).length) {
            const DocumentType = getLookup('DocumentType')
            fieldTemplates['DocsSettings'].value = getProp(this.props.resource, 'data.customers/settings/docs').map(it => {
                return {
                    value: it,
                    label: DocumentType[it],
                }
            }, {})
        }

        if (!!info?.Bond) {
            fieldTemplates['Expires'].disabled = false
            fieldTemplates['Number'].disabled = false
            fieldTemplates['Bank'].disabled = false
        }

        if (!!info?.IsDocumentsOverridden) {
            fieldTemplates['DocsSettings'].disabled = false
        }

        return fieldTemplates
    }

    getLoadInstructions = () => {
        let LoadStatuses = []

        const LoadFields = getProp(this.props.resource, 'data.' + Resources.CustomersSettingsLoad + '.list', [])
            .reduce((memo, it) => {
                memo[it.Type ?? 0] = {
                    Active: it.Active,
                    CreateTask: it.CreateTask,
                    Instruction: it.Instruction,
                    TaskGroupID: it.TaskGroupID,
                    TaskGroup: it.TaskGroup
                }
                return memo
            }, {})

        LoadStatuses.push({
            Active: new Field('Active', LoadFields[0] ? LoadFields[0].Active : '', ['empty']),
            CreateTask: new Field('CreateTask', LoadFields[0] ? LoadFields[0].CreateTask : '', ['empty']),
            Instruction: new Field('Instruction', LoadFields[0] ? LoadFields[0].Instruction : '', ['empty']),
            TaskGroupID: new Field('TaskGroupID', (LoadFields[0]?.TaskGroupID ? {
                value: LoadFields[0] ? LoadFields[0].TaskGroupID : '',
                label: LoadFields[0] ? LoadFields[0].TaskGroup : ''
            } : null), Number(LoadFields[0]?.CreateTask) === 1 ? ['empty'] : [''], Number(LoadFields[0]?.CreateTask) === 1 ? false : true, 'select-search'),
            'Type': '0'
        })

        for (let [key, value] of Object.entries(this.loadStatusList)) {
            LoadStatuses.push({
                Active: new Field('Active', LoadFields[key] ? LoadFields[key].Active : '', ['empty']),
                CreateTask: new Field('CreateTask', LoadFields[key] ? LoadFields[key].CreateTask : '', ['empty']),
                Instruction: new Field('Instruction', LoadFields[key] ? LoadFields[key].Instruction : '', ['empty']),
                TaskGroupID: new Field('TaskGroupID', (LoadFields[key]?.TaskGroupID ? {
                    value: LoadFields[key] ? LoadFields[key].TaskGroupID : '',
                    label: LoadFields[key] ? LoadFields[key].TaskGroup : ''
                } : null), Number(LoadFields[key]?.CreateTask) === 1 ? ['empty'] : [''], Number(LoadFields[key]?.CreateTask) === 1 ? false : true),
                'Type': key,
                'Name': value
            })
        }
        return LoadStatuses
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        return (
            <div className="pb-20">
                <Card className="bg-inverse rounded-card border-tm-gray-300 border shadow-card mb-4">
                    {!!resource.isLoading && (
                        <div className="w-full text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!resource.isLoading && (
                        <div>
                            <BillingSection
                                handleInputChange={this.handleCreditInputChange}
                                fields={this.state.creditFields}
                                hasInvalidEmail={!!this.state.hasInvalidEmail}
                                translate={translate}
                                OrganizationID={this.props.match.params.OrganizationID}
                            />
                        </div>
                    )}
                </Card>

                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-full 2xl:col-span-4">
                        <Card addClass={'h-full'}>
                            <CardSubtitle
                                subtitle={translate('text.GeneralLoadInstructions')}
                            />

                            <div className="form-group relative">
                                <label>Instruction</label>
                                <FieldTextarea
                                    rows={8}
                                    id={'textInput'}
                                    addClass="form-control whitespace-pre-wrap"
                                    onChange={(name, value) => this.handleLoadInputChange(name, value, 0)}
                                    {...this.state.LoadInstructions[0].Instruction}
                                    placeholder=""
                                />
                            </div>

                            <div className="form-group relative">
                                <label
                                    className="h-10 flex items-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                                    <FieldCheckbox
                                        className="checkbox"
                                        onChange={(name, value) => this.handleLoadInputChange(name, value, 0)}
                                        {...this.state.LoadInstructions[0].Active}
                                    />

                                    <span className="ml-2 text-sm font-semibold select-none">
                                        Active
                                    </span>
                                </label>

                                <label
                                    className="h-10 flex items-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                                    <FieldCheckbox
                                        className="checkbox"
                                        onChange={(name, value) => this.handleLoadInputChange(name, value, 0)}
                                        {...this.state.LoadInstructions[0].CreateTask}
                                    />

                                    <span className="ml-2 text-sm font-semibold select-none">
                                        Create task
                                    </span>
                                </label>

                                <FieldContainer
                                    item={this.state.LoadInstructions[0].TaskGroupID}
                                    translate={translate}
                                >
                                    <FieldDropdownSelect
                                        {...this.state.LoadInstructions[0].TaskGroupID}
                                        onChange={(name, value) => this.handleLoadInputChange(name, value, 0)}
                                        addClass="form-control"
                                        defaultOptions={true}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                axios.get(
                                                    Env.getApiUrl('api/' + Resources.BoardTasks, {
                                                        query: inputValue,
                                                        IsSystemUser: 1
                                                    }),
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + getJWT().access_token
                                                        }
                                                    }
                                                )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((it) => {
                                                                return {
                                                                    label: it.TaskGroupName,
                                                                    value: it.TaskGroupID
                                                                };
                                                            });

                                                            callback(list);
                                                        }
                                                    })
                                            }
                                        }
                                    />

                                </FieldContainer>

                            </div>
                        </Card>
                    </div>

                    <div className="col-span-full 2xl:col-span-8">
                        <LoadProgressButtons
                            addClass="mb-3"
                            loadStatusList={this.loadStatusList}
                            translate={translate}
                            clickable
                            activeStatus={this.state.activeTab}
                            onSetActive={this.handleStatusClick}
                            loadData={this.state.LoadInstructions}
                            LoadInstructions={this.state.LoadInstructions}
                            activeTab={this.state.activeTab}
                            handleLoadInputChange={this.handleLoadInputChange}
                        />
                    </div>
                </div>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.isDirty}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(Resources.CustomersSettings, UPDATE_PERM) && this.onSubmit}
                />
            </div>
        )
    }
}
