/**
 * Persistent storage
 */
import {cloneDeep} from "./util-vanilla";
import {JSONParseFix} from "./util-helpers";

export default class LocalStorage {
    /**
     * @param key
     * @param def
     */
    static get(key, def = undefined) {
        if (LocalStorage.has(key)) {
            try {
                return JSON.parse(localStorage.getItem(key));
            } catch (err) {
                console.log('error:', err)
            }
        }
        return def;
    }

    /**
     *
     * @param key
     * @param def
     * @param callback
     * @returns def
     */
    static getAndSave(key, def = {}, callback) {
        let value = def;
        if (LocalStorage.has(key)) {
            value = LocalStorage.get(key);
        }

        LocalStorage.set(key, callback(value));
    }

    /**
     * @param key
     * @param value
     */
    static set(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            // pass
        }
    }

    /**
     * @param key
     * @returns {boolean}
     */
    static has(key) {
        return localStorage.getItem(key) !== null;
    }

    /**
     * @param key
     */
    static remove(key) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }

    static clearAllExcept(keepThem) {
        for (let itemKey in localStorage) {
            if (!keepThem.includes(itemKey)) {
                LocalStorage.remove(itemKey);
            }
        }
    }

    static getAndRemove(key) {
        const value = JSON.parse(localStorage.getItem(key));
        LocalStorage.remove(key);
        return value;
    }

    static persistState(key, state, keys) {
        const stateToPersist = {};
        const stateUpdate = cloneDeep(state);
        if (state.queryFilterFields) {
            keys.push("queryFilterFields");
        }

        keys.forEach((it) => {
            if (it === 'queryFilterFields' && !!stateUpdate['queryFilterFields']?.offset) {
                delete stateUpdate['queryFilterFields'].offset;
            }

            if (it !== 'offset') {
                stateToPersist[it] = stateUpdate[it];
            }
        });

        LocalStorage.set(key + '_state', JSON.stringify(stateToPersist)) // Potential bug, stringify already inside a function
    }

    static rehydrateState(key) {
        if (LocalStorage.has(key + '_state')) {
            return JSONParseFix(LocalStorage.get(key + '_state'));
        }
    }

    static persistFilters(key, filterState) {
        const stateToPersist = {};

        Object.values(filterState).forEach((it) => {
            stateToPersist[it.name] = it.value;
        });

        LocalStorage.set(key + '_filters', JSON.stringify(stateToPersist))
    }

    static rehydrateFilters(key) {
        if (LocalStorage.has(key + '_filters')) {
            return JSON.parse(LocalStorage.get(key + '_filters'));
        }
    }
}
