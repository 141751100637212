import React, {Component} from "react";
import MaskedInput from "react-text-mask";
import {scrollIntoView} from "../../../util/util-vanilla";
import {handleMaskedFocus} from "../../../util/util-helpers";

export default class FieldTextMask extends Component {
    render() {
        const input = (
            <MaskedInput
                id={this.props.id}
                ref={this.props.innerRef}
                mask={this.props.mask}
                showMask={this.props.showMask}
                type={this.props.type}
                autoFocus={this.props.autoFocus}
                onChange={(event) => {
                    this.props.onChange(this.props.name, event.target.value);
                }}
                onFocus={(event) => {
                    if (this.props.onFocus) {
                        this.props.onFocus(event);
                    } else {
                        handleMaskedFocus(event);
                    }

                    scrollIntoView(event);
                }}
                onKeyDown={this.props.onKeyDown}
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                className={this.props.className + (this.props.errorMessage ? " is-invalid" : "") + (this.props.disabled ? " disabled" : "")}
                min={this.props.min}
                maxLength={this.props.max}
            />);

        return (
            <React.Fragment>
                {!!this.props.clear && (
                    <div className="clear-position">
                        <svg onClick={() => this.props.clear()}>
                            <path
                                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"/>
                        </svg>

                        {input}
                    </div>
                )}

                {!this.props.clear && (
                    input
                )}

                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold"}>
                        {!!this.props.translate && (
                            this.props.errorMessage.reduce((memo, it) => {
                                return this.props.translate(it) + (!!memo ? "," + memo : "")
                            }, "")
                        )}
                        {!this.props.translate && (
                            <span>Field required*</span>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
