import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";
import React from "react";
import moment from "moment";
import {classNames} from "../../../util/util-helpers";
import {getUserTimeFormat} from "../../../util/util-dates";
import {WORKING_HOURS_NON_STOP} from "../../../../util/util-constants";

export default function WorkingHoursDisplay({locationData, translate}) {
    let days;
    const ShippingHours = locationData.ShippingHours;
    const ReceivingHours = locationData.ReceivingHours;
    const areShippingHoursNonStop = locationData.CustomShippingHours === WORKING_HOURS_NON_STOP;
    const areReceivingHoursHoursNonStop = locationData.CustomReceivingHours === WORKING_HOURS_NON_STOP;

    if (ShippingHours?.length || ReceivingHours?.length) {
        days = ShippingHours ?? ReceivingHours;
        days = days.map(it => it.Day);
    } else {
        days = []
    }

    return (
        <div className={classNames(
            "grid divide-x divide-tm-gray-200",
                !!days.length ? "grid-cols-5" : "grid-cols-4"
        )}>
            {!!days.length && (
                <div className="bg-inverse flex items-center flex-col divide-y divide-tm-gray-300">
                    <div className="px-3 font-bold text-tm-gray-700 grow w-full flex items-center h-10">
                        {translate("text.day")}
                    </div>

                    {days.map(it => (
                        <div className="px-3 grow w-full flex items-center h-10">
                            {translate("text." + it)}
                        </div>
                    ))}
                </div>
            )}

            {!!ShippingHours?.length && (
                <WorkingHoursColumn
                    colTitle={translate("text.shipping_hours")}
                    hours={ShippingHours}
                    isNonStop={areShippingHoursNonStop}
                    translate={translate}
                />
            )}

            {!!ReceivingHours?.length && (
                <WorkingHoursColumn
                    colTitle={translate("text.receiving_hours")}
                    hours={ReceivingHours}
                    isNonStop={areReceivingHoursHoursNonStop}
                    translate={translate}
                />
            )}

            {!ShippingHours?.length && (
                <div className="border-tm-gray-200 px-3 flex items-center justify-center flex-col col-span-2 bg-inverse">
                    <div className="text-center py-10">
                        <QuestionMarkCircleIcon className="w-20 h-20 text-tm-gray-300 inline-block" />
                        <p className="font-bold text-tm-gray-00 text-xs">Shipping hours not set</p>
                    </div>
                </div>
            )}

            {!ReceivingHours?.length && (
                <div className="border-tm-gray-200 px-3 flex items-center justify-center flex-col col-span-2 bg-inverse">
                    <div className="text-center py-10">
                        <QuestionMarkCircleIcon className="w-20 h-20 text-tm-gray-300 inline-block" />
                        <p className="font-bold text-tm-gray-00 text-xs">Receiving hours not set</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export const WorkingHoursColumn = ({colTitle, hours, isNonStop, translate}) => {
    return (
        <div className="bg-inverse flex items-center flex-col divide-y divide-tm-gray-300 col-span-2">
            <div className="px-3 font-bold text-tm-gray-700 grow w-full flex justify-center items-center h-10">
                {colTitle}
            </div>
            {hours.map(it => {
                const fromTime = moment(it.FromTime, "HH:mm:ss").format(getUserTimeFormat());
                const toTime = moment(it.ToTime, "HH:mm:ss").format(getUserTimeFormat());
                it.data = fromTime + " - " + toTime;

                if (!it.Active) {
                    it.data = translate("text.closed")
                    it.addTextClass = "text-sm font-medium text-red-600 uppercase"
                }

                if (isNonStop || it.NonStop === 1) {
                    it.data = translate("text.non_stop")
                    it.addTextClass = "text-green-600 uppercase"
                }

                return (
                    <div
                        key={it.Day}
                        className="px-3 grow w-full flex justify-center items-center h-10"
                    >
                        <div>
                            <p className={classNames(
                                "font-medium leading-5",
                                it.addTextClass
                            )}>
                                {it.data}
                            </p>

                            {/*TODO: Update view for mobile*/}

                            {/*<p className="text-tm-gray-500 text-xs leading-4 hidden">Mon</p>*/}
                        </div>
                    </div>
                )}
            )}
        </div>
    )
}
