import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import ModalSaveResource from "../modal-save-resource";
import NoRecords from "../../no-records-found/no-records";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import ResourceTable from "../../resource-table";
import {checkPerm, getProp, groupListBySCAC} from "../../../util/util-helpers";
import Pagination from "../../resource-table/table-components/pagination";
import ModalConfirm from "../modal-confirm";
import Resources from "../../../../data/services/resources";
import {CREATE_PERM, DEFAULT_CRUD_STATE, READ_PERM} from "../../../../util/util-constants";
import {createDialogResource, getDialogResource} from "../../../../data/actions/dialogResource";
import {showGlobalModal} from "../../../../data/actions/ui";
import {Field} from "../../../../data/services/fields";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";

export default class TrailersTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "TrailerNumber",
            fields: this.getFields(),

            // Create/Edit modal
            createModalShow: false,
            createModalShowAnimation: false,

            // Delete modal
            confirmModal: false,
            confirmModalAnimation: false
        }
    }

    componentDidMount = () => {
        this.getId() && this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: this.getQuery(),
        }));
    };

    handleShowDetails = (item) => {
        this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalShow: !this.state.createModalShow
        })
    }

    handleShowConfirmDialog = (submit = null) => {
        this.setState({
            confirmModal: submit
        })
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmModalAnimation: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    confirmModalAnimation: false,
                    confirmModal: false
                })
            }, 500)
        })
    };

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData();
        })
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchData();
        })
    };


    getFields = () => {
        return this.props.fields
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            id: this.getId()
        }
    }

    getPrimaryKey = () => {
        return this.props.primaryKey
    }

    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName
    }

    render() {
        const {translate, dialogResource} = this.props;
        const dataItems = getProp(dialogResource, "data.list", []);
        const ExcludeIDs = dataItems.map(it => it.TrailerID)
        const isLoading = (dialogResource.isLoading);
        const count = getProp(dialogResource, "data.count", 0);
        return (
            <React.Fragment>
                <div className="flex justify-between items-center p-4">
                    <h2 className="text-tm-gray-900 text-2xl">{translate("page.heading.Trailers")}</h2>

                    <button className="btn btn-header ml-auto mr-5" onClick={this.fetchData}>
                        <ArrowPathIcon className="w-5 h-5"/>
                    </button>

                    {/*checkPerm(this.getResourceName(), CREATE_PERM) &&
                        <button
                            onClick={() => this.handleToggleCreateModal()}
                            className={"btn btn-primary"}>{translate("text.add_new")}
                        </button>
                    */}
                </div>

                <NoRecords
                    show={!dataItems.length && !isLoading}
                    addClass="p-4"
                    title="No Trailers"
                    text={translate("text.noTrailerPresent")}
                    btnLabel={checkPerm(Resources.LocationsTrailers, CREATE_PERM) && translate("text.allocateTrailerHere")}
                    onBtnClick={this.handleToggleCreateModal}
                />

                <div className="relative">
                    <ResourceTable
                        data={dataItems}
                        count={count}
                        options={{
                            style: {horizontalLines: true, floatingActions: true},
                            behaviour: {hasVirtualRows: true}
                        }}
                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        paginationButtonLimit={5}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onView={checkPerm(Resources.Contacts, READ_PERM) ? this.handleShowDetails : null}
                    />

                    <TableCardFooter
                        show={!(!dataItems.length && !isLoading) && count > this.state.limit}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            pageLimit={this.state.limit}
                            translate={translate}
                        />
                    </TableCardFooter>
                </div>


                <ModalSaveResource
                    title={"Create " + (this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-md"
                    show={this.state.createModalShow}
                    gridClass="grid gap-4 grid-cols-1 p-6"
                    onClose={this.handleToggleCreateModal}
                    fields={{
                        TrailerID: new Field("TrailerID", '', ['empty'], false, 'select-search'),
                        LocationDate: new Field("LocationDate", '', ['empty'], false, 'datetime', {
                            columnName: "Date"
                        }),
                        Notes: new Field("Notes", '', [''], false, "textarea")
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: this.props.dialogTitle + " created."
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    metadata={{
                        TrailerID: {
                            api: 'api/' + Resources.TrailersQuick,
                            query: {
                                ExcludeIDs: ExcludeIDs.join(",")
                            },
                            customizeList: (list) => {
                                return groupListBySCAC(list, "Trailer")
                            }
                        }
                    }}
                    translate={this.props.translate}
                />


                {!!this.state.confirmModal && (
                    <ModalConfirm
                        text={this.state.confirmText}
                        submit={this.state.confirmModal}
                        cancel={this.hideConfirmDialog}
                        translate={translate}
                        ui={{
                            confirmDialogHideAnimation: !!this.state.confirmModalAnimation
                        }}
                    />
                )}
            </React.Fragment>
        )
    }
}
