import React, {useEffect, useState} from 'react';
import LoadPlaningView from './load-planing-view';
import LoadPlaningPreview from './load-planing-preview';
import {FieldsManager} from "../../../../../../data/services/fields";
import {storeResourceTempData} from "../../../../../../data/actions/resource";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import ModalHeader from "../../../../../../common/components/modal/modal-header";
import {LoaderSmall} from "../../../../../../common/components/loader";

export default function LoadPlaningModal({
                                             onClose,
                                             onSubmit,
                                             isLoading,
                                             onResetLoadReviewState,
                                             previewData,
                                             translate
                                         }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [viewMode, setViewMode] = useState('plan'); // "preview"
    const [stops, setStops] = useState([]);
    const [commodityData, setCommodityData] = useState([]);
    const [isStateDirty, setIsStateDirty] = useState(false);

    const handleCreateLoadClick = () => {
        let stopsClone = [...stops];
        let LoadStopID = -1;

        const data = {
            stops: stopsClone.reduce((memo, it) => {
                if (it.StopID?.value?.value && it.StopID.metadata) {
                    let itUpdate = Object.assign(FieldsManager.getFieldKeyValues(it), it.StopID?.value?.metadata);
                    itUpdate.Stop = it.StopID.value.label;
                    itUpdate.StopName = it.StopID.value.label;
                    itUpdate.StopID = it.StopID.value.value;
                    itUpdate.Multistop = it.StopID.value.label;
                    itUpdate.Location = it.StopID.value.label;
                    itUpdate.LoadStopID = LoadStopID;

                    LoadStopID = LoadStopID - 1;
                    memo.push(itUpdate);
                }

                return memo;
            }, []),
            stopBys: [],
            commodityData
        }

        dispatch(storeResourceTempData(data));
        history.push("/loads/create")
    }

    const handleCreateQuoteClick = () => {
        let stopsClone = [...stops];
        let LoadStopID = -1;

        const data = {
            stops: stopsClone.reduce((memo, it) => {
                if (it.StopID?.value?.value && it.StopID.metadata) {

                    let itUpdate = Object.assign(FieldsManager.getFieldKeyValues(it), it.StopID?.value?.metadata)
                    itUpdate.Stop = it.StopID.value.label
                    itUpdate.Multistop = it.StopID.value.label
                    itUpdate.Location = it.StopID.value.label
                    itUpdate.LoadStopID = LoadStopID;

                    LoadStopID = LoadStopID - 1;
                    memo.push(itUpdate);
                }

                return memo;
            }, []),
            stopBys: [],
            commodityData
        }

        dispatch(storeResourceTempData(data));
        history.push("/quotes/create")
    }

    useEffect(() => {
        setViewMode(previewData?.Stops?.length ? 'preview' : 'plan');
    }, [previewData])

    return (
        <React.Fragment>
            <ModalHeader
                title={translate("dialog_heading.load_planing")}
                onClose={onClose}
            />

            {isLoading && (
                <div className="bg-tm-gray-100 absolute z-50 inset-0 flex items-center justify-center">
                    <LoaderSmall/>
                </div>
            )}

            {viewMode === "plan" && (
                <LoadPlaningView
                    stops={stops}
                    setStops={setStops}
                    onClose={onClose}
                    onSubmitClick={onSubmit}
                    commodityData={commodityData}
                    setCommodityData={setCommodityData}
                    isStateDirty={isStateDirty}
                    setIsStateDirty={setIsStateDirty}
                    translate={translate}
                />
            )}

            {viewMode === "preview" && (
                <LoadPlaningPreview
                    translate={translate}
                    onClose={onClose}
                    onResetLoadReviewState={onResetLoadReviewState}
                    previewData={previewData}
                    stops={stops}
                    setStops={setStops}
                    onCreateLoadClick={handleCreateLoadClick}
                    onCreateQuoteClick={handleCreateQuoteClick}
                    setCommodityData={setCommodityData}
                />
            )}
        </React.Fragment>
    )
}
