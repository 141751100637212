import React, {useState} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import AccountingLoadBreakdown from '../accounting-load-breakdown'
import {getProp} from '../../../../common/util/util-helpers'
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../../common/components/resource-table";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {calculateLoanPayment, planLoanPayments} from "../../../../common/util/util-accounting";

export default function AccountingMonthlyBreakdown({
                                                       onShowMonthlyBreakdownClick,
                                                       amount = 0,
                                                       IsDelayedPayment = false,
                                                       show,
                                                       cachedChangedFields,
                                                       translate
                                                   }) {

    const values = FieldsManager.getFieldKeyValues(cachedChangedFields)
    const Amount = parseFloat(getProp(values, 'Amount', amount))
    const ResidualValue = parseFloat(getProp(values, 'ResidualValue', 0))
    const InterestRate = parseFloat(getProp(values, 'InterestRate', 0))
    const LoanTerm = parseFloat(getProp(values, 'LoanTerm', 0))
    const downPayment = getProp(values, 'DownPayment', 0)
    let paymentBreakdown
    if (show && !!getProp(values, 'IsDelayedPayment', IsDelayedPayment) && (Amount > 0) && (InterestRate >= 0) && (LoanTerm > 0)) {
        paymentBreakdown = calculateLoanPayment(Amount - ResidualValue, InterestRate, LoanTerm, downPayment)

        paymentBreakdown = planLoanPayments(
            values.StartDate,
            Amount,
            ResidualValue,
            InterestRate,
            LoanTerm,
            downPayment
        );
    }

    const getQueryFields = () => {
        return {
            offset: new Field('offset', '0', [''], false, ''),
            limit: new Field('limit', '12', [''], false, 'select', {hideLabel: true})
        }
    }

    const [queryFields, setQueryFields] = useState(getQueryFields())

    const offset = queryFields?.offset?.value ?? 0
    const limit = queryFields?.limit?.value ?? 12
    let monthlyBreakdown = paymentBreakdown?.monthlyBreakdown
    let monthlyBreakdownSlice = []

    if (monthlyBreakdown?.length) {
        monthlyBreakdownSlice = monthlyBreakdown.slice(Number(offset), Number(offset) + Number(limit))
    }

    const handleUpdateOffset = (name, value) => {
        setQueryFields(FieldsManager.updateField(queryFields, name, value))
    }

    return (
        <ModalDefault
            show={show}
            widthClass={'max-w-xl'}
            title={translate('text.monthly_breakdown')}

            closeButtonLabel={translate('btn.Cancel')}
            onClose={onShowMonthlyBreakdownClick}
        >
            <div className="">
                <div className="border-b-2 border-tm-gray-200 mb-4">
                    <AccountingLoadBreakdown
                        cachedChangedFields={cachedChangedFields}
                        translate={translate}
                        show={true}
                    />
                </div>

                <div className="border-t border-tm-gray-300">
                    <ResourceTable
                        data={monthlyBreakdownSlice}
                        fields={{
                            PaymentNumber: new Field('PaymentNumber', '', [''], false, 'text'),
                            PaymentDate: new Field('PaymentDate', '', [''], false, 'date'),
                            BeginningBalance: new Field('BeginningBalance', '', [''], false, 'money'),
                            ScheduledPayment: new Field('ScheduledPayment', '', [''], false, 'money'),
                            Principal: new Field('Principal', '', [''], false, 'money'),
                            Interest: new Field('Interest', '', [''], false, 'money'),
                            EndingBalance: new Field('EndingBalance', '', [''], false, 'money'),
                            CumulativeInterest: new Field('CumulativeInterest', '', [''], false, 'money'),
                        }}
                        options={{
                            style: {
                                verticalLines: true,
                                horizontalLines: true
                            },
                        }}
                        translate={translate}
                    />

                    {(paymentBreakdown?.monthlyBreakdown?.length > 12) && (
                        <div className="px-6 py-2">
                            <Pagination
                                hideRowsPerPage
                                count={paymentBreakdown?.monthlyBreakdown?.length ?? 0}
                                handleQueryChange={
                                    (name, value) => handleUpdateOffset(name, value)}

                                queryFields={queryFields}
                                translate={translate}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ModalDefault>
    )
}
