export function previewDocumentResource(data) {
    return {
        type: 'PREVIEW_DOCUMENT_RESOURCE',
        data: data
    };
}

export function donePreviewDocumentResource(data) {
    return {
        type: 'DONE_PREVIEW_DOCUMENT_RESOURCE',
        data: data
    };
}

export function errorPreviewDocumentResource(data) {
    return {
        type: 'ERROR_PREVIEW_DOCUMENT_RESOURCE',
        data: data
    };
}

export function resetPreviewDocumentResource() {
    return {
        type: 'RESET_PREVIEW_DOCUMENT_RESOURCE',
        data: []
    }
}
