import React, {Component} from 'react';

export default class HourlyCron extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onHourChange = this.onHourChange.bind(this);
    }

    componentWillMount() {
        this.state.value = this.props.value;
    }

    onHourChange(e) {
        if (((e.target.value > 0 && e.target.value < 24) || e.target.value === '')) {
            let val = ['0', '*', '*', '*', '*'];
            val[1] = e.target.value ? `*/${e.target.value}` : "*/1";
            this.props.onChange(val);
        }
    }

    render() {
        const translateFn = this.props.translate;
        this.state.value = this.props.value
        return (
            <div className="my-2 py-6 flex items-center justify-between">
                <div className="text-sm font-semibold text-tm-gray-900">{translateFn('Every')}</div>

                <div className="space-x-3 flex items-center">
                    <input
                        className="w-24 form-control bg-tm-gray-100"
                        type="Number"
                        onChange={this.onHourChange}
                        value={this.state.value[1].split('/')[1] ? this.state.value[1].split('/')[1] : ''}
                    />

                    <div className="w-12">{translateFn('hour(s)')}</div>
                </div>
            </div>
        )
    }
}

