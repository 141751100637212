import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CheckCircleIcon, CreditCardIcon, EyeIcon} from '@heroicons/react/24/outline'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    renderExpenseStatusBadge,
    renderInvoiceStatusBadge,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {currentDate, toFrontDateTime} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import Tippy from '@tippyjs/react'
import BulkPaymentCheckDialog from './bulk-payment-check-dialog'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {getSecondResource} from '../../../data/actions/secondResource'
import {getDialogResource} from '../../../data/actions/dialogResource'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";

class CheckTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_checks'
        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            },
            columns: {
                AutoCounter: {
                    minWidth: 220,
                },
                Labels: {minWidth: 200},
                IsLoadInvoice: {minWidth: 200}
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Date',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            isTableFullScreen: false,
            // Dialog(s)
            editModalOpen: false,
            selectedItems: {},
            selectedRows: {},
            isTransactionsDialogVisible: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'income_checks_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'Checks_template' + currentDate() + '.xlsx',
                excelTemplate: 1
            }, this.getQuery())
        }))
    }

    fetchIncomeDetailsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: this.state.selectedItem?.RecvBatchPaymentID ?? null,
                RecvPaymentID: this.state.selectedItem?.RecvPaymentID ?? null
            },
            resource: Resources.IncomeTransactionsDetails
        }))
    }

    /** UI Events
     ================================================================= */
    handleSelectRowClick = (item, event) => {
        const itemID = item.RecvPaymentCheckID
        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.RecvPaymentCheckID])
            const lastSelectedItem = data.findIndex(it => it.RecvPaymentCheckID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].RecvPaymentCheckID]: data[i]})
                        selectedItems[data[i].RecvPaymentCheckID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].RecvPaymentCheckID] = data[i]
                        Object.assign(selectedRows, {[data[i].RecvPaymentCheckID]: data[i]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.RecvPaymentCheckID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                if (it.CheckStatusID == 1) {
                    selectedItems[it.RecvPaymentCheckID] = it
                    memo[it.RecvPaymentCheckID] = it
                }
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.RecvPaymentCheckID.toString())) {
                    delete selectedItems[it.RecvPaymentCheckID]
                    delete selectedRows[it.RecvPaymentCheckID]
                }
            })
        }

        this.setState({selectedRows})
    }

    deleteResource = (item) => {
        this.handleToggleDeleteModal(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: {RecvPaymentCheckID: item.RecvPaymentCheckID},
                    errorMessage: true, successMessage: `${item.CheckNumber} deleted.`,
                    resource: this.getResource(),
                    piggyQuery: this.getQuery(),
                    piggyResource: this.getResource()
                }))
                this.handleToggleDeleteModal()
            })
        }, item)
    }
    handleToggleDeleteModal = (submit, item) => {
        this.setState({
            confirmText: this.props.translate('text.unclaim_check') + (item?.CheckNumber ? item?.CheckNumber : ''),
            deleteDialog: submit
        })
    }

    closeBatchActionDialog = () => {
        this.setState({batchActionDialogHideAnimation: true}, () => {
            setTimeout(() => {
                this.setState({batchActionDialog: false, batchActionDialogHideAnimation: false})
            }, 500)
        })
    }

    handleBulkEdit = (fields, payments) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                RecvPaymentCheckID: payments.map((x) => x.RecvPaymentCheckID)
            }),
            resource: Resources.IncomeChecks,
            piggyResource: this.getResource(),
            query: this.getQuery()
        }))
        this.setState({selectedItems: {}, selectedRows: {}})
    }
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'InvoiceID'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData(true)
        })
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleToggleShowTransactions = (item = null) => {
        this.setState({
            selectedItem: item,
            selectedTransactionsItem: item,
            isTransactionsDialogVisible: !this.state.isTransactionsDialogVisible,
        }, () => {
            this.state.isTransactionsDialogVisible && this.fetchIncomeDetailsData()
            !this.state.isTransactionsDialogVisible && this.fetchData()
        })
    }

    toggleShowDetails = (item = null) => {
        this.setState({
            selectedItem: item,
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    setParentState = (data, callback = () => {
    }) => {
        this.setState(data, callback)
    }

    getQuery = () => {
        const {
            AccountID,
            StartDate,
            EndDate,
            ContactID,
            OrganizationID,
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                AccountID: AccountID,
                ContactID: ContactID,
                OrganizationID: OrganizationID,
                ...(EndDate && {EndDate: ['Date', '<', EndDate]}),
                ...(StartDate && {StartDate: ['Date', '>', StartDate]})
            })
        }
    }

    getPrimaryKey = () => {
        return 'RecvPaymentCheckID'
    }

    getResource = () => {
        return Resources.IncomeChecks
    }

    getFields = () => {
        return {
            PayFrom: new Field('PayFrom', '', [], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <div>{item.Organization ?? item.Contact}</div>
                    )
                }
            }),
            CheckNumber: new Field('CheckNumber', '', [], false),
            CheckStatusID: new Field('CheckStatusID', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <>{(item.CheckStatusID == 1) ? 'Open' : 'Claimed'}</>
                    )
                }
            }),
            Description: new Field('Description', '', [], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Description)}</div>
                )
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                render: (item) => {
                    return (
                        <div>
                            {item.ReconciliationDate ? (
                                    <Tippy content={'Reconciled: ' + toFrontDateTime(item.ReconciliationDate)}>
                                        <div className="flex justify-end">
                                            <CheckCircleIcon className="mr-4 w-5 h-5 text-green-600"/>
                                            <div>
                                                {genericMoneyFormatter(item.Amount)}
                                            </div>
                                        </div>
                                    </Tippy>
                                ) :
                                <div className={'text-right'}>
                                    {genericMoneyFormatter(item.Amount)}
                                </div>}
                        </div>
                    )
                }
            }),
            Date: new Field('Date', '', [], false, 'date'),
        }
    }

    getCheckFields = () => {
        return {
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full'
            }),
            Date: new Field('Date', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            }),
            Description: new Field('Description', '', [], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
        }
    }
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            AccountID: new Field('AccountID', '', [''], false, 'select-search', {}, {
                isClearable: true,
                api: 'api/' + Resources.AccountsQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }),
            ContactID: new Field('ContactID', '', [''], false, 'select-search', {}, {isClearable: true}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {}, {isClearable: true}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: 'float'}, {isClearable: true}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: 'float'}, {isClearable: true}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    getDetailsFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: 'PayTo',
                render: (it) => it.Organization ?? it.Contact
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                omitSort: true,
                label: 'PaymentAmount',
            }),
            ExpenseAmount: new Field('ExpenseAmount', '', [], false, 'money', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.ExpenseAmount,
                omitSort: true
            }),
            ExpenseStatus: new Field('ExpenseStatus', '', [''], false, 'custom', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.ExpenseStatus,
                omitSort: true,
                render: (it) => renderExpenseStatusBadge(it)
            }),
            InvoiceAmount: new Field('InvoiceAmount', '', [], false, 'money', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.InvoiceAmount,
                omitSort: true
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                hideTable: !getProp(this.props.dialogResource, 'data.list', [])[0]?.InvoiceStatus,
                omitSort: true,
                render: (it) => renderInvoiceStatusBadge(it)
            })
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, dialogResource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = !!resource.isLoading

        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0))

        const transactionsData = getProp(dialogResource, 'data.list', [])
        const transactionsDataIsLoading = getProp(dialogResource, 'isLoading', false)

        return (
            <React.Fragment>
                <div className="flex mb-4">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <div className="flex ml-auto">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                                gridCols={"grid-cols-1"}
                            />
                        }
                        forceDialog={true}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}
                        commonTable={true}
                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        saveTableOptions={this.setOptions}

                        onRowClick={(item) => (item.RecvPaymentID || item.RecvBatchPaymentID) && this.handleToggleShowTransactions(item)}
                        actions={[
                            {
                                action: this.handleToggleShowTransactions,
                                icon: EyeIcon,
                                visible: (item) => item.RecvPaymentID || item.RecvBatchPaymentID,
                                hasPerm: checkPerm(Resources.IncomeTransactionsDetails, READ_PERM),
                                title: translate('btn.view'),
                            }
                            ,
                            {
                                action: this.handleToggleEditModel,
                                icon: CreditCardIcon,
                                visible: (item) => item.CheckStatusID == 1,
                                hasPerm: checkPerm(Resources.IncomeChecks, UPDATE_PERM),
                                title: translate('text.claim_check'),
                            },
                            {
                                action: this.deleteResource,
                                icon: TrashIcon,
                                visible: (item) => item.CheckStatusID == 2 && !item.ReconciliationDate,
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                title: translate('btn.unclaim')
                            }
                        ]}
                        isSelectRowDisabled={(item) => item.CheckStatusID == 2}
                        onSelectRow={this.handleSelectRowClick}
                        selectedRows={this.state.selectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        tableKey={'RecvPaymentCheckID'}
                    />

                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />

                    </TableCardFooter>

                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        tableKey="RecvPaymentCheckID"
                        fields={this.getFields()}
                        translate={translate}
                        options={this.state.tableOptions}
                        setSelectedRows={this.setSelectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <div
                            className={classNames(this.props.breakpoint.index <= 1 ? 'flex divide-x-2 divide-tm-gray-300 mx-10' : 'flex divide-x-2 divide-tm-gray-300')}>
                            <div className={classNames(this.props.breakpoint.index <= 1 ? '' : 'flex px-3')}>
                                {checkPerm(Resources.IncomeChecks, UPDATE_PERM) && (
                                    <Tippy content={translate('text.ClaimChecks')}>
                                        <button
                                            onClick={() => this.setState({batchActionDialog: true})}
                                            disabled={!hasSelectedRows}
                                            className={classNames(hasSelectedRows ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                        >
                                            <CreditCardIcon
                                                className={classNames(hasSelectedRows ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                        </button>
                                    </Tippy>
                                )}
                            </div>
                        </div>
                    </TableBulkActions>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <BulkPaymentCheckDialog
                    show={this.state.batchActionDialog}
                    translate={translate}
                    HideModalAnimation={this.state.batchActionDialogHideAnimation}
                    onClose={this.closeBatchActionDialog}
                    selectedItems={this.state.selectedItems}
                    onSubmit={this.handleBulkEdit}
                    itemKeyLabel={'RecvPaymentCheckID'}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.deleteDialog}
                    text={this.state.confirmText}
                    onClose={() => this.handleToggleDeleteModal()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.deleteDialog}
                />

                <ModalSaveResource
                    title={'Deposit check'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getCheckFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Check Claimed'
                            }))
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        AccountID: {
                            api: 'api/accounting/accounts',
                            query: {
                                AccountTypeID: ACCOUNT_TYPE_CASH
                            },
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName
                            })
                        }
                    }}
                />

                <ModalDefault
                    show={this.state.isTransactionsDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.Payments')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowTransactions()}
                >
                    <div>
                        <ResourceTable
                            data={transactionsData}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={transactionsDataIsLoading}

                            limit={(transactionsData?.length ?? 10)}

                            onRowClick={this.toggleShowDetails}
                            actions={[
                                {
                                    action: this.toggleShowDetails,
                                    icon: EyeIcon,
                                    hasPerm: checkPerm(this.getResource(), READ_PERM),
                                    title: translate('text.show_details'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(transactionsData.length === 0) && !transactionsDataIsLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                        />
                    </div>

                </ModalDefault>

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.InvoiceID')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading || this.props.download.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        type="invoice"

                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getSecondResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        InvoiceID: this.state.selectedItem?.InvoiceID
                                    },
                                    resource: Resources.InvoicesInvoice
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(CheckTab)
